export const formatDateToLocal = (d: Date) => {
  if (!d) return 'N/A';

  return d.toLocaleDateString([], {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

export const formatDateToLocalWithSeconds = (d: Date) => {
  if (!d) return 'N/A';

  return `${d.toLocaleDateString([], {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })}, ${d.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })}`;
};

export const millisecondsToMicroseconds = (milliseconds: number) => {
  return milliseconds * 1000;
};

export const microsecondsToMilliseconds = (microseconds: number) => {
  if (typeof microseconds !== 'number') {
    return 0;
  }

  try {
    return microseconds / 1000;
  } catch {
    return 0;
  }
};

/**
 * Converts javascript date object to unix timestamp in microseconds
 * @param d JavaScript Date object
 */
export const dateToUnixTimestampMicroseconds = (d: Date) => {
  const dateTime = d.getTime();
  const microseconds = millisecondsToMicroseconds(dateTime);
  return parseInt(microseconds.toFixed(0));
};

/**
 * Converts unix timestamp in microseconds to locale time string, e.g. '10:00:00 PM'
 * @param timestamp unix timestamp in microseconds
 */
export const timestampToLocaleTimeString = (timestamp: number) => {
  if (!timestamp) return '-';
  return new Date(microsecondsToMilliseconds(timestamp)).toLocaleTimeString();
};

/**
 * Converts unix timestamp in microseconds to local date, e.g. '5/4/2022'
 * @param timestamp unix timestamp in microseconds
 */
export const timestampToLocalDate = (timestamp: number | null | undefined) => {
  if (!timestamp) return '-';
  const date = new Date(microsecondsToMilliseconds(timestamp));
  return formatDateToLocal(date);
};

/**
 * Converts unix timestamp in microseconds to local date, e.g. "5/4/2022, 10:00:00 PM"
 * @param timestamp unix timestamp in microseconds
 */
export const timestampToLocalDateWithTime = (timestamp: number) => {
  if (!timestamp) return '-';
  const date = new Date(microsecondsToMilliseconds(timestamp));
  return formatDateToLocalWithSeconds(date);
};

/**
 * Converts unix timestamp in microseconds to and object with local date and time properties
 * @param timestamp unix timestamp in microseconds
 * @returns [date, time]
 */
export const timestampToLocalDateAndTimeTuple = (
  timestamp: number,
): string[] | string => {
  if (!timestamp) return '-';

  return timestampToLocalDateWithTime(timestamp)
    .split(',')
    .map(s => s.trim());
};

/**
 * Converts date to time ago, e.g. "2 hours ago"
 * @param date Date object or unix timestamp in milliseconds
 * @param shortFormat If true, returns short format, e.g. "2h" instead of "2 hours ago"
 */
export const timeAgo = (date: Date, shortFormat = false): string => {
  const now = new Date();
  const diffInMilliseconds = now.getTime() - date.getTime();

  const seconds = Math.floor(diffInMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    if (shortFormat) {
      return years === 1 ? '1 year' : `${years} years`;
    }
    return years === 1 ? '1 year ago' : `${years} years ago`;
  } else if (months > 0) {
    if (shortFormat) {
      return months === 1 ? '1 month' : `${months} months`;
    }
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (weeks > 0) {
    if (shortFormat) {
      return weeks === 1 ? '1 week' : `${weeks} weeks`;
    }
    return weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
  } else if (days > 0) {
    if (shortFormat) {
      return days === 1 ? '1 day' : `${days} days`;
    }
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
    if (shortFormat) {
      return hours === 1 ? '1 hour' : `${hours} hours`;
    }
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else if (minutes > 0) {
    if (shortFormat) {
      return minutes === 1 ? '1 min' : `${minutes} mins`;
    }
    return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
  } else {
    return 'just now';
  }
};

/**
 * Parses a Date object into a string formatted with slashes (MM/DD/YYYY).
 *
 * @param inputDate Date - The Date object to be formatted.
 * @returns string - The formatted date string (e.g., "MM/DD/YYYY") or '--/--/----' if the input is not a Date.
 */
export const parseDateToSlashes = (
  inputDate: Date | null | undefined,
): string => {
  if (!inputDate) return '--/--/----';

  const day = String(inputDate.getDate()).padStart(2, '0');
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const year = inputDate.getFullYear();

  return `${month}/${day}/${year}`;
};

/**
 * Calculates the number of days elapsed since a given date.
 * @param date - The reference date.
 * @returns The number of days elapsed since the given date.
 */
export const getDaysSinceDate = (date: Date, currentDate: Date) => {
  return (currentDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
};

export const getTimeUntil = (date: number | string) => {
  let dateTimeStampe =
    typeof date === 'string' ? Date.parse(date) : date / 1000;
  const total = dateTimeStampe - new Date().getTime();
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const parseTimeUntil = (
  isoDate: number | string | undefined,
  avoidSeconds = false,
) => {
  if (!isoDate) return undefined;

  const { total, days, minutes, hours, seconds } = getTimeUntil(isoDate);

  if (total < 0) return '0d 0h 0m 0s';

  let parts: string[] = [];

  if (days) parts.push(`${days}d`);
  if (hours) parts.push(`${hours}h`);
  if (minutes) parts.push(`${minutes}m`);
  if (seconds && !avoidSeconds) parts.push(`${seconds}s`);

  return parts.join(' ').trim();
};

export const parseSecondsInDDHHMMSS = (timeLeft: number) => {
  if (!timeLeft) return undefined;

  const seconds = Math.floor((timeLeft / 1000) % 60);
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
  const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));

  if (timeLeft < 0) return '0d 0h 0m 0s';

  let parts: string[] = [];

  if (days) parts.push(`${days}d`);
  if (hours) parts.push(`${hours}h`);
  if (minutes) parts.push(`${minutes}m`);
  if (seconds) parts.push(`${seconds}s`);

  return parts.join(' ').trim();
};
