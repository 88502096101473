import styled from 'styled-components';

type ContainerProps = {
  backgroundImage?: string;
};
export const Container = styled.div<ContainerProps>`
  position: relative;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  width: 414px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 20px;

    .text-primary {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .title {
    align-self: stretch;
  }

  .close-icon {
    display: flex;
    position: absolute;
    padding: 5px;
    z-index: 3;
    background: ${({ theme }) => theme.colors.white};
    top: 10px;
    right: 10px;
    border-radius: 100px;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
`;

export const Footer = styled.div`
  padding: 0 10px;
  display: flex;
  gap: 35px;

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .icon-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: ${({ theme }) => theme.colors.shadesBackground700};
      height: 24px;
      width: 24px;
      padding: 12px;
    }

    &:hover {
      .icon-circle {
        background: ${({ theme }) => theme.colors.shadesBackground500};
      }
    }
  }
`;
