import useModal from 'hooks/useModal';

import { FormattedNumber, Icon } from 'components';
import Button from 'components/Button/button';
import Loading from 'components/Loading';
import Text from 'components/Text';

import InviteFriendsModal from './InviteFriendsModal';
import { Container, ReferralFeesHistoryContainer } from './styles';
import cashIcon from 'assets/icons/cash.svg';
import { Modals } from 'constants/modals';
import { routes } from 'pages/routes';

import { Referral } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
  userReferral: Referral | undefined;
};
const InviteFriends = ({ userReferral }: Props) => {
  const navigate = useNavigate();
  const modal = useModal();
  const { t } = useTranslation();

  if (!userReferral) return <Loading />;

  const handleViewHistory = () => {
    navigate(routes.portfolio.referralFees.getRoutePath());
  };

  const handleInviteFriends = () => {
    modal.present(
      <InviteFriendsModal referralCode={userReferral.shortCode} />,
      Modals.inviteFriendsModal,
    );
  };

  return (
    <Container>
      <div className="referral-fees-container">
        <Text variant="BODY_M" fontWeight="semiBold">
          {t('ReferralFees')}
        </Text>
        <ReferralFeesHistoryContainer>
          <div className="left-col">
            <Icon src={cashIcon} />
            <div className="value">
              <Text variant="BODY_M" fontWeight="semiBold">
                {t('youHaveEarned')}{' '}
                <FormattedNumber value={userReferral.payout24h} prefix="$" />
              </Text>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('fromYourReferralsToday')}
              </Text>
            </div>
          </div>
          <Button
            padding="12px 20px"
            textVariant="BUTTON_M"
            onClick={handleViewHistory}
            brandSpecificProps={{
              rabbitx: { colorVariant: 'primaryGreen' },
              bfx: { colorVariant: 'secondary' },
            }}
          >
            {t('ViewHistory')}
          </Button>
        </ReferralFeesHistoryContainer>
      </div>
      <Button
        block={true}
        textVariant="BUTTON_M"
        brandSpecificProps={{
          rabbitx: { colorVariant: 'primaryGreen' },
          bfx: { colorVariant: 'bfxMain' },
        }}
        padding="12px 20px"
        onClick={handleInviteFriends}
        data-gtmid="button-invite-friends"
      >
        {t('InviteFriends')}
      </Button>
    </Container>
  );
};

export default observer(InviteFriends);
