export const LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN =
  'LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN';

export const LS_SHOULD_DISABLE_CANCEL_ORDER_CONFIRMATION_MODAL =
  'LS_SHOULD_DISABLE_CANCEL_ORDER_CONFIRMATION_MODAL';

export const LS_DEFAULT_CURRENCY_KIND = 'LS_DEFAULT_CURRENCY_KIND';

export const LS_ORDERBOOK_ORDER_ENTRY_QUANTITY =
  'ORDERBOOK_ORDER_ENTRY_QUANTITY';

export const LS_IS_PRESET_SLIDER = 'IS_PRESET_SLIDER';

export const LS_SHOW_ALL_MARKETS = 'SHOW_ALL_MARKETS';

export const LS_DEFAULT_MARKET = 'DEFAULT_MARKET_KEY';

export const LS_DEFAULT_MARKET_CLOSE_SLIPPAGE = 'DEFAULT_MARKET_CLOSE_SLIPPAGE';

export const LS_DEFAULT_STLP_MARKET_SLIPPAGE = 'DEFAULT_STLP_MARKET_SLIPPAGE';

export const LS_DEFAULT_IS_STLP_MARKET = 'DEFAULT_IS_STLP_MARKET'; // used to check and store user's preference on SL/TP (we can show SL/TP price in either (slippage and marketPrice) terms or limitPrice terms)

export const LS_USER_SETTINGS = 'userSettings';

export const LS_PENDING_TRANSACTIONS = 'pendingTransactions';

export const LS_PENDING_MESH_TRANSACTIONS = 'pendingMeshTransactions';

export const LS_PENDING_TRANSACTIONS_TOAST_IDS = 'pendingTransactionsToastIds';

export const LS_MESH_ACCESS_TOKENS = 'meshAccessTokens';

export const LS_WALLET = 'wallet';

export const LS_IS_WALLET_CONNECTED_VIA_SOCIAL =
  'is_wallet_connected_via_social';

export const LS_WALLET_TYPE = 'walletType';

export const CAMPAIGN_ANALYTICS = 'campaignAnalytics';

export const LS_ORDER_TYPE_TIME_IN_FORCE = 'ORDER_TYPE_TIME_IN_FORCE';

export const LS_WALLET_SIGNATURE = 'METAMASK_SIGNATURE';

export const LS_TV_CHART_RELOAD_TIME = 'TV_CHART_RELOAD_TIME';

export const LS_ORDERBOOK_USER_SAVED_TICKS = 'LS_ORDERBOOK_USER_SAVED_TICKS';

export const LS_SAVED_WALLETS = 'rabbitx_saved_wallets';

export const LS_VAULT_ACTIVITY = 'rabbitx_vault_activity';

export const LS_CLOCK_OFFSET = 'CLOCK_OFFSET';
