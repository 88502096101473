import { memo } from 'react';

import {
  TableRow,
  TableHead as TableHeadMui,
  TableCell,
} from '@material-ui/core';

import { VisibilityProps } from '.';
import { useTranslation } from 'react-i18next';

const TableHead = ({ hideManagerColumn, hideActions }: VisibilityProps) => {
  const { t } = useTranslation();
  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>{t('type')}</TableCell>
        <TableCell key={1}>{t('vaultName')}</TableCell>
        {!hideManagerColumn ? (
          <TableCell key={2}>{t('manager')}</TableCell>
        ) : null}
        <TableCell key={3}>{t('currency')}</TableCell>
        <TableCell key={4}>{t('shares')}</TableCell>
        <TableCell key={5}>{t('value')}</TableCell>
        <TableCell key={6}>{t('status')}</TableCell>
        <TableCell key={7}>{t('time')}</TableCell>
        {!hideActions ? (
          <TableCell key={8} align="right">
            {t('actions')}
          </TableCell>
        ) : null}
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
