import NumberInput, {
  Props as NumberInputProps,
} from 'components/Inputs/NumberInput';

import { Container } from './styles';

import { useTranslation } from 'react-i18next';

export type InputProps = Pick<
  NumberInputProps,
  'onChange' | 'currencyComponent' | 'value' | 'disabled' | 'partiallyDisabled'
> & { hideInput?: boolean; children?: React.ReactNode };
const Input = ({ hideInput = false, children, ...props }: InputProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <NumberInput
        label={t('amount')}
        showPresets={false}
        showValueApproximation={false}
        showSwapCurrency={false}
        isDisabledTheSameAsBG={true}
        autoFocus
        {...props}
      />

      <div className="info">{children}</div>
    </Container>
  );
};

export default Input;
