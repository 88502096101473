import { ChangeEvent, memo, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import { useActiveWeb3React } from 'hooks';
import useKey, { Key } from 'hooks/useKey';

import Button from 'components/Button';
import CountdownTimer from 'components/Tables/TransfersTable/CountdownTimer';
import { getTierTime } from 'components/Tables/TransfersTable/PendingWithdrawal';

import { ButtonGroup, InfoRowStyled } from '../styles';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { config } from 'config';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type EmailInputProps = {
  isEnabled: boolean;
};
const EmailInput = styled.div<EmailInputProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px 6px 12px;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  border-radius: 8px;
  transition: background-color 0.5s;

  :hover {
    background: ${({ theme }) => theme.colors.shadesBackground600};
    > input {
      font-size: 16px;
      line-height: 24px;
    }
  }

  > input {
    transition: font-size 0.1s;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.shadesForeground300};
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    ::placeholder {
      color: ${({ theme }) => theme.colors.shadesForeground300};
    }
  }

  > button {
    padding: 8px 10px;
    background: ${({ theme, isEnabled }) =>
      isEnabled ? theme.colors.white : theme.colors.shadesForeground50};
    border-radius: 6px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.shadesBackground900};

    :disabled {
      background: ${({ theme }) => theme.colors.shadesForeground50};
    }
    :hover {
      background: ${({ theme }) => theme.colors.white};
    }
  }
`;

const LoadingStyled = styled(CircularProgress)`
  display: flex;
  margin: 0 auto;
  &.MuiCircularProgress-colorPrimary {
    color: initial;
  }
`;

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email: string): boolean => {
  return emailRegex.test(String(email).toLowerCase());
};

type MessageType = 'success' | 'error' | null;

type Props = {
  withdrawAmount: number;
  withdrawalDate: Date;
};
const WithdrawalProcessingStep = ({
  withdrawAmount,
  withdrawalDate,
}: Props) => {
  const { account } = useActiveWeb3React();
  const [email, setEmail] = useState<string>('');
  const [messageType, setMessageType] = useState<MessageType>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();

  useKey(Key.Enter, () => handleNotifyMe());

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
  };

  const timeToWait = getTierTime(withdrawAmount);

  const handleNotifyMe = async () => {
    if (!account) return;

    // The time difference between the withdrawal date and the current date
    const timeDiff = withdrawalDate.getTime() / 1000 - Date.now() / 1000;
    // The time to wait for the withdrawal to be processed
    const secondsLeft = timeDiff + timeToWait;

    const API_KEY = config.withdrawalEmailApiKey;
    const url = config.withdrawalEmailApiUrl;
    const body = {
      email,
      locale: navigator.language,
      wallet_address: account,
      time_to_wait: Math.floor(secondsLeft > 0 ? secondsLeft : 0),
    };

    try {
      flushSync(() => {
        setIsSubmitting(true);
      });
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${API_KEY}`,
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        setMessageType('success');
      } else {
        throw new Error('The response was not ok');
      }
    } catch (error) {
      setMessageType('error');
      console.log(error);
    } finally {
      setIsSubmitting(false);
      setEmail('');
    }
  };

  const isEmailValid = validateEmail(email);

  return (
    <>
      <InfoRowStyled
        isLeading={true}
        isHighlighted={true}
        className="mb-20"
        isCentered
      >
        <span>{t('yourWithdrawalIsBeingProcessed')}</span>
      </InfoRowStyled>
      {/* <InfoRowStyled isCentered>
        {' '}
        <span className="align-center">
          {t('enterYourDetailsBelowForWithdrawal')}
        </span>
      </InfoRowStyled> */}
      <InfoRowStyled isCentered>
        <span>
          {t('estimatedTimeForWithdrawalClaimable')}{' '}
          <CountdownTimer timeInSeconds={timeToWait} showDone />
        </span>
      </InfoRowStyled>
      <ButtonGroup className="mt-20">
        <a
          href="https://docs.rabbitx.io/faq#why-hasnt-my-withdrawal-arrived-yet"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="secondaryOutline" size="large" block>
            {t('help?')}
          </Button>
        </a>
        {/* <EmailInput isEnabled={isEmailValid}>
          <input
            type="text"
            placeholder={t('emailAddress')}
            value={email}
            onChange={handleChange}
          />
          <button
            disabled={!isEmailValid || isSubmitting}
            onClick={handleNotifyMe}
          >
            {isSubmitting ? (
              <LoadingStyled
                style={{
                  width: '10px',
                  height: '10px',
                }}
              />
            ) : (
              t('notifyMe')
            )}
          </button>
        </EmailInput> */}
      </ButtonGroup>
      {messageType && (
        <InfoRowStyled isCentered>
          <ColoredText
            className="mt-20"
            color={messageType === 'success' ? Colors.Success : Colors.Danger}
          >
            {messageType === 'success'
              ? 'Successfully subscribed!'
              : 'There was an error when trying to subscribe.'}
          </ColoredText>
        </InfoRowStyled>
      )}
    </>
  );
};

export default memo(WithdrawalProcessingStep);
