import { useReducer } from 'react';

import { Checkbox } from '@material-ui/core';

import { readUserSetting } from 'utils';

import CheckBox from 'components/Toggle/Checkbox';

import { FormControlLabelStyled } from './styles';
import { LS_ORDER_TYPE_TIME_IN_FORCE } from 'constants/localStorageKeys';
import { useAppContext } from 'contexts/AppContext';

import { OrderType } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const SET_CHECKBOX = 'SET_CHECKBOX';

export type TimeInForce =
  | 'good_till_cancel'
  | 'immediate_or_cancel'
  | 'fill_or_kill'
  | 'post_only';

type State = {
  checkbox: null | TimeInForce;
};

type Action = {
  type: 'SET_CHECKBOX';
  payload: null | TimeInForce;
  setOrderTypeTimeInForce: any;
  orderType: OrderType;
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case SET_CHECKBOX:
      const newCheckboxVal =
        action.payload === state.checkbox ? null : action.payload;
      action.setOrderTypeTimeInForce(action.orderType, newCheckboxVal);
      return {
        ...state,
        checkbox: newCheckboxVal,
      };
    default:
      return state;
  }
}

/**
 * Reads the user's settings from local storage and returns the initial state for the reducer
 * @param orderType Selected order type
 */
const getInitialState = (orderType: OrderType): State => {
  const type = readUserSetting(LS_ORDER_TYPE_TIME_IN_FORCE) ?? {};

  return {
    checkbox: type[orderType] ?? null,
  };
};

type Props = {
  orderType: OrderType;
};
const TimeInForceCheckboxes = ({ orderType }: Props) => {
  const {
    store: {
      appState: { setOrderTypeTimeInForce },
    },
  } = useAppContext();
  const [state, dispatch] = useReducer(reducer, getInitialState(orderType));
  const { t } = useTranslation();

  // Only show checkboxes for market and limit orders, and also ladder (multiple limit orders)
  if (
    ![OrderType.MARKET, OrderType.LIMIT, OrderType.LADDER].includes(orderType)
  )
    return null;

  const handleCheck = (payload: TimeInForce) => {
    dispatch({
      type: SET_CHECKBOX,
      payload,
      setOrderTypeTimeInForce,
      orderType,
    });
  };

  return (
    <>
      {[OrderType.LIMIT, OrderType.LADDER].includes(orderType) && (
        <CheckBox
          isChecked={state.checkbox === 'post_only'}
          label={t('postHyphenOnly')}
          onClick={event => {
            event.stopPropagation();
            handleCheck('post_only');
          }}
        />
      )}
      <CheckBox
        isChecked={state.checkbox === 'fill_or_kill'}
        label={t('FOK')}
        onClick={event => {
          event.stopPropagation();
          handleCheck('fill_or_kill');
        }}
      />
      <CheckBox
        isChecked={state.checkbox === 'immediate_or_cancel'}
        label={t('IOC')}
        onClick={event => {
          event.stopPropagation();
          handleCheck('immediate_or_cancel');
        }}
      />
    </>
  );
};

export default observer(TimeInForceCheckboxes);
