import { useState } from 'react';

import { getSelectedMarketLeverage, showNotification } from 'utils';

import { useAccountAPI } from 'hooks';

import { Icon } from 'components';
import Loading from 'components/Loading';
import { SelectDropdown } from 'components/SelectDropdown.tsx';
import Text from 'components/Text';

import caretDownIcon from 'assets/icons/chevron-down-gray.svg';
import { useAppContext } from 'contexts/AppContext';

import { NotificationType } from 'enums';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledLeverageSelector = styled(Text)`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px;
  justify-self: center;
  bottom: 5px;
  background: ${({ theme }) => theme.colors.shadesBackground900};
  border-radius: 4px;
  cursor: pointer;
  max-height: 21px;
  box-sizing: border-box;
  min-width: 35px;
  justify-content: center;
  align-items: center;

  :hover {
    background: ${({ theme }) => theme.colors.shadesBackground800};
  }
`;

const getLeverageOptions = (maxLeverage: number) => {
  return Array.from({ length: maxLeverage }, (_, index) => index + 1);
};

const LeverageSelector = observer(() => {
  const {
    store: {
      markets: { selectedMarketId },
      account: accountStore,
    },
  } = useAppContext();
  const { changeMarketLeverage } = useAccountAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const currentLeverage = getSelectedMarketLeverage(
    accountStore._marketLeverage,
    selectedMarketId,
  );

  const leverageOptions = getLeverageOptions(20);

  const handleMarketLeverageChange = async (leverage: number) => {
    if (!selectedMarketId) return;

    // Do nothing if the new leverage is the same as the current one
    if (leverage === currentLeverage) return;

    try {
      flushSync(() => setIsLoading(true));

      const requestBody = {
        marketID: selectedMarketId,
        leverage,
      };
      const newProfileData = await changeMarketLeverage(requestBody);
      accountStore.setAccount(newProfileData);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);

      console.error(
        'An error occurred while sending private PUT request for changing market leverage',
        e,
      );
    }
  };

  if (!accountStore.frontendSecrets) return null;

  return (
    <SelectDropdown
      disabled={isLoading}
      containerClass="absolute-bottom"
      InputElement={
        <StyledLeverageSelector variant="BODY_S">
          {isLoading ? (
            <Loading size="12px" />
          ) : (
            <>
              {currentLeverage}x <Icon size="S" src={caretDownIcon} />
            </>
          )}
        </StyledLeverageSelector>
      }
      options={leverageOptions}
      isOptionSelected={option => option === currentLeverage}
      displayTextParser={option => `${option}x`}
      onOptionSelect={handleMarketLeverageChange}
      customStyles={{
        top: '30px',
        maxHeight: '172px',
      }}
    />
  );
});

export default LeverageSelector;
