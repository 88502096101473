import Dexie, { Table } from 'dexie';

interface Signatures {
  wallet?: string;
  randomSecret?: string;
  refreshToken?: string;
  jwt?: string;
  profile?: string;
}

export class RabbitDB extends Dexie {
  signatures!: Table<Signatures, string>;

  constructor() {
    super('RabbitDB');
    this.version(1).stores({
      signatures: 'wallet',
    });
  }

  async getItem(wallet: string) {
    if (!this.signatures) {
      return;
    }

    try {
      return await this.signatures
        .where({ wallet: wallet.toLowerCase() })
        .first();
    } catch (error: any) {
      console.error(
        `Error getting item from the database. Wallet: ${wallet}`,
        error,
      );
      throw new Error(
        `Error getting item from the database. Wallet: ${wallet}`,
        {
          cause: error,
        },
      );
    }
  }

  async addItem(item: Signatures) {
    if (!this.signatures) {
      return;
    }
    try {
      return await this.signatures.add(item);
    } catch (error: any) {
      console.error(
        `Error adding item to the database. Item: ${JSON.stringify(item)}`,
        error,
      );
      throw new Error(
        `Error adding item to the database. Item: ${JSON.stringify(item)}`,
        {
          cause: error,
        },
      );
    }
  }

  async updateItem(wallet: string, item: Signatures) {
    if (!this.signatures) {
      return;
    }
    try {
      return await this.signatures.update(wallet.toLowerCase(), item);
    } catch (error: any) {
      console.error(
        `Error updating item in the database. Item: ${JSON.stringify(item)}`,
        error,
      );
      throw new Error(
        `Error updating item in the database. Item: ${JSON.stringify(item)}`,
        {
          cause: error,
        },
      );
    }
  }

  async deleteItem(wallet: string) {
    if (!this.signatures) {
      return;
    }
    try {
      return await this.signatures.delete(wallet.toLowerCase());
    } catch (error: any) {
      console.error(
        `Error deleting item from the database. Wallet: ${wallet}`,
        error,
      );
    }
  }
}

export const db = new RabbitDB();
