import { useEffect, useMemo, useState } from 'react';

import {
  basisToPercent,
  getCurrencyFromTradingPair,
  getCurrencyInputValue,
  readUserSetting,
  roundDownToNearestTick,
  roundToNearestTick,
  saveUserSetting,
  showNotification,
} from 'utils';

import { Key } from 'hooks';
import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import useModal from 'hooks/useModal';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import { FormattedNumber, Icon } from 'components';
import Badge from 'components/Badge';
import Button from 'components/Button/button';
import NumberInput from 'components/Inputs/NumberInput';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import TabSelector from 'components/TabSelector';
import Text from 'components/Text';

import {
  getIsStopLoss,
  getIsTakeProfit,
} from '../StopLossTakeProfitCancelModal';
import { getMarketPosition } from '../stopLossTakeProfitCell';
import LimitPriceAndSlippage, {
  LimitOrderOptions,
} from './LimitPriceAndSlippage';
import {
  ButtonGroup,
  ExpectedLossProfitRow,
  MarginSeparator,
  Row,
  StyledTabSelectorContainer,
} from './styles';
import deleteRedIcon from 'assets/icons/trash.svg';
import {
  LS_DEFAULT_IS_STLP_MARKET,
  LS_DEFAULT_STLP_MARKET_SLIPPAGE,
} from 'constants/localStorageKeys';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import CurrencyHeader from 'pages/Trade/components/BuySell/CurrencyHeader';
import { useMarketById } from 'queryHooks/markets';

import { NotificationType, OrderStatus, OrderType, TradeSide } from 'enums';
import { Order, Position, SpecialOrder } from 'interfaces';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const getConfirmBtnLabel = ({
  order,
  isSubmitting,
  t,
}: {
  order?: SpecialOrder | null;
  isSubmitting?: boolean;
  t: any;
}) => {
  if (isSubmitting) return t('submittingDot');

  return order ? t('update') : t('confirm');
};

/**
 * Returns an error message if the trigger price is invalid for the given order type and side
 */
export const getTriggerPriceErrorMessage = ({
  orderType,
  fairPrice,
  triggerPrice,
  tradeSide,
  t,
}: {
  orderType: OrderType;
  fairPrice: number;
  triggerPrice: number | null;
  tradeSide: TradeSide;
  t: any;
}) => {
  if (triggerPrice === null) return null;

  // Stop Loss
  if (getIsStopLoss(orderType)) {
    // Stop Loss - Long
    if (tradeSide === TradeSide.LONG && triggerPrice >= fairPrice) {
      return t('slPriceMustBeLowerThanFairPrice');
    }

    // Stop Loss - Short
    if (tradeSide === TradeSide.SHORT && triggerPrice <= fairPrice) {
      return t('slPriceMustBeHigherThanFairPrice');
    }
  }

  // Take Profit
  if (!getIsStopLoss(orderType)) {
    // Take Profit - Long
    if (tradeSide === TradeSide.LONG && triggerPrice <= fairPrice) {
      return t('tpPriceMustBeHigherThanFairPrice');
    }

    // Take Profit - Short
    if (tradeSide === TradeSide.SHORT && triggerPrice >= fairPrice) {
      return t('tpPriceMustBeLowerThanFairPrice');
    }
  }

  return null;
};

/**
 * Returns an error message if size is not between 0-100%.
 */
const getSizeErrorMessage = (sizePercent: number | null, t: any) => {
  if (sizePercent === null) return null;

  if (sizePercent <= 0 || sizePercent > 100) {
    return t('sizeMustBeBetween0and100percent');
  }
  return null;
};

export const getShouldDisableConfirmBtn = ({
  isSubmitting,
  triggerPrice,
  sizePercent,
  fairPrice,
  orderType,
  tradeSide,
  selectedLimitOrder,
  limitPrice,
  maxSlippage,
  isAmendingMarketOrder,
}: {
  isSubmitting?: boolean;
  triggerPrice?: number | null;
  sizePercent?: number | null;
  fairPrice: number;
  orderType: OrderType;
  tradeSide: TradeSide;
  selectedLimitOrder: LimitOrderOptions;
  limitPrice: number | null;
  maxSlippage: number | null;
  isAmendingMarketOrder: boolean;
}) => {
  if (isSubmitting) return true;
  if (!triggerPrice || !sizePercent) return true;
  if (sizePercent <= 0 || sizePercent > 100) return true;
  if (triggerPrice <= 0) return true;

  // Stop Loss
  if (orderType === OrderType.STOP_LOSS) {
    // Stop Loss - Long
    if (tradeSide === TradeSide.LONG && triggerPrice >= fairPrice) {
      return true;
    }
    // Stop Loss - Short
    if (tradeSide === TradeSide.SHORT && triggerPrice <= fairPrice) {
      return true;
    }
  }

  // Take Profit
  if (orderType === OrderType.TAKE_PROFIT) {
    // Take Profit - Long
    if (tradeSide === TradeSide.LONG && triggerPrice <= fairPrice) {
      return true;
    }
    // Take Profit - Short
    if (tradeSide === TradeSide.SHORT && triggerPrice >= fairPrice) {
      return true;
    }
  }

  // If the order is a limit order, we need to check the limit price
  // Skip this for old market orders
  if (!isAmendingMarketOrder) {
    if (selectedLimitOrder === 'Limit') {
      // If user select custom price and forget to input value- disable the confirm button until he types the value
      if (limitPrice === null || limitPrice <= 0) {
        return true;
      }
      // for short position, disable if the limit price > 1.03 * trigger price
      if (tradeSide === TradeSide.SHORT && limitPrice > triggerPrice * 1.03) {
        return true;
      }
      // for long position, disable if the limit price < 0.97 * trigger price
      if (tradeSide === TradeSide.LONG && limitPrice < triggerPrice * 0.97) {
        return true;
      }
    }

    if (selectedLimitOrder === 'Market' && maxSlippage !== null) {
      if (maxSlippage < 0 || maxSlippage > 3) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Returns the order type to be submitted.
 */
const getOrderTypeOnSubmit = ({ orderType }: { orderType: OrderType }) => {
  return orderType === OrderType.STOP_LOSS
    ? OrderType.STOP_LOSS_LIMIT
    : OrderType.TAKE_PROFIT_LIMIT;
};

const getOrderLimitPrice = ({
  selectedLimitOrder,
  limitPrice,
  triggerPrice,
  maxSlippage,
  tradeSide,
}: {
  orderType: OrderType;
  selectedLimitOrder: LimitOrderOptions;
  limitPrice: number | null;
  triggerPrice: number | null;
  maxSlippage: number | null;
  tradeSide: TradeSide;
}) => {
  if (!triggerPrice) return null;
  if (selectedLimitOrder === 'Limit' && limitPrice !== null && limitPrice > 0) {
    return limitPrice;
  }

  if (selectedLimitOrder === 'Market') {
    const slippageBps = (maxSlippage ?? 0) * 100;

    // for long position, trigger price * (1-user slippage input / 10000)
    if (tradeSide === TradeSide.LONG) {
      return triggerPrice * (1 - slippageBps / 10_000);
    }
    // for short position, trigger price * (1+user input slippage input / 10000)
    if (tradeSide === TradeSide.SHORT) {
      return triggerPrice * (1 + slippageBps / 10_000);
    }
  }

  return null;
};

export const calculateExpectedProfitOrLoss = ({
  sizePercent,
  triggerPrice,
  entryPrice,
  positionSize,
  tradeSide,
}: {
  sizePercent: number | null;
  triggerPrice: number | null;
  entryPrice: number;
  positionSize: number;
  tradeSide: TradeSide;
}) => {
  if (sizePercent === null || triggerPrice === null) return 0;

  // expected PnL for short position is opposite to long positions
  const entryAndTriggerPriceDiff =
    tradeSide === TradeSide.LONG
      ? triggerPrice - entryPrice
      : entryPrice - triggerPrice;

  const sizePercentBasis = sizePercent / 100;
  return positionSize * sizePercentBasis * entryAndTriggerPriceDiff;
};

// const getSelectedLimitOrder = ({
//   orderType,
//   stopLoss,
//   takeProfit,
// }: {
//   orderType: OrderType;
//   stopLoss: SpecialOrder | null;
//   takeProfit: SpecialOrder | null;
// }) => {
//   if ([OrderType.STOP_LOSS, OrderType.STOP_LOSS_LIMIT].includes(orderType)) {
//     return stopLoss?.price ? 'Limit' : 'Market';
//   }
//   if (
//     [OrderType.TAKE_PROFIT, OrderType.TAKE_PROFIT_LIMIT].includes(orderType)
//   ) {
//     return takeProfit?.price ? 'Limit' : 'Market';
//   }
//   return 'Market';
// };

const isLimitOrder = (orderType: OrderType) => {
  return [OrderType.STOP_LOSS_LIMIT, OrderType.TAKE_PROFIT_LIMIT].includes(
    orderType,
  );
};

const getInitialType = (initialType: OrderType = OrderType.STOP_LOSS) => {
  if (initialType === OrderType.STOP_LOSS_LIMIT) {
    return OrderType.STOP_LOSS;
  }
  if (initialType === OrderType.TAKE_PROFIT_LIMIT) {
    return OrderType.TAKE_PROFIT;
  }
  return initialType;
};

/**
 * Check if the modal is used to amend a market order. If so, the limit price field should be disabled.
 */
const getIsAmendingMarketOrder = ({
  orderType,
  stopLoss,
  takeProfit,
}: {
  orderType: OrderType;
  stopLoss: SpecialOrder | null;
  takeProfit: SpecialOrder | null;
}) => {
  if (orderType === OrderType.STOP_LOSS) {
    return stopLoss?.type === OrderType.STOP_LOSS;
  }
  if (orderType === OrderType.TAKE_PROFIT) {
    return takeProfit?.type === OrderType.TAKE_PROFIT;
  }
  return false;
};

const lsSlippage = readUserSetting(LS_DEFAULT_STLP_MARKET_SLIPPAGE);

const defaultSlippage = lsSlippage
  ? roundToNearestTick(lsSlippage, 0.01) ?? 0.3
  : 0.3;

const getInitialSlippageAndLimitOrderOption = ({
  orderType,
  stopLoss,
  takeProfit,
  positionSide,
}: {
  orderType: OrderType;
  stopLoss: SpecialOrder | null;
  takeProfit: SpecialOrder | null;
  positionSide: TradeSide;
}): {
  slippage: number | null;
  limitOrderOption: LimitOrderOptions;
} => {
  const savedLimitOrderOption =
    readUserSetting(LS_DEFAULT_IS_STLP_MARKET) === 'false' ? 'Limit' : 'Market';
  const sideMultiplier = positionSide === TradeSide.SHORT ? -1 : 1;

  // Get order
  const specialOrder =
    orderType === OrderType.STOP_LOSS
      ? stopLoss
      : orderType === OrderType.TAKE_PROFIT
      ? takeProfit
      : undefined;

  if (specialOrder) {
    if (specialOrder.triggerPrice && specialOrder.price) {
      // Get slippage from given set of triggerPrice and limitPrice (basically considering if the order were a market order at trigger price, what would have been the slippage as we )
      const slippage = roundToNearestTick(
        sideMultiplier *
          ((specialOrder.triggerPrice - specialOrder.price) /
            specialOrder.triggerPrice) *
          100,
        0.01,
      );

      if (slippage < 0) return { slippage: null, limitOrderOption: 'Limit' }; // Slippage less than zero is only possible when user has inputted a limitprice when setting up SLTP, so we show LIMIT option

      return { slippage, limitOrderOption: savedLimitOrderOption };
    }
    return {
      slippage: defaultSlippage,
      limitOrderOption: savedLimitOrderOption,
    };
  }

  return {
    slippage: defaultSlippage,
    limitOrderOption: savedLimitOrderOption,
  };
};

type Props = {
  marketId: string;
  initialType?: OrderType;
};
const StopLossTakeProfitAmendModal = ({
  marketId,
  initialType = OrderType.STOP_LOSS,
}: Props) => {
  const {
    store: {
      account: { _positions },
    },
  } = useAppContext();
  const theme: any = useTheme();
  const modal = useModal();
  const { createSltpOrder, amendSltpOrder, cancelSltpOrder } = useOrdersAPI();
  const { t } = useTranslation();

  const position = getMarketPosition(marketId, _positions) as Position;

  const { stopLoss: _stopLoss, takeProfit: _takeProfit } = position ?? {};

  // Only "placed" sltp orders should be shown
  const stopLoss = _stopLoss?.status === OrderStatus.PLACED ? _stopLoss : null;
  const takeProfit =
    _takeProfit?.status === OrderStatus.PLACED ? _takeProfit : null;

  const [type, setType] = useState<OrderType>(getInitialType(initialType));

  const [stopLossPrice, setStopLossPrice] = useState<number | null>(
    stopLoss?.triggerPrice ?? null,
  );
  const [stopLossSizePercent, setStopLossSizePercent] = useState<number | null>(
    basisToPercent(stopLoss?.sizePercent),
  );

  const [takeProfitPrice, setTakeProfitPrice] = useState<number | null>(
    takeProfit?.triggerPrice ?? null,
  );
  const [takeProfitSizePercent, setTakeProfitSizePercent] = useState<
    number | null
  >(basisToPercent(takeProfit?.sizePercent));

  const {
    slippage: initialSlippage,
    limitOrderOption: initialLimitOrderOption,
  } = getInitialSlippageAndLimitOrderOption({
    orderType: type,
    stopLoss,
    takeProfit,
    positionSide: position.side,
  });
  const [selectedLimitOrder, setSelectedLimitOrder] =
    useState<LimitOrderOptions>(initialLimitOrderOption);
  const [slippage, setSlippage] = useState<number | null>(initialSlippage);

  const [limitPrice, setLimitPrice] = useState<number | null>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const isStopLoss = getIsStopLoss(type);
  const isTakeProfit = getIsTakeProfit(type);

  const [isCancellingTakeProfit, setIsCancellingTakeProfit] =
    useState<boolean>(false);
  const [isCancellingStopLoss, setIsCancellingStopLoss] =
    useState<boolean>(false);

  const isAmendingMarketOrder = getIsAmendingMarketOrder({
    orderType: type,
    stopLoss,
    takeProfit,
  });

  const decimalPlaces = useGetDecimalPlaces(marketId);

  const { data: market, isLoading: isMarketLoading } = useMarketById(marketId);

  const handleCancel = async (order: Order) => {
    const isTakeProfit = getIsTakeProfit(order.type);
    try {
      flushSync(() => {
        if (isTakeProfit) {
          setIsCancellingTakeProfit(true);
        } else {
          setIsCancellingStopLoss(true);
        }
      });
      await cancelSltpOrder({
        orderID: order.id,
        marketID: order.marketID,
        type: order.type,
      });
      modal.clear();
    } catch (e: any) {
      console.log(
        `An error occurred when canceling ${order.type} order from positions table.`,
        e,
      );
    } finally {
      if (isTakeProfit) {
        setIsCancellingTakeProfit(false);
      } else {
        setIsCancellingStopLoss(false);
      }
    }
  };

  const handleSelectedLimitOrderChange = (val: LimitOrderOptions) => {
    saveUserSetting(LS_DEFAULT_IS_STLP_MARKET, (val === 'Market').toString());
    setSelectedLimitOrder(val);
  };

  useEffect(() => {
    const {
      slippage: initialSlippage,
      limitOrderOption: initialLimitOrderOption,
    } = getInitialSlippageAndLimitOrderOption({
      orderType: type,
      stopLoss,
      takeProfit,
      positionSide: position.side,
    });
    setSelectedLimitOrder(initialLimitOrderOption);
    setSlippage(initialSlippage);

    if (type === OrderType.STOP_LOSS && stopLoss?.price) {
      setLimitPrice(stopLoss.price);
    }
    if (type === OrderType.TAKE_PROFIT && takeProfit?.price) {
      setLimitPrice(takeProfit.price);
    }
  }, [type, takeProfit?.price, stopLoss?.price]);

  useMemo(() => {
    if (selectedLimitOrder === 'Limit') {
      if (limitPrice === null || limitPrice <= 0) {
        return;
      }

      let price;
      if (isStopLoss && stopLossPrice) {
        price =
          stopLossPrice * (position.side === TradeSide.SHORT ? 1.003 : 0.997);
      } else if (!isStopLoss && takeProfitPrice) {
        price =
          takeProfitPrice * (position.side === TradeSide.SHORT ? 1.003 : 0.997);
      }

      if (price) {
        const roundedPrice = roundDownToNearestTick(
          price,
          decimalPlaces.minTick,
        );
        setLimitPrice(roundedPrice);
      }
    }
  }, [stopLossPrice, takeProfitPrice]);

  const amendSlTpOrder = async ({
    type,
    triggerPrice,
    sizePercent,
    orderId,
    orderType,
  }: {
    type: OrderType;
    triggerPrice: number;
    sizePercent: number;
    orderId: string;
    orderType: OrderType;
  }) => {
    try {
      flushSync(() => {
        setIsSubmitting(true);
      });

      const roundedTriggerPrice = roundDownToNearestTick(
        triggerPrice,
        decimalPlaces.minTick,
      );

      // If the order is a limit order, we need to calculate the limit price
      // If the order is a market order, the price field should be empty
      // We still need to support market orders for SLTP orders
      const price = isLimitOrder(orderType)
        ? getOrderLimitPrice({
            selectedLimitOrder,
            limitPrice,
            triggerPrice,
            maxSlippage: slippage,
            tradeSide: position.side,
            orderType: type,
          })
        : null;
      const roundedPrice = price
        ? roundDownToNearestTick(price, decimalPlaces.minTick)
        : null;

      const params = {
        orderID: orderId,
        marketID: marketId,
        type: orderType,
        triggerPrice: roundedTriggerPrice,
        sizePercent: sizePercent / 100,
        ...(roundedPrice ? { price: roundedPrice } : {}),
      };
      await amendSltpOrder(params);
      modal.clear();
    } catch (e) {
      console.error(
        'An error occurred while amending a SLTP order from the positions table.',
        e,
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const createSlTpOrder = async ({
    type,
    triggerPrice,
    sizePercent,
  }: {
    type: OrderType;
    triggerPrice: number;
    sizePercent: number;
  }) => {
    try {
      flushSync(() => {
        setIsSubmitting(true);
      });
      const roundedTriggerPrice = roundDownToNearestTick(
        triggerPrice,
        decimalPlaces.minTick,
      );

      const typeToSubmit = getOrderTypeOnSubmit({
        orderType: type,
      });
      const price = getOrderLimitPrice({
        selectedLimitOrder,
        limitPrice,
        triggerPrice,
        maxSlippage: slippage,
        tradeSide: position.side,
        orderType: type,
      });
      const roundedPrice = price
        ? roundDownToNearestTick(price, decimalPlaces.minTick)
        : null;

      const params = {
        marketID: marketId,
        type: typeToSubmit,
        triggerPrice: roundedTriggerPrice,
        sizePercent: sizePercent / 100,
        ...(roundedPrice ? { price: roundedPrice } : {}),
      };
      await createSltpOrder(params);
      modal.clear();
    } catch (e) {
      console.log('An error occurred while trying to create a SLTP order.', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isDisabled = getShouldDisableConfirmBtn({
    isSubmitting,
    triggerPrice: isTakeProfit ? takeProfitPrice : stopLossPrice,
    sizePercent: isTakeProfit ? takeProfitSizePercent : stopLossSizePercent,
    // marketPrice: selectedMarket.lastTradePrice,
    fairPrice: position.fairPrice,
    orderType: isTakeProfit ? OrderType.TAKE_PROFIT : OrderType.STOP_LOSS,
    tradeSide: position.side,
    selectedLimitOrder,
    limitPrice,
    maxSlippage: slippage,
    isAmendingMarketOrder,
  });

  const handleSubmit = () => {
    if (isSubmitting || isDisabled) return;

    const orderType = isTakeProfit
      ? OrderType.TAKE_PROFIT
      : OrderType.STOP_LOSS;

    if (orderType === OrderType.STOP_LOSS) {
      if (stopLossPrice === null || stopLossSizePercent === null) return;

      const params = {
        type: orderType,
        triggerPrice: stopLossPrice,
        sizePercent: stopLossSizePercent,
      };
      if (stopLoss) {
        amendSlTpOrder({
          ...params,
          orderId: stopLoss.id,
          orderType: stopLoss.type,
        });
      } else {
        createSlTpOrder(params);
      }
    } else if (orderType === OrderType.TAKE_PROFIT) {
      if (takeProfitPrice === null || takeProfitSizePercent === null) return;

      const params = {
        type: orderType,
        triggerPrice: takeProfitPrice,
        sizePercent: takeProfitSizePercent,
      };
      if (takeProfit) {
        amendSlTpOrder({
          ...params,
          orderId: takeProfit.id,
          orderType: takeProfit.type,
        });
      } else {
        createSlTpOrder(params);
      }
    }
  };

  const handleDeletePress = () => {
    if (isTakeProfit && takeProfit) {
      if (isCancellingTakeProfit) return;

      handleCancel(takeProfit);
      return;
    } else if (stopLoss) {
      if (isCancellingStopLoss) return;
      handleCancel(stopLoss);
    }
  };

  // if position is not found, close the modal
  useEffect(() => {
    if (!position?.id) {
      showNotification({
        title: 'Position was closed',
        description: 'The position was closed. The modal will now close.',
        type: NotificationType.Info,
      });
      modal.clear();
    }
  }, [modal, position?.id]);

  if (!position?.id) return null;

  const expectedProfitOrLoss = calculateExpectedProfitOrLoss({
    sizePercent: isStopLoss ? stopLossSizePercent : takeProfitSizePercent,
    triggerPrice: isStopLoss ? stopLossPrice : takeProfitPrice,
    entryPrice: position.avgEntryPrice,
    positionSize: position.size,
    tradeSide: position.side,
  });

  const triggerPriceErrorMessage = getTriggerPriceErrorMessage({
    orderType: type,
    fairPrice: position.fairPrice,
    triggerPrice: isStopLoss ? stopLossPrice : takeProfitPrice,
    tradeSide: position.side,
    t,
  });

  const sizeErrorMessage = getSizeErrorMessage(
    isStopLoss ? stopLossSizePercent : takeProfitSizePercent,
    t,
  );

  const isCancelLoading =
    (isTakeProfit && isCancellingTakeProfit) ||
    (isStopLoss && isCancellingStopLoss);

  const handleSlippageChange = (val: number | null) => {
    if (val) saveUserSetting(LS_DEFAULT_STLP_MARKET_SLIPPAGE, val.toString());
    else saveUserSetting(LS_DEFAULT_STLP_MARKET_SLIPPAGE, '');

    setSlippage(val);
  };

  if (isMarketLoading || !market)
    return (
      <Modal
        showHeader={false}
        size={'small'}
        name={Modals.slTpAmendModal}
        showCloseIcon={false}
        handleKeyStrokes={{
          [Key.Enter]: handleSubmit,
        }}
      >
        <Loading />
      </Modal>
    );

  return (
    <Modal
      showHeader={false}
      size={'small'}
      name={Modals.slTpAmendModal}
      showCloseIcon={false}
      handleKeyStrokes={{
        [Key.Enter]: handleSubmit,
      }}
    >
      {market ? (
        <CurrencyHeader
          marketId={marketId}
          title={t('stopLossTakeProfit')}
          fairPrice={market.fairPrice}
          low24hPrice={market.marketPrice24hLow}
          high24hPrice={market.marketPrice24hHigh}
          change24hBasis={market.lastTradePrice24hChangeBasis}
          marketLogo={market?.iconUrl}
          positionSize={position.size}
          marketTitle={market?.marketTitle}
          className="mb-20"
        />
      ) : null}
      <StyledTabSelectorContainer>
        <TabSelector
          handleOptionSelect={(val: string) => setType(val as OrderType)}
          buttonOptions={[
            {
              label: t('stopLoss'),
              value: OrderType.STOP_LOSS,
              bgColor: theme.colors.negativeForeground300,
            },
            {
              label: t('takeProfit'),
              value: OrderType.TAKE_PROFIT,
              bgColor: theme.colors.positiveForeground300,
            },
          ]}
          initialSelectedOption={type}
        />
      </StyledTabSelectorContainer>

      <MarginSeparator />

      <Row>
        <Text flexed gap={5}>
          {market?.marketTitle}
          <Badge
            bgColor={
              position.side === TradeSide.LONG
                ? 'positiveBackground100'
                : 'negativeBackground100'
            }
            color={
              position.side === TradeSide.LONG
                ? 'positiveForeground200'
                : 'negativeForeground200'
            }
            padding="4px"
            fontWeight="semiBold"
            variant="BODY_XS"
          >
            {position.side.toLocaleUpperCase()}
          </Badge>
        </Text>
        <Text flexed gap={5}>
          <Icon src={market?.iconUrl} />
          {`1 ${getCurrencyFromTradingPair(marketId)} =`}
          <FormattedNumber
            value={market?.fairPrice}
            prefix="$"
            decimalScale={decimalPlaces.price}
          />
        </Text>
      </Row>

      {/* <Row>
        <span className="label">{t('entryPrice')}</span>
        <span className="value">
          <FormattedNumber
            value={position.avgEntryPrice}
            decimalScale={decimalPlaces.price}
          />
        </span>
      </Row>
      <Row>
        <span className="label">{t('fairPrice')}</span>
        <span className="value">
          <FormattedNumber
            value={position.fairPrice}
            decimalScale={decimalPlaces.price}
          />
        </span>
      </Row>
      <Row>
        <span className="label">
          {t('position')}
          <Badge type={position.side === TradeSide.LONG ? 'success' : 'danger'}>
            {position.side}
          </Badge>
        </span>
        <span className="value">
          <ColoredText
            color={
              position.side === TradeSide.LONG ? Colors.Success : Colors.Danger
            }
          >
            <FormattedNumber
              value={position.size}
              suffix={` ${getCurrencyFromTradingPair(marketId)}`}
              decimalScale={decimalPlaces.size}
            />
          </ColoredText>
        </span>
      </Row> */}
      <div className="mt-10"></div>

      {isStopLoss ? (
        <>
          <NumberInput
            value={getCurrencyInputValue(stopLossPrice)}
            onChange={setStopLossPrice}
            label={t('stopLoss')}
            currency="USD"
            showPresets={false}
            showSwapCurrency={false}
            showCurrencyIcon={true}
            showValueApproximation={false}
            showError={!!triggerPriceErrorMessage}
            errorMessage={triggerPriceErrorMessage}
            autoFocus
          />
          <div className="mt-10"></div>
          <NumberInput
            value={getCurrencyInputValue(stopLossSizePercent)}
            onChange={setStopLossSizePercent}
            label={t('sizePercent')}
            showSwapCurrency={false}
            showCurrencyIcon={false}
            showValueApproximation={false}
            currency="%"
            maxValue={100}
            showError={!!sizeErrorMessage}
            errorMessage={sizeErrorMessage}
          />
        </>
      ) : null}

      {isTakeProfit ? (
        <>
          <NumberInput
            value={getCurrencyInputValue(takeProfitPrice)}
            onChange={setTakeProfitPrice}
            label={t('takeProfit')}
            currency="USD"
            showSwapCurrency={false}
            showCurrencyIcon={true}
            showValueApproximation={false}
            showPresets={false}
            showError={!!triggerPriceErrorMessage}
            errorMessage={triggerPriceErrorMessage}
            autoFocus
          />
          <div className="mt-10"></div>
          <NumberInput
            value={getCurrencyInputValue(takeProfitSizePercent)}
            onChange={setTakeProfitSizePercent}
            label={t('sizePercent')}
            showSwapCurrency={false}
            showCurrencyIcon={false}
            showValueApproximation={false}
            maxValue={100}
            currency="%"
            showError={!!sizeErrorMessage}
            errorMessage={sizeErrorMessage}
          />
        </>
      ) : null}

      <ExpectedLossProfitRow>
        <Text variant="BODY_S" color="shadesForeground200">
          {t('expected')} {expectedProfitOrLoss >= 0 ? t('gain') : t('loss')}
        </Text>

        <Text flexed gap={5}>
          <FormattedNumber
            variant="BODY_S"
            value={expectedProfitOrLoss}
            color={
              expectedProfitOrLoss >= 0
                ? 'positiveForeground200'
                : 'negativeForeground200'
            }
          />

          <Badge
            color={'shadesForeground200'}
            padding="4px 6px"
            fontWeight="semiBold"
            variant="BODY_XS"
            borderColor="shadesBackground700"
          >
            USD
          </Badge>
        </Text>
      </ExpectedLossProfitRow>

      {!isAmendingMarketOrder && (
        <LimitPriceAndSlippage
          slippage={slippage}
          limitPrice={limitPrice}
          setSlippage={handleSlippageChange}
          setLimitPrice={setLimitPrice}
          orderType={type}
          selectedLimitOrder={selectedLimitOrder}
          setSelectedLimitOrder={handleSelectedLimitOrderChange}
          triggerPrice={isStopLoss ? stopLossPrice : takeProfitPrice}
          tradeSide={position.side}
          decimalScale={decimalPlaces}
        />
      )}

      <ButtonGroup className="mt-20">
        {(isTakeProfit && takeProfit) || (isStopLoss && stopLoss) ? (
          <Text
            flexed
            gap={5}
            variant="BODY_M"
            cursorPointer
            color={isCancelLoading ? 'shadesForeground200' : 'white'}
            onClick={handleDeletePress}
          >
            {isCancelLoading ? (
              <Loading size="16px" />
            ) : (
              <Icon src={deleteRedIcon} />
            )}
            {t('delete')}
          </Text>
        ) : (
          <Button
            colorVariant="secondary"
            sizeVariant="S"
            onClick={modal.clear}
          >
            {t('cancel')}
          </Button>
        )}

        {isTakeProfit && (
          <Button
            colorVariant={'primaryGreen'}
            sizeVariant="S"
            block={true}
            disabled={isDisabled}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            {getConfirmBtnLabel({ order: takeProfit, isSubmitting, t })}
          </Button>
        )}

        {isStopLoss && (
          <Button
            colorVariant={'primaryGreen'}
            sizeVariant="S"
            block={true}
            disabled={isDisabled}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            {getConfirmBtnLabel({ order: stopLoss, isSubmitting, t })}
          </Button>
        )}
      </ButtonGroup>
    </Modal>
  );
};

export default observer(StopLossTakeProfitAmendModal);
