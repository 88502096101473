import { config } from 'config';

export const EXCHANGE = 'STRIPS';

// Make requests Strips/Rabbit
export async function makeApiRequest(path) {
  try {
    const response = await fetch(`${config.apiUrl}/${path}`);
    return await response.json();
  } catch (error) {
    console.error(`STRIPS request error: ${error.status}`);
  }
}

export async function getAllSymbols() {}

export const formatResolution = resolution => {
  // @dev: our resolutions are expressed in minutes
  switch (resolution) {
    case '1D':
      return 1440;
    case '1W':
      return 1440 * 7;
    case '1M':
      return 1440 * 7 * 4;
    default:
      return parseInt(resolution);
  }
};

export const getCandleData = async (symbolInfo, resolution, periodParams) => {
  const { from, to } = periodParams;

  const urlParameters = {
    market_id: symbolInfo.ticker,
    timestamp_from: from,
    timestamp_to: to,
    period: formatResolution(resolution),
  };

  const query = Object.keys(urlParameters)
    .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
    .join('&');

  const data = await makeApiRequest(`candles?${query}`);
  return {
    ...data,
    Data: data.result
      .map(d => {
        return { ...d, time: d.time };
      })
      .sort((a, b) => a.time - b.time),
  };
};
