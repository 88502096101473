import { useCallback, useEffect, useRef, useState } from 'react';

import { brandedSelect } from 'utils/brand';

import {
  ReturnTypeFromProps,
  GeneralResponse,
  UseTwitterConnectionProps,
  objectType,
} from './types';
import { TWITTER_KEYS } from 'constants/api';

import { config } from 'config';

export const RS_PASS_CORS_KEY = 'temp_ff218f680b393f4fb218193f298347ee';
export const RS_TWITTER_OAUTH2_STATE = 'RS_TWITTER_OAUTH2_STATE';

const TWITTER_URL: string = 'https://twitter.com';
const TWITTER_API_URL: string = 'https://api.twitter.com';
export const PREVENT_CORS_URL: string = 'https://cors.bridged.cc';

const generateRandomString = (length = 20) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const bc = new BroadcastChannel('twitter-channel');

const useTwitterConnection = <Props extends UseTwitterConnectionProps>(
  props: Props = { isOnlyGetCode: false, isOnlyGetToken: false } as Props,
): ReturnTypeFromProps<Props> => {
  const {
    fields = 'created_at,description,entities,id,location,name,pinned_tweet_id,profile_image_url,protected,public_metrics,url,username,verified,withheld',
    state,
    scope = 'users.read%20tweet.read%20offline.access%20tweet.write%20follows.write%20like.write',
    isOnlyGetCode = false,
    isOnlyGetToken = false,
    onProfileDataReceived,
    onLoginStart,
    onReject,
    onResolve,
    closePopupMessage = 'User closed the popup',
  } = props;
  const { clientId, clientKeys } = config.twitterKeys;
  const redirectUri = `${
    typeof window === 'object' && window.location.origin
  }/callback/twitter`;
  const [twitterData, setTwitterData] = useState<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const popupRef = useRef<Window | null>(null);
  const popUpIntervalRef = useRef<number | null>(null);

  const getTweetInfo = useCallback(
    async (tweetId: string): Promise<GeneralResponse> => {
      if (!twitterData?.access_token) {
        console.error('Access Token not found!');
        return { success: false, message: 'Access Token not found!' };
      }

      const userId = twitterData?.data?.id;

      if (!userId) {
        console.error('Profile not found!');
        return { success: false, message: 'Profile not found!' };
      }

      const url = `${PREVENT_CORS_URL}/${TWITTER_API_URL}/2/tweets/${tweetId}?tweet.fields=public_metrics,created_at`;

      try {
        const res = await (
          await fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${twitterData.access_token}`,
              'x-cors-grida-api-key': RS_PASS_CORS_KEY,
              'Content-Type': 'application/json',
            },
          })
        ).json();

        return { success: true, data: res };
      } catch (err) {
        console.error(JSON.stringify(err));
        // We return success true even when the API fails as we have no way to check whether a given tweet is retweeted and this fails when it already is.
        // @todo: add network error handling
        return { success: true, message: JSON.stringify(err) };
      }
    },
    [twitterData],
  );

  const sendFollowRequest = useCallback(
    async (data: objectType, id: string) => {
      const url = `${PREVENT_CORS_URL}/${TWITTER_API_URL}/2/users/${id}/following`;
      try {
        const resRbx = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            target_user_id: '1542786203136983040',
          }),
          headers: {
            Authorization: `Bearer ${data?.access_token}`,
            'x-cors-grida-api-key': RS_PASS_CORS_KEY,
            'Content-Type': 'application/json',
          },
        });

        const resBfx = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            target_user_id: '1736726052876292096',
          }),
          headers: {
            Authorization: `Bearer ${data?.access_token}`,
            'x-cors-grida-api-key': RS_PASS_CORS_KEY,
            'Content-Type': 'application/json',
          },
        });

        return brandedSelect({
          bfx: resBfx,
          rabbitx: resRbx,
        }).json();
      } catch (err: any) {
        onReject?.(err);
        setIsLoading(false);
      }
    },
    [],
  );

  const tryLikingATweet = useCallback(
    async (tweetId: string): Promise<GeneralResponse> => {
      if (!twitterData?.access_token) {
        console.error('Access Token not found!');
        return { success: false, message: 'Access Token not found!' };
      }

      const userId = twitterData?.data?.id;

      if (!userId) {
        console.error('Profile not found!');
        return { success: false, message: 'Profile not found!' };
      }

      const url = `${PREVENT_CORS_URL}/${TWITTER_API_URL}/2/users/${userId}/likes`;

      try {
        const res = await (
          await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              tweet_id: tweetId,
            }),
            headers: {
              Authorization: `Bearer ${twitterData.access_token}`,
              'x-cors-grida-api-key': RS_PASS_CORS_KEY,
              'Content-Type': 'application/json',
            },
          })
        ).json();

        return { success: true, data: res };
      } catch (err) {
        console.error(JSON.stringify(err));
        // We return success true even when the API fails as we have no way to check whether a given tweet is retweeted and this fails when it already is.
        // @todo: add network error handling
        return { success: true, message: JSON.stringify(err) };
      }
    },
    [twitterData],
  );

  const tryRetweetingATweet = useCallback(
    async (tweetId: string): Promise<GeneralResponse> => {
      if (!twitterData?.access_token) {
        console.error('Access Token not found!');
        return { success: false, message: 'Access Token not found!' };
      }

      const userId = twitterData?.data?.id;

      if (!userId) {
        console.error('Profile not found!');
        return { success: false, message: 'Profile not found!' };
      }

      const url = `${PREVENT_CORS_URL}/${TWITTER_API_URL}/2/users/${userId}/retweets`;

      try {
        const res = await (
          await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              tweet_id: tweetId,
            }),
            headers: {
              Authorization: `Bearer ${twitterData.access_token}`,
              'x-cors-grida-api-key': RS_PASS_CORS_KEY,
              'Content-Type': 'application/json',
            },
          })
        ).json();

        return { success: true, data: res };
      } catch (err) {
        console.error(JSON.stringify(err));
        // We return success true even when the API fails as we have no way to check whether a given tweet is retweeted and this fails when it already is.
        // @todo: add network error handling
        return { success: true, message: JSON.stringify(err) };
      }
    },
    [twitterData],
  );

  const getProfile = useCallback(
    async (data: objectType) => {
      const url = `${PREVENT_CORS_URL}/${TWITTER_API_URL}/2/users/me?user.fields=${fields}`;
      setIsLoading(true);
      await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${data?.access_token}`,
          'x-cors-grida-api-key': RS_PASS_CORS_KEY,
        },
      })
        .then(res => res.json())
        .then(async res => {
          await sendFollowRequest(data, res?.data?.id);
          onResolve?.({ provider: 'twitter', data: { ...res.data } });
          setTwitterData({
            provider: 'twitter',
            data: { ...res.data },
            access_token: data?.access_token,
          });
          onProfileDataReceived?.({
            provider: 'twitter',
            data: { ...res.data },
          });
          setIsLoading(false);
        })
        .catch(err => {
          onReject?.(err);
          setIsLoading(false);
        });
    },
    [fields, onReject, onResolve],
  );

  const getAccessToken = useCallback(
    async (code: string) => {
      if (isOnlyGetCode) {
        onResolve?.({ provider: 'twitter', data: { code } });
        setTwitterData({ provider: 'twitter', data: { code } });
      } else {
        const payload: Record<string, any> = {
          code,
          redirect_uri: redirectUri,
          client_id: clientId,
          grant_type: 'authorization_code',
          code_verifier: 'challenge',
        };
        const details = new URLSearchParams();

        for (const key in payload) {
          if (payload.hasOwnProperty(key)) {
            details.append(key, payload[key]);
          }
        }

        const requestOAuthURL = `${PREVENT_CORS_URL}/${TWITTER_API_URL}/2/oauth2/token`;
        const base64Encoding = btoa(clientKeys);
        setIsLoading(true);
        const data = await fetch(requestOAuthURL, {
          method: 'POST',
          body: details.toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-cors-grida-api-key': RS_PASS_CORS_KEY,
            Authorization: `Basic ${base64Encoding}`,
          },
        })
          .then(data => data.json())
          .catch(err => {
            onReject?.(err);
            setIsLoading(false);
          });

        if (data?.access_token) {
          if (isOnlyGetToken) {
            onResolve?.({ provider: 'twitter', data });
            setTwitterData({ provider: 'twitter', data });
            setIsLoading(false);
          } else getProfile(data);
        }
      }
    },
    [onReject, onResolve, clientId, redirectUri, isOnlyGetCode, isOnlyGetToken],
  );

  const onChangeLocalStorage = useCallback(() => {
    window.removeEventListener('storage', onChangeLocalStorage, false);
    const code = localStorage.getItem('twitter');
    if (code) {
      localStorage.removeItem('twitter');
    }
  }, []);

  const receiveMessage = useCallback(async (event: MessageEvent) => {
    const savedState = localStorage.getItem(RS_TWITTER_OAUTH2_STATE);
    localStorage.setItem('twitter', `${event.data.code}`);

    if (event.origin === window.location.origin) {
      if (event.data.errorMessage && event.data.from === 'Twitter') {
        // Prevent CSRF attack by testing state
        if (event.data.state !== savedState) {
          popupRef.current && popupRef.current.close();
          return;
        }
        onReject?.(event.data);
        popupRef.current && popupRef.current.close();
      } else if (event.data.code && event.data.from === 'Twitter') {
        // Prevent CSRF attack by testing state
        if (event.data.state !== savedState) {
          console.error('State does not match');
          popupRef.current && popupRef.current.close();
          return;
        }
        getAccessToken(event.data.code);
        popupRef.current && popupRef.current.close();
      }
    }
  }, []);

  useEffect(() => {
    bc.addEventListener('message', receiveMessage, false);

    return () => {
      bc.removeEventListener('message', receiveMessage, false);

      if (popupRef.current) {
        popupRef.current.close();
        popupRef.current = null;
      }
      if (popUpIntervalRef.current) {
        window.clearInterval(popUpIntervalRef.current);
        popUpIntervalRef.current = null;
      }
    };
  }, [receiveMessage]);

  const getUrl = () => {
    const generatedState = state || generateRandomString();
    localStorage.setItem(RS_TWITTER_OAUTH2_STATE, generatedState);
    const oauthUrl = `${TWITTER_URL}/i/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${generatedState}&code_challenge=challenge&code_challenge_method=plain`;
    return oauthUrl;
  };

  const onLogin = useCallback(async () => {
    onLoginStart?.();
    window.addEventListener('storage', onChangeLocalStorage, false);
    const width = 450;
    const height = 730;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    popupRef.current?.close();
    popupRef.current = window.open(
      getUrl(),
      '_blank',
      'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left,
    );
    if (popUpIntervalRef.current) {
      window.clearInterval(popUpIntervalRef.current);
      popUpIntervalRef.current = null;
    }
    popUpIntervalRef.current = window.setInterval(() => {
      try {
        if (popupRef.current && popupRef.current.closed) {
          window.clearInterval(popUpIntervalRef.current as any);
          popUpIntervalRef.current = null;
          if (onReject) {
            onReject({
              error: 'user_closed_popup',
              errorMessage: closePopupMessage,
            });
          }
        }
      } catch (error) {
        console.error(error);
        window.clearInterval(popUpIntervalRef.current as any);
        popUpIntervalRef.current = null;
      }
    }, 1000);
  }, [scope, state, clientId, onLoginStart, redirectUri, onChangeLocalStorage]);

  return {
    twitterData,
    isLoading,
    onTwitterConnect: onLogin,
    tryRetweetingATweet,
    getTweetInfo,
    tryLikingATweet,
  };
};
export default useTwitterConnection;
