import { memo, useState } from 'react';

import { getCurrencyFromTradingPair } from 'utils';

import { FormattedNumber, Icon } from 'components';
import InfoTooltip from 'components/InfoTooltip';
import Text from 'components/Text';

import { DecimalScale } from 'constants/marketMappings';
import { Column } from 'theme/globalStyledComponents/column';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .absolute-right {
    position: absolute;
    right: 0;
  }
`;

type CurrencyHeaderProps = {
  marketId: string;
  fairPrice: number;
  low24hPrice: number;
  high24hPrice: number;
  change24hBasis: number;
  className?: string;
  title?: string;
  marketLogo: string;
  marketTitle: string;
  positionSize?: number;
  decimalScale?: DecimalScale;
};
const CurrencyHeader = ({
  marketId,
  fairPrice,
  low24hPrice,
  className,
  change24hBasis,
  high24hPrice,
  marketTitle,
  marketLogo,
  title,
  positionSize,
  decimalScale,
}: CurrencyHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>
      <Text flexed fontWeight="semiBold">
        {title ? (
          title
        ) : (
          <>
            <Icon src={marketLogo} size="L" />
            {marketTitle} =
            <FormattedNumber
              value={fairPrice}
              prefix="$"
              decimalScale={decimalScale?.price}
            />
          </>
        )}
      </Text>

      <InfoTooltip
        color="white"
        iconSize={24}
        className="absolute-right"
        title={
          <Column gap={5} align="stretch">
            {positionSize ? (
              <Text
                variant="BODY_S"
                flexed
                gap={30}
                color="white"
                className="justify-between"
              >
                {t('position')}{' '}
                <Text>
                  {positionSize + ' ' + getCurrencyFromTradingPair(marketId)}
                </Text>
              </Text>
            ) : null}

            <Text
              variant="BODY_S"
              flexed
              gap={30}
              color="white"
              className="justify-between"
            >
              {t('fairPrice')} <Text>${fairPrice}</Text>
            </Text>

            <Text
              variant="BODY_S"
              flexed
              gap={30}
              color="white"
              className="justify-between"
            >
              Change(24h){' '}
              <FormattedNumber
                color={
                  change24hBasis > 0
                    ? 'positiveForeground200'
                    : change24hBasis < 0
                    ? 'negativeForeground200'
                    : 'shadesForeground200'
                }
                value={change24hBasis * 100}
                suffix="%"
                prefix={change24hBasis > 0 ? '+' : ''}
              ></FormattedNumber>
            </Text>
            <Text
              variant="BODY_S"
              flexed
              gap={30}
              color="white"
              className="justify-between"
            >
              24h High <FormattedNumber value={high24hPrice} prefix="$" />
            </Text>
            <Text
              variant="BODY_S"
              flexed
              gap={30}
              color="white"
              className="justify-between"
            >
              24h Low <FormattedNumber value={low24hPrice} prefix="$" />
            </Text>
          </Column>
        }
      />
    </Container>
  );
};

export default memo(CurrencyHeader);
