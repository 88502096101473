import { brand, brandedSelect } from 'utils/brand';

import Icon from 'components/Icon';
import Text from 'components/Text';

import { Container } from './styles';
import closeGrayIcon from 'assets/icons/close-x.svg';
import rewardIcon from 'assets/icons/whale-reward-cup.svg';
import whaleIcon from 'assets/images/whale.webp';
import { Column } from 'theme/globalStyledComponents';

export const WHALE_SUPPORT_DISCORD_LINK = brandedSelect({
  bfx: 'https://discord.gg/tnb57d5X5k',
  rabbitx: 'https://discord.gg/e2bUTGFYGH',
});

const WhaleSupport = ({ onClose }: { onClose: () => void }) => {
  return (
    <Container>
      <img src={whaleIcon} alt="" className="whale-img" />
      <Column gap={10} className="content" justify="flex-start">
        <Text flexed gap={8} variant="HEADING_H2" fontWeight="semiBold">
          <Icon src={rewardIcon} alt="bolt" size="XXL" />
          Oh Shit... It's a Whale!
        </Text>
        <Text className="description" preWrap>
          At {brand.appShortName}, we like keeping our whales happy.
          <br />
          Click{' '}
          <a
            href={WHALE_SUPPORT_DISCORD_LINK}
            className="here"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          to access our VIP discord channel.
        </Text>
      </Column>
      <div className="close-btn-container" onClick={onClose}>
        <Icon src={closeGrayIcon} />
      </div>
    </Container>
  );
};

export default WhaleSupport;
