import { isBrandRabbitX } from 'utils/brand';

import { StyledVaultCard } from '../styles';

import styled from 'styled-components';

export const StyledEmptyVaultCard = styled(StyledVaultCard)`
  position: relative;
  overflow: hidden;

  .empty-button {
    border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};
    background: ${({ theme }) => theme.colors.shadesBackground500};
    height: 35.5px;
    width: 100%;
    border-radius: 8px;
  }

  .success-badge {
    padding: 4px;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.positiveBackground100};
  }

  .shimmer {
    height: 100%;
    width: 100%;
    background: ${({ theme }) =>
      `linear-gradient(
      -45deg,
      ${theme.colors.shadesBackground500}00 40%,
      ${theme.colors.shadesBackground700} 50%,
      ${theme.colors.shadesBackground500}00 60%
    )`};

    position: absolute;
    top: 0px;
    background-size: 300%;
    background-position-x: 100%;
    z-index: 10;

    animation: shimmer 1s infinite linear;

    @keyframes shimmer {
      to {
        background-position-x: 0%;
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
