import { useEffect, useRef } from 'react';

import useModal from 'hooks/useModal';

import Modal from 'components/Modal';

import BuySell from '../BuySell/BuySell';
import { Modals } from 'constants/modals';

interface Props {
  unixTime: number;
  price: number;
  subTitle: string;
  lastSize: number | null;
  afterConfirmBuy: (size: number) => void;
  afterConfirmSell: (size: number) => void;
}

export const BuySellModal = ({
  unixTime,
  price,
  subTitle,
  lastSize,
  afterConfirmBuy,
  afterConfirmSell,
}: Props) => {
  const modal = useModal();

  return (
    <Modal
      showHeader={false}
      title="Trade"
      size={'small'}
      name={Modals.buySellModal}
      showCloseIcon={false}
    >
      <BuySell
        afterConfirmBuy={size => {
          modal.clear();
          afterConfirmBuy(size);
        }}
        afterConfirmSell={size => {
          modal.clear();
          afterConfirmSell(size);
        }}
        externalPrice={price}
        lastSize={lastSize}
        showConfirmation={true}
        isSourceTradingView={true}
        className="gap-10"
      />
    </Modal>
  );
};
