import { Box } from '@material-ui/core';

import { brand } from 'utils/brand';

import DesktopMenu from '../DesktopMenu';
import ActionButtons from '../shared/ActionButtons';
import MarketSearch from '../shared/MarketSearch';
// import { UserBlock } from './UserBlock';
import UserBlock from './UserBlock';
import hamburgerMenu from 'assets/icons/hamburger-menu.svg';
import { routes } from 'pages/routes';

import { MenuItem } from 'interfaces';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderMobileStyled = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
  img.menu-icon {
    width: 24px;
    height: 24px;
  }
  img.logo {
    width: 100px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    display: none;
  }
`;

const HeaderDesktopStyled = styled.div`
  display: none;

  .right-part {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0;

    .search-wrapper {
      margin-right: 10px;

      @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
        margin-right: 12px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 54px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    height: 60px;
  }
`;

interface Props {
  menuItems: MenuItem[];
  onOpenMobileSidebar: () => void;
}

const Header = ({ menuItems, onOpenMobileSidebar }: Props) => {
  const handleOpenMobileSidebar = () => {
    onOpenMobileSidebar();
  };

  return (
    <Box>
      <HeaderMobileStyled>
        <Link className="logo-link" to={routes.trade.getRoutePath()}>
          <img
            src={brand.logo}
            className="logo"
            alt={`${brand.appShortName} logo`}
          />
        </Link>
        <img
          src={hamburgerMenu}
          onClick={handleOpenMobileSidebar}
          className="menu-icon"
          alt="Hamburger menu"
        />
      </HeaderMobileStyled>
      <HeaderDesktopStyled>
        <DesktopMenu menuItems={menuItems} />
        <div className="right-part">
          <ActionButtons />
          <UserBlock />
        </div>
      </HeaderDesktopStyled>
    </Box>
  );
};

export default Header;
