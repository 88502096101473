import styled from 'styled-components';

export const UserBlockContainer = styled.div`
  position: relative;

  .dropdown {
    position: absolute;
    z-index: 5;
    bottom: 40px;
    left: 0;

    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      top: 50px;
      right: 0;
      left: auto;
    }
  }

  .wallet-icon {
    margin-right: 10px;
    height: 16px;
    width: 16px;
  }

  .more-icon {
    height: 10px;
    width: 10px;
    margin-left: 10px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      height: 12px;
      width: 12px;
    }
  }

  .connect-button {
    display: flex;
    align-items: center;

    font-size: 12px;
    line-height: normal;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;
