import { readUserSetting } from 'utils';
import { CreateOrderParams } from 'utils/api';

import { CAMPAIGN_ANALYTICS, LS_WALLET_TYPE } from 'constants/localStorageKeys';
import {
  MIXPANEL_CANCEL_ORDERS_ALL_SUCCESS,
  MIXPANEL_CANCEL_ORDER_SUCCESS,
  MIXPANEL_CANCEL_WITHDRAW_CLICKED,
  MIXPANEL_CANCEL_WITHDRAW_SUCCESS,
  MIXPANEL_CLAIM_WITHDRAW_CLICKED,
  MIXPANEL_CLAIM_WITHDRAW_SUCCESS,
  MIXPANEL_CLOSE_POSITION_SUCCESS,
  MIXPANEL_CONNECT_WALLET_CLICKED,
  MIXPANEL_CONNECT_WALLET_NAVIGATE,
  MIXPANEL_CONNECT_WALLET_SUCCESS,
  MIXPANEL_DEPOSIT_CLICKED,
  MIXPANEL_DEPOSIT_NAVIGATE,
  MIXPANEL_DEPOSIT_SUCCESS,
  MIXPANEL_ELIXIR_VAULTS_CLICKED,
  MIXPANEL_ELIXIR_VAULTS_NAVIGATE,
  MIXPANEL_ELIXIR_VAULTS_SUCCESS,
  MIXPANEL_LOG_OUT_SUCCESS,
  MIXPANEL_OPEN_ORDER_CLICKED,
  MIXPANEL_OPEN_ORDER_SUCCESS,
  MIXPANEL_STAKE_LOCK_LP_CLICKED,
  MIXPANEL_STAKE_LOCK_LP_NAVIGATE,
  MIXPANEL_STAKE_LOCK_LP_SUCCESS,
  MIXPANEL_STAKE_LOCK_RBX_CLICKED,
  MIXPANEL_STAKE_LOCK_RBX_NAVIGATE,
  MIXPANEL_STAKE_LOCK_RBX_SUCCESS,
  MIXPANEL_WITHDRAW_CLICKED,
  MIXPANEL_WITHDRAW_NAVIGATE,
  MIXPANEL_WITHDRAW_SUCCESS,
} from 'constants/mixpanelEvents';
import { getIsMethodTransak } from 'pages/Trade/components/AccountStats/NewDepositModal/utils';
import { AmendType } from 'pages/Vaults/common/AmendLiquidityModal';

import { config } from 'config';
import { DepositMethod } from 'enums';
import mixpanel, { Mixpanel } from 'mixpanel-browser';

const MethodToTypeMap = {
  [DepositMethod.Wallet]: 'default',
  [DepositMethod.BinancePay]: 'binance_pay',
  [DepositMethod.Mesh]: 'mesh',
};

const getDepositTypeFromMethod = (method: DepositMethod) =>
  getIsMethodTransak(method) ? 'transak' : MethodToTypeMap[method] || 'default';

class MixpanelService {
  mixpanel: Mixpanel | null = null;

  constructor() {
    this.mixpanel = mixpanel;
  }

  init = () => {
    if (!this.isInitialized()) {
      const projectToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;
      if (!projectToken) {
        console.error('Mixpanel project token is missing');
        return;
      }
      mixpanel.init(projectToken, {
        debug: !config.isProd,
        track_pageview: true,
        persistence: 'cookie',
      });
    }
  };

  isInitialized() {
    try {
      return mixpanel && mixpanel.get_distinct_id();
    } catch {
      return false;
    }
  }

  connectWalletNavigate = () => {
    try {
      const values = this.checkAdditionalMeta({});
      this.mixpanel?.track(MIXPANEL_CONNECT_WALLET_NAVIGATE, values);
    } catch (error) {
      console.log('connectWalletNavigate error', error);
    }
  };

  connectWalletClicked = (address: string, type: string) => {
    try {
      const data = { wallet: address, type: type };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_CONNECT_WALLET_CLICKED, values);
    } catch (error) {
      console.log('connectWalletClicked error', error);
    }
  };

  connectWalletSuccess = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);

      this.mixpanel?.alias(address);
      this.mixpanel?.identify(address);

      const walletType = this.readWalletTypeFromLs();
      let userProperties: any = {
        wallet: address,
      };
      if (walletType) {
        userProperties = { ...userProperties, walletType };
      }
      this.mixpanel?.people.set_once(userProperties);

      this.mixpanel?.track(MIXPANEL_CONNECT_WALLET_SUCCESS, values);
    } catch (error) {
      console.log('connectWalletSuccess error', error);
    }
  };

  depositNavigate = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_DEPOSIT_NAVIGATE, values);
    } catch (error) {
      console.log('depositNavigate error', error);
    }
  };

  depositClicked = (address: string, amount: number, method: DepositMethod) => {
    const type = getDepositTypeFromMethod(method);

    try {
      const data = { wallet: address, amount_usdt: amount, deposit_type: type };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_DEPOSIT_CLICKED, values);
    } catch (e) {
      console.log('depositClicked error', e);
    }
  };

  depositSuccess = (address: string, amount: number, method: DepositMethod) => {
    try {
      const type = getDepositTypeFromMethod(method);
      const data = { wallet: address, amount_usdt: amount, deposit_type: type };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_DEPOSIT_SUCCESS, values);
    } catch (e) {
      console.log('depositSuccess error', e);
    }
  };

  withdrawNavigate = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_WITHDRAW_NAVIGATE, values);
    } catch (e) {
      console.log('withdrawNavigate error', e);
    }
  };

  withdrawClicked = (address: string, amount: number) => {
    try {
      const data = { wallet: address, amount_usdt: amount };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_WITHDRAW_CLICKED, values);
    } catch (e) {
      console.log('withdrawClicked error', e);
    }
  };

  withdrawSuccess = (address: string, amount: number) => {
    try {
      const data = { wallet: address, amount_usdt: amount };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_WITHDRAW_SUCCESS, values);
    } catch (e) {
      console.log('withdrawSuccess error', e);
    }
  };

  openOrderClicked = (
    address: string,
    params: CreateOrderParams,
    source: 'order-panel' | 'orderbook' | 'tradingview',
  ) => {
    try {
      const data = { wallet: address, ...params };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_OPEN_ORDER_CLICKED, values);
    } catch (e) {
      console.log('openOrderClicked error', e);
    }
  };

  openOrderSuccess = (
    address: string,
    params: CreateOrderParams,
    source: 'order-panel' | 'orderbook' | 'tradingview',
  ) => {
    try {
      const data = { wallet: address, ...params };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_OPEN_ORDER_SUCCESS, values);
    } catch (e) {
      console.log('openOrderSuccess error', e);
    }
  };

  cancelWithdrawClicked = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_CANCEL_WITHDRAW_CLICKED, values);
    } catch (e) {
      console.log('cancelWithdrawClicked error', e);
    }
  };

  cancelWithdrawSuccess = (address: string) => {
    try {
      const data = {
        wallet: address,
        // amount_usdt: amount
      };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_CANCEL_WITHDRAW_SUCCESS, values);
    } catch (e) {
      console.log('cancelWithdrawSuccess error', e);
    }
  };

  claimWithdrawClicked = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_CLAIM_WITHDRAW_CLICKED, values);
    } catch (e) {
      console.log('claimWithdrawClicked error', e);
    }
  };

  claimWithdrawSuccess = (address: string) => {
    try {
      const data = {
        wallet: address,
        //  amount_usdt: amount
      };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_CLAIM_WITHDRAW_SUCCESS, values);
    } catch (e) {
      console.log('claimWithdrawSuccess error', e);
    }
  };

  closePositionSuccess = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_CLOSE_POSITION_SUCCESS, values);
    } catch (e) {
      console.log('closePositionSuccess error', e);
    }
  };

  //   closePositionsAllSuccess = address => {
  //     const values = { wallet: address };
  //     //this.logEvent(AF_CLOSE_POSITIONS_ALL_SUCCESS, values)
  //   };

  cancelOrderSuccess = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_CANCEL_ORDER_SUCCESS, values);
    } catch (e) {
      console.log('cancelOrderSuccess error', e);
    }
  };

  cancelOrdersAllSuccess = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_CANCEL_ORDERS_ALL_SUCCESS, values);
    } catch (e) {
      console.log('cancelOrdersAllSuccess error', e);
    }
  };

  logoutSuccess = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_LOG_OUT_SUCCESS, values);
      this.reset();
    } catch (e) {
      console.log('logoutSuccess error', e);
    }
  };

  elixirVaultsNavigate = (address: string, actionType: AmendType) => {
    try {
      const data = { wallet: address, actionType };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_ELIXIR_VAULTS_NAVIGATE, values);
    } catch (e) {
      console.log('elixirVaultsNavigate error', e);
    }
  };

  elixirVaultsClicked = (
    address: string,
    actionType: 'stake' | 'unstake',
    amount: number,
    tokenPair: string,
  ) => {
    try {
      const data = {
        wallet: address,
        amount_usdt: amount,
        tokenPair,
        actionType,
      };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_ELIXIR_VAULTS_CLICKED, values);
    } catch (e) {
      console.log('elixirVaultsClicked error', e);
    }
  };

  elixirVaultsSuccess = (
    address: string,
    actionType: 'stake' | 'unstake',
    amount: number,
    tokenPair: string,
  ) => {
    try {
      const data = {
        wallet: address,
        amount_usdt: amount,
        tokenPair,
        actionType,
      };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_ELIXIR_VAULTS_SUCCESS, values);
    } catch (e) {
      console.log('elixirVaultsSuccess error', e);
    }
  };

  stakeLockRbxNavigate = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_STAKE_LOCK_RBX_NAVIGATE, values);
    } catch (e) {
      console.log('stakeLockRbxNavigate error', e);
    }
  };

  stakeLockRbxClicked = (address: string, amount: number) => {
    try {
      const data = { wallet: address, amount_usdt: amount };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_STAKE_LOCK_RBX_CLICKED, values);
    } catch (e) {
      console.log('stakeLockRbxClicked error', e);
    }
  };

  stakeLockRbxSuccess = (address: string, amount: number) => {
    try {
      const data = { wallet: address, amount_usdt: amount };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_STAKE_LOCK_RBX_SUCCESS, values);
    } catch (e) {
      console.log('stakeLockRbxSuccess error', e);
    }
  };

  stakeLockLpNavigate = (address: string) => {
    try {
      const data = { wallet: address };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_STAKE_LOCK_LP_NAVIGATE, values);
    } catch (e) {
      console.log('stakeLockLpNavigate error', e);
    }
  };

  stakeLockLpClicked = (address: string, amount: number) => {
    try {
      const data = { wallet: address, amount_usdt: amount };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_STAKE_LOCK_LP_CLICKED, values);
    } catch (e) {
      console.log('stakeLockLpClicked error', e);
    }
  };

  stakeLockLpSuccess = (address: string, amount: number) => {
    try {
      const data = { wallet: address, amount_usdt: amount };
      const values = this.checkAdditionalMeta(data);
      this.mixpanel?.track(MIXPANEL_STAKE_LOCK_LP_SUCCESS, values);
    } catch (e) {
      console.log('stakeLockLpSuccess error', e);
    }
  };

  reset = () => {
    this.mixpanel?.reset();
  };

  checkAdditionalMeta = data => {
    let values = { ...data, appType: 'web' };
    const campaignAnalytics = readUserSetting(CAMPAIGN_ANALYTICS);
    if (campaignAnalytics) {
      const lastCampaignEntry = (campaignAnalytics as any[]).pop();
      if (lastCampaignEntry['utm_source']) {
        values = { utmSource: lastCampaignEntry['utm_source'], ...values };
      }
      if (lastCampaignEntry['utm_medium']) {
        values = { utmMedium: lastCampaignEntry['utm_medium'], ...values };
      }
      if (lastCampaignEntry['utm_campaign']) {
        values = { utmCampaign: lastCampaignEntry['utm_campaign'], ...values };
      }
      if (lastCampaignEntry['utm_term']) {
        values = { utmTerm: lastCampaignEntry['utm_term'], ...values };
      }
      if (lastCampaignEntry['utm_content']) {
        values = { utmContent: lastCampaignEntry['utm_content'], ...values };
      }
    }

    const walletType = this.readWalletTypeFromLs();
    if (walletType) {
      values = { walletType: walletType, ...values };
    }

    return values;
  };

  readWalletTypeFromLs = () => {
    const walletType = readUserSetting(LS_WALLET_TYPE);
    return walletType;
  };
}

export const mixpanelService = new MixpanelService();
