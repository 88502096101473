import { forwardRef } from 'react';

import { abbreviateNumber } from 'utils';

import Text from 'components/Text';

import { DefaultVariantProps } from './defaults';
import { CardStatus, CardVariant } from './enums';
import { Container, Goal, Status } from './styles';
import infoIcon from 'assets/icons/info-circle-white.svg';

import { useTranslation } from 'react-i18next';

const getStatusTx = (status: Exclude<CardStatus, CardStatus.INFO>, t: any) => {
  if (status === CardStatus.CLAIMED) return t('Claimed');
  if (status === CardStatus.IN_PROGRESS) return t('InProgress');
  return '';
};

const getLevelTx = (level: number, variant: CardVariant, t: any): string => {
  const levelTx = `${t('Level')} ${level}\n`;
  switch (variant) {
    case CardVariant.GOAL:
    case CardVariant.LOCKED:
    case CardVariant.CLAIMED:
      return levelTx + t('VolumeGoal');
    case CardVariant.PRIZE:
      return levelTx + t('BonusReward');
    case CardVariant.FEE:
      return levelTx + t('Comission');
    default:
      return '';
  }
};

const getGoalValue = (goal: number, variant: CardVariant) => {
  switch (variant) {
    case CardVariant.GOAL:
    case CardVariant.LOCKED:
    case CardVariant.CLAIMED:
      return `$${abbreviateNumber(goal)}`;
    case CardVariant.PRIZE:
    case CardVariant.FEE:
      return goal;
    default:
      return '';
  }
};

type Props = {
  variant: CardVariant;
  status?: CardStatus;
  containerClass?: string;
  vectorClass?: string;
  level?: number;
  goal?: number;
  children?: React.ReactNode;
};

const RewardCard = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    variant,
    level,
    goal,
    status,
    containerClass,
    vectorClass,
    children,
  } = props;

  const variantProps = DefaultVariantProps.get(variant);

  if (!variantProps)
    throw new Error(`Props not found for CardVariant: ${variant}`);

  const { t } = useTranslation();
  const { vectorSource, rightIcon, leftIcon, gradientColors, borderColor } =
    variantProps;

  return (
    <Container
      ref={ref}
      gradientColors={gradientColors}
      borderColor={borderColor}
      className={containerClass}
    >
      <div className="noised" />

      <img
        src={vectorSource}
        alt={variant}
        className={`vector ${vectorClass}`}
      />

      {children}

      {level !== undefined && (
        <Text variant="BODY_S" fontWeight="semiBold" className="label">
          {getLevelTx(level, variant, t)}
        </Text>
      )}

      {goal !== undefined && (
        <Goal minimalIcon={variant === CardVariant.LOCKED}>
          {leftIcon && (
            <img className="icon" src={leftIcon} alt={'left-icon'} />
          )}
          <Text variant="BODY_S">{getGoalValue(goal, variant)}</Text>
          {rightIcon && (
            <img className="icon" src={rightIcon} alt={'right-icon'} />
          )}
        </Goal>
      )}

      {status ? (
        <Status status={status}>
          {status === CardStatus.INFO ? (
            <img className="info-icon" src={infoIcon} alt="info" />
          ) : (
            <Text variant="BODY_XS">{t(getStatusTx(status, t))}</Text>
          )}
        </Status>
      ) : null}
    </Container>
  );
});

export default RewardCard;
