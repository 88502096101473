import { memo, useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';

import { useActiveWeb3React, useOnClickOutside } from 'hooks';
import { useAccount } from 'hooks/useAccount';
import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { Icon } from 'components';
import Button from 'components/Button/button';

import ManageWalletDropdown from './ManageWalletDropdown';
import WalletStatus from './WalletStatus';
import { UserBlockContainer } from './styles';
import caretDownIcon from 'assets/icons/chevron-down-gray.svg';
import caretUpIcon from 'assets/icons/chevron-up-gray.svg';
import walletIcon from 'assets/icons/wallet-white.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const UserBlock = () => {
  const { status, isConnecting } = useAccount();
  const { account } = useActiveWeb3React();
  const { width: windowWidth } = useWindowDimensions();
  const {
    store: {
      savedWallets: { savedWallets },
    },
  } = useAppContext();
  const theme = useTheme();
  const { t } = useTranslation();
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const modal = useModal();
  const containerRef = useRef<HTMLDivElement>(null);
  const isAvatarPickerOpen = useRef(false);
  const isWalletModalOpen = useRef(false);

  const presentWalletModal = () => {
    modal.present(
      <WalletModal
        onClose={() => {
          isWalletModalOpen.current = false;
        }}
      />,
      Modals.walletModal,
    );
    isWalletModalOpen.current = true;
  };

  const handleConnectWallet = () => {
    if (savedWallets.length > 0) {
      setIsDropdownShown(prev => !prev);
    } else {
      presentWalletModal();
    }
  };

  useEffect(() => {
    if (
      isWalletModalOpen.current ||
      windowWidth < (theme as any).screenSizes.xl
    ) {
      return;
    }
    presentWalletModal();
  }, [account]);

  const handleOutsideClick = useCallback(() => {
    if (isAvatarPickerOpen.current) return; // If Avatar Picker is open, dont close on outside click (let the picker close)
    modal.activeModals.filter(modal =>
      (
        [
          Modals.confirmationModal,
          Modals.editWalletNameModal,
          Modals.walletModal,
        ] as string[]
      ).includes(modal.name),
    ).length === 0 && setIsDropdownShown(false); // only close if there are no relevant modals open on top
  }, [modal.activeModals]);

  useOnClickOutside(containerRef, handleOutsideClick);

  const toggleDropDown = () => setIsDropdownShown(prev => !prev);

  const onAddressClick = () => {
    isAvatarPickerOpen.current = false;
    toggleDropDown();
  };

  const onAvatarClick = () => {
    isAvatarPickerOpen.current = true;
    toggleDropDown();
  };

  const content = (() => {
    if (isConnecting) {
      return <Button disabled={isConnecting}>{/* <Preloader /> */}</Button>;
    }

    if (status === 'connected') {
      return (
        <WalletStatus
          account={account}
          onAddressClick={onAddressClick}
          onAvatarClick={onAvatarClick}
        />
      );
    }

    return (
      <Button
        data-testid="wallet-connect-btn"
        disabled={isConnecting}
        colorVariant="secondary"
        sizeVariant="S"
        onClick={handleConnectWallet}
        data-gtmid="button-connect-wallet-2"
        className="connect-button"
        background="shadesBackground800"
        border="shadesBackground700"
        leftIcon={walletIcon}
        rightIcon={
          savedWallets.length > 0
            ? isDropdownShown
              ? caretUpIcon
              : caretDownIcon
            : undefined
        }
      >
        {t('connect')}
      </Button>
    );
  })();

  return (
    <UserBlockContainer ref={containerRef}>
      {content}
      {isDropdownShown ? (
        <div className="dropdown">
          <ManageWalletDropdown
            onPickerVisibilityChange={val => (isAvatarPickerOpen.current = val)}
            isPickerOpen={isAvatarPickerOpen.current}
            onClose={handleOutsideClick}
          />
        </div>
      ) : null}
    </UserBlockContainer>
  );
};

export default memo(observer(UserBlock));
