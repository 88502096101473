import styled from 'styled-components';

export const Container = styled.div`
  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    display: grid;
    grid-gap: 10px;
    grid-template-areas: 'tab-filters search';
    grid-template-columns: 1fr 250px;
  }
`;
