import { useEffect, useRef, useState } from 'react';

import { AbstractConnector } from '@web3-react/abstract-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

import { saveUserSetting } from 'utils';
import { isBrandRabbitX } from 'utils/brand';
import { detectWalletName } from 'utils/wallet';

import { useAccount } from 'hooks';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { useExchangeAPI } from 'hooks/useExchangeAPI';
import useModal from 'hooks/useModal';

import Modal from 'components/Modal';

import { ParticleAuthConnector } from '../../../../connectors/particleAuthConnector';
import {
  LS_IS_WALLET_CONNECTED_VIA_SOCIAL,
  LS_WALLET_TYPE,
} from '../../../../constants/localStorageKeys';
import ConnectMethods from './ConnectMethods';
import SignatureVerificationState from './SignatureVerification';
import HeaderAndTnC from './headerAndTnC';
import { Body, Illustration, Content } from './styles';
import mockBfxDexImage from 'assets/images/mock-dex-bfx.png';
import mockRabbitDexImage from 'assets/images/mock-dex-rabbitx.png';
import { WALLET_CONNECT_MODAL_NAME } from 'constants/general';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { mixpanelService } from 'service/mixpanelService';

import { observer } from 'mobx-react';

export enum Steps {
  PENDING_CONNECT,
  PENDING_SIGN,
  ALREADY_SIGN,
}

const mockDexImage = isBrandRabbitX ? mockRabbitDexImage : mockBfxDexImage;

interface Props {
  name?: string;
  onClose?: () => void;
  manualConnect?: boolean;
}
export const WalletModal = observer(
  ({ onClose, manualConnect = false }: Props) => {
    const { onboardUser, isOnboarding, isReadingSignatureFromLocalDB } =
      useExchangeAPI();
    const modal = useModal();
    const { connector, library } = useActiveWeb3React();
    const { connect, manualDisconnect, account, areRequestsLocked } =
      useAccount();
    const isManualConnect = useRef(manualConnect);
    const {
      store: {
        savedWallets: { cachedVaultAddress },
      },
    } = useAppContext();

    const {
      store: { account: accountStore },
    } = useAppContext();

    const [isVault, setIsVault] = useState(false);

    const step = !account
      ? Steps.PENDING_CONNECT
      : !accountStore.frontendSecrets
      ? Steps.PENDING_SIGN
      : Steps.ALREADY_SIGN;

    if (step === Steps.ALREADY_SIGN) {
      console.log('Steps.ALREADY_SIGN : CLOSING WALLET MODAL');
      setTimeout(() => {
        if (
          modal.activeModals
            .filter(item => item.name === Modals.walletModal)
            .some(item => item.focused)
        ) {
          modal.clearByName(Modals.walletModal);
        }
        onClose && onClose();
      }, 500);
    }

    const connectWallet = async (
      connector: AbstractConnector,
      isVault = false,
    ) => {
      if (!isVault && connector instanceof InjectedConnector) {
        isManualConnect.current = true;
      }
      try {
        setIsVault(isVault);

        // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
        if (
          connector instanceof WalletConnectConnector &&
          connector.walletConnectProvider
        ) {
          connector.walletConnectProvider = undefined;
        }

        /*
      // @ts-ignore
      if(window?.ethereum?._metamask?.isUnlocked === 'function'){
        // https://github.com/MetaMask/metamask-extension/issues/10085
        // @ts-ignore
        const isWalletUnlocked = await Promise.race([
          // @ts-ignore
          window?.ethereum?._metamask?.isUnlocked(),
          new Promise(resolve => {
            setTimeout(resolve, 100, 'RACED');
          }),
        ]);

        console.log('isWalletUnlocked: ', isWalletUnlocked)

        // https://github.com/MetaMask/metamask-extension/issues/10368#issuecomment-778439371
        // Messy fix because on the first page load, the "_metamask.isUnlocked()" promise sometimes never resolves
        if (isWalletUnlocked === 'RACED') {
          window.location.reload();
        }

        if (isWalletUnlocked !== undefined && !isWalletUnlocked) {
          window?.ethereum?.request({
            method: 'wallet_requestPermissions',
            params: [{ eth_accounts: {} }],
          });
          showNotification({
            title: `Please log in to MetaMask by clicking on the extension icon.`,
            type: NotificationType.Negative,
          });
          return;
        }
      } */

        await connect(connector);
      } catch (err) {
        console.error(
          'An error occured while connecting to InjectedWallet',
          err,
        );
        isManualConnect.current = false;
        setIsVault(false);
      } finally {
        // modal.pop();
      }
    };

    if (account && accountStore.frontendSecrets) {
      saveUserSetting(
        LS_IS_WALLET_CONNECTED_VIA_SOCIAL,
        connector instanceof ParticleAuthConnector,
      );
    }

    useEffect(() => {
      if (!account || !accountStore?.frontendSecrets?.profile.wallet) {
        return;
      }

      const walletName = detectWalletName(library?.provider as any);
      saveUserSetting(LS_WALLET_TYPE, walletName);
    }, [
      account,
      accountStore.frontendSecrets?.profile.wallet,
      library?.provider,
    ]);

    useEffect(() => {
      mixpanelService.connectWalletNavigate();
    }, []);

    useEffect(() => {
      if (!account) {
        setIsVault(false);
        return;
      }

      if (isManualConnect.current && connector instanceof InjectedConnector) {
        isManualConnect.current = false;
        onboardUser();
      }
    }, [account]);

    return (
      <Modal
        size="auto"
        showHeader={false}
        data-cy="connect-wallet-modal"
        name={Modals.walletModal}
        closeButtonType="contained"
        padding="0 !important"
      >
        <Body>
          <Illustration>
            <img src={mockDexImage} alt="dex" />
          </Illustration>

          <Content>
            {step === Steps.PENDING_CONNECT ? (
              <>
                <HeaderAndTnC />
                <div className="divider" />
              </>
            ) : null}

            {step === Steps.PENDING_CONNECT ? (
              <ConnectMethods connectWallet={connectWallet} />
            ) : (
              <SignatureVerificationState
                connector={connector}
                isVault={isVault}
                onBackClick={manualDisconnect}
                onSignAndVerify={onboardUser}
                areRequestsLocked={areRequestsLocked}
                isOnboarding={isOnboarding || isReadingSignatureFromLocalDB}
                step={step}
                cachedVaultAddress={cachedVaultAddress}
                setIsVault={setIsVault}
              />
            )}
          </Content>
        </Body>
      </Modal>
    );
  },
);
// @ts-ignore
WalletModal.defaultProps = {
  name: WALLET_CONNECT_MODAL_NAME,
};
