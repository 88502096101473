import { useContext } from 'react';

import {
  UsdtContractsApiContext,
  EmptyUsdtContractsApiContext,
} from 'contexts/UsdtContractsApiContext';

export const useUsdtContractsApi = (throwError: boolean = true) => {
  const context = useContext(UsdtContractsApiContext);
  if (context) {
    return context;
  }
  if (throwError) {
    throw Error('Context Provider is not provided!');
  }
  return EmptyUsdtContractsApiContext;
};
