import { brandedSelect } from 'utils/brand';

import { Column } from 'theme/globalStyledComponents/column';

import styled, { css } from 'styled-components';

export const Container = styled(Column)<{
  isCollapsed: boolean;
  completed: boolean;
}>`
  gap: 10px;
  cursor: pointer;
  align-self: stretch;
  padding: 10px;

  .strike {
    text-decoration-line: line-through;
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .button {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.shadesBackground800};
    display: flex;
    padding: 8px 12px;

    ${({ completed }) =>
      completed
        ? css`
            opacity: 0.3;
            cursor: not-allowed;
          `
        : css`
            :hover {
              background-color: ${({ theme }) =>
                theme.colors.shadesBackground700};
            }
          `}
  }

  .icon-container {
    border-radius: 100px;
    height: 20px;
    width: 20px;
    padding: 4px;
    box-sizing: border-box;
    background-color: ${({ theme }) =>
      brandedSelect({
        rabbitx: theme.colors.positiveForeground200,
        bfx: theme.colors.main200,
      })};
  }

  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      .drop-icon {
        transition: transform 0.3s ease-in-out;
      }

      :hover {
        .drop-icon {
          transform: rotate(-180deg);
          transition: transform 0.3s ease-in-out;
        }
      }
    `}
`;
