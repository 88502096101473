import styled from 'styled-components';

const getStatBackground = (index: number) => {
  switch (index) {
    case 0:
      return '#298D6F';
    case 1:
      return '#23799E';
    case 2:
      return '#45558B';
    default:
      return '';
  }
};

export const VaultStatsStyled = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    flex-direction: row;
  }
`;

type StatStyledProps = { index: number };
export const StatStyled = styled.div<StatStyledProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  background: ${({ theme, index }) =>
    `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(145deg, ${getStatBackground(
      index,
    )} -0.11%, ${theme.colors.shadesBackground900} 93.99%)`};

  .overlay-bg {
    position: absolute;
    width: 114px;
    height: 114px;
    transform: rotate(-5deg);
    right: 8%;
    top: -19px;
  }

  .value {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;
