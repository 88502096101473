import { memo } from 'react';

import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import GeneralStats from './GeneralStats';
import SharesInfo from './SharesInfo';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  box-sizing: border-box;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    min-width: 426px;
  }

  .space-between {
    justify-content: space-between;
  }

  .icon {
    height: 16px;
    width: 16px;
  }
`;

type Props = {
  address?: string;
  vault: VaultWithContractInfo;
};
const VaultStats = ({ vault, address = '0x291231239Vy2' }: Props) => {
  return (
    <Container className="container">
      <SharesInfo vault={vault} />
      <GeneralStats address={address} vault={vault} />
    </Container>
  );
};

export default memo(VaultStats);
