import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterBar = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.shadesForeground200};

  > span {
    padding: 8px 13px 8px 13px;
    border-radius: 8px;
    cursor: pointer;

    &.active {
      background: ${({ theme }) => theme.colors.shadesBackground700};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Options = styled.ul`
  .markets {
    max-height: 260px;
    overflow-y: auto;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      max-height: 340px;
    }
  }

  list-style-type: none;
  margin-bottom: 0;
  margin-top: 8px;
  padding: 0;

  li {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    margin: 0;
    padding: 10px;
    letter-spacing: -0.5px;
    border-radius: 12px;

    :hover {
      background: ${({ theme }) => theme.colors.shadesBackground700};
      cursor: pointer;
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      padding: 12px;
    }

    :last-child {
      margin-bottom: 0;
    }

    > span {
      display: flex;
      align-items: center;
    }

    > span,
    > div {
      width: 34%;
    }

    > span:first-child,
    > div:first-child {
      width: 52%;
    }

    > span:nth-child(2),
    > div:nth-child(2) {
      width: 22%;
    }

    > span:last-of-type {
      justify-content: flex-end;
      margin-left: 5px;
      word-break: break-all;
    }

    &.heading-row {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.shadesForeground200};
      background: initial;
      cursor: default;
      padding: 0 20px 0 0;

      > div {
        display: flex;
        align-items: center;
      }

      > div:last-of-type {
        justify-content: flex-end;
      }
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
`;

export const MarketHeaderCell = styled.div`
  cursor: pointer;
  display: flex;
  .sorting-icon {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin-left: 8px;
    img {
      width: 8px;
      &.active {
        opacity: 0.5;
      }
    }
  }
`;

export const MarketCell = styled.div`
  display: flex;

  > img {
    margin-right: 8px;
    width: 24px;
  }

  .market-name {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
    }

    img.star-icon {
      width: 16px;
      height: 16px;
      height: 100%;
    }

    .market-id-and-star {
      word-break: break-word;
      max-width: 120px;
      display: flex;
      align-items: center;
      gap: 2px;

      > span {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        letter-spacing: 0.5px;
        font-size: 14px;
        line-height: 18px;
        margin-right: 1px;
      }

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        max-width: 120px;
      }
    }

    .market-name {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.shadesForeground200};
      font-size: 13px;
      line-height: 15px;
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      .market-id-and-star {
        > span {
          font-size: 16px;
        }
      }

      .market-name {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;

export const PriceCell = styled.div`
  display: flex;
  flex-direction: column;

  > span:first-of-type {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  > span:nth-of-type(2) {
    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .arrow-icon {
    margin-right: 2px;
  }
`;

export const NoResultsAndLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.shadesForeground200};
  padding: 10px;
`;
