import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  h5.heading {
    font-size: 16px;
    line-height: 24px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin-bottom: 20px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
    margin: 20px 0;
  }
`;

export const ExpirationAndAlert = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .col {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(50% - 10px);

    label {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
`;
