import { bigNumberToFloat } from 'utils';
import { brand } from 'utils/brand';

import { USDT_DECIMALS } from 'constants/contracts';
import { elixirVaultIdToRbxId } from 'pages/Vaults/Pages/Vaults/TabPanels/ElixirVaults/utils';

import axios from 'axios';
import { config } from 'config';
import { BigNumber } from 'ethers';

export type Vault = {
  tokenPair: string;
  tvlUsd: number;
  apy: number;
  productId: number;
  elixirPairId: string;
};

export type VaultContractInfo = {
  activeAmount: number;
  hardcap: number;
  walletShares?: number;
  isActive: boolean;
  routerAddress: string;
};

export type VaultWithContractInfo = Vault & VaultContractInfo;

type ElixirVaultsTvlApyResponse = {
  data: {
    tvl_per_exchange: {
      RABBITX: {
        total_usd: BigNumber;
        token_pairs: {
          [key: string]: {
            USDT: {
              amount_wei: number;
              price_usd: number;
            };
            pool_apy: number;
            product_id: number;
          };
        };
      };
    };
  };
};

export type ElixirVaults = {
  vaults: Vault[];
  totalTvlUsd: number;
};

const mapElixirVaultsTvlApyResponseToVaults = (
  response: ElixirVaultsTvlApyResponse,
): ElixirVaults => {
  const {
    data: {
      tvl_per_exchange: { RABBITX },
    },
  } = response;

  const token = config.isProd ? brand.tokenTicker : 'USDR';

  const vaults: Vault[] = Object.entries(RABBITX.token_pairs)
    .filter(([, data]) => !!data[token])
    .map(([tokenPair, data]) => {
      return {
        tokenPair: elixirVaultIdToRbxId(tokenPair),
        tvlUsd: bigNumberToFloat(data[token].price_usd, USDT_DECIMALS),
        apy: data.pool_apy,
        productId: data.product_id,
        elixirPairId: tokenPair,
      };
    });

  return {
    vaults,
    totalTvlUsd: bigNumberToFloat(RABBITX.total_usd, USDT_DECIMALS),
  };
};

export type RbxRewards = {
  amount: BigNumber;
  decimal: string;
  signature: string;
  start_timestamp: number;
  end_timestamp: number;
  token: {
    address: string;
  };
};

type RbxRewardsResponse = {
  [key: string]: {
    [key: string]: RbxRewards[];
  };
};

export function useElixirVaultsApi() {
  const fetchPoolsWithTvlAndApy = async (): Promise<ElixirVaults> => {
    try {
      const { data }: { data: ElixirVaultsTvlApyResponse } = await axios.get(
        `${config.elixirVaults.apiUrl}/metrics/tvl-apy`,
      );

      if (!data) {
        throw new Error('No data returned from Elixir Vaults API');
      }

      return mapElixirVaultsTvlApyResponseToVaults(data);
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  };

  const fetchRbxRewardsForUser = async (
    account: string,
  ): Promise<RbxRewards | null> => {
    try {
      const { data, status }: { data: RbxRewardsResponse; status: number } =
        await axios.get(
          `${config.elixirVaults.rbxRewardsApiUrl}/${account}.json`,
          {
            validateStatus: function (status: number) {
              return status >= 200 && status <= 404;
            },
          },
        );

      // 403 indicates there are no rewards to claim for this user
      if (status === 403) {
        return null;
      }

      if (!data) {
        throw new Error('No data returned from Elixir RBX Rewards API');
      }

      const rewardsArray = data[account][config.chainID];
      return rewardsArray.find(
        el => el.token.address === config.RBTAddress,
      ) as RbxRewards;
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  };

  return {
    fetchPoolsWithTvlAndApy,
    fetchRbxRewardsForUser,
  } as const;
}

export type ElixirVaultsApi = ReturnType<typeof useElixirVaultsApi>;
