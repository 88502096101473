import { memo, useState } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import { capitalizeFirstLetter, timestampToLocalDateWithTime } from 'utils';
import { brand } from 'utils/brand';

import { FormattedNumber, Icon } from 'components';
import CollapseIcon from 'components/CollapseIcon';

import LabelAndValueCell from '../components/LabelAndValueCell';
import { CollapsibleTableRow, StyledTableCell } from '../styles';
import CancelAndClaimCell from './CancelAndClaimCell/CancelAndClaimCell';
import TransferStatusCell from './TransferStatus';
import downloadPrimaryIcon from 'assets/icons/downloadPrimary.svg';
import newTabIcon from 'assets/icons/new-tab-gray.svg';
import withdrawPrimaryIcon from 'assets/icons/withdrawPrimary.svg';
import { Row } from 'theme/globalStyledComponents/row';

import { config } from 'config';
import { BalanceOpsType, DepositOrWithdrawalStatus } from 'enums';
import { ProfileBalanceOps } from 'interfaces';
import { useTranslation } from 'react-i18next';

const getOpName = (opsType: BalanceOpsType) => {
  switch (opsType) {
    case BalanceOpsType.Deposit:
      return 'Deposit';
    case BalanceOpsType.Withdrawal:
      return 'Withdrawal';
    case BalanceOpsType.DepositBonus:
      return 'Deposit Bonus';
    case BalanceOpsType.UnstakeSharesVaults:
      return 'Unstake';
    default:
      return capitalizeFirstLetter(opsType);
  }
};

export interface TransfersRowProps {
  balanceOperation: ProfileBalanceOps;
  showLabels: boolean;
  collapsible?: boolean;
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
}
const TransfersRow = ({
  balanceOperation: { id, opsType, amount, status, timestamp, txhash, wallet },
  showLabels,
  collapsible,
  isCollapsed = true,
  toggleCollapse,
}: TransfersRowProps) => {
  const { t } = useTranslation();

  const isRequestedUnstake =
    opsType === BalanceOpsType.UnstakeSharesVaults &&
    status === DepositOrWithdrawalStatus.Requested;

  if (isRequestedUnstake) return null;

  const etherscanUrl = txhash
    ? `${config.etherscanUrl}/tx/${txhash}`
    : `${config.etherscanUrl}/address/${config.RabbitContractAddress}?fromaddress=${wallet}`;

  // Only open the transaction URL if the operation is a withdrawal or deposit.
  const openTransactionUrl = () => {
    if (
      [
        BalanceOpsType.UnstakeSharesVaults,
        BalanceOpsType.DepositBonus,
      ].includes(opsType as BalanceOpsType)
    )
      return;

    window.open(etherscanUrl, '_blank');
  };

  if (collapsible && toggleCollapse)
    return (
      <CollapsibleTableRow
        isCollapsed={isCollapsed}
        onClick={toggleCollapse}
        className="cursor-pointer"
      >
        <TableCell>
          <LabelAndValueCell
            showLabel={showLabels}
            limitWidth
            isCell={false}
            label={t('type')}
            value={
              <>
                <Icon
                  src={
                    opsType === BalanceOpsType.Deposit
                      ? downloadPrimaryIcon
                      : withdrawPrimaryIcon
                  }
                  size="S"
                  alt="Deposit"
                />
                {getOpName(opsType as BalanceOpsType)}
              </>
            }
          />

          {!isCollapsed ? (
            <LabelAndValueCell
              showLabel={showLabels}
              className="mt-10"
              limitWidth
              isCell={false}
              label={t('time')}
              value={timestampToLocalDateWithTime(timestamp)}
            />
          ) : null}
        </TableCell>

        <LabelAndValueCell
          showLabel={showLabels}
          verticalAlign="top"
          label={t('currency')}
          value={
            <>
              <Icon size="S" src={brand.tokenLogo} alt="Coin" />
              {brand.tokenTicker}
            </>
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          verticalAlign="top"
          label={t('amount')}
          value={
            <>
              <FormattedNumber
                tooltipText={
                  isRequestedUnstake
                    ? 'Value will be determined when your unstake request has been accepted.'
                    : undefined
                }
                value={isRequestedUnstake ? undefined : amount}
                suffix={` ${brand.tokenTicker}`}
              />
            </>
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          verticalAlign="top"
          label={t('valueUsd')}
          value={
            <>
              <FormattedNumber
                tooltipText={
                  isRequestedUnstake
                    ? 'Value will be determined when your unstake request has been accepted.'
                    : undefined
                }
                value={isRequestedUnstake ? undefined : amount}
                prefix="$"
              />
            </>
          } // Figure how to show swaps make visible here.
        />

        <LabelAndValueCell
          showLabel={showLabels}
          verticalAlign="top"
          label={t('status')}
          value={
            <Row gap={5}>
              <TransferStatusCell
                status={status}
                txhash={txhash}
                wallet={wallet}
                timestamp={timestamp}
                amount={amount}
                type={opsType as BalanceOpsType}
              />
              {txhash ? (
                <Icon
                  size="S"
                  src={newTabIcon}
                  onClick={e => {
                    e.stopPropagation();
                    openTransactionUrl();
                  }}
                />
              ) : null}
            </Row>
          }
        />

        <StyledTableCell verticalAlign="top">
          <Row className="justify-end" gap={10}>
            <CollapseIcon isCollapsed={isCollapsed} />
            {opsType === BalanceOpsType.Withdrawal ? (
              <CancelAndClaimCell
                balanceOperationStatus={status}
                balanceOperationId={id}
              />
            ) : null}
          </Row>
        </StyledTableCell>
      </CollapsibleTableRow>
    );

  return (
    <TableRow onClick={openTransactionUrl} className="cursor-pointer">
      <LabelAndValueCell
        showLabel={showLabels}
        label={t('type')}
        value={
          <>
            <Icon
              src={
                [BalanceOpsType.Deposit, BalanceOpsType.DepositBonus].includes(
                  opsType as BalanceOpsType,
                )
                  ? downloadPrimaryIcon
                  : withdrawPrimaryIcon
              }
              size="S"
              alt="Deposit"
            />
            {getOpName(opsType as BalanceOpsType)}
          </>
        }
      />

      <LabelAndValueCell
        showLabel={showLabels}
        label={t('currency')}
        value={
          <>
            <Icon size="S" src={brand.tokenLogo} alt="Coin" />
            {brand.tokenTicker}
          </>
        }
      />

      <LabelAndValueCell
        showLabel={showLabels}
        label={t('amount')}
        value={
          <FormattedNumber
            tooltipText={
              isRequestedUnstake
                ? 'Your value will be determined once your unstake request is accepted. Please check the vaults for more details regarding this unstake.'
                : undefined
            }
            value={isRequestedUnstake ? undefined : amount}
            suffix={` ${brand.tokenTicker}`}
          />
        }
      />

      <LabelAndValueCell
        showLabel={showLabels}
        label={t('valueUsd')}
        value={
          <FormattedNumber
            tooltipText={
              isRequestedUnstake
                ? 'Your value will be determined once your unstake request is accepted. Please check the vaults for more details regarding this unstake.'
                : undefined
            }
            value={isRequestedUnstake ? undefined : amount}
            prefix="$"
          />
        } // Figure how to show swaps make visible here.
      />

      <LabelAndValueCell
        showLabel={showLabels}
        label={t('status')}
        value={
          <TransferStatusCell
            status={status}
            txhash={txhash}
            wallet={wallet}
            amount={amount}
            timestamp={timestamp}
            type={opsType as BalanceOpsType}
          />
        }
      />

      <LabelAndValueCell
        showLabel={showLabels}
        label={t('time')}
        value={timestampToLocalDateWithTime(timestamp)}
      />

      <TableCell align="right">
        {opsType === BalanceOpsType.Withdrawal ? (
          <CancelAndClaimCell
            balanceOperationStatus={status}
            balanceOperationId={id}
          />
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default memo(TransfersRow);

export const CollapsibleTransfersRow = memo((props: TransfersRowProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <TransfersRow
      collapsible
      isCollapsed={isCollapsed}
      toggleCollapse={() => setIsCollapsed(prev => !prev)}
      {...props}
    />
  );
});
