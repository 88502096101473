import styled from 'styled-components';

export const ActionButtonsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .navigation-item {
    display: flex;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;

    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.shadesForeground200};

    .dev-settings {
      position: relative;
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      span {
        display: none;
      }
    }
  }

  .qr-icon {
    padding: 5px;
  }

  .qr-btn {
    align-self: flex-start;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px 0 0;

    .qr-btn {
      align-self: center;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    margin: 0 18px 0 0;
    gap: 12px;
  }

  .row {
    display: flex;
    gap: inherit;
  }
`;

export const VerticalSeparator = styled.div`
  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    width: 1px;
    height: 24px;
    background: #2a303a;
    margin: 0px 3px;
    align-self: center;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    /* margin: 0px 6px; */
  }
`;
