import { colors } from 'components/Button/options';

import { DepositSteps } from '../utils';

import styled, { css } from 'styled-components';

export const Body = styled.div`
  padding: 24px 24px 0 24px;
  display: flex;
  flex-direction: column;
`;

export interface SectionProps {
  flex?: number;
  step?: DepositSteps;
}

export const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  flex: ${({ flex }) => `${flex ? flex : 0}`};

  label {
    font-size: 12px;
    line-height: 16px;

    /* Desktop/Foreground muted */

    color: ${({ theme }) => theme.colors.shadesForeground200};
    margin-right: 4px;
  }

  ${({ step }) =>
    css`
      span {
        color: ${({ theme }) =>
          step === DepositSteps.SUCCESS
            ? colors(theme)['primary'].default.background
            : '#FFFFFF'};
      }
    `}
`;

export const WaitingSection = styled(Section)`
  svg,
  span {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    margin-right: 5px;
  }

  label {
    /* Desktop/Box Title */

    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    letter-spacing: -0.02em;

    /* mobile/foreground 200 */

    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  span {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .linear-progress-container {
    margin-top: 20px;
  }
`;

export const CurrencyInputV2Wrapper = styled.div`
  label {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }
`;

export const SuccessSection = styled(WaitingSection)`
  span.formatted-number {
    color: ${({ theme }) => colors(theme)['primary'].default.background};
  }
`;

export const ActionSectionStyled = styled(Section)`
  margin-bottom: 0;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

export interface AvailableLabelProps {
  zero?: boolean;
}

export const AvailableLabel = styled.label<AvailableLabelProps>`
  margin: 20px 0 8px 0;

  ${({ zero }) =>
    css`
      color: ${({ theme }) =>
        zero
          ? `${theme.colors.negativeForeground200} !important`
          : theme.colors.shadesForeground100};
      span {
        color: ${({ theme }) =>
          zero
            ? theme.colors.negativeForeground200
            : theme.colors.shadesForeground100};
      }
    `}

  .info-message {
    max-width: 340px;
  }
`;

export const NotEnoughETH = styled.label`
  color: ${({ theme }) => `${theme.colors.negativeForeground200} !important`};
`;

export const Chip = styled.div`
  width: 29px;
  height: 18px;

  font-size: 10px;
  line-height: 18px;
  /* identical to box height, or 175% */

  text-align: center;

  /* mobile/foreground 200 */

  color: ${({ theme }) => theme.colors.shadesForeground100};
  background: ${({ theme }) => theme.colors.shadesBackground800};

  border-radius: 3px;
`;

export const AccountEquitySection = styled(Section)`
  margin-top: 10px;

  svg {
    margin: 0 5px;
    font-size: 1rem;
  }

  .success-green {
    color: ${({ theme }) => theme.colors.positiveForeground200};
  }
`;
