import { useState } from 'react';

import Badge from 'components/Badge';
import Icon from 'components/Icon';
import NumberInput from 'components/Inputs/NumberInput';
import Text from 'components/Text';

import chevronDownIcon from 'assets/icons/chevron-down-white.svg';
import chevronUpIcon from 'assets/icons/chevron-up-white.svg';
import { Row } from 'theme/globalStyledComponents';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;

  .divider {
    height: 1.5px;
    background-color: ${({ theme }) => theme.colors.shadesBackground700};
  }

  .head {
    cursor: pointer;
  }
`;

type MarketSlippageProps = {
  slippage: number | null;
  onSlippageChange: (n: number | null) => void;
  error?: string | null;
};
const MarketSlippage = ({
  slippage,
  onSlippageChange,
  error,
}: MarketSlippageProps) => {
  const [showInput, setShowInput] = useState(false);

  const toggleShowInput = () => {
    setShowInput(prev => !prev);
  };

  return (
    <Container>
      <div className="divider" />

      <Row className="head" onClick={toggleShowInput}>
        <Text variant="BODY_S" flexed className="flex-1">
          Slippage Settings
        </Text>
        <Badge
          padding="10px 12px"
          bgColor="shadesBackground700"
          color="shadesForeground200"
          borderRadius={100}
          variant="BODY_S"
        >
          {slippage ? `${slippage}%` : '--'}
        </Badge>

        <Icon src={showInput ? chevronDownIcon : chevronUpIcon} />
      </Row>

      {showInput ? (
        <NumberInput
          value={slippage}
          label="Max Slippage (%)"
          onChange={onSlippageChange}
          rightSideComponent={<Text variant="BODY_M">%</Text>}
          showSwapCurrency={false}
          showPresets={false}
          showValueApproximation={false}
          errorMessage={error}
          showError={!!error}
        />
      ) : null}
    </Container>
  );
};

export default MarketSlippage;
