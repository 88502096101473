import { memo } from 'react';

import {
  TableRow,
  TableHead as TableHeadMui,
  TableCell,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const TableHead = () => {
  const { t } = useTranslation();
  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>{t('type')}</TableCell>
        <TableCell key={1}>{t('shares')}</TableCell>
        <TableCell key={2}>{t('wallet')}</TableCell>
        <TableCell key={3}>{t('status')}</TableCell>
        <TableCell key={4}>Ops Id2</TableCell>
        <TableCell key={5}>{t('exhangeId')}</TableCell>
        <TableCell key={6}>Profile Id</TableCell>
        <TableCell key={7}>{t('Date')}</TableCell>
        <TableCell key={8} align="right">
          {t('approve')}
        </TableCell>
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
