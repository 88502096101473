import { useEffect, useRef } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import { ErrorBoundary } from '@sentry/react';
import { useWeb3React } from '@web3-react/core';

import { isRouteTVMobile, preloadImages, readUserSetting } from 'utils';
import { checkAndProcessPendingMeshTransfers } from 'utils/activeMeshTransactions';
import {
  checkAndProcessPendingTxs,
  readPendingTxToastIdsFromLocalStorage,
} from 'utils/activeTransactions';
import { brand, isBrandRabbitX } from 'utils/brand';
import { handleAccountChannelSubscription } from 'utils/centrifuge-channels-handlers/accountHandler';
import { handleMarketsChannelSubscription } from 'utils/centrifuge-channels-handlers/marketsHandler';
import { handlePublicChannelsSubscriptions } from 'utils/centrifuge-channels-handlers/publicChannelsHandler';
import { appLangToParticleAuthLangCode } from 'utils/particleAuth';

import { useAccount, useCampaignAnalytics, useElixirContractsAPI } from 'hooks';
import useModal from 'hooks/useModal';
import { marketIdToRoute } from 'hooks/useSwitchMarket';

import WhaleSupportCheck from 'components/CustomToasts/WhaleSupport/WhaleSupportCheck';

import GetTheApp from './components/CustomToasts/GetTheApp';
import { UserSettings, QueryKeys } from './enums';
import { TwitterCallback } from './hooks/twitter/TwitterCallback';
import { Layout } from './pages/Layout';
import NotFound from './pages/NotFound';
import { BFXInvite } from './pages/Portfolio/Pages/BFXInvite';
import { TVMobile } from './pages/TVMobile';
import Trade from './pages/Trade';
import { routes } from './pages/routes';
import CentrifugeService from './service/centrifugeService';
import {
  LS_DEFAULT_MARKET,
  LS_PENDING_TRANSACTIONS_TOAST_IDS,
} from 'constants/localStorageKeys';
import { NetworkContextName } from 'constants/networkContextName';
import { preloadedImagesSrcList } from 'constants/preloadedImages';
import { useAppContext } from 'contexts/AppContext';
import Markets from 'pages/Markets';
import PortfolioAirdrops from 'pages/Portfolio/Pages/Airdrops';
import AirdropDashboard from 'pages/Portfolio/Pages/Airdrops/AirdropDashboard';
import MigrateStrp from 'pages/Portfolio/Pages/MigrateStrp';
import PortfolioOrders from 'pages/Portfolio/Pages/Orders';
import PortfolioOverview from 'pages/Portfolio/Pages/Overview';
import PortfolioPositions from 'pages/Portfolio/Pages/Positions';
import PortfolioReferralDashboard from 'pages/Portfolio/Pages/ReferralDashboard';
import PortfolioReferralFees from 'pages/Portfolio/Pages/ReferralFees';
import PortfolioSettlements from 'pages/Portfolio/Pages/Settlements';
import PortfolioTrades from 'pages/Portfolio/Pages/Trades';
import PortfolioTransfers from 'pages/Portfolio/Pages/Transfers';
import SomethingWentWrong from 'pages/SomethingWentWrong';
import UpgradeNotice from 'pages/UpgradeNotice';
import Vaults from 'pages/Vaults';
import VaultOverview from 'pages/Vaults/Pages/VaultOverview';
import PlatformVaultOverview from 'pages/Vaults/Pages/Vaults/TabPanels/PlatformVaults/PlatformVaultOverview';
import { MarketService } from 'service/marketService';
import { mixpanelService } from 'service/mixpanelService';

import { config } from 'config';
import { socialconnect } from 'connectors';
import { Market } from 'interfaces';
import { observer } from 'mobx-react';
import { useFetchUserSettings } from 'queryHooks';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';

const GetDefaultComponent = (params: URLSearchParams) => {
  const savedMarketId = readUserSetting(LS_DEFAULT_MARKET);
  return <Navigate to={marketIdToRoute(savedMarketId, params)} replace />;
};

const App = () => {
  const { i18n } = useTranslation();
  const { library: networkProvider } =
    useWeb3React<Web3Provider>(NetworkContextName);
  const {
    store: {
      markets: marketsStore,
      account: accountStore,
      orderbook: orderbookStore,
      trades: tradesStore,
      centrifuge: centrifugeStore,
    },
  } = useAppContext();
  const { trackVaultActivities } = useElixirContractsAPI();
  useCampaignAnalytics();
  useFetchUserSettings(accountStore.frontendSecrets?.jwt);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { account } = useAccount();
  const modal = useModal();

  const previousSelectedMarket = useRef<null | string>(null);

  useQuery(
    QueryKeys.Markets,
    async () => {
      const marketService = new MarketService();
      const markets: Market[] | null = await marketService.fetchMarkets();
      if (!markets) {
        throw new Error(
          'An error occurred while trying to fetch markets on App component',
        );
      }
      return markets;
    },
    {
      refetchInterval: false,
      onSuccess: markets => {
        marketsStore.setLogoUrls(markets);
        marketsStore.setDecimalPlaces(markets);
        marketsStore.setMarketTitles(markets);
        preloadImages(markets.map(market => market.iconUrl));
      },
    },
  );

  useEffect(() => {
    // Preload market logos and other common images
    preloadImages(preloadedImagesSrcList);

    // init mixpanel
    mixpanelService.init();
  }, []);

  useEffect(() => {
    console.log('i18n.language', i18n.language);
    socialconnect.setLanguage(appLangToParticleAuthLangCode[i18n.language]);
  }, [i18n]);

  // const { fetchPrivateTransfers } = useBalanceOperationsAPI();

  // useEffect(() => {
  //   (async () => {
  //     if (!accountStore.frontendSecrets || !isBrandBfx) return;

  //     if (!isBfxDepositBonusActive) return;

  //     try {
  //       const depositsPostBfxBonusStartTime = await fetchPrivateTransfers({
  //         queryParams: {
  //           ops_type: BalanceOpsType.Deposit,
  //           start_time: new Date(START_TIME_BFX_DEPOSIT_BONUS).getTime() * 1000,
  //         },
  //       });

  //       const isUserRegisteredForBfxDepositBonus =
  //         depositsPostBfxBonusStartTime.data.length > 0;

  //       saveUserSetting(
  //         UserSettings.IS_USER_REGISTERED_FOR_BFX_DEPOSIT_BONUS,
  //         isUserRegisteredForBfxDepositBonus,
  //       );
  //     } catch (err) {
  //       console.error(
  //         'ERROR FETCHING isUserRegisteredForBfxDepositBonus : ',
  //         err,
  //       );
  //       saveUserSetting(
  //         UserSettings.IS_USER_REGISTERED_FOR_BFX_DEPOSIT_BONUS,
  //         false,
  //       );
  //     }
  //   })();
  // }, [accountStore.frontendSecrets]);

  // Centrifuge public channels (markets, trades, orderbook)
  useEffect(() => {
    if (!centrifugeStore.isPublicDisconnected) {
      handlePublicChannelsSubscriptions(centrifugeStore);
    }
  }, [centrifugeStore.isPublicDisconnected]);

  // Fetch initial markets
  useEffect(() => {
    if (!marketsStore) {
      return;
    }
    marketsStore.fetchAndSetInitialMarkets();
  }, [marketsStore]);

  // Show an alert only once in case of BFX stating that there is 3 percent deposit bonus"
  // useEffect(() => {
  //   if (!isBrandBfx || !isBfxDepositBonusActive) return;

  //   // check if the user has marked the checkbox to not show the popup again
  //   const dontShowBFXDepositPopup = readUserSetting(
  //     UserSettings.DONT_SHOW_BFX_DEPOSIT_POPUP_AGAIN,
  //   );

  //   if (
  //     !dontShowBFXDepositPopup &&
  //     !isRouteTVMobile(pathname) &&
  //     !SHOULD_DISABLE_DEPOSITS_WITHDRAWALS
  //   ) {
  //     modal.present(<DepositBonusModal />, Modals.bfxDepositBonusModal);
  //   }
  // }, []);

  // Show an alert only once when the user loads the page saying "airdrop 2 snapshot is now live!"
  // useEffect(() => {
  //   // check if the user has already seen the alert
  //   const hasSeenAirdrop2Alert = readUserSetting(
  //     UserSettings.ALERTS_HAS_SEEN_AIRDROP_2,
  //   );
  //   if (!hasSeenAirdrop2Alert && !isRouteTVMobile(pathname)) {
  //     // show the alert
  //     toast.info('Airdrop 2 snapshot is now live! Click here to learn more.', {
  //       position: 'top-center',
  //       onClick: () => {
  //         window.open(
  //           'https://medium.rabbitx.io/announcing-rabbitx-airdrop-2-6157b4b0ea3e',
  //           '_blank',
  //         );
  //       },
  //       autoClose: false,
  //       theme: 'colored',
  //       style: {
  //         backgroundColor: '#D43B4E',
  //         fontWeight: 500,
  //       },
  //     });
  //     // set the flag to true
  //     saveUserSetting(UserSettings.ALERTS_HAS_SEEN_AIRDROP_2, true);
  //   }
  // }, []);

  // Check and process pending transactions
  useEffect(() => {
    networkProvider?.off('block');
    if (
      !networkProvider ||
      accountStore.frontendSecrets?.profile.wallet === undefined
    ) {
      // Dismiss all toasts related to pending transactions
      const toastIds = readPendingTxToastIdsFromLocalStorage();
      toastIds.forEach((toastId: string | number) => {
        toast.dismiss(toastId);
      });
      localStorage.removeItem(LS_PENDING_TRANSACTIONS_TOAST_IDS);
      return;
    }

    const stopTrackingVaultAtivities = trackVaultActivities();

    checkAndProcessPendingTxs(
      networkProvider,
      accountStore.frontendSecrets.profile.wallet,
    );
    checkAndProcessPendingMeshTransfers(
      networkProvider,
      accountStore.frontendSecrets.profile.wallet,
      modal,
    );
    return () => stopTrackingVaultAtivities?.();
  }, [networkProvider, accountStore.frontendSecrets?.profile.wallet]);

  // Currently selected market websockets subscription
  useEffect(() => {
    if (!centrifugeStore.isPublicDisconnected) {
      handleMarketsChannelSubscription({
        tradesStore,
        marketsStore,
        orderbookStore,
        previousSelectedMarket,
      });
    }
  }, [
    marketsStore.selectedMarket?.id,
    marketsStore,
    orderbookStore,
    tradesStore,
    centrifugeStore.isPublicDisconnected,
  ]);

  // Account websockets subscription
  useEffect(() => {
    if (!centrifugeStore.isPrivateDisconnected) {
      handleAccountChannelSubscription(account, accountStore, centrifugeStore);
    }
  }, [
    accountStore,
    accountStore.frontendSecrets?.jwt,
    account,
    centrifugeStore.isPrivateDisconnected,
  ]);

  // fired when browser has lost access to the network
  window.addEventListener('offline', e => {
    centrifugeStore.setIsPublicConnInRecovery(true);
    centrifugeStore.setIsPublicDisconnected(true);
    centrifugeStore.setIsPrivateConnInRecovery(true);
    centrifugeStore.setIsPrivateDisconnected(true);
    CentrifugeService.disconnectPublic();
    CentrifugeService.disconnectPrivate();
  });

  // fired when browser gains access to the network
  window.addEventListener('online', e => {
    if (centrifugeStore.isPublicConnInRecovery) {
      centrifugeStore.setIsPublicConnInRecovery(false);
      previousSelectedMarket.current = null;
      tradesStore.selectedMarketID = '';
      orderbookStore.selectedMarketID = '';
      handlePublicChannelsSubscriptions(centrifugeStore);
    }

    if (account && centrifugeStore.isPrivateConnInRecovery) {
      centrifugeStore.setIsPrivateConnInRecovery(false);
      handleAccountChannelSubscription(account, accountStore, centrifugeStore);
    }
  });

  const getTheAppInitialized = useRef(false);
  useEffect(() => {
    if (!isBrandRabbitX) return;

    if (!getTheAppInitialized.current) {
      getTheAppInitialized.current = true;

      const dontShowGetTheApp = readUserSetting(
        UserSettings.DONT_SHOW_GET_THE_APP_POPUP_AGAIN,
      );

      if (!dontShowGetTheApp) {
        showGetTheApp();
      }
    }
  }, []);

  const showGetTheApp = () => {
    const toastId = toast(
      <GetTheApp
        onClosePress={() => {
          toast.dismiss(toastId);
        }}
      />,
      {
        position: 'bottom-left',
        autoClose: false,
        closeButton: false,
        hideProgressBar: true,
        pauseOnHover: false,
        draggable: true,
        closeOnClick: false,
        theme: 'dark',
        style: { background: 'none', overflow: 'visible' },
      },
    );
  };

  if (isRouteTVMobile(pathname)) {
    const backgroundColor = searchParams.get('backgroundColor');
    if (backgroundColor) {
      document.body.style.backgroundColor = `#${backgroundColor}`;
    }
    return <TVMobile />;
  }

  if (routes.invite.getRoutePath() === pathname) {
    return <BFXInvite />;
  }

  return (
    <Layout>
      {config.forceUpgrade ? (
        <UpgradeNotice />
      ) : (
        <>
          <WhaleSupportCheck />
          <ErrorBoundary fallback={<SomethingWentWrong />}>
            <Routes>
              {/* Dynamic Trade Page */}
              <Route
                path={`${routes.trade.getRoutePath()}/:pair`}
                element={<Trade />}
              />
              <Route path="/" element={GetDefaultComponent(searchParams)} />
              <Route
                path={routes.trade.getRoutePath()}
                element={GetDefaultComponent(searchParams)}
              />
              <>
                <Route path="/portfolio" element={<PortfolioOverview />} />
                <Route
                  path={routes.portfolio.overview.getRoutePath()}
                  element={<PortfolioOverview />}
                />
                <Route
                  path={routes.portfolio.getRoutePath()}
                  element={<PortfolioTrades />}
                />
                <Route
                  path={routes.portfolio.trades.getRoutePath()}
                  element={<PortfolioTrades />}
                />
                <Route
                  path={routes.portfolio.positions.getRoutePath()}
                  element={<PortfolioPositions />}
                />
                <Route
                  path={routes.portfolio.orders.getRoutePath()}
                  element={<PortfolioOrders />}
                />
                <Route
                  path={routes.portfolio.transfers.getRoutePath()}
                  element={<PortfolioTransfers />}
                />
                <Route
                  path={routes.portfolio.settlements.getRoutePath()}
                  element={<PortfolioSettlements />}
                />
                <Route
                  path={routes.portfolio.referrals.getRoutePath()}
                  element={<PortfolioReferralDashboard />}
                />
                <Route
                  path={routes.portfolio.referralFees.getRoutePath()}
                  element={<PortfolioReferralFees />}
                />
                {/* {isBrandBfx && (
              <Route
                path={routes.portfolio.leaderboard.getRoutePath()}
                element={<Leaderboard />}
              />
            )} */}
                {brand.featureFlags.airdrops.default && (
                  <Route
                    path={routes.portfolio.airdrops.getRoutePath()}
                    element={<PortfolioAirdrops />}
                  />
                )}
                <Route
                  path={routes.portfolio.airdrops.dashboard.getRoutePath()}
                  element={<AirdropDashboard />}
                />

                {brand.featureFlags.migrateStrp && (
                  <Route
                    path={routes.portfolio.migrate.getRoutePath()}
                    element={<MigrateStrp />}
                  />
                )}
              </>
              <Route
                path={routes.markets.getRoutePath()}
                element={<Markets />}
              />
              <Route path={routes.vaults.getRoutePath()} element={<Vaults />} />
              <Route
                path={routes.vaults.overview.getRoutePath()}
                element={<VaultOverview />}
              />
              <Route
                path={routes.vaults.platformOverview.getRoutePath()}
                element={<PlatformVaultOverview />}
              />
              <Route
                path={routes.callback.twitter.getRoutePath()}
                element={<TwitterCallback />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ErrorBoundary>
        </>
      )}
    </Layout>
  );
};

export default observer(App);
