import Text from 'components/Text';

import { StatusTx } from '../notifications';

import { AmendType } from '..';
import { useTranslation } from 'react-i18next';

type Props = {
  amendType: AmendType;
  tokenPair: string;
  amount: number | undefined;
};
const RequestSentButUntraceable = ({ amendType, tokenPair, amount }: Props) => {
  const { t } = useTranslation();
  const getRequestText =
    amendType === AmendType.Stake
      ? StatusTx.stakeRequestSentButUntracked
      : StatusTx.unstakeRequestSentButUntracked;
  return (
    <Text
      variant="BODY_M"
      fontWeight="semiBold"
      color="warningForeground100"
      className="align-center"
    >
      {getRequestText(tokenPair, amount, t).description}
    </Text>
  );
};

export default RequestSentButUntraceable;
