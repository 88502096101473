import styled from 'styled-components';

export const DropdownSelect = styled.div`
  cursor: pointer;
  display: flex;
  padding: 14px 12px;
  align-items: center;
  gap: 10px;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};
  border-radius: 8px;
`;
