import { memo, useState } from 'react';

import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import { StyledDarkTable } from '../../../../../../../styles';
import { VaultListProps } from '../types';
import EmptyVaultTableState from './EmptyState';
import TableHead from './TableHead';
import VaultRow from './VaultRow';
import { sortVaults } from './utils';

import styled from 'styled-components';

export enum SortBy {
  APY = 'APY',
  PPS = 'PPS',
  TVL = 'TVL',
}

export type SortConfig = {
  sortBy: SortBy;
  numOfClick: number;
};

const Seperator = styled.div`
  height: 1.5px;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  display: flex;
  width: 100%;
`;

const VaultTable = ({
  vaults,
  isLoading,
  isUserAuthenticated,
  vaultsWalletShares,
  ...rest
}: VaultListProps) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>();

  const sortedVaults = sortVaults(vaults, sortConfig);

  const segregatedVaults = {
    withPosition: [] as VaultWithContractInfo[],
    withoutPosition: [] as VaultWithContractInfo[],
  };

  // Segregate into vaults with Position (to be shown above seperator) and vaults without Position (to be shown below seperator)
  sortedVaults.forEach(vault => {
    if (
      vaultsWalletShares &&
      vaultsWalletShares[vault.productId].sharesAmount > 0
    )
      segregatedVaults.withPosition.push(vault);
    else {
      segregatedVaults.withoutPosition.push(vault);
    }
  });

  const handleSortBy = (sortBy: SortBy) => {
    setSortConfig(prev => ({
      sortBy,
      numOfClick: prev?.sortBy === sortBy ? (prev.numOfClick ?? 0) + 1 : 1,
    }));
  };

  return (
    <TableContainer>
      <StyledDarkTable stickyHeader>
        <TableHead onSetSortBy={handleSortBy} sortConfig={sortConfig} />

        {vaults.length > 0 ? (
          <TableBody>
            {segregatedVaults.withPosition.map(item => {
              return (
                <VaultRow
                  vault={item}
                  isUserAuthenticated={isUserAuthenticated}
                  walletShare={
                    vaultsWalletShares &&
                    vaultsWalletShares[item.productId].sharesAmount
                  }
                  {...rest}
                />
              );
            })}

            {segregatedVaults.withoutPosition.length !== 0 &&
            segregatedVaults.withPosition.length !== 0 ? (
              <TableRow className="seperator-row">
                <TableCell colSpan={100}>
                  <Seperator />
                </TableCell>
              </TableRow>
            ) : null}

            {segregatedVaults.withoutPosition.map(item => {
              return (
                <VaultRow
                  vault={item}
                  isUserAuthenticated={isUserAuthenticated}
                  walletShare={
                    vaultsWalletShares &&
                    vaultsWalletShares[item.productId].sharesAmount
                  }
                  {...rest}
                />
              );
            })}
          </TableBody>
        ) : (
          <EmptyVaultTableState isLoading={isLoading} />
        )}
      </StyledDarkTable>
    </TableContainer>
  );
};

export default memo(VaultTable);
