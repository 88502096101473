import { brandedSelect, isBrandBfx } from 'utils/brand';

import { FormattedNumber } from 'components';
import Loading from 'components/Loading';

import { getYourLocksAmount, getTotalLockedRbx } from '../../LockAndEarn';
import { getAllocation, LP_ALLOCATION } from '../../LockAndEarn/utils';
import {
  BFX_BADGE_HOVERABLE,
  BLASTER_SWAP_BADGE_HOVERABLE,
  TWICE_POINTS_BADGE_HOVERABLE,
} from '../../badges';
import LockOption from './LockOptionBoxLayout';
import lpAndEarnIcon from 'assets/icons/rbx-airdrop/lp-earn.svg';
import lpEarnBg from 'assets/images/lpEarnBg.webp';
import { Option } from 'pages/Portfolio/Pages/Airdrops/AirdropDashboard';
import {
  useFetchTotalLockedLpRbx,
  useFetchYourLocksLpRbx,
} from 'queryHooks/tokenLock';

import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';

const LpAndEarnOptionBox = () => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { data: totalLPLocked, isLoading: isLoadingTotalLPLocked } =
    useFetchTotalLockedLpRbx();
  const { data: yourLPLocks, isLoading: isLoadingYourLPLocks } =
    useFetchYourLocksLpRbx();
  const yourLPLocksAmount = getYourLocksAmount(yourLPLocks);
  const totalLPLockedAmount = totalLPLocked;

  return (
    <LockOption
      stats={[
        {
          label: 'Pool Allocation',
          value: (
            <FormattedNumber
              value={LP_ALLOCATION}
              decimalScale={0}
              suffix=" BFX PTS"
            />
          ),
        },
        {
          label: 'Total locked (All Chains)',
          value: isLoadingTotalLPLocked ? (
            <Loading size="10px" />
          ) : (
            <FormattedNumber value={totalLPLockedAmount} suffix=" LP" />
          ),
        },
        {
          label: 'Your Locked',
          value: isLoadingYourLPLocks ? (
            <Loading size="10px" />
          ) : (
            <FormattedNumber value={yourLPLocksAmount} suffix=" LP" />
          ),
        },
        {
          label: 'Allocated BFX Points',
          value: (
            <FormattedNumber
              value={getAllocation(
                yourLPLocksAmount ? parseFloat(yourLPLocksAmount) : 0,
                totalLPLocked ?? 0,
                LP_ALLOCATION,
              )}
              suffix=" BFX PTS"
            />
          ),
        },
      ]}
      ctaProps={{
        title: 'Lock LP',
        onClick: () => setSearchParams({ option: Option.LP_AND_EARN }),
      }}
      description={`Lock your RBX-WETH LP Tokens (${brandedSelect({
        rabbitx: 'Uniswap',
        bfx: 'Blasterswap',
      })}) to receive BFX Points`}
      title={'LP & Earn'}
      headerBanner={lpEarnBg}
      isClosed={true}
      headerIcon={lpAndEarnIcon}
      badges={
        isBrandBfx
          ? [
              BFX_BADGE_HOVERABLE,
              BLASTER_SWAP_BADGE_HOVERABLE,
              TWICE_POINTS_BADGE_HOVERABLE,
            ]
          : [BFX_BADGE_HOVERABLE]
      }
    />
  );
};

export default observer(LpAndEarnOptionBox);
