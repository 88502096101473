import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const MobileSidebarStyled = styled.div`
  position: fixed;
  z-index: 12;
  top: 0;
  width: 100vw;
  height: calc(var(--vh) * 100);
  background: rgba(52, 64, 84, 0.6);
  backdrop-filter: blur(16px);
`;

export const MobileSidebarContentStyled = styled.div`
  height: calc(100% - 20px);
  width: 236px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 12px 8px;
  transition: width 0.3s ease-out;
  z-index: 10;
  background-color: #141b29;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  overflow: visible;

  .top-part {
    display: flex;
    flex-direction: column;
    flex: auto;

    .logo > img {
      width: 100px;
      margin-bottom: 14px;

      @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
        margin-bottom: 28px;
      }
    }
  }

  .bottom-part {
    display: flex;
    flex: ini;
    flex-direction: column;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
    padding: 27px 16px;
  }
`;

export const MigrateStrpContainer = styled.div`
  margin-bottom: 10px;

  .separator {
    height: 2px;
    background: ${({ theme }) => theme.colors.shadesBackground600};
    width: 40%;
    margin: 0px 15px 10px 15px;

    @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
      margin: 20px 15px;
    }
  }

  > button {
    .migrate-text {
      display: block;
    }
    .icon-right {
      margin-left: 5px;
    }
  }
`;

export const CloseIconStyled = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 26px;
  right: 20px;
  cursor: pointer;
`;

type ListStyledProps = {
  avoidMarginAndPadding?: boolean;
};
export const ListStyled = styled.ul<ListStyledProps>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  list-style-type: none;
  padding: ${({ avoidMarginAndPadding }) =>
    avoidMarginAndPadding ? 0 : '5px 0px'};
  margin: ${({ avoidMarginAndPadding }) =>
    avoidMarginAndPadding ? 0 : '5px 0px'};
  flex: 1 0 0;
`;

type NavLinkProps = {
  isActive: boolean;
  isNested?: boolean;
};
export const NavLinkStyled = styled(NavLink)<NavLinkProps>`
  display: flex;

  text-decoration: none;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.white : theme.colors.shadesForeground200};
  margin: 2px 0px;
  padding: 3px 6px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  /* border-bottom: 3px solid
    ${({ isActive, theme }) =>
    isActive ? theme.colors.warningForeground100 : 'transparent'}; */
  font-weight: ${({ isActive, theme }) =>
    isActive ? theme.fontWeights.semiBold : 'normal'};
  margin-left: ${({ isNested }) => (isNested ? '16px' : '0')};

  /* background-color: red; */
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.shadesBackground700 : 'transaparent'};

  img {
    margin-right: 16px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0;
  }
`;

export const SeparatorStyled = styled.div`
  background: #747476;
  margin: 12px 0;
  height: 1px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
    margin: 10px 0;
  }
`;
