import { useRef, useState } from 'react';

import { pushDataLayerEvent } from 'utils';
import { base64ToUrl, fileToBase64 } from 'utils/file';

import { useOnClickOutside } from 'hooks';

import { Avatar, Container } from './styles';
import addIcon from 'assets/icons/plus.svg';
import { TSavedWallet } from 'enums/savedWallet';

import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';

const EMOJI_OPTIONS = ['🚀', '💰', '🌕', '💎', '🔒', '🧠', '🤑', '🧨'];

type Props = {
  emoji: string;
  image?: string;
  onUpdate: (input: Partial<Omit<TSavedWallet, 'address'>>) => void;
  onPickerVisibilityChange: (b: boolean) => void;
  isPickerOpenByDefault: boolean;
  isAuthenticated: boolean;
  twitterImage?: string;
};

const animateProps = {
  initial: { opacity: 0, scale: 0 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0 },
};

const WalletAvatarWithPicker = ({
  emoji,
  image,
  onUpdate,
  onPickerVisibilityChange,
  isPickerOpenByDefault,
  isAuthenticated,
  twitterImage,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPickerVisible, setIsPickerVisible] = useState(isPickerOpenByDefault);

  const togglePicker = (value?: boolean) => {
    setIsPickerVisible(prev => {
      const nextVal = value !== undefined ? value : !prev;
      nextVal && pushDataLayerEvent('wallet-avatar-picker-opened');
      onPickerVisibilityChange(nextVal);
      return nextVal;
    });
  };

  useOnClickOutside(containerRef, () => {
    togglePicker(false);
  });

  const handeImagePicked = async (file: File) => {
    pushDataLayerEvent('wallet-avatar-image-picked');
    togglePicker(false);
    const base64 = await fileToBase64(file);
    const image = base64ToUrl(base64);
    onUpdate({ image });
  };

  const handleEmojiPicked = (emoji: string) => {
    pushDataLayerEvent('wallet-avatar-emoji-picked');
    togglePicker(false);
    onUpdate({ emoji, image: undefined }); // reset the image to undefined, so avatar defaults to emoji
  };

  return (
    <Container ref={containerRef}>
      <AnimatePresence>
        <Avatar
          isAuthenticated={isAuthenticated}
          onClick={() => togglePicker()}
        >
          {image ? (
            <motion.img
              key="avatar"
              alt={emoji}
              src={image}
              className="img-avatar"
              {...animateProps}
            />
          ) : (
            <motion.span
              key="avatar"
              className="emoji-avatar"
              {...animateProps}
            >
              {emoji}
            </motion.span>
          )}
        </Avatar>
        {isPickerVisible && (
          <motion.div key="picker" className="picker" {...animateProps}>
            {EMOJI_OPTIONS.map(emoji => (
              <span
                onClick={() => handleEmojiPicked(emoji)}
                className="picker-item"
              >
                {emoji}
              </span>
            ))}
            {twitterImage ? (
              <img
                src={twitterImage}
                onClick={() => {
                  togglePicker(false);
                  onUpdate({ image: twitterImage });
                }}
                className="picker-item bg-shade"
                alt="add"
              />
            ) : null}
            <img
              src={addIcon}
              onClick={() => inputRef.current?.click()}
              className="picker-item bg-shade"
              alt="add"
            />
            <input
              ref={inputRef}
              type="file"
              accept="image/*"
              alt=""
              style={{ visibility: 'hidden', display: 'none' }}
              onChange={e =>
                e.target.files && handeImagePicked(e.target.files[0])
              }
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default observer(WalletAvatarWithPicker);
