import { useEffect } from 'react';

import { useWeb3React } from '@web3-react/core';

import { useEagerConnect, useInactiveListener } from '../../hooks';
import { NetworkContextName } from 'constants/networkContextName';

import { network } from 'connectors';

export default function Web3ReactManager({
  children,
}: {
  children: JSX.Element;
}) {
  // Injected provider
  const { active } = useWeb3React();
  // Network connector
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React(NetworkContextName);

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate it
  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network);
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active]);

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  return children;
}
