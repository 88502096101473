import { Dispatch, SetStateAction } from 'react';

import { capitalizeFirstLetter } from 'utils';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import FilterSelector from 'components/FilterSelector';
import DateRangeFilterSelector, {
  DateFilterRangeWithType,
} from 'components/FilterSelector/DateRangeFilterSelector';
import {
  LabelWithRadio,
  MarketAsFilterOption,
} from 'components/FilterSelector/OptionRenderers';

import { FilterOptionsContainer } from '../styles';
import { OrderStatusCol } from './OrderStatusCol';
import activityIcon from 'assets/icons/activity.svg';
import diceIcon from 'assets/icons/dice.svg';
import tradeIcon from 'assets/icons/portfolio-nav-items/trades-active.svg';
import { useAppContext } from 'contexts/AppContext';

import { SelectedOrdersType } from '.';
import { OrderStatus } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export type TOrderFilter = {
  marketId?: string | undefined;
  orderStatus?: OrderStatus;
  selectedOrdersType: SelectedOrdersType;
  dateRange?: DateFilterRangeWithType;
};

export const getSelectedOrderTypeLabel = (type?: SelectedOrdersType) => {
  if (type === SelectedOrdersType.LIMIT_AND_MARKET) return 'Limit & Market';
  if (type === SelectedOrdersType.STOP_ORDERS) return 'Stop Orders';
  return 'All';
};

type Props = {
  appliedFilters: TOrderFilter;
  onChangeFilter: Dispatch<SetStateAction<TOrderFilter>>;
};
const OrderFilterOptions = ({ appliedFilters, onChangeFilter }: Props) => {
  const { t } = useTranslation();
  const {
    store: {
      markets: { markets },
    },
  } = useAppContext();
  const marketLogo = useGetMarketLogoFromMarketId(appliedFilters.marketId);

  const handleFilterChange = (
    _value: TOrderFilter[keyof TOrderFilter],
    key: keyof TOrderFilter,
  ) => {
    const value =
      appliedFilters[key] === _value && key !== 'selectedOrdersType'
        ? undefined
        : _value;
    onChangeFilter({ ...appliedFilters, [key]: value });
  };

  return (
    <FilterOptionsContainer>
      <FilterSelector
        icon={tradeIcon}
        label={'Market'}
        value={appliedFilters?.marketId || 'All'}
        leftValueIcon={appliedFilters?.marketId ? marketLogo : undefined}
        renderOption={option => (
          <MarketAsFilterOption
            selected={option.id === appliedFilters?.marketId}
            market={option}
          />
        )}
        options={markets || []}
        onOptionSelect={({ id }) => handleFilterChange(id, 'marketId')}
      />

      <div className="seperator" />

      <FilterSelector
        icon={activityIcon}
        label={'Status'}
        value={
          appliedFilters.orderStatus ? (
            <OrderStatusCol
              status={appliedFilters.orderStatus}
              t={t}
              returnStrings
            />
          ) : (
            'All'
          )
        }
        renderOption={option => (
          <LabelWithRadio
            selected={option === appliedFilters.orderStatus}
            label={
              <OrderStatusCol
                status={option}
                t={t}
                variant="BODY_S"
                returnStrings
              />
            }
          />
        )}
        options={[
          OrderStatus.OPEN,
          OrderStatus.CLOSED,
          OrderStatus.CANCELED,
          OrderStatus.REJECTED,
        ]}
        onOptionSelect={option => handleFilterChange(option, 'orderStatus')}
      />

      <div className="seperator" />

      <FilterSelector
        icon={diceIcon}
        label={'Order Type'}
        value={getSelectedOrderTypeLabel(appliedFilters.selectedOrdersType)}
        renderOption={option => (
          <LabelWithRadio
            selected={option === appliedFilters?.selectedOrdersType}
            label={getSelectedOrderTypeLabel(option)}
          />
        )}
        options={[
          SelectedOrdersType.LIMIT_AND_MARKET,
          SelectedOrdersType.STOP_ORDERS,
        ]}
        onOptionSelect={option =>
          handleFilterChange(option, 'selectedOrdersType')
        }
      />

      <div className="seperator" />

      <DateRangeFilterSelector
        selectedRange={appliedFilters?.dateRange}
        onRangeSelect={range => handleFilterChange(range, 'dateRange')}
      />
    </FilterOptionsContainer>
  );
};

export default observer(OrderFilterOptions);
