import { Dispatch, memo } from 'react';

import Text from 'components/Text';

import { PriceStepKind } from '.';
import styled from 'styled-components';

type StyledSwitchProps = {
  index: number;
};

const StyledSwitch = styled(Text)<StyledSwitchProps>`
  display: flex;
  padding: 2px;
  align-items: center;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.shadesBackground800};

  > span {
    z-index: 1;
    transition: color 0.3s;
  }

  .usd {
    color: ${({ index, theme }) =>
      index === 0 ? theme.colors.white : theme.colors.shadesForeground200};
    padding: 4px 6px;
    box-sizing: border-box;
    width: 32px;
  }

  .percentage {
    color: ${({ index, theme }) =>
      index === 1 ? theme.colors.white : theme.colors.shadesForeground200};
    padding: 4px 10px;
    box-sizing: border-box;
    width: 29px;
  }

  .thumb {
    position: absolute;
    z-index: 0;
    height: calc(100% - 4px);
    top: 2px;
    width: ${({ index }) => (index === 0 ? '32px' : '29px')};
    border-radius: 100px;
    transition: margin-left 0.3s, width 0.3s;
    background-color: ${({ theme }) => theme.colors.shadesBackground500};
    margin-left: ${({ index }) => (index === 1 ? '32px' : '0px')};
  }
`;

type USDPercentSwapperProps = {
  selectedPriceKind: PriceStepKind;
  onChangePriceStepKind: Dispatch<PriceStepKind>;
};

const USDPercentSwapper = ({
  selectedPriceKind,
  onChangePriceStepKind,
}: USDPercentSwapperProps) => {
  const currentIndex = selectedPriceKind === PriceStepKind.PERCENTAGE ? 1 : 0;

  return (
    <StyledSwitch index={currentIndex} variant="BODY_XS">
      <span
        className="usd"
        onClick={() => onChangePriceStepKind(PriceStepKind.USD)}
      >
        USD
      </span>
      <span
        className="percentage"
        onClick={() => onChangePriceStepKind(PriceStepKind.PERCENTAGE)}
      >
        %
      </span>
      <div className="thumb" />
    </StyledSwitch>
  );
};

export default memo(USDPercentSwapper);
