import { createContext } from 'react';

import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse } from '@ethersproject/providers';

import { WithdrawalResponse } from 'hooks';

import { constants, ContractTransaction } from 'ethers';
import { ProfileBalanceOps } from 'interfaces';

export type DepositsAPI = {
  areReadContractsSet: boolean;
  USDTDecimals: number;
  getAccountUsdtBalance: () => Promise<BigNumber>;
  getUsdtBalance: (account: string) => Promise<BigNumber>;
  getAccountEthBalance: () => Promise<BigNumber>;
  getWithdrawableBalance: (account: string) => Promise<BigNumber>;
  isUsdtApproved: (amount?: number) => Promise<boolean>;
  getUsdtAllowance: () => Promise<BigNumber>;
  approve: (amount?: number) => Promise<TransactionResponse>;
  transferUsdtToL1SmartContract: (amount: BigNumber) => Promise<any>;
  sendTxHashToBackend: (
    amount: BigNumber,
    txHash: string,
  ) => Promise<ProfileBalanceOps>;
  withdraw: (amount: number) => Promise<ProfileBalanceOps>;
  withdrawWithdrawableBalanceOld: () => Promise<ContractTransaction>;
  withdrawWithdrawableBalance: (
    withdrawalResponse: WithdrawalResponse,
    wallet: string,
  ) => Promise<ContractTransaction>;
};

export const EmptyDepositsApiContext = {
  areReadContractsSet: false,
  USDTDecimals: 0,
  getAccountUsdtBalance: () => {
    return constants.Zero;
  },
  getUsdtBalance: () => {
    return constants.Zero;
  },
  getAccountEthBalance: () => {
    return constants.Zero;
  },
  isUsdtApproved: () => false,
  getUsdtAllowance: () => {
    return constants.Zero;
  },
  approve: () => {
    return {} as TransactionResponse;
  },
  transferUsdtToL1SmartContract: (amount: BigNumber) => {
    return {} as any;
  },
  sendTxHashToBackend: (amount: BigNumber, txHash: string) => {
    return {} as ProfileBalanceOps;
  },
  withdraw: (amount: number) => {
    return {} as ProfileBalanceOps;
  },
  getWithdrawableBalance: (account: string) => {
    return constants.Zero;
  },
  withdrawWithdrawableBalance: () => {
    return {} as ContractTransaction;
  },
  withdrawWithdrawableBalanceOld: () => {
    return {} as ContractTransaction;
  },
};
const DepositsApiContext = createContext<DepositsAPI | null>(null);

export { DepositsApiContext };
