import { memo, useState } from 'react';

import Text from 'components/Text';

import gridIcon from 'assets/icons/grid.svg';
import gridIconSelected from 'assets/icons/grid_selected.svg';
import rocketIcon from 'assets/icons/rocket.svg';
import rocketIconSelected from 'assets/icons/rocket_selected.svg';
import starIcon from 'assets/icons/star-gray.svg';
import starIconSelected from 'assets/icons/star_selected.svg';
import trendDownIcon from 'assets/icons/trend-down.svg';
import trendDownIconSelected from 'assets/icons/trend-down_selected.svg';
import trendUpIcon from 'assets/icons/trend-up.svg';
import trendUpIconSelected from 'assets/icons/trend-up_selected.svg';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type MarketFilterType =
  | 'All'
  | 'Favourites'
  | 'Top Gainers'
  | 'Top Losers'
  | 'New Listings';

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    margin-bottom: 0;
  }
`;

const Tab = styled.div`
  display: flex;
  padding: 6px 4px;
  gap: 3px;
  border-radius: 8px;

  color: ${({ theme }) => theme.colors.shadesForeground200};
  align-items: center;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  .icon {
    width: 16px;
  }

  &.selected,
  :hover {
    background: ${({ theme }) => theme.colors.shadesBackground700};
    color: ${({ theme }) => theme.colors.white};
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    padding: 6px 9px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    gap: 5px;
    padding: 8px 13px;
  }
`;

type TabItem = {
  label: string;
  icon: {
    default: string;
    selected: string;
  };
  value: MarketFilterType;
};
const createTabItems = (t: any): TabItem[] => [
  {
    label: t('All'),
    icon: {
      default: gridIcon,
      selected: gridIconSelected,
    },
    value: 'All',
  },
  {
    label: t('favourites'),
    icon: {
      default: starIcon,
      selected: starIconSelected,
    },
    value: 'Favourites',
  },
  {
    label: t('topGainers'),
    icon: {
      default: trendUpIcon,
      selected: trendUpIconSelected,
    },
    value: 'Top Gainers',
  },
  {
    label: t('topLosers'),
    icon: {
      default: trendDownIcon,
      selected: trendDownIconSelected,
    },
    value: 'Top Losers',
  },
  {
    label: t('newListings'),
    icon: {
      default: rocketIcon,
      selected: rocketIconSelected,
    },
    value: 'New Listings',
  },
];

type Props = {
  selectedFilter: MarketFilterType;
  setSelectedFilter: (filter: MarketFilterType) => void;
};
const TabFilters = ({ selectedFilter, setSelectedFilter }: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();

  const tabItems = createTabItems(t);

  const filteredTabItems = tabItems.filter(tabItem => {
    if (tabItem.value === 'Favourites') {
      return !!frontendSecrets?.jwt;
    }
    return true;
  });

  return (
    <Container>
      {filteredTabItems.map(tabItem => (
        <Tab
          key={tabItem.value}
          className={selectedFilter === tabItem.value ? 'selected' : ''}
          onClick={() => setSelectedFilter(tabItem.value)}
        >
          <img
            src={
              selectedFilter === tabItem.value
                ? tabItem.icon.selected
                : tabItem.icon.default
            }
            alt={tabItem.label}
            className="icon"
          />
          <Text variant="BODY_M">{tabItem.label}</Text>
        </Tab>
      ))}
    </Container>
  );
};

export default observer(TabFilters);
