import { useState, useEffect, useCallback } from 'react';

import { formatUnits } from '@ethersproject/units';

import {
  makeABitLessthanOrEqual,
  parseTimeUntil,
  roundJsDecimalToString,
  showNotification,
  verifyUserSession,
} from 'utils';
import { brandedSelect, isBrandBfx } from 'utils/brand';

import { useActiveWeb3React, useTokenLockAPI, useVerifyChainId } from 'hooks';
import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';
import useOnBlockUpdated from 'hooks/useOnBlockUpdated';
import useUniswapSdk from 'hooks/useUniswapSdk';

import { FormattedNumber, Icon } from 'components';
import Badge from 'components/Badge';
import Loading from 'components/Loading';
import { SelectDropdown } from 'components/SelectDropdown.tsx';
import SlippageSettings from 'components/SlippageSettingsModal';
import Text from 'components/Text';

import LockOptionLayout from '../Layout';
import { BFX_BADGE } from '../badges';
import ContractDetails from '../components/ContractDetails';
import InputAndActions from '../components/InputAndActions';
import { ActionType } from '../components/InputAndActions/LockUnlockSelector/enums';
import { RateAndSlippageDetails } from './RateAndSlippageDetails';
import SwapAndLockModal from './SwapAndLockModal';
import {
  ASSET_OPTIONS,
  ASSET_TO_ICON_MAP,
  LOCK_ALLOCATION,
  assetDisplayParser,
  getAllocation,
} from './utils';
import chevronDownIcon from 'assets/icons/chevron-down-gray.svg';
import chevronUpIcon from 'assets/icons/chevron-up-gray.svg';
import lockAndEarnBanner from 'assets/images/lockEarnBg.webp';
import { RBX_DECIMALS } from 'constants/contracts';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { ESTIMATED_GAS_FEE_OFFSET } from 'pages/Trade/components/AccountStats/NewDepositModal';
import {
  NATIVE_CURRENCY,
  uniswapSwapTokensMap,
} from 'pages/Trade/components/AccountStats/NewDepositModal/constants';
import {
  useFetchTotalLockedRbx,
  useFetchYourLocksRbx,
} from 'queryHooks/tokenLock';
import RealisticConfetti from 'react-canvas-confetti/dist/presets/realistic';
import { mixpanelService } from 'service/mixpanelService';
import { Row } from 'theme/globalStyledComponents/row';

import { NotificationType, QueryKeys, UniswapSwapTokens } from 'enums';
import { BigNumber } from 'ethers';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

const BFX_AIRDROP_ALLOCATION = 10_000_000;

export const getYourLocksAmount = (
  yourLocks:
    | {
        amount: BigNumber;
      }
    | undefined,
) => {
  if (!yourLocks) return undefined;

  return formatUnits(yourLocks.amount, RBX_DECIMALS);
};

export const getTotalLockedRbx = (totalLocked: BigNumber | undefined) => {
  if (!totalLocked) return undefined;

  return formatUnits(totalLocked, RBX_DECIMALS);
};

const DEFAULT_SLIPPAGE = 0.5;

const getCtaText = ({
  actionType,
  isAwaitingConfirmation,
  pendingTxSign,
}: {
  actionType: ActionType;
  isAwaitingConfirmation: boolean;
  pendingTxSign: boolean;
}) => {
  if (isAwaitingConfirmation) {
    return 'Awaiting Confirmation...';
  }

  if (pendingTxSign) {
    return 'Pending...';
  }

  return actionType === ActionType.LOCK ? 'Lock' : 'Unlock';
};

const getShouldDisableCta = ({
  actionType,
  inputValue,
  rbxBalance,
  isAwaitingConfirmation,
  pendingTxSign,
  lockReleaseTime,
  yourLocksAmount,
  isUnlocking,
}: {
  actionType: ActionType;
  inputValue: number | null;
  rbxBalance: number | undefined;
  isAwaitingConfirmation: boolean;
  pendingTxSign: boolean;
  lockReleaseTime: number | BigNumber | undefined;
  yourLocksAmount: number | undefined;
  isUnlocking: boolean;
}) => {
  if (isAwaitingConfirmation || pendingTxSign || isUnlocking) {
    return true;
  }

  if (actionType === ActionType.LOCK) {
    return !inputValue || !rbxBalance || inputValue > rbxBalance;
  }

  if (actionType === ActionType.UNLOCK) {
    if (!lockReleaseTime) return true;
    if (!yourLocksAmount) return true;
    const releaseTimeNum = Number(lockReleaseTime.toString()) * 1000;
    return Date.now() < releaseTimeNum;
  }

  return false;
};

const LockAndEarn = () => {
  const modal = useModal();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { account } = useActiveWeb3React();
  const { withdrawLockedTokensRbx } = useTokenLockAPI();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const [inputValue, setInputValue] = useState<number | null>(0);
  const [slippage, setSlippage] = useState(DEFAULT_SLIPPAGE);
  const [actionType, setActionType] = useState<ActionType>(ActionType.LOCK);
  const [selectedAsset, setSelectedAsset] = useState(UniswapSwapTokens.RBX);
  const [isAssetDropDownShown, setIsAssetDropDownShown] = useState(false);

  const [pendingTxSign, setPendingTxSign] = useState(false);
  const [isAwaitingConfirmation, setIsAwaitingConfirmation] = useState(false);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const IS_CLOSED = actionType === ActionType.LOCK;

  const showSwap = selectedAsset !== UniswapSwapTokens.RBX;

  const { getQuoteV2, getQuote, getCurrencyBalance } = useUniswapSdk();

  useEffect(() => {
    if (!frontendSecrets?.profile.wallet) {
      return;
    }

    mixpanelService.stakeLockRbxNavigate(frontendSecrets.profile.wallet);
  }, [frontendSecrets?.profile.wallet]);

  useOnBlockUpdated(() => {
    refetchCurrencyBalance();
    refetchQuote();
  });

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => {
        setShowConfetti(false);
      }, 2000);
    }
  }, [showConfetti]);

  const {
    data: totalLocked,
    isLoading: isLoadingTotalLocked,
    isError: isErrorTotalLocked,
    refetch: refetchTotalLocked,
  } = useFetchTotalLockedRbx();
  const {
    data: yourLocks,
    isLoading: isLoadingYourLocks,
    refetch: refetchYourLocks,
    isError: isErrorYourLocks,
  } = useFetchYourLocksRbx();

  /*const {
    data: rbxBalance,
    isLoading: isLoadingRbxBalance,
    refetch: refetchRbxBalance,
  } = useFetchAccountRbxBalance();*/

  const {
    isLoading: isCurrencyBalanceLoading,
    data: selectedTokenBalance,
    refetch: refetchCurrencyBalance,
  } = useQuery(
    [QueryKeys.CurrencyBalance, account, selectedAsset],
    async () => {
      const isNative = selectedAsset === UniswapSwapTokens.ETH;
      const res = await getCurrencyBalance(
        account as string,
        isNative ? NATIVE_CURRENCY : uniswapSwapTokensMap[selectedAsset],
      );

      if (isNative) {
        if (res > ESTIMATED_GAS_FEE_OFFSET) {
          return res - ESTIMATED_GAS_FEE_OFFSET;
        }
        return 0;
      }

      return res;
    },
    {
      enabled: !!account,
      // 10 seconds
      staleTime: 30_000,
    },
  );

  const {
    isLoading: isQuoteLoading,
    data: quote,
    refetch: refetchQuote,
  } = useQuery(
    [QueryKeys.SwapQuote, inputValue, selectedAsset],
    () =>
      isBrandBfx
        ? getQuoteV2({
            tokenIn: uniswapSwapTokensMap[selectedAsset],
            tokenOut: uniswapSwapTokensMap.RBX,
            amountIn: inputValue!,
          })
        : getQuote({
            tokenIn: uniswapSwapTokensMap[selectedAsset],
            tokenOut: uniswapSwapTokensMap.RBX,
            amountIn: inputValue!,
          }),
    {
      enabled: !!inputValue && selectedAsset !== UniswapSwapTokens.RBX,
      // 10 seconds
      staleTime: 10_000,
    },
  );

  const onReloadRate = () => {
    queryClient.removeQueries(QueryKeys.SwapQuote);
    refetchQuote();
  };

  const onSlippageEdit = () => {
    modal.present(
      <SlippageSettings
        defaultSlippage={slippage}
        onUpdate={updatedSlippage => {
          setSlippage(updatedSlippage);
        }}
      />,
      Modals.slippageSettingsModal,
    );
  };

  const onWithdrawLockedTokens = useCallback(async () => {
    if (!account) return;

    try {
      flushSync(() => {
        setPendingTxSign(true);
        setIsUnlocking(true);
      });

      if (!(await validateNetworkAndSwitchIfRequired('Restart the unlock'))) {
        return;
      }

      showNotification({
        title: 'Confirm Token Unlock',
        description: 'Please confirm the transaction in your wallet',
        type: NotificationType.Positive,
      });

      const tx = await withdrawLockedTokensRbx();

      showNotification({
        title: 'Tokens Unlock Started',
        description: 'Please wait until the transaction is confirmed',
        type: NotificationType.Positive,
      });

      flushSync(() => {
        setIsAwaitingConfirmation(true);
        setPendingTxSign(false);
      });

      await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showNotification({
        title: 'Successfully withdrew tokens',
        description: 'Your tokens have been withdrawn successfully',
        type: NotificationType.Positive,
      });
      setActionType(ActionType.LOCK);
    } catch (e) {
      console.error(e);
      showNotification({
        title: 'Error withdrawing tokens',
        description: 'An error occurred while withdrawing your tokens',
        type: NotificationType.Negative,
      });
    } finally {
      refetchYourLocks();
      refetchTotalLocked();
      refetchCurrencyBalance();
      setPendingTxSign(false);
      setIsAwaitingConfirmation(false);
      setIsUnlocking(false);
    }
  }, [
    account,
    withdrawLockedTokensRbx,
    refetchYourLocks,
    refetchTotalLocked,
    refetchCurrencyBalance,
  ]);

  const onCtaClick = useCallback(() => {
    if (modal.activeModals.some(i => i.name === Modals.lockTokenModal)) {
      return;
    }

    if (actionType === ActionType.LOCK) {
      if (!inputValue || !account) return;

      const roundedInputValue = Number(roundJsDecimalToString(inputValue));
      if (roundedInputValue === 0) {
        showNotification({
          title: 'Invalid Input',
          description: 'Please enter a valid amount to lock',
          type: NotificationType.Negative,
        });
        return;
      }

      modal.present(
        <SwapAndLockModal
          title={showSwap ? 'Swap & Lock' : 'Lock'}
          defaultSlippage={slippage}
          inputAmount={Number(roundJsDecimalToString(inputValue))}
          showSwapStep={showSwap}
          selectedAsset={selectedAsset}
          onShowConfetti={() => setShowConfetti(true)}
        />,
        Modals.lockTokenModal,
      );
    } else {
      onWithdrawLockedTokens();
    }
  }, [
    modal,
    actionType,
    inputValue,
    account,
    slippage,
    selectedAsset,
    showSwap,
    onWithdrawLockedTokens,
  ]);

  useKey(Key.Enter, onCtaClick);

  const parsedReleaseTime =
    parseTimeUntil(Number(yourLocks?.releaseTime.toString()) * 1_000_000) ??
    'Unlockable';

  const yourLocksAmount = getYourLocksAmount(yourLocks);
  const totalLockedAmount = totalLocked;
  // const estimatedAllocation = getEstimatedAllocation(
  //   Number(yourLocksAmount),
  //   Number(totalLockedAmount),
  // );

  const ctaText = getCtaText({
    actionType,
    isAwaitingConfirmation,
    pendingTxSign,
  });

  const shouldDisableCta = getShouldDisableCta({
    actionType,
    inputValue,
    rbxBalance: selectedTokenBalance,
    isAwaitingConfirmation,
    pendingTxSign,
    isUnlocking,
    lockReleaseTime: yourLocks?.releaseTime,
    yourLocksAmount: Number(yourLocksAmount),
  });

  const isLockActionType = actionType === ActionType.LOCK;

  const shouldDisableUnlock = !yourLocksAmount || Number(yourLocksAmount) === 0;
  const actionTypeInputValue = isLockActionType ? inputValue : yourLocksAmount;

  const parsedSelectedTokenBalance = makeABitLessthanOrEqual(
    selectedTokenBalance ?? 0,
    7,
  );

  const openGetRbx = () => {
    const getRbxUrl = brandedSelect({
      rabbitx:
        'https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x3ba925fdeae6b46d0bb4d424d829982cb2f7309e',
      bfx: 'https://blasterswap.com/swap?address0=0x4300000000000000000000000000000000000004&address1=0x236bb48fcF61ce996B2C8C196a9258c176100c7d',
    });
    window.open(getRbxUrl, '_blank');
  };

  const onChangeActionType = (v: ActionType) => {
    setInputValue(
      v === ActionType.LOCK
        ? 0
        : yourLocksAmount
        ? parseFloat(yourLocksAmount)
        : null,
    );
    setActionType(v);
  };

  return (
    <LockOptionLayout
      headerProps={{
        backgroundImage: lockAndEarnBanner,
        title: 'Lock & Earn',
        description: 'Lock $RBX (RabbitX Token) to receive BFX Points',
        badges: [BFX_BADGE],
      }}
    >
      <InputAndActions
        actionType={actionType}
        onChangeActionType={onChangeActionType}
        onCtaClick={onCtaClick}
        value={inputValue}
        onChange={setInputValue}
        ctaText={ctaText}
        disableCta={shouldDisableCta}
        hideInput={!isLockActionType}
        disabled={IS_CLOSED || !isLockActionType}
        disableUnlock={shouldDisableUnlock}
        partiallyDisabled={!isLockActionType && !shouldDisableCta}
        currencyComponent={
          isLockActionType ? (
            <SelectDropdown
              onDropdownShownChange={setIsAssetDropDownShown}
              options={ASSET_OPTIONS}
              onOptionSelect={setSelectedAsset}
              isOptionSelected={option => option === selectedAsset}
              InputElement={
                <Row>
                  <Text variant="BODY_M" color="white" gap={5} flexed>
                    <Icon src={ASSET_TO_ICON_MAP[selectedAsset]} />
                    {selectedAsset.toUpperCase()}
                  </Text>
                  <Icon
                    src={isAssetDropDownShown ? chevronUpIcon : chevronDownIcon}
                  />
                </Row>
              }
              displayTextParser={assetDisplayParser}
            />
          ) : (
            <Text variant="BODY_M" color="white" gap={5} flexed>
              <Icon src={ASSET_TO_ICON_MAP['RBX']} />
              RBX
            </Text>
          )
        }
      >
        {!isLockActionType && yourLocksAmount && Number(yourLocksAmount) > 0 ? (
          <div className="h-14">
            <Text flexed>Unlockable In</Text>

            {parsedReleaseTime}
          </div>
        ) : null}

        {isLockActionType ? (
          <div>
            <Text flexed>
              {t('available')}
              <Badge
                bgColor="shadesBackground800"
                padding="3px 4px"
                borderColor="shadesBackground700"
              >
                {selectedAsset.toUpperCase()}
              </Badge>
            </Text>

            <Row gap={5}>
              {isCurrencyBalanceLoading ? (
                <Loading size={12} />
              ) : (
                <FormattedNumber value={selectedTokenBalance} />
              )}
              <Badge
                bgColor="shadesBackground700"
                cursorPointer
                padding="4px 6px"
                variant="BODY_XS"
                borderColor="shadesBackground700"
                hoveredBgColor="shadesBackground900"
                color="white"
                className={IS_CLOSED ? 'cursor-not-allowed' : ''}
                onClick={() => {
                  if (IS_CLOSED) return;

                  if (!isCurrencyBalanceLoading)
                    setInputValue(parsedSelectedTokenBalance);
                }}
              >
                MAX
              </Badge>
            </Row>
          </div>
        ) : null}

        {selectedAsset === UniswapSwapTokens.RBX ? (
          <div onClick={openGetRbx} className="cursor-pointer">
            Don't have RBX?
            <Badge
              padding="6px"
              borderRadius={6}
              bgColor="positiveBackground100"
              color="positiveForeground200"
              hoveredBgColor="positiveBackground200"
              cursorPointer
            >
              Get RBX
            </Badge>
          </div>
        ) : null}

        {showSwap && isLockActionType && (
          <RateAndSlippageDetails
            onRateReload={onReloadRate}
            isUpdatingQuote={isQuoteLoading}
            onSlippageEdit={onSlippageEdit}
            slippage={slippage}
            amount={inputValue}
            rate={quote}
            asset={selectedAsset.toUpperCase()}
            avoidWrapper
          />
        )}
      </InputAndActions>

      {/* <ProgressContainer targetValue={400_000_000} currentValue={39_200_000} /> */}

      <ContractDetails
        isLoading={isLoadingTotalLocked || isLoadingYourLocks}
        isError={isErrorTotalLocked || isErrorYourLocks}
        stats={[
          {
            title: 'Total locked (All Chains)',
            value: <FormattedNumber value={totalLockedAmount} suffix=" RBX" />,
          },
          {
            title: 'Your Locked',
            value: <FormattedNumber value={yourLocksAmount} suffix=" RBX" />,
          },
          {
            title: 'Your BFX Points',
            value: (
              <FormattedNumber
                value={getAllocation(
                  yourLocksAmount ? parseFloat(yourLocksAmount) : 0,
                  totalLockedAmount,
                  LOCK_ALLOCATION,
                )}
                suffix=" BFX PTS"
              />
            ),
          },
          {
            title: 'Unlockable In',
            value: (
              <div>
                {yourLocksAmount && Number(yourLocksAmount) > 0
                  ? parsedReleaseTime
                  : '-'}
              </div>
            ),
          },
        ]}
      />
      {showConfetti ? (
        <RealisticConfetti
          autorun={{ duration: 2000, speed: 0.5 }}
          className="confetti-container"
          decorateOptions={options => ({ ...options, startVelocity: 30 })}
        />
      ) : null}

      {/* <YourContractStats
          stats={[
            { title: 'Allocation', redactedValue: true },
            { title: 'Vesting Period', redactedValue: true },
            { title: 'Total Claimed', redactedValue: true },
            { title: 'Vesting', redactedValue: true },
            { title: 'Available to Claim', redactedValue: true },
          ]}
        /> */}
    </LockOptionLayout>
  );
};

export default LockAndEarn;
