import { forwardRef } from 'react';

import { brand, isBrandRabbitX } from 'utils/brand';

import { Preview, ReferralCode } from './styles';
import giftIcon from 'assets/icons/gift.svg';
import previewBackgroundBfx from 'assets/images/invite-friends-background-bfx.png';
import previewBackgroundRabbitx from 'assets/images/invite-friends-background-rabbitx.png';

import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

type Props = {
  referralCode: string;
  linkUrl: string;
};

const previewBackground = isBrandRabbitX
  ? previewBackgroundRabbitx
  : previewBackgroundBfx;

const InvitePreview = forwardRef<HTMLDivElement, Props>(
  ({ referralCode, linkUrl }, ref) => {
    const { t } = useTranslation();

    return (
      <Preview>
        <div className="preview" ref={ref}>
          <img alt="logo" className="logo" src={brand.logo} />

          <img
            src={previewBackground}
            className="preview-image"
            alt="preview"
          />

          <QRCode size={30} className="qrcode" value={linkUrl} />

          <span className="bonus-text">
            {/*
              Commented as per gitlab issue 402
              {t('Receive\n100%\nBonus\n')}
              <span className="bonus-text-s">on first deposit</span>
              <img src={giftIcon} alt="gift" className="gift-icon" /> 
            */}
          </span>

          <ReferralCode>
            <span className="text-right">{t('referral\ncode')}</span>
            <div className="divider" />
            <span className="referral-code">{referralCode}</span>
          </ReferralCode>
        </div>
      </Preview>
    );
  },
);

export default InvitePreview;
