import Text from 'components/Text';
import { TextVariant } from 'components/Text/variant';

import styled from 'styled-components';

export const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Switcher = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;

  img {
    padding: 2px 0;
  }

  > span {
    cursor: pointer;
    font-size: ${TextVariant['BODY_S'].fontSize};
    line-height: ${TextVariant['BODY_S'].lineHeight};
    color: ${({ theme }) => theme.colors.shadesForeground200};
    &.selected {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const ValueAndToggle = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;

  > span {
    font-size: ${TextVariant['BODY_S'].fontSize};
    line-height: ${TextVariant['BODY_S'].lineHeight};
    color: ${({ theme }) => theme.colors.shadesForeground200};
    padding: 10px 12px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
    border-radius: 100px;
  }
`;

export const Separator = styled(Text)`
  width: 100%;
  gap: 10px;
  margin: 20px 0;

  .seperator {
    background: ${({ theme }) => theme.colors.shadesBackground700};
    height: 1.5px;
    flex: 1;
  }
`;

export const Warning = styled.div`
  color: ${({ theme }) => theme.colors.shadesForeground200};
  max-width: 360px;
  font-size: 14px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.negativeForeground200};
  max-width: 360px;
  font-size: 12px;
`;
