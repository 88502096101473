// We need to contruct the config from the response recieved on logging in ,
import { UserInfo } from '@particle-network/auth';

import { TSavedWallet } from 'enums/savedWallet';

import { ConnectorNames } from 'enums';

type ReturnConfig = Pick<
  TSavedWallet,
  'address' | 'connectorName' | 'socialLoginOptions' | 'image'
>;

/**
 * Constructs a particle saved configuration based on user information and address.
 *
 * @param {UserInfo} userInfo - The user information.
 * @param {string} address - The address to be used in the configuration.
 * @returns {ReturnConfig | undefined} The constructed configuration or undefined if unsuccessful.
 */
export const constructParticleSavedConfig = (
  userInfo: UserInfo,
  address: string,
): ReturnConfig | undefined => {
  let res: ReturnConfig = {
    address: address.toString().toLowerCase(),
    connectorName: ConnectorNames.SocialConnect,
    image: userInfo.thirdparty_user_info?.user_info.picture,
  };

  // Auth Method: email
  if (userInfo.email) {
    res.socialLoginOptions = {
      preferredAuthType: 'email',
      account: userInfo.email,
    };
    return res as ReturnConfig;
  }

  // Auth Method: phone
  if (userInfo.phone) {
    res.socialLoginOptions = {
      preferredAuthType: 'phone',
      account: userInfo.phone,
    };
    return res as ReturnConfig;
  }

  // Auth Method: google (not email)
  if (userInfo.google_id) {
    res.socialLoginOptions = {
      preferredAuthType: 'google',
      account: userInfo.google_id,
    };
    return res as ReturnConfig;
  }

  // Auth Method: apple
  if (userInfo.apple_id) {
    res.socialLoginOptions = {
      preferredAuthType: 'apple',
      account: userInfo.apple_email || userInfo.apple_id,
    };
    return res as ReturnConfig;
  }

  // Auth Method: twitter
  if (userInfo.twitter_id) {
    res.socialLoginOptions = {
      preferredAuthType: 'twitter',
      account: userInfo.twitter_email || userInfo.twitter_id,
    };
    return res as ReturnConfig;
  }

  // Auth Method: discord
  if (userInfo.discord_id) {
    res.socialLoginOptions = {
      preferredAuthType: 'discord',
      account: userInfo.discord_email || userInfo.discord_id,
    };
    return res as ReturnConfig;
  }

  // Auth Method: twitch
  if (userInfo.twitch_email) {
    res.socialLoginOptions = {
      preferredAuthType: 'twitch',
      account: userInfo.twitch_email,
    };
    return res as ReturnConfig;
  }

  // Auth Method: facebook
  if (userInfo.facebook_email) {
    res.socialLoginOptions = {
      preferredAuthType: 'facebook',
      account: userInfo.facebook_email,
    };

    return res as ReturnConfig;
  }

  // Auth Method: linked_in
  if (userInfo.linkedin_email) {
    res.socialLoginOptions = {
      preferredAuthType: 'linkedin',
      account: userInfo.linkedin_email,
    };
    return res as ReturnConfig;
  }

  // Auth Method: microsoft
  if (userInfo.microsoft_email) {
    res.socialLoginOptions = {
      preferredAuthType: 'microsoft',
      account: userInfo.microsoft_email,
    };
    return res as ReturnConfig;
  }

  // Auth Method: github
  if (userInfo.github_id) {
    res.socialLoginOptions = {
      preferredAuthType: 'github',
      account: userInfo.github_email || userInfo.github_id,
    };
    return res as ReturnConfig;
  }

  return undefined;
};

export const appLangToParticleAuthLangCode = {
  en: 'en',
  ko: 'ko',
  ja: 'ja',
  zh: 'zh-CN',
  'zh-Hant': 'zh-TW',
};
