// @todo: back to normal later...
export const ONBOARDING_MESSAGE = 'Welcome to Rabbit DEX';

export const TWITTER_KEYS = {
  // clientId: 'eTBFMzNCWGVfd09YM2RjT05ZclI6MTpjaQ',
  // clientKeys: 'PzNLNZm_8HJoz0TrH7oaVquaHM6t_z7LLdvPsbs4ic9RJUyj0a',

  // // BFX
  // clientId: 'b3pGdlhBV2xYMzhWcUc2c2lPRUQ6MTpjaQ',
  // clientKeys: '6omJ7M0FNRYZzg9LklyFnyJimnZKekSej02kgak9rirR160h8V',

  // RABBTIX
  clientId: 'dG5iMnJ4Tnl0YklvWE94dnBfbWw6MTpjaQ',
  clientKeys: 'Ct5EPUmBiRQtc9m73Q7UHE0NCrw3nzbAGXQlwJGnERF2UHxUm8',
};
export const SIGNATURE_LIFETIME = 90;
