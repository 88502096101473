export * from './gaEventActions';
export * from './gaEventCategories';
export * from './orderType';
export * from './tradeSide';
export * from './filledTypes';
export * from './depositOrWithdrawalStatus';
export * from './takerSide';
export * from './orderbookSide';
export * from './arrowState';
export * from './endpoints';
export * from './websocketChannels';
export * from './requestMethods';
export * from './accountStatus';
export * from './liquiditySide';
export * from './notificationType';
export * from './orderStatus';
export * from './profileStatus';
export * from './profileType';
export * from './markets';
export * from './apiMappings';
export * from './timescaleType';
export * from './userSettings';
export * from './balanceOpsType';
export * from './queryKeys';
export * from './lsTransactionTypes';
export * from './depositMethod';
export * from './uniswapSwapTokens';
export * from './chain';

export enum ComponentsName {
  Trade = 'Trade',
  PortfolioOverview = 'Portfolio/Overview',
  PortfolioTrades = 'Portfolio/Trades',
  PortfolioLeaderboard = 'Portfolio/Leaderboard',
  PortfolioPositions = 'Portfolio/Positions',
  PortfolioOrders = 'Portfolio/Orders',
  PortfolioTransfers = 'Portfolio/Transfers',
  PortfolioSettlements = 'Portfolio/Settlements',
  PortfolioMigrate = 'Portfolio/Migrate',
  PortfolioReferralFees = 'Portfolio/ReferralFees',
  PortfolioReferrals = 'Portfolio/Referrals',
  Documentation = 'Documentation',
  Markets = 'Markets',
  Vaults = 'Vaults',
}

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,
  SEPOLIA = 11155111,
  BLAST_MAINNET = 81457,
  BLAST_SEPOLIA = 168587773,

  BSC = 56,
  BSC_TESTNET = 97,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,

  LOCAL = 31337,
}

export enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
  SocialConnect = 'SocialConnect',
}
