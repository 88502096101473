import { memo } from 'react';

import { TableCell, TableHead, TableRow } from '@material-ui/core';

const FundingRatesTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell key={0}>Time</TableCell>
        <TableCell key={1}>Interval</TableCell>
        <TableCell key={2} align="right">
          Rate
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default memo(FundingRatesTableHead);
