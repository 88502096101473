import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;

  @media (min-width: ${({ theme }) => theme.screenSizes.xxl}px) {
    padding-top: 100px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  .heading {
    > h3 {
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.4px;
    }
    > span {
      font-size: 13px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.shadesForeground50};
    }
  }

  .boxes {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
      align-items: flex-start;
      align-content: flex-start;
      align-self: stretch;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`;

export const Box = styled.div<{ bgImg: string; blurred?: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  min-width: 300px;
  transition: all ease-in-out 0.2s;

  :hover {
    margin-top: -5px;
    margin-bottom: 5px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    border-radius: 12px;
    position: relative;
    border: ${({ theme, blurred }) =>
      blurred ? `1px solid ${theme.colors.shadesBackground500}` : 'none'};
    overflow: hidden;
  }

  .badges {
    position: absolute;
    right: 10px;
    top: -8px;
    display: flex;
    gap: 5px;
    z-index: 4;
  }

  .locked-button {
    background: ${({ theme }) => theme.colors.shadesBackground500};
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .heading {
    background: url(${({ bgImg }) => bgImg}) top / cover no-repeat;
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    z-index: 3;
    position: relative;
    border-radius: 12px;
    filter: ${({ blurred }) => (blurred ? 'blur(6px)' : 'none')};

    /* background: url(<path-to-image>) lightgray 50% / cover no-repeat; */

    > img {
      width: 46px;
      height: 46px;
    }

    > .description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      > h4 {
        font-size: 16px;
        font-size: 13px;
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        margin: 0;
        line-height: initial;
      }
      > span {
        font-size: 11px;
        color: ${({ theme }) => theme.colors.shadesForeground200};
      }
    }
  }
`;

export const BoxLabel = styled.div`
  position: absolute;
  right: 3.5px;
  top: -8px;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.primaryGreenBackground500};

  > span {
    font-size: 11px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primaryGreenForeground100};
  }
`;

export const BoxContent = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  z-index: 2;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: -12px;
  padding-top: 32px;
`;

export const BoxItems = styled.div<{ blurred?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  flex: 1;
  align-self: stretch;
  filter: ${({ blurred }) => (blurred ? 'blur(6px)' : 'none')};
  overflow: hidden;
`;

export const BoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  > div:last-child {
    align-items: flex-end;
  }
`;

export const BoxItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  .label {
    font-size: 11px;
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
  .value {
    font-size: 11px;
  }
`;

export const HorizontalSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(20, 36, 49, 0) 0%,
    #213c4b 50.09%,
    rgba(20, 36, 49, 0) 100%
  );
`;

export const VerticalSeparator = styled.div`
  position: absolute;
  width: 1px;
  background: linear-gradient(
    90deg,
    rgba(20, 36, 49, 0) -29.85%,
    #213c4b 49.77%,
    rgba(20, 36, 49, 0) 129.1%
  );
  // center align
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
`;
