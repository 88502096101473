import { useState } from 'react';

import { TableCell } from '@material-ui/core';

import {
  USD_DECIMAL_SCALE,
  roundToNearestTick,
  timestampToLocalDateWithTime,
} from 'utils';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import { useSwitchMarket } from 'hooks/useSwitchMarket';

import { FormattedNumber } from 'components';
import CollapseIcon from 'components/CollapseIcon';

import LabelAndValueCell from '../components/LabelAndValueCell';
import MarketLogoWithTradeSide from '../components/MarketLogoWithTradeSide';
import { CollapsibleTableRow } from '../styles';

import { TradeSide } from 'enums';
import { Fill, Order } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const IconTableCell = styled(TableCell)`
  width: 16px;
  vertical-align: top;
`;

export interface FillRowProps {
  showLabels: boolean;
  collapsible?: boolean;
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
  fill: Fill;
}
export const FillsRow = observer(
  ({
    showLabels,
    collapsible,
    isCollapsed = true,
    toggleCollapse,
    fill,
  }: FillRowProps) => {
    const { switchMarketById } = useSwitchMarket();
    const { t } = useTranslation();

    const { price, size, marketID, side, liquidation, fee, timestamp } = fill;

    const decimalPlaces = useGetDecimalPlaces(marketID);

    const valueUsd = roundToNearestTick(
      size * price,
      USD_DECIMAL_SCALE.minOrder,
    );

    if (collapsible && toggleCollapse) {
      return (
        <CollapsibleTableRow isCollapsed={isCollapsed} onClick={toggleCollapse}>
          <TableCell className="cursor-pointer">
            <MarketLogoWithTradeSide
              limitWidth
              marketID={marketID}
              onClick={e => {
                e.stopPropagation();
                switchMarketById(marketID);
              }}
              tradeSide={side}
            />

            {!isCollapsed ? (
              <LabelAndValueCell
                showLabel={showLabels}
                className="mt-10 ml-32"
                limitWidth
                isCell={false}
                label={t('liq')}
                value={liquidation ? 'True' : 'False'}
              />
            ) : null}
          </TableCell>

          <TableCell>
            <LabelAndValueCell
              showLabel={showLabels}
              limitWidth
              isCell={false}
              label={t('filled')}
              value={
                <FormattedNumber
                  value={size}
                  decimalScale={decimalPlaces.size}
                  disableTooltip={true}
                />
              }
            />

            {!isCollapsed ? (
              <LabelAndValueCell
                showLabel={showLabels}
                className="mt-10"
                limitWidth
                isCell={false}
                label={t('fee')}
                value={
                  <FormattedNumber value={fee} decimalScale={6} prefix="$" />
                }
              />
            ) : null}
          </TableCell>

          <LabelAndValueCell
            showLabel={showLabels}
            verticalAlign="top"
            limitWidth
            label={t('valueUsd')}
            value={<FormattedNumber value={valueUsd} prefix="$" />}
          />

          <LabelAndValueCell
            showLabel={showLabels}
            verticalAlign="top"
            limitWidth
            label={t('price')}
            value={
              <FormattedNumber
                value={price}
                decimalScale={decimalPlaces.price}
                disableTooltip={true}
              />
            }
          />

          <LabelAndValueCell
            showLabel={showLabels}
            limitWidth
            verticalAlign="top"
            label={t('time')}
            value={timestampToLocalDateWithTime(timestamp)}
          />

          <IconTableCell>
            <CollapseIcon isCollapsed={isCollapsed} />
          </IconTableCell>
        </CollapsibleTableRow>
      );
    }

    return (
      <CollapsibleTableRow isCollapsed={isCollapsed}>
        <TableCell
          onClick={() => switchMarketById(marketID)}
          className="cursor-pointer"
        >
          <MarketLogoWithTradeSide marketID={marketID} tradeSide={side} />
        </TableCell>

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('filled')}
          value={
            <FormattedNumber
              value={size}
              decimalScale={decimalPlaces.size}
              disableTooltip={true}
            />
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('valueUsd')}
          value={<FormattedNumber value={valueUsd} prefix="$" />}
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('price')}
          value={
            <FormattedNumber
              value={price}
              decimalScale={decimalPlaces.price}
              disableTooltip={true}
            />
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('liq')}
          value={
            liquidation === undefined ? '-' : liquidation ? 'True' : 'False'
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('fee')}
          value={<FormattedNumber value={fee} decimalScale={6} prefix="$" />}
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('time')}
          value={timestampToLocalDateWithTime(timestamp)}
        />
      </CollapsibleTableRow>
    );
  },
);

export const CollapsibleFillRow = observer((props: FillRowProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <FillsRow
      collapsible={true}
      isCollapsed={isCollapsed}
      toggleCollapse={() => {
        setIsCollapsed(prev => !prev);
      }}
      {...props}
    />
  );
});
