import { useEffect } from 'react';

import { readUserSetting, saveUserSetting } from 'utils';

import { CAMPAIGN_ANALYTICS } from 'constants/localStorageKeys';

import { isEqual, omit } from 'lodash';
import { useSearchParams } from 'react-router-dom';

export interface CampaignAnalytics {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  ts: string;
  http_referer?: string;
}

const UTM_TAGS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

const getAllowedUtmKeys = (searchParams: URLSearchParams): string[] =>
  Array.from(searchParams.keys()).filter(key =>
    UTM_TAGS.includes(key.toLowerCase()),
  );

const generateCampaignObj = (
  utmKeys: string[],
  searchParams: URLSearchParams,
  referrer?: string,
): CampaignAnalytics => ({
  ts: new Date().toISOString(),
  ...utmKeys.reduce(
    (acc, key) => ({ ...acc, [key]: searchParams.get(key) }),
    {},
  ),
  ...(referrer ? { http_referer: referrer } : {}),
});

const saveToLocalStorage = (data: CampaignAnalytics) => {
  const existingData = readUserSetting(CAMPAIGN_ANALYTICS) ?? [];
  saveUserSetting(CAMPAIGN_ANALYTICS, [...existingData, data]);
};

const getLastCampaignEntry = (): CampaignAnalytics | null => {
  const lsCampaignAnalytics = readUserSetting(CAMPAIGN_ANALYTICS) ?? [];
  return lsCampaignAnalytics.length > 0
    ? lsCampaignAnalytics[lsCampaignAnalytics.length - 1]
    : null;
};

const updateCampaignAnalytics = (
  lastCampaignEntry: CampaignAnalytics | null,
  newCampaignEntry: CampaignAnalytics,
) => {
  // Check if history empty or last history entry is same as the utm tags that you have now from query string
  // if it's the same, ignore it
  if (
    !isEqual(omit(lastCampaignEntry, ['ts']), omit(newCampaignEntry, ['ts']))
  ) {
    saveToLocalStorage(newCampaignEntry);
  }
};

export const useCampaignAnalytics = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const utmKeys = getAllowedUtmKeys(searchParams);
    if (utmKeys.length === 0) return;

    const campaignMetadata = generateCampaignObj(
      utmKeys,
      searchParams,
      document.referrer,
    );
    const lastCampaignEntry = getLastCampaignEntry();
    updateCampaignAnalytics(lastCampaignEntry, campaignMetadata);
  }, [searchParams]);
};
