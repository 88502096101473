import { memo } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import { timestampToLocalDateWithTime } from 'utils';
import { brand } from 'utils/brand';

import { FormattedNumber } from 'components';

import { ProfileBalanceOps } from 'interfaces';

interface Props {
  referralFee: ProfileBalanceOps;
}
const ReferralFeesRow = ({ referralFee }: Props) => {
  const { amount, timestamp } = referralFee;

  return (
    <TableRow>
      <TableCell>
        <span className="flex items-center gap-8">
          <img className="asset-logo" src={brand.tokenLogo} alt="Market logo" />
          {brand.tokenTicker}
        </span>
      </TableCell>
      <TableCell>
        <FormattedNumber value={amount} prefix="$" />
      </TableCell>
      <TableCell>{timestampToLocalDateWithTime(timestamp)}</TableCell>
    </TableRow>
  );
};

export default memo(ReferralFeesRow);
