import { memo } from 'react';

import {
  TableCell,
  TableRow,
  TableHead as TableHeadMui,
} from '@material-ui/core';

import caretDown from 'assets/icons/caret-down.svg';
import caretUp from 'assets/icons/caret-up.svg';

import { SortConfig } from '.';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type SortConfigItems =
  | 'id'
  | 'lastTradePrice'
  | 'lastTradePrice24hChangeBasis'
  | 'lastFundingRateBasis'
  | 'openInterest'
  | 'marketVolume24h'
  | 'marketCap';

export const CellContainer = styled.div`
  cursor: pointer;
  display: flex;
  .sorting-icon {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    margin-left: 8px;
    img {
      width: 8px;
      &.active {
        opacity: 0.5;
      }
    }
  }
`;

const getClassName = (
  sortConfig: SortConfig | null,
  value: SortConfigItems,
  numClicks: number,
): string => {
  return sortConfig?.value === value && sortConfig?.numClicks % 3 === numClicks
    ? 'active'
    : '';
};

type Props = {
  sortConfig: SortConfig | null;
  onSetSortConfig: (label: SortConfigItems) => void;
};
const TableHead = ({ sortConfig, onSetSortConfig }: Props) => {
  const { t } = useTranslation();

  const renderCell = (
    label: string,
    sortItemValue: SortConfigItems,
    alignRight?: boolean,
  ) => {
    return (
      <CellContainer
        onClick={() => onSetSortConfig(sortItemValue)}
        className={alignRight ? 'justify-end' : ''}
      >
        <span>{label}</span>
        <div className="sorting-icon">
          <img
            className={getClassName(sortConfig, sortItemValue, 2)}
            src={caretUp}
            alt={'Caret up icon'}
          />
          <img
            className={getClassName(sortConfig, sortItemValue, 1)}
            src={caretDown}
            alt={'Caret down icon'}
          />
        </div>
      </CellContainer>
    );
  };

  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>{renderCell(t('market'), 'id')}</TableCell>
        <TableCell key={1}>
          {renderCell(t('price'), 'lastTradePrice')}
        </TableCell>
        <TableCell key={2}>
          {renderCell(t('24hChange'), 'lastTradePrice24hChangeBasis')}
        </TableCell>
        <TableCell key={3}>
          {renderCell(t('1hFundingRate'), 'lastFundingRateBasis')}
        </TableCell>
        <TableCell key={4}>
          {renderCell(t('openInterest'), 'openInterest')}
        </TableCell>
        <TableCell key={5} align="right">
          {renderCell(t('24hVolume'), 'marketVolume24h', true)}
        </TableCell>
        <TableCell key={6} align="right">
          {renderCell(t('marketCap'), 'marketCap', true)}
        </TableCell>
        {/* <TableCell key={6} align="right">
          {t('24hLongShort')}
        </TableCell> */}
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
