import blastLogo from 'assets/assetLogos/blast.svg';
import ethLogo from 'assets/assetLogos/eth.svg';
import usdbLogo from 'assets/assetLogos/usdb.svg';
import usdtLogo from 'assets/assetLogos/usdt.svg';
import bfxFavicon from 'assets/logos/bfx-head.jpg';
import bfxLogo from 'assets/logos/bfx-logo.svg';
import rabbitXFavicon from 'assets/logos/rabbit-x-head.svg';
import rabbitXLogo from 'assets/logos/rabbit-x-logo.svg';

import { config } from 'config';
import { BrandName } from 'types';

const getBrand = () => {
  switch (config.brandName) {
    case 'bfx':
      return {
        featureFlags: {
          elixirVaults: false,
          platformVaults: true,
          migrateStrp: false,
          airdrops: { blastAirdrop: true, default: false },
          transak: false,
          swap: true,
          syncWithDesktop: false,
          poweredByTheTieNews: false,
          news: false,
        },
        appTitle: 'BFX — #1 Perpetuals DEX with Native Yield',
        appDescription: 'Trade Futures on Blast? Trade on Blast Futures.',
        logo: bfxLogo,
        favicon: bfxFavicon,
        appShortName: 'BFX',
        tagline: 'Trade To Win.',
        onboardingMessage:
          'Welcome to Bfx!\n\nClick to sign in and on-board your wallet for trading perpetuals.\n\nThis request will not trigger a blockchain transaction or cost any gas fees. This signature only proves you are the true owner of this wallet.\n\nBy signing this message you agree to the terms and conditions of the exchange.',
        typedDataDomainName: 'BfxId',
        tocLink: 'https://blastfutures.com/terms-of-use',
        docsLink: 'https://docs.blastfutures.com',
        apiDocsLink: 'https://docs.rabbitx.io/api-documentation',
        privacyPolicyLink: 'https://blastfutures.com/privacy-policy',
        telegramUrl: 'https://t.me/BlastFutures',
        appUrl: config.isProd
          ? 'https://bfx.trade'
          : 'https://testnet.bfx.trade',
        blockExplorerUrl: {
          testnet: 'https://testnet.blastscan.io',
          prod: 'https://blastscan.io',
        },
        rewardTokenTicker: 'BFX',
        tokenTicker: 'USDB',
        tokenLogo: usdbLogo,
        chainLogo: blastLogo,
        chainName: 'Blast',
        blockTimeSeconds: 2,
        brandId: 'bfx',
      };
    case 'rabbitx':
    default:
      return {
        featureFlags: {
          elixirVaults: true,
          platformVaults: false,
          migrateStrp: true,
          airdrops: { blastAirdrop: false, default: true },
          transak: true,
          swap: true,
          syncWithDesktop: true,
          poweredByTheTieNews: true,
          news: false,
          blastPoints: false,
        },
        appTitle: 'RabbitX - Trade Decentralised Perpetuals',
        appDescription:
          'Zero-fee decentralised derivatives exchange (DEX) built on Starknet. 20x leverage, global access.',
        logo: rabbitXLogo,
        favicon: rabbitXFavicon,
        appShortName: 'RabbitX',
        tagline: 'Trade. Earn. Win.',
        onboardingMessage:
          'Welcome to RabbitX!\n\nClick to sign in and on-board your wallet for trading perpetuals.\n\nThis request will not trigger a blockchain transaction or cost any gas fees. This signature only proves you are the true owner of this wallet.\n\nBy signing this message you agree to the terms and conditions of the exchange.',
        typedDataDomainName: 'RabbitXId',
        tocLink: 'https://landing.rabbitx.io/terms-of-use',
        docsLink: 'https://docs.rabbitx.io',
        apiDocsLink: 'https://docs.rabbitx.io/api-documentation',
        privacyPolicyLink: 'https://landing.rabbitx.io/privacy-policy',
        telegramUrl: 'https://t.me/stripsfi',
        appUrl: config.isProd
          ? 'https://app.rabbitx.io'
          : 'https://testnet.rabbitx.io',
        blockExplorerUrl: {
          testnet: 'https://sepolia.etherscan.io',
          prod: 'https://etherscan.io',
        },
        rewardTokenTicker: 'RBX',
        tokenTicker: 'USDT',
        tokenLogo: usdtLogo,
        chainLogo: ethLogo,
        chainName: 'Ethereum',
        blockTimeSeconds: 15,
        brandId: 'rbx',
      };
  }
};

export const isBrandRabbitX = config.brandName === 'rabbitx';
export const isBrandBfx = config.brandName === 'bfx';

export const brand = getBrand();

export const brandedSelect = <T>(input: Record<BrandName, T>) =>
  input[config.brandName];
