import { brand, isBrandBfx } from 'utils/brand';

import buildRouteTree from 'build-route-tree';

export const tradePathRegex = /^\/trade(\/[a-zA-Z]+|\/|-)?$/;

export const routes = buildRouteTree({
  trade: null,
  markets: null,
  portfolio: {
    overview: null,
    trades: null,
    positions: null,
    orders: null,
    transfers: null,
    settlements: null,
    referrals: null,
    referralFees: null,
    ...(isBrandBfx
      ? {
          leaderboard: null,
        }
      : {}),
    airdrops: {
      dashboard: null,
    },
    ...(brand.featureFlags.migrateStrp
      ? {
          migrate: null,
        }
      : {}),
  },

  vaults: {
    overview: null,
    platformOverview: null,
  },
  invite: null,
  callback: {
    twitter: null,
  },
}) as any;
