import { memo, useState } from 'react';

import { AirdropUserStats } from '../../../PhaseContainer';
import DropdownToggle from '../../shared/DropdownToggle';
import { Container, Content } from '../styles';
import checkmarkIcon from 'assets/icons/checkmark.svg';
import closeIcon from 'assets/icons/close-red.svg';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TRADING_VOLUME_ELIGIBILITY_THRESHOLD_USD = 100;
const AVG_OPEN_INTEREST_ELIGIBILITY_THRESHOLD_USD = 10;
const PROFIT_AND_LOSS_ELIGIBILITY_THRESHOLD_USD = 5;
const AVERAGE_DEPOSIT_ELIGIBILITY_THRESHOLD_USD = 10;

const EligibilityCriteria = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.shadesForeground200};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  > img {
    margin-right: 5px;
  }
`;

const CriteriaDescriptionBox = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.colors.shadesBackground600};
  border-radius: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.shadesForeground200};
`;

type EligibilityCheckProps = {
  airdropUserStats: AirdropUserStats;
};
const EligibilityCheck = ({ airdropUserStats }: EligibilityCheckProps) => {
  const { tradingVolume, pnl, avgOpenInterest, isEligible, avgDeposit } =
    airdropUserStats || {};
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(!isEligible);

  const isTradingVolumeEligible =
    tradingVolume !== undefined &&
    tradingVolume >= TRADING_VOLUME_ELIGIBILITY_THRESHOLD_USD;
  const isAvgOpenInterestEligible =
    avgOpenInterest !== undefined &&
    avgOpenInterest >= AVG_OPEN_INTEREST_ELIGIBILITY_THRESHOLD_USD;
  const isProfitAndLossEligible =
    pnl !== undefined &&
    Math.abs(pnl) >= PROFIT_AND_LOSS_ELIGIBILITY_THRESHOLD_USD;
  const isAverageDepositEligible =
    avgDeposit !== undefined &&
    avgDeposit >= AVERAGE_DEPOSIT_ELIGIBILITY_THRESHOLD_USD;

  return (
    <Container>
      <DropdownToggle
        isExpanded={isExpanded}
        label={t('eligibilityCheck')}
        handleClick={() => setIsExpanded(prevExpanded => !prevExpanded)}
        enableToggle={isEligible}
      />
      <Content isExpanded={isExpanded}>
        <EligibilityCriteria>
          <img
            src={isTradingVolumeEligible ? checkmarkIcon : closeIcon}
            alt="Eligibility check icon"
          />{' '}
          {t('tradingVolumeAbove$100')}
        </EligibilityCriteria>
        <EligibilityCriteria>
          <img
            src={isAverageDepositEligible ? checkmarkIcon : closeIcon}
            alt="Eligibility check icon"
          />{' '}
          {t('averageTimeDepositAbove$10')}
        </EligibilityCriteria>
        <EligibilityCriteria>
          <img
            src={isProfitAndLossEligible ? checkmarkIcon : closeIcon}
            alt="Eligibility check icon"
          />{' '}
          {t('profitOrLossAbove$5')}
        </EligibilityCriteria>
        <EligibilityCriteria>
          <img
            src={isAvgOpenInterestEligible ? checkmarkIcon : closeIcon}
            alt="Eligibility check icon"
          />{' '}
          {t('averageOpenInterestAbove$10')}
        </EligibilityCriteria>
        <CriteriaDescriptionBox>
          {t('minimumOf3PointsTotalRequired')}
        </CriteriaDescriptionBox>
      </Content>
    </Container>
  );
};

export default memo(EligibilityCheck);
