/* Autogenerated file. Do not edit manually. */

/* tslint:disable */

/* eslint-disable */
import type { RabbitManager, RabbitManagerInterface } from "../RabbitManager";
import type { Provider } from "@ethersproject/providers";
import { Contract, Signer, utils } from "ethers";

const _abi = [
  {
    type: "constructor",
    inputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "addPool",
    inputs: [
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "hardcap",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "externalAccount",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "claim",
    inputs: [
      {
        name: "user",
        type: "address",
        internalType: "address",
      },
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "claimPaused",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "deposit",
    inputs: [
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "receiver",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "depositPaused",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "elixirFee",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "elixirGas",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getUserActiveAmount",
    inputs: [
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "user",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getUserFee",
    inputs: [
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "user",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getUserPendingAmount",
    inputs: [
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "user",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "initialize",
    inputs: [
      {
        name: "_rabbit",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "nextSpot",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "tuple",
        internalType: "struct IRabbitManager.Spot",
        components: [
          {
            name: "sender",
            type: "address",
            internalType: "address",
          },
          {
            name: "router",
            type: "address",
            internalType: "address",
          },
          {
            name: "spotType",
            type: "uint8",
            internalType: "enum IRabbitManager.SpotType",
          },
          {
            name: "transaction",
            type: "bytes",
            internalType: "bytes",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "owner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "pause",
    inputs: [
      {
        name: "_depositPaused",
        type: "bool",
        internalType: "bool",
      },
      {
        name: "_withdrawPaused",
        type: "bool",
        internalType: "bool",
      },
      {
        name: "_claimPaused",
        type: "bool",
        internalType: "bool",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "pools",
    inputs: [
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "router",
        type: "address",
        internalType: "address",
      },
      {
        name: "poolType",
        type: "uint8",
        internalType: "enum IRabbitManager.PoolType",
      },
      {
        name: "activeAmount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "hardcap",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "processSpot",
    inputs: [
      {
        name: "spot",
        type: "tuple",
        internalType: "struct IRabbitManager.Spot",
        components: [
          {
            name: "sender",
            type: "address",
            internalType: "address",
          },
          {
            name: "router",
            type: "address",
            internalType: "address",
          },
          {
            name: "spotType",
            type: "uint8",
            internalType: "enum IRabbitManager.SpotType",
          },
          {
            name: "transaction",
            type: "bytes",
            internalType: "bytes",
          },
        ],
      },
      {
        name: "response",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "proxiableUUID",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "queue",
    inputs: [
      {
        name: "",
        type: "uint128",
        internalType: "uint128",
      },
    ],
    outputs: [
      {
        name: "sender",
        type: "address",
        internalType: "address",
      },
      {
        name: "router",
        type: "address",
        internalType: "address",
      },
      {
        name: "spotType",
        type: "uint8",
        internalType: "enum IRabbitManager.SpotType",
      },
      {
        name: "transaction",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "queueCount",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint128",
        internalType: "uint128",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "queueUpTo",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint128",
        internalType: "uint128",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "rabbit",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "contract IRabbitX",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "renounceOwnership",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "rescue",
    inputs: [
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "token",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "contract IERC20Metadata",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "transferOwnership",
    inputs: [
      {
        name: "newOwner",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "unqueue",
    inputs: [
      {
        name: "spotId",
        type: "uint128",
        internalType: "uint128",
      },
      {
        name: "response",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updatePoolHardcap",
    inputs: [
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "hardcap",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "upgradeTo",
    inputs: [
      {
        name: "newImplementation",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "upgradeToAndCall",
    inputs: [
      {
        name: "newImplementation",
        type: "address",
        internalType: "address",
      },
      {
        name: "data",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "withdraw",
    inputs: [
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "withdrawPaused",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "event",
    name: "AdminChanged",
    inputs: [
      {
        name: "previousAdmin",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "newAdmin",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "BeaconUpgraded",
    inputs: [
      {
        name: "beacon",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Claim",
    inputs: [
      {
        name: "user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Deposit",
    inputs: [
      {
        name: "router",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "caller",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "receiver",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "id",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "shares",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Initialized",
    inputs: [
      {
        name: "version",
        type: "uint8",
        indexed: false,
        internalType: "uint8",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OwnershipTransferred",
    inputs: [
      {
        name: "previousOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "newOwner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "PauseUpdated",
    inputs: [
      {
        name: "depositPaused",
        type: "bool",
        indexed: true,
        internalType: "bool",
      },
      {
        name: "withdrawPaused",
        type: "bool",
        indexed: true,
        internalType: "bool",
      },
      {
        name: "claimPaused",
        type: "bool",
        indexed: true,
        internalType: "bool",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "PoolAdded",
    inputs: [
      {
        name: "id",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "router",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "hardcap",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "PoolHardcapUpdated",
    inputs: [
      {
        name: "id",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "hardcap",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Queued",
    inputs: [
      {
        name: "spot",
        type: "tuple",
        indexed: false,
        internalType: "struct IRabbitManager.Spot",
        components: [
          {
            name: "sender",
            type: "address",
            internalType: "address",
          },
          {
            name: "router",
            type: "address",
            internalType: "address",
          },
          {
            name: "spotType",
            type: "uint8",
            internalType: "enum IRabbitManager.SpotType",
          },
          {
            name: "transaction",
            type: "bytes",
            internalType: "bytes",
          },
        ],
      },
      {
        name: "queueCount",
        type: "uint128",
        indexed: false,
        internalType: "uint128",
      },
      {
        name: "queueUpTo",
        type: "uint128",
        indexed: false,
        internalType: "uint128",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Upgraded",
    inputs: [
      {
        name: "implementation",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Withdraw",
    inputs: [
      {
        name: "router",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "user",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "error",
    name: "ClaimsPaused",
    inputs: [],
  },
  {
    type: "error",
    name: "DepositsPaused",
    inputs: [],
  },
  {
    type: "error",
    name: "FeeTooLow",
    inputs: [
      {
        name: "value",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "fee",
        type: "uint256",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "error",
    name: "FeeTransferFailed",
    inputs: [],
  },
  {
    type: "error",
    name: "HardcapReached",
    inputs: [
      {
        name: "hardcap",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "activeAmount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "error",
    name: "InvalidPool",
    inputs: [
      {
        name: "id",
        type: "uint256",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "error",
    name: "InvalidSpot",
    inputs: [
      {
        name: "spotId",
        type: "uint128",
        internalType: "uint128",
      },
      {
        name: "queueUpTo",
        type: "uint128",
        internalType: "uint128",
      },
    ],
  },
  {
    type: "error",
    name: "InvalidSpotType",
    inputs: [
      {
        name: "spot",
        type: "tuple",
        internalType: "struct IRabbitManager.Spot",
        components: [
          {
            name: "sender",
            type: "address",
            internalType: "address",
          },
          {
            name: "router",
            type: "address",
            internalType: "address",
          },
          {
            name: "spotType",
            type: "uint8",
            internalType: "enum IRabbitManager.SpotType",
          },
          {
            name: "transaction",
            type: "bytes",
            internalType: "bytes",
          },
        ],
      },
    ],
  },
  {
    type: "error",
    name: "NotExternalAccount",
    inputs: [
      {
        name: "router",
        type: "address",
        internalType: "address",
      },
      {
        name: "externalAccount",
        type: "address",
        internalType: "address",
      },
      {
        name: "caller",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    type: "error",
    name: "NotSelf",
    inputs: [],
  },
  {
    type: "error",
    name: "WithdrawalsPaused",
    inputs: [],
  },
  {
    type: "error",
    name: "ZeroAddress",
    inputs: [],
  },
] as const;

export class RabbitManager__factory {
  static readonly abi = _abi;
  static createInterface(): RabbitManagerInterface {
    return new utils.Interface(_abi) as RabbitManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): RabbitManager {
    return new Contract(address, _abi, signerOrProvider) as RabbitManager;
  }
}
