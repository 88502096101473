import { SyntheticEvent, useEffect, useRef, useState } from 'react';

import { pushDataLayerEvent } from 'utils';

import useModal from 'hooks/useModal';

import Button, { ButtonProps } from 'components/Button/button';
import Modal from 'components/Modal';
import Text from 'components/Text';

import { Modals } from 'constants/modals';

import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledText = styled(Text)`
  width: 348px;
  margin: 0 auto;
`;

type Props = {
  title: React.ReactNode;
  description: React.ReactNode;
  onButtonRightPress: () => void | Promise<void>;
  buttonRightTitle: string;
  onButtonLeftPress?: () => void;
  buttonLeftTitle?: string;
  buttonRightProps?: ButtonProps & { gtmId?: string };
  buttonLeftProps?: ButtonProps & { gtmId?: string };
  dataGtmId?: string;
  treatAsASync?: boolean; // If true, the modal will be treated as an async modal, and the onButtonRightPress will be awaited, else, everything will be handled after the modal is closed
  name?: string;
  children?: React.ReactNode;
};

const ConfirmationModal = ({
  title,
  description,
  onButtonRightPress,
  buttonLeftTitle = 'Cancel',
  buttonRightTitle,
  onButtonLeftPress,
  buttonRightProps,
  buttonLeftProps,
  dataGtmId,
  treatAsASync = true,
  name = Modals.confirmationModal,
  children,
}: Props) => {
  const modal = useModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!treatAsASync) {
      modal.pop({ name: name });
      onButtonRightPress();
      return;
    }

    try {
      setIsLoading(true);
      await onButtonRightPress();
      modal.pop({ name: name });
    } catch (e: any) {
      // This wouldn't get called if onButtonRightPress handles its own errors
      console.log('An error occurred : ', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => {
    onButtonLeftPress?.();
    dataGtmId && pushDataLayerEvent(`${dataGtmId}-close`);
    modal.pop({ name: name });
  };

  /* Trading View disables all listeners, so when modals are presented fronm TV, we need to focus something on modal to register key listeners */
  useEffect(() => {
    confirmButtonRef.current?.focus();
    confirmButtonRef.current?.blur();
  }, [confirmButtonRef]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit(event);
      }
      if (event.key === 'Escape') {
        onCancel();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      gtmId={dataGtmId}
      showHeader={true}
      title={title}
      size={'small'}
      name={name}
      showCloseIcon={false}
    >
      <StyledText
        variant="BODY_M"
        color="shadesForeground200"
        preWrap
        className="align-center justify-center"
        flexed
      >
        {description}
      </StyledText>

      {children ? <div className="mt-20">{children}</div> : null}

      <ButtonGroup className="mt-20">
        <Button
          colorVariant="secondary"
          sizeVariant="S"
          block={true}
          onClick={onCancel}
          data-gtmid={buttonLeftProps?.gtmId}
          {...buttonLeftProps}
        >
          {buttonLeftTitle}
        </Button>
        <Button
          ref={confirmButtonRef}
          colorVariant={'primaryGreen'}
          sizeVariant="S"
          block={true}
          onClick={handleSubmit}
          disabled={isLoading}
          isLoading={isLoading}
          data-gtmid={buttonRightProps?.gtmId || `button-${dataGtmId}-confirm`}
          {...buttonRightProps}
        >
          {buttonRightTitle}
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default ConfirmationModal;
