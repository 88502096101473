import { useState } from 'react';

import {
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { useBalanceOperationsAPI } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';

import TableLoader from 'components/TableLoader';

import { usePaginationFetch } from '../../../hooks/usePaginationFetch';
import { StyledTable, TableContainer } from '../styles';
import TableHead from './TableHead';
import TransfersFilterOptions, {
  TTransfersFilter,
} from './TransfersFilterOptions';
import BaseTransfersRow, {
  CollapsibleTransfersRow,
  TransfersRowProps,
} from './TransfersRow';
import { useAppContext } from 'contexts/AppContext';
import { QueryParams } from 'service/restService';

import { QueryKeys } from 'enums';
import { ProfileBalanceOps } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const constructRequestQueryParamsFilters = (filter: TTransfersFilter) => {
  const params = {};

  if (filter.type) {
    params['ops_type'] = filter.type;
  }

  if (
    filter.dateRange &&
    filter.dateRange.range &&
    filter.dateRange.range[0] &&
    filter.dateRange.range[1]
  ) {
    params['start_time'] = filter.dateRange.range[0];
    params['end_time'] = filter.dateRange.range[1];
  }

  return params;
};

const TransfersRow = ({
  isRowCollapsible,
  ...rest
}: {
  isRowCollapsible: boolean;
} & TransfersRowProps) =>
  isRowCollapsible ? (
    <CollapsibleTransfersRow {...rest} />
  ) : (
    <BaseTransfersRow {...rest} />
  );

type TransfersTableProps = {
  isFetchingWithdrawableBalance: boolean;
  includePagination?: boolean;
  requestQueryParams?: QueryParams;
  showTableHead?: boolean;
  showLabelOnTableCell?: boolean;
  showFilterOptions?: boolean;
  isRowCollapsible?: boolean;
};
const TransfersTable = ({
  isFetchingWithdrawableBalance,
  includePagination,
  requestQueryParams = {},
  showTableHead = true,
  showLabelOnTableCell = false,
  showFilterOptions = false,
  isRowCollapsible = false,
}: TransfersTableProps) => {
  const { isOnboarding } = useExchangeAPI();
  const { t } = useTranslation();
  const { fetchPrivateTransfers } = useBalanceOperationsAPI();
  const {
    store: {
      account: { frontendSecrets, _balanceOperations },
    },
  } = useAppContext();
  const [appliedFilters, setAppliedFilters] = useState<TTransfersFilter>({});

  const {
    isLoading,
    data: transfers,
    pagination,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePaginationFetch<ProfileBalanceOps>({
    fetchFn: fetchPrivateTransfers,
    socketSource: includePagination ? null : _balanceOperations,
    initialPagination: { page: 0, limit: 10 },
    currentAuthenticatedWallet: frontendSecrets?.profile?.wallet,
    requestQueryParams: {
      ...constructRequestQueryParamsFilters(appliedFilters),
      ...requestQueryParams,
    },
    isOnboarding,
    queryKey: QueryKeys.Transfers,
  });

  if (isLoading || totalCount === null || !frontendSecrets) {
    return (
      <>
        {showFilterOptions ? (
          <TransfersFilterOptions
            appliedFilters={appliedFilters}
            onChangeFilter={setAppliedFilters}
          />
        ) : null}
        <TableContainer>
          <StyledTable>
            {showTableHead ? <TableHead /> : null}
            <TableBody>
              {!frontendSecrets ? (
                <TableRow>
                  <TableCell colSpan={100} className="no-items">
                    {t('connectYourWalletToStartTrading')}
                  </TableCell>
                </TableRow>
              ) : (
                <TableLoader />
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </>
    );
  }

  const { limit, page } = pagination;

  return (
    <>
      {showFilterOptions ? (
        <TransfersFilterOptions
          appliedFilters={appliedFilters}
          onChangeFilter={setAppliedFilters}
        />
      ) : null}
      <TableContainer useScroll={!includePagination}>
        <StyledTable stickyHeader>
          {showTableHead ? <TableHead /> : null}
          <TableBody>
            {transfers.length > 0 ? (
              transfers.map(item => {
                const key = item.id;
                return (
                  <TransfersRow
                    isRowCollapsible={isRowCollapsible}
                    showLabels={showLabelOnTableCell}
                    balanceOperation={item}
                    key={key}
                  />
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={100} className="no-items align-left">
                  You have no transfers.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
        {includePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>
  );
};

export default observer(TransfersTable);
