import { TableCell } from '@material-ui/core';

import EmptyText from 'components/EmptyText';
import Icon from 'components/Icon';

import { IconTableCell } from './OrdersHistoryRow';
import { Column, Row } from 'theme/globalStyledComponents';

import styled from 'styled-components';

const StyledTableRow = styled.tr`
  position: relative;
  overflow: hidden;

  width: 100%;
  padding: 0 !important;
  border-radius: 0px !important;

  .ml-32 {
    margin-left: 32px;
  }

  .empty-icon {
    background: ${({ theme }) => theme.colors.shadesBackground800};
  }

  :hover {
    background: ${({ theme }) => theme.colors.shadesBackground900} !important;
  }

  .shimmer {
    top: 0px;
    height: calc(100%);
    width: 100%;
    background: ${({ theme }) =>
      `linear-gradient(
      -45deg,
      ${theme.colors.shadesBackground900}00 40%,
      ${theme.colors.shadesBackground900} 50%,
      ${theme.colors.shadesBackground900}00 60%
    )`};

    position: absolute;
    background-size: 300%;
    background-position-x: 100%;
    z-index: 10;

    animation: shimmer 1s infinite linear;

    @keyframes shimmer {
      to {
        background-position-x: 0%;
      }
    }
  }

  > td {
    vertical-align: top;
    position: relative;
  }
`;

const LoadingChildFills = () => {
  return (
    <StyledTableRow className="bg-900">
      <TableCell>
        <Column gap={10} className="market">
          <Row gap={8}>
            <Icon isEmpty className="empty-icon" size="L" />
            <Column>
              <EmptyText width={48} />
              <EmptyText width={102} />
            </Column>
          </Row>
          <Column className="ml-32">
            <EmptyText width={70} />
            <EmptyText width={48} />
          </Column>
        </Column>
        <div className="shimmer" />
      </TableCell>
      <TableCell>
        <Column className="mb-10">
          <EmptyText width={70} />
          <EmptyText width={48} />
        </Column>
        <Column>
          <EmptyText width={70} />
          <EmptyText width={48} />
        </Column>
        <div className="shimmer" />
      </TableCell>
      <TableCell>
        <Column>
          <EmptyText width={80} />
          <EmptyText width={48} />
        </Column>
        <div className="shimmer" />
      </TableCell>
      <TableCell>
        <Column>
          <EmptyText width={70} />
          <EmptyText width={48} />
        </Column>
        <div className="shimmer" />
      </TableCell>
      <TableCell>
        <Column>
          <EmptyText width={30} />
          <EmptyText width={102} />
        </Column>
        <div className="shimmer" />
      </TableCell>
      <TableCell>
        <Column>
          <EmptyText width={30} />
          <EmptyText width={102} />
        </Column>
        <div className="shimmer" />
      </TableCell>
      <IconTableCell></IconTableCell>
    </StyledTableRow>
  );
};

export default LoadingChildFills;
