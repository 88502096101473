import { ParticleNetwork } from '@particle-network/auth';

import { showNotification, textWithEllipsisInTheMiddle } from 'utils';

import { useAccount, useActiveWeb3React } from 'hooks';
import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';

import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import { SocialWalletModal } from 'components/SocialWalletModal/SocialWalletModal';

import { connectorsByName } from '../../../../../constants';
import WalletItem from './WalletItem';
import { Container } from './styles';
import addIcon from 'assets/icons/plus-gray.svg';
import { ParticleAuthConnector } from 'connectors/particleAuthConnector';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { TSavedWallet } from 'enums/savedWallet';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import { constructWalletIdentifier } from 'stores/savedWalletsStore';

import { doesCurrentChainSupportParticleAuth, socialconnect } from 'connectors';
import { ConnectorNames, NotificationType } from 'enums';
import { observer } from 'mobx-react';
import { useUserRegisteredForBlastAirdrop } from 'queryHooks';
import { useTranslation } from 'react-i18next';

const isWalletAuthenticated = (
  savedWalletId: string,
  account: string | null | undefined,
  cachedVaultAddress: string | undefined,
) => {
  return account
    ? savedWalletId === constructWalletIdentifier(account, cachedVaultAddress)
    : false;
};

const sortWalletsByIsAuthenticated = (
  wallets: TSavedWallet[],
  account: string | null | undefined,
  cachedVaultAddress: string | undefined,
) => {
  return wallets.slice().sort((a, b) => {
    if (isWalletAuthenticated(a.id, account, cachedVaultAddress)) {
      return -1;
    } else if (isWalletAuthenticated(b.id, account, cachedVaultAddress)) {
      return 1;
    } else {
      return 0;
    }
  });
};

type Props = {
  onPickerVisibilityChange: (b: boolean) => void;
  isPickerOpen: boolean;
  onClose: () => void;
};

const ManageWalletDropdown = ({
  onPickerVisibilityChange,
  isPickerOpen,
  onClose,
}: Props) => {
  const modal = useModal();
  const { t } = useTranslation();
  const {
    store: {
      savedWallets: {
        savedWallets,
        removeFromSavedWallets,
        updateSavedWallet,
        cacheVaultAddress,
        cachedVaultAddress,
      },
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { connector } = useActiveWeb3React();
  const { account, manualDisconnect, status, connect } = useAccount();
  const { data: userRegistration } = useUserRegisteredForBlastAirdrop(
    frontendSecrets?.jwt,
  );

  const isSocialConnect = connector instanceof ParticleAuthConnector;

  useKey(Key.Escape, onClose);

  const disconnectAndPresentWalletModal = async () => {
    // Add a confirmation modal here? as this disconnects the user
    if (status === 'connected') {
      manualDisconnect();

      // if (frontendSecrets) {
      //   // These are Locked Routes and user has to register on a modal before being able to check these, so handle the Modal Presentation
      //   // @Todo: Find a better way to do this
      //   const DONT_PRESENT_WALLET_MODAL_ROUTES = [
      //     routes.portfolio.airdrops.dashboard.getRoutePath().toLowerCase(),
      //   ];

      //   if (DONT_PRESENT_WALLET_MODAL_ROUTES.includes(pathname.toLowerCase()))
      //     return;
      // }
    }

    modal.present(<WalletModal />, Modals.walletModal);
  };

  const onManageWallet = () => {
    if (!connector || !isSocialConnect) return;

    let particleNetwork = connector.particleNetwork as ParticleNetwork;
    if (particleNetwork.auth?.isLogin()) {
      const url = particleNetwork.buildWalletUrl({
        topMenuType: 'close',
      });
      modal.present(<SocialWalletModal url={url} />, Modals.socialWalletModal);
    } else {
      manualDisconnect();
    }
  };

  const onDelete = (id: string, address: string) => {
    const deleteWallet = () => {
      try {
        removeFromSavedWallets(id);
        showNotification({
          title: 'Successfully removed!',
          description: `Account with address ${textWithEllipsisInTheMiddle(
            address,
          )} was removed.`,
          type: NotificationType.Positive,
        });
      } catch (err) {
        console.log(err);
        showNotification({
          title: 'Something went wrong!',
          description: `We were not able to remove your account.`,
          type: NotificationType.Negative,
        });
      }
    };

    modal.present(
      <ConfirmationModal
        title={`Remove ${textWithEllipsisInTheMiddle(address)}?`}
        description={`This will only remove ${textWithEllipsisInTheMiddle(
          address,
        )} from your wallet list.\nYou can always re-add it at a later stage.`}
        onButtonRightPress={deleteWallet}
        buttonRightProps={{ colorVariant: 'primaryRed' }}
        buttonRightTitle="Remove"
        dataGtmId="confirm-remove-wallet-modal"
      />,
      Modals.confirmationModal,
    );
  };

  const onLogin = async (walletData: TSavedWallet) => {
    /**
     * If a user attempts to log in with a Metamask account that is not currently selected in the Metamask extension,
     * we cannot automatically switch wallets on the user's behalf. Instead, we display a notification to prompt the user
     * to manually select the desired account in their Metamask extension.
     */

    try {
      if (walletData.connectorName === ConnectorNames.Injected) {
        const ethereum = window.ethereum;
        if (ethereum) {
          const accounts = await ethereum.request({
            method: 'eth_requestAccounts',
          });
          if (accounts[0] !== walletData.address) {
            showNotification({
              title: 'Action Required : Metamask Detected!',
              description: `Please update your selected account in Metamask to log in automatically.`,
              type: NotificationType.Info,
            });
            return;
          }
        }
      }

      if (status === 'connected') {
        manualDisconnect();
      }

      if (
        walletData.connectorName === ConnectorNames.SocialConnect &&
        walletData.socialLoginOptions
      ) {
        if (!doesCurrentChainSupportParticleAuth) return; // is current chian is not supported then return
        socialconnect.setLoginOptions(walletData.socialLoginOptions);
      }

      if (walletData.vaultAddress) {
        cacheVaultAddress(walletData.vaultAddress);
      }

      /**
       * Note on Web3React: A waiting period is required before reactivating after a manual disconnect.
       * Failure to wait may result in unexpected behavior, usually with wallet_connect and particle_wallet.
       */
      setTimeout(() => {
        connect(connectorsByName[walletData.connectorName]);
        modal.present(<WalletModal manualConnect={true} />, Modals.walletModal); // Prompt user to sign and verify
      }, 1000);
    } catch (error: any) {
      error.code === -32002
        ? showNotification(
            {
              title: t('requestPendingCheckWallet'),
              type: NotificationType.Info,
            },
            true,
          )
        : showNotification(
            { title: error.message, type: NotificationType.Negative },
            true,
          );
    }
  };

  const onUpdate = (
    input: Partial<Omit<TSavedWallet, 'address'>>,
    id: string,
  ) => {
    updateSavedWallet(id, input);
  };

  const sortedWallets = sortWalletsByIsAuthenticated(
    savedWallets,
    account,
    cachedVaultAddress,
  );

  const twitterProfilePicture = userRegistration?.twitterData?.profilePicture;

  return (
    <Container>
      <div className="gapped-column">
        {sortedWallets.map((walletData, index) => {
          const isThisAuthenticated = isWalletAuthenticated(
            walletData.id,
            account,
            cachedVaultAddress,
          );
          return (
            <WalletItem
              key={index}
              isThisAuthenticated={isThisAuthenticated}
              isPickerOpen={isPickerOpen}
              walletData={walletData}
              twitterImage={
                isThisAuthenticated ? twitterProfilePicture : undefined
              }
              onDelete={onDelete}
              onLogin={onLogin}
              onPickerVisibilityChange={onPickerVisibilityChange}
              onUpdate={onUpdate}
            />
          );
        })}

        <div
          data-gtmid="button-connect-wallet-2"
          className="gapped-row"
          onClick={disconnectAndPresentWalletModal}
        >
          <img src={addIcon} alt="add" className="icon" />
          <span className="secondary-text">{t('addAnotherWallet')}</span>
        </div>
      </div>

      {status === 'connected' ? (
        <div className="button-row">
          <Button
            variant="destructive"
            className={`button ${!isSocialConnect && 'flexed'}`}
            onClick={manualDisconnect}
            data-gtmid="button-sign-out"
          >
            {t('signOut')}
          </Button>

          {isSocialConnect ? (
            <Button
              variant="secondaryDefault"
              className="button flexed"
              onClick={onManageWallet}
              data-gtmid="button-manage-wallet"
            >
              {t('manage')}
            </Button>
          ) : null}
        </div>
      ) : null}
    </Container>
  );
};

export default observer(ManageWalletDropdown);
