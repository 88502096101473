import { memo } from 'react';

import { ArrowForward } from '@material-ui/icons';

import { FormattedNumber } from 'components';

import {
  Row,
  AccountEquitySection as AccountEquitySectionStyled,
} from '../Steps/styles';
import { Badge } from '../styles';
import { DepositSteps } from '../utils';
import { DEFAULT_DECIMAL_SCALE } from 'constants/general';

import { useTranslation } from 'react-i18next';

interface Props {
  step: DepositSteps;
  walletBalance: number;
  depositAmount: number | null;
}
const AccountEquitySection = ({
  walletBalance,
  step,
  depositAmount,
}: Props) => {
  const { t } = useTranslation();

  if (!depositAmount) return null;
  return (
    <AccountEquitySectionStyled step={step}>
      <Row>
        <label>{t('walletBalance')}</label>
        <Badge>USD</Badge>
      </Row>
      {step === DepositSteps.SUCCESS ? (
        <Row>
          <FormattedNumber
            value={walletBalance}
            decimalScale={DEFAULT_DECIMAL_SCALE}
            disableTooltip={true}
            prefix="$"
          />
        </Row>
      ) : (
        <Row>
          <FormattedNumber
            value={walletBalance}
            decimalScale={DEFAULT_DECIMAL_SCALE}
            disableTooltip={true}
            prefix="$"
          />
          <ArrowForward className="success-green" />
          <FormattedNumber
            value={walletBalance + depositAmount}
            decimalScale={DEFAULT_DECIMAL_SCALE}
            disableTooltip={true}
            prefix="$"
          />
        </Row>
      )}
    </AccountEquitySectionStyled>
  );
};

export default memo(AccountEquitySection);
