import { useCallback } from 'react';

import { API_MAPPINGS } from 'constants/apiMappings';
import { RestService } from 'service/restService';

import { Endpoints, RequestMethod } from 'enums';

export type PlatformVaultStatus = 'active' | 'suspended';

export type PlatformVaultResponse = {
  vaultProfileId: number;
  accountEquity: number;
  totalShares: number;
  sharePrice: number;
  apy: number;
  status: PlatformVaultStatus;
  performanceFee: number;
  managerName: string;
  vaultName: string;
  wallet: string;
  inceptionTimestamp: number;
};

export type PlatformVaultHoldingsResponse = {
  stakerProfileId: number;
  vaultProfileId: number;
  status: PlatformVaultStatus;
  vaultName: string;
  managerName: string;
  userShares: number;
  withdrawable: number;
  netWithdrawable: number;
  performanceCharge: number;
  performanceFee: number;
};

export type PlatformVaultsDataWithHoldings = PlatformVaultResponse & {
  holdings?: PlatformVaultHoldingsResponse;
};

const restService = RestService.get();

export function usePlatformVaultsAPI() {
  const fetchVaults = useCallback(
    async (vaultWallets = ''): Promise<PlatformVaultResponse[]> => {
      try {
        const { data }: { data: any } = await restService.request<
          PlatformVaultResponse[]
        >({
          method: RequestMethod.GET,
          path: `/${Endpoints.VAULTS}`,
          responseMapping: API_MAPPINGS.VAULTS_MAPPING,
          queryParams: {
            ...(vaultWallets && { vault_wallet: vaultWallets }),
          },
          isArray: true,
        });

        return data;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [],
  );

  const fetchVaultsHoldings = useCallback(
    async (vaultWallets = ''): Promise<PlatformVaultHoldingsResponse[]> => {
      try {
        const { data }: { data: any } = await restService.request<
          PlatformVaultHoldingsResponse[]
        >({
          method: RequestMethod.GET,
          path: `/${Endpoints.VAULTS_HOLDINGS}`,
          responseMapping: API_MAPPINGS.VAULTS_HOLDINGS_MAPPING,
          queryParams: {
            ...(vaultWallets && { vault_wallet: vaultWallets }),
          },
          isArray: true,
        });

        return data;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [],
  );

  return {
    fetchVaults,
    fetchVaultsHoldings,
  } as const;
}

export type PlatformVaultsAPI = ReturnType<typeof usePlatformVaultsAPI>;
