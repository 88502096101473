import {
  getCurrencyFromTradingPair,
  mergeState,
  readUserSetting,
  saveUserSetting,
} from 'utils';

import { DEFAULT_DECIMAL_PLACES } from 'hooks/useDecimalPlaces';
import { getMarketFromPath } from 'hooks/useSwitchMarket';

import AppStore from './app';
import { LS_DEFAULT_MARKET } from 'constants/localStorageKeys';
import { PriceChangeType } from 'enums/priceChangeType';
import { defaultMarket } from 'mockData/markets';
import { mapMarket, MarketService } from 'service/marketService';

import { Market } from 'interfaces';
import {
  action,
  comparer,
  computed,
  makeAutoObservable,
  observable,
} from 'mobx';

export default class MarketsStore {
  private marketService = MarketService.get();
  markets: Market[] | null = null;
  selectedMarket: Market | null = null;
  selectedMarketId: string = '';
  previousSelectedMarketId: string | null = null;
  previousLastTradePrice: number | null = null;
  isLoading = false;
  avoidPairEffect = false;
  logosUrls: Record<string, string> = {};
  decimalPlaces: Record<
    string,
    {
      minTick: number;
      minOrder: number;
      price: number;
      size: number;
      priceScale: number;
    }
  > = {};
  marketsTitles: Record<string, string> = {};

  constructor(private store: AppStore) {
    makeAutoObservable(this, {
      markets: observable,
      selectedMarket: observable,
      selectedMarketId: observable,
      previousSelectedMarketId: observable,
      previousLastTradePrice: observable,
      isLoading: observable,
      switchMarket: action.bound,
      setIsLoading: action,
      lastTradePriceChangeType: computed,
      logosUrls: observable,
      marketsTitles: observable,
      setAll: action,
      setSelected: action,
      setSelectedById: action.bound,
      setSelectedMarketId: action,
      setLogoUrls: action.bound,
      setDecimalPlaces: action.bound,
      setMarketTitles: action.bound,
      decimalPlaces: observable,
      avoidPairEffect: observable,
      _decimalPlaces: computed({ equals: comparer.structural }),
      _logosUrls: computed({ equals: comparer.structural }),
      _selectedMarket: computed({ equals: comparer.structural }),
      _marketsTitles: computed({ equals: comparer.structural }),
    });
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setAll(markets: Market[]): Market[] {
    this.markets = markets;
    return this.markets;
  }

  switchMarket(market: Market) {
    this.selectedMarket = null;
    this.setSelected(market);
  }

  setSelected(market: Market): Market {
    // Save previous last trade price
    if (
      market.lastTradePrice !== undefined &&
      this.selectedMarket &&
      this.selectedMarket?.lastTradePrice !== market.lastTradePrice
    ) {
      this.previousLastTradePrice = this.selectedMarket?.lastTradePrice ?? null;
    }
    const mergedMarkets = mergeState(this.selectedMarket, market);
    this.selectedMarket = mapMarket(mergedMarkets);
    this.setDefaultMarket(this.selectedMarket.id);
    this.setSelectedMarketId(this.selectedMarket.id);
    return this.selectedMarket;
  }

  setSelectedMarketId(marketId: string): string {
    this.selectedMarketId = marketId;
    return marketId;
  }

  setSelectedById = async (marketID: string) => {
    if (marketID === this.selectedMarket?.id) return null;

    try {
      const market = await this.marketService.fetchMarketWithId(marketID);

      if (!market) {
        throw new Error('An error occurred while trying to fetch market by ID');
      }

      this.switchMarket(market);
    } catch (e) {
      console.error(e);
    }
  };

  getById = (marketID: string) => {
    if (!this.markets) return null;

    const market = this.markets.find(el => el.id === marketID);

    if (!market) {
      return null;
    }

    return market;
  };

  fetchAndSetInitialMarkets = async () => {
    try {
      const markets: Market[] | null = await this.marketService.fetchMarkets();
      if (!markets) return;
      this.setAll(markets);

      const marketFromPath = getMarketFromPath(markets);

      if (marketFromPath) {
        this.setSelected(marketFromPath);
      } else {
        this.setSelected(this.getDefaultMarket(markets) ?? markets[0]);
      }

      // this.setSelected(this.getDefaultMarket(markets) ?? markets[0]);
    } catch (e) {
      console.error(e);
    }
  };

  setAvoidPairEffect = (value: boolean) => {
    this.avoidPairEffect = value;
  };

  getDefaultMarket(markets: Market[]) {
    const item = readUserSetting(LS_DEFAULT_MARKET);
    return item ? markets.find(el => el.id === item) : markets[0];
  }

  setDefaultMarket(selectedMarketId: string) {
    saveUserSetting(LS_DEFAULT_MARKET, selectedMarketId);
  }

  get lastTradePriceChangeType() {
    // First shown value is always positive (green)
    if (this.previousLastTradePrice === null || !this.selectedMarket) {
      return PriceChangeType.Positive;
    }
    return this.selectedMarket.lastTradePrice >= this.previousLastTradePrice
      ? PriceChangeType.Positive
      : PriceChangeType.Negative;
  }

  get _selectedMarket() {
    return this.selectedMarket;
  }

  get _logosUrls() {
    return this.logosUrls;
  }

  get _decimalPlaces() {
    return this.decimalPlaces;
  }

  get _marketsTitles() {
    return this.marketsTitles;
  }

  setLogoUrls = (markets: Market[]) => {
    markets.forEach(market => {
      this.logosUrls[getCurrencyFromTradingPair(market.id).toLowerCase()] =
        market.iconUrl;
    });
  };

  setDecimalPlaces = (markets: Market[]) => {
    markets.forEach(market => {
      const decimalPlacesMinTick = getDecimalPlaces(market.minTick);
      const decimalPlacesMinOrder = getDecimalPlaces(market.minOrder);
      this.decimalPlaces[getCurrencyFromTradingPair(market.id).toLowerCase()] =
        {
          minTick: market.minTick,
          minOrder: market.minOrder,
          price: decimalPlacesMinTick,
          size: decimalPlacesMinOrder > 0 ? decimalPlacesMinOrder : 0,
          priceScale: Math.pow(10, decimalPlacesMinTick),
        };
    });
  };

  getDecimalPlacesByMarketId = (marketId: string) => {
    return (
      this._decimalPlaces[getCurrencyFromTradingPair(marketId).toLowerCase()] ??
      DEFAULT_DECIMAL_PLACES
    );
  };

  setMarketTitles = (markets: Market[]) => {
    markets.forEach(market => {
      this.marketsTitles[getCurrencyFromTradingPair(market.id).toLowerCase()] =
        market.marketTitle;
    });
  };

  getMarketTitleByMarketId = (marketId: string) => {
    return (
      this._marketsTitles[getCurrencyFromTradingPair(marketId).toLowerCase()] ??
      ''
    );
  };
}

const getDecimalPlaces = num => {
  let decimalPlaces = 0;

  // Convert the number to a full decimal representation string
  const numStr = num.toString();

  // Check if the number is in scientific notation
  if (numStr.includes('e') || numStr.includes('E')) {
    // Convert to a full decimal representation
    const fullNumStr = num.toFixed(20).replace(/0+$/, '');
    if (fullNumStr.includes('.')) {
      decimalPlaces = fullNumStr.split('.')[1].length;
    }
  } else if (num < 1) {
    // Handle regular decimal numbers
    const tickStr = numStr.split('.')[1] || '';
    decimalPlaces = tickStr.length;
  }

  return decimalPlaces;
};
