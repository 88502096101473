import Text from 'components/Text';

import { useTranslation } from 'react-i18next';

type Props = { count: number | undefined };

const Header = ({ count }: Props) => {
  const { t } = useTranslation();
  return (
    <Text
      variant="HEADING_H2"
      fontWeight="semiBold"
      className="items-end gap-5 flex"
    >
      {t('allVaults')}{' '}
      {count !== undefined ? (
        <Text variant="BODY_M" fontWeight="medium" color="shadesForeground200">
          ({count} {t(count > 1 ? 'vaults' : 'vault')})
        </Text>
      ) : null}
    </Text>
  );
};

export default Header;
