import { TableCell, TableRow } from '@material-ui/core';

import {
  textWithEllipsisInTheMiddle,
  timestampToLocalDateWithTime,
} from 'utils';

import { FormattedNumber } from 'components';
import Button from 'components/Button/button';
import TransferStatusCell from 'components/Tables/TransfersTable/TransferStatus';

import { Row } from 'theme/globalStyledComponents/row';

import { ProfileBalanceOps } from 'interfaces';
import { useTranslation } from 'react-i18next';

const UnstakeRequestsRow = ({
  balanceOps,
  onApprove,
}: {
  balanceOps: ProfileBalanceOps;
  onApprove: (opsId2: string) => void;
}) => {
  const { t } = useTranslation();
  const {
    type,
    opsType,
    amount,
    wallet,
    status,
    timestamp,
    opsId2,
    exchangeId,
    profileID,
  } = balanceOps;

  return (
    <TableRow>
      <TableCell>
        <Row className="gap-5">{type ?? opsType}</Row>
      </TableCell>
      <TableCell>
        <FormattedNumber value={amount} suffix=" Redeemed" decimalScale={2} />
      </TableCell>
      <TableCell>{textWithEllipsisInTheMiddle(wallet)}</TableCell>
      <TableCell>
        <TransferStatusCell status={status} />
      </TableCell>
      <TableCell>{opsId2}</TableCell>
      <TableCell>{exchangeId ?? '-'}</TableCell>
      <TableCell>{profileID}</TableCell>
      <TableCell>{timestampToLocalDateWithTime(timestamp)}</TableCell>

      <TableCell align="right">
        <Row className="justify-end">
          <Button
            sizeVariant="S"
            colorVariant="secondary"
            border="shadesBackground500"
            background="transparent"
            onClick={() => onApprove(balanceOps.opsId2 as string)}
          >
            {t('approve')}
          </Button>
        </Row>
      </TableCell>
    </TableRow>
  );
};

export default UnstakeRequestsRow;
