import React from 'react';

import { CircularProgress } from '@material-ui/core';

import { colors, sizes } from './options';
import { TScreenSize, theme } from 'theme/Theme';

import styled, { css } from 'styled-components';

// const sortByScreenSizeValues = (a: TScreenSize, b: TScreenSize) =>
//   theme.screenSizes[a] - theme.screenSizes[b];

type Size =
  | 'subtle'
  | 'extraSmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'extraLarge'
  | 'huge'
  | 'S'
  | 'M'
  | 'L';

type TResponsiveSize = {
  [key in TScreenSize]?: Size;
};

export type Props = {
  size?: Size;
  children?: any;
  onClick?: (e: any) => void;
  className?: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'subtle'
    | 'success'
    | 'destructive'
    | 'preset'
    | 'primaryDefault'
    | 'primaryGreen'
    | 'primaryRed'
    | 'secondaryDefault'
    | 'secondaryOutline'
    | 'tertiary'
    | 'icons'
    | 'destructiveNew'
    | 'buy'
    | 'sell';
  block?: boolean;
  isVisible?: boolean;
  rounded?: boolean;
  outlined?: boolean;
  clearBorderRadiusLeft?: boolean;
  clearBorderRadiusRight?: boolean;
  gaLabelName?: string;
  disableGA?: boolean;
  disableConnectWallet?: boolean;
  noBorder?: boolean;
  isSupport?: boolean;
  noPadding?: boolean;
  underline?: boolean;
  btnId?: string;
  isLoading?: boolean;
  animateContent?: boolean;
  responsiveSizing?: TResponsiveSize; // @todo : sort the object before constructing css media queries from it
} & Pick<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'type' | 'disabled' | 'color'
>;

interface ButtonProps {
  variant: string;
  outlined: boolean;
  size: string;
  block?: boolean;
  rounded?: boolean;
  clearBorderRadiusLeft?: boolean;
  clearBorderRadiusRight?: boolean;
  isVisible?: boolean;
  onClick: any;
  noBorder?: boolean;
  noPadding?: boolean;
  isSupport?: boolean;
  underline?: boolean;
  animateContent?: boolean;
  responsiveSizing?: TResponsiveSize;
}
const ButtonStyled = styled.button<ButtonProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  letter-spacing: -0.3px;
  font-style: normal;

  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  .icon-right {
    margin-left: 10px;
  }

  .icon-left {
    margin-right: 10px;
  }

  img {
    vertical-align: middle;
    width: ${({ theme, size }) => sizes(theme)[size].iconSize};
  }

  ${({ variant }) =>
    css`
      color: ${({ theme }) => colors(theme)[variant].default.text};
      background: ${({ theme }) => colors(theme)[variant].default.background};
      border: solid 2px ${({ theme }) =>
        colors(theme)[variant].default.border}};
      :hover {
        background: ${({ theme }) => colors(theme)[variant].hover.background};
        border: solid 2px ${({ theme }) => colors(theme)[variant].hover.border};
        color: ${({ theme }) => colors(theme)[variant].hover.text};
      }
    `}

  ${({ variant, outlined }) =>
    outlined &&
    css`
      color: ${({ theme }) => colors(theme)[variant].outline.default.text};
      border: 2px solid
        ${({ theme }) => colors(theme)[variant].outline.default.border};
      background: ${({ theme }) =>
        colors(theme)[variant].outline.default.background};
      :hover {
        background: ${({ theme }) =>
          colors(theme)[variant].outline.hover.background};
        color: ${({ theme }) => colors(theme)[variant].outline.hover.text};
        border: 2px solid
          ${({ theme }) => colors(theme)[variant].outline.hover.border};
      }
    `}
    

  ${({ size }) =>
    css`
      font-weight: ${({ theme }) => sizes(theme)[size].fontWeight};
      font-size: ${({ theme }) => sizes(theme)[size].fontSize};
      height: ${({ theme }) => sizes(theme)[size].height};
      padding: ${({ theme }) => sizes(theme)[size].padding};
      border-radius: ${({ theme }) => sizes(theme)[size].borderRadius};
      letter-spacing: ${({ theme }) => sizes(theme)[size].letterSpacing};
      line-height: ${({ theme }) => sizes(theme)[size].lineHeight};
    `}

    ${({ responsiveSizing, theme }) =>
    responsiveSizing &&
    css`
      ${Object.keys(responsiveSizing).map(
        size =>
          `@media (min-width: ${theme.screenSizes[size]}px) {
      font-weight: ${sizes(theme)[responsiveSizing[size]].fontWeight};
      font-size: ${sizes(theme)[responsiveSizing[size]].fontSize};
      height: ${sizes(theme)[responsiveSizing[size]].height};
      padding: ${sizes(theme)[responsiveSizing[size]].padding};
      border-radius: ${sizes(theme)[responsiveSizing[size]].borderRadius};
      letter-spacing: ${sizes(theme)[responsiveSizing[size]].letterSpacing};
      line-height: ${sizes(theme)[responsiveSizing[size]].lineHeight};
        };`,
      )}
    `} 

    ${({ variant }) =>
    variant === 'clear' &&
    css`
      border: 0;
      height: 20px;
      :hover {
        background: ${({ theme }) => theme.colors[variant].light};
        border: 0;
      }
      :focus {
        border: 0;
      }
    `}

    ${({ block }) =>
    block &&
    css`
      display: block;
      width: 100%;
    `}
    ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50px;
    `}

  ${({ clearBorderRadiusLeft }) =>
    clearBorderRadiusLeft &&
    css`
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    `};

  ${({ clearBorderRadiusRight }) =>
    clearBorderRadiusRight &&
    css`
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    `};

  ${({ noBorder }) =>
    noBorder &&
    css`
      border: 0;
      :hover {
        border: 0;
      }
    `};

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `};

  ${({ isVisible }) =>
    !isVisible &&
    css`
      display: none;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: auto;
    `}

    ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `};

  ${({ isSupport }) =>
    isSupport &&
    css`
      padding: 0;
      margin: 0;
      text-transform: none;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      color: ${({ theme }) => theme.colors.warningForeground100};
      background: transparent;
      cursor: pointer;
      display: inline-block;
      border: 0;
      :hover {
        background: none;
        border: 0;
      }
    `}

  ${({ animateContent }) =>
    animateContent &&
    css`
      > .btn-text,
      > img.icon-right {
        transition: all 0.3s ease-in-out;
      }
      > .btn-text {
        display: inline-block;
      }

      &:hover {
        > .btn-text {
          transform: translateX(-5px);
        }
        > img.icon-right {
          transform: translateX(5px);
        }
      }
    `};

  ${({ variant }) =>
    (variant === 'buy' || variant === 'sell') &&
    css`
      &:hover {
        > img.icon-right {
          // Change to black
          filter: grayscale(100%) invert(100%) sepia(100%) brightness(0%)
            hue-rotate(180deg);
        }
      }
    `}
`;

const LoadingStyled = styled(CircularProgress)`
  display: flex;
  margin: 0 auto;
  &.MuiCircularProgress-colorPrimary {
    color: initial;
  }
`;

const Button = ({
  size = 'medium',
  children,
  onClick,
  className,
  variant = 'primary',
  block,
  rounded,
  isVisible = true,
  outlined = false,
  gaLabelName,
  disableGA = false,
  disableConnectWallet = false,
  disabled = false,
  underline = false,
  btnId,
  isLoading = false,
  animateContent = false,
  ...restProps
}: Props) => {
  return (
    <ButtonStyled
      size={size}
      variant={variant}
      block={block}
      rounded={rounded}
      isVisible={isVisible}
      disabled={disabled}
      onClick={onClick}
      outlined={outlined}
      className={className}
      underline={underline}
      animateContent={animateContent}
      {...restProps}
    >
      {isLoading ? (
        <LoadingStyled
          style={{
            width: '15px',
            height: '15px',
          }}
        />
      ) : (
        children
      )}
    </ButtonStyled>
  );
};

export default Button;
