export enum UserSettings {
  // Account
  ACCOUNT_SHOW_BALANCE = 'account.showBalance',

  // Orders
  ORDERS_HIDE_CLOSED = 'orders.hideClosed',

  // Alerts
  ALERTS_HAS_SEEN_AIRDROP_2 = 'alerts.hasSeenAirdrop2',

  // Get Started
  HAS_WENT_THROUGH_GET_STARTED = 'popups.hasWentThroughGetStarted',

  // Popups
  DONT_SHOW_BFX_DEPOSIT_POPUP_AGAIN = 'modals.dontShowBfxDepositPopupAgain',

  BLAST_SUPER_CHARGED_BOOST_LAST_POP_TIME = 'modals.blastSuperChargedBoostLastPopTime',

  DONT_SHOW_GET_THE_APP_POPUP_AGAIN = 'modals.dontShowGetTheAppPopupAgain',

  DONT_SHOW_WHALE_SUPPORT_POPUP_AGAIN = 'modals.dontShowWhaleSupportPopupAgain',

  // Temporary Settings
  IS_USER_REGISTERED_FOR_BFX_DEPOSIT_BONUS = 'modals.isUserRegisteredBfxDepositBonus',

  IS_GOLD_POINTS_PROGRAM_BANNER_DISMISSED = 'modals.isGoldPointsBannerDismissed',

  IS_MARKET_TRADE_WARNING_BANNER_DISMISSED = 'modals.marketTradeWarningBannerDismissed',
}
