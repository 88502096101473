import {
  mergeOrderbook,
  notifications,
  parseResponseData,
  readUserSetting,
  saveUserSetting,
  showNotificationIfAllChannelsSubscribed,
  sortOrderbook,
} from 'utils';

import {
  LIMIT_EXCESSIVE_ARRAY_FOR_ORDERBOOK,
  ORDERBOOK_THROTTLE_REFLECT_THRESHOLD,
} from '../constants/general';
import AppStore from './app';
import { LS_ORDERBOOK_USER_SAVED_TICKS } from 'constants/localStorageKeys';
import CentrifugeService from 'service/centrifugeService';

import {
  PublicationContext,
  State,
  SubscribedContext,
  UnsubscribedContext,
} from 'centrifuge';
import { Markets, OrderType, TradeSide, WebsocketChannels } from 'enums';
import {
  CentrifugeSubscribingChannelData,
  OrderbookData,
  OrderbookOrder,
  OrderbookWSResponse,
} from 'interfaces';
import { action, makeAutoObservable, observable } from 'mobx';

const RESUBSCRIBE_TRIES = [10, 30, 100];

const getChannelName = (marketID: string) =>
  `${WebsocketChannels.Orderbook}:${marketID}`;

const calcultateOBSpreadPercentage = (
  askOrder: OrderbookOrder | undefined,
  bidOrder: OrderbookOrder | undefined,
  midPrice: number,
) => {
  if (!askOrder || !bidOrder || !midPrice) return undefined;
  const spread = askOrder.price - bidOrder.price;
  const spreadPercentage = (spread / midPrice) * 100;
  return spreadPercentage;
};

export default class OrderbooksStore {
  private centrifugeService = CentrifugeService.getPublic();

  bids: OrderbookOrder[] = [];
  bidsTemp: OrderbookOrder[] = [];
  bidsWithTotal: OrderbookOrder[] = [];

  selectedMarketID: string = '';
  asks: OrderbookOrder[] = [];
  asksTemp: OrderbookOrder[] = [];
  asksWithTotal: OrderbookOrder[] = [];

  indexPrice: number = 0;
  spread: number | undefined = 0;
  midPrice = 0;
  spreadPercentage: number | undefined = 0;
  currentPrice: number = 0;
  previousPrice: number = 0;

  sequence: number = 0;
  processingHistory: boolean = false;
  dataBuffer: OrderbookWSResponse[] = [];

  orderInputPrice: number | null = null;
  isOrderEntryPopUpShown: boolean = false;
  orderbookOrderEntryPrice: number | null = null;

  savedUserTicks: Record<Markets, number>;

  isLoading = true;

  private lastReflectTime = 0;

  constructor(private store: AppStore) {
    makeAutoObservable(this, {
      isLoading: observable,
      midPrice: observable,
      orderInputPrice: observable,
      isOrderEntryPopUpShown: observable,
      orderbookOrderEntryPrice: observable,
      setOrderInputPrice: action.bound,
      setOrderbookOrderEntryPrice: action.bound,
      setIsOrderEntryPopUpShown: action.bound,
      getOrderbookEdgePrice: observable,
      getMarketOrderEstimation: observable,
      onSubscribed: action,
      onPublication: action,
      setCurrentPrice: action,
      setPreviousPrice: action,
      setSequence: action,
      setAsks: action,
      setBids: action,
      refresh: action,
      setIsLoading: action,
      checkOrderBookThrottleReflect: action,
      savedUserTicks: observable,
    });

    this.savedUserTicks = readUserSetting(LS_ORDERBOOK_USER_SAVED_TICKS) || {};
  }

  refresh = (update: OrderbookData, isFirstRender = false) => {
    const hasNewAsks = update.asks && update.asks.length > 0;
    const hasNewBids = update.bids && update.bids.length > 0;

    if (hasNewAsks) {
      const asksMerged = mergeOrderbook(
        this.asksTemp,
        update.asks,
        isFirstRender,
      );
      const sortedAndReversedAsks = sortOrderbook(asksMerged).reverse();
      this.asksTemp =
        sortedAndReversedAsks.length > LIMIT_EXCESSIVE_ARRAY_FOR_ORDERBOOK
          ? sortedAndReversedAsks.slice(0, LIMIT_EXCESSIVE_ARRAY_FOR_ORDERBOOK)
          : sortedAndReversedAsks;
    }
    if (hasNewBids) {
      const bidsMerged = mergeOrderbook(
        this.bidsTemp,
        update.bids,
        isFirstRender,
      );
      const sortedBids = sortOrderbook(bidsMerged);
      this.bidsTemp =
        sortedBids.length > LIMIT_EXCESSIVE_ARRAY_FOR_ORDERBOOK
          ? sortedBids.slice(0, LIMIT_EXCESSIVE_ARRAY_FOR_ORDERBOOK)
          : sortedBids;
    }

    let updatedPrice = this.bidsTemp.length > 0 ? this.bidsTemp[0].price : 0;
    if (updatedPrice === 0)
      updatedPrice = this.asksTemp.length > 0 ? this.asksTemp[0].price : 0;

    this.setPreviousPrice(this.currentPrice);
    this.setCurrentPrice(updatedPrice);
    this.setSequence(update.sequence);
    this.checkOrderBookThrottleReflect();

    const areAskAndBidsValid =
      this.asksTemp.length > 0 && this.bidsTemp.length > 0;

    this.spread = areAskAndBidsValid
      ? this.asksTemp[0].price - this.bidsTemp[0].price
      : 0;
    this.midPrice = areAskAndBidsValid
      ? (this.bidsTemp[0].price + this.asksTemp[0].price) / 2
      : 0;
    this.spreadPercentage = calcultateOBSpreadPercentage(
      this.asksTemp[0],
      this.bidsTemp[0],
      this.midPrice,
    );
  };

  checkOrderBookThrottleReflect(source?: string) {
    const elapsed = Date.now() - this.lastReflectTime;
    if (elapsed > ORDERBOOK_THROTTLE_REFLECT_THRESHOLD) {
      this.lastReflectTime = Date.now();
      this.bids = this.bidsTemp;
      this.asks = this.asksTemp;
    } else {
      if (!source) {
        setTimeout(() => {
          this.checkOrderBookThrottleReflect('itself');
        }, ORDERBOOK_THROTTLE_REFLECT_THRESHOLD);
      }
    }
  }

  setOrderInputPrice(price: number | null) {
    this.orderInputPrice = price;
  }

  setOrderbookOrderEntryPrice(price: number | null) {
    this.orderbookOrderEntryPrice = price;
  }

  setIsOrderEntryPopUpShown(isShown: boolean) {
    this.isOrderEntryPopUpShown = isShown;
  }

  setBids(bids: OrderbookOrder[]) {
    this.bids = bids;
  }

  setAsks(asks: OrderbookOrder[]) {
    this.asks = asks;
  }

  setPreviousPrice(previousPrice: number) {
    this.previousPrice = previousPrice;
  }

  setCurrentPrice(currentPrice: number) {
    this.currentPrice = currentPrice;
  }

  setSequence(sequence: number) {
    this.sequence = sequence;
  }

  subscribe = (marketID: string, centrifugeService: CentrifugeService) => {
    if (marketID === this.selectedMarketID) return;
    this.centrifugeService = centrifugeService;

    if (this.selectedMarketID !== '') {
      this.centrifugeService.removeSubscription(
        getChannelName(this.selectedMarketID),
      );
    }

    this.setIsLoading(true);

    this.centrifugeService.addSubscription(getChannelName(marketID), {
      subscribing: (data: CentrifugeSubscribingChannelData) => {
        this.subscribing(data);
      },
      unsubscribed: (data: UnsubscribedContext) => {
        this.unsubscribed(data);
      },
      subscribed: (data: SubscribedContext) => {
        this.onSubscribed(data.data, data.wasRecovering);
      },
    });
  };

  unsubscribed = (data: UnsubscribedContext, isResettingConnection = false) => {
    this.spread = undefined;
    this.midPrice = 0;
    this.spreadPercentage = undefined;

    if (!isResettingConnection) {
      this.setIsLoading(true);
    }

    // If we manually called "unsubscribe", don't show notification
    if (data.code === 0) return;

    const centrifugeClientState = this.centrifugeService.centrifuge.state;

    // Don't show if the client is not connected, as this means that all channels were unsubscribed
    // and in this case we show a general error that all public data connection was lost
    if (centrifugeClientState === State.Connected) {
      notifications.couldNotReEstablishConnectionToData('orderbook');
    }
  };

  subscribing = (
    data: CentrifugeSubscribingChannelData,
    isResettingConnection = false,
  ) => {
    // code === 0 on the initial subscribing event
    if (data.code === 0) return;

    if (!isResettingConnection) {
      this.setIsLoading(true);
    }
    const centrifugeClientState = this.centrifugeService.centrifuge.state;

    // Don't show if the client is not connected, as this means that all channels were unsubscribed
    // and in this case we show a general error that all public data connection was lost
    if (centrifugeClientState === State.Connected) {
      notifications.dataConnectionLost('orderbook');
    }
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  getHistory = async (limit: number) => {
    const history = await this.centrifugeService.getHistory(
      getChannelName(this.selectedMarketID),
      limit,
    );

    const publications = history?.publications ?? [];

    return publications;
  };

  resetConnection = () => {
    this.centrifugeService.removeSubscription(
      getChannelName(this.selectedMarketID),
    );

    // this.setIsLoading(true);

    this.centrifugeService.addSubscription(
      getChannelName(this.selectedMarketID),
      {
        subscribing: (data: CentrifugeSubscribingChannelData) => {
          this.subscribing(data, true);
        },
        unsubscribed: (data: UnsubscribedContext) => {
          this.unsubscribed(data, true);
        },
        subscribed: (data: SubscribedContext) => {
          this.onSubscribed(data.data, false);
        },
      },
    );
  };

  resubscribe = async (currentData: OrderbookWSResponse) => {
    this.processingHistory = true;
    let publications: PublicationContext[] = [];

    for (let i = 0; i < RESUBSCRIBE_TRIES.length; i++) {
      const minIsHigherThanLocal =
        publications.length > 0 &&
        publications[publications.length - 1].data.sequence > this.sequence + 1;

      if (i === 0 || minIsHigherThanLocal) {
        publications = await this.getHistory(RESUBSCRIBE_TRIES[i]);
      }
    }

    publications = publications.reverse();
    if (publications.length === 0) {
      this.processingHistory = false;
      const parsedData = this.parseResponse(currentData);
      this.refresh(parsedData);
      return;
    }
    if (publications[0].data.sequence > this.sequence + 1) {
      this.resetConnection();
      this.processingHistory = false;
      return;
    }

    for (let i = 0; i < publications.length; i++) {
      const sequenceWasAlreadyProcessed =
        publications[i].data.sequence <= this.sequence;
      if (!sequenceWasAlreadyProcessed) {
        const parsedData = this.parseResponse(publications[i].data);
        this.refresh(parsedData);
      }
    }
    this.processingHistory = false;
  };

  onSubscribed = (data: OrderbookWSResponse, wasRecovering = false) => {
    if (wasRecovering) {
      showNotificationIfAllChannelsSubscribed(this.centrifugeService);
    }

    this.setAsks([]);
    this.asksTemp = [];
    this.setBids([]);
    this.bidsTemp = [];
    this.lastReflectTime = 0;
    this.previousPrice = 0;
    this.currentPrice = 0;
    this.setSequence(0);
    this.processingHistory = false;
    this.dataBuffer = [];

    const parsedData = this.parseResponse(data);
    this.refresh(parsedData, true);

    this.selectedMarketID = (data as OrderbookWSResponse).market_id;
    const orderboookChannelName = getChannelName(this.selectedMarketID);

    this.setIsLoading(false);

    this.centrifugeService
      .getSubscription(orderboookChannelName)
      ?.on('publication', this.onPublication);
  };

  onPublication = ({ data }: any) => {
    this.dataBuffer.push(data);
    if (this.processingHistory) return;

    for (let i = 0; i < this.dataBuffer.length; i++) {
      const currentData = this.dataBuffer[i];
      const duplicateOrLowerSequence =
        currentData.sequence - this.sequence <= 0;
      const missingSequence = currentData.sequence - this.sequence > 1;

      if (duplicateOrLowerSequence) continue;
      if (missingSequence) {
        (async () => {
          this.dataBuffer = [];
          this.setIsLoading(false);
          this.resubscribe(currentData);
        })();
        return;
      }

      const parsedData = this.parseResponse(currentData);
      this.refresh(parsedData);
    }
    this.dataBuffer = [];
    this.setIsLoading(false);
  };

  parseResponse = (data: OrderbookWSResponse): OrderbookData => {
    const bids = parseResponseData(data?.bids ?? []);
    const asks = parseResponseData(data?.asks ?? []);
    const { sequence } = data;

    return { asks, bids, sequence };
  };

  updateCurrentMarketSavedTickSize = (value: number) => {
    this.savedUserTicks = {
      ...this.savedUserTicks,
      [this.selectedMarketID]: value,
    };
    saveUserSetting(LS_ORDERBOOK_USER_SAVED_TICKS, this.savedUserTicks);
  };

  /**
   * Get preferred orderbook tick size for current selected market.
   * @returns {number} The tick size for the selected market.
   */
  getSelectedMarketOrderbookTick = (): number => {
    if (!this.selectedMarketID) return 0;

    return this.savedUserTicks[this.selectedMarketID];
  };

  getOrderbookEdgePrice = () => {
    return { short: this.bids[0]?.price, long: this.asks[0]?.price };
  };

  getMarketOrderEstimation = (
    size: number | undefined | null,
    tradeSide: TradeSide,
    orderType: OrderType | undefined,
    maxBuyingPower?: number,
  ) => {
    if (orderType !== OrderType.MARKET) return;

    if (!size && !maxBuyingPower) return undefined;

    if (this.asks.length === 0 || this.bids.length === 0) return undefined;

    let quantityFilled = 0;
    let totalUsdValue = 0;

    const orderSide = tradeSide === TradeSide.SHORT ? 'bids' : 'asks'; // Determine order side based on tradeSide

    const basePrice = this[orderSide][0].price;

    let currentRowPrice = 0;

    let inputQuantityFilled = false;

    let boughtWithPower = 0;
    let maxPurchasableQuantity = 0;

    for (const order of this[orderSide]) {
      const levelQuantity = order.quantity;
      const levelUsdValue = order.quantity * order.price;
      currentRowPrice = order.price;

      if (!maxBuyingPower && inputQuantityFilled) break;

      // Used to calculate what can be maxPurchasableQuantity, so that if user inputs a market order above that, we pass this instead of userInput to make user the order goes through
      if (maxBuyingPower) {
        if (boughtWithPower + levelUsdValue >= maxBuyingPower) {
          maxPurchasableQuantity +=
            (maxBuyingPower - boughtWithPower) / currentRowPrice;
          boughtWithPower = maxBuyingPower;
          break;
        } else {
          boughtWithPower += levelUsdValue;
          maxPurchasableQuantity += levelQuantity;
        }
      }

      // For Size
      if (size) {
        if (!inputQuantityFilled) {
          if (quantityFilled + levelQuantity >= size) {
            totalUsdValue += currentRowPrice * (size - quantityFilled);
            quantityFilled = size;
            inputQuantityFilled = true;
          } else {
            quantityFilled += levelQuantity;
            totalUsdValue += currentRowPrice * levelQuantity;
          }
        }
      }
    }

    const result: {
      avgPrice: number;
      priceImpact: number;
      maxPurchasableQuantity?: number;
    } = {
      avgPrice: 0,
      priceImpact: 0,
    };

    if (size) {
      if (quantityFilled < size) {
        totalUsdValue += currentRowPrice * (size - quantityFilled);
      }

      const avgPrice = totalUsdValue / size;

      const priceDifference = Math.abs(avgPrice - basePrice);
      result.avgPrice = avgPrice;
      result.priceImpact = (priceDifference / basePrice) * 100;
    }

    if (maxPurchasableQuantity) {
      result.maxPurchasableQuantity = maxPurchasableQuantity;
    }

    return result;
  };
}
