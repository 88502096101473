import { memo, useState } from 'react';

import Icon, { IconProps } from 'components/Icon';
import Text, { TextProps } from 'components/Text';

import copyWhiteIcon from 'assets/icons/copy-white.svg';
import copyGrayIcon from 'assets/icons/copy.svg';
import { Row } from 'theme/globalStyledComponents/row';

import styled from 'styled-components';

type AnimatedTextProps = {
  isJustCopied?: boolean;
};
const AnimatedText = styled(Text)<AnimatedTextProps>`
  transition: opacity 0.3s ease, width 0.3s ease, margin-left ease 0.1s;
  opacity: ${({ isJustCopied }) => (isJustCopied ? 1 : 0)};
  width: ${({ isJustCopied }) => (isJustCopied ? '40px' : 0)};
  cursor: default;
  left: 20px;
  margin-left: ${({ isJustCopied }) => (isJustCopied ? '5px' : 0)};
`;

let tempTimeout: NodeJS.Timeout;

type Props = {
  labelProps?: TextProps;
  textToCopy: string;
  iconColor: 'white' | 'gray';
} & Omit<IconProps, 'src'>;
const ResponsiveCopyIcon = ({
  labelProps,
  textToCopy,
  iconColor,
  ...rest
}: Props) => {
  const [isJustCopied, setIsJustCopied] = useState(false);

  const onPress = () => {
    try {
      clearTimeout(tempTimeout);
      navigator.clipboard.writeText(textToCopy as string);
      setIsJustCopied(true);
      tempTimeout = setTimeout(() => setIsJustCopied(false), 1000);
    } catch (e) {
      console.error('Error copying text to clipboard', e);
    }
  };

  return (
    <Row>
      <Icon
        src={iconColor === 'white' ? copyWhiteIcon : copyGrayIcon}
        onClick={onPress}
        {...rest}
      />
      <AnimatedText
        variant="BODY_S"
        fontWeight="semiBold"
        isJustCopied={isJustCopied}
        {...labelProps}
      >
        Copied
      </AnimatedText>
    </Row>
  );
};

export default memo(ResponsiveCopyIcon);
