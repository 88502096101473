import { Box } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';

import { FormattedNumber } from 'components';
import LinearProgressBar from 'components/LinearProgressBar';
import Text from 'components/Text';

import { StatusTx } from '../notifications';
import { Row, WaitingSection } from '../styles';
import { SpotStateType } from 'enums/spotState';

import { AmendType } from '..';
import { useTranslation } from 'react-i18next';

type Props = {
  amendType: AmendType;
  tokenPair: string;
  amount: number | undefined;
  initialPosition: number;
  currentPosition: number;
  spotStateType: SpotStateType;
};

const RequestPending = ({
  amendType,
  tokenPair,
  amount,
  initialPosition,
  currentPosition,
  spotStateType,
}: Props) => {
  const { t } = useTranslation();
  const progressPercent =
    ((initialPosition - currentPosition) / initialPosition) * 100;

  const isProcessing = spotStateType === SpotStateType.Executing;

  const requestText =
    amendType === AmendType.Stake
      ? isProcessing
        ? StatusTx.stakeProcessing(tokenPair, amount, t)
        : StatusTx.stakeInQueue(tokenPair, amount, currentPosition, t)
      : isProcessing
      ? StatusTx.unstakeProcessing(tokenPair, amount, t)
      : StatusTx.unstakeInQueue(tokenPair, amount, currentPosition, t);

  return (
    <WaitingSection>
      <Row className="mb-10 gap-5">
        <AccessTime />
        <Text variant="BODY_M" color="shadesForeground200">
          {requestText.description}
        </Text>
      </Row>

      <Box className="linear-progress-container">
        <LinearProgressBar percent={progressPercent} customBarColor="#d43b4e" />
      </Box>

      <Text variant="BODY_S" color="shadesForeground200">
        {isProcessing
          ? t('processing')
          : `${t('currentPositionInTheQueue')}: ${currentPosition}`}
      </Text>
    </WaitingSection>
  );
};

export default RequestPending;
