import { memo } from 'react';

import Search from 'components/Search/Search';

import TabFilters, { MarketFilterType } from './TabFilters';
import { Container } from './styles';

import { useTranslation } from 'react-i18next';

type Props = {
  selectedFilter: MarketFilterType;
  setSelectedFilter: (filter: MarketFilterType) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
};
const MarketFilters = ({
  selectedFilter,
  setSelectedFilter,
  searchTerm,
  setSearchTerm,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TabFilters
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <Search
        placeholder={t('searchMarkets')}
        value={searchTerm}
        onChange={setSearchTerm}
      />
    </Container>
  );
};

export default memo(MarketFilters);
