import { useState } from 'react';

import MarketsDropdown from 'components/MarketsDropdown';
import Search from 'components/Search/Search';

import { Container, Heading, TopPart } from './styles';
import closeIcon from 'assets/icons/close-x.svg';

import { Market } from 'interfaces';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const MarketsDropdownContainer = styled.div`
  width: 100%;
  border-radius: 8px;
`;

type SearchInputProps = {
  onOptionSelect: (option: Market) => void;
  onDropdownClose: () => void;
};
const MarketSelectDropdown = ({
  onOptionSelect,
  onDropdownClose,
}: SearchInputProps) => {
  const { t } = useTranslation();
  const [searchMarketsValue, setSearchMarketsValue] = useState<string>('');

  const handleSearchChange = (value: string) => {
    setSearchMarketsValue(value);
  };

  return (
    <Container>
      <TopPart>
        <Heading>
          <span className="font-body-l font-semiBold">{t('selectMarket')}</span>
          <img src={closeIcon} alt="Close Icon" onClick={onDropdownClose} />
        </Heading>
        <Search
          placeholder={t('searchMarkets')}
          onChange={handleSearchChange}
          value={searchMarketsValue}
        />
      </TopPart>
      <MarketsDropdownContainer>
        <MarketsDropdown
          onOptionSelect={onOptionSelect}
          searchMarketsValue={searchMarketsValue}
        />
      </MarketsDropdownContainer>
    </Container>
  );
};

export default MarketSelectDropdown;
