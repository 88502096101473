import { memo } from 'react';

import { Icon } from 'components';
import Badge from 'components/Badge';
import InfoTooltip from 'components/InfoTooltip';
import ShimmingRainbow from 'components/ShimmingRainbow';
import Text from 'components/Text';

import pendingIcon from 'assets/icons/pending.svg';
import { Column } from 'theme/globalStyledComponents/column';
import { Row } from 'theme/globalStyledComponents/row';

type Props = {
  onClick?: () => void;
  className?: string;
  hideBadgeAndTooltip?: boolean;
  innerClass?: string;
  contentClass?: string;
  isSizeXs?: boolean;
  alignRight?: boolean;
};
const ActiveBonus = ({
  hideBadgeAndTooltip = false,
  isSizeXs = false,
  alignRight = false,
  ...rest
}: Props) => {
  return (
    <ShimmingRainbow {...rest}>
      <Column gap={3} align={alignRight ? 'flex-end' : undefined}>
        <Text
          variant={isSizeXs ? 'BODY_XS' : 'BODY_S'}
          flexed
          color="shadesForeground200"
          gap={2}
        >
          <Icon size={isSizeXs ? 'XSS' : 'S'} src={pendingIcon} />
          Hold for 2 weeks
        </Text>
        <Text variant={isSizeXs ? 'BODY_S' : 'BODY_M'} fontWeight="semiBold">
          3% Cash Bonus
        </Text>
      </Column>

      {!hideBadgeAndTooltip ? (
        <Row gap={5}>
          <InfoTooltip
            color="white"
            iconSize={12}
            title={'Deposit and hold funds for 2 weeks to earn 3% cash bonus.'}
          />

          <Badge
            bgColor="positiveBackground100"
            color="positiveForeground200"
            variant="BODY_S"
            fontWeight="semiBold"
            padding="4px"
          >
            ACTIVE
          </Badge>
        </Row>
      ) : null}
    </ShimmingRainbow>
  );
};

export default memo(ActiveBonus);
