export const Size = {
  XXXS: '4px',
  XXS: '6px',
  XS: '8px',
  XSS: '10px',
  S: '12px',
  SM: '14px',
  M: '16px',
  ML: '20px',
  ML_L: '22px',
  L: '24px',
  XL: '32px',
  XXL: '40px',
};
