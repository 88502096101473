import { NotificationType } from 'enums';
import { Notification } from 'interfaces';

export const notifications: Notification[] = [
  {
    type: NotificationType.Positive,
    title: 'Trade filled',
    description: 'You sold 1.20010 BTC at 48.8888',
  },
  {
    type: NotificationType.Positive,
    title: 'Sell Order Confirmed',
  },
  {
    type: NotificationType.PositivePriceChange,
    title: 'Bitcoin Price Alert',
    description: 'Bitcoin is up -3.09% in the last hour.',
  },
  {
    type: NotificationType.Negative,
    title: 'Order rejected',
    description:
      'Exchange busy. Retry later or contact support. BTC at 48.8888',
  },
  {
    type: NotificationType.NegativePriceChange,
    title: 'Bitcoin Price Alert',
    description: 'Bitcoin is down -3.09% in the last hour.',
  },
  {
    type: NotificationType.Info,
    title: 'Position close to liquidation',
    description:
      'Your position is close to liquidation due to low equity balance. Deposit to continue trading.',
  },
  {
    type: NotificationType.Neutral,
    title: 'Position close to liquidation',
    description:
      'Your position is close to liquidation due to low equity balance. Deposit to continue trading.',
  },
];
