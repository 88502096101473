import FundingRatesChart from './FundingRatesChart';
import FundingRatesTable from './FundingRatesTable';
import { Container } from './styles';

import { observer } from 'mobx-react';

const FundingRates = () => {
  return (
    <Container>
      <div className="chart-container">
        <FundingRatesChart />
      </div>
      <div className="history-table-container">
        <FundingRatesTable />
      </div>
    </Container>
  );
};

export default observer(FundingRates);
