import { Size } from 'components/Icon/size';

import styled, { css } from 'styled-components';

type Props = {
  size?: keyof typeof Size;
  selected: boolean;
};

const StyledRadio = styled.div<Props>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${({ size = 'M' }) => Size[size]};
  height: ${({ size = 'M' }) => Size[size]};
  border-radius: 100%;
  border: 1px solid
    ${({ theme, selected }) =>
      selected
        ? theme.colors.shadesForeground200
        : theme.colors.shadesBackground500};

  &:hover {
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
  }

  > div {
    ${({ selected, theme }) =>
      selected &&
      css`
        width: 50%;
        height: 50%;
        border-radius: 100%;
        background-color: ${theme.colors.shadesForeground200};
      `};
  }
`;

const Radio = ({ ...props }: Props) => {
  return (
    <StyledRadio {...props}>
      <div />
    </StyledRadio>
  );
};

export default Radio;
