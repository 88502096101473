import { useEffect, useRef, useState } from 'react';

import { TransactionReceipt, Web3Provider } from '@ethersproject/providers';
import { parseUnits } from '@ethersproject/units';

import { useWeb3React } from '@web3-react/core';

import {
  bigNumberToFloat,
  roundJsDecimalToString,
  showNotification,
  verifyUserSession,
} from 'utils';
import {
  hasRequiredConfirmations,
  removeTxFromLocalStorage,
  saveTxToLocalStorage,
  showToast,
} from 'utils/activeTransactions';
import { brand, brandedSelect } from 'utils/brand';

import {
  Key,
  useActiveWeb3React,
  useRabbitContractsAPI,
  useVerifyChainId,
} from 'hooks';
import { useDepositsAPI } from 'hooks/useDepositsAPI';
import useModal from 'hooks/useModal';

import Button from 'components/Button/button';
import DepositInProgress from 'components/CustomToasts/DepositInProgress';
import DepositBonusModal from 'components/DepositBonusModal';
import { isBfxDepositBonusActive } from 'components/DepositBonusModal/utils';
import Modal from 'components/Modal';
import TabSelector from 'components/TabSelector';
import Text from 'components/Text';

import ActiveBonus from './ActiveBonus';
import RequestStatus from './RequestStatus';
import WaitingStep from './RequestStatus/WaitingSection';
import StakeInputs from './StakeInputs';
import UnstakeInputs from './UnstakeInputs';
import VaultStats from './VaultStats';
import { AmendStep } from './enums';
import { Container, StyledLoading } from './styles';
// import usePlatformVaultAmend from './usePlatformVaultAmend';
import { getActionButtonState, getCurrentAmendStep } from './utils';
import { USDT_DECIMALS } from 'constants/contracts';
import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';
import { Modals } from 'constants/modals';
import { NetworkContextName } from 'constants/networkContextName';
import { useAppContext } from 'contexts/AppContext';
import { Logger } from 'ethers/lib/utils';
import { getCurrentPps } from 'pages/Vaults/utils';
import { useFetchPlatformVaultsDataWithHoldings } from 'queryHooks/platformVaults';
import { theme } from 'theme/Theme';

import { LocalStorageTransactionTypes, NotificationType } from 'enums';
import { BigNumber, constants } from 'ethers';
import { ProfileBalanceOps } from 'interfaces';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export enum AmendType {
  Stake,
  UnStake,
}

const getHeading = (type: AmendType, t) => {
  switch (type) {
    case AmendType.Stake:
      return t('addLiquidity');
    case AmendType.UnStake:
      return t('removeLiquidity');
    default:
      return '';
  }
};

type Props = {
  defaultAmendType: AmendType;
  vaultWallet: string;
};
const AmendLiquidityModal = ({ defaultAmendType, vaultWallet }: Props) => {
  const modal = useModal();
  const { account } = useActiveWeb3React();
  const { library: networkProvider } =
    useWeb3React<Web3Provider>(NetworkContextName);

  const [amendType, setAmendType] = useState(defaultAmendType);
  const [stakeAmount, setStakeAmount] = useState<number | null>(null);
  const [unstakeAmount, setUnstakeAmount] = useState<number | null>(null);
  const [usdtAllowance, setUsdtAllowance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [depositAmount, setDepositAmount] = useState<number | null>(null);
  const [isApprovingUsdt, setIsApprovingUsdt] = useState(false);
  const [isPendingTxSign, setIsPendingTxSign] = useState(false);
  const [isAwaitingConfirmation, setIsAwaitingConfirmation] = useState(false);
  const [ethBalance, setEthBalance] = useState<BigNumber>(constants.Zero);
  const [usdtBalance, setUsdtBalance] = useState<number>(0);
  const [stakeResultData, setStakeResultData] = useState<
    ProfileBalanceOps | undefined
  >(undefined);
  const [stakeToastId, setStakeToastId] = useState<string>();
  const [confirmations, setConfirmations] = useState<number>(0);
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();
  const { t } = useTranslation();
  const {
    store: { account: accountStore },
  } = useAppContext();

  const blockHandlerRef = useRef<(blockNumber: number) => void>();

  const [isDepositConfirmed, setIsDepositConfirmed] = useState(false);

  // undefined when request hasnt been sent yet, else true for success, false for failure
  const [unstakeRequestStatus, setUnstakeRequestStatus] = useState<
    boolean | undefined
  >();
  const [stakeRequestStatus, setStakeRequestStatus] = useState<
    boolean | undefined
  >();

  const walletBalance = accountStore?.accountStats?.balance ?? 0;
  const balanceOperations = accountStore?.accountStats?.balanceOperations ?? [];

  const {
    stakeToVault,
    getUsdtAllowanceForVault,
    approveUsdtForVault,
    isUsdtApprovedForVault,
    sendVaultStakeTxHashToBackend,
    sendUnstakeFromVaultRequest,
  } = useRabbitContractsAPI();

  const {
    getAccountEthBalance,
    getAccountUsdtBalance,
    areReadContractsSet: areDepositReadContractsSet,
  } = useDepositsAPI();

  const updateState = async () => {
    try {
      const [ethBalance, _usdtBalance, _usdtAllowance] = await Promise.all([
        getAccountEthBalance(),
        getAccountUsdtBalance(),
        getUsdtAllowanceForVault(),
      ]);
      setUsdtBalance(bigNumberToFloat(_usdtBalance, USDT_DECIMALS));
      setEthBalance(ethBalance);
      setUsdtAllowance(bigNumberToFloat(_usdtAllowance, USDT_DECIMALS));
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = async () => {
    setUsdtAllowance(0);
    setIsPendingTxSign(false);
    setIsApprovingUsdt(false);
    setIsLoading(true);
    setStakeResultData(undefined);
    await updateState();
  };

  // Enable USDT
  const enableUsdt = async () => {
    if (!(await validateNetworkAndSwitchIfRequired('Approve USDT again.'))) {
      return;
    }

    try {
      if (!account || !stakeAmount) return;

      showNotification({
        title: `Approving ${brand.tokenTicker}`,
        description: `Approving ${brand.tokenTicker} for staking. Confirm the transaction in your wallet.`,
        type: NotificationType.Info,
      });

      flushSync(() => {
        setIsPendingTxSign(true);
      });
      const tx = await approveUsdtForVault();
      flushSync(() => {
        setIsPendingTxSign(false);
        setIsApprovingUsdt(true);
      });
      showNotification({
        title: `Waiting for ${brand.tokenTicker} approval to complete`,
        description: `The approval transaction has been sent. Waiting for it to complete.`,
        type: NotificationType.Info,
      });
      await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showNotification({
        title: `${brand.tokenTicker} Approval Successful.`,
        description: 'You can now proceed with the deposit.',
        type: NotificationType.Positive,
      });
      return true;
    } catch (e: any) {
      showNotification({
        title: t('tokenApprovalFailedTitle', { token: brand.tokenTicker }),
        description: t('tokenApprovalFailedDescription', {
          errorMessage: e.message,
          token: brand.tokenTicker,
        }),
        type: NotificationType.Negative,
      });

      return false;
    } finally {
      await resetState();
    }
  };

  const processTransactionConfirmations = async (
    tx: TransactionReceipt,
    networkProvider: Web3Provider,
    walletAddress: string,
    depositAmount: number,
  ) => {
    if (!networkProvider) return;

    // Fetch the latest transaction receipt
    const minedTx = await networkProvider.getTransactionReceipt(
      tx.transactionHash,
    );

    if (!minedTx || minedTx.status !== 1) {
      console.log('Transaction was unsuccessful, not tracking confirmations.');
      return;
    }

    saveTxToLocalStorage(
      minedTx,
      LocalStorageTransactionTypes.VaultStake,
      walletAddress,
      depositAmount,
    );

    // Get the current block number
    let currentBlockNumber = await networkProvider.getBlockNumber();

    // Compute the number of confirmations the transaction currently has
    // Add 1 because when the transaction is mined, it has 1 confirmation
    let confirmations = currentBlockNumber - minedTx.blockNumber + 1;
    setConfirmations(confirmations);

    // Check if transaction already has required confirmations
    if (hasRequiredConfirmations(confirmations)) {
      setIsAwaitingConfirmation(false);
      setStakeRequestStatus(true);
      removeTxFromLocalStorage(tx.transactionHash, walletAddress);
      return;
    }

    const toastId = showToast(
      depositAmount,
      confirmations,
      minedTx.transactionHash,
      LocalStorageTransactionTypes.VaultStake,
    );

    setStakeToastId(stakeToastId);

    const updateConfirmations = (value: number) => {
      setConfirmations(value);

      // Update the content of the toast
      toast.update(toastId, {
        render: (
          <DepositInProgress
            confirmations={value}
            depositAmount={depositAmount}
            txhash={minedTx.transactionHash}
            type={LocalStorageTransactionTypes.VaultStake}
          />
        ),
        progress: value / CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
      });
    };

    blockHandlerRef.current = blockNumber =>
      handleBlockEvent(
        blockNumber,
        minedTx,
        toastId,
        depositAmount,
        walletAddress,
        networkProvider,
        updateConfirmations,
      );

    // Start listening for 'block' events
    networkProvider.on('block', blockHandlerRef.current);
  };

  const handleBlockEvent = async (
    blockNumber: number,
    minedTx: TransactionReceipt,
    toastId: React.ReactText,
    depositAmount: number,
    walletAddress: string,
    networkProvider: Web3Provider,
    updateConfirmations: (value: number) => void,
  ) => {
    let confirmations = blockNumber - minedTx.blockNumber + 1;

    updateConfirmations(confirmations);

    // If more than CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT confirmations, stop listening to 'block' events and remove transaction from local storage
    if (confirmations >= CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT) {
      networkProvider.off('block', blockHandlerRef.current);

      updateConfirmations(CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT);

      showNotification(
        {
          title: t('tokenDepositSuccessTitle', {
            depositAmount: depositAmount,
            token: brand.tokenTicker,
          }),
          type: NotificationType.Positive,
        },
        true,
      );
      toast.dismiss(toastId);
      setIsAwaitingConfirmation(false);
      setStakeRequestStatus(true);
      removeTxFromLocalStorage(minedTx.transactionHash, walletAddress);
      return;
    }
  };

  // Deposit USDT/USDB, send TxHash to backend, and update state
  const stakeUsdt = async () => {
    if (!stakeAmount || !networkProvider || !account || !vaultWallet) return;

    flushSync(() => {
      setIsPendingTxSign(true);
    });

    if (!(await validateNetworkAndSwitchIfRequired('Restart the deposit'))) {
      return;
    }

    try {
      // Check if USDT/USDB is approved for the deposit amount
      const isUsdtApproved = await isUsdtApprovedForVault(stakeAmount);

      if (!isUsdtApproved) {
        const res = await enableUsdt();
        if (!res) {
          return;
        }
      }

      // showNotification({
      //   type: NotificationType.Info,
      //   title: 'Deposit Started',
      //   description:
      //     'Your deposit has started. Confirm the transaction in your wallet.',
      // });

      const amountBn: BigNumber = parseUnits(
        roundJsDecimalToString(stakeAmount, USDT_DECIMALS),
        USDT_DECIMALS,
      );

      const tx = await stakeToVault(amountBn);

      flushSync(() => {
        setIsAwaitingConfirmation(true);
        setIsPendingTxSign(false);
      });

      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      const stakeResultData = await sendVaultStakeTxHashToBackend(
        vaultWallet,
        amountBn,
        tx.hash,
      );

      setStakeResultData(stakeResultData);

      accountStore.addNewBalanceOperation(stakeResultData);

      // const notifTitle = t('depositRequestedTitle');
      // const notifDescription = t('depositRequestedDescription');
      // showNotification({
      //   title: notifTitle,
      //   description: notifDescription,
      //   type: NotificationType.Info,
      // });

      const minedTx = await tx.wait(1);
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      setConfirmations(brandedSelect({ rabbitx: 1, bfx: 0 }));

      showNotification({
        title: t('depositInProgressTitle'),
        description: t('depositInProgressDescription', {
          confirmationsRequired: CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
        }),
        type: NotificationType.Info,
      });

      if (minedTx?.status !== 1) {
        throw new Error('Transaction reverted');
      }

      processTransactionConfirmations(
        minedTx,
        networkProvider,
        account,
        stakeAmount,
      );

      modal.pop({ name: Modals.amendPlatformVaultLiquidityModal });
    } catch (error: any) {
      if (error.code === Logger.errors.TRANSACTION_REPLACED) {
        // The transaction was canceled/replaced with another unrelated one
        if (error.cancelled) {
          showNotification({
            title: t('transactionCancelledTitle'),
            description: t('transactionCancelledDescription'),
            type: NotificationType.Negative,
          });
        }
        // The user used "speed up" or something similar in their client, but we now have the updated info
        else {
          processTransactionConfirmations(
            error.receipt,
            networkProvider,
            account,
            stakeAmount,
          );
          // we need to return here, because we don't want to show the error and reset state
          return;
        }
      }
      console.log(error);
      setStakeResultData(undefined);
      setIsAwaitingConfirmation(false);
      setIsPendingTxSign(false);
      setIsLoading(true);
      setUsdtAllowance(0);
      await updateState();

      const notifTitle = t('depositRequestFailedTitle');
      const notifDescription = t('depositRequestFailedDescription', {
        errorMessage: error.message,
      });
      showNotification({
        title: notifTitle,
        description: notifDescription,
        type: NotificationType.Negative,
      });
    }
  };

  // Account changed
  useEffect(() => {
    (async () => {
      try {
        if (!account || !areDepositReadContractsSet) return;
        await updateState();
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [account, areDepositReadContractsSet]);

  // Intervally fetch account ETH and USDT/USDB balance and USDT/USDB approval status
  useEffect(() => {
    if (!account || !areDepositReadContractsSet) return;

    const interval = setInterval(async () => {
      await updateState();
    }, 8000);
    return () => clearInterval(interval);
  }, [account, areDepositReadContractsSet]);

  // Deposit result data or balance operations changed
  useEffect(() => {
    if (!stakeResultData || !account || !networkProvider) return;

    if (
      balanceOperations.find(
        b => b.txhash === stakeResultData.txhash && b.status === 'canceled',
      )
    ) {
      showNotification(
        {
          title: t('depositTransactionSpedUpOrCancelledTitle'),
          description: t('depositTransactionSpedUpOrCancelledDescription'),
          type: NotificationType.Info,
        },
        true,
        null,
      );
      resetState();
      modal.clear();
    }

    // If it hasn't been checked yet, check if the deposit tx is confirmed
    if (
      !isDepositConfirmed &&
      balanceOperations.find(
        b => b.txhash === stakeResultData.txhash && b.status === 'success',
      )
    ) {
      setIsDepositConfirmed(true);
      setDepositAmount(Number(stakeResultData.amount));
      showNotification(
        {
          title: t('tokenDepositSuccessTitle', {
            depositAmount: depositAmount,
            token: brand.tokenTicker,
          }),
          type: NotificationType.Positive,
        },
        true,
      );
      removeTxFromLocalStorage(stakeResultData?.txhash ?? '', account);
      networkProvider.off('block', blockHandlerRef.current);
      stakeToastId && toast.dismiss(stakeToastId);
    }
  }, [balanceOperations, stakeResultData, networkProvider, account]);

  const isAmendTypeStake = amendType === AmendType.Stake;
  const isAmendTypeUnstake = amendType === AmendType.UnStake;

  const currentStep = getCurrentAmendStep(
    amendType,
    usdtAllowance,
    stakeAmount ?? 0,
    stakeRequestStatus,
    unstakeRequestStatus,
    isAwaitingConfirmation,
  );

  const onStake = () => {
    if (currentStep === AmendStep.ENABLE_USDT) {
      enableUsdt();
      return;
    }

    if (currentStep === AmendStep.STAKE) {
      stakeUsdt();
      return;
    }

    modal.pop({ name: Modals.amendPlatformVaultLiquidityModal });
  };

  const onUnstake = async () => {
    if (!account || !unstakeAmount) return;

    if (unstakeAmount > userShares) return;

    if (currentStep !== AmendStep.UNSTAKE) {
      modal.pop({ name: Modals.amendPlatformVaultLiquidityModal });
      return;
    }

    try {
      flushSync(() => {
        setIsPendingTxSign(true);
      });

      if (!(await validateNetworkAndSwitchIfRequired('Restart the deposit'))) {
        return;
      }

      if (!vaultDataWithHoldings?.wallet) return;

      const res = await sendUnstakeFromVaultRequest(vaultWallet, unstakeAmount);

      accountStore.addNewBalanceOperation(res);

      setUnstakeRequestStatus(true);

      showNotification({
        title: 'Unstake request sent!',
        description:
          'You will receive your unstaked funds in trading wallet within 24 hours.',
        type: NotificationType.Positive,
      });

      modal.pop({ name: Modals.amendPlatformVaultLiquidityModal });
    } catch (err) {
      console.error('Error Unstaking Shares : ', err);
      setUnstakeRequestStatus(false);
    } finally {
      setIsPendingTxSign(false);
    }
  };

  const {
    data: allVaultsWithHoldings,
    isLoading: isLoadingVaultData,
    isError: isErrorVaultData,
    refetch: refetchVaultData,
    isRefetching: isRefetchingVaultData,
  } = useFetchPlatformVaultsDataWithHoldings(vaultWallet);

  const vaultDataWithHoldings = allVaultsWithHoldings?.[0];

  const userShares = vaultDataWithHoldings?.holdings?.userShares ?? 0;

  const { label: buttonLabel, isDisabled: isButtonDisabled } =
    getActionButtonState(
      currentStep,
      amendType,
      isPendingTxSign,
      isAwaitingConfirmation,
      isApprovingUsdt,
      stakeAmount,
      unstakeAmount,
      userShares,
      usdtBalance,
      t,
    );

  const buttonColorVariant =
    currentStep === AmendStep.STAKE_SUCCESS ||
    currentStep === AmendStep.UNSTAKE_SUCCESS
      ? 'primaryGreen'
      : currentStep === AmendStep.STAKE_FAILED ||
        currentStep === AmendStep.UNSTAKE_FAILED
      ? 'primaryRed'
      : isAmendTypeUnstake
      ? 'primaryRed'
      : 'primaryGreen';

  const handleCtaPress = () => {
    if (isButtonDisabled) return;
    isAmendTypeStake ? onStake() : onUnstake();
  };

  return (
    <Modal
      name={Modals.amendPlatformVaultLiquidityModal}
      showHeader={false}
      showCloseIcon={false}
      size="auto"
      data-gtmid={`amend-liquidity-modal`}
      handleKeyStrokes={{
        [Key.Enter]: handleCtaPress,
      }}
    >
      <Container>
        <Text variant="BODY_L" className="align-center" fontWeight="semiBold">
          {getHeading(amendType, t)}
        </Text>

        <TabSelector
          disabled={
            isPendingTxSign ||
            isAwaitingConfirmation ||
            [
              AmendStep.STAKE_FAILED,
              AmendStep.STAKE_SUCCESS,
              AmendStep.UNSTAKE_FAILED,
              AmendStep.UNSTAKE_SUCCESS,
            ].includes(currentStep) // Dont let user switch if request has been sent.
          }
          buttonOptions={[
            {
              label: 'Stake',
              value: AmendType.Stake,
              bgColor: theme.colors.shadesBackground900,
            },
            {
              label: 'Unstake',
              value: AmendType.UnStake,
              bgColor: theme.colors.shadesBackground900,
            },
          ]}
          handleOptionSelect={setAmendType}
          initialSelectedOption={amendType}
        />

        {isBfxDepositBonusActive ? (
          <ActiveBonus
            onClick={() => {
              modal.present(
                <DepositBonusModal isOriginAmendModal={true} />,
                Modals.bfxDepositBonusModal,
              );
            }}
          />
        ) : null}

        {(isLoadingVaultData || isLoading) && !isErrorVaultData ? (
          <StyledLoading />
        ) : (
          <>
            {[
              AmendStep.ENABLE_USDT,
              AmendStep.STAKE,
              AmendStep.UNSTAKE,
            ].includes(currentStep) ? (
              <>
                <VaultStats
                  amendType={amendType}
                  pricePerShare={vaultDataWithHoldings?.sharePrice}
                  totalIssuedShares={vaultDataWithHoldings?.totalShares}
                  vaultEquity={vaultDataWithHoldings?.accountEquity}
                  vaultName={vaultDataWithHoldings?.vaultName}
                  unstakeAmount={unstakeAmount}
                  performanceFee={vaultDataWithHoldings?.performanceFee}
                />

                {isAmendTypeStake ? (
                  <StakeInputs
                    amount={stakeAmount}
                    onAmountChange={setStakeAmount}
                    availableBalance={usdtBalance}
                    pricePerShare={vaultDataWithHoldings?.sharePrice ?? 1} // @recheck: sharePrice is zero for some reason
                    onRefetchPricePerShare={() => refetchVaultData()}
                    isRefetching={isRefetchingVaultData}
                    disabled={isPendingTxSign}
                  />
                ) : null}

                {isAmendTypeUnstake ? (
                  <UnstakeInputs
                    availableBalance={userShares}
                    amount={unstakeAmount}
                    onAmountChange={setUnstakeAmount}
                    maxRedeemableShares={userShares}
                    disabled={isPendingTxSign}
                  />
                ) : null}
              </>
            ) : null}

            {[AmendStep.AWAITING_STAKE_CONFIRMATION].includes(currentStep) ? (
              <WaitingStep
                depositAmount={stakeAmount}
                confirmations={confirmations}
                step={currentStep}
              />
            ) : null}

            {[AmendStep.UNSTAKE_SUCCESS, AmendStep.UNSTAKE_FAILED].includes(
              currentStep,
            ) ? (
              <RequestStatus
                amount={unstakeAmount ?? 0}
                step={currentStep}
                type={AmendType.UnStake}
              />
            ) : null}

            {[AmendStep.STAKE_SUCCESS, AmendStep.STAKE_FAILED].includes(
              currentStep,
            ) ? (
              <RequestStatus
                amount={stakeAmount ?? 0}
                step={currentStep}
                type={AmendType.Stake}
              />
            ) : null}

            <Button
              disabled={isButtonDisabled}
              colorVariant={buttonColorVariant}
              sizeVariant="S"
              onClick={isAmendTypeStake ? onStake : onUnstake}
              data-gtmid={`button-${
                isAmendTypeStake ? 'stake' : 'unstake'
              }-vault`}
            >
              {buttonLabel}
            </Button>
          </>
        )}
      </Container>
    </Modal>
  );
};

export default observer(AmendLiquidityModal);
