import Text from 'components/Text';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const IconPairContainer = styled(Text)`
  margin: -2px 0px;
`;
