import { Tooltip } from '@material-ui/core';

import { getSelectedMarketLeverage } from 'utils';

import { FormattedNumber, Icon } from 'components';
import Badge from 'components/Badge';
import Loading from 'components/Loading';
import Text from 'components/Text';

import { ColoredText, Colors } from '../shared';
import Header from './Header';
import MarketLeverageSlider from './MarketLeverageSlider';
import BlankLeverageSlider from './MarketLeverageSlider/BlankLeverageSlider';
import VipTier from './VipTier';
import {
  AccountHealthStyled,
  AccountStatsStyled,
  AccountStatsStyledProps,
  Content,
  SliderBlank,
  Row,
  ValueWithLabel,
} from './styles';
import heartGray from 'assets/icons/heart-gray.svg';
import heartGreen from 'assets/icons/heart-green.svg';
import heartRed from 'assets/icons/heart-red.svg';
import heartYellow from 'assets/icons/heart-yellow.svg';
import leverageSphereGray from 'assets/icons/leverage-sphere-gray.svg';
import leverageSphere from 'assets/icons/leverage-sphere.svg';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const getHeartIcon = (health: number) => {
  if (health > 50) {
    return heartGreen;
  } else if (health > 3.5) {
    return heartYellow;
  }
  return heartRed;
};
const getHealthColor = (health: number) => {
  if (health > 50) {
    return Colors.Success;
  } else if (health > 3.5) {
    return Colors.Warning;
  }
  return Colors.Danger;
};

export const AccountStats = observer((props: AccountStatsStyledProps) => {
  const {
    store: {
      account: accountStore,
      markets: { selectedMarketId },
    },
  } = useAppContext();
  const { t } = useTranslation();

  // Wallet not connected
  if (
    !accountStore.frontendSecrets ||
    !accountStore._accountStats ||
    selectedMarketId === null
  ) {
    return (
      <AccountStatsStyled {...props}>
        <Header />
        <Content data-cy="account-stats">
          <Row className="mb-20">
            <ValueWithLabel className="flex-1">
              <Tooltip title="Wallet Balance + Unrealised PNL">
                <Text variant="BODY_S" color="shadesForeground200">
                  {t('accountEquity')}
                </Text>
              </Tooltip>
              <Text
                variant="NUMBERS_XL"
                fontWeight="semiBold"
                color="shadesForeground200"
              >
                --
              </Text>
            </ValueWithLabel>

            <AccountHealthStyled>
              <img
                src={leverageSphereGray}
                alt=""
                className="absolute-sphere"
              />

              <div className="heart-and-percentage">
                <Text color="shadesForeground200" data-cy="health-percentage">
                  --
                </Text>
                <Icon size="S" src={heartGray} alt="Heart Icon" />
              </div>
            </AccountHealthStyled>
          </Row>

          <SliderBlank className="mb-20">
            <Text variant="BODY_S" color="shadesForeground200" flexed gap={5}>
              {t('tier')}
              <Badge borderColor="shadesBackground500" bgColor="transparent">
                /
              </Badge>
            </Text>
            <div className="slider"></div>
          </SliderBlank>

          <SliderBlank className="mb-20">
            <Text variant="BODY_S" color="shadesForeground200">
              {t('leverage')}
            </Text>
            <BlankLeverageSlider />
          </SliderBlank>

          <Row className="mb-16">
            <ValueWithLabel>
              <Tooltip title="Current profit or loss from all open positions">
                <Text variant="BODY_S" color="shadesForeground200">
                  {t('unrealisedPnl')}
                </Text>
              </Tooltip>
              <Text
                variant="BODY_M"
                fontWeight="semiBold"
                color="shadesForeground200"
              >
                --
              </Text>
            </ValueWithLabel>

            <ValueWithLabel>
              <Tooltip title="Open Positions / Account Equity">
                <Text variant="BODY_S" color="shadesForeground200">
                  {t('accountLeverage')}
                </Text>
              </Tooltip>
              <Text
                variant="BODY_M"
                fontWeight="semiBold"
                color="shadesForeground200"
              >
                --
              </Text>
            </ValueWithLabel>
          </Row>
          <Row>
            <ValueWithLabel>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('buyingPower')}
              </Text>
              <Text
                variant="BODY_M"
                fontWeight="semiBold"
                color="shadesForeground200"
              >
                --
              </Text>
            </ValueWithLabel>

            <ValueWithLabel>
              <Tooltip title="Deposits - Withdrawals + Realised Pnl">
                <Text variant="BODY_S" color="shadesForeground200">
                  {t('walletBalance')}
                </Text>
              </Tooltip>
              <Text
                variant="BODY_M"
                fontWeight="semiBold"
                color="shadesForeground200"
              >
                --
              </Text>
            </ValueWithLabel>
          </Row>
        </Content>
      </AccountStatsStyled>
    );
  }

  // Account loading
  if (accountStore.isLoading) {
    return (
      <AccountStatsStyled {...props}>
        <Header />
        <Content>
          <div className="loading-wrapper">
            <Loading />
          </div>
        </Content>
      </AccountStatsStyled>
    );
  }

  const {
    balance: walletBalance,
    cumUnrealizedPnl,
    accountEquity,
    accountLeverage,
    health,
    withdrawableBalance,
    leverage: marketLeverage,
    tierStatus,
    cumTradingVolume,
  } = accountStore._accountStats;

  // Sometimes on initial data, health is undefined, so let's show 100% as default, the same way we always show
  // "positive" price change on first render
  const healthPercentage = health ? Math.round(health * 100) : 100;
  const accountHealthIcon = getHeartIcon(healthPercentage);
  const healthColor = getHealthColor(healthPercentage);

  const buyingPower =
    withdrawableBalance *
    getSelectedMarketLeverage(marketLeverage, selectedMarketId)!;

  return (
    <AccountStatsStyled {...props}>
      <Header />

      <Content data-cy="account-stats">
        <Row className="mb-20">
          <ValueWithLabel className="flex-1">
            <Tooltip title={t('accountEquityTooltip')}>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('accountEquity')}
              </Text>
            </Tooltip>

            <FormattedNumber
              value={accountEquity}
              prefix="$"
              units={0}
              tooltipText={t('accountEquityTooltip') + ' = ' + accountEquity}
              data-cy="account-equity"
              variant="NUMBERS_XL"
              fontWeight="semiBold"
              negativeToZero
            />
          </ValueWithLabel>

          <AccountHealthStyled>
            <img src={leverageSphere} alt="" className="absolute-sphere" />
            <Tooltip title={t('accountHealthTooltip')}>
              <div className="heart-and-percentage">
                <ColoredText color={healthColor} data-cy="health-percentage">
                  {healthPercentage}%
                </ColoredText>
                <Icon size="S" src={accountHealthIcon} alt="Heart Icon" />
              </div>
            </Tooltip>
          </AccountHealthStyled>
        </Row>

        {tierStatus && (
          <VipTier
            tierStatus={tierStatus}
            volume30d={cumTradingVolume ?? 0}
            health={health}
            className="mb-20"
          />
        )}

        <MarketLeverageSlider className="mb-20" />

        <Row className="mb-16">
          <ValueWithLabel>
            <Tooltip title={t('unrealisedPnlTooltip')}>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('unrealisedPnl')}
              </Text>
            </Tooltip>
            <Text variant="BODY_M" fontWeight="semiBold">
              <ColoredText
                color={
                  cumUnrealizedPnl === undefined
                    ? Colors.Inherit
                    : cumUnrealizedPnl < 0
                    ? Colors.Danger
                    : Colors.Success
                }
              >
                <FormattedNumber
                  value={cumUnrealizedPnl}
                  prefix="$"
                  data-cy="unrealised-pnl"
                />
              </ColoredText>
            </Text>
          </ValueWithLabel>

          <ValueWithLabel>
            <Tooltip title={t('accountLeverageTooltip')}>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('accountLeverage')}
              </Text>
            </Tooltip>
            <Text variant="BODY_M" fontWeight="semiBold">
              <FormattedNumber
                value={accountLeverage}
                suffix="x"
                data-cy="account-leverage"
              />
            </Text>
          </ValueWithLabel>
        </Row>

        <Row>
          <ValueWithLabel>
            <Text variant="BODY_S" color="shadesForeground200">
              {t('buyingPower')}
            </Text>
            <Text variant="BODY_M" fontWeight="semiBold">
              <FormattedNumber
                value={Math.max(0, buyingPower)}
                prefix="$"
                data-cy="buying-power"
                negativeToZero
              />
            </Text>
          </ValueWithLabel>

          <ValueWithLabel>
            <Tooltip title={t('walletBalanceTooltip')}>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('walletBalance')}
              </Text>
            </Tooltip>
            <Text variant="BODY_M" fontWeight="semiBold">
              <FormattedNumber
                value={walletBalance}
                prefix="$"
                data-cy="wallet-balance"
                negativeToZero
              />
            </Text>
          </ValueWithLabel>
        </Row>
      </Content>
    </AccountStatsStyled>
  );
});
