import { useState } from 'react';

import {
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { useBalanceOperationsAPI, usePaginationFetch } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';

import TableLoader from 'components/TableLoader';

import { StyledTable, TableContainer } from '../styles';
import SettlementsFilterOptions, {
  TSettlementsFilter,
} from './SettlementsFilterOptions';
import TableHead from './SettlementsHead';
import SettlementsRow from './SettlementsRow';
import { useAppContext } from 'contexts/AppContext';

import { QueryKeys } from 'enums';
import { ProfileBalanceOps } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const constructRequestQueryParamsFilters = (filter: TSettlementsFilter) => {
  const params = {};

  if (filter.type) {
    params['ops_type'] = filter.type;
  }

  if (
    filter.dateRange &&
    filter.dateRange.range &&
    filter.dateRange.range[0] &&
    filter.dateRange.range[1]
  ) {
    params['start_time'] = filter.dateRange.range[0];
    params['end_time'] = filter.dateRange.range[1];
  }

  return params;
};

type Props = {
  showLabelOnTableCell?: boolean;
  showTableHead?: boolean;
  showFilterOptions?: boolean;
};
const SettlementsTable = ({
  showTableHead = true,
  showLabelOnTableCell = false,
  showFilterOptions = false,
}: Props) => {
  const { isOnboarding } = useExchangeAPI();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();
  const { fetchPrivateSettlements } = useBalanceOperationsAPI();
  const [appliedFilters, setAppliedFilters] = useState<TSettlementsFilter>({});

  const {
    isLoading,
    data: settlements,
    pagination,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePaginationFetch<ProfileBalanceOps>({
    fetchFn: fetchPrivateSettlements,
    socketSource: null,
    initialPagination: { page: 0, limit: 10 },
    currentAuthenticatedWallet: frontendSecrets?.profile?.wallet,
    isOnboarding,
    requestQueryParams: constructRequestQueryParamsFilters(appliedFilters),
    queryKey: QueryKeys.Settlements,
  });

  if (isLoading || totalCount === null || !frontendSecrets) {
    return (
      <>
        {showFilterOptions ? (
          <SettlementsFilterOptions
            appliedFilters={appliedFilters}
            onChangeFilter={setAppliedFilters}
          />
        ) : null}
        <TableContainer>
          <StyledTable>
            {showTableHead ? <TableHead /> : null}
            <TableBody>
              {!frontendSecrets ? (
                <TableRow>
                  <TableCell colSpan={100} className="no-items">
                    {t('connectYourWalletToStartTrading')}
                  </TableCell>
                </TableRow>
              ) : (
                <TableLoader />
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </>
    );
  }

  const { page, limit: rowsPerPage } = pagination;

  const sortedSettlements = settlements ?? [];

  return (
    <>
      {showFilterOptions ? (
        <SettlementsFilterOptions
          appliedFilters={appliedFilters}
          onChangeFilter={setAppliedFilters}
        />
      ) : null}
      <TableContainer useScroll={false}>
        <StyledTable stickyHeader>
          {showTableHead ? <TableHead /> : null}

          <TableBody>
            {sortedSettlements.length > 0 ? (
              sortedSettlements.map(item => {
                const key = `${item.id}`;
                return (
                  <SettlementsRow
                    showLabels={showLabelOnTableCell}
                    settlement={item}
                    key={key}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={100} className="no-items">
                  You have no settlements.
                </td>
              </tr>
            )}
          </TableBody>
        </StyledTable>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default observer(SettlementsTable);
