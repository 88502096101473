import { memo } from 'react';

import { showNotification, textWithEllipsisInTheMiddle } from 'utils';

import { useExchangeAPI } from 'hooks/useExchangeAPI';
import useModal from 'hooks/useModal';

import { WalletStatusStyled, Avatar } from './styles';
import caretDownIcon from 'assets/icons/chevron-down-white.svg';
import copyIcon from 'assets/icons/copy.svg';
import metamaskIcon from 'assets/icons/metamask.svg';
import profileIcon from 'assets/icons/socials/profile.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';

import { socialconnect } from 'connectors';
import { NotificationType } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type Props = {
  account: string | null | undefined;
  onAddressClick: () => void;
  onAvatarClick: () => void;
};

const WalletStatus = ({ account, onAddressClick, onAvatarClick }: Props) => {
  const modal = useModal();
  const { t } = useTranslation();
  const {
    store: {
      account: { frontendSecrets, _accountStats },
      savedWallets: { getSavedWallet },
    },
  } = useAppContext();
  const { onboardUser } = useExchangeAPI();

  if (!account) return null;

  const walletAddress =
    frontendSecrets && _accountStats ? _accountStats.wallet : account;

  const savedConfig = getSavedWallet(account);

  // just in case, if saved wallet isn't found in local storage, we give it a deafault image
  const { emoji, image } = savedConfig || {
    image: socialconnect?.particleNetwork?.auth?.isLogin()
      ? profileIcon
      : metamaskIcon,
  };

  const onSignAndVerify = () => {
    onboardUser();
    modal.present(<WalletModal />, Modals.walletModal);
  };

  const onCopy = () => {
    navigator.clipboard
      .writeText(walletAddress)
      .then(() => {
        showNotification({
          type: NotificationType.Positive,
          title: 'Wallet Address Copied to Clipboard',
        });
      })
      .catch(error => {
        showNotification({
          type: NotificationType.Negative,
          title: 'Failed to Copy to Clipboard',
        });
      });
  };

  return (
    <WalletStatusStyled>
      <Avatar onClick={onAvatarClick}>
        {image ? (
          <img alt={'avatar'} src={image} className="avatar" />
        ) : (
          <span className="avatar">{emoji}</span>
        )}
      </Avatar>
      <div className="column">
        <span
          onClick={onAddressClick}
          className="text-primary"
          data-gtmid="dropdown-connected"
        >
          {textWithEllipsisInTheMiddle(walletAddress)}
          <img src={caretDownIcon} alt="more" />
        </span>

        {frontendSecrets ? (
          <span
            className="text-secondary"
            onClick={onCopy}
            data-gtmid="button-copy-address-1"
          >
            <img alt="copy" src={copyIcon} />
            {t('copyAddress')}
          </span>
        ) : (
          <span
            className="text-secondary success"
            data-gtmid="button-sign"
            onClick={onSignAndVerify}
          >
            {t('signAndVerify')}
          </span>
        )}
      </div>
    </WalletStatusStyled>
  );
};

export default memo(observer(WalletStatus));
