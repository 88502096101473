import styled, { css } from 'styled-components';

type RowProps = {
  gap?: number;
  align?: 'center' | 'flex-start' | 'flex-end' | 'initial';
  justify?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'initial'
    | 'space-between'
    | 'space-around';
  flex?: number | string;
};
export const Row = styled.div<RowProps>`
  display: flex;
  align-items: ${({ align = 'center' }) => align};

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

    ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
`;

export default Row;
