import { memo } from 'react';

import { TimescaleType } from 'enums';
import { useTranslation } from 'react-i18next';

type TimescaleOption = {
  label: string;
  value: TimescaleType;
  selected?: boolean;
};

const timescaleOptions = (t: any): TimescaleOption[] => [
  { label: t('ALL'), value: TimescaleType.All },
  { label: t('1H'), value: TimescaleType.Hour },
  { label: t('1D'), value: TimescaleType.Day },
  { label: t('1W'), value: TimescaleType.Week },
  { label: t('1M'), value: TimescaleType.Month, selected: true },
  { label: t('1Y'), value: TimescaleType.Year },
];

type Props = {
  onChange: (value: TimescaleType) => void;
  selectedTimescale: string;
};
const Timescale = ({ onChange, selectedTimescale }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="timescale">
      {timescaleOptions(t).map(({ label, value }) => (
        <button
          className={`${selectedTimescale === value ? 'selected' : ''}`}
          onClick={() => onChange(value)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default memo(Timescale);
