import { MarketFilterType } from './MarketFilters/TabFilters';
import { SortConfig } from './MarketsTable';

import { Market } from 'interfaces';

export const isMarketFavorite = (
  marketId: string,
  settingsData: any,
): boolean => {
  if (settingsData?.favoriteMarkets) {
    return !!settingsData.favoriteMarkets[marketId];
  }
  return false;
};

export const getFilteredArray = ({
  markets,
  selectedFilter,
  searchTerm,
  userSettings,
}: {
  markets: Market[];
  selectedFilter: MarketFilterType;
  searchTerm: string;
  userSettings: any;
}) => {
  let filteredMarkets = [...markets];

  if (selectedFilter === 'Favourites') {
    filteredMarkets = filteredMarkets.filter(market =>
      isMarketFavorite(market.id, userSettings),
    );
  } else if (selectedFilter === 'Top Gainers') {
    filteredMarkets = filteredMarkets
      .filter(market => market.lastTradePrice24hChangeBasis > 0)
      .sort(
        (a, b) =>
          b.lastTradePrice24hChangeBasis - a.lastTradePrice24hChangeBasis,
      );
  } else if (selectedFilter === 'Top Losers') {
    filteredMarkets = filteredMarkets
      .filter(market => market.lastTradePrice24hChangeBasis < 0)
      .sort(
        (a, b) =>
          a.lastTradePrice24hChangeBasis - b.lastTradePrice24hChangeBasis,
      );
  } else if (selectedFilter === 'New Listings') {
    // Get the last 4 markets
    filteredMarkets = filteredMarkets.slice(-4);
  }

  if (searchTerm) {
    filteredMarkets = filteredMarkets.filter(
      market =>
        market.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        market.marketTitle.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  return filteredMarkets;
};

export const getSortedMarkets = (
  sortConfig: SortConfig | null,
  markets: Market[],
  coingeckoMarketCaps: Record<string, number>,
) => {
  if (
    sortConfig === null ||
    sortConfig.numClicks === 0 ||
    sortConfig.numClicks % 3 === 0
  )
    return markets;

  // if the numClicks is odd, we want to sort in descending order, otherwise ascending order
  const isDescending = sortConfig.numClicks % 2 === 1;

  if (sortConfig.value === 'marketCap') {
    const sortedMarkets = [...markets].sort((a, b) => {
      const aValue = coingeckoMarketCaps[a.id];
      const bValue = coingeckoMarketCaps[b.id];

      if (aValue < bValue) {
        return isDescending ? 1 : -1;
      }
      if (aValue > bValue) {
        return isDescending ? -1 : 1;
      }
      return 0;
    });

    return sortedMarkets;
  }

  const sortedMarkets = [...markets].sort((a, b) => {
    const aValue = a[sortConfig.value];
    const bValue = b[sortConfig.value];

    if (aValue < bValue) {
      return isDescending ? 1 : -1;
    }
    if (aValue > bValue) {
      return isDescending ? -1 : 1;
    }
    return 0;
  });

  return sortedMarkets;
};
