import useModal from 'hooks/useModal';

import Button from 'components/Button';
import Loading from 'components/Loading';

import { AirdropUserStats } from '../PhaseContainer';
import EligibilityAndClaimSection from './EligibilityAndClaimSection';
import { ConnectWalletContainer } from './styles';
import walletIcon from 'assets/icons/wallet.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { observer } from 'mobx-react';

type Props = {
  airdropUserStats: AirdropUserStats;
  isLoadingAirdropUserStats: boolean;
  isErrorAirdropUserStats: boolean;
};
const PhaseOne = ({
  airdropUserStats,
  isLoadingAirdropUserStats,
  isErrorAirdropUserStats,
}: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const modal = useModal();

  if (!frontendSecrets?.jwt) {
    return (
      <ConnectWalletContainer>
        <span className="header">Wallet Verification</span>
        <span className="description">
          Connect & sign your wallet. The wallet will need to support ETH, such
          as Rabby with the network set to Mainnet
        </span>
        <Button
          variant="secondaryOutline"
          size="medium"
          onClick={() => {
            modal.present(<WalletModal />, Modals.walletModal);
          }}
          data-gtmid="button-connect-wallet-2"
        >
          Connect
          <img src={walletIcon} alt="wallet" className="icon-right" />
        </Button>
      </ConnectWalletContainer>
    );
  }

  if (isLoadingAirdropUserStats) {
    return (
      <div className="flex justify-center w-100">
        <Loading />
      </div>
    );
  }

  if (isErrorAirdropUserStats) {
    return (
      <ColoredText color={Colors.Danger}>
        Error fetching eligibility
      </ColoredText>
    );
  }

  return <EligibilityAndClaimSection airdropUserStats={airdropUserStats} />;
};

export default observer(PhaseOne);
