import { useRef } from 'react';

import { useOnClickOutside } from 'hooks';
import useKey, { Key } from 'hooks/useKey';

import Button from 'components/Button';

import { Notification } from './Notification';
import { useAppContext } from 'contexts/AppContext';
import { notifications } from 'mockData/notifications';

import styled from 'styled-components';

const NotificationsSidebarStyled = styled.div`
  position: absolute;
  overflow-y: hidden;
  z-index: 12;
  top: 0;
  width: 100%;
  height: 100%;
  /* mobile viewport bug fix */
  height: -webkit-fill-available;
  background: rgba(52, 64, 84, 0.6);
  backdrop-filter: blur(16px);
  transition: all 0.5s;
`;

const NotificationsSidebarContentStyled = styled.div`
  position: fixed;
  right: 0;
  height: 100%;
  width: 300px;
  transition: all 0.5s;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.shadesBackground800};
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    width: 400px;
  }
`;

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;

  > h1 {
    margin: 0;
    padding: 0;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const NotificationsList = styled.ul`
  list-style-type: none;
  padding: 0;
  height: 100%;
  overflow-y: scroll;
  li {
    margin: 20px 0;
  }
`;

const NotificationsSidebar = () => {
  const {
    store: { appState },
  } = useAppContext();

  const close = () => appState.setIsNotificationsSidebarOpen(false);

  const sidebarContentRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(sidebarContentRef, () => close());

  useKey(Key.Escape, () => close());

  return (
    <NotificationsSidebarStyled>
      <NotificationsSidebarContentStyled ref={sidebarContentRef}>
        <HeaderStyled>
          <h1>Notifications</h1>
          <Button
            size="small"
            variant="subtle"
            isSupport={true}
            onClick={() => {}}
            gaLabelName="MARK_ALL_NOTIFICATIONS_AS_READ"
            disableConnectWallet={true}
            underline={true}
          >
            mark all as read
          </Button>
        </HeaderStyled>
        <NotificationsList>
          {notifications.map(el => (
            <li key={el.title}>
              <Notification notification={el} />
            </li>
          ))}
        </NotificationsList>
      </NotificationsSidebarContentStyled>
    </NotificationsSidebarStyled>
  );
};

export default NotificationsSidebar;
