import { Tooltip, TooltipProps } from '@material-ui/core';

import Text, { TextProps } from 'components/Text';

import styled from 'styled-components';

const StyledText = styled(Text)`
  cursor: help;
  text-decoration-style: dashed;
  text-decoration-thickness: 0px;
  text-decoration-style: dashed;
  text-underline-position: under;
  text-decoration-line: underline;
`;

type Props = {
  title: string;
  placement?: TooltipProps['placement'];
} & TextProps;
const TextToolTip = ({ title, placement = 'top', ...rest }: Props) => {
  return (
    <Tooltip placement={placement} title={title}>
      <StyledText {...rest} />
    </Tooltip>
  );
};

export default TextToolTip;
