export enum DepositMethod {
  Wallet = 'Wallet',
  Card = 'Card',
  ApplePay = 'ApplePay',
  GooglePay = 'GooglePay',
  BinancePay = 'BinancePay',
  Mesh = 'Mesh',
}

export enum MeshIntegration {
  Bitfinex = 'bitfinex',
  Coinbase = 'coinbase',
  Binance = 'binance',
  Others = 'others',
}
