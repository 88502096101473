import { memo } from 'react';

import { brand, isBrandRabbitX } from 'utils/brand';

import Button from 'components/Button/button';
import { SelectDropdown } from 'components/SelectDropdown.tsx';

import infoIcon from 'assets/icons/info-circle-thin.svg';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type InfoOption = 'Docs' | 'API' | 'Discord' | 'Telegram';

const options: InfoOption[] = ['Docs', 'API'];

if (isBrandRabbitX) {
  options.push('Discord');
} else {
  options.push('Telegram');
}

const optionToLink = (option: InfoOption) => {
  switch (option) {
    case 'Docs':
      return brand.docsLink;
    case 'API':
      return brand.apiDocsLink;
    case 'Discord':
      return 'https://discord.com/invite/rabbitx';
    case 'Telegram':
      return brand.telegramUrl;
    default:
      return '';
  }
};

const TextParser = styled.span`
  color: ${({ theme }) => theme.colors.shadesForeground200};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 109px;
  padding: 10px;
  border-radius: 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.shadesBackground700};
  }
  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const textParser = (t, _value: InfoOption) => {
  const value = _value === 'Docs' ? t('docs') : _value;
  return <TextParser>{value}</TextParser>;
};

const InfoDropdown = () => {
  const { t } = useTranslation();
  const handleOptionSelect = (option: InfoOption) => {
    window.open(optionToLink(option), '_blank');
  };

  return (
    <div>
      <SelectDropdown
        options={options}
        onOptionSelect={handleOptionSelect}
        InputElement={
          <Button rightIcon={infoIcon} iconSize="L" iconOnly={true} />
        }
        gtmIdGenerator={option => `option-info-${option.toLowerCase()}`}
        displayTextParser={option => textParser(t, option)}
        customStyles={{
          borderRadius: '8px',
          padding: '8px',
          liBorderRadius: '8px',
          top: '40px',
          maxHeight: '216px',
          liPadding: '0px',
          liHoveredBackgroundColor: 'transparent',
        }}
      />
    </div>
  );
};

export default memo(InfoDropdown);
