import { ImgHTMLAttributes, MouseEventHandler } from 'react';

import Button from 'components/Button/button';

import { Size } from './size';
import { Theme } from 'interfaces/theme';

import styled from 'styled-components';

type TColor = keyof Theme['colors'];

type Props = {
  size?: keyof typeof Size;
  stroke?: TColor;
  fill?: TColor;
  opacity?: number;
  isPointer?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  borderRadius?: number;
};

const StyledIcon = styled.img<Props>`
  height: ${({ size = 'M' }) => Size[size]};
  width: ${({ size = 'M' }) => Size[size]};
  border-radius: ${({ rounded, borderRadius }) =>
    rounded ? '100%' : `${borderRadius || 0}px`};
`;

const Container = styled.div<Props>`
  display: flex;
  cursor: ${({ disabled = false, isPointer = true }) =>
    disabled ? 'not-allowed' : isPointer ? 'pointer' : 'auto'};
  opacity: ${({ opacity = 1 }) => opacity};
`;

const EmptyCircle = styled.div<Props>`
  height: ${({ size = 'M' }) => Size[size]};
  width: ${({ size = 'M' }) => Size[size]};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.shadesBackground900};
`;

export type IconProps = {
  isEmpty?: boolean;
  avoidPadding?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
} & Props &
  Omit<ImgHTMLAttributes<HTMLImageElement>, 'onClick'>;

const DataGtmIdWrapper = ({ gtmId, children }) =>
  gtmId ? (
    <Button iconOnly avoidPadding data-gtmid={gtmId}>
      {children}
    </Button>
  ) : (
    children
  );

export const Icon = ({
  isEmpty,
  onClick,
  disabled,
  isPointer,
  ...props
}: IconProps) => {
  if (isEmpty) return <EmptyCircle {...props} />;

  return (
    <Container onClick={onClick} disabled={disabled} isPointer={isPointer}>
      <DataGtmIdWrapper gtmId={props['data-gtmid']}>
        <StyledIcon {...props} />
      </DataGtmIdWrapper>
    </Container>
  );
};

export default Icon;
