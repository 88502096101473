import { TableBody } from '@material-ui/core';

import EmptyStateOverlay, { EmptyOverlayProps } from '.';
import styled from 'styled-components';

const StyledEmptyTableBody = styled(TableBody)`
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  .shimmer {
    height: calc(100% - 10px);
    width: 100%;
    background: ${({ theme }) =>
      `linear-gradient(
      -45deg,
      ${theme.colors.shadesBackground500}00 40%,
      ${theme.colors.shadesBackground700} 50%,
      ${theme.colors.shadesBackground500}00 60%
    )`};

    position: absolute;
    top: 10px;
    background-size: 300%;
    background-position-x: 100%;
    z-index: 10;

    animation: shimmer 1s infinite linear;

    @keyframes shimmer {
      to {
        background-position-x: 0%;
      }
    }
  }
`;

const EMPTY_STATE_ROW_COUNT = 9;

type Props = {
  children?: React.ReactNode;
  isLoading?: boolean;
  RowComponent: React.ReactNode;
  rowCount?: number;
} & EmptyOverlayProps;

const EmptyTableBody = ({
  children,
  isLoading = false,
  RowComponent,
  rowCount = EMPTY_STATE_ROW_COUNT,
  ...rest
}: Props) => {
  return (
    <StyledEmptyTableBody>
      {new Array(rowCount).fill(0).map(el => RowComponent)}
      {children}
      {isLoading ? <div className="shimmer" /> : null}
      {!isLoading ? <EmptyStateOverlay {...rest} /> : null}
    </StyledEmptyTableBody>
  );
};
export default EmptyTableBody;
