import { Slider } from '@material-ui/core';

import styled, { css } from 'styled-components';

type Props = { hideHoverDisplay?: boolean; hideBarMarkers?: boolean };

const SliderStyled = styled(Slider)<Props>`
  width: calc(100% - 8px);

  & .MuiSlider-rail {
    opacity: 0.5;
  }

  & .MuiSlider-thumb {
    border: 1px solid ${({ theme }) => theme.colors.shadesForeground300};
    background: 1px solid ${({ theme }) => theme.colors.shadesForeground400};
  }

  & .muislider-mark {
    &.MuiSlider-markActive {
      opacity: 1;
    }
  }

  .MuiSlider-valueLabel {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px
      ${({ theme }) => theme.colors.shadesForeground300}34;
  }

  .MuiSlider-track {
    background: ${({ theme }) => theme.colors.shadesForeground300}34;
  }

  ${({ hideHoverDisplay }) =>
    hideHoverDisplay &&
    css`
      .MuiSlider-valueLabel {
        display: none;
      }
    `}

  ${({ hideBarMarkers }) =>
    hideBarMarkers &&
    css`
      .MuiSlider-markLabel {
        display: none;
      }
    `}
`;

export const SliderContainer = styled.div`
  position: relative;
  height: 39px;
  align-self: stretch;

  .labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

export default SliderStyled;
