import { FeeAmount } from '@uniswap/v3-sdk';

export enum UniswapSwapTokens {
  USDT = 'USDT',
  USDB = 'USDB',
  ETH = 'ETH',
  USDC = 'USDC',
  WETH = 'WETH',
  WBTC = 'WBTC',
  RBX = 'RBX',
  DAI = 'DAI',
}

export const UniswapTokensPoolFees = {
  [UniswapSwapTokens.USDT]: FeeAmount.MEDIUM,
  [UniswapSwapTokens.USDB]: FeeAmount.MEDIUM,
  [UniswapSwapTokens.ETH]: FeeAmount.LOW,
  [UniswapSwapTokens.USDC]: FeeAmount.LOWEST,
  [UniswapSwapTokens.WETH]: FeeAmount.LOW,
  [UniswapSwapTokens.WBTC]: FeeAmount.MEDIUM,
};
