import { Icon } from 'components';
import Text from 'components/Text';

import { InputContainer } from './styles';
import chevronDownIcon from 'assets/icons/chevron-down-gray.svg';
import chevronUpIcon from 'assets/icons/chevron-up-gray.svg';
import { Column } from 'theme/globalStyledComponents/column';

export type FilterInputProps = {
  isActive: boolean;
  icon: string;
  label: string;
  value: string | JSX.Element;
  leftValueIcon?: string;
  valueClass?: string;
  onClick?: () => void;
};

const FilterInput = ({
  isActive,
  icon,
  label,
  leftValueIcon,
  value,
  valueClass,
  onClick,
}: FilterInputProps) => {
  return (
    <InputContainer className={isActive ? 'active' : ''} onClick={onClick}>
      <img src={icon} alt="icon" className="component-icon" />

      <Column className="gap-5">
        <Text color="shadesForeground200" variant="BODY_S" flexed gap={2}>
          {label}
          <Icon
            src={isActive ? chevronUpIcon : chevronDownIcon}
            alt={isActive ? 'up' : 'down'}
            size="S"
          />
        </Text>

        {typeof value === 'string' ? (
          <Text variant="BODY_S" flexed>
            {leftValueIcon ? (
              <Icon
                size="S"
                className={`value-icon ${valueClass}`}
                src={leftValueIcon}
                alt={value}
              />
            ) : null}
            {value}
          </Text>
        ) : (
          value
        )}
      </Column>
    </InputContainer>
  );
};

export default FilterInput;
