/**
 * Formats a string by adding an ellipsis in the middle if it exceeds a certain length.
 * If the input string is null or undefined, it returns a dash ("-").
 * @param str - The input string to be formatted.
 * @param padLength - The length at which the ellipsis should be added. Default is 5.
 * @returns The formatted string.
 */
export const textWithEllipsisInTheMiddle = (
  str: string | undefined | null,
  padLength = 4,
): string => {
  if (!str) return '-';
  if (str.length <= padLength * 2) return str;

  return `${str.substring(0, padLength)}...${str.substring(
    str.length - padLength,
    str.length,
  )}`;
};

/**
 * Capitalizes the first letter of a string.
 * If the input string is empty, it returns an empty string.
 * @param text - The input string to be capitalized.
 * @returns The capitalized string.
 */
export const capitalizeFirstLetter = (text: string) => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Removes all whitespaces from a string.
 * @param input - The input string from which whitespaces should be removed.
 * @returns The string without whitespaces.
 */
export const removeWhitespaces = (input: string): string => {
  return input.replace(/\s+/g, '');
};

/**
 * Creates a slug from the given input string.
 *
 * @param input - The input string to create a slug from.
 * @returns The slug created from the input string.
 */
export const createSlug = (input: string): string => {
  return (
    input
      // Replace spaces and invalid characters
      .replace(/[\s\W-]+/g, '-')
      // Remove duplicate hyphens
      .replace(/-+/g, '-')
      // Trim hyphens from start and end
      .replace(/^-+|-+$/g, '')
  );
};

/**
 * Checks if the given input is a valid slug.
 *
 * @param input - The input string to check.
 * @returns True if the input is a valid slug, false otherwise.
 */
export const isSlug = (input: string): boolean => {
  const slugRegex = /^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/g;
  return slugRegex.test(input);
};
