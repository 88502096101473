import { memo } from 'react';

import { usePortfolioAPI } from 'hooks/usePortfolioAPI';

import { FormattedNumber, Icon } from 'components';
import Loading from 'components/Loading';
import RbxAreaChart from 'components/RbxAreaChart';
import Text from 'components/Text';

import {
  getChangeDirection,
  getChangePercentage,
  getChangeValue,
} from '../PortfolioChart';
import { Container } from './styles';
import arrowDown from 'assets/icons/arrow-down-new.svg';
import arrowUp from 'assets/icons/arrow-up-new.svg';
import { useAppContext } from 'contexts/AppContext';
import { Column } from 'theme/globalStyledComponents/column';
import { Row } from 'theme/globalStyledComponents/row';

import { QueryKeys, TimescaleType } from 'enums';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

type PortfolioValueProps = {
  cumUnrealizedPnl: number;
  balance: number;
};

const timescale = TimescaleType.Day;

const PortfolioValue = ({ balance }: PortfolioValueProps) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { fetchPortfolioStats } = usePortfolioAPI();
  const { t } = useTranslation();

  const { isLoading: isLoadingPortfolioChart, data: portfolioChart } = useQuery(
    [QueryKeys.Portfolio, timescale],
    async () => await fetchPortfolioStats(timescale),
    { enabled: !!frontendSecrets?.jwt, refetchInterval: 10_000 },
  );

  const isValueChangePositive =
    getChangeDirection(portfolioChart) === 'positive';
  const changeValue = getChangeValue(portfolioChart);
  const changePercentage = getChangePercentage(portfolioChart);

  return (
    <Container>
      <Text variant="BODY_L" color="shadesForeground200">
        {t('portfolioValue')}
      </Text>

      <Column gap={5}>
        <FormattedNumber
          variant="NUMBERS_XL"
          fontWeight="semiBold"
          value={balance}
          prefix="$"
          className="balance"
          negativeToZero
        />
        {isLoadingPortfolioChart ? (
          <Loading size={20} />
        ) : (
          <Row gap={2} className="price-change">
            <Icon
              size="S"
              src={isValueChangePositive ? arrowUp : arrowDown}
              alt="Arrow price change"
            />
            <FormattedNumber
              value={changeValue}
              allowNegative={false}
              prefix="$"
              color={
                isValueChangePositive
                  ? 'primaryGreenForeground100'
                  : 'negativeForeground200'
              }
              variant="BODY_M"
            />{' '}
            <FormattedNumber
              prefix={isValueChangePositive ? '(+' : '('}
              value={changePercentage}
              isPercentage={true}
              allowNegative={false}
              color={
                isValueChangePositive
                  ? 'primaryGreenForeground100'
                  : 'negativeForeground200'
              }
              variant="BODY_M"
              suffix="%)"
            />
          </Row>
        )}
      </Column>

      <div className="chart">
        {isLoadingPortfolioChart ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <RbxAreaChart
            data={portfolioChart}
            isPositiveChange={isValueChangePositive}
            strokeWidth={3}
            minHeight={75}
            containsDates={true}
          />
        )}
      </div>
    </Container>
  );
};

export default memo(PortfolioValue);
