export enum Markets {
  BTC_USD = 'BTC-USD',
  ETH_USD = 'ETH-USD',
  SOL_USD = 'SOL-USD',
  LUNA_USD = 'LUNA-USD',
  ARB_USD = 'ARB-USD',
  MATIC_USD = 'MATIC-USD',
  TEST_MARKET = 'TEST-MARKET',
  DOGE_USD = 'DOGE-USD',
  LDO_USD = 'LDO-USD',
  SUI_USD = 'SUI-USD',
  PEPE1000_USD = 'PEPE1000-USD',
  BCH_USD = 'BCH-USD',
  XRP_USD = 'XRP-USD',
  WLD_USD = 'WLD-USD',
  TON_USD = 'TON-USD',
  STX_USD = 'STX-USD',
  TRB_USD = 'TRB-USD',
  APT_USD = 'APT-USD',
  INJ_USD = 'INJ-USD',
  AAVE_USD = 'AAVE-USD',
  LINK_USD = 'LINK-USD',
  BNB_USD = 'BNB-USD',
  RNDR_USD = 'RNDR-USD',
  MKR_USD = 'MKR-USD',
  RLB_USD = 'RLB-USD',
  ORDI_USD = 'ORDI-USD',
  STG_USD = 'STG-USD',
  SATS1000000_USD = 'SATS1000000-USD',
  TIA_USD = 'TIA-USD',
  BLUR_USD = 'BLUR-USD',
  JTO_USD = 'JTO-USD',
  MEME_USD = 'MEME-USD',
  SEI_USD = 'SEI-USD',
  FIL_USD = 'FIL-USD',
  OP_USD = 'OP-USD',
  AVAX_USD = 'AVAX-USD',
  YES_USD = 'YES-USD',
  WIF_USD = 'WIF-USD',
  STRK_USD = 'STRK-USD',
  SHIB1000_USD = 'SHIB1000-USD',
  BOME_USD = 'BOME-USD',
  SLERF_USD = 'SLERF-USD',
  W_USD = 'W-USD',
  ENA_USD = 'ENA-USD',
  PAC_USD = 'PAC-USD',
  MAGA_USD = 'MAGA-USD',
  TRUMP_USD = 'TRUMP-USD',
  MOG1000_USD = 'MOG1000-USD',
  NOT_USD = 'NOT-USD',
  MOTHER_USD = 'MOTHER-USD',
  BONK1000_USD = 'BONK1000-USD',
  TAIKO_USD = 'TAIKO-USD',
  FLOKI1000_USD = 'FLOKI1000-USD',
  BLAST_USD = 'BLAST-USD',
  PENDLE_USD = 'PENDLE-USD',
  JUP_USD = 'JUP-USD',
  FTM_USD = 'FTM-USD',
}
