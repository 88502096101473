import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  padding-top: 20vh;
  text-align: center;

  span.subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors.warningForeground100};
  }

  h1.title {
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 1px;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.warningForeground100};
  }

  p.text {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors.background};
    margin: 24px 0 52px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 12px;

  button {
    width: 100%;
  }

  .arrow-back-icon {
    margin-right: 14px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    flex-direction: row;
    button {
      width: initial;
    }
  }
`;

const SomethingWentWrong = () => {
  const goHome = () => {
    window.location.reload();
  };

  return (
    <Container>
      <h1 className="title">Sorry, something went wrong</h1>
      <p className="text">An unexpected error has occurred.</p>
      <ButtonGroup>
        <button onClick={goHome}>Take me home</button>
      </ButtonGroup>
    </Container>
  );
};

export default SomethingWentWrong;
