import { memo } from 'react';

import xGrayIcon from '../../../assets/icons/close-x.svg';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { Column } from '../../../theme/globalStyledComponents/column';
import { Row } from '../../../theme/globalStyledComponents/row';
import { saveUserSetting } from '../../../utils';
import Icon from '../../Icon';
import Text from '../../Text';
import rbxAnroidiOSLogo from 'assets/icons/rbxAnroidiOSLogo.svg';
import rbxGetAppLogo from 'assets/icons/rbxGetAppLogo.svg';

import { UserSettings } from 'enums';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import styled, { useTheme } from 'styled-components';

const Container = styled(Column)`
  position: relative;
  border-radius: 20px;
  border: 1px solid rgba(26, 34, 53, 1);
  background: rgba(10, 14, 24, 1);
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4) inset;
  font-family: ${({ theme }) => theme.fonts.kabeljauwRabbitXText};

  .header {
    z-index: 2;
    position: absolute;
    top: -38px;
    justify-content: space-between;
    right: 0;

    .icon-bg-32 {
      height: 32px;
      width: 32px;
      background: ${({ theme }) => theme.colors.shadesBackground800};
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        background: ${({ theme }) => theme.colors.shadesBackground700};
      }
    }
  }

  .content {
    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      gap: 15px;
    }
  }

  .logos {
    padding: 20px;
    background: linear-gradient(
      90deg,
      rgba(65, 25, 28, 0.6) 0%,
      rgba(10, 14, 24, 0.6) 104.17%
    );
    border-radius: 20px;
  }

  .rbxGetAppLogo {
    border-radius: 12px;
    gap: 10px;
  }

  .rbxAnroidiOSLogo {
    position: absolute;
    top: 91px;
    left: 61px;
    border-radius: 100px;
    border: 3px;
    gap: 5px;
  }

  .info {
    width: 200px;
    gap: 10px;
    padding-right: 15px;

    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      width: 254px;
      padding-right: 0;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: center;
  }

  .desc {
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      font-size: 16px;
    }
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;

    background-color: #696f7b;
    background-image: linear-gradient(45deg, #696f7b, #c0cbe1);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  .learnMore {
    color: ${({ theme }) => theme.colors.positiveForeground200};
    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      color: ${({ theme }) => theme.colors.negativeForeground200};
    }
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
  }

  .qr {
    padding: 0px 20px 0px 20px;
    gap: 10px;
  }

  .qrcode {
    width: 85px;
    height: 85px;
    display: flex;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 12px;
    padding: 4px;
  }
`;

type Props = {
  onClosePress?: () => void;
};

const GetTheAppToast = ({ onClosePress }: Props) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = width < (theme as any).screenSizes.xl;

  const dowloadLink = 'https://rabbitx.io/download';
  const appLink = 'https://rabbitx.onelink.me/fiiu/web2app?af_qr=true';

  const onCloseModal = () => {
    saveUserSetting(UserSettings.DONT_SHOW_GET_THE_APP_POPUP_AGAIN, true);
    onClosePress?.();
  };

  return (
    <Container>
      <Row className="header">
        <div className="icon-bg-32 cursor-pointer" onClick={onCloseModal}>
          <Icon src={xGrayIcon} onClick={onCloseModal} />
        </div>
      </Row>

      <Row
        className="content"
        onClick={() => {
          if (isMobile) window.open(dowloadLink, '_blank');
        }}
      >
        <Column className="logos">
          <img className="rbxGetAppLogo" src={rbxGetAppLogo} />
          <img className="rbxAnroidiOSLogo" src={rbxAnroidiOSLogo} />
        </Column>

        <Column className="info">
          <Text className="title">{t('getTheAppTitle')}</Text>

          <Text className="desc">{t('getTheAppDescription')}</Text>

          <Text
            className="learnMore cursor-pointer"
            onClick={() => {
              window.open(dowloadLink, '_blank');
            }}
          >
            {!isMobile ? t('getTheAppLearnMore') : t('getTheAppDowloadApp')}
          </Text>
        </Column>
        {!isMobile ? (
          <Column className="qr">
            <QRCode className="qrcode" value={appLink} />
          </Column>
        ) : null}
      </Row>
    </Container>
  );
};

export default memo(GetTheAppToast);
