import { useEffect } from 'react';

export const useOnClickOutside = (
  ref: React.RefObject<HTMLDivElement> | undefined,
  handler: () => void,
  id?: string | undefined,
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside({ target }: MouseEvent) {
      if (ref?.current && !ref.current.contains(target as Node)) {
        handler();
      }

      if (id) {
        const element = document.getElementById(id);
        if (element && !element.contains(target as Node)) {
          handler();
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
};
