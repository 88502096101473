import { isBrandBfx, isBrandRabbitX } from 'utils/brand';

import Text from 'components/Text';

import EngageAndEarnOptionBox from '../../BfxAirdrop/LockOptions/components/LockOptionBox/EngageAndEarnOptionBox';
import LockAndEarnOptionBox from '../../BfxAirdrop/LockOptions/components/LockOptionBox/LockAndEarnOptionBox';
import LpAndEarnOptionBox from '../../BfxAirdrop/LockOptions/components/LockOptionBox/LpAndEarnOptionBox';
import RBXAirdropClaimOptionBox from '../../BfxAirdrop/LockOptions/components/LockOptionBox/RBXAirdropClaimOptionBox';
import ShareAndEarnOptionBox from '../../BfxAirdrop/LockOptions/components/LockOptionBox/ShareAndEarnOptionBox';
import { Column } from 'theme/globalStyledComponents';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
    ${isBrandRabbitX
      ? css`
          flex-direction: row;
        `
      : css`
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));

          @media (min-width: ${isBrandBfx ? 1500 : 1100}px) {
            display: flex;
            flex-direction: row;
          }
        `}
  }
`;

const OngoingCampaigns = ({ yourDeposits }: { yourDeposits?: number }) => {
  return (
    <>
      <Column gap={5} className="mb-10">
        <Text variant="HEADING_H2" fontWeight="semiBold">
          Ongoing Campaigns
        </Text>
        <Text variant="BODY_M" color="shadesForeground200">
          Start earning yourself some BFX Points
        </Text>
      </Column>

      <Container>
        <LockAndEarnOptionBox />
        <LpAndEarnOptionBox />

        {isBrandRabbitX ? <RBXAirdropClaimOptionBox /> : null}
        {isBrandBfx ? (
          <>
            <EngageAndEarnOptionBox yourDeposits={yourDeposits} />
            <ShareAndEarnOptionBox />
          </>
        ) : null}
      </Container>
    </>
  );
};

export default OngoingCampaigns;
