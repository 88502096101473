import { useEffect, useState } from 'react';

import { TableBody, TableContainer } from '@material-ui/core';

import {
  TSavedVaultActivity,
  VAULT_ACTIVITY_UPDATE_EVENT,
  getVaultActivitiesForTokenPair,
  readVaultTxsFromLocalStorage,
} from 'utils/vaultActivity';

import { useActiveWeb3React } from 'hooks';
import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import Text from 'components/Text';

import { StyledTableContainer, StyledDarkTable } from '../../styles';
import ActivityRow from './ActivityRow';
import EmptyActivityTableState from './EmptyState';
import TableHead from './TableHead';
import WithdrawalRows from './WithdrawalRows';
import { useAppContext } from 'contexts/AppContext';
import { VaultManager } from 'enums/vaultManager';
import { ColoredText, Colors } from 'pages/Trade/components/shared';
import { getCurrentPps } from 'pages/Vaults/utils';
import { useFetchElixirVaultsData } from 'queryHooks/elixirVaults';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export type VisibilityProps = {
  hideActions?: boolean;
  hideManagerColumn?: boolean;
};

const getPPSForTokenPair = (
  vaults: VaultWithContractInfo[],
  tokenPair: string,
) => {
  const vault = vaults.find(vault => vault.tokenPair === tokenPair);
  return getCurrentPps(vault as VaultWithContractInfo);
};

type Props = VisibilityProps & {
  tokenPair?: string;
  isLoadingVaults: boolean;
};
const ActivityTable = ({
  hideActions = false,
  isLoadingVaults,
  hideManagerColumn = false,
  tokenPair,
}: Props) => {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const {
    data: elixirVaultsData,
    isLoading: isLoadingElixirVaultsData,
    isError: isErrorElixirVaultsData,
  } = useFetchElixirVaultsData();

  const [activities, setActivities] = useState<TSavedVaultActivity[]>([]);

  // Keep State Update with data in local storage
  useEffect(() => {
    if (!account) return;

    const updateActivities = () => {
      setActivities(
        tokenPair
          ? getVaultActivitiesForTokenPair(account, tokenPair)
          : readVaultTxsFromLocalStorage(account),
      );
    };

    updateActivities();

    document.addEventListener(VAULT_ACTIVITY_UPDATE_EVENT, updateActivities);

    return () =>
      document.removeEventListener(
        VAULT_ACTIVITY_UPDATE_EVENT,
        updateActivities,
      );
  }, [tokenPair, account]);

  if (!account || !frontendSecrets?.profile.wallet) return null;

  const vaults = elixirVaultsData?.vaults;

  const isEmpty = !vaults || activities.length === 0 || vaults?.length === 0;

  return (
    <TableContainer>
      <StyledTableContainer>
        <Text
          fontWeight="semiBold"
          className="header-span"
          variant="HEADING_H2"
        >
          {t('recentActivity')}
        </Text>

        {isErrorElixirVaultsData && (
          <ColoredText color={Colors.Danger}>
            {t('errorFetchingVaultsPleaseRefresh')}
          </ColoredText>
        )}

        {!isErrorElixirVaultsData && (
          <StyledDarkTable stickyHeader>
            <TableHead
              hideActions={hideActions}
              hideManagerColumn={hideManagerColumn}
            />

            {isLoadingElixirVaultsData || isEmpty ? (
              <EmptyActivityTableState
                hideActions={hideActions}
                hideManagerColumn={hideManagerColumn}
                isLoading={isLoadingElixirVaultsData}
              />
            ) : (
              <TableBody>
                <WithdrawalRows
                  isLoadingVaults={isLoadingVaults}
                  vaults={vaults}
                  vaultManager={VaultManager.ELIXIR} // Replace later when we have more managers
                />
                {activities.map(activity => {
                  return (
                    <ActivityRow
                      currentPPS={getPPSForTokenPair(
                        vaults as VaultWithContractInfo[],
                        activity.tokenPair,
                      )}
                      activity={activity}
                      hideActions={hideActions}
                      hideManagerColumn={hideManagerColumn}
                    />
                  );
                })}
              </TableBody>
            )}
          </StyledDarkTable>
        )}
      </StyledTableContainer>
    </TableContainer>
  );
};

export default observer(ActivityTable);
