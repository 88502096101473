import { useCallback, useEffect, useRef } from 'react';

import { isNumberInRange } from 'utils/math';

const DRAG_REGISTER_OFFSET = 1;

const useDragToScroll = (
  ref: React.RefObject<HTMLElement>,
  defaultOffset: number = 0,
  scrollSpeed: number = 1,
) => {
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  useEffect(() => {
    const container = ref.current;

    if (!container) return;

    if (container.scrollWidth <= container.clientWidth) return;

    const children = Array.from(container.children) as HTMLElement[];

    const handleMouseDown = (e: MouseEvent) => {
      isDragging.current = true;
      startX.current = e.pageX - (container.offsetLeft || 0);
      scrollLeft.current = container.scrollLeft || 0;
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging.current) return;
      const x = e.pageX - (container.offsetLeft || 0);
      const walk = (x - startX.current) * scrollSpeed;
      if (container && Math.abs(walk) > DRAG_REGISTER_OFFSET) {
        children.forEach(child => (child.style.pointerEvents = 'none'));
        container.scrollLeft = scrollLeft.current - walk;
        container.style.cursor = 'grabbing';
        container.style.userSelect = 'none';
      }
    };

    const handleMouseUp = () => {
      children.forEach(child => (child.style.pointerEvents = 'auto'));
      isDragging.current = false;
      container.style.cursor = 'default';
      container.style.userSelect = 'auto';
    };

    const handleMouseLeave = () => {
      children.forEach(child => (child.style.pointerEvents = 'auto'));
      isDragging.current = false;
      container.style.cursor = 'default';
      container.style.userSelect = 'auto';
    };

    if (container) {
      container.addEventListener('mousedown', handleMouseDown);
      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('mouseleave', handleMouseLeave);
      window.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (container) {
        container.removeEventListener('mousedown', handleMouseDown);
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('mouseleave', handleMouseLeave);
      }
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [ref, scrollSpeed]);

  // If direction is not passed, Element centers on both axis
  const centerElement = useCallback(
    (index: number, direction?: 'horizontal' | 'vertical') => {
      if (ref.current && index >= 0) {
        const elements = ref.current.children;
        if (index < elements.length) {
          const containerRect = ref.current.getBoundingClientRect();
          const targetRect = elements[index].getBoundingClientRect();
          let offsetLeft = 0;
          let offsetTop = 0;

          if (!direction || direction === 'horizontal') {
            offsetLeft +=
              targetRect.left -
              containerRect.left -
              (containerRect.width - targetRect.width) / 2;
          }

          if (!direction || direction === 'vertical') {
            offsetTop +=
              targetRect.top -
              containerRect.top -
              (containerRect.height - targetRect.height) / 2;
          }

          ref.current.scrollBy({
            left: offsetLeft,
            top: offsetTop,
            behavior: 'smooth',
          });

          return offsetLeft;
        }
      }
    },
    [ref],
  );

  // Scrolls such that the element is at the left edge post scroll
  const scrollToIndex = useCallback(
    (index: number, offset = defaultOffset) => {
      const container = ref.current;

      if (!container || index < 0 || index > container.children.length - 1)
        return;

      let components = Array.from(container.children);
      container.scrollBy({
        left:
          components[index].getBoundingClientRect().left -
          (container.getBoundingClientRect().left + offset),
        behavior: 'smooth',
      });
    },
    [defaultOffset, ref],
  );

  // Scrolls to next or prev index
  const scrollBy = useCallback(
    (direction: -1 | 1, offset = defaultOffset) => {
      const container = ref.current;
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      let components = Array.from(container.children);
      if (direction < 0) components = components.reverse();

      for (let i = 0; i <= components.length - 1; i++) {
        const component = components[i] as HTMLElement;
        let componentOffset =
          component.getBoundingClientRect().left -
          (containerRect.left + offset);

        const OFFSET_WHERE_ELEMENT_IS_CONSIDERED_OFF_FOCUS = 2;

        // Round Down to zero if in range [-OFFSET_WHERE_ELEMENT_IS_CONSIDERED_OFF_FOCUS, OFFSET_WHERE_ELEMENT_IS_CONSIDERED_OFF_FOCUS]
        if (
          isNumberInRange(componentOffset, [
            -OFFSET_WHERE_ELEMENT_IS_CONSIDERED_OFF_FOCUS,
            OFFSET_WHERE_ELEMENT_IS_CONSIDERED_OFF_FOCUS,
          ])
        ) {
          componentOffset = 0;
        }

        if (
          direction < 0 &&
          componentOffset <= OFFSET_WHERE_ELEMENT_IS_CONSIDERED_OFF_FOCUS
        ) {
          scrollToIndex(
            components.length - 1 - (componentOffset === 0 ? i + 1 : i),
            offset,
          );
          break;
        }

        if (
          direction > 0 &&
          componentOffset >= -OFFSET_WHERE_ELEMENT_IS_CONSIDERED_OFF_FOCUS
        ) {
          scrollToIndex(componentOffset === 0 ? i + 1 : i, offset);
          break;
        }
      }
    },
    [defaultOffset, ref, scrollToIndex],
  );

  return {
    isDragging: isDragging.current,
    centerElement,
    scrollBy,
    scrollToIndex,
  };
};

export default useDragToScroll;
