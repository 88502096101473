import { memo } from 'react';

import CustomTooltip from './CustomTooltip';

import {
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  AreaChart as RechartsAreaChart,
} from 'recharts';

const getChartColor = (isPositiveChange: boolean) => {
  return isPositiveChange ? '#0DCB92' : '#FF475D';
};

type Props<T> = {
  data: T[] | undefined;
  isPositiveChange: boolean;
  showTooltip?: boolean;
  showArea?: boolean;
  strokeWidth?: number;
  minHeight?: number;
  containsDates?: boolean;
  hideXAxis?: boolean;
  hideYAxis?: boolean;
};
const RbxAreaChart = <T,>({
  data,
  isPositiveChange,
  showTooltip = true,
  showArea = true,
  strokeWidth = 1,
  minHeight,
  containsDates,
  hideXAxis = true,
  hideYAxis = true,
}: Props<T>) => {
  const chartColor = getChartColor(isPositiveChange);
  const gradientId = isPositiveChange ? 'success-gradient' : 'danger-gradient';

  if (!data || data.length === 0) {
    return null;
  }

  const getAreaDataKey = () => {
    return containsDates ? 'value' : (v: any) => v;
  };

  const getXAxisDataKey = () => (containsDates ? 'time' : (v: any) => v);

  const getYAxis = () => {
    if (containsDates) {
      return <YAxis hide={hideYAxis} />;
    }

    return (
      <YAxis
        hide
        dataKey={v => v}
        domain={[
          Math.min(...(data as number[])),
          Math.max(...(data as number[])),
        ]}
      />
    );
  };

  return (
    <ResponsiveContainer minHeight={minHeight ?? 'initial'}>
      <RechartsAreaChart data={data}>
        <defs>
          <linearGradient id="success-gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0DCB92" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#0DCB92" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="danger-gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FF475D" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#FF475D" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis hide={hideXAxis} dataKey={getXAxisDataKey()} />
        {getYAxis()}
        <Area
          strokeWidth={strokeWidth}
          type="monotone"
          dataKey={getAreaDataKey()}
          stackId="1"
          stroke={chartColor}
          fill={`${showArea ? `url(#${gradientId})` : 'none'}`}
        />
        {showTooltip ? (
          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{ outline: 'none', zIndex: 5 }}
          />
        ) : null}
      </RechartsAreaChart>
    </ResponsiveContainer>
  );
};

export default memo(RbxAreaChart);
