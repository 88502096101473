import { isBrandRabbitX } from 'utils/brand';

import previewBackgroundBfx from 'assets/images/invite-friends-background-bfx.png';
import previewBackgroundRabbitx from 'assets/images/invite-friends-background-rabbitx.png';

import styled from 'styled-components';

const previewBackground = isBrandRabbitX
  ? previewBackgroundRabbitx
  : previewBackgroundBfx;

export const DownloadPreviewContainer = styled.div`
  box-shadow: -5px 10px 30px 0px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  gap: 10px;
  position: absolute;
  // hide the element by positioning it out of user's view
  /* top: -100vh; */
  /* left: 100vw; */
  top: 0;

  background: url(${previewBackground}) no-repeat;
  background-size: cover; /* Cover the full width and height of the container */
  background-position: center; /* Center the background image */

  width: 400px;
  height: 400px;
  padding: 15px;
  z-index: -10;
  opacity: 0;

  .logo {
    position: absolute;
    top: 15px;
    left: 15px;

    height: 18px;
    z-index: 2;
  }

  .qrcode {
    z-index: 2;
    border-radius: 6px;
    padding: 4px;
    background-color: ${({ theme }) => theme.colors.white};
    width: 66px;
    height: 66px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      height: 44px;
      width: 44px;
    }
  }

  .bonus-text {
    z-index: 2;
    margin: 0;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    white-space: pre-wrap;
    position: relative;
    flex: 1;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.4px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 16px;
      line-height: 15px;
      letter-spacing: -0.32px;
    }
  }

  .bonus-text-s {
    z-index: 2;
    line-height: normal;
    font-size: 12px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 10px;
    }
  }

  .gift-icon {
    z-index: 2;
    transform: rotate(4.39deg);
    position: absolute;
    width: 24px;
    height: 24px;
    left: 50px;
    top: 17px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      left: 38.8px;
      top: 12px;
      width: 18.961px;
      height: 19.138px;
    }
  }
`;

export const Preview = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 12px 12px 0 0;
  background: ${isBrandRabbitX
    ? 'linear-gradient( 255deg, #350209 -0.04%,#a69df3 53.5%, #281d6c 94.28%)'
    : 'linear-gradient(255deg, #4E479D -0.04%, #5881A0 53.87%, #61B7A2 107.79%)'};
  height: 178px;
  position: relative;

  .preview-image {
    z-index: 1;
    position: absolute;
    width: 466px;
    height: 466px;
    bottom: -10px;
  }

  .preview {
    border-radius: 10px 0px 0px 0px;
    box-shadow: -5px 10px 30px 0px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    gap: 10px;
    position: relative;
    width: 384px;
    height: 148px;
    padding: 15px;

    .logo {
      position: absolute;
      top: 10px;
      left: 10px;

      height: 18px;
      z-index: 2;
    }
  }

  .qrcode {
    z-index: 2;
    border-radius: 6px;
    padding: 4px;
    background-color: ${({ theme }) => theme.colors.white};
    width: 66px;
    height: 66px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      height: 44px;
      width: 44px;
    }
  }

  .bonus-text {
    z-index: 2;
    margin: 0;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    white-space: pre-wrap;
    position: relative;
    flex: 1;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: -0.4px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 16px;
      line-height: 15px;
      letter-spacing: -0.32px;
    }
  }

  .bonus-text-s {
    z-index: 2;
    line-height: normal;
    font-size: 12px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 10px;
    }
  }

  .gift-icon {
    z-index: 2;
    transform: rotate(4.39deg);
    position: absolute;
    width: 24px;
    height: 24px;
    left: 50px;
    top: 17px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      left: 38.8px;
      top: 12px;
      width: 18.961px;
      height: 19.138px;
    }
  }
`;

export const ReferralCode = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;

  .text-right {
    text-align: right;

    font-size: 12px;
    line-height: normal;
    white-space: pre-wrap;
    font-weight: ${({ theme }) => theme.fontWeights.medium};

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 10px;
      line-height: 10px;
    }
  }

  .divider {
    height: 30px;
    width: 1.5px;
    border-radius: '100%';
    background: ${({ theme }) => theme.colors.white};
    margin: 0 10px;
  }

  .referral-code {
    font-size: 28px;
    line-height: normal;
    letter-spacing: -0.56px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;
