import { memo } from 'react';

import { makeABitLessthanOrEqual, toFloat } from 'utils';
import { MAX_FLOAT_DECIMALS } from 'utils/math';

import { FormattedNumber } from 'components';
import NumberInput from 'components/Inputs/NumberInput';
import Text from 'components/Text';

import { RowBetween } from '../styles';
import { UnstakeWarning } from './warning';
import arrowRightIcon from 'assets/icons/arrow-right-gray.svg';

import { useTranslation } from 'react-i18next';

// let testVal = toFloat('133.64179501461421220062546288627274485');

// if (typeof testVal === 'string') testVal = 0;

type Props = {
  availableBalance: number;
  amount: number | null;
  onAmountChange: (value: number | null) => void;
  maxRedeemableShares: number;
  disabled: boolean;
};
const StakeInputs = ({
  availableBalance,
  onAmountChange,
  amount,
  maxRedeemableShares,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const balancePostUnstake = availableBalance - (amount ?? 0);

  // Leave some to account for automatic rounding
  const parsedMaxValue = makeABitLessthanOrEqual(
    maxRedeemableShares,
    MAX_FLOAT_DECIMALS,
  );

  return (
    <>
      <NumberInput
        value={amount}
        onChange={onAmountChange}
        label={t('quantity')}
        showSwapCurrency={false}
        showCurrencyIcon={false}
        currency={t('shares')}
        showValueApproximation={false}
        showError={(amount ?? 0) > parsedMaxValue}
        errorMessage={t('notEnoughAvailableShares')}
        maxValue={parsedMaxValue}
        disabled={disabled}
      >
        <RowBetween>
          <span>{t('availableShares')}</span>
          <span className="flex gap-2 items-center">
            <FormattedNumber value={availableBalance} />
            <img src={arrowRightIcon} alt="->" />
            <FormattedNumber
              value={balancePostUnstake > 0 ? balancePostUnstake : 0}
            />
          </span>
        </RowBetween>
      </NumberInput>

      <UnstakeWarning />
    </>
  );
};

export default memo(StakeInputs);
