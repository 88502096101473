import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: calc(100% - 2px);
  z-index: 4;
  left: -10px;
  padding: 10px;

  background: ${({ theme }) => theme.colors.shadesBackground800};
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};
  box-shadow: 2px 2px 40px rgba(23, 23, 27, 0.8);
  border-radius: 12px;
`;

export const Row = styled.div`
  :first-of-type {
    margin-bottom: 12px;
  }
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;
