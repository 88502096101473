import { memo } from 'react';

import { Tab, TabProps } from '@material-ui/core';

import Badge from 'components/Badge';
import Text from 'components/Text';

type TabWithCounterProps = {
  label: string;
  showCounter?: boolean;
  count?: number;
  isActive: boolean;
} & TabProps;

const TabWithCounter = ({
  label,
  showCounter,
  count,
  isActive,
  ...rest
}: TabWithCounterProps) => {
  return (
    <Tab
      label={
        <Text
          flexed
          variant="BODY_M"
          color={isActive ? 'white' : 'shadesForeground200'}
          fontWeight={isActive ? 'semiBold' : 'regular'}
          gap={5}
        >
          {label}
          {showCounter ? (
            <Badge
              padding="3px 6px"
              variant="BODY_S"
              bgColor={isActive ? 'shadesBackground700' : undefined}
            >
              {count}
            </Badge>
          ) : null}
        </Text>
      }
      {...rest}
    />
  );
};

export default memo(TabWithCounter);
