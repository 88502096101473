import { FrontendSecrets } from './signatures';
import { db } from 'IndexedDB/db';
import { API_MAPPINGS } from 'constants/apiMappings';
import { RestService } from 'service/restService';
import AccountStore from 'stores/account';

import { Endpoints, RequestMethod } from 'enums';
import { toJS } from 'mobx';

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

/**
 * @param token JWT token
 * @returns true if jwt token has expired, or false otherwise
 */
export const checkIfJwtExpired = (token: string) => {
  const decoded = parseJwt(token);
  return Date.now() >= decoded.exp * 1000;
};

interface ValidateJWTResult {
  success: boolean;
  error: string;
  result: any;
}

export const checkIfJwtIsValid = async (token: string) => {
  const restService = RestService.get();

  try {
    const res: any = await restService.request<ValidateJWTResult>({
      method: RequestMethod.GET,
      path: `/${Endpoints.ACCOUNT_VALIDATE}?jwt=${token}`,
      body: {},
      headers: {},
      returnRaw: true,
    });

    return res?.success;
  } catch (e: any) {}
  return false;
};

/**
 * A function for centrifuge that refreshes the jwt token and updates the account store
 * and returns the new jwt token or empty string if the user has no permissions
 * @param account wallet address
 * @param accountStore account store instance
 * @returns jwt token or empty string if the user has no permissions
 */
export const refreshCentrifugeJwt = async (
  account: string,
  accountStore: AccountStore,
): Promise<string> => {
  try {
    const oldSecrets = accountStore.frontendSecrets;
    if (!oldSecrets) {
      return '';
    }

    const restService = new RestService();
    const params = {
      isClient: true,
      refreshToken: oldSecrets.refreshToken,
    };
    const { data: newSecrets } = await restService.privateRequest<any>({
      method: RequestMethod.POST,
      endpoint: `/${Endpoints.JWT}`,
      requestParams: params,
      responseMapping: API_MAPPINGS.REFRESH_JWT_RESPONSE,
      paramsMapping: API_MAPPINGS.REFRESH_JWT_PARAMS,
      accountSecrets: oldSecrets as FrontendSecrets,
    });

    const newFrontendSecrets = {
      ...newSecrets,
      wallet: account.toLowerCase(),
      profile: toJS(oldSecrets?.profile),
    };
    accountStore.setFrontendSecrets(newFrontendSecrets as FrontendSecrets);
    await db.updateItem(account, newFrontendSecrets);

    return newSecrets.jwt;
  } catch (e: any) {
    console.error(e);
    return '';
  }
};
