import { bigNumberToFloat } from 'utils';

import { useRabbitContractsAPI, useTokenLockAPI } from 'hooks';

import { RBX_DECIMALS } from 'constants/contracts';
import { useAppContext } from 'contexts/AppContext';
import { getYourLocksAmount } from 'pages/Portfolio/Pages/Airdrops/BfxAirdrop/LockOptions/LockAndEarn';
import {
  LOCK_ALLOCATION,
  LP_ALLOCATION,
  getAllocation,
} from 'pages/Portfolio/Pages/Airdrops/BfxAirdrop/LockOptions/LockAndEarn/utils';

import { QueryKeys } from 'enums';
import { useQuery } from 'react-query';

export function useFetchAccountRbxBalance() {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { areReadContractsSet } = useTokenLockAPI();
  const { getAccountRBTBalance } = useRabbitContractsAPI();

  return useQuery(
    [QueryKeys.AccountRbxBalance, frontendSecrets?.profile.wallet],
    async () => {
      const rbxBalanceBn = await getAccountRBTBalance();
      return bigNumberToFloat(rbxBalanceBn, RBX_DECIMALS);
    },
    {
      refetchInterval: 60_000,
      staleTime: 45_000,
      enabled: !!areReadContractsSet,
    },
  );
}

export function useFetchAccountLpRbxBalance() {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { areReadContractsSet, getAccountLpRbxBalance } = useTokenLockAPI();

  return useQuery(
    [QueryKeys.AccountLpRbxBalance, frontendSecrets?.profile.wallet],
    async () => {
      const lpRbxBalance = await getAccountLpRbxBalance();
      return bigNumberToFloat(lpRbxBalance, RBX_DECIMALS);
    },
    {
      refetchInterval: 60_000,
      staleTime: 45_000,
      enabled: !!areReadContractsSet,
    },
  );
}

export function useFetchTotalLockedRbx() {
  const { getTotalLockedRbx, areReadContractsSet } = useTokenLockAPI();
  return useQuery(
    [QueryKeys.TotalTokensLocked, 'rbx'],
    async () => {
      return await getTotalLockedRbx();
    },
    {
      refetchInterval: 60_000,
      staleTime: 45_000,
      enabled: !!areReadContractsSet,
    },
  );
}

export function useGetBfxAllocation() {
  const { data: totalRbxLockedAmount, isLoading: totalRbxLockedLoading } =
    useFetchTotalLockedRbx();
  const { data: totalLpRbxLockedAmount, isLoading: totalLpRbxLockedLoading } =
    useFetchTotalLockedLpRbx();
  const { data: yourRbxLocked, isLoading: yourRbxLockedLoading } =
    useFetchYourLocksRbx();

  const { data: yourLpRbxLocked, isLoading: yourLPRbxLockedLoading } =
    useFetchYourLocksLpRbx();

  const yourRbxLockedAmount = parseFloat(
    getYourLocksAmount(yourRbxLocked) ?? '',
  );
  const yourLPRbxLockedAmount = parseFloat(
    getYourLocksAmount(yourLpRbxLocked) ?? '',
  );

  const lpAllocation =
    getAllocation(
      yourLPRbxLockedAmount,
      totalLpRbxLockedAmount,
      LP_ALLOCATION,
    ) ?? 0;

  const lockAllocation =
    getAllocation(yourRbxLockedAmount, totalRbxLockedAmount, LOCK_ALLOCATION) ??
    0;

  return {
    isLoading:
      totalRbxLockedLoading ||
      totalLpRbxLockedLoading ||
      yourRbxLockedLoading ||
      yourLPRbxLockedLoading,
    lpAllocation,
    lockAllocation,
    totalAllocation: lockAllocation + lpAllocation,
  };
}

export function useFetchTotalLockedLpRbx(props?: { disableRefetch: boolean }) {
  const { getTotalLockedLpRbx, areReadContractsSet } = useTokenLockAPI();

  return useQuery(
    [QueryKeys.TotalTokensLocked, 'lp'],
    async () => {
      return await getTotalLockedLpRbx();
    },
    {
      refetchInterval: props?.disableRefetch ? false : 60_000,
      staleTime: 45_000,
      enabled: !!areReadContractsSet,
    },
  );
}

export function useFetchYourLocksRbx(props?: { disableRefetch: boolean }) {
  const { getLocksRbx, areReadContractsSet } = useTokenLockAPI();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  return useQuery(
    [QueryKeys.YourTokenLocks, 'rbx', frontendSecrets?.profile.wallet],
    async () => {
      if (!frontendSecrets?.profile.wallet) return;

      return await getLocksRbx(frontendSecrets?.profile.wallet);
    },
    {
      refetchInterval: props?.disableRefetch ? false : 60_000,
      staleTime: 45_000,
      enabled: !!frontendSecrets?.profile.wallet && !!areReadContractsSet,
    },
  );
}

export function useFetchYourLocksLpRbx(props?: { disableRefetch?: boolean }) {
  const { getLocksLpRbx, areReadContractsSet } = useTokenLockAPI();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  return useQuery(
    [QueryKeys.YourTokenLocks, 'lp', frontendSecrets?.profile.wallet],
    async () => {
      if (!frontendSecrets?.profile.wallet) return;

      return await getLocksLpRbx(frontendSecrets?.profile.wallet);
    },
    {
      refetchInterval: props?.disableRefetch ? false : 60_000,
      staleTime: 45_000,
      enabled: !!frontendSecrets?.profile.wallet && !!areReadContractsSet,
    },
  );
}

export function useFetchCutoffTimeRbx() {
  const { getLockCutoffTimeRbx, areReadContractsSet } = useTokenLockAPI();

  return useQuery(
    [QueryKeys.LockCutoffTime, 'rbx'],
    async () => {
      return await getLockCutoffTimeRbx();
    },
    {
      refetchInterval: 60_000,
      staleTime: 45_000,
      enabled: !!areReadContractsSet,
    },
  );
}

export function useFetchCutoffTimeLpRbx() {
  const { getLockCutoffTimeLpRbx, areReadContractsSet } = useTokenLockAPI();

  return useQuery(
    [QueryKeys.LockCutoffTime, 'lp'],
    async () => {
      return await getLockCutoffTimeLpRbx();
    },
    {
      refetchInterval: 60_000,
      staleTime: 45_000,
      enabled: !!areReadContractsSet,
    },
  );
}
