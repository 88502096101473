import { memo } from 'react';

import { brand, isBrandRabbitX } from 'utils/brand';

import Icon from 'components/Icon';

import CountdownTimer from './CountdownTimer';
import { StatusText } from './TransferStatus';
import hourGlassIcon from 'assets/icons/hourglass.svg';

import { useTranslation } from 'react-i18next';

// The delay in seconds that the backend takes to change the status of a withdrawal from pending to claimable
const BACKEND_CONFIRMATION_DELAY = 16;

const getWithdrawalTimesInSecondsPerTier = () => {
  if (isBrandRabbitX) {
    return {
      0: 1 * brand.blockTimeSeconds,
      10_000: 10 * brand.blockTimeSeconds,
      100_000: 100 * brand.blockTimeSeconds,
      1_000_000: 1800 * brand.blockTimeSeconds,
    };
  }
  return {
    0: 1 * brand.blockTimeSeconds,
    10_000: 100 * brand.blockTimeSeconds,
    100_000: 1_000 * brand.blockTimeSeconds,
    1_000_000: 10_800 * brand.blockTimeSeconds,
  };
};

export const getTierTime = (amount: number) => {
  // Get the withdrawal times object for the current brand
  const withdrawalTimesInSecondsPerTier = getWithdrawalTimesInSecondsPerTier();

  // Convert the tier keys into a sorted array of numbers
  const tiers = Object.keys(withdrawalTimesInSecondsPerTier)
    .map(Number)
    .sort((a, b) => a - b);

  // Find the correct tier for the given amount
  let tierTime = tiers[0];
  for (let tier of tiers) {
    if (amount >= tier) {
      tierTime = withdrawalTimesInSecondsPerTier[tier];
    } else {
      break; // Exit the loop once the amount falls below the current tier
    }
  }

  return tierTime;
};

function calculateTimeDifferenceInSeconds(
  timestampMicroseconds: number,
  timestampMilliseconds: number,
) {
  // Convert the microseconds timestamp to milliseconds for uniform comparison
  const timestampInMillisecondsFromMicro = timestampMicroseconds / 1000;

  // Calculate the absolute difference in milliseconds
  const differenceInMilliseconds = Math.abs(
    timestampInMillisecondsFromMicro - timestampMilliseconds,
  );

  // Convert the millisecond difference to seconds
  const differenceInSeconds = differenceInMilliseconds / 1000;

  return differenceInSeconds;
}

const PendingWithdrawal = ({
  amount,
  timestamp,
}: {
  amount: number;
  timestamp: number;
}) => {
  const { t } = useTranslation();

  const timeToWait = getTierTime(amount) + BACKEND_CONFIRMATION_DELAY;

  const timeDiff = calculateTimeDifferenceInSeconds(
    timestamp,
    new Date().getTime(),
  );

  const isTimeToWait = timeDiff < timeToWait;

  return (
    <StatusText>
      <Icon size="S" src={hourGlassIcon} alt="Pending" />
      {t('pending')}{' '}
      {timestamp && isTimeToWait && (
        <CountdownTimer timeInSeconds={Math.abs(timeToWait - timeDiff)} />
      )}
    </StatusText>
  );
};

export default memo(PendingWithdrawal);
