import styled, { css } from 'styled-components';

type Props = {
  isSourceModal?: boolean;
};
export const Container = styled.div<Props>`
  position: relative;
  z-index: 2;
  display: flex;
  padding: 20.522px 10px 6.478px 165px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4) inset;
  background: linear-gradient(90deg, #0c0d0f 0.01%, #121c25 100%);
  position: relative;
  height: 130px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.kabeljauwRabbitXText};

  ${({ isSourceModal }) =>
    isSourceModal &&
    css`
      flex-direction: column;
      align-items: center;
      padding: 20px;
      height: auto;
      border-radius: 12px;
    `}

  .whale-img {
    width: 221.025px;
    height: 221.025px;

    ${({ isSourceModal }) =>
      !isSourceModal &&
      css`
        left: -33.64px;
        bottom: -33.534px;
        position: absolute;
      `}
  }

  .description {
    font-size: 15px;
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    line-height: normal;
    background: linear-gradient(272deg, #696f7b -40.85%, #d1d4da 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${({ isSourceModal }) =>
      isSourceModal &&
      css`
        text-align: center;
      `}
  }

  .content {
    align-items: center;

    ${({ isSourceModal }) =>
      !isSourceModal &&
      css`
        width: 320px;
        align-items: initial;
      `}
  }

  .here {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
    text-decoration-line: underline;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  .close-btn-container {
    border-radius: 100px;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.shadesBackground800};
    border: 1px solid #142431;
    position: absolute;
    top: -42px;
    right: 0;
    cursor: pointer;

    :hover {
      background: ${({ theme }) => theme.colors.shadesBackground600};
    }
  }
`;
