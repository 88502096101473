import styled from 'styled-components';

export const Container = styled.div`
  .top-row {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .bottom-row {
    display: flex;
    align-items: center;
    gap: 5px;

    .input-container {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 12px 15px;
      gap: 10px;
      justify-content: space-between;
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.shadesBackground700};

      input {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.shadesForeground200};
        border: 0;
        background: transparent;
        outline: 0;
        width: 100%;
      }
    }
  }
`;
