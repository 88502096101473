import { TableBody, TableCell, TableHead } from '@material-ui/core';

import useModal from 'hooks/useModal';

import Loading from 'components/Loading';

import NewReferralLinkModal from '../../NewReferralLinkModal';
import TableRow from './TableRow';
import {
  Container,
  TableBanner,
  TableContainerStyled,
  TableRowStyled,
  TableStyled,
} from './styles';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { ReferralLeaderboard } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type Props = {
  isLoading?: boolean;
  isError?: boolean;
  referralLeaderboard?: ReferralLeaderboard[];
  userLeaderboard: ReferralLeaderboard | undefined;
  userShortCode: string | undefined;
  twitterData: Record<string, any>;
};
const LeaderboardTable = ({
  isLoading,
  isError,
  referralLeaderboard,
  userLeaderboard,
  userShortCode,
  twitterData,
}: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const modal = useModal();
  const { t } = useTranslation();

  const onUserShare = (rankText: string) => {
    if (!userShortCode) return;

    /*modal.present(
      <InviteFriendsModal referralCode={userShortCode} rankText={rankText} />,
      Modals.inviteFriendsModal,
    );*/

    modal.present(
      <NewReferralLinkModal referralLinkChangesLeft={3} />,
      Modals.newReferralLinkModal,
    );
  };

  return (
    <Container>
      <div className="outer-table-container">
        <TableContainerStyled>
          <TableStyled>
            <TableHead>
              <TableRowStyled>
                <TableCell>{'Rank'}</TableCell>
                <TableCell>{'User'}</TableCell>
                <TableCell>{'Signups'}</TableCell>
                <TableCell>{'Twitter'}</TableCell>
              </TableRowStyled>
            </TableHead>
            <TableBody>
              {!!frontendSecrets?.jwt && userLeaderboard && (
                <>
                  <TableRow
                    referralLeaderboard={{
                      ...userLeaderboard,
                      wallet: frontendSecrets.profile.wallet,
                    }}
                    isCurrentUser
                    onSharePress={onUserShare}
                    twitterData={twitterData}
                  />
                  <TableRowStyled className="empty-row"></TableRowStyled>
                  <TableRowStyled className="empty-row"></TableRowStyled>
                  <TableRowStyled className="separator-row">
                    <TableCell colSpan={4}></TableCell>
                  </TableRowStyled>
                  <TableRowStyled className="empty-row"></TableRowStyled>
                </>
              )}

              {referralLeaderboard &&
                referralLeaderboard.map((item, i) => (
                  <TableRow
                    key={i}
                    referralLeaderboard={item}
                    twitterData={twitterData}
                  />
                ))}

              {isLoading && (
                <TableRowStyled>
                  <TableCell colSpan={5} align="center">
                    <Loading />
                  </TableCell>
                </TableRowStyled>
              )}

              {referralLeaderboard && referralLeaderboard.length === 0 && (
                <TableRowStyled>
                  <TableCell colSpan={5}>{t('noResults')}</TableCell>
                </TableRowStyled>
              )}

              {isError && (
                <TableRowStyled>
                  <TableCell colSpan={5}>
                    <ColoredText color={Colors.Danger}>
                      {t('errorOccurredTryAgain')}
                    </ColoredText>
                  </TableCell>
                </TableRowStyled>
              )}
            </TableBody>
          </TableStyled>
        </TableContainerStyled>
      </div>
    </Container>
  );
};

export default observer(LeaderboardTable);
