import { showNotificationIfAllChannelsSubscribed } from 'utils';
import { notifications } from 'utils/notifications';

import CentrifugeService from 'service/centrifugeService';
import CentrifugeStore from 'stores/centrifuge';

const handleConnectingEvent = (centrifugeStore: CentrifugeStore) => {
  notifications.dataConnectionLost('public data');
};

const handleConnectedEvent = (
  centrifugeStore: CentrifugeStore,
  centrifugeService: CentrifugeService,
) => {
  // Don't show when the client was not disconnected (the first page load)
  if (centrifugeStore.isPublicDisconnected) {
    // Give some time after the client has reconnected to all public channels to resubscribe
    setTimeout(() => {
      showNotificationIfAllChannelsSubscribed(centrifugeService);
      centrifugeStore.setIsPublicDisconnected(false);
    }, 1000);
  }
};

const handleDisconnectedEvent = (centrifugeStore: CentrifugeStore) => {
  notifications.couldNotReEstablishConnectionToData('public data');
};

export const handlePublicChannelsSubscriptions = (
  centrifugeStore: CentrifugeStore,
) => {
  const centrifugeService = CentrifugeService.getPublic();

  centrifugeService.centrifuge.on('connecting', () => {
    handleConnectingEvent(centrifugeStore);
  });

  centrifugeService.centrifuge.on('connected', () => {
    handleConnectedEvent(centrifugeStore, centrifugeService);
  });

  centrifugeService.centrifuge.on('disconnected', () => {
    handleDisconnectedEvent(centrifugeStore);
  });
};
