import { Icon } from 'components';

import bfxIcon from 'assets/assetLogos/blast-green.svg';
import bannerImage from 'assets/images/bfx-lock-banner.png';

import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 61px;
  background: url(${bannerImage}) lightgray 0px -87px / 100.319% 283.024% no-repeat;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  .icon {
    position: absolute;
    bottom: -22px;
    height: 48px;
    width: 48px;
    border-radius: 100px;
  }
`;

const Header = () => {
  return (
    <Container>
      <Icon src={bfxIcon} className="icon" />
    </Container>
  );
};

export default Header;
