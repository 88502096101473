import { Icon } from 'components';
import Text from 'components/Text';

import { Color } from 'interfaces';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.shadesBackground700};
  margin-top: 10px;

  .data-rows {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;
  }
`;

export type IOrderPrePlaceDetail = {
  label: string;
  value: number | string;
  valueColor?: Color;
  color?: Color;
  labelColor?: Color;
  labelLeftIcon?: string;
};

type Props = {
  children: React.ReactNode;
  data?: IOrderPrePlaceDetail[];
};
const OrderPrePlaceDetails = ({ children, data }: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      {data ? (
        <div className="data-rows">
          {data.map(
            ({ label, value, valueColor, labelLeftIcon, labelColor }) => (
              <Text
                variant="BODY_S"
                color="shadesForeground200"
                flexed
                className="justify-between"
              >
                <Text flexed color={labelColor}>
                  {labelLeftIcon ? <Icon size="S" src={labelLeftIcon} /> : null}
                  {t(label)}
                </Text>

                <Text color={valueColor}>{value}</Text>
              </Text>
            ),
          )}
        </div>
      ) : null}
      {children}
    </Container>
  );
};

export default OrderPrePlaceDetails;
