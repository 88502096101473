import { useState } from 'react';

import { showNotification, timestampToLocalDateWithTime } from 'utils';
import { brand } from 'utils/brand';

import Button from 'components/Button/button';

import downloadIcon from 'assets/icons/download-gray.svg';
import { useAppContext } from 'contexts/AppContext';
import { RequestResponse } from 'service/restService';

import { NotificationType } from 'enums';

type TFetchFn = ({
  queryParams,
}: {
  queryParams: any;
}) => Promise<RequestResponse<any[]>>;

const DOWNLOAD_CAP = 50_000;

const PAGE_LIMIT = 1000;

const getMaxPage = (totalCount: number) =>
  Math.ceil(totalCount / PAGE_LIMIT) - 1;

const generateFetchPromises = (fetchFunction: TFetchFn, totalCount: number) => {
  const promises: Promise<RequestResponse<any[]>>[] = [];

  for (let i = 1; i <= getMaxPage(totalCount); i++) {
    promises.push(
      fetchFunction({ queryParams: { p_page: i, p_limit: PAGE_LIMIT } }),
    );
  }
  return promises;
};

const convertObjArrayToCsvRows = (data: any[], avoidRows: string[]) => {
  return data.map(o => {
    avoidRows.forEach(key => delete o[key]); //  delete key-values we dont want in csv

    o.timestamp &&
      (o.timestamp = `"${timestampToLocalDateWithTime(o.timestamp)}"`); // covert timestamp to local string for better readability

    return Object.values(o).join(',') + '\n';
  });
};

const generateHeaderKeys = (
  data: object,
  avoidRows: string[],
  headTranslations: Record<string, string>,
) => {
  return (
    Object.keys(data)
      .filter(key => !avoidRows.includes(key)) // avoid some rows' key
      .map(key => headTranslations[key]?.toLocaleLowerCase() || key) // toLocaleLowerCase for consistency as keys are default lowercased from BE
      .join(',') + '\n'
  );
};

export const useDownloadCsv = ({
  fetchFunction,
  data,
  fileName = 'DATA',
  avoidRows = [],
  headTranslations,
}: {
  fetchFunction?: TFetchFn;
  data?: any[];
  avoidRows?: string[];
  fileName: string;
  headTranslations: Record<string, string>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    store: { account },
  } = useAppContext();

  const downloadCsv = async () => {
    if (!fetchFunction) return;

    setIsLoading(true);
    let totalCount = 0;
    try {
      const firstPage = await fetchFunction({
        queryParams: { p_page: 0, p_limit: PAGE_LIMIT },
      });

      totalCount = Math.min(firstPage.pagination.total, DOWNLOAD_CAP); // Cap totalCount at DOWNLOAD_CAP

      const responseSecondPageAndCounting = await Promise.all(
        generateFetchPromises(fetchFunction, totalCount),
      );

      const allData = [
        generateHeaderKeys(firstPage.data[0], avoidRows, headTranslations), // Header Row

        ...convertObjArrayToCsvRows(firstPage.data, avoidRows), // First page

        // Rest of the pages
        ...responseSecondPageAndCounting.reduce(
          (acc, response) => [
            ...acc,
            ...convertObjArrayToCsvRows(response.data, avoidRows),
          ],
          [] as any[],
        ),
      ];

      const url = window.URL.createObjectURL(new Blob(allData));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${brand.appShortName}_${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      showNotification({
        title: 'Downloaded CSV',
        type: NotificationType.Positive,
      });
    } catch (e) {
      console.error('ERROR WHILE DOWNLOADING CSV : ' + e);
      showNotification({
        title: 'Failed to download CSV',
        type: NotificationType.Negative,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const convertArrayToCsvAndDownload = () => {
    if (!data) return;
    try {
      const blobData = [
        generateHeaderKeys(data[0], avoidRows, headTranslations), // Header Row

        ...convertObjArrayToCsvRows(data, avoidRows), // Rest of the  pages
      ];

      const url = window.URL.createObjectURL(new Blob(blobData));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${brand.appShortName}_${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      showNotification({
        title: 'Downloaded CSV',
        type: NotificationType.Positive,
      });
    } catch (e) {
      console.error('ERROR WHILE DOWNLOADING CSV : ' + e);
      showNotification({
        title: 'Failed to download CSV',
        type: NotificationType.Negative,
      });
    } finally {
    }
  };

  const isDataEmpty = data !== undefined && data.length === 0;

  const DownloadButton = () => {
    return (
      <Button
        colorVariant="secondary"
        rightIcon={downloadIcon}
        onClick={fetchFunction ? downloadCsv : convertArrayToCsvAndDownload}
        sizeVariant="S"
        disabled={!account.frontendSecrets || isDataEmpty}
        isLoading={isLoading}
      >
        Download CSV
      </Button>
    );
  };

  return DownloadButton;
};
