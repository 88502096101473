import { withStyles } from '@material-ui/core/styles';

import CustomToast from '../components/CustomToast';

import { NotificationType } from 'enums';
import { SnackbarProvider } from 'notistack';

const styles = {
  containerAnchorOriginBottomRight: {},
};

declare module 'notistack' {
  interface VariantOverrides {
    customToast: {
      title: string;
      type: NotificationType;
      description?: string;
    };
  }
}

const SnackbarWithStyles = ({ classes, children }) => (
  <SnackbarProvider
    autoHideDuration={8000}
    maxSnack={2}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    preventDuplicate={true}
    classes={{
      containerAnchorOriginBottomRight:
        classes.containerAnchorOriginBottomRight,
    }}
    Components={{
      customToast: CustomToast,
    }}
  >
    {children}
  </SnackbarProvider>
);

export default withStyles(styles)(SnackbarWithStyles);
