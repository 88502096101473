import { memo } from 'react';

import Banner from 'components/Banner';
import Text from 'components/Text';

import { UserSettings } from 'enums';

const READ_MORE_URL =
  'https://docs.blastfutures.com/get-started/blast-points-and-gold/blast-gold-liquidity-program';

const GoldLiquidityProgramBanner = () => {
  return (
    <Banner
      closeFlagSettingKey={UserSettings.IS_GOLD_POINTS_PROGRAM_BANNER_DISMISSED}
      className="mb-10"
    >
      Announcement: Check out the new Blast Gold Liquidity Program.{' '}
      <Text color="white" underline>
        <a target="_blank" href={READ_MORE_URL} rel="noreferrer">
          Read More
        </a>
      </Text>
    </Banner>
  );
};

export default memo(GoldLiquidityProgramBanner);
