import { useEffect } from 'react';

import Icon from 'components/Icon';
import useCancelAndClaimWithdrawal from 'components/Tables/TransfersTable/CancelAndClaimCell/useCancelAndClaimWithdrawal';
import Text from 'components/Text';

import checkIcon from 'assets/icons/check-circle-green.svg';
import { useAppContext } from 'contexts/AppContext';

import { DepositOrWithdrawalStatus } from 'enums';
import styled from 'styled-components';

type WithdrawMessageProps = {
  opsId: string;
  opsStatus: DepositOrWithdrawalStatus;
};

const TextUnderline = styled(Text)`
  text-decoration: underline;
`;

const ClaimMessage = ({ opsId, opsStatus }: WithdrawMessageProps) => {
  const {
    handleClaimWithdrawal,
    shouldDisableClaimButton,
    claimButtonText,
    isClaiming,
    isPendingTxSign,
  } = useCancelAndClaimWithdrawal({
    opsId,
    opsStatus,
  });

  return (
    <Text variant="BODY_M" flexed gap={20}>
      <Text flexed gap={5}>
        <Icon src={checkIcon} />
        You have a withdraw ready!
      </Text>
      <TextUnderline
        fontWeight="semiBold"
        color={
          shouldDisableClaimButton || isClaiming || isPendingTxSign
            ? 'shadesForeground200'
            : 'positiveForeground200'
        }
        onClick={handleClaimWithdrawal}
        cursorPointer
      >
        {claimButtonText}
      </TextUnderline>
    </Text>
  );
};

export default ClaimMessage;
