import { useEffect, useState } from 'react';

import { isBrandBfx } from 'utils/brand';

import useWindowDimensions from 'hooks/useWindowDimensions';

import ClaimableWithdrawal from 'components/CustomToasts/ClaimableWithdrawal';
import OnboardingSteps from 'components/OnboardingSteps';

import Footer from './Footer';
import Header from './Header';
import MobileSidebar from './MobileSidebar';
import NotificationsSidebar from './NotificationsSidebar';
import warningIcon from 'assets/icons/warning.svg';
import { useAppContext } from 'contexts/AppContext';
import { getPortfolioMenuItems } from 'pages/Portfolio/Sidebar/navItems';
import { routes, tradePathRegex } from 'pages/routes';
import { theme } from 'theme/Theme';

import { config } from 'config';
import { ComponentsName, UserSettings } from 'enums';
import { TFunction } from 'i18next';
import { MenuItem } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface Props {
  children?: any;
}

type ContainerStyledProps = {
  isHeightFixed: boolean;
};
const ContainerStyled = styled.div<ContainerStyledProps>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;

  ${({ isHeightFixed }) =>
    isHeightFixed &&
    css`
      height: 100vh;
      flex: 1;
      overflow-y: hidden;
    `};
`;

const ContentWrapperStyled = styled.div<ContainerStyledProps>`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ isHeightFixed }) =>
    /* Use default bottom padding only on pages without fixed height */
    !isHeightFixed &&
    css`
      padding-bottom: 60px;
    `};
`;

const Announcement = styled.div`
  background: ${({ theme }) => theme.colors.warningBackground400};
  padding: 10px;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  margin: 0px 10px 5px 10px;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  img {
    width: 24px;
    height: 24px;
  }

  > a,
  > span {
    color: ${({ theme }) => theme.colors.secondaryGold300};
    font-size: 14px;
  }
`;

const getMenuItems = (
  t: TFunction<'translation', undefined>,
  additionalMenuItems = [] as MenuItem[],
): MenuItem[] => {
  const menuItems = [
    {
      text: t('menu.trade'),
      to: routes.trade.getRoutePath(),
      componentName: ComponentsName.Trade,
      gtmId: 'tab-trade',
    },
    {
      text: t('menu.portfolio'),
      to: routes.portfolio.getRoutePath(),
      componentName: ComponentsName.PortfolioTrades,
      gtmId: 'tab-portfolio',
      children: getPortfolioMenuItems(t),
    },
    {
      text: t('markets'),
      to: routes.markets.getRoutePath(),
      componentName: ComponentsName.Markets,
      gtmId: 'tab-markets',
    },
    {
      text: t('vaults'),
      to: routes.vaults.getRoutePath(),
      componentName: ComponentsName.Vaults,
      gtmId: 'tab-vaults',
    },
    ...additionalMenuItems,
  ];

  return menuItems;
};

export const menuItemsForDevicesOverMedium = (t?: any) => {
  return (
    isBrandBfx
      ? [
          // Add a referral tab if the brand is BFX

          {
            text: t?.('referrals'),
            to: routes.portfolio.referrals.getRoutePath(),
            componentName: ComponentsName.PortfolioReferrals,
            gtmId: 'tab-referrals',
          },
        ]
      : []
  ) as MenuItem[];
};

const Layout = observer(({ children }: Props) => {
  const {
    store: { appState },
  } = useAppContext();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const additionalMenuItems =
    width > theme.screenSizes.xxl
      ? menuItemsForDevicesOverMedium(t)
      : ([] as MenuItem[]);

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] =
    useState<boolean>(false);
  const menuItems = getMenuItems(t, additionalMenuItems);

  // Height is fixed on /trade as we had to make the a child scrollable with fullheight
  const isHeightFixed = tradePathRegex.test(window.location.pathname);

  const showNewsFooter = !isHeightFixed;

  return (
    <ContainerStyled isHeightFixed={isHeightFixed}>
      <Header
        menuItems={menuItems}
        onOpenMobileSidebar={() => setIsMobileSidebarOpen(true)}
      />
      {isMobileSidebarOpen ? (
        <MobileSidebar
          menuItems={menuItems}
          onCloseMobileSidebar={() => setIsMobileSidebarOpen(false)}
        />
      ) : null}
      {appState.isNotificationsSidebarOpen ? <NotificationsSidebar /> : null}
      {config.announcement.text && config.announcement.text !== "''" && (
        <Announcement>
          <img src={warningIcon} alt="Warning" />
          {config.announcement.link && config.announcement.link !== "''" ? (
            <a href={config.announcement.link} target="_blank" rel="noreferrer">
              {config.announcement.text}
            </a>
          ) : (
            <span>{config.announcement.text}</span>
          )}
          <img src={warningIcon} alt="Warning" />
        </Announcement>
      )}
      {/* Shows Withdrawal Banner if a withdrawal is claimable */}
      <ClaimableWithdrawal />
      <ContentWrapperStyled isHeightFixed>{children}</ContentWrapperStyled>
      {showNewsFooter ? <Footer /> : null}
      {!appState.hasWentThroughGetStarted ? <OnboardingSteps /> : null}
    </ContainerStyled>
  );
});

const HeaderAndMobileSideBarLayout = ({ children }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const additionalMenuItems =
    width > theme.screenSizes.xxl
      ? menuItemsForDevicesOverMedium(t)
      : ([] as MenuItem[]);

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] =
    useState<boolean>(false);
  const menuItems = getMenuItems(t, additionalMenuItems);

  return (
    <ContainerStyled isHeightFixed={true}>
      <Header
        menuItems={menuItems}
        onOpenMobileSidebar={() => setIsMobileSidebarOpen(true)}
      />
      {isMobileSidebarOpen ? (
        <MobileSidebar
          menuItems={menuItems}
          onCloseMobileSidebar={() => setIsMobileSidebarOpen(false)}
        />
      ) : null}
      <ContentWrapperStyled isHeightFixed={false}>
        {children}
      </ContentWrapperStyled>
    </ContainerStyled>
  );
};

export { Layout, HeaderAndMobileSideBarLayout };
