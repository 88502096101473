import { useMatch } from 'react-router-dom';

/**
 * Check if the current page is the trade page
 * @returns - true if the current page is the trade page, false otherwise
 */
export const useIsTradePage = () => {
  const matchTrade = useMatch('/trade/:pair');

  return !!matchTrade;
};
