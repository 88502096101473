import Loading from 'components/Loading';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  padding: 20px;
  width: calc(100vw - 40px);

  box-sizing: border-box;

  @media (min-width: 430px) {
    width: 390px;
  }

  .tabs-container {
    padding: 6px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }

  .verti-align-middle {
    vertical-align: middle;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const RowBetween = styled(Row)`
  align-items: center;
  justify-content: space-between;
`;

export const StyledLoading = styled(Loading)`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WaitingSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  svg,
  span {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;
