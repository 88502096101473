import { forwardRef } from 'react';

import { brand, isBrandRabbitX } from 'utils/brand';

import { DownloadPreviewContainer, ReferralCode } from './styles';
import giftIcon from 'assets/icons/gift.svg';

import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

type Props = {
  referralCode: string;
  linkUrl: string;
};

const DownloadPreview = forwardRef<HTMLDivElement, Props>(
  ({ referralCode, linkUrl }, ref) => {
    const { t } = useTranslation();

    return (
      <DownloadPreviewContainer ref={ref}>
        <img alt="logo" className="logo" src={brand.logo} />

        <QRCode size={30} className="qrcode" value={linkUrl} />

        <span className="bonus-text">
          {/*
              Commented as per gitlab issue 402
              {t('Receive\n100%\nBonus\n')}
              <span className="bonus-text-s">on first deposit</span>
              <img src={giftIcon} alt="gift" className="gift-icon" /> 
            */}
        </span>

        <ReferralCode>
          <span className="text-right">{t('referral\ncode')}</span>
          <div className="divider" />
          <span className="referral-code">{referralCode}</span>
        </ReferralCode>
      </DownloadPreviewContainer>
    );
  },
);

export default DownloadPreview;
