import { useCallback, useRef, useState } from 'react';

import { useOnClickOutside } from 'hooks';
import useKey, { Key } from 'hooks/useKey';

import MarketsDropdown from 'components/MarketsDropdown';
import Search from 'components/Search/Search';

import { Market } from 'interfaces';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type ContainerProps = {
  isBlock: boolean;
};
const Container = styled.div<ContainerProps>`
  position: relative;

  width: ${({ isBlock }) => (isBlock ? 'auto' : '168px')};

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    width: ${({ isBlock }) => (isBlock ? 'auto' : '242px')};
  }
`;

export const DropdownStyled = styled.div`
  position: absolute;
  top: 40px;
  padding-top: 10px;
  margin: 0;
  z-index: 12;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
  width: 320px;

  @media (min-width: 355px) {
    width: 355px;
  }

  @media (min-width: 360px) {
    width: 360px;
  }

  @media (min-width: 800px) {
    width: 349px;
    padding: 6px 16px 16px 16px;
  }

  @media (min-width: 1280px) {
    width: 349px;
    padding: 6px 16px 16px 16px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    width: 386px;
    top: 44px;
    padding: 14px 24px 24px 24px;
  }
`;

type SearchInputProps = {
  onOptionSelect?: (option: Market) => void;
  isBlock?: boolean;
};
const MarketSearch = ({
  onOptionSelect,
  isBlock = false,
}: SearchInputProps) => {
  const { t } = useTranslation();
  const [searchMarketsValue, setSearchMarketsValue] = useState<string>('');
  const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);

  const searchRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    setIsDropdownShown(false);
  }, []);

  useOnClickOutside(searchRef, handleClose);
  useKey(Key.Escape, handleClose);

  const handleSearchChange = (value: string) => {
    setSearchMarketsValue(value);
  };

  const handleSearchClick = () => {
    setIsDropdownShown(true);
  };

  const handleOptionSelect = (option: Market) => {
    setIsDropdownShown(false);
    onOptionSelect && onOptionSelect(option);
  };

  return (
    <Container isBlock={isBlock} ref={searchRef} data-cy="market-search">
      <Search
        placeholder={t('searchMarkets')}
        onChange={handleSearchChange}
        value={searchMarketsValue}
        onClick={handleSearchClick}
      />
      {isDropdownShown ? (
        <DropdownStyled>
          <MarketsDropdown
            onOptionSelect={handleOptionSelect}
            searchMarketsValue={searchMarketsValue}
          />
        </DropdownStyled>
      ) : null}
    </Container>
  );
};

export default MarketSearch;
