import { isBrandRabbitX } from 'utils/brand';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .content {
    display: flex;
    align-items: center;

    gap: 20px;

    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      border-radius: 8px;
      background: ${({ theme }) =>
        isBrandRabbitX
          ? theme.colors.shadesBackground700
          : theme.colors.main200};

      > img {
        width: 24px;
        height: 24px;
      }
    }

    .value-container {
      display: flex;
      flex-direction: column;
      justify-items: center;
      gap: 5px;
    }
  }
`;

export const Divider = styled.div`
  width: 1.5px;
  height: 48px;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  display: none;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    display: block;
  }
`;
