import styled from 'styled-components';

export const Container = styled.div`
  .row {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .col {
      width: calc(50% - 10px);
    }
  }
`;
