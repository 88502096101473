import { memo } from 'react';

import RewardCard from '../../RewardCard';
import { CardStatus, CardVariant } from '../../RewardCard/enums';
import { Container } from './styles';
import { ReferralLevel } from 'interfaces';

type Props = ReferralLevel & {
  isExtended: boolean;
  toggle: (lvl: number) => void;
};

const ClaimedStack = ({
  level,
  volume,
  comissionBonus,
  comissionPercent,
  isExtended,
  toggle,
}: Props) => {
  const openClass = isExtended ? 'open' : '';

  return (
    <Container onClick={() => toggle(level)}>
      <RewardCard
        variant={CardVariant.CLAIMED}
        level={level}
        goal={volume}
        status={CardStatus.CLAIMED}
        containerClass="claimed-card"
      />
      <RewardCard
        variant={CardVariant.PRIZE}
        level={level}
        goal={comissionBonus}
        status={CardStatus.CLAIMED}
        containerClass={`shifted-card prize-card ${openClass}`}
        vectorClass={`adjusted-vector ${openClass}`}
      />
      <RewardCard
        variant={CardVariant.FEE}
        level={level}
        goal={comissionPercent}
        status={CardStatus.CLAIMED}
        containerClass={`shifted-card fee-card ${openClass}`}
        vectorClass={`adjusted-vector ${openClass}`}
      />
    </Container>
  );
};

export default memo(ClaimedStack);
