import { memo, useState } from 'react';

import { getCurrencyInputValue } from 'utils';

import { FormattedNumber } from 'components/FormattedNumber';
import NumberInput from 'components/Inputs/NumberInput';

import {
  Container,
  Error,
  Separator,
  Switcher,
  ValueAndToggle,
} from './styles';
import caretDown from 'assets/icons/chevron-down-gray.svg';
import caretUp from 'assets/icons/chevron-up-gray.svg';
import swapIcon from 'assets/icons/swap-left-right.svg';
import { DecimalScale } from 'constants/marketMappings';

import { OrderType, TradeSide } from 'enums';
import { useTranslation } from 'react-i18next';

export type LimitOrderOptions = 'Market' | 'Limit';

const getCurrentOptionValue = ({
  selectedLimitOrder,
  slippage,
  limitPrice,
  decimalScale,
  t,
}: {
  selectedLimitOrder: LimitOrderOptions;
  slippage: number | null;
  limitPrice: number | null;
  decimalScale: DecimalScale;
  t: any;
}) => {
  if (selectedLimitOrder === 'Market') {
    return slippage !== null ? (
      <FormattedNumber value={slippage} suffix={t('percentSlippage')} />
    ) : (
      '- -'
    );
  }
  return limitPrice !== null ? (
    <FormattedNumber
      value={limitPrice}
      suffix=" USD"
      decimalScale={decimalScale.price}
    />
  ) : (
    '- -'
  );
};

const orderTypeToLabel = (orderType: OrderType, t: any) => {
  return {
    [OrderType.STOP_LOSS]: t('stopLoss'),
    [OrderType.TAKE_PROFIT]: t('takeProfit'),
  }[orderType];
};

const getLimitPriceErrorMessage = ({
  selectedLimitOrder,
  limitPrice,
  tradeSide,
  t,
  triggerPrice,
  orderType,
}: {
  selectedLimitOrder: LimitOrderOptions;
  limitPrice: number | null;
  tradeSide: TradeSide;
  t: any;
  triggerPrice: number | null;
  orderType: OrderType;
}) => {
  if (selectedLimitOrder !== 'Limit') return null;
  if (!triggerPrice) return null;

  if (limitPrice === null || limitPrice <= 0) {
    return t('limitPriceCannotBeEmpty');
  }

  const orderTypeLabel = orderTypeToLabel(orderType, t);

  const limitPriceErrorMsg = t('limit3%AwayFromTriggerPriceError', {
    orderTypeLabel,
  });

  // for short position, disable if the limit price >1.03 * trigger price
  if (tradeSide === TradeSide.SHORT && limitPrice > triggerPrice * 1.03) {
    return limitPriceErrorMsg;
  }

  // for long position, disable if the limit price < 0.97 * trigger price
  if (tradeSide === TradeSide.LONG && limitPrice < triggerPrice * 0.97) {
    return limitPriceErrorMsg;
  }
  return null;
};

type Props = {
  slippage: number | null;
  limitPrice: number | null;
  triggerPrice: number | null;
  setSlippage: (value: number | null) => void;
  setLimitPrice: (value: number | null) => void;
  orderType: OrderType;
  selectedLimitOrder: LimitOrderOptions;
  setSelectedLimitOrder: (option: LimitOrderOptions) => void;
  tradeSide: TradeSide;
  decimalScale: DecimalScale;
};
const LimitPriceAndSlippage = ({
  slippage,
  limitPrice,
  setSlippage,
  setLimitPrice,
  orderType,
  selectedLimitOrder,
  setSelectedLimitOrder,
  tradeSide,
  triggerPrice,
  decimalScale,
}: Props) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleSwapOptionClick = () => {
    setSelectedLimitOrder(selectedLimitOrder === 'Market' ? 'Limit' : 'Market');
  };

  const handleSetOption = (option: LimitOrderOptions) => {
    setSelectedLimitOrder(option);
    setIsExpanded(true);
  };

  const handleSetIsExpanded = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  const currentOptionValue = getCurrentOptionValue({
    selectedLimitOrder,
    slippage,
    limitPrice,
    decimalScale,
    t,
  });

  const limitPriceErrorMsg = getLimitPriceErrorMessage({
    limitPrice,
    tradeSide,
    triggerPrice,
    orderType,
    t,
    selectedLimitOrder,
  });

  return (
    <Container>
      <Separator variant="BODY_S" flexed color="shadesForeground200">
        <div className="seperator" />
        {t('orderType')}
        <div className="seperator" />
      </Separator>
      <div className="header">
        <Switcher>
          <span
            className={selectedLimitOrder === 'Market' ? 'selected' : ''}
            onClick={() => handleSetOption('Market')}
          >
            {t('market')}
          </span>
          <img
            src={swapIcon}
            className="cursor-pointer"
            alt="swap"
            onClick={handleSwapOptionClick}
          />
          <span
            onClick={() => handleSetOption('Limit')}
            className={selectedLimitOrder === 'Limit' ? 'selected' : ''}
          >
            {t('limit')}
          </span>
        </Switcher>
        <ValueAndToggle onClick={handleSetIsExpanded}>
          <span>{currentOptionValue}</span>
          <img
            src={isExpanded ? caretUp : caretDown}
            alt="caret"
            className="cursor-pointer"
          />
        </ValueAndToggle>
      </div>

      {isExpanded && (
        <>
          {selectedLimitOrder === 'Market' && (
            <NumberInput
              className="mt-10"
              value={getCurrencyInputValue(slippage)}
              onChange={setSlippage}
              label={t('maxSlippagePercent')}
              currency="%"
              showSwapCurrency={false}
              showCurrencyIcon={false}
              showValueApproximation={false}
              showPresets={false}
              showError={slippage !== null && (slippage < 0 || slippage > 3)}
              errorMessage={t('maxSlippageMustBeBetween0and3Percent')}
            />
          )}
          {selectedLimitOrder === 'Limit' && (
            <NumberInput
              className="mt-10"
              value={getCurrencyInputValue(limitPrice)}
              onChange={setLimitPrice}
              label={t('limitPrice')}
              currency="USD"
              showPresets={false}
              showSwapCurrency={false}
              showCurrencyIcon={true}
              showValueApproximation={false}
              showError={limitPrice !== null && limitPrice <= 0}
              errorMessage={t('limitPriceMustBeGreaterThan0')}
            />
          )}
        </>
      )}

      {true && <Error className="mt-10">{limitPriceErrorMsg}</Error>}

      {selectedLimitOrder === 'Market' && slippage === null && (
        <Error className="mt-15">{t('marketSlippageSetTo0')}</Error>
      )}
    </Container>
  );
};

export default memo(LimitPriceAndSlippage);
