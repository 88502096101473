import { useEffect, useState } from 'react';

import {
  checkIfJwtExpired,
  parseJwt,
  checkIfJwtIsValid,
  showNotification,
  saveUserSetting,
  readUserSetting,
} from 'utils';
import {
  getTimestampWithOffsetFromLocalStorage,
  OnBoardingParams,
  RefreshJwtParams,
} from 'utils/api';
import { getSrvTimestamp } from 'utils/clock';
import { createTypedDataSignRequestData } from 'utils/typedDataSign';

import { useVerifyChainId } from 'hooks';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';

import { ExchangeApiContext } from '../contexts/ExchangeApiContext';
import {
  ConnectorNames,
  Endpoints,
  NotificationType,
  privateQueryKeysArray,
  RequestMethod,
  WebsocketChannels,
} from '../enums';
import { jsToGoSignature, FrontendSecrets } from '../utils/signatures';
import { db } from 'IndexedDB/db';
import { SIGNATURE_LIFETIME } from 'constants/api';
import { API_MAPPINGS } from 'constants/apiMappings';
import {
  LS_WALLET,
  LS_WALLET_SIGNATURE,
  CAMPAIGN_ANALYTICS,
} from 'constants/localStorageKeys';
import { useAppContext } from 'contexts/AppContext';
import CentrifugeService from 'service/centrifugeService';
import { QueryParams, RequestResponse, RestService } from 'service/restService';

import { config } from 'config';
import { ApiMapping } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

export interface MakePrivateRequestParams {
  method: RequestMethod;
  endpoint: string;
  requestParams: any;
  refreshJwt?: boolean;
  responseMapping?: ApiMapping | undefined;
  paramsMapping?: ApiMapping | undefined;
  isArray?: boolean;
  queryParams?: QueryParams;
  shouldCheckJwtValidity?: boolean;
  shouldSignMetamaskMessage?: boolean;
  signMetamaskMessageNotifDescription?: string;
}

const restService = RestService.get();

export const ExchangeApiProvider = ({ children }) => {
  const { account, library } = useActiveWeb3React();
  const {
    store: {
      account: accountStore,
      savedWallets: { handleAccountActivation },
    },
  } = useAppContext();

  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isReadingSignatureFromLocalDB, setIsReadingSignatureFromLocalDB] =
    useState<boolean>(false);

  const queryClient = useQueryClient();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  // On account change, check if user exists in local db and validate jwt
  useEffect(() => {
    (async () => {
      // Clear all private cached queries
      privateQueryKeysArray.forEach(queryKey => {
        queryClient.removeQueries({
          queryKey,
          exact: false,
        });
      });

      if (!account) {
        return;
      }

      try {
        setIsReadingSignatureFromLocalDB(true);

        const oldWallet = accountStore?.frontendSecrets?.profile?.wallet;

        // Unset secrets and disconnect private connection if any exists
        unsetUserSecretsAndDisconnectPrivateConnection();

        // If the user is logging in with a different wallet, return, as they need to onboard again
        if (oldWallet && oldWallet !== account) {
          // autoOnboardUser();
          return;
        }

        // Read user secrets from local db by wallet address and validate them
        const refreshToken = await readAndValidateUserSecretsFromStorage(
          account,
        );

        // If user exists, refresh jwt
        if (refreshToken) {
          await refreshJwt(refreshToken);
        }
      } catch (e: any) {
        unsetUserSecretsAndDisconnectPrivateConnection();
        console.error(e);
        showNotification({
          title: t('errorHasOccurred'),
          description: t('errorOccurredDuringWalletLogin'),
          type: NotificationType.Negative,
        });
      } finally {
        setIsReadingSignatureFromLocalDB(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, queryClient]);

  /**
   * Reads user secrets from browser storage
   * @param account - Wallet address
   * @returns User secrets from local db
   */
  const readUserSecretsFromBrowserStorage = async (
    account: string,
  ): Promise<any> => {
    try {
      // Read user secrets from local db by wallet address
      const secrets = await db.getItem(account);
      return secrets;
    } catch (e: any) {
      throw new Error('Error reading user secrets from local db', { cause: e });
    }
  };

  /**
   * Create or update a user in the local db with the frontend secrets (jwt, refresh token, random secret)
   * @param frontendSecrets - Frontend secrets
   * @param account - Wallet address
   */
  const createOrUpdateLocalDbUser = async (
    frontendSecrets: FrontendSecrets,
    account: string,
  ) => {
    // Create a user object from frontend secrets
    const dbObject = {
      profile: JSON.stringify(frontendSecrets.profile),
      jwt: frontendSecrets.jwt,
      refreshToken: frontendSecrets.refreshToken,
      randomSecret: frontendSecrets.randomSecret,
    };

    // Read existing user from local db by wallet address
    const recovered = await readUserSecretsFromBrowserStorage(account);

    // Save wallet address in local storage
    saveUserSetting(LS_WALLET, account);

    // If user does not exist, create it with the wallet address as a key called 'wallet'
    if (!recovered?.jwt) {
      try {
        await db.addItem({ ...dbObject, wallet: account.toLowerCase() });
      } catch (e: any) {
        console.error('Error creating a user in local db', e);
        throw new Error('Error creating a user in local db', { cause: e });
      }
    }
    // If user exists, update it with the new frontend secrets
    else {
      try {
        await db.updateItem(account, {
          ...dbObject,
          wallet: account.toLowerCase(),
        });
      } catch (e: any) {
        console.error('Error updating a user in local db', e);
        throw new Error('Error updating a user in local db', { cause: e });
      }
    }
  };

  /**
   * Delete user from session storage with key of wallet address
   * @param wallet - Wallet address
   */
  const deleteUserFromLocalDb = async (wallet: string) => {
    try {
      await db.deleteItem(wallet);
    } catch (e: any) {
      console.error('Error deleting item from local db', e);
      throw new Error('Error deleting item from local db', { cause: e });
    }
  };

  const jwtCheckIfExpiredOrInvalidAndRefresh = async (
    jwt: string,
    shouldCheckValidity = true,
  ) => {
    try {
      // If jwt is expired, return false
      const isJwtExpired = checkIfJwtExpired(jwt);
      if (isJwtExpired) {
        await refreshJwt();
        return;
      }

      // For some requests, we don't need to check if jwt is valid, as it takes too much time
      if (!shouldCheckValidity) {
        return;
      }

      // If jwt is not expired, check if it is valid
      const isJwtValid = await checkIfJwtIsValid(jwt);
      if (!isJwtValid) {
        await refreshJwt();
        return;
      }
    } catch (e: any) {
      console.error('Error while checking if jwt is expired or invalid', e);
      throw new Error('Error while checking if jwt is expired or invalid', {
        cause: e,
      });
    }
  };

  // Disconnect from private centrifuge service for the current jwt
  const disconnectPrivateWebsocketConnection = (jwt: string) => {
    try {
      const privateCentrifugeService = CentrifugeService.getPrivate(jwt);

      // Remove account subscription from private centrifuge service and disconnect from it
      const jwtPayload = parseJwt(jwt);
      const profileId = jwtPayload.sub;
      privateCentrifugeService.removeSubscription(
        `${WebsocketChannels.Account}@${profileId}`,
      );
      CentrifugeService.disconnectPrivate();
    } catch (e: any) {
      console.error('Error while disconnecting private centrifuge service', e);
    }
  };

  /**
   * Unset frontend secrets from store and disconnect from private centrifuge service
   */
  const unsetUserSecretsAndDisconnectPrivateConnection = () => {
    // Delete wallet address from local storage
    saveUserSetting(LS_WALLET, null);
    saveUserSetting(LS_WALLET_SIGNATURE, null);

    // If the user is not logged in, return
    if (!accountStore?.frontendSecrets?.jwt) {
      return;
    }

    // Disconnect from private centrifuge service for the current jwt
    const { jwt } = accountStore.frontendSecrets;

    // Clear frontend secrets from store
    accountStore.unsetFrontendSecrets();

    disconnectPrivateWebsocketConnection(jwt);
  };

  /**
   * Tries to read user secrets from local db and validate them. If user exists and is valid,
   * returns true, false otherwise
   * @param account - Wallet address
   * @returns true if user exists in local db and is valid, false otherwise
   */
  const readAndValidateUserSecretsFromStorage = async (
    account: string,
  ): Promise<undefined | string> => {
    try {
      // Read existing user from local db by wallet address
      const recovered = await readUserSecretsFromBrowserStorage(account);

      // If user does not exist, return false
      if (!recovered?.jwt) {
        return undefined;
      }

      // If user exists, check if jwt is expired or invalid
      const { jwt } = recovered;

      // If jwt is expired, return false
      const isJwtExpired = checkIfJwtExpired(jwt);
      if (isJwtExpired) {
        return undefined;
      }
      // If jwt is not expired, check if it is valid
      const isJwtValid = await checkIfJwtIsValid(jwt);
      if (!isJwtValid) {
        return undefined;
      }

      // If jwt is valid, refresh it and set frontend secrets in store
      const recoveredFrontendSecrets: FrontendSecrets = {
        profile: JSON.parse(recovered.profile),
        jwt: recovered.jwt,
        refreshToken: recovered.refreshToken,
        randomSecret: recovered.randomSecret,
      };
      accountStore.setFrontendSecrets(recoveredFrontendSecrets);

      return recovered.refreshToken;
    } catch (e: any) {
      console.error(
        'Error reading user secrets from local db and validating them',
        e,
      );
      throw new Error(
        'Error reading user secrets from local db and validating them',
        { cause: e },
      );
    }
  };

  /**
   * Signs a metamask message with the wallet and returns the signature
   * @param timestamp ISO timestamp of when the request was signed. Must be within 30 seconds of the server time.
   * @returns signature of the metamask message
   */
  const signMetamaskMessageAndCreateSignature = async (timestamp: number) => {
    try {
      if (
        !(await validateNetworkAndSwitchIfRequired('Sign Metamask Message.'))
      ) {
        throw new Error('Please switch to the correct network.');
      }

      showNotification({
        title: t('signMessageToVerifyOwnership'),
        description: t('signMessageToVerifyOwnershipDescription'),
        type: NotificationType.Info,
      });

      const signRequest = createTypedDataSignRequestData(
        timestamp,
        config.chainID,
      );

      const signatureJS = await library?.send('eth_signTypedData_v4', [
        account,
        JSON.stringify(signRequest),
      ]);

      if (!signatureJS) {
        throw new Error('Error signing metamask message');
      }

      return jsToGoSignature(signatureJS);
    } catch (e: any) {
      const message = `Error signing metamask message. ${e?.message ?? ''}`;
      showNotification({
        title: 'Error signing metamask message',
        description: message,
        type: NotificationType.Negative,
      });
      console.error(message, e);
      throw new Error(message);
    }
  };

  /**
   * Creates params for the onboarding request from the wallet address and signature
   * @param timestamp ISO timestamp of when the request was signed. Must be within 30 seconds of the server time.
   * @param vaultAddress - If the user is onboarding as a vault type of the account, the vault address is passed here
   * @returns params for the onboarding request, e.g. { wallet: '0x...', is_client: true, signature: '0x...' }
   */
  const createOnboardingParams = async (
    timestamp: number,
    vaultAddress?: string,
  ): Promise<OnBoardingParams | undefined> => {
    if (!account) {
      return;
    }

    try {
      // Ask the user to sign the message with their wallet
      const signature = await signMetamaskMessageAndCreateSignature(timestamp);

      const campaignAnalytics = readUserSetting(CAMPAIGN_ANALYTICS) ?? [];

      const referralCode = searchParams.get('ref');

      return {
        // if it's a vault type of the account, use the vault address as the wallet address
        wallet: vaultAddress ? vaultAddress : account,
        is_client: true,
        signature,
        meta: {
          campaign: campaignAnalytics,
          ...(referralCode && { referrer_short_code: referralCode }),
        },
        // Add profile_type if it's a vault type of the account
        ...(vaultAddress && { profile_type: 'vault' }),
      };
    } catch (e: any) {
      const message = `Error creating onboarding params. ${e?.message ?? ''}`;
      console.error(message, e);
      throw new Error(message, e);
    }
  };

  /**
   * Onboards a user by signing a message with their wallet and sending it to the backend, which verifies the signature
   * and returns frontend secrets. The frontend secrets are stored in the browser storage and local store (mobx), and also in
   * browser cookies using the set-cookie header.
   * @param vaultAddress - If the user is onboarding as a vault type of the account, the vault address is passed here
   * @returns true if the user was onboarded successfully, false otherwise
   */
  const onboardUser = async (vaultAddress?: string) => {
    try {
      if (isOnboarding || !account) {
        return false;
      }

      setIsOnboarding(true);

      // Get the server timestamp and save the offset to local storage
      await getSrvTimestamp();

      const timestamp =
        getTimestampWithOffsetFromLocalStorage() + SIGNATURE_LIFETIME;

      console.log('onboardUser', account, timestamp);

      // Create params for the onboarding request by signing a message with the wallet
      const params = await createOnboardingParams(timestamp, vaultAddress);

      const { data: res }: { data: FrontendSecrets } =
        await restService.request<FrontendSecrets>({
          method: RequestMethod.POST,
          path: `/${Endpoints.ONBOARDING}`,
          body: params,
          headers: {
            'RBT-TS': timestamp.toString(),
          },
        });

      if (vaultAddress) {
        // On valid vault account, handle it as per its a new wallet
        if (vaultAddress) {
          handleAccountActivation({
            address: account,
            vaultAddress,
            connectorName: ConnectorNames.Injected,
          });
        }

        // If the user is onboarding as a vault type of the account, delete the old user from local db
        // this is because we don't support automatic wallet connect on page refresh for vaults
        await deleteUserFromLocalDb(account);
      } else {
        // Create or update user in browser storage and local store with frontend secrets
        await createOrUpdateLocalDbUser(res, account);
      }
      accountStore.setFrontendSecrets(res);

      showNotification({
        title: t('verifiedOwnership'),
        description: t('verifiedOwnershipDescription'),
        type: NotificationType.Positive,
      });
      return true;
    } catch (e: any) {
      console.error(e);
      showNotification({
        title: t('ownershipVerificationFailed'),
        description: `${e?.message}`,
        type: NotificationType.Negative,
      });
      return false;
    } finally {
      setIsOnboarding(false);
    }
  };

  /**
   * Signs a metamask message with the wallet and returns the signature and timestamp as headers.
   * If the timestamp is still valid, use the signature and timestamp from local storage.
   * @returns signature and timestamp as headers
   */
  const requestMetamaskSignatureOrReadFromLocalStorage = async ({
    signMetamaskMessageNotifDescription,
  }: {
    signMetamaskMessageNotifDescription: string;
  }): Promise<{
    'RBT-PK-SIGNATURE': string;
    'RBT-PK-TS': string;
  }> => {
    const lsWalletSignature = readUserSetting(LS_WALLET_SIGNATURE) ?? {};
    const oldSignatureTimestamp = Number(lsWalletSignature.timestamp) ?? 0;
    const oldSignature = lsWalletSignature.signature ?? '';

    const currentTimestamp = getTimestampWithOffsetFromLocalStorage();
    if (
      !oldSignatureTimestamp ||
      oldSignatureTimestamp <
        // Add 5 seconds to the current timestamp to make sure the timestamp is still valid
        currentTimestamp + 5
    ) {
      showNotification({
        title: 'Sign Metamask Message',
        description: signMetamaskMessageNotifDescription,
        type: NotificationType.Info,
      });

      const startTime = Date.now();

      // Get current timestamp and add SIGNATURE_LIFETIME seconds to it
      const timestamp =
        getTimestampWithOffsetFromLocalStorage() + SIGNATURE_LIFETIME;
      const signature = await signMetamaskMessageAndCreateSignature(timestamp);

      const duration = (Date.now() - startTime) / 1000;

      // Check if the action took more than SIGNATURE_LIFETIME seconds (add 5 seconds for safety)
      if (duration > SIGNATURE_LIFETIME - 5) {
        showNotification({
          title: 'Message Signing Delay',
          description: 'The message signing process took too long.',
          type: NotificationType.Negative,
        });

        throw new Error('Message signing process took too long.');
      }

      const newSignature = {
        timestamp,
        signature,
      };
      saveUserSetting(LS_WALLET_SIGNATURE, newSignature);

      return {
        'RBT-PK-SIGNATURE': signature,
        'RBT-PK-TS': timestamp.toString(),
      };
    }
    // If the timestamp is still valid, use the signature and timestamp from local storage
    else {
      return {
        'RBT-PK-SIGNATURE': oldSignature,
        'RBT-PK-TS': oldSignatureTimestamp.toString(),
      };
    }
  };

  const makePrivateRequest = async ({
    method,
    endpoint,
    requestParams,
    refreshJwt = true,
    responseMapping,
    paramsMapping,
    isArray = false,
    queryParams,
    shouldCheckJwtValidity = true,
    shouldSignMetamaskMessage = false,
    signMetamaskMessageNotifDescription = 'Please sign the message with your wallet to proceed with the request.',
  }: MakePrivateRequestParams): Promise<RequestResponse<any>> => {
    if (!account || !accountStore.frontendSecrets) {
      throw new Error(
        `User was not logged when sending a ${method} request to ${endpoint}.`,
      );
    }

    try {
      // If jwt is expired or invalid, refresh it
      if (refreshJwt) {
        await jwtCheckIfExpiredOrInvalidAndRefresh(
          accountStore.frontendSecrets.jwt,
          shouldCheckJwtValidity,
        );
      }

      let requestHeaders = {};
      // If the request should be signed with metamask (verifies the private key access with fresh timestamp),
      // sign it and add the signature and timestamp to the headers
      if (shouldSignMetamaskMessage) {
        requestHeaders = await requestMetamaskSignatureOrReadFromLocalStorage({
          signMetamaskMessageNotifDescription,
        });
      }

      return await restService.privateRequest({
        method,
        endpoint,
        requestParams,
        responseMapping,
        paramsMapping,
        accountSecrets: accountStore.frontendSecrets,
        isArray,
        queryParams,
        requestHeaders,
      });
    } catch (e: any) {
      console.error(
        `Error sending a ${method} request to ${endpoint}`,
        e?.message,
      );
      throw new Error(e?.message, {
        cause: `Error sending a ${method} request to ${endpoint}`,
      });
    }
  };

  /**
   * Refreshes the JWT and updates the frontend secrets in the browser storage and local store (mobx)
   * with the new frontend secrets returned by the backend.
   */
  const refreshJwt = async (_refreshToken?: string) => {
    try {
      if (!accountStore.frontendSecrets || !account) {
        throw new Error(`User was not logged in when trying to refresh JWT.`);
      }

      // Read current frontend secrets from store, refreshToken is needed to refresh the jwt
      const { refreshToken: oldRefreshToken, profile } =
        accountStore.frontendSecrets;

      const params: RefreshJwtParams = {
        isClient: true,
        refreshToken: _refreshToken || oldRefreshToken,
      };

      const {
        data: {
          jwt: newJwt,
          randomSecret: newRandomSecret,
          refreshToken: newRefreshToken,
        },
      } = await makePrivateRequest({
        method: RequestMethod.POST,
        endpoint: `/${Endpoints.JWT}`,
        requestParams: params,
        refreshJwt: false,
        responseMapping: API_MAPPINGS.REFRESH_JWT_RESPONSE,
        paramsMapping: API_MAPPINGS.REFRESH_JWT_PARAMS,
      });

      // Update user in browser storage and local store with frontend secrets
      const newFrontendSecrets: FrontendSecrets = {
        profile,
        jwt: newJwt,
        randomSecret: newRandomSecret,
        refreshToken: newRefreshToken,
      };
      await createOrUpdateLocalDbUser(newFrontendSecrets, account);
      accountStore.setFrontendSecrets(newFrontendSecrets);
    } catch (e: any) {
      unsetUserSecretsAndDisconnectPrivateConnection();
      console.error(e);
      throw new Error(`An error has occurred when trying to refresh JWT.`, {
        cause: e,
      });
    } finally {
      accountStore.setIsAuthenticated(true);
    }
  };

  const value = {
    frontendSecrets: accountStore.frontendSecrets,
    onboardUser,
    refreshJwt,
    makePrivateRequest,
    isOnboarding,
    isReadingSignatureFromLocalDB,
    signMetamaskMessageAndCreateSignature,
  };

  return (
    <ExchangeApiContext.Provider value={value}>
      {children}
    </ExchangeApiContext.Provider>
  );
};
