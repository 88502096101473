import { memo, useState } from 'react';

import { brand } from 'utils/brand';

import Text from 'components/Text';

import DataCards from './DataCards';
import MarketFilters from './MarketFilters';
import { MarketFilterType } from './MarketFilters/TabFilters';
import MarketsTable from './MarketsTable';
import { Container, MainContent } from './styles';
import { getFilteredArray } from './utils';
import {
  coingeckoMarketIds,
  MAP_COINGECKO_ID_TO_MARKET_ID,
} from 'constants/marketMappings';
import { useAppContext } from 'contexts/AppContext';
import Watchlist from 'pages/Portfolio/components/Watchlist';
import { MarketService } from 'service/marketService';
import { RestService } from 'service/restService';

import { Endpoints, QueryKeys, RequestMethod } from 'enums';
import { Market } from 'interfaces';
import { useFetchUserSettings } from 'queryHooks';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const marketService = MarketService.get();

const restService = RestService.get();

type CoingeckoResponse = {
  id: string;
  market_cap: number;
  market_cap_rank: number;
};

const MarketsPage = () => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<MarketFilterType>('All');

  const { data: settingsData } = useFetchUserSettings(frontendSecrets?.jwt);

  const {
    isLoading: isLoadingCoingeckoMarketCaps,
    data: coingeckoMarketCaps,
  }: {
    isLoading: boolean;
    data:
      | {
          [key: string]: number;
        }
      | undefined;
  } = useQuery(
    [QueryKeys.CoingeckoMarketData],
    async () => {
      try {
        const { data }: { data: CoingeckoResponse[] } =
          await restService.request({
            method: RequestMethod.GET,
            path: `/${Endpoints.MARKETS_COINS}`,
            queryParams: {
              coin_ids: coingeckoMarketIds,
            },
            isArray: true,
          });

        // create a map of rabbitx market ID to coingecko market cap
        const marketIdToCoingeckoMarketCap: { [key: string]: number } =
          data.reduce((acc, market) => {
            acc[MAP_COINGECKO_ID_TO_MARKET_ID[market.id]] =
              market.market_cap_rank ? market.market_cap : undefined;
            return acc;
          }, {});

        return marketIdToCoingeckoMarketCap;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    { refetchInterval: false },
  );

  const { isLoading: isLoadingMarkets, data: markets } = useQuery(
    QueryKeys.Markets,
    async () => {
      const markets: Market[] | null = await marketService.fetchMarkets();
      if (!markets) {
        throw new Error(
          'An error occurred while trying to fetch markets in MarketSearchDropdown component',
        );
      }
      return markets;
    },
    {
      // 60 seconds
      refetchInterval: 60_000,
    },
  );

  const filteredMarkets = getFilteredArray({
    markets: markets ?? [],
    selectedFilter,
    searchTerm,
    userSettings: settingsData,
  });

  return (
    <Container>
      <MainContent>
        <div className="header">
          <Text
            variant="HEADING_H2"
            flexed
            className="mb-5"
            fontWeight="semiBold"
          >
            {t('marketDiscovery')}
          </Text>
          <Text variant="BODY_M" color="shadesForeground200">
            {t('marketDiscoveryDescription').replace(
              'RabbitX',
              brand.appShortName,
            )}
          </Text>
          <DataCards markets={markets} />
        </div>
        <MarketFilters
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <MarketsTable
          markets={filteredMarkets}
          isLoading={isLoadingCoingeckoMarketCaps || isLoadingMarkets}
          coingeckoMarketCaps={coingeckoMarketCaps}
        />
      </MainContent>
      <Watchlist showWatchlistOnMediumDevices={true} />
    </Container>
  );
};

export default memo(MarketsPage);
