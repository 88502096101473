import { showNotification } from 'utils';
import { ChangeMarketLeverageParams } from 'utils/api';

import { useExchangeAPI } from './useExchangeAPI';
import { API_MAPPINGS } from 'constants/apiMappings';

import { Endpoints, NotificationType, RequestMethod } from 'enums';
import { AccountStats } from 'interfaces';
import { useTranslation } from 'react-i18next';

export interface ChangeMarketLeverageResponse {
  leverage: number;
  marketID: string;
}

export function useAccountAPI() {
  const { makePrivateRequest } = useExchangeAPI();
  const { t } = useTranslation();

  const changeMarketLeverage = async (
    params: ChangeMarketLeverageParams,
  ): Promise<AccountStats> => {
    try {
      const { data: profileData }: { data: AccountStats } =
        await makePrivateRequest({
          method: RequestMethod.PUT,
          endpoint: `/${Endpoints.ACCOUNT_LEVERAGE}`,
          requestParams: params,
          responseMapping: API_MAPPINGS.CHANGE_MARKET_LEVERAGE_RESPONSE,
          paramsMapping: API_MAPPINGS.CHANGE_MARKET_LEVERAGE_PARAMS,
        });

      const { leverage } = profileData;
      if (!leverage || !leverage[params.marketID] === undefined) {
        throw new Error(
          "Couldn't change market leverage, new leverage was not returned from the server",
        );
      }

      const marketID = params.marketID;
      const newMarketLeverage = leverage[params.marketID];
      const notifTitle = t('marketLeverageChangedTitle', {
        newMarketLeverage,
      });
      const notifDescription = t('marketLeverageChangedDescription', {
        marketID: marketID,
        newMarketLeverage,
      });

      showNotification({
        title: notifTitle,
        description: notifDescription,
        type: NotificationType.Positive,
      });
      return profileData;
    } catch (e: any) {
      console.error(e.message);
      showNotification({
        title: t('marketLeverageChangeFailedTitle'),
        description: t('marketLeverageChangeFailedDescription', {
          errorMessage: e.message,
        }),
        type: NotificationType.Negative,
      });
      throw e;
    }
  };

  return {
    changeMarketLeverage,
  } as const;
}

export type AccountAPI = ReturnType<typeof useAccountAPI>;
