import { memo } from 'react';

import {
  TableCell,
  TableRow,
  TableHead as TableHeadMui,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const TableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>{t('Payout')}</TableCell>
        <TableCell key={1}>{t('FeesEarned')}</TableCell>
        <TableCell key={2} align="right">
          {t('Time')}
        </TableCell>
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
