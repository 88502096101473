import { memo } from 'react';

import { makeABitLessthanOrEqual } from 'utils';
import { brand } from 'utils/brand';

import { FormattedNumber } from 'components';
import NumberInput from 'components/Inputs/NumberInput';
import Loading from 'components/Loading';

import { RowBetween } from '../styles';
import refreshIcon from 'assets/icons/refresh.svg';
import { USDT_DECIMALS } from 'constants/contracts';

import { useTranslation } from 'react-i18next';

type Props = {
  availableBalance: number;
  amount: number | null;
  onAmountChange: (value: number | null) => void;
  pricePerShare: number;
  onRefetchPricePerShare: () => void;
  isRefetching: boolean;
  disabled: boolean;
};
const StakeInputs = ({
  availableBalance,
  onAmountChange,
  pricePerShare,
  amount,
  isRefetching,
  onRefetchPricePerShare,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const parsedAvailableBalance = makeABitLessthanOrEqual(
    availableBalance,
    USDT_DECIMALS,
  );
  const isAmountMoreThanAvailableBalance =
    (amount || 0) > parsedAvailableBalance; // If the amount if more than available balance

  return (
    <>
      <NumberInput
        value={amount}
        onChange={onAmountChange}
        label={t('size')}
        showSwapCurrency={false}
        currency={brand.tokenTicker}
        customCurrencyIcon={brand.tokenLogo}
        showValueApproximation={false}
        showError={isAmountMoreThanAvailableBalance}
        errorMessage={
          isAmountMoreThanAvailableBalance ? t('notEnoughAvailableBalance') : ''
        }
        maxValue={parsedAvailableBalance}
        disabled={disabled}
      >
        <RowBetween>
          <span>
            {t('available')} {brand.tokenTicker}
          </span>
          <FormattedNumber value={parsedAvailableBalance} />
        </RowBetween>

        {amount && amount > 0 ? (
          <RowBetween>
            <span>{t('sharesCreated')}</span>
            <span className="flex items-center gap-2">
              <FormattedNumber value={amount} /> {brand.tokenTicker} ≈{' '}
              {isRefetching ? (
                <Loading size={10} className="verti-align-middle" />
              ) : (
                <FormattedNumber value={amount / pricePerShare} />
              )}{' '}
              {t('shares')}
              <img
                src={refreshIcon}
                alt="reload"
                onClick={onRefetchPricePerShare}
                className="cursor-pointer"
              />
            </span>
          </RowBetween>
        ) : null}
      </NumberInput>
    </>
  );
};

export default memo(StakeInputs);
