import { useEffect, useState } from 'react';

import { FormatColorResetRounded } from '@material-ui/icons';

import { useBfxPointsAPI } from 'hooks/useBFXPointsAPI';

import { FormattedNumber, Icon } from 'components';
import Loading from 'components/Loading';
import Text from 'components/Text';
import { TextVariant } from 'components/Text/variant';

import bfxGreenIcon from 'assets/assetLogos/blast-green.svg';
import bfxWhiteIcon from 'assets/assetLogos/blast-white.svg';
import blastGoldIcon from 'assets/icons/blast-gold.svg';
import bfxBgVector from 'assets/vectors/generic-border.png';
import bfxBgVectorContentRect from 'assets/vectors/generic-content-rect.png';
import bfxBgVectorGold from 'assets/vectors/gold-border.png';
import bfxBgVectorGoldContentRect from 'assets/vectors/gold-content-rect.png';
import 'odometer/themes/odometer-theme-default.css';
import { useGetBfxAllocation } from 'queryHooks/tokenLock';
import { Column, Row } from 'theme/globalStyledComponents';

import Odometer from 'react-odometerjs';
import styled from 'styled-components';

const StyledStatsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media (min-width: 1020px) {
    flex-direction: row;
  }
`;
type BfxStatsProps = {
  goldPoints: number;
  pendingGoldPoints: number;
  blastPoints: number;
  pendingBlastPoints: number;
};
const BfxStats = ({
  goldPoints,
  pendingGoldPoints,
  blastPoints,
  pendingBlastPoints,
}: BfxStatsProps) => {
  // const { totalAllocation, isLoading } = useGetBfxAllocation();
  const { bfxPoints, isLoading } = useBfxPointsAPI();
  const STATS = [
    {
      label: 'BFX Points',
      value: bfxPoints ?? 0,
      description: <>Engage & Earn points coming soon</>,
      valueLeftComponent: <Icon src={bfxGreenIcon} size="L" />,
      isLoading: isLoading,
    },

    {
      label: 'Blast Points',
      value: blastPoints,
      valueLeftComponent: <Icon src={bfxWhiteIcon} size="L" />,
      description: (
        <>
          You have{' '}
          <FormattedNumber
            color="positiveForeground200"
            value={pendingBlastPoints}
          />{' '}
          pending Points
        </>
      ),
    },
    {
      label: 'Blast Gold',
      value: goldPoints,
      description: (
        <>
          You have{' '}
          <FormattedNumber
            color="positiveForeground200"
            value={pendingGoldPoints}
          />{' '}
          pending Gold
        </>
      ),
      valueLeftComponent: <Icon src={blastGoldIcon} size="L" />,
    },
  ];

  return (
    <StyledStatsGroup>
      {STATS.map(stat => (
        <StatItem {...stat} />
      ))}
    </StyledStatsGroup>
  );
};

export default BfxStats;

const StyledHeader = styled(Text)`
  margin-bottom: -2px;
  margin-left: 105px;

  /* 
  @todo: Consult with @Matt on TextStroke
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${({ theme }) =>
    theme.colors.positiveBackground100};
  font-weight: 900 !important; */
`;

type Props = { isColorGold?: boolean };
const Container = styled(Row)<Props>`
  height: 85.7px;

  object-fit: fill;
  width: 100%;
  position: relative;

  .left-border {
    margin-left: 20px;
    height: 100%;
    width: 1px;
    background: ${({ theme, isColorGold }) =>
      isColorGold ? '#8C6B2E' : theme.colors.positiveBackground100};
  }

  .ml-16 {
    margin-left: 16px;
  }

  .title-number {
    font-size: ${TextVariant['NUMBERS_XL'].fontSize};
    line-height: ${TextVariant['NUMBERS_XL'].lineHeight};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  .content {
    position: relative;
    z-index: 3;
  }

  .right-component {
    position: absolute;
    right: 0;
    align-self: stretch;
    width: calc(100% - 250px);
    padding-right: 20px;
    height: calc(100% - 3px);
    bottom: -1px;
    background: linear-gradient(
      to left,
      #111416,
      #12181c 44.1626%,
      rgba(19, 30, 38, 0) 100%
    );
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .left-bg-container {
    display: flex;
    position: absolute;
    height: 100%;
    z-index: 0;
    width: 110px;
    background: ${({ isColorGold }) =>
      `url(${isColorGold ? bfxBgVectorGold : bfxBgVector})`};
    background-size: cover;
  }

  .right-bg {
    display: flex;
    z-index: 0;
    object-fit: fill;
    width: calc(100% - 120px);
    height: 100%;
    bottom: 0;
    position: absolute;
    left: 110px;
  }
`;

type StatItemProps = {
  label: string;
  value: number | string;
  rightComponent?: React.ReactNode;
  description: React.ReactNode;
  isColorGold?: boolean;
  valueRightComponent?: React.ReactNode;
  valueLeftComponent?: React.ReactNode;
  valuePrefix?: string;
  isLoading?: boolean;
};
const StatItem = ({
  label,
  value,
  rightComponent,
  description,
  isColorGold,
  valueLeftComponent,
  valueRightComponent,
  valuePrefix,
  isLoading = false,
}: StatItemProps) => {
  return (
    <Column className="flex-1">
      <StyledHeader className="header" variant="BODY_M" fontWeight="semiBold">
        {label.toUpperCase()}
      </StyledHeader>
      <Container isColorGold={isColorGold}>
        <div className="left-bg-container"></div>
        <img
          className="right-bg"
          src={
            isColorGold ? bfxBgVectorGoldContentRect : bfxBgVectorContentRect
          }
          alt=""
        ></img>

        <div className="left-border" />

        <Column gap={8} className="flex-1 ml-16 content">
          <Row gap={5}>
            {valueLeftComponent}
            {isLoading ? (
              <Loading size="20px" />
            ) : typeof value === 'string' ? (
              <Text className="title-number">{value}</Text>
            ) : (
              <Odometer
                value={value}
                className="title-number"
                prefix={valuePrefix}
              />
            )}
            {valueRightComponent}
          </Row>
          <Text variant="BODY_S" color="shadesForeground200">
            {description}
          </Text>
        </Column>

        <Row className="right-component" justify="flex-end">
          {rightComponent}
        </Row>
      </Container>
    </Column>
  );
};
