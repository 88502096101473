import Loading from 'components/Loading';

import Layout from '../../common/Layout';
import VaultActivityTable from '../../common/VaultActivityTable';
import { Column } from '../../styles';
import BackAndActions from './components/BackAndActions';
import Chart from './components/Chart';
import Stats from './components/Stats';
import VaultName from './components/VaultName';
import YourRewards from './components/YourRewards';
import { Container } from './styles';
import { ColoredText, Colors } from 'pages/Trade/components/shared';
import { useFetchSingleVaultData } from 'queryHooks/elixirVaults';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const VaultOverview = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const vaultId = searchParams.get('id');

  const {
    data: vaultData,
    isLoading: isLoadingVaultData,
    isError: isErrorVaultData,
  } = useFetchSingleVaultData({
    poolId: Number(vaultId),
  });

  return (
    <Layout>
      <Container>
        <BackAndActions isWatched={true} isLoadingVault={isLoadingVaultData} />

        <div className="content">
          {isErrorVaultData ? (
            <ColoredText color={Colors.Danger}>
              {t('errorFetchingVaultDataPleaseRefresh')}
            </ColoredText>
          ) : !vaultData || isLoadingVaultData ? (
            <Loading className="loader" />
          ) : (
            <>
              <Column className="left-col">
                <VaultName vault={vaultData} />
                <Chart vault={vaultData} />
              </Column>
              <Column className="right-col">
                <Stats vault={vaultData} />
                <YourRewards vault={vaultData} />
              </Column>
            </>
          )}
        </div>

        <VaultActivityTable isLoadingVaults={isLoadingVaultData} />
      </Container>
    </Layout>
  );
};

export default VaultOverview;
