import { AbstractConnector } from '@web3-react/abstract-connector';
import { InjectedConnector } from '@web3-react/injected-connector';

import { isInjectedProviderMetamask } from '../ConnectMethods';
import { getMethodLabelAndIcon } from '../ConnectMethods/helpers';
import { Container } from './styles';
import arrowRightIcon from 'assets/icons/arrow-right-gray.svg';
import moreIcon from 'assets/icons/horizontal-three-dots.svg';
import { WalletConnectV2Connector } from 'connectors/walletconnectV2Connector';

const openProviderInstallationPage = () => {
  window.open('https://metamask.io/download/', '_blank');
};

type Props = {
  hideLabel?: boolean;
  connector: AbstractConnector;
  isVault?: boolean;
  connectWallet: (
    connector: AbstractConnector,
    isVault?: boolean,
  ) => Promise<void>;
  gtmId?: string;
  dataCy?: string;
  iconBorder?: 'half' | 'full';
  showMoreIcon?: boolean;
};

export const MethodIcon = ({
  icon,
  isWalletConnect,
  showMoreIcon,
}: {
  icon: string | string[];
  isWalletConnect?: boolean;
  showMoreIcon: boolean;
}) => {
  if (!icon) return null;

  const isIconArray = Array.isArray(icon);

  if (isIconArray) {
    const icons = showMoreIcon ? [...icon, moreIcon] : icon;
    return (
      <div className="spread-icons border">
        {icons.map(icon => (
          <img src={icon} className="spreaded-icon" alt="icon" />
        ))}
      </div>
    );
  }

  return (
    <img
      src={icon}
      alt="icon"
      className={isWalletConnect ? 'wallet-connect-background' : ''}
    />
  );
};

const MethodOption = ({
  connector,
  connectWallet,
  isVault = false,
  hideLabel,
  gtmId = '',
  dataCy = '',
  iconBorder = 'full',
  showMoreIcon = true,
}: Props) => {
  const isInjectedMetamask = isInjectedProviderMetamask();

  const { icon, label, description } = getMethodLabelAndIcon({
    connector,
    isVault,
    isInjectedMetamask,
  });

  const onClick = () => {
    if (connector instanceof InjectedConnector) {
      if (!isInjectedMetamask) {
        openProviderInstallationPage();
        return;
      }
    }
    connectWallet(connector, isVault);
  };

  return (
    <Container
      onClick={onClick}
      data-cy={dataCy}
      data-gtmid={gtmId}
      iconBorder={iconBorder}
      showMoreIcon={showMoreIcon}
    >
      <div className="column">
        <div className="row">
          <MethodIcon
            icon={icon}
            isWalletConnect={connector instanceof WalletConnectV2Connector}
            showMoreIcon={showMoreIcon}
          />
          {hideLabel ? null : label}
        </div>

        {description ? (
          <span className="description">{description}</span>
        ) : null}
      </div>

      <img className="arrow-right" src={arrowRightIcon} alt="->" />
    </Container>
  );
};

export default MethodOption;
