import { useEffect, useState } from 'react';

import { getSavedVaultActivityFromSpotId } from 'utils/vaultActivity';

import { useAccount, useElixirContractsAPI } from 'hooks';

import RequestFailedState from './Error';
import RequestPendingState from './Pending';
import RequestSentButUntraceable from './SentButCouldntTrack';
import RequestSucessState from './Success';
import { SpotState } from 'contexts/ElixirContractsApiContext';
import { SpotStateType } from 'enums/spotState';

import { AmendType } from '..';

interface Props {
  amount: number | undefined;
  initialSpotState?: SpotState;
  tokenPair: string;
  amendType: AmendType;
  requestSent: boolean | undefined;
}

const RequestStatus = ({
  amount,
  initialSpotState,
  tokenPair,
  amendType,
  requestSent,
}: Props) => {
  const { handleSpotUpdateOnQueueMove } = useElixirContractsAPI();
  const { account } = useAccount();
  const [currentSpotState, setCurrentSpotState] = useState(initialSpotState);

  useEffect(() => {
    if (!initialSpotState?.id || !amount || !tokenPair || !account) return;

    const activity = getSavedVaultActivityFromSpotId(
      account,
      initialSpotState.id,
    );

    if (!activity) return;

    const stopListener = handleSpotUpdateOnQueueMove(
      initialSpotState?.id,
      setCurrentSpotState,
    );

    return stopListener;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSpotState?.id, account]);

  if (!initialSpotState || !currentSpotState) {
    if (requestSent) {
      return (
        <RequestSentButUntraceable
          amendType={amendType}
          tokenPair={tokenPair}
          amount={amount}
        />
      );
    }

    return (
      <RequestFailedState
        amendType={amendType}
        tokenPair={tokenPair}
        amount={amount}
      />
    );
  }

  if (currentSpotState.spotStateType === SpotStateType.Executed) {
    return (
      <RequestSucessState
        amendType={amendType}
        tokenPair={tokenPair}
        amount={amount}
      />
    );
  }

  if (
    [SpotStateType.Queued, SpotStateType.Executing].includes(
      currentSpotState.spotStateType,
    )
  )
    return (
      <RequestPendingState
        amendType={amendType}
        tokenPair={tokenPair}
        amount={amount}
        initialPosition={initialSpotState.positionInQueue}
        currentPosition={currentSpotState.positionInQueue}
        spotStateType={currentSpotState.spotStateType}
      />
    );

  return (
    <RequestFailedState
      amendType={amendType}
      tokenPair={tokenPair}
      amount={amount}
    />
  );
};

export default RequestStatus;
