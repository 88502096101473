import { memo } from 'react';

import useModal from 'hooks/useModal';

import Button from 'components/Button/button';

import settingsIcon from 'assets/icons/settings.svg';
import { Modals } from 'constants/modals';
import ApiKeysModal from 'pages/ApiKeysModal';

const SettingsDropdown = () => {
  const modal = useModal();
  const handleApiKeysClick = () => {
    modal.present(<ApiKeysModal />, Modals.apiKeysModal);
  };

  return (
    <Button
      rightIcon={settingsIcon}
      iconSize="L"
      iconOnly={true}
      onClick={handleApiKeysClick}
    />
  );
};

export default memo(SettingsDropdown);
