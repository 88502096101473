import { useState } from 'react';

import {
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { useFillsAPI, useIsTradePage, usePaginationFetch } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import TableLoader from 'components/TableLoader';

import { StyledTable, TableContainer } from '../styles';
import { GroupedOrderFillsRow } from './OrdersHistoryRow';
import { useAppContext } from 'contexts/AppContext';

import { Markets, OrderStatus, QueryKeys } from 'enums';
import { Order } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const filterOrders = ({
  _orders,
  showAllMarkets,
  selectedMarketId,
  isTradePage,
}: {
  _orders: Order[] | undefined;
  showAllMarkets?: boolean;
  selectedMarketId?: string;
  isTradePage?: boolean;
}) => {
  let fills = _orders ? [..._orders] : [];

  // If we are on the trade page and we don't want to show all markets
  if (isTradePage && !showAllMarkets) {
    fills = fills.filter(
      fill => fill.marketID === selectedMarketId ?? Markets.BTC_USD,
    );
  }

  return fills;
};

interface Props {
  includePagination?: boolean;
  showLabelOnTableCell?: boolean;
  showTableHead?: boolean;
  showFilterOptions?: boolean;
}

const OrderHistoryTable = ({
  includePagination,
  showLabelOnTableCell = false,
}: Props) => {
  const { isOnboarding } = useExchangeAPI();
  const {
    store: {
      account: { _orders, frontendSecrets },
      appState: { showAllMarkets },
      markets: { selectedMarketId },
    },
  } = useAppContext();
  const { fetchFillsForOrder } = useFillsAPI();
  const { fetchPrivateOrders } = useOrdersAPI();
  const isTradePage = useIsTradePage();
  const { t } = useTranslation();
  const [expandedRowId, setExpandedRowId] = useState<string>();

  const { isLoading: areOrderFillsLoading, data: expandedOrderFills } =
    useQuery(
      [QueryKeys.OrderFills, expandedRowId],
      async () => {
        if (!expandedRowId) return undefined;
        return await fetchFillsForOrder(expandedRowId);
      },
      { enabled: !!expandedRowId },
    );

  const {
    isLoading: areOrdersLoading,
    data: orders,
    setPagination,
    pagination,
  } = usePaginationFetch<Order>({
    fetchFn: fetchPrivateOrders,
    socketSource: _orders,
    allowNewItemsFromSocket: !includePagination,
    initialPagination: { page: 0, limit: 10 },
    currentAuthenticatedWallet: frontendSecrets?.profile?.wallet,
    requestQueryParams: {
      status: [OrderStatus.CLOSED, OrderStatus.OPEN, OrderStatus.CANCELED],
    },
    isOnboarding,
    queryKey: QueryKeys.Orders,
    disableRefetchInterval: !includePagination,
    includePagination,
  });

  if (areOrdersLoading || !frontendSecrets) {
    return (
      <TableContainer>
        <StyledTable>
          <TableBody>
            {!frontendSecrets ? (
              <TableRow>
                <TableCell colSpan={100} className="no-items">
                  {t('connectYourWalletToStartTrading')}
                </TableCell>
              </TableRow>
            ) : (
              <TableLoader />
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
    );
  }

  const filteredOrders = filterOrders({
    _orders: orders,
    showAllMarkets,
    selectedMarketId,
    isTradePage,
  }).filter(order => order.totalFilledSize > 0);

  // if we filter out all the orders then fetch more by modifying limit
  if (orders.length > 0 && filteredOrders.length === 0) {
    if (pagination.limit !== 25) {
      setPagination(prev => ({ ...prev, limit: 25, page: 0 }));
    }
  }

  const resultsFound = filteredOrders.length > 0;

  const toggleExpandedRow = (id: string) => {
    if (id === expandedRowId) setExpandedRowId(undefined);
    else setExpandedRowId(id);
  };

  return (
    <>
      <TableContainer useScroll={!includePagination}>
        <StyledTable stickyHeader>
          <TableBody>
            {resultsFound ? (
              filteredOrders.map(item => {
                const key = `${item.id}`;
                return (
                  <GroupedOrderFillsRow
                    order={item}
                    key={key}
                    onRowClick={toggleExpandedRow}
                    showFills={expandedRowId === item.id}
                    areOrderFillsLoading={areOrderFillsLoading}
                    orderFills={expandedOrderFills}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={100} className="no-items">
                  You have no valid recent order history.
                </td>
              </tr>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
};

export default observer(OrderHistoryTable);
