import { useEffect } from 'react';

import { useWeb3React } from '@web3-react/core';

import { sendGaEvent } from 'utils';

import { NetworkContextName } from 'constants/index';

import { injected, network } from 'connectors';
import { GaEventActions, GaEventCategories } from 'enums';

export function useInactiveListener(suppress: boolean = false) {
  const { active, error, activate } = useWeb3React();
  const { activate: activateNetwork } = useWeb3React(NetworkContextName);

  useEffect((): any => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        sendGaEvent(
          GaEventCategories.Button,
          GaEventActions.Click,
          'Connect wallet',
        );
        console.log("Handling 'connect' event");
        activate(injected);
        activateNetwork(network);
      };
      const handleChainChanged = (chainId: string | number) => {
        sendGaEvent(
          GaEventCategories.Button,
          GaEventActions.Click,
          'Connect wallet',
        );
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injected);
        activateNetwork(network);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          sendGaEvent(
            GaEventCategories.Button,
            GaEventActions.Click,
            'Connect wallet',
          );
          // logWallet(accounts[0]);
          activate(injected);
          activateNetwork(network);
        }
      };

      ethereum.on('connect', handleConnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
  }, [active, error, suppress, activate]);
}
