import AppStore from './app';
import { LS_SAVED_WALLETS } from 'constants/localStorageKeys';
import { TSavedWallet } from 'enums/savedWallet';

import { ConnectorNames } from 'enums';
import { makeAutoObservable, observable, action } from 'mobx';

const getSavedWalletsFromLocalStorage = (): TSavedWallet[] => {
  const transactions = localStorage.getItem(LS_SAVED_WALLETS);
  return transactions ? JSON.parse(transactions) : [];
};

const MAX_LENGTH = 10;

type TPartialWallet = Pick<
  TSavedWallet,
  'address' | 'connectorName' | 'socialLoginOptions' | 'image' | 'vaultAddress'
>;

// Wallet Identifier to be used as a key
export const constructWalletIdentifier = (
  address: string,
  vaultAddress?: string,
) => {
  return (
    address.toLowerCase() + (vaultAddress ? vaultAddress?.toLowerCase() : '')
  );
};

export default class SavedWalletStore {
  savedWallets: TSavedWallet[] = getSavedWalletsFromLocalStorage();
  cachedVaultAddress?: string;

  constructor(store: AppStore) {
    makeAutoObservable(this, {
      savedWallets: observable,
      removeFromSavedWallets: action.bound,
      handleAccountActivation: action.bound,
      updateSavedWallet: action.bound,
      getSavedWallet: action.bound,
      cleanup: action.bound,
      cacheVaultAddress: action.bound,
    });

    // Listen to Account changes and save them appropriately
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', accounts => {
        if (!accounts[0]) return;
        this.handleAccountActivation({
          address: accounts[0],
          connectorName: ConnectorNames.Injected,
        });
      });
    }
  }

  syncAndSaveToLocalStorage(walletsToSave: TSavedWallet[]): void {
    const savedWalletsWithAddressesLowerCased = walletsToSave.map(wallet => ({
      ...wallet,
      address: wallet.address.toLowerCase(),
    }));

    this.savedWallets = savedWalletsWithAddressesLowerCased;
    localStorage.setItem(
      LS_SAVED_WALLETS,
      JSON.stringify(savedWalletsWithAddressesLowerCased),
    );
  }

  removeFromSavedWallets(id: string): void {
    const updatedWalletArr = this.savedWallets.filter(
      wallet => wallet.id !== id,
    );

    this.syncAndSaveToLocalStorage(updatedWalletArr);
  }

  handleAccountActivation(partialWallet: TPartialWallet | undefined): void {
    if (!partialWallet) return;

    this.addOrUpdateWallet(partialWallet);

    // Override if provided
    if (partialWallet.vaultAddress) {
      this.cachedVaultAddress = partialWallet.vaultAddress;
    }
  }

  addOrUpdateWallet(requiredConfig: TPartialWallet) {
    const walletId = constructWalletIdentifier(
      requiredConfig.address,
      requiredConfig.vaultAddress,
    );

    const isWalletAlreadySaved = this.savedWallets.some(
      wallet => wallet.id === walletId,
    );

    if (isWalletAlreadySaved) {
      // If already saved, just update the connectorName and socialAccount, as user can login on the same wallet with another method
      this.updateSavedWallet(walletId, {
        connectorName: requiredConfig.connectorName,
        socialLoginOptions: requiredConfig.socialLoginOptions,
      });
    } else {
      this.addToSavedWallets(requiredConfig);
    }

    return walletId;
  }

  addToSavedWallets(config: TPartialWallet) {
    if (this.savedWallets.length >= MAX_LENGTH) {
      this.removeOldestWallet();
    }
    const walletToAdd: TSavedWallet = {
      ...config,
      id: constructWalletIdentifier(config.address, config.vaultAddress),
      name: `Wallet ${this.savedWallets.length + 1}`,
      emoji: '🔐',
      updatedAt: new Date().getTime() * 1000,
    };

    if (config.vaultAddress) {
      walletToAdd.vaultAddress = config.vaultAddress.toLowerCase();
    }

    // Add to savedWallets with default name and emoji
    const updatedWalletArr = [...this.savedWallets, walletToAdd];

    this.syncAndSaveToLocalStorage(updatedWalletArr);
  }

  updateSavedWallet(
    id: string,
    data: Partial<Omit<TSavedWallet, 'address'>>,
  ): void {
    const updatedWalletArr = this.savedWallets.map(wallet => {
      if (wallet.id === id) {
        return { ...wallet, ...data };
      }
      return wallet;
    });

    this.syncAndSaveToLocalStorage(updatedWalletArr);
  }

  getSavedWallet(address: string, vaultAddress?: string) {
    return this.savedWallets.find(
      i => i.id === constructWalletIdentifier(address, vaultAddress),
    );
  }

  removeOldestWallet() {
    const wallets = this.savedWallets;

    // Find the index of the oldest wallet
    let oldestIndex = 0;
    for (let i = 1; i < wallets.length; i++) {
      if (wallets[i].updatedAt < wallets[oldestIndex].updatedAt) {
        oldestIndex = i;
      }
    }

    // Remove the oldest wallet from the array
    const updatedWallets = [...wallets];
    updatedWallets.splice(oldestIndex, 1);

    this.syncAndSaveToLocalStorage(updatedWallets);
  }

  /**
   * Caches the provided vault address to be retrieved later when signing to verify.
   * The cached vault address is removed when the wallet is disconnected.
   *
   * @param {string} vaultAddress - The vault address to be cached.
   * @returns {void}
   */
  cacheVaultAddress(vaultAddress: string): void {
    this.cachedVaultAddress = vaultAddress;
  }

  // Run this on disconnect to clean any local config
  cleanup() {
    this.cachedVaultAddress = undefined;
  }
}
