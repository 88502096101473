import { useState } from 'react';

import { TableCell, TableRow, Grid } from '@material-ui/core';

import { getCurrencyFromTradingPair } from 'utils';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import useModal from 'hooks/useModal';
import { useSwitchMarket } from 'hooks/useSwitchMarket';

import { FormattedNumber, Icon } from 'components';
import Badge from 'components/Badge';
import Button from 'components/Button/button';
import CollapseIcon from 'components/CollapseIcon';

import { calculateLiquidationPrice } from '../../../utils/position';
import LabelAndValueCell from '../components/LabelAndValueCell';
import MarketLogoWithTradeSide from '../components/MarketLogoWithTradeSide';
import { CollapsibleTableRow, StyledTableCell } from '../styles';
import ClosePositionModal from './ClosePositionModal';
import SharePnlModal from './SharePnlModal';
import StopLossTakeProfitCell from './stopLossTakeProfitCell';
import shareIcon from 'assets/icons/share.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { Row } from 'theme/globalStyledComponents/row';

import { Position } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const calculateUnrealisedPnlPercentage = (
  unrealizedPnl: number,
  avgEntryPrice: number,
  positionSize: number,
) => (unrealizedPnl / (avgEntryPrice * positionSize)) * 100;

export interface PositionsRowProps {
  index: number;
  position: Position;
  showLabels: boolean;
  collapsible?: boolean;
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
}
const BasePositionsRow = observer(
  ({
    index,
    position,
    showLabels,
    collapsible,
    isCollapsed = true,
    toggleCollapse,
  }: PositionsRowProps) => {
    const {
      store: { account: accountStore, markets: marketsStore },
    } = useAppContext();
    const { switchMarketById } = useSwitchMarket();
    const modal = useModal();
    const { t } = useTranslation();

    const handleClosePositionClick = () => {
      modal.present(
        <ClosePositionModal
          currency={getCurrencyFromTradingPair(position.marketID)}
          initialPrice={null}
          position={position}
        />,
        Modals.closePositionModal,
      );
    };

    const { marketID } = position;
    const maintenanceMargin = marketsStore.getById(marketID)?.maintenanceMargin;

    const handleSetSelectedById = () => {
      switchMarketById(marketID);
    };

    const openSharePnlModal = () => {
      modal.present(
        <SharePnlModal marketId={marketID} />,
        Modals.sharePnlModal,
      );
    };

    const currency = getCurrencyFromTradingPair(marketID);
    const decimalPlaces = useGetDecimalPlaces(marketID);
    const isUnrealisedPnlNegative = position.unrealizedPnl < 0;

    if (collapsible && toggleCollapse)
      return (
        <CollapsibleTableRow
          isCollapsed={isCollapsed}
          onClick={toggleCollapse}
          data-cy={`position-${marketID}`}
        >
          <TableCell>
            <MarketLogoWithTradeSide
              limitWidth
              onClick={e => {
                e.stopPropagation();
                handleSetSelectedById();
              }}
              marketID={position.marketID}
              tradeSide={position.side}
            />
            {!isCollapsed ? (
              <LabelAndValueCell
                showLabel={showLabels}
                limitWidth
                isCell={false}
                label={t('fairPrice')}
                value={
                  <FormattedNumber
                    value={position.fairPrice}
                    decimalScale={decimalPlaces.price}
                  />
                }
                className="ml-32 mt-10"
              />
            ) : null}
          </TableCell>

          <TableCell>
            <LabelAndValueCell
              showLabel={showLabels}
              limitWidth
              isCell={false}
              label={t('positionSize')}
              value={
                <FormattedNumber
                  dataTestID={`history-position-size-${index}`}
                  value={position.size}
                  decimalScale={decimalPlaces.size}
                  suffix={` ${currency}`}
                  disableTooltip={true}
                  data-cy="size"
                />
              }
            />

            {!isCollapsed ? (
              <LabelAndValueCell
                showLabel={showLabels}
                limitWidth
                isCell={false}
                className="mt-10"
                label={t('liquidationPrice')}
                value={
                  <FormattedNumber
                    value={calculateLiquidationPrice(
                      accountStore._positions,
                      accountStore._accountStats?.balance,
                      position,
                      maintenanceMargin,
                    )}
                    decimalScale={decimalPlaces.price}
                    disableTooltip={true}
                  />
                }
              />
            ) : null}
          </TableCell>

          <TableCell>
            <LabelAndValueCell
              showLabel={showLabels}
              limitWidth
              isCell={false}
              label={t('valueUsd')}
              value={
                <FormattedNumber
                  value={position.notional}
                  prefix="$"
                  data-cy="notional"
                />
              }
            />

            {!isCollapsed ? (
              <LabelAndValueCell
                showLabel={showLabels}
                limitWidth
                isCell={false}
                className="mt-10"
                label={t('margin')}
                value={<FormattedNumber value={position.margin} />}
              />
            ) : null}
          </TableCell>

          <TableCell>
            <LabelAndValueCell
              showLabel={showLabels}
              limitWidth
              isCell={false}
              label={t('unrealisedPnl')}
              color={
                isUnrealisedPnlNegative
                  ? 'negativeForeground200'
                  : 'primaryGreenForeground100'
              }
              value={
                <>
                  <FormattedNumber
                    value={Math.abs(position.unrealizedPnl)}
                    prefix={isUnrealisedPnlNegative ? '-$' : '+$'}
                  />
                  <Badge
                    padding="3px 4px"
                    bgColor={
                      isUnrealisedPnlNegative
                        ? 'primaryRedBackground100'
                        : 'primaryGreenBackground100'
                    }
                    variant="BODY_XS"
                    responsiveVariant={{ default: 'BODY_S' }}
                  >
                    <FormattedNumber
                      value={calculateUnrealisedPnlPercentage(
                        position.unrealizedPnl,
                        position.avgEntryPrice,
                        position.size,
                      )}
                      prefix={isUnrealisedPnlNegative ? '' : '+'}
                      suffix="%"
                    />
                  </Badge>
                  <Icon
                    src={shareIcon}
                    alt="Share P&L"
                    className="cursor-pointer ml-5"
                    size="S"
                    onClick={e => {
                      e.stopPropagation();
                      openSharePnlModal();
                    }}
                  />
                </>
              }
            />

            {!isCollapsed ? (
              <LabelAndValueCell
                showLabel={showLabels}
                limitWidth
                className="mt-10"
                isCell={false}
                label={t('stopLossTakeProfit')}
                value={<StopLossTakeProfitCell marketId={marketID} />}
              />
            ) : null}
          </TableCell>

          <LabelAndValueCell
            showLabel={showLabels}
            verticalAlign="top"
            limitWidth
            label={t('avgOpenPrice')}
            value={
              <FormattedNumber
                value={position.avgEntryPrice}
                decimalScale={decimalPlaces.price}
              />
            }
          />

          <StyledTableCell verticalAlign="top">
            <Row gap={10} justify="flex-end">
              <CollapseIcon isCollapsed={isCollapsed} />
              <Button
                data-cy="close-position-button"
                data-testid={`cancel-position-btn-${index}`}
                sizeVariant="S"
                onClick={e => {
                  e.stopPropagation();
                  handleClosePositionClick();
                }}
                data-gtmid="button-positions-close"
                border="shadesBackground500"
                background="transparent"
              >
                Close
              </Button>
            </Row>
          </StyledTableCell>
        </CollapsibleTableRow>
      );

    return (
      <TableRow data-cy={`position-${marketID}`}>
        <TableCell onClick={handleSetSelectedById} className="cursor-pointer">
          <MarketLogoWithTradeSide
            marketID={position.marketID}
            tradeSide={position.side}
          />
        </TableCell>

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('positionSize')}
          value={
            <FormattedNumber
              dataTestID={`history-position-size-${index}`}
              value={position.size}
              decimalScale={decimalPlaces.size}
              suffix={` ${currency}`}
              disableTooltip={true}
              data-cy="size"
            />
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('valueUsd')}
          value={
            <FormattedNumber
              value={position.notional}
              prefix="$"
              data-cy="notional"
            />
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('avgOpenPrice')}
          value={
            <FormattedNumber
              value={position.avgEntryPrice}
              decimalScale={decimalPlaces.price}
            />
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('fairPrice')}
          value={
            <FormattedNumber
              value={position.fairPrice}
              decimalScale={decimalPlaces.price}
            />
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('liquidationPrice')}
          value={
            <FormattedNumber
              value={calculateLiquidationPrice(
                accountStore._positions,
                accountStore._accountStats?.balance,
                position,
                maintenanceMargin,
              )}
              decimalScale={decimalPlaces.price}
              disableTooltip={true}
            />
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('unrealisedPnl')}
          color={
            isUnrealisedPnlNegative
              ? 'negativeForeground200'
              : 'primaryGreenForeground100'
          }
          value={
            <>
              <FormattedNumber
                value={Math.abs(position.unrealizedPnl)}
                prefix={isUnrealisedPnlNegative ? '-$' : '+$'}
              />
              <Badge
                padding="3px 4px"
                bgColor={
                  isUnrealisedPnlNegative
                    ? 'primaryRedBackground100'
                    : 'primaryGreenBackground100'
                }
                variant="BODY_XS"
                responsiveVariant={{ default: 'BODY_S' }}
              >
                <FormattedNumber
                  value={calculateUnrealisedPnlPercentage(
                    position.unrealizedPnl,
                    position.avgEntryPrice,
                    position.size,
                  )}
                  prefix={isUnrealisedPnlNegative ? '' : '+'}
                  suffix="%"
                />
              </Badge>
              <Icon
                src={shareIcon}
                alt="Share P&L"
                className="cursor-pointer ml-5"
                size="S"
                onClick={openSharePnlModal}
              />
            </>
          }
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('margin')}
          value={<FormattedNumber value={position.margin} />}
        />

        <LabelAndValueCell
          showLabel={showLabels}
          label={t('stopLossTakeProfit')}
          value={<StopLossTakeProfitCell marketId={marketID} />}
          alignRight
        />

        <TableCell>
          <Row gap={10} justify="flex-end">
            <Button
              data-cy="close-position-button"
              data-testid={`cancel-position-btn-${index}`}
              sizeVariant="S"
              onClick={handleClosePositionClick}
              data-gtmid="button-positions-close"
              border="shadesBackground500"
              background="transparent"
            >
              Close
            </Button>
          </Row>
        </TableCell>
      </TableRow>
    );
  },
);

export default BasePositionsRow;

export const CollapsiblePositionRow = ({ ...props }: PositionsRowProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <BasePositionsRow
      {...props}
      collapsible
      isCollapsed={isCollapsed}
      toggleCollapse={() => setIsCollapsed(prev => !prev)}
    />
  );
};

export const PositionsRow = ({
  isRowCollapsible,
  ...rest
}: {
  isRowCollapsible: boolean;
} & PositionsRowProps) =>
  isRowCollapsible ? (
    <CollapsiblePositionRow {...rest} />
  ) : (
    <BasePositionsRow {...rest} />
  );
