import { memo } from 'react';

import { isBrandBfx } from 'utils/brand';

import { VerticalSeparator } from '../styles';
import BlastPoints from './BlastPoints';
import TokenAllocation from './TokenAllocation';
import { useAppContext } from 'contexts/AppContext';
import { routes } from 'pages/routes';
import { Row } from 'theme/globalStyledComponents/row';

import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

const AirdropActions = ({
  closeMobileSidebar,
}: {
  closeMobileSidebar?: () => void;
}) => {
  const { pathname } = useLocation();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const isActive = pathname
    .toLowerCase()
    .includes(routes.portfolio.airdrops.dashboard.getRoutePath().toLowerCase());

  const showVerticalSeparator = isBrandBfx && !!frontendSecrets;

  return (
    <Row gap={10}>
      {frontendSecrets ? (
        <TokenAllocation
          isActive={isActive}
          closeMobileSidebar={closeMobileSidebar}
        />
      ) : null}

      {showVerticalSeparator ? <VerticalSeparator /> : null}

      {isBrandBfx ? (
        <>
          <BlastPoints
            isActive={isActive}
            closeMobileSidebar={closeMobileSidebar}
          />
        </>
      ) : null}
    </Row>
  );
};
export default observer(AirdropActions);
