import { useCallback, useEffect, useState } from 'react';

import { BigNumber } from '@ethersproject/bignumber';
import {
  JsonRpcProvider,
  TransactionResponse,
  Web3Provider,
} from '@ethersproject/providers';
import { formatUnits, parseUnits } from '@ethersproject/units';

import { useWeb3React } from '@web3-react/core';

import { roundJsDecimalToString } from 'utils';
import { brandedSelect, isBrandBfx } from 'utils/brand';
import { getContract } from 'utils/contracts';

import { useVerifyChainId } from 'hooks';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';

import { RBX_DECIMALS } from 'constants/contracts';
import { NetworkContextName } from 'constants/networkContextName';
import {
  RBT,
  RBT__factory,
  TokenLock,
  TokenLock__factory,
} from 'contracts/types';
import { TokenLockAbi } from 'contracts/types/factories/TokenLock__factory';

import { config } from 'config';
import { NETWORK_URLS } from 'connectors';
import { Chain, ChainId } from 'enums';
import { ethers, constants, BigNumberish, Contract } from 'ethers';

const blastProvider = new JsonRpcProvider(
  {
    url: NETWORK_URLS[ChainId.BLAST_MAINNET],
  },
  {
    chainId: ChainId.BLAST_MAINNET,
    name: Chain.Blast,
  },
);

const ethProvider = new JsonRpcProvider(
  {
    url: NETWORK_URLS[ChainId.MAINNET],
  },
  {
    chainId: ChainId.MAINNET,
    name: Chain.ETH,
  },
);

const parseBigNumber = (number: BigNumber | undefined) => {
  if (!number) return 0;

  const res = parseFloat(formatUnits(number, RBX_DECIMALS));

  if (isNaN(res)) return 0;
  return res;
};

export function useTokenLockAPI() {
  const { library: libraryNetwork } =
    useWeb3React<Web3Provider>(NetworkContextName);

  const { account, library } = useActiveWeb3React();
  const { isCorrectChainId } = useVerifyChainId();

  const [RBXReadContract, setRBXReadContract] = useState<RBT | undefined>(
    undefined,
  );
  const [RBXSignContract, setRBXSignContract] = useState<RBT | undefined>(
    undefined,
  );

  const [LpRbxReadContract, setLpRbxReadContract] = useState<RBT | undefined>(
    undefined,
  );
  const [LpRbxSignContract, setLpRbxSignContract] = useState<RBT | undefined>(
    undefined,
  );
  const [areReadContractsSet, setAreReadContractsSet] =
    useState<boolean>(false);

  const [RbxTokenLockReadContract, setRbxTokenLockReadContract] = useState<
    TokenLock | undefined
  >(undefined);
  const [RbxTokenLockSignContract, setRbxTokenLockSignContract] = useState<
    TokenLock | undefined
  >(undefined);

  const [LpRbxTokenLockReadContract, setLpRbxTokenLockReadContract] = useState<
    TokenLock | undefined
  >(undefined);
  const [LpRbxTokenLockSignContract, setLpRbxTokenLockSignContract] = useState<
    TokenLock | undefined
  >(undefined);

  const [otherChainLpRbxReadContract, setOtherChainLpRbxReadContract] =
    useState<TokenLock | undefined>(undefined);
  const [otherChainRbxReadContract, setOtherChainRbxReadContract] = useState<
    TokenLock | undefined
  >(undefined);

  // Set read contracts
  useEffect(() => {
    (async () => {
      try {
        if (!libraryNetwork) {
          return;
        }
        setAreReadContractsSet(false);
        const RbxTokenLockReadContract = getContract<TokenLock>(
          TokenLock__factory,
          config.RbxTokenLockContractAddress,
          libraryNetwork,
        );
        setRbxTokenLockReadContract(RbxTokenLockReadContract);

        const LpRbxTokenLockReadContract = getContract<TokenLock>(
          TokenLock__factory,
          config.LpRbxTokenLockContractAddress,
          libraryNetwork,
        );
        setLpRbxTokenLockReadContract(LpRbxTokenLockReadContract);

        const otherChainProvider = brandedSelect({
          bfx: ethProvider,
          rabbitx: blastProvider,
        });

        // const otherChainLpRbxReadContract =
        setOtherChainLpRbxReadContract(
          new Contract(
            brandedSelect({
              bfx: config.LpRbxTokenLockEthContractAddress,
              rabbitx: config.LpRbxTokenLockBlastContractAddress,
            }),
            TokenLockAbi,
            otherChainProvider,
          ) as TokenLock,
        );

        setOtherChainRbxReadContract(
          new Contract(
            brandedSelect({
              bfx: config.RbxTokenLockEthContractAddress,
              rabbitx: config.RbxTokenLockBlastContractAddress,
            }),
            TokenLockAbi,
            otherChainProvider,
          ) as TokenLock,
        );

        // RBX contract
        const RBXReadContract = getContract<RBT>(
          RBT__factory,
          config.tokenAddresses.RBX,
          libraryNetwork,
        );
        setRBXReadContract(RBXReadContract);

        // RBX LP contract
        const LpRbxReadContract = getContract<RBT>(
          RBT__factory,
          config.tokenAddresses.RBX_LP,
          libraryNetwork,
        );
        setLpRbxReadContract(LpRbxReadContract);

        setAreReadContractsSet(true);
      } catch (e) {
        console.error(e);
        setAreReadContractsSet(false);
      }
    })();
  }, [libraryNetwork]);

  // Set sign contracts
  useEffect(() => {
    (async () => {
      try {
        if (!account || !library) return;
        const RbxTokenLockSignContract = getContract<TokenLock>(
          TokenLock__factory,
          config.RbxTokenLockContractAddress,
          library,
          account,
        );
        setRbxTokenLockSignContract(RbxTokenLockSignContract);

        const LpRbxTokenLockSignContract = getContract<TokenLock>(
          TokenLock__factory,
          config.LpRbxTokenLockContractAddress,
          library,
          account,
        );
        setLpRbxTokenLockSignContract(LpRbxTokenLockSignContract);

        const RBXSignContract = getContract<RBT>(
          RBT__factory,
          config.RBTAddress,
          library,
          account,
        );
        setRBXSignContract(RBXSignContract);

        const LpRbxSignContract = getContract<RBT>(
          RBT__factory,
          config.tokenAddresses.RBX_LP,
          library,
          account,
        );
        setLpRbxSignContract(LpRbxSignContract);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [account, library]);

  const getAccountLpRbxBalance = useCallback(async () => {
    try {
      if (!LpRbxReadContract || !account) {
        return constants.Zero;
      }

      return (await LpRbxReadContract.balanceOf(account)) as BigNumber;
    } catch (e: any) {
      console.error('Error while getting account LP RBX balance', e);
      throw e;
    }
  }, [LpRbxReadContract, account]);

  const approveRbxForContract = useCallback(
    async (contractAddress: string): Promise<TransactionResponse> => {
      try {
        if (!isCorrectChainId) {
          throw new Error(`Incorrect chain ID when approving RBX`);
        }
        if (!RBXSignContract) {
          throw new Error(`RBTSignContract is not defined`);
        }

        const tx = (await RBXSignContract.approve(
          contractAddress,
          constants.MaxUint256 as BigNumberish,
        )) as TransactionResponse;

        return tx;
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    },
    [RBXSignContract, isCorrectChainId],
  );

  const isRbxApprovedForContract = useCallback(
    async (contractAddress: string, amount?: number) => {
      try {
        if (!account) {
          throw new Error(`Account is not defined when checking RBX allowance`);
        }
        if (!RBXReadContract) {
          throw new Error(
            `RBXReadContract contract is not defined when checking rBX allowance`,
          );
        }
        const allowance: BigNumber = (await RBXReadContract.allowance(
          account,
          contractAddress,
        )) as BigNumber;
        // Check for custom amount allowance
        if (amount) {
          return allowance.gte(
            parseUnits(
              roundJsDecimalToString(amount, RBX_DECIMALS),
              RBX_DECIMALS,
            ),
          );
        }
        // Otherwise check for max allowance
        return constants.MaxUint256.div(2).lt(allowance);
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    },
    [RBXReadContract, account],
  );

  const approveLpRbxForContract = useCallback(
    async (contractAddress: string): Promise<TransactionResponse> => {
      try {
        if (!isCorrectChainId) {
          throw new Error(`Incorrect chain ID when approving RBX`);
        }
        if (!LpRbxSignContract) {
          throw new Error(`RlpSignContract is not defined`);
        }

        const tx = (await LpRbxSignContract.approve(
          contractAddress,
          constants.MaxUint256 as BigNumberish,
        )) as TransactionResponse;

        return tx;
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    },
    [LpRbxSignContract, isCorrectChainId],
  );

  const isLpRbxApprovedForContract = useCallback(
    async (contractAddress: string, amount?: number) => {
      try {
        if (!account) {
          throw new Error(`Account is not defined when checking RBX allowance`);
        }
        if (!LpRbxReadContract) {
          throw new Error(
            `RBXReadContract contract is not defined when checking rBX allowance`,
          );
        }
        const allowance: BigNumber = (await LpRbxReadContract.allowance(
          account,
          contractAddress,
        )) as BigNumber;
        // Check for custom amount allowance
        if (amount) {
          return allowance.gte(
            parseUnits(
              roundJsDecimalToString(amount, RBX_DECIMALS),
              RBX_DECIMALS,
            ),
          );
        }
        // Otherwise check for max allowance
        return constants.MaxUint256.div(2).lt(allowance);
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    },
    [LpRbxReadContract, account],
  );

  const getTotalLockedRbx = useCallback(async () => {
    try {
      if (!RbxTokenLockReadContract) {
        throw new Error('RbxTokenLockReadContract contract is not defined');
      }
      if (!otherChainRbxReadContract) {
        throw new Error('otherChainRbxReadContract contract is not defined');
      }

      const totalFromOtherChain = await otherChainRbxReadContract.totalLocked();
      const totalFromCurrentChain =
        await RbxTokenLockReadContract.totalLocked();

      return (
        parseBigNumber(totalFromCurrentChain) +
        parseBigNumber(totalFromOtherChain)
      );
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  }, [RbxTokenLockReadContract, otherChainRbxReadContract]);

  const getTotalLockedLpRbx = useCallback(async () => {
    try {
      if (!LpRbxTokenLockReadContract) {
        throw new Error('RbxTokenLockReadContract contract is not defined');
      }
      if (!otherChainLpRbxReadContract) {
        throw new Error('otherChainLpRbxReadContract contract is not defined');
      }

      const totalFromOtherChain =
        await otherChainLpRbxReadContract.totalLocked();
      const totalFromCurrentChain =
        await LpRbxTokenLockReadContract.totalLocked();

      return (
        parseBigNumber(totalFromCurrentChain) +
        parseBigNumber(totalFromOtherChain)
      );
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  }, [LpRbxTokenLockReadContract, otherChainLpRbxReadContract]);

  const getLocksRbx = useCallback(
    async (wallet: string) => {
      try {
        if (!RbxTokenLockReadContract) {
          throw new Error('RbxTokenLockReadContract contract is not defined');
        }

        const locks = await RbxTokenLockReadContract.locks(wallet);
        return {
          amount: locks[0],
          releaseTime: locks[1],
        };
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    },
    [RbxTokenLockReadContract],
  );

  const getLocksLpRbx = useCallback(
    async (wallet: string) => {
      try {
        if (!LpRbxTokenLockReadContract) {
          throw new Error('RbxTokenLockReadContract contract is not defined');
        }

        const locks = await LpRbxTokenLockReadContract.locks(wallet);
        return {
          amount: locks[0],
          releaseTime: locks[1],
        };
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    },
    [LpRbxTokenLockReadContract],
  );

  const getLockCutoffTimeRbx = useCallback(async () => {
    try {
      if (!RbxTokenLockReadContract) {
        throw new Error('RbxTokenLockReadContract contract is not defined');
      }

      return await RbxTokenLockReadContract.cutoffTime();
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  }, [RbxTokenLockReadContract]);

  const getLockCutoffTimeLpRbx = useCallback(async () => {
    try {
      if (!LpRbxTokenLockReadContract) {
        throw new Error('RbxTokenLockReadContract contract is not defined');
      }

      return await LpRbxTokenLockReadContract.cutoffTime();
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  }, [LpRbxTokenLockReadContract]);

  const lockTokensRbx = useCallback(
    async (amount: BigNumber) => {
      try {
        if (!isCorrectChainId) {
          throw new Error(`Incorrect chain ID when locking tokens.`);
        }
        if (!RbxTokenLockSignContract) {
          throw new Error('RbxTokenLockSignContract contract is not defined');
        }

        return await RbxTokenLockSignContract.lock(amount as ethers.BigNumber);
      } catch (e: any) {
        console.error('Error locking tokens', e);
        throw e;
      }
    },
    [RbxTokenLockSignContract, isCorrectChainId],
  );

  const lockTokensLpRbx = useCallback(
    async (amount: BigNumber) => {
      try {
        if (!isCorrectChainId) {
          throw new Error(`Incorrect chain ID when locking tokens.`);
        }
        if (!LpRbxTokenLockSignContract) {
          throw new Error('RbxTokenLockSignContract contract is not defined');
        }

        return await LpRbxTokenLockSignContract.lock(
          amount as ethers.BigNumber,
        );
      } catch (e: any) {
        console.error('Error locking tokens', e);
        throw e;
      }
    },
    [LpRbxTokenLockSignContract, isCorrectChainId],
  );

  const withdrawLockedTokensRbx = useCallback(async () => {
    try {
      if (!isCorrectChainId) {
        throw new Error(`Incorrect chain ID when withdrawing tokens.`);
      }
      if (!RbxTokenLockSignContract) {
        throw new Error('RbxTokenLockSignContract contract is not defined');
      }

      return await RbxTokenLockSignContract.withdraw();
    } catch (e: any) {
      console.error('Error withdrawing locked tokens', e);
      throw e;
    }
  }, [isCorrectChainId, RbxTokenLockSignContract]);

  const withdrawLockedTokensLpRbx = useCallback(async () => {
    try {
      if (!isCorrectChainId) {
        throw new Error(`Incorrect chain ID when withdrawing tokens.`);
      }
      if (!LpRbxTokenLockSignContract) {
        throw new Error('RbxTokenLockSignContract contract is not defined');
      }

      return await LpRbxTokenLockSignContract.withdraw();
    } catch (e: any) {
      console.error('Error withdrawing locked tokens', e);
      throw e;
    }
  }, [isCorrectChainId, LpRbxTokenLockSignContract]);

  return {
    areReadContractsSet,
    getAccountLpRbxBalance,
    withdrawLockedTokensRbx,
    getLockCutoffTimeRbx,
    isRbxApprovedForContract,
    approveRbxForContract,
    lockTokensRbx,
    getTotalLockedRbx,
    getLocksRbx,
    withdrawLockedTokensLpRbx,
    getLockCutoffTimeLpRbx,
    isLpRbxApprovedForContract,
    approveLpRbxForContract,
    lockTokensLpRbx,
    getTotalLockedLpRbx,
    getLocksLpRbx,
  } as const;
}

export type TokenLockAPI = ReturnType<typeof useTokenLockAPI>;
