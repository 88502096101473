import { FormattedNumber } from 'components';
import Badge from 'components/Badge';
import InfoTooltip from 'components/InfoTooltip';
import Loading from 'components/Loading';
import Text from 'components/Text';

import { StatStyled, VaultStatsStyled } from './styles';
import handsWithDollarOverlayIcon from 'assets/icons/hands-with-dollar-overlay.svg';
import lockedOverlayIcon from 'assets/icons/locked-overlay.svg';
import walletOverlayIcon from 'assets/icons/wallet-overlay.svg';
import { useAppContext } from 'contexts/AppContext';
import { FetchPlatformVaultPnlResponse } from 'queryHooks/platformVaults';

import { useTranslation } from 'react-i18next';

type Props = {
  lockedRbx?: number;
  liquidity: number | undefined;
  yourShares: number | undefined;
  vaultPnl?: FetchPlatformVaultPnlResponse;
  showVaultPnl?: boolean;
};
const VaultStats = ({
  lockedRbx,
  liquidity,
  yourShares,
  vaultPnl,
  showVaultPnl,
}: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();

  const { pnl, percentChange } = vaultPnl || {};
  const isPositivePnl = pnl && pnl > 0;
  const pnlPrefix = isPositivePnl ? '+$' : '$';

  return (
    <VaultStatsStyled>
      <Stat
        index={0}
        value={liquidity}
        bgOverlayIcon={lockedOverlayIcon}
        valuePrefix="$"
        label={t('vaultsLiquiditySupplied')}
        tooltipTitle={t('vaultsLiquiditySuppliedToolTip')}
      />
      {frontendSecrets?.jwt && (
        <>
          <Stat
            index={1}
            value={yourShares}
            bgOverlayIcon={walletOverlayIcon}
            valuePrefix="$"
            label={t('yourShares')}
            tooltipTitle={t('yourSharesToolTip')}
          />
          {showVaultPnl && (
            <>
              <Stat
                index={2}
                label={t('yourPnl')}
                value={pnl}
                bgOverlayIcon={handsWithDollarOverlayIcon}
                valuePrefix={pnlPrefix}
                tooltipTitle={t('yourSuppliedVaultsPnl')}
                percentChange={isPositivePnl ? percentChange : undefined}
              />
            </>
          )}
        </>
      )}
    </VaultStatsStyled>
  );
};

type StatProps = {
  index: number;
  label: string;
  value: number | undefined;
  bgOverlayIcon: string;
  valuePrefix?: string;
  valueSuffix?: string;
  tooltipTitle?: string;
  percentChange?: number;
};
const Stat = ({
  index,
  label,
  value,
  valuePrefix,
  valueSuffix,
  bgOverlayIcon,
  tooltipTitle,
  percentChange,
}: StatProps) => {
  return (
    <StatStyled index={index}>
      <Text variant="BODY_M" flexed gap={5} className="label">
        {label}
        {tooltipTitle ? (
          <InfoTooltip color="white" title={tooltipTitle} iconSize={16} />
        ) : null}
      </Text>
      {value === undefined ? (
        <Loading size={20} />
      ) : (
        <div className="value">
          <FormattedNumber
            variant="NUMBERS_XL"
            fontWeight="semiBold"
            value={value}
            prefix={valuePrefix}
            suffix={valueSuffix}
            className="value"
          />
          {percentChange !== undefined && (
            <Badge
              bgColor="positiveBackground100"
              color="positiveForeground200"
              variant="BODY_M"
              padding="4px"
            >
              <FormattedNumber value={percentChange} suffix="%" prefix="+" />
            </Badge>
          )}
        </div>
      )}

      <img className="overlay-bg" src={bgOverlayIcon} alt="" />
    </StatStyled>
  );
};

export default VaultStats;
