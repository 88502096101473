import { useState } from 'react';

import Text from 'components/Text';
import { TextVariant } from 'components/Text/variant';

import styled from 'styled-components';

const ButtonSwitchStyled = styled.div`
  background: ${({ theme }) => theme.colors.shadesBackground700};
  border-radius: 6px;
  padding: 6px;
  display: flex;

  .button {
    display: flex;
    padding: 8px 15px;
    background: transparent;
    border-radius: 6px;
    white-space: nowrap;
    cursor: pointer;
    flex: 1;
    justify-content: center;
    color: ${({ theme }) => theme.colors.shadesForeground200};

    :hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .button.selected {
    background: ${({ theme }) => theme.colors.shadesBackground900};
    color: ${({ theme }) => theme.colors.white};
  }

  .disabled-btn {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;
export interface ButtonSwitchOption<T> {
  text: T;
  selected: boolean;
  isDisabled?: boolean;
}

interface Props<T> {
  initialOptions: ButtonSwitchOption<T>[];
  onOptionSelect: (text: T) => void;
  displayTextTransform?: (text: T) => string;
  dataGtmIds?: string[];
  dataCyIds?: string[];
  textVariant?: keyof typeof TextVariant;
  className?: string;
}

export const ButtonSwitch = <T extends unknown>({
  initialOptions,
  onOptionSelect,
  displayTextTransform,
  dataGtmIds,
  dataCyIds,
  textVariant,
  ...restProps
}: Props<T>) => {
  const [options, setOptions] =
    useState<ButtonSwitchOption<T>[]>(initialOptions);

  const handleOptionSelect = (option: ButtonSwitchOption<T>, index: number) => {
    // If the option is already selected, do nothing
    onOptionSelect(option.text);
    if (option.selected || option.isDisabled) return;
    const resetOptions = [...initialOptions].map(el => ({
      ...el,
      selected: false,
    }));
    const newOptions = [...resetOptions];
    newOptions[index].selected = true;
    setOptions(newOptions);
  };

  return (
    <ButtonSwitchStyled {...restProps}>
      {options.map((el, index) => (
        <Text
          key={el.text as string}
          className={`button  ${el.selected ? 'selected' : ''} ${
            el.isDisabled ? ' disabled-btn' : ''
          }`}
          onClick={() => handleOptionSelect(el, index)}
          data-gtmid={dataGtmIds ? dataGtmIds[index] : undefined}
          data-cy={dataCyIds ? dataCyIds[index] : undefined}
          variant={textVariant || 'BODY_S'}
          fontWeight={el.selected ? 'semiBold' : 'medium'}
        >
          {displayTextTransform
            ? displayTextTransform(el.text)
            : (el.text as string)}
        </Text>
      ))}
    </ButtonSwitchStyled>
  );
};
