import { memo } from 'react';

import { timestampToLocalDate } from 'utils';

import CustomTooltip from 'components/RbxAreaChart/CustomTooltip';
import { TextVariant } from 'components/Text/variant';

import { theme } from 'theme/Theme';

import { ChartType } from '.';
import {
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  AreaChart as RechartsAreaChart,
} from 'recharts';
import styled from 'styled-components';

const getChartColor = (isPositiveChange: boolean) => {
  return isPositiveChange
    ? theme.colors.positiveForeground200
    : theme.colors.negativeForeground200;
};

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  /* margin: 0px 0px 0px -20px; */

  .recharts-cartesian-axis-line {
    stroke: ${({ theme }) => theme.colors.shadesBackground500};
  }
  .recharts-cartesian-axis-tick-line {
    stroke: ${({ theme }) => theme.colors.shadesBackground500};
  }

  .recharts-cartesian-axis-tick {
    margin: 0px 10px;
  }
`;

type Props = {
  type: ChartType;
  data: any;
};
const TVLAndPerformanceChart = ({ type, data }: Props) => {
  const isPositiveChange = true;
  const chartColor = getChartColor(isPositiveChange);
  const gradientId = isPositiveChange ? 'success-gradient' : 'danger-gradient';

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <StyledResponsiveContainer minHeight={300} minWidth={200}>
      <RechartsAreaChart data={data}>
        <defs>
          <linearGradient id="success-gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0DCB92" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#0DCB92" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="danger-gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FF475D" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#FF475D" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          style={{
            ...TextVariant['BODY_S'],
            fontWeight: theme.fontWeights.medium,
            fill: theme.colors.white,
            gap: '15px',
          }}
          dataKey={'time'}
          fill={theme.colors.white}
          color={theme.colors.white}
          tickFormatter={tick => timestampToLocalDate(tick)}
        />

        <YAxis
          dataKey={'value'}
          style={{
            ...TextVariant['BODY_S'],
            fontWeight: theme.fontWeights.medium,
            fill: theme.colors.white,
          }}
          tickFormatter={tick => `$${tick.toLocaleString()}`}
        />
        <Area
          strokeWidth={2}
          type="monotone"
          dataKey={'value'}
          stackId="1"
          stroke={chartColor}
          fill={`url(#${gradientId})`}
        />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />
      </RechartsAreaChart>
    </StyledResponsiveContainer>
  );
};

export default memo(TVLAndPerformanceChart);
