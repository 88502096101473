import { Dispatch, memo, useState } from 'react';

import { roundToNearestTick } from 'utils';
import { getMaxOrderCount } from 'utils/order';

import Badge from 'components/Badge';
import NumberInput from 'components/Inputs/NumberInput';
import Text from 'components/Text';

import { TCreateAndSetLadderProps } from '../BuySell';
import { OrderStateAction, OrderStateActionType } from '../orderStateReducer';
import USDPercentSwapper from './USDPercentSwapper';
import { Container } from './styles';
import { Column, Row } from 'theme/globalStyledComponents';
import SliderStyled, {
  SliderContainer,
} from 'theme/globalStyledComponents/slider';

import { TradeSide } from 'enums';
import { Market } from 'interfaces';
import { useTranslation } from 'react-i18next';

export enum PriceStepKind {
  USD = 'USD',
  PERCENTAGE = 'PERCENTAGE',
}

export const getOrderCountFromPercentage = (
  percentage: number,
  maxOrderCount: number,
) => {
  if (percentage < 100 / maxOrderCount) return 0;
  return roundToNearestTick((percentage * maxOrderCount) / 100, 1);
};

const getPercentageFromOrderCount = (
  orderCount: number,
  maxOrderCount: number,
) => {
  return (orderCount / maxOrderCount) * 100;
};

type Props = {
  price?: number | null;
  priceStep?: number | null;
  sizeScale?: number | null;
  orderCount?: number | null;
  priceStepKind: PriceStepKind;
  orderStateDispatch: Dispatch<OrderStateAction>;
  tradeSide: TradeSide;
  isCurrencyKindUSD: boolean;
  selectedMarket: Market | null;
};
const LadderInputs = ({
  price,
  priceStep,
  sizeScale,
  orderCount,
  orderStateDispatch,
  priceStepKind,
  tradeSide,
  selectedMarket,
  isCurrencyKindUSD,
}: Props) => {
  const { t } = useTranslation();
  const [tempOrderCount, setTempOrderCount] = useState<number | null>(
    orderCount || null,
  );

  const maxOrderCount = getMaxOrderCount(
    price,
    priceStep,
    priceStepKind,
    tradeSide,
  );

  const handleLadderPriceStepChange = (val: number | null) => {
    const updatedOrderCount = getMaxOrderCount(
      price,
      val,
      priceStepKind,
      tradeSide,
    );
    const newOrderCount = orderCount
      ? Math.min(orderCount, updatedOrderCount)
      : null;

    orderStateDispatch({
      payload: {
        ladder: {
          priceStep: val,
          orderCount: newOrderCount,
        },
        selectedMarket,
        isCurrencyKindUSD,
      },
      type: OrderStateActionType.UPDATE_LADDER_STATE,
    });
  };

  const handleLadderSizeScaleChange = (val: number | null) => {
    orderStateDispatch({
      payload: {
        ladder: {
          sizeScale: val,
        },
        selectedMarket,
        isCurrencyKindUSD,
      },
      type: OrderStateActionType.UPDATE_LADDER_STATE,
    });
  };

  const handleLadderOrderCountChange = (val: number | null) => {
    orderStateDispatch({
      payload: {
        ladder: {
          orderCount: val,
        },
        selectedMarket,
        isCurrencyKindUSD,
      },
      type: OrderStateActionType.UPDATE_LADDER_STATE,
    });
  };

  const handlePriceStepKindChange = (val: PriceStepKind) => {
    const updatedOrderCount = getMaxOrderCount(
      price,
      priceStep,
      val,
      tradeSide,
    );
    const newOrderCount = orderCount
      ? Math.min(orderCount, updatedOrderCount)
      : null;

    orderStateDispatch({
      payload: {
        ladder: {
          priceStepKind: val,
          orderCount: newOrderCount,
        },
        selectedMarket,
        isCurrencyKindUSD,
      },
      type: OrderStateActionType.UPDATE_LADDER_STATE,
    });
  };

  const sliderValue = getPercentageFromOrderCount(
    tempOrderCount ?? 0,
    maxOrderCount,
  );

  return (
    <Container>
      <Row gap={10}>
        <NumberInput
          label={t('priceStep')}
          value={priceStep}
          onChange={handleLadderPriceStepChange}
          showPresets={false}
          showValueApproximation={false}
          showSwapCurrency={false}
          rightSideComponent={
            <USDPercentSwapper
              selectedPriceKind={priceStepKind}
              onChangePriceStepKind={handlePriceStepKindChange}
            />
          }
          showSingleMaxButton={false}
        />
        <NumberInput
          tooltip={t('sizeScaleTooltip')}
          label={t('sizeScale')}
          value={sizeScale}
          onChange={handleLadderSizeScaleChange}
          showPresets={false}
          showSwapCurrency={false}
          showValueApproximation={false}
        />
      </Row>

      <Column align="baseline">
        <Text variant="BODY_S" color="shadesForeground200" flexed gap={5}>
          {t('orderCount')}
          <Badge
            padding="4px"
            bgColor="shadesBackground700"
            fontWeight="semiBold"
          >
            {tempOrderCount ?? 0}
          </Badge>
        </Text>

        <SliderContainer>
          <SliderStyled
            defaultValue={sliderValue}
            value={sliderValue}
            getAriaValueText={v =>
              `${getOrderCountFromPercentage(v as number, maxOrderCount)}`
            }
            valueLabelDisplay="auto"
            onChange={(e, v) => {
              setTempOrderCount(
                getOrderCountFromPercentage(v as number, maxOrderCount),
              );
            }}
            onChangeCommitted={(e, v) =>
              handleLadderOrderCountChange(
                getOrderCountFromPercentage(v as number, maxOrderCount),
              )
            }
            hideHoverDisplay
            marks={[0, 25, 50, 75, 100].map(i => ({
              label: [0, 100].includes(i)
                ? getOrderCountFromPercentage(i, maxOrderCount).toString()
                : '',
              value: i,
            }))}
          />
        </SliderContainer>
      </Column>
    </Container>
  );
};

export default memo(LadderInputs);
