import { savePendingTxToastIdsToLocalStorage } from './activeTransactions';
import { LS_VAULT_ACTIVITY } from 'constants/localStorageKeys';
import { SpotState } from 'contexts/ElixirContractsApiContext';
import { SpotStateType } from 'enums/spotState';
import { SpotType } from 'enums/spotType';
import { AmendType } from 'pages/Vaults/common/AmendLiquidityModal';
import PendingVaultActivityToast from 'pages/Vaults/common/AmendLiquidityModal/CustomPendingVaultActivityToast';
import { showRequestStatusNotification } from 'pages/Vaults/common/AmendLiquidityModal/notifications';
import {
  getPositionInQueue,
  getSpotStateType,
} from 'providers/elixirContractsProvider';

import { ContractTransaction } from 'ethers';
import { toast } from 'react-toastify';

// This can be used later to save vault activity in local storage to keep track of local deposits and withdrawals
const MAX_ACTIVITY_LENGTH = 10;

export const VAULT_ACTIVITY_UPDATE_EVENT = 'VAULT_ACTIVITY_UPDATE_EVENT';

const vaultActivityUpdateEvent = new Event(VAULT_ACTIVITY_UPDATE_EVENT);

export const generatePendingVaultToastId = (
  wallet: string,
  timestamp: number,
) => {
  return wallet + timestamp;
};

export type TSavedVaultActivity = ContractTransaction & {
  spotType: SpotType;
  amount: number;
  routerAddress: string;
  spotId: number | undefined;
  spotStateType: SpotStateType;
  timestamp: number;
  tokenPair: string;
};

const getVaultActivitiesFromLocalStorage = (): Record<
  string,
  {
    [key: string]: TSavedVaultActivity;
  }
> => {
  const activity = localStorage.getItem(LS_VAULT_ACTIVITY);
  return activity ? JSON.parse(activity) : {};
};

const saveActivitiesToLocalStorage = (transactionsObj: Record<string, any>) => {
  localStorage.setItem(LS_VAULT_ACTIVITY, JSON.stringify(transactionsObj));
  document.dispatchEvent(vaultActivityUpdateEvent); // dispatch event here, that is listened on Recent Activity Section
};

export const saveVaultTxToLocalStorage = (
  tx: ContractTransaction,
  spotType: SpotType,
  walletAddress: string,
  amount: number,
  spotId: number | undefined,
  routerAddress: string,
  spotStateType: SpotStateType,
  tokenPair: string,
) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const activitiesObj = getVaultActivitiesFromLocalStorage();

  const timestamp = new Date().getTime() * 1000;

  let addressActivities = activitiesObj[lowercasedWalletAddress] || {};

  const updatedAddressActivites = {
    ...addressActivities,
    [tx.hash]: {
      ...tx,
      spotType,
      amount,
      routerAddress,
      spotId,
      timestamp,
      spotStateType,
      tokenPair,
    } as TSavedVaultActivity,
  };

  const entries = Object.entries(updatedAddressActivites);

  entries.sort((a, b) => b[1].timestamp - a[1].timestamp);

  const latestMaxAllowedEntries = entries.slice(0, MAX_ACTIVITY_LENGTH);

  const latestMaxAllowedAddressActivities = Object.fromEntries(
    latestMaxAllowedEntries,
  );

  const txsObjUpdated = {
    ...activitiesObj,
    [lowercasedWalletAddress]: latestMaxAllowedAddressActivities,
  };

  saveActivitiesToLocalStorage(txsObjUpdated);
};

// Update spot on tx saved in local storage
export const updateSpotInLocalStorage = (
  txHash: string,
  walletAddress: string,
  spotStateType: SpotStateType,
  spotId?: number,
) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const transactionsObj = getVaultActivitiesFromLocalStorage();

  if (
    spotStateType ===
    transactionsObj[lowercasedWalletAddress][txHash].spotStateType
  )
    return;

  if (
    transactionsObj[lowercasedWalletAddress] &&
    transactionsObj[lowercasedWalletAddress][txHash]
  ) {
    transactionsObj[lowercasedWalletAddress][txHash] = {
      ...transactionsObj[lowercasedWalletAddress][txHash],
      spotStateType,
      spotId: spotId || transactionsObj[lowercasedWalletAddress][txHash].spotId, // Update spotId if passed
    }; // Remove the transaction

    saveActivitiesToLocalStorage(transactionsObj); // Update local storage
  }
};

export const removeVaultTxFromLocalStorage = (
  txHash: string,
  walletAddress: string,
) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const transactionsObj = getVaultActivitiesFromLocalStorage();

  if (
    transactionsObj[lowercasedWalletAddress] &&
    transactionsObj[lowercasedWalletAddress][txHash]
  ) {
    delete transactionsObj[lowercasedWalletAddress][txHash]; // Remove the transaction

    // If there are no more transactions for the given wallet address, remove the address key as well
    if (Object.keys(transactionsObj[lowercasedWalletAddress]).length === 0) {
      delete transactionsObj[lowercasedWalletAddress];
    }

    saveActivitiesToLocalStorage(transactionsObj); // Update local storage
  }
};

export const readVaultTxsFromLocalStorage = (walletAddress: string) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const transactionsObj = getVaultActivitiesFromLocalStorage();

  const addressTransactions = transactionsObj[lowercasedWalletAddress] ?? {};

  return (
    Object.keys(addressTransactions).map(key => addressTransactions[key]) ?? []
  );
};

const showToast = (
  amount: number,
  positionInQueue: number,
  txhash: string,
  spotStateType: SpotStateType,
  spotType: SpotType,
  tokenPair: string,
  timeStamp: number,
  wallet: string,
) => {
  const toastId = generatePendingVaultToastId(wallet, timeStamp);

  toast(
    <PendingVaultActivityToast
      positionInQueue={positionInQueue}
      amount={amount}
      txhash={txhash}
      type={spotType}
      spotStateType={spotStateType}
      tokenPair={tokenPair}
    />,
    {
      toastId,
      updateId: toastId,
      position: 'top-right',
      autoClose: false,
      pauseOnHover: false,
      draggable: true,
      closeOnClick: false,
      theme: 'dark',
    },
  );

  savePendingTxToastIdsToLocalStorage(toastId);

  return toastId;
};

export const getSavedVaultActivityFromSpotId = (
  walletAddress: string,
  spotId: number,
) => {
  const res = readVaultTxsFromLocalStorage(walletAddress);

  return res.find(activity => activity.spotId === spotId);
};

export const getVaultActivitiesForTokenPair = (
  walletAddress: string,
  tokenPair: string,
) => {
  const res = readVaultTxsFromLocalStorage(walletAddress);

  return res.filter(activity => activity.tokenPair === tokenPair);
};

export const handleNotificationsOnQueueUpdate = async (
  account: string,
  spotIds: number[] | undefined,
  txHashes: string[] | undefined,
  queuedUpto: number,
  stopListenerFn: () => void,
  toastType: 'show' | 'update' = 'show', // if type is update, then existing toasts are updated, else shown
  getSpotState: (txHash) => Promise<SpotState>,
  t,
) => {
  let activities = readVaultTxsFromLocalStorage(account);

  if (spotIds && spotIds.length > 0) {
    activities = activities.filter(
      activity => activity.spotId && spotIds.includes(activity.spotId),
    );
  }

  if (txHashes && txHashes.length > 0) {
    activities = activities.filter(activity =>
      txHashes.includes(activity.hash),
    );
  }

  let executionResults: boolean[] = [];

  for (const activity of activities) {
    if (activity.spotStateType === SpotStateType.Executed) {
      executionResults.push(true);
      continue;
    }

    let newSpotStateType: SpotStateType;
    let spotId = activity.spotId;
    let positionInQueue: number;

    // If spot
    if (spotId) {
      positionInQueue = getPositionInQueue(spotId, queuedUpto);
      newSpotStateType = getSpotStateType(positionInQueue);
    } else {
      try {
        const spotState = await getSpotState(activity.hash);
        spotId = spotState.id;
        positionInQueue = getPositionInQueue(spotState.id, queuedUpto);
        newSpotStateType = getSpotStateType(positionInQueue);
      } catch (err) {
        continue;
      }
    }

    if (toastType === 'update') {
      toast.update(generatePendingVaultToastId(account, activity.timestamp), {
        render: (
          <PendingVaultActivityToast
            positionInQueue={positionInQueue}
            amount={activity.amount}
            txhash={activity.hash}
            type={activity.spotType}
            spotStateType={newSpotStateType}
            tokenPair={activity.tokenPair}
          />
        ),
      });
    } else {
      showToast(
        activity.amount,
        positionInQueue,
        activity.hash,
        newSpotStateType,
        activity.spotType,
        activity.tokenPair,
        activity.timestamp,
        account,
      );
    }

    updateSpotInLocalStorage(activity.hash, account, newSpotStateType, spotId);

    if (newSpotStateType === SpotStateType.Executed) {
      showRequestStatusNotification({
        type:
          activity.spotType === SpotType.Deposit
            ? AmendType.Stake
            : activity.spotType === SpotType.Withdraw
            ? AmendType.UnStake
            : undefined,
        spotStateType: newSpotStateType,
        amount: activity.amount,
        tokenPair: activity.tokenPair,
        t: t,
      });
      executionResults.push(true);
    }

    executionResults.push(false);
  }

  // If all are executed, stop the callback as there are no transactions in the queue to keep track of
  if (!executionResults.includes(false)) stopListenerFn();
};
