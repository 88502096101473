import React, { useEffect } from 'react';

import { brand } from 'utils/brand';

import Modal from 'components/Modal';

import { useAccount } from '../../hooks';
import useModal from '../../hooks/useModal';
import { useOrdersAPI } from '../../hooks/useOrdersAPI';
import { theme } from '../../theme/Theme';
import { Modals } from 'constants/modals';

import QRCode from 'react-qr-code';
import styled from 'styled-components';

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  gap: 24px;

  .header {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.shadesForeground100};
  }

  .desc {
    text-align: center;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.shadesForeground100};
  }
`;

const QRContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 24px 0;
`;

export interface QRCodeContent {
  wallet: string;
  randomSecret: string;
  refreshToken: string;
}

interface Props {
  content: QRCodeContent;
}

export const SyncWithDesktop = ({ content }: Props) => {
  const { fetchPrivateOrders } = useOrdersAPI();
  const modal = useModal();
  const { manualDisconnect } = useAccount();

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        // Check if token still alive, after sync with mobile it will be inactive, then we can close the modal
        await fetchPrivateOrders({
          queryParams: {
            p_page: 0,
            p_limit: 1,
          },
        });
      } catch (e) {
        manualDisconnect();
        modal.clear();
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Modal
      showHeader={true}
      title="Mobile App Login"
      size={'small'}
      name={Modals.syncWithDesktopModal}
    >
      <InfoContainer>
        <div className="header">Scan the QR code to login to your App</div>
        <div className="desc">
          After installing the {brand.appShortName}, you can log in with your
          mobile phone without installing a wallet. Please do not share your
          unique QR code with anyone else.
        </div>
      </InfoContainer>
      <QRContainer>
        {
          <QRCode
            size={256}
            style={{
              height: 'auto',
              maxWidth: '80%',
              width: '80%',
              padding: 8,
              borderRadius: 4,
              backgroundColor: theme.colors.white,
            }}
            value={JSON.stringify(content)}
            viewBox={`0 0 256 256`}
          />
        }
      </QRContainer>
    </Modal>
  );
};
