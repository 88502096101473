import { TextVariant } from 'components/Text/variant';

import { Column } from 'theme/globalStyledComponents/column';

import styled, { css } from 'styled-components';

type StatItemProps = {
  alignRight?: boolean;
  fullWidth?: boolean;
};
export const StyledStatItem = styled(Column)<StatItemProps>`
  gap: 2px;
  border-radius: 8px;
  padding: 10px;
  min-width: ${({ fullWidth }) => (fullWidth ? '180px' : '91.5px')};
  background: ${({ theme }) => theme.colors.shadesBackground700};

  ${({ alignRight }) =>
    alignRight &&
    css`
      align-items: flex-end;
      background: transparent;
    `};

  .gold-color {
    color: #fcfc03;
  }

  .value {
    font-size: ${TextVariant['NUMBERS_L'].fontSize};
    line-height: ${TextVariant['NUMBERS_L'].lineHeight};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;
