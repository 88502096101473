import { memo } from 'react';

import useWindowDimensions from 'hooks/useWindowDimensions';

import EmptyVaultCard, { EmptyVaultCardProps } from './EmptyVaultCard';
import { theme } from 'theme/Theme';

import styled from 'styled-components';

type GradientOverlayProps = { span: number };
const GradientOverlay = styled.div<GradientOverlayProps>`
  display: flex;

  width: 100%;
  gap: inherit;
  position: relative;
  grid-column: span ${({ span }) => span};

  .overlay {
    border-radius: 8px;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 3;
    top: 0;
    background: ${({ theme }) =>
      `linear-gradient(90deg,rgba(10, 14, 24, 0.4) 0%, ${theme.colors.shadesBackground900} 100%)`};
  }
`;

export const EmptyGridState = (
  props: Omit<EmptyVaultCardProps, 'displayActions'> & {
    onStartStakingUnstaking?: () => void;
    maxApy?: number;
  },
) => {
  const { width: windowWidth } = useWindowDimensions();

  const GAP = 10;
  // Calculating number of empty cards needed to fill first row (if windowWidth > theme.screenSizes.default, take into consideration, the sidewatchlist as well)
  const numberOfEmptyVaults = Math.floor(
    (windowWidth - GAP - (windowWidth >= theme.screenSizes.default ? 320 : 0)) /
      (375 + GAP),
  );

  // If isLoading, dont show the very first card, show all as empty card when a gradeitn on top
  if (props.isLoading) {
    return (
      <GradientOverlay span={numberOfEmptyVaults}>
        {new Array(numberOfEmptyVaults).fill(0).map(_el => (
          <EmptyVaultCard {...props} />
        ))}
        <div className="overlay" />
      </GradientOverlay>
    );
  }

  return (
    <>
      <EmptyVaultCard
        displayActions
        onCtaPress={props.onStartStakingUnstaking}
        maxApy={props.maxApy}
        {...props}
      />
      {numberOfEmptyVaults > 0 ? (
        <GradientOverlay span={numberOfEmptyVaults - 1}>
          {new Array(numberOfEmptyVaults - 1).fill(0).map(_el => (
            <EmptyVaultCard {...props} />
          ))}
          <div className="overlay" />
        </GradientOverlay>
      ) : null}
    </>
  );
};

export default memo(EmptyGridState);
