import { readUserSetting } from 'utils';

import { useVerifyChainId } from 'hooks';
import useModal from 'hooks/useModal';

import Button from 'components/Button/button';

import { Container } from './styles';
import downloadWhiteIcon from 'assets/icons/download-white.svg';
import withdrawGrayIcon from 'assets/icons/withdrawPrimary.svg';
import { SHOULD_DISABLE_DEPOSITS_WITHDRAWALS } from 'constants/general';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import DepositModal from 'pages/Trade/components/AccountStats/NewDepositModal';
import WithdrawModal from 'pages/Trade/components/AccountStats/WithdrawModal';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const DepositAndWithdrawButtons = () => {
  const {
    store: {
      account: { isVaultAccount },
    },
  } = useAppContext();
  const modal = useModal();
  const { t } = useTranslation();
  const { validateNetworkAndDoAction } = useVerifyChainId();

  const handleDeposit = () => {
    validateNetworkAndDoAction(() => {
      modal.present(<DepositModal />, Modals.depositModal);
    }, 'You can now deposit funds.');
  };

  const handleWithdraw = () => {
    modal.present(<WithdrawModal />, Modals.withdrawModal);
  };

  return (
    <Container>
      <Button
        colorVariant="primaryGreen"
        sizeVariant="M"
        block={true}
        onClick={handleDeposit}
        disabled={isVaultAccount || SHOULD_DISABLE_DEPOSITS_WITHDRAWALS}
        data-gtmid="button-deposit-4"
        brandSpecificProps={{
          rabbitx: {
            colorVariant: 'secondary',
            background: {
              default: 'primaryGreenBackground200',
              hovered: 'primaryGreenBackground100',
            },
            rightIcon: downloadWhiteIcon,
          },
          bfx: { colorVariant: 'bfxMain' },
        }}
      >
        {t('deposit')}
      </Button>
      <Button
        colorVariant="secondary"
        sizeVariant="M"
        block={true}
        onClick={handleWithdraw}
        data-gtmid="button-withdraw-4"
        background={{
          default: 'shadesBackground700',
          hovered: 'shadesBackground600',
        }}
        border="shadesBackground700"
        brandSpecificProps={{
          rabbitx: {
            rightIcon: withdrawGrayIcon,
          },
        }}
        disabled={SHOULD_DISABLE_DEPOSITS_WITHDRAWALS}
      >
        {t('withdraw')}
      </Button>
    </Container>
  );
};

export default observer(DepositAndWithdrawButtons);
