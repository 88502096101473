export enum ApiMappings {
  POSITION = 'POSITION',
  ORDER = 'ORDER',
  FILL = 'FILL',
  PROFILE_DATA = 'PROFILE_DATA',
  MARKET = 'MARKET',
  CREATE_ORDER_PARAMS = 'CREATE_ORDER_PARAMS',
  AMEND_ORDER_PARAMS = 'AMEND_ORDER_PARAMS',
  CANCEL_ORDER_PARAMS = 'CANCEL_ORDER_PARAMS',
  CHANGE_MARKET_LEVERAGE_PARAMS = 'CHANGE_MARKET_LEVERAGE_PARAMS',
  CHANGE_MARKET_LEVERAGE_RESPONSE = 'CHANGE_MARKET_LEVERAGE_RESPONSE',
  RECENT_TRADE = 'RECENT_TRADE',
  REFRESH_JWT_PARAMS = 'REFRESH_JWT_PARAMS',
  REFRESH_JWT_RESPONSE = 'REFRESH_JWT_RESPONSE',
  BALANCE_OPERATIONS = 'BALANCE_OPERATIONS',
  PROFILE_AIRDROP = 'PROFILE_AIRDROP',
  CLAIM_ALL_AIRDROPS_RESPONSE_MAPPING = 'CLAIM_ALL_AIRDROPS_RESPONSE_MAPPING',
  WITHDRAWAL_RESPONSE_MAPPING = 'WITHDRAWAL_RESPONSE_MAPPING',
  CREATE_SLTP_ORDER_PARAMS_MAPPING = 'CREATE_SLTP_ORDER_PARAMS_MAPPING',
  AMEND_SLTP_ORDER_PARAMS_MAPPING = 'AMEND_SLTP_ORDER_PARAMS_MAPPING',
  API_KEY = 'API_KEY',
  CREATE_API_KEY_PARAMS_MAPPING = 'CREATE_API_KEY_PARAMS_MAPPING',
  API_KEYS_LIST_RESPONSE_MAPPING = 'API_KEYS_LIST_RESPONSE_MAPPING',
  DELETE_API_KEY_PARAMS_MAPPING = 'DELETE_API_KEY_PARAMS_MAPPING',
  REFRESH_API_KEY_PARAMS_MAPPING = 'REFRESH_API_KEY_PARAMS_MAPPING',
  PORTFOLIO_STATS_MAPPING = 'PORTFOLIO_STATS_MAPPING',
  TIER_STATUS_MAPPING = 'TIER_STATUS_MAPPING',
  FUNDING_RATE_MAPPING = 'FUNDING_RATE_MAPPING',
  REFERRAL_MAPPING = 'REFERRAL_MAPPING',
  EDIT_REFERRAL_PARAMS_MAPPING = 'EDIT_REFERRAL_PARAMS_MAPPING',
  REFERRAL_LEADERBOARD_MAPPING = 'REFERRAL_LEADERBOARD_MAPPING',
  VAULT_TVL_CHART_MAPPING = 'VAULT_TVL_CHART_MAPPING',
  VAULTS_MAPPING = 'VAULTS_MAPPING',
  VAULTS_HOLDINGS_MAPPING = 'VAULTS_HOLDINGS_MAPPING',
  VAULTS_BALANCE_OPERATIONS = 'VAULTS_BALANCE_OPERATIONS',
  BLAST_POINTS = 'BLAST_POINTS',
  GAME_ASSETS_BLAST = 'GAME_ASSETS_BLAST',
  GAME_ASSETS_BLAST_LEADERBOARD = 'GAME_ASSETS_BLAST_LEADERBOARD',
  MARKET_ORDERBOOK = 'MARKET_ORDERBOOK',
  BFX_POINTS_BALANCE = 'BFX_POINTS_BALANCE',
}
