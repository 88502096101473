import { TableCell, TableRow } from '@material-ui/core';

import { brand } from 'utils/brand';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import { FormattedNumber } from 'components';
import Button from 'components/Button/button';
import Icon from 'components/Icon';
import IconPair from 'components/IconPair';

import { vaultManagerDetailsMap } from '../../../Pages/Vaults/TabPanels/common/VaultList/utils';
import packageCheckIcon from 'assets/icons/package-check.svg';
import clockIcon from 'assets/icons/pending.svg';
import downloadIcon from 'assets/icons/upload.svg';
import { VaultManager } from 'enums/vaultManager';
import { Row } from 'pages/Vaults/styles';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type Props = {
  vault: VaultWithContractInfo;
  pendingAmount: number;
  routerUsdtBalance: number;
  onClaim: (poolId: number, pendingAmount: number) => void;
  vaultManager: VaultManager;
  isClaiming: boolean;
};
const WithdrawalRow = ({
  vault,
  pendingAmount,
  routerUsdtBalance,
  onClaim,
  vaultManager,
  isClaiming,
}: Props) => {
  const { t } = useTranslation();
  const marketLogo = useGetMarketLogoFromMarketId(vault.tokenPair);

  const isClaimable = routerUsdtBalance >= pendingAmount;

  const { icon: managerIcon, label: managerLabel } =
    vaultManagerDetailsMap[vaultManager];
  return (
    <TableRow>
      <TableCell>
        <Row className="gap-5">
          <Icon src={downloadIcon} alt="stake" />
          {t('withdrawal')}
        </Row>
      </TableCell>
      <TableCell>
        <Row className="gap-10">
          {/* <img
            src={isWatched ? starFilledIcon : starOutlineIcon}
            alt="wathlist-status"
          /> */}
          <Row className="gap-5 items-center">
            <IconPair icons={[marketLogo, brand.tokenLogo]} size={16} />
            {vault.tokenPair}
          </Row>
        </Row>
      </TableCell>
      <TableCell>
        <Row className="gap-5">
          <Icon src={managerIcon} />
          {managerLabel}
        </Row>
      </TableCell>
      <TableCell>
        <Row className="gap-5">
          <Icon src={brand.tokenLogo} alt={brand.tokenTicker} />
          {brand.tokenTicker}
        </Row>
      </TableCell>
      <TableCell>-</TableCell>
      <TableCell>
        <FormattedNumber value={pendingAmount} prefix="$" decimalScale={2} />
      </TableCell>
      <TableCell>
        <Row className="gap-5">
          {isClaimable ? (
            <>
              <Icon src={packageCheckIcon} />
              {t('claimable')}
            </>
          ) : (
            <>
              <Icon src={clockIcon} />
              {t('pending')}
            </>
          )}
        </Row>
      </TableCell>

      <TableCell>-</TableCell>

      <TableCell align="right">
        <Button
          sizeVariant="S"
          colorVariant="primaryGreen"
          disabled={!isClaimable || isClaiming}
          onClick={() => onClaim(vault.productId, pendingAmount)}
        >
          {t('claim')}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default observer(WithdrawalRow);
