import btcLogo from 'assets/assetLogos/btc.svg';
import rbxLogo from 'assets/assetLogos/rbx.svg';
import defaultLogo from 'assets/assetLogos/usd.svg';
import usdtLogo from 'assets/assetLogos/usdt.svg';
import binanceLogo from 'assets/exchanges/binance-dark-rounded.svg';
import binanceIcon from 'assets/exchanges/binance.svg';
import bitfinexIcon from 'assets/exchanges/bitfinex.svg';
import coinbaseLogo from 'assets/exchanges/coinbase.svg';
import exchangesStackIcon from 'assets/exchanges/exhange-stack.svg';
import editPenGrayIcon from 'assets/icons/edit-pen.svg';
import errorCircle from 'assets/icons/error-circle.svg';
import arrowDown from 'assets/icons/market-down.svg';
import arrowDownMarket from 'assets/icons/market-down.svg';
import arrowUp from 'assets/icons/market-up.svg';
import arrowUpMarket from 'assets/icons/market-up.svg';
import masterCardIcon from 'assets/icons/mastercard.svg';
import appleIcon from 'assets/icons/socials/apple24.svg';
import googleIcon from 'assets/icons/socials/google24.svg';
import walletIcon from 'assets/icons/wallet-white.svg';

export const preloadedImagesSrcList: string[] = [
  defaultLogo,
  btcLogo,
  usdtLogo,
  rbxLogo,
  errorCircle, // for orderbook hovers
  editPenGrayIcon, // for orderbook hovers
  arrowDown,
  arrowUp,
  arrowDownMarket,
  arrowUpMarket,
  binanceLogo,
  coinbaseLogo,
  bitfinexIcon,
  exchangesStackIcon,
  binanceIcon,
  masterCardIcon,
  appleIcon,
  googleIcon,
  walletIcon,
];
