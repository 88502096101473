import styled from 'styled-components';

export const Container = styled.div`
  > .header {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;

    > h6 {
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      margin: 0;
      font-size: 24px;
      letter-spacing: -0.48px;

      @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
        font-size: 20px;
        letter-spacing: -0.4px;
      }
    }
  }
`;
