import { memo, useEffect, useState } from 'react';

import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';

import {
  bigNumberToFloat,
  isNumber,
  showNotification,
  verifyUserSession,
} from 'utils';

import {
  useActiveWeb3React,
  useRabbitContractsAPI,
  useVerifyChainId,
} from 'hooks';
import useModal from 'hooks/useModal';

import Button from 'components/Button';
import MigrateStrpInput from 'components/Inputs/MigrateStrpInput';
import Loading from 'components/Loading';

import Portfolio from '../../index';
import ConfirmSection from './ConfirmSection';
import Header from './Header';
import {
  ArrowDownContainer,
  CenterContent,
  Container,
  InputsContainer,
} from './styles';
import rbxIcon from 'assets/assetLogos/rbx.svg';
import strpIcon from 'assets/assetLogos/strp.svg';
import arrowDownIcon from 'assets/icons/arrow-down-white.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';

import { NotificationType } from 'enums';
import BigDecimalJS from 'js-big-decimal';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

export enum SwapSteps {
  APPROVE_STRP,
  APPROVING_STRP,
  SWAP,
  PENDING_TX_SIGN,
  PROCESSING_SWAP,
}

const getCurrentStep = (
  strpAmountToSwap: BigDecimalJS | null,
  strpAllowance: BigDecimalJS,
  isApprovingStrp: boolean,
  isPendingTxSign: boolean,
  isProcessingSwap: boolean,
): SwapSteps => {
  let step = SwapSteps.SWAP;
  // STRP is approved for the entered amount
  if (strpAmountToSwap && strpAllowance.compareTo(strpAmountToSwap) < 0) {
    step = SwapSteps.APPROVE_STRP;
  }
  if (isApprovingStrp) {
    step = SwapSteps.APPROVING_STRP;
  }
  if (isPendingTxSign) {
    step = SwapSteps.PENDING_TX_SIGN;
  }
  if (isProcessingSwap) {
    step = SwapSteps.PROCESSING_SWAP;
  }
  return step;
};

interface GetShouldDisableActionBtnProps {
  isLoading: boolean;
  isApprovingStrp: boolean;
  isPendingTxSign: boolean;
  swapAmount: BigDecimalJS | null;
  maxAmount: BigDecimalJS;
  isProcessingSwap: boolean;
}
const getShouldDisableActionBtn = ({
  isLoading,
  isApprovingStrp,
  isPendingTxSign,
  swapAmount,
  maxAmount,
  isProcessingSwap,
}: GetShouldDisableActionBtnProps) => {
  // Disable the action button if:
  return (
    // 1. The app is currently loading
    isLoading ||
    // 2. The tx is waiting to be signed
    isPendingTxSign ||
    // 3. The tx is currently being processed
    isProcessingSwap ||
    // 4. The user is currently approving STRP
    isApprovingStrp ||
    // 5. The user has entered a swap amount that is greater than the maximum amount
    (swapAmount && swapAmount.compareTo(maxAmount) > 0) ||
    // 6. The user has not entered a deposit amount
    !swapAmount
  );
};

const MigrateStrp = () => {
  const modal = useModal();
  const {
    getAccountSTRPBalance,
    getAccountRBTBalance,
    getStrpAllowanceForRbt,
    STRPDecimals,
    RBTDecimals,
    areReadContractsSet,
    isRbtApprovedForStrp,
    approveRbtForStrpContract,
    swapStrpToRbt,
  } = useRabbitContractsAPI();
  const { account, chainId } = useActiveWeb3React();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();

  const [isLoading, setIsLoading] = useState(true);
  const [strpBalance, setStrpBalance] = useState<BigNumber>(BigNumber.from(0));
  const [rbtBalance, setRbtBalance] = useState<BigNumber>(BigNumber.from(0));
  const [strpAmountToSwap, setStrpAmountToSwap] = useState<BigDecimalJS>(
    new BigDecimalJS(0),
  );

  const [isApprovingStrp, setIsApprovingStrp] = useState(false);
  const [isPendingTxSign, setIsPendingTxSign] = useState(false);
  const [isProcessingSwap, setIsProcessingSwap] = useState(false);
  const [strpAllowance, setStrpAllowance] = useState<BigNumber>(
    BigNumber.from(0),
  );

  const updateState = async () => {
    try {
      const [_strpAllowance, _strpBalance, _rbtBalance] = await Promise.all([
        getStrpAllowanceForRbt(),
        getAccountSTRPBalance(),
        getAccountRBTBalance(),
      ]);
      setStrpAllowance(_strpAllowance);
      setStrpBalance(_strpBalance);
      setRbtBalance(_rbtBalance);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Account or chain changed
  useEffect(() => {
    (async () => {
      try {
        if (!account || !areReadContractsSet) return;
        await updateState();
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [account, areReadContractsSet, chainId]);

  // Intervally fetch account STRP and RBX balance and STRP approval status
  useEffect(() => {
    if (!account || !areReadContractsSet) return;

    const interval = setInterval(async () => {
      await updateState();
    }, 8000);
    return () => clearInterval(interval);
  }, [account, areReadContractsSet]);

  // Proceed to next step based on current step
  const proceedToNextStep = async (step: SwapSteps) => {
    try {
      if (step === SwapSteps.APPROVE_STRP) {
        await approveSTRP();
      }
      if (step === SwapSteps.SWAP) {
        await handleConfirm();
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const resetState = async () => {
    setStrpAllowance(BigNumber.from(0));
    await updateState();
    setIsLoading(false);
    setIsApprovingStrp(false);
    setIsPendingTxSign(false);
    setIsProcessingSwap(false);
  };

  // Approve STRP
  const approveSTRP = async () => {
    try {
      if (!account) return;
      if (!(await validateNetworkAndSwitchIfRequired('Approve STRP again.'))) {
        return;
      }

      flushSync(() => {
        setIsPendingTxSign(true);
      });

      const tx = await approveRbtForStrpContract();
      flushSync(() => {
        setIsPendingTxSign(false);
        setIsApprovingStrp(true);
      });

      showNotification({
        title: t('approvingStrpTitle'),
        type: NotificationType.Info,
      });

      await tx.wait();

      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showNotification({
        title: t('strpApprovedTitle'),
        type: NotificationType.Positive,
      });

      return true;
    } catch (e: any) {
      setIsPendingTxSign(false);
      showNotification({
        title: t('rbxApprovalFailedTitle'),
        description: t('rbxApprovalFailedDescription', {
          errorMessage: e.message,
        }),
        type: NotificationType.Negative,
      });
      return false;
    } finally {
      await resetState();
    }
  };

  // Swap STRP to RBX and update state
  const handleConfirm = async () => {
    try {
      if (!account) return;
      if (!strpAmountToSwap) return;
      if (strpAmountToSwap.compareTo(new BigDecimalJS(0)) < 1) return;

      if (!(await validateNetworkAndSwitchIfRequired('Swap STRP again.'))) {
        return;
      }

      flushSync(() => {
        setIsPendingTxSign(true);
      });

      // Check if STRP is approved for the swap amount
      const isStrpApproved = await isRbtApprovedForStrp(
        parseUnits(strpAmountToSwap.getValue(), STRPDecimals),
      );
      if (!isStrpApproved) {
        const res = await approveSTRP();
        if (!res) {
          return;
        }
      }

      const amount: any = parseUnits(strpAmountToSwap.getValue(), STRPDecimals);

      flushSync(() => {
        setIsPendingTxSign(true);
      });

      const tx = await swapStrpToRbt(amount);
      flushSync(() => {
        setIsProcessingSwap(true);
        setIsPendingTxSign(false);
      });
      const notifTitle = t('swappingStrpToRbxTitle');
      const notifDescription = t('swappingStrpToRbxDescription');
      showNotification({
        title: notifTitle,
        description: notifDescription,
        type: NotificationType.Info,
      });

      const minedTx = await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }
      if (minedTx?.status !== 1) {
        throw new Error('Transaction reverted');
      }

      await resetState();
      showNotification({
        title: t('swapSuccessTitle'),
        description: t('swapSuccessDescription'),
        type: NotificationType.Positive,
      });
    } catch (error: any) {
      console.log(error);

      const notifTitle = t('swapFailedTitle');
      const notifDescription = t('swapFailedDescription', {
        errorMessage: error.message,
      });
      showNotification({
        title: notifTitle,
        description: notifDescription,
        type: NotificationType.Negative,
      });
    } finally {
      await resetState();
    }
  };

  const handleSwapAmountChange = (value: any) => {
    try {
      if (!value) {
        setStrpAmountToSwap(new BigDecimalJS(0));
      } else if (value instanceof BigDecimalJS) {
        setStrpAmountToSwap(value);
      } else if (isNumber(value)) {
        setStrpAmountToSwap(new BigDecimalJS(value));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const rbtValue =
    strpAmountToSwap !== null
      ? strpAmountToSwap?.multiply(new BigDecimalJS(10))
      : null;

  if (!frontendSecrets || isLoading) {
    return (
      <Portfolio>
        <Container>
          <CenterContent>
            <Header />
            <div className="mt-15">
              {!frontendSecrets ? (
                <Button
                  variant="primaryGreen"
                  size="extraLarge"
                  block
                  onClick={() => {
                    modal.present(<WalletModal />, Modals.walletModal);
                  }}
                >
                  {t('connectWallet')}
                </Button>
              ) : (
                <Loading />
              )}
            </div>
          </CenterContent>
        </Container>
      </Portfolio>
    );
  }

  const step = getCurrentStep(
    strpAmountToSwap,
    new BigDecimalJS(formatUnits(strpAllowance, STRPDecimals)),
    isApprovingStrp,
    isPendingTxSign,
    isProcessingSwap,
  );

  const shouldDisableActionButton = getShouldDisableActionBtn({
    isLoading,
    swapAmount: strpAmountToSwap,
    maxAmount: new BigDecimalJS(formatUnits(strpBalance, STRPDecimals)),
    isPendingTxSign,
    isApprovingStrp,
    isProcessingSwap,
  });

  return (
    <Portfolio>
      <Container>
        <CenterContent>
          <Header />
          <InputsContainer className="mt-15">
            <ArrowDownContainer>
              <img src={arrowDownIcon} alt="Arrow down" />
            </ArrowDownContainer>
            <MigrateStrpInput
              value={strpAmountToSwap.getValue()}
              currency="STRP"
              currencyIcon={strpIcon}
              onChange={handleSwapAmountChange}
              label={t('send')}
              currencyBalance={bigNumberToFloat(strpBalance, STRPDecimals)}
              maxValue={
                new BigDecimalJS(formatUnits(strpBalance, STRPDecimals))
              }
            />
            <div className="mt-15" />
            <MigrateStrpInput
              value={rbtValue?.getValue()}
              currency="RBX"
              currencyIcon={rbxIcon}
              label={t('receive')}
              showMax={false}
              disabled
              currencyBalance={bigNumberToFloat(rbtBalance, RBTDecimals)}
            />
          </InputsContainer>
          <ConfirmSection
            shouldDisableActionBtn={shouldDisableActionButton}
            proceedToNextStep={proceedToNextStep}
            isLoading={isLoading}
            step={step}
          />
        </CenterContent>
      </Container>
    </Portfolio>
  );
};

export default memo(MigrateStrp);
