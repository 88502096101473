import { useCallback, useEffect, useRef, useState } from 'react';

import { getCurrencyFromTradingPair } from 'utils';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { useGetMarketTitleFromMarketId } from 'hooks';
import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { Icon } from 'components';
import Badge from 'components/Badge';
import Text from 'components/Text';

import RewardsBadge from '../MarketBar/components/RewardsBadge';
import MarketSelectDropdown from './MarketSelectDropdown';
import {
  MarketContainer,
  MarketInfo,
  MarketInfoWrapper,
  MarketSelectStyled,
} from './styles';
import chevronDown from 'assets/icons/chevron-down-gray.svg';
import chevronUp from 'assets/icons/chevron-up-gray.svg';
import starBrightIcon from 'assets/icons/star-bright.svg';
import starGrayIcon from 'assets/icons/star-gray.svg';
import { useAppContext } from 'contexts/AppContext';
import {
  REWARD_BADGE_ENABLED_MARKETS,
  REWARD_MARKET_CONFIG,
} from 'pages/Trade';

import { Markets } from 'enums';
import { observer } from 'mobx-react';
import { useFetchUserSettings, useSaveSettings } from 'queryHooks';

const getMaxAllowedLeverage = (marketId: string) => {
  // @Todo: Add static max leveragees later, we are planning for 50x leverage on BTC
  return 20;
};

const MarketSelect = () => {
  const {
    store: {
      markets: { selectedMarketId },
      account: accountStore,
    },
  } = useAppContext();
  const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);

  const searchRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    setIsDropdownShown(false);
  }, []);

  useOnClickOutside(searchRef, handleClose);

  useKey(Key.Escape, handleClose);

  const modal = useModal();

  useEffect(() => {
    modal.setPresentingEffect(isDropdownShown);
  }, [modal, isDropdownShown]);

  const { data: settingsData } = useFetchUserSettings(
    accountStore.frontendSecrets?.jwt,
  );

  const { handleFavoriteMarket } = useSaveSettings();

  const selectedMarketLogo = useGetMarketLogoFromMarketId(selectedMarketId);
  const marketTitle = useGetMarketTitleFromMarketId(selectedMarketId);

  const isMarketFavorite = settingsData?.favoriteMarkets
    ? !!settingsData.favoriteMarkets[selectedMarketId]
    : false;

  const handleStarClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    handleFavoriteMarket(selectedMarketId);
  };

  const assetName = getCurrencyFromTradingPair(selectedMarketId);

  return (
    <MarketSelectStyled ref={searchRef} data-cy="market-select">
      <MarketContainer
        onClick={() => {
          setIsDropdownShown(prev => !prev);
        }}
        data-gtmid="dropdown-pair"
        isDropdownShown={isDropdownShown}
      >
        <Icon src={selectedMarketLogo} alt={`${assetName} Icon`} size="L" />
        <MarketInfoWrapper>
          <MarketInfo>
            <Text
              fontWeight="semiBold"
              variant="BODY_L"
              data-cy="selected-market-id"
              flexed
              noWrap
            >
              {accountStore.frontendSecrets?.jwt ? (
                <Icon
                  onClick={handleStarClick}
                  src={isMarketFavorite ? starBrightIcon : starGrayIcon}
                />
              ) : null}
              {selectedMarketId}
            </Text>
            <Text
              variant="BODY_M"
              data-cy="selected-market-id"
              color="shadesForeground200"
              flexed
            >
              {marketTitle ?? '-'}
              {REWARD_BADGE_ENABLED_MARKETS.includes(
                selectedMarketId as Markets,
              ) ? (
                <RewardsBadge
                  multiplier={REWARD_MARKET_CONFIG[selectedMarketId]}
                />
              ) : (
                <Badge
                  variant="BODY_S"
                  bgColor={'warningBackground400'}
                  color="secondaryGold300"
                  padding="2px 4px"
                >
                  {getMaxAllowedLeverage(selectedMarketId)}x
                </Badge>
              )}
            </Text>
          </MarketInfo>
          <Icon
            className="caret-icon ml-10"
            size="M"
            src={isDropdownShown ? chevronUp : chevronDown}
            alt="Caret icon"
          />
        </MarketInfoWrapper>
      </MarketContainer>
      {isDropdownShown ? (
        <MarketSelectDropdown
          onOptionSelect={() => {
            setIsDropdownShown(false);
          }}
          onDropdownClose={() => setIsDropdownShown(false)}
        />
      ) : null}
    </MarketSelectStyled>
  );
};

export default observer(MarketSelect);
