import { memo } from 'react';

import useModal from 'hooks/useModal';

import Button from 'components/Button';

import { ConnectWalletContainer } from '../components/Overview/styles';
import walletIcon from 'assets/icons/wallet.svg';
import { Modals } from 'constants/modals';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';

import { useTranslation } from 'react-i18next';

const Overview = () => {
  const modal = useModal();
  const { t } = useTranslation();

  return (
    <ConnectWalletContainer>
      <span className="header">{t('walletVerificationTitle')}</span>
      <span className="description">{t('walletVerificationDescription')}</span>
      <Button
        variant="secondaryOutline"
        size="medium"
        onClick={() => {
          modal.present(<WalletModal />, Modals.walletModal);
        }}
        data-gtmid="button-connect-wallet-2"
      >
        Connect
        <img src={walletIcon} alt="wallet" className="icon-right" />
      </Button>
    </ConnectWalletContainer>
  );
};

export default memo(Overview);
