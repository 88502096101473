import { CreateOrderParams } from './api';
import {
  roundDownToNearestTick,
  numToString,
  roundToNearestTick,
  getCurrencyFromTradingPair,
  USD_DECIMAL_SCALE,
  roundUpToNearestTick,
  roundToNearestTickAndPrettify,
} from './helpers';
import { prettyFormatNumber } from './math';
import { calculateLiquidationPrice } from './position';
import alertTriangleIcon from 'assets/icons/alert-triangle.svg';
import { MARKET_PRICE_SPREAD_THRESHOLD } from 'constants/general';
import { DecimalScale } from 'constants/marketMappings';
import {
  TCreateAndSetLadderProps,
  getTimeInForce,
} from 'pages/Trade/components/BuySell/BuySell';
import { PriceStepKind } from 'pages/Trade/components/BuySell/LadderInputs';
import { IOrderPrePlaceDetail } from 'pages/Trade/components/BuySell/OrderPrePlaceDetails';
import { OrderState } from 'pages/Trade/components/BuySell/orderStateReducer';

import { Markets, OrderType, TradeSide } from 'enums';
import { Position } from 'interfaces';
import bigDecimal from 'js-big-decimal';

export const hasPriceInput = (orderType: OrderType | undefined) =>
  orderType &&
  [OrderType.LIMIT, OrderType.ST0P_LIMIT, OrderType.LADDER].includes(orderType);

export const hasTriggerPriceInput = (orderType: OrderType) =>
  [OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(orderType);

export const MAX_ORDER_SIZES_BY_MARKET = {
  ['sats1000000'.toUpperCase()]: 50_000,
  ['yes'.toUpperCase()]: 5_000,
};

// 3.5% buffer to account for fees
export const MAX_ORDER_USD_VALUE_BUFFER_PERCENTAGE = 0.035;

export const getLongAndShortBuyingPower = (
  positions: Position[] = [],
  marketId: string | undefined,
  buyingPower: number = 0,
  price: number | null,
  assetMarketPrice: number | null,
  assetDecimalScale: DecimalScale,
  orderType: OrderType,
) => {
  const currentMarketPositions = positions.filter(p => p.marketID === marketId);

  const shortPosition = currentMarketPositions.find(
    p => p.side === TradeSide.SHORT,
  );

  const longPosition = currentMarketPositions.find(
    p => p.side === TradeSide.LONG,
  );

  const shortingPower = parseFloat(
    new bigDecimal(buyingPower ?? 0)
      .add(new bigDecimal(longPosition?.notional ?? 0))
      .getValue(),
  );

  const longingPower = parseFloat(
    new bigDecimal(buyingPower ?? 0)
      .add(new bigDecimal(shortPosition?.notional ?? 0))
      .getValue(),
  );

  const maxQuantity = getCryptoAmountInBigDecimalFromNotional(
    ([OrderType.MARKET, OrderType.STOP_MARKET].includes(orderType)
      ? assetMarketPrice
      : price) || 0,
    buyingPower,
    assetDecimalScale,
  );

  if (orderType !== OrderType.MARKET) {
    const floatMaxQuantity = parseFloat(maxQuantity.getValue());
    return {
      shortingPower: buyingPower,
      longingPower: buyingPower,
      longPosition,
      longMaxQuantity: floatMaxQuantity,
      shortMaxQuantity: floatMaxQuantity,
      shortPosition,
    };
  }

  return {
    shortingPower: shortingPower
      ? roundDownToNearestTick(shortingPower, 0.01)
      : 0,
    longingPower: longingPower ? roundDownToNearestTick(longingPower, 0.01) : 0,
    longPosition,
    longMaxQuantity: parseFloat(
      maxQuantity.add(new bigDecimal(shortPosition?.size ?? 0)).getValue(),
    ),
    shortMaxQuantity: parseFloat(
      maxQuantity.add(new bigDecimal(longPosition?.size ?? 0)).getValue(),
    ),
    shortPosition,
  };
};

/**
 * Returns the max order value minus fees
 * @param value - the value to subtract the margin percentage from
 * @param marginPercentage - the margin percentage to subtract from the value
 */
export const getMaxOrderUsdValueMinusFees = (
  value: number | null | undefined,
  marginPercentage = MAX_ORDER_USD_VALUE_BUFFER_PERCENTAGE,
) => {
  if (!value) {
    return null;
  }
  return value * (1 - marginPercentage);
};

export const checkOrderForErrors = ({
  orderConfig,
  decimalPlaces,
  assetName,
  maxOrderUsdValueMinusFees = 0,
  currencyKind,
  positions = [],
  marketId,
  isCurrencyKindUSD,
  orderSide,
  fairPrice,
}: {
  orderConfig: {
    price?: number | null;
    triggerPrice?: number | null;
    quantity?: number | null;
    value?: number | null;
    type?: OrderType;
    marketPrice?: number;
    ladder?: {
      priceStepKind?: PriceStepKind;
      priceStep?: number | null;
      sizeScale?: number | null;
      orderCount?: number | null;
    };
    ladderOrders?: CreateOrderParams[];
  };
  decimalPlaces: DecimalScale | undefined;
  assetName: string;
  maxOrderUsdValueMinusFees: number | null;
  currencyKind: string;
  positions: Position[] | undefined;
  marketId: string | undefined;
  isCurrencyKindUSD: boolean;
  ladderOrders?: CreateOrderParams[];
  orderSide?: TradeSide;
  fairPrice?: number | null;
}): {
  quantityError: {
    long: string | null;
    short: string | null;
  };
  priceError: string | null;
  triggerPriceError: string | null;
  ladder?: {
    priceStepError?: string | null;
    orderCountError?: string | null;
    sizeScaleError?: string | null;
  };
} => {
  if (!orderConfig?.type || !decimalPlaces || !assetName) {
    return {
      quantityError: { long: null, short: null },
      priceError: null,
      triggerPriceError: null,
    };
  }

  const { longingPower, shortingPower, longMaxQuantity, shortMaxQuantity } =
    getLongAndShortBuyingPower(
      positions,
      marketId,
      maxOrderUsdValueMinusFees ?? 0,
      orderConfig.price ?? 0,
      orderConfig.marketPrice ?? 0,
      decimalPlaces,
      orderConfig.type,
    );

  let quantityErrorMessage: string | null = null;

  let quantityLongErrorMessage: string | null = null;

  let quantityShortErrorMessage: string | null = null;

  const minOrderValue =
    decimalPlaces.minOrder *
    (orderConfig.type === OrderType.MARKET
      ? orderConfig.marketPrice ?? 0
      : orderConfig.price ?? orderConfig.marketPrice ?? 0);

  if (!orderConfig.quantity || orderConfig.quantity < decimalPlaces.minOrder) {
    quantityErrorMessage =
      currencyKind === 'USD'
        ? `Min order value : ${minOrderValue.toFixed(2)} USD`
        : `Min order size : ${decimalPlaces.minOrder} ${assetName}`;
  }

  const parsedValue =
    orderConfig.type === OrderType.LADDER && orderConfig.ladderOrders
      ? getLadderOrderValue(orderConfig.ladderOrders)
      : orderConfig.value;
  const parsedQuantity =
    orderConfig.type === OrderType.LADDER && orderConfig.ladderOrders
      ? getLadderOrderSize(orderConfig.ladderOrders, decimalPlaces.minOrder)
      : orderConfig.quantity;

  // Show error message if the quantity is less than the minimum order size
  // Show error message if the sidePower is less than the minimum order size
  if (isCurrencyKindUSD) {
    // Check for values

    if (longingPower < (parsedValue ?? 0) || longingPower < minOrderValue) {
      quantityLongErrorMessage = `Not Enough Margin`;
    }
    if (shortingPower < (parsedValue ?? 0) || shortingPower < minOrderValue) {
      quantityShortErrorMessage = `Not Enough Margin`;
    }
  } else {
    // Check for quantities
    if (
      longMaxQuantity < (parsedQuantity ?? 0) ||
      longMaxQuantity < decimalPlaces.minOrder
    ) {
      quantityLongErrorMessage = `Not Enough Margin`;
    }
    if (
      shortMaxQuantity < (parsedQuantity ?? 0) ||
      shortMaxQuantity < decimalPlaces.minOrder
    ) {
      quantityShortErrorMessage = `Not Enough Margin`;
    }
  }

  const maxOrderSize = MAX_ORDER_SIZES_BY_MARKET[assetName];
  if (orderConfig.quantity && maxOrderSize) {
    if (orderConfig.quantity > maxOrderSize) {
      quantityErrorMessage = `Max Order Size : ${maxOrderSize} ${assetName}`;
    }
  }

  // Show error message if the price is less than the minimum tick size
  let priceErrorMessage: string | null = hasPriceInput(orderConfig.type)
    ? !orderConfig.price
      ? 'Price is required'
      : null
    : null;

  if (
    hasPriceInput(orderConfig.type) &&
    orderConfig.value &&
    orderConfig.price
  ) {
    if (orderConfig.price < decimalPlaces.minTick)
      priceErrorMessage = `Minimum price is ${numToString(
        decimalPlaces.minTick,
      )} USD`;

    if (orderConfig.marketPrice && orderSide) {
      const MAX_LONG_ORDER_PRICE = roundDownToNearestTick(
        orderConfig.marketPrice * (1 + MARKET_PRICE_SPREAD_THRESHOLD),
        decimalPlaces.minTick,
      );
      const MIN_SHORT_ORDER_PRICE = roundDownToNearestTick(
        orderConfig.marketPrice * (1 - MARKET_PRICE_SPREAD_THRESHOLD),
        decimalPlaces.minTick,
      );
      if (
        orderSide === TradeSide.LONG &&
        orderConfig.price > MAX_LONG_ORDER_PRICE
      ) {
        priceErrorMessage = `Price should be ≤ ${prettyFormatNumber(
          MAX_LONG_ORDER_PRICE,
        )}`;
      }

      if (
        orderSide === TradeSide.SHORT &&
        orderConfig.price < MIN_SHORT_ORDER_PRICE
      ) {
        priceErrorMessage = `Price should be ≥ ${prettyFormatNumber(
          MIN_SHORT_ORDER_PRICE,
        )}`;
      }
    }
  }

  let triggerPriceErrorMessage: string | null = null;

  if (hasTriggerPriceInput(orderConfig.type) && orderConfig.triggerPrice) {
    if (orderConfig.triggerPrice < decimalPlaces.minTick) {
      triggerPriceErrorMessage = `Minimum trigger price is ${numToString(
        decimalPlaces.minTick,
      )} USD`;
    }
    if (fairPrice) {
      if (
        orderConfig.triggerPrice >= fairPrice &&
        orderSide === TradeSide.SHORT
      ) {
        triggerPriceErrorMessage = `Trigger price should be ≤ ${prettyFormatNumber(
          fairPrice - decimalPlaces.minTick,
        )}`;
      }

      if (
        orderConfig.triggerPrice <= fairPrice &&
        orderSide === TradeSide.LONG
      ) {
        triggerPriceErrorMessage = `Trigger price should be ≥ ${prettyFormatNumber(
          fairPrice + decimalPlaces.minTick,
        )}`;
      }
    }
  }

  const errors = {
    quantityError: {
      short: quantityShortErrorMessage || quantityErrorMessage,
      long: quantityLongErrorMessage || quantityErrorMessage,
    },
    priceError: priceErrorMessage,
    triggerPriceError: triggerPriceErrorMessage,
  };

  if (orderConfig.type === OrderType.LADDER) {
    let priceStepError = '';
    let orderCountError = '';
    let sizeScaleError = '';

    // Show error message if the price step not defined
    if (!orderConfig.ladder?.priceStep) {
      priceStepError = 'Price step is required';
    }

    // Show error message if the price step is less than the minimum tick size
    if (orderConfig.ladder?.priceStep && orderConfig.ladder.priceStepKind) {
      const parsedPriceStep = parsePriceStep(
        orderConfig.price!,
        orderConfig.ladder.priceStep,
        orderConfig.ladder.priceStepKind,
        orderSide!,
      );
      if (Math.abs(parsedPriceStep) < decimalPlaces.minTick) {
        if (orderConfig.ladder.priceStepKind === PriceStepKind.USD) {
          priceStepError = `Minimum price step is ${numToString(
            decimalPlaces.minTick,
          )} USD`;
        } else {
          // Remove trailing zeros, and round up
          const minPercentage = roundUpToNearestTick(
            (decimalPlaces.minTick / orderConfig.price!) * 100,
            0.00001,
          ).toString();
          priceStepError = `Minimum price step is ${minPercentage} %`;
        }
      }
    }

    // Show error message if the size scale not defined
    if (!orderConfig.ladder?.sizeScale) {
      sizeScaleError = 'Size scale is required';
    }

    // // Show error message if the size scale is less than the minimum order size
    // if (
    //   orderConfig.ladder?.sizeScale &&
    //   orderConfig.ladder?.sizeScale < decimalPlaces.minOrder
    // ) {
    //   sizeScaleError = `Minimum size scale is ${decimalPlaces.minOrder} ${assetName}`;
    // }

    // Show error message if the order count is less than 1
    if (!orderConfig.ladder?.orderCount) {
      orderCountError = 'Order count is required';
    }

    // if no errors are found for ladder, return the errors object
    if (!priceStepError && !sizeScaleError && !orderCountError) {
      return errors;
    }

    return {
      ...errors,
      ladder: {
        priceStepError,
        orderCountError,
        sizeScaleError,
      },
    };
  }

  return errors;
};

export const getCryptoAmountInBigDecimalFromNotional = (
  price: number,
  notional: number,
  decimalScale: DecimalScale,
) => {
  const bigPrice = new bigDecimal(price);
  const bigNotional = new bigDecimal(notional);

  if (!price || !notional) return new bigDecimal(0);

  const cryptoAmount = bigNotional.divide(bigPrice, decimalScale.size);

  return cryptoAmount;
};

// const getFirstOrderSize = (
//   totalMultiplier: number,
//   isCurrencyKindUSD: boolean,
//   quantity: number | null,
//   valueUsd: number | null,
//   priceStep: number,
//   price: number,
//   orderSizeMultipliers: number[],
// ) => {
//   if (isCurrencyKindUSD) {
//     const totalUSDMultiplier = orderSizeMultipliers.reduce(
//       (acc, orderSizeMultiplier, index) => {
//         const priceIncrement = price + index * priceStep;
//         if (priceIncrement >= 0) {
//           return acc + orderSizeMultiplier * priceIncrement;
//         } else {
//           return acc;
//         }
//       },
//       0,
//     );

//     return (valueUsd ?? 0) / totalUSDMultiplier;
//   } else {
//     return quantity ? quantity / totalMultiplier : 0;
//   }
// };

const getPositiveOnlyOrderCount = (
  price: number,
  priceStep: number,
  orderCount: number,
) => {
  let newOrderCount = 0;

  for (let i = 0; i < orderCount; i++) {
    const currentPrice = price + i * priceStep;

    if (currentPrice > 0) {
      newOrderCount += 1;
    } else {
      break;
    }
  }

  return newOrderCount;
};

export type CreateLadderParamsProps = {
  price: number | null;
  quantity: number | null;
  valueUsd: number | null;
  priceStep: number | null;
  priceStepKind: PriceStepKind;
  sizeScale: number | null;
  orderCount: number | null;
  tradeSide: TradeSide;
  minOrder: number;
  minTick: number;
  marketId: string | undefined;
  isCurrencyKindUSD: boolean;
  timeInForce: string;
  avoidZeroPrice?: boolean;
  paramFunc?: (params: CreateOrderParams) => void;
};

const parsePriceStep = (
  price: number,
  priceStep: number,
  priceStepKind: PriceStepKind,
  tradeSide: TradeSide,
) => {
  return (
    (tradeSide === TradeSide.SHORT ? +1 : -1) *
    (priceStepKind === PriceStepKind.USD
      ? priceStep
      : (price * priceStep) / 100)
  );
};
export const createLadderParams = ({
  price,
  quantity,
  valueUsd,
  priceStep,
  priceStepKind,
  sizeScale,
  orderCount,
  tradeSide,
  minOrder,
  minTick,
  marketId,
  isCurrencyKindUSD,
  timeInForce,
  paramFunc,
  avoidZeroPrice = false,
}: CreateLadderParamsProps) => {
  let paramsArray: CreateOrderParams[] = [];

  if (!orderCount || !price || !priceStep || !sizeScale || !marketId) return;

  // if (valueUsd !== undefined) {
  //   if (valueUsd! / price < minOrder) return undefined;
  // }

  if ((quantity ?? 0) < minOrder) return undefined;

  const parsedPriceStep = parsePriceStep(
    price,
    priceStep,
    priceStepKind,
    tradeSide,
  );

  const parsedOrderCount = avoidZeroPrice
    ? getPositiveOnlyOrderCount(price, parsedPriceStep, orderCount)
    : orderCount;

  if (parsedOrderCount === 1) {
    return [
      {
        price: price,
        marketID: marketId,
        size: quantity ? roundDownToNearestTick(quantity, minOrder) : 0,
        side: tradeSide,
        type: OrderType.LIMIT,
        timeInForce,
      },
    ];
  }

  let currentPrice = price; // Starting price

  // By multiplier we just mean what it needs to be multiplied to first order size to get the current order size,
  // similar to solving a linear equation
  let totalOrderSizeMultiplier = 0;

  let orderSizesMultipliers: number[] = [];

  for (let i = 0; i < parsedOrderCount; i++) {
    const currentOrderSizeMultiplier = Math.pow(sizeScale, i);
    totalOrderSizeMultiplier += currentOrderSizeMultiplier;
    orderSizesMultipliers.push(currentOrderSizeMultiplier);
  }

  const firstOrderSize =
    quantity || roundToNearestTick(valueUsd! / price, minOrder);

  orderSizesMultipliers.forEach(orderSizeMultiplier => {
    let orderSize = firstOrderSize * orderSizeMultiplier;

    const params: CreateOrderParams = {
      price: roundToNearestTick(currentPrice, minTick),
      marketID: marketId,
      size: roundDownToNearestTick(orderSize, minOrder),
      side: tradeSide,
      type: OrderType.LIMIT,
      timeInForce,
    };

    paramsArray.push(params);

    paramFunc?.(params); // Call the function with the params

    // Increase Price in case of short Order and decrease price in case of long order
    currentPrice = currentPrice + parsedPriceStep;
  });

  return paramsArray;
};

const getLadderOrderValue = (ladderOrders: CreateOrderParams[]) => {
  return roundToNearestTick(
    ladderOrders.reduce(
      (acc, order) => acc + (order.price > 0 ? order.size * order.price : 0),
      0,
    ),
    USD_DECIMAL_SCALE.minTick,
  );
};

const getLadderOrderSize = (
  ladderOrders: CreateOrderParams[],
  minOrder: number,
) => {
  return roundToNearestTick(
    ladderOrders.reduce(
      (acc, order) => acc + (order.price > 0 ? order.size : 0),
      0,
    ),
    minOrder,
  );
};

export const getPrePlaceData = (
  orderType: OrderType | undefined,
  ladderOrders: CreateOrderParams[],
  minOrder: number,
  minTick: number,
  _positions: Position[] | undefined,
  balance: number | undefined,
  prePosition: Position,
  marketMaintainanceMargin: number | undefined,
  marketId: string | undefined,
  marketOrderEstimation:
    | { priceImpact: number; avgPrice: number; maxPurchasableQuantity?: number }
    | undefined,
  quantity: number | undefined | null,
): IOrderPrePlaceDetail[] | undefined => {
  if (!orderType) return;

  if (orderType === OrderType.LADDER) {
    if (ladderOrders.length < 2 || !minOrder) return;
    const firstOrder = ladderOrders[0];
    const lastOrder = ladderOrders[ladderOrders.length - 1];

    const currency = getCurrencyFromTradingPair(firstOrder.marketID);

    const orderValue = getLadderOrderValue(ladderOrders);

    const orderSize = getLadderOrderSize(ladderOrders, minOrder);

    const firstOrderSize = roundToNearestTick(firstOrder.size, minOrder);
    const lastOrderSize = roundToNearestTick(lastOrder.size, minOrder);

    return [
      {
        label: 'firstOrder',
        value: `${prettyFormatNumber(
          firstOrderSize,
        )} ${currency} @ ${prettyFormatNumber(firstOrder.price)}`,
      },
      {
        label: 'lastOrder',
        value: `${prettyFormatNumber(
          lastOrderSize,
        )} ${currency} @ ${prettyFormatNumber(lastOrder.price)}`,
        valueColor: lastOrder.price <= 0 ? 'negativeForeground200' : undefined,
      },
      {
        label: 'totalValue',
        value: prettyFormatNumber(orderValue) + ' USD',
        valueColor: orderValue <= 0 ? 'negativeForeground200' : undefined,
      },

      {
        label: 'totalSize',
        value: prettyFormatNumber(orderSize) + ' ' + currency,
        valueColor: orderSize <= 0 ? 'negativeForeground200' : undefined,
      },
    ];
  }

  if (
    [
      OrderType.LIMIT,
      OrderType.MARKET,
      OrderType.ST0P_LIMIT,
      OrderType.STOP_MARKET,
    ].includes(orderType) &&
    prePosition.size &&
    prePosition.avgEntryPrice
  ) {
    const liquidationPrice = calculateLiquidationPrice(
      _positions,
      balance,
      prePosition,
      marketMaintainanceMargin,
    );

    const returnData: IOrderPrePlaceDetail[] = [];

    if (orderType === OrderType.MARKET && marketOrderEstimation) {
      returnData.push({
        label: 'expectedPrice',
        value:
          '$' +
          roundToNearestTickAndPrettify(
            marketOrderEstimation.avgPrice,
            minTick,
          ),
      });

      if (
        isSlippageAboveAllowed(
          marketId as Markets,
          marketOrderEstimation.priceImpact,
        )
      ) {
        returnData.push({
          label: 'priceImpact',
          value:
            roundToNearestTick(marketOrderEstimation.priceImpact, 0.01) + '%',
          valueColor: 'warningForeground300',
          labelColor: 'warningForeground300',
          labelLeftIcon: alertTriangleIcon,
        });
      }

      const maxPurchasableQuantity =
        marketOrderEstimation?.maxPurchasableQuantity;

      if (
        maxPurchasableQuantity &&
        quantity &&
        quantity > maxPurchasableQuantity
      ) {
        returnData.push({
          label: 'slippage',
          value:
            roundToNearestTick(
              ((quantity - maxPurchasableQuantity) / quantity) * 100,
              0.01,
            ) + '%',
          valueColor: 'warningForeground300',
          labelColor: 'warningForeground300',
          labelLeftIcon: alertTriangleIcon,
        });
      }
    }

    returnData.push({
      label: 'liquidationPrice',
      value: liquidationPrice
        ? '$' + roundToNearestTickAndPrettify(liquidationPrice, minTick)
        : '-',
    });

    return returnData.length === 0 ? undefined : returnData;
  }
};

export const getAllowedSlippageForMarket = (market: Markets) => {
  if ([Markets.BTC_USD, Markets.SOL_USD, Markets.ETH_USD].includes(market)) {
    return 0.5;
  }

  if (
    [
      Markets.PAC_USD,
      Markets.MAGA_USD,
      Markets.TRUMP_USD,
      Markets.MOG1000_USD,
      Markets.YES_USD,
      Markets.MOTHER_USD,
    ].includes(market)
  ) {
    return 3;
  }

  return 1;
};

export const isSlippageAboveAllowed = (
  market: Markets,
  priceImpact: number,
) => {
  return getAllowedSlippageForMarket(market) <= priceImpact;
};

export const getMarketOrderWarning = (market: Markets, priceImpact: number) => {
  const allowedPriceImpact = getAllowedSlippageForMarket(market);

  if (allowedPriceImpact <= priceImpact) {
    return `Warning - You may encounter a price impact of ≥${allowedPriceImpact}%`;
  }

  return '';
};

const MAX_ORDER_COUNT = 20;

export const getMaxOrderCount = (
  price: number | null | undefined,
  priceStep: number | null | undefined,
  priceStepKind: PriceStepKind,
  tradeSide: TradeSide,
) => {
  if (tradeSide === TradeSide.SHORT) return MAX_ORDER_COUNT;

  if (!price || !priceStep) return MAX_ORDER_COUNT;

  const parsedPriceStep = parsePriceStep(
    price,
    priceStep,
    priceStepKind,
    tradeSide,
  );

  let orderCount = 0;
  let currentPrice = price;

  while (currentPrice > 0 && orderCount < MAX_ORDER_COUNT) {
    orderCount++;
    currentPrice += parsedPriceStep;
  }

  return Math.min(orderCount, MAX_ORDER_COUNT);
};

type ConversionProps = {
  price?: number | null;
  marketPrice?: number | null;
  orderType?: OrderType;
  priceStep?: number | null;
  priceStepKind?: PriceStepKind;
  sizeScale?: number | null;
  orderCount?: number | null;
  tradeSide?: TradeSide;
  minOrder: number | undefined;
  minTick: number | undefined;
  marketId: string | undefined;
  timeInForce: string;
};
export const getSizeFromValue = ({
  value,
  price,
  marketPrice,
  orderType,
  minOrder = 0.0001,
}: ConversionProps & { value?: number | null }) => {
  if (value === null || value === 0) {
    return null;
  }

  const limitSize = value! / (price ?? marketPrice!);
  const marketSize = marketPrice ? value! / marketPrice! : 0;

  if (value! < minOrder * (price ?? marketPrice!))
    return orderType === OrderType.MARKET ? marketSize : limitSize;
  if (!orderType) return limitSize;

  if (
    [OrderType.LIMIT, OrderType.ST0P_LIMIT, OrderType.LADDER].includes(
      orderType,
    )
  ) {
    return limitSize;
  } else if ([OrderType.MARKET, OrderType.STOP_MARKET].includes(orderType)) {
    return marketSize;
  } else {
    return marketSize;
  }
};

export const getValueFromSize = ({
  quantity,
  price,
  marketPrice,
  orderType,
}: ConversionProps & { quantity?: number | null }) => {
  if (!quantity) {
    return null;
  }

  if (quantity) {
    const limitValue = quantity! * (price ?? marketPrice!);
    const marketValue = quantity! * marketPrice!;
    if (!orderType) return limitValue;

    if (
      [OrderType.LIMIT, OrderType.ST0P_LIMIT, OrderType.LADDER].includes(
        orderType,
      )
    ) {
      return limitValue;
    } else if ([OrderType.MARKET, OrderType.STOP_MARKET].includes(orderType)) {
      return marketValue;
    } else if (orderType === OrderType.LADDER) {
    } else {
      return marketValue;
    }
  }
};

export const getUpdatedLadderOrders = (
  { selectedMarket, isCurrencyKindUSD }: TCreateAndSetLadderProps,
  orderState: OrderState,
) => {
  if (orderState.orderTypeDropdownValue !== OrderType.LADDER) {
    return { orders: undefined, ladderParams: undefined };
  }

  if (
    orderState.ladder?.priceStep &&
    orderState.ladder?.priceStepKind &&
    orderState.ladder?.sizeScale &&
    orderState.ladder?.orderCount &&
    orderState.price &&
    (orderState.quantity || orderState.value) &&
    selectedMarket &&
    selectedMarket?.id &&
    orderState.tradeSide
  ) {
    const timeInForce = getTimeInForce(orderState.orderTypeDropdownValue!);
    const roundedPrice = roundToNearestTick(
      orderState.price,
      selectedMarket.minTick,
    );
    const roundedSize = orderState.quantity
      ? roundToNearestTick(orderState.quantity, selectedMarket.minOrder)
      : 0;
    const ladderParams = {
      price: roundedPrice,
      marketId: selectedMarket?.id,
      valueUsd: orderState.value ?? null,
      quantity: roundedSize,
      timeInForce,
      priceStepKind: orderState.ladder?.priceStepKind,
      orderCount: orderState.ladder?.orderCount,
      priceStep: orderState.ladder?.priceStep,
      sizeScale: orderState.ladder?.sizeScale,
      minTick: selectedMarket.minTick,
      minOrder: selectedMarket.minOrder,
      tradeSide: orderState.tradeSide,
      isCurrencyKindUSD: isCurrencyKindUSD,
    };

    return {
      orders: createLadderParams({
        ...ladderParams,
        quantity: orderState.quantity ?? 0,
      }), // dont use rounded quantity here
      ladderParams,
    };
  } else {
    return { orders: undefined, ladderParams: undefined };
  }
};
