import { abbreviateNumber } from 'utils';
import { brand } from 'utils/brand';

import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import Text from 'components/Text';

import { SharesInfoContainer } from './styles';
import { Column, Row } from 'pages/Vaults/styles';

import { useTranslation } from 'react-i18next';

type Props = {
  vault: VaultWithContractInfo;
};
const SharesInfo = ({ vault }: Props) => {
  const { t } = useTranslation();
  const activeShares = [
    {
      asset: brand.tokenTicker,
      icon: brand.tokenLogo,
      value: vault.activeAmount,
    },
  ];

  return (
    <SharesInfoContainer>
      <Text variant="BODY_M" color="shadesForeground200">
        {t('totalActiveShares')}
      </Text>
      <Column className="gap-5">
        {activeShares.map(({ asset, icon, value }) => (
          <Row className="space-between">
            <Text
              className="gap-5 flex items-center"
              variant="BODY_M"
              fontWeight="semiBold"
            >
              {/* <img className="icon" src={icon} alt={asset} /> */}
              {/* {asset} */}
              {abbreviateNumber(value).toLowerCase()}
            </Text>
            {/* <Text className="gap-5" variant="BODY_M">
            </Text> */}
          </Row>
        ))}
      </Column>
    </SharesInfoContainer>
  );
};
export default SharesInfo;
