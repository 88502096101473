import styled from 'styled-components';

type ContainerStyledProps = {
  size: number;
  bgColor?: string;
};
const ContainerStyled = styled.div<ContainerStyledProps>`
  display: flex;
  margin-right: -2px;
  margin-left: -2px;
  justify-content: center;

  > div {
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
    border-radius: 100px;
  }

  > div:first-child {
    z-index: 1;
    background: ${({ theme }) => theme.colors.shadesBackground800};
  }

  > div:last-child {
    z-index: 0;
    margin-left: ${({ size }) => `${-(size / 4) - 2}px`};
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }

  > img {
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
    border-radius: 100px;
    border: 2px solid ${({ bgColor }) => bgColor || '#0b0f18'};
  }

  > img:first-child {
    z-index: 1;
  }

  > img:last-child {
    z-index: 0;
    margin-left: ${({ size }) => `${-(size / 4) - 2}px`};
  }
`;

type Props = {
  icons?: [string, string];
  size: number;
  bgColor?: string;
  isEmpty?: boolean;
};
const IconPair = ({ icons, size, bgColor, isEmpty = false }: Props) => {
  return (
    <ContainerStyled bgColor={bgColor} size={size}>
      {isEmpty ? (
        <>
          <div />
          <div />
        </>
      ) : (
        icons?.map((icon, index) => <img src={icon} alt={index.toString()} />)
      )}
    </ContainerStyled>
  );
};

export default IconPair;
