import { brandedSelect, isBrandRabbitX } from 'utils/brand';

import { isBfxDepositBonusActive } from 'components/DepositBonusModal/utils';

import styled, { css } from 'styled-components';

type Props = {
  disableHoverAnimation?: boolean;
};
export const StyledVaultCard = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${({ theme }) =>
    isBrandRabbitX
      ? 'linear-gradient(270deg, #101423 0%, #272d44 100%)'
      : theme.colors.shadesBackground700};

  border-radius: 8px;
  border: 1px solid #1a2138;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  ${({ disableHoverAnimation = false }) =>
    !disableHoverAnimation &&
    css`
      :hover {
        margin-top: -5px;
        margin-bottom: 5px;
      }
    `}

  .top-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    flex: 1;
    min-height: ${isBfxDepositBonusActive
      ? brandedSelect({ rabbitx: 206, bfx: 224 })
      : 206}px;
    padding: 15px;
    border-radius: 8px;
    background: ${isBrandRabbitX
      ? 'linear-gradient(244deg, #000 -49%, #1a2235 100%)'
      : 'linear-gradient(244deg, #030606 -49%, #1E2B34 100%)'};
  }

  .bottom-part {
    box-sizing: border-box;
    padding: 10px 15px;
  }

  .icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

  .space-between {
    justify-content: space-between;
  }

  .align-end {
    align-items: flex-end;
  }

  .gap-2 {
    gap: 2px;
  }

  .gap-3 {
    gap: 3px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
