import { memo } from 'react';

import { brandedSelect } from 'utils/brand';

import useKey, { Key } from 'hooks/useKey';

import Button from 'components/Button';

import { ActionSectionContainer, ButtonGroup } from './styles';
import { ModalsContextInterface } from 'contexts/ModalContext';

import { WithdrawSteps } from '.';
import { useTranslation } from 'react-i18next';

const getButtonLabel = (step: WithdrawSteps, t: any) => {
  switch (step) {
    case WithdrawSteps.REQUEST:
      return t(
        brandedSelect({
          bfx: 'withdrawToBlast',
          rabbitx: 'withdrawToEthereum',
        }),
      );
    case WithdrawSteps.CONFIRM:
      return t('confirmWithdrawal');
    case WithdrawSteps.ERROR:
      return t('resetAndTryAgain');
    default:
      break;
  }
};

const getShouldDisableButton = (
  withdrawValue: number | null,
  withdrawableBalance: number,
  isConfirmingWithdrawal: boolean,
) => {
  if (isConfirmingWithdrawal) {
    return true;
  }
  if (!withdrawValue || withdrawValue <= 0) {
    return true;
  }
  if (withdrawValue > withdrawableBalance) {
    return true;
  }

  return false;
};

const getButtonGtmId = (step: WithdrawSteps) => {
  switch (step) {
    case WithdrawSteps.REQUEST:
      return 'button-withdraw-popup-ethereum';
    case WithdrawSteps.CONFIRM:
      return 'button-withdraw-popup-confirm';
    default:
      return '';
  }
};

interface Props {
  modal: ModalsContextInterface;
  step: WithdrawSteps;
  handleWithdrawClick: () => void;
  withdrawValue: number | null;
  withdrawableBalance: number;
  isConfirmingWithdrawal: boolean;
}
const ActionSection = ({
  modal,
  step,
  handleWithdrawClick,
  withdrawValue,
  withdrawableBalance,
  isConfirmingWithdrawal,
}: Props) => {
  const { t } = useTranslation();
  const buttonLabel = getButtonLabel(step, t);
  const shouldDisableButton = getShouldDisableButton(
    withdrawValue,
    withdrawableBalance,
    isConfirmingWithdrawal,
  );

  useKey(Key.Enter, () => !shouldDisableButton && handleWithdrawClick());

  return (
    <ActionSectionContainer>
      <ButtonGroup className="mt-30">
        <Button
          variant="secondaryOutline"
          size="S"
          responsiveSizing={{ default: 'large' }}
          onClick={modal.clear}
        >
          {t('cancel')}
        </Button>
        <Button
          data-gtmid={getButtonGtmId(step)}
          variant={'primaryGreen'}
          block={true}
          onClick={handleWithdrawClick}
          disabled={shouldDisableButton}
          isLoading={isConfirmingWithdrawal}
          size="S"
          responsiveSizing={{
            default: 'large',
          }}
        >
          {buttonLabel}
        </Button>
      </ButtonGroup>
    </ActionSectionContainer>
  );
};

export default memo(ActionSection);
