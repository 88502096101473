import { capitalizeFirstLetter } from 'utils';

import useModal from 'hooks/useModal';

import { Icon } from 'components';
import Button from 'components/Button/button';
import Text from 'components/Text';

import Header from './Header';
import Input, { InputProps } from './Input';
import LockUnlockSelector from './LockUnlockSelector';
import { ActionType } from './LockUnlockSelector/enums';
import { Container } from './styles';
import blastBlackIcon from 'assets/assetLogos/blast.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type Props = {
  actionType: ActionType;
  onChangeActionType: (actionType: ActionType) => void;
  onCtaClick: () => void;
  disableCta?: boolean;
  ctaText?: string;
  disableUnlock?: boolean;
} & InputProps;
const InputAndActions = ({
  actionType,
  onChangeActionType,
  onCtaClick,
  disableCta,
  ctaText = '',
  disableUnlock = false,
  ...inputProps
}: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const modal = useModal();
  const { t } = useTranslation();

  const onConnectWallet = () =>
    modal.present(<WalletModal />, Modals.walletModal);

  const isWalletConnected = !!frontendSecrets?.profile.wallet;

  return (
    <Container>
      <Header />

      <div className="content">
        <div>
          <Text variant="BODY_M" flexed className="justify-between">
            Earn BFX Points
            <Icon src={blastBlackIcon} rounded />
          </Text>
          <Text variant="BODY_S" flexed className="negative-mt-5">
            <Text color="shadesForeground200" flexed className="flex-1">
              Lock Period
            </Text>
            <Text color="white">2 Months</Text>
          </Text>

          {/* <Text
            className="align-center px-10"
            variant="BODY_XS"
            color="shadesForeground200"
          >
            The first perpetuals DEX with native yield. BFX Points token will form the
            backbone of the exchange.
          </Text> */}

          <LockUnlockSelector
            selected={actionType}
            onChange={onChangeActionType}
            disableUnlock={disableUnlock}
          />

          <Input {...inputProps} />
        </div>

        <Button
          sizeVariant="S"
          colorVariant="primaryGreen"
          onClick={
            frontendSecrets?.profile.wallet ? onCtaClick : onConnectWallet
          }
          disabled={isWalletConnected ? disableCta : false}
        >
          {isWalletConnected
            ? capitalizeFirstLetter(ctaText)
            : t('connectWallet')}
        </Button>
      </div>
    </Container>
  );
};

export default observer(InputAndActions);
