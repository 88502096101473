import { TMeshState } from 'hooks/useMeshAPI';

export enum MESH_ORDER_STATUS {
  ERRORED_OUT = 'ERRORED_OUT',
  CONTRACT_CALLED = 'CONTRACT_CALLED',
  CONTRACT_CALL_FAILED = 'CONTRACT_CALL_FAILED',
  RECEIVED_IN_PROXY_WALLET = 'RECEIVED_IN_PROXY_WALLET',
  PENDING_FROM_MESH = 'PENDING_FROM_MESH',
}

export const getMeshOrderStatusFromMeshState = (meshState: TMeshState) => {
  if (meshState.error) return MESH_ORDER_STATUS.ERRORED_OUT;

  return MESH_ORDER_STATUS.PENDING_FROM_MESH;
};

const STEP_TO_DESCRIPTION: Record<MESH_ORDER_STATUS, string> = {
  [MESH_ORDER_STATUS.ERRORED_OUT]: 'Error',
  [MESH_ORDER_STATUS.CONTRACT_CALLED]: 'Processing Deposit',
  [MESH_ORDER_STATUS.CONTRACT_CALL_FAILED]: 'Contract Call Failed',
  [MESH_ORDER_STATUS.RECEIVED_IN_PROXY_WALLET]: 'Processing Deposit',
  [MESH_ORDER_STATUS.PENDING_FROM_MESH]: 'Pending Deposit',
};

export const getStepDescriptionText = (status: MESH_ORDER_STATUS) => {
  return STEP_TO_DESCRIPTION[status];
};
