import { memo } from 'react';

import OrdersTable from 'components/Tables/OrdersTable';
import { TablePageContainer } from 'components/Tables/styles';
import Text from 'components/Text';

import Portfolio from '../../index';

const PortfolioOrders = () => {
  return (
    <Portfolio>
      <TablePageContainer addBackground={true} addPadding>
        <Text variant="HEADING_H2" fontWeight="semiBold">
          Orders
        </Text>

        <OrdersTable
          includePagination
          allowNewItemsFromSocket={false}
          showFilterOptions
        />
      </TablePageContainer>
    </Portfolio>
  );
};

export default memo(PortfolioOrders);
