import { FormattedNumber } from 'components';
import Loading from 'components/Loading';
import Text from 'components/Text';

import { Container } from './styles';

type Props = {
  icon: string;
  value?: number;
  label: string;
  title: string;
  isLoading?: boolean;
};
const DataCard = ({ title, icon, value, label, isLoading }: Props) => {
  return (
    <Container>
      <Text variant="BODY_M" color="shadesForeground200">
        {title}
      </Text>
      <div className="content">
        <div className="icon-container">
          <img src={icon} alt="icon" />
        </div>
        {isLoading ? (
          <Loading size={20} />
        ) : (
          <div className="value-container">
            <FormattedNumber
              fontWeight="semiBold"
              value={value}
              prefix="$"
              variant="NUMBERS_XL"
            />
            <Text variant="BODY_M" color="shadesForeground200">
              {label}
            </Text>
          </div>
        )}
      </div>
    </Container>
  );
};

export default DataCard;
