import { Icon } from 'components';
import Badge from 'components/Badge';

import ethIcon from 'assets/assetLogos/eth.svg';
import rbxIcon from 'assets/assetLogos/rbx-circle-white.svg';
import arrowRightWhiteIcon from 'assets/icons/arrow-right-white.svg';
import { Row } from 'theme/globalStyledComponents/row';

const MarketBadge = ({ icon, name }: { icon: string; name: string }) => (
  <Badge
    gap={3}
    flexed
    padding="4px"
    bgColor="shadesBackground800"
    fontWeight="semiBold"
    variant="BODY_XS"
  >
    <Icon src={icon} size="S" />
    {name}
  </Badge>
);

// @Todo: Add Props
const SwapStepTitle = () => (
  <Row gap={5}>
    Swapping{' '}
    <Row gap={2}>
      <MarketBadge icon={ethIcon} name="ETH" />

      <Icon src={arrowRightWhiteIcon} size="S" />

      <MarketBadge icon={rbxIcon} name="RBX" />
    </Row>
  </Row>
);

export default SwapStepTitle;
