import { memo } from 'react';

import CredentialRevealer from '../CredentialRevealer';
import { Container } from './styles';
import { ApiKey } from 'interfaces/apiKey';

type Props = {
  apiKey: ApiKey;
  profileId: string | number;
};
const ApiKeys = ({
  apiKey: {
    apiSecret: { key, secret },
    jwtPublic,
    jwtPrivate,
    refreshToken,
  },
  profileId,
}: Props) => {
  return (
    <Container>
      <div className="row">
        <div className="col">
          <CredentialRevealer
            secret={profileId as string}
            label={'Profile ID'}
            showVisibilityToggle={false}
            initialIsVisible={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <CredentialRevealer
            secret={key}
            label={'API Key'}
            showVisibilityToggle={false}
            initialIsVisible={true}
          />
        </div>
        <div className="col">
          <CredentialRevealer secret={secret} label={'Secret'} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <CredentialRevealer
            secret={jwtPublic}
            label={'Public JWT'}
            showVisibilityToggle={false}
            initialIsVisible={true}
          />
        </div>
        <div className="col">
          <CredentialRevealer secret={jwtPrivate} label={'Private JWT'} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <CredentialRevealer
            secret={refreshToken}
            label={'Refresh Token'}
            showVisibilityToggle={false}
            initialIsVisible={true}
          />
        </div>
      </div>
    </Container>
  );
};

export default memo(ApiKeys);
