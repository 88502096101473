import Text, { TextProps } from 'components/Text';

import { Color } from 'interfaces';
import styled, { css } from 'styled-components';

type StyledTextProps = {
  bgColor?: Color;
  hoveredBgColor?: Color;
  borderRadius?: number;
  borderColor?: Color;
  padding?: string;
};
const StyledText = styled(Text)<StyledTextProps>`
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] : 'transparent'};
  border-radius: ${({ borderRadius = 4 }) => `${borderRadius}px`};
  border: ${({ theme, borderColor }) =>
    borderColor ? `1px solid ${theme.colors[borderColor]}` : 'none'};
  padding: ${({ padding = '3px 4px' }) => padding};

  ${({ hoveredBgColor, theme }) =>
    hoveredBgColor &&
    css`
      :hover {
        background: ${theme.colors[hoveredBgColor]};
      }
    `}
`;

type Props = Partial<StyledTextProps> & TextProps;
const Badge = ({ ...rest }: Props) => {
  return <StyledText {...rest}></StyledText>;
};

export default Badge;
