import { memo, useState } from 'react';

import { showNotification, verifyUserSession } from 'utils';

import {
  useActiveWeb3React,
  useAirdropsAPI,
  useRabbitContractsAPI,
  useVerifyChainId,
} from 'hooks';

import { FormattedNumber } from 'components';
import Button from 'components/Button';

import { Container } from './styles';
import rbxIcon from 'assets/assetLogos/rbx-circle.svg';
import { useAppContext } from 'contexts/AppContext';

import { AIRDROP_PHASE_TWO_TITLE } from '..';
import { NotificationType, QueryKeys } from 'enums';
import { ProfileAirdrop } from 'interfaces';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

const getClaimBtnText = (
  isClaiming: boolean,
  isPendingTxSign: boolean,
  t: any,
) => {
  if (isPendingTxSign) {
    return t('pendingDot');
  }

  if (isClaiming) {
    return t('claimingDot');
  }

  return t('claim');
};

type Props = {
  profileAirdrop: ProfileAirdrop;
};
const ClaimBox = ({ profileAirdrop }: Props) => {
  const { account } = useActiveWeb3React();
  const { claimAirdrop } = useAirdropsAPI();
  const [isClaiming, setIsClaiming] = useState(false);
  const [isPendingTxSign, setIsPendingTxSign] = useState(false);
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { claimAirdropFromContract } = useRabbitContractsAPI();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();

  /**
   * Handles the claim all button click
   */
  const handleClaimAll = async () => {
    try {
      if (!frontendSecrets || !account) return;

      if (!(await validateNetworkAndSwitchIfRequired('Claim airdrop again.'))) {
        return;
      }

      showNotification({
        title: t('claimingAirdropTitle'),
        type: NotificationType.Info,
      });
      flushSync(() => {
        setIsClaiming(true);
      });

      // call API endpoint for claiming claimable amount for airdrop_title
      const res = await claimAirdrop(AIRDROP_PHASE_TWO_TITLE);

      flushSync(() => {
        setIsPendingTxSign(true);
      });

      // call contract method for claiming claimable amount for airdrop_title with the response from the API
      const tx = await claimAirdropFromContract(res, account);

      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      flushSync(() => {
        setIsPendingTxSign(false);
      });

      const minedTx = await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      if (minedTx.status !== 1) {
        throw new Error('Transaction failed');
      }

      await queryClient.invalidateQueries(QueryKeys.Airdrops);
      showNotification({
        title: t('claimingSuccessfulTitle'),
        description: t('claimingSuccessfulDescription'),
        type: NotificationType.Positive,
      });
    } catch (e: any) {
      console.error(e);
      showNotification({
        title: t('claimingFailedTitle'),
        description: t('claimingFailedDescription'),
        type: NotificationType.Negative,
      });
    } finally {
      await queryClient.invalidateQueries(QueryKeys.Airdrops);
      setIsPendingTxSign(false);
      setIsClaiming(false);
    }
  };

  return (
    <Container>
      <div className="available-to-claim">
        <span className="label">{t('availableToClaim')}</span>
        <div className="claim-amount">
          <img src={rbxIcon} alt="RBX icon" />
          <FormattedNumber value={profileAirdrop.claimable} suffix=" RBX" />
        </div>
      </div>
      <div className="divider" />
      <div className="claim-button-container">
        <Button
          variant="primaryGreen"
          size="small"
          onClick={handleClaimAll}
          disabled={
            profileAirdrop.claimable <= 0 ||
            isClaiming ||
            profileAirdrop.status === 'finished'
          }
        >
          {getClaimBtnText(isClaiming, isPendingTxSign, t)}
        </Button>
      </div>
    </Container>
  );
};

export default memo(ClaimBox);
