import { memo } from 'react';

import { useBalanceOperationsAPI } from 'hooks';
import { useDownloadCsv } from 'hooks';

import SettlementsTable from 'components/Tables/SettlementsTable';
import { TablePageContainer } from 'components/Tables/styles';
import Text from 'components/Text';

import Portfolio from '../../index';

import { useTranslation } from 'react-i18next';

const PortfolioSettlements = () => {
  const { t } = useTranslation();
  const { fetchPrivateSettlements } = useBalanceOperationsAPI();
  const DownloadCSV = useDownloadCsv({
    fetchFunction: fetchPrivateSettlements,
    fileName: 'SETTLEMENT_HISTORY',
    avoidRows: ['id', 'wallet', 'opsId2', 'reason'],
    headTranslations: {
      shardId: t('market'),
      opsType: t('type'),
      amount: t('amountUsd'),
      time: t('time'),
    },
  });

  return (
    <Portfolio>
      <TablePageContainer addBackground={true} addPadding>
        <Text
          variant="HEADING_H2"
          fontWeight="semiBold"
          flexed
          className="justify-between"
        >
          {t('settlement')}

          <DownloadCSV />
        </Text>

        <SettlementsTable showFilterOptions />
      </TablePageContainer>
    </Portfolio>
  );
};

export default memo(PortfolioSettlements);
