import { memo } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import { timestampToLocalDateWithTime } from 'utils';

import { FormattedNumber, Icon } from 'components';
import Text from 'components/Text';

import LabelAndValueCell, {
  LabelAndValueCellProps,
} from '../components/LabelAndValueCell';
import MarketLogoWithTradeSide from '../components/MarketLogoWithTradeSide';
import usdbLogo from 'assets/assetLogos/usdb.svg';
import { Column } from 'theme/globalStyledComponents/column';
import { Row } from 'theme/globalStyledComponents/row';

import { BalanceOpsType } from 'enums';
import { ProfileBalanceOps } from 'interfaces';
import { useTranslation } from 'react-i18next';

export const opsTypeToSettlement = {
  [BalanceOpsType.Fee]: 'Fee',
  [BalanceOpsType.RealizedPnl]: 'Realised P&L',
  [BalanceOpsType.Funding]: 'Funding',
  [BalanceOpsType.YieldPayout]: 'Native Yield',
};

interface Props {
  settlement: ProfileBalanceOps;
  showLabels: boolean;
}
const SettlementsRow = ({ settlement, showLabels }: Props) => {
  const { shardId: marketId, opsType, amount, timestamp } = settlement;
  const { t } = useTranslation();

  const isSettlementYieldPayout =
    settlement.opsType === BalanceOpsType.YieldPayout;

  return (
    <TableRow>
      <TableCell>
        {isSettlementYieldPayout ? (
          <Row gap={8} align="flex-start">
            <Icon src={usdbLogo} size="L" />
            <Column gap={2}>
              <Text
                variant="BODY_S"
                responsiveVariant={{ default: 'BODY_M' }}
                fontWeight="semiBold"
              >
                Blast
              </Text>
              <Text
                variant="BODY_S"
                responsiveVariant={{ default: 'BODY_M' }}
                color="shadesForeground200"
              >
                USDB Interest
              </Text>
            </Column>
          </Row>
        ) : (
          <MarketLogoWithTradeSide marketID={marketId} />
        )}
      </TableCell>

      <LabelAndValueCell
        showLabel={showLabels}
        label={t('settlementType')}
        value={opsTypeToSettlement[opsType] ?? '-'}
      />

      <LabelAndValueCell
        showLabel={showLabels}
        label={t('amount')}
        value={<FormattedNumber value={amount} prefix="$" />}
      />

      <LabelAndValueCell
        showLabel={showLabels}
        alignRight
        label={t('time')}
        value={timestampToLocalDateWithTime(timestamp)}
      />
    </TableRow>
  );
};

export default memo(SettlementsRow);
