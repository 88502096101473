import { TextVariant } from 'components/Text/variant';

import { Column } from 'pages/Vaults/styles';

import styled from 'styled-components';

const StyledStatItem = styled(Column)`
  padding: 10px;
  border-radius: 8px;
  gap: 2px;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  font-size: ${TextVariant['BODY_S'].fontSize};
  line-height: ${TextVariant['BODY_S'].lineHeight};

  > span:first-child {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

export type StatItemProps = {
  title: string;
  value: React.ReactNode;
};
const StatItem = ({ title, value }: StatItemProps) => {
  return (
    <StyledStatItem>
      <span>{title}</span>
      {value}
    </StyledStatItem>
  );
};

export default StatItem;
