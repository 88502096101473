import Loading from 'components/Loading';
import Text from 'components/Text';

import ContractDetailsHeader from './Header';
import StatItem, { StatItemProps } from './StatItem';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;

  > div {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 10px;
    gap: 15px;
    flex: 1;
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.shadesBackground700};

    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }
  }
`;

type ContractDetailsProps = {
  stats: StatItemProps[];
  isLoading?: boolean;
  isError?: boolean;
};
const ContractDetails = ({
  stats,
  isLoading,
  isError,
}: ContractDetailsProps) => {
  return (
    <Container>
      <div>
        <ContractDetailsHeader />

        {isLoading && (
          <div className="flex justify-center">
            <Loading size={20} />
          </div>
        )}
        {isError && (
          <Text color="primaryRedForeground100" variant="BODY_S">
            An error occurred while fetching contract details
          </Text>
        )}
        {!isLoading && !isError && (
          <div className="grid">
            {stats.map(stat => (
              <StatItem {...stat} />
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default ContractDetails;
