import { brand, isBrandRabbitX } from 'utils/brand';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { isActiveLink } from '../shared/utils';
import { routes } from 'pages/routes';
import { theme } from 'theme/Theme';

import { MenuItem } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const DesktopMenuStyled = styled.div`
  display: flex;
  height: 30px;

  .logo-link {
    display: flex;
    margin-left: 10px;
    cursor: pointer;
    .logo {
      width: 100%;
    }
  }
`;

const ListStyled = styled.ul`
  margin-left: 30px;
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-left: 30px;

  @media (min-width: ${({ theme }) => theme.screenSizes.medium}px) {
    margin-left: 30px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    margin-left: 40px;
  }
`;

type NavLinkProps = {
  isactive: number;
};
const NavLinkStyled = styled(NavLink)<NavLinkProps>`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 12px;
  line-height: normal;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin: 0px 2.5px;
  text-decoration: none;

  background: ${({ isactive, theme }) =>
    isactive
      ? isBrandRabbitX
        ? theme.colors.shadesBackground600
        : theme.colors.main200
      : 'transparent'};

  /* border-bottom: 3px solid
    ${({ isactive, theme }) =>
    isactive ? theme.colors.warningForeground100 : 'transparent'}; */

  color: ${({ isactive, theme }) =>
    isactive
      ? isBrandRabbitX
        ? theme.colors.white
        : theme.colors.black
      : theme.colors.shadesForeground200};

  &:hover {
    background: ${({ theme, isactive }) =>
      isactive
        ? isBrandRabbitX
          ? theme.colors.shadesBackground600
          : theme.colors.main200
        : theme.colors.shadesBackground500};
  }
`;

interface Props {
  className?: string;
  menuItems: MenuItem[];
}
const DesktopMenu = ({ menuItems }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const isWidthOverMedium = width > theme.screenSizes.medium;

  return (
    <DesktopMenuStyled>
      <Link className="logo-link" to={routes.trade.getRoutePath()}>
        <img
          src={brand.logo}
          className="logo"
          alt={`${brand.appShortName} logo`}
        />
      </Link>
      <ListStyled>
        {menuItems.map((item: any, i: any) => (
          <li key={i}>
            <NavLinkStyled
              data-gtmid={item.gtmId}
              to={item.to}
              isactive={
                isActiveLink(
                  pathname,
                  item.to,
                  item.componentName,
                  isWidthOverMedium,
                )
                  ? 1
                  : 0
              }
            >
              <span>{item.text}</span>
            </NavLinkStyled>
          </li>
        ))}
        {brand.featureFlags.news && (
          <li key={'news'}>
            <NavLinkStyled
              target={'_blank'}
              to={'https://news.rabbitx.io'}
              isactive={0}
            >
              <span>{t('news')}</span>
            </NavLinkStyled>
          </li>
        )}
      </ListStyled>
      {/* <MoreMenu /> */}
    </DesktopMenuStyled>
  );
};

export default DesktopMenu;
