import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import useModal from 'hooks/useModal';

import { FormattedNumber } from 'components/FormattedNumber';
import Icon from 'components/Icon';
import Text from 'components/Text';

import StopLossTakeProfitAmendModal from './StopLossTakeProfitAmendModal';
import StopLossTakeProfitCancelModal from './StopLossTakeProfitCancelModal';
import editIcon from 'assets/icons/edit-pen.svg';
import addIcon from 'assets/icons/plus.svg';
import deleteIcon from 'assets/icons/trash.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';

import { OrderStatus, OrderType } from 'enums';
import { Position } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;

  font-size: 12px;

  img {
    cursor: pointer;
  }
  .separator {
    height: 12px;
    width: 1px;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.shadesForeground300};
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    font-size: 14px;
  }
`;

const AddButton = styled(Text)`
  display: flex;
  cursor: pointer;
  padding: 4px 5px 4px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.shadesBackground500};
  border-radius: 100px;
`;

export const getMarketPosition = (marketId: string, positions?: Position[]) => {
  return positions?.find(p => p.marketID === marketId);
};

type Props = {
  marketId: string;
};
const StopLossTakeProfitCell = ({ marketId }: Props) => {
  const modal = useModal();
  const { t } = useTranslation();
  const {
    store: {
      account: { _positions },
    },
  } = useAppContext();

  const handleCancel = e => {
    e?.stopPropagation();
    modal.present(
      <StopLossTakeProfitCancelModal marketId={marketId} />,
      Modals.slTpCancelModal,
    );
  };

  const decimalScales = useGetDecimalPlaces(marketId);

  const position = getMarketPosition(marketId, _positions);
  if (!position) return null;

  const { stopLoss: _stopLoss, takeProfit: _takeProfit } = position;
  // Only "placed" sltp orders should be shown
  const stopLoss = _stopLoss?.status === OrderStatus.PLACED ? _stopLoss : null;
  const takeProfit =
    _takeProfit?.status === OrderStatus.PLACED ? _takeProfit : null;

  const showCancel = stopLoss || takeProfit;
  const showUnifiedEdit = stopLoss && takeProfit;
  const showLeftEdit = stopLoss && !takeProfit;
  const showRightEdit = !stopLoss && takeProfit;

  const handleAddOrEdit = (type?: OrderType) => {
    modal.present(
      <StopLossTakeProfitAmendModal marketId={marketId} initialType={type} />,
      Modals.slTpAmendModal,
    );
  };

  const renderAddButton = (orderType?: OrderType) => {
    return (
      <AddButton
        onClick={e => {
          e.stopPropagation();
          handleAddOrEdit(orderType);
        }}
      >
        <Icon size="S" src={addIcon} alt="Add" />
        <Text variant="BODY_XS" responsiveVariant={{ default: 'BODY_S' }}>
          {t('add')}
        </Text>
      </AddButton>
    );
  };

  const renderEditButton = (orderType?: OrderType) => {
    return (
      <Icon
        size="S"
        src={editIcon}
        alt="Edit"
        onClick={e => {
          e.stopPropagation();
          handleAddOrEdit(orderType);
        }}
      />
    );
  };

  return (
    <Container>
      {stopLoss ? (
        <Text
          className="cursor-pointer"
          color="negativeForeground200"
          variant="BODY_S"
          onClick={() => handleAddOrEdit(OrderType.STOP_LOSS)}
        >
          <FormattedNumber
            value={stopLoss.triggerPrice}
            decimalScale={decimalScales.price}
          />
        </Text>
      ) : (
        renderAddButton(OrderType.STOP_LOSS)
      )}
      {showLeftEdit && renderEditButton(OrderType.STOP_LOSS)}
      <span className="separator" />
      {takeProfit ? (
        <Text
          className="cursor-pointer"
          color="primaryGreenForeground100"
          variant="BODY_S"
          onClick={() => handleAddOrEdit(OrderType.TAKE_PROFIT)}
        >
          <FormattedNumber
            value={takeProfit.triggerPrice}
            decimalScale={decimalScales.price}
          />
        </Text>
      ) : (
        renderAddButton(OrderType.TAKE_PROFIT)
      )}
      {showRightEdit && renderEditButton(OrderType.TAKE_PROFIT)}
      {showUnifiedEdit && renderEditButton()}
      {showCancel && (
        <Icon size="S" src={deleteIcon} alt="Cancel" onClick={handleCancel} />
      )}
    </Container>
  );
};

export default observer(StopLossTakeProfitCell);
