import { AuthType } from '@particle-network/auth';

import walletConnectIcon from 'assets/assetLogos/walletconnect.svg';
import metamaskIcon from 'assets/icons/metamask.svg';
import appleIcon from 'assets/icons/socials/apple24.svg';
import discordIcon from 'assets/icons/socials/discord24.svg';
import emailIcon from 'assets/icons/socials/email-rounded.svg';
import facebookIcon from 'assets/icons/socials/facebook.svg';
import githubIcon from 'assets/icons/socials/github.svg';
import googleIcon from 'assets/icons/socials/google24.svg';
import linkedinIcon from 'assets/icons/socials/linkedin.svg';
import microsoftIcon from 'assets/icons/socials/microsoft.svg';
import phoneIcon from 'assets/icons/socials/phone.svg';
import profileIcon from 'assets/icons/socials/profile.svg';
import twitchIcon from 'assets/icons/socials/twitch.svg';
import twitterIcon from 'assets/icons/socials/twitter.svg';
import vaultIcon from 'assets/icons/vault.svg';
import { TSavedWallet } from 'enums/savedWallet';

import { ConnectorNames } from 'enums';

const MAPPED_SOCIALCONNECT_ICONS: Record<AuthType, string> = {
  twitter: twitterIcon,
  email: emailIcon,
  phone: phoneIcon,
  facebook: facebookIcon,
  google: googleIcon,
  apple: appleIcon,
  discord: discordIcon,
  github: githubIcon,
  twitch: twitchIcon,
  microsoft: microsoftIcon,
  linkedin: linkedinIcon,
  jwt: profileIcon,
};

export const getBadgeIcon = (wallet: TSavedWallet) => {
  if (wallet.vaultAddress) {
    return vaultIcon;
  }

  if (wallet.connectorName === ConnectorNames.Injected) {
    return metamaskIcon;
  }

  if (wallet.connectorName === ConnectorNames.WalletConnect) {
    return walletConnectIcon;
  }

  if (wallet.connectorName === ConnectorNames.SocialConnect) {
    if (wallet.socialLoginOptions?.preferredAuthType) {
      return MAPPED_SOCIALCONNECT_ICONS[
        wallet.socialLoginOptions?.preferredAuthType
      ];
    }
  }

  return undefined;
};
