import { useEffect, useState } from 'react';

import { AbstractConnector } from '@web3-react/abstract-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

import { useActiveWeb3React } from 'hooks';
import useKey, { Key } from 'hooks/useKey';
import useWindowDimensions from 'hooks/useWindowDimensions';

import Button from 'components/Button';
import TextInput from 'components/Inputs/TextInput';
import { StepList } from 'components/Step';

import { isInjectedProviderMetamask } from '../ConnectMethods';
import { getMethodLabelAndIcon } from '../ConnectMethods/helpers';
import { Steps as ConnectStep } from '../WalletModal';
import { Container, Header } from './styles';
import metamaskLogo from 'assets/assetLogos/metamask.svg';
import particleWalletLogo from 'assets/assetLogos/particle-wallet.png';
import walletconnectLogo from 'assets/assetLogos/walletconnect.svg';
import arrowLeftIcon from 'assets/icons/arrow-left-gray.svg';
import vaultLogo from 'assets/icons/vault.svg';
import { ParticleAuthConnector } from 'connectors/particleAuthConnector';
import { WalletConnectV2Connector } from 'connectors/walletconnectV2Connector';
import { mixpanelService } from 'service/mixpanelService';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const getWalletNameFromConnector = (
  connector: AbstractConnector | undefined,
) => {
  if (
    connector instanceof WalletConnectV2Connector ||
    connector instanceof WalletConnectConnector
  ) {
    return 'WalletConnect';
  }

  if (connector instanceof ParticleAuthConnector) {
    return 'Particle Network';
  }

  return 'MetaMask';
};

type Props = {
  connector: AbstractConnector | undefined;
  isVault: boolean;
  onBackClick: () => void;
  onSignAndVerify: (vaultAddress: string) => void;
  areRequestsLocked: boolean;
  isOnboarding: boolean;
  step: ConnectStep;
  cachedVaultAddress?: string;
  setIsVault: (b: boolean) => void;
};

const ERC20_ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;

const validateErc20 = (address: string): boolean => {
  return ERC20_ADDRESS_REGEX.test(String(address).toLowerCase());
};

const getHeaderIcon = (
  connector: AbstractConnector | undefined,
  isVault: boolean,
) => {
  if (isVault) {
    return vaultLogo;
  }
  if (connector instanceof WalletConnectV2Connector) {
    return walletconnectLogo;
  }

  if (connector instanceof ParticleAuthConnector) {
    return particleWalletLogo;
  }
  return metamaskLogo;
};

const SignatureVerificationState = ({
  connector,
  onBackClick,
  isVault,
  onSignAndVerify,
  areRequestsLocked,
  isOnboarding,
  step,
  cachedVaultAddress,
  setIsVault,
}: Props) => {
  const { account } = useActiveWeb3React();
  const theme: any = useTheme();
  const { t } = useTranslation();
  const { width: windowWidth } = useWindowDimensions();
  const header = getMethodLabelAndIcon({
    connector,
    isVault,
    isInjectedMetamask: isInjectedProviderMetamask(),
  });
  const [vaultAddress, setVaultAddress] = useState('');

  useEffect(() => {
    if (cachedVaultAddress) {
      setIsVault(true);
      setVaultAddress(cachedVaultAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedVaultAddress]);

  const isVaultAddressValid = validateErc20(vaultAddress);

  // if not connected, step 1, if connected but not signed, step 2, if connected and signed, step 3
  const currentStep = step === ConnectStep.ALREADY_SIGN ? 3 : 2;

  const handleCtaClick = () => {
    const connectorName = connector?.constructor.name;
    mixpanelService.connectWalletClicked(
      account ?? vaultAddress,
      connectorName ?? 'N/A',
    );
    onSignAndVerify(vaultAddress);
  };

  useKey(Key.Enter, handleCtaClick);

  return (
    <Container>
      <Header>
        <div>
          <img alt="method" src={getHeaderIcon(connector, isVault)} />
          {header.label}
        </div>
        <Button
          onClick={onBackClick}
          size="S"
          variant="secondaryOutline"
          className="back-button"
          data-cy="wallet-modal-disconnect-button"
        >
          <img src={arrowLeftIcon} alt="<-" />
          {t('back')}
        </Button>
      </Header>

      <StepList
        steps={[
          {
            label: t('connectWallet'),
            description: t('startByConnectingYourWallet'),
          },
          {
            label: t('verifyOwnership'),
            description: t('verifyOwnershipDescription'),
          },
        ]}
        currentStep={currentStep}
        isLoading={isOnboarding}
      />

      {isVault ? (
        <TextInput
          autoFocus
          value={vaultAddress}
          onChange={setVaultAddress}
          label="Vault Address"
          errorMessage={
            'Invalid Ethereum address. Please enter a valid Ethereum address.'
          }
          showError={!!vaultAddress && !isVaultAddressValid}
        />
      ) : null}

      {step === ConnectStep.ALREADY_SIGN ? (
        <Button
          data-testid={'wallet-connect-success-btn'}
          size="M"
          block
          variant="primaryGreen"
          className="primary-button"
        >
          {t('success')}
        </Button>
      ) : (
        <Button
          data-testid={'sign-and-verify-modal'}
          data-gtmid="button-sign"
          onClick={handleCtaClick}
          disabled={isVault && (!vaultAddress || !isVaultAddressValid)}
          size={windowWidth >= theme.screenSizes.default ? 'M' : 'S'}
          block
          variant="primaryGreen"
          className="primary-button"
        >
          {t('signAndVerify')}
        </Button>
      )}
    </Container>
  );
};

export default SignatureVerificationState;
