import { Box } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';

import { brand } from 'utils/brand';

import LinearProgressBar from 'components/LinearProgressBar';
import Text from 'components/Text';

import { AmendStep } from '../enums';
import { Row, WaitingSection } from '../styles';
import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';

import { useTranslation } from 'react-i18next';

const getProgressPercentage = (step: AmendStep, confirmations: number) => {
  if (step !== AmendStep.AWAITING_STAKE_CONFIRMATION) return 0;

  return (confirmations / CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT) * 100;
};

interface Props {
  depositAmount: number | null;
  step: AmendStep;
  confirmations: number;
}

const WaitingStep = ({ depositAmount, step, confirmations }: Props) => {
  const { t } = useTranslation();
  const cappedConfirmations = Math.min(
    confirmations,
    CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
  );
  const progressPercentage = getProgressPercentage(step, cappedConfirmations);

  return (
    <WaitingSection>
      <Row className="mb-10 gap-5">
        <AccessTime />
        <Text variant="BODY_M" color="shadesForeground200">
          {t('depositTokenConfirmation', {
            depositAmount: depositAmount,
            total: CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
            token: brand.tokenTicker,
          })}
        </Text>
      </Row>

      <Box className="linear-progress-container">
        <LinearProgressBar
          percent={progressPercentage}
          customBarColor="#d43b4e"
        />
      </Box>

      <Text variant="BODY_S" color="shadesForeground200">
        {t('currentDepositConfirmations', {
          confirmations: cappedConfirmations,
          total: CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
        })}
      </Text>
    </WaitingSection>
  );
};

export default WaitingStep;
