import { brandedSelect } from 'utils/brand';

import blastIcon from 'assets/assetLogos/blast.svg';
import DaiIcon from 'assets/assetLogos/dai.svg';
import EthIcon from 'assets/assetLogos/eth.svg';
import UsdbIcon from 'assets/assetLogos/usdb.svg';
import UsdcIcon from 'assets/assetLogos/usdc.svg';
import UsdtIcon from 'assets/assetLogos/usdt.svg';
import WbtcIcon from 'assets/assetLogos/wbtc.svg';
import WethIcon from 'assets/assetLogos/weth.svg';

import { Chain, UniswapSwapTokens } from 'enums';

export const getChainIconAndLabel = (chain: Chain) => {
  switch (chain) {
    case Chain.ETH:
      return { icon: EthIcon, label: 'Ethereum' };

    case Chain.Blast:
      return { icon: blastIcon, label: 'Blast' };
    default:
      return { icon: '', label: 'N/A' }; // change to some default later
  }
};

export const getAssetIconAndLabel = (token: UniswapSwapTokens) => {
  switch (token) {
    case UniswapSwapTokens.DAI:
      return { icon: DaiIcon, label: 'DAI' };
    case UniswapSwapTokens.ETH:
      return { icon: EthIcon, label: 'ETH' };
    case UniswapSwapTokens.USDC:
      return { icon: UsdcIcon, label: 'USDC' };
    case UniswapSwapTokens.USDT:
      return { icon: UsdtIcon, label: 'USDT' };
    case UniswapSwapTokens.USDB:
      return { icon: UsdbIcon, label: 'USDB' };
    case UniswapSwapTokens.WBTC:
      return { icon: WbtcIcon, label: 'WBTC' };
    case UniswapSwapTokens.WETH:
      return { icon: WethIcon, label: 'WETH' };
    default:
      return { icon: '', label: 'N/A' };
  }
};

export const getTokenOptionsForChain = (chain: Chain) => {
  switch (chain) {
    case Chain.Blast:
      return [
        brandedSelect({
          rabbitx: UniswapSwapTokens.USDT,
          bfx: UniswapSwapTokens.USDB,
        }),
        UniswapSwapTokens.ETH,
        UniswapSwapTokens.WETH,
      ];
    case Chain.ETH:
      return [
        brandedSelect({
          rabbitx: UniswapSwapTokens.USDT,
          bfx: UniswapSwapTokens.USDB,
        }),
        UniswapSwapTokens.ETH,
        UniswapSwapTokens.USDC,
        UniswapSwapTokens.WETH,
        UniswapSwapTokens.WBTC,
        // Token.DOGE,
      ];
    default:
      return [];
  }
};
