import { memo } from 'react';

import { basisToPercent, timestampToLocalDate } from 'utils';

import CustomTooltip from 'components/RbxAreaChart/CustomTooltip';

import { FundingRate } from 'interfaces';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

type Props = {
  fundingRates: FundingRate[];
};
const FundingRatesChart = ({ fundingRates }: Props) => {
  return (
    <ResponsiveContainer>
      <LineChart data={fundingRates}>
        <XAxis
          dataKey="timestamp"
          tickFormatter={tick => timestampToLocalDate(tick)}
          style={{
            fontSize: '12px',
            lineHeight: '16px',
            fill: '#828DA4',
            fontWeight: '500',
          }}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          style={{
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: '500',
            fill: '#828DA4',
          }}
          tickFormatter={tick => `${basisToPercent(tick)}%`}
        />
        <CartesianGrid
          strokeDasharray="none"
          vertical={false}
          strokeWidth={1}
          stroke="#23304A"
        />
        <Tooltip
          content={
            <CustomTooltip
              content={props => (
                <CustomTooltip {...props} isPercentage={'test'} />
              )}
            />
          }
          wrapperStyle={{ outline: 'none' }}
        />
        <Line type="monotone" dataKey="fundingRate" stroke="#fff" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default memo(FundingRatesChart);
