import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
`;

export const Separator = styled.div`
  height: 1.5px;
  width: 220px;
  margin: 20px auto;
  background: ${({ theme }) => theme.colors.shadesBackground700};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > span {
    font-size: 14px;
    line-height: 16px;
    white-space: pre-wrap;
    color: ${({ theme }) => theme.colors.shadesForeground200};

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 12px;
      line-height: normal;
    }
  }
`;

export const InputContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 500px;

  > input {
    border: none;
    background: transparent;
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    outline: none;
    ::placeholder {
      color: ${({ theme }) => theme.colors.shadesBackground500};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    }
    text-align: center;
    font-size: 24px;
    letter-spacing: -0.48px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 20px;
      letter-spacing: -0.4px;
    }
  }

  .error-message {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.negativeForeground200};
    margin-top: 5px;
    text-align: center;
  }
`;
