import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';

import { FormattedNumber, Icon } from 'components';
import Loading from 'components/Loading';

import MarketStatItem from './components/MarketStatItem';
import { MarketPrice, Seperator } from './style';
import arrowDown from 'assets/icons/arrow-down-new.svg';
import arrowUp from 'assets/icons/arrow-up-new.svg';
import { useAppContext } from 'contexts/AppContext';
import { PriceChangeType } from 'enums/priceChangeType';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  margin-left: 30px;
`;

const MainStats = () => {
  const {
    store: {
      markets: { selectedMarket, lastTradePriceChangeType, isLoading },
    },
  } = useAppContext();
  const { t } = useTranslation();

  const decimalPlaces = useGetDecimalPlaces(selectedMarket?.id);

  if (!selectedMarket) {
    return null;
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  const {
    lastTradePrice,
    percentChangeType,
    lastTradePrice24hChangeBasis,
    lastTradePrice24hChangePremium,
  } = selectedMarket;

  const isPositivePriceChange = percentChangeType === PriceChangeType.Positive;
  const isNegativePriceChange = percentChangeType === PriceChangeType.Negative;

  const changeColor = isPositivePriceChange
    ? 'primaryGreenForeground100'
    : isNegativePriceChange
    ? 'negativeForeground200'
    : 'white';

  return (
    <>
      <MarketPrice>
        <FormattedNumber
          dataTestID="market-price"
          data-cy="last-trade-price"
          prefix="$"
          value={lastTradePrice}
          decimalScale={decimalPlaces.price}
          disableTooltip={true}
          color={
            lastTradePriceChangeType === PriceChangeType.Positive
              ? 'primaryGreenForeground100'
              : 'negativeForeground200'
          }
          variant="NUMBERS_XL"
          fontWeight="semiBold"
        />
      </MarketPrice>
      <Seperator />
      <MarketStatItem
        label={t('24hChange')}
        valueColor={changeColor}
        value={
          <>
            <Icon size="S" src={isPositivePriceChange ? arrowUp : arrowDown} />
            <FormattedNumber
              prefix={`${isPositivePriceChange ? '+' : ''}$`}
              value={lastTradePrice24hChangePremium}
              decimalScale={decimalPlaces.price}
              data-cy="market-price-24h-change-premium"
            />

            <FormattedNumber
              value={lastTradePrice24hChangeBasis * 100}
              suffix="%)"
              prefix={'(' + (isPositivePriceChange ? '+' : '')}
            />
          </>
        }
      />
    </>
  );
};

export default observer(MainStats);
