import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';

import { roundJsDecimalToString } from './helpers';

/**
 * Converts a BigNumber to a float
 * @param bigNumber big number to convert
 * @param decimals number of decimals used for formatting the big number
 * @returns float
 */
export const bigNumberToFloat = (bigNumber: BigNumber, decimals: number) => {
  return parseFloat(roundJsDecimalToString(formatUnits(bigNumber, decimals)));
};

const READABLE_FORM_LEN = 4;

export function fromReadableAmount(
  amount: number,
  decimals: number,
): BigNumber {
  return parseUnits(amount.toString(), decimals);
}

export function toReadableAmount(rawAmount: number, decimals: number): string {
  return formatUnits(rawAmount, decimals).slice(0, READABLE_FORM_LEN);
}
