import { memo } from 'react';

import RewardCard from '../../RewardCard';
import { CardStatus, CardVariant } from '../../RewardCard/enums';
import { Container } from './styles';
import { ReferralLevel } from 'interfaces';


const ClaimedStack = ({ level, volume, comissionBonus , comissionPercent}: ReferralLevel) => {

  const toggleStack = () => {}
  
  return (
    <Container onClick={toggleStack}>
      <RewardCard
        variant={CardVariant.GOAL}
        level={level}
        goal={volume}
        status={CardStatus.IN_PROGRESS}
      />
      <RewardCard
        variant={CardVariant.PRIZE}
        level={level}
        goal={comissionBonus}
        status={CardStatus.IN_PROGRESS}
      />
      <RewardCard
        variant={CardVariant.FEE}
        level={level}
        goal={comissionPercent}
        status={CardStatus.IN_PROGRESS}
      />
    </Container>
  );
};

export default memo(ClaimedStack);
