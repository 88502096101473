import { Token, Ether } from '@uniswap/sdk-core';

import binanceLogo from 'assets/exchanges/binance-dark-rounded.svg';
import bitfinexLogo from 'assets/exchanges/bitfinex.svg';
import coinbaseLogo from 'assets/exchanges/coinbase.svg';
import { UniswapSwapTokens } from 'enums/uniswapSwapTokens';

import { config } from 'config';
import { MeshIntegration } from 'enums';

export const tokensDecimalsMap = {
  [UniswapSwapTokens.USDT]: 6,
  [UniswapSwapTokens.USDB]: 18,
  [UniswapSwapTokens.USDC]: 6,
  [UniswapSwapTokens.WETH]: 18,
  [UniswapSwapTokens.WBTC]: 8,
  [UniswapSwapTokens.DAI]: 18,
};

type UniswapSwapTokensMap = {
  [K in UniswapSwapTokens]: Token;
};

export const NATIVE_CURRENCY = Ether.onChain(config.chainID);

export const uniswapSwapTokensMap: UniswapSwapTokensMap = {
  // Native ETH is handled as WETH
  [UniswapSwapTokens.ETH]: new Token(
    config.chainID,
    config.tokenAddresses.WETH,
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [UniswapSwapTokens.WETH]: new Token(
    config.chainID,
    config.tokenAddresses.WETH,
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [UniswapSwapTokens.USDC]: new Token(
    config.chainID,
    config.tokenAddresses.USDC,
    6,
    'USDC',
    'USD//C',
  ),
  [UniswapSwapTokens.DAI]: new Token(
    config.chainID,
    config.tokenAddresses.DAI,
    18,
    'DAI',
    'Dai Stablecoin',
  ),
  [UniswapSwapTokens.USDT]: new Token(
    config.chainID,
    config.tokenAddresses.USDT,
    6,
    'USDT',
    'Tether USD',
  ),
  [UniswapSwapTokens.USDB]: new Token(
    config.chainID,
    config.tokenAddresses.USDT,
    18,
    'USDB',
    'USDB',
  ),
  [UniswapSwapTokens.WBTC]: new Token(
    config.chainID,
    config.tokenAddresses.WBTC,
    8,
    'WBTC',
    'Wrapped BTC',
  ),
  [UniswapSwapTokens.RBX]: new Token(
    config.chainID,
    config.tokenAddresses.RBX,
    18,
    'RBX',
    'RabbitX',
  ),
};

export const ERC20_ABI = [
  // Read-Only Functions
  'function balanceOf(address owner) view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function symbol() view returns (string)',

  // Authenticated Functions
  'function transfer(address to, uint amount) returns (bool)',
  'function approve(address _spender, uint256 _value) returns (bool)',

  // Events
  'event Transfer(address indexed from, address indexed to, uint amount)',
];

export const WETH_ABI = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'guy', type: 'address' },
      { name: 'wad', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'src', type: 'address' },
      { name: 'dst', type: 'address' },
      { name: 'wad', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: 'wad', type: 'uint256' }],
    name: 'withdraw',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: '', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'dst', type: 'address' },
      { name: 'wad', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [],
    name: 'deposit',
    outputs: [],
    payable: true,
    stateMutability: 'payable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { name: '', type: 'address' },
      { name: '', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  { payable: true, stateMutability: 'payable', type: 'fallback' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'src', type: 'address' },
      { indexed: true, name: 'guy', type: 'address' },
      { indexed: false, name: 'wad', type: 'uint256' },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'src', type: 'address' },
      { indexed: true, name: 'dst', type: 'address' },
      { indexed: false, name: 'wad', type: 'uint256' },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'dst', type: 'address' },
      { indexed: false, name: 'wad', type: 'uint256' },
    ],
    name: 'Deposit',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'src', type: 'address' },
      { indexed: false, name: 'wad', type: 'uint256' },
    ],
    name: 'Withdrawal',
    type: 'event',
  },
];

// Transactions

export const MAX_FEE_PER_GAS = '100000';
export const MAX_PRIORITY_FEE_PER_GAS = '100000';

export enum TransactionState {
  Failed = 'Failed',
  New = 'New',
  Rejected = 'Rejected',
  Sending = 'Sending',
  Sent = 'Sent',
}

// Integrations Ids for mesh are static so we can save them on our FE to show
export const MESH_INTEGRATION_TO_LABLE_ICON_MAP = {
  [MeshIntegration.Binance]: {
    label: 'Binance',
    icon: binanceLogo,
  },

  [MeshIntegration.Coinbase]: {
    label: 'Coinbase',
    icon: coinbaseLogo,
  },
  [MeshIntegration.Bitfinex]: {
    label: 'Bitfinex',
    icon: bitfinexLogo,
  },

  [MeshIntegration.Others]: {
    label: 'Others',
    icon: '',
  },
};
