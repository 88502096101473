// @dev: helpful links for documented and undocumented features
// https://github.com/sonnh58/TradingView/wiki/Widget-Constructor
// https://github.com/StellarCN/firefly/blob/master/src/components/TradingViewChart.vue
// https://github.com/tradingview/charting_library/wiki/Chart-Methods#createstudyname-forceoverlay-lock-inputs-overrides-options
// https://github.com/mmmy/css3demos/wiki/Overrides
import { useEffect } from 'react';

import { isBrandBfx } from 'utils/brand';

import { TV_CHART_RELOAD_INTERVAL } from '../../constants/general';
import { useAppContext } from '../../contexts/AppContext';
import { LocalStorageSaveLoadAdapter } from './LocalStorageSaveLoadAdapter';
import {
  IChartingLibraryWidget,
  IExternalSaveLoadAdapter,
  ResolutionString,
  SeriesStyle,
  VisibleTimeRange,
} from './charting_library';
import { LS_TV_CHART_RELOAD_TIME } from 'constants/localStorageKeys';
import { theme } from 'theme/Theme';

import styled from 'styled-components';

export const BarStyles = {
  BARS: 0,
  CANDLES: 1,
  LINE: 2,
  AREA: 3,
  RENKO: 4,
  KAGI: 5,
  POINT_AND_FIGURE: 6,
  LINE_BREAK: 7,
  HEIKIN_ASHI: 8,
  HOLLOW_CANDLES: 9,
};

export const LineStyle = {
  Solid: 0,
  Dotted: 1,
  Dashed: 2,
};

interface TVChartContainerProps {
  symbol?: string;
  interval?: string;
  fullscreen?: boolean;
  container?: string;
  datafeed?: any;
  library_path?: string;
  chartType?: SeriesStyle;

  debug?: boolean;
  locale?: string;
  disabled_features?: string[];
  enabled_features?: string[];
  charts_storage_url?: string;
  charts_storage_api_version?: string;
  client_id?: string;
  preset?: string;
  user_id?: string;
  autosize?: boolean;
  studies_overrides?: any;
  overrides?: any;
  timeframe?: any;
  toolbar_bg?: string;
  custom_css_url?: string;
  loading_screen?: any;
  favorites?: any;
  time_frames?: any;
  theme?: 'Light' | 'Dark';
  timezone?: string;
  onIntervalChanged?: (resolution: ResolutionString) => void;
  onVisibleRangeChanged?: (range: VisibleTimeRange) => void;
  onChartTypeChanged?: (chartType: SeriesStyle) => void;
  onChartReady?: () => void;
  resetCache?;
  load_last_chart?: boolean;
  save_load_adapter?: IExternalSaveLoadAdapter;
}

const defaultProps: TVChartContainerProps = {
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  symbol: 'BTC/USD',
  interval: '1D',
  fullscreen: false,
  container: 'tv_chart_container',
  library_path: '/charting_library/',
  time_frames: [
    { text: '1m', resolution: '1', description: '1 Minute' },
    { text: '5m', resolution: '5', description: '5 Minutes' },
    { text: '15m', resolution: '15', description: '15 Minutes' },
    { text: '30m', resolution: '30', description: '30 Minutes' },
    { text: '1h', resolution: '60', description: '1 Hour' },
    { text: '4h', resolution: '240', description: '4 Hours' },
    { text: '1d', resolution: '1D', description: '1 Day' },
  ],
  theme: 'Dark',
  toolbar_bg: isBrandBfx
    ? theme.colors.shadesBackground700TransparentChart
    : theme.colors.shadesBackground800,
  custom_css_url: isBrandBfx ? './themeBFX.css' : './theme.css',
  loading_screen: {
    backgroundColor: isBrandBfx
      ? theme.colors.shadesBackground700TransparentChart
      : theme.colors.shadesBackground800,
  },
  debug: false,
  locale: 'en',
  disabled_features: [
    'volume_force_overlay',
    'show_right_widgets_panel_by_default',
    'show_object_tree',
    'show_order_panel_on_start',
    'trading_account_manager',
    'header_symbol_search',
    'symbol_search_hot_key',
    'right_toolbar',
    'popup_hints',
    'order_panel',
    'header_compare',
    'go_to_date',
  ],
  enabled_features: ['study_templates', 'hide_volume'],
  client_id: 'tradingview.com',
  user_id: 'public_user_id',
  autosize: true,
  studies_overrides: {
    /*'volume.volume.transparency': 50,
    'volume.volume.color.0': 'rgba(174,78,84,0.7)',
    'volume.volume.color.1': 'rgba(88,144,101,0.7)',*/
  },

  overrides: {
    'paneProperties.backgroundType': 'solid',
    'paneProperties.background': isBrandBfx
      ? theme.colors.shadesBackground700TransparentChart
      : theme.colors.shadesBackground800,
    'paneProperties.vertGridProperties.color': isBrandBfx
      ? theme.colors.shadesBackground700
      : theme.colors.shadesBackground700TransparentChart,
    'paneProperties.horzGridProperties.color': isBrandBfx
      ? theme.colors.shadesBackground700
      : theme.colors.shadesBackground700TransparentChart,
    'mainSeriesProperties.style': BarStyles.CANDLES,
    volumePaneSize: 'medium',

    /*'symbolWatermarkProperties.transparency': 90,
    'paneProperties.legendProperties.showBarChange': false,
    'paneProperties.legendProperties.showSeriesOHLC': false,
    'paneProperties.legendProperties.showStudyValues': false,

    'mainSeriesProperties.priceAxisProperties.percentage': false,
    'mainSeriesProperties.priceAxisProperties.percentageDisabled': false,
    'mainSeriesProperties.priceAxisProperties.log': false,
    'mainSeriesProperties.priceAxisProperties.logDisabled': false,


    'paneProperties.bottomMargin': 48,
    'scalesProperties.showLeftScale': true,
    'scalesProperties.showRightScale': true,
    'scalesProperties.scaleSeriesOnly': false,
    'mainSeriesProperties.extendedHours': false,*/
  },
  load_last_chart: true,
  save_load_adapter: new LocalStorageSaveLoadAdapter(),
};

export const getWidget = (): IChartingLibraryWidget => {
  // @ts-ignore
  return window?.tvWidget;
};

const TVChartContainerStyled = styled.div`
  height: 100%;
`;

const hasChart = () => {
  let ret = false;
  try {
    // @ts-ignore
    ret = window.tvWidget.chartsCount() > 0;
  } catch (e: any) {}

  return ret;
};

export const TVChartContainer = (props: TVChartContainerProps) => {
  const {
    store: { tvConfig, account: accountStore },
  } = useAppContext();

  useEffect(() => {
    if (!window || !window.TradingView) return;

    // @ts-ignore
    if (window.tvWidget && hasChart()) return;

    if (props.chartType) {
      let overrides = { ...props?.overrides };
      overrides['mainSeriesProperties.style'] = props.chartType;
      defaultProps.overrides = { ...defaultProps.overrides, ...overrides };
    }

    if (props.preset) {
      defaultProps.preset = props.preset;
      if ('mobile' === props.preset) {
        let mobilePrimary = tvConfig.config.mobileBackgroundColor;
        defaultProps.disabled_features = [
          ...defaultProps.disabled_features!,
          // 'create_volume_indicator_by_default',
          'legend_widget',
          'main_series_scale_menu',
          'axis_pressed_mouse_move_scale',
          'vert_touch_drag_scroll',
        ];
        defaultProps.toolbar_bg = mobilePrimary;
        defaultProps.custom_css_url = './theme-mobile.css';
        defaultProps.loading_screen.backgroundColor = mobilePrimary;
        defaultProps.overrides['paneProperties.background'] = mobilePrimary;
        defaultProps.overrides['paneProperties.vertGridProperties.color'] =
          mobilePrimary;
        defaultProps.overrides['paneProperties.horzGridProperties.color'] =
          mobilePrimary;

        defaultProps.disabled_features = defaultProps.disabled_features.filter(
          item => item !== 'volume_force_overlay',
        );
        defaultProps.overrides['scalesProperties.fontSize'] = 10;
        defaultProps.overrides['scalesProperties.showStudyLastValue'] = false;

        defaultProps.theme = mobilePrimary.includes('FFFFFF')
          ? 'Light'
          : 'Dark';
        if (mobilePrimary.includes('FFFFFF'))
          defaultProps.custom_css_url = './theme-mobile-FFFFFF.css';
        else if (mobilePrimary.includes('0A0E18'))
          defaultProps.custom_css_url = './theme-mobile-0A0E18.css';
      }
    }

    // @ts-ignore
    const widget = (window.tvWidget = new window.TradingView.widget({
      ...defaultProps,
      ...props,
    }));

    widget.onChartReady(() => {
      //@ts-ignore
      // widget.chart().createStudy('Volume', false, false, 'volume', {});
      if ('mobile' === props.preset) {
        widget.chart().getTimeScale().defaultRightOffset().setValue(2);
      }
      const studies = widget.chart().getAllStudies();
      const movingAverageTriple = 'Moving Average Triple';

      if (!studies.find(s => s.name === movingAverageTriple)) {
        widget
          .chart()
          .createStudy(movingAverageTriple, false, false, [7, 25, 100], {
            showLabelsOnPriceScale: false,
            'Plot 1.color': 'rgba(255, 109, 0, 0.7)',
            'Plot 2.color': 'rgba(33, 150, 243, 0.7)',
            'Plot 3.color': 'rgba(38, 198, 218, 0.7)',
          });
      }

      /*const macd = 'MACD';
      if (!studies.find(s => s.name === macd)) {
        widget.chart().createStudy('Stochastic RSI', false, false, [21, 21, 14, 14], {
          '%K.color': '#ff6d00',
          '%K.linewidth': 1.5,
          '%K.transparency': 0,
          '%D.color': '#2962ff',
          '%D.linewidth': 1.5,
          '%D.transparency': 0,
          'UpperLimit.value': 80,
          'UpperLimit.color': theme.colors.shadesForeground50,
          'LowerLimit.value': 20,
          'LowerLimit.color': theme.colors.shadesForeground50,
        });
        widget.chart().createStudy(macd, false, false, [12, 26, 'close', 9], {
          'histogram.color.0': 'rgba(34, 171, 148, 0.7)',
          'histogram.color.1': 'rgba(172, 229, 220, 0.7)',
          'histogram.color.2': 'rgba(252, 203, 205, 0.7)',
          'histogram.color.3': 'rgba(255, 82, 82, 0.7)',
          'macd.color': 'rgba(33, 150, 243, 0.7)',
          'signal.color': 'rgba(255, 109, 0, 0.7)',
        });
      }

      setTimeout(() => {
        widget.chart().getPanes()[2]?.setHeight(90);
      }, 50);*/

      widget
        .chart()
        .onIntervalChanged()
        .subscribe(null, (resolution: ResolutionString) => {
          tvConfig.setResolution(resolution);
          setTimeout(() => {
            widget.saveChartToServer(
              () => {},
              () => {},
              { defaultChartName: 'Default' },
            );
            widget.activeChart().executeActionById('timeScaleReset');
          }, 1500);
          setTimeout(() => {
            accountStore.setIsOrdersOrPositionsChanged();
          }, 500);
        });

      if (props.onVisibleRangeChanged) {
        widget
          .chart()
          .onVisibleRangeChanged()
          .subscribe(null, props.onVisibleRangeChanged);
      }

      if (props.onChartTypeChanged) {
        widget
          .chart()
          .onChartTypeChanged()
          .subscribe(null, props.onChartTypeChanged);
      }

      if (props.onChartReady) {
        widget.applyOverrides(defaultProps.overrides);
        widget.onChartReady(props.onChartReady);
      }

      widget.subscribe('onAutoSaveNeeded', () => {
        widget.saveChartToServer(
          () => {},
          () => {},
          { defaultChartName: 'Default' },
        );
      });
    });

    widget.headerReady().then(function () {
      const button = widget.createButton();
      const changeStyle = button => {
        const style = tvConfig.config.showOrderLines
          ? 'apply-common-tooltip customButton-LZaMRgb9 order-lines-active'
          : 'apply-common-tooltip customButton-LZaMRgb9';
        button.setAttribute('class', style);
      };
      changeStyle(button);
      button.addEventListener('click', function () {
        tvConfig.setShowOrderLines(!tvConfig.config.showOrderLines);
        changeStyle(button);
        accountStore.setIsOrdersOrPositionsChanged();
      });
      button.textContent = 'Order Lines';
    });

    // if the tab is inactive or system is in sleep mode for a long time, the historical data will be outdated. By resetting it'll call bars endpoint again
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        localStorage.setItem(LS_TV_CHART_RELOAD_TIME, Date.now().toString());
      } else {
        const tvReloadTimestamp = Number(
          localStorage.getItem(LS_TV_CHART_RELOAD_TIME),
        );
        if (
          tvReloadTimestamp &&
          Date.now() - tvReloadTimestamp > TV_CHART_RELOAD_INTERVAL
        ) {
          if (widget && widget.chart() && props.resetCache) {
            props.resetCache?.current?.();
            widget.chart().resetData();
          }
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      getWidget() && getWidget().remove();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [props.symbol]);

  return (
    <TVChartContainerStyled
      id={props.container || defaultProps.container}
      className={'TVChartContainer'}
    />
  );
};
