import { useEffect, useState, useRef } from 'react';

import { parseUnits } from '@ethersproject/units';

import {
  roundJsDecimalToString,
  showNotification,
  verifyUserSession,
} from 'utils';
import { isBrandBfx } from 'utils/brand';

import {
  Key,
  useActiveWeb3React,
  useTokenLockAPI,
  useVerifyChainId,
} from 'hooks';
import useModal from 'hooks/useModal';
import useOnBlockUpdated from 'hooks/useOnBlockUpdated';
import useUniswapSdk from 'hooks/useUniswapSdk';

import Button from 'components/Button/button';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import Steps from 'components/ProgressiveSteps';
import SlippageSettings from 'components/SlippageSettingsModal';
import Text from 'components/Text';

import { RateAndSlippageDetails } from '../RateAndSlippageDetails';
import SwapStepTitle, { RbxBadge } from './SwapStepTitle';
import { RBX_DECIMALS } from 'constants/contracts';
import { Modals } from 'constants/modals';
import { getSwapButtonLabel } from 'pages/Trade/components/AccountStats/NewDepositModal/ActionSection';
import { uniswapSwapTokensMap } from 'pages/Trade/components/AccountStats/NewDepositModal/constants';
import {
  getCurrentSwapStep,
  SwapSteps,
} from 'pages/Trade/components/AccountStats/NewDepositModal/utils';
import { mixpanelService } from 'service/mixpanelService';
import { Row } from 'theme/globalStyledComponents';

import { config } from 'config';
import { NotificationType, QueryKeys, UniswapSwapTokens } from 'enums';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .button-and-other-actions-container {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }
`;

const getCtaText = ({
  isAwaitingConfirmation,
  pendingTxSign,
  isApproving,
  isRbxApproved,
  currentSubStepIndex,
}: {
  isAwaitingConfirmation: boolean;
  pendingTxSign: boolean;
  isApproving: boolean;
  isRbxApproved: boolean;
  currentSubStepIndex: number;
}) => {
  // All steps done
  if (currentSubStepIndex === 2) {
    return 'Success';
  }

  if (isAwaitingConfirmation) {
    return 'Awaiting Confirmation...';
  }

  if (pendingTxSign) {
    return 'Pending...';
  }

  if (isApproving) {
    return 'Approving RBX...';
  }

  return isRbxApproved ? 'Lock' : 'Approve';
};

const getShouldDisableCta = ({
  inputValue,
  isAwaitingConfirmation,
  pendingTxSign,
  isApproving,
  isSwapping,
}: {
  inputValue: number | null;
  isAwaitingConfirmation: boolean;
  pendingTxSign: boolean;
  isApproving: boolean;
  isSwapping: boolean;
}) => {
  if (isAwaitingConfirmation || pendingTxSign || isApproving || isSwapping) {
    return true;
  }

  return false;
};

type Props = {
  title: string;
  showSwapStep?: boolean;
  defaultSlippage: number;
  inputAmount: number;
  selectedAsset?: UniswapSwapTokens;
  onShowConfetti?: () => void;
};
const SwapAndLockModal = ({
  title,
  defaultSlippage,
  showSwapStep = false,
  inputAmount,
  selectedAsset = UniswapSwapTokens.RBX,
  onShowConfetti,
}: Props) => {
  const queryClient = useQueryClient();
  const modal = useModal();
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentSubStepIndex, setCurrentSubStepIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [slippage, setSlippage] = useState(defaultSlippage);
  const isModalOpen = useRef(true);

  const [pendingTxSign, setPendingTxSign] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isAwaitingConfirmation, setIsAwaitingConfirmation] = useState(false);
  const [isTokenApproved, setIsTokenApproved] = useState(false);

  const [swapStepDone, setSwapStepDone] = useState(false);
  const [lockAmount, setLockAmount] = useState<number | null>(inputAmount);

  const {
    lockTokensRbx,
    isRbxApprovedForContract,
    approveRbxForContract,
    areReadContractsSet,
  } = useTokenLockAPI();

  const {
    getQuoteV2,
    getQuote,
    getCurrencyBalance,
    createTrade,
    executeTrade,
    executeTradeV2,
    isTokenApprovedForSwap,
    approveTokenForSwap,
  } = useUniswapSdk();

  useOnBlockUpdated(() => {
    refetchQuote();
  });

  const {
    isLoading: isQuoteLoading,
    data: quote,
    refetch: refetchQuote,
  } = useQuery(
    [QueryKeys.SwapQuote, lockAmount, selectedAsset],
    () =>
      isBrandBfx
        ? getQuoteV2({
            tokenIn: uniswapSwapTokensMap[selectedAsset],
            tokenOut: uniswapSwapTokensMap.RBX,
            amountIn: lockAmount!,
          })
        : getQuote({
            tokenIn: uniswapSwapTokensMap[selectedAsset],
            tokenOut: uniswapSwapTokensMap.RBX,
            amountIn: lockAmount!,
          }),
    {
      enabled: !!lockAmount && selectedAsset !== UniswapSwapTokens.RBX,
      // 10 seconds
      staleTime: 10_000,
    },
  );

  const onReloadRate = () => {
    queryClient.removeQueries(QueryKeys.SwapQuote);
    refetchQuote();
  };

  const {
    // isLoading: isTokenApprovedForSwapLoading,
    data: isTokenApprovedForSwapData,
    refetch: refetchIsTokenApprovedForSwap,
  } = useQuery(
    [QueryKeys.IsTokenApprovedForSwap, account, lockAmount, selectedAsset],
    () =>
      isTokenApprovedForSwap({
        token: uniswapSwapTokensMap[selectedAsset],
        walletAddress: account!,
        amount: lockAmount!,
        isBlasterSwap: true,
      }),
    {
      enabled: !!lockAmount && showSwapStep && !!account,
      // 10 seconds
      staleTime: 10_000,
    },
  );
  const [isSwapping, setIsSwapping] = useState(false);
  const [isApprovingTokenForSwap, setisApprovingTokenForSwap] = useState(false);
  const [isPendingTxSign, setIsPendingTxSign] = useState(false);

  const swapStep = getCurrentSwapStep({
    isTokenApprovedForSwap: isTokenApprovedForSwapData ?? false,
    isApprovingToken: isApprovingTokenForSwap,
    isSwapping,
    isPendingTxSign,
  });

  const swapButtonLabel = getSwapButtonLabel({
    step: swapStep,
    t,
    selectedToken: selectedAsset,
  });

  useEffect(() => {
    const fetchRbxApproval = async () => {
      if (!account || !lockAmount || !areReadContractsSet) return;

      if (showSwapStep && !swapStepDone) {
        if (isTokenApprovedForSwapData) setCurrentSubStepIndex(1);
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);

        const isRbxApproved = await isRbxApprovedForContract(
          config.RbxTokenLockContractAddress,
          lockAmount,
        );

        setIsTokenApproved(isRbxApproved);
        if (isRbxApproved) {
          // If RBX is already approved, move to the next step
          if (!showSwapStep) {
            setCurrentSubStepIndex(1);
          }
          // If swap step is enabled, move to the next step only if the current step is locking
          else if (showSwapStep && currentStepIndex !== 1) {
            setCurrentSubStepIndex(1);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRbxApproval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account,
    lockAmount,
    swapStepDone,
    isTokenApprovedForSwapData,
    areReadContractsSet,
  ]);

  const steps: any[] = [
    {
      title: (
        <Row gap={5}>
          Locking your <RbxBadge />
        </Row>
      ),
      description: 'This action will create your vesting contract',
      subSteps: [
        {
          title: 'Approve RBX',
        },
        {
          title: 'Lock RBX',
        },
      ],
    },
  ];

  if (showSwapStep) {
    steps.unshift({
      title: <SwapStepTitle selectedAsset={selectedAsset} />,
      description: `${
        currentStepIndex > 0 ? 'Optimised' : 'Optimising'
      } price routing for cheapest rate`,
      subSteps: [
        {
          title: 'Approve for spending',
        },
        { title: 'Execute Swap' },
      ],
    });
  }

  // Enable USDT
  const approveRbx = async () => {
    try {
      if (!account || !lockAmount) return;

      showNotification({
        title: `Approving RBX`,
        description: `Approving RBX for lock. Confirm the transaction in your wallet.`,
        type: NotificationType.Info,
      });

      flushSync(() => {
        setPendingTxSign(true);
      });
      const tx = await approveRbxForContract(
        config.RbxTokenLockContractAddress,
      );
      flushSync(() => {
        setPendingTxSign(false);
        setIsApproving(true);
      });
      showNotification({
        title: `Waiting for RBX approval to complete`,
        description: `The approval transaction has been sent. Waiting for it to complete.`,
        type: NotificationType.Info,
      });
      await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showNotification({
        title: `RBX Approval Successful.`,
        description: 'You can now proceed with the lock.',
        type: NotificationType.Positive,
      });
      flushSync(() => {
        setCurrentSubStepIndex(1);
        setIsTokenApproved(true);
      });
      return true;
    } catch (e: any) {
      showNotification({
        title: t('tokenApprovalFailedTitle', { token: 'RBX' }),
        description: t('tokenApprovalFailedDescription', {
          errorMessage: e.message,
          token: 'RBX',
        }),
        type: NotificationType.Negative,
      });

      return false;
    } finally {
      setPendingTxSign(false);
      setIsApproving(false);
    }
  };

  // Approve selected token for swap
  const approveSelectedToken = async () => {
    if (
      !(await validateNetworkAndSwitchIfRequired(
        `Approve ${selectedAsset} again.`,
      ))
    ) {
      return;
    }

    try {
      if (!account || !lockAmount) return;

      showNotification({
        title: `Approving ${selectedAsset}`,
        description: `Approving ${selectedAsset} for swap. Confirm the transaction in your wallet.`,
        type: NotificationType.Info,
      });
      flushSync(() => {
        setIsPendingTxSign(true);
      });
      const tx = await approveTokenForSwap(
        uniswapSwapTokensMap[selectedAsset],
        account,
        undefined,
        true,
      );
      flushSync(() => {
        setIsPendingTxSign(false);
        setisApprovingTokenForSwap(true);
      });
      showNotification({
        title: `Waiting for ${selectedAsset} approval to complete`,
        description: `The approval transaction has been sent. Waiting for it to complete.`,
        type: NotificationType.Info,
      });
      await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showNotification({
        title: 'Token Approval Successful',
        type: NotificationType.Positive,
      });
      return true;
    } catch (e: any) {
      console.error('Token approval failed', e);
      showNotification({
        title: 'Token Approval Failed',
        type: NotificationType.Negative,
      });
      return false;
    } finally {
      refetchIsTokenApprovedForSwap();
      setisApprovingTokenForSwap(false);
      setIsPendingTxSign(false);
    }
  };

  const onSwap = async () => {
    try {
      if (!lockAmount || !account) return;

      if (!(await validateNetworkAndSwitchIfRequired('Initiate Swap Again'))) {
        return;
      }

      flushSync(() => setIsPendingTxSign(true));

      showNotification({
        type: NotificationType.Info,
        title: 'Swap Started',
        description:
          'Your swap has started. Confirm the transaction in your wallet.',
      });

      let tx;
      if (isBrandBfx) {
        tx = await executeTradeV2({
          quote,
          tokenIn: uniswapSwapTokensMap[selectedAsset],
          tokenOut: uniswapSwapTokensMap.RBX,
          amount: lockAmount as number,
          walletAddress: account as string,
          slippageTolerance: slippage,
          selectedAsset: selectedAsset,
        });
      } else {
        const trade = await createTrade({
          tokenIn: uniswapSwapTokensMap[selectedAsset],
          tokenOut: uniswapSwapTokensMap.RBX,
          amountIn: lockAmount,
        });
        tx = await executeTrade({
          trade,
          tokenIn: uniswapSwapTokensMap[selectedAsset],
          tokenOut: uniswapSwapTokensMap.RBX,
          amount: lockAmount as number,
          walletAddress: account as string,
          slippageTolerance: slippage,
        });
      }

      flushSync(() => setIsSwapping(true));

      showNotification({
        type: NotificationType.Positive,
        title: 'Swap transaction sent',
        description:
          'Your swap transaction has been sent. Waiting for it to complete.',
      });

      await tx.wait();

      console.log('tx : ', tx);

      showNotification({
        type: NotificationType.Positive,
        title: 'Swap Successful',
        description: 'Your swap was successful.',
      });

      const balance = await getCurrencyBalance(
        account as string,
        uniswapSwapTokensMap.RBX,
      );
      setLockAmount(balance);
      setSwapStepDone(true);
      setCurrentStepIndex(1);
      setCurrentSubStepIndex(0);
    } catch (e) {
      console.error(e);
      showNotification({
        type: NotificationType.Negative,
        title: 'Swap Failed',
        description: 'Your swap failed. Please try again.',
      });
      setIsPendingTxSign(false);
    } finally {
      refetchQuote();
      flushSync(() => {
        setIsSwapping(false);
      });
    }
  };

  const proceedToNextSwapStep = async (swapStep: SwapSteps) => {
    try {
      if (swapStep === SwapSteps.APPROVE_TOKEN) {
        if (await approveSelectedToken()) await onSwap();
      }

      if (swapStep === SwapSteps.SWAP) {
        await onSwap();
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const resetData = () => {
    queryClient.invalidateQueries(QueryKeys.YourTokenLocks);
    queryClient.invalidateQueries(QueryKeys.TotalTokensLocked);
    queryClient.invalidateQueries(QueryKeys.AccountRbxBalance);
    queryClient.invalidateQueries(QueryKeys.LockCutoffTime);
  };

  const onSlippageEdit = () => {
    modal.present(
      <SlippageSettings
        defaultSlippage={slippage}
        onUpdate={updatedSlippage => {
          setSlippage(updatedSlippage);
        }}
      />,
      Modals.slippageSettingsModal,
    );
  };

  let ctaText = getCtaText({
    isAwaitingConfirmation,
    pendingTxSign,
    isApproving,
    isRbxApproved: isTokenApproved,
    currentSubStepIndex,
  });

  if (showSwapStep && !swapStepDone && swapButtonLabel)
    ctaText = swapButtonLabel;

  const shouldDisableCta = getShouldDisableCta({
    inputValue: lockAmount,
    isAwaitingConfirmation,
    pendingTxSign,
    isApproving,
    isSwapping,
  });

  const onLockTokens = async () => {
    if (shouldDisableCta) return;

    if (showSwapStep && !swapStepDone) {
      proceedToNextSwapStep(swapStep);
      return;
    }

    // Close the modal if all steps are done
    if (currentSubStepIndex === 2) {
      modal.clear();
      return;
    }

    if (!lockAmount || !account) return;

    flushSync(() => {
      setPendingTxSign(true);
    });

    if (!(await validateNetworkAndSwitchIfRequired('Restart the lock'))) {
      return;
    }

    mixpanelService.stakeLockRbxClicked(account, lockAmount);

    try {
      // Check if RBX is approved for the lock amount
      const isRbxApproved = await isRbxApprovedForContract(
        config.RbxTokenLockContractAddress,
        lockAmount,
      );

      if (!isRbxApproved) {
        const res = await approveRbx();
        if (!res) {
          return;
        }
      }

      showNotification({
        title: 'Token Lock Started',
        description: 'Please wait until the transaction is confirmed',
        type: NotificationType.Positive,
      });

      const tx = await lockTokensRbx(
        parseUnits(
          roundJsDecimalToString(lockAmount, RBX_DECIMALS),
          RBX_DECIMALS,
        ),
      );

      flushSync(() => {
        setIsAwaitingConfirmation(true);
        setPendingTxSign(false);
      });

      await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      flushSync(() => {
        setIsAwaitingConfirmation(false);
        setCurrentSubStepIndex(2);
      });

      showNotification({
        title: 'Successfully locked tokens',
        description: 'Your tokens have been locked successfully',
        type: NotificationType.Positive,
      });

      mixpanelService.stakeLockRbxSuccess(account, lockAmount);

      if (!isModalOpen.current) {
        return;
      }

      onShowConfetti && onShowConfetti();
      setTimeout(() => {
        modal.clear();
      }, 2000);
    } catch (e) {
      console.error(e);
      showNotification({
        title: 'Error locking tokens',
        description: 'An error occurred while locking your tokens',
        type: NotificationType.Negative,
      });
    } finally {
      resetData();
      setPendingTxSign(false);
      setIsAwaitingConfirmation(false);
    }
  };

  return (
    <Modal
      name={Modals.lockTokenModal}
      showHeader={false}
      showCloseIcon={false}
      size="small"
      onClose={() => {
        isModalOpen.current = false;
        modal.clear();
      }}
      handleKeyStrokes={{ [Key.Enter]: onLockTokens }}
    >
      <Container>
        {isLoading && (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}

        {!isLoading && (
          <>
            <Text
              variant="BODY_L"
              fontWeight="semiBold"
              className="align-center"
            >
              {title}
            </Text>

            <Steps
              steps={steps}
              currentStepIndex={currentStepIndex}
              currentSubStepIndex={currentSubStepIndex}
            />

            <div className="button-and-other-actions-container">
              {showSwapStep && !swapStepDone && (
                <RateAndSlippageDetails
                  onRateReload={onReloadRate}
                  isUpdatingQuote={isQuoteLoading}
                  onSlippageEdit={onSlippageEdit}
                  slippage={slippage}
                  amount={lockAmount}
                  rate={quote}
                  asset={selectedAsset.toUpperCase()}
                />
              )}
              <Button
                sizeVariant="S"
                colorVariant="primaryGreen"
                block
                onClick={onLockTokens}
                disabled={shouldDisableCta}
              >
                {ctaText}
              </Button>
            </div>
          </>
        )}
      </Container>
    </Modal>
  );
};

export default SwapAndLockModal;
