import { useState } from 'react';

import { basisToPercent, capitalizeFirstLetter } from 'utils';

import { ButtonSwitch, ButtonSwitchOption, FormattedNumber } from 'components';
import Loading from 'components/Loading';
import Text from 'components/Text';

import FundingRatesChart from './FundingRatesChart';
import { useAppContext } from 'contexts/AppContext';
import { MarketService } from 'service/marketService';

import { QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';

type DaysOptions = '7 Days' | '14 Days' | '30 Days';

const initialDaysOption: ButtonSwitchOption<DaysOptions>[] = [
  { text: '7 Days', selected: true },
  { text: '14 Days', selected: false },
  { text: '30 Days', selected: false },
];

const daysOptionToDaysNumber: {
  [key in DaysOptions]: number;
} = {
  '7 Days': 7,
  '14 Days': 14,
  '30 Days': 30,
};

const getTimestampFromDaysAgo = (
  currentTimestamp: number,
  daysAgo: DaysOptions,
) => {
  return (
    currentTimestamp -
    daysOptionToDaysNumber[daysAgo] * 24 * 60 * 60 * 1000 * 1000
  );
};

const marketService = MarketService.get();

const FundingRatesChartContainer = () => {
  const {
    store: {
      markets: { selectedMarketId, selectedMarket },
    },
  } = useAppContext();

  const [days, setDays] = useState<DaysOptions>('7 Days');

  const currentTimestamp = Date.now();
  const timestampFromDaysAgo = getTimestampFromDaysAgo(
    currentTimestamp * 1000,
    days,
  );

  const {
    isLoading: isLoadingFundingRates,
    data,
    isError,
  } = useQuery(
    [QueryKeys.FundingRates, selectedMarketId, days],
    async () => {
      const data = await marketService.fetchFundingRatesForMarket({
        market_id: selectedMarketId,
        start_time: timestampFromDaysAgo,
        p_limit: 720,
        p_order: 'ASC',
      });
      return data;
    },
    {
      refetchInterval: 10_000,
      enabled: !!selectedMarketId,
    },
  );

  const fundingRates = data?.data;

  return (
    <>
      <div className="header">
        <Text variant="BODY_M">
          Funding Rate:{' '}
          <FormattedNumber
            value={basisToPercent(selectedMarket?.lastFundingRateBasis) ?? 0}
            suffix="%"
            decimalScale={6}
          />
        </Text>
        <ButtonSwitch
          displayTextTransform={(text: string | number) =>
            capitalizeFirstLetter(text as string)
          }
          initialOptions={initialDaysOption}
          onOptionSelect={value => setDays(value as DaysOptions)}
        />
      </div>
      <div className="chart">
        {!fundingRates || isError || isLoadingFundingRates ? (
          isError ? (
            'Something went wrong.'
          ) : (
            <div className="flex justify-center items-center">
              <Loading />
            </div>
          )
        ) : (
          <FundingRatesChart fundingRates={fundingRates} />
        )}
      </div>
    </>
  );
};

export default observer(FundingRatesChartContainer);
