import { Box, Tooltip } from '@material-ui/core';

import { numToString } from 'utils';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';

import { FormattedNumber } from 'components';
import TextToolTip from 'components/InfoTooltip/TextToolTip';
import Loading from 'components/Loading';
import Text from 'components/Text';

import { tierToFee } from '../AccountStats/VipTier';
import {
  ContractDetailsStyled,
  ContractDetailsStyledProps,
  StatRow,
} from './styles';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const ContractDetails = (props: ContractDetailsStyledProps) => {
  const {
    store: {
      markets: { _selectedMarket, isLoading },
      account: { accountStats },
    },
  } = useAppContext();
  const { t } = useTranslation();

  const decimalPlaces = useGetDecimalPlaces(_selectedMarket?.id);

  const currentTier = accountStats?.tierStatus?.current?.tier;

  const tierFees =
    currentTier !== undefined ? tierToFee[currentTier] : undefined;

  return (
    <ContractDetailsStyled {...props}>
      <Text fontWeight="semiBold" variant="BODY_M" className="title">
        {t('contractDetails')}
      </Text>

      {isLoading ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Box className="stats" data-cy="contract-details">
          <StatRow>
            <Tooltip title={t('indexPriceTooltip')}>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('indexPrice')}
              </Text>
            </Tooltip>
            <Text variant="BODY_M" data-cy="index-price">
              <FormattedNumber
                value={_selectedMarket?.indexPrice}
                decimalScale={decimalPlaces.price}
              />
            </Text>
          </StatRow>
          <StatRow>
            <Tooltip title={t('fairPriceTooltip')}>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('fairPrice')}
              </Text>
            </Tooltip>
            <Text variant="BODY_M" data-cy="fair-price">
              <FormattedNumber
                value={_selectedMarket?.fairPrice}
                decimalScale={decimalPlaces.price}
              />
            </Text>
          </StatRow>
          <StatRow>
            <Tooltip title={t('maintenanceMarginTooltip')}>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('maintenanceMargin')}
              </Text>
            </Tooltip>
            <Text variant="BODY_M">
              <FormattedNumber
                value={
                  _selectedMarket?.maintenanceMargin &&
                  _selectedMarket?.maintenanceMargin * 100
                }
                suffix="%"
              />
            </Text>
          </StatRow>
          <StatRow>
            <Tooltip title={t('minTickTooltip')}>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('minTick')}
              </Text>
            </Tooltip>
            <Text variant="BODY_M" data-cy="min-tick">
              {numToString(_selectedMarket?.minTick) ?? '-'}
            </Text>
          </StatRow>
          <StatRow>
            <Tooltip title={t('minOrderSizeTooltip')}>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('minOrderSize')}
              </Text>
            </Tooltip>
            <Text variant="BODY_M" data-cy="min-order">
              {_selectedMarket?.minOrder ?? '-'}
            </Text>
          </StatRow>

          {tierFees ? (
            <>
              <StatRow>
                <Text variant="BODY_S" color="shadesForeground200">
                  {t('fees')} [{t('vip') + ' ' + currentTier}]
                </Text>

                <Text variant="BODY_M">
                  <TextToolTip title={t('makerFee')}>
                    <>{tierFees.maker}%</>
                  </TextToolTip>{' '}
                  /{' '}
                  <TextToolTip title={t('takerFee')}>
                    <>{tierFees.taker}%</>
                  </TextToolTip>
                </Text>
              </StatRow>
            </>
          ) : null}
        </Box>
      )}
    </ContractDetailsStyled>
  );
};

export default observer(ContractDetails);
