import styled from 'styled-components';

export const MarketBarStyled = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  align-items: center;
  column-gap: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  padding-bottom: 8px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    padding-bottom: 0px;
  }

  .main-stats,
  .other-stats {
    gap: 20px;
    margin-left: 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const Seperator = styled.div`
  height: 30px;
  width: 2px;
  background: ${({ theme }) => theme.colors.shadesBackground700};
`;

export const MarketPrice = styled.div`
  display: flex;
  align-items: center;
`;

export const MarketStat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
