import { formatResolution } from '../../../../../components/TVChartContainer/api/helpersRabbit';
import { Markets, TradeSide, WebsocketChannels } from '../../../../../enums';
import CentrifugeService from '../../../../../service/centrifugeService';
import TVConfigStore from '../../../../../stores/tvConfig';
import { DecimalScale } from 'constants/marketMappings';

export interface FeTrade {
  id?: string;
  price: number;
  size: number;
  timestamp: number;
  takerSide: TradeSide;
  liquidation?: boolean;
  marketID: string;
}

interface BackendTrade {
  id?: string;
  price: string;
  size: string;
  timestamp: number;
  taker_side: TradeSide;
  liquidation?: boolean;
  market_id: string;
}

const lastBarsCache = new Map();
let centrifugeService;

export const getDataFeed = (
  configurationData: any,
  getAllSymbols: any,
  getCandleData: any,
  subscribeOnStream: any,
  unsubscribeFromStream: any,
  resetCacheRef: any,
  getDecimalPlacesByMarketId: (marketId: string) => DecimalScale,
  tvConfig: TVConfigStore,
) => {
  return {
    onReady: callback => {
      // console.log('[onReady]: Method call');
      setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (userInput, exchange, onResultReadyCallback) => {
      // console.log('[searchSymbols]: Method call');
      const symbols = await getAllSymbols();
      const newSymbols = symbols.filter(symbol => {
        const isExchangeValid = exchange === '' || symbol.exchange === exchange;
        const isFullSymbolContainsInput =
          symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !==
          -1;
        return isExchangeValid && isFullSymbolContainsInput;
      });

      onResultReadyCallback(newSymbols);
    },

    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback,
    ) => {
      // console.log('[resolveSymbol]: Method call', symbolName);
      const symbols = await getAllSymbols();
      const symbolItem = symbols.find(
        ({ full_name }) => full_name === symbolName,
      );
      if (!symbolItem) {
        // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
        onResolveErrorCallback('cannot resolve symbol');
        return;
      }

      const decimalScales = getDecimalPlacesByMarketId(symbolName);

      const symbolInfo = {
        ticker: symbolItem.full_name,
        name: symbolItem.symbol,
        description: symbolItem.description,
        type: symbolItem.type,
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: symbolItem.exchange,
        minmov: 1,
        pricescale: decimalScales.priceScale,
        has_intraday: true,
        has_daily: true,
        has_empty_bars: true,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2,
        data_status: 'streaming',
      };

      // console.log('[resolveSymbol]: Symbol resolved', symbolName);
      onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback,
    ) => {
      const { firstDataRequest } = periodParams;
      // console.log(
      //   '[getBars]: Method call',
      //   symbolInfo,
      //   resolution,
      //   from,
      //   to,
      //   firstDataRequest,
      // );

      try {
        const data = await getCandleData(symbolInfo, resolution, periodParams);
        if (
          (data.Response && data.Response === 'Error') ||
          data.Data.length === 0
        ) {
          // "noData" should be set if there is no data in the requested period.
          onHistoryCallback([], {
            noData: true,
          });
          return;
        }

        let bars = data.Data.map(b => {
          return {
            high: parseFloat(b.high),
            low: parseFloat(b.low),
            open: parseFloat(b.open),
            close: parseFloat(b.close),
            volume: parseFloat(b.volume),
            time: b.time * 1000,
          };
        });
        const now = Date.now();
        bars = bars.filter(i => i.time <= now);

        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.full_name, {
            //@ts-ignore
            ...bars[bars.length - 1],
          });
        }
        // console.log(`[getBars]: returned ${bars.length} bar(s)`);
        onHistoryCallback(bars, {
          noData: false,
        });
      } catch (error) {
        // console.log('[getBars]: Get error', error);
        onErrorCallback(error);
      }
    },

    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
    ) => {
      // console.log(
      //   '[subscribeBars]: Method call with subscribeUID:',
      //   subscribeUID,
      // );

      centrifugeService = CentrifugeService.getPublic();
      let channel = `${WebsocketChannels.Trades}:${symbolInfo.name}`;

      centrifugeService
        .getSubscription(channel)
        ?.on(
          'publication',
          ({ data: backendData }: { data: BackendTrade[] }) => {
            if (tvConfig.config.resolution !== resolution) return;
            const data: FeTrade[] = backendData.map(
              ({
                id,
                liquidation,
                market_id,
                price,
                size,
                taker_side,
                timestamp,
              }) => {
                return {
                  id,
                  marketID: market_id,
                  liquidation,
                  takerSide: taker_side,
                  price: parseFloat(price),
                  size: parseFloat(size),
                  timestamp: Math.floor(timestamp / 1000 / 1000),
                };
              },
            );
            for (const item of data?.reverse()) {
              const lastBar = lastBarsCache.get(symbolInfo.full_name);
              const resolutionMin = formatResolution(resolution);

              // TODO: Check 1 minutes as threshold
              let threshold = resolutionMin * 60;
              let rounded = Math.floor(item.timestamp / threshold) * threshold;
              if (lastBar) {
                let lastBarSec = lastBar.time / 1000;
                let tick;

                if (rounded > lastBarSec) {
                  tick = {
                    time: rounded * 1000,
                    open: lastBar.close,
                    high: lastBar.close,
                    low: lastBar.close,
                    close: item.price,
                    volume: item.size * item.price,
                  };
                } else {
                  if (item.price < lastBar.low) {
                    lastBar.low = item.price;
                  } else if (item.price > lastBar.high) {
                    lastBar.high = item.price;
                  }

                  lastBar.volume += item.size * item.price;
                  lastBar.close = item.price;
                  tick = lastBar;
                }

                lastBarsCache.set(symbolInfo.full_name, tick);

                onRealtimeCallback(tick);
              }
            }
          },
        );

      if (resetCacheRef) {
        resetCacheRef.current = onResetCacheNeededCallback;
      }
      subscribeOnStream(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback,
        lastBarsCache.get(symbolInfo.full_name),
      );
    },

    unsubscribeBars: subscriberUID => {
      // console.log(
      //   '[unsubscribeBars]: Method call with subscriberUID:',
      //   subscriberUID,
      // );
      unsubscribeFromStream(subscriberUID);
    },
  };
};
