import React from 'react';

import { AccountProvider } from './AccountProvider';
import { ModalPresenter } from './ModalPresenter';
import SnackbarWithStyles from './SnackbarWithStyles';
import { DepositsApiProvider } from './depositsApiProvider';
import { ElixirContractsProvider } from './elixirContractsProvider';
import { RabbitContractsProvider } from './rabbitContractsProvider';
import { UsdtContractsProviders } from './usdtContractsProvider';
import { QUERY_REFETCH_INTERVAL } from 'constants/general';
import AppContext from 'contexts/AppContext';
import { ExchangeApiProvider } from 'providers/exchangeApiProvider';
import AppStore from 'stores/app';

import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: QUERY_REFETCH_INTERVAL,
    },
  },
});

const store = new AppStore();

export const Providers = ({ children }) => {
  return (
    <AppContext.Provider value={{ store }}>
      <BrowserRouter>
        <SnackbarWithStyles>
          <QueryClientProvider client={queryClient}>
            <AccountProvider>
              <UsdtContractsProviders>
                <ExchangeApiProvider>
                  <DepositsApiProvider>
                    <RabbitContractsProvider>
                      <ElixirContractsProvider>
                        <ModalPresenter>{children}</ModalPresenter>
                      </ElixirContractsProvider>
                    </RabbitContractsProvider>
                  </DepositsApiProvider>
                </ExchangeApiProvider>
              </UsdtContractsProviders>
            </AccountProvider>
          </QueryClientProvider>
        </SnackbarWithStyles>
      </BrowserRouter>
    </AppContext.Provider>
  );
};
