import { memo } from 'react';

import { brand } from 'utils/brand';

import useModal from 'hooks/useModal';

import Text from 'components/Text';

import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';
import {
  getStepDescriptionText,
  MESH_ORDER_STATUS,
} from 'pages/Trade/components/AccountStats/NewDepositModal/Steps/MeshOrderStatus/meshOrderStatus';

import { observer } from 'mobx-react';
import styled from 'styled-components';

const Container = styled.div`
  p {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  .Toastify__progress-bar-theme--dark {
    background: red !important;
  }
`;

const getDescription = (
  amount: number,
  meshOrderStatus: MESH_ORDER_STATUS,
  brokerName: string,
) => {
  let description = '';

  const amountStr = amount.toFixed(2);

  switch (meshOrderStatus) {
    case MESH_ORDER_STATUS.ERRORED_OUT:
      description = `Deposit for ${amountStr} ${brand.tokenTicker} from ${brokerName} encountered an error. Please contact team for more information.`;
      break;
    case MESH_ORDER_STATUS.CONTRACT_CALLED:
      description = `Deposit for ${amountStr} ${brand.tokenTicker} from ${brokerName} has been received. Your deposit will be available after ${CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT} block confirmations.`;
      break;
    case MESH_ORDER_STATUS.CONTRACT_CALL_FAILED:
      description = `Deposit for ${amountStr} ${brand.tokenTicker} from ${brokerName} was cancelled. Please contact team for more information.`;
      break;
    case MESH_ORDER_STATUS.PENDING_FROM_MESH:
      description = `Deposit for ${amountStr} ${brand.tokenTicker} from ${brokerName} is pending confirmation from the exchange.`;
      break;
    case MESH_ORDER_STATUS.RECEIVED_IN_PROXY_WALLET:
      description = `Deposit for ${amountStr} ${brand.tokenTicker} from ${brokerName} has been received. Your deposit will be available after ${CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT} block confirmations.`;
      break;
    default:
      description = `Your deposit for ${amountStr} ${brand.tokenTicker} has an unknown status.`;
      break;
  }

  return description;
};

type Props = {
  amount: number;
  status: MESH_ORDER_STATUS;
  brokerName: string;
  modalId?: string;
};
const MeshUpdateToast = ({ amount, status, brokerName, modalId }: Props) => {
  const modal = useModal();
  console.log('modals');

  const currentModal = modal.activeModals.find(m => m.id === modalId);
  const isModalOpen = currentModal ? !currentModal.minimised : false;
  console.log('isModalOpen', isModalOpen);
  console.log('currentModal', currentModal);

  const onTrackTransfer = () => {
    modalId && modal.maximiseById(modalId);
  };

  return (
    <Container>
      <h6>{getStepDescriptionText(status)}</h6>
      <p>{getDescription(amount, status, brokerName)}</p>
      {modalId && !isModalOpen ? (
        <Text onClick={onTrackTransfer} variant="BODY_M" cursorPointer>
          Click to track transfer
        </Text>
      ) : null}
    </Container>
  );
};

export default observer(MeshUpdateToast);
