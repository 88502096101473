import { brand, isBrandRabbitX } from 'utils/brand';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 10px;

    font-size: 18px;
    line-height: 24px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 24px;
      line-height: normal;
      letter-spacing: -0.48px;
    }
  }

  span {
    font-size: 10px;
    line-height: normal;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.shadesForeground200};

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 12px;
    }

    > a {
      color: ${({ theme }) =>
        isBrandRabbitX
          ? theme.colors.tertiaryGradientsVeryHighLeverageRisk
          : theme.colors.main200};
    }
  }
`;

const HeaderAndTnC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <h5>{t('ConnectMethod')}</h5>
      <span>
        {t('byTradingOnRabbixYouAgree').replace('RabbitX', brand.appShortName)}{' '}
        <a href={brand.tocLink} target="_blank" rel="noreferrer">
          {t('termsAndConditions')}
        </a>{' '}
        {t('and')}{' '}
        <a href={brand.privacyPolicyLink} target="_blank" rel="noreferrer">
          {t('privacyPolicy')}{' '}
        </a>
        {t('ofThePlatform')}.
      </span>
    </Container>
  );
};

export default HeaderAndTnC;
