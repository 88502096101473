import {
  getCurrencyFromTradingPair,
  removeTrailingDigitsFromTradingPair,
} from 'utils';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import { useSwitchMarket } from 'hooks/useSwitchMarket';

import { FormattedNumber, Icon } from 'components';
import RbxAreaChart from 'components/RbxAreaChart';
import Text from 'components/Text';

import { CoinChart, CoinInfo, CoinRowContainer, CoinStats } from './styles';
import arrowDown from 'assets/icons/arrow-down-new.svg';
import arrowUp from 'assets/icons/arrow-up-new.svg';
import starBright from 'assets/icons/star-bright.svg';
import starGray from 'assets/icons/star-gray.svg';

import { Market, UserSettings } from 'interfaces';
import { observer } from 'mobx-react';
import { useQueryClient } from 'react-query';

type Props = {
  market: Market;
  setFavoriteMarket: (marketId: string) => void;
};
const CoinRow = ({ market, setFavoriteMarket }: Props) => {
  const {
    id,
    lastTradePrice24hChangeBasis,
    marketTitle,
    chartPrices,
    lastTradePrice,
  } = market;
  const { switchMarket } = useSwitchMarket();
  const isPositivePriceChange = lastTradePrice24hChangeBasis >= 0;
  const queryClient = useQueryClient();

  const marketLogo = useGetMarketLogoFromMarketId(id);

  const userSettings: UserSettings | undefined =
    queryClient.getQueryData('settings');

  const isFavoriteMarket =
    userSettings?.favoriteMarkets && userSettings?.favoriteMarkets[id];

  const handleMarketClick = () => {
    switchMarket(market);
  };

  const handleFavorite = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setFavoriteMarket(id);
  };

  const decimalPlaces = useGetDecimalPlaces(id);

  return (
    <CoinRowContainer onClick={handleMarketClick}>
      <CoinInfo>
        <img className="coin-icon" src={marketLogo} alt="coin icon" />
        <div className="coin-name">
          <Text
            variant="BODY_S"
            fontWeight="semiBold"
            className="coin-ticker"
            flexed
          >
            {userSettings !== undefined ? (
              <Icon
                src={isFavoriteMarket ? starBright : starGray}
                alt="watchlist star"
                onClick={handleFavorite}
                size="S"
              />
            ) : null}
            {removeTrailingDigitsFromTradingPair(
              getCurrencyFromTradingPair(id),
            )}
          </Text>
          <Text variant="BODY_S" color="shadesForeground200">
            {marketTitle}
          </Text>
        </div>
      </CoinInfo>
      <CoinChart>
        <RbxAreaChart
          containsDates={false}
          data={chartPrices as unknown as number[]}
          showTooltip={false}
          isPositiveChange={isPositivePriceChange}
          strokeWidth={1}
          showArea={false}
        />
      </CoinChart>
      <CoinStats>
        <FormattedNumber
          value={lastTradePrice}
          prefix="$"
          decimalScale={decimalPlaces.price}
          variant="BODY_S"
        />

        <Text
          variant="BODY_S"
          className="justify-end"
          color={
            isPositivePriceChange
              ? 'primaryGreenForeground100'
              : 'negativeForeground200'
          }
          flexed
        >
          <img
            src={isPositivePriceChange ? arrowUp : arrowDown}
            alt="price change arrow"
          />
          <FormattedNumber
            value={lastTradePrice24hChangeBasis * 100}
            isPercentage={true}
            allowNegative={false}
            suffix="%"
          />
        </Text>
      </CoinStats>
    </CoinRowContainer>
  );
};

export default observer(CoinRow);
