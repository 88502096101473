import { useState } from 'react';

import { Tab, Tabs } from '@material-ui/core';

import { useApiKeysAPI } from 'hooks';

import Button from 'components/Button';

import { isKeyExpired } from '../ApiKeysList';
import IpRestrictions from '../IpRestrictions';
import { a11yProps, TabPanel } from './ApiKeyDetailsTab';
import ApiKeys from './ApiKeys';
import { ButtonsContainer, Container, TabsContainer } from './styles';
import processingIcon from 'assets/icons/processing-gray.svg';
import uploadIcon from 'assets/icons/upload.svg';
import { useAppContext } from 'contexts/AppContext';
import { ApiKey } from 'interfaces/apiKey';

import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';

const daysUntilExpiration = (timestamp: number) => {
  // Current date with time reset to midnight
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Timestamp's date with time reset to midnight
  const expirationDate = new Date(timestamp * 1000); // Convert to milliseconds
  expirationDate.setHours(0, 0, 0, 0);

  // Calculate difference in days
  const timeDifference = expirationDate.getTime() - currentDate.getTime();
  const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

  return Math.floor(dayDifference);
};

const createJsonDataDownloadUrl = (apiKey: ApiKey, profileId: string) => {
  const exportJsonData = {
    tag: apiKey.apiSecret.tag,
    key: apiKey.apiSecret.key,
    secret: apiKey.apiSecret.secret,
    publicJwt: apiKey.jwtPublic,
    privateJwt: apiKey.jwtPrivate,
    refreshToken: apiKey.refreshToken,
    profileId,
  };
  const blob = new Blob([JSON.stringify(exportJsonData, null, 2)], {
    type: 'application/json',
  });
  return URL.createObjectURL(blob);
};

type Props = {
  apiKey: ApiKey;
};
const ApiKeyDetails = ({ apiKey }: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { refresh } = useApiKeysAPI();
  const [activeTab, setActiveTab] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const renderExpiration = () => {
    if (isKeyExpired(Date.now() / 1000, apiKey.apiSecret.expiration)) {
      return <span className="expired">Expired</span>;
    }
    return (
      <span className="expiry">
        Expiring in {daysUntilExpiration(apiKey.apiSecret.expiration)} days
      </span>
    );
  };

  const handleRefreshKey = async (key: string) => {
    try {
      flushSync(() => {
        setIsRefreshing(true);
      });
      await refresh({
        key: apiKey.apiSecret.key,
        refreshToken: apiKey.refreshToken,
      });
    } catch (e: any) {
      console.log('An error occurred when refreshing API key', e);
    } finally {
      setIsRefreshing(false);
    }
  };

  const jsonDataDownloadUrl = createJsonDataDownloadUrl(
    apiKey,
    frontendSecrets?.profile.id ?? '-',
  );

  return (
    <Container>
      <div className="heading-container">
        <h5 className="heading">API Key Details</h5>
        {renderExpiration()}
      </div>
      <TabsContainer>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="Airdrop phases tabs"
        >
          <Tab label="API Keys" {...a11yProps(0)} />
          <Tab label="IP Restrictions" {...a11yProps(1)} />
        </Tabs>
      </TabsContainer>
      <TabPanel value={activeTab} index={0}>
        <div className="mt-20" />
        <ApiKeys
          apiKey={apiKey}
          profileId={frontendSecrets?.profile.id ?? -''}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <div className="mt-20" />
        <IpRestrictions
          showHeader={false}
          allowedIps={apiKey.allowedIpList ?? []}
        />
      </TabPanel>
      <ButtonsContainer>
        <Button
          variant="secondaryOutline"
          size="small"
          onClick={handleRefreshKey}
          isLoading={isRefreshing}
        >
          Renew Now
          <img src={processingIcon} alt="Processing" className="ml-5" />
        </Button>
        <a download="apiKey.json" href={jsonDataDownloadUrl}>
          <Button variant="primaryGreen" size="small">
            Export
            <img src={uploadIcon} alt="Upload" className="ml-5" />
          </Button>
        </a>
      </ButtonsContainer>
    </Container>
  );
};

export default observer(ApiKeyDetails);
