import { useReferralsAPI } from 'hooks/useReferralsAPI';

import Loading from 'components/Loading';

import Portfolio from '../../index';
import GeneralStats from './GeneralStats';
import InviteFriends from './InviteFriends';
import Leaderboard from './Leaderboard';
import ProfileInfo from './ProfileInfo';
import RewardsHub from './RewardsHub';
import { Container } from './styles';
import { useAppContext } from 'contexts/AppContext';
import { ReferralModel } from 'enums/referralModel';
import WalletVerification from 'pages/Portfolio/common/walletVerification';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';

const PortfolioReferralDashboard = () => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { fetchUserReferral } = useReferralsAPI();

  const {
    isLoading: isLoadingUserReferral,
    data: userReferral,
    isError: isErrorUserReferral,
  } = useQuery(
    [QueryKeys.UserReferral],
    async () => await fetchUserReferral(),
    {
      // 60 seconds
      refetchInterval: 60_000,
      enabled: !!frontendSecrets?.jwt,
    },
  );

  return (
    <Portfolio>
      <Container>
        {frontendSecrets?.jwt && userReferral && (
          <>
            <div className="row">
              <ProfileInfo userReferral={userReferral} />
              <InviteFriends userReferral={userReferral} />
            </div>
            <div className="row">
              <div>
                <GeneralStats userReferral={userReferral} />
              </div>
              {userReferral.model === ReferralModel.Percentage ? (
                <RewardsHub
                  nextLevel={userReferral.referralLevelStatus.next?.level}
                />
              ) : null}
            </div>
          </>
        )}
        {isLoadingUserReferral && (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        {isErrorUserReferral && (
          <div className="flex justify-center">
            <ColoredText color={Colors.Danger}>
              Something went wrong
            </ColoredText>
          </div>
        )}
        {!frontendSecrets?.jwt && (
          <div className="flex justify-center mt-30 mb-30">
            <WalletVerification />
          </div>
        )}
        <Leaderboard userReferral={userReferral} />
      </Container>
    </Portfolio>
  );
};

export default observer(PortfolioReferralDashboard);
