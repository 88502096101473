import { FormattedNumber } from 'components';
import Text from 'components/Text';

import { StatusTx } from '../notifications';

import { AmendType } from '..';
import { useTranslation } from 'react-i18next';

type Props = {
  amendType: AmendType;
  tokenPair: string;
  amount?: number;
};
const RequestError = ({ amendType, tokenPair, amount }: Props) => {
  const { t } = useTranslation();
  const getRequestText =
    amendType === AmendType.Stake
      ? StatusTx.stakeFailed
      : StatusTx.unstakeFailed;
  return (
    <Text
      variant="BODY_M"
      fontWeight="semiBold"
      color="negativeForeground200"
      className="align-center"
    >
      {getRequestText(tokenPair, amount, t).description}
    </Text>
  );
};

export default RequestError;
