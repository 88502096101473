import { textWithEllipsisInTheMiddle, timestampToLocalDate } from 'utils';

import { PlatformVaultsDataWithHoldings } from 'hooks';

import { FormattedNumber, FormattedPercentage } from 'components';
import InfoTooltip from 'components/InfoTooltip';
import Text from 'components/Text';

import { getTotalVaultApy } from '../../../../VaultList/utils';
import { GeneralStatsContainer } from './styles';
import newTabIcon from 'assets/icons/new-tab-white.svg';
import { Column } from 'pages/Vaults/styles';
import { getPositivePPS } from 'pages/Vaults/utils';

import { config } from 'config';
import { useTranslation } from 'react-i18next';

type Props = { vault: PlatformVaultsDataWithHoldings; address: string };

const GeneralStats = ({ address, vault }: Props) => {
  const { t } = useTranslation();
  const generalStats = [
    {
      label: t('inceptionDate'),
      value: timestampToLocalDate(vault.inceptionTimestamp),
    },
    {
      label: t('contractAddress'),
      value: textWithEllipsisInTheMiddle(config.platformVaultContractAddress),
      openInNewTab: () =>
        window.open(
          `${config.etherscanUrl}/address/${config.platformVaultContractAddress}`,
          '_blank',
        ),
    },
    {
      label: t('hardCap'),
      value: t('noCap'),
    },
    {
      label: t('productID'),
      value: vault.vaultProfileId,
    },
    {
      label: t('inceptionPPS'),

      value: <FormattedNumber value={1} prefix="$" />,
      toolTipText: t('inceptionPPSTooltip'),
    },
    {
      label: t('currentPPS24h'),
      value: (
        <FormattedNumber value={getPositivePPS(vault.sharePrice)} prefix="$" />
      ),
      toolTipText: t('netValuePerShare'),
    },
    {
      label: t('estMonthlyAPY'),
      value: <FormattedPercentage value={getTotalVaultApy(vault.apy)} />,
      toolTipText: t('vaultApyTooltip'),
      // value: <FormattedPercentage value={vault.apy * 100} />,
    },
    {
      label: t('managementFee'),
      value: <FormattedPercentage value={0} />,
    },
    // {
    //   label: 'Est. Rewards APY',
    //   value: <FormattedPercentage value={vault.apy * 100} />,
    // },
    {
      label: t('performanceFee'),
      value: <FormattedPercentage value={vault.performanceFee * 100} />,
    },
  ];

  return (
    <GeneralStatsContainer>
      {/* <Text variant="BODY_M" fontWeight="semiBold">
        {t('generalStats')}
      </Text> */}

      <div className="grid">
        {generalStats.map(({ label, value, toolTipText, openInNewTab }) => (
          <Column className="item">
            <Text
              variant="BODY_S"
              className="flex items-center gap-2"
              color="shadesForeground200"
            >
              {label}
              {toolTipText ? (
                <InfoTooltip iconSize={12} title={toolTipText} />
              ) : null}
            </Text>

            <Text variant="BODY_S" className="flex items-center gap-2">
              {value}
              {openInNewTab ? (
                <img
                  src={newTabIcon}
                  onClick={openInNewTab}
                  alt="->"
                  className="icon-12 cursor-pointer"
                />
              ) : null}
            </Text>
          </Column>
        ))}
      </div>
    </GeneralStatsContainer>
  );
};

export default GeneralStats;
