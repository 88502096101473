import styled from 'styled-components';

type StyledSwitchProps = {
  index: number;
};

const StyledSwitch = styled.div<StyledSwitchProps>`
  display: flex;
  padding: 4px;
  align-items: center;
  height: 26px;
  width: 56px;
  border-radius: 26px;
  position: relative;
  cursor: pointer;
  user-select: none;
  background-color: ${({ theme }) => theme.colors.shadesBackground700};

  > img {
    height: 16px;
    width: 26px;
    z-index: 1;
  }

  .thumb {
    position: absolute;
    z-index: 0;
    height: 26px;
    width: 26px;
    border-radius: 23px;
    transition: margin-left 0.3s;
    background-color: ${({ theme }) => theme.colors.white};
    margin-left: ${({ index }) => (index === 1 ? '26px' : '0px')};
  }
`;

interface Option<T> {
  defaultIcon: string;
  activeIcon: string;
  value: T;
}
interface Props<T> {
  value: T;
  options: [Option<T>, Option<T>];
  onToggle: (value: T) => void;
}

const SwitchWithIcons = <T extends unknown>({
  options,
  value,
  onToggle,
}: Props<T>) => {
  const selectedIndex = value === options[0].value ? 0 : 1;

  const onClick = () => {
    onToggle(value === options[0].value ? options[1].value : options[0].value);
  };

  return (
    <StyledSwitch onClick={onClick} index={selectedIndex}>
      {options.map(({ defaultIcon, activeIcon }, index) => (
        <img
          src={selectedIndex === index ? activeIcon : defaultIcon}
          alt={index.toString()}
        />
      ))}
      <div className="thumb" />
    </StyledSwitch>
  );
};

export default SwitchWithIcons;
