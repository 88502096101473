import { useContext } from 'react';

import {
  EmptyRabbitContractsAPIContext,
  RabbitContractsApiContext,
} from 'contexts/RabbitContractsApiContext';

export const useRabbitContractsAPI = (throwError: boolean = true) => {
  const context = useContext(RabbitContractsApiContext);
  if (context) {
    return context;
  }
  if (throwError) {
    throw Error('Context Provider is not provided!');
  }
  return EmptyRabbitContractsAPIContext;
};
