import { TableBody, TableContainer } from '@material-ui/core';

import { showNotification } from 'utils';

import {
  useActiveWeb3React,
  useBalanceOperationsAPI,
  useRabbitContractsAPI,
} from 'hooks';
import { usePaginationFetch } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';
import useModal from 'hooks/useModal';

import ConfirmationModal from 'components/ConfirmationModal';
import Text from 'components/Text';

import ActivityRow from './ActivityRow';
import EmptyActivityTableState from './EmptyState';
import TableHead from './TableHead';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { VaultBalanceOps } from 'interfaces/vaultBalanceOps';
import { ColoredText, Colors } from 'pages/Trade/components/shared';
import { StyledDarkTable, StyledTableContainer } from 'pages/Vaults/styles';
import { useFetchPrivateVaultBalanceOps } from 'queryHooks/platformVaults';

import { DepositOrWithdrawalStatus, NotificationType, QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export type VisibilityProps = {
  hideActions?: boolean;
  hideManagerColumn?: boolean;
};

type Props = VisibilityProps & {
  vaultAddress?: string;
};
const ActivityTable = ({
  hideManagerColumn = false,
  hideActions = false,
  vaultAddress,
}: Props) => {
  const queryClient = useQueryClient();
  const modal = useModal();
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { fetchPrivateVaultBalanceOps } = useBalanceOperationsAPI();
  const { cancelUnstakeFromVaultRequest } = useRabbitContractsAPI();

  const {
    isLoading: isLoadingVaultBalanceOps,
    data: vaultBalanceOps,
    isError: isErrorVaultBalanceOps,
  } = useFetchPrivateVaultBalanceOps(vaultAddress);

  if (!account || !frontendSecrets?.profile.wallet) return null;

  const onCancel = async (opsId: string, shares: number) => {
    if (!opsId) return;

    const cancelRequest = async () => {
      try {
        await cancelUnstakeFromVaultRequest(opsId);

        // showNotification({
        //   title: `${t('cancellingUnstakeRequestFor')} ${shares} ${t(
        //     'shares',
        //   )}! `,
        //   description: t('cancellingUnstakeRequestDescription', {
        //     amount: shares,
        //   }),
        //   type: NotificationType.Positive,
        // });

        // Optimistic Update here
        queryClient.setQueryData<VaultBalanceOps[] | undefined>(
          [
            QueryKeys.VaultsBalanceOps,
            fetchPrivateVaultBalanceOps,
            vaultAddress,
          ],
          balanceOps =>
            balanceOps?.map(balanceOp => {
              if (balanceOp.id === opsId) {
                return {
                  ...balanceOp,
                  status: DepositOrWithdrawalStatus.Canceled,
                };
              }
              return balanceOp;
            }),
        );

        showNotification({
          title: `🎉 ${t('sucessfullyCancelledRequest')}! `,
          description: t('sucessfullyCancelledUnstakeRequestDescription', {
            amount: shares,
          }),
          type: NotificationType.Positive,
        });
      } catch (err) {
        showNotification({
          title: t('couldntCancelUnstakeRequest'),
          description: t('couldntCancelUnstakeRequestDescription'),
          type: NotificationType.Negative,
        });
      }
    };

    modal.present(
      <ConfirmationModal
        title={t('cancelUnstakeRequestTitle', { amount: shares })}
        description={t('cancelUnstakeRequestDescription', { amount: shares })}
        onButtonRightPress={cancelRequest}
        buttonRightTitle={t('yes')}
        buttonLeftTitle={t('no')}
        buttonRightProps={{ colorVariant: 'primaryRed' }}
      />,
      Modals.confirmationModal,
    );
  };

  return (
    <TableContainer>
      <StyledTableContainer>
        <Text
          fontWeight="semiBold"
          className="header-span"
          variant="HEADING_H2"
        >
          {t('recentActivity')}
        </Text>

        {isErrorVaultBalanceOps && (
          <ColoredText color={Colors.Danger}>
            {t('errorFetchingVaultsPleaseRefresh')}
          </ColoredText>
        )}

        {!isErrorVaultBalanceOps && (
          <StyledDarkTable stickyHeader>
            <TableHead
              hideActions={hideActions}
              hideManagerColumn={hideManagerColumn}
            />

            {isLoadingVaultBalanceOps ||
            !vaultBalanceOps ||
            vaultBalanceOps.length === 0 ? (
              <EmptyActivityTableState
                hideActions={hideActions}
                hideManagerColumn={hideManagerColumn}
                isLoading={isLoadingVaultBalanceOps}
              />
            ) : (
              <TableBody>
                {vaultBalanceOps.map(balanceOps => {
                  return (
                    <ActivityRow
                      balanceOps={balanceOps}
                      hideActions={hideActions}
                      hideManagerColumn={hideManagerColumn}
                      onCancel={onCancel}
                    />
                  );
                })}
              </TableBody>
            )}
          </StyledDarkTable>
        )}
      </StyledTableContainer>
    </TableContainer>
  );
};

export default observer(ActivityTable);
