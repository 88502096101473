import { memo } from 'react';

import { timeAgo } from 'utils';

import Badge from 'components/Badge';
import Text from 'components/Text';

import { NewsItem, NewsStyled, Separator } from './styles';
import {
  feedNameToId,
  fetchNews,
  NewsFeedItem,
} from 'pages/Portfolio/components';

import { QueryKeys } from 'enums';
import { useQuery } from 'react-query';

const NEWS_LIMIT = 12;
const SIXTY_SECONDS = 60 * 1000;

const News = () => {
  const { data, isLoading, isError } = useQuery(
    [QueryKeys.NewsFeed, 'discover', NEWS_LIMIT],
    () => fetchNews(feedNameToId['discover'], NEWS_LIMIT),
    {
      refetchInterval: SIXTY_SECONDS,
    },
  );

  if (isLoading || isError) {
    return null;
  }

  const renderNews = () => {
    return data.data.map((newsItem: NewsFeedItem, index: number) => {
      return (
        <>
          <NewsItem className={index === 0 ? 'ml-0' : ''}>
            <a
              href={newsItem.link}
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <Badge
                variant="BODY_XS"
                bgColor="shadesBackground700"
                color="shadesForeground200"
                padding="5px"
                className="time"
              >
                {timeAgo(new Date(newsItem.date), true)}
              </Badge>
              <Badge
                className="headline"
                variant="BODY_S"
                bgColor={
                  newsItem.is_trending_news
                    ? 'negativeForeground200'
                    : 'transparent'
                }
                color="white"
                fontWeight="semiBold"
                padding="4px"
              >
                {newsItem.headline}
              </Badge>
            </a>
          </NewsItem>
          <Separator />
        </>
      );
    });
  };

  const renderedNews = renderNews();

  return (
    <NewsStyled>
      {/* Duplicated for infinite horizontal rolling */}
      <div className="news-list">{renderedNews}</div>
      <div className="news-list">{renderedNews}</div>
    </NewsStyled>
  );
};

export default memo(News);
