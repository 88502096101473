import { bigNumberToFloat } from 'utils';

import { useActiveWeb3React, useElixirContractsAPI } from 'hooks';
import {
  useElixirVaultsApi,
  VaultWithContractInfo,
} from 'hooks/useElixirVaults';

import { USDT_DECIMALS } from 'constants/contracts';
import { useAppContext } from 'contexts/AppContext';
import {
  VaultPendingWalletShares,
  VaultWalletShares,
} from 'pages/Vaults/Pages/Vaults/TabPanels/ElixirVaults';
import {
  ElixirPoolInfoResponse,
  PoolType,
} from 'providers/elixirContractsProvider';

import { QueryKeys } from 'enums';
import { BigNumber } from 'ethers';
import { useQuery } from 'react-query';

export function useFetchElixirVaultsData(isEnabled = true) {
  const { fetchPoolsWithTvlAndApy } = useElixirVaultsApi();
  const { getPoolInfo, areReadContractsSet } = useElixirContractsAPI();

  return useQuery(
    [QueryKeys.ElixirVaultsTvlApy],
    async () => {
      const vaultsWithTvlAndApy = await fetchPoolsWithTvlAndApy();
      const contractPoolInfoArr: ElixirPoolInfoResponse[] = await Promise.all(
        vaultsWithTvlAndApy.vaults.map(
          vault => getPoolInfo(vault.productId) as ElixirPoolInfoResponse,
        ),
      );
      const vaultsWithContractPoolInfo: VaultWithContractInfo[] =
        vaultsWithTvlAndApy.vaults.map((vault, index) => ({
          ...vault,
          activeAmount: bigNumberToFloat(
            contractPoolInfoArr[index].activeAmount,
            USDT_DECIMALS,
          ),
          hardcap: bigNumberToFloat(
            contractPoolInfoArr[index].hardcap,
            USDT_DECIMALS,
          ),
          isActive: contractPoolInfoArr[index].poolType === PoolType.Perp,
          routerAddress: contractPoolInfoArr[index].router,
        }));

      return {
        totalTvlUsd: vaultsWithTvlAndApy.totalTvlUsd,
        vaults: vaultsWithContractPoolInfo,
      };
    },
    {
      enabled: areReadContractsSet && isEnabled,
      // 5 minutes
      refetchInterval: 300_000,
      // 45 seconds
      staleTime: 45_000,
    },
  );
}

type FetchVaultWalletSharesProps = {
  vaultsPoolIds: number[] | undefined;
  elixirVaultsData: VaultWithContractInfo[] | undefined;
  isEnabled?: boolean;
  disableRefetch?: boolean;
};
export function useFetchVaultsWalletShares({
  vaultsPoolIds,
  elixirVaultsData,
  isEnabled = true,
  disableRefetch = false,
}: FetchVaultWalletSharesProps) {
  const { getWalletSharesForVault, areReadContractsSet } =
    useElixirContractsAPI();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  return useQuery(
    [
      QueryKeys.ElixirVaultsWalletShares,
      vaultsPoolIds,
      frontendSecrets?.profile.wallet,
    ],
    async () => {
      if (
        !vaultsPoolIds ||
        !elixirVaultsData ||
        !frontendSecrets?.profile.wallet
      )
        return;

      const walletShares: any[] = await Promise.all(
        vaultsPoolIds.map(
          poolId => getWalletSharesForVault(poolId) as BigNumber,
        ),
      );

      return walletShares.reduce(
        (acc: { [key: number]: number }, curr: BigNumber, index: number) => {
          const walletSharesForVault = bigNumberToFloat(curr, USDT_DECIMALS);
          return {
            ...acc,
            [vaultsPoolIds[index]]: {
              sharesAmount: walletSharesForVault ?? 0,
              pricePerShare:
                elixirVaultsData[index].activeAmount === 0
                  ? 0
                  : elixirVaultsData[index].tvlUsd /
                    elixirVaultsData[index].activeAmount,
            },
          };
        },
        {},
      ) as VaultWalletShares;
    },
    {
      enabled:
        !!vaultsPoolIds &&
        !!frontendSecrets?.profile.wallet &&
        areReadContractsSet &&
        isEnabled,
      // 5 minutes
      refetchInterval: disableRefetch ? false : 300_000,
      // 45 seconds
      staleTime: 45_000,
    },
  );
}

type FetchSingleVaultDataForWallet = {
  poolId: number;
};
export function useFetchSingleVaultData({
  poolId,
}: FetchSingleVaultDataForWallet) {
  const { fetchPoolsWithTvlAndApy } = useElixirVaultsApi();
  const { getWalletSharesForVault, getPoolInfo, areReadContractsSet } =
    useElixirContractsAPI();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  return useQuery(
    [
      QueryKeys.ElixirVaultsTvlApy,
      poolId,
      frontendSecrets?.profile.wallet ?? 'no-wallet',
    ],
    async () => {
      const promises: any[] = [fetchPoolsWithTvlAndApy(), getPoolInfo(poolId)];

      if (frontendSecrets?.profile.wallet) {
        promises.push(getWalletSharesForVault(poolId));
      }

      const [
        vaultsWithTvlAndApy,
        currentVaultContractPoolInfo,
        walletVaultShares,
      ] = await Promise.all(promises);

      const currentVaultTvlAndApy = vaultsWithTvlAndApy.vaults.find(
        vault => vault.productId === poolId,
      );

      return {
        ...currentVaultTvlAndApy,
        activeAmount: bigNumberToFloat(
          currentVaultContractPoolInfo.activeAmount,
          USDT_DECIMALS,
        ),
        hardcap: bigNumberToFloat(
          currentVaultContractPoolInfo.hardcap,
          USDT_DECIMALS,
        ),
        isActive: currentVaultContractPoolInfo.poolType === PoolType.Perp,
        routerAddress: currentVaultContractPoolInfo.router,
        walletShares:
          walletVaultShares !== undefined
            ? bigNumberToFloat(walletVaultShares, USDT_DECIMALS)
            : undefined,
      } as VaultWithContractInfo;
    },
    {
      enabled: areReadContractsSet && poolId !== undefined,
      refetchInterval: 60_000,
      staleTime: 10_000,
    },
  );
}

type FetchVaultPendingWalletSharesProps = {
  vaultsPoolIds: number[] | undefined;
  elixirVaultsData: VaultWithContractInfo[] | undefined;
};
export function useFetchVaultsPendingWalletShares({
  vaultsPoolIds,
  elixirVaultsData,
}: FetchVaultPendingWalletSharesProps) {
  const { getPendingWalletSharesForVault, areReadContractsSet } =
    useElixirContractsAPI();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  return useQuery(
    [
      QueryKeys.ElixirVaultsPendingWalletShares,
      vaultsPoolIds,
      frontendSecrets?.profile.wallet,
    ],
    async () => {
      if (
        !vaultsPoolIds ||
        !elixirVaultsData ||
        !frontendSecrets?.profile.wallet
      )
        return;

      const walletPendingShares: any[] = await Promise.all(
        vaultsPoolIds.map(
          poolId => getPendingWalletSharesForVault(poolId) as BigNumber,
        ),
      );

      return walletPendingShares.reduce(
        (acc: { [key: number]: number }, curr: BigNumber, index: number) => {
          const pendingWalletSharesForVault = bigNumberToFloat(
            curr,
            USDT_DECIMALS,
          );

          return {
            ...acc,
            [vaultsPoolIds[index]]: pendingWalletSharesForVault ?? 0,
          };
        },
        {},
      ) as VaultPendingWalletShares;
    },
    {
      enabled:
        !!vaultsPoolIds &&
        !!frontendSecrets?.profile.wallet &&
        areReadContractsSet,
      // 2 minutes
      refetchInterval: 120_000,
      // 30 seconds
      staleTime: 30_000,
    },
  );
}

type FetchVaultsRouterUsdtBalancesProps = {
  routerAddresses: string[] | undefined;
};
export function useFetchVaultsRouterUsdtBalances({
  routerAddresses,
}: FetchVaultsRouterUsdtBalancesProps) {
  const { getVaultRouterUsdtBalance, areReadContractsSet } =
    useElixirContractsAPI();

  return useQuery(
    [QueryKeys.ElixirVaultsPendingWalletShares, routerAddresses],
    async () => {
      if (!routerAddresses || routerAddresses.length === 0) return;

      const vaultsRouterUsdtBalances: any[] = await Promise.all(
        routerAddresses.map(
          routerAddress =>
            getVaultRouterUsdtBalance(routerAddress) as BigNumber,
        ),
      );

      return vaultsRouterUsdtBalances.reduce(
        (acc: { [key: number]: number }, curr: BigNumber, index: number) => {
          const routerUsdtBalance = bigNumberToFloat(curr, USDT_DECIMALS);
          return {
            ...acc,
            [routerAddresses[index]]: routerUsdtBalance ?? 0,
          };
        },
        {},
      ) as { [key: string]: number };
    },
    {
      enabled: !!routerAddresses && areReadContractsSet,
      // 2 minutes
      refetchInterval: 120_000,
      // 30 seconds
      staleTime: 30_000,
    },
  );
}

export function useFetchRbxRewardsForUser() {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { account } = useActiveWeb3React();
  const { fetchRbxRewardsForUser } = useElixirVaultsApi();

  return useQuery(
    [QueryKeys.ElixirVaultsRbxRewardsForUser, frontendSecrets?.profile.wallet],
    async () => {
      if (!account || !frontendSecrets?.profile.wallet) return;

      return await fetchRbxRewardsForUser(account);
    },
    {
      enabled: !!frontendSecrets?.profile.wallet,
      refetchInterval: 60_000,
      staleTime: 10_000,
    },
  );
}

export function useFetchClaimedRbxRewardsForUser() {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { getUserClaimedRbxRewards } = useElixirContractsAPI();

  return useQuery(
    [
      QueryKeys.ElixirVaultsRbxRewardsForUserClaimed,
      frontendSecrets?.profile.wallet,
    ],
    async () => {
      if (!frontendSecrets?.profile.wallet) return;

      return await getUserClaimedRbxRewards();
    },
    {
      enabled: !!frontendSecrets?.profile.wallet,
      refetchInterval: 60_000,
      staleTime: 10_000,
    },
  );
}
