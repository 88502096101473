import Loading from 'components/Loading';

import { Container } from '../../../../VaultOverview/styles';
import { Column } from '../../common/VaultList/List/Grid/styles';
import VaultActivityTable from '../VaultActivityTable';
import BackAndActions from './components/BackAndActions';
import Chart from './components/Chart';
import Stats from './components/Stats';
import VaultName from './components/VaultName';
import YourRewards from './components/YourRewards';
import { ColoredText, Colors } from 'pages/Trade/components/shared';
import Layout from 'pages/Vaults/common/Layout';
import { useFetchPlatformVaultsDataWithHoldings } from 'queryHooks/platformVaults';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const PlatformVaultOverview = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const vaultWallet = searchParams.get('vault_wallet');

  const {
    data: vaultsData,
    isLoading: isLoadingVaultsData,
    isError: isErrorVaultsData,
  } = useFetchPlatformVaultsDataWithHoldings();

  if (!vaultWallet) {
    return null;
  }

  const currentVault = vaultsData?.find(vault => vault.wallet === vaultWallet);

  return (
    <Layout>
      <Container>
        <BackAndActions isWatched={true} isLoadingVault={isLoadingVaultsData} />

        <div className="content">
          {isErrorVaultsData ? (
            <ColoredText color={Colors.Danger}>
              {t('errorFetchingVaultDataPleaseRefresh')}
            </ColoredText>
          ) : !currentVault || isLoadingVaultsData ? (
            <Loading className="loader" />
          ) : (
            <>
              <Column className="left-col">
                <VaultName vault={currentVault} />
                <Chart vault={currentVault} />
              </Column>
              <Column className="right-col">
                <Stats vault={currentVault} />
                {/* <YourRewards vault={vaultData} /> */}
              </Column>
            </>
          )}
        </div>

        <VaultActivityTable vaultAddress={vaultWallet} hideManagerColumn />
      </Container>
    </Layout>
  );
};

export default PlatformVaultOverview;
