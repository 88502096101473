import { saveUserSetting } from 'utils';
import { brand } from 'utils/brand';

import useModal from 'hooks/useModal';

import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Text from 'components/Text';

import { Container } from './styles';
import closeGrayIcon from 'assets/icons/close-x.svg';
import rewardIcon from 'assets/icons/whale-reward-cup.svg';
import whaleIcon from 'assets/images/whale.webp';
import { Modals } from 'constants/modals';
import { Column } from 'theme/globalStyledComponents';

import { WHALE_SUPPORT_DISCORD_LINK } from '.';
import { UserSettings } from 'enums';
import { observer } from 'mobx-react';

const WhaleSupportMobileModal = () => {
  const modal = useModal();

  const onClose = () => {
    saveUserSetting(UserSettings.DONT_SHOW_WHALE_SUPPORT_POPUP_AGAIN, true);
    modal.clear();
  };

  return (
    <Modal
      name={Modals.whaleSupportModal}
      showCloseIcon={true}
      size="auto"
      showHeader={false}
      closeButtonType="contained"
      onClose={onClose}
      disableCloseOnClickOutside
    >
      <Container isSourceModal>
        <img src={whaleIcon} alt="" className="whale-img" />
        <Column gap={10} className="content" justify="flex-start">
          <Text flexed gap={8} variant="HEADING_H2" fontWeight="semiBold">
            <Icon src={rewardIcon} alt="bolt" size="XXL" />
            Oh Shit... It's a Whale!
          </Text>
          <Text className="description" preWrap>
            At {brand.appShortName}, we like keeping our whales happy.
            <br />
            Click{' '}
            <a
              href={WHALE_SUPPORT_DISCORD_LINK}
              className="here"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            to access our VIP discord channel.
          </Text>
        </Column>
        <div className="close-btn-container">
          <Icon src={closeGrayIcon} />
        </div>
      </Container>
    </Modal>
  );
};

export default observer(WhaleSupportMobileModal);
