import { TableCell, TableRow, styled } from '@material-ui/core';

import EmptyText from 'components/EmptyText';
import Icon from 'components/Icon';
import IconPair from 'components/IconPair';

import { Column, Row } from 'pages/Vaults/styles';

const EmptyVaultRow = () => {
  return (
    <TableRow>
      <TableCell>
        <Row className="gap-10">
          <IconPair isEmpty size={16} />
          <EmptyText width={62} />
        </Row>
      </TableCell>

      <TableCell>
        <Row className="gap-5">
          <Row className="gap-2">
            <EmptyText width={22} />
            <EmptyText width={22} />
          </Row>
        </Row>
      </TableCell>

      <TableCell>
        <Column className="gap-2">
          <EmptyText width={20} />
          <EmptyText width={44} />
        </Column>
      </TableCell>

      <TableCell>
        <Row className="gap-5">
          <Icon isEmpty />
          <EmptyText width={48} />
        </Row>
      </TableCell>

      <TableCell>
        <EmptyText width={44} />
      </TableCell>

      <TableCell>
        <EmptyText width={44} />
      </TableCell>

      <TableCell>
        <EmptyText width={0} height={34} />
      </TableCell>
    </TableRow>
  );
};

export default EmptyVaultRow;
