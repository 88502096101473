import styled from 'styled-components';

const TabPanelContainerStyled = styled.div`
  overflow-x: auto;
  /* height: calc(100% - 50px); */
  height: 100%;
`;

export const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelContainerStyled
      role="tabpanel"
      hidden={value !== index}
      id={`trading-chart-area-tabpanel-${index}`}
      aria-labelledby={`trading-chart-area-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </TabPanelContainerStyled>
  );
};

export const a11yProps = index => {
  return {
    id: `trading-chart-area-tab-${index}`,
    'aria-controls': `trading-chart-area-tabpanel-${index}`,
  };
};
