import styled, { css } from 'styled-components';

interface PresenterProps {
  isPresenting: boolean;
  isBlurred?: boolean;
}
export const Presenter = styled.div<PresenterProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  // transition: 0.2s opacity ease-out;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 13;
  ${({ isPresenting }) =>
    isPresenting &&
    css`
      opacity: 1;
      pointer-events: all;
      touch-action: auto;
    `}

  ${({ isBlurred }) =>
    isBlurred &&
    css`
      background: rgba(17, 20, 22, 0.7);
      backdrop-filter: blur(5px);
      width: 100%;
      height: 100%;
    `}
`;

interface ModalStyledProps {
  isFocused: boolean | undefined;
  isStashed: boolean | undefined;
}
export const ModalStyled = styled.div<ModalStyledProps>`
  position: absolute;
  min-height: 100%;
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  // transition: transform 0.2s ease-out, opacity 0.2s;
  opacity: 0;
  transform: translate3d(100px, 0, 0);
  height: 100%;
  /* overflow-y: scroll;   
  -webkit-overflow-scrolling: touch; */

  ${({ isFocused }) =>
    isFocused &&
    css`
      transform: translate3d(0, 0, 0);
      opacity: 1;
    `}

  ${({ isStashed }) =>
    isStashed &&
    css`
      transform: translate3d(-100px, 0, 0);
      opacity: 0;
    `}
`;
