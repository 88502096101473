import { Color } from 'interfaces';
import styled from 'styled-components';

type Props = {
  width: number;
  height?: number;
  background?: Color;
  className?: string;
};

const StyledEmptyText = styled.div<Props>`
  margin: 4px 0;
  // Default height is 6px
  height: ${({ height = 6 }) => `${height}px`};

  // Default Background is shadesBackground500
  background: ${({ theme, background = 'shadesBackground500' }) =>
    theme.colors[background]};

  width: ${({ width }) => width + 'px'};
  border-radius: 100px;
`;

const EmptyText = (props: Props) => {
  return <StyledEmptyText {...props} />;
};

export default EmptyText;
