import { TableContainer } from '@material-ui/core';

import { StyledTable } from 'components/Tables/styles';

import styled from 'styled-components';

export const StyledDarkTable = styled(StyledTable)`
  th {
    background: ${({ theme }) => theme.colors.shadesBackground800} !important;
    user-select: none;
  }

  tr {
    background: ${({ theme }) => theme.colors.shadesBackground800} !important;
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.colors.shadesBackground700} !important;
    }
  }

  tbody:before {
    line-height: 10px;
  }

  tbody {
    tr:first-child {
      td {
        /* padding-top: 20px !important; */
      }
    }
    tr:last-child {
      td {
        /* padding-bottom: 20px !important; */
      }
    }
  }

  .color-secondary {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .justify-end {
    justify-content: flex-end;
  }

  .seperator-row {
    height: 20px;
    cursor: default !important;

    :hover {
      background: ${({ theme }) => theme.colors.shadesBackground800} !important;
    }
  }

  // Padding on this button was different than available default sizes
  .custom-button {
    padding: 8px 13px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .header-span {
    position: sticky;
    left: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  > img {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
