import {
  getCurrencyFromTradingPair,
  removeTrailingDigitsFromTradingPair,
} from 'utils';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';

import { FormattedNumber } from 'components';

import { MarketCell, PriceCell } from './styles';
import arrowDown from 'assets/icons/arrow-down.svg';
import arrowUp from 'assets/icons/arrow-up.svg';
import starBright from 'assets/icons/star-bright.svg';
import starGray from 'assets/icons/star-gray.svg';
import { PriceChangeType } from 'enums/priceChangeType';
import {
  REWARD_BADGE_ENABLED_MARKETS,
  REWARD_MARKET_CONFIG,
} from 'pages/Trade';
import RewardsBadge from 'pages/Trade/components/MarketBar/components/RewardsBadge';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { Markets } from 'enums';
import { Market } from 'interfaces';
import { observer } from 'mobx-react';
import { useSaveSettings } from 'queryHooks';

interface Props {
  onOptionSelect: (option: Market) => void;
  market: Market;
  isConnected: boolean;
  isFavorite: boolean;
}

const MarketRow = ({
  onOptionSelect,
  market,
  isConnected,
  isFavorite,
}: Props) => {
  const {
    id,
    lastTradePrice24hChangeBasis,
    percentChangeType,
    marketVolume24h,
    lastTradePrice,
    marketTitle,
  } = market;

  const marketLogo = useGetMarketLogoFromMarketId(id);
  const decimalPlaces = useGetDecimalPlaces(id);

  const { handleFavoriteMarket } = useSaveSettings();

  const handleClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    handleFavoriteMarket(market.id);
  };

  return (
    <li onClick={() => onOptionSelect(market)}>
      <MarketCell
        data-cy="market-dropdown-option"
        data-testid={`market-select-${id}`}
      >
        <img src={marketLogo} alt="Asset Logo" />
        <div className="market-name">
          <div className="market-id-and-star">
            {isConnected && (
              <img
                className="star-icon"
                src={isFavorite ? starBright : starGray}
                alt="watchlist star"
                onClick={event => {
                  handleClick(event);
                }}
              />
            )}

            <span data-cy="market-id">
              {removeTrailingDigitsFromTradingPair(
                getCurrencyFromTradingPair(id),
              )}
            </span>

            {REWARD_BADGE_ENABLED_MARKETS.includes(id as Markets) ? (
              <RewardsBadge multiplier={REWARD_MARKET_CONFIG[id]} />
            ) : null}
          </div>
          <span className="market-name">{marketTitle}</span>
        </div>
      </MarketCell>
      <PriceCell>
        <span>
          <FormattedNumber
            value={lastTradePrice}
            prefix="$"
            decimalScale={decimalPlaces.price}
            disableTooltip={true}
          />
        </span>
        <span>
          <img
            src={
              percentChangeType === PriceChangeType.Positive
                ? arrowUp
                : arrowDown
            }
            alt="Current price arrow"
            className="arrow-icon"
          />
          <ColoredText
            color={
              percentChangeType === PriceChangeType.Positive
                ? Colors.Success
                : Colors.Danger
            }
          >
            <FormattedNumber
              value={lastTradePrice24hChangeBasis * 100}
              suffix="%"
              allowNegative={false}
            />
          </ColoredText>
        </span>
      </PriceCell>
      <span>
        <FormattedNumber value={marketVolume24h} prefix="$" decimalScale={0} />
      </span>
    </li>
  );
};

export default observer(MarketRow);
