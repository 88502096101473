import { TextVariant } from 'components/Text/variant';

import styled from 'styled-components';

export const VaultsPageStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;

  .MuiTabs-fixed {
    overflow: auto !important;

    /* User can scroll without a bar on mobile devices */
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .tab-override {
    .MuiTab-wrapper {
      flex-direction: row;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
      font-size: ${TextVariant['BODY_M'].fontSize};

      *:first-child {
        margin-bottom: 0;
      }
    }
  }

  .MuiTab-root {
    margin: 0;
    padding: 0;
  }

  .MuiTabs-flexContainer {
    border-bottom: 3px solid ${({ theme }) => theme.colors.shadesBackground800};
    gap: 20px;
  }

  .MuiTabs-indicator {
    height: 3px;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.shadesBackground500};
  }
`;

export const VaultsSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
