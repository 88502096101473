import { memo, useEffect, useState } from 'react';

import { brand } from 'utils/brand';

import { useActiveWeb3React } from 'hooks';
import { useDepositsAPI } from 'hooks/useDepositsAPI';
import useModal from 'hooks/useModal';

import Modal from 'components/Modal';

import { ColoredText, Colors } from '../../shared';
import ActionSection from './ActionSection';
import ConfirmStep from './Steps/ConfirmStep';
import RequestStep from './Steps/RequestStep';
import WithdrawalProcessingStep from './Steps/WithdrawalProcessingStep';
import WithdrawData from './WithdrawData';
import { InfoRowStyled } from './styles';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { mixpanelService } from 'service/mixpanelService';

import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

export const CURRENCY = brand.tokenTicker;

export enum WithdrawSteps {
  REQUEST,
  CONFIRM,
  WITHDRAWAL_PROCESSING,
  ERROR,
}

const getWithdrawableBalance = (
  withdrawableBalance: number | undefined | null,
) => {
  if (!withdrawableBalance || withdrawableBalance <= 0) {
    return 0;
  }

  return withdrawableBalance;
};

const WithdrawModal = () => {
  const { account } = useActiveWeb3React();
  const {
    store: {
      account: { _accountStats, addNewBalanceOperation },
    },
  } = useAppContext();
  const { withdraw } = useDepositsAPI();
  const { t } = useTranslation();

  const withdrawableBalance = getWithdrawableBalance(
    _accountStats?.withdrawableBalance,
  );

  const modal = useModal();

  const [step, setStep] = useState<WithdrawSteps>(WithdrawSteps.REQUEST);
  const [withdrawValue, setWithdrawValue] = useState<number | null>(null);
  const [isConfirmingWithdrawal, setIsConfirmingWithdrawal] = useState(false);
  const [withdrawalDate, setWithdrawalDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!account) return;
    mixpanelService.withdrawNavigate(account);
  }, [account]);

  const handleWithdrawClick = async () => {
    switch (step) {
      case WithdrawSteps.REQUEST:
        setStep(WithdrawSteps.CONFIRM);
        break;
      case WithdrawSteps.CONFIRM:
        try {
          if (!withdrawValue || !account) {
            return;
          }

          mixpanelService.withdrawClicked(account, withdrawValue);

          flushSync(() => {
            setIsConfirmingWithdrawal(true);
          });
          const newBalanceOperation = await withdraw(withdrawValue);
          mixpanelService.withdrawSuccess(account, withdrawValue);
          setWithdrawalDate(new Date());
          addNewBalanceOperation(newBalanceOperation);
          setStep(WithdrawSteps.WITHDRAWAL_PROCESSING);
        } catch (e) {
          setStep(WithdrawSteps.ERROR);
          console.error(e);
        } finally {
          setIsConfirmingWithdrawal(false);
        }
        break;
      case WithdrawSteps.ERROR:
        setStep(WithdrawSteps.REQUEST);
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      showHeader={true}
      title={t('withdraw')}
      size={'small'}
      name={Modals.withdrawModal}
    >
      {step === WithdrawSteps.REQUEST ? (
        <RequestStep
          withdrawValue={withdrawValue}
          withdrawableBalance={withdrawableBalance}
          setWithdrawValue={setWithdrawValue}
        />
      ) : null}

      {/* <SeparatorStyled /> */}
      {step === WithdrawSteps.CONFIRM ? (
        <ConfirmStep withdrawValue={withdrawValue} />
      ) : null}

      {step === WithdrawSteps.REQUEST ? (
        <WithdrawData withdrawValue={withdrawValue}></WithdrawData>
      ) : null}

      {step === WithdrawSteps.WITHDRAWAL_PROCESSING ? (
        <WithdrawalProcessingStep
          withdrawAmount={withdrawValue as number}
          withdrawalDate={withdrawalDate as Date}
        />
      ) : null}

      {step === WithdrawSteps.ERROR ? (
        <InfoRowStyled isLeading={true} className="mt-20">
          <ColoredText color={Colors.Danger}>
            {t('errorOccurredTryAgain')}
          </ColoredText>
        </InfoRowStyled>
      ) : step === WithdrawSteps.REQUEST ? (
        <InfoRowStyled isLeading={false} className="mt-20">
          <span>{t('withdrawalsAreATwoStepProcessClaimNeeded')}</span>
        </InfoRowStyled>
      ) : null}

      {step !== WithdrawSteps.WITHDRAWAL_PROCESSING ? (
        <ActionSection
          handleWithdrawClick={handleWithdrawClick}
          withdrawValue={withdrawValue}
          withdrawableBalance={withdrawableBalance}
          modal={modal}
          step={step}
          isConfirmingWithdrawal={isConfirmingWithdrawal}
        />
      ) : null}
    </Modal>
  );
};

export default memo(WithdrawModal);
