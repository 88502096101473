import { HTMLAttributes } from 'react';

import { Tooltip } from '@material-ui/core';

import {
  formatDateToLocalWithSeconds,
  getTimeUntil,
  parseTimeUntil,
} from 'utils';

import { useActiveWeb3React } from 'hooks';
import { useBlastGameAssets } from 'hooks/useBlastGameAssets';

import { FormattedNumber, Icon } from 'components';
import TextToolTip from 'components/InfoTooltip/TextToolTip';
import Loading from 'components/Loading';
import Text from 'components/Text';

import StatItem from '../components/StatIem';
import { StyledDropDownContent } from '../styles';
import hourGlassIcon from 'assets/icons/hourglass.svg';
import lightningIcon from 'assets/icons/lightning-warning.svg';
import trophyIcon from 'assets/icons/trophy-gray.svg';
import { useAppContext } from 'contexts/AppContext';
import { getWalletRankFromLeaderboard } from 'pages/Portfolio/Pages/Airdrops/BlastAidropDashboard/utils';
import { Row } from 'theme/globalStyledComponents/row';

import { QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';

const isFinalizing = (isoDate: string, pendingPoints: number) => {
  if (pendingPoints === 0) return false;
  if (getTimeUntil(isoDate).total > 0) return false;
  return true;
};

type Props = {
  type: 'blast' | 'gold';
  finalizedPoints: number;
  pendingPoints: number;
  nextBatchFinalizingAt: string;
  onViewDashboard: () => void;
} & HTMLAttributes<HTMLDivElement>;
const StatsAndTimeDropdown = ({
  type,
  finalizedPoints,
  pendingPoints,
  nextBatchFinalizingAt,
  onViewDashboard,
  ...rest
}: Props) => {
  // const { account } = useActiveWeb3React();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { fetchUserGameAssets } = useBlastGameAssets();

  const { data: userGameAssets, isLoading: isLoadingUserGameAssets } = useQuery(
    [QueryKeys.UserGameAssets],
    fetchUserGameAssets,
    {
      enabled: !!frontendSecrets?.jwt,
      refetchInterval: 20_000,
    },
  );

  // const {
  //   isLoading: isLoadingLeaderboard,
  //   data: gameAssetsLeaderboard,
  //   isError: isErrorFetchingLeaderboard,
  // } = useQuery(
  //   [QueryKeys.GameAssetsLeaderboard],
  //   async () => await fetchGameAssetsLeaderboard(),
  //   {
  //     enabled: !!frontendSecrets,
  //     // 10 minutes
  //     refetchInterval: 60_000 * 10,
  //   },
  // );

  const isTypeGold = type === 'gold';

  const prefix = isTypeGold ? 'Gold' : 'Points';

  // const userRank = gameAssetsLeaderboard
  //   ? getWalletRankFromLeaderboard(gameAssetsLeaderboard, account)
  //   : undefined;

  const arePointsFinalzing = isFinalizing(nextBatchFinalizingAt, pendingPoints);
  const pointsFinalizingUntil = parseTimeUntil(nextBatchFinalizingAt);

  return (
    <StyledDropDownContent {...rest}>
      <div /> {/* top margin */}
      <div>
        <Row gap={10}>
          <StatItem
            label={`${prefix} Received`}
            value={finalizedPoints}
            className={isTypeGold ? 'gold-color' : undefined}
          />
          <StatItem
            label={`${prefix} Pending`}
            value={pendingPoints}
            alignRight
            className={isTypeGold ? 'gold-color' : undefined}
          />
        </Row>

        <div className="time-container gap-5">
          {/* <Row gap={3}>
            <Icon size="S" src={trophyIcon} />
            <Text
              variant="BODY_S"
              color="shadesForeground200"
              flexed
              className="flex-1"
            >
              Rank
            </Text>
            {isLoadingLeaderboard ? (
              <Loading size="12px" />
            ) : (
              <Text variant="BODY_S">{userRank || 'Unranked'}</Text>
            )}{' '}
          </Row> */}

          <Row gap={3}>
            <Icon size="S" src={hourGlassIcon} />
            <Text
              variant="BODY_S"
              color="shadesForeground200"
              flexed
              className="flex-1"
            >
              {isTypeGold ? 'Gold' : 'Points'} Sent in
            </Text>
            <TextToolTip
              variant="BODY_S"
              fontWeight="semiBold"
              title={
                arePointsFinalzing
                  ? 'Points are being finalized..'
                  : pendingPoints > 0 && pointsFinalizingUntil
                  ? `${formatDateToLocalWithSeconds(
                      new Date(nextBatchFinalizingAt),
                    )}`
                  : 'No Pending points to send'
              }
            >
              {arePointsFinalzing
                ? 'Sending...'
                : pendingPoints > 0
                ? pointsFinalizingUntil
                : '--'}
            </TextToolTip>
          </Row>

          <Row gap={3}>
            <Icon size="S" src={lightningIcon} />
            <Text
              variant="BODY_S"
              color="shadesForeground200"
              flexed
              className="flex-1 warning-text-gradient"
            >
              Airdrop Boost
            </Text>
            {isLoadingUserGameAssets ? (
              <Loading size="12px" />
            ) : (
              <FormattedNumber
                variant="BODY_S"
                value={(userGameAssets?.totalBoost ?? 0) * 100}
                prefix="+"
                suffix="%"
                decimalScale={0}
              />
            )}
          </Row>

          <Text variant="BODY_XS" color="shadesForeground200" className="mt-5">
            Pending {isTypeGold ? 'gold' : 'points'} will be displayed once the
            epoch has ended (24 hours have passed)
          </Text>
        </div>

        <div className="dashboard-button" onClick={onViewDashboard}>
          View Dashboard
        </div>
      </div>
    </StyledDropDownContent>
  );
};

export default observer(StatsAndTimeDropdown);
