import { TextVariant } from 'components/Text/variant';

import styled from 'styled-components';

type ContentRowProps = {
  isPositive: boolean;
};
export const ContentRow = styled.div<ContentRowProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  span.label {
    padding: 6px 9px;

    display: flex;
    align-items: center;
    border-radius: 6px;
    font-size: 11px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    background: ${({ theme, isPositive }) =>
      isPositive
        ? theme.colors.positiveForeground300
        : theme.colors.negativeForeground300};
  }

  .content {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    gap: 15px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
    border-radius: 8px;

    .cell {
      display: flex;
      font-size: ${TextVariant['BODY_S'].fontSize};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      line-height: ${TextVariant['BODY_S'].lineHeight};
      gap: 10px;
      align-items: center;

      .loading-icon {
        width: 16px !important;
        height: 16px !important;
      }
      img {
        cursor: pointer;
      }
    }

    .separator {
      width: 1px;
      height: 16px;
      background: ${({ theme }) => theme.colors.shadesBackground500};
    }
  }
`;
