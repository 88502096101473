// import { toFloat } from 'utils';
import { ApiMappings } from 'enums';
import { ApiMapping } from 'interfaces';

function toFloat(value: string) {
  const res = parseFloat(value);
  if (isNaN(res)) {
    return value;
  }
  return res;
}

export const FILL_MAPPING: ApiMapping = {
  id: { to: 'id', required: true },
  profile_id: { to: 'profileID', required: true },
  market_id: { to: 'marketID', required: true },
  order_id: { to: 'orderID', required: true },
  timestamp: { to: 'timestamp', required: true },
  trade_id: { to: 'tradeID', required: true },
  price: { to: 'price', required: true, transform: toFloat },
  size: { to: 'size', required: true, transform: toFloat },
  side: { to: 'side', required: true },
  is_maker: { to: 'isMaker', required: true },
  fee: { to: 'fee', required: true, transform: toFloat },
  liquidation: { to: 'liquidation', required: true },
};

export const ORDER_MAPPING: ApiMapping = {
  id: { to: 'id', required: true },
  market_id: { to: 'marketID', required: true },
  profile_id: { to: 'profileID', required: true },
  order_type: { to: 'type', required: true },
  status: { to: 'status', required: true },
  price: { to: 'price', required: true, transform: toFloat },
  trigger_price: { to: 'triggerPrice', transform: toFloat },
  size: { to: 'remainingSize', required: true, transform: toFloat },
  initial_size: { to: 'initialSize', required: true, transform: toFloat },
  total_filled_size: {
    to: 'totalFilledSize',
    required: true,
    transform: toFloat,
  },
  side: { to: 'side', required: true },
  timestamp: { to: 'createdAt', required: true },
  reason: { to: 'reason', required: true },
  size_percent: { to: 'sizePercent', transform: toFloat },
};

export const POSITION_MAPPING: ApiMapping = {
  id: { to: 'id', required: true },
  market_id: { to: 'marketID', required: true },
  profile_id: { to: 'profileID', required: true },
  size: { to: 'size', required: true, transform: toFloat },
  side: { to: 'side', required: true },
  entry_price: { to: 'avgEntryPrice', required: true, transform: toFloat },
  unrealized_pnl: { to: 'unrealizedPnl', transform: toFloat },
  notional: { to: 'notional', transform: toFloat },
  margin: { to: 'margin', transform: toFloat },
  liquidation_price: { to: 'liquidationPrice', transform: toFloat },
  fair_price: { to: 'fairPrice', transform: toFloat },
  stop_loss: {
    to: 'stopLoss',
    mappings: ORDER_MAPPING,
    isObject: true,
  },
  take_profit: {
    to: 'takeProfit',
    mappings: ORDER_MAPPING,
    isObject: true,
  },
};

export const PROFILE_NOTIFICATIONS: ApiMapping = {
  type: { to: 'type', required: true },
  title: { to: 'title', required: true },
  description: { to: 'description', required: true },
};

export const BALANCE_OPERATIONS: ApiMapping = {
  id: { to: 'id' },
  status: { to: 'status' },
  reason: { to: 'reason' },
  txhash: { to: 'txhash' },
  profile_id: { to: 'profileID' },
  wallet: { to: 'wallet' },
  ops_type: { to: 'opsType' },
  ops_id2: { to: 'opsId2' },
  amount: { to: 'amount', transform: toFloat },
  timestamp: { to: 'timestamp' },
  shard_id: { to: 'shardId' },
  exchange_id: { to: 'exchangeId' },
};

export const VAULT_BALANCE_OPERATIONS: ApiMapping = {
  id: { to: 'id' },
  type: { to: 'type' },
  sub_type: { to: 'subType' },
  staker_profile_id: { to: 'stakerProfileId' },
  vault_profile_id: { to: 'vaultProfileId' },
  wallet: { to: 'wallet' },
  exchange_id: { to: 'exchangeId' },
  status: { to: 'status' },
  vault_name: { to: 'vaultName' },
  manager_name: { to: 'managerName' },
  timestamp: { to: 'timestamp' },
  inception_timestamp: { to: 'inceptionTimestamp' },
  stake_usdt: { to: 'stakeUSDT', transform: toFloat },
  stake_shares: { to: 'stakeShares', transform: toFloat },
  unstake_shares: { to: 'unstakeShares', transform: toFloat },
  unstake_usdt: { to: 'unstakeUSDT', transform: toFloat },
  unstake_fee_usdt: { to: 'unstakeFeeUSDT', transform: toFloat },
  ops_type: { to: 'opsType' },
};

export const SINGLE_TIER_DATA_MAPPING: ApiMapping = {
  min_volume: { to: 'minVolume', required: true, transform: toFloat },
  tier: { to: 'tier', required: true },
  title: { to: 'title' },
};

export const TIER_STATUS_MAPPING: ApiMapping = {
  current: {
    to: 'current',
    required: true,
    mappings: SINGLE_TIER_DATA_MAPPING,
    isObject: true,
  },
  needed_volume: { to: 'neededVolume', required: true, transform: toFloat },
  next: { to: 'next', mappings: SINGLE_TIER_DATA_MAPPING, isObject: true },
};

export const PROFILE_DATA_MAPPING: ApiMapping = {
  id: { to: 'id', required: true },
  profile_type: { to: 'profileType' },
  status: { to: 'status' },
  wallet: { to: 'wallet' },
  last_update: { to: 'lastUpdate' },
  balance: { to: 'balance', transform: toFloat },
  account_equity: { to: 'accountEquity', transform: toFloat },
  total_position_margin: { to: 'totalPositionMargin', transform: toFloat },
  total_order_margin: { to: 'totalOrderMargin', transform: toFloat },
  total_notional: { to: 'totalNotional', transform: toFloat },
  account_margin: { to: 'accountMargin', transform: toFloat },
  withdrawable_balance: { to: 'withdrawableBalance', transform: toFloat },
  cum_unrealized_pnl: { to: 'cumUnrealizedPnl', transform: toFloat },
  health: { to: 'health', transform: toFloat },
  account_leverage: { to: 'accountLeverage', transform: toFloat },
  cum_trading_volume: { to: 'cumTradingVolume', transform: toFloat },
  leverage: { to: 'leverage', transform: toFloat, isMap: true },
  tier_status: {
    to: 'tierStatus',
    required: true,
    mappings: TIER_STATUS_MAPPING,
    isObject: true,
  },
  positions: {
    to: 'positions',
    isArray: true,
    mappings: POSITION_MAPPING,
  },
  orders: {
    to: 'orders',
    isArray: true,
    mappings: ORDER_MAPPING,
  },
  fills: {
    to: 'fills',
    isArray: true,
    mappings: FILL_MAPPING,
  },
  profile_notifications: {
    to: 'profileNotifications',
    isArray: true,
    mappings: PROFILE_NOTIFICATIONS,
  },
  balance_operations: {
    to: 'balanceOperations',
    isArray: true,
    mappings: BALANCE_OPERATIONS,
  },
};

export const MARKET_MAPPING: ApiMapping = {
  id: { to: 'id', required: true },
  market_id: { to: 'marketID' },
  min_tick: { to: 'minTick', transform: toFloat },
  min_order: { to: 'minOrder', transform: toFloat },
  icon_url: { to: 'iconUrl' },
  market_title: { to: 'marketTitle' },
  status: { to: 'status' },
  open_interest: { to: 'openInterest', transform: toFloat },
  long_ratio: { to: 'longRatio', transform: toFloat },
  short_ratio: { to: 'shortRatio', transform: toFloat },
  min_initial_margin: { to: 'minInitialMargin', transform: toFloat },
  forced_margin: { to: 'maintenanceMargin', transform: toFloat },
  liquidation_margin: { to: 'liquidationMargin', transform: toFloat },
  best_bid: { to: 'bestBid', transform: toFloat },
  best_ask: { to: 'bestAsk', transform: toFloat },
  market_price: { to: 'marketPrice', transform: toFloat },
  index_price: { to: 'indexPrice', transform: toFloat },
  last_trade_price: { to: 'lastTradePrice', transform: toFloat },
  fair_price: { to: 'fairPrice', transform: toFloat },
  instant_funding_rate: { to: 'instantFundingRate', transform: toFloat },
  instant_daily_volume: { to: 'instantDailyVolume', transform: toFloat },
  last_funding_rate_basis: { to: 'lastFundingRateBasis', transform: toFloat },
  last_trade_price_24h_change_premium: {
    to: 'lastTradePrice24hChangePremium',
    transform: toFloat,
  },
  last_trade_price_24h_change_basis: {
    to: 'lastTradePrice24hChangeBasis',
    transform: toFloat,
  },
  last_trade_price_24high: { to: 'marketPrice24hHigh', transform: toFloat },
  last_trade_price_24low: { to: 'marketPrice24hLow', transform: toFloat },
  average_daily_volume: { to: 'marketVolume24h', transform: toFloat },
  average_daily_volume_change_premium: {
    to: 'marketVolume24hChangePremium',
    transform: toFloat,
  },
  average_daily_volume_change_basis: {
    to: 'marketVolume24hChangeBasis',
    transform: toFloat,
  },
  chart_prices: { to: 'chartPrices', isArray: true, transform: toFloat },
};

export const PROFILE_AIRDROP: ApiMapping = {
  profile_id: { to: 'profileId', required: true, transform: toFloat },
  airdrop_title: { to: 'airdropTitle', required: true },
  status: { to: 'status', required: true },
  total_volume_for_airdrop: {
    to: 'totalVolumeForAirdrop',
    transform: toFloat,
  },
  total_volume_after_airdrop: {
    to: 'totalVolumeAfterAirdrop',
    transform: toFloat,
  },
  total_rewards: {
    to: 'totalRewards',
    transform: toFloat,
  },
  claimable: {
    to: 'claimable',
    transform: toFloat,
  },
  claimed: {
    to: 'claimed',
    transform: toFloat,
  },
  last_fill_timestamp: {
    to: 'lastFillTimestamp',
    transform: toFloat,
  },
};

export const REFERRAL_LEADERBOARD_MAPPING: ApiMapping = {
  profile_id: { to: 'profileId', required: true, transform: toFloat },
  volume: { to: 'volume', required: true, transform: toFloat },
  previous_rank: {
    to: 'previousRank',
    required: true,
    transform: toFloat,
  },
  current_rank: {
    to: 'currentRank',
    required: true,
    transform: toFloat,
  },
  change: {
    to: 'change',
    required: true,
    transform: toFloat,
  },
  wallet: {
    to: 'wallet',
    required: true,
  },
  invited_counter: {
    to: 'invitedCounter',
    required: true,
    transform: toFloat,
  },
};

export const REFERRAL_LEVEL_MAPPING: ApiMapping = {
  volume: { to: 'volume', required: true, transform: toFloat },
  level: { to: 'level', required: true, transform: toFloat },
  commission_percent: {
    to: 'comissionPercent',
    required: true,
    transform: toFloat,
  },
  milestone_bonus: { to: 'milestoneBonus', required: true, transform: toFloat },
};

export const REFERRAL_LEVEL_STATUS_MAPPING: ApiMapping = {
  volume: { to: 'volume', required: true, transform: toFloat },
  needed_volume: { to: 'neededVolume', required: true, transform: toFloat },
  current: {
    to: 'current',
    required: true,
    mappings: REFERRAL_LEVEL_MAPPING,
    isObject: true,
  },
  next: {
    to: 'next',
    required: true,
    mappings: REFERRAL_LEVEL_MAPPING,
    isObject: true,
  },
  prev: {
    to: 'prev',
    required: true,
    mappings: REFERRAL_LEVEL_MAPPING,
    isObject: true,
  },
};

export const REFERRAL_MAPPING: ApiMapping = {
  profile_id: { to: 'profileId', required: true },
  short_code: { to: 'shortCode', required: true },
  model: { to: 'model', required: true },
  model_fee_percent: {
    to: 'modelFeePercent',
    required: true,
    transform: toFloat,
  },
  amend_counter: { to: 'amendCounter', required: true },
  timestamp: { to: 'timestamp', required: true },
  invited_counter: { to: 'invitedCounter', required: true },
  volume: { to: 'volume', required: true, transform: toFloat },
  previous_rank: { to: 'previousRank', required: true, transform: toFloat },
  current_rank: { to: 'currentRank', required: true },
  change: { to: 'change', required: true },
  earnings_all_time: {
    to: 'earningsAllTime',
    required: true,
    transform: toFloat,
  },
  wallet: {
    to: 'wallet',
    required: true,
  },
  payout_24h: {
    to: 'payout24h',
    required: true,
    transform: toFloat,
  },
  referral_level_status: {
    to: 'referralLevelStatus',
    required: true,
    mappings: REFERRAL_LEVEL_STATUS_MAPPING,
    isObject: true,
  },
  leader_board_status_weekly: {
    to: 'leaderboardStatusWeekly',
    required: true,
    mappings: REFERRAL_LEADERBOARD_MAPPING,
    isObject: true,
  },
  leader_board_status_monthly: {
    to: 'leaderboardStatusMonthly',
    required: true,
    mappings: REFERRAL_LEADERBOARD_MAPPING,
    isObject: true,
  },
  leader_board_status_all: {
    to: 'leaderboardStatusAll',
    required: true,
    mappings: REFERRAL_LEADERBOARD_MAPPING,
    isObject: true,
  },
};

export const CREATE_ORDER_PARAMS_MAPPING: ApiMapping = {
  marketID: { to: 'market_id', required: true },
  type: { to: 'type', required: true },
  side: { to: 'side', required: true },
  price: { to: 'price', required: true },
  triggerPrice: { to: 'trigger_price', required: true },
  size: { to: 'size', required: true },
  timeInForce: { to: 'time_in_force', required: true },
};

export const CREATE_SLTP_ORDER_PARAMS_MAPPING: ApiMapping = {
  marketID: { to: 'market_id', required: true },
  type: { to: 'type', required: true },
  triggerPrice: { to: 'trigger_price', required: true },
  sizePercent: { to: 'size_percent', required: true },
  timeInForce: { to: 'time_in_force' },
  price: { to: 'price' },
};

export const AMEND_ORDER_PARAMS_MAPPING: ApiMapping = {
  orderID: { to: 'order_id', required: true },
  marketID: { to: 'market_id', required: true },
  type: { to: 'type', required: true },
  price: { to: 'price', required: true },
  triggerPrice: { to: 'trigger_price', required: true },
  size: { to: 'size', required: true },
};

export const AMEND_SLTP_ORDER_PARAMS_MAPPING: ApiMapping = {
  orderID: { to: 'order_id', required: true },
  marketID: { to: 'market_id', required: true },
  type: { to: 'type', required: true },
  triggerPrice: { to: 'trigger_price', required: true },
  sizePercent: { to: 'size_percent', required: true },
  price: { to: 'price' },
};

export const CANCEL_ORDER_PARAMS_MAPPING: ApiMapping = {
  orderID: { to: 'order_id', required: true },
  marketID: { to: 'market_id', required: true },
};

export const EDIT_REFERRAL_PARAMS_MAPPING: ApiMapping = {
  newReferralCode: { to: 'new_referral_code', required: true },
};

export const CHANGE_MARKET_LEVERAGE_PARAMS_MAPPING: ApiMapping = {
  marketID: { to: 'market_id', required: true },
  leverage: { to: 'leverage', required: true },
};

export const CREATE_API_KEY_PARAMS_MAPPING: ApiMapping = {
  tag: { to: 'tag', required: true },
  expiration: { to: 'expiration', required: true },
  allowedIpList: { to: 'allowed_ip_list', required: true },
};

export const CHANGE_MARKET_LEVERAGE_RESPONSE_MAPPING: ApiMapping = {
  market_id: { to: 'marketID', required: true },
  leverage: { to: 'leverage', required: true },
};

export const CLAIM_OPS: ApiMapping = {
  id: { to: 'id', required: true, transform: toFloat },
  profile_id: { to: 'profileId', required: true, transform: toFloat },
  status: { to: 'status', required: true },
  amount: { to: 'amount', required: true, transform: toFloat },
  timestamp: { to: 'timestamp', required: true, transform: toFloat },
};

export const CLAIM_ALL_AIRDROPS_RESPONSE_MAPPING: ApiMapping = {
  claim_ops: { to: 'claimOps', required: true, mappings: CLAIM_OPS },
  bn_amount: { to: 'bnAmount', required: true },
  r: { to: 'r', required: true },
  s: { to: 's', required: true },
  v: { to: 'v', required: true, transform: toFloat },
};

export const API_SECRET_MAPPING: ApiMapping = {
  Key: { to: 'key', required: true },
  ProfileID: { to: 'profileId', required: true, transform: toFloat },
  Secret: { to: 'secret', required: true },
  Tag: { to: 'tag', required: true },
  Expiration: { to: 'expiration', required: true, transform: toFloat },
  Status: { to: 'status', required: true },
};

export const API_KEY_MAPPING: ApiMapping = {
  api_secret: {
    to: 'apiSecret',
    required: true,
    mappings: API_SECRET_MAPPING,
    isObject: true,
  },
  created_at: { to: 'createdAt', required: true, transform: toFloat },
  jwt_private: { to: 'jwtPrivate', required: true },
  jwt_public: { to: 'jwtPublic', required: true },
  refresh_token: { to: 'refreshToken', required: true },
  allowed_ip_list: { to: 'allowedIpList', required: true, isArray: true },
};

export const PORTFOLIO_STATS_MAPPING: ApiMapping = {
  time: { to: 'time', required: true, transform: toFloat },
  value: { to: 'value', required: true, transform: toFloat },
};

export const API_KEYS_LIST_RESPONSE_MAPPING: ApiMapping = {
  secrets: {
    to: 'secrets',
    required: true,
    mappings: API_KEY_MAPPING,
    isArray: true,
  },
};

export const DELETE_API_KEY_PARAMS_MAPPING: ApiMapping = {
  key: { to: 'key', required: true },
};

export const REFRESH_API_KEY_PARAMS_MAPPING: ApiMapping = {
  key: { to: 'key', required: true },
  refreshToken: { to: 'refresh_token', required: true },
};

export const WITHDRAWAL_RESPONSE_MAPPING: ApiMapping = {
  withdrawal: {
    to: 'withdrawal',
    required: true,
    mappings: BALANCE_OPERATIONS,
  },
  bn_amount: { to: 'bnAmount', required: true },
  r: { to: 'r', required: true },
  s: { to: 's', required: true },
  v: { to: 'v', required: true, transform: toFloat },
};

export const RECENT_TRADE_MAPPING: ApiMapping = {
  id: { to: 'id' },
  price: { to: 'price', transform: toFloat },
  size: { to: 'size', transform: toFloat },
  timestamp: { to: 'timestamp' },
  taker_side: { to: 'takerSide' },
  liquidation: { to: 'liquidation' },
  market_id: { to: 'marketID' },
};

export const REFRESH_JWT_PARAMS_MAPPING: ApiMapping = {
  refreshToken: { to: 'refresh_token', required: true },
  isClient: { to: 'is_client', required: true },
};

export const REFRESH_JWT_RESPONSE_MAPPING: ApiMapping = {
  jwt: { to: 'jwt', required: true },
  random_secret: { to: 'randomSecret', required: true },
  refresh_token: { to: 'refreshToken', required: true },
};

export const FUNDING_RATE_MAPPING: ApiMapping = {
  market_id: { to: 'marketId', required: true },
  funding_rate: { to: 'fundingRate', required: true, transform: toFloat },
  timestamp: { to: 'timestamp', required: true, transform: toFloat },
};

export const VAULT_TVL_CHART_MAPPING: ApiMapping = {
  time: { to: 'time', required: true, transform: toFloat },
  value: { to: 'value', required: true, transform: toFloat },
};

export const VAULTS_MAPPING: ApiMapping = {
  vault_profile_id: {
    to: 'vaultProfileId',
    required: true,
    transform: toFloat,
  },
  account_equity: { to: 'accountEquity', required: true, transform: toFloat },
  total_shares: { to: 'totalShares', required: true, transform: toFloat },
  share_price: { to: 'sharePrice', required: true, transform: toFloat },
  apy: { to: 'apy', required: true, transform: toFloat },
  status: { to: 'status', required: true },
  performance_fee: { to: 'performanceFee', required: true, transform: toFloat },
  manager_name: { to: 'managerName', required: true, transform: toFloat },
  vault_name: { to: 'vaultName', required: true, transform: toFloat },
  wallet: { to: 'wallet', required: true },
  inception_timestamp: { to: 'inceptionTimestamp', required: true },
};

export const VAULTS_HOLDINGS_MAPPING: ApiMapping = {
  staker_profile_id: {
    to: 'stakerProfileId',
    required: true,
    transform: toFloat,
  },
  vault_profile_id: {
    to: 'vaultProfileId',
    required: true,
    transform: toFloat,
  },
  status: { to: 'status', required: true },
  vault_name: { to: 'vaultName', required: true },
  manager_name: { to: 'managerName', required: true },
  shares: { to: 'userShares', required: true, transform: toFloat },
  net_withdrawable: {
    to: 'netWithdrawable',
    required: true,
    transform: toFloat,
  },
  performance_charge: {
    to: 'performanceCharge',
    required: true,
    transform: toFloat,
  },
  performance_fee: { to: 'performanceFee', required: true, transform: toFloat },
};

const POINT_MAPPING = {
  points: {
    to: 'finalizedPoints',
    transform: toFloat,
    required: true,
  },
  pendingPoints: {
    to: 'pendingPoints',
    transform: toFloat,
    required: true,
  },
  nextBatchFinalizingAt: {
    to: 'nextBatchFinalizingAt',
  },
};

export const BLAST_POINTS: ApiMapping = {
  gold: {
    to: 'gold',
    isObject: true,
    mappings: POINT_MAPPING,
  },
  blast: {
    to: 'blast',
    isObject: true,
    mappings: POINT_MAPPING,
  },
};

export const BFX_POINTS_BALANCE: ApiMapping = {
  points: { to: 'points', transform: toFloat, required: true },
};

export const GAME_ASSETS_BLAST: ApiMapping = {
  batch_id: { to: 'batchId', required: true },
  bonus_gold: { to: 'bonusGold', required: true },
  bonus_points: { to: 'bonusPoints', required: true },
  deposits: { to: 'deposits', required: true },
  level_boost: { to: 'levelBoost', required: true },
  level_tier: { to: 'levelTier', required: true },
  profile_id: { to: 'profileId', required: true },
  referral_boost: { to: 'referralBoost', required: true },
  referrer_profile_id: { to: 'referrerProfileId' },
  referrer_wallet: { to: 'referrerWallet' },
  staked_notional_net: { to: 'stakedNotionalNet', required: true },
  staking_gold: { to: 'stakingGold', required: true },
  staking_points: { to: 'stakingPoints', required: true },
  total_boost: { to: 'totalBoost', required: true },
  total_gold: { to: 'totalGold', required: true },
  total_points: { to: 'totalPoints', required: true },
  total_referred_deposit: { to: 'totalReferredDeposits', required: true },
  total_referred_stakes: { to: 'totalReferredStakes', required: true },
  trading_gold: { to: 'tradingGold', required: true },
  trading_notional: { to: 'tradingNotional', required: true },
  trading_points: { to: 'tradingPoints', required: true },
  wallet: { to: 'wallet', required: true },
  withdraws: { to: 'withdraws', required: true },
};

export const GAME_ASSETS_BLAST_LEADERBOARD: ApiMapping = {
  wallet: { to: 'wallet', required: true },
  invited_by_wallet: { to: 'invitedByWallet', required: true },
  total_points: { to: 'totalPoints', required: true },
  total_gold: { to: 'totalGold', required: true },
  trading_notional: { to: 'tradingNotional', required: true },
  deposits: { to: 'deposits', required: true },
};

export const MARKET_ORDERBOOK: ApiMapping = {
  market_id: { to: 'marketId', required: true },
  bids: {
    to: 'bids',
    required: true,
    isArray: true,
    transform: val => val.map(v => parseFloat(v)),
  },
  asks: {
    to: 'asks',
    required: true,
    isArray: true,
    transform: val => val.map(v => parseFloat(v)),
  },
  timestamp: { to: 'timestamp', required: true, transform: toFloat },
  sequence: { to: 'sequence', required: true, transform: toFloat },
};

export const API_MAPPINGS: { [key in ApiMappings]: ApiMapping } = {
  [ApiMappings.POSITION]: POSITION_MAPPING,
  [ApiMappings.ORDER]: ORDER_MAPPING,
  [ApiMappings.PROFILE_DATA]: PROFILE_DATA_MAPPING,
  [ApiMappings.MARKET]: MARKET_MAPPING,
  [ApiMappings.CREATE_ORDER_PARAMS]: CREATE_ORDER_PARAMS_MAPPING,
  [ApiMappings.AMEND_ORDER_PARAMS]: AMEND_ORDER_PARAMS_MAPPING,
  [ApiMappings.CANCEL_ORDER_PARAMS]: CANCEL_ORDER_PARAMS_MAPPING,
  [ApiMappings.CHANGE_MARKET_LEVERAGE_PARAMS]:
    CHANGE_MARKET_LEVERAGE_PARAMS_MAPPING,
  [ApiMappings.CHANGE_MARKET_LEVERAGE_RESPONSE]:
    CHANGE_MARKET_LEVERAGE_RESPONSE_MAPPING,
  [ApiMappings.RECENT_TRADE]: RECENT_TRADE_MAPPING,
  [ApiMappings.REFRESH_JWT_PARAMS]: REFRESH_JWT_PARAMS_MAPPING,
  [ApiMappings.REFRESH_JWT_RESPONSE]: REFRESH_JWT_RESPONSE_MAPPING,
  [ApiMappings.FILL]: FILL_MAPPING,
  [ApiMappings.BALANCE_OPERATIONS]: BALANCE_OPERATIONS,
  [ApiMappings.PROFILE_AIRDROP]: PROFILE_AIRDROP,
  [ApiMappings.CLAIM_ALL_AIRDROPS_RESPONSE_MAPPING]:
    CLAIM_ALL_AIRDROPS_RESPONSE_MAPPING,
  [ApiMappings.WITHDRAWAL_RESPONSE_MAPPING]: WITHDRAWAL_RESPONSE_MAPPING,
  [ApiMappings.CREATE_SLTP_ORDER_PARAMS_MAPPING]:
    CREATE_SLTP_ORDER_PARAMS_MAPPING,
  [ApiMappings.AMEND_SLTP_ORDER_PARAMS_MAPPING]:
    AMEND_SLTP_ORDER_PARAMS_MAPPING,
  [ApiMappings.API_KEY]: API_KEY_MAPPING,
  [ApiMappings.CREATE_API_KEY_PARAMS_MAPPING]: CREATE_API_KEY_PARAMS_MAPPING,
  [ApiMappings.API_KEYS_LIST_RESPONSE_MAPPING]: API_KEYS_LIST_RESPONSE_MAPPING,
  [ApiMappings.DELETE_API_KEY_PARAMS_MAPPING]: DELETE_API_KEY_PARAMS_MAPPING,
  [ApiMappings.REFRESH_API_KEY_PARAMS_MAPPING]: REFRESH_API_KEY_PARAMS_MAPPING,
  [ApiMappings.PORTFOLIO_STATS_MAPPING]: PORTFOLIO_STATS_MAPPING,
  [ApiMappings.TIER_STATUS_MAPPING]: TIER_STATUS_MAPPING,
  [ApiMappings.FUNDING_RATE_MAPPING]: FUNDING_RATE_MAPPING,
  [ApiMappings.REFERRAL_MAPPING]: REFERRAL_MAPPING,
  [ApiMappings.EDIT_REFERRAL_PARAMS_MAPPING]: EDIT_REFERRAL_PARAMS_MAPPING,
  [ApiMappings.REFERRAL_LEADERBOARD_MAPPING]: REFERRAL_LEADERBOARD_MAPPING,
  [ApiMappings.VAULT_TVL_CHART_MAPPING]: VAULT_TVL_CHART_MAPPING,
  [ApiMappings.VAULTS_MAPPING]: VAULTS_MAPPING,
  [ApiMappings.VAULTS_HOLDINGS_MAPPING]: VAULTS_HOLDINGS_MAPPING,
  [ApiMappings.VAULTS_BALANCE_OPERATIONS]: VAULT_BALANCE_OPERATIONS,
  [ApiMappings.BLAST_POINTS]: BLAST_POINTS,
  [ApiMappings.GAME_ASSETS_BLAST]: GAME_ASSETS_BLAST,
  [ApiMappings.GAME_ASSETS_BLAST_LEADERBOARD]: GAME_ASSETS_BLAST_LEADERBOARD,
  [ApiMappings.MARKET_ORDERBOOK]: MARKET_ORDERBOOK,
  [ApiMappings.BFX_POINTS_BALANCE]: BFX_POINTS_BALANCE,
};
