import styled, { css } from 'styled-components';

type ContainerProps = {
  avoidPaddings: boolean;
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  margin: ${({ avoidPaddings }) => (avoidPaddings ? 0 : '0 10px')};

  ${({ avoidPaddings }) =>
    !avoidPaddings
      ? css`
          flex-basis: 0;
          flex-grow: 1;
        `
      : css`
          flex: 1;
        `}

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    border-top: 0;
  }
`;

export const RecentTradesTableStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
`;

export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  > span {
    flex: 1;
    padding: 0px 2px;
  }
`;

export const NoResultsAndLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.shadesForeground300};
`;
