import Box from '@material-ui/core/Box';

import styled from 'styled-components';

const TabPanelContainerStyled = styled.div`
  overflow-x: auto;
`;

export const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelContainerStyled
      role="tabpanel"
      hidden={value !== index}
      id={`airdrop-phase-tabpanel-${index}`}
      aria-labelledby={`airdrop-phase-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </TabPanelContainerStyled>
  );
};

export const a11yProps = index => {
  return {
    id: `airdrop-phase-tab-${index}`,
    'aria-controls': `airdrop-phase-tabpanel-${index}`,
  };
};
