import { memo } from 'react';

import { FormattedNumber } from 'components';

import { InfoRowStyled } from './styles';

import { useTranslation } from 'react-i18next';

interface Props {
  withdrawValue: number | null;
}
const WithdrawData = ({ withdrawValue }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {/* <InfoRowStyled isLeading={true}>
        <span>Estimated Ethereum Fees</span>
        <span>FREE!</span>
      </InfoRowStyled> */}
      <InfoRowStyled isLeading={true} isHighlighted={true}>
        <span>{t('totalReceived')}</span>
        <span>
          <FormattedNumber
            value={withdrawValue ?? undefined}
            units={0}
            prefix="$"
          />
        </span>
      </InfoRowStyled>
    </>
  );
};

export default memo(WithdrawData);
