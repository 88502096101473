import { brand } from 'utils/brand';

import { AmendStep } from './enums';
import { SpotType } from 'enums/spotType';

import { AmendType } from '.';

export const getCurrentAmendStep = (
  amendType: AmendType,
  usdtAllowance: number,
  stakeAmount: number,
  isStakeRequestSent: boolean | undefined,
  isUnstakeRequestSent: boolean | undefined,
  isAwaitingConfirmation: boolean,
) => {
  if (isAwaitingConfirmation) {
    return AmendStep.AWAITING_STAKE_CONFIRMATION;
  }

  if (amendType === AmendType.Stake) {
    if (isStakeRequestSent === true) return AmendStep.STAKE_SUCCESS;

    if (isStakeRequestSent === false) return AmendStep.STAKE_FAILED;

    if (stakeAmount > usdtAllowance) return AmendStep.ENABLE_USDT;

    return AmendStep.STAKE;
  }

  if (amendType === AmendType.UnStake) {
    if (isUnstakeRequestSent === true) return AmendStep.UNSTAKE_SUCCESS;

    if (isUnstakeRequestSent === false) return AmendStep.UNSTAKE_FAILED;

    return AmendStep.UNSTAKE;
  }

  return AmendStep.UNKNOWN;
};

export const getActionButtonState = (
  step: AmendStep,
  amendType: AmendType,
  isPendingTxSign: boolean,
  isAwaitingConfirmation: boolean,
  isApprovingUsdt: boolean,
  stakeAmount: number | null,
  unStakeAmount: number | null,
  userShares: number,
  usdtBalance: number,
  t,
): { label: string; isDisabled: boolean } => {
  if (isPendingTxSign)
    return {
      label: t('pendingSignature') + '...',
      isDisabled: true,
    };

  if (isApprovingUsdt)
    return {
      label: `${t('approving')} ${brand.tokenTicker}...`,
      isDisabled: true,
    };

  if (isAwaitingConfirmation)
    return {
      label: t(amendType === AmendType.Stake ? 'staking' : 'unstaking') + '...',
      isDisabled: true,
    };

  if (step === AmendStep.ENABLE_USDT)
    return {
      label: `${t('enable')} ${brand.tokenTicker}`,
      isDisabled: false,
    };

  if (step === AmendStep.STAKE_SUCCESS)
    return {
      label: t('exploreMore'),
      isDisabled: false,
    };

  if (step === AmendStep.UNSTAKE_FAILED)
    return {
      label: t('exploreMore'),
      isDisabled: false,
    };

  if (step === AmendStep.UNSTAKE_SUCCESS)
    return {
      label: t('exploreMore'),
      isDisabled: false,
    };

  if (amendType === AmendType.Stake)
    return {
      label: t('stake'),
      isDisabled: (stakeAmount ?? 0) > usdtBalance || (stakeAmount || 0) <= 0,
    };

  if (amendType === AmendType.UnStake) {
    return {
      label: 'Unstake & Stop Earning',
      isDisabled:
        (unStakeAmount ?? 0) > userShares || (unStakeAmount || 0) === 0,
    };
  }

  return {
    label: '-',
    isDisabled: true,
  };
};

export const convertAmendTypeToSpotType = (amendType: AmendType) => {
  if (amendType === AmendType.Stake) return SpotType.Deposit;
  if (amendType === AmendType.UnStake) return SpotType.Withdraw;
  return SpotType.Empty;
};
