import { roundToNearestTick } from 'utils';
import { CreateOrderParams } from 'utils/api';
import {
  CreateLadderParamsProps,
  createLadderParams,
  getSizeFromValue,
  getUpdatedLadderOrders,
  getValueFromSize,
} from 'utils/order';

import { TCreateAndSetLadderProps, getTimeInForce } from './BuySell';
import { PriceStepKind } from './LadderInputs';

import { OrderType, TradeSide } from 'enums';
import { Market } from 'interfaces';

export interface OrderState {
  price?: number | null;
  triggerPrice?: number | null;
  quantity?: number | null;
  value?: number | null;
  orderTypeDropdownValue?: OrderType;
  fairPrice?: number;
  tradeSide?: TradeSide;
  ladder?: {
    priceStep?: number | null;
    priceStepKind?: PriceStepKind;
    sizeScale?: number | null;
    orderCount?: number | null;
  };
  ladderOrders?: {
    ladderParams: CreateLadderParamsProps | undefined;
    orders: CreateOrderParams[] | undefined;
  };
}

export enum OrderStateActionType {
  CHANGE_PRICE = 'CHANGE_PRICE',
  CHANGE_QUANTITY = 'CHANGE_QUANTITY',
  CHANGE_VALUE = 'CHANGE_VALUE',
  CHANGE_TRIGGER_PRICE = 'CHANGE_TRIGGER_PRICE',
  RESET_PRICE = 'RESET_PRICE',
  RESET_QUANTITY = 'RESET_QUANTITY',
  RESET_VALUE = 'RESET_VALUE',
  RESET_TRIGGER_PRICE = 'RESET_TRIGGER_PRICE',
  CHANGE_ORDER_TYPE = 'CHANGE_ORDER_TYPE',
  UPDATE_MARKET_PRICE = 'UPDATE_MARKET_PRICE',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  UPDATE_LADDER_STATE = 'UPDATE_LADDER_STATE',
  CHANGE_TRADE_SIDE = 'CHANGE_TRADE_SIDE',
}

type TPayload = OrderState & {
  isCurrencyKindUSD?: boolean;
  selectedMarket?: Market | null;
};
export interface OrderStateAction {
  type: OrderStateActionType;
  payload: TPayload;
}

export function orderReducer(state: OrderState, action: OrderStateAction) {
  const { type, payload } = action;
  const newState = { ...state };

  const getNewSize = () => {
    return getSizeFromValue({
      value: newState.value,
      price: newState.price,
      marketPrice: payload.selectedMarket?.fairPrice,
      orderType: newState.orderTypeDropdownValue,
      priceStep: newState.ladder?.priceStep,
      priceStepKind: newState.ladder?.priceStepKind ?? PriceStepKind.USD,
      sizeScale: newState.ladder?.sizeScale,
      orderCount: newState.ladder?.orderCount,
      tradeSide: newState.tradeSide,
      marketId: payload.selectedMarket?.id,
      minOrder: payload.selectedMarket?.minOrder,
      minTick: payload.selectedMarket?.minTick,
      timeInForce: getTimeInForce(newState.orderTypeDropdownValue),
    });
  };

  const getNewValue = () => {
    return getValueFromSize({
      quantity: newState.quantity,
      price: newState.price,
      marketPrice: payload.selectedMarket?.fairPrice,
      orderType: newState.orderTypeDropdownValue,
      priceStep: newState.ladder?.priceStep,
      priceStepKind: newState.ladder?.priceStepKind ?? PriceStepKind.USD,
      sizeScale: newState.ladder?.sizeScale,
      orderCount: newState.ladder?.orderCount,
      tradeSide: newState.tradeSide,
      marketId: payload.selectedMarket?.id,
      minOrder: payload.selectedMarket?.minOrder,
      minTick: payload.selectedMarket?.minTick,
      timeInForce: getTimeInForce(newState.orderTypeDropdownValue),
    });
  };

  const recalculateOppositeCurrencyKind = () => {
    if (payload.isCurrencyKindUSD) {
      newState.quantity = getNewSize();
    } else {
      newState.value = getNewValue();
    }
  };

  switch (type) {
    case OrderStateActionType.SET_INITIAL_STATE: {
      const initialState = {
        ...payload,
      };

      initialState.orderTypeDropdownValue =
        newState.orderTypeDropdownValue ?? OrderType.LIMIT;
      initialState.tradeSide = newState.tradeSide ?? TradeSide.LONG;

      // if (payload.quantity) {
      //   if (payload.orderTypeDropdownValue === OrderType.LIMIT) {
      //     initialState.value =
      //       payload.quantity! * (payload.price ?? payload.fairPrice!);
      //   } else {
      //     initialState.value = payload.quantity! * payload.fairPrice!;
      //   }
      // }

      initialState.ladder = {
        priceStepKind: PriceStepKind.PERCENTAGE,
        priceStep: 0.2,
        sizeScale: 1,
        orderCount: 5,
      };

      if (initialState.orderTypeDropdownValue === OrderType.LADDER) {
        const ladderOrders = getUpdatedLadderOrders(
          {
            selectedMarket: payload.selectedMarket!,
            isCurrencyKindUSD: payload.isCurrencyKindUSD!,
          },
          initialState,
        );
        initialState.ladderOrders = ladderOrders;
      }

      return {
        ...initialState,
      };
    }
    case OrderStateActionType.UPDATE_MARKET_PRICE: {
      newState.fairPrice = payload.fairPrice;
      return newState;
    }
    case OrderStateActionType.CHANGE_ORDER_TYPE: {
      newState.orderTypeDropdownValue = payload.orderTypeDropdownValue!;

      recalculateOppositeCurrencyKind();

      const ladderOrders = getUpdatedLadderOrders(
        {
          selectedMarket: payload.selectedMarket!,
          isCurrencyKindUSD: payload.isCurrencyKindUSD!,
        },
        newState,
      );
      newState.ladderOrders = ladderOrders;
      return newState;
    }
    case OrderStateActionType.CHANGE_TRADE_SIDE: {
      newState.tradeSide = payload.tradeSide!;

      const ladderOrders = getUpdatedLadderOrders(
        {
          selectedMarket: payload.selectedMarket!,
          isCurrencyKindUSD: payload.isCurrencyKindUSD!,
        },
        newState,
      );
      newState.ladderOrders = ladderOrders;
      return newState;
    }
    case OrderStateActionType.CHANGE_PRICE: {
      newState.price = payload.price;
      recalculateOppositeCurrencyKind();

      const ladderOrders = getUpdatedLadderOrders(
        {
          selectedMarket: payload.selectedMarket!,
          isCurrencyKindUSD: payload.isCurrencyKindUSD!,
        },
        newState,
      );
      newState.ladderOrders = ladderOrders;

      return newState;
    }
    case OrderStateActionType.RESET_PRICE: {
      newState.price = payload.price;
      if (state.quantity && newState.price) {
        newState.value = newState.price * state.quantity;
      } else if (state.quantity) {
        newState.value = state.fairPrice! * state.quantity;
      }
      return newState;
    }
    case OrderStateActionType.CHANGE_TRIGGER_PRICE: {
      newState.triggerPrice = payload.triggerPrice;
      return newState;
    }
    case OrderStateActionType.RESET_TRIGGER_PRICE: {
      newState.triggerPrice = payload.triggerPrice;
      return newState;
    }
    case OrderStateActionType.CHANGE_QUANTITY: {
      newState.quantity = payload.quantity;

      const updatedValue = getValueFromSize({
        quantity: payload.quantity,
        price: newState.price,
        marketPrice: payload.selectedMarket?.fairPrice,
        orderType: newState.orderTypeDropdownValue,
        priceStep: newState.ladder?.priceStep,
        priceStepKind: newState.ladder?.priceStepKind ?? PriceStepKind.USD,
        sizeScale: newState.ladder?.sizeScale,
        orderCount: newState.ladder?.orderCount,
        tradeSide: newState.tradeSide,
        marketId: payload.selectedMarket?.id,
        minOrder: payload.selectedMarket?.minOrder,
        minTick: payload.selectedMarket?.minTick,
        timeInForce: getTimeInForce(newState.orderTypeDropdownValue),
      });

      newState.value = updatedValue;

      const ladderOrders = getUpdatedLadderOrders(
        {
          selectedMarket: payload.selectedMarket!,
          isCurrencyKindUSD: payload.isCurrencyKindUSD!,
        },
        newState,
      );
      newState.ladderOrders = ladderOrders;

      return newState;
    }
    case OrderStateActionType.RESET_QUANTITY: {
      newState.quantity = null;
      if (state.value) {
        newState.value = null;
      }
      return newState;
    }
    case OrderStateActionType.CHANGE_VALUE: {
      newState.value = payload.value;
      console.log('PAYLOAD', payload);

      const updatedSize = getSizeFromValue({
        value: payload.value,
        price: newState.price,
        marketPrice: payload.selectedMarket?.marketPrice,
        orderType: newState.orderTypeDropdownValue,
        priceStep: newState.ladder?.priceStep,
        priceStepKind: newState.ladder?.priceStepKind ?? PriceStepKind.USD,
        sizeScale: newState.ladder?.sizeScale,
        orderCount: newState.ladder?.orderCount,
        tradeSide: newState.tradeSide,
        marketId: payload.selectedMarket?.id,
        minOrder: payload.selectedMarket?.minOrder,
        minTick: payload.selectedMarket?.minTick,
        timeInForce: getTimeInForce(newState.orderTypeDropdownValue),
      });

      newState.quantity = updatedSize;

      const ladderOrders = getUpdatedLadderOrders(
        {
          selectedMarket: payload.selectedMarket!,
          isCurrencyKindUSD: payload.isCurrencyKindUSD!,
        },
        newState,
      );
      newState.ladderOrders = ladderOrders;

      return newState;
    }

    case OrderStateActionType.UPDATE_LADDER_STATE: {
      newState.ladder = {
        ...newState.ladder,
        ...payload.ladder,
      };

      recalculateOppositeCurrencyKind();

      const ladderOrders = getUpdatedLadderOrders(
        {
          selectedMarket: payload.selectedMarket!,
          isCurrencyKindUSD: payload.isCurrencyKindUSD!,
        },
        newState,
      );
      newState.ladderOrders = ladderOrders;

      return newState;
    }

    default:
      return newState;
  }
}
