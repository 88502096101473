import { memo, useState } from 'react';

import { Content } from '../styles';
import ClaimBox from './ClaimBox';
import ProgressBox from './ProgressBox';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { ProfileAirdrop } from 'interfaces';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const AIRDROP_PHASE_TWO_TITLE = 'phase2';

const Container = styled.div`
  transition: opacity 0.2s ease-out, max-height 0.5s ease-out;
`;

type Props = {
  profileAirdrop: ProfileAirdrop;
};
const ClaimRewards = ({ profileAirdrop }: Props) => {
  const { t } = useTranslation();

  if (profileAirdrop === undefined) {
    return (
      <Container>
        <ColoredText color={Colors.Danger} className="mt-10">
          {t('noAirdropsFoundForUser')}
        </ColoredText>
      </Container>
    );
  }

  return (
    <Container>
      <Content isExpanded={true}>
        <p className="mb-20">
          {profileAirdrop.status === 'finished'
            ? t('claimedYourRewards')
            : t('onceClaimingLive')}
        </p>
        <ClaimBox profileAirdrop={profileAirdrop} />
        <ProgressBox profileAirdrop={profileAirdrop} />
      </Content>
    </Container>
  );
};

export default memo(ClaimRewards);
