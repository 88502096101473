import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { formatUnits, parseEther, parseUnits } from '@ethersproject/units';

import {
  Currency,
  CurrencyAmount,
  Percent,
  Token,
  TradeType,
} from '@uniswap/sdk-core';
import UniswapV2Factory from '@uniswap/v2-core/build/UniswapV2Factory.json';
import UniswapV2Pair from '@uniswap/v2-core/build/UniswapV2Pair.json';
import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json';
import {
  Pool,
  Route,
  SwapOptions,
  SwapQuoter,
  Trade,
  SwapRouter as SwapRouterUniswap,
} from '@uniswap/v3-sdk';
import { computePoolAddress } from '@uniswap/v3-sdk';
import { useWeb3React } from '@web3-react/core';

import {
  bigNumberToFloat,
  fromReadableAmount,
  roundJsDecimalToString,
} from 'utils';
import { brand, isBrandRabbitX } from 'utils/brand';
import { getContract } from 'utils/contracts';

import ERC20_ABI from '../contracts/abis/local/ERC20.json';
import { ISwapRouter02__factory } from '../contracts/types/factories/ISwapRouter02__factory';
import { UniswapSwapTokens, UniswapTokensPoolFees } from '../enums';
import { useActiveWeb3React } from './useActiveWeb3React';
import { useVerifyChainId } from './useVerifyChainId';
import { NetworkContextName } from 'constants/networkContextName';
import {
  ERC20,
  ERC20__factory,
  SwapRouter,
  SwapRouter__factory,
} from 'contracts/types';
import {
  TransactionState,
  uniswapSwapTokensMap,
} from 'pages/Trade/components/AccountStats/NewDepositModal/constants';

import { config } from 'config';
import { BigNumber, constants, ethers } from 'ethers';
import JSBI from 'jsbi';

const THRUSTER_POOL_DEPLOYER_ADDRESS =
  '0xa08ae3d3f4dA51C22d3c041E468bdF4C61405AaB';

const brandedStablecoinSelect = (): Token => {
  return isBrandRabbitX ? uniswapSwapTokensMap.USDT : uniswapSwapTokensMap.USDB;
};

export type TokenTrade = Trade<Token, Token, TradeType>;

function useUniswapSdk() {
  const { library: providerNetwork } =
    useWeb3React<Web3Provider>(NetworkContextName);
  const { library: provider } = useActiveWeb3React();
  const { isCorrectChainId } = useVerifyChainId();

  const isTokenApprovedForSwap = async ({
    token,
    walletAddress,
    amount,
    isBlasterSwap = false,
  }: {
    token: Token;
    walletAddress: string;
    amount?: number;
    isBlasterSwap?: boolean;
  }): Promise<boolean> => {
    try {
      if (!providerNetwork) {
        throw Error(
          'Network provider is not ready when checking token allowance!',
        );
      }

      // No need to check for ETH
      if (token === uniswapSwapTokensMap.ETH) {
        return true;
      }

      const tokenReadContract = getContract<ERC20>(
        ERC20__factory,
        token.address,
        providerNetwork,
      );
      const allowance: BigNumber = (await tokenReadContract.allowance(
        walletAddress,
        isBlasterSwap
          ? config.uniswapSdkAddresses.BlasterswapSwapRouter
          : config.uniswapSdkAddresses.SwapRouter,
      )) as BigNumber;
      // Check for custom amount allowance
      if (amount) {
        return allowance.gte(
          parseUnits(
            roundJsDecimalToString(amount, token.decimals),
            token.decimals,
          ),
        );
      }
      // Otherwise check for max allowance
      return constants.MaxUint256.div(2).lt(allowance);
    } catch (e: any) {
      console.error(e);
      throw Error('Failed to check token allowance!');
    }
  };

  const approveTokenForSwap = async (
    token: Token,
    walletAddress: string,
    amount?: number,
    isBlasterSwap = false,
  ): Promise<TransactionResponse> => {
    try {
      if (!isCorrectChainId) {
        throw new Error(
          `Incorrect chain ID when approving ${brand.tokenTicker}`,
        );
      }
      if (!provider) {
        throw Error('Network provider is not ready!');
      }

      const ERC20SignContract = getContract<ERC20>(
        ERC20__factory,
        token.address,
        provider,
        walletAddress,
      );

      const allowanceAmount = amount
        ? parseUnits(amount.toString(), token.decimals)
        : constants.MaxUint256;
      const tx = (await ERC20SignContract.approve(
        isBlasterSwap
          ? config.uniswapSdkAddresses.BlasterswapSwapRouter
          : config.uniswapSdkAddresses.SwapRouter,
        allowanceAmount,
      )) as TransactionResponse;

      return tx;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };

  const getCurrencyBalance = async (
    address: string,
    currency: Currency,
  ): Promise<number> => {
    if (!providerNetwork) {
      throw Error('Network provider is not ready!');
    }

    // Handle ETH directly
    if (currency.isNative) {
      const balance = await providerNetwork.getBalance(address);
      return bigNumberToFloat(balance, 18);
    }

    // Get currency otherwise
    const currencyContract = new ethers.Contract(
      currency.address,
      ERC20_ABI,
      providerNetwork,
    );
    const balance: number = await currencyContract.balanceOf(address);
    const decimals: number = await currencyContract.decimals();

    return bigNumberToFloat(BigNumber.from(balance), decimals);
  };

  const getQuoteV2 = async ({
    tokenIn,
    tokenOut = brandedStablecoinSelect(),
    amountIn,
  }: {
    tokenIn: Token;
    tokenOut?: Token;
    amountIn: number;
  }): Promise<string> => {
    if (!providerNetwork) {
      throw Error('Network provider is not ready!');
    }

    if (!amountIn) {
      return '0';
    }

    if (tokenIn === tokenOut) {
      return amountIn.toString();
    }

    const factory = new ethers.Contract(
      config.uniswapSdkAddresses.BlasterswapPoolFactory,
      UniswapV2Factory.abi,
      providerNetwork,
    );
    const pair = await factory.getPair(tokenIn.address, tokenOut.address);
    const pairContract = new ethers.Contract(
      pair,
      UniswapV2Pair.abi,
      providerNetwork,
    );
    const reserves = await pairContract.getReserves();
    const [r0, r1] = reserves;
    const fr0 = formatUnits(r1, tokenIn.decimals);
    const fr1 = formatUnits(r0, tokenOut.decimals);

    const fee = 1 - 0.3 / 100;
    const amountInWithFee = amountIn * fee;
    const numerator = amountInWithFee * +fr1;
    const denominator = +fr0 + amountInWithFee;
    return String(numerator / denominator);
  };

  const getQuote = async ({
    tokenIn,
    tokenOut = brandedStablecoinSelect(),
    amountIn,
  }: {
    tokenIn: Token;
    tokenOut?: Token;
    amountIn: number;
  }): Promise<string> => {
    if (!providerNetwork) {
      throw Error('Network provider is not ready!');
    }

    if (!amountIn) {
      return '0';
    }

    if (tokenIn === tokenOut) {
      return amountIn.toString();
    }

    const poolConstants = await getPoolInfo({ tokenIn, tokenOut });

    const pool = new Pool(
      tokenIn,
      tokenOut,
      poolConstants.fee,
      poolConstants.sqrtPriceX96.toString(),
      poolConstants.liquidity.toString(),
      poolConstants.tick,
    );

    const swapRoute = new Route([pool], tokenIn, tokenOut);

    const amountOut = await getOutputQuote({
      route: swapRoute,
      tokenIn,
      tokenOut,
      amountIn,
    });

    const parsedAmount = CurrencyAmount.fromRawAmount(
      tokenOut,
      JSBI.BigInt(amountOut),
    );

    return parsedAmount.toExact();
  };

  async function getPoolInfo({
    tokenIn,
    tokenOut,
  }: {
    tokenIn: Token;
    tokenOut: Token;
  }): Promise<{
    token0: string;
    token1: string;
    fee: number;
    tickSpacing: number;
    sqrtPriceX96: ethers.BigNumber;
    liquidity: ethers.BigNumber;
    tick: number;
  }> {
    if (!providerNetwork) {
      throw Error('Network provider is not ready!');
    }

    const currentPoolAddress = computePoolAddress({
      factoryAddress: isBrandRabbitX
        ? config.uniswapSdkAddresses.PoolFactory
        : THRUSTER_POOL_DEPLOYER_ADDRESS,
      tokenA: tokenIn,
      tokenB: tokenOut,
      fee: UniswapTokensPoolFees[tokenIn.symbol as string],
      initCodeHashManualOverride: !isBrandRabbitX
        ? '0xd0c3a51b16dbc778f000c620eaabeecd33b33a80bd145e1f7cbc0d4de335193d'
        : undefined,
    });

    const poolContract = new ethers.Contract(
      currentPoolAddress,
      IUniswapV3PoolABI.abi,
      providerNetwork,
    );
    const [token0, token1, fee, tickSpacing, liquidity, slot0] =
      await Promise.all([
        poolContract.token0(),
        poolContract.token1(),
        poolContract.fee(),
        poolContract.tickSpacing(),
        poolContract.liquidity(),
        poolContract.slot0(),
      ]);

    return {
      token0,
      token1,
      fee,
      tickSpacing,
      liquidity,
      sqrtPriceX96: slot0[0],
      tick: slot0[1],
    };
  }

  const getOutputQuote = async ({
    route,
    tokenIn,
    tokenOut = brandedStablecoinSelect(),
    amountIn,
  }: {
    route: Route<Currency, Currency>;
    tokenIn: Token;
    tokenOut?: Token;
    amountIn: number;
  }) => {
    if (!providerNetwork) {
      throw new Error('Provider required to get pool state');
    }

    const { calldata } = await SwapQuoter.quoteCallParameters(
      route,
      CurrencyAmount.fromRawAmount(
        tokenIn,
        fromReadableAmount(amountIn, tokenIn.decimals).toString(),
      ),
      TradeType.EXACT_INPUT,
      {
        useQuoterV2: true,
      },
    );

    const quoteCallReturnData = await providerNetwork.call({
      to: config.uniswapSdkAddresses.Quoterv2,
      data: calldata,
    });

    return ethers.utils.defaultAbiCoder.decode(
      ['uint256'],
      quoteCallReturnData,
    );
  };

  const createTrade = async ({
    tokenIn,
    tokenOut = brandedStablecoinSelect(),
    amountIn,
  }: {
    tokenIn: Token;
    tokenOut?: Token;
    amountIn: number;
  }): Promise<TokenTrade> => {
    const poolInfo = await getPoolInfo({
      tokenIn,
      tokenOut,
    });

    const pool = new Pool(
      tokenIn,
      tokenOut,
      poolInfo.fee,
      poolInfo.sqrtPriceX96.toString(),
      poolInfo.liquidity.toString(),
      poolInfo.tick,
    );

    const swapRoute = new Route([pool], tokenIn, tokenOut);

    const amountOut = await getOutputQuote({
      route: swapRoute,
      tokenIn,
      tokenOut,
      amountIn,
    });

    const uncheckedTrade = Trade.createUncheckedTrade({
      route: swapRoute,
      inputAmount: CurrencyAmount.fromRawAmount(
        tokenIn,
        fromReadableAmount(amountIn, tokenIn.decimals).toString(),
      ),
      outputAmount: CurrencyAmount.fromRawAmount(
        tokenOut,
        JSBI.BigInt(amountOut),
      ),
      tradeType: TradeType.EXACT_INPUT,
    });

    return uncheckedTrade;
  };

  const executeTradeV2 = async ({
    quote,
    tokenIn,
    tokenOut = brandedStablecoinSelect(),
    amount,
    walletAddress,
    slippageTolerance,
    selectedAsset,
  }: {
    quote: string | undefined;
    tokenIn: Token;
    tokenOut?: Token;
    amount: number;
    walletAddress: string;
    slippageTolerance: number;
    selectedAsset: UniswapSwapTokens;
  }): Promise<any> => {
    if (!provider) {
      throw new Error('Cannot execute a trade without a connected wallet');
    }

    if (!quote) {
      throw new Error('Cannot execute a trade without a quote');
    }

    // Give approval to the router to spend the token
    const tokenApproval = await getTokenTransferApproval(
      tokenIn,
      walletAddress,
      amount,
    );

    if (!tokenApproval) {
      return TransactionState.Failed;
    }

    const swapRouterContract = ISwapRouter02__factory.connect(
      config.uniswapSdkAddresses.BlasterswapSwapRouter,
      provider.getSigner(),
    );

    const deadline: number = Math.floor(Date.now() / 1000) + 60 * 20;
    const minAmountOut = +quote - (+quote * slippageTolerance) / 100;

    let tx;
    if (selectedAsset === UniswapSwapTokens.ETH) {
      tx = await swapRouterContract.swapExactETHForTokens(
        parseUnits(minAmountOut.toString(), tokenOut.decimals),
        [tokenIn.address, tokenOut.address],
        walletAddress,
        deadline,
        { value: parseEther(amount.toString()) },
      );
    } else {
      tx = await swapRouterContract.swapExactTokensForTokens(
        parseUnits(amount.toString(), tokenIn.decimals),
        parseUnits(minAmountOut.toString(), tokenOut.decimals),
        [tokenIn.address, tokenOut.address],
        walletAddress,
        deadline,
      );
    }

    return tx;
  };

  const executeTrade = async ({
    trade,
    tokenIn,
    tokenOut = brandedStablecoinSelect(),
    amount,
    walletAddress,
    slippageTolerance,
  }: {
    trade: TokenTrade;
    tokenIn: Token;
    tokenOut?: Token;
    amount: number;
    walletAddress: string;
    slippageTolerance: number;
  }): Promise<any> => {
    if (!provider) {
      throw new Error('Cannot execute a trade without a connected wallet');
    }

    // Give approval to the router to spend the token
    const tokenApproval = await getTokenTransferApproval(
      tokenIn,
      walletAddress,
      amount,
    );

    if (!tokenApproval) {
      return TransactionState.Failed;
    }

    const options: SwapOptions = {
      slippageTolerance: new Percent(slippageTolerance * 100, 10_000),
      deadline: Math.floor(Date.now() / 1000) + 60 * 20, // 20 minutes from the current Unix time
      recipient: walletAddress,
    };

    const methodParameters = SwapRouterUniswap.swapCallParameters(
      [trade],
      options,
    );

    const swapRouterContract = getContract<SwapRouter>(
      SwapRouter__factory,
      config.uniswapSdkAddresses.SwapRouter,
      provider,
      walletAddress,
    );

    const amountOutMinimum = trade.minimumAmountOut(options.slippageTolerance);

    const poolFee = UniswapTokensPoolFees[tokenIn.symbol as string];

    const currentBlock = await provider.getBlock('latest');

    // Add a buffer to the deadline to ensure the transaction goes through
    const deadlineBufferSeconds = 60 * 2;
    const swapParams = {
      tokenIn: tokenIn.address,
      tokenOut: tokenOut.address,
      fee: poolFee,
      recipient: walletAddress,
      deadline: currentBlock.timestamp + deadlineBufferSeconds,
      amountIn: parseUnits(amount.toString(), tokenIn.decimals),
      amountOutMinimum: parseUnits(
        amountOutMinimum.toExact(),
        tokenOut.decimals,
      ),
      sqrtPriceLimitX96: 0,
    };

    const swapOverrides = {
      from: walletAddress,
      value:
        tokenIn === uniswapSwapTokensMap.ETH
          ? parseUnits(amount.toString(), tokenIn.decimals)
          : methodParameters.value,
    };

    const tx = await swapRouterContract.exactInputSingle(
      swapParams,
      swapOverrides,
    );

    return tx;
  };

  const getTokenTransferApproval = async (
    token: Token,
    address: string,
    amount,
  ): Promise<any> => {
    if (!provider || !address) {
      console.error('No Provider Found');
      return TransactionState.Failed;
    }

    try {
      if (token === uniswapSwapTokensMap.ETH) {
        return true;
      }
      const tokenContract = new ethers.Contract(
        token.address,
        ERC20_ABI,
        provider,
      );

      // Retrieve the current allowance
      const currentAllowance = await tokenContract.allowance(
        address,
        config.uniswapSdkAddresses.SwapRouter,
      );

      // Convert the desired amount to a BigNumber for comparison
      const amountInBigNumber = ethers.BigNumber.from(
        fromReadableAmount(amount, token.decimals).toString(),
      );

      // Only proceed if the current allowance is less than the desired amount
      if (currentAllowance.lt(amountInBigNumber)) {
        const transaction = await tokenContract.populateTransaction.approve(
          config.uniswapSdkAddresses.SwapRouter,
          fromReadableAmount(amount, token.decimals).toString(),
        );

        const tx = await provider.getSigner().sendTransaction(transaction);
        await tx.wait();
        return TransactionState.Sent;
      } else {
        return TransactionState.Sent;
      }
    } catch (e) {
      console.error(e);
      return TransactionState.Failed;
    }
  };

  return {
    getQuoteV2,
    getQuote,
    getCurrencyBalance,
    createTrade,
    executeTrade,
    executeTradeV2,
    isTokenApprovedForSwap,
    approveTokenForSwap,
  } as const;
}

export default useUniswapSdk;

export type UniswapSdk = ReturnType<typeof useUniswapSdk>;
