import { useState } from 'react';

import {
  SelectDropdown,
  Props as SelectDropdownProps,
} from 'components/SelectDropdown.tsx';

import { SelectorContainer, SelectorOption } from './styles';
import arrowDownIcon from 'assets/icons/chevron-down-gray.svg';
import arrowUpIcon from 'assets/icons/chevron-up-gray.svg';

import { UniswapSwapTokens, Chain } from 'enums';

type SelectorProps<T> = {
  title: string;
  value: T;
  getLabelAndIcon: (val: T) => { icon: string; label: string };
  disabledLabel?: string;
} & Omit<SelectDropdownProps<T>, 'InputElement'>;

const Selector = <T extends Chain | UniswapSwapTokens>({
  getLabelAndIcon,
  value,
  title,
  disabled,
  disabledLabel,
  ...selectDropDownProps
}: SelectorProps<T>) => {
  const { icon, label } = getLabelAndIcon(value);
  const [isDropdownShown, setIsDropdownShown] = useState(false);

  return (
    <SelectDropdown<T>
      containerClass="flex-grow"
      inputClass="flex-grow"
      customStyles={{
        top: '60px',
        liPadding: '0',
      }}
      onDropdownShownChange={setIsDropdownShown}
      displayTextParser={(option: T) => (
        <SelectorOption selected={value === option}>
          <img src={getLabelAndIcon(option).icon} alt={option} />
          {option}
        </SelectorOption>
      )}
      InputElement={
        <SelectorContainer disabled={disabled}>
          <div className="column">
            <span className="label">{title}</span>
            <span className="value">
              <img src={icon} alt={label} />
              {label}
            </span>
          </div>
          {!disabled ? (
            <img
              src={isDropdownShown ? arrowUpIcon : arrowDownIcon}
              alt={'toggle'}
              className="arrow-icon"
            />
          ) : null}
          {disabledLabel ? (
            <span className="disabled-badge">{disabledLabel}</span>
          ) : null}
        </SelectorContainer>
      }
      disabled={disabled}
      {...selectDropDownProps}
    />
  );
};

export default Selector;
