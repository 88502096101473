import { memo } from 'react';

import { getTimeUntil } from 'utils';

import { Icon } from 'components';
import CountdownTimer from 'components/Tables/TransfersTable/CountdownTimer';
import Text from 'components/Text';

import bfxIcon from 'assets/assetLogos/blast-green.svg';
import rbxIcon from 'assets/assetLogos/rbx-black-ear.svg';
import clockIcon from 'assets/icons/pending-white.svg';
import { Column } from 'theme/globalStyledComponents/column';
import { Row } from 'theme/globalStyledComponents/row';

import styled from 'styled-components';

const SNAPSHOT_TIMESTAMP = 1717200000;

const Container = styled(Row)`
  gap: 5px;

  .icons {
    height: 32px;
    width: 38px;
    position: relative;

    > img {
      height: 24px;
      width: 24px;
    }

    > img:last-child {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 100px;
      height: 18px;
      width: 18px;
      padding: 3px;
    }
  }

  .time-badge {
    display: flex;
    padding: 3px 4px;
    align-items: center;
    gap: 3px;
    border-radius: 48px;
    background: ${({ theme }) => theme.colors.shadesBackground500};
  }
`;

const ContractDetailsHeader = () => {
  const { total } = getTimeUntil(Number(SNAPSHOT_TIMESTAMP) * 1_000_000);

  return (
    <Container>
      <div className="icons">
        <img src={bfxIcon} alt="rbx" />
        <img src={rbxIcon} alt="bfx" />
      </div>

      <Column gap={2}>
        <Text variant="BODY_M" fontWeight="semiBold">
          Snapshot In:
        </Text>

        <Text className="time-badge" variant="BODY_XS">
          <Icon src={clockIcon} size="S" />
          <CountdownTimer timeInSeconds={total / 1000} format="extended" />
        </Text>
      </Column>
    </Container>
  );
};

export default memo(ContractDetailsHeader);
