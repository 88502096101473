import { memo } from 'react';

import { brand } from 'utils/brand';

import { FormattedNumber } from 'components/FormattedNumber';

import shortcutIcon from 'assets/icons/shortcut-gray.svg';
import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';

import { config } from 'config';
import { LocalStorageTransactionTypes } from 'enums';
import styled from 'styled-components';

const Container = styled.div`
  p {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  .confirmations {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  .Toastify__progress-bar-theme--dark {
    background: red !important;
  }
`;

const getTitle = (type: LocalStorageTransactionTypes) => {
  if (type === LocalStorageTransactionTypes.Deposit) return 'Deposit';
  if (type === LocalStorageTransactionTypes.VaultStake) return 'Stake Request';
  return '';
};

const getDescription = (type: LocalStorageTransactionTypes, amount: number) => {
  if (type === LocalStorageTransactionTypes.Deposit)
    return (
      <>
        Your deposit of{' '}
        <FormattedNumber value={amount} suffix={` ${brand.tokenTicker}`} /> will
        be available after {CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT}{' '}
        confirmations.
      </>
    );
  if (type === LocalStorageTransactionTypes.VaultStake)
    return (
      <>
        Your <FormattedNumber value={amount} suffix={` ${brand.tokenTicker}`} />{' '}
        will be staked once {CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT}{' '}
        confirmations have been received.
      </>
    );
  return <></>;
};

type Props = {
  confirmations: number;
  depositAmount: number;
  txhash: string;
  type?: LocalStorageTransactionTypes;
};
const DepositInProgressToast = ({
  confirmations,
  depositAmount,
  txhash,
  type = LocalStorageTransactionTypes.Deposit, // default if none is supplied
}: Props) => {
  const etherscanUrl = `${config.etherscanUrl}/tx/${txhash}`;

  return (
    <Container>
      <h6>{getTitle(type)}</h6>
      <p>
        {getDescription(type, depositAmount)}
        <a
          href={etherscanUrl}
          target="_blank"
          rel="noreferrer"
          className="ml-5"
        >
          <img src={shortcutIcon} alt="Shortcut" />
        </a>
      </p>
      <p className="m-0 confirmations">
        {Math.min(confirmations, CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT)} OF{' '}
        {CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT} CONFIRMATIONS
      </p>
    </Container>
  );
};

export default memo(DepositInProgressToast);
