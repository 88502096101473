import Text from 'components/Text';
import Radio from 'components/Toggle/Radio';

import { Container } from '../styles';

type TLabelWithRadioOption = {
  selected: boolean;
  label: string | React.ReactElement;
};

export const LabelWithRadio = ({ selected, label }: TLabelWithRadioOption) => {
  return (
    <Container selected={selected} padding={10}>
      <Radio selected={selected} size="SM" />
      <Text
        fontWeight={selected ? 'semiBold' : 'medium'}
        color={selected ? 'white' : 'shadesForeground200'}
        variant="BODY_S"
        noWrap
      >
        {label}
      </Text>
    </Container>
  );
};

export default LabelWithRadio;
