import { isBrandBfx } from 'utils/brand';

import LockOptionLayout from '../Layout';
import { BFX_BADGE, POINTS_BADGE } from '../badges';
import engageAndEarnBanner from 'assets/images/engageEarnBg.webp';

const EngageAndEarn = () => {
  return (
    <LockOptionLayout
      headerProps={{
        backgroundImage: engageAndEarnBanner,
        title: 'Engage & Earn',
        description: 'Deposit, trade, stake on BFX to earn BFX Points',
        badges: isBrandBfx ? [BFX_BADGE, POINTS_BADGE] : [],
      }}
    >
      <></>
    </LockOptionLayout>
  );
};

export default EngageAndEarn;
