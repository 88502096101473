import { useState, useEffect, useRef } from 'react';

import {
  getCurrencyFromTradingPair,
  removeTrailingDigitsFromTradingPair,
} from 'utils';
import { brand } from 'utils/brand';

import { useOnClickOutside } from 'hooks';
import { useConfirmCancelOrder } from 'hooks/useConfirmCancelOrder';
import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import useModal from 'hooks/useModal';

import { filterOrders } from 'components/Tables/OrdersTable/utils';

import RecentTrades from '../RecentTrades/RecentTrades';
import AsksTable from './AsksTable';
import BidsTable from './BidsTable';
import CurrentPriceRow from './CurrentPriceRow';
import Header from './Header';
import { Container, OrdersTableStyled } from './OrderBookStyles';
import OrderEntryPopUp from './OrderbookEntryPopUp/OrderEntryPopUp';
import TitleRow from './TitleRow';
import { useAppContext } from 'contexts/AppContext';

import { TradeSide } from 'enums';
import { CurrencyKind, Order, OrderbookOrder } from 'interfaces';
import { observer } from 'mobx-react';
import styled from 'styled-components';

export enum Tab {
  'ORDER_BOOK',
  'RECENT_TRADES',
}

const getOrdersForMarketId = (
  _orders: Order[] | undefined,
  selectedMarketId: string,
) => {
  const filteredOrders: Order[] = filterOrders({
    _orders,
    showAllMarkets: false,
    selectedMarketId,
    shouldHideClosed: true,
  });

  const result = filteredOrders.reduce(
    (acc: Array<Order[]>, order: Order) => {
      if (order.side === TradeSide.SHORT) {
        acc[0].push(order);
      } else if (order.side === TradeSide.LONG) {
        acc[1].push(order);
      }
      return acc;
    },
    [[], []],
  );
  return result;
};

const AsksAndBidsWrapper = styled.div`
  position: relative;
`;

export type AskBidTableProps = {
  setOrderInputPrice: (price: number) => void;
  setIsOrderEntryPopUpShown: (isShown: boolean) => void;
  setOrderbookOrderEntryPrice: (price: number) => void;
  ordersAndTradesCurrencyKind: CurrencyKind | undefined;
  selectedMarketId: string;
  pricePrecision: number;
  minTick: number;
  isLoading: boolean;
  selectedTick: number;
  selectedMarketOrders: Order[];
  cancelOrders: (order: Order[]) => Promise<void>;
  isOrderEntryPopUpShown: boolean;
  orderbookOrderEntryPrice: number | null;
  currentlyCancellingOrderIds: string[];
  cancelOrder: (order: Order) => void;
};

const constructDocTitleFromPriceAndMarketId = (
  midPrice: string,
  marketId: string,
) => {
  return `${midPrice} | ${removeTrailingDigitsFromTradingPair(
    getCurrencyFromTradingPair(marketId),
  )} | ${brand.appShortName}`;
};

const OrderBook = ({ showRecentTrades }: { showRecentTrades: boolean }) => {
  const { cancelMultipleOrders, currentlyCancellingOrderIds, cancelOrder } =
    useConfirmCancelOrder();
  const [selectedTab, setSelectedTab] = useState(Tab.ORDER_BOOK);
  const containerRef = useRef<HTMLDivElement>(null);
  const modal = useModal();

  const {
    store: {
      orderbook: {
        asks: allAsks,
        bids: allBids,
        isLoading,
        setOrderInputPrice,
        setIsOrderEntryPopUpShown,
        setOrderbookOrderEntryPrice,
        getSelectedMarketOrderbookTick,
        isOrderEntryPopUpShown,
        selectedMarketID,
        orderbookOrderEntryPrice,
        spreadPercentage,
        midPrice,
      },
      account: { _orders },
      markets: { selectedMarketId },
      appState: { defaultCurrencyKind },
    },
  } = useAppContext();

  const [askOrders, bidOrders] = getOrdersForMarketId(
    _orders,
    selectedMarketID,
  );

  const { minTick, price: pricePrecision } =
    useGetDecimalPlaces(selectedMarketID);

  if (midPrice && selectedMarketId) {
    const stringifiedMidPrice = midPrice.toLocaleString(undefined, {
      minimumFractionDigits: pricePrecision,
      maximumFractionDigits: pricePrecision,
    });
    document.title = constructDocTitleFromPriceAndMarketId(
      stringifiedMidPrice,
      selectedMarketId,
    );
  }

  // Reset Document title once unmounted!
  useEffect(() => {
    return () => {
      document.title = brand.appTitle;
    };
  }, []);

  // close the pop up when clicking outside of it or pressing the escape key
  useOnClickOutside(containerRef, () => {
    // Don't close the pop up if the confirmation modal (or any other) is shown
    if (modal.activeModals.length > 0) return;

    setIsOrderEntryPopUpShown(false);
  });

  const savedUserTick = getSelectedMarketOrderbookTick();

  const selectedTick = savedUserTick === undefined ? minTick : savedUserTick;

  const tableProps = {
    setOrderInputPrice,
    setIsOrderEntryPopUpShown,
    setOrderbookOrderEntryPrice,
    ordersAndTradesCurrencyKind: defaultCurrencyKind,
    selectedMarketId,
    pricePrecision,
    minTick,
    isLoading,
    selectedTick,
    cancelOrders: (orders: Order[]) => cancelMultipleOrders(orders, false),
    isOrderEntryPopUpShown,
    orderbookOrderEntryPrice,
    currentlyCancellingOrderIds,
    cancelOrder,
  };

  return (
    <Container data-cy="orderbook">
      <Header
        minTick={minTick}
        showRecentTrades={showRecentTrades}
        onTabChange={setSelectedTab}
        activeTab={selectedTab}
      />
      {!showRecentTrades || selectedTab === Tab.ORDER_BOOK ? (
        <AsksAndBidsWrapper id="asks-and-bids-wrapper" ref={containerRef}>
          <OrdersTableStyled>
            {isOrderEntryPopUpShown ? <OrderEntryPopUp /> : null}
            <TitleRow />
            <AsksTable
              allAsks={allAsks}
              selectedMarketOrders={askOrders}
              {...tableProps}
            />
          </OrdersTableStyled>
          <CurrentPriceRow
            onClick={() => setIsOrderEntryPopUpShown(false)}
            spreadPercentage={spreadPercentage}
          />
          <OrdersTableStyled>
            <TitleRow />
            <BidsTable
              allBids={allBids}
              selectedMarketOrders={bidOrders}
              {...tableProps}
            />
          </OrdersTableStyled>
        </AsksAndBidsWrapper>
      ) : null}
      {showRecentTrades && selectedTab === Tab.RECENT_TRADES ? (
        <RecentTrades avoidHeader avoidPaddings />
      ) : null}
    </Container>
  );
};

export default observer(OrderBook);
