import { Table, TableContainer, TableRow } from '@material-ui/core';

import { isBrandRabbitX } from 'utils/brand';

import { TextVariant } from 'components/Text/variant';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  .outer-table-container {
    padding: 15px;
    display: flex;
    background-color: ${({ theme }) => theme.colors.shadesBackground800};
    border-radius: 8px;
    margin-top: -10px;
    z-index: 3;
    position: relative;
  }
`;

export const TableBanner = styled.div`
  background-color: ${isBrandRabbitX ? '#292848' : '#111518'};
  position: relative;
  overflow: hidden;

  > div > img {
    border-radius: 12px;
    position: absolute;
    right: 0;
    top: 0;
    height: 225px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      height: 280px;
    }
  }

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  .content {
    height: 166px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      height: 183px;
    }

    box-sizing: border-box;
    justify-content: center;
    position: relative;
    z-index: 2;
    display: flex;
    padding: 35px 30px 45px 30px;

    flex-direction: column;
    gap: 15px;

    > span:first-child {
      font-size: 13px;
      line-height: normal;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        font-size: 16px;
      }
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      padding: 30px 30px 40px 30px;
    }

    > h2 {
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};

      font-size: 20px;
      line-height: normal;
      letter-spacing: -0.4px;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        font-size: 24px;
        letter-spacing: -0.48px;
      }
    }
  }
`;

export const TableContainerStyled = styled(TableContainer)``;

export const TableStyled = styled(Table)`
  border-collapse: collapse;

  /* tbody tr:after {
    content: '-';
    display: block;
    line-height: 8px;
    color: transparent;
  } */

  tbody tr.empty-row {
    height: 5px;
    background: transparent;
    td {
      padding: 0;
    }
  }

  tbody tr.separator-row {
    height: 1px;
    background: ${({ theme }) => theme.colors.shadesBackground500};
    td {
      padding: 0;
    }
  }

  tr td,
  tr th {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    border: 0;
    /* border-bottom: 10px solid ${({ theme }) =>
      theme.colors.shadesBackground800}; */
  }

  tr th {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    font-size: ${TextVariant['BODY_S'].fontSize};
    line-height: ${TextVariant['BODY_S'].lineHeight};
    padding: 0 15px 10px 15px;
  }
  tr td {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${TextVariant['BODY_S'].fontSize};
    line-height: ${TextVariant['BODY_S'].lineHeight};
    padding: 11.5px 15px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    tr th {
      font-size: ${TextVariant['BODY_M'].fontSize};
      line-height: ${TextVariant['BODY_M'].lineHeight};
    }
    tr td {
      font-size: ${TextVariant['BODY_M'].fontSize};
      line-height: ${TextVariant['BODY_M'].lineHeight};
    }
  }

  tbody tr td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  tbody tr td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  /* tr:last-child td {
    padding-bottom: 0;
  } */
`;

type TableRowStyledProps = {
  backgroundGradient?: string;
};
export const TableRowStyled = styled(TableRow)<TableRowStyledProps>`
  background: ${({ backgroundGradient }) =>
    backgroundGradient ?? 'transparent'};

  .icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
`;

export const RankCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  > img {
    width: 18px;
  }
`;

type RankChangeCellProps = {
  isNegative?: boolean;
  isPositive?: boolean;
  isNeutral?: boolean;
};
export const RankChangeLabel = styled.span<RankChangeCellProps>`
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 2px 4px;
  font-size: ${TextVariant['BODY_S'].fontSize};
  line-height: ${TextVariant['BODY_S'].lineHeight};
  border-radius: 4px;

  > img {
    width: 12px;
    height: 12px;
  }

  ${({ isPositive }) =>
    isPositive &&
    css`
      background: ${({ theme }) => theme.colors.positiveBackground100};
      color: ${({ theme }) => theme.colors.positiveForeground200};
    `};

  ${({ isNegative }) =>
    isNegative &&
    css`
      background: ${({ theme }) => theme.colors.negativeBackground100};
      color: ${({ theme }) => theme.colors.negativeForeground200};
    `};

  ${({ isNeutral }) =>
    isNeutral &&
    css`
      background: ${({ theme }) => theme.colors.shadesBackground500};
      color: ${({ theme }) => theme.colors.shadesForeground200};
    `};
`;
