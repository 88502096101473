import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  /* .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 12px 0;

    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      width: 48%;
      > span.title {
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-size: 14px;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.shadesForeground200};

        margin-bottom: 6px;
      }
    }
    span.formatted-number {
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }
  } */
`;
