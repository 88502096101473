import { TableBody, TableContainer } from '@material-ui/core';

import { showNotification } from 'utils';

import { useActiveWeb3React, useBalanceOperationsAPI } from 'hooks';

import Text from 'components/Text';

import EmptyActivityTableState from './EmptyState';
import TableHead from './TableHead';
import UnstakeRequestsRow from './UnstakeRequestsRow';
import { useAppContext } from 'contexts/AppContext';
import { ColoredText, Colors } from 'pages/Trade/components/shared';
import { StyledDarkTable, StyledTableContainer } from 'pages/Vaults/styles';

import { NotificationType, QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

type Props = {
  vaultAddress?: string;
};
const UnstakeRequestsTable = ({ vaultAddress }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { account } = useActiveWeb3React();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { fetchPrivateRequestedUnstakes, processUnstake } =
    useBalanceOperationsAPI();

  const {
    isLoading: isLoadingRequestedUnstakes,
    data: requestedUnstakes,
    isError: isErrorRequestedUnstakes,
  } = useQuery(
    [QueryKeys.BalanceOpsRequestedUnstakes, frontendSecrets?.profile.wallet],
    async () => {
      return await fetchPrivateRequestedUnstakes();
    },
    {
      enabled: !!frontendSecrets?.profile.wallet,
      refetchInterval: 10_000,
      staleTime: 5_000,
    },
  );

  if (!account || !frontendSecrets?.profile.wallet) return null;

  const onApprove = async (opsId2: string) => {
    if (!opsId2 || !frontendSecrets.profile.wallet) return;

    try {
      showNotification({
        title: `${t('approvingUnstakeRequestFor')} opsId2: ${opsId2}!`,
        type: NotificationType.Info,
      });

      // extract the last word after last _ in the opsId2
      const fromId = Number(opsId2.split('_').pop());

      if (fromId === undefined) {
        showNotification({
          title: `${t('invalid')} opsId2: ${opsId2}!`,
          type: NotificationType.Negative,
        });
        return;
      }

      await processUnstake(
        frontendSecrets.profile.wallet.toLowerCase(),
        fromId,
      );

      queryClient.invalidateQueries([
        QueryKeys.BalanceOpsRequestedUnstakes,
        frontendSecrets.profile.wallet,
      ]);

      showNotification({
        title: `🎉 ${t('sucessfullyApprovedUnstakeRequest')}! `,
        type: NotificationType.Positive,
      });
    } catch (err: any) {
      showNotification({
        title: t('couldntApproveUnstakeRequest'),
        description: err?.message,
        type: NotificationType.Negative,
      });
    }
  };

  return (
    <TableContainer>
      <StyledTableContainer>
        <Text
          fontWeight="semiBold"
          className="header-span"
          variant="HEADING_H2"
        >
          {t('unstakeRequests')}
        </Text>

        {isErrorRequestedUnstakes && (
          <ColoredText color={Colors.Danger}>
            {t('errorFetchingRequestedUnstakePleaseRefresh')}
          </ColoredText>
        )}

        {!isErrorRequestedUnstakes && (
          <StyledDarkTable stickyHeader>
            <TableHead />

            {isLoadingRequestedUnstakes ||
            !requestedUnstakes ||
            requestedUnstakes.length === 0 ? (
              <EmptyActivityTableState isLoading={isLoadingRequestedUnstakes} />
            ) : (
              <TableBody>
                {requestedUnstakes.map(balanceOps => {
                  return (
                    <UnstakeRequestsRow
                      balanceOps={balanceOps}
                      onApprove={onApprove}
                    />
                  );
                })}
              </TableBody>
            )}
          </StyledDarkTable>
        )}
      </StyledTableContainer>
    </TableContainer>
  );
};

export default observer(UnstakeRequestsTable);
