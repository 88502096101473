import { useCallback } from 'react';

import { useExchangeAPI } from './useExchangeAPI';
import { API_MAPPINGS } from 'constants/apiMappings';
import {
  IGameAssetsBlast,
  IGameAssetsBlastLeaderboardItem,
} from 'interfaces/gameAssets';

import { ApiMappings, Endpoints, RequestMethod } from 'enums';

export function useBlastGameAssets() {
  const { makePrivateRequest } = useExchangeAPI();

  const fetchUserGameAssets =
    useCallback(async (): Promise<IGameAssetsBlast> => {
      try {
        const { data }: { data: IGameAssetsBlast } = await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.GAME_ASSETS_BLAST}`,
          requestParams: {},
          responseMapping: API_MAPPINGS.GAME_ASSETS_BLAST,
          shouldCheckJwtValidity: false,
        });

        return data;
      } catch (e: any) {
        console.error(
          'An error occured while fetching UserGameAssets : ',
          e.message,
        );
        throw e;
      }
    }, [makePrivateRequest]);

  const fetchGameAssetsLeaderboard = useCallback(async (): Promise<
    IGameAssetsBlastLeaderboardItem[]
  > => {
    try {
      const { data }: { data: IGameAssetsBlastLeaderboardItem[] } =
        await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.GAME_ASSETS_BLAST_LEADERBOARD}`,
          isArray: true,
          requestParams: {},
          responseMapping: API_MAPPINGS.GAME_ASSETS_BLAST_LEADERBOARD,
        });

      return data;
    } catch (e: any) {
      console.error(
        'An error occured while fetching GameAssetsLeaderboard : ',
        e.message,
      );
      throw e;
    }
  }, [makePrivateRequest]);

  return {
    fetchUserGameAssets,
    fetchGameAssetsLeaderboard,
  } as const;
}

export type GameAssetsBlastApi = ReturnType<typeof useBlastGameAssets>;
