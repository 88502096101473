import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { Tooltip } from '@material-ui/core';

import {
  pushDataLayerEvent,
  readUserSetting,
  roundDownToNearestTick,
  roundToNearestTick,
  saveUserSetting,
} from 'utils';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { FormattedNumber } from 'components/FormattedNumber';
import TextToolTip from 'components/InfoTooltip/TextToolTip';
import { Presets } from 'components/Presets/Presets';
import Text from 'components/Text';
import Switch from 'components/Toggle/Switch';

import { Container, ErrorMessage, InputContainer } from './styles';
import errorIcon from 'assets/icons/error.svg';
import swapCurrencyDisabledIcon from 'assets/icons/swap-currency-disabled.svg';
import swapCurrencyIcon from 'assets/icons/swap-currency.svg';
import { LS_IS_PRESET_SLIDER } from 'constants/localStorageKeys';
import { DecimalScale } from 'constants/marketMappings';
import { Row } from 'theme/globalStyledComponents';

import { observer } from 'mobx-react';
import { useDebouncedCallback } from 'use-debounce';

const getCurrencyIcon = ({
  showCurrencyIcon,
  currency,
  customCurrencyIcon,
  icon,
}: {
  showCurrencyIcon: boolean;
  currency: string | undefined;
  customCurrencyIcon: string | undefined;
  icon: string | undefined;
}) => {
  if (!showCurrencyIcon) return null;

  if (customCurrencyIcon) {
    return customCurrencyIcon;
  }

  return currency && icon ? icon : null;
};

function disableWheel(ev: WheelEvent) {
  ev.preventDefault();
}

const MAX_ALLOWED_DECIMALS_ON_INPUT = 6;

// new value is the percentage of the max order value
const getValueOnPreset = (
  percentage: number,
  maxValue: number,
  decimalScale: number,
) => {
  return percentage && maxValue
    ? roundDownToNearestTick(maxValue * (percentage / 100), decimalScale)
    : null;
};

const getPresetFromValue = (
  maxValue: number | null | undefined,
  value: number | null,
) => {
  return maxValue && value
    ? roundToNearestTick((value / maxValue) * 100, 1)
    : null;
};

export type Props = {
  value: any;
  maxValue?: number | undefined | null;
  currency?: string;
  onChange?: (value: number | null) => void;
  onSwapCurrency?: () => void;
  label?: string;
  showPresets?: boolean;
  debounceTimeout?: number;
  placeholder?: string;
  showSwapCurrency?: boolean;
  showError?: boolean;
  errorMessage?: string | null;
  showCurrencyIcon?: boolean;
  customCurrencyIcon?: string;
  showValueApproximation?: boolean;
  showSingleMaxButton?: boolean;
  midValue?: number;
  approximationCurrency?: string;
  approximatedValue?: number | string | null;
  inputValueDecimalScale?: DecimalScale;
  approximatedValueDecimalScale?: DecimalScale;
  gtmId?: string;
  children?: React.ReactNode;
  presetClass?: string;
  className?: string;
  rightSideComponent?: React.ReactNode;
  isDisabledTheSameAsBG?: boolean;
  partiallyDisabled?: boolean;
  hidePresetSwitcher?: boolean;
  currencyComponent?: React.ReactNode;
  defaultPresetType?: 'SLIDER' | 'BUTTONS';
  isErrorAWarning?: boolean;
  tooltip?: string;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  Exclude<
    keyof React.InputHTMLAttributes<HTMLInputElement>,
    'defaultValue' | 'type' | 'value' | 'onChange'
  >
>;

const NumberInput = (props: Props) => {
  const {
    label,
    value,
    maxValue,
    showPresets = true,
    currency,
    onChange,
    onSwapCurrency,
    debounceTimeout,
    disabled = false,
    placeholder = '0.00',
    showSwapCurrency = true,
    showError = false,
    errorMessage,
    showCurrencyIcon = true,
    customCurrencyIcon,
    showValueApproximation = true,
    approximationCurrency = 'USD',
    approximatedValue,
    showSingleMaxButton = false,
    inputValueDecimalScale,
    approximatedValueDecimalScale,
    gtmId,
    children,
    presetClass,
    className,
    autoFocus,
    rightSideComponent,
    isDisabledTheSameAsBG = false,
    partiallyDisabled = false,
    currencyComponent = null,
    hidePresetSwitcher = false,
    defaultPresetType,
    midValue,
    isErrorAWarning = false,
    tooltip,
    maxLength,
    ...rest
  } = props;

  const icon = useGetMarketLogoFromMarketId(currency);

  const currencyLogo = getCurrencyIcon({
    showCurrencyIcon,
    currency,
    customCurrencyIcon,
    icon,
  });

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = React.useState(!!autoFocus);
  const [selectedPreset, setSelectedPreset] = React.useState<number | null>(
    getPresetFromValue(maxValue, value),
  );
  const [isPresetTypeSlider, setIsPresetTypeSlider] = useState(
    defaultPresetType
      ? defaultPresetType === 'SLIDER'
      : readUserSetting(LS_IS_PRESET_SLIDER) === 'true',
  );

  const changeValue = useCallback(
    value => {
      let parsedVal = value !== '' ? Number(value) : null;

      setSelectedPreset(getPresetFromValue(maxValue, parsedVal)); // Update preset on value change

      onChange?.(parsedVal);
    },
    [maxValue, currency],
  );

  const changeValueDebounced = useCallback(
    useDebouncedCallback(value => {
      changeValue(value);
    }, debounceTimeout),
    [changeValue],
  );

  const handleValueChange = useCallback(
    ({ value }: { value: any }) => {
      if (debounceTimeout) {
        changeValueDebounced(value);
      } else {
        changeValue(value);
      }
    },
    [changeValue, maxLength],
  );

  // percentage of the max value selected from the preset options
  const handlePercentagePresetOptionClick = (percentage: number) => {
    if (maxValue === undefined || maxValue === null) return;

    setSelectedPreset(percentage);

    let newValue = getValueOnPreset(
      percentage,
      maxValue,
      inputValueDecimalScale?.minOrder ??
        1 / Math.pow(10, MAX_ALLOWED_DECIMALS_ON_INPUT),
    );

    changeValueDebounced(newValue);

    handleFocusInput();
  };

  useEffect(() => {
    // When max value changes, update the selected preset only when it is at max to avoid errors
    if (selectedPreset === 100) {
      handlePercentagePresetOptionClick(selectedPreset);
      return;
    }

    setSelectedPreset(getPresetFromValue(maxValue, value)); // else, change the preset based on value
  }, [maxValue]);

  const handleFocusInput = () => {
    inputRef.current?.focus();
  };

  const handleSwapCurrency = (event: React.MouseEvent<HTMLDivElement>) => {
    if (gtmId) {
      pushDataLayerEvent(`${gtmId}-swap-currency`);
    }

    if (!onSwapCurrency) return;

    event.stopPropagation();

    onSwapCurrency();
  };

  return (
    <Container
      className={disabled ? `${className} cursor-not-allowed` : className}
    >
      <div>
        <div className="contained-container">
          <InputContainer
            isErrorAWarning={isErrorAWarning}
            showSwapCurrency={showSwapCurrency}
            isFocused={isFocused}
            showError={showError}
            isDisabled={disabled}
            partiallyDisabled={partiallyDisabled}
            className="container"
            onClick={handleFocusInput}
            isDisabledTheSameAsBG={isDisabledTheSameAsBG}
          >
            <div
              className={`column ${
                rightSideComponent && showSingleMaxButton ? 'gap-8' : ''
              }`}
            >
              {label ? (
                tooltip ? (
                  <TextToolTip
                    variant="BODY_XS"
                    flexed
                    gap={5}
                    color="shadesForeground200"
                    className="mb-4"
                    title={tooltip}
                  >
                    {label}
                    {showError && (
                      <img
                        className="error-icon"
                        src={errorIcon}
                        alt="error icon"
                      />
                    )}
                  </TextToolTip>
                ) : (
                  <Text
                    variant="BODY_XS"
                    flexed
                    gap={5}
                    color="shadesForeground200"
                    className="mb-4"
                  >
                    {label}
                    {showError && !isErrorAWarning && (
                      <img
                        className="error-icon"
                        src={errorIcon}
                        alt="error icon"
                      />
                    )}
                  </Text>
                )
              ) : null}
              <div className="value-content">
                <input
                  ref={inputRef}
                  className="value"
                  type="number"
                  value={value}
                  placeholder={placeholder ?? '0.00'}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onChange={event =>
                    handleValueChange({ value: event.target.value })
                  }
                  disabled={disabled}
                  autoFocus={autoFocus}
                  step="any"
                  {...rest}
                />
                <div
                  className="currency-container"
                  onClick={handleSwapCurrency}
                >
                  {midValue ? (
                    <span
                      onClick={() => handleValueChange({ value: midValue })}
                      className={
                        disabled
                          ? 'mid-badge badge opacity-0'
                          : 'badge mid-badge'
                      }
                    >
                      Mid
                    </span>
                  ) : null}
                  {showSwapCurrency && (
                    <img
                      src={
                        disabled ? swapCurrencyDisabledIcon : swapCurrencyIcon
                      }
                      alt="swap currency"
                    />
                  )}
                  {currency && <span className="currency">{currency}</span>}
                  {showCurrencyIcon && currencyLogo && (
                    <img
                      className="currency-icon"
                      src={currencyLogo}
                      alt={`${currency} logo`}
                    />
                  )}
                  {currencyComponent}
                </div>
              </div>
            </div>
            {showSingleMaxButton || rightSideComponent ? (
              <div className="right-container">
                {showSingleMaxButton ? (
                  <span
                    onClick={() => handlePercentagePresetOptionClick(100)}
                    className={disabled ? 'badge opacity-0' : 'badge'}
                  >
                    MAX
                  </span>
                ) : null}

                {rightSideComponent}
              </div>
            ) : null}
          </InputContainer>
          {children ? (
            <div className="children-container">{children}</div>
          ) : null}
        </div>
        {showError && errorMessage && (
          <ErrorMessage isErrorAWarning={isErrorAWarning}>
            {errorMessage}
          </ErrorMessage>
        )}

        {(showValueApproximation || (showPresets && !hidePresetSwitcher)) && (
          <Row justify="space-between" className="mt-5">
            {showValueApproximation ? (
              <Text
                variant="BODY_S"
                color="shadesForeground200"
                className="flex-1"
                flexed
              >
                ≈{' '}
                <FormattedNumber
                  value={approximatedValue ?? 0}
                  decimalScale={approximatedValueDecimalScale?.size ?? 2}
                />{' '}
                {approximationCurrency}
              </Text>
            ) : null}

            {showPresets && !hidePresetSwitcher ? (
              <Text
                flexed
                gap={5}
                color="shadesForeground200"
                variant="BODY_S"
                className={`justify-between ${
                  !showValueApproximation ? 'flex-1' : ''
                }`}
              >
                Slider
                <Switch
                  on={isPresetTypeSlider}
                  onToggle={() =>
                    setIsPresetTypeSlider(prev => {
                      const nextVal = !prev;

                      saveUserSetting(LS_IS_PRESET_SLIDER, nextVal.toString());

                      return nextVal;
                    })
                  }
                  size="S"
                />
              </Text>
            ) : null}
          </Row>
        )}
      </div>

      {showPresets && (
        <Presets
          isPresetTypeSlider={isPresetTypeSlider}
          disabled={disabled || !maxValue}
          onOptionSelect={handlePercentagePresetOptionClick}
          dataGtmId={gtmId}
          selectedPreset={selectedPreset}
          className={presetClass || 'mt-10'}
        />
      )}
    </Container>
  );
};

export default observer(NumberInput);
