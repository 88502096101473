import { memo, useEffect, useState } from 'react';

const getStartTime = (startTime?: number) => {
  if (startTime) {
    return 10 * 60 - (new Date().getTime() - startTime) / 1000;
  }
  return 10 * 60; // seconds (10 mins)
};

const MockTimer = ({ startTime }: { startTime?: number }) => {
  const [timeRemaining, setTimeRemaining] = useState(getStartTime(startTime));

  useEffect(() => {
    const timeinterval = setInterval(() => {
      setTimeRemaining(prev => {
        if (prev <= 10) return 40;
        else return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timeinterval);
  }, []);

  const seconds = (timeRemaining % 60).toFixed(0);
  const minutes = Math.floor(timeRemaining / 60).toFixed(0);

  return (
    <>
      {minutes}m {seconds}s
    </>
  );
};

export default memo(MockTimer);
