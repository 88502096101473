import backgroundImage from 'assets/images/vault-overview-background.png';
import { Column } from 'pages/Vaults/styles';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  background: ${({
    theme,
  }) => `linear-gradient(270deg, ${theme.colors.shadesBackground800} 7.64%, rgba(16, 22, 36, 0) 75.09%),
    url(${backgroundImage}) no-repeat,
    lightgray -1px -176.767px / 115.479% 339.884% no-repeat`};

  background-size: cover;
  background-position: center;

  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(auto, auto);
  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    grid-template-columns: 1fr 2fr;
  } */

  .br-4 {
    border-radius: 4px;
  }

  > div {
    display: flex;
    justify-content: space-between;
  }

  /* .top-part {
    align-items: flex-start;
  }

  .bottom-part {
    align-items: flex-end;
  } */

  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .gap-30 {
    gap: 30px;
  }

  .stats-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;

    @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
      flex-direction: row;
      align-items: flex-start;
      gap: 30px;
    }
  }

  .action-buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    justify-self: flex-end;

    align-items: flex-end;
  }

  .icons-and-status-container {
    justify-content: flex-end;
  }

  .responsive-row {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const StatSeperator = styled.div`
  height: 2px;
  width: 36px;
  background-color: rgba(27, 62, 129, 0.31);

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    height: 36px;
    width: 2px;
  }
`;

export const StatContainer = styled(Column)`
  gap: 2px;
  align-items: flex-end;

  .value {
    display: flex;
    gap: 5px;
  }
`;
