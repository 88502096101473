import { roundToNearestTick } from 'utils';

import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import { VaultWalletShares } from '../../ElixirVaults';
import { VaultType } from 'pages/Vaults/enums';

export const getFilteredVaults = ({
  vaults,
  searchTerm,
  type,
  vaultsWalletShares,
}: {
  vaults: VaultWithContractInfo[] | undefined;
  searchTerm: string;
  type: VaultType;
  vaultsWalletShares?: VaultWalletShares;
}) => {
  if (!vaults) {
    return [];
  }

  const filteredVaults = vaults.filter(vault => {
    if (type === VaultType.MY_VAULTS) {
      return (
        vaultsWalletShares &&
        vaultsWalletShares[vault.productId].sharesAmount > 0
      );
    }

    if (type === VaultType.ALL) {
      return true;
    }

    return false;
  });

  if (!searchTerm) {
    return filteredVaults;
  }

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return filteredVaults.filter(vault => {
    const tokenPair = vault.tokenPair.toLowerCase();

    return tokenPair.toLowerCase().includes(lowerCaseSearchTerm);
  });
};

export const cashYieldPercentage = 10;

export const getTotalVaultApy = (apy: number) => {
  return roundToNearestTick(apy * 100 * 1.33 + cashYieldPercentage, 0.01);
};
