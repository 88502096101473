import { getCurrencyFromTradingPair } from 'utils';

import { DecimalScale } from 'constants/marketMappings';
import { useAppContext } from 'contexts/AppContext';

export const DEFAULT_DECIMAL_PLACES: DecimalScale = {
  price: 2,
  priceScale: 2,
  size: 2,
  minOrder: 0.0001,
  minTick: 0.0001,
};

export const useGetDecimalPlaces = (marketId: string | undefined) => {
  const {
    store: {
      markets: { _decimalPlaces },
    },
  } = useAppContext();

  const decimalPlaces = marketId
    ? _decimalPlaces[getCurrencyFromTradingPair(marketId).toLowerCase()]
    : null;

  return decimalPlaces ? decimalPlaces : DEFAULT_DECIMAL_PLACES;
};
