import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  min-width: 195px;

  border-right: 1.5px solid ${({ theme }) => theme.colors.shadesBackground700};

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    min-width: 220px;
  }

  .header {
    font-size: 14px;
    line-height: 16px;
    border-bottom: 1.5px solid
      ${({ theme }) => theme.colors.shadesBackground700};
    padding: 20px;
  }

  .create-key-container {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .keys-list-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0;
    margin: 0;
    overflow-y: auto;

    .keys-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      max-height: 450px;

      .key {
        padding: 8px 12px;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 14px;
        color: ${({ theme }) => theme.colors.shadesForeground200};

        @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
          padding: 10px 12px;
        }

        .expired-and-name {
          display: flex;
          gap: 10px;
          align-items: center;
          .key-name {
            font-size: 14px;
            line-height: 16px;
            .key-name-input {
              border: none;
              background: transparent;
              color: ${({ theme }) => theme.colors.white};
              font-size: 14px;
              line-height: 16px;
              font-weight: ${({ theme }) => theme.fontWeights.medium};
              ::placeholder {
                color: ${({ theme }) => theme.colors.shadesForeground200};
              }
              width: 100%;
              outline: none;
            }
          }
        }

        .key-edit {
          font-size: 14px;
          line-height: 16px;
          color: ${({ theme }) => theme.colors.shadesForeground200};
          display: flex;
          align-items: center;
          gap: 5px;

          .MuiCircularProgress-root {
            width: 12px !important;
            height: 12px !important;
          }
        }

        :hover,
        &.selected {
          background: ${({ theme }) => theme.colors.shadesBackground700};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`;

export const ExpiredLabel = styled.div`
  display: flex;
  padding: 2px 4px;
  align-items: center;
  background: ${({ theme }) => theme.colors.negativeForeground200};
  font-size: 10px;
  border-radius: 100px;
  color: ${({ theme }) => theme.colors.white};
`;
