import styled from 'styled-components';

type Props = {
  isPadded: boolean;
};
export const Container = styled.footer<Props>`
  min-height: 30px;

  background: ${({ theme }) => theme.colors.shadesBackground900};
  padding: ${({ isPadded }) => (isPadded ? '0px 10px' : 0)};

  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
`;

export const Col = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  gap: 10px;

  &.center {
    width: calc(100% - 320px);
    mask-image: linear-gradient(
      to left,
      transparent,
      black 10%,
      black 90%,
      transparent
    );
  }
`;

export const NewsStyled = styled.div`
  @media (max-width: 600px) {
    display: none;
  }

  display: flex;
  align-items: center;
  height: 30px;
  gap: 10px;

  /* width: 100%; */
  position: relative;
  overflow: hidden;

  .news-list {
    display: inline-flex;
    animation: tickerMovement 75s linear infinite;
  }

  &:hover .news-list {
    animation-play-state: paused;
  }

  @keyframes tickerMovement {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;

export const NewsItem = styled.div`
  /* border-right: 1px solid ${({ theme }) =>
    theme.colors.shadesBackground500}; */

  a {
    gap: 5px;
    display: flex;
    white-space: nowrap;
    margin: 0 10px;

    span.time {
      white-space: nowrap;
    }

    span.headline {
      max-width: 400px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.ml-0 a {
    margin-left: 0;
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 18px;
  margin: 1.5px 0px;
  background: ${({ theme }) => theme.colors.shadesBackground500};
`;
