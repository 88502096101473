import styled, { css } from 'styled-components';

interface ModalStyledProps {
  small: boolean;
  medium: boolean;
  large: boolean;
  custom: boolean;
  auto: boolean;
  backgroundImage?: string;
  width?: number;
  height?: number;
  overflow?: string;
  padding?: string;
  borderRadius?: string;
  noBorder: boolean;
  noShadow: boolean;
}
export const ModalStyled = styled.div<ModalStyledProps>`
  background: ${({ backgroundImage }) =>
      backgroundImage && `url(${backgroundImage})`}
    no-repeat center;
  background-size: contain;
  background-color: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 12px;

  border: ${({ theme, noBorder }) =>
    noBorder ? 'none' : `1px solid ${theme.colors.shadesBackground700}`};

  margin: 0 auto;
  overflow: ${({ overflow }) => overflow || 'hidden'};
  position: relative;
  min-width: calc(100vw - 100px);
  border: ${({ noShadow }) =>
    noShadow ? 'none' : '0 0 10px rgba(0, 0, 0, 0.2)'};

  padding: 10px;

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    padding: 15px;
  }

  ${({ small }) =>
    small &&
    css`
      @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
        min-width: 358px;
        max-width: 358px;
      }
    `}

  ${({ medium }) =>
    medium &&
    css`
      @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
        min-width: 600px;
      }
    `}

  ${({ large }) =>
    large &&
    css`
      @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
        min-width: 800px;
      }
    `}

  ${({ custom }) =>
    custom &&
    css`
      @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
        min-width: 400px;
      }
      @media (min-width: ${({ theme }) => theme.screenSizes.xs}px) {
        padding: 0;
      }
    `}

  ${({ auto }) =>
    auto &&
    css`
      padding: 0 !important;
      min-width: initial;
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}


  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}

    ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `}
`;

interface HeaderStyledProps {
  isSuccess?: boolean;
  isDanger?: boolean;
}
export const HeaderStyled = styled.div<HeaderStyledProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;

  > h5 {
    margin: 5px auto;
    color: ${({ theme }) => theme.colors.shadesForeground300};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CloseIcon = styled.button`
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 1.2em;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -3px;
  z-index: 10;

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    top: 12px;
    right: 10px;
  }

  & path {
    fill: ${({ theme }) => theme.colors.shadesForeground100};
  }
`;

export const ContainedIcon = styled.img`
  display: flex;
  position: absolute;
  padding: 5px;
  z-index: 10;
  background: ${({ theme }) => theme.colors.white};

  border-radius: 100px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  top: 10px;
  right: 10px;
`;
