import { useEffect, useRef } from 'react';

import {
  PlatformVaultsDataWithHoldings,
  useActiveWeb3React,
  useVerifyChainId,
} from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';
import useModal from 'hooks/useModal';

import { VaultsSectionStyled } from '../../styles';
import VaultStats from '../common/Stats';
import AmendPlatformVaultLiquidityModal, {
  AmendType,
} from './AmendPlatformVaultLiquidityModal';
import VaultActivityTable from './VaultActivityTable';
import VaultList from './VaultList';
import { useAmendPlatformVault } from './useAmendPlatformVault';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import {
  useFetchPlatformVaultPnl,
  useFetchPlatformVaultsDataWithHoldings,
} from 'queryHooks/platformVaults';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const getTotalLiquidity = (
  vaults: PlatformVaultsDataWithHoldings[] | undefined,
) => {
  return vaults?.reduce((acc, vault) => {
    return acc + vault.accountEquity;
  }, 0);
};

export const getYourSharesUsd = (
  vaults: PlatformVaultsDataWithHoldings[] | undefined,
) => {
  if (!vaults) return 0;

  return vaults?.reduce((acc, vault) => {
    if (!vault.holdings) return acc;

    return acc + vault.holdings.userShares * vault.sharePrice;
  }, 0);
};

const PlatformVaults = () => {
  const {
    data: vaultsDataWithHoldings,
    isLoading,
    isError,
  } = useFetchPlatformVaultsDataWithHoldings();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const vaultAddress =
    vaultsDataWithHoldings && vaultsDataWithHoldings[0]?.wallet;

  const vaultPnl = useFetchPlatformVaultPnl(vaultAddress);

  const { onboardUser } = useExchangeAPI();
  const modal = useModal();
  const { account } = useActiveWeb3React();
  const isConnectedAndVerified = frontendSecrets?.jwt;
  const [searchParams, setSearchParams] = useSearchParams();
  const shouldConnectAndShowAmendModal =
    searchParams.get('origin') === 'stake_now';
  const isWalletModalOpen = useRef(false);

  const { amendPlatformVault } = useAmendPlatformVault();

  const showWalletModal = () =>
    modal.present(
      <WalletModal onClose={() => (isWalletModalOpen.current = false)} />,
      Modals.walletModal,
    ); // Prompt user to sign and verify

  const openAmendModal = () => {
    const defaultVaultWallet = vaultsDataWithHoldings?.[0]?.wallet;
    if (!defaultVaultWallet) return;
    amendPlatformVault(AmendType.Stake, defaultVaultWallet);
  };

  useEffect(() => {
    if (!shouldConnectAndShowAmendModal) return;

    if (!account) {
      if (isWalletModalOpen.current) return;
      showWalletModal();
      return;
    }

    if (!frontendSecrets?.jwt) {
      showWalletModal();
      onboardUser();
      return;
    }

    if (isLoading) return;

    // once done, reset the search params so, we dont keep poping the amend modal
    openAmendModal();
    setSearchParams({});
  }, [
    account,
    shouldConnectAndShowAmendModal,
    frontendSecrets?.jwt,
    isLoading,
    isWalletModalOpen.current,
  ]);

  const totalLiquidity = getTotalLiquidity(vaultsDataWithHoldings);
  const yourSharesUsd = getYourSharesUsd(vaultsDataWithHoldings);

  return (
    <VaultsSectionStyled>
      <VaultStats
        vaultPnl={vaultPnl}
        liquidity={totalLiquidity}
        yourShares={yourSharesUsd}
        showVaultPnl={true}
      />
      <VaultList
        vaults={vaultsDataWithHoldings}
        isLoading={isLoading}
        isError={isError}
      />

      {isConnectedAndVerified ? <VaultActivityTable /> : null}
    </VaultsSectionStyled>
  );
};

export default observer(PlatformVaults);
