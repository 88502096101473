import { useStorageAPI } from 'hooks/useSettingsAPI';

import { IAirdropRegistration } from 'interfaces/airdropRegistration';

import { QueryKeys } from 'enums';
import { UserSettings } from 'interfaces';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export function useFetchUserSettings(jwt: string | undefined) {
  const { fetchSettingsForUser } = useStorageAPI();

  return useQuery(
    [QueryKeys.Settings],
    async () => await fetchSettingsForUser(),
    { enabled: !!jwt, refetchInterval: 20_000 },
  );
}

export function useUserRegisteredForBlastAirdrop(jwt: string | undefined) {
  const res = useFetchUserSettings(jwt);

  return {
    ...res,
    data: (res.data
      ? res.data?.airdropRegistration || {
          isRegisteredForAirdrop: false,
        }
      : res.data) as IAirdropRegistration | undefined,
  };
}

export function useSaveSettings() {
  const { saveSettings } = useStorageAPI();
  const queryClient = useQueryClient();

  const mutation = useMutation(saveSettings, {
    onMutate: async (newSettings: UserSettings) => {
      // Get a snapshot of current data
      const previousSettings = queryClient.getQueryData(QueryKeys.Settings);

      // Modify cache to reflect this optimistic update
      queryClient.setQueryData(QueryKeys.Settings, () => newSettings);

      // Return a snapshot so we can rollback in case of failure
      return {
        previousSettings,
      };
    },

    onError: (error, marketId, context) => {
      // Rollback the changes using the snapshot
      if (context?.previousSettings) {
        queryClient.setQueryData(QueryKeys.Settings, context?.previousSettings);
      }
    },
  });

  const handleFavoriteMarket = (marketId: string) => {
    const oldSettings: UserSettings | undefined = queryClient.getQueryData(
      QueryKeys.Settings,
    );

    const prevFavorite =
      oldSettings?.favoriteMarkets && oldSettings.favoriteMarkets[marketId];

    const newSettings = {
      ...oldSettings,
      favoriteMarkets: {
        ...(oldSettings?.favoriteMarkets ?? {}),
        [marketId]: !prevFavorite,
      },
    };

    mutation.mutate(newSettings);
  };

  const handleBfxAidropRegistration = async (
    twitterProfileId: string,
    twitterUsername: string,
    twitterName: string,
    twitterProfilePicture: string | undefined,
  ) => {
    const oldSettings: UserSettings | undefined = queryClient.getQueryData(
      QueryKeys.Settings,
    );

    const newSettings: UserSettings = {
      ...oldSettings,
      airdropRegistration: {
        twitterData: {
          profileId: twitterProfileId,
          username: twitterUsername,
          name: twitterName,
          profilePicture: twitterProfilePicture?.replace('_normal', ''), // Enhance the twitter image
        },
        isRegisteredForAirdrop: true,
        isOnboardded: false,
      },
    };

    return await mutation.mutateAsync(newSettings);
  };

  const markUserOnboarded = async () => {
    const oldSettings: UserSettings | undefined = queryClient.getQueryData(
      QueryKeys.Settings,
    );

    const newSettings: UserSettings = {
      ...oldSettings,
      airdropRegistration: {
        ...oldSettings?.airdropRegistration,
        isOnboardded: true,
      } as IAirdropRegistration,
    };

    return await mutation.mutateAsync(newSettings);
  };

  return {
    mutation,
    handleFavoriteMarket,
    handleBfxAidropRegistration,
    markUserOnboarded,
  };
}
