import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 0;
  flex-grow: 1;
  width: 100%;

  /* @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    flex-grow: 1;
  } */

  .container {
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.shadesBackground800};
    border-radius: 8px;
    padding: 15px;
    gap: 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-height: 200px;
    justify-content: center;
    margin-bottom: 10px;

    @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
      flex-direction: row;
    }
  }

  .left-col {
    flex: 1 1 0;
    gap: inherit;
  }

  .right-col {
    flex: 0 1 0;
    gap: inherit;
  }

  .loader {
    display: flex;
    justify-self: center;
    align-self: center;
  }
`;
