export interface OrderbookWSResponse {
  market_id: string;
  bids: any;
  asks: any;
  sequence: number;
  timestamp: number;
}

export interface OrderbookOrder {
  price: number;
  quantity: number;
  total: number;
  hasChanged: boolean;
  depthPercentage: number;
}

export interface OrderbookData {
  bids: OrderbookOrder[];
  asks: OrderbookOrder[];
  sequence: number;
}

export enum CurrencyKind {
  BASE = 'BASE',
  QUOTE = 'QUOTE',
}
