import Text, { TextProps } from 'components/Text';

import { MarketStat } from '../style';

type Props = {
  label: string;
  value: React.ReactNode;
  valueColor?: TextProps['color'];
};
const MarketStatItem = ({ label, value, valueColor = 'white' }: Props) => {
  return (
    <MarketStat>
      <Text color="shadesForeground200" variant="BODY_XS">
        {label}
      </Text>
      <Text
        color={valueColor}
        variant="BODY_S"
        className="flex items-center gap-2"
      >
        {value}
      </Text>
    </MarketStat>
  );
};

export default MarketStatItem;
