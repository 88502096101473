import { mergeOrderbookWithDepthDataByPriceTick } from 'utils';

import { AskBidTableProps } from './OrderBook';
import { BidsAndAsksContainer } from './OrderBookStyles';
import OrderRow from './OrderRow';

import { OrderbookSide } from 'enums';
import { OrderbookOrder } from 'interfaces';

type Props = {
  allAsks: OrderbookOrder[];
} & AskBidTableProps;
const AsksTable = ({
  selectedMarketOrders,
  minTick,
  pricePrecision,
  allAsks,
  selectedMarketId,
  ordersAndTradesCurrencyKind,
  isLoading,
  selectedTick,
  ...rest
}: Props) => {
  const asks = mergeOrderbookWithDepthDataByPriceTick(
    selectedTick === minTick ? allAsks.slice(0, 11) : allAsks,
    OrderbookSide.Ask,
    selectedTick,
    pricePrecision,
    minTick,
  ).reverse();

  return (
    <BidsAndAsksContainer className="asks">
      {!isLoading
        ? asks.map((item, index) => (
            <OrderRow
              order={item}
              isFirstRow={index === asks.length - 1}
              orderSide={OrderbookSide.Ask}
              currency={ordersAndTradesCurrencyKind}
              marketID={selectedMarketId}
              key={item.price}
              ordersForCurrentPriceLevel={selectedMarketOrders.filter(
                order => order.price === item.price,
              )}
              {...rest}
            />
          ))
        : null}
    </BidsAndAsksContainer>
  );
};

export default AsksTable;
