import { TableCell, TableRow } from '@material-ui/core';

import { getCurrencyFromTradingPair } from 'utils';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';
import { roundDown } from 'utils/math';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import { useSwitchMarket } from 'hooks/useSwitchMarket';

import { FormattedNumber } from 'components';

import arrowDown from 'assets/icons/arrow-down-new.svg';
import arrowUp from 'assets/icons/arrow-up-new.svg';
import { PriceChangeType } from 'enums/priceChangeType';
import { ColoredText, Colors } from 'pages/Trade/components/shared';
import { routes } from 'pages/routes';

import { Market } from 'interfaces';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const MarketCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  > span:first-child {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    letter-spacing: 0.5px;
  }

  > span:last-child {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
`;

const TwoRowsCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  img {
    width: 8px;
    height: 8px;
  }

  > span {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  > span:first-child {
    color: ${({ theme }) => theme.colors.shadesForeground500};
  }

  > span:last-child {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

/**
 * Calculates the Annual Percentage Yield (APY) based on a given funding rate and its frequency.
 *
 * @param {number} fundingRate - The funding rate.
 * @param {number} fundingFrequencyPerDay - The number of times the funding rate is applied per day.
 *
 * @returns {number} The calculated APY as a percentage.
 *
 * @example
 * // Calculate APY for a funding rate of 0.003831% applied hourly.
 * const apy = calculateAPY(0.00003831, 24);
 *
 * @remarks
 * This function assumes that the funding rate is compounded at each period it is applied.
 * The APY is returned as a percentage, not a decimal.
 */
const calculateFundingRateApy = (
  fundingRate: number,
  fundingFrequencyPerDay: number,
): number => {
  const fundingPeriodsPerYear = fundingFrequencyPerDay * 365;
  const apy =
    (fundingRate / Math.abs(fundingRate)) *
    (Math.pow(1 + Math.abs(fundingRate), fundingPeriodsPerYear) - 1);
  return apy * 100; // Convert to percentage
};

type LongShortRatioLabelProps = {
  isSuccess: boolean;
  isHighlighted: boolean;
};
const LongShortRatioLabel = styled.div<LongShortRatioLabelProps>`
  display: flex;
  color: ${({ isSuccess, theme }) =>
    isSuccess
      ? theme.colors.positiveForeground200
      : theme.colors.negativeForeground200};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  padding: 4px;
  border-radius: 4px;
  background: ${({ isHighlighted, theme, isSuccess }) =>
    isHighlighted
      ? isSuccess
        ? theme.colors.positiveBackground100
        : theme.colors.negativeBackground100
      : 'transparent'};
`;

interface Props {
  market: Market;
  marketCap: number | undefined;
}
export const MarketsRow = ({
  market: {
    id: marketId,
    lastTradePrice,
    percentChangeType,
    lastTradePrice24hChangeBasis,
    lastTradePrice24hChangePremium,
    lastFundingRateBasis,
    openInterest,
    marketVolume24h,
    marketVolume24hChangeBasis,
    marketTitle,
  },
  marketCap,
}: Props) => {
  const { switchMarketById } = useSwitchMarket();
  const navigate = useNavigate();

  const marketLogo = useGetMarketLogoFromMarketId(marketId);
  const decimalPlaces = useGetDecimalPlaces(marketId);

  const handleMarketClick = () => {
    switchMarketById(marketId);
    navigate(routes.trade.getRoutePath());
  };

  const fundingApyPercentage = calculateFundingRateApy(
    lastFundingRateBasis,
    24,
  );

  const roundedDownOpenInterestQuantity = roundDown(
    openInterest / lastTradePrice,
    decimalPlaces.minOrder,
  );

  return (
    <TableRow>
      <TableCell onClick={handleMarketClick} className="cursor-pointer">
        <span className="flex items-center gap-8">
          <img className="asset-logo" src={marketLogo} alt="Market logo" />
          <MarketCell>
            <span>{marketId}</span>
            <span>{marketTitle}</span>
          </MarketCell>
        </span>
      </TableCell>
      <TableCell>
        <FormattedNumber
          value={lastTradePrice}
          prefix="$"
          decimalScale={decimalPlaces.price}
        />
      </TableCell>
      <TableCell>
        <TwoRowsCell>
          <span>
            <img
              src={
                percentChangeType === PriceChangeType.Positive
                  ? arrowUp
                  : arrowDown
              }
              alt="Current price arrow"
              className="arrow-icon"
            />
            <ColoredText
              color={
                percentChangeType === PriceChangeType.Positive
                  ? Colors.Success
                  : Colors.Danger
              }
            >
              <FormattedNumber
                value={lastTradePrice24hChangeBasis * 100}
                suffix="%"
                allowNegative={false}
              />
            </ColoredText>
          </span>
          <span>
            <FormattedNumber
              value={lastTradePrice24hChangePremium}
              decimalScale={decimalPlaces.price}
              prefix="$"
            />
          </span>
        </TwoRowsCell>
      </TableCell>
      <TableCell>
        <TwoRowsCell>
          <span>
            <FormattedNumber
              value={lastFundingRateBasis * 100}
              suffix="%"
              decimalScale={6}
            />
          </span>
          <span>
            <FormattedNumber
              value={fundingApyPercentage}
              suffix="% APY"
              allowNegative={true}
            />
          </span>
        </TwoRowsCell>
      </TableCell>
      <TableCell>
        <TwoRowsCell>
          <span>
            <FormattedNumber
              value={roundedDownOpenInterestQuantity}
              suffix={` ${getCurrencyFromTradingPair(marketId)}`}
              decimalScale={decimalPlaces.size}
            />
          </span>
          <span>
            <FormattedNumber value={openInterest} prefix="≈ $" />
          </span>
        </TwoRowsCell>
      </TableCell>
      <TableCell align="right">
        <TwoRowsCell className="items-end">
          <span>
            <FormattedNumber value={marketVolume24h} prefix="$" />
          </span>
          <span>
            <img
              src={marketVolume24hChangeBasis > 0 ? arrowUp : arrowDown}
              alt="Market volume arrow"
              className="arrow-icon"
            />
            <ColoredText
              color={
                marketVolume24hChangeBasis > 0 ? Colors.Success : Colors.Danger
              }
            >
              <FormattedNumber
                value={marketVolume24hChangeBasis * 100}
                suffix="%"
                allowNegative={false}
              />
            </ColoredText>
          </span>
        </TwoRowsCell>
      </TableCell>
      <TableCell align="right">
        <FormattedNumber value={marketCap} prefix="$" decimalScale={0} />
      </TableCell>
      {/* <TableCell>
        <div className="flex justify-end items-center">
          <LongShortRatioLabel isSuccess isHighlighted={longRatio * 100 > 50}>
            <FormattedNumber
              value={longRatio * 100}
              suffix="%"
              decimalScale={0}
            />
          </LongShortRatioLabel>
          &nbsp; / &nbsp;
          <LongShortRatioLabel
            isSuccess={false}
            isHighlighted={shortRatio * 100 > 50}
          >
            <FormattedNumber
              value={shortRatio * 100}
              suffix="%"
              decimalScale={0}
            />
          </LongShortRatioLabel>
        </div>
      </TableCell> */}
    </TableRow>
  );
};

export default observer(MarketsRow);
