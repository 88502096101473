import AppStore from './app';

import { BarStyles } from 'components/TVChartContainer';
import { ResolutionString, SeriesStyle } from 'components/TVChartContainer/charting_library';
import { TVConfig } from 'interfaces';
import { action, makeAutoObservable, observable } from 'mobx';


const LS_TVCONFIG_KEY = 'LS_TVCONFIG_KEY'
const DEFAULT_CONFIG:TVConfig = <TVConfig>{
  //@ts-ignore
  resolution: '5',
  timeframe: '12',
  chartType: BarStyles.CANDLES,
  showOrderLines: true,
  mobileBackgroundColor: '#202839',
}


const getInitialConfig = () => {

  //@ts-ignore
  const items = JSON.parse(localStorage.getItem(LS_TVCONFIG_KEY));
  if (!items) 
   return DEFAULT_CONFIG
  
  return items
}

export default class TVConfigStore {
  @observable config: TVConfig = getInitialConfig();

  constructor(private store: AppStore) {
    makeAutoObservable(this);
  }

  @action
  setResolution(resolution: ResolutionString) {
    this.config.resolution = resolution;
    localStorage.setItem(LS_TVCONFIG_KEY, JSON.stringify(this.config));
  }

  @action
  setTimeframe(timeframe: string) {
    this.config.timeframe = timeframe;
    localStorage.setItem(LS_TVCONFIG_KEY, JSON.stringify(this.config));
  }

  @action
  setChartType(chartType: SeriesStyle) {
    this.config.chartType = chartType;
    localStorage.setItem(LS_TVCONFIG_KEY, JSON.stringify(this.config));
  }

  @action
  setShowOrderLines(showOrderLines: boolean) {
    this.config.showOrderLines = showOrderLines;
    localStorage.setItem(LS_TVCONFIG_KEY, JSON.stringify(this.config));
  }

  @action
  setMobileBackgroundColor(color: string) {
    this.config.mobileBackgroundColor = color;
    localStorage.setItem(LS_TVCONFIG_KEY, JSON.stringify(this.config));
  }
}
