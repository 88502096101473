import { memo } from 'react';

import Sidebar from './Sidebar';
import Watchlist from './components/Watchlist';

import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

type LayoutProps = {
  showWatchlistOnMediumDevices: boolean;
  showWatchlistOnlyOnUltraWideDevices: boolean;
};
const Layout = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: auto;
    grid-template-areas: 'sidebar main watchlist';
    grid-template-columns: 48px 1fr 0;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.medium}px) {
    grid-template-areas: 'sidebar main watchlist';
    grid-template-columns: ${({ showWatchlistOnMediumDevices }: LayoutProps) =>
      showWatchlistOnMediumDevices ? '48px 1fr 310px' : '48px 1fr 0'};
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    grid-gap: 10px;
    grid-template-areas: 'sidebar main watchlist';
    grid-template-columns: ${({
      showWatchlistOnlyOnUltraWideDevices,
    }: LayoutProps) =>
      !showWatchlistOnlyOnUltraWideDevices ? '138px 1fr 310px' : '138px 1fr 0'};
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.ultrawide}px) {
    grid-gap: 10px;
    grid-template-areas: 'sidebar main watchlist';
    grid-template-columns: 138px 1fr 310px;
  }
`;

const MainContent = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1;

  margin: 10px;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    margin: 0;
  }
`;

const mediumDeviceWatchlistAllowedPaths = [
  '/portfolio',
  '/portfolio/overview',
  '/portfolio/migrate',
];

const ultrawideOnlyWatchlistAllowedPaths = ['/portfolio/airdrops/blastAirdrop'];

const isAllowedPath = (pathname: string, allowedPaths: string[]) => {
  return allowedPaths.includes(pathname);
};

const Portfolio = ({ children }) => {
  const location = useLocation();

  const showWatchlistOnMediumDevices = isAllowedPath(
    location.pathname,
    mediumDeviceWatchlistAllowedPaths,
  );

  const showWatchlistOnlyOnUltraWideDevices = isAllowedPath(
    location.pathname,
    ultrawideOnlyWatchlistAllowedPaths,
  );

  return (
    <Layout
      showWatchlistOnMediumDevices={showWatchlistOnMediumDevices}
      showWatchlistOnlyOnUltraWideDevices={showWatchlistOnlyOnUltraWideDevices}
    >
      <Sidebar />
      <MainContent>{children}</MainContent>
      <Watchlist
        showWatchlistOnMediumDevices={showWatchlistOnMediumDevices}
        showWatchlistOnlyOnUltraWideDevices={
          showWatchlistOnlyOnUltraWideDevices
        }
      />
    </Layout>
  );
};

export default memo(Portfolio);
