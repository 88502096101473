import { isBrandBfx } from 'utils/brand';

import {
  useGetPlatformVaultsShares,
  useGetElixirVaultsShares,
} from 'hooks/useGetStakeAmount';
import { useReferralsAPI } from 'hooks/useReferralsAPI';

import { FormattedNumber } from 'components';

import { BFX_BADGE_HOVERABLE, POINTS_BADGE_HOVERABLE } from '../../badges';
import LockOption from './LockOptionBoxLayout';
import engageAndEarnIcon from 'assets/icons/rbx-airdrop/engage-earn.svg';
import enagageAndEarnBg from 'assets/images/engageEarnBg.webp';
import { useAppContext } from 'contexts/AppContext';

import { QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';

const EngageAndEarnOptionBox = ({
  yourDeposits,
}: {
  yourDeposits?: number;
}) => {
  const {
    store: {
      account: { frontendSecrets, accountStats },
    },
  } = useAppContext();
  const { fetchUserReferral } = useReferralsAPI();

  const { data: userReferral } = useQuery(
    [QueryKeys.UserReferral],
    async () => await fetchUserReferral(),
    {
      // 60 seconds
      refetchInterval: 60_000,
      enabled: !!frontendSecrets?.jwt,
    },
  );

  const platformVaultSharesAmount = useGetPlatformVaultsShares();
  const elixirVaultsSharesAmount = useGetElixirVaultsShares();

  const yourDepositsAndStakesAmount =
    (yourDeposits ?? 0) +
    (platformVaultSharesAmount ?? 0) +
    (elixirVaultsSharesAmount ?? 0);

  return (
    <LockOption
      stats={[
        { label: 'Pool Allocation', value: '[REDACTED]' },
        {
          label: 'Your Trading Volume',
          value: (
            <FormattedNumber
              value={accountStats?.cumTradingVolume}
              prefix="$"
            />
          ),
        },
        {
          label: 'Your Balance & Stake',
          value: (
            <FormattedNumber value={yourDepositsAndStakesAmount} prefix="$" />
          ),
        },
        {
          label: 'Your Referrals',
          value: userReferral?.invitedCounter ?? '-',
        },
      ]}
      ctaProps={{
        title: 'Coming Soon',
        // onClick: () => setSearchParams({ option: Option.ENGAGE_AND_EARN }),
        disabled: true,
      }}
      title={'Engage & Earn'}
      description={'Deposit, trade, stake on BFX to earn BFX Points'}
      headerBanner={enagageAndEarnBg}
      isActive={true}
      badges={isBrandBfx ? [BFX_BADGE_HOVERABLE, POINTS_BADGE_HOVERABLE] : []}
      headerIcon={engageAndEarnIcon}
    />
  );
};

export default observer(EngageAndEarnOptionBox);
