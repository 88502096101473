import { brand } from 'utils/brand';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { useVerifyChainId } from 'hooks';
import { VaultWithContractInfo } from 'hooks/useElixirVaults';
import useModal from 'hooks/useModal';
import { useSwitchMarket } from 'hooks/useSwitchMarket';

import { FormattedNumber } from 'components';
import Button from 'components/Button/button';
import IconPair from 'components/IconPair';
import InfoTooltip from 'components/InfoTooltip';
import Text from 'components/Text';

import { Container, StatContainer, StatSeperator } from './styles';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import { vaultManagerDetailsMap } from 'pages/Vaults/Pages/Vaults/TabPanels/common/VaultList/utils';
import AmendLiquidityModal, {
  AmendType,
} from 'pages/Vaults/common/AmendLiquidityModal';
import { Column } from 'pages/Vaults/styles';
import { getNAV, getTotalApyForVault } from 'pages/Vaults/utils';
import { routes } from 'pages/routes';
import { Row } from 'theme/globalStyledComponents/row';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

type Props = {
  vault: VaultWithContractInfo;
};
const VaultName = ({ vault }: Props) => {
  const { t } = useTranslation();
  const modal = useModal();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { validateNetworkAndDoAction } = useVerifyChainId();
  const theme: any = useTheme();

  const showLiquidityModal = (amendType: AmendType) => {
    validateNetworkAndDoAction(() => {
      modal.present(
        <AmendLiquidityModal
          defaultAmendType={amendType}
          poolId={vault.productId}
        />,
        Modals.amendLiquidityModal,
      );
    }, t('youCanNowStakeUnstakeFunds'));
  };

  const navigate = useNavigate();
  const marketLogo = useGetMarketLogoFromMarketId(vault.tokenPair);

  const onConnectWallet = () =>
    modal.present(<WalletModal />, Modals.walletModal);

  const { switchMarketById } = useSwitchMarket();

  return (
    <Container>
      <div className="top-part">
        <Text variant="BODY_L">
          {t('operatedBy')} {t(vaultManagerDetailsMap['ElIXIR'].labelTx)}
        </Text>

        <div className="stats-container">
          <Stat
            label={t('yourShares')}
            value={vault.walletShares}
            suffix={' ' + t('shares')}
          />
          <StatSeperator />
          <Stat
            label={t('yourNAV')}
            value={frontendSecrets?.jwt ? getNAV(vault) : undefined}
            suffix={` ${brand.tokenTicker}`}
          />
          <StatSeperator />
          <Stat
            label={t('totalAPY')}
            value={getTotalApyForVault(vault)}
            suffix="%"
          />
        </div>
      </div>

      <div className="bottom-part">
        <Column className="gap-10 icons-and-status-container">
          <Row className="gap-10">
            <Text
              variant="BODY_S"
              className={`active-badge ${
                vault.isActive ? 'success' : 'warning'
              }`}
            >
              {t(vault.isActive ? 'active' : 'inactive')}
            </Text>
            {/* <Icon size="L" src={brand.chainLogo} alt="market logo" /> */}
          </Row>

          <Text
            variant="BODY_L"
            fontWeight="semiBold"
            className="gap-5 responsive-row"
            onClick={() => {
              switchMarketById(vault.tokenPair);
              navigate(routes.trade.getRedirectPath());
            }}
          >
            <IconPair
              icons={[marketLogo, brand.tokenLogo]}
              size={24}
              bgColor={theme.colors.shadesBackground700}
            />
            {vault.tokenPair}
          </Text>
        </Column>

        <div className="action-buttons">
          {frontendSecrets?.jwt ? (
            <>
              <Button
                sizeVariant="S"
                colorVariant="primaryGreen"
                onClick={() => showLiquidityModal(AmendType.Stake)}
                // rightIcon={downloadIcon}
              >
                {t('stake')}
              </Button>

              <Button
                sizeVariant="S"
                colorVariant="secondary"
                block
                onClick={() => showLiquidityModal(AmendType.UnStake)}
                // rightIcon={uploadIcon}
              >
                {t('unstake')}
              </Button>
            </>
          ) : (
            <Button
              colorVariant="primaryGreen"
              block
              sizeVariant="S"
              onClick={event => {
                event.stopPropagation();
                onConnectWallet();
              }}
            >
              {t('connectWallet')}
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};

type StatProps = {
  label: string;
  value: number | undefined;
  toolTipText?: string;
  suffix: string;
};
const Stat = ({ label, value, toolTipText, suffix }: StatProps) => {
  return (
    <StatContainer>
      <Text
        variant="BODY_M"
        className="align-right"
        color="shadesForeground200"
      >
        {label}
        {toolTipText ? <InfoTooltip title={toolTipText} /> : null}
      </Text>
      <FormattedNumber
        variant="NUMBERS_L"
        fontWeight="semiBold"
        value={value}
        suffix={suffix}
        className="align-right"
      />
    </StatContainer>
  );
};

export default observer(VaultName);
