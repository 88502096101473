import { brand } from 'utils/brand';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { FormattedNumber } from 'components';
import Badge from 'components/Badge';
import Button from 'components/Button/button';
import IconPair from 'components/IconPair';
import Text from 'components/Text';

import {
  getCurrentPps,
  getTotalApyForVault,
  navigateToDetailedVault,
} from '../../../../../../../utils';
import { vaultManagerDetailsMap } from '../../utils';
import { VaultItemProps } from '../types';
import { Column, Row, StyledVaultCard } from './styles';
import infoCircleIcon from 'assets/icons/info-circle-white.svg';
import { AmendType } from 'pages/Vaults/common/AmendLiquidityModal';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const VaultCard = ({
  vault,
  walletShare,
  isUserAuthenticated,
  showLiquidityModal,
  onConnectWallet,
  manager,
}: VaultItemProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const marketLogo = useGetMarketLogoFromMarketId(vault?.tokenPair);

  if (!vault) return null;

  const { titleTx: managerTitleTx } = vaultManagerDetailsMap[manager];

  return (
    <StyledVaultCard>
      <div
        className="top-part"
        onClick={() => navigateToDetailedVault(navigate, vault.productId)}
      >
        <Row className="space-between">
          <Row className="gap-5">
            {/* <img
              className="icon"
              src={isWatched ? starFilledIcon : starOutlineIcon}
              alt="star"
            /> */}

            <Column className="gap-2">
              <Text variant="BODY_M" fontWeight="semiBold">
                {vault.tokenPair}
              </Text>
              <Text variant="BODY_S" color="shadesForeground200">
                {t(managerTitleTx)}
              </Text>
            </Column>
          </Row>

          <IconPair icons={[marketLogo, brand.tokenLogo]} size={32} />
        </Row>

        <Row className="gap-3 align-end">
          <FormattedNumber
            variant="NUMBERS_XL"
            fontWeight="semiBold"
            suffix="%"
            value={getTotalApyForVault(vault)}
          />
          <Badge
            variant="BODY_XS"
            bgColor="positiveBackground100"
            padding="4px"
            color="positiveForeground200"
          >
            {t('apy')}
          </Badge>
          {/* <Text variant="BODY_M" color="shadesForeground200">
            {t('apy')}
          </Text> */}
        </Row>

        <Column className="gap-2">
          <Row className="space-between">
            <Text variant="BODY_S" color="shadesForeground200">
              {t('shares')}
            </Text>
            <FormattedNumber value={vault.activeAmount} variant="BODY_S" />
          </Row>

          <Row className="space-between">
            <Text variant="BODY_S" color="shadesForeground200">
              {t('pricePerShare')}
            </Text>
            <FormattedNumber
              variant="BODY_S"
              value={getCurrentPps(vault)}
              suffix={` ${brand.tokenTicker}`}
            />
          </Row>

          <Row className="space-between">
            <Text variant="BODY_S" color="shadesForeground200">
              {t('tvl')}
            </Text>
            <FormattedNumber
              variant="BODY_S"
              value={vault.tvlUsd}
              suffix={` ${brand.tokenTicker}`}
            />
          </Row>
        </Column>

        {isUserAuthenticated ? (
          <Row className="gap-10">
            <img src={infoCircleIcon} alt="info" />

            <Button
              colorVariant="primaryGreen"
              block
              sizeVariant="S"
              onClick={e => {
                e.stopPropagation();
                showLiquidityModal(AmendType.Stake, vault.productId);
              }}
              data-gtmid="button-stake-vault-init"
            >
              {t('stake')}
            </Button>

            {walletShare ? (
              <Button
                colorVariant="secondary"
                block
                sizeVariant="S"
                onClick={e => {
                  e.stopPropagation();
                  showLiquidityModal(AmendType.UnStake, vault.productId);
                }}
                data-gtmid="button-unstake-vault-init"
              >
                {t('unstake')}
              </Button>
            ) : null}
          </Row>
        ) : null}
      </div>
      {isUserAuthenticated ? (
        <Row className="bottom-part space-between">
          <Text variant="BODY_S" color="shadesForeground200">
            {t(walletShare ? 'myPosition' : 'noPosition')}
          </Text>
          {walletShare ? (
            <FormattedNumber
              variant="BODY_S"
              value={walletShare}
              suffix={' ' + t('shares')}
            />
          ) : null}
        </Row>
      ) : null}
    </StyledVaultCard>
  );
};

export default observer(VaultCard);
