import { Tooltip } from '@material-ui/core';

import { roundDownToNearestTick, roundToNearestTick } from 'utils';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';

import { FormattedNumber, FormattedPercentage, Icon } from 'components';
import Text from 'components/Text';

import arrowDown from 'assets/icons/market-down.svg';
import arrowUp from 'assets/icons/market-up.svg';
import { useAppContext } from 'contexts/AppContext';
import { PriceChangeType } from 'enums/priceChangeType';
import { access } from 'fs/promises';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const CurrentPriceRowStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.shadesBackground700};
  border-radius: 4px;
  padding: 8px 10px;
  justify-content: space-between;
  margin: 5px 0px;
`;

const CurrentPriceRow = ({
  onClick,
  spreadPercentage,
}: {
  onClick: () => void;
  spreadPercentage: number | undefined;
}) => {
  const {
    store: {
      markets: marketsStore,
      orderbook: { isLoading },
    },
  } = useAppContext();
  const { t } = useTranslation();

  const selectedMarket = marketsStore.selectedMarket;
  const marketID = selectedMarket?.id;

  const decimalScales = useGetDecimalPlaces(marketID);

  const priceChangeArrow =
    marketsStore.lastTradePriceChangeType === PriceChangeType.Positive
      ? arrowUp
      : arrowDown;

  const lastTradePrice = selectedMarket?.lastTradePrice;

  return (
    <CurrentPriceRowStyled onClick={onClick}>
      <Text
        variant="NUMBERS_L"
        fontWeight="semiBold"
        color={
          marketsStore.lastTradePriceChangeType === PriceChangeType.Positive
            ? 'primaryGreenForeground100'
            : 'negativeForeground200'
        }
        flexed
        className="flex-1"
        gap={5}
      >
        <Icon src={priceChangeArrow} alt="Current price arrow" size="M" />

        <FormattedNumber
          tooltipText={t('lastTradedPriceTooltip')}
          value={lastTradePrice}
          decimalScale={decimalScales.price}
        />
      </Text>

      {spreadPercentage !== undefined ? (
        <FormattedPercentage
          variant="BODY_M"
          color="shadesForeground200"
          value={
            spreadPercentage
              ? roundDownToNearestTick(spreadPercentage, 0.01)
              : 0
          }
          tooltipText={t('spreadPercentageTooltip')}
        />
      ) : null}
    </CurrentPriceRowStyled>
  );
};

export default observer(CurrentPriceRow);
