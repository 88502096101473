export type ITextVariants =
  | 'HEADING_H1'
  | 'HEADING_H2'
  | 'BODY_M'
  | 'BODY_SM'
  | 'BODY_S'
  | 'BODY_XS'
  | 'BODY_XXS'
  | 'BODY_L'
  | 'NUMBERS_L'
  | 'NUMBERS_XL'
  | 'NUMBERS_XXL'
  | 'BUTTON_L'
  | 'BUTTON_M'
  | 'BUTTON_S';

export const TextVariant: Record<
  ITextVariants,
  {
    fontSize: string;
    lineHeight: string;
    letterSpacing?: string;
  }
> = {
  HEADING_H1: {
    fontSize: '24px',
    letterSpacing: '-0.48px',
    lineHeight: 'normal',
  },

  HEADING_H2: {
    fontSize: '20px',
    lineHeight: 'normal',
    letterSpacing: '-0.4px',
  },

  BODY_M: {
    fontSize: '13px',
    lineHeight: 'normal',
  },

  BODY_SM: {
    fontSize: '12px',
    lineHeight: 'normal',
  },

  BODY_S: {
    fontSize: '11px',
    lineHeight: 'normal',
  },

  BODY_XS: {
    fontSize: '10px',
    lineHeight: 'normal',
  },

  BODY_XXS: {
    fontSize: '8px',
    lineHeight: 'normal',
  },

  BODY_L: {
    fontSize: '16px',
    lineHeight: 'normal',
  },

  NUMBERS_L: {
    fontSize: '16px',
    lineHeight: 'normal',
    letterSpacing: '-0.5px',
  },

  NUMBERS_XL: {
    fontSize: '20px',
    lineHeight: 'normal',
    letterSpacing: '-1px',
  },

  NUMBERS_XXL: {
    fontSize: '24px',
    lineHeight: 'normal',
    letterSpacing: '-1px',
  },

  BUTTON_L: {
    fontSize: '16px',
    lineHeight: '24px',
  },

  BUTTON_M: {
    fontSize: '14px',
    lineHeight: 'normal',
    letterSpacing: '-0.3px',
  },

  BUTTON_S: {
    fontSize: '12px',
    lineHeight: 'normal',
  },
};
