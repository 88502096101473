import { getCurrencyFromTradingPair } from 'utils';
import { deserializeSingle } from 'utils/apiMiddleware';

import { RequestResponse, RestService } from './restService';
import { API_MAPPINGS } from 'constants/apiMappings';
import { PriceChangeType } from 'enums/priceChangeType';

import { Endpoints, RequestMethod } from 'enums';
import { FundingRate, Market } from 'interfaces';

export const mapMarket = (market: Market): Market => {
  return {
    ...market,
    percentChangeType:
      market.lastTradePrice24hChangeBasis >= 0
        ? PriceChangeType.Positive
        : PriceChangeType.Negative,
    marketTitle: market.marketTitle
      ? market.marketTitle
      : getCurrencyFromTradingPair(market.id),
  };
};

export class MarketService {
  private restService: RestService;
  private static instance: MarketService;

  constructor() {
    this.restService = RestService.get();
  }

  static get = (): MarketService => {
    if (!MarketService.instance) {
      MarketService.instance = new MarketService();
    }
    return MarketService.instance;
  };

  static deserializeSingle = (market: Market) => {
    const deserializedMarket = deserializeSingle<Market>(
      market,
      API_MAPPINGS.MARKET,
    );
    return mapMarket(deserializedMarket);
  };

  async fetchMarkets(
    queryParams: Record<string, string> = {},
  ): Promise<Market[] | null> {
    try {
      const { data: markets }: { data: Market[] } =
        await this.restService.request({
          method: RequestMethod.GET,
          path: '/markets',
          isArray: true,
          responseMapping: API_MAPPINGS.MARKET,
          queryParams: {
            ...queryParams,
          },
        });

      if (!markets) {
        throw new Error(`Could not fetch markets`);
      }

      return markets.map((el: Market) => mapMarket(el));
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async fetchMarketWithId(marketID: string): Promise<Market | null> {
    try {
      const { data: market }: { data: Market } = await this.restService.request(
        {
          method: RequestMethod.GET,
          path: `/markets?market_id=${marketID}`,
          responseMapping: API_MAPPINGS.MARKET,
        },
      );

      if (!market) {
        throw new Error(`Could not fetch market with ID: ${marketID}`);
      }

      return mapMarket(market);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async fetchFundingRatesForMarket(queryParams: {
    market_id: string;
    p_page?: number;
    p_limit?: number;
    start_time?: number;
    p_order?: string;
  }): Promise<RequestResponse<FundingRate[]>> {
    try {
      const res = await this.restService.request<FundingRate[]>({
        method: RequestMethod.GET,
        path: `/${Endpoints.FUNDING_RATE}`,
        responseMapping: API_MAPPINGS.FUNDING_RATE_MAPPING,
        queryParams,
        isArray: true,
      });

      return res;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
