import { useState } from 'react';

import { useActiveWeb3React } from 'hooks';
import { useBlastPointsAPI } from 'hooks/useBlastPointsAPI';

import { FormattedNumber, Icon } from 'components';

import { API_MAPPINGS } from '../../../../../../constants';
import { Container } from '../styles';
import StatsAndTimeDropdown from './StatsAndTimeDropdown';
import blastOutlineWhite from 'assets/assetLogos/blast-white-outline.svg';
import blastGold from 'assets/icons/blast-gold.svg';
import { routes } from 'pages/routes';
import { RestService } from 'service/restService';
import { Row } from 'theme/globalStyledComponents/row';

import { Endpoints, QueryKeys, RequestMethod } from 'enums';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

type TBlastPointsResponse = {
  gold: {
    finalizedPoints: number;
    nextBatchFinalizingAt: string;
    pendingPoints: number;
  };
  blast: {
    finalizedPoints: number;
    nextBatchFinalizingAt: string;
    pendingPoints: number;
  };
};

type TBlastPoints = {
  gold: {
    finalizedPoints: number;
    estPerHour: string;
    pendingPoints: number;
  };
  blast: {
    finalizedPoints: number;
    estPerHour: string;
    pendingPoints: number;
  };
};

const restService = RestService.get();

const EMPTY_BLAST_POINTS = {
  gold: {
    finalizedPoints: 0,
    estPerHour: 0,
    pendingPoints: 0,
  },
  blast: {
    finalizedPoints: 0,
    estPerHour: 0,
    pendingPoints: 0,
  },
};

type Props = { isActive: boolean; closeMobileSidebar?: () => void };
const BlastPoints = ({ isActive, closeMobileSidebar }: Props) => {
  const [isBlastHovered, setIsBlastHovered] = useState(false);
  const [isGoldHovered, setIsGoldHovered] = useState(false);
  const navigate = useNavigate();

  const { blastPoints } = useBlastPointsAPI();

  const navigateToDashboard = () => {
    setIsGoldHovered(false);
    setIsBlastHovered(false);
    navigate(routes.portfolio.airdrops.dashboard.getRedirectPath());
    closeMobileSidebar?.();
  };

  return (
    <div className="relative">
      <Container
        isHovered={isActive || isGoldHovered || isBlastHovered}
        onClick={navigateToDashboard}
      >
        <div className="animated-border" />

        <Row>
          <Row
            className="item"
            onMouseEnter={() => setIsBlastHovered(true)}
            onMouseLeave={() => setIsBlastHovered(false)}
          >
            <Icon src={blastOutlineWhite} size="ML_L" />
            <FormattedNumber
              variant="NUMBERS_XL"
              fontWeight="semiBold"
              value={
                (blastPoints?.blast.finalizedPoints || 0) +
                (blastPoints?.blast.pendingPoints || 0)
              }
              abbreviated
              disableTooltip
            />
          </Row>

          <Row
            className="item"
            onMouseEnter={() => setIsGoldHovered(true)}
            onMouseLeave={() => setIsGoldHovered(false)}
          >
            <Icon src={blastGold} size="ML" />
            <FormattedNumber
              variant="NUMBERS_XL"
              fontWeight="semiBold"
              value={
                (blastPoints?.gold.finalizedPoints || 0) +
                (blastPoints?.gold.pendingPoints || 0)
              }
              abbreviated
              disableTooltip
            />
          </Row>
        </Row>
      </Container>

      {isBlastHovered ? (
        <StatsAndTimeDropdown
          onViewDashboard={navigateToDashboard}
          onMouseEnter={() => setIsBlastHovered(true)}
          onMouseLeave={() => setIsBlastHovered(false)}
          type="blast"
          {...blastPoints.blast}
        />
      ) : null}
      {isGoldHovered ? (
        <StatsAndTimeDropdown
          onViewDashboard={navigateToDashboard}
          onMouseEnter={() => setIsGoldHovered(true)}
          onMouseLeave={() => setIsGoldHovered(false)}
          type="gold"
          {...blastPoints.gold}
        />
      ) : null}
    </div>
  );
};

export default observer(BlastPoints);
