import { memo, useEffect, useRef, useState } from 'react';

import { isNumberInRange } from 'utils/math';

import useDragToScroll from 'hooks/useDragToScroll';

import Text from 'components/Text';

import ClaimedStack from './CardStack/Claimed';
import InProgressStack from './CardStack/InProgress';
import LockedStack from './CardStack/Locked';
import { CARD_LEVELS } from './cardLevels';
import { BlurredEdge, CardsContainer, Container } from './styles';
import leftIcon from 'assets/icons/arrow-left-dark.svg';
import rightIcon from 'assets/icons/arrow-right-dark.svg';

import { useTranslation } from 'react-i18next';

type Props = {
  nextLevel: number | undefined;
};

const getCardStack = (cardLevel: number, nextLevel: number | undefined) => {
  if (!nextLevel) return ClaimedStack;

  return cardLevel < nextLevel
    ? ClaimedStack
    : cardLevel === nextLevel
    ? InProgressStack
    : LockedStack;
};

const BLURRED_VIEW_ADJUSTMENT_WIDTH = 36; // Blurred View's width on container's end

const RewardsHub = ({ nextLevel }: Props) => {
  const [extendedCardLevel, setExtendedCardLevel] = useState<
    number | undefined
  >(undefined);
  const { t } = useTranslation();

  const scrollRef = useRef<HTMLDivElement>(null);
  const leftEdge = useRef<HTMLDivElement>(null);
  const rightEdge = useRef<HTMLDivElement>(null);
  const { scrollToIndex, scrollBy } = useDragToScroll(
    scrollRef,
    BLURRED_VIEW_ADJUSTMENT_WIDTH,
  );

  // Attach the event listener for handling edgeVisibility, onScroll
  const handleEdgeVisibility = () => {
    const container = scrollRef.current;
    if (!container || !leftEdge.current || !rightEdge.current) return;
    if (isNumberInRange(container.scrollLeft, [-1, 1])) {
      leftEdge.current.style.visibility = 'hidden';
    } else {
      leftEdge.current.style.visibility = 'visible';
    }

    const offsetFromRight =
      container.scrollWidth - (container.scrollLeft + container.offsetWidth);

    if (isNumberInRange(offsetFromRight, [-1, 1])) {
      rightEdge.current.style.visibility = 'hidden';
    } else {
      rightEdge.current.style.visibility = 'visible';
    }
  };

  // Toggle and save any claimed card that is extended in a state, only one card stack is suppposed to extended at a time. Auto close others if extended
  const toggleExtendedCardLevel = (lvl: number) => {
    if (lvl === extendedCardLevel) {
      setExtendedCardLevel(undefined);
      return;
    } else {
      setExtendedCardLevel(lvl);
    }
    const container = scrollRef.current;
    if (!container) return;

    // scroll into view post animation end
    setTimeout(() => scrollToIndex(lvl - 1), 300);
  };

  useEffect(() => {
    if (scrollRef.current && nextLevel) {
      const cardIndex = nextLevel ? nextLevel - 1 : CARD_LEVELS.length - 1;
      scrollToIndex(cardIndex);
    }
  }, [nextLevel, scrollToIndex]);

  return (
    <Container>
      <Text variant="BODY_M" fontWeight="semiBold">
        {t('rewardsHub')}
      </Text>

      <CardsContainer>
        <div ref={scrollRef} onScroll={handleEdgeVisibility} className="cards">
          {CARD_LEVELS.map(props => {
            const Stack = getCardStack(props.level, nextLevel);
            return (
              <Stack
                {...props}
                isExtended={extendedCardLevel === props.level}
                toggle={toggleExtendedCardLevel}
              />
            );
          })}
        </div>

        <BlurredEdge ref={leftEdge}>
          <img src={leftIcon} alt="left" onClick={() => scrollBy(-1)} />
        </BlurredEdge>
        <BlurredEdge right ref={rightEdge}>
          <img src={rightIcon} alt="right" onClick={() => scrollBy(1)} />
        </BlurredEdge>
      </CardsContainer>
    </Container>
  );
};

export default memo(RewardsHub);
