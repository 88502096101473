import { MAX_FLOAT_DECIMALS, prettyFormatNumber } from './math';
import { readUserSetting } from './userSettings';
import {
  DEFAULT_ACCOUNT_MARKET_LEVERAGE,
  DEFAULT_DECIMAL_SCALE,
} from 'constants/general';
import {
  LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN,
  LS_SHOULD_DISABLE_CANCEL_ORDER_CONFIRMATION_MODAL,
  LS_WALLET,
} from 'constants/localStorageKeys';
import { DecimalScale } from 'constants/marketMappings';
import { base64, toUtf8String, arrayify, toUtf8Bytes } from 'ethers/lib/utils';

import { config } from 'config';
import { AccountStats, Market, MarketLeverage } from 'interfaces';
import bigDecimal from 'js-big-decimal';

export const timeout = (delay: number) => {
  return new Promise(res => setTimeout(res, delay));
};

/**
 * @param pair Trading pair name text, e.g. BTC-USD or BTC/USDT
 * @returns currency from the trading pair text separated either by '-' or '/'
 */
export const getCurrencyFromTradingPair = (pair: string | undefined) => {
  if (!pair) {
    return '-';
  }

  if (pair.includes('-')) {
    return pair.split('-')[0];
  } else if (pair.includes('/')) {
    return pair.split('/')[0];
  } else if (pair.includes('_')) {
    return pair.split('_')[0];
  }

  return pair;
};

/**
 * @param pair Trading pair name text, e.g. BTC-USD or BTC/USDT
 * @returns trading pair without digits like 1000,100, 100000. SATS10000-USD => SATS-USD
 */
export const removeTrailingDigitsFromTradingPair = (
  pair: string | undefined,
) => {
  // Remove numbers that are powers of 10
  return pair?.replace(/\d{2,}/g, '');
};

/**
 * The NumberFormat component expects an empty string when you want to explicitly reset it (set the value to null),
 * otherwise it uses the old value (super weird behaviour).
 * More context: https://github.com/s-yadav/react-number-format/issues/500
 * @param value value passed into CurrencyInput component
 */
export const getCurrencyInputValue = (
  value: string | number | null | undefined,
): string | number | undefined => {
  return value === null ? '' : value;
};

export const getSelectedMarketLeverage = (
  marketLeverage: MarketLeverage | undefined,
  selectedMarketId: string | null | undefined,
): number | null => {
  if (!selectedMarketId) {
    return null;
  }

  const selectedMarketLeverage =
    marketLeverage && marketLeverage[selectedMarketId];

  // If market-specific leverage exists
  if (selectedMarketLeverage !== undefined) {
    return parseFloat(selectedMarketLeverage);
  }

  // Otherwise return default  1 (1 is always default account leverage)
  return DEFAULT_ACCOUNT_MARKET_LEVERAGE;
};

/**
 * Calculates the max order USD value based on the account stats and the selected market
 * @param accountStats account stats, including the withdrawable balance
 * @param selectedMarket selected market, including the leverage
 * @returns max order USD value
 */
export const getMaxOrderUsdValue = (
  accountStats: AccountStats | null | undefined,
  selectedMarket: Market | null,
): number | null => {
  if (!accountStats || !selectedMarket) {
    return null;
  }

  const leverage = getSelectedMarketLeverage(
    accountStats.leverage,
    selectedMarket.id,
  );
  const withdrawableBalance = accountStats.withdrawableBalance;
  if (!leverage) {
    return Math.max(withdrawableBalance, 0);
  }

  const max = leverage * withdrawableBalance;
  const roundedMax = roundToNearestTick(max, 0.01);

  return Math.max(roundedMax, 0);
};

// /**
//  * Returns the maximum order quantity based on the max order USD value and the order price
//  * @param maxOrderUsdValue max order USD value
//  * @param orderPrice entered order price
//  * @param assetMarketPrice current market price of the currency
//  * @returns
//  */
// export const getMaxOrderQuantity = (
//   maxOrderUsdValue: number | null,
//   orderPrice: number | null | undefined,
//   assetMarketPrice: number,
//   decimalScale = defaultDecimalScales,
// ): number => {
//   if (!maxOrderUsdValue) {
//     return 0;
//   }

//   // If order price is not entered, use the market price
//   const price = orderPrice || assetMarketPrice;

//   if (price === 0) {
//     return maxOrderUsdValue;
//   }

//   return getCryptoAmountFromNotional(price, maxOrderUsdValue, decimalScale);
// };

export const roundToNearestTick = (
  size: number | string,
  tick: number,
): number => {
  try {
    if (size === null || size === undefined) {
      return size;
    }

    const parsedSize = typeof size === 'number' ? size : parseFloat(size);
    if (tick <= 0) {
      return parsedSize;
    }
    const numTicks = Math.round(parsedSize / tick);

    let first = new bigDecimal(numTicks);
    let second = new bigDecimal(tick);
    let res = first.multiply(second);

    const num = Number(res.getValue());

    // Check if the number is zero and returns minTick or minOrder, or the input number otherwise
    if (num === 0) {
      return tick;
    }

    return num;
  } catch (e) {
    return size as number;
  }
};

export const roundUpToNearestTick = (
  size: number | string,
  tick: number,
): number => {
  const roundedDown = roundDownToNearestTick(size, tick);
  if (roundedDown) {
    return roundedDown + tick;
  }
  return roundedDown;
};

export const roundDownToNearestTick = (
  size: number | string,
  tick: number,
): number => {
  try {
    if (size === null || size === undefined) {
      return size;
    }

    const parsedSize = typeof size === 'number' ? size : parseFloat(size);
    if (tick <= 0) {
      return parsedSize;
    }
    const numTicks = Math.floor(parsedSize / tick);

    let first = new bigDecimal(numTicks);
    let second = new bigDecimal(tick);
    let res = first.multiply(second);

    const num = Number(res.getValue());

    // Check if the number is zero and returns minTick or minOrder, or the input number otherwise
    if (num === 0) {
      return tick;
    }
    return num;
  } catch (e) {
    return size as number;
  }
};

const MAX_JS_DECIMALS = 12;
/**
 * Rounds the given javascript decimal number to the specified number of decimal places
 * and returns the result as a string.
 * @param jsDecimal Javascript decimal number to round
 * @param precision Number of decimal places to round to
 * @returns Rounded number as a string
 */
export const roundJsDecimalToString = (
  jsDecimal: number | string,
  precision: number = MAX_JS_DECIMALS,
) => {
  try {
    const maxPrecision =
      precision > MAX_JS_DECIMALS ? MAX_JS_DECIMALS : precision;
    const bigDecimalJs = new bigDecimal(jsDecimal).round(
      maxPrecision,
      bigDecimal.RoundingModes.DOWN,
    );
    return bigDecimalJs.getValue();
  } catch (e) {
    console.error('Error rounding js decimal to string', e);
    return jsDecimal as string;
  }
};

// const defaultDecimalScales: DecimalScale = {
//   price: DEFAULT_DECIMAL_SCALE,
//   priceScale: DEFAULT_DECIMAL_SCALE,
//   size: DEFAULT_DECIMAL_SCALE,
//   minOrder: 0.0001,
//   minTick: 0.0001,
// };

export const USD_DECIMAL_SCALE: DecimalScale = {
  price: DEFAULT_DECIMAL_SCALE,
  priceScale: DEFAULT_DECIMAL_SCALE,
  size: DEFAULT_DECIMAL_SCALE,
  minOrder: 0.0001,
  minTick: 0.0001,
};

/**
 * Filters and returns an array of markets based on the search input value
 * @param markets Array of markets to filter
 * @param searchMarketsValue Search value based on which to filter market IDs (e.g. 'ETH')
 * @returns An array of filtered markets based on the search input
 */
export const filterMarkets = (
  markets: Market[] | null | undefined,
  searchMarketsValue: string,
): Market[] | null => {
  let filteredMarkets = markets ? [...markets] : null;

  if (!filteredMarkets || filterMarkets.length === 0 || !searchMarketsValue) {
    return filteredMarkets;
  }

  const lowerCasedSearchValue = searchMarketsValue.toLowerCase();
  filteredMarkets = [
    ...filteredMarkets.filter(
      el =>
        el.id.toLowerCase().startsWith(lowerCasedSearchValue) ||
        el.marketTitle.toLowerCase().startsWith(lowerCasedSearchValue),
    ),
  ];

  return filteredMarkets;
};

export const IS_ROUTE_TV_MOBILE_KEY = 'IS_ROUTE_TV_MOBILE_KEY';
export const isRouteTVMobile = pathname => {
  let isTVMobile = pathname === '/tv-mobile';
  localStorage.setItem(IS_ROUTE_TV_MOBILE_KEY, `${isTVMobile}`);
  return isTVMobile;
};

/**
 * Gets the value from of the user's preference to disable the order confirmation screen from local storage
 * @returns true if the user has previously selected to disable the order confirmation screen
 */
export const getDisableConfirmationValFromLocalStorage = () => {
  const localStorageVal = localStorage.getItem(
    LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN,
  );

  if (!localStorageVal) return false;

  return localStorageVal === 'true' ? true : false;
};

/**
 * Gets the value from of the user's preference to disable the order confirmation screen from local storage
 * @returns true if the user has previously selected to disable the order confirmation screen
 */
export const getDisableCancelOrderConfirmationValFromLocalStorage = () => {
  const localStorageVal = localStorage.getItem(
    LS_SHOULD_DISABLE_CANCEL_ORDER_CONFIRMATION_MODAL,
  );

  if (!localStorageVal) return false;

  return localStorageVal === 'true' ? true : false;
};

/**
 * Returns a boolean value indicating whether the orders or positions in the old state object differ from those in the updated state object.
 * @param oldState - The old state object to compare against
 * @param updatedState - The updated state object to compare against
 * @returns `true` if the orders or positions in the old state object differ from those in the updated state object, `false` otherwise.
 */
export const checkIsOrdersOrPositionsChanged = (
  oldState: AccountStats | undefined | null,
  updatedState: AccountStats | undefined | null,
) => {
  try {
    // Check if orders or positions, fills length has changed
    if (oldState?.orders?.length !== updatedState?.orders?.length) {
      return true;
    }

    if (oldState?.positions?.length !== updatedState?.positions?.length) {
      return true;
    }

    if (oldState?.fills?.length !== updatedState?.fills?.length) {
      return true;
    }

    // Check if any order has changed
    if (oldState?.orders) {
      const ordersChanged = oldState?.orders?.filter((p, idx) => {
        return Object.keys(p).some(prop => {
          return p[prop] !== updatedState?.orders[idx][prop];
        });
      });
      if (ordersChanged && ordersChanged?.length > 0) return true;
    }

    // Check if any position has changed
    if (oldState?.positions) {
      const positionsChanged = oldState?.positions?.filter((p, idx) => {
        return Object.keys(p).some(prop => {
          return (
            ['id', 'size', 'side', 'unrealizedPnl'].includes(prop) &&
            p[prop] !== updatedState?.positions[idx][prop]
          );
        });
      });
      if (positionsChanged && positionsChanged?.length > 0) return true;
    }
    return false;
  } catch (error) {
    return true;
  }
};

/**
 * Checks if the given value is a number.
 *
 * @remarks
 * This function returns true for numeric strings as well as for number values.
 *
 * @param value - The value to check if it's a number.
 * @returns True if the value is a number or numeric string, otherwise false.
 */
export const isNumber = (value: any): value is number => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

/**
 * Truncates a price value to a specified number of decimal places.
 *
 * @remarks
 * This function takes a number or a numeric string as input and returns the
 * truncated value as a number. If the input is not a number, the function
 * returns null. For negative numbers, it ensures correct rounding behavior.
 *
 * @param value - The price value to truncate, as a number or numeric string.
 * @param digits - The number of decimal places to truncate to (default: 0).
 * @returns The truncated price value as a number, or null if the input is not a number.
 */
export const truncatePrice = (
  value: number | string | null,
  digits = 0,
): number | null => {
  // Check if the value is a number or numeric string
  if (!isNumber(value) || value === null || value === undefined) {
    return null;
  }

  const parsedValue = Number(value);

  // Calculate the factor used for truncation
  const fact = 10 ** digits;
  // Truncate the parsed value, ensuring correct rounding behavior for negative numbers
  const truncatedValue =
    parsedValue >= 0
      ? Math.floor(parsedValue * fact)
      : Math.ceil(parsedValue * fact);

  // Return the truncated value divided by the factor
  return truncatedValue / fact;
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Validates email address
 * @param email Email to validate
 * @returns true if email is valid, false otherwise
 */
export const isEmailValid = (email: string): boolean => {
  return emailRegex.test(String(email).toLowerCase());
};

/**
 * Checks if the device is a mobile device
 * @returns true if the device is a mobile device, false otherwise
 */
export const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(
    navigator.userAgent,
  );
};

/**
 * Converts a basis value to a percentage, or returns `null` if the input is `undefined`.
 * @param {number | undefined} basis - The basis value to be converted. Can be either a number or `undefined`.
 * @returns {number | null} - Returns the input value multiplied by 100 (converted to a percentage).
 * If the input is `undefined`, returns `null`.
 */
export const basisToPercent = (basis: number | undefined): number | null => {
  if (basis === undefined) return null;
  return basis * 100;
};

/**
 * Checks if the given wallet is the same as the one stored in local storage. Used to verify user session.
 * @param wallet Wallet to check
 * @returns true if the given wallet is the same as the one stored in local storage, false otherwise
 */
export const verifyUserSession = (wallet: string) => {
  const walletFromLocalStorage = readUserSetting(LS_WALLET);
  return walletFromLocalStorage?.toLowerCase() === wallet?.toLowerCase();
};

export const jsonToByteArray = (value: string) => {
  return Array.from(arrayify(toUtf8Bytes(JSON.stringify(value))));
};

export const byteArrayToString = (value: any) => {
  return toUtf8String(base64.decode(value));
};

/**
/**
 * Abbreviates a number to K, M, or B based on its magnitude.
 * @param number - The input number to be abbreviated.
 * @returns string - The abbreviated number with a suffix (K for thousands, M for millions, B for billions).
 * If the input is not a valid number, returns 'Invalid Number'.
 */
export const abbreviateNumber = (input: number | string) => {
  let number = 0;
  if (typeof input === 'string') {
    number = parseFloat(input);
  } else {
    number = input;
  }
  if (isNaN(number)) {
    return 'Invalid Number';
  }
  if (number === 0) return '0';

  if (number < 1000) return number.toFixed(2);

  const suffixes = ['', 'K', 'M', 'B'];
  const index = Math.floor(Math.log10(number) / 3);

  const result = (number / 10 ** (index * 3)).toFixed(2).replace(/\.0+$/, '');

  return parseFloat(result) + suffixes[index];
};

/**
 * Substracts a tiny amount from the passed in value to account for auto rounding issues that occurs when a string is parsed to Float, for eg: 4.187432899999999 will return 4.18743290.......
 *
 * @param num - The value.
 * @param decimalPlaces - The number substracted depends on decimalPlaces, if 0, it might divide 1
 *
 */
export const makeABitLessthanOrEqual = (
  num: number,
  _decimalPlaces: number,
  multiplier = 1,
) => {
  if (num === 0) return 0;

  const decimalPlaces =
    _decimalPlaces > MAX_FLOAT_DECIMALS ? MAX_FLOAT_DECIMALS : _decimalPlaces; // to account for rounding issues

  return num - multiplier / Math.pow(10, decimalPlaces - 1); // at -1 decimals place from right, cuz when too close to a 11 digit whole number, the number is rounded off to 11 digits as well automatically
};

/**
 * Checks if the user is a whale based on accountEquity, tradingVolume or stakedInVault
 *
 * @param accountStats
 * @returns boolean - true if the user is a whale, false otherwise
 */
export const isWhaleUser = (
  accountEquity = 0,
  tradingVolume = 0,
  stakedInVault = 0,
  yourLocksAmount = 0,
  yourLocksLPAmount = 0,
) => {
  if (
    accountEquity >= 50_000 ||
    tradingVolume >= 8_000_000 ||
    stakedInVault >= 100_000 ||
    yourLocksAmount >= 500_000 ||
    yourLocksLPAmount >= 3000
  )
    return true;

  return false;
};

/**
 * Checks if the environment is production or not, returns disabled if it is, else always returns false
 *
 * @param disabled
 * @returns boolean - input value if the environment is production, false otherwise
 */
export const parseProdDisabled = (disabled: boolean) => {
  if (config.isProd) return disabled;

  return false;
};

/**
 * Shows a number in scientific notation as a string
 */
export const numToString = (num: number | undefined | null) => {
  if (!num) return num;
  if (num === undefined || num === null) return num;

  // Convert number to string using exponential notation to capture all digits
  const stringifiedNum = num.toString();

  // If the stringified number contains an 'e', we will convert it to a fixed-point notation string
  if (stringifiedNum.includes('e')) {
    return num
      .toFixed(12)
      .replace(/(\.\d*?[1-9])0+$/, '$1')
      .replace(/\.0+$/, '.0');
  } else {
    return num;
  }
};

export const roundToNearestTickAndPrettify = (
  size: number | string,
  tick: number,
): string => {
  return prettyFormatNumber(roundToNearestTick(size, tick));
};

export const openTransactionOnExplorer = (txHash: string) => {
  const etherscanUrl = `${config.etherscanUrl}/tx/${txHash}`;

  window.open(etherscanUrl, '_blank');
};
