import { Dispatch, SetStateAction } from 'react';

import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import FilterSelector from 'components/FilterSelector';
import DateRangeFilterSelector, {
  DateFilterRangeWithType,
} from 'components/FilterSelector/DateRangeFilterSelector';
import { MarketAsFilterOption } from 'components/FilterSelector/OptionRenderers';

import { FilterOptionsContainer } from '../styles';
import tradeIcon from 'assets/icons/portfolio-nav-items/trades-active.svg';
import { useAppContext } from 'contexts/AppContext';

import { TradeSide } from 'enums';
import { observer } from 'mobx-react';

export type TFillsFilter = {
  marketId?: string;
  side?: TradeSide;
  dateRange?: DateFilterRangeWithType;
};

type Props = {
  appliedFilters: TFillsFilter;
  onChangeFilter: Dispatch<SetStateAction<TFillsFilter>>;
};
const FillsFilterOptions = ({ appliedFilters, onChangeFilter }: Props) => {
  const {
    store: {
      markets: { markets },
    },
  } = useAppContext();
  const marketLogo = useGetMarketLogoFromMarketId(appliedFilters.marketId);

  const handleFilterChange = (
    _value: TFillsFilter[keyof TFillsFilter],
    key: keyof TFillsFilter,
  ) => {
    const value = appliedFilters[key] === _value ? undefined : _value;
    onChangeFilter({ ...appliedFilters, [key]: value });
  };

  return (
    <FilterOptionsContainer>
      <FilterSelector
        icon={tradeIcon}
        label={'Market'}
        value={appliedFilters?.marketId || 'All'}
        leftValueIcon={appliedFilters?.marketId ? marketLogo : undefined}
        renderOption={option => (
          <MarketAsFilterOption
            selected={option.id === appliedFilters?.marketId}
            market={option}
          />
        )}
        options={markets || []}
        onOptionSelect={({ id }) => handleFilterChange(id, 'marketId')}
      />

      <div className="seperator" />

      {/* <FilterSelector
        icon={diceIcon}
        label={'Trade Type'}
        value={
          appliedFilters.side
            ? capitalizeFirstLetter(appliedFilters.side)
            : 'All'
        }
        renderOption={option => (
          <LabelWithRadio
            selected={option === appliedFilters?.side}
            label={capitalizeFirstLetter(option)}
          />
        )}
        options={[TradeSide.LONG, TradeSide.SHORT]}
        onOptionSelect={option => handleFilterChange(option, 'side')}
      />

      <div className="seperator" /> */}

      <DateRangeFilterSelector
        selectedRange={appliedFilters?.dateRange}
        onRangeSelect={range => handleFilterChange(range, 'dateRange')}
      />
    </FilterOptionsContainer>
  );
};

export default observer(FillsFilterOptions);
