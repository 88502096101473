import { Dispatch, SetStateAction } from 'react';

import DateRangeFilterSelector, {
  DateFilterRangeWithType,
} from 'components/FilterSelector/DateRangeFilterSelector';

import { observer } from 'mobx-react';

export type TReferralFeesFilter = {
  dateRange?: DateFilterRangeWithType;
};

type Props = {
  appliedFilters: TReferralFeesFilter;
  onChangeFilter: Dispatch<SetStateAction<TReferralFeesFilter>>;
};
const ReferralFeesFilterOptions = ({
  appliedFilters,
  onChangeFilter,
}: Props) => {
  return (
    <DateRangeFilterSelector
      selectedRange={appliedFilters?.dateRange}
      onRangeSelect={dateRange => onChangeFilter({ dateRange })}
    />
  );
};

export default observer(ReferralFeesFilterOptions);
