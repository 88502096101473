import { useCallback } from 'react';

import { showNotification } from 'utils';

import { useExchangeAPI } from './useExchangeAPI';
import { API_MAPPINGS } from 'constants/apiMappings';
import { TimeOptions } from 'pages/Portfolio/Pages/ReferralDashboard/Leaderboard/TimeframeSelectDropdown';
import { RestService } from 'service/restService';

import { Endpoints, NotificationType, QueryKeys, RequestMethod } from 'enums';
import { Referral, ReferralLeaderboard } from 'interfaces';
import { useQueryClient } from 'react-query';

type EditReferralCodeParams = {
  newReferralCode: string;
};

const restService = RestService.get();

export function useReferralsAPI() {
  const queryClient = useQueryClient();
  const { makePrivateRequest } = useExchangeAPI();

  const fetchUserReferral = useCallback(async (): Promise<Referral> => {
    try {
      const { data }: { data: Referral } = await makePrivateRequest({
        method: RequestMethod.GET,
        endpoint: `/${Endpoints.REFERRAL}`,
        requestParams: {},
        responseMapping: API_MAPPINGS.REFERRAL_MAPPING,
        shouldCheckJwtValidity: false,
      });

      // If Referral code hasn't been created yet, create one
      if (!data) {
        const { data }: { data: Referral } = await makePrivateRequest({
          method: RequestMethod.POST,
          endpoint: `/${Endpoints.REFERRAL}`,
          requestParams: {
            model: 'percentage',
          },
          responseMapping: API_MAPPINGS.REFERRAL_MAPPING,
          shouldCheckJwtValidity: true,
        });

        return data;
      }

      return data;
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  }, [makePrivateRequest]);

  const fetchReferralsLeaderboard = useCallback(
    async (selectedTimescale: TimeOptions): Promise<ReferralLeaderboard[]> => {
      try {
        const { data }: { data: ReferralLeaderboard[] } =
          await restService.request({
            method: RequestMethod.GET,
            path: `/${Endpoints.REFERRAL_LEADERBOARD}`,
            queryParams: {
              range: selectedTimescale,
            },
            responseMapping: API_MAPPINGS.REFERRAL_MAPPING,
            isArray: true,
          });

        return data;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [],
  );

  const editReferralCode = useCallback(
    async (params: EditReferralCodeParams): Promise<boolean> => {
      try {
        await makePrivateRequest({
          method: RequestMethod.PATCH,
          endpoint: `/${Endpoints.REFERRAL}`,
          requestParams: params,
          paramsMapping: API_MAPPINGS.EDIT_REFERRAL_PARAMS_MAPPING,
        });

        queryClient.setQueryData(QueryKeys.UserReferral, (oldData: any) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            shortCode: params.newReferralCode,
            amendCounter: oldData.amendCounter + 1,
          };
        });

        queryClient.invalidateQueries(QueryKeys.UserReferral);

        return true;
      } catch (e: any) {
        showNotification({
          title: 'Error occurred when editing referral code',
          description:
            'An error occurred when editing referral code, please try again later.',
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [makePrivateRequest, queryClient],
  );

  return {
    fetchReferralsLeaderboard,
    fetchUserReferral,
    editReferralCode,
  } as const;
}

export type ReferralsAPI = ReturnType<typeof useReferralsAPI>;
