import Text from 'components/Text';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  align-items: stretch;
  background: ${({ theme }) => theme.colors.negativeBackground500};

  .content {
    display: flex;
    padding: 10px;
  }

  .left-border {
    display: flex;
    width: 5px;

    background: ${({ theme }) => theme.colors.negativeForeground200};
  }
`;

export const UnstakeWarning = () => {
  return (
    <Container>
      <div className="left-border" />
      <div className="content">
        <Text variant="BODY_S">
          You will no longer earn rewards or yield if you unstake. Are you sure
          you want to proceed?
          <br />
          <br />
          Unstaking from the vault may take up to 24h to process. Funds will be
          credited to your trading balance.
        </Text>
      </div>
    </Container>
  );
};
