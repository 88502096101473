import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import dayjs from 'dayjs';

dayjs.extend(timezone);
dayjs.extend(utc);
const tz = 'America/New_York';

export enum ElixirPools {
  BTC_PERP = 'BTC_PERP',
  ETH_PERP = 'ETH_PERP',
  SOL_PERP = 'SOL_PERP',
  LUNA_PERP = 'LUNA_PERP',
  ARB_PERP = 'ARB_PERP',
  MATIC_PERP = 'MATIC_PERP',
  DOGE_PERP = 'DOGE_PERP',
  LDO_PERP = 'LDO_PERP',
  SUI_PERP = 'SUI_PERP',
  PEPE_PERP = 'PEPE_PERP',
  BCH_PERP = 'BCH_PERP',
  XRP_PERP = 'XRP_PERP',
  WLD_PERP = 'WLD_PERP',
  TON_PERP = 'TON_PERP',
  STX_PERP = 'STX_PERP',
  TRB_PERP = 'TRB_PERP',
  APT_PERP = 'APT_PERP',
  INJ_PERP = 'INJ_PERP',
  AAVE_PERP = 'AAVE_PERP',
  LINK_PERP = 'LINK_PERP',
  BNB_PERP = 'BNB_PERP',
  RNDR_PERP = 'RNDR_PERP',
  MKR_PERP = 'MKR_PERP',
  RLB_PERP = 'RLB_PERP',
  ORDI_PERP = 'ORDI_PERP',
  STG_PERP = 'STG_PERP',
  SATS_PERP = 'SATS_PERP',
  TIA_PERP = 'TIA_PERP',
  BLUR_PERP = 'BLUR_PERP',
  JTO_PERP = 'JTO_PERP',
  MEME_PERP = 'MEME_PERP',
  SEI_PERP = 'SEI_PERP',
  FIL_PERP = 'FIL_PERP',
  OP_PERP = 'OP_PERP',
  AVAX_PERP = 'AVAX_PERP',
}

const convertToJavaScriptDate = (date: string): Date => {
  return dayjs.tz(date, tz).toDate();
};

type ElixirVaultsConstants = {
  [key in ElixirPools]?: {
    inceptionDate: Date;
    inceptionTvlUsd: number;
    inceptionPricePerShare: number;
  };
};
export const elixirVaultsConstants: ElixirVaultsConstants = {
  [ElixirPools.BTC_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.ETH_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.SOL_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.LUNA_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.ARB_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.MATIC_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.DOGE_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.LDO_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.SUI_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.PEPE_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.BCH_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.XRP_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.WLD_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.TON_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.STX_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.TRB_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.APT_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.INJ_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.AAVE_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.LINK_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.BNB_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.RNDR_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.MKR_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.RLB_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.ORDI_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.STG_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.SATS_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.TIA_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.BLUR_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.JTO_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.MEME_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.SEI_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.FIL_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.OP_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
  [ElixirPools.AVAX_PERP]: {
    inceptionDate: convertToJavaScriptDate('2024-02-05T23:59:00'),
    inceptionTvlUsd: 10_000,
    inceptionPricePerShare: 1,
  },
};
