import { ReactNode } from 'react';

import { Tooltip, TooltipProps } from '@material-ui/core';

import infoCircleWhiteIcon from 'assets/icons/info-circle-white.svg';
import infoCircleIcon from 'assets/icons/info-circle.svg';

import styled from 'styled-components';

type IconProps = { size: number };
const Icon = styled.img<IconProps>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
`;

type IconColor = 'white' | 'gray';

const getIconSource = (color: IconColor) => {
  switch (color) {
    case 'gray':
      return infoCircleIcon;
    case 'white':
      return infoCircleWhiteIcon;
    default:
      return infoCircleIcon;
  }
};
interface Props {
  title: NonNullable<ReactNode>;
  placement?: TooltipProps['placement'];
  iconSize?: number;
  className?: string;
  color?: IconColor;
}
const InfoTooltip = ({
  title,
  placement = 'top',
  iconSize = 16,
  color = 'gray',
  className,
}: Props) => {
  return (
    <Tooltip placement={placement} title={title}>
      <Icon size={iconSize} src={getIconSource(color)} className={className} />
    </Tooltip>
  );
};

export default InfoTooltip;
