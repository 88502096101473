import { forwardRef, ReactNode, useCallback, useEffect, useRef } from 'react';

import { Clear } from '@material-ui/icons';

import { pushDataLayerEvent } from 'utils';

import { useOnClickOutside } from 'hooks';
import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';

import Text from 'components/Text';

import { CloseIcon, ContainedIcon, HeaderStyled, ModalStyled } from './style';
import xCloseIcon from 'assets/icons/close-x-dark.svg';

export type ModalSize = 'large' | 'medium' | 'small' | 'custom' | 'auto';

type TCloseButton = 'contained' | 'normal';
interface Props {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  children: ReactNode;
  size?: ModalSize;
  showHeader?: boolean;
  showCloseIcon?: boolean;
  /** If provided then you must handle the modal pop manually */
  onClose?: (isFromCloseButton?: boolean) => void;
  isSuccess?: boolean;
  isDanger?: boolean;
  name: string;
  disableCloseOnClickOutside?: boolean;
  backgroundImage?: string;
  width?: number;
  height?: number;
  ref?: any;
  overflow?: string;
  padding?: string;
  borderRadius?: string;
  gtmId?: string;
  closeButtonType?: TCloseButton;
  noBorder?: boolean;
  noShadow?: boolean;
  isBlurred?: boolean;
  minimiseOnOutsideClick?: boolean;
  handleKeyStrokes?: Record<number, () => Promise<void> | void>;
  id?: string;
}

const Modal = forwardRef<HTMLDivElement, Props>((props: Props, ref: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const modal = useModal();

  const {
    title,
    subTitle,
    children,
    size = 'medium',
    showHeader = true,
    showCloseIcon = true,
    onClose,
    isSuccess,
    isDanger,
    name,
    disableCloseOnClickOutside = false,
    backgroundImage,
    width,
    height,
    overflow = 'hidden',
    padding,
    borderRadius,
    gtmId,
    closeButtonType = 'normal',
    noBorder = false,
    noShadow = false,
    id,
    handleKeyStrokes = {},
  } = props;

  function close(isFromCloseButton?: boolean) {
    if (gtmId) {
      pushDataLayerEvent(`${gtmId}-close`);
    }
    if (!!onClose) {
      onClose(isFromCloseButton);
    } else {
      modal.pop({ name });
    }
  }

  const activeRef = ref ? ref : containerRef;

  const isLastModal = useCallback(() => {
    const filteredModals = modal.activeModals.filter(modal => !modal.minimised);
    const lastModal = filteredModals[filteredModals.length - 1];

    return (
      filteredModals.length > 0 &&
      lastModal.name === name &&
      lastModal.id === id
    );
  }, [id, modal.activeModals, name]);

  useOnClickOutside(activeRef, () => {
    if (!isLastModal()) {
      return;
    }
    if (!disableCloseOnClickOutside) {
      close();
    }
  });

  const objectKeys = Object.keys(handleKeyStrokes).map(i => parseInt(i));

  const handleKey = useCallback(
    (e?: KeyboardEvent) => {
      if (e?.keyCode === Key.Escape) {
        if (!isLastModal() || disableCloseOnClickOutside) {
          return;
        }
        close();
      }

      objectKeys.forEach(
        key => key === e?.keyCode && handleKeyStrokes[key]?.(),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLastModal, disableCloseOnClickOutside, close, handleKeyStrokes],
  );

  useKey([Key.Escape, ...objectKeys], handleKey);

  function renderCloseBtn() {
    if (closeButtonType === 'contained')
      return <ContainedIcon onClick={() => close(true)} src={xCloseIcon} />;

    return (
      <CloseIcon onClick={() => close(true)} className="close-icon">
        <Clear />
      </CloseIcon>
    );
  }

  return (
    <ModalStyled
      ref={activeRef}
      small={size === 'small'}
      medium={size === 'medium'}
      large={size === 'large'}
      custom={size === 'custom'}
      auto={size === 'auto'}
      backgroundImage={backgroundImage}
      width={width}
      height={height}
      overflow={overflow}
      padding={padding}
      borderRadius={borderRadius}
      noBorder={noBorder}
      noShadow={noShadow}
      onClick={e => e.stopPropagation()}
    >
      {showHeader ? (
        <HeaderStyled isSuccess={isSuccess} isDanger={isDanger}>
          <Text
            color={
              isSuccess
                ? 'positiveForeground200'
                : isDanger
                ? 'negativeForeground200'
                : 'white'
            }
            variant="BODY_L"
            className="align-center"
          >
            {title}
          </Text>
          {/* Replace h5 later when we have approprotae haeding variants on Text */}
          {subTitle ? <h5>{subTitle}</h5> : null}
        </HeaderStyled>
      ) : null}
      {showCloseIcon ? renderCloseBtn() : null}
      {children}
    </ModalStyled>
  );
});

export default Modal;
