import { useRef } from 'react';

import { showNotification } from 'utils';
import { brand } from 'utils/brand';

import useModal from 'hooks/useModal';

import Modal from 'components/Modal';
import Text from 'components/Text';

import InvitePreview from './Preview';
import DownloadPreview from './Preview/DownloadPreview';
import StepsForInvitingFriends from './Steps';
import { Container, Footer } from './styles';
import xCloseIcon from 'assets/icons/close-x-dark.svg';
import downloadIcon from 'assets/icons/download-white.svg';
import linkIcon from 'assets/icons/link.svg';
import telegramIcon from 'assets/icons/socials/telegram-white.svg';
import twitterIcon from 'assets/icons/socials/twitter-white.svg';
import { Modals } from 'constants/modals';

import { NotificationType } from 'enums';
import { toPng } from 'html-to-image';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const URI_TERMS = brand.tocLink;

const generateShareText = (
  referralCode: string,
  linkUrl: string,
  rankText?: string,
) => {
  let tweetText = `Join my referral: ${referralCode}\n${linkUrl}`;

  if (rankText !== undefined)
    tweetText =
      `I am rank ${rankText} on the referral leaderboard, win amazing rewards for reaching the leaderboard.\n` +
      tweetText;

  return tweetText;
};

const openTwitterWithText = (tweetText: string) => {
  const baseTwitterURL = 'https://twitter.com/intent/tweet?text=';
  const encodedText = encodeURIComponent(tweetText);
  const fullURL = baseTwitterURL + encodedText;
  window.open(fullURL, '_blank');
};

const openTelegramWithText = (tweetText: string) => {
  const baseTelegramURL = 'https://t.me/share/url?url=';
  const encodedText = encodeURIComponent(tweetText);
  const fullURL = baseTelegramURL + encodedText;

  window.open(fullURL, '_blank');
};

/**
 * Filter out html elements that should not be included in the image
 */
const htmlImageElementsFilter = (node: HTMLElement) => {
  const exclusionClasses = ['close-icon'];
  return !exclusionClasses.some(classname =>
    node.classList?.contains(classname),
  );
};

export const shortCodeToUrl = (shortCode?: string, baseUrl?: string) =>
  `${baseUrl || brand.appUrl}?ref=${shortCode || ''}`;

type Props = {
  referralCode: string;
  rankText?: string;
};
const InviteFriendsModal = ({ referralCode, rankText }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { pop } = useModal();
  const { t } = useTranslation();

  const linkUrl = shortCodeToUrl(referralCode);

  const closeModal = () => pop({ name: Modals.inviteFriendsModal });

  const createPng = async (): Promise<any> => {
    try {
      if (!ref.current) return;
      const htmlElement = ref.current;
      const dataUrl = await toPng(htmlElement, {
        filter: htmlImageElementsFilter,
        style: { opacity: '1' },
      });
      return dataUrl;
    } catch (err) {
      console.error('Failed to capture component:', err);
      throw err;
    }
  };

  const downloadImage = async () => {
    try {
      const image = await createPng();
      const link = document.createElement('a');
      link.download = `invite-friends.png`;
      link.href = image;
      link.click();
      showNotification({
        type: NotificationType.Positive,
        title: t('inviteFriendsImageDownloaded'),
      });
    } catch (e) {
      console.error(e);
      showNotification({
        type: NotificationType.Negative,
        title: 'Failed to Download Image',
      });
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(linkUrl)
      .then(() => {
        showNotification({
          type: NotificationType.Positive,
          title: t('referralLinkCopiedToClipboard'),
        });
      })
      .catch(error => {
        showNotification({
          type: NotificationType.Negative,
          title: 'Failed to Copy to Clipboard',
        });
      });
  };

  const ShareOptions = [
    {
      icon: linkIcon,
      label: t('CopyLink'),
      onClick: copyToClipboard,
      gtmId: 'copy-link',
    },
    {
      icon: telegramIcon,
      label: 'Telegram',
      onClick: () =>
        openTelegramWithText(
          generateShareText(referralCode, linkUrl, rankText),
        ),
      gtmId: 'telegram',
    },
    {
      icon: twitterIcon,
      label: 'Twitter',
      onClick: () =>
        openTwitterWithText(generateShareText(referralCode, linkUrl, rankText)),
      gtmId: 'twitter',
    },
    {
      icon: downloadIcon,
      label: t('Download'),
      onClick: downloadImage,
      gtmId: 'download',
    },
  ];

  return (
    <>
      {/* This is the image to be downloaded, not visible to user as the opacity is zero */}
      <DownloadPreview
        ref={ref}
        referralCode={referralCode}
        linkUrl={linkUrl}
      />
      <Modal
        showHeader={false}
        showCloseIcon={false}
        size={'auto'}
        name={Modals.inviteFriendsModal}
        overflow={'visible'}
        padding={'0px'}
        borderRadius={'12px'}
        gtmId="invite-friends-modal"
      >
        <Container>
          <InvitePreview
            // ref={ref}
            referralCode={referralCode}
            linkUrl={linkUrl}
          />

          <div className="content">
            <Text variant="HEADING_H2" className="title" fontWeight="semiBold">
              {t('InviteYourFriends')}
            </Text>

            <StepsForInvitingFriends />

            <Text
              variant="BODY_S"
              color="shadesForeground200"
              className="align-center"
            >
              {t('bySendingThisYouAgreeToDisclaimersAndOur')}{' '}
              <a
                className="text-primary"
                href={URI_TERMS}
                target="_blank"
                rel="noreferrer"
              >
                {t('termsAndConditions')}
              </a>
              .
            </Text>

            <Footer>
              {ShareOptions.map((option, index) => (
                <div
                  className="column"
                  data-gtmid={`button-invite-friends-${option.gtmId}`}
                  onClick={option.onClick}
                >
                  <div className="icon-circle">
                    <img src={option.icon} alt="clipboard" />
                  </div>
                  <Text variant="BODY_S">{option.label}</Text>
                </div>
              ))}
            </Footer>
          </div>

          <img
            onClick={closeModal}
            className="close-icon"
            src={xCloseIcon}
            alt="x"
          />
        </Container>
      </Modal>
    </>
  );
};

export default observer(InviteFriendsModal);
