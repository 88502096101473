import { brandedSelect } from 'utils/brand';

import Text from 'components/Text';

import clipStepInactiveIcon from 'assets/icons/step-clip-gray.svg';
import clipStepActiveIcon from 'assets/icons/step-clip-positive.svg';

import styled from 'styled-components';

type Props = { checked: boolean };
export const StyledStepIcon = styled.div<Props>`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 4px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  background-color: ${({ theme, checked }) =>
    checked ? theme.colors.positiveForeground300 : 'transparent'};

  border: 1.2px solid
    ${({ theme, checked }) =>
      checked ? 'transparent' : theme.colors.shadesForeground200};

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

export const Steps = styled.div`
  display: flex;
  padding: ${brandedSelect({
    rabbitx: '10px 15px',
    bfx: '0',
  })};
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;

  gap: ${brandedSelect({
    rabbitx: 2,
    bfx: 15,
  })}px;

  background: ${({ theme }) =>
    brandedSelect({
      rabbitx: theme.colors.shadesBackground700,
      bfx: theme.colors.transparent,
    })};

  .step-divider {
    height: 1.5px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
    width: 100%;
    border-radius: 100px;
  }

  .step {
    display: flex;
    padding: ${brandedSelect({ rabbitx: '10px 0px', bfx: '0' })};

    align-items: center;
    gap: 15px;
    align-self: stretch;

    .column {
      display: flex;
      flex-direction: column;
      gap: 2px;
      flex: 1;
      /* 
      > span:first-child {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        font-size: 12px;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      > span:last-child {
        color: ${({ theme }) => theme.colors.shadesForeground200};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-size: 10px;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
          font-size: 12px;
          line-height: normal;
        }
      } */
    }

    .loading-icon-container {
      width: 20px;
      height: 20px;
    }
  }
`;

type StyledClipStepIconProps = {
  isActive: boolean;
};

export const StyledClipStepIcon = styled(Text)<StyledClipStepIconProps>`
  height: 32px;
  width: 32px;
  background-image: ${({ isActive }) =>
    isActive ? `url(${clipStepActiveIcon})` : `url(${clipStepInactiveIcon})`};
  background-repeat: no-repeat;
  background-size: 32px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
