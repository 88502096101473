import { showNotification, textWithEllipsisInTheMiddle } from 'utils';

import { useAccount } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';
import useModal from 'hooks/useModal';
import { useReferralsAPI } from 'hooks/useReferralsAPI';
import useWindowDimensions from 'hooks/useWindowDimensions';

import Loading from 'components/Loading';

import useTwitterConnection from '../../../../hooks/twitter/useTwitterConnection';
import { MAX_REFERRAL_LINK_CHANGES } from '../ReferralDashboard/ProfileInfo';
import Leaderboard from './Leaderboard';
import NewReferralLinkModal from './NewReferralLinkModal';
import BTCLogo from 'assets/bfxInvite/BTC.svg';
import DoublePlusLogo from 'assets/bfxInvite/DoublePlus.svg';
import ETHLogo from 'assets/bfxInvite/ETH.svg';
import PlusLogo from 'assets/bfxInvite/Plus.svg';
import actionBackground from 'assets/bfxInvite/actionBackground.svg';
import bfxGridBackground from 'assets/bfxInvite/bfxGridBackground.svg';
import bfxLogo from 'assets/bfxInvite/bfxLogo.svg';
import CopyLogo from 'assets/bfxInvite/copy.svg';
import SeparatorLogo from 'assets/bfxInvite/separator.svg';
import TheyReceiveLogo from 'assets/bfxInvite/theyReceive.svg';
import TwitterLogo from 'assets/bfxInvite/twitter.svg';
import WalletLogo from 'assets/bfxInvite/wallet.svg';
import YouEarnLogo from 'assets/bfxInvite/youEarn.svg';
import { TWITTER_KEYS } from 'constants/api';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';

import { NotificationType, QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';
import styled, { useTheme } from 'styled-components';

const BodyContainer = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--color-black);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) 40px;
  box-sizing: border-box;
  gap: 120px;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--colour-text-primary);
  font-family: var(--font-britanica-bold);
`;

const GridBackground = styled.img`
  width: 100vw;
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;
`;

const Nav = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.screenSizes.xl}px) {
    flex-direction: column;
    gap: var(--gap-21xl);
  }
`;

const LogoIcon = styled.img`
  position: relative;
`;

const Menu = styled.div`
  border-radius: var(--br-xs);
  background: linear-gradient(
    -88.99deg,
    rgba(168, 168, 168, 0.14),
    rgba(255, 255, 255, 0.14)
  );
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.16);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) 30px;
  gap: var(--gap-21xl);
  cursor: pointer;
`;

const Docs = styled.div`
  position: relative;

  :hover {
    color: #b1fb16;
  }
`;

const TextItem = styled.div`
  position: relative;
`;

const NavButtons = styled.div`
  height: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  color: var(--color-black);
  font-family: var(--font-britanica-bold);
`;

const InviteFriends = styled.div`
  border-radius: var(--br-xs);
  background-color: #b1fb16;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-xl);
  cursor: pointer;
`;

const JoinNewsletter = styled.div`
  border-radius: var(--br-xs);
  border: 1px solid var(--colour-text-primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-xl);
  color: var(--colour-text-primary);
  cursor: pointer;

  :hover {
    color: var(--color-black);
    background-color: var(--colour-text-primary);
  }

  @media (max-width: ${({ theme }) => theme.screenSizes.xl}px) {
    padding: 14px var(--padding-xl);
  }
`;

const ButtonItem = styled.div`
  position: relative;
`;

const BtcIcon = styled.img`
  position: absolute;
  top: 129px;
  left: 254px;
  object-fit: cover;
`;

const PlusIcon = styled.img`
  position: absolute;
  top: 324px;
  left: 146px;
  object-fit: cover;
`;

const DoublePlusIcon = styled.img`
  position: absolute;
  top: 189px;
  right: 197px;
  object-fit: cover;
`;

const EthIcon = styled.img`
  position: absolute;
  top: 404px;
  right: 124px;
  object-fit: cover;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 3;
  font-size: 52px;
  font-family: var(--font-britanica-black);
`;

const Span = styled.span`
  letter-spacing: -8px;
`;

const ReferYourFriends = styled.div`
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-britanica-regular);
`;

const ActionsSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-11xl);
  z-index: 4;
  text-align: left;
  font-size: var(--font-size-lg);

  @media (max-width: ${({ theme }) => theme.screenSizes.xl}px) {
    flex-direction: column;
    gap: var(--gap-21xl);
    width: 100%;
    overflow: auto;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xl);
  width: 619px;
`;

const ConnectWalletSection = styled.div`
  align-self: stretch;
  background: url(${actionBackground}) no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xl) 0px;
  gap: var(--gap-3xs);
  cursor: pointer;
`;

const ConnectedWalletSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-lg) var(--padding-xl);
  background: url(${actionBackground}) no-repeat;
`;

const Disconnect = styled.div`
  border-radius: var(--br-5xs);
  border: 1px solid var(--colour-text-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-mini);
  font-size: 15px;
  font-family: var(--font-britanica-bold);
  cursor: pointer;
`;

const WalletIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;

const ConnectWallet = styled.div`
  position: relative;
  letter-spacing: 1px;
`;

const Separator = styled.img`
  position: relative;
  object-fit: cover;
`;

const YouEarn = styled.div`
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: #0f4c76;
  border: 1px solid var(--color-gray-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl);
  gap: var(--gap-11xl);
  font-size: var(--font-size-5xl);
  font-family: var(--font-britanica-black);
`;

const YouEarnIcon = styled.img`
  width: 26px;
  position: relative;
  height: 26px;
`;
const TheyReceiveText = styled.div`
  position: relative;
  line-height: 104.83%;
`;
const YouEarnHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const BonusPointsWhen = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 104.83%;
`;
const HowItWorks = styled.div`
  flex: 1;
  position: relative;
  line-height: 104.83%;
`;
const Disclaimer = styled.div`
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: #093a5b;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-3xs);
  font-size: var(--font-size-base);
  font-family: var(--font-britanica-regular);
`;

const Disclaimer1 = styled.div`
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: #292254;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-3xs);
  font-size: var(--font-size-base);
  font-family: var(--font-britanica-regular);
`;

const Description = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-mini);
  font-size: var(--font-size-xl);
  font-family: var(--font-britanica-bold);
`;

const TwitterIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.3;
`;
const VerifyTwitter = styled.div`
  position: relative;
  opacity: 0.3;
`;
const TwitterSection = styled.div`
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: rgba(28, 28, 28, 0.3);
  backdrop-filter: blur(44px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xl) 0px;
  gap: var(--gap-3xs);
`;

const TwitterWCIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;

const TwitterWCSection = styled.div`
  align-self: stretch;
  background: url(${actionBackground}) no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 25px 0px;
  gap: var(--gap-3xs);
  cursor: pointer;
`;

const TwitterConnetedSection = styled.div`
  align-self: stretch;
  background: url(${actionBackground}) no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-lg) var(--padding-xl);
`;

const Https = styled.span`
  color: var(--colour-text-secondary);
`;
const RefLink = styled.span`
  color: var(--colour-text-primary);
`;
const CopyIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`;
const EditLink = styled.div`
  border-radius: var(--br-5xs);
  border: 1px solid var(--colour-text-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-mini);
  font-size: 15px;
  font-family: var(--font-britanica-bold);
  cursor: pointer;
`;
const EditLinkSection = styled.div`
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
  font-size: var(--font-size-mini);
  color: var(--colour-text-primary);
  font-family: var(--font-britanica-bold);
`;

const TheyReceiveIcon = styled.img`
  width: 25px;
  position: relative;
  height: 23px;
`;

const TheyReceive = styled.div`
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: #3e3479;
  border: 1px solid var(--color-gray-100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl);
  gap: var(--gap-11xl);
  font-size: var(--font-size-5xl);
  font-family: var(--font-britanica-black);
`;

const LeaderboardSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  z-index: 5;
  font-size: 32px;
`;

const HelloBlasterContainer = styled.div`
  position: relative;
  text-transform: capitalize;
`;

const LeaderboardTable = styled.div`
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: var(--colour-background);
  border: 1px solid var(--color-gray-100);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl);
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--font-size-smi);
  color: var(--colour-text-secondary);
  font-family: var(--numbers-xs-medium);
`;

export const BFXInvite = observer(() => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { fetchUserReferral } = useReferralsAPI();
  const modal = useModal();
  const { manualDisconnect } = useAccount();
  const { isReadingSignatureFromLocalDB } = useExchangeAPI();

  const {
    isLoading: isLoadingUserReferral,
    data: userReferral,
    isError: isErrorUserReferral,
  } = useQuery(
    [QueryKeys.UserReferral],
    async () => await fetchUserReferral(),
    {
      // 60 seconds
      refetchInterval: 60_000,
      enabled: !!frontendSecrets?.jwt,
    },
  );

  const LS_TWITTER_PROFILE_DATA = 'twitterProfileData';
  const walletDisconnect = () => {
    manualDisconnect();
    localStorage.removeItem(LS_TWITTER_PROFILE_DATA);
  };

  // const REDIRECT_URI = `https://blastfutures.com/invite/callback/twitter`
  // const REDIRECT_URI = `https://localhost:3000/callback/twitter`

  const onProfileDataReceived = (twitterData: Record<string, any>) => {
    localStorage.setItem(LS_TWITTER_PROFILE_DATA, JSON.stringify(twitterData));
  };

  const getProfileData = () => {
    const twitterProfileData = localStorage.getItem(LS_TWITTER_PROFILE_DATA);

    return twitterProfileData ? JSON.parse(twitterProfileData) : {};
  };

  const { onTwitterConnect, twitterData, isLoading } = useTwitterConnection({
    onProfileDataReceived: onProfileDataReceived,
  });

  const copyToClipboard = () => {
    const linkUrl = `${
      typeof window === 'object' && window.location.origin
    }/?ref=${userReferral?.shortCode}`;
    navigator.clipboard
      .writeText(linkUrl)
      .then(() => {
        showNotification({
          type: NotificationType.Positive,
          title: 'Referral Link Copied to Clipboard',
        });
      })
      .catch(error => {
        showNotification({
          type: NotificationType.Negative,
          title: 'Failed to Copy to Clipboard',
        });
      });
  };

  const openNewReferralLinkModal = () => {
    const referralLinkChangesLeft =
      MAX_REFERRAL_LINK_CHANGES - (userReferral?.amendCounter ?? 0);
    modal.present(
      <NewReferralLinkModal
        referralLinkChangesLeft={referralLinkChangesLeft}
      />,
      Modals.newReferralLinkModal,
    );
  };

  const { width } = useWindowDimensions();
  const theme = useTheme();
  const isBiggerThanXL = width > (theme as any).screenSizes.xl;

  return (
    <BodyContainer>
      <GridBackground alt="" src={bfxGridBackground} />
      <Nav>
        <LogoIcon alt="" src={bfxLogo} />
        <Menu>
          <Docs
            onClick={() =>
              window.open('https://docs.blastfutures.com/', '_blank')
            }
          >
            Docs
          </Docs>
          <Docs
            onClick={() => window.open('https://t.me/BlastFutures', '_blank')}
          >
            Telegram
          </Docs>
          <Docs
            onClick={() =>
              window.open('https://twitter.com/BlastFutures', '_blank')
            }
          >
            Twitter
          </Docs>
        </Menu>
        <NavButtons>
          <InviteFriends
            onClick={() =>
              window.open('https://blastfutures.com/invite', '_self')
            }
          >
            <ButtonItem>Invite Friends</ButtonItem>
          </InviteFriends>
          <JoinNewsletter
            onClick={() => window.open('https://blastfutures.com', '_self')}
          >
            <ButtonItem>Join Newsletter</ButtonItem>
          </JoinNewsletter>
        </NavButtons>
      </Nav>
      {isBiggerThanXL && (
        <>
          <EthIcon alt="" src={ETHLogo} />
          <BtcIcon alt="" src={BTCLogo} />
          <DoublePlusIcon alt="" src={DoublePlusLogo} />
          <PlusIcon alt="" src={PlusLogo} />
        </>
      )}
      <Header>
        <HelloBlasterContainer>
          Hello
          <Span>{` `}</Span>
          👋{' '}
          {!!frontendSecrets?.jwt
            ? textWithEllipsisInTheMiddle(frontendSecrets.profile.wallet)
            : 'Blaster...'}
        </HelloBlasterContainer>
        <ReferYourFriends>{`Refer your friends to start earning points & climb up the ladder`}</ReferYourFriends>
      </Header>
      <ActionsSection>
        <LeftContainer>
          {!!frontendSecrets?.jwt ? (
            <ConnectedWalletSection>
              <TextItem>
                {textWithEllipsisInTheMiddle(frontendSecrets.profile.wallet)}
              </TextItem>
              <Disconnect onClick={() => walletDisconnect()}>
                <TextItem>Disconnect</TextItem>
              </Disconnect>
            </ConnectedWalletSection>
          ) : (
            <ConnectWalletSection
              onClick={() => modal.present(<WalletModal />, Modals.walletModal)}
            >
              {isReadingSignatureFromLocalDB ? (
                <Loading />
              ) : (
                <>
                  <WalletIcon alt="" src={WalletLogo} />
                  <ConnectWallet>Connect Wallet</ConnectWallet>
                </>
              )}
            </ConnectWalletSection>
          )}

          <YouEarn>
            <YouEarnHeader>
              <YouEarnIcon alt="" src={YouEarnLogo} />
              <TheyReceiveText>You Earn</TheyReceiveText>
            </YouEarnHeader>
            <Description>
              <BonusPointsWhen>{`16% bonus points when you invite your friends. `}</BonusPointsWhen>
              <Disclaimer>
                <HowItWorks>
                  How it works: Share your Blast Futures referral link with your
                  friends and earn 16% bonus points when your friends earn
                  points.
                </HowItWorks>
              </Disclaimer>
            </Description>
          </YouEarn>
        </LeftContainer>
        {isBiggerThanXL && <Separator alt="" src={SeparatorLogo} />}
        <LeftContainer>
          {!!frontendSecrets?.jwt ? (
            getProfileData()['data'] ? (
              <TwitterConnetedSection>
                <TextItem>
                  <Https>https://... /</Https>
                  <RefLink>{userReferral?.shortCode}</RefLink>
                </TextItem>
                <EditLinkSection>
                  <CopyIcon alt="" src={CopyLogo} onClick={copyToClipboard} />
                  <EditLink onClick={openNewReferralLinkModal}>
                    <TextItem>Edit Link</TextItem>
                  </EditLink>
                </EditLinkSection>
              </TwitterConnetedSection>
            ) : (
              <TwitterWCSection onClick={onTwitterConnect}>
                <TwitterWCIcon alt="" src={TwitterLogo} />
                <TextItem>{`Verify Twitter & Generate Invite`}</TextItem>
              </TwitterWCSection>
            )
          ) : (
            <TwitterSection>
              <TwitterIcon alt="" src={TwitterLogo} />
              <VerifyTwitter>{`Verify Twitter & Generate Invite`}</VerifyTwitter>
            </TwitterSection>
          )}

          <TheyReceive>
            <YouEarnHeader>
              <TheyReceiveIcon alt="" src={TheyReceiveLogo} />
              <TheyReceiveText>They Receive</TheyReceiveText>
            </YouEarnHeader>
            <Description>
              <BonusPointsWhen>
                8% bonus points using your referral link.
              </BonusPointsWhen>
              <Disclaimer1>
                <HowItWorks>
                  Instant Boost for Your Friends: When your friends sign up
                  using your referral link, they receive an automatic 8% bonus
                  points on their airdrop.
                </HowItWorks>
              </Disclaimer1>
            </Description>
          </TheyReceive>
        </LeftContainer>
      </ActionsSection>
      <LeaderboardSection>
        <HelloBlasterContainer>{`Start early, climb the leaderboard! `}</HelloBlasterContainer>
        <LeaderboardTable>
          <Leaderboard
            twitterData={getProfileData()}
            userReferral={userReferral}
          />
        </LeaderboardTable>
      </LeaderboardSection>
    </BodyContainer>
  );
});
