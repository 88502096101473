import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  flex: 1;
  align-self: stretch;

  .content {
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex: 1;

    > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex: 1;
    }

    .spacer {
      display: flex;
      flex: 1;
    }

    .negative-mt-5 {
      margin-top: -5px;
    }

    .button-switch-container {
      border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
      padding: 4px;
      margin-top: 5px;
      margin-bottom: -1px;

      .button {
        padding: 10px 15px;
      }
    }
  }
`;
