import styled from 'styled-components';

export const ToggleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  .flexed {
    display: flex;
    gap: 2px;
    flex-grow: 1;
    flex-direction: column;

    .heading {
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      font-size: 14px;
      line-height: 16px;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .description {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.shadesForeground200};
      font-size: 10px;
      line-height: 14px;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;

export const ErrorText = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.negativeForeground200};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 10px;
  line-height: 14px;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
