import styled from 'styled-components';

export const WalletStatusStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .column {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .text-primary {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;

    font-size: 12px;
    line-height: 14px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 14px;
      line-height: 20px;
    }

    > img {
      height: 16px;
      width: 16px;
    }
  }

  .text-secondary {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 10px;
    line-height: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.shadesForeground200};
    cursor: pointer;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 12px;
      line-height: 16px;
    }

    > img {
      height: 12px;
      width: 12px;
    }
  }

  .success {
    color: ${({ theme }) => theme.colors.positiveForeground200};
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  border-radius: 100px;
  overflow: hidden;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.shadesBackground500};

  :hover {
    background: ${({ theme }) => theme.colors.shadesForeground400};
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    height: 40px;
    width: 40px;
  }

  > img {
    height: 100%;
    width: 100%;
  }

  > span {
    display: flex;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    transform: translateY(1.25px) translateX(-1px); // align emoji vertically
    align-items: center;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 20px;
      line-height: 24px;
      transform: translateY(1.4px) translateX(-1.5px);
    }
  }
`;
