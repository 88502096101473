import { RequestMethod } from 'enums';
import { utils } from 'ethers';

export const numberToBigEndian = (input: number) => {
  const arr32 = new Uint32Array(1);
  const arr8 = new Uint8Array(arr32.buffer);
  arr32[0] = input;
  arr8.reverse();
  const bigEndian = new Uint8Array(8);
  bigEndian[4] = arr8[0];
  bigEndian[5] = arr8[1];
  bigEndian[6] = arr8[2];
  bigEndian[7] = arr8[3];
  return bigEndian;
};
export interface DigestSignature {
  r: string;
  s: string;
  recoveryParam: number;
}

export const digestToGoSignature = (s: DigestSignature) => {
  return `${s.r}${s.s.slice(2)}0${s.recoveryParam.toString()}`;
};

export const jsToGoSignature = (s: string) => {
  const rawSignature = utils.splitSignature(s);
  return digestToGoSignature(rawSignature);
};

export interface FrontendSecrets {
  profile: {
    api_keys: {
      exp: number;
      secret: string;
      tag: string;
    };
    balance: number;
    id: string;
    lock: boolean;
    random_secret: string;
    refresh_token: string;
    status: string;
    type: string;
    wallet: string;
  };
  jwt: string;
  randomSecret: string;
  refreshToken: string;
}

export interface PayloadDic {
  key: string;
  value: string;
}

// export const RefreshJwtParamsToPayload = (
//   params: RefreshJwtParams,
// ): PayloadDic[] => {
//   let verifyPayload: PayloadDic[] = [];
//   verifyPayload = [{ key: 'RefreshToken', value: params.refresh_token }];

//   return verifyPayload;
// };

export const NewPayload = <T>(
  timestamp: number,
  method: RequestMethod,
  endpoint: string,
  params: any,
): PayloadDic[] => {
  let verifyPayload: PayloadDic[] = [];
  verifyPayload = Object.keys(params).map((key: string) => ({
    key: key,
    value: params[key],
  }));

  verifyPayload = [
    ...verifyPayload,
    {
      key: 'method',
      value: method,
    },
    {
      key: 'path',
      value: endpoint,
    },
  ];

  return verifyPayload;
};

export const PayloadHash = (timestamp: number, v: PayloadDic[]) => {
  let message = '';

  const sortedPayloadKeys = v.sort((a, b) =>
    a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
  );

  sortedPayloadKeys.forEach(p => {
    if (Array.isArray(p.value)) {
      // For arrays, we'll join them with "," and surround with escaped quotes
      message += `${p.key}=[\"${p.value.join('","')}\"]`;
    } else {
      message += `${p.key}=${p.value}`;
    }
  });

  const messageWithTS = `${message}${timestamp}`;
  const value = utils.toUtf8Bytes(messageWithTS);
  return utils.sha256(value);
};

export const RBTSignature = (payload: string, randomSecret: string) => {
  // @dev: site to validate https://www.devglan.com/online-tools/hmac-sha256-online
  // The RBT-SIGNATURE is a SHA-256 HMAC produced as described below, and encoded as a `Base64` string.

  // A SHA-256 HMAC is created using the API key `secret` and the message `timestamp + method + requestPath + body` defined as follows:

  // - `requestPath`: The API endpoint path, beginning with `/`orders.
  // - `timestamp`: The RBT-TIMESTAMP header
  // - `method`: The name of the HTTP method used, uppercase (e.g. POST).
  // - `body`: The HTTP request body (normally empty for `GET` and `DELETE`).

  // The HMAC should be encoded as a Base64 string and sent as the RBT-SIGNATURE header.

  const hash = utils.computeHmac(
    utils.SupportedAlgorithm.sha256,
    randomSecret,
    payload,
  );
  return hash;
};
