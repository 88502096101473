import { useContext } from 'react';

import { AccountContext, EmptyAccount } from '../contexts/AccountContext';

export const useAccount = (throwError: boolean = true) => {
  const context = useContext(AccountContext);
  if (context) {
    return context;
  }
  if (throwError) {
    throw Error('Context Provider is not provided!');
  }
  return EmptyAccount;
};
