import { useCallback } from 'react';

import { showNotification } from 'utils';
import {
  CreateNewKeyParams,
  DeleteApiKeyParams,
  RefreshApiKeyParams,
} from 'utils/api';

import { useExchangeAPI } from './useExchangeAPI';
import { API_MAPPINGS } from 'constants/apiMappings';
import { ApiKey } from 'interfaces/apiKey';

import { Endpoints, NotificationType, QueryKeys, RequestMethod } from 'enums';
import { useQueryClient } from 'react-query';

export function useApiKeysAPI() {
  const { makePrivateRequest } = useExchangeAPI();
  const queryClient = useQueryClient();

  const createNewApiKey = useCallback(
    async (params: CreateNewKeyParams): Promise<ApiKey> => {
      try {
        const { data: apiKey }: { data: ApiKey } = await makePrivateRequest({
          method: RequestMethod.POST,
          endpoint: `/${Endpoints.SECRETS}`,
          requestParams: params,
          responseMapping: API_MAPPINGS.API_KEY,
          paramsMapping: API_MAPPINGS.CREATE_API_KEY_PARAMS_MAPPING,
          shouldSignMetamaskMessage: true,
          signMetamaskMessageNotifDescription:
            'Please sign the metamask message to create new API key',
        });

        queryClient.removeQueries(QueryKeys.ApiKeys);

        showNotification({
          title: 'New API Key Created',
          description: `New API key with tag ${apiKey.apiSecret.tag} created`,
          type: NotificationType.Positive,
        });

        return apiKey;
      } catch (e: any) {
        console.error(e.message);
        showNotification({
          title: 'Error creating API key',
          description:
            'There was an error creating your API key. Please try again.',
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [makePrivateRequest, queryClient],
  );

  const deleteApiKey = useCallback(
    async (params: DeleteApiKeyParams): Promise<boolean> => {
      try {
        await makePrivateRequest({
          method: RequestMethod.DELETE,
          endpoint: `/${Endpoints.SECRETS}`,
          requestParams: params,
          responseMapping: API_MAPPINGS.API_KEY,
          paramsMapping: API_MAPPINGS.DELETE_API_KEY_PARAMS_MAPPING,
          shouldSignMetamaskMessage: true,
          signMetamaskMessageNotifDescription:
            'Please sign the metamask message to delete the API key',
        });

        queryClient.invalidateQueries(QueryKeys.ApiKeys);

        showNotification({
          title: 'Deleted API Key',
          description: `API key deleted successfully`,
          type: NotificationType.Positive,
        });

        return true;
      } catch (e: any) {
        console.error(e.message);
        showNotification({
          title: 'Error deleting API key',
          description:
            'There was an error deleting your API key. Please try again.',
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [makePrivateRequest, queryClient],
  );

  const refresh = useCallback(
    async (params: RefreshApiKeyParams): Promise<ApiKey> => {
      try {
        const { data: apiKey }: { data: ApiKey } = await makePrivateRequest({
          method: RequestMethod.POST,
          endpoint: `/${Endpoints.SECRETS}/refresh`,
          requestParams: params,
          responseMapping: API_MAPPINGS.API_KEY,
          paramsMapping: API_MAPPINGS.REFRESH_API_KEY_PARAMS_MAPPING,
          shouldSignMetamaskMessage: true,
          signMetamaskMessageNotifDescription:
            'Please sign the metamask message to refresh the API key',
        });

        queryClient.invalidateQueries(QueryKeys.ApiKeys);

        showNotification({
          title: 'Refreshed API Key',
          description: `API key ${apiKey.apiSecret.tag} refreshed successfully`,
          type: NotificationType.Positive,
        });

        return apiKey;
      } catch (e: any) {
        console.error(e.message);
        showNotification({
          title: 'Error refreshing API key',
          description:
            'There was an error refreshing your API key. Please try again.',
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [makePrivateRequest, queryClient],
  );

  const fetchApiKeys = useCallback(async (): Promise<ApiKey[]> => {
    try {
      const { data }: { data: ApiKey[] } = await makePrivateRequest({
        method: RequestMethod.GET,
        endpoint: `/${Endpoints.SECRETS}`,
        requestParams: {},
        responseMapping: API_MAPPINGS.API_KEY,
        isArray: true,
        shouldCheckJwtValidity: false,
        shouldSignMetamaskMessage: true,
        signMetamaskMessageNotifDescription:
          'Please sign the metamask message to fetch your API keys',
      });

      return data.sort((a: ApiKey, b: ApiKey) => {
        return a.createdAt - b.createdAt;
      });
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  }, [makePrivateRequest]);

  return {
    createNewApiKey,
    deleteApiKey,
    fetchApiKeys,
    refresh,
  } as const;
}

export type ApiKeysAPI = ReturnType<typeof useApiKeysAPI>;
