import { memo, useEffect } from 'react';
import React from 'react';

import { useApiKeysAPI } from 'hooks';

import Button from 'components/Button';
import Loading from 'components/Loading';

import { Container, ExpiredLabel } from './styles';
import deleteIcon from 'assets/icons/trash.svg';
import { ApiKey } from 'interfaces/apiKey';

import { flushSync } from 'react-dom';

/**
 * Generates a string in the format of api-YYYY-MM-DD-HH-MM
 * @returns {string} api-2021-09-09-12-00
 */
export const generateApiString = (): string => {
  const now = new Date();

  const year = now.getFullYear();

  const month = String(now.getMonth() + 1).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `api-${year}-${month}-${seconds}`;
};

export const isKeyExpired = (
  currentTimestamp: number,
  expirationTimestamp: number,
): boolean => {
  return currentTimestamp > expirationTimestamp;
};

type Props = {
  newKeyTag: string | null;
  setNewKeyTag: (value: string | null) => void;
  apiKeys: ApiKey[];
  selectedKey: ApiKey | null;
  onHandleSelectKey: (key: ApiKey | null) => void;
  isLoading?: boolean;
};
const ApiKeysList = ({
  newKeyTag,
  setNewKeyTag,
  apiKeys,
  onHandleSelectKey,
  selectedKey,
  isLoading = false,
}: Props) => {
  const { deleteApiKey } = useApiKeysAPI();

  const [deletingApiKey, setDeletingApiKey] = React.useState<string | null>(
    null,
  );

  const newKeyTagRef = React.useRef<HTMLInputElement>(null);

  const focusNewKeyTagInput = () => {
    newKeyTagRef.current?.focus();
  };

  const handleCreateKey = () => {
    if (newKeyTag === null) {
      setNewKeyTag(generateApiString());
      onHandleSelectKey(apiKeys ? apiKeys[0] : null);
    }
    focusNewKeyTagInput();
  };

  const handleNewKeyTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewKeyTag(e.target.value);
  };

  const handleSelectKey = (key: ApiKey) => {
    onHandleSelectKey(key);
    setNewKeyTag(null);
  };

  const handleDeleteKey = async (key: string) => {
    try {
      flushSync(() => {
        setDeletingApiKey(key);
      });
      await deleteApiKey({
        key,
      });
    } catch (e: any) {
      console.log('An error occurrd when deleting API key.', e);
    } finally {
      setDeletingApiKey(null);
    }
  };

  useEffect(() => {
    if (newKeyTag !== null) {
      focusNewKeyTagInput();
    }
  }, [newKeyTag]);

  const currentTimestamp = Date.now() / 1000;

  return (
    <Container>
      <div className="header">🔑 Your API Keys</div>
      <div className="keys-list-container">
        <ul className="keys-list">
          {isLoading && (
            <div className="flex justify-center mt-10">
              <Loading />
            </div>
          )}
          {apiKeys &&
            apiKeys.map(apiKey => {
              const {
                apiSecret: { tag, expiration, key },
              } = apiKey;
              return (
                <li
                  className={`key ${
                    newKeyTag === null &&
                    key === selectedKey?.apiSecret.key &&
                    'selected'
                  }`}
                  key={key}
                  onClick={() => handleSelectKey(apiKey)}
                >
                  <div className="expired-and-name">
                    {isKeyExpired(currentTimestamp, expiration) && (
                      <ExpiredLabel>Expired</ExpiredLabel>
                    )}
                    <div className="key-name">{tag}</div>
                  </div>
                  <div className="key-edit">
                    {deletingApiKey === key ? (
                      <Loading />
                    ) : (
                      <img
                        src={deleteIcon}
                        alt="Delete"
                        onClick={() => handleDeleteKey(key)}
                      />
                    )}
                  </div>
                </li>
              );
            })}

          {newKeyTag !== null && (
            <li className={`key ${newKeyTag !== null && 'selected'}`}>
              <div className="expired-and-name">
                <div className="key-name">
                  <input
                    ref={newKeyTagRef}
                    type="text"
                    value={newKeyTag}
                    onChange={handleNewKeyTagChange}
                    className="key-name-input"
                    placeholder="Enter API key tag name"
                  />
                </div>
              </div>
              <div className="key-edit">
                <img
                  src={deleteIcon}
                  alt="Delete"
                  onClick={() => setNewKeyTag(null)}
                />
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className="create-key-container">
        <Button
          variant="secondaryDefault"
          size="small"
          onClick={handleCreateKey}
        >
          Create Key +
        </Button>
      </div>
    </Container>
  );
};

export default memo(ApiKeysList);
