import { IS_ROUTE_TV_MOBILE_KEY } from './helpers';
import { capitalizeFirstLetter } from './textFormatters';
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import arrowUpIcon from 'assets/icons/arrow-up.svg';
import negativeIcon from 'assets/icons/notifications-negative.svg';
import neutralIcon from 'assets/icons/notifications-neutral.svg';
import positiveIcon from 'assets/icons/notifications-positive.svg';
import i18n from 'i18n/config';

import { NotificationType } from 'enums';
import { Notification } from 'interfaces';
import { enqueueSnackbar } from 'notistack';

export interface StatusBasedStyles {
  background: string;
  title: string;
  icon: string;
}
export const getStyles = (
  theme: any,
  notificationType: NotificationType,
): StatusBasedStyles => {
  switch (notificationType) {
    case NotificationType.Positive: {
      return {
        background:
          'linear-gradient(90deg, rgba(13, 203, 146, 0.4) 0%, rgba(13, 203, 146, 0) 100.31%), #202839',
        title: theme.colors.positiveForeground200,
        icon: positiveIcon,
      };
    }
    case NotificationType.PositivePriceChange: {
      return {
        background:
          'linear-gradient(90deg, rgba(13, 203, 146, 0.4) 0%, rgba(13, 203, 146, 0) 100.31%), #202839',
        title: theme.colors.positiveForeground200,
        icon: arrowUpIcon,
      };
    }
    case NotificationType.Negative: {
      return {
        background:
          'linear-gradient(90deg, rgba(218, 83, 83, 0.4) 0%, rgba(218, 83, 83, 0) 100.31%), #202839',
        title: theme.colors.negativeForeground200,
        icon: negativeIcon,
      };
    }
    case NotificationType.NegativePriceChange: {
      return {
        background:
          'linear-gradient(90deg, rgba(218, 83, 83, 0.4) 0%, rgba(218, 83, 83, 0) 100.31%), #202839',
        title: theme.colors.negativeForeground200,
        icon: arrowDownIcon,
      };
    }
    case NotificationType.Info: {
      return {
        background:
          'linear-gradient(90deg, rgba(246, 225, 152, 0.4) 0%, rgba(246, 225, 152, 0) 100.31%), #202839',
        title: theme.colors.warningForeground100,
        icon: neutralIcon,
      };
    }
    case NotificationType.Neutral: {
      return {
        background: theme.colors.shadesBackground800,
        title: theme.colors.warningForeground100,
        icon: neutralIcon,
      };
    }
    default: {
      return {
        background: theme.colors.shadesBackground800,
        title: theme.colors.warningForeground100,
        icon: neutralIcon,
      };
    }
  }
};

export const notifications = {
  connectionToDataReEstablished: (channel: string) => {
    console.info('CONNECTION RESTORED : ', channel);
    // showNotification(
    //   {
    //     title: i18n.t('connectionToDataReEstablishedTitle', {
    //       channel,
    //     }),
    //     description: i18n.t('connectionToDataReEstablishedDescription', {
    //       channel,
    //     }),
    //     type: NotificationType.Positive,
    //   },
    //   true,
    // );
  },
  couldNotReEstablishConnectionToData: (channel: string) => {
    console.info('CONNECTION COULD NOT RESTABLISH : ', channel);
    // showNotification(
    //   {
    //     title: i18n.t('couldNotReEstablishConnectionToDataTitle', {
    //       channel,
    //     }),
    //     description: i18n.t('couldNotReEstablishConnectionToDataDescription', {
    //       channel,
    //     }),
    //     type: NotificationType.Negative,
    //   },
    //   true,
    // );
  },
  dataConnectionLost: (channel: string) => {
    console.info('CONNECTION LOST : ', channel);
    // showNotification(
    //   {
    //     title: i18n.t('dataConnectionLostTitle', {
    //       channel: capitalizeFirstLetter(channel),
    //     }),
    //     description: i18n.t('dataConnectionLostDescription', {
    //       channel: capitalizeFirstLetter(channel),
    //     }),
    //     type: NotificationType.Negative,
    //   },
    //   true,
    // );
  },
};

/**
 * Shows a notification and prevents duplicates when desired.
 * @param notification Notification fields (type, title, description)
 * @param preventDuplicate Prevents duplicates when true by not assigning a random number to the notification message ID
 */
export const showNotification = (
  notification: Notification,
  preventDuplicate = false,
  autoHideDuration?: number | null | undefined,
) => {
  const isRouteTVMobile = localStorage.getItem(IS_ROUTE_TV_MOBILE_KEY);
  if (isRouteTVMobile === 'true') return;

  const { title, type, description } = notification;

  let messageId: string = title;
  if (!preventDuplicate) {
    messageId = `${title}-${Math.random()}`;
  }

  enqueueSnackbar(messageId, {
    variant: 'customToast',
    title: capitalizeFirstLetter(title),
    description,
    type,
    autoHideDuration: autoHideDuration !== undefined ? autoHideDuration : 5000,
  });
};
