import { memo } from 'react';

import { basisToPercent } from 'utils';

import { FormattedNumber } from 'components';

import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  gap: 8px;
  background: #171d29;
  border: 1px solid #283247;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 5;

  .price {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -2px;
    color: #ffffff;
  }
  .date {
    margin-top: 8px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 14px;
    line-height: 16px;
    color: #636e85;
  }
`;

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Container>
        <span className="price">
          {payload[0].dataKey === 'fundingRate' ? (
            <FormattedNumber
              value={basisToPercent(payload[0].value) ?? 0}
              suffix={'%'}
              decimalScale={7}
            />
          ) : (
            <FormattedNumber value={payload[0].value} prefix="$" />
          )}
        </span>
        <span className="date">{new Date(label / 1000).toLocaleString()}</span>
      </Container>
    );
  }

  return null;
};

export default memo(CustomTooltip);
