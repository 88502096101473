import Text, { TextProps } from 'components/Text';

import { StyledTableCellProps, StyledTableCell } from '../styles';
import { Column } from 'theme/globalStyledComponents/column';

import styled from 'styled-components';

type StyledColumnProps = {
  limitWidth?: boolean;
};
const StyledColumn = styled(Column)<StyledColumnProps>`
  height: 35.5px;
  min-width: ${({ limitWidth }) => (limitWidth ? '110px' : 'initial')};

  @media (min-width: ${({ theme }) => theme.screenSizes.xxl}px) {
    min-width: ${({ limitWidth }) => (limitWidth ? '150px' : 'initial')};
  }
`;

const Container = ({ children, isCell, ...rest }) =>
  isCell ? (
    <StyledTableCell {...rest}>{children}</StyledTableCell>
  ) : (
    <>{children}</>
  );

export type LabelAndValueCellProps = {
  label: string;
  value: React.ReactNode;
  showLabel: boolean;
  alignRight?: boolean;
  isCell?: boolean;
  className?: string;
} & Pick<TextProps, 'color'> &
  StyledTableCellProps &
  StyledColumnProps;
const LabelAndValueCell = ({
  label,
  value,
  color,
  alignRight,
  showLabel,
  isCell = true,
  className,
  limitWidth,
  ...rest
}: LabelAndValueCellProps) => {
  return (
    <Container isCell={isCell} {...rest}>
      <StyledColumn
        gap={2}
        align={alignRight ? 'flex-end' : undefined}
        className={className}
        limitWidth={limitWidth}
      >
        {showLabel ? (
          <Text
            responsiveVariant={{ medium: 'BODY_S', default: 'BODY_M' }}
            color="shadesForeground200"
          >
            {label}
          </Text>
        ) : null}
        <Text
          responsiveVariant={{ medium: 'BODY_S', default: 'BODY_M' }}
          color={color}
          flexed
          gap={2}
        >
          {value}
        </Text>
      </StyledColumn>
    </Container>
  );
};

export default LabelAndValueCell;
