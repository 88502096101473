import { memo, useRef } from 'react';

import { brand } from 'utils/brand';

import RewardCard from '../../RewardCard';
import { CardStatus, CardVariant } from '../../RewardCard/enums';
import { Container } from './styles';

import { ReferralLevel } from 'interfaces';

const ClaimedStack = ({
  level,
  volume,
  comissionBonus,
  comissionPercent,
}: ReferralLevel) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleCardClick = () => {
    const card = containerRef.current;
    if (!card) return;

    if (card.classList.contains('flipped')) {
      card.classList.remove('flipped');
      card.classList.add('unflipped');
    } else {
      card.classList.remove('unflipped');
      card.classList.add('flipped');
    }
  };

  return (
    <Container onClick={handleCardClick} ref={containerRef}>
      <RewardCard
        variant={CardVariant.LOCKED}
        level={level}
        goal={volume}
        status={CardStatus.INFO}
        containerClass="card-front"
      />
      <RewardCard
        variant={CardVariant.LOCKED}
        status={CardStatus.INFO}
        containerClass="card-back"
      >
        <div className="content">
          <div>
            <span className="text">
              <img
                className="icon-usdt"
                src={brand.tokenLogo}
                alt={brand.tokenTicker}
              />
              {comissionBonus}
            </span>
            <span className="text-s">Bonus Reward</span>
          </div>
          <div>
            <span className="text">{comissionPercent}%</span>
            <span className="text-s">Commission</span>
          </div>
        </div>
      </RewardCard>
    </Container>
  );
};

export default memo(ClaimedStack);
