import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.shadesBackground700};

  .content {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    gap: 10px;
  }

  .row {
    padding: 0px 14px;
    display: flex;
    justify-content: space-between;

    > span {
      display: flex;
      align-items: center;
      gap: 3px;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.shadesForeground200};
      font-size: 12px;
      line-height: 14px;

      > img {
        height: 12px;
        width: 12px;
      }
    }
  }

  .cursor-blocked {
    cursor: not-allowed;
  }

  .edit-icon {
    height: 12px;
    width: 12px;
    padding: 1px;
    box-sizing: border-box;
  }
`;
