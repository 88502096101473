import { brandedSelect } from 'utils/brand';

export const twitterUrl = brandedSelect({
  bfx: 'https://x.com/blastfutures',
  rabbitx: 'https://x.com/rabbitx_io',
});

export const discordUrl = brandedSelect({
  bfx: 'https://discord.com/invite/blastfutures',
  rabbitx: 'https://discord.com/invite/rabbitx',
});
