import { getDaysSinceDate } from 'utils';

import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import { elixirVaultsConstants } from './Pages/Vaults/TabPanels/ElixirVaults/constants';
import { routes } from 'pages/routes';

import { NavigateFunction } from 'react-router-dom';

export const navigateToDetailedVault = (
  navigate: NavigateFunction,
  vaultId: number,
) =>
  navigate({
    pathname: routes.vaults.overview.getRoutePath(),
    search: new URLSearchParams({ id: vaultId.toString() }).toString(),
  });

export const navigateToDetailedPlatformVault = (
  navigate: NavigateFunction,
  vaultWallet: string,
) =>
  navigate({
    pathname: routes.vaults.platformOverview.getRoutePath(),
    search: new URLSearchParams({ vault_wallet: vaultWallet }).toString(),
  });

/**
 * Calculates the current Price Per Share (PPS) for a vault.
 * @param vault - Vault information including TVL (Total Value Locked) in USD and active amount.
 * @returns The current Price Per Share (PPS) for the given vault.
 */
export const getCurrentPps = (vault: VaultWithContractInfo) => {
  if (!vault.activeAmount || !vault.tvlUsd) return 1;

  return vault.tvlUsd / vault.activeAmount;
};

/**
 * Calculates the total APY (Annual Percentage Yield) by summing the rewards APY and USDT APY.
 * @param rewardsApy - APY from rewards or yield farming.
 * @param usdtApy - USDT APY for the vault.
 * @returns The total calculated APY.
 */
export const getTotalApy = (rewardsApy: number, usdtApy: number) => {
  // if the USDT APY is negative, return the rewards APY
  if (rewardsApy + usdtApy < rewardsApy) return rewardsApy;

  return rewardsApy + usdtApy;
};

/**
 * Calculates the USDT APY for a vault.
 * @param vault - Vault information including TVL (Total Value Locked) in USD and active amount.
 * @returns The USDT APY for the given vault.
 */
export const getUsdtApyForVault = (
  vault: VaultWithContractInfo,
  currentDate: Date,
) => {
  try {
    const currentPps = getCurrentPps(vault);
    const currentVaultConstants = elixirVaultsConstants[vault.elixirPairId];
    const ppsAtInception = currentVaultConstants.inceptionPricePerShare;
    const daysSinceInception = getDaysSinceDate(
      currentVaultConstants.inceptionDate,
      currentDate,
    );

    // usdt apy: (current PPS - pps at inception which is 1) / 1 * (365 / days since inception)
    const usdtApy =
      ((currentPps - ppsAtInception) / ppsAtInception) *
      (365 / daysSinceInception);

    return usdtApy;
  } catch (err) {
    console.log('Error in getUsdtApyForVault', vault.elixirPairId, err);
    throw err;
  }
};

/**
 * Calculates the total APY (Annual Percentage Yield) for a vault. The total APY is the sum of the rewards APY and USDT APY.
 * @param vault - Vault information including TVL (Total Value Locked) in USD, total shares issued, and wallet shares.
 * @returns The total APY for the given vault.
 */
export const getTotalApyForVault = (vault: VaultWithContractInfo) => {
  const usdtApy = getUsdtApyForVault(vault, new Date());

  return getTotalApy(vault.apy, usdtApy);
};

/**
 * Calculates the Net Asset Value (NAV) for a vault.
 * @param vault - Vault information including TVL (Total Value Locked) in USD, total shares issued, and wallet shares.
 * @returns The Net Asset Value (NAV) for the given vault.
 */
export const getNAV = (vault: VaultWithContractInfo) => {
  if (!vault.walletShares) return 0;

  return (vault.tvlUsd / vault.activeAmount) * vault.walletShares;
};

/**
 * Checks if a vault has a hard cap. A vault has a hard cap if the hard cap is less than 1e50.
 * @param hardCap - The hard cap of the vault.
 * @returns True if the vault has a hard cap, false otherwise.
 */
export const hasHardCap = (hardCap: number): boolean => {
  const veryLargeHardCapThreshold = 1e50;

  return hardCap <= veryLargeHardCapThreshold;
};

export const getPositivePPS = (pps: number | undefined) => {
  if (!pps) return 1;
  return pps > 0 ? pps : 1;
};
