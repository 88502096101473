import AppStore from './app';

import { action, makeAutoObservable, observable } from 'mobx';

export default class CentrifugeStore {
  isPublicDisconnected: boolean = false;
  isPublicConnInRecovery: boolean = false;
  isPrivateDisconnected: boolean = false;
  isPrivateConnInRecovery: boolean = false;

  constructor(private store: AppStore) {
    makeAutoObservable(this, {
      isPublicDisconnected: observable,
      isPublicConnInRecovery: observable,
      isPrivateDisconnected: observable,
      isPrivateConnInRecovery: observable,
      setIsPublicDisconnected: action,
      setIsPrivateDisconnected: action,
      setIsPublicConnInRecovery: action,
      setIsPrivateConnInRecovery: action,
    });
  }

  setIsPublicDisconnected(isDisconnected: boolean) {
    this.isPublicDisconnected = isDisconnected;
  }

  setIsPrivateDisconnected(isDisconnected: boolean) {
    this.isPrivateDisconnected = isDisconnected;
  }

  setIsPublicConnInRecovery(isPublicConnInRecovery: boolean) {
    this.isPublicConnInRecovery = isPublicConnInRecovery;
  }

  setIsPrivateConnInRecovery(isPrivateConnInRecovery: boolean) {
    this.isPrivateConnInRecovery = isPrivateConnInRecovery;
  }
}
