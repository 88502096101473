import { BodyContainer, HeaderContainer } from '../shared';
import chevronDown from 'assets/icons/chevronDown.svg';
import chevronUp from 'assets/icons/chevronUpYellow.svg';

import styled from 'styled-components';

const DEFAULT_LABEL = 'Type';

const InputStyled = styled.input`
  pointer-events: none;
`;

interface Props {
  showHeader?: boolean;
  label?: string;
  value: string;
  isDropdownShown: boolean;
}
export const DropdownInput = ({
  showHeader,
  label = DEFAULT_LABEL,
  value,
  isDropdownShown,
}: Props) => {
  return (
    <div className={'content'}>
      {showHeader ? (
        <HeaderContainer>
          <label>{label ? label : ''}</label>
        </HeaderContainer>
      ) : null}
      <BodyContainer isFocused={isDropdownShown}>
        <InputStyled
          type="text"
          value={value}
          disabled
          placeholder="Select Item"
        />
        <img
          src={isDropdownShown ? chevronUp : chevronDown}
          alt="Chevron Icon"
        />
      </BodyContainer>
    </div>
  );
};

export default DropdownInput;
