import { CardStatus } from './enums';
import Noised from 'assets/images/noise.png';

import styled from 'styled-components';

type ContainerProps = {
  gradientColors?: string[];
  borderColor?: string;
};

export const Container = styled.div<ContainerProps>`
  width: 123px;
  height: 144px;
  display: flex;
  flex-shrink: 0;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground900};
  background: linear-gradient(
    ${({ gradientColors }) =>
      gradientColors
        ? `147deg, ${gradientColors[0]} -0.1%, ${gradientColors[1]} 100.1%`
        : '150deg, #0A0E18 0%, #111725 52.08%, #181F31 100%, #181F31 100%'}
  );

  .noised {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    opacity: 0.04;
    background: url(${Noised}),
      lightgray 0% 0% / 31.615382432937622px 31.615382432937622px repeat;
    mix-blend-mode: overlay;
    position: absolute;
  }

  .vector {
    width: 90px;
    height: 90px;
    object-fit: contain;
  }

  .label {
    white-space: pre-wrap;
    text-align: center;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;

type GoalProps = {
  minimalIcon?: boolean;
};
export const Goal = styled.span<GoalProps>`
  background: rgba(35, 48, 74, 0.5);
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 5px;
  border: 0.2px solid #304464;
  gap: 3px;
  backdrop-filter: blur(2px);
  margin: 10px 0px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  .icon {
    height: ${({ minimalIcon }) => (minimalIcon ? '14px' : '16px')};

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      height: ${({ minimalIcon }) => (minimalIcon ? '12px' : '14px')};
    }
  }
`;

type StatusProps = {
  status: CardStatus;
};
export const Status = styled.div<StatusProps>`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: ${({ status }) => (status === CardStatus.INFO ? '6px' : '5px')};
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  backdrop-filter: blur(2px);
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  border: 0.2px solid
    ${({ status }) => (status === CardStatus.CLAIMED ? '#1b3929' : ' #374365')};
  background: ${({ status }) =>
    status === CardStatus.CLAIMED
      ? 'rgba(35, 74, 41, 0.5)'
      : ' rgba(35, 48, 74, 0.5);'};

  @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
    top: 8px;
    right: 8px;
  }

  .info-icon {
    height: 16px;
    width: 16px;
    stroke: ${({ theme }) => theme.colors.white};

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      height: 12px;
      width: 12px;
    }
  }
`;
