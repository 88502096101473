import { brand } from 'utils/brand';

import Text from 'components/Text';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  span:last-child {
    display: flex;
    align-items: center;
    gap: 5px;

    > span {
      display: flex;
      padding: 4px 6px;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      border-radius: 100px;
      background: ${({ theme }) => theme.colors.shadesBackground700};

      .icon-container {
        display: flex;
        padding: 3px;
        background: ${({ theme }) => theme.colors.shadesBackground800};
        border-radius: 100px;

        > img {
          height: 8px;
          width: 8px;
        }
      }
    }
  }
`;

const DOCS_URL = brand.docsLink;

const onDocsClick = () => {
  window.open(DOCS_URL, '_blank');
};

const Header = () => {
  const { t } = useTranslation();

  return (
    <HeaderContainer>
      <Text variant="HEADING_H2" fontWeight="semiBold">
        {t('stakeAndStartEarning')}
      </Text>
      <Text variant="BODY_M">
        {t('learnMoreAboutExchangeRewardsProgramInOur', {
          exchange: brand.appShortName,
        })}
        <span onClick={onDocsClick}>{t('docs')}</span>
      </Text>
    </HeaderContainer>
  );
};

export default Header;
