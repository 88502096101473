import { useEffect, useState } from 'react';

import { pushDataLayerEvent } from 'utils';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';

import { FormattedNumber } from 'components';
import { TextVariant } from 'components/Text/variant';

import { EditOrdersIcon, CancelOrdersIcon } from './OrdersActionIcon';
import { DEFAULT_DECIMAL_SCALE } from 'constants/general';
import { theme } from 'theme/Theme';
import { Row } from 'theme/globalStyledComponents';

import { OrderbookSide } from 'enums';
import { CurrencyKind, Order, OrderbookOrder } from 'interfaces';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import styled, { css } from 'styled-components';

interface OrderRowProps {
  isBold?: boolean;
  backgroundColor: string;
  side: OrderbookSide;
  isEmptyRow?: boolean;
  isFirstRow?: boolean;
  textColor: { price: string; default: string };
}
const OrderRowStyled = styled.div<OrderRowProps>`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  height: 17px;
  gap: 10px;

  font-weight: ${({ isBold, theme }) =>
    isBold ? theme.fontWeights.semiBold : theme.fontWeights.medium};
  cursor: pointer;

  :hover {
    background-color: rgba(192, 203, 225, 0.1);
    border-radius: 4px;
  }

  .row {
    display: flex;
    gap: 5px;
  }

  .indicator {
    height: 4px;
    width: 4px;
    margin: 0px 4px;
    border-radius: 2px;
    background: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .text-right {
    text-align: right;
  }

  > div {
    width: 33.33%;
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    height: 100%;

    > span {
      padding: 0px 2px;
      width: 100%;
      height: 100%;
      /* overflow: hidden; */
      display: flex;
      align-items: center;
      color: ${({ textColor }) => textColor.default};
    }

    :first-child > span {
      text-align: left;
      background: ${({ backgroundColor }) => backgroundColor};
      border-radius: 4px;
      color: ${({ textColor }) => textColor.price};
      ${({ isFirstRow }) =>
        isFirstRow &&
        css`
          font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        `}
    }

    :last-child {
      z-index: 3;
      display: flex;
      position: relative;
      > span {
        width: 100%;
        position: relative;
      }
    }
  }
`;

interface DepthVisualiserProps {
  bgColor: string;
  width: number;
}
const DepthVisualiserStyled = styled.div<DepthVisualiserProps>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 100%;
  width: ${({ width }) => width}%;
  background-color: ${({ bgColor }) => bgColor};
`;

// export const getPriceBackgroundColor = (
//   orderSide: OrderbookSide | undefined,
//   isFirstRow: boolean | undefined,
// ): string => {
//   if (!isFirstRow) return 'transparent';

//   return orderSide === OrderbookSide.Ask ? '#472233' : '#113A42';
// };

export const getTextColor = (
  hasChanged: boolean,
  orderSide: OrderbookSide | undefined,
) => {
  if (!hasChanged || !orderSide)
    return {
      price: orderSide === OrderbookSide.Ask ? '#CC3548' : '#009978',
      default: theme.colors.shadesForeground200,
    };

  const activeColor = orderSide === OrderbookSide.Ask ? '#FF475D' : '#19EAAB';

  return { price: activeColor, default: activeColor };
};

export const getDepthVisualiserBgColor = (
  orderSide: OrderbookSide | undefined,
) => {
  if (!orderSide) return 'transparent';

  return orderSide === OrderbookSide.Ask ? '#321E2C' : '#12292E';
};

interface Props {
  order?: OrderbookOrder;
  isEmptyRow?: boolean;
  orderSide?: OrderbookSide;
  isFirstRow?: boolean;
  isHighlighted?: boolean;
  currency?: CurrencyKind;
  marketID: string;
  setOrderInputPrice: (price: number) => void;
  isOrderEntryPopUpShown: boolean;
  setIsOrderEntryPopUpShown: (isShown: boolean) => void;
  setOrderbookOrderEntryPrice: (price: number) => void;
  ordersForCurrentPriceLevel: Order[];
  cancelOrders: (o: Order[]) => Promise<void>;
  orderbookOrderEntryPrice: number | null;
  currentlyCancellingOrderIds: string[];
  cancelOrder: (order: Order) => void;
}
const OrderRow = ({
  order: { price, quantity, total, hasChanged, depthPercentage } = {
    price: 0,
    quantity: 0,
    total: 0,
    hasChanged: false,
    depthPercentage: 0,
  },
  isEmptyRow,
  orderSide,
  isFirstRow,
  currency,
  marketID,
  setOrderInputPrice,
  setIsOrderEntryPopUpShown,
  setOrderbookOrderEntryPrice,
  ordersForCurrentPriceLevel,
  isOrderEntryPopUpShown,
  cancelOrders,
  orderbookOrderEntryPrice,
  currentlyCancellingOrderIds,
  cancelOrder,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [textColor, setTextColor] = useState(
    getTextColor(hasChanged, orderSide),
  );
  const priceBackgroundColor = isFirstRow
    ? getDepthVisualiserBgColor(orderSide)
    : 'transparent';
  const depthVisualiserBgColor = getDepthVisualiserBgColor(orderSide);

  quantity = currency === CurrencyKind.BASE ? quantity : quantity * price;
  total = currency === CurrencyKind.BASE ? total : total * price;

  const decimalScales = useGetDecimalPlaces(marketID);
  const priceDecimalScale = decimalScales.price;
  const sizeDecimalScale =
    currency === CurrencyKind.QUOTE
      ? DEFAULT_DECIMAL_SCALE
      : decimalScales.size;

  useEffect(() => {
    // Set the initial color
    setTextColor(getTextColor(hasChanged, orderSide));
    // Change it back to the default color after 500 ms (the flashing effect)
    const timeout = setTimeout(() => {
      setTextColor(getTextColor(false, orderSide));
    }, 500);
    return () => clearTimeout(timeout);
  }, [hasChanged, orderSide, price, quantity]);

  const handleRowClick = event => {
    if (event.type === 'contextmenu') {
      setOrderInputPrice(price);
      event.preventDefault(); // Prevent the browser's default context menu from showing
      return;
    }
    // Set the price in the order input

    // Only handle right clicks
    if (event.type !== 'click') {
      return;
    }

    if (isOrderEntryPopUpShown && orderbookOrderEntryPrice === price) {
      setIsOrderEntryPopUpShown(false);
      return;
    }

    // GTM event
    pushDataLayerEvent('orderbookRightClickPrice');

    flushSync(() => {
      setIsOrderEntryPopUpShown(true);
      setOrderbookOrderEntryPrice(price);
    });

    const rect = event.target.getBoundingClientRect();
    const popUp = document.getElementById('order-entry-pop-up');
    const rectParent = document.getElementById('asks-and-bids-wrapper');
    rectParent?.getBoundingClientRect();

    if (!popUp || !rectParent) return;

    // Position the pop-up relative to the clicked element
    popUp.style.position = 'absolute';
    popUp.style.top = `${rect.top + rect.height - rectParent.offsetTop + 5}px`;
    // popUp.style.right = `${rect.top + rect.height - rectParent.offsetTop + 5}px`;
  };

  const onCancelOrders = () => {
    cancelOrders(ordersForCurrentPriceLevel);
  };

  return (
    <OrderRowStyled
      side={orderSide!}
      isBold={false}
      backgroundColor={priceBackgroundColor}
      isEmptyRow={isEmptyRow}
      isFirstRow={isFirstRow}
      textColor={textColor}
      onClick={handleRowClick}
      onContextMenu={handleRowClick}
      data-gtmid="price-orderbook"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={TextVariant['BODY_S']}
    >
      <div>
        <span>
          {isEmptyRow ? (
            ''
          ) : (
            <div className="row items-center">
              <FormattedNumber
                value={price}
                decimalScale={priceDecimalScale}
                disableTooltip={true}
              />
              {ordersForCurrentPriceLevel.length > 0 ? (
                isHovered ? (
                  <Row gap={5}>
                    <EditOrdersIcon orders={ordersForCurrentPriceLevel} />

                    <CancelOrdersIcon
                      cancelAllOrders={onCancelOrders}
                      handleCancelOrder={cancelOrder}
                      orders={ordersForCurrentPriceLevel}
                      currentlyCancellingOrderIds={currentlyCancellingOrderIds}
                    />
                  </Row>
                ) : (
                  <div className="indicator" />
                )
              ) : null}
            </div>
          )}
        </span>
      </div>
      <div>
        <span className="justify-end">
          {isEmptyRow ? (
            ''
          ) : (
            <FormattedNumber
              value={quantity}
              decimalScale={sizeDecimalScale}
              disableTooltip={true}
              // className="text-right"
            />
          )}
        </span>
      </div>
      <div>
        <span className="justify-end">
          {!isEmptyRow ? (
            // <DepthVisualiserStyled
            //   bgColor={depthVisualiserBgColor}
            //   width={depthPercentage}
            // />
            <div
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: -1,
                height: '100%',
                width: depthPercentage + '%',
                backgroundColor: depthVisualiserBgColor,
              }}
            ></div>
          ) : null}
          {isEmptyRow ? (
            ''
          ) : (
            <FormattedNumber
              value={total}
              decimalScale={sizeDecimalScale}
              disableTooltip={true}
            />
          )}
        </span>
      </div>
    </OrderRowStyled>
  );
};

export default observer(OrderRow);
