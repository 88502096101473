import { memo } from 'react';

import { makeABitLessthanOrEqual } from 'utils';
import { MAX_FLOAT_DECIMALS } from 'utils/math';

import { FormattedNumber } from 'components';
import NumberInput from 'components/Inputs/NumberInput';
import Text from 'components/Text';

import { RowBetween } from '../styles';
import arrowRightIcon from 'assets/icons/arrow-right-gray.svg';

import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

type Props = {
  amount: number | null;
  onAmountChange: (value: number | null) => void;
  maxRedeemableShares: number;
  pricePerShare: number;
  disabled: boolean;
};
const UnstakeInputs = ({
  onAmountChange,
  amount,
  maxRedeemableShares,
  pricePerShare,
  disabled,
}: Props) => {
  const { t } = useTranslation();

  const parsedMaxRedeemableShares = makeABitLessthanOrEqual(
    maxRedeemableShares,
    MAX_FLOAT_DECIMALS,
  );

  const balancePostUnstake = parsedMaxRedeemableShares - (amount ?? 0);

  return (
    <>
      <NumberInput
        value={amount}
        onChange={onAmountChange}
        label={t('quantity')}
        showSwapCurrency={false}
        showCurrencyIcon={false}
        currency={t('shares')}
        showValueApproximation={false}
        showError={(amount ?? 0) > parsedMaxRedeemableShares}
        errorMessage={t('notEnoughAvailableShares')}
        maxValue={parsedMaxRedeemableShares}
        disabled={disabled}
      >
        <RowBetween>
          <span>{t('availableShares')}</span>
          <span className="flex gap-2 items-center">
            <FormattedNumber value={parsedMaxRedeemableShares} />
            <img src={arrowRightIcon} alt="->" />
            <FormattedNumber
              value={balancePostUnstake > 0 ? balancePostUnstake : 0}
            />
          </span>
        </RowBetween>
      </NumberInput>
      <Text variant="BODY_S" color="shadesForeground200">
        <Text color="white">{t('disclaimer')}: </Text>
        {t('elixirUnstakeDisclaimer')}
      </Text>
    </>
  );
};

export default memo(UnstakeInputs);
