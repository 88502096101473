import { useCallback, useEffect, useReducer, useState } from 'react';

import { Box } from '@material-ui/core';

import {
  USD_DECIMAL_SCALE,
  getCurrencyFromTradingPair,
  getCurrencyInputValue,
  getMaxOrderUsdValue,
  parseProdDisabled,
  readUserSetting,
  roundToNearestTick,
  numToString,
  showNotification,
} from 'utils';
import { CreateOrderParams } from 'utils/api';
import {
  checkOrderForErrors,
  getLongAndShortBuyingPower,
  getMarketOrderWarning,
  getMaxOrderUsdValueMinusFees,
  getPrePlaceData,
  hasPriceInput,
  hasTriggerPriceInput,
} from 'utils/order';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import { FormattedNumber } from 'components';
import Button from 'components/Button/button';
import NumberInput from 'components/Inputs/NumberInput';
import TabSelector from 'components/TabSelector';
import { getMarketPosition } from 'components/Tables/PositionsTable/stopLossTakeProfitCell';
import Text from 'components/Text';

import AdvancedOptions from './AdvancedOptions';
import { TimeInForce } from './AdvancedOptions/TimeInForceCheckboxes';
import ConfirmBuySellModal from './ConfirmBuySellModal';
import CurrencyHeader from './CurrencyHeader';
import LadderInputs, { PriceStepKind } from './LadderInputs';
import OrderPrePlaceDetails from './OrderPrePlaceDetails';
import LongShortSelectorWithLeverage from './TradeSideAndLeverageSelector';
import UserMarketStats from './UserMarketStats';
import {
  orderReducer,
  OrderState,
  OrderStateActionType,
} from './orderStateReducer';
import { BuySellStyled, TopPart } from './style';
import { MAX_INPUT_LENGTH } from 'constants/general';
import {
  LS_ORDER_TYPE_TIME_IN_FORCE,
  LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN,
} from 'constants/localStorageKeys';
import { DecimalScale } from 'constants/marketMappings';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import { mixpanelService } from 'service/mixpanelService';

import { Markets, NotificationType, OrderType, TradeSide } from 'enums';
import { motion } from 'framer-motion';
import { AccountStats, Market, Position } from 'interfaces';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  afterConfirmBuy?: (size: number) => void;
  afterConfirmSell?: (size: number) => void;
  externalPrice?: number | null;
  lastSize?: number | null;
  showConfirmation: boolean;
  isSourceTradingView?: boolean;
  className?: string;
}

export type TCreateAndSetLadderProps = {
  selectedMarket: Market;
  isCurrencyKindUSD: boolean;
};

const shouldEnableBuySell = ({
  orderState,
  selectedOrderType,
  isProcessingCreateOrder,
  accountStats,
  decimalPlaces,
}: {
  orderState: OrderState;
  selectedOrderType: OrderType;
  isProcessingCreateOrder: 'buy' | 'sell' | null;
  accountStats: AccountStats | null | undefined;
  decimalPlaces?: DecimalScale;
}) => {
  if (
    !decimalPlaces ||
    !accountStats ||
    accountStats.accountEquity <= 0 ||
    isProcessingCreateOrder
  ) {
    return false;
  }

  // All fields are required
  if (!orderState.quantity || !orderState.value) {
    return false;
  }

  // Don't allow negative values
  if (orderState.quantity <= 0 || orderState.value <= 0) {
    return false;
  }

  // Don't allow order size less than the minimum order size
  if (orderState.quantity < decimalPlaces.minOrder) {
    return false;
  }

  // Limit or Stop Limit orders
  if (hasPriceInput(selectedOrderType)) {
    // Price is mandatory
    if (!orderState.price) {
      return false;
    }
    // Don't allow price less than the minimum tick size
    if (orderState.price < decimalPlaces.minTick) {
      return false;
    }
  }

  if (hasTriggerPriceInput(selectedOrderType)) {
    // Trigger price is mandatory
    if (!orderState.triggerPrice) {
      return false;
    }
    // Don't allow trigger price less than the minimum tick size
    if (orderState.triggerPrice < decimalPlaces.minTick) {
      return false;
    }
  }

  if (orderState.orderTypeDropdownValue === OrderType.LADDER) {
    if (
      !orderState.ladder?.priceStep ||
      !orderState.ladder?.sizeScale ||
      !orderState.ladder?.orderCount
    ) {
      return false;
    }
  }

  return true;
};

const getInitialPrice = (
  externalInitialPrice: number | null | undefined,
  selectedMarket: Market | null,
) => {
  const price = externalInitialPrice ?? selectedMarket?.lastTradePrice;

  if (price !== null && price !== undefined) {
    return roundToNearestTick(price, selectedMarket?.minTick ?? 0.0001);
  }

  return null;
};

export const getTimeInForce = (_orderType: OrderType | undefined) => {
  let timeInForce: TimeInForce;

  if (!_orderType) return 'good_till_cancel';

  // Treat ladder orders as limit orders
  const orderType =
    _orderType === OrderType.LADDER ? OrderType.LIMIT : _orderType;

  if (
    [OrderType.LIMIT, OrderType.MARKET, OrderType.LADDER].includes(orderType)
  ) {
    const timeInForceLs = readUserSetting(LS_ORDER_TYPE_TIME_IN_FORCE);
    // default to good till cancel
    if (!timeInForceLs) {
      timeInForce = 'good_till_cancel';
    } else {
      timeInForce = timeInForceLs[orderType] ?? 'good_till_cancel';
    }
  } else {
    // Stop orders - always good till cancel
    timeInForce = 'good_till_cancel';
  }

  return timeInForce;
};

export const getAvgEntryPrice = (
  orderType: OrderType,
  triggerPrice: number | null | undefined,
  assetMarketPrice: number | null | undefined,
  limitPrice: number | null | undefined,
  estimatedMarketEntryPrice?: number | null,
) => {
  if (hasTriggerPriceInput(orderType)) {
    if (orderType === OrderType.ST0P_LIMIT) return limitPrice;

    return triggerPrice;
  }

  if (orderType === OrderType.MARKET)
    return estimatedMarketEntryPrice || assetMarketPrice;

  return limitPrice;
};

const BuySell = ({
  afterConfirmBuy,
  afterConfirmSell,
  externalPrice,
  lastSize,
  showConfirmation,
  isSourceTradingView,
  className,
}: Props) => {
  const {
    store: {
      markets: { selectedMarket },
      account: accountStore,
      orderbook: {
        orderInputPrice: orderbookSelectedPrice,
        midPrice,
        getMarketOrderEstimation,
        getOrderbookEdgePrice,
      },
      appState: { isCurrencyKindUSD, toggleCurrencyKind },
    },
  } = useAppContext();
  const { createOrder } = useOrdersAPI();
  const { t } = useTranslation();

  const INITIAL_ORDER_TYPE = isSourceTradingView
    ? OrderType.LIMIT
    : OrderType.MARKET;

  const [isProcessingCreateOrder, setIsProcessingCreateOrder] = useState<
    'buy' | 'sell' | null
  >(null);

  const assetMarketPrice = selectedMarket?.fairPrice ?? 0;
  const initialPrice = getInitialPrice(externalPrice, selectedMarket);

  // Initial reducer price
  const [orderState, orderStateDispatch] = useReducer(orderReducer, {
    price: initialPrice,
    quantity: lastSize,
    value: null,
    triggerPrice: null,
    orderTypeDropdownValue: INITIAL_ORDER_TYPE,
    fairPrice: selectedMarket?.fairPrice,
    tradeSide: TradeSide.LONG,
  });
  const selectedTradeSide = orderState.tradeSide || TradeSide.LONG;

  const isBuy = selectedTradeSide === TradeSide.LONG;

  const assetName = getCurrencyFromTradingPair(selectedMarket?.id);

  const quantityCurrency = isCurrencyKindUSD ? 'USD' : assetName;

  // Change (pre-fill) PRICE input when the Order Type Dropdown value has changed to LIMIT
  useEffect(() => {
    const newPrice = getInitialPrice(externalPrice, selectedMarket);

    if (hasPriceInput(orderState.orderTypeDropdownValue!)) {
      orderStateDispatch({
        payload: {
          price: newPrice,
          selectedMarket: selectedMarket,
          isCurrencyKindUSD: isCurrencyKindUSD,
        },
        type: OrderStateActionType.CHANGE_PRICE,
      });
    }
    if (hasTriggerPriceInput(orderState.orderTypeDropdownValue!)) {
      orderStateDispatch({
        payload: {
          triggerPrice: newPrice,
        },
        type: OrderStateActionType.CHANGE_TRIGGER_PRICE,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalPrice, orderState.orderTypeDropdownValue, selectedMarket?.id]);

  // Reset all values when the selected market has changed
  useEffect(() => {
    orderStateDispatch({
      payload: {
        price: hasPriceInput(orderState.orderTypeDropdownValue)
          ? getInitialPrice(externalPrice, selectedMarket)
          : null,
        value: null,
        quantity: null,
        fairPrice: selectedMarket?.fairPrice,
      },
      type: OrderStateActionType.SET_INITIAL_STATE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarket?.id]);

  // Market's market price changed
  useEffect(() => {
    if (selectedMarket?.fairPrice === orderState.fairPrice) return;
    orderStateDispatch({
      type: OrderStateActionType.UPDATE_MARKET_PRICE,
      payload: { fairPrice: selectedMarket?.fairPrice || 1 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarket?.fairPrice]);

  // Limit price changed by selecting a price via the tradingview widget price level
  useEffect(() => {
    if (accountStore?.orderEntryPrice !== 0) {
      if (hasPriceInput(orderState.orderTypeDropdownValue)) {
        orderStateDispatch({
          payload: {
            price: accountStore?.orderEntryPrice,
            selectedMarket: selectedMarket,
            isCurrencyKindUSD: isCurrencyKindUSD,
          },
          type: OrderStateActionType.CHANGE_PRICE,
        });
        accountStore.changeOrderEntryPrice(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountStore?.orderEntryPrice]);

  // Limit price changed by selecting a price via the orderbook price level
  useEffect(() => {
    if (
      hasPriceInput(orderState.orderTypeDropdownValue) &&
      orderbookSelectedPrice !== null
    ) {
      orderStateDispatch({
        payload: {
          price: orderbookSelectedPrice,
          selectedMarket: selectedMarket,
          isCurrencyKindUSD: isCurrencyKindUSD,
        },
        type: OrderStateActionType.CHANGE_PRICE,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderbookSelectedPrice]);

  const modal = useModal();

  const onConfirmCreateOrder = async (
    params: CreateOrderParams,
    isBuy: boolean,
  ) => {
    try {
      if (!accountStore.frontendSecrets || params.price <= 0) return;

      mixpanelService.openOrderClicked(
        accountStore.frontendSecrets?.profile.wallet,
        params,
        'order-panel',
      );

      await createOrder(params);

      mixpanelService.openOrderSuccess(
        accountStore.frontendSecrets?.profile.wallet,
        params,
        'order-panel',
      );

      if (isBuy) {
        afterConfirmBuy && afterConfirmBuy(params.size);
      } else {
        afterConfirmSell && afterConfirmSell(params.size);
      }
    } catch (e: any) {
      console.log('An error occurred while trying to create an order.', e);
    } finally {
      setIsProcessingCreateOrder(null);
    }
  };

  const maxOrderUsdValueRounded = getMaxOrderUsdValue(
    accountStore.accountStats,
    selectedMarket,
  );

  const assetDecimalScale = useGetDecimalPlaces(selectedMarket?.id);

  // account for some fee and change in withdrawable balance (subtract 3%)
  const maxOrderUsdValueMinusFees = maxOrderUsdValueRounded
    ? roundToNearestTick(
        getMaxOrderUsdValueMinusFees(maxOrderUsdValueRounded) as number,
        0.03,
      )
    : null;

  const isUserAuthenticated = !!accountStore.frontendSecrets;

  const {
    longingPower: longinPowerMinusFees,
    shortingPower: shortingPowerMinusFees,
    longMaxQuantity,
    shortMaxQuantity,
  } = getLongAndShortBuyingPower(
    accountStore._positions,
    selectedMarket?.id,
    maxOrderUsdValueMinusFees ?? 0,
    orderState.price ?? 0,
    assetMarketPrice,
    assetDecimalScale,
    orderState.orderTypeDropdownValue ?? OrderType.LIMIT,
  );

  const marketOrderEstimation = getMarketOrderEstimation(
    orderState.quantity,
    selectedTradeSide,
    orderState.orderTypeDropdownValue,
    selectedTradeSide === TradeSide.LONG
      ? longinPowerMinusFees
      : shortingPowerMinusFees,
  );

  const openConfirmModal = () => {
    if (
      !selectedMarket?.id ||
      !orderState.quantity ||
      !orderState.orderTypeDropdownValue ||
      (hasPriceInput(orderState.orderTypeDropdownValue) && !orderState.price) ||
      (hasTriggerPriceInput(orderState.orderTypeDropdownValue) &&
        !orderState.triggerPrice)
    )
      return;

    flushSync(() => {
      setIsProcessingCreateOrder(isBuy ? 'buy' : 'sell');
    });

    const price = hasPriceInput(orderState.orderTypeDropdownValue)
      ? orderState.price!
      : assetMarketPrice;

    let orderQuantity = orderState.quantity;
    const maxPurchasableMarketQuantity =
      marketOrderEstimation?.maxPurchasableQuantity;

    // If order quantity is above maxPurchasableMarketQuantity, then use maxPurchasableMarketQuantity
    if (orderType === OrderType.MARKET && maxPurchasableMarketQuantity) {
      if (orderQuantity > maxPurchasableMarketQuantity)
        orderQuantity = maxPurchasableMarketQuantity;
    }

    const roundedPrice = roundToNearestTick(price, selectedMarket.minTick);
    const roundedSize = roundToNearestTick(
      orderQuantity,
      selectedMarket.minOrder,
    );

    const triggerPrice = hasTriggerPriceInput(orderState.orderTypeDropdownValue)
      ? orderState.triggerPrice
      : null;

    const roundedTriggerPrice =
      triggerPrice && roundToNearestTick(triggerPrice, selectedMarket.minTick);

    const timeInForce = getTimeInForce(orderState.orderTypeDropdownValue!);

    const params =
      orderState.orderTypeDropdownValue === OrderType.LADDER
        ? orderState.ladderOrders?.orders
        : {
            price: roundedPrice,
            marketID: selectedMarket?.id,
            quantity: roundedSize,
            size: roundedSize,
            side: isBuy ? TradeSide.LONG : TradeSide.SHORT,
            type: orderState.orderTypeDropdownValue,
            timeInForce,
            ...(roundedTriggerPrice
              ? { triggerPrice: roundedTriggerPrice }
              : {}),
          };

    if (!params) {
      showNotification({
        title: 'Error Creating Orders',
        description: 'Error creating orders. Please try again.',
        type: NotificationType.Negative,
      });
      setIsProcessingCreateOrder(null);
      return;
    }

    const createOrder = (p: CreateOrderParams | CreateOrderParams[]) => {
      if (isArray(p)) {
        p.forEach(param => {
          onConfirmCreateOrder(param, isBuy);
        });
        return;
      }

      onConfirmCreateOrder(p, isBuy);
    };

    const isAnyLadderOrderInvalid = orderState.ladderOrders?.orders?.some(
      order => order.size <= 0 || order.price <= 0,
    );

    if (
      !isAnyLadderOrderInvalid &&
      (!showConfirmation ||
        localStorage.getItem(LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN) ===
          'true')
    ) {
      createOrder(params);
      return;
    }

    modal.present(
      <ConfirmBuySellModal
        data={params}
        onConfirm={createOrder}
        isBuy={isBuy}
        onClose={() => {
          setIsProcessingCreateOrder(null);
        }}
        ladderParams={orderState.ladderOrders?.ladderParams}
      />,
      Modals.confirmBuySellModal,
    );
  };

  const handleOrderTypeOptionSelect = (val: OrderType) => {
    orderStateDispatch({
      type: OrderStateActionType.CHANGE_ORDER_TYPE,
      payload: {
        orderTypeDropdownValue: val,
        selectedMarket: selectedMarket,
        isCurrencyKindUSD: isCurrencyKindUSD,
      },
    });
  };

  const handlePriceChange = (val: number | null) => {
    orderStateDispatch({
      payload: {
        price: val,
        selectedMarket: selectedMarket,
        isCurrencyKindUSD: isCurrencyKindUSD,
      },
      type: OrderStateActionType.CHANGE_PRICE,
    });
  };

  const handleTriggerPriceChange = (val: number | null) => {
    orderStateDispatch({
      payload: { triggerPrice: val },
      type: OrderStateActionType.CHANGE_TRIGGER_PRICE,
    });
  };

  const setSelectedTradeSide = (side: TradeSide) => {
    orderStateDispatch({
      payload: {
        tradeSide: side,
        selectedMarket: selectedMarket,
        isCurrencyKindUSD: isCurrencyKindUSD,
      },
      type: OrderStateActionType.CHANGE_TRADE_SIDE,
    });
  };

  const handleQuantityChange = (val: number | null) => {
    if (!selectedMarket || !orderState.orderTypeDropdownValue) return;

    orderStateDispatch({
      payload: {
        quantity: val,
        selectedMarket: selectedMarket,
        isCurrencyKindUSD: isCurrencyKindUSD,
      },
      type: OrderStateActionType.CHANGE_QUANTITY,
    });
  };

  const handleValueChange = (val: number | null) => {
    orderStateDispatch({
      payload: {
        value: val,
        selectedMarket: selectedMarket,
        isCurrencyKindUSD: isCurrencyKindUSD,
      },
      type: OrderStateActionType.CHANGE_VALUE,
    });
  };

  const { quantityError, priceError, triggerPriceError, ladder } =
    checkOrderForErrors({
      orderConfig: {
        price: orderState.price,
        triggerPrice: orderState.triggerPrice,
        quantity: orderState.quantity,
        value: orderState.value,
        type: orderState.orderTypeDropdownValue,
        marketPrice: selectedMarket?.fairPrice,
        ladder: orderState.ladder,
        ladderOrders: orderState.ladderOrders?.orders,
      },
      decimalPlaces: assetDecimalScale,
      assetName,
      maxOrderUsdValueMinusFees,
      currencyKind: quantityCurrency,
      positions: accountStore._positions,
      marketId: selectedMarket?.id,
      isCurrencyKindUSD,
      orderSide: selectedTradeSide,
      fairPrice: selectedMarket?.fairPrice,
    });

  const hasError =
    !!quantityError[selectedTradeSide] ||
    !!priceError ||
    !!triggerPriceError ||
    !!ladder;

  const enableBuySell =
    !hasError &&
    shouldEnableBuySell({
      orderState,
      selectedOrderType: orderState.orderTypeDropdownValue!,
      isProcessingCreateOrder,
      accountStats: accountStore._accountStats,
      decimalPlaces: assetDecimalScale,
    });

  useKey(
    Key.Enter,
    enableBuySell && isSourceTradingView ? openConfirmModal : undefined,
  );

  const shouldShowSizeSlider =
    orderState.orderTypeDropdownValue !== OrderType.LADDER;

  const ctaErrorMessage =
    quantityError[selectedTradeSide] ||
    priceError ||
    triggerPriceError ||
    ladder?.priceStepError ||
    ladder?.sizeScaleError ||
    ladder?.orderCountError;

  const orderType = orderState.orderTypeDropdownValue;

  if (!orderState.orderTypeDropdownValue || !selectedMarket?.id) return null;

  const avgEntryPrice = getAvgEntryPrice(
    orderState.orderTypeDropdownValue,
    orderState.triggerPrice,
    assetMarketPrice,
    orderState.price,
    marketOrderEstimation?.avgPrice,
  );

  const prePosition = {
    marketID: selectedMarket.id,
    size: roundToNearestTick(orderState.quantity ?? 0, selectedMarket.minOrder),
    side: selectedTradeSide,
    avgEntryPrice: avgEntryPrice,
  } as Position;

  const prePlaceOrderData = getPrePlaceData(
    orderType,
    orderState.ladderOrders?.orders ?? [],
    selectedMarket.minOrder,
    selectedMarket.minTick,
    accountStore._positions,
    accountStore._accountStats?.balance,
    prePosition,
    selectedMarket?.maintenanceMargin,
    selectedMarket?.id,
    marketOrderEstimation,
    !isCurrencyKindUSD ? orderState.quantity : null, // only pass is current current kind is crypto
  );

  const marketOrderWarning = marketOrderEstimation
    ? getMarketOrderWarning(
        selectedMarket.id as Markets,
        marketOrderEstimation?.priceImpact,
      )
    : null;

  const orderPriceValue = [
    OrderType.MARKET,
    OrderType.STOP_MARKET,
    OrderType.LADDER,
  ].includes(orderState.orderTypeDropdownValue)
    ? undefined
    : hasPriceInput(orderType)
    ? orderState.price
    : assetMarketPrice;

  const { long: longPrice, short: shortPrice } = getOrderbookEdgePrice();

  // For Ladder orders, approximation doesnt makes sense as the quantity si place at multiple price levels
  const shouldShowQuantityValueApproximation = orderType !== OrderType.LADDER;

  return (
    <BuySellStyled className={className}>
      <TopPart>
        {/* <BuySellHeader
          isSourceTradingView={isSourceTradingView}
          handleOrderTypeOptionSelect={handleOrderTypeOptionSelect}
        /> */}
        {isSourceTradingView ? (
          selectedMarket ? (
            <CurrencyHeader
              className="mb-10"
              marketId={selectedMarket.id}
              fairPrice={selectedMarket.fairPrice}
              high24hPrice={selectedMarket.marketPrice24hHigh}
              low24hPrice={selectedMarket.marketPrice24hLow}
              change24hBasis={selectedMarket.lastTradePrice24hChangeBasis}
              marketLogo={selectedMarket?.iconUrl}
              marketTitle={selectedMarket?.marketTitle}
              positionSize={
                getMarketPosition(selectedMarket?.id, accountStore._positions)
                  ?.size
              }
              decimalScale={assetDecimalScale}
            />
          ) : null
        ) : null}

        <LongShortSelectorWithLeverage
          tradeSide={selectedTradeSide}
          onClickTradeSide={setSelectedTradeSide}
          longPrice={longPrice}
          shortPrice={shortPrice}
          decimalScale={assetDecimalScale}
          className="mb-10"
        />

        <TabSelector
          handleOptionSelect={(val: string) =>
            handleOrderTypeOptionSelect(val as OrderType)
          }
          buttonOptions={[
            { label: t('market'), value: OrderType.MARKET },
            { label: t('limit'), value: OrderType.LIMIT },
          ]}
          dropdownOptions={[
            { label: t('stopMarket'), value: OrderType.STOP_MARKET },
            { label: t('stopLimit'), value: OrderType.ST0P_LIMIT },
            { label: t('ladder'), value: OrderType.LADDER },
          ]}
          initialSelectedOption={INITIAL_ORDER_TYPE}
          initialOptionsDropdownAlignment={'left'}
        />

        <motion.div
          layout
          transition={{ type: 'orchestration', duration: 0.1 }}
        >
          {/* Trigger Price Input */}
          <Box className="mt-10">
            {/* Show trigger price input for stop-limit and stop-market orders */}
            {hasTriggerPriceInput(orderState.orderTypeDropdownValue) ? (
              <NumberInput
                value={getCurrencyInputValue(orderState.triggerPrice)}
                onChange={handleTriggerPriceChange}
                label={t('triggerPrice')}
                placeholder={'0.00'}
                currency="USD"
                showPresets={false}
                showSwapCurrency={false}
                showCurrencyIcon={true}
                showValueApproximation={false}
                showError={!!triggerPriceError}
                errorMessage={triggerPriceError}
              />
            ) : null}
          </Box>
          <Box className="mt-10">
            {/* Show price input for limit and stop-limit orders */}
            {hasPriceInput(orderState.orderTypeDropdownValue) ? (
              <NumberInput
                key={`${selectedMarket?.id}${isCurrencyKindUSD}`}
                value={getCurrencyInputValue(numToString(orderState.price))}
                onChange={handlePriceChange}
                label={
                  [OrderType.LIMIT, OrderType.LADDER].includes(
                    orderState.orderTypeDropdownValue,
                  )
                    ? t('price')
                    : t('limitPrice')
                }
                placeholder={'0.00'}
                currency="USD"
                showPresets={false}
                showSwapCurrency={false}
                showCurrencyIcon={true}
                showValueApproximation={false}
                midValue={roundToNearestTick(
                  midPrice,
                  assetDecimalScale.minTick,
                )}
                // showError={!!priceError}
                // errorMessage={priceError}
              />
            ) : null}
          </Box>

          {/* Order QUANTITY Input */}
          <Box className="mt-10">
            {quantityCurrency === 'USD' ? (
              <NumberInput
                key={assetName}
                value={getCurrencyInputValue(orderState.value)}
                onChange={handleValueChange}
                label={
                  orderState.orderTypeDropdownValue === OrderType.LADDER
                    ? t('initialSize')
                    : t('size')
                }
                currency={'USD'}
                showPresets={shouldShowSizeSlider && isUserAuthenticated}
                maxValue={
                  selectedTradeSide === TradeSide.LONG
                    ? longinPowerMinusFees
                    : shortingPowerMinusFees
                }
                onSwapCurrency={toggleCurrencyKind}
                approximationCurrency={assetName}
                // needs to be converted to string - https://github.com/s-yadav/react-number-format/issues/212
                approximatedValue={orderState.quantity?.toString()}
                approximatedValueDecimalScale={assetDecimalScale}
                inputValueDecimalScale={USD_DECIMAL_SCALE}
                showValueApproximation={shouldShowQuantityValueApproximation}
                autoFocus={isSourceTradingView}
                errorMessage={marketOrderWarning}
                showError={!!marketOrderWarning}
                isErrorAWarning
                maxLength={MAX_INPUT_LENGTH}
              />
            ) : (
              <NumberInput
                key={assetName}
                value={getCurrencyInputValue(orderState.quantity)}
                onChange={handleQuantityChange}
                label={
                  orderState.orderTypeDropdownValue === OrderType.LADDER
                    ? t('initialSize')
                    : t('size')
                }
                currency={assetName}
                showPresets={shouldShowSizeSlider && isUserAuthenticated}
                maxValue={
                  selectedTradeSide === TradeSide.LONG
                    ? longMaxQuantity
                    : shortMaxQuantity
                }
                onSwapCurrency={toggleCurrencyKind}
                approximationCurrency="USD"
                approximatedValue={orderState.value?.toString()}
                inputValueDecimalScale={assetDecimalScale}
                approximatedValueDecimalScale={USD_DECIMAL_SCALE}
                showValueApproximation={shouldShowQuantityValueApproximation}
                autoFocus={isSourceTradingView}
                errorMessage={marketOrderWarning}
                showError={!!marketOrderWarning}
                isErrorAWarning
                maxLength={MAX_INPUT_LENGTH}
              />
            )}
          </Box>

          {orderState.orderTypeDropdownValue === OrderType.LADDER ? (
            <LadderInputs
              key={orderState.ladder?.orderCount}
              priceStep={orderState.ladder?.priceStep}
              sizeScale={orderState.ladder?.sizeScale}
              orderCount={orderState.ladder?.orderCount}
              priceStepKind={
                orderState.ladder?.priceStepKind || PriceStepKind.USD
              }
              orderStateDispatch={orderStateDispatch}
              tradeSide={selectedTradeSide}
              price={orderState.price}
              selectedMarket={selectedMarket}
              isCurrencyKindUSD={isCurrencyKindUSD}
            />
          ) : null}

          {/* {isSourceTradingView ? ( */}

          <OrderPrePlaceDetails data={prePlaceOrderData}>
            {accountStore.frontendSecrets ? (
              <Button
                sizeVariant="S"
                block
                colorVariant={isBuy ? 'longBuy' : 'longSell'}
                onClick={openConfirmModal}
                isLoading={!!isProcessingCreateOrder}
                disabled={parseProdDisabled(!enableBuySell)}
                customDisabledOpacity={0.5}
              >
                {ctaErrorMessage ? (
                  <Text avoidTransition>{ctaErrorMessage}</Text>
                ) : orderPriceValue ? (
                  <FormattedNumber
                    prefix={`${selectedTradeSide.toUpperCase()} @ `}
                    decimalScale={assetDecimalScale.price}
                    value={orderPriceValue}
                  />
                ) : (
                  selectedTradeSide.toUpperCase()
                )}
              </Button>
            ) : (
              <Button
                colorVariant="primaryGreen"
                sizeVariant="S"
                block
                data-gtmid="button-connect-wallet-1"
                onClick={() => {
                  modal.present(<WalletModal />, Modals.walletModal);
                }}
              >
                {t('connectWallet')}
              </Button>
            )}
          </OrderPrePlaceDetails>

          <AdvancedOptions
            orderType={orderState.orderTypeDropdownValue!}
            className={isSourceTradingView ? 'mt-20' : 'mt-10'}
          />
        </motion.div>
      </TopPart>

      <UserMarketStats handleQuantityChange={handleQuantityChange} />
    </BuySellStyled>
  );
};

export default observer(BuySell);
