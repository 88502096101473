import { memo } from 'react';

import {
  TableRow,
  TableHead as TableHeadMui,
  TableCell,
} from '@material-ui/core';

import SortStatusDoubleCaret from '../components/SortStatusDoubleCaret';
import caretDownGray from 'assets/icons/caretDownGray.svg';
import { Row } from 'theme/globalStyledComponents/row';

import { PositionSortConfig, SortBy } from '.';
import { useTranslation } from 'react-i18next';

interface Props {
  onSetSortBy?: (sortBy: SortBy) => void;
  sortConfig: PositionSortConfig | null;
}
const TableHead = ({ onSetSortBy, sortConfig }: Props) => {
  const { t } = useTranslation();

  const SortStatusIcon = ({ sortBy }: { sortBy: SortBy }) => (
    <SortStatusDoubleCaret
      isSorted={sortBy === sortConfig?.sortBy}
      numOfClicks={sortConfig?.numOfClicks}
    />
  );

  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>{t('market')}</TableCell>
        <TableCell key={1}>{t('positionSize')}</TableCell>
        <TableCell
          key={2}
          className="cursor-pointer flex items-center gap-5"
          onClick={() => onSetSortBy && onSetSortBy(SortBy.NOTIONAL)}
        >
          <Row gap={5}>
            {t('value')}
            <SortStatusIcon sortBy={SortBy.NOTIONAL} />
          </Row>
        </TableCell>

        <TableCell key={3}>{t('entryPrice')}</TableCell>

        <TableCell key={4}>{t('fairPrice')}</TableCell>

        <TableCell key={5}>{t('liquidationPrice')}</TableCell>

        <TableCell
          key={6}
          className="cursor-pointer flex items-center gap-5"
          onClick={() => onSetSortBy && onSetSortBy(SortBy.PNL)}
        >
          <Row gap={5}>
            {t('unrealisedPnl')}
            <SortStatusIcon sortBy={SortBy.PNL} />
          </Row>
        </TableCell>
        <TableCell key={7}>{t('positionMargin')}</TableCell>
        <TableCell key={8} align="right">
          {t('stopLossTakeProfit')}
        </TableCell>
        <TableCell key={9} align="right"></TableCell>
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
