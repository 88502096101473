import { memo } from 'react';

import Portfolio from '../../index';
import ClaimAirdrop from 'pages/Portfolio/components/ClaimAirdrop';

const PortfolioAirdrops = () => {
  return (
    <Portfolio>
      <ClaimAirdrop />
    </Portfolio>
  );
};

export default memo(PortfolioAirdrops);
