import { Theme } from 'interfaces';

//@todo: I keep them as param because we don't have any styled option in the project so it's easier to use this file as template for now
export const sizes = (theme: any) => {
  return {
    subtle: {
      fontSize: '12px',
      padding: '4px 8px',
      borderRadius: '4px',
      letterSpacing: '0.25px',
      lineHeight: '14px',
      fontWeight: theme.fontWeights.medium,
    },
    extraSmall: {
      fontSize: '10px',
      padding: '6px 16px',
      borderRadius: '4px',
      letterSpacing: '0.25px',
      lineHeight: '10px',
      fontWeight: theme.fontWeights.medium,
      iconSize: '16px',
    },
    huge: {
      fontSize: '18px',
      padding: '20px 32px',
      borderRadius: '16px',
      letterSpacing: '0.25px',
      lineHeight: '26px',
      fontWeight: theme.fontWeights.semiBold,
      iconSize: '16px',
    },
    S: {
      iconSize: '16px',
      fontSize: '12px',
      fontWeight: theme.fontWeights.semiBold,
      lineHeight: '14px',
      padding: '8px 12px', // -2 from figma design to account for 2px transparent defult border
      borderRadius: '8px',
    },
    M: {
      iconSize: '24px',
      fontSize: '14px',
      fontWeight: theme.fontWeights.semiBold,
      lineHeight: 'initial',
      padding: '10px 18px', // -2 from figma design to account for 2px transparent defult border
      letterSpacing: '-0.3px',
      borderRadius: '8px',
    },
    L: {
      iconSize: '24px',
      fontSize: '16px',
      fontWeight: theme.fontWeights.semiBold,
      lineHeight: '24px',
      padding: '10px 22px', // -2 from figma design to account for 2px transparent defult border
      letterSpacing: '-0.3px',
      borderRadius: '8px',
    },
    small: {
      fontSize: '13px',
      padding: '6px 12px',
      borderRadius: '8px',
      letterSpacing: 'initial',
      lineHeight: '15px',
      fontWeight: theme.fontWeights.semiBold,
      iconSize: '16px',
    },
    medium: {
      fontSize: '14px',
      letterSpacing: '-0.3px',
      lineHeight: '16px',
      padding: '6px 20px',
      borderRadius: '8px',
      fontWeight: theme.fontWeights.semiBold,
      iconSize: '16px',
    },
    large: {
      fontSize: '16px',
      letterSpacing: '-0.3px',
      lineHeight: '24px',
      padding: '6px 24px',
      borderRadius: '8px',
      fontWeight: theme.fontWeights.semiBold,
      iconSize: '24px',
    },
    extraLarge: {
      fontSize: '18px',
      letterSpacing: '-0.3px',
      lineHeight: '24px',
      padding: '6px 24px',
      borderRadius: '8px',
      fontWeight: theme.fontWeights.semiBold,
      iconSize: '24px',
    },
  };
};

export const colors = (theme: Theme) => {
  const { colors } = theme;
  return {
    primaryDefault: {
      default: {
        text: colors.white,
        background: colors.positiveBackground300,
        border: 'transparent',
      },
      hover: {
        text: colors.white,
        background: colors.positiveBackground200,
        border: 'transparent',
      },
    },
    primaryGreen: {
      default: {
        text: colors.white,
        background: colors.positiveForeground300,
        border: 'transparent',
      },
      hover: {
        text: colors.white,
        background: colors.positiveForeground400,
        border: 'transparent',
      },
    },
    primaryRed: {
      default: {
        text: colors.white,
        background: colors.negativeForeground200,
        border: 'transparent',
      },
      hover: {
        text: colors.white,
        background: colors.negativeForeground300,
        border: 'transparent',
      },
    },
    secondaryDefault: {
      default: {
        text: colors.shadesForeground100,
        background: colors.shadesBackground600,
        border: 'transparent',
      },
      hover: {
        text: colors.shadesForeground100,
        background: colors.shadesBackground500,
        border: 'transparent',
      },
    },
    secondaryOutline: {
      default: {
        text: colors.white,
        background: 'transparent',
        border: `${colors.shadesBackground500}`,
      },
      hover: {
        text: colors.white,
        background: colors.shadesBackground600,
        border: `${colors.shadesForeground300}`,
      },
    },
    tertiary: {
      default: {
        text: colors.white,
        background: 'linear-gradient(90deg, #292638 -6.52%, #38342B 108.7%)',
        border: '0px',
      },
      hover: {
        text: colors.white,
        background: 'linear-gradient(90deg, #393552 -6.52%, #524935 100%)',
        border: '0px',
      },
    },
    buy: {
      default: {
        text: colors.positiveForeground200,
        background: colors.positiveBackground200,
        border: 'transparent',
      },
      hover: {
        text: colors.shadesBackground900,
        background: colors.positiveForeground200,
        border: 'transparent',
      },
    },
    sell: {
      default: {
        text: colors.negativeForeground200,
        background: colors.negativeBackground100,
        border: 'transparent',
      },
      hover: {
        text: colors.shadesBackground900,
        background: colors.negativeForeground200,
        border: 'transparent',
      },
    },
    icons: {
      default: {
        text: colors.white,
        background: 'transparent',
        border: 'transparent',
      },
      hover: {
        text: colors.white,
        background: colors.shadesBackground600,
        border: 'transparent',
      },
    },
    destructiveNew: {
      default: {
        text: colors.negativeForeground200,
        background: colors.negativeBackground300,
        border: 'transparent',
      },
      hover: {
        text: colors.negativeForeground200,
        background: colors.negativeBackground100,
        border: `1px solid ${colors.negativeForeground200}`,
      },
    },
    primary: {
      default: {
        text: colors.shadesBackground900,
        background: colors.positiveForeground200,
        border: 'transparent',
      },
      hover: {
        text: colors.shadesBackground900,
        background: colors.positiveForeground300,
        border: 'transparent',
      },
      focused: {
        text: colors.shadesBackground900,
        background: colors.positiveForeground200,
        border: colors.secondaryGoldTransparent,
      },
      outline: {
        default: {
          text: colors.positiveForeground200,
          background: 'transparent',
          border: colors.positiveForeground200,
        },
        hover: {
          text: colors.positiveForeground300,
          background: 'transparent',
          border: colors.positiveForeground300,
        },
        focused: {
          text: colors.positiveForeground200,
          background: 'transparent',
          border: colors.positiveForeground300,
        },
      },
    },
    secondary: {
      default: {
        text: colors.shadesForeground100,
        background: 'transparent',
        border: colors.mobileOutline,
      },
      hover: {
        text: colors.shadesForeground100,
        background: 'transparent',
        border: colors.shadesForeground100,
      },
      focused: {
        text: colors.shadesForeground100,
        background: colors.shadesBackground800,
        border: colors.shadesForeground300,
      },
    },
    success: {
      default: {
        text: colors.white,
        background: colors.positiveForeground200,
        border: 'transparent',
      },
      hover: {
        text: colors.white,
        background: colors.positiveForeground300,
        border: 'transparent',
      },
      focused: {
        text: colors.white,
        background: colors.positiveForeground200,
        border: colors.positiveBackground100,
      },
    },
    destructive: {
      default: {
        text: colors.white,
        background: colors.negativeForeground200,
        border: 'transparent',
      },
      hover: {
        text: colors.white,
        background: colors.negativeForeground300,
        border: 'transparent',
      },
      focused: {
        text: colors.white,
        background: colors.negativeForeground200,
        border: colors.negativeBackground100,
      },
      outline: {
        default: {
          text: colors.negativeForeground200,
          background: colors.negativeBackground100,
          border: 'transparent',
        },
        hover: {
          text: colors.white,
          background: colors.negativeBackground100,
          border: 'transparent',
        },
        focused: {
          text: colors.negativeForeground200,
          background: colors.negativeBackground100,
          border: colors.negativeBackground100,
        },
      },
    },
    preset: {
      default: {
        text: colors.shadesForeground300,
        background: 'transparent',
        border: 'transparent',
      },
      hover: {
        text: colors.white,
        background: colors.shadesBackground800,
        border: 'transparent',
      },
    },
    subtle: {
      default: {
        text: colors.white,
        background: colors.warningForeground100,
        border: 'transparent',
      },
      hover: {
        text: colors.white,
        background: colors.warningForeground100,
        border: 'transparent',
      },
    },
  };
};
