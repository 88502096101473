import { useVaultsApi } from 'hooks/useVaultsAPI';

import { QueryKeys, TimescaleType } from 'enums';
import { useQuery } from 'react-query';

type FetchVaultTvlHistoryProps = {
  vaultWalletAddress: string | undefined;
  timescale: TimescaleType;
};
export function useFetchVaultTvlHistory({
  vaultWalletAddress,
  timescale,
}: FetchVaultTvlHistoryProps) {
  const { fetchVaultNavHistory } = useVaultsApi();

  return useQuery(
    [QueryKeys.VaultsNavHistory, vaultWalletAddress, timescale],
    async () => {
      if (!vaultWalletAddress) {
        return;
      }
      return await fetchVaultNavHistory(vaultWalletAddress, timescale);
    },
    {
      // 5 minutes
      refetchInterval: 300_000,
      // 45 seconds
      staleTime: 60_000,
      enabled: !!vaultWalletAddress,
    },
  );
}
