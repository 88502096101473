import { memo, useState } from 'react';

import { Checkbox } from '@material-ui/core';

import {
  createSlug,
  isSlug,
  pushDataLayerEvent,
  showNotification,
} from 'utils';

import { Key } from 'hooks';
import useModal from 'hooks/useModal';
import { useReferralsAPI } from 'hooks/useReferralsAPI';

import Button from 'components/Button';
import Modal from 'components/Modal';

import {
  ButtonGroup,
  CheckboxContainer,
  InputContainer,
  Separator,
} from './styles';
import { Modals } from 'constants/modals';

import { NotificationType } from 'enums';
import { useTranslation } from 'react-i18next';

type Props = {
  referralLinkChangesLeft: number;
};
const EditReferralLinkModal = ({ referralLinkChangesLeft }: Props) => {
  const modal = useModal();
  const { editReferralCode } = useReferralsAPI();
  const { t } = useTranslation();

  const [understandCheckbox, setUnderstandCheckbox] = useState<boolean>(false);
  const [newReferralLink, setNewReferralLink] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showIsNotSlugError, setShowIsNotSlugError] = useState<boolean>(false);

  const [isUpdatingReferralLink, setIsUpdatingReferralLink] =
    useState<boolean>(false);

  const closeModal = () => modal.pop({ name: Modals.editReferralLinkModal });

  const handleUpdateReferralLink = async () => {
    if (referralLinkChangesLeft === 1 && !understandCheckbox) {
      setErrorMessage(t('pleaseCheckConfirmationCheckboxToProceed'));
      return;
    }
    const referralLinkWithoutWhitespaces = createSlug(newReferralLink);

    if (referralLinkWithoutWhitespaces.length < 3) {
      setErrorMessage(t('minimum3Characters'));
      return;
    } else {
      setErrorMessage('');
    }

    try {
      setIsUpdatingReferralLink(true);
      await editReferralCode({
        newReferralCode: referralLinkWithoutWhitespaces,
      });
      showNotification({
        title: t('successfullyUpdatedReferralLink'),
        description: t('yourReferralLinkHasBeenUpdated'),
        type: NotificationType.Positive,
      });
      closeModal();
    } catch (error) {
      showNotification({
        title: t('failedToUpdateReferralLink'),
        description: `An error occurred while updating your referral link with the following error: ${error}`,
        type: NotificationType.Negative,
      });
      setErrorMessage(t('referralLinkTakenChooseAnother'));
    } finally {
      setIsUpdatingReferralLink(false);
    }
  };

  return (
    <Modal
      showHeader={false}
      name={Modals.editReferralLinkModal}
      showCloseIcon={false}
      size={'auto'}
      padding="20px !important"
      gtmId="update-referral-link-modal"
      handleKeyStrokes={{
        [Key.Enter]: handleUpdateReferralLink,
      }}
    >
      <InputContainer>
        <input
          autoFocus
          type="text"
          value={newReferralLink}
          onChange={event => {
            if (event.target.value?.length >= 3) {
              const isValidSlug = isSlug(event.target.value);
              setShowIsNotSlugError(!isValidSlug);
            } else {
              setShowIsNotSlugError(false);
            }
            setNewReferralLink(event.target.value);
          }}
          placeholder={t('newReferralLink')}
          maxLength={15}
        />
        {errorMessage && <span className="error-message">{errorMessage}</span>}
        {showIsNotSlugError && (
          <span className="error-message">{t('notValidSlug')}</span>
        )}
      </InputContainer>
      <Separator />
      {referralLinkChangesLeft === 1 && (
        <CheckboxContainer onClick={() => setUnderstandCheckbox(b => !b)}>
          <Checkbox checked={understandCheckbox} />
          <span>{t('iUnderstandReferralChange')}</span>
        </CheckboxContainer>
      )}
      <ButtonGroup className="mt-20">
        <Button
          variant="secondaryOutline"
          size="medium"
          onClick={() => {
            pushDataLayerEvent('update-referral-link-modal-close');
            closeModal();
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          data-gtmid="button-edit-referral-link-update"
          variant={'primaryGreen'}
          size="medium"
          block
          disabled={isUpdatingReferralLink}
          onClick={handleUpdateReferralLink}
          isLoading={isUpdatingReferralLink}
        >
          {t('Update')}
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default memo(EditReferralLinkModal);
