export enum BalanceOpsType {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
  RealizedPnl = 'pnl',
  Fee = 'fee',
  Funding = 'funding',
  ReferralPayout = 'referral_payout',
  UnstakeShares = 'unstake',
  UnstakeSharesVaults = 'unstake_shares',
  Stake = 'stake',
  YieldPayout = 'yield_payout',
  DepositBonus = 'deposit_bonus',
}
