import { useState, useEffect } from 'react';

import { Web3Provider } from '@ethersproject/providers';
import { parseUnits } from '@ethersproject/units';

import { useWeb3React } from '@web3-react/core';

import {
  bigNumberToFloat,
  verifyUserSession,
  showNotification,
  roundJsDecimalToString,
} from 'utils';
import { brand } from 'utils/brand';
import { preciselyRoundDown } from 'utils/math';
import {
  saveVaultTxToLocalStorage,
  updateSpotInLocalStorage,
} from 'utils/vaultActivity';

import {
  useActiveWeb3React,
  useVerifyChainId,
  useElixirContractsAPI,
} from 'hooks';
import { useDepositsAPI } from 'hooks/useDepositsAPI';
import useModal from 'hooks/useModal';

import { AmendStep } from './enums';
import { StatusTx, showRequestStatusNotification } from './notifications';
import { getCurrentAmendStep } from './utils';
import { USDT_DECIMALS } from 'constants/contracts';
import { Modals } from 'constants/modals';
import { NetworkContextName } from 'constants/networkContextName';
import { SpotState } from 'contexts/ElixirContractsApiContext';
import { SpotStateType } from 'enums/spotState';
import { SpotType } from 'enums/spotType';
import { Logger } from 'ethers/lib/utils';
import { useFetchSingleVaultData } from 'queryHooks/elixirVaults';
import { mixpanelService } from 'service/mixpanelService';

import { AmendType } from '.';
import { NotificationType } from 'enums';
import { BigNumber, constants, ethers } from 'ethers';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

type Props = { defaultAmendType: AmendType; poolId: number };
const useAmend = ({ defaultAmendType, poolId }: Props) => {
  const { account } = useActiveWeb3React();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();
  const { t } = useTranslation();
  const modal = useModal();
  const { library: networkProvider } =
    useWeb3React<Web3Provider>(NetworkContextName);
  const { trackVaultActivities } = useElixirContractsAPI();
  const [amendType, setAmendType] = useState(defaultAmendType);
  const [stakeAmount, setStakeAmount] = useState<null | number>(null);
  const [unStakeAmount, setUnStakeAmount] = useState<null | number>(null);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState<BigNumber>(constants.Zero);
  const [userShares, setUserShares] = useState<number>(0);
  const [usdtAllowance, setUsdtAllowance] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPendingTxSign, setIsPendingTxSign] = useState(false);
  const [isApprovingUsdt, setIsApprovingUsdt] = useState(false);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  // const [confirmations, setConfirmations] = useState(0);
  // const [stakeToastId, setStakeToastId] = useState<React.ReactText | null>(
  //   null,
  // );
  // const blockHandlerRef = useRef<(blockNumber: number) => void>();

  const [stakeResult, setStakeResult] = useState<
    { requestSent: boolean; spotState?: SpotState } | undefined
  >(undefined);
  const [unstakeResult, setUnstakeResult] = useState<
    { requestSent: boolean; spotState?: SpotState } | undefined
  >(undefined);

  const isAmendTypeStake = amendType === AmendType.Stake;
  const isAmendTypeUnstake = amendType === AmendType.UnStake;

  const {
    data: vaultData,
    isLoading: isLoadingVaultData,
    isError: isErrorVaultData,
    refetch: refetchVaultData,
    isRefetching: isRefetchingVaultData,
  } = useFetchSingleVaultData({
    poolId,
  });

  const {
    USDTDecimals,
    getAccountUsdtBalance,
    getAccountEthBalance,
    areReadContractsSet: areDepositsApiReadContractsSet,
  } = useDepositsAPI();

  const {
    getUsdtAllowanceForRabbitManager,
    areReadContractsSet: areElixirContractsApiReadContractSet,
    deposit,
    getSpotState,
    withdraw,
    approve: approveAPI,
    isUsdtApproved: isUsdtApprovedAPI,
    getUserShares,
  } = useElixirContractsAPI();

  // Fetch account ETH and USDT/USDB balance and USDT/USDB approval status
  const updateState = async () => {
    try {
      if (!account || !areDepositsApiReadContractsSet) return;

      const [ethBalance, balance, _usdtAllowance, _userShares] =
        await Promise.all([
          getAccountEthBalance(),
          getAccountUsdtBalance(),
          getUsdtAllowanceForRabbitManager(),
          getUserShares(account, poolId),
        ]);
      setEthBalance(ethBalance);
      setAvailableBalance(bigNumberToFloat(balance, USDTDecimals));
      setUsdtAllowance(bigNumberToFloat(_usdtAllowance, USDTDecimals));
      setUserShares(bigNumberToFloat(_userShares, USDTDecimals));
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Account changed
  useEffect(() => {
    (async () => {
      try {
        await updateState();
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [
    account,
    areDepositsApiReadContractsSet,
    areElixirContractsApiReadContractSet,
  ]);

  const resetState = async () => {
    setUsdtAllowance(0);
    setIsPendingTxSign(false);
    setIsApprovingUsdt(false);
    setIsLoading(true);
    await updateState();
  };

  const currentStep = getCurrentAmendStep(
    amendType,
    usdtAllowance,
    stakeAmount || 0,
    stakeResult?.requestSent,
    unstakeResult?.requestSent,
  );

  const enableUsdt = async () => {
    if (
      !(await validateNetworkAndSwitchIfRequired(
        `Approve ${brand.tokenTicker} again.`,
      ))
    ) {
      return;
    }

    try {
      if (!account) return;

      flushSync(() => {
        setIsPendingTxSign(true);
      });

      const tx = await approveAPI();

      flushSync(() => {
        setIsPendingTxSign(false);
        setIsApprovingUsdt(true);
      });

      await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      return true;
    } catch (e: any) {
      showNotification({
        title: t('tokenApprovalFailedTitle', { token: brand.tokenTicker }),
        description: t('tokenApprovalFailedDescription', {
          errorMessage: e.message,
          token: brand.tokenTicker,
        }),
        type: NotificationType.Negative,
      });

      return false;
    } finally {
      // Reset State here
      await resetState();
    }
  };

  const stakeUsdt = async () => {
    if (!stakeAmount || !networkProvider || !account || !vaultData) return;

    if (!(await validateNetworkAndSwitchIfRequired('Restart the deposit'))) {
      return;
    }

    mixpanelService.elixirVaultsClicked(
      account,
      'stake',
      stakeAmount,
      vaultData.tokenPair,
    );

    let sentTx: ethers.ContractTransaction | undefined = undefined;

    const handleSpotStateUpdateOnRequestSent = async (
      tx: ethers.ContractTransaction,
    ) => {
      const spotState = await getSpotState(tx.hash);

      setStakeResult({ requestSent: true, spotState });

      // Update spot state with details fetched from contract for txHash
      updateSpotInLocalStorage(
        tx.hash,
        account,
        spotState.spotStateType,
        spotState.id,
      );

      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showRequestStatusNotification({
        type: AmendType.Stake,
        amount: stakeAmount,
        tokenPair: vaultData.tokenPair,
        spotStateType: spotState.spotStateType,
        positionInQueue: spotState.positionInQueue,
        t,
      });

      return spotState;
    };

    try {
      flushSync(() => {
        setIsPendingTxSign(true);
      });

      // Check if USDT/USDB is approved for the deposit amount
      const isUsdtApproved = await isUsdtApprovedAPI(stakeAmount);
      if (!isUsdtApproved) {
        const res = await enableUsdt();
        if (!res) {
          return;
        }
      }

      const amount: BigNumber = parseUnits(
        roundJsDecimalToString(stakeAmount, USDTDecimals),
        USDTDecimals,
      );

      sentTx = await deposit(poolId, amount, account);

      // Save tx in local storage with what was inputted by user.
      saveVaultTxToLocalStorage(
        sentTx,
        SpotType.Deposit,
        account,
        stakeAmount,
        undefined,
        vaultData.routerAddress,
        SpotStateType.Empty,
        vaultData.tokenPair,
      );

      showRequestStatusNotification({
        justSubmitted: true,
        type: AmendType.Stake,
        amount: stakeAmount,
        tokenPair: vaultData.tokenPair,
        t,
      });

      flushSync(() => {
        setIsPendingTxSign(false);
        setIsProcessingRequest(true);
      });

      await sentTx.wait(1);

      mixpanelService.elixirVaultsSuccess(
        account,
        'stake',
        stakeAmount,
        vaultData.tokenPair,
      );

      try {
        const spotState = await handleSpotStateUpdateOnRequestSent(sentTx);

        if (!spotState) return; // user is not verified

        // Start tracking vault Activity
        trackVaultActivities({ spotIds: [spotState.id] });
        setStakeResult({ requestSent: true, spotState });
        modal.pop({ name: Modals.amendLiquidityModal });
      } catch (err) {
        throw new Error('UNABLE_TO_TRACK');
      }
    } catch (error: any) {
      if (!sentTx) return;
      console.log('ERROR:STAKE', error.message);
      // If error was UNABLE_TO_TRACK, we try once again, even if that isnt successfull, we throw a notification letting the user know that we couldnt the queue.
      if (error.message === 'UNABLE_TO_TRACK') {
        try {
          // Try to fetch and update spotState from contract into local storage again
          const spotState = await handleSpotStateUpdateOnRequestSent(sentTx);

          if (!spotState) return; // user is not verified

          // Start tracking vault Activity
          trackVaultActivities({ spotIds: [spotState.id] });
          setStakeResult({ requestSent: true, spotState });
        } catch (err) {
          // If unable to fetch spotState from contract, let user know, we couldn't
          showNotification({
            ...StatusTx.stakeRequestSentButUntracked(
              vaultData.tokenPair,
              stakeAmount,
              t,
            ),
            type: NotificationType.Info,
          });
          setStakeResult({ requestSent: true });
        }

        setIsPendingTxSign(false);
        setIsLoading(true);
        setUsdtAllowance(0);
        await updateState();
        return;
      }
      if (error.code === Logger.errors.TRANSACTION_REPLACED) {
        // The transaction was canceled/replaced with another unrelated one
        if (error.cancelled) {
          showNotification({
            title: t('transactionCancelledTitle'),
            description: t('transactionCancelledDescription'),
            type: NotificationType.Negative,
          });
        } else {
          showNotification({
            ...StatusTx.stakeFailed(vaultData.tokenPair, stakeAmount, t),
            type: NotificationType.Negative,
          });
        }
      }
      setStakeResult({ requestSent: false });
      setIsPendingTxSign(false);
      setIsLoading(true);
      setUsdtAllowance(0);
      await updateState();
    } finally {
      setIsPendingTxSign(false);
      setIsProcessingRequest(false);
    }
  };

  const onStake = () => {
    if (stakeResult?.requestSent) {
      modal.clear();
      return;
    }

    if (currentStep === AmendStep.ENABLE_USDT) {
      enableUsdt();
      return;
    }

    if (currentStep === AmendStep.STAKE) {
      stakeUsdt();
      return;
    }
  };

  const onUnstake = async () => {
    // isUnstakeRequestSent is undefined when it hasn't been sent
    if (unstakeResult?.requestSent !== undefined) {
      modal.clear();
      return;
    }

    if (!unStakeAmount || !account || !vaultData) return;

    const handleSpotStateUpdateOnRequestSent = async (
      tx: ethers.ContractTransaction,
    ) => {
      const spotState = await getSpotState(tx.hash);

      setUnstakeResult({ requestSent: true, spotState });

      // Update spot state with details fetched from contract for txHash
      updateSpotInLocalStorage(
        tx.hash,
        account,
        spotState.spotStateType,
        spotState.id,
      );

      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showRequestStatusNotification({
        type: AmendType.UnStake,
        amount: unStakeAmount,
        tokenPair: vaultData.tokenPair,
        spotStateType: spotState.spotStateType,
        positionInQueue: spotState.positionInQueue,
        t,
      });

      return spotState;
    };

    let sentTx: ethers.ContractTransaction | undefined = undefined;

    try {
      setIsPendingTxSign(true);

      sentTx = await withdraw(
        poolId,
        parseUnits(
          preciselyRoundDown(unStakeAmount, USDT_DECIMALS).toString(),
          USDT_DECIMALS,
        ),
      );

      // Save tx in local storage with what was inputted by user.
      saveVaultTxToLocalStorage(
        sentTx,
        SpotType.Withdraw,
        account,
        unStakeAmount,
        undefined,
        vaultData.routerAddress,
        SpotStateType.Empty,
        vaultData.tokenPair,
      );

      // Request Submitted notification
      showRequestStatusNotification({
        justSubmitted: true,
        type: AmendType.UnStake,
        amount: unStakeAmount,
        tokenPair: vaultData.tokenPair,
        t,
      });

      setIsPendingTxSign(false);
      setIsProcessingRequest(true);

      await sentTx.wait(1);

      try {
        const spotState = await handleSpotStateUpdateOnRequestSent(sentTx);

        if (!spotState) return; // user is not verified

        // Start tracking vault Activity
        trackVaultActivities({ spotIds: [spotState.id] });
        setUnstakeResult({ requestSent: true, spotState });
        modal.pop({ name: Modals.amendLiquidityModal });
      } catch (err) {
        throw new Error('UNABLE_TO_TRACK');
      }
    } catch (error: any) {
      if (!sentTx) return;
      console.log('ERROR:UNSTAKE', error.message);
      // If error was UNABLE_TO_TRACK, we try once again, even if that isnt successfull, we throw a notification letting the user know that we couldnt the queue.
      if (error.message === 'UNABLE_TO_TRACK') {
        try {
          // Try to fetch and update spotState from contract into local storage again
          const spotState = await handleSpotStateUpdateOnRequestSent(sentTx);

          if (!spotState) return; // user is not verified

          // Start tracking vault Activity
          trackVaultActivities({ spotIds: [spotState.id] });

          setUnstakeResult({ requestSent: true, spotState });
        } catch (err) {
          // If unable to fetch spotState from contract, let user know, we couldn't

          showNotification({
            ...StatusTx.unstakeRequestSentButUntracked(
              vaultData.tokenPair,
              unStakeAmount,
              t,
            ),
            type: NotificationType.Info,
          });
          setUnstakeResult({ requestSent: true });
        }

        setIsPendingTxSign(false);
        setIsLoading(true);
        setUsdtAllowance(0);
        await updateState();
        return;
      }

      if (error.code === Logger.errors.TRANSACTION_REPLACED) {
        // The transaction was canceled/replaced with another unrelated one
        if (error.cancelled) {
          showNotification({
            title: t('transactionCancelledTitle'),
            description: t('transactionCancelledDescription'),
            type: NotificationType.Negative,
          });
        } else {
          const notifTitle = 'Unstake Request Unsuccessful!';
          const notifDescription =
            'Unfortunately, your unstake request encountered an issue and could not be completed at this time.';

          showNotification({
            title: notifTitle,
            description: notifDescription,
            type: NotificationType.Negative,
          });
        }
      }
      setUnstakeResult({ requestSent: false });
      setIsPendingTxSign(false);
      setIsLoading(true);
      setUsdtAllowance(0);
      await updateState();
    } finally {
      setIsPendingTxSign(false);
      setIsProcessingRequest(false);
    }
  };
  return {
    onStake,
    onUnstake,
    currentStep,
    amendType,
    isPendingTxSign,
    isProcessingRequest,
    isApprovingUsdt,
    stakeAmount,
    userShares,
    unStakeAmount,
    vaultData,
    setAmendType,
    isLoadingVaultData,
    isErrorVaultData,
    isAmendTypeStake,
    isAmendTypeUnstake,
    stakeResult,
    setStakeAmount,
    availableBalance,
    refetchVaultData,
    isRefetchingVaultData,
    setUnStakeAmount,
    unstakeResult,
    isLoading,
  };
};
export default useAmend;
