import { LS_USER_SETTINGS } from 'constants/localStorageKeys';

/**
 * Reads a user setting from the `userSettings` object stored in local storage
 * @param key - The key of the setting to read
 * @returns The value of the user setting associated with the given key
 */
export const readUserSetting = (key: string): any => {
  const userSettingsString = localStorage.getItem(LS_USER_SETTINGS) || '{}';
  const userSettings = JSON.parse(userSettingsString);
  return userSettings[key];
};

/**
 * Saves a new user setting in the `userSettings` object stored in local storage
 * @param key - The key of the new setting
 * @param value - The value of the new setting
 */
export const saveUserSetting = (key: string, value: any) => {
  const userSettingsString = localStorage.getItem(LS_USER_SETTINGS) || '{}';
  const userSettings = JSON.parse(userSettingsString);
  const newUserSettings = { ...userSettings, [key]: value };
  localStorage.setItem('userSettings', JSON.stringify(newUserSettings));
};
