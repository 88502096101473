import { useEffect } from 'react';

import { Web3Provider } from '@ethersproject/providers';

import { useWeb3React } from '@web3-react/core';

import { NetworkContextName } from 'constants/networkContextName';

/**
 * Custom React hook for subscribing to Ethereum block updates.
 * Note: Changing callbacks dynamically is not supported with this hook.
 *
 * @param callback A function to be called when a new Ethereum block is mined.
 */
const useOnBlockUpdated = (callback: (blockNumber: number) => void) => {
  const { library: providerNetwork } =
    useWeb3React<Web3Provider>(NetworkContextName);

  useEffect(() => {
    const subscription = providerNetwork?.on('block', callback);

    return () => {
      subscription?.removeAllListeners();
    };
  }, [providerNetwork]);
};

export default useOnBlockUpdated;
