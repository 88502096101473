import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;

  .flex-grow {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
  }
`;

type SelectorContainerProps = {
  disabled?: boolean;
};
export const SelectorContainer = styled.div<SelectorContainerProps>`
  display: flex;
  position: relative;
  width: 100%;
  padding: 8px 10px;
  border-radius: 8px;
  align-items: center;

  background: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.shadesBackground700
      : theme.colors.shadesBackground900};

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    padding: 10px;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 8px;
  }

  .label {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.shadesForeground200};

    font-size: 10px;
    line-height: 14px;
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 12px;
      line-height: normal;
    }
  }

  .value {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
    font-size: 12px;
    line-height: 14px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 14px;
      line-height: 16px;
    }

    > img {
      height: 16px;
      width: 16px;
      border-radius: 4px;
    }
  }

  .disabled-badge {
    display: flex;
    position: absolute;
    right: 5px;
    top: -7px;
    padding: 4px 6px;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.goldGradient200};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    color: ${({ theme }) => theme.colors.warningForeground100};

    font-size: 10px;
    line-height: 14px;
  }

  .arrow-icon {
    height: 16px;
    width: 16px;
  }
`;

type SelectorOptionProps = {
  selected: boolean;
};
export const SelectorOption = styled.span<SelectorOptionProps>`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 14px;
  line-height: 16px;
  padding: 10px;
  border-radius: 8px;
  gap: 5px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.shadesForeground200};
  background: ${({ theme, selected }) =>
    selected ? theme.colors.shadesBackground700 : 'transparent'};

  > img {
    width: 16px;
    height: 16px;
  }
`;
