import { ChangeEvent, useEffect, useState } from 'react';

import { Checkbox, FormGroup } from '@material-ui/core';

import { Icon } from 'components';
import Text from 'components/Text';
import CheckBox from 'components/Toggle/Checkbox';

import OrderTypesCheckboxes from './TimeInForceCheckboxes';
import {
  AdvancedOptionsStyled,
  DropdownStyled,
  FormControlLabelStyled,
} from './styles';
import chevronDown from 'assets/icons/chevron-down-gray.svg';
import chevronUp from 'assets/icons/chevron-up-gray.svg';
import { useAppContext } from 'contexts/AppContext';

import { OrderType } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type Props = {
  orderType: OrderType;
  className?: string;
};
const AdvancedOptions = ({ orderType, className }: Props) => {
  const {
    store: {
      appState: {
        disableOrderConfirmationModal,
        setDisableOrderConfirmationModal,
      },
    },
  } = useAppContext();
  const { t } = useTranslation();

  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <AdvancedOptionsStyled
        className={className}
        onClick={() => setIsShown(prev => !prev)}
        isOpen={isShown}
      >
        <Text
          variant="BODY_S"
          color="shadesForeground200"
          flexed
          className="justify-between"
        >
          {t('advanced')}
          <Icon src={isShown ? chevronUp : chevronDown} size="M" />
        </Text>

        {isShown ? (
          <DropdownStyled>
            <CheckBox
              isChecked={!disableOrderConfirmationModal}
              label={t('enableConfirmation')}
              onClick={event => {
                event.stopPropagation();
                setDisableOrderConfirmationModal(
                  !disableOrderConfirmationModal,
                );
              }}
            />
            <OrderTypesCheckboxes orderType={orderType} key={orderType} />
          </DropdownStyled>
        ) : null}
      </AdvancedOptionsStyled>
    </>
  );
};

export default observer(AdvancedOptions);
