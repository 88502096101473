import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) =>
    theme.colors.shadesBackground700TransparentChart};
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  .recharts-wrapper {
    width: 100% !important;
  }

  .recharts-surface {
    width: 100% !important;
    display: block;
  }

  .chart {
    display: flex;
    flex: 1;
    margin: 0px -5px;
    overflow: hidden;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 20px;
  align-items: flex-start;
  flex-direction: column;

  .timescale {
    display: flex;
    flex-basis: 0;
    flex-wrap: wrap;

    padding: 6px;
    margin-top: 10px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.shadesBackground700};

    button {
      border-radius: 6px;
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      font-size: 11px;
      text-transform: uppercase;
      background: transparent;
      color: ${({ theme }) => theme.colors.shadesForeground200};
      padding: 8px 15px;

      &.selected {
        background: ${({ theme }) => theme.colors.shadesBackground900};
      }

      &.selected,
      &:hover {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    flex-direction: row;
    justify-content: space-between;

    .timescale {
      margin-top: 0px;
      flex-wrap: unset;
    }
  }
`;
