import { FormattedNumber } from 'components';

import LockOption from './LockOptionBoxLayout';
import shareEarnIcon from 'assets/icons/rbx-airdrop/share-earn-blurred.webp';
import shareEarnBg from 'assets/images/shareEarnBg.webp';

import { observer } from 'mobx-react';

const LpAndEarnOptionBox = () => {
  return (
    <LockOption
      stats={[
        { label: 'XXXX XXXXXXXXXX', value: '[REDACTED]' },
        {
          label: 'XXXX XXXX',
          value: <FormattedNumber value={123} decimalScale={0} />,
        },
        {
          label: 'XXXX XXXX',
          value: <FormattedNumber value={5113} decimalScale={0} />,
        },
        {
          label: 'XXXX XXXX',
          value: <FormattedNumber value={812023} decimalScale={0} />,
        },
      ]}
      ctaProps={{
        title: 'Locked',
        disabled: true,
      }}
      title={'XXXXX & XXXXX'}
      description={
        'XXXXX xxxxxxxx xxxxx XXX (XxxxxXxxxxxx). Xxxxx xxxx xxxxx xxx XXX Xxxxx.'
      }
      headerBanner={shareEarnBg}
      isActive={false}
      isComingSoon={true}
      headerIcon={shareEarnIcon}
    />
  );
};

export default observer(LpAndEarnOptionBox);
