import { memo, useCallback } from 'react';

import {
  TableCell,
  TableRow,
  TableHead as TableHeadMui,
} from '@material-ui/core';

import useModal from 'hooks/useModal';

import SortStatusDoubleCaret from '../components/SortStatusDoubleCaret';
import { OrderSortConfig } from './LimitAndMarketOrders';
import { Row } from 'theme/globalStyledComponents/row';

import { SelectedOrdersType, SortBy } from '.';
import { useTranslation } from 'react-i18next';

interface Props {
  onSetSortBy?: (sortBy: SortBy) => void;
  selectedOrdersType: SelectedOrdersType;
  sortConfig: OrderSortConfig | null;
}
const TableHead = ({ onSetSortBy, sortConfig, selectedOrdersType }: Props) => {
  const { t } = useTranslation();

  const SortStatusIcon = ({ sortBy }: { sortBy: SortBy }) => (
    <SortStatusDoubleCaret
      isSorted={sortBy === sortConfig?.sortBy}
      numOfClicks={sortConfig?.numOfClicks}
    />
  );

  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>{t('market')}</TableCell>
        {/* <TableCell
          key={1}
          className="cursor-pointer"
          onClick={() => onSetSortBy && onSetSortBy(SortBy.SIDE)}
        >
          <span className="cell-span">
            {t('side')}
            <img
              src={caretDownGray}
              alt="Side Caret Down"
              onClick={() => onSetSortBy && onSetSortBy(SortBy.SIDE)}
            />
          </span>
        </TableCell> */}
        <TableCell key={1}>{t('orderSize')}</TableCell>
        <TableCell
          key={2}
          className="cursor-pointer select-none"
          onClick={() => onSetSortBy && onSetSortBy(SortBy.NOTIONAL)}
        >
          <Row gap={5}>
            {t('value')}
            <SortStatusIcon sortBy={SortBy.NOTIONAL} />
          </Row>
        </TableCell>

        <TableCell key={3}>{t('type')}</TableCell>

        <TableCell key={4}>{t('price')}</TableCell>

        {selectedOrdersType === 'STOP_ORDERS' && (
          <TableCell key={5}>{t('triggerPrice')}</TableCell>
        )}

        <TableCell
          key={6}
          className="cursor-pointer select-none"
          onClick={() => onSetSortBy && onSetSortBy(SortBy.STATUS)}
        >
          <Row gap={5}>
            {t('status')}
            <SortStatusIcon sortBy={SortBy.STATUS} />
          </Row>
        </TableCell>
        {/* <TableCell key={8}>{t('leverage')}</TableCell> */}
        <TableCell key={7}>{t('filledTotal')}</TableCell>
        <TableCell key={8} align="right">
          {t('time')}
        </TableCell>

        <TableCell key={9}></TableCell>
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
