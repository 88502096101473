import { ChangeEvent, useState } from 'react';
import React from 'react';

import {
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { StyledTable } from 'components/Tables/styles';

import deleteIcon from 'assets/icons/trash.svg';

import { flushSync } from 'react-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  h5.heading {
    font-size: 16px;
    line-height: 24px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    margin-bottom: 10px;
  }

  p.description {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.shadesForeground200};
    margin-bottom: 20px;
  }
`;

const AddMoreCell = styled(TableCell)`
  text-align: left !important;
  span {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    font-size: 14px;
  }
`;

const IpInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  &::placeholder {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

type Props = {
  showHeader?: boolean;
  handleAllowedIpListAdd?: (ip: string) => void;
  handleDeleteAllowedIp?: (ip: string) => void;
  handleAllowedIpListChange?: (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  allowedIps: string[];
};
const IpRestrictions = ({
  showHeader = true,
  handleAllowedIpListAdd,
  handleDeleteAllowedIp,
  handleAllowedIpListChange,
  allowedIps,
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);

  const firstRowInputRef = React.useRef<HTMLInputElement>(null);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  return (
    <Container>
      {showHeader && <h5 className="heading">IP Restrictions</h5>}
      <p className="description">
        We strongly recommend restricted access to trusted IPs only. By leaving
        the field below empty, you are allowing for unrestricted access to your
        API Key.
      </p>

      <StyledTable stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell key={0}>Address</TableCell>
            {handleAllowedIpListAdd && <TableCell key={2}>Edit</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {handleAllowedIpListAdd && (
            <TableRow
              onClick={() => {
                flushSync(() => {
                  handleAllowedIpListAdd('');
                });
                firstRowInputRef.current?.focus();
                // change page to the next if the new ip is added to the last row
                // if (
                //   allowedIps.length % rowsPerPage === 0 &&
                //   allowedIps.length > 1
                // ) {
                //   setPage(page + 1);
                // }
              }}
            >
              <AddMoreCell colSpan={3} key={0} align="left">
                <span className="cursor-pointer">+ Add More</span>
              </AddMoreCell>
            </TableRow>
          )}

          {allowedIps.length === 0 && (
            <TableRow>
              <TableCell colSpan={3} key={0} align="left">
                No IP restrictions
              </TableCell>
            </TableRow>
          )}

          {allowedIps
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => {
              const absoluteIndex = page * rowsPerPage + index;
              return (
                <TableRow key={`${absoluteIndex}`}>
                  <TableCell key={0}>
                    <IpInput
                      ref={absoluteIndex === 0 ? firstRowInputRef : null}
                      type="text"
                      value={item}
                      onChange={e => {
                        handleAllowedIpListChange &&
                          handleAllowedIpListChange(e, absoluteIndex);
                      }}
                    />
                  </TableCell>
                  {handleAllowedIpListAdd && (
                    <TableCell key={1}>
                      <img
                        src={deleteIcon}
                        alt="delete"
                        className="cursor-pointer"
                        onClick={() => {
                          handleDeleteAllowedIp && handleDeleteAllowedIp(item);
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </StyledTable>
      <TablePagination
        component="div"
        count={allowedIps.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      />
    </Container>
  );
};

export default IpRestrictions;
