import styled from 'styled-components';

type ProgressContainerProps = {
  size: number;
};

export const ProgressContainer = styled.div<ProgressContainerProps>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  position: relative;
  .children {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
  }

  svg {
    transform: rotate(-90deg);
  }
`;
