import styled from 'styled-components';

export type ContainerProps = {
  backgroundColor?: string;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 2px;
  border-radius: 100%;
  flex-direction: column;
  margin-bottom: 4px; // height adjustment for the label overflow
  position: relative;
  background: ${({ theme }) => theme.colors.goldGradient100};

  .avatar {
    border-radius: 100%;
    border: 6px solid;
    border-color: ${({ theme, backgroundColor }) =>
      backgroundColor || theme.colors.shadesBackground900};

    height: 60px;
    width: 60px;
  }

  .label {
    display: flex;
    position: absolute;
    align-self: center;
    bottom: -4px;
    padding: 4px 11px;
    border-radius: 50px;
    border: 3px solid
      ${({ theme, backgroundColor }) =>
        backgroundColor || theme.colors.shadesBackground900};
    background: ${({ theme }) => theme.colors.goldGradient100};
    justify-content: center;
    align-items: center;

    text-transform: uppercase;
    font-size: 12px;
    line-height: normal;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;
