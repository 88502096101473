import { memo } from 'react';

import { isMobileDevice } from 'utils';
import { brand, isBrandRabbitX } from 'utils/brand';

import useModal from 'hooks/useModal';

import { Icon } from 'components';
import Badge from 'components/Badge';
import Text from 'components/Text';

import News from './News';
import { Col, Container } from './styles';
import infoIcon from 'assets/icons/info-circle-white.svg';
import blastLogo from 'assets/logos/blast-logo.svg';
import starknetLogo from 'assets/logos/starknet-logo.svg';
import { useAppContext } from 'contexts/AppContext';
import { Color } from 'interfaces/theme';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type DotProps = {
  bgColor: Color;
};
const StyledDot = styled.div<DotProps>`
  width: 3px;
  height: 3px;
  border-radius: 100px;
  background: ${({ theme, bgColor }) => theme.colors[bgColor]};
`;

const Footer = ({ isPadded = true }: { isPadded?: boolean }) => {
  const {
    store: {
      appState: { setWentThroughtGetStarted, hasWentThroughGetStarted },
    },
  } = useAppContext();
  const { t } = useTranslation();

  return (
    <Container isPadded={isPadded}>
      <Col className="first">
        {isMobileDevice() ? (
          <Text variant="BODY_S" color="shadesForeground200">
            {brand.appShortName} © 2024
          </Text>
        ) : (
          <Text
            variant="BODY_M"
            flexed
            gap={5}
            fontWeight="semiBold"
            onClick={() => setWentThroughtGetStarted(!hasWentThroughGetStarted)}
            cursorPointer
            noWrap
          >
            <Icon src={infoIcon} />
            {t('getStarted')}
          </Text>
        )}
      </Col>
      <Col className="center">
        <News />
      </Col>
      <Col className="last">
        <Text flexed variant="BODY_XS" color="shadesForeground200" noWrap>
          {t('poweredBy')}
          {isBrandRabbitX ? (
            <img className="logo" src={starknetLogo} alt="Starknet logo" />
          ) : (
            <img className="logo" src={blastLogo} alt="Blast logo" />
          )}
        </Text>
      </Col>
    </Container>
  );
};

export default observer(Footer);
