import { brand } from 'utils/brand';

import useKey, { Key } from 'hooks/useKey';

import Button from 'components/Button';

import { Method } from '../Steps/DepositStep/DepositMethods/styles';
import { ActionSectionStyled } from '../Steps/styles';
import {
  DepositSteps,
  getIsMethodTransak,
  isTargetToken,
  SwapSteps,
} from '../utils';

import { DepositMethod, UniswapSwapTokens } from 'enums';
import { useTranslation } from 'react-i18next';

export const getSwapButtonLabel = ({
  step,
  t,
  selectedToken,
}: {
  step: SwapSteps;
  t: any;
  selectedToken: UniswapSwapTokens;
}) => {
  switch (step) {
    case SwapSteps.APPROVE_TOKEN:
      return `Approve ${selectedToken}`;
    case SwapSteps.APPROVING_TOKEN:
      return `Approving ${selectedToken}...`;
    case SwapSteps.PENDING_TX_SIGN:
      return `Pending...`;
    case SwapSteps.SWAP:
      return 'Swap';
    case SwapSteps.SWAPPING:
      return 'Swapping...';
    default:
      break;
  }
};

const getDepositButtonLabel = ({
  step,
  t,
  depositMethod,
}: {
  step: DepositSteps;
  depositMethod: DepositMethod;
  t: any;
}) => {
  const isStepPostPayment = [
    DepositSteps.SUCCESS,
    DepositSteps.PROCESSING_DEPOSIT,
  ].includes(step);

  if (DepositMethod.BinancePay === depositMethod && !isStepPostPayment) {
    if (step === DepositSteps.BINANCE_PAY_CHECKOUT) return t('done');
  }

  if (DepositMethod.Mesh === depositMethod) {
    if (step === DepositSteps.MESH_ORDER_CHECKOUT) return t('done');
  }

  if (
    getIsMethodTransak(depositMethod) &&
    ![
      DepositSteps.SUCCESS,
      DepositSteps.PROCESSING_DEPOSIT,
      DepositSteps.AWAITING_CONFIRMATION,
      DepositSteps.WAITING_FOR_DELIVERY_FROM_TRANSAK,
      DepositSteps.TRANSAK_IS_PROCESSING_ORDER,
    ].includes(step)
  ) {
    return t('depositNow');
  }

  switch (step) {
    case DepositSteps.ENABLE_USDT:
      return t('enable') + ' ' + brand.tokenTicker;
    case DepositSteps.APPROVING_USDT:
      return t('approving') + ' ' + brand.tokenTicker + '...';
    case DepositSteps.PENDING_TX_SIGN:
      return t('depositingDot');
    case DepositSteps.AWAITING_CONFIRMATION:
      return t('awaitingConfirmationDot');
    case DepositSteps.TRANSAK_IS_PROCESSING_ORDER:
      return 'Transak is processing your order...';
    case DepositSteps.WAITING_FOR_DELIVERY_FROM_TRANSAK:
      return 'Waiting for delivery from Transak...';
    case DepositSteps.PROCESSING_DEPOSIT:
      return t('processingDot');
    case DepositSteps.DEPOSIT:
      return t('deposit') + ' ' + brand.tokenTicker;
    case DepositSteps.BINANCE_PAY_CHECKOUT:
      return 'Done';
    case DepositSteps.SUCCESS:
      return t('startTrading');
    default:
      break;
  }
};

const getDepositButtonGTMId = (step: DepositSteps) => {
  if (step === DepositSteps.ENABLE_USDT) return 'button-enable-usdt';
  if (step === DepositSteps.DEPOSIT) return 'button-deposit-usdt';
  return '';
};

const getSwapButtonDataGtmId = (step: SwapSteps, token: UniswapSwapTokens) => {
  if (step === SwapSteps.APPROVE_TOKEN)
    return `button-approve-${token.toLowerCase()}`;
  if (step === SwapSteps.SWAP) return `button-swap-${token.toLowerCase()}`;
  return '';
};

interface Props {
  proceedToNextDepositStep: (step: DepositSteps) => void;
  proceedToNextSwapStep: (step: SwapSteps) => void;
  step: DepositSteps;
  swapStep: SwapSteps;
  shouldDisableActionBtn: boolean;
  selectedToken: UniswapSwapTokens;
  shouldDisableSwapBtn: boolean;
  depositMethod: DepositMethod;
  isLoadingCta: boolean;
}

export const ActionSection = ({
  proceedToNextDepositStep,
  proceedToNextSwapStep,
  step,
  swapStep,
  shouldDisableActionBtn,
  selectedToken,
  shouldDisableSwapBtn,
  depositMethod,
  isLoadingCta,
}: Props) => {
  const { t } = useTranslation();
  const depositButtonLabel = getDepositButtonLabel({
    step,
    t,
    depositMethod,
  });
  const swapButtonLabel = getSwapButtonLabel({
    step: swapStep,
    t,
    selectedToken,
  });

  const isTargetTokenSelected = isTargetToken(selectedToken);

  useKey(Key.Enter, () => {
    if (isTargetTokenSelected) {
      proceedToNextDepositStep(step);
    } else {
      proceedToNextSwapStep(swapStep);
    }
  });

  return (
    <ActionSectionStyled>
      {!isTargetTokenSelected && (
        <Button
          size="M"
          block
          variant="primaryGreen"
          onClick={() => proceedToNextSwapStep(swapStep)}
          disabled={shouldDisableSwapBtn}
          data-gtmid={getSwapButtonDataGtmId(swapStep, selectedToken)}
        >
          {swapButtonLabel}
        </Button>
      )}
      {isTargetTokenSelected && (
        <Button
          data-gtmid={getDepositButtonGTMId(step)}
          size="M"
          block
          variant="primaryGreen"
          onClick={() => {
            proceedToNextDepositStep(step);
          }}
          disabled={shouldDisableActionBtn}
          isLoading={isLoadingCta}
        >
          {depositButtonLabel}
        </Button>
      )}
    </ActionSectionStyled>
  );
};
