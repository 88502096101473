import styled from 'styled-components';

export const EmptyProgressBar = styled.div`
  background-color: ${({ theme }) => theme.colors.shadesBackground700};
  border-radius: 100px;
  height: 6px;
  overflow: hidden;
  position: relative;
`;

export const FillingProgressBar = styled.div`
  border-radius: 100px;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const MainProgressBarDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
`;
