import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  transform-style: preserve-3d;
  flex-shrink: 0;
  transform: rotateY(180deg);
  width: 132px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
    width: 123px;
  }

  &.flipped {
    animation: cardFlip 0.275s forwards linear;
  }

  &.unflipped {
    animation: cardUnFlip 0.275s forwards linear;
  }

  @keyframes cardFlip {
    0% {
      transform: rotateZ(0deg) rotateY(180deg);
    }
    50% {
      transform: rotateZ(-10deg) rotateY(90deg);
    }
    100% {
      transform: rotateZ(0deg) rotateY(0deg);
    }
  }

  @keyframes cardUnFlip {
    0% {
      transform: rotateZ(0deg) rotateY(0deg);
    }
    50% {
      transform: rotateZ(-10deg) rotateY(90deg);
    }
    100% {
      transform: rotateZ(0deg) rotateY(180deg);
    }
  }

  .card-front {
    position: absolute;
    backface-visibility: hidden;
    transform: rotateY(180deg);
  }

  .card-back {
    position: absolute;
    backface-visibility: hidden;
  }

  .card-front {
    &::before,
    &::after {
      content: '';
      position: absolute;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .column {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-self: stretch;
    padding: 0px 10px;
    margin-bottom: 10px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      gap: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;

      @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
        gap: 2px;
      }
    }
  }

  .text {
    gap: 3px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 12px;
    line-height: 14px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 10px;
    }
  }

  .text-s {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 10px;
    line-height: 14px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      font-size: 8px;
      line-height: normal;
    }
  }

  .icon-usdt {
    height: 16px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      height: 14px;
    }
  }
`;
