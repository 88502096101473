import { memo } from 'react';

import { useVerifyChainId } from 'hooks';
import { VaultWithContractInfo } from 'hooks/useElixirVaults';
import useModal from 'hooks/useModal';

import { ViewType } from '../../../../../../enums';
import { VaultWalletShares } from '../../../ElixirVaults';
import VaultGrid from './Grid';
import VaultTable from './Table';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { VaultManager } from 'enums/vaultManager';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import AmendLiquidityModal, {
  AmendType,
} from 'pages/Vaults/common/AmendLiquidityModal';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type Props = {
  viewType: ViewType;
  vaults: VaultWithContractInfo[];
  vaultsWalletShares?: VaultWalletShares;
  isLoading: boolean;
  vaultManager: VaultManager;
  initialVaultId?: number;
  maxApy?: number;
};
const List = ({ vaultManager, ...rest }: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();
  const { validateNetworkAndDoAction } = useVerifyChainId();

  const modal = useModal();

  const showLiquidityModal = (amendType: AmendType, poolId: number) => {
    validateNetworkAndDoAction(() => {
      modal.present(
        <AmendLiquidityModal defaultAmendType={amendType} poolId={poolId} />,
        Modals.amendLiquidityModal,
      );
    }, t('youCanNowStakeUnstakeFunds'));
  };

  const onConnectWallet = () =>
    modal.present(<WalletModal />, Modals.walletModal);

  const isUserAuthenticated = !!frontendSecrets?.jwt;

  return rest.viewType === ViewType.GRID ? (
    <VaultGrid
      isUserAuthenticated={isUserAuthenticated}
      showLiquidityModal={showLiquidityModal}
      onConnectWallet={onConnectWallet}
      manager={vaultManager}
      {...rest}
    />
  ) : (
    <VaultTable
      isUserAuthenticated={isUserAuthenticated}
      showLiquidityModal={showLiquidityModal}
      onConnectWallet={onConnectWallet}
      manager={vaultManager}
      {...rest}
    />
  );
};

export default observer(List);
