import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    display: flex;
    gap: 20px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    gap: 50px;
  }
`;

export const ConnectWalletContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  .header {
    font-size: 16px;
    line-height: 24px;
  }
  .description {
    margin: 15px 0 20px 0;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

type ContentProps = {
  isExpanded: boolean;
};
export const Content = styled.div<ContentProps>`
  color: ${({ theme }) => theme.colors.shadesForeground200};
  font-size: 12px;
  line-height: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  opacity: 1;
  max-height: 1000px;
  overflow: hidden;
  transition: opacity 0.2s ease-out, max-height 0.5s ease-out;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    font-size: 14px;
    line-height: 16px;
  }

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      opacity: 0;
      max-height: 0;
    `}
`;
