import bigDecimal from 'js-big-decimal';

/**
 * Rounds a number down to the nearest multiple of a specified base.
 *
 * @param num - The number to be rounded down.
 * @param base - The base to round down to. This should be a positive integer.
 *
 * @example
 * // Returns 250100
 * console.log(roundDown(250111, 100));
 *
 * @example
 * // Returns 250000
 * console.log(roundDown(250111, 1000));
 */
export const roundDown = (num: number, base: number): number => {
  // console.log('rounddown', num, '==', Math.floor(num / base) * base);
  return Math.floor(num / base) * base;
};

/**
 * Checks if a number is within a specified range.
 *
 * @param {number} number - The number to be checked.
 * @param {number[]} range - The range specified as an array [min, max].
 * @returns {boolean} - True if the number is within the range, false otherwise.
 */
export const isNumberInRange = (
  number: number,
  range: [number, number],
): boolean => {
  const [min, max] = range;
  return number >= min && number <= max;
};

export const MAX_FLOAT_DECIMALS = 12; // Float supports upto 14 decimal points, anything above it automatically rounded

/**
 * Avoids auto ciel roudning on a number when converted to float and rounds down.
 *
 * @param num - The number to be rounded down.
 * @param presision - The precision of the resulting number. (should be less than 14, else may lead to rounding errors)
 *
 */
export const preciselyRoundDown = (
  num: number,
  precision = MAX_FLOAT_DECIMALS,
) => {
  const parsed = new bigDecimal(num.toPrecision(100)).round(
    precision,
    bigDecimal.RoundingModes.FLOOR,
  );

  return parseFloat(parsed.getValue());
};

export const prettyFormatNumber = (number: number) => {
  // Handle non-numeric input
  if (isNaN(number)) {
    return number.toString();
  }

  // Split the number string into integer and decimal parts
  const [integerPart, decimalPart] = number.toString().split('.');

  // Add thousand separators
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ',',
  );

  // Combine formatted integer and decimal parts
  const formattedNumber = decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;

  return formattedNumber;
};
