import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .primary-button {
    margin-top: 10px;
    padding: 8px 14px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      padding: 12px 20px;
    }
  }
`;

export const Steps = styled.div`
  display: flex;
  padding: 10px 15px;
  align-items: flex-start;
  border-radius: 12px;
  flex-direction: column;
  gap: 2px;
  background: ${({ theme }) => theme.colors.shadesBackground700};

  .step {
    display: flex;
    padding: 10px 0px;
    align-items: center;
    gap: 15px;
    align-self: stretch;

    .column {
      display: flex;
      flex-direction: column;
      gap: 2px;

      > span:first-child {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        font-size: 12px;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      > span:last-child {
        color: ${({ theme }) => theme.colors.shadesForeground200};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        font-size: 10px;
        line-height: normal;

        @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
          font-size: 12px;
          line-height: normal;
        }
      }
    }

    .loading-icon-container {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};

    font-size: 14px;
    line-height: 16px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 20px;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    > img {
      display: flex;
      width: 22px;
      height: 20px;
      padding: 2px 1px;
    }
  }

  .back-button {
    display: flex;
    gap: 5px;
    color: ${({ theme }) => theme.colors.shadesForeground200};
    padding: 7px;
    font-size: 12px;
    line-height: normal;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      padding: 10px 12px;
      font-size: 14px;
      line-height: 16px;
    }

    > img {
      height: 14px;
      width: 14px;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
