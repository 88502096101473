import styled from 'styled-components';

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  .asset-logo {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
  }

  tr {
    font-style: normal;
    font-size: 14px;
    line-height: 15px;
    border-top: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
    color: ${({ theme }) => theme.colors.white};

    td:first-child,
    th:first-child {
      padding-left: 20px;

      min-width: 105px;

      @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
        min-width: 120px;
      }
      @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
        min-width: initial;
      }
    }

    td,
    th {
      padding: 7px 5px;
      height: 24px;
      img {
        cursor: pointer;
        margin-left: 8px;
      }

      .MuiCheckbox-root {
        margin-right: 5px;
        padding: 0;
      }
    }

    th {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }
  }

  thead tr {
    text-align: left;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  th:first-child {
    color: ${({ theme }) => theme.colors.warningForeground100};
  }

  td:first-child {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  td.no-items {
    font-weight: normal;
  }

  td.action-btns {
    display: flex;
    gap: 10px;
    img {
      width: 15px;
    }
  }

  button {
    height: 22px;
  }

  svg {
    cursor: pointer;
  }
`;

export enum Colors {
  Success = 'Success',
  Danger = 'Danger',
  Info = 'Info',
  Warning = 'Warning',
  Inherit = 'Inherit',
  White = 'White',
}

interface ColoredTextProps {
  color: Colors;
  isUnderlined?: boolean;
  isBold?: boolean;
}
export const ColoredText = styled.span<ColoredTextProps>`
  display: inline-block;
  text-decoration: ${({ isUnderlined }) =>
    isUnderlined ? 'underline' : 'none'};
  font-weight: ${({ isBold, theme }) =>
    isBold ? theme.fontWeights.medium : 'inherit'};
  color: ${({ color, theme }) =>
    color === Colors.Success
      ? theme.colors.positiveForeground200
      : color === Colors.Danger
      ? theme.colors.negativeForeground200
      : color === Colors.Warning
      ? theme.colors.warningForeground100
      : color === Colors.Info
      ? '#569085'
      : color === Colors.White
      ? theme.colors.white
      : color === Colors.Inherit
      ? 'inherit'
      : theme.colors.shadesForeground200} !important;
`;

interface TradeSideLabelProps {
  isSuccess?: boolean;
}
export const TradeSideLabel = styled.span<TradeSideLabelProps>`
  font-size: 14px;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  /* background: ${({ isSuccess }) =>
    isSuccess ? 'rgba(13, 203, 146, 0.25)' : 'rgba(224, 64, 64, 0.2)'}; */
  color: ${({ isSuccess, theme }) =>
    isSuccess
      ? theme.colors.positiveForeground200
      : theme.colors.negativeForeground200};
  border-radius: 4px;
`;
