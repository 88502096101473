import styled from 'styled-components';

export const Body = styled.div`
  width: calc(100vw - 40px);

  @media (min-width: 470px) {
    width: 430px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    width: 490px;
  }
`;

export const Illustration = styled.div`
  background: linear-gradient(
    255deg,
    #628bc0 -0.04%,
    #7e858b 53.5%,
    #b17155 94.28%
  );
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
  align-self: stretch;
  position: relative;
  overflow: hidden;
  height: 250px;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    /* object-fit: cover; */
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  gap: 20px;
  padding: 7.5%;

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    padding: 30px;
  }

  .divider {
    width: 56px;
    height: 1px;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.shadesBackground500};
  }
`;
