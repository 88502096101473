export enum DepositOrWithdrawalStatus {
  Pending = 'pending',
  Claimable = 'claimable',
  Claiming = 'claiming',
  Success = 'success',
  Canceled = 'canceled',
  Processing = 'processing',
  // from v1
  Failed = 'failed',
  Transferring = 'transferring',

  // from stake, unstake_shares Ops Type
  Requested = 'requested',
}
