import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    gap: 10px;
  }

  .title {
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: 16px;
  }

  .button {
    margin-top: 20px;
  }
`;

type SlippageOptionProps = {
  isFocused: boolean;
};
export const SlippageOption = styled.span<SlippageOptionProps>`
  display: flex;
  cursor: pointer;
  padding: 15px;
  border-radius: 8px;
  box-sizing: border-box;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme, isFocused }) =>
    isFocused ? theme.colors.white : theme.colors.shadesForeground200};
  border: 1.5px solid
    ${({ theme, isFocused }) =>
      isFocused ? theme.colors.white : theme.colors.shadesBackground500};
  font-size: 14px;
  line-height: 16px;

  &:hover {
    border: 1.5px solid
      ${({ theme, isFocused }) =>
        isFocused ? theme.colors.white : theme.colors.shadesForeground400};
  }
`;

type SlippageInputProps = {
  customSelected: boolean;
};
export const SlippageInput = styled.input<SlippageInputProps>`
  outline: none;
  padding: 15px;
  display: flex;
  flex: 1;
  box-sizing: border-box;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 14px;
  line-height: 16px;
  border-radius: 8px;
  background: transparent;
  color: ${({ theme, customSelected }) =>
    customSelected ? theme.colors.white : theme.colors.shadesForeground200};
  -moz-appearance: textfield;

  border: 1.5px solid
    ${({ theme, customSelected }) =>
      customSelected ? theme.colors.white : theme.colors.shadesBackground500};

  &:hover {
    border: 1.5px solid
      ${({ theme, customSelected }) =>
        customSelected ? theme.colors.white : theme.colors.shadesForeground400};
  }

  &:focus {
    border: 1.5px solid ${({ theme }) => theme.colors.white};
    /* color: ${({ theme }) => theme.colors.white}; */

    &:hover {
      border: 1.5px solid ${({ theme }) => theme.colors.white};
    }
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  ::placeholder {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

export const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.negativeForeground200};
  white-space: pre-wrap;
`;

export const WarningText = styled.span`
  color: ${({ theme }) => theme.colors.warningForeground200};
  white-space: pre-wrap;
`;
