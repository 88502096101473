import { PriceChangeType } from 'enums/priceChangeType';

export const defaultMarket = {
  id: 'BTC-USD',
  marketID: 'BTC-USD',
  name: 'Bitcoin',
  maxOrderSize: 0,
  minOrder: 0,
  minTick: 1,
  enabled: false,
  ask: 0,
  bid: 0,
  lastFunding: 0,
  marketPrice: 0,
  lastTradePrice: 0,
  indexPrice: 0,
  change1h: 0,
  change24h: 0,
  volume24h: 0,
  high24h: 0,
  low24h: 0,
  percentChangeType: PriceChangeType.Positive,
  lastTradePrice24hChangeBasis: 0,
  marketPrice24hHigh: 0,
  marketPrice24hLow: 0,
  marketVolume24hChangeBasis: 0,
  marketVolume24h: 0,
  lastFundingRateBasis: 0,
  lastTradePrice24hChangePremium: 0,
  maintenanceMargin: 0,
  fairPrice: 0,
};

export const mockMarkets: any[] = [
  {
    id: 'BTC-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 1,
    minOrder: 0.0001,
    bestBid: 36259,
    bestAsk: 36267,
    marketPrice: 36263,
    indexPrice: 36249,
    lastTradePrice: 36283,
    fairPrice: 36269,
    instantFundingRate: 0.00010650959732249502,
    lastFundingRateBasis: 0.00007298138365119603,
    lastTradePrice24hChangePremium: -607,
    lastTradePrice24hChangeBasis: -0.016462802744704512,
    marketPrice24hHigh: 37064,
    marketPrice24hLow: 35941,
    marketVolume24h: 186735692.3632,
    marketVolume24hChangePremium: 56834289.4982,
    marketVolume24hChangeBasis: 0.4375186737379967,
    percentChangeType: PriceChangeType.Negative,
    name: 'Bitcoin',
    openInterest: 20,
    longRatio: 30,
    shortRatio: 70,
  },
  {
    id: 'ETH-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.1,
    minOrder: 0.001,
    bestBid: 2047.1,
    bestAsk: 2047.3,
    marketPrice: 2047.2,
    indexPrice: 2046.8,
    lastTradePrice: 2047.1,
    fairPrice: 2047.2,
    instantFundingRate: 0.0000494911670082018,
    lastFundingRateBasis: 0.00003327297751379868,
    lastTradePrice24hChangePremium: -62.9,
    lastTradePrice24hChangeBasis: -0.029811839423669367,
    marketPrice24hHigh: 2143.4,
    marketPrice24hLow: 2024.9,
    marketVolume24h: 156345938.476,
    marketVolume24hChangePremium: 7206331.1954,
    marketVolume24hChangeBasis: 0.04831936550457442,
    percentChangeType: PriceChangeType.Negative,
    name: 'Ethereum',
    openInterest: 20,
  },
  {
    id: 'SOL-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 0.01,
    bestBid: 56.4141,
    bestAsk: 56.5862,
    marketPrice: 56.50015,
    indexPrice: 56.465,
    lastTradePrice: 56.5642,
    fairPrice: 56.5193,
    instantFundingRate: 0.00013888203348594143,
    lastFundingRateBasis: 0.00013189340218612789,
    lastTradePrice24hChangePremium: 0.6547,
    lastTradePrice24hChangeBasis: 0.011716722682157723,
    marketPrice24hHigh: 58.1872,
    marketPrice24hLow: 51.4133,
    marketVolume24h: 87874915.435605,
    marketVolume24hChangePremium: -5861514.992979,
    marketVolume24hChangeBasis: -0.06253187758674869,
    percentChangeType: PriceChangeType.Positive,
    name: 'Solana',
    openInterest: 20,
  },
  {
    id: 'ARB-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 1,
    bestBid: 1.0986,
    bestAsk: 1.0997,
    marketPrice: 1.09915,
    indexPrice: 1.0987,
    lastTradePrice: 1.0996,
    fairPrice: 1.0989,
    instantFundingRate: 0.00006002288457432001,
    lastFundingRateBasis: 0.00003865177257365986,
    lastTradePrice24hChangePremium: -0.0861,
    lastTradePrice24hChangeBasis: -0.07263371014003712,
    marketPrice24hHigh: 1.1963,
    marketPrice24hLow: 1.0754,
    marketVolume24h: 22635906.4648,
    marketVolume24hChangePremium: 1168108.1022,
    marketVolume24hChangeBasis: 0.054412105166546224,
    percentChangeType: PriceChangeType.Negative,
    name: 'Arbitrum',
    openInterest: 20,
  },
  {
    id: 'DOGE-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.00001,
    minOrder: 10,
    bestBid: 0.0743,
    bestAsk: 0.07434,
    marketPrice: 0.07432,
    indexPrice: 0.07429,
    lastTradePrice: 0.07433,
    fairPrice: 0.07431,
    instantFundingRate: 0.00007507005595862974,
    lastFundingRateBasis: 0.000039126876369331506,
    lastTradePrice24hChangePremium: -0.00309,
    lastTradePrice24hChangeBasis: -0.03992248062015504,
    marketPrice24hHigh: 0.07894,
    marketPrice24hLow: 0.07267,
    marketVolume24h: 26356712.1655,
    marketVolume24hChangePremium: -2651886.4266,
    marketVolume24hChangeBasis: -0.09141725403178202,
    percentChangeType: PriceChangeType.Negative,
    name: 'Dogecoin',
    openInterest: 20,
  },
  {
    id: 'LDO-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.001,
    minOrder: 1,
    bestBid: 2.215,
    bestAsk: 2.23,
    marketPrice: 2.2225,
    indexPrice: 2.22,
    lastTradePrice: 2.223,
    fairPrice: 2.221,
    instantFundingRate: 0.00014452748550168584,
    lastFundingRateBasis: 0.00008326164053197722,
    lastTradePrice24hChangePremium: -0.221,
    lastTradePrice24hChangeBasis: -0.09042553191489362,
    marketPrice24hHigh: 2.485,
    marketPrice24hLow: 2.105,
    marketVolume24h: 11439694.125,
    marketVolume24hChangePremium: -818312.36,
    marketVolume24hChangeBasis: -0.06675737698469654,
    percentChangeType: PriceChangeType.Negative,
    name: 'Lido DAO',
    openInterest: 20,
  },
  {
    id: 'SUI-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 1,
    bestBid: 0.5928,
    bestAsk: 0.5939,
    marketPrice: 0.59335,
    indexPrice: 0.5928,
    lastTradePrice: 0.5934,
    fairPrice: 0.593,
    instantFundingRate: 0.000061021160051754237,
    lastFundingRateBasis: 0.00007297294907869898,
    lastTradePrice24hChangePremium: -0.0496,
    lastTradePrice24hChangeBasis: -0.07715041219474257,
    marketPrice24hHigh: 0.6556,
    marketPrice24hLow: 0.5721,
    marketVolume24h: 16499242.835,
    marketVolume24hChangePremium: -1638811.9509,
    marketVolume24hChangeBasis: -0.09035213369043107,
    percentChangeType: PriceChangeType.Negative,
    name: 'Sui',
    openInterest: 20,
  },
  {
    id: 'PEPE1000-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.000001,
    minOrder: 1000,
    bestBid: 0.00117,
    bestAsk: 0.001179,
    marketPrice: 0.0011745,
    indexPrice: 0.001178,
    lastTradePrice: 0.001172,
    fairPrice: 0.001176,
    instantFundingRate: -0.00014653894445294886,
    lastFundingRateBasis: -0.00023003228279870597,
    lastTradePrice24hChangePremium: -0.000024,
    lastTradePrice24hChangeBasis: -0.02003338898163606,
    marketPrice24hHigh: 0.001301,
    marketPrice24hLow: 0.001104,
    marketVolume24h: 10919240.292,
    marketVolume24hChangePremium: -771158.538,
    marketVolume24hChangeBasis: -0.06596511797536338,
    percentChangeType: PriceChangeType.Negative,
    name: 'Pepe',
    openInterest: 20,
  },
  {
    id: 'BCH-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.01,
    minOrder: 0.01,
    bestBid: 235.76,
    bestAsk: 235.83,
    marketPrice: 235.795,
    indexPrice: 235.7,
    lastTradePrice: 235.82,
    fairPrice: 235.78,
    instantFundingRate: 0.00003947208033276761,
    lastFundingRateBasis: 0.00005776943659497544,
    lastTradePrice24hChangePremium: -2.07,
    lastTradePrice24hChangeBasis: -0.008700769198436384,
    marketPrice24hHigh: 239.91,
    marketPrice24hLow: 231.44,
    marketVolume24h: 16999120.9974,
    marketVolume24hChangePremium: -3939238.1156,
    marketVolume24hChangeBasis: -0.18813499636436387,
    percentChangeType: PriceChangeType.Negative,
    name: 'Bitcoin Cash',
    openInterest: 20,
  },
  {
    id: 'XRP-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.6552,
    bestAsk: 0.6555,
    marketPrice: 0.65535,
    indexPrice: 0.6553,
    lastTradePrice: 0.6556,
    fairPrice: 0.6554,
    instantFundingRate: 0.00003955022254261823,
    lastFundingRateBasis: 0.000043162834310533364,
    lastTradePrice24hChangePremium: 0.0046,
    lastTradePrice24hChangeBasis: 0.007068223724646589,
    marketPrice24hHigh: 0.7549,
    marketPrice24hLow: 0.6438,
    marketVolume24h: 28451086.79,
    marketVolume24hChangePremium: 3689404.195,
    marketVolume24hChangeBasis: 0.14899650622873997,
    percentChangeType: PriceChangeType.Positive,
    name: 'Ripple',
    openInterest: 20,
  },
  {
    id: 'WLD-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 1,
    bestBid: 2.2205,
    bestAsk: 2.2292,
    marketPrice: 2.22485,
    indexPrice: 2.222,
    lastTradePrice: 2.2266,
    fairPrice: 2.2239,
    instantFundingRate: 0.00010665167420762994,
    lastFundingRateBasis: 0.00012201105936104774,
    lastTradePrice24hChangePremium: -0.2856,
    lastTradePrice24hChangeBasis: -0.11382113821138211,
    marketPrice24hHigh: 2.5387,
    marketPrice24hLow: 2.1206,
    marketVolume24h: 12709018.71,
    marketVolume24hChangePremium: -2290624.1963,
    marketVolume24hChangeBasis: -0.15271191525085673,
    percentChangeType: PriceChangeType.Negative,
    name: 'Worldcoin',
    openInterest: 20,
  },
  {
    id: 'TON-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 1,
    bestBid: 2.3293,
    bestAsk: 2.3361,
    marketPrice: 2.3327,
    indexPrice: 2.329,
    lastTradePrice: 2.336,
    fairPrice: 2.331,
    instantFundingRate: 0.0001345581542906412,
    lastFundingRateBasis: 0.00009440513184144353,
    lastTradePrice24hChangePremium: -0.0905,
    lastTradePrice24hChangeBasis: -0.037307280072553385,
    marketPrice24hHigh: 2.4337,
    marketPrice24hLow: 2.3197,
    marketVolume24h: 70726928.3265,
    marketVolume24hChangePremium: -26732773.904,
    marketVolume24hChangeBasis: -0.27429566571807135,
    percentChangeType: PriceChangeType.Negative,
    name: 'Toncoin',
    openInterest: 20,
  },
  {
    id: 'STX-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 1,
    bestBid: 0.639,
    bestAsk: 0.6413,
    marketPrice: 0.64015,
    indexPrice: 0.6394,
    lastTradePrice: 0.6397,
    fairPrice: 0.6394,
    instantFundingRate: 0.00005007213306614641,
    lastFundingRateBasis: 0.000017828291214460142,
    lastTradePrice24hChangePremium: -0.0243,
    lastTradePrice24hChangeBasis: -0.03661292752749736,
    marketPrice24hHigh: 0.6681,
    marketPrice24hLow: 0.6206,
    marketVolume24h: 9966285.9959,
    marketVolume24hChangePremium: -1585968.6055,
    marketVolume24hChangeBasis: -0.13728650036052692,
    percentChangeType: PriceChangeType.Negative,
    name: 'Stacks',
    openInterest: 20,
  },
  {
    id: 'MATIC-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 1,
    bestBid: 0.9061,
    bestAsk: 0.9108,
    marketPrice: 0.90845,
    indexPrice: 0.9081,
    lastTradePrice: 0.9109,
    fairPrice: 0.909,
    instantFundingRate: 0.00013377074246117044,
    lastFundingRateBasis: 0.0001310133521315882,
    lastTradePrice24hChangePremium: 0.0146,
    lastTradePrice24hChangeBasis: 0.016294642857142858,
    marketPrice24hHigh: 0.987,
    marketPrice24hLow: 0.8598,
    marketVolume24h: 33234275.9248,
    marketVolume24hChangePremium: 8349138.8536,
    marketVolume24hChangeBasis: 0.3355070470261787,
    percentChangeType: PriceChangeType.Positive,
    name: 'Polygon',
    openInterest: 20,
  },
  {
    id: 'TRB-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.01,
    minOrder: 0.01,
    bestBid: 84.41,
    bestAsk: 84.64,
    marketPrice: 84.525,
    indexPrice: 84.4,
    lastTradePrice: 84.55,
    fairPrice: 84.49,
    instantFundingRate: 0.0001976754381812358,
    lastFundingRateBasis: 0.00010645763800968691,
    lastTradePrice24hChangePremium: 2.08,
    lastTradePrice24hChangeBasis: 0.025202956500666424,
    marketPrice24hHigh: 87.6,
    marketPrice24hLow: 77.64,
    marketVolume24h: 13269400.3262,
    marketVolume24hChangePremium: 183516.5162,
    marketVolume24hChangeBasis: 0.014024006239437945,
    percentChangeType: PriceChangeType.Positive,
    name: 'Tellor Tributes',
    openInterest: 20,
  },
  {
    id: 'APT-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.001,
    minOrder: 0.1,
    bestBid: 7.292,
    bestAsk: 7.314,
    marketPrice: 7.303,
    indexPrice: 7.304,
    lastTradePrice: 7.315,
    fairPrice: 7.303,
    instantFundingRate: 0.000027468288271539806,
    lastFundingRateBasis: -0.000042414519571819865,
    lastTradePrice24hChangePremium: -0.346,
    lastTradePrice24hChangeBasis: -0.04518150953251502,
    marketPrice24hHigh: 7.733,
    marketPrice24hLow: 7.021,
    marketVolume24h: 14251053.6505,
    marketVolume24hChangePremium: -774538.8426,
    marketVolume24hChangeBasis: -0.051547973429712075,
    percentChangeType: PriceChangeType.Negative,
    name: 'Aptos',
    openInterest: 20,
  },
  {
    id: 'INJ-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.001,
    minOrder: 0.1,
    bestBid: 15.801,
    bestAsk: 15.857,
    marketPrice: 15.829,
    indexPrice: 15.82,
    lastTradePrice: 15.856,
    fairPrice: 15.834,
    instantFundingRate: 0.0001592785650008942,
    lastFundingRateBasis: 0.00010987103816358113,
    lastTradePrice24hChangePremium: -0.399,
    lastTradePrice24hChangeBasis: -0.024546293448169793,
    marketPrice24hHigh: 16.474,
    marketPrice24hLow: 15.058,
    marketVolume24h: 11232259.2233,
    marketVolume24hChangePremium: -1572999.5824,
    marketVolume24hChangeBasis: -0.12284012422301151,
    percentChangeType: PriceChangeType.Negative,
    name: 'Injective',
    openInterest: 20,
  },
  {
    id: 'AAVE-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.01,
    minOrder: 0.01,
    bestBid: 93.23,
    bestAsk: 93.39,
    marketPrice: 93.31,
    indexPrice: 93.34,
    lastTradePrice: 93.36,
    fairPrice: 93.33,
    instantFundingRate: 0.00006288503925708129,
    lastFundingRateBasis: -0.000004210398301622572,
    lastTradePrice24hChangePremium: -4.13,
    lastTradePrice24hChangeBasis: -0.04235463029432879,
    marketPrice24hHigh: 98.78,
    marketPrice24hLow: 91.26,
    marketVolume24h: 11480122.6863,
    marketVolume24hChangePremium: -3615679.8575,
    marketVolume24hChangeBasis: -0.23951557706251242,
    percentChangeType: PriceChangeType.Negative,
    name: 'Aave',
    openInterest: 20,
  },
  {
    id: 'LINK-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.001,
    minOrder: 0.1,
    bestBid: 14.229,
    bestAsk: 14.234,
    marketPrice: 14.2315,
    indexPrice: 14.217,
    lastTradePrice: 14.246,
    fairPrice: 14.227,
    instantFundingRate: 0.00010563528173068772,
    lastFundingRateBasis: 0.00011175912215329976,
    lastTradePrice24hChangePremium: -0.937,
    lastTradePrice24hChangeBasis: -0.06167313894556704,
    marketPrice24hHigh: 15.439,
    marketPrice24hLow: 13.869,
    marketVolume24h: 22106065.2748,
    marketVolume24hChangePremium: 951380.1419,
    marketVolume24hChangeBasis: 0.044972550332143826,
    percentChangeType: PriceChangeType.Negative,
    name: 'ChainLink',
    openInterest: 20,
  },
  {
    id: 'BNB-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.01,
    minOrder: 0.01,
    bestBid: 244.88,
    bestAsk: 245.03,
    marketPrice: 244.955,
    indexPrice: 245,
    lastTradePrice: 245.09,
    fairPrice: 245.02,
    instantFundingRate: 0.00004232744696847182,
    lastFundingRateBasis: -0.000004673646198742944,
    lastTradePrice24hChangePremium: -2.09,
    lastTradePrice24hChangeBasis: -0.008455376648596165,
    marketPrice24hHigh: 250.09,
    marketPrice24hLow: 238.47,
    marketVolume24h: 18051970.8614,
    marketVolume24hChangePremium: -2550461.1062,
    marketVolume24hChangeBasis: -0.12379417683363456,
    percentChangeType: PriceChangeType.Negative,
    name: 'BNB',
    openInterest: 20,
  },
  {
    id: 'RNDR-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 1,
    bestBid: 2.2807,
    bestAsk: 2.2909,
    marketPrice: 2.2858,
    indexPrice: 2.2904,
    lastTradePrice: 2.2872,
    fairPrice: 2.2894,
    instantFundingRate: -0.000026035401879414882,
    lastFundingRateBasis: -0.00005764024552445769,
    lastTradePrice24hChangePremium: -0.1701,
    lastTradePrice24hChangeBasis: -0.06922231717738982,
    marketPrice24hHigh: 2.5362,
    marketPrice24hLow: 2.1971,
    marketVolume24h: 9643988.8871,
    marketVolume24hChangePremium: -2669933.7819,
    marketVolume24hChangeBasis: -0.21682236064560428,
    percentChangeType: PriceChangeType.Negative,
    name: 'Render',
    openInterest: 20,
  },
  {
    id: 'MKR-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.1,
    minOrder: 0.001,
    bestBid: 1372.5,
    bestAsk: 1375.7,
    marketPrice: 1374.1,
    indexPrice: 1371.7,
    lastTradePrice: 1375.7,
    fairPrice: 1373.3,
    instantFundingRate: 0.00018831723982304027,
    lastFundingRateBasis: 0.00013413611288448033,
    lastTradePrice24hChangePremium: 12,
    lastTradePrice24hChangeBasis: 0.00881445570736007,
    marketPrice24hHigh: 1442.4,
    marketPrice24hLow: 1342.2,
    marketVolume24h: 12326713.0851,
    marketVolume24hChangePremium: 499940.7311,
    marketVolume24hChangeBasis: 0.04227195012601322,
    percentChangeType: PriceChangeType.Positive,
    name: 'Maker',
    openInterest: 20,
  },
  {
    id: 'RLB-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2214,
    bestAsk: 0.2224,
    marketPrice: 0.2219,
    indexPrice: 0.2215,
    lastTradePrice: 0.2214,
    fairPrice: 0.2211,
    instantFundingRate: -0.000013491173004469671,
    lastFundingRateBasis: -0.0002487010446073828,
    lastTradePrice24hChangePremium: -0.005,
    lastTradePrice24hChangeBasis: -0.022084805653710248,
    marketPrice24hHigh: 0.227,
    marketPrice24hLow: 0.2059,
    marketVolume24h: 217184.59,
    marketVolume24hChangePremium: -134815.292,
    marketVolume24hChangeBasis: -0.38299811702777786,
    percentChangeType: PriceChangeType.Negative,
    name: 'Rollbit',
    openInterest: 20,
  },
  {
    id: 'ORDI-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.001,
    minOrder: 0.1,
    bestBid: 21.161,
    bestAsk: 21.199,
    marketPrice: 21.18,
    indexPrice: 21.153,
    lastTradePrice: 21.193,
    fairPrice: 21.157,
    instantFundingRate: 0.00005325484794335756,
    lastFundingRateBasis: 0.000020706135215513466,
    lastTradePrice24hChangePremium: -0.054,
    lastTradePrice24hChangeBasis: -0.0025416548997458347,
    marketPrice24hHigh: 21.93,
    marketPrice24hLow: 17.98,
    marketVolume24h: 16130530.0245,
    marketVolume24hChangePremium: -1092149.3315,
    marketVolume24hChangeBasis: -0.06341343927531923,
    percentChangeType: PriceChangeType.Negative,
    name: 'Ordi',
    openInterest: 20,
  },
  {
    id: 'STG-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.5404,
    bestAsk: 0.5413,
    marketPrice: 0.54085,
    indexPrice: 0.5405,
    lastTradePrice: 0.5409,
    fairPrice: 0.5407,
    instantFundingRate: 0.00008310232786360185,
    lastFundingRateBasis: 0.0000605689540248731,
    lastTradePrice24hChangePremium: -0.0528,
    lastTradePrice24hChangeBasis: -0.0888739269483252,
    marketPrice24hHigh: 0.5989,
    marketPrice24hLow: 0.53,
    marketVolume24h: 8264405.175,
    marketVolume24hChangePremium: -1420493.247,
    marketVolume24hChangeBasis: -0.14667094946223072,
    percentChangeType: PriceChangeType.Negative,
    name: 'Stargate Finance',
    openInterest: 20,
  },
  {
    id: 'SATS1000000-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'SATS (Ordinals)',
    openInterest: 20,
  },
  {
    id: 'TIA-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Celestia',
    openInterest: 20,
  },
  {
    id: 'BLUR-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Celestia',
    openInterest: 20,
  },
  {
    id: 'JTO-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Celestia',
    openInterest: 20,
  },
  {
    id: 'MEME-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Celestia',
    openInterest: 20,
  },
  {
    id: 'SEI-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Celestia',
    openInterest: 20,
  },
  {
    id: 'FIL-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Filecoin',
    openInterest: 20,
  },
  {
    id: 'OP-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Optimism',
    openInterest: 20,
  },
  {
    id: 'AVAX-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Avalanche',
    openInterest: 20,
  },
  {
    id: 'YES-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'YES Money',
    openInterest: 20,
  },
  {
    id: 'WIF-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Dogwifhat',
    openInterest: 20,
  },
  {
    id: 'STRK-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Starknet',
    openInterest: 20,
  },
  {
    id: 'SHIB1000-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Shiba Inu',
    openInterest: 20,
  },
  {
    id: 'BOME-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Book of meme',
    openInterest: 20,
  },
  {
    id: 'SLERF-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Slerf',
    openInterest: 20,
  },
  {
    id: 'W-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Wormhole',
    openInterest: 20,
  },
  {
    id: 'ENA-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'Ethena',
    openInterest: 20,
  },
  {
    id: 'PAC-USD',
    status: 'active',
    minInitialMargin: 0.05,
    maintenanceMargin: 0.03,
    liquidationMargin: 0.02,
    minTick: 0.0001,
    minOrder: 10,
    bestBid: 0.2034,
    bestAsk: 0.2038,
    marketPrice: 0.2036,
    indexPrice: 0.1974,
    lastTradePrice: 0.2034,
    fairPrice: 0.1979,
    instantFundingRate: -0.00003453360698342683,
    lastFundingRateBasis: -0.00005788974708446718,
    lastTradePrice24hChangePremium: 0.0012,
    lastTradePrice24hChangeBasis: 0.005934718100890208,
    marketPrice24hHigh: 0.2105,
    marketPrice24hLow: 0.1695,
    marketVolume24h: 25861.046,
    marketVolume24hChangePremium: -107319.651,
    marketVolume24hChangeBasis: -0.8058198629190235,
    percentChangeType: PriceChangeType.Positive,
    name: 'PacMoon',
    openInterest: 20,
  },
];
