import { createContext } from 'react';

import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse } from '@ethersproject/providers';

import { ClaimAllResponse } from 'hooks';

import { constants, ContractTransaction } from 'ethers';
import { ProfileBalanceOps } from 'interfaces';

export type RabbitContractsAPI = {
  STRPDecimals: number;
  getAccountSTRPBalance: () => Promise<BigNumber>;
  RBTDecimals: number;
  getAccountRBTBalance: () => Promise<BigNumber>;
  getStrpAllowanceForRbt: () => Promise<BigNumber>;
  areReadContractsSet: boolean;
  isRbtApprovedForStrp: (amount?: BigNumber) => Promise<boolean>;
  approveRbtForStrpContract: () => Promise<TransactionResponse>;
  swapStrpToRbt: (amount: BigNumber) => Promise<ContractTransaction>;
  claimAirdropFromContract: (
    claimAllResponse: ClaimAllResponse,
    wallet: string,
  ) => Promise<ContractTransaction>;
  stakeToVault: (amount: BigNumber) => Promise<TransactionResponse>;
  getUsdtAllowanceForVault: () => Promise<BigNumber>;
  approveUsdtForVault: () => Promise<TransactionResponse>;
  isUsdtApprovedForVault: (amount?: number) => Promise<boolean>;
  sendVaultStakeTxHashToBackend: (
    vaultAddress: string,
    amount: BigNumber,
    txhash: string,
  ) => Promise<ProfileBalanceOps>;
  sendUnstakeFromVaultRequest: (
    vaultAddress: string,
    shares: number,
  ) => Promise<ProfileBalanceOps>;
  cancelUnstakeFromVaultRequest: (balanceOpsId: string) => Promise<boolean>;
  isRbxApprovedForContract: (
    contractaddress: string,
    amount: number,
  ) => Promise<boolean>;
  approveRbxForContract: (
    contractaddress: string,
  ) => Promise<TransactionResponse>;
};

export const EmptyRabbitContractsAPIContext = {
  STRPDecimals: 0,
  getAccountSTRPBalance: () => {
    return constants.Zero;
  },
  RBTDecimals: 0,
  getAccountRBTBalance: () => {
    return constants.Zero;
  },
  getStrpAllowanceForRbt: () => {
    return constants.Zero;
  },
  areReadContractsSet: false,
  isRbtApprovedForStrp: () => false,
  approveRbtForStrpContract: () => {
    return {} as TransactionResponse;
  },
  swapStrpToRbt: () => {
    return {} as TransactionResponse;
  },
  claimAirdropFromContract: () => {
    return {} as TransactionResponse;
  },
  stakeToVault: () => {
    return {} as TransactionResponse;
  },
  getUsdtAllowanceForVault: () => {
    return {} as BigNumber;
  },
  approveUsdtForVault: () => {
    return {} as TransactionResponse;
  },
  isUsdtApprovedForVault: () => false,
  sendVaultStakeTxHashToBackend: () => {
    return {} as ProfileBalanceOps;
  },
  sendUnstakeFromVaultRequest: () => {
    return {} as ProfileBalanceOps;
  },
  cancelUnstakeFromVaultRequest: () => false,
  isRbxApprovedForContract: () => false,
  approveRbxForContract: () => {
    return {} as TransactionResponse;
  },
};
const RabbitContractsApiContext = createContext<RabbitContractsAPI | null>(
  null,
);

export { RabbitContractsApiContext };
