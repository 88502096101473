import styled, { css } from 'styled-components';

export type AccountStatsStyledProps = {
  isPadded?: boolean;
};
export const AccountStatsStyled = styled.div<AccountStatsStyledProps>`
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;

  ${({ isPadded }) =>
    isPadded &&
    css`
      padding: 15px;
    `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  .loading-wrapper {
    display: flex;
    justify-content: center;
  }

  .mb-16 {
    margin-bottom: 16px;
  }
`;

export const AccountHealthStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;

  width: 44px;
  height: 35px;

  .absolute-sphere {
    position: absolute;
    width: 44px;
    height: 28px;
    top: 0;
  }

  .heart-and-percentage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 11px;
  }
`;

export const DepositWithdrawButtonsContainer = styled.div`
  display: flex;
  gap: 8px;

  button {
    word-break: keep-all;
  }
`;

export const ValueWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  flex-basis: 50%;
  gap: 2px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  > div.column {
    flex-basis: 50%;
    gap: 2px;
  }
`;

export const SliderBlank = styled.div`
  display: flex;
  flex-direction: column;

  .slider {
    width: 100%;
    height: 8px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
    border-radius: 4px;
    margin-top: 10px;
  }
`;
