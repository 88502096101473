import configurationObject from './config.json';
import { BrandName } from 'types/brandName';

import { ChainId, ComponentsName } from 'enums';

type Config = {
  brandName: BrandName;
  chainID: ChainId;
  network: {
    networkName: string;
    url: string;
    symbol: string;
    explorerUrl: string;
  };
  apiUrl: string;
  airdropProfileStatsLambdaUrl: string;
  isDev: boolean;
  isProd: boolean;
  websocketUrl: string;
  USDTAddress: string;
  RBTAddress: string;
  STRPAddress: string;
  AirdropAddress: string;
  RabbitContractAddress: string;
  RabbitOldContractAddress: string;
  RabbitDepositContractAddress: string;
  RbxTokenLockContractAddress: string;
  RbxTokenLockBlastContractAddress: string;
  RbxTokenLockEthContractAddress: string;
  LpRbxTokenLockContractAddress: string;
  LpRbxTokenLockBlastContractAddress: string;
  LpRbxTokenLockEthContractAddress: string;
  emailApiUrl: string;
  emailApiKey: string;
  withdrawalEmailApiUrl: string;
  withdrawalEmailApiKey: string;
  features: {
    defaultComponent: ComponentsName;
  };
  etherscanUrl: string;
  sentryDSN: string;
  sentryRelease: string;
  newsFeed: {
    url: string;
    apiKey: string;
  };
  elixirVaults: {
    apiUrl: string;
    rbxRewardsApiUrl: string;
    rabbitManagerContractAddress: string;
    elixirDistributorContractAddress: string;
  };
  platformVaultContractAddress: string;
  tokenAddresses: {
    USDT: string;
    USDC: string;
    DAI: string;
    WETH: string;
    WBTC: string;
    RBX: string;
    RBX_LP: string;
  };
  uniswapSdkAddresses: {
    SwapRouter: string;
    PoolFactory: string;
    Quoterv2: string;
    BlasterswapSwapRouter: string;
    BlasterswapPoolFactory: string;
  };
  transakApiKey: string;
  transakContractAddress?: string;
  announcement: {
    text: string;
    link: string;
  };
  appUnderMaintainance: boolean;
  twitterKeys: {
    clientId: string;
    clientKeys: string;
  };
  mesh: {
    clientId: string;
  };
  forceUpgrade: boolean;
};

const configuration = getConfigWithLowerCasedIDs(configurationObject);

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
if (typeof CHAIN_ID === 'undefined') {
  throw new Error(`REACT_APP_CHAIN_ID must be a defined environment variable`);
}

const chainID = (Number(CHAIN_ID) as ChainId) ?? 5;

const IS_DEV = process.env.REACT_APP_IS_DEV;
const isDev = IS_DEV === 'true' ? true : false;

const IS_PROD = process.env.REACT_APP_IS_PROD;
const isProd = IS_PROD === 'true' ? true : false;
const defaultBrandName: BrandName = 'rabbitx';
const brandName: BrandName =
  (process.env.REACT_APP_BRAND_NAME as BrandName) ?? defaultBrandName;

let etherscanUrl: string = '';
if (brandName === 'rabbitx') {
  if (isProd) {
    etherscanUrl = 'https://etherscan.io';
  } else {
    etherscanUrl = 'https://sepolia.etherscan.io';
  }
} else {
  if (isProd) {
    etherscanUrl = 'https://blastscan.io';
  } else {
    etherscanUrl = 'https://testnet.blastscan.io';
  }
}

const newsFeedUrl = process.env.REACT_APP_NEWS_FEED_URL;
const newsFeedApiKey = process.env.REACT_APP_NEWS_FEED_API_KEY;

const announcementText =
  process.env.REACT_APP_ANNOUNCEMENT_TEXT !== 'false' &&
  process.env.REACT_APP_ANNOUNCEMENT_TEXT
    ? process.env.REACT_APP_ANNOUNCEMENT_TEXT
    : '';

const announcementLink =
  process.env.REACT_APP_ANNOUNCEMENT_LINK !== 'false' &&
  process.env.REACT_APP_ANNOUNCEMENT_LINK
    ? process.env.REACT_APP_ANNOUNCEMENT_LINK
    : '';

const isBrandBfx = brandName === 'bfx';

export let config = ((): Config => ({
  brandName,
  chainID,
  network: {
    networkName: configuration.network[chainID].networkName,
    url: configuration.network[chainID].url,
    symbol: configuration.network[chainID].symbol,
    explorerUrl: configuration.network[chainID].explorerUrl,
  },
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.dev.rabbitx.io',
  airdropProfileStatsLambdaUrl:
    process.env.REACT_APP_AIRDROP_PROFILE_STATS_LAMBDA_URL ||
    'https://ba311ql5ad.execute-api.ap-northeast-1.amazonaws.com/Prod/stats',
  isDev,
  isProd,
  websocketUrl:
    process.env.REACT_APP_WEBSOCKET_URL || 'wss://api.dev.rabbitx.io/ws',
  newsFeed: {
    url: newsFeedUrl ?? '',
    apiKey: newsFeedApiKey ?? '',
  },
  USDTAddress:
    process.env.REACT_APP_USDT_ADDRESS ||
    '0x40132f6E2C88C9B00F6bec5820C2E470BFBC3f0e',
  RBTAddress:
    process.env.REACT_APP_RBT_ADDRESS ||
    '0x496eD36faEeAb6Caf8da238592331d665a853fF9',
  STRPAddress:
    process.env.REACT_APP_STRP_ADDRESS ||
    '0x71d595e3A8d2897407cbCF12fC231c8ACC654151',
  RabbitContractAddress:
    process.env.REACT_APP_RABBIT_CONTRACT_ADDRESS ||
    '0x135f6Fa0ad2fF8B355560f712EF2F17D52777499',
  RabbitOldContractAddress:
    process.env.REACT_APP_RABBIT_OLD_CONTRACT_ADDRESS ||
    '0x4746A4621A642285715DfD3Db0A0C3871cF1953C',
  RabbitDepositContractAddress:
    process.env.REACT_APP_RABBIT_DEPOSIT_CONTRACT_ADDRESS ||
    '0xb7aB57eF6b8EDF889455521c151ccd86e3C8c886',
  RbxTokenLockContractAddress:
    (isBrandBfx
      ? process.env.REACT_APP_RBX_TOKEN_LOCK_BLAST_CONTRACT_ADDRESS
      : process.env.REACT_APP_RBX_TOKEN_LOCK_ETH_CONTRACT_ADDRESS) ||
    '0xEAa74FFe05E31fE189EEE81EB9CB61B771C33C76',
  RbxTokenLockBlastContractAddress:
    process.env.REACT_APP_RBX_TOKEN_LOCK_BLAST_CONTRACT_ADDRESS || '0x0',
  RbxTokenLockEthContractAddress:
    process.env.REACT_APP_RBX_TOKEN_LOCK_ETH_CONTRACT_ADDRESS || '0x0',
  LpRbxTokenLockContractAddress:
    (isBrandBfx
      ? process.env.REACT_APP_LP_RBX_TOKEN_LOCK_BLAST_CONTRACT_ADDRESS
      : process.env.REACT_APP_LP_RBX_TOKEN_LOCK_ETH_CONTRACT_ADDRESS) || '0x0',
  LpRbxTokenLockBlastContractAddress:
    process.env.REACT_APP_LP_RBX_TOKEN_LOCK_BLAST_CONTRACT_ADDRESS || '0x0',
  LpRbxTokenLockEthContractAddress:
    process.env.REACT_APP_LP_RBX_TOKEN_LOCK_ETH_CONTRACT_ADDRESS || '0x0',

  AirdropAddress:
    process.env.REACT_APP_AIRDROP_ADDRESS ||
    '0x01cB633ACdbe00459D97F6213793fe69eBe275cd',
  emailApiUrl:
    process.env.REACT_APP_EMAIL_API_URL ||
    'https://landing.rabbitx.io/version-test/api/1.1/wf/create_subscriber',
  emailApiKey: process.env.REACT_APP_EMAIL_API_KEY ?? '',
  withdrawalEmailApiUrl:
    process.env.REACT_APP_WITHDRAWAL_EMAIL_API_URL ||
    'https://landing.rabbitx.io/version-test/api/1.1/wf/create_subscriber',
  withdrawalEmailApiKey: process.env.REACT_APP_WITHDRAWAL_EMAIL_API_KEY ?? '',
  features: {
    defaultComponent: ComponentsName.Trade,
  },
  etherscanUrl,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN ?? '',
  sentryRelease: process.env.REACT_APP_SENTRY_RELEASE ?? '',
  elixirVaults: {
    apiUrl: process.env.REACT_APP_ELIXIR_VAULTS_API_URL || '',
    rbxRewardsApiUrl:
      process.env.REACT_APP_ELIXIR_VAULTS_RBX_REWARDS_API_URL || '',
    rabbitManagerContractAddress:
      process.env.REACT_APP_RABBIT_MANAGER_CONTRACT_ADDRESS || '',
    elixirDistributorContractAddress:
      process.env.REACT_APP_ELIXIR_DISTRIBUTOR_CONTRACT_ADDRESS || '',
  },
  tokenAddresses: {
    DAI: process.env.REACT_APP_DAI_ADDRESS ?? '',
    USDT: process.env.REACT_APP_USDT_ADDRESS ?? '',
    USDC: process.env.REACT_APP_USDC_ADDRESS ?? '',
    WETH: process.env.REACT_APP_WETH_ADDRESS ?? '',
    WBTC: process.env.REACT_APP_WBTC_ADDRESS ?? '',
    RBX: process.env.REACT_APP_RBX_ADDRESS ?? '',
    RBX_LP: process.env.REACT_APP_RBX_LP_ADDRESS ?? '',
  },
  uniswapSdkAddresses: {
    SwapRouter: process.env.REACT_APP_UNISWAP_SWAP_ROUTER_ADDRESS ?? '',
    PoolFactory: process.env.REACT_APP_UNISWAP_POOL_FACTORY_ADDRESS ?? '',
    Quoterv2: process.env.REACT_APP_UNISWAP_QUOTERV2_ADDRESS ?? '',
    BlasterswapSwapRouter:
      process.env.REACT_APP_BLASTERSWAP_SWAP_ROUTER_ADDRESS ?? '',
    BlasterswapPoolFactory:
      process.env.REACT_APP_BLASTERSWAP_POOL_FACTORY_ADDRESS ?? '',
  },
  transakApiKey: process.env.REACT_APP_TRANSAK_API_KEY ?? '',
  transakContractAddress: process.env.REACT_APP_TRANSAK_CONTRACT_ADDRESS ?? '',
  platformVaultContractAddress:
    process.env.REACT_APP_PLATFORM_VAULT_CONTRACT_ADDRESS || '',
  announcement: {
    text: announcementText,
    link: announcementLink,
  },
  appUnderMaintainance: process.env.REACT_APP_UNDER_MAINTENANCE === 'true',
  twitterKeys: {
    clientId: process.env.REACT_APP_TWITTER_CLIENT_ID ?? '',
    clientKeys: process.env.REACT_APP_TWITTER_CLIENT_KEYS ?? '',
  },
  mesh: {
    clientId: process.env.REACT_APP_MESH_CLIENT_ID ?? '',
  },
  forceUpgrade: process.env.REACT_APP_FORCE_UPGRADE === 'true',
}))();

if (![ChainId.ARBITRUM_ONE, ChainId.MAINNET].includes(config.chainID)) {
  console.log('Current config (only dev and QA env)');
  // console.log(config);
}

function getConfigWithLowerCasedIDs(configObject: object) {
  const entries: [string, any][] = Object.entries(configObject).map(
    ([key, value]) => {
      const isID = (value: unknown) => {
        return typeof value === 'string' && value.startsWith('0x');
      };
      const newKey = isID(key) ? key.toLowerCase() : key;
      const newValue =
        value && typeof value === 'object'
          ? getConfigWithLowerCasedIDs(value)
          : isID(value)
          ? value.toLowerCase()
          : value;
      return [newKey, newValue];
    },
  );
  return Object.fromEntries(entries);
}
