import { memo } from 'react';

import { Icon } from 'components';
import Text from 'components/Text';

import backIcon from 'assets/icons/arrow-left-white.svg';
import newTabIcon from 'assets/icons/new-tab-white.svg';

import { config } from 'config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;

  > span:last-child {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

type Props = { isWatched: boolean; isLoadingVault: boolean };
const BackAndActions = ({ isLoadingVault }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <StyledRow>
      <Text
        variant="BODY_L"
        flexed
        gap={5}
        cursorPointer
        fontWeight="semiBold"
        onClick={() => navigate(-1)}
      >
        <Icon src={backIcon} alt="<-" />
        {t('back')}
      </Text>
      {!isLoadingVault ? (
        <span>
          {/* We dont support watchlisting vaults RN */}
          {/* <img src={isWatched ? starFilledIcon : starOutlineIcon} alt="watch" /> */}
          <a
            href={`${config.etherscanUrl}/address/${config.platformVaultContractAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            <Icon src={newTabIcon} alt="share" />
          </a>
        </span>
      ) : null}
    </StyledRow>
  );
};

export default memo(BackAndActions);
