import { MarketService } from 'service/marketService';

import { QueryKeys } from 'enums';
import { useQuery } from 'react-query';

const marketService = new MarketService();
export function useMarketById(marketId: string) {
  return useQuery(
    [QueryKeys.UserProfile, marketId],
    async () => {
      return await marketService.fetchMarketWithId(marketId);
    },
    {
      refetchInterval: 3_000,
      staleTime: 3_000,
      enabled: !!marketId,
    },
  );
}
