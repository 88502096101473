import { useCallback } from 'react';

import { useExchangeAPI } from './useExchangeAPI';
import { API_MAPPINGS } from 'constants/apiMappings';

import { Endpoints, RequestMethod, TimescaleType } from 'enums';
import { PortfolioHistoricalStats } from 'interfaces';

export function usePortfolioAPI() {
  const { makePrivateRequest } = useExchangeAPI();

  const fetchPortfolioStats = useCallback(
    async (
      selectedTimescale: TimescaleType,
    ): Promise<PortfolioHistoricalStats[]> => {
      try {
        const { data }: { data: PortfolioHistoricalStats[] } =
          await makePrivateRequest({
            method: RequestMethod.GET,
            endpoint: `/${Endpoints.PORTFOLIO}`,
            requestParams: {},
            queryParams: {
              range: selectedTimescale,
            },
            responseMapping: API_MAPPINGS.PORTFOLIO_STATS_MAPPING,
            isArray: true,
            shouldCheckJwtValidity: false,
          });

        return data;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [makePrivateRequest],
  );

  return {
    fetchPortfolioStats,
  } as const;
}

export type PortfolioStatsAPI = ReturnType<typeof usePortfolioAPI>;
