import { memo } from 'react';

import { Key } from 'hooks';
import useModal from 'hooks/useModal';

import Button from 'components/Button/button';
import Modal from 'components/Modal';
import Text from 'components/Text';

import { Modals } from 'constants/modals';
import { Column } from 'theme/globalStyledComponents';

import { observer } from 'mobx-react';
import styled from 'styled-components';

const Container = styled(Column)`
  border: 1px solid ${({ theme }) => theme.colors.negativeForeground200};
  background: ${({ theme }) => theme.colors.negativeBackground500};
  padding: 20px;
  border-radius: 20px;
  width: 378px;
`;

type Props = {
  onContinue: () => void;
};

const UnstakeWarningModal = ({ onContinue }: Props) => {
  const modal = useModal();
  return (
    <Modal
      name={Modals.unstakeWarningModal}
      showHeader={false}
      showCloseIcon={false}
      data-gtmid={`amend-liquidity-modal`}
      handleKeyStrokes={{
        [Key.Enter]: onContinue,
      }}
      noBorder
      padding="0px !important"
      size="auto"
    >
      <Container gap={20} align="center">
        <Text variant="HEADING_H2" fontWeight="semiBold">
          ⚠️ Unstake Request ⚠️
        </Text>

        <Text
          variant="BODY_L"
          fontWeight="semiBold"
          className="align-center px-8"
        >
          You will no longer earn points or yield if you unstake. Are you sure
          you want to proceed?
          <br />
          <br />
          <Text
            color="shadesForeground200"
            variant="BODY_M"
            fontWeight="medium"
          >
            Unstaking from the vault may take up to 24h to process. Funds will
            be credited to your trading balance.
          </Text>
        </Text>

        <Column gap={8} align="center">
          <Button
            sizeVariant="M"
            colorVariant="primaryGreen"
            onClick={() => modal.pop({ name: Modals.unstakeWarningModal })}
          >
            <span className="px-20">Back to Vault Dashboard</span>
          </Button>
          <Text
            className="cursor-pointer"
            variant="BODY_M"
            hoveredColor="shadesForeground200"
            onClick={onContinue}
          >
            Continue Anyway
          </Text>
        </Column>
      </Container>
    </Modal>
  );
};

export default observer(UnstakeWarningModal);
