import {
  useGetPlatformVaultsShares,
  useGetElixirVaultsShares,
} from 'hooks/useGetStakeAmount';
import { useReferralsAPI } from 'hooks/useReferralsAPI';

import { FormattedNumber } from 'components';

import { RABBIT_BADGE } from '../../badges';
import LockOption from './LockOptionBoxLayout';
import engageAndEarnIcon from 'assets/icons/rbx-airdrop/engage-earn.svg';
import enagageAndEarnBg from 'assets/images/engageEarnBg.webp';
import { useAppContext } from 'contexts/AppContext';
import { routes } from 'pages/routes';

import { QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const EngageAndEarnOptionBox = () => {
  const {
    store: {
      account: { frontendSecrets, accountStats },
    },
  } = useAppContext();
  const { fetchUserReferral } = useReferralsAPI();
  const navigate = useNavigate();

  const { data: userReferral } = useQuery(
    [QueryKeys.UserReferral],
    async () => await fetchUserReferral(),
    {
      // 60 seconds
      refetchInterval: 60_000,
      enabled: !!frontendSecrets?.jwt,
    },
  );

  const platformVaultSharesAmount = useGetPlatformVaultsShares();
  const elixirVaultsSharesAmount = useGetElixirVaultsShares();

  const yourStakes =
    (platformVaultSharesAmount ?? 0) + (elixirVaultsSharesAmount ?? 0);

  return (
    <LockOption
      stats={[
        {
          label: 'Your Balance',
          value: <FormattedNumber value={accountStats?.balance} prefix="$" />,
        },
        {
          label: 'Your Trading Volume',
          value: (
            <FormattedNumber
              value={accountStats?.cumTradingVolume}
              prefix="$"
            />
          ),
        },
        {
          label: 'Your Stakes',
          value: <FormattedNumber value={yourStakes} prefix="$" />,
        },
        {
          label: 'Your Referrals',
          value: userReferral?.invitedCounter ?? '-',
        },
      ]}
      ctaProps={{
        title: 'Claim RBX',
        onClick: () => navigate(routes.portfolio.airdrops.getRoutePath()),
      }}
      title={'RBX Airdrop Claim'}
      description={'Check you allocation and claim your $RBX'}
      headerBanner={enagageAndEarnBg}
      isActive={true}
      badges={[RABBIT_BADGE]}
      headerIcon={engageAndEarnIcon}
    />
  );
};

export default observer(EngageAndEarnOptionBox);
