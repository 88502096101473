import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 16px;
  padding: 12px;

  > h5 {
    margin-bottom: 40px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    padding: 32px 44px;
  }
`;

export const AirdropPhases = styled.div`
  padding: 6px 12px 12px 12px;
  background: ${({ theme }) =>
    theme.colors.shadesBackground700TransparentChart};
  border-radius: 12px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    padding: 12px 24px 24px 24px;
  }
`;
export const AirdropPhasesTabsStyled = styled.div`
  // grid-column: auto / span 2;
  padding-bottom: 10px;
  min-width: 0;
  border-radius: 16px;

  .MuiTabs-flexContainer {
    border-bottom: 2px solid ${({ theme }) => theme.colors.shadesBackground600};
  }
`;
