import { FormattedNumber } from 'components';
import Loading from 'components/Loading';

import { getYourLocksAmount, getTotalLockedRbx } from '../../LockAndEarn';
import { LOCK_ALLOCATION, getAllocation } from '../../LockAndEarn/utils';
import { BFX_BADGE_HOVERABLE } from '../../badges';
import LockOption from './LockOptionBoxLayout';
import lockIcon from 'assets/icons/rbx-airdrop/lock.svg';
import lockEarnBg from 'assets/images/lockEarnBg.webp';
import { Option } from 'pages/Portfolio/Pages/Airdrops/AirdropDashboard';
import {
  useFetchTotalLockedRbx,
  useFetchYourLocksRbx,
} from 'queryHooks/tokenLock';

import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';

const LockAndEarnOption = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: totalLocked, isLoading: isLoadingTotalLocked } =
    useFetchTotalLockedRbx();
  const { data: yourLocks, isLoading: isLoadingYourLocks } =
    useFetchYourLocksRbx();
  const yourLocksAmount = getYourLocksAmount(yourLocks);
  const totalLockedAmount = totalLocked;

  return (
    <LockOption
      stats={[
        {
          label: 'Pool Allocation',
          value: (
            <FormattedNumber
              value={LOCK_ALLOCATION}
              decimalScale={0}
              suffix=" BFX PTS"
            />
          ),
        },
        {
          label: 'Total locked (All Chains)',
          value: isLoadingTotalLocked ? (
            <Loading size="10px" />
          ) : (
            <FormattedNumber value={totalLockedAmount} suffix=" RBX" />
          ),
        },
        {
          label: 'Your Locked',
          value: isLoadingYourLocks ? (
            <Loading size="10px" />
          ) : (
            <FormattedNumber value={yourLocksAmount} suffix=" RBX" />
          ),
        },
        {
          label: 'Allocated BFX Points',
          value: (
            <FormattedNumber
              value={getAllocation(
                yourLocksAmount ? parseFloat(yourLocksAmount) : 0,
                totalLockedAmount ?? 0,
                LOCK_ALLOCATION,
              )}
              suffix=" BFX PTS"
            />
          ),
        },
      ]}
      ctaProps={{
        title: 'Lock RBX',
        onClick: () => setSearchParams({ option: Option.LOCK_AND_EARN }),
      }}
      title={'Lock & Earn'}
      description={'Lock $RBX (RabbitX) to receive BFX Points'}
      headerBanner={lockEarnBg}
      isClosed={true}
      badges={[BFX_BADGE_HOVERABLE]}
      headerIcon={lockIcon}
    />
  );
};

export default observer(LockAndEarnOption);
