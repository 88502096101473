import { useState } from 'react';

import {
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { useFillsAPI, useIsTradePage, usePaginationFetch } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import TableLoader from 'components/TableLoader';

import { StyledTable, TableContainer } from '../styles';
import FillsFilterOptions, { TFillsFilter } from './FillsFilterOptions';
import {
  CollapsibleFillRow,
  FillRowProps,
  FillsRow as BaseFillsRow,
} from './FillsRow';
import TableHead from './TradesHead';
import { useAppContext } from 'contexts/AppContext';

import { Markets, QueryKeys } from 'enums';
import { Fill } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const filterFills = ({
  _fills,
  showAllMarkets,
  selectedMarketId,
  isTradePage,
}: {
  _fills: Fill[] | undefined;
  showAllMarkets?: boolean;
  selectedMarketId?: string;
  isTradePage?: boolean;
}) => {
  let fills = _fills ? [..._fills] : [];

  // If we are on the trade page and we don't want to show all markets
  if (isTradePage && !showAllMarkets) {
    fills = fills.filter(
      fill => fill.marketID === selectedMarketId ?? Markets.BTC_USD,
    );
  }

  return fills;
};

const constructRequestQueryParamsFilters = (filter: TFillsFilter) => {
  const params = {};

  if (filter.marketId) {
    params['market_id'] = filter.marketId;
  }

  if (filter.side) {
    params['side'] = filter.side;
  }

  if (
    filter.dateRange &&
    filter.dateRange.range &&
    filter.dateRange.range[0] &&
    filter.dateRange.range[1]
  ) {
    params['start_time'] = filter.dateRange.range[0];
    params['end_time'] = filter.dateRange.range[1];
  }

  return params;
};

interface Props {
  includePagination?: boolean;
  showLabelOnTableCell?: boolean;
  showTableHead?: boolean;
  showFilterOptions?: boolean;
  isRowCollapsible?: boolean;
}

const FillsRow = ({
  isRowCollapsible,
  ...rest
}: {
  isRowCollapsible: boolean;
} & FillRowProps) =>
  isRowCollapsible ? (
    <CollapsibleFillRow {...rest} />
  ) : (
    <BaseFillsRow {...rest} />
  );

const FillsTable = ({
  includePagination,
  showLabelOnTableCell = false,
  showTableHead = true,
  showFilterOptions = false,
  isRowCollapsible = false,
}: Props) => {
  const { isOnboarding } = useExchangeAPI();
  const {
    store: {
      account: { _fills, frontendSecrets },
      appState: { showAllMarkets },
      markets: { selectedMarketId },
    },
  } = useAppContext();
  const { fetchPrivateFills } = useFillsAPI();

  const isTradePage = useIsTradePage();
  const { t } = useTranslation();
  const [appliedFilters, setAppliedFilters] = useState<TFillsFilter>({});

  const {
    isLoading: areFillsLoading,
    data: fills,
    pagination,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePaginationFetch<Fill>({
    fetchFn: fetchPrivateFills,
    socketSource: _fills,
    initialPagination: { page: 0, limit: 10 },
    currentAuthenticatedWallet: frontendSecrets?.profile?.wallet,
    requestQueryParams: constructRequestQueryParamsFilters(appliedFilters),
    isOnboarding,
    queryKey: QueryKeys.Fills,
    disableRefetchInterval: !includePagination,
    includePagination,
  });

  if (areFillsLoading || totalCount === null || !frontendSecrets) {
    return (
      <>
        {showFilterOptions ? (
          <FillsFilterOptions
            appliedFilters={appliedFilters}
            onChangeFilter={setAppliedFilters}
          />
        ) : null}
        <TableContainer>
          <StyledTable>
            {showTableHead ? <TableHead /> : null}
            <TableBody>
              {!frontendSecrets ? (
                <TableRow>
                  <TableCell colSpan={100} className="no-items">
                    {t('connectYourWalletToStartTrading')}
                  </TableCell>
                </TableRow>
              ) : (
                <TableLoader />
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </>
    );
  }

  const filteredFills = filterFills({
    _fills: fills,
    showAllMarkets,
    selectedMarketId,
    isTradePage,
  });

  const { page, limit: rowsPerPage } = pagination;

  const resultsFound = filteredFills.length > 0;

  return (
    <>
      {showFilterOptions ? (
        <FillsFilterOptions
          appliedFilters={appliedFilters}
          onChangeFilter={setAppliedFilters}
        />
      ) : null}
      <TableContainer useScroll={!includePagination}>
        <StyledTable stickyHeader>
          {showTableHead ? <TableHead /> : null}
          <TableBody>
            {resultsFound ? (
              fills.map(fill => {
                return (
                  <FillsRow
                    key={fill.id}
                    showLabels={showLabelOnTableCell}
                    isRowCollapsible={isRowCollapsible}
                    fill={fill}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={100} className="no-items">
                  You have no trades.
                </td>
              </tr>
            )}
          </TableBody>
        </StyledTable>
        {includePagination && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </>
  );
};

export default observer(FillsTable);
