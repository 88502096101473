import { OrderSortConfig } from './LimitAndMarketOrders';

import { SortBy } from '.';
import { Markets, OrderStatus, OrderType } from 'enums';
import { Order } from 'interfaces';

/**
 * Filter orders by selected market and showAllMarkets flag (if we are on the trade page)
 */
export const filterOrders = ({
  _orders,
  showAllMarkets,
  selectedMarketId,
  isTradePage,
  shouldHideClosed,
  orderTypes,
}: {
  _orders: Order[] | undefined;
  showAllMarkets: boolean;
  selectedMarketId: string | undefined;
  isTradePage?: boolean;
  shouldHideClosed?: boolean;
  orderTypes?: OrderType[];
}) => {
  let orders = _orders ? [..._orders] : [];

  // Hide SL/TP orders that haven't been placed yet
  orders = orders.filter(
    el =>
      // Hide SL/TP orders that are in PLACED status
      !(
        [
          OrderType.STOP_LOSS,
          OrderType.TAKE_PROFIT,
          OrderType.STOP_LOSS_LIMIT,
          OrderType.TAKE_PROFIT_LIMIT,
        ].includes(el.type) && el.status === OrderStatus.PLACED
      ),
  );

  if (shouldHideClosed) {
    orders = orders.filter(el =>
      [OrderStatus.OPEN, OrderStatus.PROCESSING, OrderStatus.PLACED].includes(
        el.status,
      ),
    );
  }

  if (orderTypes) {
    orders = orders.filter(order => orderTypes.includes(order.type));
  }

  if (isTradePage && !showAllMarkets) {
    orders = orders.filter(
      order => order.marketID === selectedMarketId ?? Markets.BTC_USD,
    );
  }

  return orders;
};

/**
 * Sort orders by selected sort by option (if any)
 */
export const sortOrders = ({
  _orders,
  sortConfig,
}: {
  _orders: Order[] | undefined;
  sortConfig: OrderSortConfig | null;
}) => {
  let orders = _orders ? [..._orders] : [];

  if (!sortConfig) return orders;

  const factor =
    sortConfig.numOfClicks % 3 === 1
      ? -1
      : sortConfig.numOfClicks % 3 === 2
      ? 1
      : 0;

  if (factor === 0) return orders;

  if (sortConfig.sortBy === SortBy.CREATED_AT) {
    orders = orders.sort((a, b) => factor * (b.createdAt - a.createdAt));
  }

  if (sortConfig.sortBy === SortBy.NOTIONAL) {
    orders = orders.sort(
      (a, b) => factor * (b.initialSize * b.price - a.initialSize * a.price),
    );
  }

  if (sortConfig.sortBy === SortBy.SIDE) {
    orders = orders.sort((a, b) => factor * a.side.localeCompare(b.side));
  }

  if (sortConfig.sortBy === SortBy.STATUS) {
    orders = orders.sort((a, b) => factor * b.status.localeCompare(a.status));
  }

  return orders;
};
