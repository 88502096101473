import { memo } from 'react';

import {
  TableCell,
  TableRow,
  TableHead as TableHeadMui,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const TableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>{t('market')}</TableCell>

        <TableCell key={1}>{t('filled')}</TableCell>

        <TableCell key={2}>{t('value')}</TableCell>

        <TableCell key={3}>{t('averagePrice')}</TableCell>

        <TableCell key={4}>{t('liquidation')}</TableCell>

        <TableCell key={5}>{t('fee')}</TableCell>

        <TableCell key={6}>{t('time')}</TableCell>
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
