import {
  PlatformVaultHoldingsResponse,
  PlatformVaultResponse,
  PlatformVaultsDataWithHoldings,
  useVerifyChainId,
} from 'hooks';
import useModal from 'hooks/useModal';

import { EmptyGridState } from '../../../common/VaultList/List/Grid/EmptyState';
import EmptyVaultCard from '../../../common/VaultList/List/Grid/EmptyState/EmptyVaultCard';
import AmendPlatformVaultLiquidityModal, {
  AmendType,
} from '../../AmendPlatformVaultLiquidityModal';
import { useAmendPlatformVault } from '../../useAmendPlatformVault';
import { getTotalVaultApy } from '../utils';
import VaultCard from './VaultCard';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  }
`;

type Props = {
  vaults: PlatformVaultsDataWithHoldings[] | undefined;
  isLoading: boolean;
  initialVaultRouterAddress?: string;
};
const VaultGrid = ({
  vaults = [],
  initialVaultRouterAddress,
  isLoading,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const {
    store: {
      account: { frontendSecrets, isVaultAccount },
    },
  } = useAppContext();
  const { amendPlatformVault } = useAmendPlatformVault();

  const isUserAuthenticated = !!frontendSecrets?.profile.wallet;

  const onStartStakeUnstake = () => {
    initialVaultRouterAddress &&
      amendPlatformVault(AmendType.Stake, initialVaultRouterAddress);
  };

  const dmmVaultApy = vaults?.[0]?.apy;

  return (
    <Grid>
      {vaults.length === 0 ? (
        <EmptyGridState
          isLoading={isLoading}
          isUserAuthenticated={isUserAuthenticated}
          onCtaPress={onStartStakeUnstake}
        />
      ) : (
        <>
          {!isUserAuthenticated ? (
            <EmptyVaultCard
              displayActions
              isUserAuthenticated={false}
              isLoading={isLoading}
              title={t('noWalletConnected'!)}
              description={t('connectNowAndStartStakingUnstaking')}
              apy={dmmVaultApy ? getTotalVaultApy(dmmVaultApy) : undefined}
            />
          ) : null}
          {vaults.map(vault => (
            <VaultCard
              vault={vault}
              isUserAuthenticated={isUserAuthenticated}
              manager={vault.managerName}
              isVaultAccount={isVaultAccount}
              {...rest}
            />
          ))}
        </>
      )}
    </Grid>
  );
};

export default observer(VaultGrid);
