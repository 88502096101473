import styled from 'styled-components';

type Props = { isAuthenticated: boolean };

export const Avatar = styled.div<Props>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.shadesBackground500};
  width: 100%;
  height: 100%;
  border-radius: 100px;
  opacity: ${({ isAuthenticated }) => (isAuthenticated ? 1 : 0.3)};

  &:hover {
    background: ${({ theme }) => theme.colors.shadesForeground400};
  }

  .img-avatar {
    height: 100%;
    width: 100%;
    border-radius: 100px;
    object-fit: containd;
  }

  .emoji-avatar {
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    transform: translateY(1.4px) translateX(-1px) !important;
    font-size: 18px;
    line-height: 24px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      transform: translateY(1px) translateX(-1.25px) !important;
      font-size: 20px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;

  width: 34px;
  height: 34px;
  border-radius: 100px;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    justify-content: center;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    height: 40px;
    width: 40px;
  }

  .picker {
    opacity: 1;
    z-index: 1;
    display: flex;
    align-items: center;
    position: absolute;
    background: ${({ theme }) => theme.colors.shadesBackground500};
    border-radius: 100px;
    padding: 8px;
    gap: 4px;
    top: -40px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      top: -50px;
      gap: 0px;
    }

    .picker-item {
      display: flex;
      align-items: center;
      height: 24px;
      width: 24px;
      font-size: 20px;
      line-height: 24px;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        width: 32px;
        height: 32px;
        font-size: 24px;
      }

      &:hover {
        transform: scale(1.2);
      }
    }

    .bg-shade {
      border-radius: 100px;
      background: ${({ theme }) => theme.colors.shadesBackground800};
    }
  }
`;
