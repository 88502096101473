import React, { useEffect, useState } from 'react';

type Props = {
  timeInSeconds: number;
  showDone?: boolean;
  format?: 'short' | 'extended';
};
const CountdownTimer = ({
  timeInSeconds,
  showDone = false,
  format = 'short',
}: Props) => {
  const [timeLeft, setTimeLeft] = useState(timeInSeconds);

  // Convert seconds into HH:MM:SS format
  const formatTime = (seconds: number) => {
    if (seconds <= 0) return '00:00:00';

    if (format === 'extended') {
      const days = Math.floor(seconds / 86400);
      const hours = Math.floor((seconds % 86400) / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      let result: any = [];
      if (days > 0) {
        result.push(`${days}d`);
      }
      if (hours > 0) {
        result.push(`${hours}h`);
      }
      if (minutes > 0) {
        result.push(`${minutes}m`);
      }
      if (secs > 0) {
        result.push(`${secs.toFixed(0)}s`);
      }

      if (result.length === 0) {
        return '00:00:00';
      } else {
        return result.join(' ');
      }
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      let timeString = '';
      // If hours is not zero, prepend it to the time string
      if (hours > 0) {
        timeString += `${hours < 10 ? '0' : ''}${Math.floor(hours)}:`;
      }

      // Append minutes and seconds
      timeString += `${minutes < 10 ? '0' : ''}${Math.floor(minutes)}:${
        secs < 10 ? '0' : ''
      }${Math.floor(secs)}`;

      return timeString;
    }
  };

  useEffect(() => {
    // Exit early when we reach 0
    if (timeLeft <= 0) {
      return;
    }

    // Save intervalId to clear the interval when the
    // component re-renders or unmounts
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  if (showDone && timeLeft <= 0) {
    return <span>00:00</span>;
  }

  return (
    <div>
      <span>{formatTime(timeLeft)}</span>
    </div>
  );
};

export default CountdownTimer;
