import { API_MAPPINGS } from '../constants';
import { useActiveWeb3React } from './useActiveWeb3React';
import { useExchangeAPI } from './useExchangeAPI';
import { useAppContext } from 'contexts/AppContext';

import { Endpoints, QueryKeys, RequestMethod } from 'enums';
import { useQuery } from 'react-query';

type TBfxPoints = {
  points: number;
};

export function useBfxPointsAPI() {
  const { account } = useActiveWeb3React();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { makePrivateRequest } = useExchangeAPI();

  const {
    data: bfxPoints,
    isLoading,
  }: {
    isLoading: boolean;
    data: number | undefined;
    isError?: any;
  } = useQuery(
    [QueryKeys.BFXPointsBalance, frontendSecrets],
    async () => {
      try {
        if (!account) return;

        const { data }: { data: TBfxPoints } = await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.BFX_POINTS_BALANCE}`,
          requestParams: {},
          responseMapping: API_MAPPINGS.BFX_POINTS_BALANCE,
        });

        console.log('data', data);
        return data?.points;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    { enabled: !!frontendSecrets },
  );

  return { bfxPoints, isLoading } as const;
}

export type TBFXPointsApi = ReturnType<typeof useBfxPointsAPI>;
