import { config } from 'config';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

const PROJECT_ID = process.env.REACT_APP_LOCIZE_PROJECT_ID;
const API_KEY = process.env.REACT_APP_LOCIZE_API_KEY;
if (typeof PROJECT_ID === 'undefined') {
  throw new Error(
    `REACT_APP_LOCIZE_PROJECT_ID must be a defined environment variable`,
  );
}
if (typeof API_KEY === 'undefined') {
  throw new Error(
    `REACT_APP_LOCIZE_API_KEY must be a defined environment variable`,
  );
}

const locizeOptions = {
  projectId: PROJECT_ID,
  referenceLng: 'en',
  // version: config.isProd ? 'production' : 'latest',
  version: 'latest',
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: !config.isProd,
    saveMissing: false,
    backend: locizeOptions,
    interpolation: {
      escapeValue: false,
    },
  });

// i18n.use(Backend).use(initReactI18next).init({
//   lng: 'en',
//   debug: !config.isProd,
//   saveMissing: !config.isProd,
//   backend: locizeOptions,
// });

export default i18n;
