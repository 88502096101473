import {
  Table,
  TableCell,
  TableRow,
  TableContainer as TableContainerMui,
} from '@material-ui/core';

import { TextVariant } from 'components/Text/variant';

import { Row } from 'theme/globalStyledComponents/row';

import styled, { css } from 'styled-components';

interface TablePageContainerProps {
  addBackground?: boolean;
  addPadding?: boolean;
}
export const TablePageContainer = styled.div<TablePageContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${({ addBackground, theme }) =>
    addBackground && theme.colors.shadesBackground800};
  border-radius: 8px;
  gap: 20px;

  ${({ addPadding }) =>
    addPadding &&
    css`
      padding: 15px;
    `};

  /* @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    margin: 0;
  } */
  /* min-height: calc(100vh - 110px); */
`;

export interface TableContainerProps {
  useScroll?: boolean;
}
export const TableContainer = styled(TableContainerMui)<TableContainerProps>`
  width: auto;

  .MuiTablePagination-root {
    overflow: visible;
  }

  ${props =>
    props.useScroll &&
    css`
      /* max-height: 570px; */
      overflow-y: auto;

      ::-webkit-scrollbar {
        display: none;
      }
    `}
`;

export const TableTitle = styled.div`
  padding: 14px 20px;

  > h5 {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 24px;
    line-height: 27px;
    margin: 0;
    margin-bottom: 10px;
  }

  > button {
    > img {
      margin-left: 8px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.smaller}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 36px 48px;

    > h5 {
      margin-bottom: 0;
    }
  }
`;

export const StyledTable = styled(Table)`
  border-collapse: collapse;
  width: 100%;

  // Hack for margin between thead and tbody (https://stackoverflow.com/a/9260996/17694616)
  tbody:before {
    content: '-';
    display: block;
    line-height: 8px;
    color: transparent;
  }

  tr {
    padding: 10px 15px;

    td,
    th {
      padding: 10px 15px;
      border: 0;
      white-space: nowrap;
    }

    td:first-child,
    th:first-child {
      min-width: 120px;
    }
  }

  thead {
    background-color: #0a0e18;
    margin-bottom: 8px;
    border-radius: 8px;
    th {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: ${TextVariant['BODY_S'].fontSize};
      line-height: ${TextVariant['BODY_S'].lineHeight};
      color: ${({ theme }) => theme.colors.shadesForeground200};

      .cell-span {
        display: flex;
        gap: 5px;
      }

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        font-size: ${TextVariant['BODY_M'].fontSize};
        line-height: ${TextVariant['BODY_M'].lineHeight};
      }
    }

    th:last-child::not(:first-child) {
      text-align: right;
    }
  }

  tbody {
    tr {
      background: ${({ theme }) => theme.colors.shadesBackground700};
      transition: background-color 0.5s ease;

      :not(:last-child) {
        border-bottom: 1px solid #11161f;
      }

      td {
        font-size: ${TextVariant['BODY_S'].fontSize};
        line-height: ${TextVariant['BODY_S'].lineHeight};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        color: ${({ theme }) => theme.colors.white};

        @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
          font-size: ${TextVariant['BODY_M'].fontSize};
          line-height: ${TextVariant['BODY_M'].lineHeight};
        }
      }

      td:first-child {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      }

      td:last-child:not(:first-child) {
        text-align: right;
      }

      td.no-items {
        text-align: left;
      }

      &:hover {
        background: ${({ theme }) => theme.colors.shadesBackground600};
      }
    }

    .bg-600 {
      background: ${({ theme }) => theme.colors.shadesBackground600};

      &:hover {
        background: ${({ theme }) => theme.colors.shadesBackground500};
      }
    }

    .bg-900 {
      background: ${({ theme }) => theme.colors.shadesBackground900};

      &:hover {
        background: ${({ theme }) => theme.colors.shadesBackground800};
      }
    }
  }

  // Border radius for thead and tbody
  tr:first-child th:first-child,
  tr:first-child td:first-child {
    border-top-left-radius: 8px;
  }
  tr:first-child th:last-child,
  tr:first-child td:last-child {
    border-top-right-radius: 8px;
  }
  tr:last-child th:first-child,
  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }
  tr:last-child th:last-child,
  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  svg {
    cursor: pointer;
  }

  .asset-logo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    vertical-align: middle;
  }
`;

interface TradeSideLabelProps {
  isSuccess?: boolean;
}
export const TradeSideLabel = styled.span<TradeSideLabelProps>`
  padding: 4px;
  background: #462936;
  border-radius: 4px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 14px;
  font-size: 11px;
  line-height: 14px;
  background-color: ${({ isSuccess, theme }) =>
    isSuccess ? '#1C3D44' : '#46293'};
  color: ${({ isSuccess, theme }) => (isSuccess ? '#19EAAB' : '#FF475D')};
  text-transform: uppercase;
`;

export type StyledTableCellProps = {
  verticalAlign?: 'top' | 'middle' | 'bottom';
};
export const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  vertical-align: ${({ verticalAlign }) => verticalAlign};
`;

type CollapsibleTableRowProps = {
  isCollapsed: boolean;
};
export const CollapsibleTableRow = styled(TableRow)<CollapsibleTableRowProps>`
  cursor: pointer;

  :hover {
    .collapse-icon {
      gap: ${({ isCollapsed }) => (isCollapsed ? '4px' : '0px')};
    }
  }
`;

export const FilterOptionsContainer = styled(Row)`
  gap: 20px;
  flex-wrap: wrap;

  .seperator {
    @media (min-width: ${({ theme }) => theme.screenSizes.xs}px) {
      height: 24px;
      width: 1.5px;
      background: ${({ theme }) => theme.colors.shadesBackground500};
      border-radius: 100px;
    }
  }
`;
