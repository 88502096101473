import { Tabs } from '@material-ui/core';
import { Tab as MuiTab } from '@material-ui/core';

import {
  getCurrencyFromTradingPair,
  numToString,
  removeTrailingDigitsFromTradingPair,
} from 'utils';

import { SelectDropdown } from 'components/SelectDropdown.tsx';
import Text from 'components/Text';

import { Tab } from './OrderBook';
import settingsIcon from 'assets/icons/settings-white.svg';
import { useAppContext } from 'contexts/AppContext';

import { CurrencyKind } from 'interfaces';
import bigDecimal from 'js-big-decimal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Selector = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.shadesBackground900};
  border-radius: 4px;

  .badge {
    cursor: pointer;
    padding: 4px 6px;
    border-radius: 4px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 10px;
    line-height: normal;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .badge.focused {
    background-color: ${({ theme }) => theme.colors.shadesBackground600};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .select-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
  }

  .icon {
    height: 75%;
    width: 75%;
  }
`;

export const getCurrencyFromKind = (
  currenyKind: CurrencyKind,
  marketId: string,
) => {
  const token = getCurrencyFromTradingPair(marketId);
  if (currenyKind === CurrencyKind.BASE) return token;
  if (currenyKind === CurrencyKind.QUOTE) return 'USD';
  return '';
};

const OPTIONS_MULTIPLIER = [2, 4, 10, 20, 50, 100];

const constructTickSizes = (minTick: number) => {
  const tickSizes = [minTick]; // Default

  for (const multiplier of OPTIONS_MULTIPLIER) {
    const option = parseFloat(
      new bigDecimal(multiplier).multiply(new bigDecimal(minTick)).getValue(),
    );

    tickSizes.push(option);
  }

  return tickSizes;
};

const Header = ({
  minTick,
  showRecentTrades,
  activeTab,
  onTabChange,
}: {
  minTick: number;
  showRecentTrades: boolean;
  onTabChange: (tab: Tab) => void;
  activeTab: Tab;
}) => {
  const {
    store: {
      appState,
      orderbook: {
        updateCurrentMarketSavedTickSize,
        getSelectedMarketOrderbookTick,
        selectedMarketID,
      },
    },
  } = useAppContext();
  const { t } = useTranslation();

  const { defaultCurrencyKind, setDefaultCurrencyKind } = appState;

  const savedUserTick = getSelectedMarketOrderbookTick();

  const selectedTick = savedUserTick === undefined ? minTick : savedUserTick;

  return (
    <Text
      flexed
      className="justify-between mb-10"
      variant="BODY_M"
      fontWeight="semiBold"
    >
      {showRecentTrades ? (
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => onTabChange(newValue)}
          aria-label="Positions Tab"
        >
          <MuiTab
            data-gtmid="tab-orderbook"
            data-cy="tab-orderbook"
            label={t('orderbook')}
          />
          <MuiTab
            data-gtmid="tab-recent-trades"
            data-cy="tab-recent-trades"
            label={t('trades')}
          />
        </Tabs>
      ) : (
        t('orderbook')
      )}

      <StyledRow>
        <Selector>
          {[CurrencyKind.BASE, CurrencyKind.QUOTE].map(currenyKind => (
            <div
              onClick={() => setDefaultCurrencyKind(currenyKind)}
              className={`badge ${
                currenyKind === defaultCurrencyKind ? 'focused' : ''
              }`}
            >
              {removeTrailingDigitsFromTradingPair(
                getCurrencyFromKind(currenyKind, selectedMarketID),
              )}
            </div>
          ))}
        </Selector>

        <SelectDropdown
          options={constructTickSizes(minTick)}
          onOptionSelect={updateCurrentMarketSavedTickSize}
          inputClass="select-input-container"
          customStyles={{ right: '0', top: '28px' }}
          isOptionSelected={option => option === selectedTick}
          displayTextParser={value => numToString(value) as string}
          InputElement={
            <img
              className="icon"
              src={settingsIcon}
              alt="tickSize"
              data-gtmid="icon-orderbook-settings"
            />
          }
        />
      </StyledRow>
    </Text>
  );
};

export default observer(Header);
