import { useEffect } from 'react';

import { readUserSetting } from 'utils';
import { isBrandBfx, isBrandRabbitX } from 'utils/brand';

import { useBlastGameAssets } from 'hooks/useBlastGameAssets';
import useModal from 'hooks/useModal';

import EngageAndEarn from '../BfxAirdrop/LockOptions/EngageAndEarn';
import LpAndEarn from '../BfxAirdrop/LockOptions/LPAndEarn';
import LockAndEarn from '../BfxAirdrop/LockOptions/LockAndEarn';
import AirdropRegistrationOverlay from '../BlastAidropDashboard/RegistrationAndOnboardingOverlay';
import BlastStatsAndGreetingHeader from './BlastStatsAndGreetingHeader';
import GoldLiquidityProgramBanner from './GoldLiquidityProgramBanner';
import GreetingAvatar from './GreetingAvatar';
import OngoingCampaigns from './OngoingCampaigns';
import { Container } from './styles';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import Portfolio from 'pages/Portfolio';

import { QueryKeys, UserSettings as UserSettingsKey } from 'enums';
import { UserSettings } from 'interfaces';
import { observer } from 'mobx-react';
import { useUserRegisteredForBlastAirdrop } from 'queryHooks';
import { useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

export enum Option {
  LP_AND_EARN = 'lpandearn',
  LOCK_AND_EARN = 'lockandearn',
  ENGAGE_AND_EARN = 'engageandearn',
}

const AirdropDashboard = () => {
  const [searchParams] = useSearchParams();

  const {
    store: {
      account: { frontendSecrets, accountStats },
    },
  } = useAppContext();
  const { data: userRegistration } = useUserRegisteredForBlastAirdrop(
    frontendSecrets?.jwt,
  );
  const { fetchUserGameAssets } = useBlastGameAssets();

  const option = searchParams.get('option') as Option;

  const showRegistrationOrOnboardingModal = isBrandRabbitX
    ? !userRegistration?.isRegisteredForAirdrop // Just want to make sure user is registered,
    : !(
        userRegistration?.isRegisteredForAirdrop && // registered and also onboarded in case of BFX,
        userRegistration?.isOnboardded
      );

  const { data: userGameAssets } = useQuery(
    [QueryKeys.UserGameAssets],
    async () => await fetchUserGameAssets(),
    {
      // 60 seconds
      refetchInterval: 60_000,
      enabled: !!frontendSecrets?.jwt,
    },
  );

  return (
    <>
      <Portfolio>
        {isBrandBfx &&
        !readUserSetting(
          UserSettingsKey.IS_GOLD_POINTS_PROGRAM_BANNER_DISMISSED,
        ) ? (
          <GoldLiquidityProgramBanner />
        ) : null}

        <Container>
          {![
            Option.ENGAGE_AND_EARN,
            Option.LOCK_AND_EARN,
            Option.LP_AND_EARN,
          ].includes(option) ? (
            <>
              {isBrandBfx ? (
                <BlastStatsAndGreetingHeader userGameAssets={userGameAssets} />
              ) : null}
              {isBrandRabbitX ? <GreetingAvatar /> : null}

              <OngoingCampaigns yourDeposits={accountStats?.balance} />
            </>
          ) : null}

          {option === Option.ENGAGE_AND_EARN ? <EngageAndEarn /> : null}
          {option === Option.LOCK_AND_EARN ? <LockAndEarn /> : null}
          {option === Option.LP_AND_EARN ? <LpAndEarn /> : null}
        </Container>
      </Portfolio>

      {/* {showRegistrationOrOnboardingModal ? (
        <AirdropRegistrationOverlay
          isUserRegisteredForAirdrop={userRegistration?.isRegisteredForAirdrop}
        />
      ) : null} */}
    </>
  );
};

export default observer(AirdropDashboard);
