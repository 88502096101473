import {
  checkIsOrdersOrPositionsChanged,
  mergeState,
  saveUserSetting,
} from 'utils';
import { FrontendSecrets } from 'utils/signatures';

import AppStore from './app';
import { DEFAULT_ACCOUNT_MARKET_LEVERAGE } from 'constants/general';
import { LS_WALLET, LS_WALLET_SIGNATURE } from 'constants/localStorageKeys';
import {
  AccountStats,
  MarketLeverage,
  ProfileBalanceOps,
} from 'interfaces/accountStats';
import cloneDeep from 'lodash/cloneDeep';

import { BalanceOpsType, OrderStatus, OrderType, ProfileType } from 'enums';
import { Order } from 'interfaces';
import {
  action,
  comparer,
  computed,
  makeAutoObservable,
  observable,
} from 'mobx';

export default class AccountStore {
  accountStats: AccountStats | undefined | null;
  isOrdersOrPositionsChanged: boolean = false;
  orderEntryPrice: number = 0;
  isAuthenticated: boolean = false;
  isLoading: boolean = true;
  frontendSecrets: FrontendSecrets | null = null;
  orders: Order[] | null = null;
  leverage: MarketLeverage | null = null;

  constructor(private store: AppStore) {
    makeAutoObservable(this, {
      accountStats: observable,
      isOrdersOrPositionsChanged: observable,
      orderEntryPrice: observable,
      isAuthenticated: observable,
      isLoading: observable,
      frontendSecrets: observable,
      orders: observable,
      leverage: observable,
      setAccount: action,
      // changeMarketLeverage: action,
      changeOrderEntryPrice: action,
      createOrder: action,
      addNewBalanceOperation: action.bound,
      _positions: computed({ equals: comparer.structural }),
      _orders: computed({ equals: comparer.structural }),
      _stopOrders: computed({ equals: comparer.structural }),
      _limitAndMarketOrders: computed({ equals: comparer.structural }),
      _balanceOperations: computed({ equals: comparer.structural }),
      _vaultBalanceOperations: computed({ equals: comparer.structural }),
      _fills: computed({ equals: comparer.structural }),
      _marketLeverage: computed({ equals: comparer.structural }),
      _accountStats: computed({ equals: comparer.structural }),
      isVaultAccount: computed({ equals: comparer.structural }),
    });
  }

  setAccount(
    newOrPartiallyUpdatedState: AccountStats,
    isSubscribe = false,
  ): AccountStats {
    const oldStateDeepClone = cloneDeep(this.accountStats);
    const mergedState = mergeState<AccountStats>(
      isSubscribe ? null : oldStateDeepClone,
      newOrPartiallyUpdatedState,
    );
    const isOrdersOrPositionsChanged = checkIsOrdersOrPositionsChanged(
      this.accountStats,
      mergedState,
    );
    if (isOrdersOrPositionsChanged) {
      this.setIsOrdersOrPositionsChanged();
    }
    this.accountStats = mergedState;
    return this.accountStats;
  }

  setIsOrdersOrPositionsChanged() {
    this.isOrdersOrPositionsChanged = !this.isOrdersOrPositionsChanged;
  }

  changeOrderEntryPrice(price: number) {
    this.orderEntryPrice = price;
  }

  setIsAuthenticated(isAuthenticated: boolean): boolean {
    this.isAuthenticated = isAuthenticated;
    return isAuthenticated;
  }

  setIsLoading(isLoading: boolean): boolean {
    this.isLoading = isLoading;
    return isLoading;
  }

  setFrontendSecrets(frontendSecrets: FrontendSecrets): FrontendSecrets {
    this.frontendSecrets = frontendSecrets;
    return frontendSecrets;
  }

  unsetFrontendSecrets() {
    this.frontendSecrets = null;
    this.accountStats = null;
    // Delete wallet address from local storage
    saveUserSetting(LS_WALLET, null);
    saveUserSetting(LS_WALLET_SIGNATURE, null);
  }

  createOrder(order: Order) {
    if (!this.accountStats) {
      return;
    }
    // Don't add it to the array if this order was already added through websockets
    // (sometimes the response from websockets is faster than from REST API)
    if (
      this.accountStats.orders &&
      this.accountStats.orders.some(el => el.id === order.id)
    ) {
      return;
    }

    const newOrders = [order, ...(this.accountStats.orders ?? [])];

    this.accountStats = cloneDeep({
      ...this.accountStats,
      orders: newOrders,
    });
  }

  // changeMarketLeverage(marketID: string, leverage: string) {
  //   if (!this.accountStats) return;

  //   const newMarketLeverage = {
  //     ...this.accountStats.leverage,
  //     [marketID]: leverage,
  //   };

  //   this.setAccount({
  //     ...this.accountStats,
  //     leverage: newMarketLeverage,
  //   });
  // }

  getMarketLeverage(market: string) {
    if (!this?.accountStats || !this.accountStats?.leverage) return 1;

    const leverage =
      this.accountStats.leverage && this.accountStats.leverage[market];

    return leverage !== undefined ? leverage : DEFAULT_ACCOUNT_MARKET_LEVERAGE;
  }

  addNewBalanceOperation(balanceOperation: ProfileBalanceOps) {
    if (!this.accountStats) {
      return;
    }
    // Don't add it to the array if this balance operation was already added through websockets
    // (sometimes the response from websockets is faster than from REST API)
    if (
      this.accountStats.balanceOperations &&
      this.accountStats.balanceOperations.some(
        el => el.id === balanceOperation.id,
      )
    ) {
      return;
    }

    const newBalanceOperations = [
      balanceOperation,
      ...(this.accountStats.balanceOperations ?? []),
    ];

    this.accountStats = cloneDeep({
      ...this.accountStats,
      balanceOperations: newBalanceOperations,
    });
  }

  get _accountStats() {
    return this.accountStats;
  }

  get _marketLeverage() {
    return this._accountStats?.leverage;
  }

  get _positions() {
    return this._accountStats?.positions;
  }

  get _orders() {
    return this._accountStats?.orders;
    // ?.slice()
    // ?.sort((a, b) => b.createdAt - a.createdAt);
  }

  get _limitAndMarketOrders() {
    // return this._orders?.filter(order =>
    //   [OrderStatus.CANCELED].includes(order.status),
    // );
    return this._orders?.filter(
      order =>
        [
          OrderType.LIMIT,
          OrderType.MARKET,
          OrderType.STOP_LOSS,
          OrderType.TAKE_PROFIT,
          OrderType.STOP_LOSS_LIMIT,
          OrderType.TAKE_PROFIT_LIMIT,
        ].includes(order.type) && order.status !== OrderStatus.PLACED,
    );
  }

  get _stopOrders() {
    // return this._orders?.filter(order =>
    //   [OrderStatus.OPEN].includes(order.status),
    // );
    return this._orders?.filter(order =>
      [OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(order.type),
    );
  }

  get _fills() {
    return this._accountStats?.fills;
    // ?.slice()
    // ?.sort((a, b) => b.timestamp - a.timestamp);
  }

  get _balanceOperations() {
    return this._accountStats?.balanceOperations;
    // ?.slice()
    // ?.sort((a, b) => b.timestamp - a.timestamp);
  }

  get _vaultBalanceOperations() {
    return this._accountStats?.balanceOperations?.filter(
      balanceOperation =>
        [BalanceOpsType.Stake, BalanceOpsType.UnstakeShares].includes(
          balanceOperation.opsType as BalanceOpsType,
        ) ||
        [BalanceOpsType.Stake, BalanceOpsType.UnstakeShares].includes(
          balanceOperation.opsType as BalanceOpsType,
        ),
    );
  }

  get isVaultAccount() {
    return this._accountStats?.profileType === ProfileType.VAULT;
  }
}
