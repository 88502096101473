import { memo, useState } from 'react';

import { showNotification, textWithEllipsisInTheMiddle } from 'utils';

import useModal from 'hooks/useModal';

import EditModal from 'components/EditModal';
import Switch from 'components/Toggle/Switch';

import { ErrorText, ToggleContainer } from './styles';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';

import { NotificationType } from 'enums';
import { observer } from 'mobx-react';

type Props = {
  account: string;
  defaultValue: string;
  onUpdate: (name: string) => void;
  gtmId?: string;
};

const MAX_WALLET_NAME_LENGTH = 14;

const EditWalletName = ({ account, defaultValue, onUpdate, gtmId }: Props) => {
  const modal = useModal();
  const [errorMessage, setErrorMessage] = useState('');
  const [hiddenFromLeaderboards, setHiddenFromLeaderboards] = useState(false); // replace with deafult value later

  const toggleHiddenFromLeaderboards = () => {
    setHiddenFromLeaderboards(prev => !prev);
  };

  const handleUpdate = (name: string) => {
    if (name.length > 20) {
      setErrorMessage('Maximum 20 characters');
      return;
    } else if (name.length < 3) {
      setErrorMessage('Minimum 3 characters');
      return;
    } else {
      setErrorMessage('');
    }

    try {
      onUpdate(name);
      showNotification({
        title: 'Successfully updated.',
        description: `Name for wallet ${textWithEllipsisInTheMiddle(
          account,
        )} has been updated.`,
        type: NotificationType.Positive,
      });
    } catch (err) {
      console.log(err);
      showNotification({
        title: 'Something Went Wrong.',
        description: `We weren't able to update anme for your wallet : ${textWithEllipsisInTheMiddle(
          account,
        )}.`,
        type: NotificationType.Negative,
      });
    } finally {
      modal.pop({ name: Modals.editWalletNameModal });
    }
  };

  return (
    <EditModal
      placeholder="Name your wallet"
      onUpdate={handleUpdate}
      defaultValue={defaultValue}
      errorMessage={errorMessage}
      name={Modals.editWalletNameModal}
      maxLength={MAX_WALLET_NAME_LENGTH}
      gtmId={gtmId}
    >
      <ErrorText>{`Wallet name should be below/equal to ${MAX_WALLET_NAME_LENGTH} characters.`}</ErrorText>

      {/* <ToggleContainer>
        <div className="flexed">
          <span className="heading">Hide from Leaderboard</span>
          <span className="description">
            Wallet name is used for leaderboards.
          </span>
        </div>
        <Switch
          on={hiddenFromLeaderboards}
          onToggle={toggleHiddenFromLeaderboards}
        />
      </ToggleContainer> */}
    </EditModal>
  );
};

export default EditWalletName;
