import styled from 'styled-components';

type ContainerProps = {
  scrollLeft?: number;
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 10px;
  flex-direction: column;
  position: relative;

  .mesh-dropdown {
    position: absolute;
    top: 90px;
    left: 105px;
    min-width: 150px;
  }

  .title {
    font-size: 12px;
    font-weight: ${({ theme }) => theme.colors.medium};
    line-height: normal;
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .method-row {
    display: flex;
    gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: flex-end;

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.shadesBackground500};
    }
  }

  .method-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .coming-soon-badge {
      position: relative;
      display: flex;
      width: max-content;
      padding: 4px 6px;
      border-radius: 100px;
      background: ${({ theme }) => theme.colors.goldGradient200};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      color: ${({ theme }) => theme.colors.warningForeground100};
      margin-bottom: -10px;
      right: -4px;
      justify-self: flex-end;
      z-index: 5;
      font-size: 10px;
      line-height: 14px;
    }
  }
`;

type MethodProps = {
  isFocused: boolean;
  isDisabled: boolean;
  isComingSoon: boolean;
  isHovered?: boolean;
};
export const Method = styled.div<MethodProps>`
  display: flex;
  position: relative;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  box-sizing: border-box;
  overflow: visible;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  background: ${({ theme, isHovered }) =>
    theme.colors[isHovered ? 'shadesBackground600' : 'shadesBackground700']};
  border: 1px solid
    ${({ theme, isFocused }) =>
      isFocused ? theme.colors.white : 'transparent'};

  :hover {
    background: ${({ theme }) => theme.colors.shadesBackground600};
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    padding: 12px 20px;
  }

  > span {
    font-size: 12px;
    line-height: normal;
    font-weight: ${({ theme }) => theme.colors.medium};
    line-height: normal;
    color: ${({ theme, isFocused }) =>
      isFocused ? theme.colors.white : theme.colors.shadesForeground200};

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  > img {
    height: 16px;
  }

  .checked-container {
    display: flex;
    width: 14px;
    height: 14px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -1.5px;
    top: -1.5px;
    border-radius: 100px;
    border: 1.5px solid ${({ theme }) => theme.colors.shadesBackground900};
    background: ${({ theme }) => theme.colors.white};

    > img {
      width: 8px;
      height: 8px;
    }
  }
  opacity: ${({ isFocused, isDisabled }) =>
    !isFocused && isDisabled ? 0.3 : 1};
  cursor: ${({ isComingSoon, isDisabled }) =>
    isDisabled || isComingSoon ? 'not-allowed' : 'pointer'};
`;
