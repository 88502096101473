import { Tooltip } from '@material-ui/core';

import { FormattedNumber } from 'components';
import Text from 'components/Text';

import { StyledStatItem } from './styles';
import 'odometer/themes/odometer-theme-default.css';

import Odometer from 'react-odometerjs';

type StatItemProps = {
  label: string;
  value: number | undefined;
  alignRight?: boolean;
  className?: string;
  suffix?: string;
  abbreviated?: boolean;
  redacted?: boolean;
  fullWidth?: boolean;
};

const StatItem = ({
  label,
  value,
  alignRight,
  className,
  abbreviated = true,
  suffix,
  redacted,
  fullWidth,
}: StatItemProps) => {
  return (
    <StyledStatItem alignRight={alignRight} fullWidth={fullWidth}>
      <Text variant="BODY_S" color="shadesForeground200" fontWeight="semiBold">
        {label}
      </Text>
      {redacted ? (
        <Tooltip title="This will be revealead at a later stage.">
          <Text variant="NUMBERS_L" fontWeight="semiBold" className={className}>
            [REDACTED]
          </Text>
        </Tooltip>
      ) : (
        <FormattedNumber value={value || 0} className={`${className} value`} />
      )}
    </StyledStatItem>
  );
};

export default StatItem;
