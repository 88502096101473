import { useContext } from 'react';

import {
  EmptyExchangeApiContext,
  ExchangeApiContext,
} from 'contexts/ExchangeApiContext';

export const useExchangeAPI = (throwError: boolean = true) => {
  const context = useContext(ExchangeApiContext);
  if (context) {
    return context;
  }
  if (throwError) {
    throw Error('Context Provider is not provided!');
  }
  return EmptyExchangeApiContext;
};
