import { isBrandBfx } from 'utils/brand';

import styled from 'styled-components';

export const TradingChartTabsStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px;
  /* min-width: 0; */
  background: ${({ theme }) =>
    isBrandBfx
      ? theme.colors.shadesBackground700TransparentChart
      : theme.colors.shadesBackground800};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};
  /* height: calc(100% - 40px); */

  .MuiTabs-scroller.MuiTabs-fixed {
    overflow-x: auto !important;
    border-bottom: 2px solid ${({ theme }) => theme.colors.shadesBackground600};
    /* margin: 0 -10px; */
  }

  div[role='tabpanel'] {
    /* margin: 10px; */

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      /* margin: 30px 20px; */
    }
  }
`;
