import { memo, useState } from 'react';

import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import { VaultType, ViewType } from '../../../../../enums';
import { VaultWalletShares } from '../../ElixirVaults';
import Filters from './Filters';
import List from './List';
import { getFilteredVaults } from './utils';
import { VaultManager } from 'enums/vaultManager';
import { ColoredText, Colors } from 'pages/Trade/components/shared';
import VaultActivityTable from 'pages/Vaults/common/VaultActivityTable';
import { getTotalApyForVault } from 'pages/Vaults/utils';

import { useTranslation } from 'react-i18next';

type Props = {
  vaults: VaultWithContractInfo[] | undefined;
  isLoadingElixirVaults: boolean;
  isErrorElixirVaults: boolean;
  vaultsWalletShares?: VaultWalletShares;
  vaultManager: VaultManager;
};
const VaultsList = ({
  vaults,
  isLoadingElixirVaults,
  isErrorElixirVaults,
  vaultsWalletShares,
  vaultManager,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [viewType, setViewType] = useState(ViewType.GRID);
  const [type, setType] = useState(VaultType.ALL);
  const { t } = useTranslation();
  const showActivity = type === VaultType.MY_VAULTS;

  const filteredVaults = getFilteredVaults({
    vaults,
    searchTerm,
    type,
    vaultsWalletShares,
  });

  const initialVaultId = vaults?.[0]?.productId;

  const maxApy = vaults
    ? Math.max(...vaults.map(vault => getTotalApyForVault(vault)))
    : undefined;

  return (
    <>
      <Filters
        searchTerm={searchTerm}
        onChangeSearchTerm={setSearchTerm}
        viewType={viewType}
        onChangeViewType={setViewType}
        type={type}
        onChangeType={setType}
      />

      {isErrorElixirVaults ? (
        <ColoredText color={Colors.Danger}>
          {t('errorFetchingVaultsPleaseRefresh')}
        </ColoredText>
      ) : (
        <List
          viewType={viewType}
          vaults={filteredVaults}
          vaultsWalletShares={vaultsWalletShares}
          isLoading={isLoadingElixirVaults}
          vaultManager={vaultManager}
          initialVaultId={initialVaultId}
          maxApy={maxApy}
        />
      )}

      {showActivity ? (
        <VaultActivityTable isLoadingVaults={isLoadingElixirVaults} />
      ) : null}
    </>
  );
};

export default memo(VaultsList);
