import { useEffect } from 'react';

type Props = {
  handleSubmit?: (e?: any) => void;
  handleClose?: () => void;
};
const useModalKeyListener = ({ handleSubmit, handleClose }: Props) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit && handleSubmit(event);
      }
      if (event.key === 'Escape') {
        handleClose && handleClose();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleClose, handleSubmit]);
};

export default useModalKeyListener;
