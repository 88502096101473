import { useContext } from 'react';

import {
  EmptyElixirContractsAPIContext,
  ElixirContractsAPIContext,
} from 'contexts/ElixirContractsApiContext';

export const useElixirContractsAPI = (throwError: boolean = true) => {
  const context = useContext(ElixirContractsAPIContext);
  if (context) {
    return context;
  }
  if (throwError) {
    throw Error('Context Provider is not provided!');
  }
  return EmptyElixirContractsAPIContext;
};
