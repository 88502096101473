import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  .available-to-claim {
    > label {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: 10px;
      line-height: 14px;
    }

    .claim-amount {
      margin-top: 3px;
      display: flex;
      gap: 8px;
      align-items: center;
      .formatted-number {
        color: ${({ theme }) => theme.colors.white};
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .divider {
    height: 23px;
    width: 2px;
    margin: auto 15px;
    background: ${({ theme }) => theme.colors.shadesBackground500};
  }

  .claim-button-container {
    display: flex;
    align-items: center;
  }
`;
