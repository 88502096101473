export enum AmendStep {
  UNKNOWN,
  ENABLE_USDT,
  AWAITING_STAKE_CONFIRMATION,
  STAKE,
  UNSTAKE,
  UNSTAKE_FAILED,
  UNSTAKE_SUCCESS,
  STAKE_SUCCESS,
  STAKE_FAILED,
}
