import { notifications } from './notifications';
import CentrifugeService from 'service/centrifugeService';

import { SubscriptionState } from 'centrifuge';

export const checkIfAllChannelsSubscribed = (
  centrifugeService: CentrifugeService,
) => {
  return !Object.values(centrifugeService.centrifuge.subscriptions).some(
    el => el.state !== SubscriptionState.Subscribed,
  );
};

export const showNotificationIfAllChannelsSubscribed = (
  centrifugeService: CentrifugeService,
) => {
  const areAllChannelsSubscribed =
    checkIfAllChannelsSubscribed(centrifugeService);
  if (areAllChannelsSubscribed) {
    notifications.connectionToDataReEstablished('all public data');
  }
};
