import * as React from 'react';
import { useEffect, useState } from 'react';

import {
  getSelectedMarketLeverage,
  roundToNearestTick,
  showNotification,
} from 'utils';

import { useAccountAPI } from 'hooks';

import Text from 'components/Text';

import RiskObservantSlider from './RiskObservantSlider';
import { MarketLeverageSliderStyled } from './styles';
import { useAppContext } from 'contexts/AppContext';

import { NotificationType } from 'enums';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

export const MAX_LEVERAGE = 20;

export const getLeverageFromSliderPercentage = (percentage: number) =>
  roundToNearestTick((percentage * MAX_LEVERAGE) / 100, 1);

export const getSliderPercentageFromCurrentLeverage = (
  leverage: number | null,
) => (leverage ?? 0) * (100 / MAX_LEVERAGE);

const MarketLeverageSlider = ({ className }: { className?: string }) => {
  const {
    store: {
      markets: { selectedMarketId },
      account: accountStore,
    },
  } = useAppContext();
  const { changeMarketLeverage } = useAccountAPI();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const [marketLeverage, setMarketLeverage] = useState<number | null>(null);
  const [defaultSliderValue, setDefaultSliderValue] = useState<number | null>(
    null,
  );
  const [sliderKey, setSliderKey] = useState<number>(0);

  const prevSelectedMarketId = React.useRef<null | string>(null);

  useEffect(() => {
    if (!selectedMarketId || !accountStore._marketLeverage) {
      return;
    }
    const initialMarketLeverage = getSelectedMarketLeverage(
      accountStore._marketLeverage,
      selectedMarketId,
    );
    if (
      prevSelectedMarketId.current === selectedMarketId &&
      initialMarketLeverage === marketLeverage
    ) {
      return;
    }
    prevSelectedMarketId.current = selectedMarketId;
    setMarketLeverage(initialMarketLeverage);
    setDefaultSliderValue(
      getSliderPercentageFromCurrentLeverage(initialMarketLeverage),
    );
    setSliderKey(prev => prev + 1);
    setIsLoading(false);
  }, [selectedMarketId, accountStore._marketLeverage]);

  const handleMarketLeverageChange = async (percentage: number) => {
    if (!selectedMarketId) return;

    const parsedLeverage = getLeverageFromSliderPercentage(percentage);

    // Do nothing if the new leverage is the same as the current one
    if (
      parsedLeverage ===
      getSelectedMarketLeverage(accountStore._marketLeverage, selectedMarketId)
    )
      return;

    try {
      flushSync(() => setIsLoading(true));

      const requestBody = {
        marketID: selectedMarketId,
        leverage: parsedLeverage,
      };
      const newProfileData = await changeMarketLeverage(requestBody);
      accountStore.setAccount(newProfileData);
      setSliderKey(prev => prev + 1);
    } catch (e: any) {
      setIsLoading(false);
      setSliderKey(prev => prev + 1);
      console.error(
        'An error occurred while sending private PUT request for changing market leverage',
        e,
      );
    }
  };

  const isSliderDisabled =
    !accountStore.frontendSecrets ||
    marketLeverage === null ||
    defaultSliderValue === null;

  return (
    <MarketLeverageSliderStyled
      className={className}
      // isDisabled={isSliderDisabled}
      // riskLevel={getRiskLevelFromLeverage(defaultSliderValue)}
    >
      <Text variant="BODY_S" color="white" flexed>
        {t('leverage')}
      </Text>

      <RiskObservantSlider
        isDisabled={isSliderDisabled}
        aria-label="Custom marks"
        defaultValue={defaultSliderValue || 0}
        leverage={marketLeverage}
        onChangeCommitted={(e, v) => handleMarketLeverageChange(v as number)}
        // getAriaValueText={getValueText}
        // step={1}
        valueLabelDisplay="auto"
        // marks={marketLeverageValues}
        // min={1}
        // max={20}
        key={sliderKey}
        disabled={isLoading}
      />

      {/* <Text variant="BODY_XS" color="shadesForeground200">
        * Maximum position size at current leverage:
        <br />
        <FormattedNumber value={80_000} suffix={` ${brand.tokenTicker}`} />
      </Text> */}
    </MarketLeverageSliderStyled>
  );
};

export default observer(MarketLeverageSlider);
