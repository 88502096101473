import { createContext } from 'react';

import { TransactionResponse } from '@ethersproject/providers';

import { RbxRewards } from 'hooks/useElixirVaults';

import { SpotStateType } from 'enums/spotState';
import { SpotType } from 'enums/spotType';
import { ElixirPoolInfoResponse } from 'providers/elixirContractsProvider';

import { BigNumber, constants, ContractTransaction } from 'ethers';

export type SpotState = {
  id: number;
  type: SpotType;
  positionInQueue: number;
  spotStateType: SpotStateType;
};

export type ElixirContractsAPI = {
  getWalletSharesForVault: (poolId: number) => Promise<BigNumber>;
  getVaultRouterUsdtBalance: (routerAddress: string) => Promise<BigNumber>;
  getPendingWalletSharesForVault: (poolId: number) => Promise<BigNumber>;
  getPoolInfo: (poolId: number) => Promise<ElixirPoolInfoResponse>;
  getUsdtAllowanceForRabbitManager: () => Promise<BigNumber>;
  deposit: (
    poolId: number,
    amount: BigNumber,
    wallet: string,
  ) => Promise<ContractTransaction>;
  withdraw: (poolId: number, amount: BigNumber) => Promise<ContractTransaction>;
  claim: (poolId: number) => Promise<ContractTransaction>;
  getSpotState: (txHash: string) => Promise<SpotState>;
  getSpotStateFromId: (spotId: number) => Promise<SpotState>;
  getUserShares: (wallet: string, poolId: number) => Promise<BigNumber>;
  approve: () => Promise<TransactionResponse>;
  isUsdtApproved: (amount?: number) => Promise<boolean>;
  trackVaultActivities: (props?: {
    spotIds?: number[];
    txHashes?: string[];
  }) => () => void;
  handleSpotUpdateOnQueueMove: (
    spotId: number,
    fn: (spotState: SpotState) => void,
  ) => () => void;
  claimRbxRewards: (rbxRewards: RbxRewards) => Promise<ContractTransaction>;
  getUserClaimedRbxRewards: () => Promise<BigNumber>;
  areReadContractsSet: boolean;
};

export const EmptyElixirContractsAPIContext = {
  getWalletSharesForVault: () => {
    return constants.Zero;
  },
  getVaultRouterUsdtBalance: () => {
    return constants.Zero;
  },
  getPendingWalletSharesForVault: () => {
    return constants.Zero;
  },
  getPoolInfo: () => {
    return {} as ElixirPoolInfoResponse;
  },
  getUsdtAllowanceForRabbitManager: () => {
    return constants.Zero;
  },
  deposit: () => {
    return {} as ContractTransaction;
  },
  withdraw: () => {
    return {} as ContractTransaction;
  },
  claim: () => {
    return {} as TransactionResponse;
  },
  getUserShares: () => constants.Zero,
  approve: () => {
    return {} as TransactionResponse;
  },
  isUsdtApproved: () => false,
  claimRbxRewards: () => {
    return {} as TransactionResponse;
  },
  getUserClaimedRbxRewards: () => {
    return constants.Zero;
  },
  areReadContractsSet: false,
  getSpotState: () => {
    return {} as SpotState;
  },
  getSpotStateFromId: () => {
    return {} as SpotState;
  },
  handleSpotUpdateOnQueueMove: () => {
    return () => {};
  },
  trackVaultActivities: () => {},
};
const ElixirContractsAPIContext = createContext<ElixirContractsAPI | null>(
  null,
);

export { ElixirContractsAPIContext };
