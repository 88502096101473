import { useEffect, useState } from 'react';

import { DefaultEventsMap } from '@socket.io/component-emitter';

import { capitalizeFirstLetter, openTransactionOnExplorer } from 'utils';
import {
  removeTransferFromLocalStorage,
  saveTransferToLocalStorage,
  showMeshUpdateToast,
} from 'utils/activeMeshTransactions';
import { savePendingTxToastIdsToLocalStorage } from 'utils/activeTransactions';
import { brand } from 'utils/brand';

import { useActiveWeb3React } from 'hooks';
import { MESH_RBX_BASE_URL, TMeshTransfer } from 'hooks/useMeshAPI';
import useModal from 'hooks/useModal';

import { Icon } from 'components';
import Steps from 'components/ProgressiveSteps';
import Text from 'components/Text';

import { MESH_ORDER_STATUS, getStepDescriptionText } from './meshOrderStatus';
import MockTimer from './mockTimer';
import newTabIcon from 'assets/icons/new-tab-gray.svg';
import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Socket, io } from 'socket.io-client';

const getCurrentStepIndex = (
  orderStatus: MESH_ORDER_STATUS,
  isDepositConfirmed: boolean,
) =>
  isDepositConfirmed
    ? 3
    : orderStatus === MESH_ORDER_STATUS.PENDING_FROM_MESH
    ? 1
    : [
        MESH_ORDER_STATUS.RECEIVED_IN_PROXY_WALLET,
        MESH_ORDER_STATUS.CONTRACT_CALLED,
      ].includes(orderStatus)
    ? 2
    : 0;

export type Props = {
  institutionTxId: string;
  depositAmount: number;
  brokerName?: string;
  processDepositOnIndividualContract: (
    txHash: string,
    depositAmountOnContract: number,
    toastId?: React.ReactText,
  ) => void;
  confirmations: number;
  isDepositConfirmed: boolean;
  modalId?: string;
  initialTxHash?: string;
  error?: string;
  transferInfo?: TMeshTransfer;
  updateToastId?: (toastId: React.ReactText) => void;
};

const ErrorMessage = ({ message }: { message?: string }) => {
  return (
    <Text
      color="primaryRedForeground200"
      variant="BODY_M"
      fontWeight="semiBold"
      className="align-center"
    >
      Something went wrong.{message ? ` ${message} ` : ' '}Please try again.
    </Text>
  );
};

// This can be used to show the status of a mesh order, and start tracking the order, and update the modal metadata, based on the status of the order
// This component is used in the NewDepositModal with meshState or can also be used with transferInfo fetched form the server to continue tracking the order
export const MeshOrderStatus = observer(
  ({
    depositAmount,
    institutionTxId,
    brokerName: _brokerName,
    processDepositOnIndividualContract,
    confirmations,
    isDepositConfirmed,
    modalId,
    error,
    transferInfo,
    updateToastId,
  }: Props) => {
    const initialTxHash = transferInfo?.tx_hash;
    const modals = useModal();
    const { t } = useTranslation();
    const [orderStatus, setOrderStatus] = useState<{
      status: MESH_ORDER_STATUS;
      txHash?: string;
      amount?: number;
    }>({
      status: initialTxHash
        ? MESH_ORDER_STATUS.CONTRACT_CALLED
        : MESH_ORDER_STATUS.PENDING_FROM_MESH,
      txHash: initialTxHash,
    });
    const { account } = useActiveWeb3React();

    const brokerName = _brokerName || 'exchange';

    const txHash = orderStatus.txHash;

    const handleTransferUpdate = (
      data: {
        code: MESH_ORDER_STATUS;
        tx_hash?: string;
        amount?: number;
      },
      socket: Socket<DefaultEventsMap, DefaultEventsMap>,
      toastId: React.ReactText,
    ) => {
      if (!institutionTxId || !modalId || !account || !depositAmount) return;
      if (data.code === orderStatus.status) return; // dont update if the status is the same

      if (data.code) {
        if (
          [
            MESH_ORDER_STATUS.PENDING_FROM_MESH,
            MESH_ORDER_STATUS.RECEIVED_IN_PROXY_WALLET,
            MESH_ORDER_STATUS.CONTRACT_CALLED,
            MESH_ORDER_STATUS.CONTRACT_CALL_FAILED,
            MESH_ORDER_STATUS.ERRORED_OUT,
          ].includes(data.code)
        ) {
          setOrderStatus({
            status: data.code,
            txHash: data.tx_hash,
            amount: data.amount,
          });

          if (
            data.amount &&
            [
              MESH_ORDER_STATUS.RECEIVED_IN_PROXY_WALLET,
              MESH_ORDER_STATUS.CONTRACT_CALLED,
            ].includes(data.code)
          ) {
            console.log('UPDATING MESH TOAST', data);
            showMeshUpdateToast(
              data.amount,
              data.code,
              brokerName,
              toastId,
              modalId,
            );
          }

          // On error set Modal Metadata status to error
          const status = [
            MESH_ORDER_STATUS.CONTRACT_CALL_FAILED,
            MESH_ORDER_STATUS.ERRORED_OUT,
          ].includes(data.code)
            ? {
                status: 'error' as const,
              }
            : {};

          // update modal metadata
          modals.setModalMetadata(modalId, {
            description: getStepDescriptionText(data.code),
            ...status,
          });

          // if all conditions are met, call the process funciton
          if (
            data.code === MESH_ORDER_STATUS.CONTRACT_CALLED &&
            data.tx_hash &&
            data.amount
          ) {
            processDepositOnIndividualContract(
              data.tx_hash,
              data.amount,
              toastId,
            );
            removeTransferFromLocalStorage(institutionTxId, account); // remove transfer from local storage, processDepositOnIndividualContract will handle the rest from here
            socket.disconnect();
          }

          // On all other data codes, we can stop listening, based on how we send updates from the server
          if (data.code !== MESH_ORDER_STATUS.RECEIVED_IN_PROXY_WALLET) {
            socket.disconnect();
          }
        }
      }
    };

    useEffect(() => {
      if (!account) {
        modalId && modals.clearById(modalId);
        return;
      }

      if (txHash) {
        processDepositOnIndividualContract(txHash, depositAmount);
        removeTransferFromLocalStorage(institutionTxId, account);
        return;
      }

      let socket: Socket<DefaultEventsMap, DefaultEventsMap>;

      if (institutionTxId && modalId && depositAmount) {
        const toastId = showMeshUpdateToast(
          depositAmount,
          MESH_ORDER_STATUS.PENDING_FROM_MESH,
          brokerName,
          undefined,
          modalId,
        );
        updateToastId?.(toastId); // update the toastId in the parent component, if it exists

        savePendingTxToastIdsToLocalStorage(toastId);

        // Save transfer to local storage
        saveTransferToLocalStorage(
          institutionTxId,
          account,
          depositAmount,
          brokerName,
        );

        modals.setModalMetadata(modalId, {
          title: t('deposit'),
          description: `${capitalizeFirstLetter(brokerName)} transfer`,
          status: 'processing',
        });

        socket = io(MESH_RBX_BASE_URL, {
          transports: ['websocket'],
          autoConnect: false,
        });

        socket.connect();

        // client-side
        socket.on('connect', () => {
          socket.emit('join', institutionTxId);
        });

        socket.on('transfer_update', payload =>
          handleTransferUpdate(payload, socket, toastId),
        );
      }

      return () => {
        socket?.disconnect();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const finalOrderStatus =
      orderStatus?.status ||
      (error
        ? MESH_ORDER_STATUS.ERRORED_OUT
        : MESH_ORDER_STATUS.PENDING_FROM_MESH);

    const orderId = institutionTxId;

    // Handle orderId not found
    if (!orderId) return <ErrorMessage />;

    // Handle errors from mesh's end
    if (finalOrderStatus === MESH_ORDER_STATUS.ERRORED_OUT)
      return <ErrorMessage message={error} />;

    //  Handle Contract Call Failed
    if (finalOrderStatus === MESH_ORDER_STATUS.CONTRACT_CALL_FAILED) {
      return (
        <Text
          color="shadesForeground200"
          variant="BODY_S"
          className="align-center"
        >
          Unable to process your transaction!
          <br />
          <br />
          We were unable to your Mesh order with id :{' '}
          <Text color="white">{orderId}</Text>. Please contact support.
        </Text>
      );
    }

    const currentStepIndex = getCurrentStepIndex(
      orderStatus?.status,
      isDepositConfirmed,
    );

    const startTime = transferInfo?.timestamp;

    return (
      <Steps
        steps={[
          {
            title: <>Initiate {brokerName} transfer</>,
            description: `Order id : ${orderId}`,
          },
          {
            title: (
              <>
                Awaiting confirmation from {brokerName} (
                {`${depositAmount} ${brand.tokenTicker}`})
              </>
            ),
            description:
              currentStepIndex === 1 ? (
                <MockTimer
                  startTime={startTime ? startTime * 1000 : undefined}
                />
              ) : (
                '(approx. 5-10mins)'
              ),
          },
          {
            title: <>Waiting for on-chain confirmations</>,
            description: (
              <Text flexed gap={5}>
                (
                {isDepositConfirmed
                  ? CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT
                  : confirmations}
                /{CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT} deposit
                confirmations)
                {txHash ? (
                  <Icon
                    src={newTabIcon}
                    size="S"
                    onClick={() => openTransactionOnExplorer(txHash)}
                  />
                ) : null}
              </Text>
            ),
          },
        ]}
        currentStepIndex={currentStepIndex}
        forceShowDescription
      />
    );
  },
);
