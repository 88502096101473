import { memo, useState } from 'react';

import {
  SelectDropdown,
  Props as SelectDropdownProps,
} from 'components/SelectDropdown.tsx';

import FilterInput, { FilterInputProps } from './FilterInput';

type Props<T> = {
  renderOption: (option: T) => JSX.Element;
} & Omit<SelectDropdownProps<T>, 'displayTextParser' | 'InputElement'> &
  Omit<FilterInputProps, 'isActive'>;

/**
 * FilterSelector Component
 *
 * Displays a filter selector component with an icon, label, and dynamic value, which can be either a string or a React element.
 *
 * @component
 * @param {string} icon - The source URL for the main icon.
 * @param {string} label - The label associated with the filter.
 * @param {string | React.ReactElement } value - The value to be displayed, which can be either a string or a custom React element.
 * @param {string} leftValueIcon - Icon to the left of value, only renders if value is of type string.
 * @param {string} valueClass - overrides value's class if provided.
 * @param {...props} props.* - Any additional props are accepted and forwarded to SelectDropdown component except inputElement and displayTextParser(use renderOption instead).
 * @returns {JSX.Element} - Rendered FilterSelector component.
 */
const FilterSelector = <T,>({
  icon,
  label,
  value,
  renderOption,
  leftValueIcon,
  valueClass,
  ...rest
}: Props<T>): JSX.Element => {
  const [isDropDownShown, setDropdownShown] = useState(false);
  return (
    <SelectDropdown
      onDropdownShownChange={setDropdownShown}
      InputElement={
        <FilterInput
          isActive={isDropDownShown}
          icon={icon}
          label={label}
          value={value}
          leftValueIcon={leftValueIcon}
          valueClass={valueClass}
        />
      }
      displayTextParser={renderOption}
      customStyles={{
        liPadding: '0px',
        top: '60px',
        maxHeight: '216px',
      }}
      {...rest}
    />
  );
};

export default memo(FilterSelector) as <T>(props: Props<T>) => JSX.Element;
