import Text from 'components/Text';

import styled from 'styled-components';

export const FiltersStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    flex-direction: row;
    gap: 0px;
  }

  > div:first-child {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 5px;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row-reverse;

    @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
      width: auto;
      flex-direction: row;
    }
  }

  .search-container {
    width: 100%;

    @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
      width: 222px;
    }
  }
`;

type StyledMenuItemProps = {
  isActive: boolean;
};
export const StyledMenuItem = styled(Text)<StyledMenuItemProps>`
  display: flex;
  padding: 14px;
  cursor: pointer;
  border-radius: 8px;
  flex: 1;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    flex: initial;
  }

  font-weight: ${({ theme, isActive }) =>
    isActive ? theme.fontWeights.semiBold : theme.fontWeights.medium};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.white : theme.colors.shadesForeground200};
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.shadesBackground700 : 'transparent'};
  height: min-content;
`;
