import { capitalizeFirstLetter } from 'utils';

import Icon from 'components/Icon';
import TextToolTip from 'components/InfoTooltip/TextToolTip';
import Text from 'components/Text';
import { ITextVariants } from 'components/Text/variant';

import xGrayIcon from 'assets/icons/close-x.svg';
import pendingIcon from 'assets/icons/pending.svg';

import { OrderStatus } from 'enums';

const MaybeAToolTip = props => {
  if (props.reason) {
    return <TextToolTip title={props.reason} {...props} />;
  }
  return <Text {...props} />;
};

interface Props {
  status: OrderStatus;
  reason?: string;
  t: any;
  variant?: ITextVariants;
  returnStrings?: boolean;
}

const OrderStatusToLabelTx = {
  [OrderStatus.CANCELED]: 'cancelled',
  [OrderStatus.CLOSED]: 'filled',
  [OrderStatus.REJECTED]: 'rejected',
  [OrderStatus.PROCESSING]: 'pending',
  [OrderStatus.OPEN]: 'open',
  [OrderStatus.PLACED]: 'places',
};

export const OrderStatusCol = ({
  status,
  t,
  reason,
  variant,
  returnStrings,
}: Props) => {
  const fontVariantProps = variant
    ? { variant }
    : {
        responsiveVariant: { default: 'BODY_M' as ITextVariants },
        variant: 'BODY_S' as ITextVariants,
      };

  const label = OrderStatusToLabelTx[status]
    ? t(OrderStatusToLabelTx[status])
    : capitalizeFirstLetter(status);

  if (returnStrings) return <Text {...fontVariantProps}>{label}</Text>;

  switch (status) {
    case OrderStatus.REJECTED: {
      return (
        <MaybeAToolTip
          reason={reason}
          color="shadesForeground200"
          {...fontVariantProps}
        >
          {t('rejected')}
        </MaybeAToolTip>
      );
    }
    case OrderStatus.PROCESSING: {
      return (
        <Text flexed gap={2} {...fontVariantProps}>
          <Icon size="S" src={pendingIcon} />
          {t('pending')}
        </Text>
      );
    }
    case OrderStatus.OPEN: {
      return (
        <Text
          variant="BODY_S"
          responsiveVariant={{ default: 'BODY_M' }}
          color="primaryGreenForeground100"
        >
          {t('open')}
        </Text>
      );
    }
    case OrderStatus.PLACED: {
      return (
        <Text color="secondaryGold400" {...fontVariantProps}>
          {t('placed')}
        </Text>
      );
    }
    case OrderStatus.CANCELED: {
      return (
        <Text flexed gap={2} color="shadesForeground200" {...fontVariantProps}>
          <Icon size="S" src={xGrayIcon} />
          {t('cancelled')}
        </Text>
      );
    }
    case OrderStatus.CLOSED: {
      return <Text {...fontVariantProps}>{t('filled')}</Text>;
    }
    default: {
      return <Text {...fontVariantProps}>{capitalizeFirstLetter(status)}</Text>;
    }
  }
};
