import { memo } from 'react';

import { brand } from 'utils/brand';

import { PlatformVaultsDataWithHoldings, useVerifyChainId } from 'hooks';
import useModal from 'hooks/useModal';

import { FormattedNumber, Icon } from 'components';
import Badge from 'components/Badge';
import Button from 'components/Button/button';
import { isBfxDepositBonusActive } from 'components/DepositBonusModal/utils';
import IconPair from 'components/IconPair';
import Text from 'components/Text';

import {
  StyledVaultCard,
  Row,
  Column,
} from '../../../common/VaultList/List/Grid/styles';
import AmendPlatformVaultLiquidityModal from '../../AmendPlatformVaultLiquidityModal';
import ActiveBonus from '../../AmendPlatformVaultLiquidityModal/ActiveBonus';
import { useAmendPlatformVault } from '../../useAmendPlatformVault';
import { getTotalVaultApy } from '../utils';
import blastLogo from 'assets/assetLogos/blast-green.svg';
import infoCircleIcon from 'assets/icons/info-circle-white.svg';
import { SHOULD_DISABLE_DEPOSITS_WITHDRAWALS } from 'constants/general';
import { Modals } from 'constants/modals';
import { AmendType } from 'pages/Vaults/common/AmendLiquidityModal';
import {
  getPositivePPS,
  navigateToDetailedPlatformVault,
} from 'pages/Vaults/utils';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  vault: PlatformVaultsDataWithHoldings | undefined;
  isUserAuthenticated: boolean;
  manager: string;
  isVaultAccount: boolean;
};

const StyledApyContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > div {
    width: auto;
  }

  .active-bonus {
    width: 109px;
    box-sizing: border-box;
    height: 42px;
  }

  .content-class {
    padding: 6px;
  }

  .items-start {
    align-items: flex-start;
  }
`;

const VaultCard = ({
  vault,
  isUserAuthenticated,
  manager,
  isVaultAccount,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { amendPlatformVault } = useAmendPlatformVault();

  if (!vault) return null;

  const userShares = vault.holdings?.userShares;

  const isSuspended = vault.status === 'suspended';
  const shouldDisableActionButtons = isVaultAccount || isSuspended;

  const showLiquidityModal = (amendType: AmendType) => {
    if (shouldDisableActionButtons) return;

    amendPlatformVault(amendType, vault.wallet);
  };

  return (
    <StyledVaultCard>
      <div
        className="top-part"
        onClick={() => navigateToDetailedPlatformVault(navigate, vault.wallet)}
      >
        <Row className="space-between items-center">
          <Row className="gap-5">
            {/* <img
              className="icon"
              src={isWatched ? starFilledIcon : starOutlineIcon}
              alt="star"
            /> */}

            <Column className="gap-2">
              <Text variant="BODY_M" fontWeight="semiBold">
                {vault.vaultName}

                <Text
                  variant="BODY_S"
                  className={`active-badge ml-10 ${
                    !isSuspended ? 'success' : 'warning'
                  }`}
                >
                  {t(!isSuspended ? 'active' : 'inactive')}
                </Text>
              </Text>
              <Text variant="BODY_S" color="shadesForeground200">
                {vault.managerName}
              </Text>
            </Column>
          </Row>

          <IconPair
            size={24}
            icons={[blastLogo, brand.tokenLogo]}
            // alt={brand.tokenTicker}
          />
        </Row>

        <StyledApyContainer>
          <Row className="gap-5 items-center">
            <FormattedNumber
              variant="NUMBERS_XL"
              fontWeight="semiBold"
              value={getTotalVaultApy(vault.apy)}
              suffix="%"
              styleAsTooltip
              tooltipText={t('vaultApyTooltip')}
            />

            <Badge
              variant="BODY_XS"
              bgColor="positiveBackground100"
              padding="4px"
              color="positiveForeground200"
            >
              {t('apy')}
            </Badge>

            {/* <Badge
              bgColor="positiveBackground100"
              color="positiveForeground200"
              variant="BODY_S"
              padding="4px"
            >
              +{cashYieldPercentage}% Yield
            </Badge> */}
          </Row>

          {isBfxDepositBonusActive ? (
            <>
              <ActiveBonus
                innerClass="active-bonus"
                contentClass="content-class"
                hideBadgeAndTooltip
                isSizeXs
                alignRight
              />
            </>
          ) : null}
        </StyledApyContainer>

        <Column className="gap-2">
          <Row className="space-between">
            <Text variant="BODY_S" color="shadesForeground200">
              {t('shares')}
            </Text>
            <FormattedNumber variant="BODY_S" value={vault.totalShares} />
          </Row>

          <Row className="space-between">
            <Text variant="BODY_S" color="shadesForeground200">
              {t('pricePerShare')}
            </Text>
            <FormattedNumber
              variant="BODY_S"
              value={getPositivePPS(vault.sharePrice)}
              suffix={` ${brand.tokenTicker}`}
            />
          </Row>

          <Row className="space-between">
            <Text variant="BODY_S" color="shadesForeground200">
              {t('tvl')}
            </Text>
            <FormattedNumber
              variant="BODY_S"
              value={vault.accountEquity}
              suffix={` ${brand.tokenTicker}`}
            />
          </Row>
        </Column>

        {isUserAuthenticated ? (
          <Row className="gap-10">
            <img src={infoCircleIcon} alt="info" />

            <Button
              colorVariant="primaryGreen"
              block
              sizeVariant="S"
              disabled={
                shouldDisableActionButtons ||
                SHOULD_DISABLE_DEPOSITS_WITHDRAWALS
              }
              onClick={e => {
                e.stopPropagation();
                showLiquidityModal(AmendType.Stake);
              }}
              data-gtmid="button-stake-vault-init"
            >
              {t('stake')}
            </Button>

            {userShares ? (
              <Button
                colorVariant="secondary"
                block
                sizeVariant="S"
                disabled={
                  shouldDisableActionButtons ||
                  SHOULD_DISABLE_DEPOSITS_WITHDRAWALS
                }
                onClick={e => {
                  e.stopPropagation();
                  showLiquidityModal(AmendType.UnStake);
                }}
                data-gtmid="button-unstake-vault-init"
              >
                {t('unstake')}
              </Button>
            ) : null}
          </Row>
        ) : null}
      </div>
      {isUserAuthenticated ? (
        <Row className="bottom-part space-between">
          <Text variant="BODY_S" color="shadesForeground200">
            {isUserAuthenticated && userShares ? 'My Position' : 'No Position'}
          </Text>
          {isUserAuthenticated && userShares ? (
            <FormattedNumber
              variant="BODY_S"
              value={userShares}
              suffix={' ' + t('shares')}
            />
          ) : null}
        </Row>
      ) : null}
    </StyledVaultCard>
  );
};

export default memo(VaultCard);
