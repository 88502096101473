import { useState } from 'react';

import { isBrandRabbitX } from 'utils/brand';

import { useVerifyChainId } from 'hooks';
import useModal from 'hooks/useModal';

import { SpecialButton } from 'components/Button/button';
import Text from 'components/Text';

import StatusIcon from './statusIcon';
import downloadWhite from 'assets/icons/download-white.svg';
import downloadPrimary from 'assets/icons/downloadPrimary.svg';
import { SHOULD_DISABLE_DEPOSITS_WITHDRAWALS } from 'constants/general';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import DepositModal from 'pages/Trade/components/AccountStats/NewDepositModal';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  display: flex;
  position: relative;
  gap: 5px;

  .spacer {
    height: 10px;
    align-self: stretch;
  }

  .hovered-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 34px;
    left: 0;
    z-index: 10;
  }

  .content {
    border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
    background: ${({ theme }) => theme.colors.shadesBackground800};
    box-shadow: 2px 2px 40px 0px rgba(23, 23, 27, 0.8);
    padding: 5px;
    gap: 5px;
    z-index: 2;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    > span {
      padding: 5px 10px;
      display: flex;
    }
  }

  .badge {
    display: flex;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: ${({ theme }) => theme.colors.positiveForeground300};
    border: 1px solid ${({ theme }) => theme.colors.white};
  }
`;

const DepositButtonWithBadge = () => {
  const [hovered, setHovered] = useState(false);
  const modalContext = useModal();
  const { validateNetworkAndDoAction } = useVerifyChainId();
  const { t } = useTranslation();
  const {
    store: {
      account: { frontendSecrets, isVaultAccount },
    },
  } = useAppContext();

  const handleDepositClick = () => {
    validateNetworkAndDoAction(() => {
      modalContext.present(<DepositModal />, Modals.depositModal);
    }, 'You can now deposit funds.');
  };

  const minimisedDepositModals = modalContext.activeModals.filter(
    i => i.minimised && i.name === Modals.depositModal,
  );

  const count = minimisedDepositModals.length;

  const isAuthenticated = !!frontendSecrets;

  return (
    <Container
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <SpecialButton
        colorVariant="longBuy"
        sizeVariant="S"
        data-gtmid="button-deposit"
        className="relative"
        onClick={handleDepositClick}
        disabled={
          !frontendSecrets ||
          isVaultAccount ||
          SHOULD_DISABLE_DEPOSITS_WITHDRAWALS
        }
        rightIcon={
          isBrandRabbitX
            ? { default: downloadPrimary, hovered: downloadWhite }
            : undefined
        }
        brandSpecificProps={{
          rabbitx: { colorVariant: 'longBuy' },
          bfx: { colorVariant: 'bfxMain' },
        }}
      >
        {t('deposit')}
        {count && isAuthenticated ? (
          <Text variant="BODY_XS" color="white" className="badge">
            {count}
          </Text>
        ) : null}
      </SpecialButton>

      {hovered && count && isAuthenticated ? (
        <div className="hovered-content">
          <div className="spacer" />

          <div className="content">
            {minimisedDepositModals.map((modal, index) => (
              <Text
                variant="BODY_XS"
                fontWeight="semiBold"
                color="shadesForeground200"
                hoveredColor="white"
                cursorPointer
                onClick={() => modal.id && modalContext.maximiseById(modal.id)}
                noWrap
                numOfLines={1}
                flexed
                gap={5}
              >
                <StatusIcon status={modal.metadata?.status} />
                {modal.metadata?.title || modal.name} (
                {modal.metadata?.description})
              </Text>
            ))}
          </div>
        </div>
      ) : null}
    </Container>
  );
};

export default observer(DepositButtonWithBadge);
