import { PositionSortConfig, SortBy } from '.';
import { Markets } from 'enums';
import { Position } from 'interfaces';

/**
 * Filter positions by selected market and showAllMarkets flag (if we are on the trade page)
 */
export const filterPositions = ({
  _positions,
  showAllMarkets,
  selectedMarketId,
  isTradePage,
}: {
  _positions: Position[] | undefined;
  showAllMarkets: boolean;
  selectedMarketId: string | undefined;
  isTradePage?: boolean;
}) => {
  let positions = _positions ? [..._positions] : [];

  // If we are on the trade page and we don't want to show all markets
  if (isTradePage && !showAllMarkets) {
    positions = positions.filter(
      position => position.marketID === selectedMarketId ?? Markets.BTC_USD,
    );
  }

  return positions;
};

/**
 * Sort positions by selected sort by option (if any)
 */
export const sortPositions = (
  _positions: Position[] | undefined,
  sortConfig: PositionSortConfig | null,
) => {
  let positions = _positions ? [..._positions] : [];

  if (!sortConfig) return positions;

  const factor =
    sortConfig.numOfClicks % 3 === 1
      ? -1
      : sortConfig.numOfClicks % 3 === 2
      ? 1
      : 0;

  if (factor === 0) return positions;

  if (sortConfig.sortBy === SortBy.PNL) {
    positions = positions.sort(
      (a, b) => factor * (b.unrealizedPnl - a.unrealizedPnl),
    );
  }
  if (sortConfig.sortBy === SortBy.NOTIONAL) {
    positions = positions.sort((a, b) => factor * (b.notional - a.notional));
  }

  return positions;
};
