import { memo } from 'react';

import { FormattedNumber } from 'components';
import Text from 'components/Text';

type StatBoxProps = {
  title: string;
  value: number;
  valuePrefix?: string;
  valueSuffix?: string;
  valueDecimalScale?: number;
  valueIcon?: string;
};
const StatBox = ({
  title,
  value,
  valuePrefix = '',
  valueSuffix = '',
  valueDecimalScale = 2,
  valueIcon,
}: StatBoxProps) => {
  return (
    <div className="stat">
      <Text variant="BODY_S" color="shadesForeground200">
        {title}
      </Text>
      <Text variant="BODY_S" fontWeight="semiBold">
        {valueIcon && (
          <img src={valueIcon} alt="value icon" className="value-icon" />
        )}
        <FormattedNumber
          value={value}
          prefix={valuePrefix}
          suffix={valueSuffix}
          decimalScale={valueDecimalScale}
        />
      </Text>
    </div>
  );
};

export default memo(StatBox);
