import { memo } from 'react';

import EmptyTableBody from 'components/EmptyStateOverlay/emptyTableBody';

import EmptyVaultRow from './EmptyVaultRow';
import folderQuestionIcon from 'assets/icons/folder-question-white.svg';

import { useTranslation } from 'react-i18next';

type Props = { isLoading: boolean };
const EmptyVaultTableState = ({ isLoading }: Props) => {
  const { t } = useTranslation();
  return (
    <EmptyTableBody
      isLoading={isLoading}
      RowComponent={<EmptyVaultRow />}
      icon={folderQuestionIcon}
      title={t('noVaultsFound')!}
      description={t('noVaultsFoundDescription')}
    />
  );
};

export default memo(EmptyVaultTableState);
