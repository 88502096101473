import { useState } from 'react';

import { SliderProps } from '@material-ui/core';

import Text from 'components/Text';

import { StyledSlider, SliderContainer } from './styles';
import { Row } from 'theme/globalStyledComponents';

import { getLeverageFromSliderPercentage } from '.';

type Props = SliderProps & {
  isDisabled: boolean;
  leverage: number | null;
};
const RiskObservantSlider = ({
  isDisabled,
  leverage,
  ...sliderProps
}: Props) => {
  const [tempLeverage, setTempLeverage] = useState<number>(leverage ?? 0);

  return (
    <SliderContainer>
      <StyledSlider
        onChange={(e, v) =>
          setTempLeverage(getLeverageFromSliderPercentage(v as number))
        }
        disabled={isDisabled}
        {...sliderProps}
      />
      <Row>
        <Text variant="BODY_S" className="value" gap={5}>
          <Text>{tempLeverage}</Text>x
        </Text>
      </Row>
    </SliderContainer>
  );
};
export default RiskObservantSlider;
