import { memo } from 'react';

import { FormattedNumber } from 'components';
import Loading from 'components/Loading';
import Text from 'components/Text';

import LeverageSelector from './LeverageSelector';
import { DecimalScale } from 'constants/marketMappings';
import { Row } from 'theme/globalStyledComponents/row';

import { TradeSide } from 'enums';
import styled, { css } from 'styled-components';

type TypeOptions = 'selector' | 'clickable';

type ContainerProps = {
  type: TypeOptions;
} & Pick<Props, 'disabled' | 'longDisabled' | 'shortDisabled'>;
const Container = styled(Row)<ContainerProps>`
  position: relative;
  gap: 3px;
  justify-content: center;

  .absolute-bottom {
    position: absolute;
    bottom: 5px;
  }

  .selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    gap: 2px;
    color: ${({ theme }) => theme.colors.shadesForeground200};
    padding: 8px 10px;
    min-height: 37px;
    box-sizing: border-box;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.shadesBackground700};
    transition: all 0.5 ease-in-out;

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5;
        cursor: not-allowed;
      `}

    ${({ disabled }) =>
      !disabled &&
      css`
        :hover {
          .loading-icon {
            color: ${({ theme }) => theme.colors.white};
          }

          color: ${({ theme }) => theme.colors.white};
        }
      `}
    
    &.long {
      ${({ longDisabled }) =>
        longDisabled &&
        css`
          opacity: 0.5;
          cursor: not-allowed;
        `}

      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      ${({ type }) =>
        type === 'clickable' &&
        css`
          color: ${({ theme }) => theme.colors.positiveForeground200};
          background: ${({ theme }) => theme.colors.positiveBackground100};

          .loading-icon {
            color: ${({ theme }) => theme.colors.positiveForeground200};
          }

          ${({ disabled, longDisabled }) =>
            !disabled &&
            !longDisabled &&
            css`
              :hover {
                background: ${({ theme }) =>
                  theme.colors.positiveForeground300};
                color: ${({ theme }) => theme.colors.white};

                .loading-icon {
                  color: ${({ theme }) => theme.colors.white};
                }
              }
            `}
        `}

      &.active {
        color: ${({ theme }) => theme.colors.white};
        .loading-icon {
          color: ${({ theme }) => theme.colors.white};
        }
        background: ${({ theme }) => theme.colors.positiveForeground300};
      }
    }

    &.short {
      ${({ shortDisabled }) =>
        shortDisabled &&
        css`
          opacity: 0.5;
          cursor: not-allowed;
        `}

      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      ${({ type }) =>
        type === 'clickable' &&
        css`
          background: ${({ theme }) => theme.colors.negativeBackground100};
          color: ${({ theme }) => theme.colors.negativeForeground200};
          .loading-icon {
            color: ${({ theme }) => theme.colors.negativeForeground200};
          }

          ${({ disabled, shortDisabled }) =>
            !disabled &&
            !shortDisabled &&
            css`
              :hover {
                background: ${({ theme }) =>
                  theme.colors.negativeForeground300};
                color: ${({ theme }) => theme.colors.white};

                .loading-icon {
                  color: ${({ theme }) => theme.colors.white};
                }
              }
            `}
        `}

      &.active {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.negativeForeground300};

        .loading-icon {
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`;

type Props = {
  tradeSide?: TradeSide;
  onClickTradeSide: (side: TradeSide) => void;
  type?: TypeOptions;
  isLongLoading?: boolean;
  isShortLoading?: boolean;
  longPrice: number | null | undefined;
  shortPrice: number | null | undefined;
  className?: string;
  disabled?: boolean;
  longDisabled?: boolean;
  shortDisabled?: boolean;
  longErrorMessage?: string | null;
  shortErrorMessage?: string | null;
  decimalScale?: DecimalScale;
};

const LongShortSelectorWithLeverage = ({
  onClickTradeSide,
  tradeSide,
  type = 'selector',
  isLongLoading,
  isShortLoading,
  className,
  longPrice,
  shortPrice,
  disabled = false,
  longDisabled = false,
  shortDisabled = false,
  longErrorMessage,
  shortErrorMessage,
  decimalScale,
}: Props) => {
  const enableAction = !isLongLoading && !isShortLoading && !disabled;

  return (
    <Container
      type={type}
      className={className}
      disabled={disabled}
      longDisabled={longDisabled}
      shortDisabled={shortDisabled}
    >
      <div
        className={`selector long ${
          tradeSide === TradeSide.LONG ? 'active' : ''
        }`}
        onClick={() =>
          enableAction && !longDisabled && onClickTradeSide(TradeSide.LONG)
        }
      >
        <Text flexed variant="BODY_S" avoidTransition gap={5}>
          LONG
          {isLongLoading ? <Loading size="10px" /> : null}
        </Text>
        {longPrice ? (
          longErrorMessage ? (
            <Text variant="BODY_S" className="flex">
              {longErrorMessage}
            </Text>
          ) : (
            <FormattedNumber
              variant="BODY_S"
              className="flex"
              value={longPrice}
              disableTooltip
              decimalScale={decimalScale?.price}
            />
          )
        ) : null}{' '}
      </div>

      <div
        className={`selector short ${
          tradeSide === TradeSide.SHORT ? 'active' : ''
        }`}
        onClick={() =>
          enableAction && !shortDisabled && onClickTradeSide(TradeSide.SHORT)
        }
      >
        <Text
          variant="BODY_S"
          flexed
          className="justify-end"
          avoidTransition
          gap={5}
        >
          {isShortLoading ? <Loading size="10px" /> : null} SHORT
        </Text>

        {shortPrice ? (
          shortErrorMessage ? (
            <Text variant="BODY_S" className="flex justify-end">
              {shortErrorMessage}
            </Text>
          ) : (
            <FormattedNumber
              variant="BODY_S"
              className="flex justify-end"
              value={shortPrice}
              disableTooltip
              decimalScale={decimalScale?.price}
            />
          )
        ) : null}
      </div>

      <LeverageSelector />
    </Container>
  );
};

export default LongShortSelectorWithLeverage;
