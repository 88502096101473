import styled from 'styled-components';

interface NotificationStyledProps {
  titleColor: string;
  backgroundColor: string;
}
export const NotificationStyled = styled.div<NotificationStyledProps>`
  display: flex;
  align-content: center;
  padding: 20px;
  background: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0px 0px 40px rgba(23, 23, 27, 0.6);
  .content {
    margin: 0 8px;
    display: flex;
    justify-content: center;
    h3 {
      margin: 0;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: 14px;
      color: ${({ titleColor }) => titleColor};
    }
    p {
      margin: 5px 0 0;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: 12px;
      line-height: 15px;
      color: ${({ theme }) => theme.colors.white};
    }
    flex-direction: column;
    flex: 1;
  }

  > img.notification-icon {
    width: 24px;
  }
  > img {
    display: flex;
    align-self: center;
  }
`;
