import {
  notifications,
  parseInitialResponseData,
  parsePublicationData,
  showNotificationIfAllChannelsSubscribed,
} from 'utils';
import { deserializeArray } from 'utils/apiMiddleware';

import AppStore from './app';
import { API_MAPPINGS } from 'constants/apiMappings';
import { MAX_RECENT_TRADES_NUM } from 'constants/general';
import CentrifugeService from 'service/centrifugeService';

import { State, SubscribedContext, UnsubscribedContext } from 'centrifuge';
import { WebsocketChannels } from 'enums';
import { CentrifugeSubscribingChannelData, Trade } from 'interfaces';
import { action, makeAutoObservable } from 'mobx';

const getChannelName = (marketID: string) =>
  `${WebsocketChannels.Trades}:${marketID}`;

export default class TradesStore {
  private centrifugeService = CentrifugeService.getPublic();

  selectedMarketID: string = '';
  trades: Trade[] | null = null;
  isLoading: boolean = true;

  constructor(private store: AppStore) {
    makeAutoObservable(this, {
      subscribed: action,
      publication: action,
      setIsLoading: action,
      setTrades: action,
    });
  }

  subscribe = (marketID: string, centrifugeService: CentrifugeService) => {
    if (marketID === this.selectedMarketID) return;
    this.centrifugeService = centrifugeService;

    this.trades = null;
    this.setIsLoading(true);

    if (this.selectedMarketID !== '') {
      this.centrifugeService.removeSubscription(
        getChannelName(this.selectedMarketID),
      );
    }

    this.centrifugeService.addSubscription(getChannelName(marketID), {
      subscribing: (data: CentrifugeSubscribingChannelData) => {
        this.subscribing(data);
      },
      unsubscribed: (data: UnsubscribedContext) => {
        this.unsubscribed(data);
      },
      subscribed: (data: SubscribedContext) => {
        const deserializedTrades = deserializeArray<Trade>(
          data.data,
          API_MAPPINGS.RECENT_TRADE,
        );
        this.subscribed(deserializedTrades, marketID, data.wasRecovering);
      },
    });
  };

  unsubscribed = (data: UnsubscribedContext) => {
    this.setIsLoading(true);

    // If we manually called "unsubscribe", don't show notification
    if (data.code === 0) return;

    const centrifugeClientState = this.centrifugeService.centrifuge.state;

    // Don't show if the client is not connected, as this means that all channels were unsubscribed
    // and in this case we show a general error that all public data connection was lost
    if (centrifugeClientState === State.Connected) {
      notifications.couldNotReEstablishConnectionToData('recent trades');
    }
  };

  subscribing = (data: CentrifugeSubscribingChannelData) => {
    // code === 0 on the initial subscribing event
    if (data.code === 0) return;

    this.setIsLoading(true);
    const centrifugeClientState = this.centrifugeService.centrifuge.state;

    // Don't show if the client is not connected, as this means that all channels were unsubscribed
    // and in this case we show a general error that all public data connection was lost
    if (centrifugeClientState === State.Connected) {
      notifications.dataConnectionLost('recent trades');
    }
  };

  subscribed = (data: Trade[], marketID: string, wasRecovering = false) => {
    if (wasRecovering) {
      showNotificationIfAllChannelsSubscribed(this.centrifugeService);
    }

    this.trades = null;
    this.setIsLoading(true);
    this.selectedMarketID = marketID;
    const tradesChannelName = getChannelName(this.selectedMarketID);
    data &&
      this.setTrades(parseInitialResponseData(data, MAX_RECENT_TRADES_NUM));
    this.setIsLoading(false);

    this.centrifugeService
      .getSubscription(tradesChannelName)
      ?.on('publication', this.publication);
  };

  publication = ({ data }: { data: Trade[] }) => {
    const deserializedTrades = deserializeArray<Trade>(
      data,
      API_MAPPINGS.RECENT_TRADE,
    );
    const uniques = deserializedTrades.filter(
      trade => !this.trades?.find(item => item.id === trade.id),
    );
    this.setTrades(parsePublicationData(this.trades, uniques));
    this.setIsLoading(false);
  };

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setTrades = (trades: Trade[] | null) => {
    if (!trades) {
      this.trades = trades;
      return;
    }

    const sortedTrades = trades.sort((a, b) => b.timestamp - a.timestamp);
    this.trades = sortedTrades.slice(0, MAX_RECENT_TRADES_NUM);
  };
}
