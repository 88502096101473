import { useEffect, useState } from 'react';

import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { parseUnits } from '@ethersproject/units';

import { useWeb3React } from '@web3-react/core';

import {
  bigNumberToFloat,
  roundJsDecimalToString,
  showNotification,
} from 'utils';
import { brand, isBrandRabbitX } from 'utils/brand';
import { getContract } from 'utils/contracts';
import { handleNotificationsOnQueueUpdate } from 'utils/vaultActivity';

import { useVerifyChainId } from 'hooks';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { RbxRewards } from 'hooks/useElixirVaults';

import { USDT_DECIMALS } from 'constants/contracts';
import { NetworkContextName } from 'constants/networkContextName';
import {
  ElixirContractsAPIContext,
  SpotState,
} from 'contexts/ElixirContractsApiContext';
import {
  ElixirDistributor,
  ElixirDistributor__factory,
  RabbitManager,
  RabbitManager__factory,
  USDT,
  USDT__factory,
} from 'contracts/types';
import { SpotStateType } from 'enums/spotState';

import { config } from 'config';
import { NotificationType } from 'enums';
import { BigNumber, BigNumberish, constants, ethers } from 'ethers';
import { useTranslation } from 'react-i18next';

/* 
 1 or above  : In Queue
 0 : Is currently being executed
 -1 or less: was execute 
*/
export const getPositionInQueue = (spotId: number, queuedUpTo: number) =>
  spotId - queuedUpTo - 1;

export const getSpotStateType = (positionInQueue: number) => {
  if (positionInQueue < 0) return SpotStateType.Executed;
  if (positionInQueue === 0) return SpotStateType.Executing;
  return SpotStateType.Queued;
};

export enum PoolType {
  Inactive,
  Perp,
}

export type ElixirPoolInfoResponse = {
  activeAmount: BigNumber;
  hardcap: BigNumber;
  poolType: PoolType;
  router: string;
};

const GAS_FEE_BUFFER = config.isProd ? 120 : 150;

export const ElixirContractsProvider = ({ children }) => {
  const { t } = useTranslation();
  const { library: libraryNetwork } =
    useWeb3React<Web3Provider>(NetworkContextName);
  const { account, library } = useActiveWeb3React();
  const { isCorrectChainId } = useVerifyChainId();

  const [RabbitManagerSignContract, setRabbitManagerSignContract] = useState<
    RabbitManager | undefined
  >(undefined);
  const [RabbitManagerReadContract, setRabbitMangerReadContract] = useState<
    RabbitManager | undefined
  >(undefined);

  const [ElixirDistributorReadContract, setElixirDistributorReadContract] =
    useState<ElixirDistributor | undefined>(undefined);
  const [ElixirDistributorSignContract, setElixirDistributorSignContract] =
    useState<ElixirDistributor | undefined>(undefined);

  const [USDTSignContract, setUSDTSignContract] = useState<USDT | undefined>(
    undefined,
  );
  const [USDTReadContract, setUSDTReadContract] = useState<USDT | undefined>(
    undefined,
  );
  const [USDTDecimals, setUSDTDecimals] = useState<number>(0);
  const [areReadContractsSet, setAreReadContractsSet] =
    useState<boolean>(false);

  const [areSignContractsSet, setAreSignContractsSet] =
    useState<boolean>(false);

  // Set read contracts
  useEffect(() => {
    (async () => {
      try {
        if (!libraryNetwork) {
          return;
        }
        setAreReadContractsSet(false);
        // RabbitManager contract
        const RabbitManagerReadContract = getContract<RabbitManager>(
          RabbitManager__factory,
          config.elixirVaults.rabbitManagerContractAddress,
          libraryNetwork,
        );
        setRabbitMangerReadContract(RabbitManagerReadContract);

        // ElixirDistributor contract
        const ElixirDistributorReadContract = getContract<ElixirDistributor>(
          ElixirDistributor__factory,
          config.elixirVaults.elixirDistributorContractAddress,
          libraryNetwork,
        );
        setElixirDistributorReadContract(ElixirDistributorReadContract);

        // USDT contract
        const USDTReadContract = getContract<USDT>(
          USDT__factory,
          config.USDTAddress,
          libraryNetwork,
        );

        const USDTDecimals = USDT_DECIMALS;
        setUSDTDecimals(USDTDecimals);
        setUSDTReadContract(USDTReadContract);
        setAreReadContractsSet(true);
      } catch (e) {
        console.error(e);
        setAreReadContractsSet(false);
      }
    })();
  }, [libraryNetwork]);

  // Attach a queuedUpto Listener if
  useEffect(() => {
    if (!libraryNetwork || !areReadContractsSet) {
      return;
    }
  }, [areReadContractsSet, libraryNetwork]);

  // Set sign contracts
  useEffect(() => {
    (async () => {
      try {
        if (!account || !library) return;
        setAreSignContractsSet(false);
        // RabbitManager contract
        const RabbitManagerSignContract = getContract<RabbitManager>(
          RabbitManager__factory,
          config.elixirVaults.rabbitManagerContractAddress,
          library,
          account,
        );

        // ElixirDistributor contract
        const ElixirDistributorSignContract = getContract<ElixirDistributor>(
          ElixirDistributor__factory,
          config.elixirVaults.elixirDistributorContractAddress,
          library,
          account,
        );
        setElixirDistributorSignContract(ElixirDistributorSignContract);

        const USDTSignContract = getContract<USDT>(
          USDT__factory,
          config.USDTAddress,
          library,
          account,
        );
        setUSDTSignContract(USDTSignContract);
        setRabbitManagerSignContract(RabbitManagerSignContract);
        setAreSignContractsSet(true);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [account, library]);

  // Attaches listener for passed spotIds or Transaction hashes, and keeps tracking until all passed are executed
  const trackVaultActivities = (props?: {
    spotIds?: number[];
    txHashes?: string[];
  }) => {
    if (!RabbitManagerReadContract || !account || !isBrandRabbitX)
      return () => {};

    let queueEventListener: () => void;

    const stopListener = () => {
      RabbitManagerReadContract.off('Withdraw', queueEventListener);
      RabbitManagerReadContract.off('Deposit', queueEventListener);
    };

    queueEventListener = async () => {
      // get current queuedUpto
      const queuedUpto = bigNumberToFloat(
        await RabbitManagerReadContract.queueUpTo(),
        0,
      );

      handleNotificationsOnQueueUpdate(
        account,
        props?.spotIds,
        props?.txHashes,
        queuedUpto,
        stopListener,
        'update',
        getSpotState,
        t,
      );
    };

    // Call once to show current progress, then its handle on every Queued event
    RabbitManagerReadContract.queueUpTo().then(_queuedUpto => {
      const queuedUpto = bigNumberToFloat(_queuedUpto, 0);
      handleNotificationsOnQueueUpdate(
        account,
        props?.spotIds,
        props?.txHashes,
        queuedUpto,
        stopListener,
        'show',
        getSpotState,
        t,
      );
    });

    RabbitManagerReadContract.on('Withdraw', queueEventListener);
    RabbitManagerReadContract.on('Deposit', queueEventListener);

    return stopListener;
  };

  const getUsdtAllowanceForRabbitManager = async (): Promise<BigNumber> => {
    try {
      if (!USDTReadContract || !account) return constants.Zero;

      const allowance: BigNumber = (await USDTReadContract.allowance(
        account,
        config.elixirVaults.rabbitManagerContractAddress,
      )) as BigNumber;
      return allowance;
    } catch (e: any) {
      console.error(e);
    }
    return constants.Zero;
  };

  const getVaultRouterUsdtBalance = async (routerAddress: string) => {
    try {
      if (!USDTReadContract || !routerAddress) return constants.Zero;

      return (await USDTReadContract.balanceOf(routerAddress)) as BigNumber;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };

  const getWalletSharesForVault = async (
    poolId: number,
  ): Promise<BigNumber> => {
    try {
      if (!RabbitManagerReadContract || !account) return constants.Zero;

      return await RabbitManagerReadContract.getUserActiveAmount(
        poolId,
        account,
      );
    } catch (e: any) {
      console.error(e);
      throw new Error('Error getting wallet shares for vault');
    }
  };

  const getPendingWalletSharesForVault = async (
    poolId: number,
  ): Promise<BigNumber> => {
    try {
      if (!RabbitManagerReadContract || !account) return constants.Zero;

      return await RabbitManagerReadContract.getUserPendingAmount(
        poolId,
        account,
      );
    } catch (e: any) {
      console.error(e);
      throw new Error('Error getting pending wallet shares for vault');
    }
  };

  const getPoolInfo = async (
    poolId: number,
  ): Promise<ElixirPoolInfoResponse> => {
    try {
      if (!RabbitManagerReadContract) {
        throw new Error('RabbitManagerReadContract not set');
      }

      return await RabbitManagerReadContract.pools(poolId);
    } catch (e: any) {
      console.error(e);
      throw new Error(`error getting pool info for poolId: ${poolId}`);
    }
  };

  const getDepositFee = async (): Promise<BigNumber> => {
    try {
      if (!RabbitManagerReadContract || !libraryNetwork) {
        throw new Error('RabbitManagerReadContract not set');
      }

      const gasPrice = await libraryNetwork.getGasPrice();
      // Add 50% buffer to gas price to avoid failed transactions
      const gasPriceWithBuffer = gasPrice.mul(GAS_FEE_BUFFER).div(100);
      const elixirGas = await RabbitManagerReadContract.elixirGas();

      return elixirGas.mul(gasPriceWithBuffer);
    } catch (e) {
      console.error(e);
      throw new Error('Error getting deposit fee');
    }
  };

  const approve = async (): Promise<TransactionResponse> => {
    try {
      if (!isCorrectChainId) {
        throw new Error(
          `Incorrect chain ID when approving ${brand.tokenTicker}`,
        );
      }
      if (!USDTSignContract) {
        throw new Error(`${brand.tokenTicker}SignContract is not defined`);
      }

      const tx = (await USDTSignContract.approve(
        config.elixirVaults.rabbitManagerContractAddress,
        constants.MaxUint256 as BigNumberish,
      )) as TransactionResponse;

      return tx;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };

  const isUsdtApproved = async (amount?: number): Promise<boolean> => {
    try {
      if (!account) {
        throw new Error(
          `Account is not defined when checking ${brand.tokenTicker} allowance`,
        );
      }
      if (!USDTReadContract) {
        throw new Error(
          `${brand.tokenTicker} contract is not defined when checking ${brand.tokenTicker} allowance`,
        );
      }
      const allowance: BigNumber = (await USDTReadContract.allowance(
        account,
        config.elixirVaults.rabbitManagerContractAddress,
      )) as BigNumber;
      // Check for custom amount allowance
      if (amount) {
        return allowance.gte(
          parseUnits(
            roundJsDecimalToString(amount, USDTDecimals),
            USDTDecimals,
          ),
        );
      }
      // Otherwise check for max allowance
      return constants.MaxUint256.div(2).lt(allowance);
    } catch (e: any) {
      console.error(e);
    } finally {
    }
    return false;
  };

  const deposit = async (poolId: number, amount: BigNumber, wallet: string) => {
    try {
      if (!isCorrectChainId) {
        throw new Error(
          `Incorrect chain ID when depositing ${brand.tokenTicker}`,
        );
      }
      if (!RabbitManagerSignContract) {
        throw new Error('RabbitManagerSignContract contract is not defined');
      }

      const depositFee = await getDepositFee();

      return await RabbitManagerSignContract.deposit(poolId, amount, wallet, {
        value: depositFee,
      });
    } catch (e: any) {
      console.error(e);
      showNotification({
        title: 'An error occurred while staking USDT',
        description: e?.data?.message,
        type: NotificationType.Negative,
      });
      throw e;
    }
  };

  const withdraw = async (poolId: number, amount: BigNumber) => {
    try {
      if (!isCorrectChainId) {
        throw new Error('Incorrect chain ID when depositing USDT');
      }
      if (!RabbitManagerSignContract) {
        throw new Error('RabbitManagerSignContract contract is not defined');
      }

      const depositFee = await getDepositFee();

      return await RabbitManagerSignContract.withdraw(poolId, amount, {
        value: depositFee,
      });
    } catch (e: any) {
      console.error(e);
      showNotification({
        title: 'An error occurred while unstaking USDT',
        description: e?.data?.message,
        type: NotificationType.Negative,
      });
      throw e;
    }
  };

  const getSpotStateFromId = async (spotId: number) => {
    try {
      if (!libraryNetwork) {
        throw new Error('Library network not set');
      }

      if (!RabbitManagerReadContract) {
        throw new Error('RabbitManagerReadContract not set');
      }

      const queuedUpto = bigNumberToFloat(
        await RabbitManagerReadContract.queueUpTo(),
        0,
      );

      const spot = await RabbitManagerReadContract.queue(spotId);
      const positionInQueue = getPositionInQueue(spotId, queuedUpto);

      return {
        id: spotId,
        type: spot.spotType,
        positionInQueue: positionInQueue,
        spotStateType: getSpotStateType(positionInQueue),
      } as SpotState;
    } catch (e: any) {
      console.log('getSpotState:error ', e);
      throw new Error('Error reading transaction!');
    }
  };

  const getSpotState = async (txHash: string) => {
    try {
      if (!libraryNetwork) {
        throw new Error('Library network not set');
      }

      if (!RabbitManagerReadContract) {
        throw new Error('RabbitManagerReadContract not set');
      }
      const receipt = await libraryNetwork.getTransactionReceipt(txHash);

      if (!receipt) {
        throw new Error('Transaction not found');
      }

      const eventFragment =
        RabbitManagerReadContract.interface.getEvent('Queued');

      const log = receipt.logs[0];
      const decodedLog = ethers.utils.defaultAbiCoder.decode(
        eventFragment.inputs,
        log.data,
      );

      const queuedUpto = bigNumberToFloat(decodedLog[2], 0); // Based on Queued event, the queuedUpto is third param,
      const queueCount = bigNumberToFloat(decodedLog[1], 0); // Based on Queued event, the queueCount is second param,

      const spotId = queueCount - 1; // When the event is emitted, its so post queueCount update

      const spot = await RabbitManagerReadContract.queue(spotId);

      const positionInQueue = getPositionInQueue(spotId, queuedUpto);

      return {
        id: spotId,
        type: spot.spotType,
        positionInQueue,
        spotStateType: getSpotStateType(positionInQueue),
      } as SpotState;
    } catch (e: any) {
      console.log('getSpotState:error ', e);
      throw new Error('Error reading transaction!');
    }
  };

  const handleSpotUpdateOnQueueMove = (
    spotId: number,
    onUpdate: (s: SpotState) => void,
  ) => {
    if (!libraryNetwork) {
      throw new Error('Library network not set');
    }

    if (!RabbitManagerReadContract) {
      throw new Error('RabbitManagerReadContract not set');
    }

    const onQueuedEvent = async () => {
      const spotState = await getSpotStateFromId(spotId);

      onUpdate(spotState);
    };

    onQueuedEvent(); // call once to update as we don't when the next Queued event will be emitted

    RabbitManagerReadContract.on('Deposit', onQueuedEvent);
    RabbitManagerReadContract.on('Withdraw', onQueuedEvent);

    return () => {
      RabbitManagerReadContract.off('Deposit', onQueuedEvent);
      RabbitManagerReadContract.on('Withdraw', onQueuedEvent);
    };
  };

  const claim = async (poolId: number) => {
    try {
      if (!account) {
        throw new Error(
          `Account is not defined when claiming ${brand.tokenTicker}`,
        );
      }
      if (!isCorrectChainId) {
        throw new Error(
          `Incorrect chain ID when claiming ${brand.tokenTicker} from Elixir vault`,
        );
      }
      if (!RabbitManagerSignContract) {
        throw new Error('RabbitManagerSignContract contract is not defined');
      }

      const tx = await RabbitManagerSignContract.claim(account, poolId);
      return tx;
    } catch (e: any) {
      throw e;
    }
  };

  const getUserShares = async (wallet: string, poolId: number) => {
    try {
      if (!isCorrectChainId) {
        throw new Error(
          `Incorrect chain ID when claiming ${brand.tokenTicker} from Elixir vault`,
        );
      }
      if (!RabbitManagerSignContract) {
        throw new Error('RabbitManagerSignContract contract is not defined');
      }

      return await RabbitManagerSignContract.getUserActiveAmount(
        poolId,
        wallet,
      );
    } catch (e: any) {
      throw e;
    }
  };

  const getUserClaimedRbxRewards = async () => {
    if (!ElixirDistributorReadContract) {
      throw new Error('ElixirDistributorReadContract is not defined');
    }
    if (!account) {
      throw new Error('Account is not defined');
    }

    return await ElixirDistributorReadContract.claimed(
      account,
      config.RBTAddress,
    );
  };

  const claimRbxRewards = async (rbxRewardsForUser: RbxRewards) => {
    if (!ElixirDistributorSignContract) {
      throw new Error('ElixirDistributorSignContract is not defined');
    }
    if (!account) {
      throw new Error('Account is not defined');
    }

    return await ElixirDistributorSignContract.claim(
      account,
      config.RBTAddress,
      rbxRewardsForUser.amount,
      rbxRewardsForUser.signature,
    );
  };

  const value = {
    areReadContractsSet,
    getVaultRouterUsdtBalance,
    getWalletSharesForVault,
    getPendingWalletSharesForVault,
    getUsdtAllowanceForRabbitManager,
    getPoolInfo,
    deposit,
    withdraw,
    claim,
    approve,
    isUsdtApproved,
    getUserShares,
    getSpotState,
    getSpotStateFromId,
    claimRbxRewards,
    getUserClaimedRbxRewards,
    handleSpotUpdateOnQueueMove,
    trackVaultActivities,
  };

  return (
    <ElixirContractsAPIContext.Provider value={value}>
      {children}
    </ElixirContractsAPIContext.Provider>
  );
};
