export const Modals = {
  slTpAmendModal: 'slTpAmendModal',
  slTpCreateModal: 'slTpCreateModal',
  slTpCancelModal: 'slTpCancelModal',
  socialWalletModal: 'socialWalletModal',
  walletModal: 'walletModal',
  blurredWalletModal: 'blurredWalletModal',
  confirmOrderCancelModal: 'confirmOrderCancelModal',
  amendOrderModal: 'amendOrderModal',
  confirmAllOrdersCancelModal: 'confirmAllOrdersCancelModal',
  closePositionModal: 'closePositionModal',
  depositModal: 'depositModal',
  withdrawModal: 'withdrawModal',
  syncWithDesktopModal: 'syncWithDesktopModal',
  apiKeysModal: 'apiKeysModal',
  confirmBuySellModal: 'confirmBuySellModal',
  buySellModal: 'buySellModal',
  sharePnlModal: 'sharePnlModal',
  newReferralLinkModal: 'newReferralLinkModal',
  inviteFriendsModal: 'inviteFriendsModal',
  slippageSettingsModal: 'slippageSettingsModal',
  editWalletNameModal: 'editWalletNameModal',
  confirmationModal: 'confirmationModal',
  amendLiquidityModal: 'amendLiquidityModal',
  amendPlatformVaultLiquidityModal: 'amendPlatformVaultLiquidityModal',
  airdropRegistrationAndOnboardingModal:
    'airdropRegistrationAndOnboardingModal',
  bfxDepositBonusModal: 'bfxDepositBonusModal',
  onboardingModal: 'onboardingModal',
  lockTokenModal: 'lockTokenModal',
  editReferralLinkModal: 'editReferralLinkModal',
  bonusWinModal: 'bonusWinModal',
  whaleSupportModal: 'whaleSupportModal',
  unstakeWarningModal: 'unstakeWarningModal',
} as const;
