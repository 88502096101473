import { memo } from 'react';

import { FormattedNumber } from 'components';
import Text from 'components/Text';

import { MAX_TIER_LEVEL, TIER_TUPLES } from '../../tierConfig';
import { Column } from 'theme/globalStyledComponents';

import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  width: 100%;
  border-radius: 8px;
  grid-gap: 5px;
  grid-template-columns: repeat(2, 1fr);

  background: ${({ theme }) => theme.colors.shadesBackground800};
  box-sizing: border-box;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .item {
    padding: 12px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
    border-radius: 8px;
    grid-column: span 1;
    height: 34px;
  }
`;

const getActiveBoost = (
  levelTier: number,
  totalBoost: number,
  cappedNextLevelBoost: number,
  currentLevelBoost: number,
) => {
  if (levelTier === MAX_TIER_LEVEL) return `+${totalBoost?.toFixed(0) ?? '-'}%`;

  return `+${totalBoost.toFixed(0) ?? '-'}% (Next Level +${(
    totalBoost +
    cappedNextLevelBoost -
    currentLevelBoost
  ).toFixed(0)}%)`;
};

type Props = {
  usersReferred?: number;
  tradingVolume?: number;
  openPositionsValue?: number;
  referredVolume?: number;
  levelTier?: number;
  vaultDeposits?: number;
  totalBoost?: number;
};
const UserStats = ({
  usersReferred,
  tradingVolume,
  openPositionsValue,
  referredVolume,
  levelTier = 1,
  vaultDeposits,
  totalBoost,
}: Props) => {
  const cappedNextLevel = Math.min(levelTier + 1, MAX_TIER_LEVEL); // Upcoming tier Level
  const currentLevelBoost = TIER_TUPLES[levelTier - 1][1] * 100;
  const cappedNextLevelBoost = TIER_TUPLES[cappedNextLevel - 1][1] * 100;

  return (
    <Container>
      <StatItem
        label="Active Boost"
        value={getActiveBoost(
          levelTier,
          totalBoost ?? 0,
          cappedNextLevelBoost,
          currentLevelBoost,
        )}
      />
      <StatItem label="Vault Deposits" value={vaultDeposits} />
      <StatItem label="Trading Volume" prefix="$" value={tradingVolume} />
      <StatItem label="Open Positions" prefix="$" value={openPositionsValue} />
      <StatItem label="Users Referred" value={usersReferred} decimals={0} />
      <StatItem label="Referred Volume" prefix="$" value={referredVolume} />
    </Container>
  );
};

type StatItemProps = {
  label: string;
  value: number | undefined | string;
  prefix?: string;
  decimals?: number;
};
const StatItem = ({ prefix, value, label, decimals }: StatItemProps) => {
  return (
    <Column gap={5} className="item">
      <Text color="shadesForeground200" variant="BODY_S">
        {label}
      </Text>
      {typeof value === 'string' ? (
        <Text variant="BODY_S" fontWeight="semiBold">
          {value}
        </Text>
      ) : (
        <FormattedNumber
          variant="BODY_S"
          fontWeight="semiBold"
          value={value}
          prefix={prefix}
          decimalScale={decimals}
        />
      )}
    </Column>
  );
};

export default memo(UserStats);
