import { memo } from 'react';

import { Tooltip } from '@material-ui/core';

import { FormattedNumber } from 'components/FormattedNumber';

import { Container } from './styles';
import infoIcon from 'assets/icons/infoSquare.svg';

import { ProfileAirdrop } from 'interfaces';
import { useTranslation } from 'react-i18next';

const getPendingBonus = ({
  claimable,
  claimed,
  totalRewards,
}: {
  claimable: number;
  claimed: number;
  totalRewards: number;
}) => {
  return totalRewards - claimable - claimed;
};

type Props = {
  profileAirdrop: ProfileAirdrop;
};
const ProgressBox = ({
  profileAirdrop: {
    claimable,
    claimed,
    totalRewards,
    totalVolumeForAirdrop,
    totalVolumeAfterAirdrop,
  },
}: Props) => {
  const pendingBonus = getPendingBonus({ claimable, claimed, totalRewards });
  const { t } = useTranslation();

  return (
    <Container>
      {/* <div className="header">
        <span className="label">
          {t('progress')}
          <Tooltip title={t('progressTooltip')} placement="top">
            <img src={infoIcon} alt="Information" className="cursor-pointer" />
          </Tooltip>
        </span>
        <div className="progress-value">
          <FormattedNumber
            value={Math.min(totalVolumeAfterAirdrop, totalVolumeForAirdrop)}
            prefix="$"
            className="current-progress"
          />{' '}
          / <FormattedNumber value={totalVolumeForAirdrop} prefix="$" />
        </div>
      </div>
      <LinearProgressBar percent={Math.min(progress * 100, 100)} /> */}
      <div className="stats-columns">
        <div className="stats-column">
          <span className="label">{t('claimedRewards')}</span>
          <FormattedNumber value={claimed} suffix=" RBX" />
        </div>
        <div className="stats-column">
          <span className="label flex items-center gap-5">
            {t('pendingBonus')}
            <Tooltip title={t('airdrop2PendingBonusTooltip')} placement="top">
              <img
                src={infoIcon}
                alt="Information"
                className="cursor-pointer"
              />
            </Tooltip>
          </span>
          <FormattedNumber value={pendingBonus} suffix=" RBX" />
        </div>
        <div className="stats-column">
          <span className="label">{t('totalEligible')}</span>
          <FormattedNumber value={totalRewards} suffix=" RBX" />
        </div>
      </div>
    </Container>
  );
};

export default memo(ProgressBox);
