import Loading from 'components/Loading';

import styled from 'styled-components';

const TableLoaderStyled = styled.tr`
  .loader-wrapper {
    padding: 10px 0;
    display: flex;
    justify-content: center;
  }
`;

const TableLoader = () => {
  return (
    <TableLoaderStyled>
      <td colSpan={100}>
        <div className="loader-wrapper">
          <Loading />
        </div>
      </td>
    </TableLoaderStyled>
  );
};

export default TableLoader;
