import {
  LIMIT_EXCESSIVE_ARRAY_FOR_SCROLL,
  MAX_ORDERS_TO_SHOW,
} from '../constants/general';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';

import { Fill, Order, Position } from 'interfaces';

interface UpdatedArrays {
  positions?: Position[];
  orders?: Order[];
  fills?: Fill[];
}

/**
 * Merges old and updated state object into one. It uses Go's "omitempty JSON" method, which means that
 * the field should be updated if the value equals to anything other than "undefined". If the value is "undefined",
 * it should be ignored and not overwritten (the important part). It executes deep merge.
 * @param oldState Old state object
 * @param newOrPartiallyUpdatedState New complete state object or partially updated
 * @returns Merged state object using omitempty JSON method
 */
export const mergeState = <T>(
  oldState: T | null | undefined,
  newOrPartiallyUpdatedState: T,
) => {
  if (!oldState) {
    return limitExcessiveArrays(newOrPartiallyUpdatedState);
  }

  const updatedArrays: UpdatedArrays = {};

  Object.keys(newOrPartiallyUpdatedState as unknown as object).forEach(key => {
    if (Array.isArray(newOrPartiallyUpdatedState[key])) {
      updatedArrays[key] = Object.values(
        merge(
          keyBy(oldState[key], 'id'),
          keyBy(newOrPartiallyUpdatedState[key], 'id'),
        ),
      );
    }
  });

  const positions = updatedArrays.positions
    ? updatedArrays.positions.filter(el => el.size !== 0)
    : oldState['positions'];
  updatedArrays.positions = positions;

  let data = {
    ...oldState,
    ...newOrPartiallyUpdatedState,
    ...updatedArrays,
  };
  return limitExcessiveArrays(data);
};

// For now, as we currently don't have pagination, let's sort fills and orders in descending order
// and save only 40, otherwise we get very long arrays when the user doesn't reload the page for a long time
// and they have a lot of orders/fills (market makers, bots,...). This should be removed when pagination is implemented
const limitExcessiveArrays = <T>(data: T) => {
  if (
    data['fills'] &&
    data['fills'].length > LIMIT_EXCESSIVE_ARRAY_FOR_SCROLL
  ) {
    const fills = data['fills']
      .sort((a, b) => b.timestamp - a.timestamp)
      .slice(0, LIMIT_EXCESSIVE_ARRAY_FOR_SCROLL);
    data['fills'] = fills;
  }
  if (data['orders'] && data['orders'].length > MAX_ORDERS_TO_SHOW) {
    const orders = data['orders']
      .sort((a, b) => b.createdAt - a.createdAt)
      .slice(0, MAX_ORDERS_TO_SHOW);
    data['orders'] = orders;
  }
  return data;
};
