import { Column } from 'pages/Vaults/styles';

import styled, { css } from 'styled-components';

type Props = { isHovered?: boolean };
export const Container = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  overflow: hidden;
  border-radius: 4px;
  width: min-content;
  cursor: pointer;
  ${({ isHovered }) =>
    isHovered &&
    css`
      background: linear-gradient(
        -100deg,
        rgba(232, 200, 139, 1) 0%,
        rgba(140, 107, 46, 1) 50%
      );
    `}

  @keyframes rotateBorder {
    0% {
      left: -120px;
    }
    50% {
      left: 300px;
    }
    100% {
      left: -120px;
    }
  }

  > div:last-child {
    z-index: 2;
    border-radius: 4px;
    background: #141b29; /* Mobile Sidebar */
    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      background: ${({ theme }) => theme.colors.shadesBackground900};
    }

    :hover {
      background: ${({ theme }) => theme.colors.shadesBackground800};
    }
  }

  .animated-border {
    z-index: 1;
    position: absolute;
    height: 300%;
    width: 30px;
    transform: rotate(-45deg);

    ${({ isHovered }) =>
      isHovered &&
      css`
        animation: rotateBorder 6s ease-in-out infinite;
        background: #ffb03a;
      `}
  }

  .item {
    z-index: 2;
    padding: 6px;
    gap: 3px;
    width: 100%;
    border-radius: 4px;

    /* ${({ isHovered }) => isHovered && css``} */
  }
`;

export const StyledDropDownContent = styled(Column)`
  position: absolute;
  bottom: calc(100% + 10px);
  /* Mobile Sidebar */
  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    top: 100%;
    bottom: initial;
    right: 0;
    z-index: 6;
  }

  > div:first-child {
    height: 10px;
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
    background: ${({ theme }) => theme.colors.shadesBackground800};
    box-shadow: 2px 2px 40px 0px rgba(23, 23, 27, 0.8);
    padding: 5px;
    gap: 5px;
    z-index: 2;
  }

  .time-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;
  }

  .dashboard-button {
    display: flex;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};
    background: ${({ theme }) => theme.colors.shadesBackground700};
    font-size: 10px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    cursor: pointer;
    justify-content: center;

    :hover {
      background: ${({ theme }) => theme.colors.shadesBackground800};
    }
  }

  .warning-text-gradient {
    background: linear-gradient(90deg, #f4d490 0%, #d79940 126.12%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
