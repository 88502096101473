import { showNotification } from 'utils';

import { useAccount } from './useAccount';
import { useActiveWeb3React } from './useActiveWeb3React';

import { config } from 'config';
import { NotificationType } from 'enums';

export const useVerifyChainId = () => {
  const { switchNetwork, addNetwork: addCurrentNetwork } = useAccount();
  const { chainId } = useActiveWeb3React();
  const isCorrectChainId = chainId === config.chainID;
  /**
   * Asynchronously validates if the current network is correct, and switches to the correct one if it is not.
   * Also triggers appropriate notifications based on the result.
   * @returns A promise that resolves to `true` if the network is already correct, `false` otherwise.
   */
  const validateNetworkAndSwitchIfRequired = async (
    successMessage = '',
  ): Promise<boolean> => {
    if (isCorrectChainId) {
      return true;
    }

    showNotification({
      title: 'Switch to the correct network',
      description: `Please switch to ${config.network.networkName} network to continue`,
      type: NotificationType.Info,
    });

    const switchAndShowNotification = async () => {
      await switchNetwork(config.chainID);

      let notifDescription = 'Switched to the correct network.';
      if (successMessage) {
        notifDescription += ` ${successMessage}`;
      }

      showNotification({
        title: 'Switched to the correct network.',
        description: notifDescription,
        type: NotificationType.Positive,
      });
    };

    try {
      await switchAndShowNotification();
      return true;
    } catch (e: any) {
      // This error code indicates that the chain has not been added to MetaMask, hence add it
      if (e.code === 4902) {
        try {
          await addCurrentNetwork();
          await switchAndShowNotification();
          return true;
        } catch (err) {
          console.error(err);
        }
      }

      showNotification({
        title: 'Error switching network',
        description: `Please switch to ${config.network.networkName} network to continue`,
        type: NotificationType.Negative,
      });
      return false;
    }
  };

  /**
   * Asynchronously validates the network environment and performs a given action if the network is correct.
   * If the network is not correct, it displays a notification prompting the user to switch to the required network.
   * @param action - A callback function representing the action to be executed upon successful network validation.
   * @param successMessage - (Optional) A message to be used for successful network validation.
   *                         Defaults to an empty string if not provided.
   */
  const validateNetworkAndDoAction = async (
    action: () => any,
    successMessage = '',
  ): Promise<void> => {
    const isNetworkCorrect = await validateNetworkAndSwitchIfRequired(
      successMessage,
    );
    if (isNetworkCorrect) {
      await action();
    } else {
      showNotification({
        title: 'Error switching network',
        description: `Please switch to ${config.network.networkName} network to continue`,
        type: NotificationType.Negative,
      });
    }
  };

  return {
    isCorrectChainId,
    validateNetworkAndSwitchIfRequired,
    validateNetworkAndDoAction,
  };
};
