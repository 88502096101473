import { useEffect } from 'react';

import { readUserSetting, saveUserSetting } from 'utils';

import { usePlatformVaultsAPI } from 'hooks';
import { useBlastGameAssets } from 'hooks/useBlastGameAssets';
import { useBlastPointsAPI } from 'hooks/useBlastPointsAPI';
import useModal from 'hooks/useModal';
import { useReferralsAPI } from 'hooks/useReferralsAPI';

import SuperChargedBoost from 'components/CustomToasts/SuperChargedBoost';
import EditReferralLinkModal from 'components/EditReferralLinkModal';

import BfxStats from '../../BlastAidropDashboard/components/BfxStats';
import GreetingHeader from '../../BlastAidropDashboard/components/GreetingHeader';
import UserStats from '../../BlastAidropDashboard/components/UserStats';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { IGameAssetsBlast } from 'interfaces/gameAssets';
import { MAX_REFERRAL_LINK_CHANGES } from 'pages/Portfolio/Pages/ReferralDashboard/ProfileInfo';
import { getYourSharesUsd } from 'pages/Vaults/Pages/Vaults/TabPanels/PlatformVaults';
import { useFetchPlatformVaultsDataWithHoldings } from 'queryHooks/platformVaults';

import { config } from 'config';
import { QueryKeys, UserSettings } from 'enums';
import { observer } from 'mobx-react';
import { useUserRegisteredForBlastAirdrop } from 'queryHooks';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

const hoursToMs = (h: number) => h * 60 * 60 * 1000;

const BlastStatsAndGreetingHeader = ({
  userGameAssets,
}: {
  userGameAssets: IGameAssetsBlast | undefined;
}) => {
  const {
    store: {
      account: { frontendSecrets, _positions, accountStats },
    },
  } = useAppContext();
  const { fetchUserReferral } = useReferralsAPI();
  const {
    blastPoints: {
      gold: {
        finalizedPoints: finalizedGoldPoints,
        pendingPoints: pendingGoldPoints,
      },
      blast: {
        finalizedPoints: finalizedBlastPoints,
        pendingPoints: pendingBlastPoints,
      },
    },
  } = useBlastPointsAPI();

  const { fetchVaults } = usePlatformVaultsAPI();
  const { data: userRegistration } = useUserRegisteredForBlastAirdrop(
    frontendSecrets?.jwt,
  );

  const openPositionsValue = _positions?.reduce((acc, position) => {
    return acc + position.notional;
  }, 0);

  const { data: userReferral } = useQuery(
    [QueryKeys.UserReferral],
    async () => await fetchUserReferral(),
    {
      // 60 seconds
      refetchInterval: 60_000,
      enabled: !!frontendSecrets?.jwt,
    },
  );

  const { data: vault } = useQuery(
    [QueryKeys.PlatformVault],
    async () => {
      const vaults = await fetchVaults();
      return vaults[0];
    },
    {
      enabled: !!frontendSecrets?.jwt,
    },
  );

  const { data: vaultsDataWithHoldings } =
    useFetchPlatformVaultsDataWithHoldings();

  useEffect(() => {
    if (!userGameAssets?.totalBoost) return; // return in case of userGameAssets?.totalBoost === 0 as well

    const isOverTwentyFourHoursSinceLastShown =
      (readUserSetting(UserSettings.BLAST_SUPER_CHARGED_BOOST_LAST_POP_TIME) ??
        0) +
        hoursToMs(config.isProd ? 24 : 0.1) <
      Date.now();

    if (!isOverTwentyFourHoursSinceLastShown) return;

    saveUserSetting(
      UserSettings.BLAST_SUPER_CHARGED_BOOST_LAST_POP_TIME,
      Date.now(),
    );

    const toastId = toast(
      <SuperChargedBoost
        onClose={() => {
          toast.dismiss(toastId);
        }}
        levelBoost={userGameAssets?.totalBoost ?? 0}
      />,
      {
        position: 'bottom-left',
        autoClose: 5_000,
        closeButton: false,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: false,
        closeOnClick: false,
        theme: 'dark',
        style: { background: 'none', overflow: 'visible' },
      },
    );
  }, [userGameAssets?.totalBoost]);

  return (
    <>
      <GreetingHeader
        username={userRegistration?.twitterData?.username}
        profilePicture={userRegistration?.twitterData?.profilePicture}
        level={userGameAssets?.levelTier}
        tradingVolume={accountStats?.cumTradingVolume ?? 0}
      />

      <BfxStats
        goldPoints={finalizedGoldPoints}
        pendingGoldPoints={pendingGoldPoints}
        blastPoints={finalizedBlastPoints}
        pendingBlastPoints={pendingBlastPoints}
      />

      <UserStats
        usersReferred={userReferral?.invitedCounter}
        vaultDeposits={getYourSharesUsd(vaultsDataWithHoldings)}
        levelTier={userGameAssets?.levelTier}
        tradingVolume={accountStats?.cumTradingVolume}
        referredVolume={userReferral?.referralLevelStatus.volume}
        openPositionsValue={openPositionsValue}
        totalBoost={(userGameAssets?.totalBoost ?? 0) * 100}
      />
    </>
  );
};

export default observer(BlastStatsAndGreetingHeader);
