import styled from 'styled-components';

export const DepositContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 379px;
  justify-content: center;

  .loading-icon-container {
    display: flex;
    align-self: center;
  }

  > h6 {
    text-align: center;
    letter-spacing: -0.4px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    margin-bottom: 20px;
    padding: 0;
  }
`;

export const Badge = styled.span`
  display: block;
  padding: 2px 4px;
  align-items: center;
  border-radius: 4px;
  border: 0.7px solid ${({ theme }) => theme.colors.shadesBackground700};
  background: ${({ theme }) => theme.colors.shadesBackground800};
  color: ${({ theme }) => theme.colors.shadesForeground200} !important;

  font-size: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 14px;
`;

export const ApproveTooltip = styled.div`
  display: flex;
  justify-content: center;
  background: transparent;
  > span {
    margin-top: 10px;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.shadesForeground300};
  }
`;
