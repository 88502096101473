import { BigNumber, FixedNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

import Tooltip from '@material-ui/core/Tooltip';

import { abbreviateNumber } from 'utils';

import { TextVariant } from 'components/Text/variant';

import { DEFAULT_DECIMAL_SCALE } from 'constants/general';
import { TScreenSize } from 'theme/Theme';

import { Theme } from 'interfaces';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';

type Colors = Theme['colors'];
type FontWeights = Theme['fontWeights'];

type StyledNumberProps = {
  color?: keyof Colors;
  fontWeight?: keyof FontWeights;
  variant?: keyof typeof TextVariant;
  responsiveVariant?: {
    [key in TScreenSize]?: keyof typeof TextVariant;
  };
  preWrap?: boolean;
  noWrap?: boolean;
  styleAsTooltip?: boolean;
};
export const NumberFormatStyled = styled(NumberFormat)<StyledNumberProps>`
  font-family: ${({ theme }) => theme.fontFamilyNumbers};

  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${({ theme }) => theme.fontWeights[fontWeight]};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${({ theme }) => theme.colors[color]};
    `}

    ${({ variant }) =>
    variant &&
    css`
      font-size: ${TextVariant[variant].fontSize};
      line-height: ${TextVariant[variant].lineHeight};
      letter-spacing: ${TextVariant[variant]?.letterSpacing ?? 'initial'};
    `}

    ${({ responsiveVariant, theme }) =>
    responsiveVariant &&
    css`
      ${Object.keys(responsiveVariant).map(
        size =>
          `@media (min-width: ${theme.screenSizes[size]}px) {
      font-size: ${TextVariant[responsiveVariant[size]].fontSize};
      line-height: ${TextVariant[responsiveVariant[size]].lineHeight};
      letter-spacing: ${
        TextVariant[responsiveVariant[size]]?.letterSpacing ?? 'initial'
      };

        };`,
      )}
    `}

    ${({ styleAsTooltip }) =>
    styleAsTooltip &&
    css`
      cursor: help;
      text-decoration-style: dashed;
      text-decoration-thickness: 0px;
      text-decoration-style: dashed;
      text-underline-position: under;
      text-decoration-line: underline;
    `}

    white-space: ${({ preWrap, noWrap }) =>
    noWrap ? 'nowrap' : preWrap ? 'pre-wrap' : 'initial'};
`;

export interface FormattedNumberProps extends StyledNumberProps {
  noValue?: string;
  value?: BigNumber | number | string | null;
  valueFixed?: FixedNumber;
  units?: number;
  prefix?: string;
  suffix?: string;
  decimalScale?: number;
  isPercentage?: boolean;
  allowNegative?: boolean;
  disableTooltip?: boolean;
  dataTestID?: string;
  className?: string;
  abbreviated?: boolean;
  variant?: keyof typeof TextVariant;
  tooltipText?: string;
  withChangingColor?: boolean;
  negativeToZero?: boolean;
}

const getStrValue = (
  units: number,
  value?: BigNumber,
  valueFixed?: FixedNumber,
  isPercentage?: boolean,
) => {
  if (valueFixed) {
    const val = isPercentage
      ? valueFixed.mulUnsafe(FixedNumber.from(100))
      : valueFixed;
    return val.toString();
  }

  const val = isPercentage ? value?.mul(BigNumber.from(100)) : value;
  return formatUnits(val!, units);
};

// If a formatter is provided, suffix and prefix arent added so, that has to handled by the formatter
const abbreviateFormatter = (
  input: string,
  prefix: string | undefined,
  suffix: string | undefined,
) => {
  return `${prefix || ''}${abbreviateNumber(input)}${suffix || ''}`;
};

export const FormattedNumber = ({
  noValue = '-',
  value,
  valueFixed,
  units = 18,
  prefix,
  suffix,
  decimalScale = DEFAULT_DECIMAL_SCALE,
  isPercentage = false,
  allowNegative = true,
  disableTooltip = false,
  dataTestID,
  className = '',
  abbreviated = false,
  variant,
  tooltipText,
  withChangingColor = false,
  negativeToZero = false,
  ...restProps
}: FormattedNumberProps) => {
  let v;
  if (BigNumber.isBigNumber(value)) {
    v =
      !valueFixed && !value
        ? noValue
        : getStrValue(units, value, valueFixed, isPercentage);
  } else {
    v =
      value !== undefined && value !== null && !isNaN(value as number)
        ? value
        : noValue;
  }

  let customProps: {
    color?: keyof Colors;
  } = {};

  if (withChangingColor) {
    customProps['color'] =
      v >= 0 ? 'positiveForeground200' : 'negativeForeground200';
  }

  if (negativeToZero && v < 0) {
    v = 0;
  }

  return (
    <Tooltip
      placement="top"
      title={tooltipText || v}
      disableHoverListener={disableTooltip}
      disableFocusListener={disableTooltip}
      disableTouchListener={disableTooltip}
    >
      <NumberFormatStyled
        data-testid={dataTestID}
        value={v}
        displayType={'text'}
        thousandSeparator={true}
        format={
          abbreviated
            ? input => abbreviateFormatter(input, prefix, suffix)
            : undefined
        }
        prefix={prefix}
        suffix={suffix}
        decimalScale={decimalScale}
        fixedDecimalScale={true}
        allowNegative={allowNegative}
        className={`formatted-number ${className}`}
        variant={variant}
        // style={variant && TextVariant[variant]} // Map as per Text variants
        {...restProps}
        {...customProps}
      />
    </Tooltip>
  );
};

type PercentageRemainingKeys = Exclude<
  keyof FormattedNumberProps,
  'units' | 'isPercentage' | 'suffix'
>;
type PercentageProps = Pick<FormattedNumberProps, PercentageRemainingKeys>;

export const FormattedPercentage = ({ ...props }: PercentageProps) => (
  <FormattedNumber {...props} units={18} isPercentage={true} suffix={'%'} />
);

export const FormattedRatio = ({ ...props }: PercentageProps) => (
  <FormattedNumber {...props} units={18} isPercentage={false} suffix={'%'} />
);
