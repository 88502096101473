import { useEffect } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import { showNotification } from 'utils';
import { deepMergeArraysById } from 'utils/state';

import {
  PlatformVaultsDataWithHoldings,
  useBalanceOperationsAPI,
  useRabbitContractsAPI,
} from 'hooks';
import useModal from 'hooks/useModal';

import ConfirmationModal from 'components/ConfirmationModal';

import WithdrawalRow from './UnstakeRequestRow';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';

import { VisibilityProps } from '..';
import {
  BalanceOpsType,
  DepositOrWithdrawalStatus,
  NotificationType,
  QueryKeys,
} from 'enums';
import { ProfileBalanceOps } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components';

const Seperator = styled.div`
  height: 1.5px;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  display: flex;
  width: 100%;
`;

type Props = {
  vaultDataWithHoldings: PlatformVaultsDataWithHoldings[] | undefined;
  vaultAddress?: string;
} & VisibilityProps;

export const isUnstakeStatusCancellable = (status: DepositOrWithdrawalStatus) =>
  status === DepositOrWithdrawalStatus.Requested;

const UnstakeRequestRows = ({
  vaultDataWithHoldings = [],
  vaultAddress,
  hideManagerColumn,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { cancelUnstakeFromVaultRequest } = useRabbitContractsAPI();
  const { fetchPrivateSettlements } = useBalanceOperationsAPI();
  const {
    store: {
      account: { _balanceOperations },
    },
  } = useAppContext();
  const modal = useModal();

  const { data: unstakeRequests } = useQuery(
    QueryKeys.VaultUnstakeRequests,
    async () => {
      const res = await fetchPrivateSettlements({
        queryParams: {
          ops_type: BalanceOpsType.UnstakeShares,
          status: [DepositOrWithdrawalStatus.Requested],
        },
      });
      return res.data;
    },
    {
      refetchInterval: 60_000,
      staleTime: 45_000,
    },
  );

  // Check for new balance operation and add them to the existing list
  useEffect(() => {
    if (_balanceOperations) {
      const _unstakeRequests = _balanceOperations.filter(
        balanceOp =>
          balanceOp.opsType === BalanceOpsType.UnstakeShares &&
          isUnstakeStatusCancellable(balanceOp.status),
      );

      if (_unstakeRequests.length > 0) {
        queryClient.setQueryData<ProfileBalanceOps[] | undefined>(
          QueryKeys.VaultUnstakeRequests,
          balanceOps =>
            deepMergeArraysById(balanceOps || [], _unstakeRequests, true),
        );
      }
    }
  }, [_balanceOperations, queryClient]);

  const onCancel = async (opsId: string, shares: number) => {
    if (!opsId) return;

    const cancelRequest = async () => {
      try {
        await cancelUnstakeFromVaultRequest(opsId);

        showNotification({
          title: `${t('cancellingUnstakeRequestFor')} ${shares} ${t(
            'shares',
          )}! `,
          description: t('cancellingUnstakeRequestDescription', {
            amount: shares,
          }),
          type: NotificationType.Positive,
        });

        // Optimistic Update here
        queryClient.setQueryData<ProfileBalanceOps[] | undefined>(
          QueryKeys.VaultUnstakeRequests,
          balanceOps =>
            balanceOps?.map(balanceOp => {
              if (balanceOp.id === opsId) {
                return {
                  ...balanceOp,
                  status: DepositOrWithdrawalStatus.Canceled,
                };
              }
              return balanceOp;
            }),
        );

        showNotification({
          title: `🎉 ${t('sucessfullyCancelledRequest')}! `,
          description: t('sucessfullyCancelledUnstakeRequestDescription', {
            amount: shares,
          }),
          type: NotificationType.Positive,
        });
      } catch (err) {
        showNotification({
          title: t(`couldntCancelUnstakeRequest`), // Unable to cancel your unstaked request at the moment. Please try again later or check your network connection.
          description: t('couldntCancelUnstakeRequestDescription'),
          type: NotificationType.Negative,
        });
      }
    };

    modal.present(
      <ConfirmationModal
        title={t('cancelUnstakeRequestTitle', { amount: shares })}
        description={t('cancelUnstakeRequestDescription', { amount: shares })}
        onButtonRightPress={cancelRequest}
        buttonRightTitle={t('yes')}
        buttonLeftTitle={t('no')}
        buttonRightProps={{ colorVariant: 'primaryRed' }}
      />,
      Modals.confirmationModal,
    );
  };

  const filteredUnstakeRequests = vaultAddress
    ? unstakeRequests?.filter(
        unstakeRequest => unstakeRequest.wallet === vaultAddress,
      )
    : unstakeRequests;

  const withdrawalsDontExists =
    !filteredUnstakeRequests || filteredUnstakeRequests.length === 0;

  if (withdrawalsDontExists) return null;

  return (
    <>
      {filteredUnstakeRequests.map(balanceOps => {
        const vaultData = vaultDataWithHoldings.find(
          vault => vault.wallet === balanceOps.wallet,
        );

        return (
          <WithdrawalRow
            vaultData={vaultData}
            status={balanceOps.status}
            shares={parseFloat(balanceOps.amount)} // Check this, should be a already a number from API_MAPPING
            onCancel={onCancel}
            opsId={balanceOps.id}
            timestamp={balanceOps.timestamp}
            hideManagerColumn={hideManagerColumn}
          />
        );
      })}
      <TableRow className="seperator-row">
        <TableCell colSpan={100}>
          <Seperator />
        </TableCell>
      </TableRow>
    </>
  );
};

export default observer(UnstakeRequestRows);
