import { memo } from 'react';

import { FormattedNumber, Icon } from 'components';
import Badge from 'components/Badge';
import Button from 'components/Button/button';
import InfoTooltip from 'components/InfoTooltip';
import LinearProgressBar from 'components/LinearProgressBar';
import Text from 'components/Text';

import SpecialTierCard from './SpecialCard';
import { StyledTierCard } from './styles';
import lockIcon from 'assets/icons/lock-black.svg';
import { getTierNumber } from 'pages/Trade/components/AccountStats/VipTier';
import { routes } from 'pages/routes';
import { Column } from 'theme/globalStyledComponents/column';
import { Row } from 'theme/globalStyledComponents/row';

import { SingleTierData, Tier } from 'interfaces';
import { useNavigate } from 'react-router-dom';

const getTierPercent = (
  current: SingleTierData,
  next: SingleTierData,
  volume: number | undefined,
) => {
  if (!next?.minVolume) return 100;

  if (!volume) return 0;

  return (
    ((volume - current.minVolume) / (next.minVolume - current.minVolume)) * 100
  );
};

const TierCard = ({
  tierStatus,
  tradingVolume,
}: {
  tierStatus: Tier;
  tradingVolume: number | undefined;
}) => {
  const navigate = useNavigate();
  console.log('tierStatus', tierStatus);
  const { current, next: _next } = tierStatus;

  const next = _next ?? { tier: 0, minVolume: 0 };

  // if Special tier i.e. over 6, we should the tier 6 card
  const cappedTierLevel = Math.min(6, current.tier ?? 0);

  const Wrapper =
    current.tier >= 6 && current.tier !== 13 ? SpecialTierCard : StyledTierCard;

  return (
    <Wrapper tier={cappedTierLevel}>
      <Row className="justify-between">
        <Column gap={5}>
          <Text variant="BODY_XS">Current Tier</Text>
          <Badge
            bgColor="white"
            variant="BODY_XS"
            color="black"
            fontWeight="semiBold"
            padding="4px 6px"
          >
            {`VIP ${getTierNumber(current.tier)}`}
          </Badge>
        </Column>
        <Column gap={5} align="flex-end">
          <Text variant="BODY_XS">Next Tier</Text>
          <Badge
            bgColor="transparent"
            borderColor="white"
            variant="BODY_XS"
            padding="4px 6px"
            fontWeight="semiBold"
          >
            {`VIP ${next.tier}`}
          </Badge>
        </Column>
      </Row>

      <Row gap={10} align="flex-start">
        <Column className="flex-1">
          <LinearProgressBar
            percent={getTierPercent(current, next, tradingVolume)}
            barClass="progress_bar"
            customBarColor="white"
            className="w-full"
          />

          <Row className="w-full justify-between">
            <Text variant="BODY_S">
              Traded <FormattedNumber value={tradingVolume} />
            </Text>
            <Text variant="BODY_S">
              Goal <FormattedNumber value={next.minVolume} />
            </Text>
          </Row>
        </Column>

        <Icon className="lock_container" src={lockIcon} size="M" />
      </Row>

      <Row gap={10}>
        <Button
          block
          background={'white'}
          sizeVariant="S"
          className="trade_button"
          onClick={() => navigate(routes.trade.getRedirectPath())}
        >
          Trade
        </Button>
        <InfoTooltip
          color="white"
          iconSize={16}
          title={'Trade More to increase your tier level.'}
        />
      </Row>
    </Wrapper>
  );
};

export default memo(TierCard);
