import useModal from 'hooks/useModal';

import Button from 'components/Button';
import Loading from 'components/Loading';

import DepositAndWithdrawButtons from './DepositAndWithdrawButtons';
import PortfolioChart from './PortfolioChart';
import PortfolioStats from './PortfolioStats';
import PortfolioValue from './PortfolioValue';
import TierCard from './TierCard';
import {
  ConnectWalletContainer,
  Container,
  PortfolioValueAndStats,
} from './styles';
import walletIcon from 'assets/icons/wallet.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const Overview = () => {
  const {
    store: {
      account: { _accountStats, isLoading: isAccountLoading, frontendSecrets },
    },
  } = useAppContext();
  const modal = useModal();
  const { t } = useTranslation();

  // Wallet not connected
  if (!_accountStats || !frontendSecrets?.jwt) {
    return (
      <ConnectWalletContainer>
        <span className="header">{t('walletVerificationTitle')}</span>
        <span className="description">
          {t('walletVerificationDescription')}
        </span>
        <Button
          variant="secondaryOutline"
          size="medium"
          onClick={() => {
            modal.present(<WalletModal />, Modals.walletModal);
          }}
          data-gtmid="button-connect-wallet-2"
        >
          Connect
          <img src={walletIcon} alt="wallet" className="icon-right" />
        </Button>
      </ConnectWalletContainer>
    );
  }

  if (isAccountLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  const {
    cumTradingVolume,
    cumUnrealizedPnl,
    accountLeverage,
    totalPositionMargin,
    totalOrderMargin,
    balance,
    tierStatus,
    accountEquity,
  } = _accountStats;

  return (
    <Container>
      <PortfolioValueAndStats>
        <PortfolioValue
          cumUnrealizedPnl={cumUnrealizedPnl ?? 0}
          balance={accountEquity}
        />
        {tierStatus && (
          <TierCard tierStatus={tierStatus} tradingVolume={cumTradingVolume} />
        )}
        <PortfolioStats
          unrealizedPnl={cumUnrealizedPnl ?? 0}
          accountLeverage={accountLeverage}
          totalOrderMargin={totalOrderMargin}
          totalPositionMargin={totalPositionMargin}
          balance={balance}
        />
        <DepositAndWithdrawButtons />
      </PortfolioValueAndStats>
      <PortfolioChart balance={accountEquity} />
    </Container>
  );
};

export default observer(Overview);
