import { useEffect } from 'react';

export enum Key {
  Escape = 27,
  Up = 38,
  Down = 40,
  Right = 39,
  Left = 37,
  Enter = 13,
}

const useKey = (key: Key | Key[], handler?: (e?: KeyboardEvent) => void) => {
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      console.log('KEY DOWN', e.keyCode);
      if (
        (Array.isArray(key) && key.includes(e.keyCode)) ||
        e.keyCode === key
      ) {
        e.preventDefault();
        handler?.(e);
      }
    };

    document.addEventListener('keydown', handleKeydown, false);
    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [handler, key]);
};

export default useKey;
