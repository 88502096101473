import { readUserSetting } from 'utils';

import { useVerifyChainId } from 'hooks';
import useModal from 'hooks/useModal';

import Button from 'components/Button/button';
import ConfirmationModal from 'components/ConfirmationModal';
import Text from 'components/Text';

import DepositModal from './NewDepositModal';
import WithdrawModal from './WithdrawModal';
import { DepositWithdrawButtonsContainer } from './styles';
import { SHOULD_DISABLE_DEPOSITS_WITHDRAWALS } from 'constants/general';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';

import { UserSettings } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  height: 40px;
`;

const Header = () => {
  const {
    store: {
      account: { frontendSecrets, isVaultAccount },
    },
  } = useAppContext();
  const modal = useModal();
  const { t } = useTranslation();
  const { validateNetworkAndDoAction } = useVerifyChainId();

  const handleWithdraw = () => {
    modal.present(<WithdrawModal />, Modals.withdrawModal);
  };

  const handleDeposit = async () => {
    validateNetworkAndDoAction(() => {
      modal.present(<DepositModal />, Modals.depositModal);
    }, 'You can now deposit funds.');
  };

  return (
    <HeaderStyled>
      <Text variant="BODY_M" fontWeight="semiBold" preWrap>
        {t('accountDetails')}
      </Text>
      {frontendSecrets?.jwt ? (
        <DepositWithdrawButtonsContainer>
          <Button
            colorVariant="primaryGreen"
            sizeVariant="S"
            block={true}
            onClick={handleDeposit}
            data-gtmid="button-deposit-2"
            disabled={isVaultAccount || SHOULD_DISABLE_DEPOSITS_WITHDRAWALS}
            brandSpecificProps={{
              rabbitx: { colorVariant: 'primaryGreen' },
              bfx: { colorVariant: 'bfxMain' },
            }}
          >
            {t('deposit')}
          </Button>
          <Button
            colorVariant="secondary"
            sizeVariant="S"
            block={true}
            onClick={handleWithdraw}
            data-gtmid="button-withdraw"
            disabled={SHOULD_DISABLE_DEPOSITS_WITHDRAWALS}
            background={{
              default: 'shadesBackground700',
              hovered: 'shadesBackground600',
            }}
            border="shadesBackground700"
          >
            {t('withdraw')}
          </Button>
        </DepositWithdrawButtonsContainer>
      ) : null}
    </HeaderStyled>
  );
};

export default observer(Header);
