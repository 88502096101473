import IconAndLabelSelectOption from 'components/FilterSelector/OptionRenderers/IconAndLabelSelectOption';

import ethLogo from 'assets/assetLogos/eth.svg';
import rbxLogo from 'assets/assetLogos/rbx-circle-white.svg';
import wethIcon from 'assets/assetLogos/weth.svg';

import { UniswapSwapTokens } from 'enums';

export const ASSET_OPTIONS = [
  UniswapSwapTokens.RBX,
  UniswapSwapTokens.ETH,
  UniswapSwapTokens.WETH,
];

export const ASSET_TO_ICON_MAP = {
  [UniswapSwapTokens.ETH]: ethLogo,
  [UniswapSwapTokens.WETH]: wethIcon,
  [UniswapSwapTokens.RBX]: rbxLogo,
};

export const assetDisplayParser = (
  asset: UniswapSwapTokens,
  isSelected?: boolean,
) => {
  return (
    <IconAndLabelSelectOption
      icon={ASSET_TO_ICON_MAP[asset]}
      label={asset.toUpperCase()}
      selected={isSelected || false}
      variant={'BODY_M'}
    />
  );
};

export const LOCK_ALLOCATION = 10_000_000;
export const LP_ALLOCATION = 90_000_000;

export const getAllocation = (
  amount: number | undefined,
  totalLocked: number | undefined,
  totalAllocated: number | undefined,
) => {
  if (!amount || !totalLocked || totalLocked === 0 || !totalAllocated)
    return undefined;

  return totalAllocated * (amount / totalLocked);
};
