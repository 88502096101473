import { useEffect, useRef } from 'react';

import {
  isMobileDevice,
  isWhaleUser,
  readUserSetting,
  saveUserSetting,
} from 'utils';
import { brandedSelect, isBrandBfx } from 'utils/brand';

import { useGetStakedAmount } from 'hooks/useGetStakeAmount';
import useModal from 'hooks/useModal';

import WhaleSupportMobileModal from './WhaleSupportModal';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { getYourLocksAmount } from 'pages/Portfolio/Pages/Airdrops/BfxAirdrop/LockOptions/LockAndEarn';
import {
  useFetchYourLocksLpRbx,
  useFetchYourLocksRbx,
} from 'queryHooks/tokenLock';

import WhaleSupportToast from '.';
import { UserSettings } from 'enums';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';

export const WhaleSupportPositiveCheck = observer(() => {
  const {
    store: {
      account: { accountStats },
    },
  } = useAppContext();
  const modal = useModal();
  const isWhaleSupportAlreadyShown = useRef(false);
  const stakedAmount = useGetStakedAmount({ disableRefetch: true });
  const { data: yourLocks } = useFetchYourLocksRbx({ disableRefetch: true });
  const { data: yourLPLocks } = useFetchYourLocksLpRbx({
    disableRefetch: true,
  });
  const yourLocksAmount = parseFloat(getYourLocksAmount(yourLocks) || '');
  const yourLpLocksAmount = parseFloat(getYourLocksAmount(yourLPLocks) || '');

  useEffect(() => {
    if (
      isWhaleUser(
        accountStats?.accountEquity,
        accountStats?.cumTradingVolume,
        stakedAmount,
        yourLocksAmount,
        yourLpLocksAmount,
      )
    ) {
      // isWhaleSupportAlreadyShown is a boolean ref that is initialized to false. It is used to keep track of whether the WhaleSupportToast has been shown to the user. If it has been shown, it will not be shown again.
      if (isWhaleSupportAlreadyShown.current) return;

      const dontShowWhaleSupport = readUserSetting(
        UserSettings.DONT_SHOW_WHALE_SUPPORT_POPUP_AGAIN,
      );

      if (!dontShowWhaleSupport) {
        isWhaleSupportAlreadyShown.current = true;

        const saveWhaleSupportShown = () =>
          saveUserSetting(
            UserSettings.DONT_SHOW_WHALE_SUPPORT_POPUP_AGAIN,
            true,
          );

        if (isMobileDevice()) {
          // Modal on Mobile
          modal.present(<WhaleSupportMobileModal />, Modals.whaleSupportModal);
        } else {
          // Toast on Desktop
          const toastId = toast(
            <WhaleSupportToast
              onClose={() => {
                toast.dismiss(toastId);
                saveWhaleSupportShown();
              }}
            />,
            {
              position: 'bottom-left',
              autoClose: false,
              closeButton: false,
              hideProgressBar: true,
              pauseOnHover: false,
              draggable: true,
              closeOnClick: false,
              theme: 'dark',
              style: { background: 'none', overflow: 'visible' },
            },
          );
        }
      }
    }
  }, [
    stakedAmount,
    accountStats?.cumTradingVolume,
    accountStats?.accountEquity,
    modal,
    yourLocksAmount,
    yourLpLocksAmount,
  ]);

  return null;
});

const WhaleSupportCheck = () => {
  const dontShowWhaleSupport = readUserSetting(
    UserSettings.DONT_SHOW_WHALE_SUPPORT_POPUP_AGAIN,
  );

  if (dontShowWhaleSupport) return null;
  return <WhaleSupportPositiveCheck />;
};

export default WhaleSupportCheck;
