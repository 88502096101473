import { useEffect, useRef } from 'react';

import { useActiveWeb3React, useVerifyChainId } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';
import useModal from 'hooks/useModal';

import { VaultsSectionStyled } from '../../styles';
import VaultStats from '../common/Stats';
import VaultCountHeader from '../common/VaultCountHeader';
import VaultList from '../common/VaultList';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { VaultManager } from 'enums/vaultManager';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import AmendLiquidityModal, {
  AmendType,
} from 'pages/Vaults/common/AmendLiquidityModal';
import {
  useFetchElixirVaultsData,
  useFetchVaultsWalletShares,
} from 'queryHooks/elixirVaults';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export type VaultWalletShares = {
  [key: number]: {
    sharesAmount: number;
    pricePerShare: number;
  };
};

export type VaultPendingWalletShares = {
  [key: number]: number;
};

export const getYourSharesNum = (
  vaultsWalletShares: VaultWalletShares | undefined,
) => {
  if (!vaultsWalletShares) {
    return undefined;
  }

  return Object.values(vaultsWalletShares).reduce(
    (acc, curr) => acc + curr.sharesAmount * curr.pricePerShare,
    0,
  );
};

const ElixirVaults = () => {
  const {
    data: elixirVaultsData,
    isLoading: isLoadingElixirVaults,
    isError: isErrorElixirVaults,
  } = useFetchElixirVaultsData();
  const { t } = useTranslation();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const modal = useModal();
  const { validateNetworkAndDoAction } = useVerifyChainId();

  const { onboardUser } = useExchangeAPI();
  const { account } = useActiveWeb3React();

  const [searchParams, setSearchParams] = useSearchParams();

  const shouldConnectAndShowAmendModal =
    searchParams.get('origin') === 'stake_now';
  const isWalletModalOpen = useRef(false);

  const vaultsPoolIds = elixirVaultsData?.vaults.map(vault => vault.productId);

  const { data: vaultsWalletShares, isLoading: isFetchingVaultsWalletShares } =
    useFetchVaultsWalletShares({
      vaultsPoolIds,
      elixirVaultsData: elixirVaultsData?.vaults,
    });

  const showWalletModal = () =>
    modal.present(
      <WalletModal onClose={() => (isWalletModalOpen.current = false)} />,
      Modals.walletModal,
    ); // Prompt user to sign and verify

  const openAmendModal = () => {
    const defaultPoolId = vaultsPoolIds?.[0];

    if (!defaultPoolId) return;

    validateNetworkAndDoAction(() => {
      modal.present(
        <AmendLiquidityModal
          defaultAmendType={AmendType.Stake}
          poolId={defaultPoolId}
        />,
        Modals.amendLiquidityModal,
      );
    }, t('youCanNowStakeUnstakeFunds'));
  };

  useEffect(() => {
    if (!shouldConnectAndShowAmendModal) return;

    if (!account) {
      if (isWalletModalOpen.current) return;
      showWalletModal();
      return;
    }

    if (!frontendSecrets?.jwt) {
      showWalletModal();
      onboardUser();
      return;
    }

    if (isLoadingElixirVaults) return;

    // once done, reset the search params so, we dont keep poping the amend modal
    openAmendModal();
    setSearchParams({});
  }, [
    account,
    shouldConnectAndShowAmendModal,
    frontendSecrets?.jwt,
    isLoadingElixirVaults,
    isWalletModalOpen.current,
  ]);

  const yourSharesUsd = getYourSharesNum(vaultsWalletShares);

  return (
    <VaultsSectionStyled>
      <VaultCountHeader count={elixirVaultsData?.vaults.length} />
      <VaultStats
        lockedRbx={50_000}
        liquidity={elixirVaultsData?.totalTvlUsd}
        yourShares={yourSharesUsd}
      />
      <VaultList
        vaultManager={VaultManager.ELIXIR}
        vaults={elixirVaultsData?.vaults}
        isLoadingElixirVaults={
          isLoadingElixirVaults || isFetchingVaultsWalletShares
        }
        isErrorElixirVaults={isErrorElixirVaults}
        vaultsWalletShares={vaultsWalletShares}
      />
    </VaultsSectionStyled>
  );
};

export default observer(ElixirVaults);
