import { brand, isBrandBfx } from 'utils/brand';

import { useBlastGameAssets } from 'hooks/useBlastGameAssets';

import { FormattedNumber, Icon } from 'components';
import Loading from 'components/Loading';
import Text from 'components/Text';

import StatItem from '../components/StatIem';
import { StyledDropDownContent } from '../styles';
import lightningIcon from 'assets/icons/lightning-warning.svg';
import engangeIcon from 'assets/icons/rbx-airdrop/engage-outline.svg';
import lockIcon from 'assets/icons/rbx-airdrop/lock-outline.svg';
import lpIcon from 'assets/icons/rbx-airdrop/lp-outline.svg';
import { useAppContext } from 'contexts/AppContext';
import { Row } from 'theme/globalStyledComponents';

import { QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';

const AirdropBoostItem = observer(() => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const { fetchUserGameAssets } = useBlastGameAssets();

  const { data: userGameAssets, isLoading: isLoadingUserGameAssets } = useQuery(
    [QueryKeys.UserGameAssets],
    fetchUserGameAssets,
    {
      enabled:
        !!frontendSecrets?.jwt && brand.featureFlags.airdrops.blastAirdrop,
    },
  );
  return (
    <Row gap={3} className="my-5">
      <Icon size="S" src={lightningIcon} />
      <Text
        variant="BODY_S"
        color="shadesForeground200"
        flexed
        className={`flex-1 warning-text-gradient`}
      >
        Airdrop Boost
      </Text>
      {isLoadingUserGameAssets ? (
        <Loading size="12px" />
      ) : (
        <FormattedNumber
          variant="BODY_S"
          value={(userGameAssets?.totalBoost ?? 0) * 100}
          prefix="+"
          suffix="%"
        />
      )}
    </Row>
  );
});

type Props = {
  onViewDashboard: () => void;
  lockAllocation: number | undefined;
  lpAllocation: number | undefined;
  totalAllocation: number | undefined;
  isLoading: boolean;
};
const DropDownContent = ({
  onViewDashboard,
  lockAllocation,
  totalAllocation,
  lpAllocation,
  isLoading,
}: Props) => {
  // const ITEMS: {
  //   icon: string;
  //   label: string;
  //   value: React.ReactElement | string;
  //   isValueLoading?: boolean;
  //   highlighted?: boolean;
  //   isLoading?: boolean;
  // }[] = [
  //   {
  //     icon: lockIcon,
  //     label: 'Lock & Earn',
  //     value: (
  //       <FormattedNumber variant="BODY_S" value={lockAllocation} abbreviated />
  //     ),
  //     isValueLoading: isLoading,
  //   },
  //   {
  //     icon: lpIcon,
  //     label: 'LP & Earn',
  //     value: (
  //       <FormattedNumber variant="BODY_S" value={lpAllocation} abbreviated />
  //     ),
  //     isValueLoading: isLoading,
  //   },
  //   {
  //     icon: engangeIcon,
  //     label: 'Engage & Earn',
  //     value: <Text variant="BODY_S">[REDACTED]</Text>,
  //   },
  // ];

  return (
    <StyledDropDownContent>
      <div /> {/* top margin */}
      <div>
        <StatItem
          label={'Your BFX Points'}
          value={totalAllocation}
          abbreviated={false}
          fullWidth
        />

        {/* <div className="time-container gap-5">
          {ITEMS.map(({ label, icon, value, isValueLoading, highlighted }) => (
            <Row gap={3}>
              <Icon size="S" src={icon} />
              <Text
                variant="BODY_S"
                color="shadesForeground200"
                flexed
                className={`flex-1 ${
                  highlighted ? 'warning-text-gradient' : ''
                }`}
              >
                {label}
              </Text>
              {isValueLoading ? <Loading size="12px" /> : value}
            </Row>
          ))}
        </div> */}
        {isBrandBfx ? <AirdropBoostItem /> : null}

        <div className="dashboard-button" onClick={onViewDashboard}>
          View Dashboard
        </div>
      </div>
    </StyledDropDownContent>
  );
};

export default observer(DropDownContent);
