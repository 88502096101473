import { TableContainer as TableContainerMui } from '@material-ui/core';

import styled from 'styled-components';

export const Container = styled.div`
  .chart-container {
    width: 100%;
    margin-bottom: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .chart {
      height: 220px;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        height: 550px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    display: flex;
    gap: 30px;
    justify-content: space-between;

    .chart-container {
      width: 60%;
    }
    .history-table-container {
      width: 40%;
    }
  }
`;

export const TableContainer = styled(TableContainerMui)`
  width: auto;

  .MuiTablePagination-root {
    overflow: visible;
  }
`;
