import styled from 'styled-components';

export const RelativeParent = styled.div`
  position: relative;
  > img {
    position: absolute;
    z-index: 1;
  }

  .bolt-1 {
    top: 60px;
    right: 20px;
    width: 238.58px;
    height: 137.125px;

    flex-shrink: 0;
  }

  .bolt-2 {
    top: 35px;
    right: 10px;
    width: 150px;
    height: 157.127px;
  }

  .bolt-3 {
    top: -63px;
    right: -70px;
    width: 250px;
    height: 160px;
  }
`;

export const Container = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  padding: 20.989px 22px 23.011px 0px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4) inset;
  background: linear-gradient(90deg, #0c0d0f 0.01%, #121c25 100%);
  position: relative;
  height: 132px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fonts.kabeljauwRabbitXText};

  .title {
    font-size: 18px;
    font-weight: 600; // for some reason 550 doesnt work here
    line-height: normal;
  }

  .description {
    font-size: 15px;
    font-style: normal;
    font-weight: 500; // for some reason regular doesnt work here
    line-height: normal;
    background: linear-gradient(272deg, #696f7b -40.85%, #d1d4da 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .contained-icon {
    height: 40px;
    width: 40px;
  }

  .content {
    width: 286px;
  }

  .ligning-bolt {
    width: 213.049px;
    height: 213.049px;
    mix-blend-mode: lighten;
    margin-left: -28.5px;
    margin-right: -10px;
    align-self: center;
    margin-bottom: 20px;
  }

  .close-btn-container {
    border-radius: 100px;
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.shadesBackground800};
    border: 1px solid #142431;
    position: absolute;
    top: -42px;
    right: 0;
    cursor: pointer;

    :hover {
      background: ${({ theme }) => theme.colors.shadesBackground700};
    }
  }
`;
