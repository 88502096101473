import Text from 'components/Text';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > h6 {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 24px;
    line-height: 24px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      letter-spacing: -0.4px;
      font-size: 20px;
    }
  }

  > .header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0px;

    @media (min-width: ${({ theme }) => theme.screenSizes.smaller}px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 0px;
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
      margin: 0;
    }

    > .left-col {
      display: flex;
      align-items: center;
      gap: 20px;

      .separator {
        height: 38px;
        width: 2px;
        background-color: ${({ theme }) => theme.colors.shadesBackground700};
      }
    }

    .right-col {
      display: flex;
      gap: 10px;
      align-items: flex-start;
      flex-direction: column;
      justify-items: center;

      @media (min-width: ${({ theme }) => theme.screenSizes.smaller}px) {
        align-items: flex-end;
      }

      .current-level {
        display: flex;
        flex-direction: row-reverse;
        gap: 5px;

        @media (min-width: ${({ theme }) => theme.screenSizes.smaller}px) {
          flex-direction: row;
        }

        img {
          width: 28px;
          height: 28px;
        }

        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          @media (min-width: ${({ theme }) => theme.screenSizes.smaller}px) {
            align-items: flex-end;
          }
        }
      }

      .level-details {
        display: flex;
        align-items: center;
        background: ${({ theme }) => theme.colors.shadesBackground700};
        border-radius: 8px;
        padding: 8px 10px;
        gap: 12px;

        @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
          padding: 6px 8px;
          gap: 10px;
        }

        > .separator {
          height: 20px;
          width: 1px;
          background-color: ${({ theme }) => theme.colors.shadesBackground500};
          margin: auto;
        }

        > div {
          display: flex;
          flex-direction: column;
          gap: 2px;

          > div {
            display: flex;
            align-items: center;

            @media (min-width: ${({ theme }) => theme.screenSizes.smaller}px) {
              justify-content: flex-end;
            }
          }

          > span {
            @media (min-width: ${({ theme }) => theme.screenSizes.smaller}px) {
              text-align: right;
            }
          }
        }
      }
    }
  }
`;

export const ReferralLinkInfo = styled.div`
  .header {
    display: flex;
    align-items: center;
    gap: 5px;

    span.label {
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.shadesForeground200};
      font-weight: ${({ theme }) => theme.fontWeights.medium};

      @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
        font-size: 12px;
        line-height: normal;
      }
    }
  }

  .edit-container {
    display: flex;
    gap: 5px;
    align-items: center;

    > img {
      cursor: pointer;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      padding: 2px;
    }
  }
`;

interface ChangeLabelProps {
  isSuccess?: boolean;
}
export const ChangeLabel = styled(Text)<ChangeLabelProps>`
  display: flex;
  padding: 5px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;

  background-color: ${({ isSuccess, theme }) =>
    isSuccess
      ? theme.colors.positiveBackground100
      : theme.colors.negativeBackground100};
  color: ${({ isSuccess, theme }) =>
    isSuccess
      ? theme.colors.positiveForeground200
      : theme.colors.negativeForeground200};
`;
