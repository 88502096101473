import { isBrandRabbitX } from 'utils/brand';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;
  overflow: hidden;

  > span {
    margin: 0 15px;
  }
`;

export const CardsContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  .cards {
    display: inline-flex;
    flex: 1;
    overflow: hidden;
    padding: 20px 36px 0;
    gap: 15px;

    @media (max-width: ${({ theme }) => theme.screenSizes.sm}px) {
      overflow-x: auto;
    }
  }
`;

type BlurredEdgeProps = {
  right?: boolean;
};

export const BlurredEdge = styled.div<BlurredEdgeProps>`
  position: absolute;
  pointer-events: none;
  display: flex;
  width: 103px;
  height: 100%;
  align-items: center;
  z-index: 4;

  background: linear-gradient(
    91deg,
    ${({ right, theme }) =>
        right
          ? isBrandRabbitX
            ? `rgba(16, 22, 36, 0.00) 1.26%, rgba(16, 22, 36, 0.59) 44.34%, ${theme.colors.shadesBackground800}`
            : 'rgba(20, 26, 42, 0.00) 1.26%, rgba(20, 26, 42, 0.60) 44.34%, #141A2A'
          : isBrandRabbitX
          ? `${theme.colors.shadesBackground800} 1.26%, rgba(16, 22, 36, 0.59) 44.34%, rgba(16, 22, 36, 0.00)`
          : '#141A2A 1.26%, rgba(20, 26, 42, 0.60) 44.34%, rgba(20, 26, 42, 0.00)'}
      89.11%
  );

  ${({ right }) =>
    right
      ? css`
          right: 0;
          justify-content: flex-end;
        `
      : css`
          left: 0;
          justify-content: flex-start;
          visibility: hidden;
        `};

  > img {
    pointer-events: all;
    height: 16px;
    width: 16px;
    margin: 20px 10px 0;
    padding: 5px;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }
`;
