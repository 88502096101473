import { useState } from 'react';

import Text from 'components/Text';

import { motion } from 'framer-motion';
import styled from 'styled-components';

type ContainerProps = {
  borderColor?: string;
  leftIconBackground?: string;
};
const Container = styled.div<ContainerProps>`
  padding: 1px;
  border-radius: 100px;
  background: ${({ borderColor }) => borderColor};

  .mr-7 {
    margin-right: 7px;
    height: 17px;
    margin-top: 4px;
  }

  .content {
    border-radius: 100px;
    display: flex;
    gap: 5px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
    align-items: center;

    > img {
      background: ${({ leftIconBackground }) => leftIconBackground};

      height: 21px;
      width: 21px;
      border-radius: 100px;
    }
  }
`;

export const RAINBOW_GRADIENT =
  'linear-gradient(to right,  #FADD88 0%, #D966B7 20%, #9A6DF6 40%,  #7C9AFB 60%, #3FE4FF 80%)';

export type BorderBadgeProps = {
  border: string;
  leftIcon: string;
  leftIconBackground?: string;
  content: string | null;
  hoveredContent?: string;
};
const BorderBadge = ({
  leftIcon,
  leftIconBackground,
  content,
  border,
  hoveredContent,
}: BorderBadgeProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <motion.div
      layout
      transition={{
        type: 'spring',
        stiffness: 700,
        damping: 30,
      }}
    >
      <Container
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        leftIconBackground={leftIconBackground}
        borderColor={border}
      >
        <div className="content">
          <img src={leftIcon} alt="badge" />
          {content !== null || isHovered ? (
            <Text variant="BODY_S" className="mr-7">
              {hoveredContent
                ? isHovered
                  ? hoveredContent
                  : content
                : content}
            </Text>
          ) : null}
        </div>
      </Container>
    </motion.div>
  );
};

export default BorderBadge;
