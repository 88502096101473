import { useState } from 'react';

import { TableBody, TableCell, TableRow } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

import { useExchangeAPI } from 'hooks/useExchangeAPI';
import { useOrdersAPI } from 'hooks/useOrdersAPI';
import { usePaginationFetch } from 'hooks/usePaginationFetch';

import TableLoader from 'components/TableLoader';
import { StyledTable } from 'components/Tables/styles';

import BaseOrdersRow, { CollapsibleOrdersRow, OrderProps } from '../OrdersRow';
import TableHead from '../TableHead';
import { filterOrders, sortOrders } from '../utils';
import { useAppContext } from 'contexts/AppContext';
import { QueryParams } from 'service/restService';

import { SelectedOrdersType } from '..';
import { QueryKeys } from 'enums';
import { Order } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export enum SortBy {
  NOTIONAL = 'NOTIONAL',
  SIDE = 'SIDE',
  STATUS = 'STATUS',
  CREATED_AT = 'CREATED_AT',
  PNL = 'PNL',
}

interface Props {
  includePagination?: boolean;
  requestQueryParams?: QueryParams;
  shouldHideClosed?: boolean;
  allowNewItemsFromSocket?: boolean;
  handleCancelOrder: (order: Order) => void;
  handleAmendOrder: (order: Order, assetName: string) => void;
  selectedOrdersType: SelectedOrdersType;
  socketSource: any;
  isTradePage: boolean;
  showTableHead: boolean;
  showLabelOnTableCell: boolean;
  isRowCollapsible?: boolean;
  currentlyCancellingOrderIds: string[];
}

export type OrderSortConfig = {
  sortBy: SortBy;
  numOfClicks: number;
};

const OrdersRow = ({
  isRowCollapsible,
  ...rest
}: {
  isRowCollapsible: boolean;
} & OrderProps) =>
  isRowCollapsible ? (
    <CollapsibleOrdersRow {...rest} />
  ) : (
    <BaseOrdersRow {...rest} />
  );

const LimitAndMarketOrdersTable = ({
  includePagination,
  requestQueryParams = {},
  allowNewItemsFromSocket = true,
  handleCancelOrder,
  handleAmendOrder,
  selectedOrdersType,
  socketSource,
  isTradePage,
  shouldHideClosed,
  showTableHead,
  showLabelOnTableCell,
  isRowCollapsible = false,
  currentlyCancellingOrderIds,
}: Props) => {
  const { fetchPrivateOrders } = useOrdersAPI();
  const { isOnboarding } = useExchangeAPI();
  const { t } = useTranslation();
  const {
    store: {
      account: { frontendSecrets },
      markets: { selectedMarketId },
      appState: { showAllMarkets },
    },
  } = useAppContext();

  const [sortConfig, setSortConfig] = useState<OrderSortConfig | null>(null);

  const {
    isLoading,
    data: orders,
    pagination,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePaginationFetch<Order>({
    fetchFn: fetchPrivateOrders,
    socketSource,
    allowNewItemsFromSocket,
    initialPagination: { page: 0, limit: 10 },
    currentAuthenticatedWallet: frontendSecrets?.profile?.wallet,
    requestQueryParams,
    isOnboarding,
    queryKey: QueryKeys.Orders,
    disableRefetchInterval: !includePagination,
    includePagination,
  });

  if (isLoading || totalCount === null || !frontendSecrets) {
    return (
      <StyledTable>
        {showTableHead ? (
          <TableHead
            selectedOrdersType={selectedOrdersType}
            sortConfig={sortConfig}
          />
        ) : null}
        <TableBody>
          {!frontendSecrets ? (
            <TableRow>
              <TableCell colSpan={100} className="no-items">
                {t('connectYourWalletToStartTrading')}
              </TableCell>
            </TableRow>
          ) : (
            <TableLoader />
          )}
        </TableBody>
      </StyledTable>
    );
  }

  const filteredOrders = filterOrders({
    _orders: orders,
    showAllMarkets,
    selectedMarketId,
    isTradePage,
    shouldHideClosed,
  });

  const filteredAndSortedOrders = sortOrders({
    _orders: filteredOrders,
    sortConfig,
  });

  const { page, limit } = pagination;

  const handleSortBy = (sortBy: SortBy) => {
    setSortConfig(prev => ({
      sortBy,
      numOfClicks: (prev?.numOfClicks ?? 0) + 1,
    }));
  };

  return (
    <>
      <StyledTable stickyHeader>
        {showTableHead ? (
          <TableHead
            onSetSortBy={handleSortBy}
            sortConfig={sortConfig}
            selectedOrdersType={selectedOrdersType}
          />
        ) : null}

        <TableBody>
          {filteredAndSortedOrders.length > 0 ? (
            filteredAndSortedOrders.map((item, index) => {
              const key = `${item.id}`;
              return (
                <OrdersRow
                  isRowCollapsible={isRowCollapsible}
                  showLabels={showLabelOnTableCell}
                  index={index}
                  order={item}
                  key={key}
                  onCancelOrder={handleCancelOrder}
                  onAmendOrder={handleAmendOrder}
                  isCancelling={currentlyCancellingOrderIds.includes(item.id)}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={100} className="no-items">
                You have no orders.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>

      {includePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default observer(LimitAndMarketOrdersTable);
