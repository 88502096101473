import { useState } from 'react';

import CheckBox from 'components/Toggle/Checkbox';

import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const DisableCancelOrderConfirmationCheckbox = () => {
  const { t } = useTranslation();
  const {
    store: {
      appState: {
        disableCancelOrderConfirmationModal,
        setDisableCancelOrderConfirmationModal,
      },
    },
  } = useAppContext();

  const [shouldNotShowAgain, setShouldNotShowAgain] = useState(
    disableCancelOrderConfirmationModal,
  );

  return (
    <CheckBox
      onClick={() => {
        setShouldNotShowAgain(prev => {
          setDisableCancelOrderConfirmationModal(!prev);
          return !prev;
        });
      }}
      isChecked={shouldNotShowAgain}
      label={t('doNotShowAgain')}
      data-gtmid="checkbox-dont-show-order-confirmation"
    />
  );
};

export default observer(DisableCancelOrderConfirmationCheckbox);
