import { roundDownToNearestTick, roundUpToNearestTick } from './helpers';
import { MAX_ORDERBOOK_DISPLAY_ROWS } from 'constants/general';

import { OrderbookSide } from 'enums';
import { OrderbookOrder } from 'interfaces';

export const sortOrderbook = (orders: OrderbookOrder[]) => {
  return orders.sort((a, b) => b.price - a.price);
};

/**
 * Populate orderbook with total quantity and depth percentage for each order/order_group
 * @param orderbookOrders orderbook orders, sorted by price
 * @param orderSide : orderside is used to whether the price should be rounded up or rounded down
 * @param priceTick : price tick at which the order price is rounded upto
 * @param pricePrecision : price decimal places
 * @param minTick : quantity decimal places
 * @returns orderbook orders with total quantity and depth percentage
 */
export const mergeOrderbookWithDepthDataByPriceTick = (
  orderbookOrders: OrderbookOrder[],
  orderSide: OrderbookSide,
  priceTick: number,
  pricePrecision: number,
  minTick: number,
) => {
  if (orderbookOrders.length === 0) return [];

  // const { minTick, price: pricePrecision } = getDecimalPlaces(marketId);

  // If marketId is defined, check if group size is equal to minTick(grouping doesnt makes sense)
  const shouldPerformGrouping = priceTick !== minTick;

  const roundPrice = (price: number) => {
    const roundingFn =
      orderSide === OrderbookSide.Bid
        ? roundDownToNearestTick
        : roundUpToNearestTick;
    const res = roundingFn(price, priceTick);

    return parseFloat(res.toFixed(pricePrecision));
  };

  const quantitySum = orderbookOrders.reduce(
    (acc: number, currItem: OrderbookOrder) => acc + currItem.quantity,
    0,
  );

  let totalQuantity = 0;

  let updatedOrderbookOrders: OrderbookOrder[] = [];

  let groupedOrderBookOrder: OrderbookOrder = {
    price: roundPrice(orderbookOrders[0].price),
    quantity: 0,
    total: 0,
    hasChanged: false,
    depthPercentage: 0,
  };

  for (let index = 0; index < orderbookOrders.length; index++) {
    if (updatedOrderbookOrders.length >= MAX_ORDERBOOK_DISPLAY_ROWS) break;
    let order = orderbookOrders[index];

    totalQuantity += order.quantity;

    const depthPercentage = parseFloat(
      ((totalQuantity * 100) / quantitySum).toFixed(2),
    );

    if (shouldPerformGrouping && priceTick) {
      // Check if current order's price is inside the grouped price brakcet
      if (
        orderSide === OrderbookSide.Bid
          ? order.price >= groupedOrderBookOrder.price
          : order.price <= groupedOrderBookOrder.price
      ) {
        groupedOrderBookOrder = {
          ...groupedOrderBookOrder,
          quantity: groupedOrderBookOrder.quantity + order.quantity,
          total: totalQuantity,
          hasChanged: groupedOrderBookOrder.hasChanged || order.hasChanged,
          depthPercentage,
        };
      } else {
        // Push the grouped order to the updated borderbook and set the next grouped order price
        updatedOrderbookOrders.push(groupedOrderBookOrder);

        // reset groupedOrderBookOrder to the next price group
        groupedOrderBookOrder = {
          ...order,
          price: roundPrice(order.price), // price for next batch
          total: totalQuantity,
          depthPercentage,
        };
      }

      // if its last index, dont wait for next iteration, push rn
      if (index === orderbookOrders.length - 1) {
        updatedOrderbookOrders.push(groupedOrderBookOrder);
      }

      continue;
    }

    updatedOrderbookOrders.push({
      ...order,
      total: totalQuantity,
      depthPercentage: (totalQuantity * 100) / quantitySum,
    });
  }

  orderbookOrders.forEach((order, index) => {});

  return updatedOrderbookOrders;
};

/**
 * Merge orderbook orders from previous render with orderbook orders from current render
 * @param prevOrderbookOrders orderbook orders from previous render
 * @param newOrderbookOrders orderbook orders from current render
 * @param isFirstRender whether this is the first render, if so, all orders will be marked as changed
 * @returns merged orderbook orders
 */
export const mergeOrderbook = (
  prevOrderbookOrders: OrderbookOrder[],
  newOrderbookOrders: OrderbookOrder[],
  isFirstRender = false,
) => {
  const map = new Map(
    prevOrderbookOrders.map(item => [
      item.price,
      { ...item, hasChanged: false },
    ]),
  );

  const updatedMap = newOrderbookOrders.reduce((acc, curr) => {
    acc.set(curr.price, {
      ...curr,
      hasChanged: !isFirstRender,
    });
    return acc;
  }, map);

  // convert map to array
  const res = Array.from(updatedMap.values());

  // remove orders with quantity 0
  return res.filter(item => {
    return item.quantity !== 0;
  });
};

/**
 * Parse backend response data for orderbook items into OrderbookOrder[]
 * @param resData backend response data for orderbook items
 * @returns parsed orderbook orders as OrderbookOrder[]
 */
export const parseResponseData = (resData: {
  [key: string]: string[];
}): OrderbookOrder[] => {
  if (!resData) {
    return [];
  }

  // since the backend response data is an object with index as key, we need to convert it
  // to an array of values and parse the price and quantity to float, since they are strings
  const parsedOrderbookOrders = Object.values(resData).map(
    ([price, quantity]) => {
      return {
        price: parseFloat(price),
        quantity: parseFloat(quantity),
        total: 0,
        hasChanged: false,
        depthPercentage: 0,
      };
    },
  );

  return parsedOrderbookOrders;
};
