import { getCandleData as getCandleDataHelper } from '../../../../../components/TVChartContainer/api/helpersRabbit';
import { orderTypeToLabel } from '../../../../../components/Tables/OrdersTable/OrdersRow';
import { EXCHANGE } from './HistoricalPrice.const';

import { OrderStatus, OrderType, TradeSide } from 'enums';
import { TFunction } from 'i18next';
import { Market, Order, Position } from 'interfaces';

export const marketOptionToTVMarket = (m: Market) => ({
  description: m.id,
  exchange: EXCHANGE,
  full_name: m.id,
  symbol: m.id,
  type: 'crypto',
});

export const getAllSymbols = (ms: Market[]) => () =>
  ms.map(m => marketOptionToTVMarket(m));

// @todo: helpers to WS

//   export const DEFAULT_RESOLUTION = '5';

// const parseTradesFromEvent = (e: MessageEvent) => {
//   // {
//   //   channel: "trades",
//   //   market: "BTC-PERP",
//   //   type: "update",
//   //   data: [
//   //     {
//   //       id: 3843756620,
//   //       price: 39739.0,
//   //       size: 0.25,
//   //       side: "buy",
//   //       liquidation: false,
//   //       time: "2022-04-28T15:25:34.885243+00:00",
//   //     },
//   //     {
//   //       id: 3843756621,
//   //       price: 39739.0,
//   //       size: 0.5,
//   //       side: "buy",
//   //       liquidation: false,
//   //       time: "2022-04-28T15:25:34.885243+00:00",
//   //     },
//   //     {
//   //       id: 3843756622,
//   //       price: 39739.0,
//   //       size: 0.5,
//   //       side: "buy",
//   //       liquidation: false,
//   //       time: "2022-04-28T15:25:34.885243+00:00",
//   //     },
//   //   ],
//   // };
//   try {
//     const obj = JSON.parse(e.data);
//     if (obj.type === 'update') {
//       const events = obj.data;
//       const ret = events.reduce(
//         (p, c) => [...p, { ...c, market: obj.market }],
//         [],
//       );

//       return ret;
//     }
//   } catch (e: any) {
//     console.log(e);
//   }

//   return [];
// };

const channelToSubscription = new Map();

// // @dev: resolution is in minutes, barTime in miliseconds
// const getNextDailyBarTime = (barTime: number, resolution: number) => {
//   const date = new Date(barTime + resolution * 60 * 1000);
//   return Math.floor(date.getTime() / 1000);
// };

// export const resolutionToNumber = (r: string) => {
//   if (r.indexOf('D') > 0) return parseInt(r) * 24 * 60;
//   if (r.indexOf('W') > 0) return parseInt(r) * 24 * 60 * 7;
//   if (r.indexOf('M') > 0) return parseInt(r) * 24 * 60 * 7 * 30;
//   return parseInt(r);
// };

// let resolution = DEFAULT_RESOLUTION;

// const onTrade = (message: any) => {
//   const data = parseTradesFromEvent(message);
//   data.map(d => {
//     const tradePrice = d.price;
//     const tradeTime = Math.floor(Date.parse(d.time) / 1000);
//     const channelString = `${EXCHANGE}:${d.market}`;
//     const subscriptionItem = channelToSubscription.get(channelString);
//     if (subscriptionItem === undefined) {
//       return;
//     }
//     const lastDailyBar = subscriptionItem.lastDailyBar;
//     const nextDailyBarTime = getNextDailyBarTime(
//       lastDailyBar.time,
//       resolutionToNumber(resolution),
//     );

//     let bar;
//     // console.log(
//     //   'tradebar',
//     //   tradeTime,
//     //   lastDailyBar.time,
//     //   nextDailyBarTime,
//     // );
//     if (tradeTime >= nextDailyBarTime) {
//       bar = {
//         time: nextDailyBarTime * 1000,
//         open: tradePrice,
//         high: tradePrice,
//         low: tradePrice,
//         close: tradePrice,
//       };
//       // console.log('[socket] Generate new bar', bar);
//     } else {
//       bar = {
//         ...lastDailyBar,
//         high: Math.max(lastDailyBar.high, tradePrice),
//         low: Math.min(lastDailyBar.low, tradePrice),
//         close: tradePrice,
//       };
//       // console.log('[socket] Update the latest bar by price', tradePrice);
//     }
//     subscriptionItem.lastDailyBar = bar;
//     // send data to every subscriber of that symbol
//     subscriptionItem.handlers.forEach(handler => handler.callback(bar));
//   });
// };

// @todo: update when we have WS to process real time data
// useEffect(() => {
//   if (!socket) return;
//   socket.onopen = () => {
//     if (isReady) return;
//     console.log('WebSocket Client Connected');
//     setIsReady(true);
//     socket.send(SubAdd(symbols[0]).data);

//     socket.onmessage = message => {
//       // console.log(message);
//       setMsgs(prev => [message, ...prev]);

//       const obj = JSON.parse(message.data);
//       if (obj.channel === 'trades') {
//         onTrade(message);
//       }
//     };
//   };
// }, [socket, onTrade]);

// const barInfoToChartBar = (
//   bar: BarInfo,
//   isFixed: boolean,
//   decimals: number,
// ) => {
//   const volume = parseFloat(formatUnits(bar.notional, decimals));

//   return {
//     //@ts-ignore
//     time: bar.timestamp * 1000, //bar time must be in milliseconds
//     //@ts-ignore
//     low: isFixed ? formatUnits(bar.low.mul(100)) : formatUnits(bar.oracleRate),
//     // @ts-ignore
//     high: isFixed
//       ? formatUnits(bar.high.mul(100))
//       : formatUnits(bar.oracleRate),
//     //@ts-ignore
//     open: isFixed
//       ? formatUnits(bar.open.mul(100))
//       : formatUnits(bar.oracleRate),
//     //@ts-ignore
//     close: isFixed
//       ? parseFloat(formatUnits(bar.close.mul(100)))
//       : parseFloat(formatUnits(bar.oracleRate.mul(100))),
//     //@ts-ignore
//     volume,
//   };
// };

//===================
// CHART FUNCTIONS
//===================

export const getCandleData = async (symbolInfo, resolution, periodParams) => {
  return getCandleDataHelper(symbolInfo, resolution, periodParams);
};

export const subscribeOnStream = (
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar,
) => {
  // const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
  // const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;
  const channelString = symbolInfo.full_name;

  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };
  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscribeUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
  };
  channelToSubscription.set(channelString, subscriptionItem);
  // console.log(
  //   '[subscribeBars]: Subscribe to streaming. Channel:',
  //   channelString,
  // );
};

export const unsubscribeFromStream = () => {};
//===================

export const getOrderLabel = (order: Order, t: any) => {
  if (
    [OrderStatus.PLACED].includes(order.status) &&
    [
      OrderType.STOP_LOSS,
      OrderType.STOP_LOSS_LIMIT,
      OrderType.TAKE_PROFIT,
      OrderType.TAKE_PROFIT_LIMIT,
    ].includes(order.type)
  ) {
    return `${orderTypeToLabel(order.type, t)}`;
  }
  return `${order.side === TradeSide.LONG ? 'Buy' : 'Sell'} ${orderTypeToLabel(
    order.type,
    t,
  )}`;
};

export const getPositionLabel = (position: Position) =>
  `${position.side === TradeSide.LONG ? '' : ''} ${position.side}`;
