import { memo } from 'react';

import Text from 'components/Text';

import Header from './Header';
import TradesList from './TradesList';
import { Container, RecentTradesTableStyled } from './style';

import { useTranslation } from 'react-i18next';

const RecentTrades = ({
  avoidPaddings = false,
  avoidHeader = false,
}: {
  avoidPaddings?: boolean;
  avoidHeader?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Container avoidPaddings={avoidPaddings}>
      {!avoidHeader ? (
        <Text variant="BODY_M" fontWeight="semiBold" flexed className="my-10">
          {t('trades')}
        </Text>
      ) : null}
      <RecentTradesTableStyled>
        <Header />
        <TradesList />
      </RecentTradesTableStyled>
    </Container>
  );
};

export default memo(RecentTrades);
