export enum CardVariant {
  GOAL = 'GOAL',
  PRIZE = 'PRIZE',
  FEE = 'FEE',
  LOCKED = 'LOCKED',
  LOCKED_INFO = 'LOCKED_INFO',
  CLAIMED = 'CLAIMED',
}

export enum CardStatus {
  CLAIMED = 'CLAIMED',
  IN_PROGRESS = 'INPROGRESS',
  INFO = 'INFO', // for Info Icon
}