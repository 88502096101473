export enum OrderType {
  LIMIT = 'limit',
  MARKET = 'market',
  CANCEL = 'cancel',
  AMEND = 'amend',
  STOP_LOSS = 'stop_loss',
  TAKE_PROFIT = 'take_profit',
  ST0P_LIMIT = 'stop_limit',
  STOP_MARKET = 'stop_market',
  STOP_LOSS_LIMIT = 'stop_loss_limit',
  TAKE_PROFIT_LIMIT = 'take_profit_limit',
  LADDER = 'ladder',
}
