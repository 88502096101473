import styled from 'styled-components';

export const Container = styled.div`
  .referral-fees-container {
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.shadesBackground800};
    padding: 15px;
    gap: 20px;
    margin-bottom: 20px;
    border-radius: 8px;

    @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
      gap: 10px;
    }
  }
`;

export const ReferralFeesHistoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  padding: 15px 10px;
  border-radius: 8px;
  gap: 10px;

  .left-col {
    display: flex;
    align-items: center;
    gap: 10px;

    > img {
      width: 32px;
      height: 32px;
    }

    .value {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2px;
    }
  }
`;
