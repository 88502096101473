import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  min-width: 362px;
  padding: 20px 0px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};
  background: ${({ theme }) => theme.colors.shadesBackground800};

  .gapped-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .gapped-row {
    display: flex;
    align-self: flex-start;
    gap: 5px;
    margin-left: 20px;
    flex-grow: 0;
    align-items: center;
    cursor: pointer;

    .icon {
      height: 16px;
      width: 16px;
    }

    .secondary-text {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.shadesForeground200};
      font-size: 12px;
      line-height: normal;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .button-row {
    display: flex;
    margin-top: 20px;
    gap: 10px;
    align-items: center;
    padding: 0px 20px;

    .button {
      padding: 10px 20px;
      color: ${({ theme }) => theme.colors.white};

      font-size: 12px;
      line-height: 14px;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        padding: 12px 20px;

        font-size: 14px;
        line-height: 16px;
      }
    }

    .flexed {
      display: flex;
      flex-grow: 1;
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      margin-top: 30px;
    }
  }
`;
