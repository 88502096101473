import styled from 'styled-components';

export const SharesInfoContainer = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
  background: ${({ theme }) => theme.colors.shadesBackground700};
`;
