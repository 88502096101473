import { memo } from 'react';

import { useAirdropsAPI } from 'hooks';
import useModal from 'hooks/useModal';

import Button from 'components/Button';
import Loading from 'components/Loading';

import ClaimRewards, { AIRDROP_PHASE_TWO_TITLE } from './ClaimRewards';
import { ConnectWalletContainer } from './styles';
import walletIcon from 'assets/icons/wallet.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 30px;
  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    margin-top: 0;
    width: 420px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
`;

const PhaseTwo = () => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();
  const { fetchAllAirdropsForUser } = useAirdropsAPI();
  const modal = useModal();

  const {
    isLoading,
    data: profileAirdrops,
    isError,
  } = useQuery(
    [QueryKeys.Airdrops, frontendSecrets?.profile.wallet],
    async () => {
      try {
        const res = await fetchAllAirdropsForUser();
        return res.data;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    {
      refetchInterval: 15_000,
      staleTime: 10_000,
      enabled: !!frontendSecrets?.profile.wallet,
    },
  );

  if (!frontendSecrets?.jwt) {
    return (
      <ConnectWalletContainer>
        <span className="header">Wallet Verification</span>
        <span className="description">
          Connect & sign your wallet. The wallet will need to support ETH, such
          as Rabby with the network set to Mainnet
        </span>
        <Button
          variant="secondaryOutline"
          size="medium"
          onClick={() => {
            modal.present(<WalletModal />, Modals.walletModal);
          }}
          data-gtmid="button-connect-wallet-2"
        >
          Connect
          <img src={walletIcon} alt="wallet" className="icon-right" />
        </Button>
      </ConnectWalletContainer>
    );
  }

  if (isError) {
    return (
      <Container>
        <ColoredText color={Colors.Danger} className="mt-10">
          {t('somethingWentWrong')}
        </ColoredText>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  const profileAirdrop =
    profileAirdrops &&
    profileAirdrops.find(
      airdrop => airdrop.airdropTitle === AIRDROP_PHASE_TWO_TITLE,
    );

  const isEligible = profileAirdrop !== undefined;

  return (
    <Container>
      <span className="font-body-l font-medium">
        {isEligible ? t('congratsEligible') : t('ohNoNotEligible')}
      </span>
      {isEligible ? <ClaimRewards profileAirdrop={profileAirdrop} /> : null}
    </Container>
  );
};

export default observer(PhaseTwo);
