import { useCallback, useEffect, useRef, useState } from 'react';

import { useOnClickOutside } from 'hooks';
import useKey, { Key } from 'hooks/useKey';

import { TextVariant } from 'components/Text/variant';

import styled, { css } from 'styled-components';

const DropdownContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

interface CustomUlStyles {
  top?: string;
  right?: string;
  padding?: string;
  liPadding?: string;
  liBorderRadius?: string;
  borderRadius?: string;
  maxHeight?: string;
  liHoveredBackgroundColor?: string;
}
interface ListProps {
  customStyles?: CustomUlStyles;
}

export const List = styled.div<ListProps>`
  z-index: 11;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
  border-radius: ${({ customStyles }) =>
    customStyles?.borderRadius ? customStyles.borderRadius : '6px'};
  position: absolute;
  top: ${({ customStyles }) => (customStyles?.top ? customStyles.top : '40px')};
  right: ${({ customStyles }) =>
    customStyles?.right ? customStyles.right : 'auto'};
  padding: ${({ customStyles }) =>
    customStyles?.padding ? customStyles.padding : '5px'};
  max-height: ${({ customStyles }) =>
    customStyles?.maxHeight ? customStyles.maxHeight : 'auto'};
  margin: 0;
  list-style-type: none;
  min-width: 100%;
  box-shadow: 2px 2px 40px 0px rgba(23, 23, 27, 0.8);
  overflow-y: auto;

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.shadesBackground500};
  }

  li {
    cursor: pointer;
    margin: 0;
    color: ${({ theme }) => theme.colors.shadesForeground200};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    padding: ${({ customStyles }) =>
      customStyles?.liPadding ? customStyles.liPadding : '10px'};
    border-radius: ${({ customStyles }) =>
      customStyles?.liBorderRadius ? customStyles.liBorderRadius : '8px'};
    display: flex;
    white-space: nowrap;
    :hover {
      background: ${({ theme, customStyles }) =>
        customStyles?.liHoveredBackgroundColor
          ? customStyles.liHoveredBackgroundColor
          : theme.colors.shadesBackground700};
    }
  }

  .selected {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }
`;

export interface Props<T> {
  options: T[];
  onOptionSelect: (option: T) => void;
  onDropdownShownChange?: (isShown: boolean) => void;
  InputElement: JSX.Element;
  customStyles?: CustomUlStyles;
  displayTextParser?: (value: T, isSelected?: boolean) => string | JSX.Element;
  containerClass?: string;
  inputClass?: string;
  disabled?: boolean;
  isOptionSelected?: (option: T) => boolean;
  gtmIdGenerator?: (option: T) => string;
}

export const SelectDropdown = <T extends unknown>({
  options,
  onOptionSelect,
  onDropdownShownChange,
  InputElement,
  customStyles,
  displayTextParser,
  containerClass,
  inputClass,
  disabled = false,
  isOptionSelected,
  gtmIdGenerator,
}: Props<T>) => {
  const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);

  useEffect(() => {
    onDropdownShownChange && onDropdownShownChange(isDropdownShown);
  }, [isDropdownShown]);

  const inputRef = useRef<HTMLDivElement>(null);

  const handleDropdownClose = useCallback(() => {
    setIsDropdownShown(false);
  }, []);

  useOnClickOutside(inputRef, handleDropdownClose);

  useKey(Key.Escape, handleDropdownClose);

  const handleOptionSelect = (option: T) => {
    setIsDropdownShown(false);
    onOptionSelect(option);
  };

  return (
    <DropdownContainer ref={inputRef} className={containerClass}>
      <div
        onClick={() => !disabled && setIsDropdownShown(prev => !prev)}
        className={`${inputClass} ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
      >
        {InputElement}
      </div>
      {isDropdownShown ? (
        <List customStyles={customStyles}>
          {options.map(el => (
            <li
              onClick={() => handleOptionSelect(el)}
              key={`${el}`}
              data-cy="select-dropdown-item"
              data-gtmid={gtmIdGenerator?.(el)}
              style={TextVariant['BODY_S']}
              className={isOptionSelected?.(el) ? 'selected' : ''}
            >
              {displayTextParser
                ? displayTextParser(el, isOptionSelected?.(el))
                : (el as string)}
            </li>
          ))}
        </List>
      ) : null}
    </DropdownContainer>
  );
};
