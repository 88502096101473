import { Icon } from 'components';
import Text from 'components/Text';

import BorderBadge, { BorderBadgeProps } from './BorderBadge';
import leftArrowWhiteIcon from 'assets/icons/arrow-left-white.svg';
import { routes } from 'pages/routes';
import { Column } from 'theme/globalStyledComponents/column';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type ContainerProps = {
  backgroundImage: string;
};
const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: ${({ backgroundImage }) =>
    `url(${backgroundImage}) lightgray 50% / cover no-repeat`};

  .badges-container {
    position: absolute;
    right: 10px;
    top: -8px;
    display: flex;
    gap: 5px;
  }
`;

export type HeaderProps = {
  backgroundImage: string;
  title: string;
  description: string;
  badges?: BorderBadgeProps[];
};
const Header = ({
  backgroundImage,
  title,
  description,
  badges,
}: HeaderProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Column gap={10}>
      <Text
        flexed
        gap={5}
        cursorPointer
        onClick={() =>
          navigate(routes.portfolio.airdrops.dashboard.getRoutePath())
        }
      >
        <Icon src={leftArrowWhiteIcon} />
        {t('back')}
      </Text>
      <Container backgroundImage={backgroundImage}>
        <Text variant="HEADING_H2" fontWeight="semiBold">
          {title}
        </Text>
        <Text variant="BODY_M" color="shadesForeground200">
          {description}
        </Text>

        {badges && badges.length > 0 ? (
          <div className="badges-container">
            {badges.map(badgeProps => (
              <BorderBadge {...badgeProps} />
            ))}
          </div>
        ) : null}
      </Container>
    </Column>
  );
};

export default Header;
