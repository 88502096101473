import { parseEther } from '@ethersproject/units';

import { brandedSelect } from 'utils/brand';

import { config } from 'config';

export const DEFAULT_ACCOUNT_MARKET_LEVERAGE = 1;
export const DEFAULT_DECIMAL_SCALE = 2;
export const MAX_RECENT_TRADES_NUM = 100;
export const TIME_TO_WAIT_CONFIRMATION = 900000;
export const ETH_FEE_TO_DEPOSITIN_WEI = parseEther('0.001');
export const LIMIT_EXCESSIVE_ARRAY_FOR_SCROLL = 40;
export const MAX_ORDERS_TO_SHOW = 500;
export const LIMIT_EXCESSIVE_ARRAY_FOR_ORDERBOOK = 100;
export const MAX_ORDERBOOK_DISPLAY_ROWS = 11;

export const WALLET_CONNECT_MODAL_NAME = 'walletConnectModalName';

export const QUERY_REFETCH_INTERVAL = 30_000;

export const ORDERBOOK_THROTTLE_REFLECT_THRESHOLD = 250;

export const CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT = brandedSelect({
  rabbitx: 16,
  bfx: 13,
});

export const TV_CHART_RELOAD_INTERVAL = 15 * 60 * 1000; // 15 minutes

export const SHOULD_DISABLE_DEPOSITS_WITHDRAWALS = config.forceUpgrade; // disabled deposits withdrawls in case of exchange under force upgrade

export const MARKET_PRICE_SPREAD_THRESHOLD = 0.05; // 5%
export const MARKET_PRICE_SPREAD_THRESHOLD_PERCENTAGE =
  MARKET_PRICE_SPREAD_THRESHOLD * 100; // 5%

export const MAX_INPUT_LENGTH = 14;
