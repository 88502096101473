import { useState } from 'react';

import { useBfxPointsAPI } from 'hooks/useBFXPointsAPI';

import { FormattedNumber, Icon } from 'components';
import Loading from 'components/Loading';

import { Container } from '../styles';
import DropDownContent from './DropDownContent';
import bfxIcon from 'assets/assetLogos/blast-green.svg';
import { routes } from 'pages/routes';
import { Row } from 'theme/globalStyledComponents/row';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

type Props = { isActive: boolean; closeMobileSidebar?: () => void };
const TokenAllocation = ({ isActive, closeMobileSidebar }: Props) => {
  const navigate = useNavigate();
  // const {
  //   totalAllocation,
  //   lpAllocation,
  //   lockAllocation,
  //   isLoading: isLockOrLPAllocationLoading,
  // } = useGetBfxAllocation();

  const [isHovered, setIsHovered] = useState(false);

  const { bfxPoints, isLoading } = useBfxPointsAPI();

  const isContainerActive = isActive || isHovered;

  const navigateToDashboard = () => {
    setIsHovered(false);
    navigate(routes.portfolio.airdrops.dashboard.getRedirectPath());
    closeMobileSidebar?.();
  };

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={navigateToDashboard}
    >
      <Container isHovered={isContainerActive} onClick={navigateToDashboard}>
        <div className="animated-border" />
        <Row className="item">
          <Icon src={bfxIcon} size="ML" />

          {isLoading ? (
            <Loading size={'14px'} className="ml-5" />
          ) : (
            <FormattedNumber
              variant="NUMBERS_XL"
              fontWeight="semiBold"
              value={bfxPoints}
              abbreviated
              disableTooltip
            ></FormattedNumber>
          )}
        </Row>
      </Container>
      {isHovered ? (
        <DropDownContent
          onViewDashboard={navigateToDashboard}
          totalAllocation={bfxPoints}
          // lockAllocation={lockAllocation}
          // lpAllocation={lpAllocation}
          // isLoading={isLockOrLPAllocationLoading}
          // Temporary fix until we have new Api from backend
          lockAllocation={0}
          lpAllocation={0}
          isLoading={false}
        />
      ) : null}
    </div>
  );
};

export default observer(TokenAllocation);
