import { useEffect } from 'react';

import { brand } from 'utils/brand';

import Button from 'components/Button/button';

import UserBlock from '../Header/UserBlock';
import ActionButtons from '../shared/ActionButtons';
import MarketSearch from '../shared/MarketSearch';
import { isActiveLink } from '../shared/utils';
import {
  NavLinkStyled,
  ListStyled,
  MobileSidebarStyled,
  CloseIconStyled,
  MobileSidebarContentStyled,
  SeparatorStyled,
} from './styles';
import closeIcon from 'assets/icons/close-x.svg';
import claimAirdropIcon from 'assets/icons/portfolio-nav-items/claim-airdrop.svg';
import AirdropSideBarActions from 'pages/Portfolio/Sidebar/AirdropSideBarActions';
import {
  ListSeperator,
  NavItemContainer,
} from 'pages/Portfolio/Sidebar/styles';
import { routes } from 'pages/routes';
import { Row } from 'theme/globalStyledComponents';

import { MenuItem } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  menuItems: MenuItem[];
  onCloseMobileSidebar: () => void;
}
const MobileSidebar = ({ menuItems, onCloseMobileSidebar }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onMarketOptionSelect = () => {
    onCloseMobileSidebar();
  };

  const goToClaimAirdropPage = () => {
    navigate(routes.portfolio.airdrops.getRedirectPath());
    onCloseMobileSidebar();
  };

  // Set the CSS variable to the correct value on resize (vh units don't work in mobile browsers as expected because of the search navbar)
  useEffect(() => {
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVh();

    window.addEventListener('resize', setVh);

    return () => window.removeEventListener('resize', setVh);
  }, []);

  const renderMenuItems = (
    items: MenuItem[],
    pathname: string,
    isNested = false,
  ) => {
    return items.map((item, i) => (
      <li key={i}>
        <NavLinkStyled
          to={item.to}
          isActive={isActiveLink(pathname, item.to, item.componentName, false)}
          onClick={onCloseMobileSidebar}
          isNested={isNested}
        >
          <span>{item.text}</span>
        </NavLinkStyled>
        {item.children ? (
          <ListStyled avoidMarginAndPadding>
            {renderMenuItems(item.children as any, pathname, true)}
          </ListStyled>
        ) : null}
      </li>
    ));
  };

  return (
    <MobileSidebarStyled>
      <CloseIconStyled
        src={closeIcon}
        alt="Close Menu"
        onClick={onCloseMobileSidebar}
      />
      <MobileSidebarContentStyled>
        <div className="top-part">
          <NavLink to={routes.trade.getRoutePath()} className="logo">
            <img src={brand.logo} alt="Logo" />
          </NavLink>
          <MarketSearch isBlock onOptionSelect={onMarketOptionSelect} />

          <ListStyled>
            {renderMenuItems(menuItems, pathname)}

            {brand.featureFlags.news && (
              <li key={'news'}>
                <NavLinkStyled
                  target={'_blank'}
                  to={'https://news.rabbitx.io'}
                  isActive={false}
                >
                  <span>{t('news')}</span>
                </NavLinkStyled>
              </li>
            )}
          </ListStyled>

          {/* <MigrateStrpContainer>
            <div className="separator" />
            <Button variant="tertiary" size="medium" onClick={goToMigratePage}>
              <span className="migrate-text">Migrate $STRP</span>
              <img src={swapIcon} alt="Swap" className="icon-right" />
            </Button>
          </MigrateStrpContainer> */}
        </div>
        <div className="bottom-part">
          <Row gap={10} className="mb-10">
            <AirdropSideBarActions
              pathname={pathname}
              closeMobileSideBar={onCloseMobileSidebar}
            />

            {brand.featureFlags.airdrops.default && (
              <>
                <ListSeperator />
                <NavItemContainer>
                  <Button
                    colorVariant="tertiary"
                    sizeVariant="S"
                    rightIcon={claimAirdropIcon}
                    onClick={goToClaimAirdropPage}
                  >
                    <span className="menu-label">
                      {t('menu.portfolioAirdrops')}
                    </span>
                  </Button>
                  {/* <div className="menu-text">{t('menu.portfolioAirdrops')}</div> */}
                </NavItemContainer>
              </>
            )}
          </Row>

          <ActionButtons closeMobileSideBar={onCloseMobileSidebar} />
          <SeparatorStyled />
          <UserBlock />
        </div>
      </MobileSidebarContentStyled>
    </MobileSidebarStyled>
  );
};

export default MobileSidebar;
