import { useMemo, useState } from 'react';

import { filterMarkets } from 'utils';

import { useSwitchMarket } from 'hooks/useSwitchMarket';

import Loading from 'components/Loading';
import Text from 'components/Text';

import MarketRow from './MarketRow';
import {
  Container,
  FilterBar,
  MarketHeaderCell,
  NoResultsAndLoaderContainer,
  Options,
} from './styles';
import caretDown from 'assets/icons/caret-down.svg';
import caretUp from 'assets/icons/caret-up.svg';
import { useAppContext } from 'contexts/AppContext';
import { PriceChangeType } from 'enums/priceChangeType';
import { MarketService } from 'service/marketService';

import { QueryKeys } from 'enums';
import { Market } from 'interfaces';
import { observer } from 'mobx-react';
import { useFetchUserSettings } from 'queryHooks';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const marketService = MarketService.get();

const marketHeaderItems: SortConfig[] = [
  { label: 'market', value: 'id', numClicks: 0 },
  { label: 'price', value: 'lastTradePrice24hChangeBasis', numClicks: 0 },
  { label: 'volume', value: 'marketVolume24h', numClicks: 0 },
];

const filterBarItems = [
  'All',
  'Favorites',
  'Top Gainers',
  'Top Losers',
] as const;

type FilterBarItemType = (typeof filterBarItems)[number];

type SortConfig = {
  label: string;
  value: string;
  numClicks: number;
};

export const getSortedArray = (
  data: Market[],
  sortConfig: SortConfig | null,
  activeFilter: string,
): Market[] => {
  let sortableItems = [...data];
  if (['Top Gainers', 'Top Losers'].includes(activeFilter)) {
    sortableItems = sortableItems
      .filter((item: Market) => {
        return activeFilter === 'Top Gainers'
          ? item.percentChangeType === PriceChangeType.Positive
          : item.percentChangeType === PriceChangeType.Negative;
      })
      .sort((a: Market, b: Market) => {
        if (a.lastTradePrice24hChangeBasis < b.lastTradePrice24hChangeBasis) {
          return activeFilter === 'Top Gainers' ? 1 : -1;
        }
        if (a.lastTradePrice24hChangeBasis > b.lastTradePrice24hChangeBasis) {
          return activeFilter === 'Top Gainers' ? -1 : 1;
        }
        return 0;
      });
  }
  if (sortConfig !== null) {
    sortableItems = sortableItems.sort((a, b) => {
      if (sortConfig.numClicks % 3 === 0) return 0;
      if (a[sortConfig.value] < b[sortConfig.value]) {
        return sortConfig.numClicks % 3 === 1 ? -1 : 1;
      }
      if (a[sortConfig.value] > b[sortConfig.value]) {
        return sortConfig.numClicks % 3 === 1 ? 1 : -1;
      }
      return 0;
    });
  }
  return sortableItems;
};

interface Props {
  onOptionSelect: (option: Market) => void;
  searchMarketsValue: string;
}
const MarketsDropdown = ({ onOptionSelect, searchMarketsValue }: Props) => {
  const { t } = useTranslation();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { switchMarket } = useSwitchMarket();
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);
  const [activeFilter, setActiveFilter] = useState<FilterBarItemType>('All');

  const { isLoading, data: markets } = useQuery(
    QueryKeys.Markets,
    async () => {
      const markets: Market[] | null = await marketService.fetchMarkets();
      if (!markets) {
        throw new Error(
          'An error occurred while trying to fetch markets in MarketSearchDropdown component',
        );
      }
      return markets;
    },
    {
      refetchInterval: 3000,
    },
  );

  const { data: settingsData } = useFetchUserSettings(frontendSecrets?.jwt);

  const handleOptionSelect = (option: Market) => {
    switchMarket(option);
    onOptionSelect(option);
  };

  const handleSelectActiveFilter = (filter: FilterBarItemType) => () => {
    setActiveFilter(filter);
  };

  const handleSetSortConfig = (label: string, value: string) => () => {
    setSortConfig(prevState => {
      // We want to increase the numClicks if the user clicks on the same label
      const shouldIncreaseNumClicks =
        prevState === null || prevState.label === label;

      // If the user clicks on a different label, we want to set the numClicks to 1
      // If the user clicks on the same label, we want to increase the numClicks
      const newNumClicks = shouldIncreaseNumClicks
        ? (prevState?.numClicks ?? 0) + 1
        : 1;

      return {
        label,
        value,
        numClicks: newNumClicks,
      };
    });
  };

  const filterBarItemsArr = useMemo(() => {
    if (!frontendSecrets?.jwt)
      return filterBarItems.filter(item => item !== 'Favorites');
    return filterBarItems;
  }, [frontendSecrets?.jwt]);

  if (isLoading) {
    return (
      <Options>
        <NoResultsAndLoaderContainer>
          <Loading />
        </NoResultsAndLoaderContainer>
      </Options>
    );
  }

  const filteredMarkets = filterMarkets(markets, searchMarketsValue);

  if (!filteredMarkets || filteredMarkets.length === 0) {
    return (
      <Options data-cy="market-dropdown-options">
        <NoResultsAndLoaderContainer>
          {filteredMarkets?.length === 0 ? 'No Results' : null}
        </NoResultsAndLoaderContainer>
      </Options>
    );
  }

  const getClassName = (value: string, numClicks: number): string => {
    return sortConfig?.value === value &&
      sortConfig?.numClicks % 3 === numClicks
      ? 'active'
      : '';
  };

  const isMarketFavorite = (marketId): boolean => {
    if (settingsData?.favoriteMarkets) {
      return !!settingsData.favoriteMarkets[marketId];
    }
    return false;
  };

  const getFavoriteMarkets = (markets: Market[]): Market[] => {
    const favoriteMarkets = settingsData?.favoriteMarkets
      ? markets.filter(market => settingsData.favoriteMarkets[market.id])
      : [];

    const nonFavoriteMarkets = settingsData?.favoriteMarkets
      ? markets.filter(market => !settingsData.favoriteMarkets[market.id])
      : markets;

    if (activeFilter === 'Favorites') {
      return favoriteMarkets;
    }
    return [...favoriteMarkets, ...nonFavoriteMarkets];
  };

  return (
    <Container>
      <FilterBar>
        {filterBarItemsArr.map((item: FilterBarItemType) => (
          <span
            className={`${activeFilter === item ? 'active' : ''}`}
            onClick={handleSelectActiveFilter(item)}
          >
            {item}
          </span>
        ))}
      </FilterBar>
      <Options data-cy="market-dropdown-options">
        <li className="heading-row">
          {marketHeaderItems.map(item => (
            <div>
              <MarketHeaderCell
                onClick={handleSetSortConfig(item.label, item.value)}
              >
                <span>{t(item.label)}</span>
                <div className="sorting-icon">
                  <img
                    className={getClassName(item.value, 2)}
                    src={caretUp}
                    alt={'Caret up icon'}
                  />
                  <img
                    className={getClassName(item.value, 1)}
                    src={caretDown}
                    alt={'Caret down icon'}
                  />
                </div>
              </MarketHeaderCell>
            </div>
          ))}
        </li>
        <div className="markets">
          {getFavoriteMarkets(
            getSortedArray(filteredMarkets, sortConfig, activeFilter),
          ).map(option => (
            <MarketRow
              market={option}
              isConnected={!!frontendSecrets?.jwt}
              isFavorite={isMarketFavorite(option.id)}
              key={option.id}
              onOptionSelect={handleOptionSelect}
            />
          ))}
        </div>
      </Options>
    </Container>
  );
};

export default observer(MarketsDropdown);
