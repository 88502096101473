import { useState } from 'react';

import {
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { useBalanceOperationsAPI, usePaginationFetch } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';

import TableLoader from 'components/TableLoader';

import { StyledTable, TableContainer } from '../styles';
import ReferralFeesFilterOptions, {
  TReferralFeesFilter,
} from './ReferralFeesFilterOptions';
import TableHead from './ReferralFeesHead';
import ReferralFeesRow from './ReferralFeesRow';
import { useAppContext } from 'contexts/AppContext';

import { QueryKeys } from 'enums';
import { ProfileBalanceOps } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const constructRequestQueryParamsFilters = (filter: TReferralFeesFilter) => {
  const params = {};

  if (
    filter.dateRange &&
    filter.dateRange.range &&
    filter.dateRange.range[0] &&
    filter.dateRange.range[1]
  ) {
    params['start_time'] = filter.dateRange.range[0];
    params['end_time'] = filter.dateRange.range[1];
  }

  return params;
};

type Props = {
  showFilterOptions?: boolean;
};
const ReferralFeesTable = ({ showFilterOptions = false }: Props) => {
  const { isOnboarding } = useExchangeAPI();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { fetchPrivateReferralPayouts } = useBalanceOperationsAPI();
  const { t } = useTranslation();
  const [appliedFilters, setAppliedFilters] = useState<TReferralFeesFilter>({});

  const {
    isLoading,
    data: referralFees,
    pagination,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePaginationFetch<ProfileBalanceOps>({
    fetchFn: fetchPrivateReferralPayouts,
    socketSource: null,
    initialPagination: { page: 0, limit: 10 },
    currentAuthenticatedWallet: frontendSecrets?.profile?.wallet,
    isOnboarding,
    queryKey: QueryKeys.ReferralFees,
    requestQueryParams: constructRequestQueryParamsFilters(appliedFilters),
  });

  if (isLoading || totalCount === null || !frontendSecrets) {
    return (
      <>
        {showFilterOptions ? (
          <ReferralFeesFilterOptions
            appliedFilters={appliedFilters}
            onChangeFilter={setAppliedFilters}
          />
        ) : null}
        <TableContainer>
          <StyledTable>
            <TableHead />
            <TableBody>
              {!frontendSecrets ? (
                <TableRow>
                  <TableCell colSpan={100} className="no-items">
                    {t('connectYourWalletToStartTrading')}
                  </TableCell>
                </TableRow>
              ) : (
                <TableLoader />
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </>
    );
  }

  const { page, limit: rowsPerPage } = pagination;

  const sortedReferralFees = referralFees ?? [];

  return (
    <>
      {showFilterOptions ? (
        <ReferralFeesFilterOptions
          appliedFilters={appliedFilters}
          onChangeFilter={setAppliedFilters}
        />
      ) : null}
      <TableContainer useScroll={false}>
        <StyledTable stickyHeader>
          <TableHead />
          <TableBody>
            {sortedReferralFees.length > 0 ? (
              sortedReferralFees.map(item => {
                const key = `${item.id}`;
                return <ReferralFeesRow referralFee={item} key={key} />;
              })
            ) : (
              <tr>
                <td colSpan={100} className="no-items">
                  {t('youHaveNoReferralFees')}
                </td>
              </tr>
            )}
          </TableBody>
        </StyledTable>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default observer(ReferralFeesTable);
