import { theme } from 'theme/Theme';

export const Config = {
  outline: {
    background: theme.colors.shadesBackground800,
    padding: '15px',
    border: `1px solid ${theme.colors.shadesBackground700}`,
    borderHovered: `1px solid ${theme.colors.shadesBackground500}`,
    borderFocused: `1px solid ${theme.colors.shadesForeground400}`,
    borderError: `1px solid ${theme.colors.negativeForeground200}`,
    label: { color: theme.colors.white },
  },
  default: {
    background: theme.colors.shadesBackground900,
    padding: '12px 16px',
    border: `2px solid transparent`,
    borderHovered: `2px solid ${theme.colors.shadesBackground500}`,
    borderFocused: `2px solid ${theme.colors.shadesForeground400}`,
    borderError: `2px solid ${theme.colors.negativeForeground200}`,
    label: { color: theme.colors.shadesForeground100 },
  },
};
