import { memo, useEffect, useState } from 'react';

import { getTimeTillBfxDepositDeadline } from './utils';

import styled from 'styled-components';

const TimerText = styled.span`
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'ss05' on;
  font-size: 18px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: normal;
  letter-spacing: -0.5px;
`;

type Props = {
  onTimeover: () => void;
};
const Timer = ({ onTimeover }: Props) => {
  const [{ days, hours, minutes, seconds }, setTime] = useState(
    getTimeTillBfxDepositDeadline(),
  );

  useEffect(() => {
    const timeinterval = setInterval(() => {
      const t = getTimeTillBfxDepositDeadline();
      setTime(t);

      if (t.total <= 0) {
        setTime({
          total: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        clearInterval(timeinterval);
        onTimeover();
      }
    }, 1000);

    return () => clearInterval(timeinterval);
  }, []);

  return <TimerText>{`${days}d ${hours}h ${minutes}m ${seconds}s`}</TimerText>;
};

export default memo(Timer);
