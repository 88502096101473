import { memo } from 'react';

import {
  TableCell,
  TableRow,
  TableHead as TableHeadMui,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const TableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>
          {t('market')}
          {/* <img src={caretDownYellow} alt="Markets Caret Down" /> */}
        </TableCell>
        <TableCell key={1}>{t('settlementType')}</TableCell>
        <TableCell key={2}>{t('amountUsd')}</TableCell>
        <TableCell key={3} align="right">
          {t('time')}
        </TableCell>
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
