import React from 'react';

import { TransactionReceipt, Web3Provider } from '@ethersproject/providers';

import DepositInProgress from 'components/CustomToasts/DepositInProgress';

import { brand } from './brand';
import { showNotification } from './notifications';
import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';
import {
  LS_PENDING_TRANSACTIONS,
  LS_PENDING_TRANSACTIONS_TOAST_IDS,
} from 'constants/localStorageKeys';
import i18n from 'i18n/config';

import { LocalStorageTransactionTypes, NotificationType } from 'enums';
import { toast } from 'react-toastify';

export type TSavedTransaction = TransactionReceipt & {
  depositAmount: number;
  txType: LocalStorageTransactionTypes;
};

const getTransactionsFromLocalStorage = (): Record<string, any> => {
  const transactions = localStorage.getItem(LS_PENDING_TRANSACTIONS);
  return transactions ? JSON.parse(transactions) : {};
};

const saveTransactionsToLocalStorage = (
  transactionsObj: Record<string, any>,
) => {
  localStorage.setItem(
    LS_PENDING_TRANSACTIONS,
    JSON.stringify(transactionsObj),
  );
};

export const saveTxToLocalStorage = (
  tx: TransactionReceipt,
  txType: LocalStorageTransactionTypes,
  walletAddress: string,
  depositAmount: number,
) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const transactionsObj = getTransactionsFromLocalStorage();

  const addressTransactions = transactionsObj[lowercasedWalletAddress] || {};

  const txsObjUpdated = {
    ...transactionsObj,
    [lowercasedWalletAddress]: {
      ...addressTransactions,
      [tx.transactionHash]: {
        ...tx,
        txType: txType,
        depositAmount,
      },
    },
  };

  saveTransactionsToLocalStorage(txsObjUpdated);
};

export const removeTxFromLocalStorage = (
  txHash: string,
  walletAddress: string,
) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const transactionsObj = getTransactionsFromLocalStorage();

  if (
    transactionsObj[lowercasedWalletAddress] &&
    transactionsObj[lowercasedWalletAddress][txHash]
  ) {
    delete transactionsObj[lowercasedWalletAddress][txHash]; // Remove the transaction

    // If there are no more transactions for the given wallet address, remove the address key as well
    if (Object.keys(transactionsObj[lowercasedWalletAddress]).length === 0) {
      delete transactionsObj[lowercasedWalletAddress];
    }

    saveTransactionsToLocalStorage(transactionsObj); // Update local storage
  }
};

export const readTxsFromLocalStorage = (walletAddress: string) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const transactionsObj = getTransactionsFromLocalStorage();

  const addressTransactions = transactionsObj[lowercasedWalletAddress] ?? {};

  return (
    Object.keys(addressTransactions).map(key => addressTransactions[key]) ?? []
  );
};

export const checkAndProcessPendingTxs = (
  networkProvider: Web3Provider,
  walletAddress: string,
) => {
  const transactions = readTxsFromLocalStorage(walletAddress);

  transactions.forEach((tx: TSavedTransaction) => {
    processTransactionConfirmations(
      tx,
      networkProvider,
      walletAddress,
      tx.depositAmount,
      tx.txType,
    );
  });
};

export const processTransactionConfirmations = async (
  tx: TransactionReceipt,
  networkProvider: Web3Provider,
  walletAddress: string,
  depositAmount: number,
  txType: LocalStorageTransactionTypes,
  defaultToastId?: React.ReactText,
  updateConfirmations?: (confirmations: number) => void,
) => {
  if (!networkProvider) return;

  // Fetch the latest transaction receipt
  const minedTx = await networkProvider.getTransactionReceipt(
    tx.transactionHash,
  );

  if (!minedTx || minedTx.status !== 1) {
    console.log('Transaction was unsuccessful, not tracking confirmations.');
    return;
  }

  saveTxToLocalStorage(minedTx, txType, walletAddress, depositAmount);

  // Get the current block number
  let currentBlockNumber = await networkProvider.getBlockNumber();

  // Compute the number of confirmations the transaction currently has
  // Add 1 because when the transaction is mined, it has 1 confirmation
  let confirmations = currentBlockNumber - minedTx.blockNumber + 1;

  // Check if transaction already has required confirmations
  if (hasRequiredConfirmations(confirmations)) {
    removeTxFromLocalStorage(tx.transactionHash, walletAddress);
    return;
  }

  let toastId: React.ReactText;
  if (defaultToastId) {
    toastId = defaultToastId;
    // Update the content of the toast
    toast.update(defaultToastId, {
      render: (
        <DepositInProgress
          confirmations={confirmations}
          depositAmount={depositAmount}
          txhash={minedTx.transactionHash}
          type={txType}
        />
      ),
      progress: confirmations / CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
    });
  } else {
    toastId = showToast(
      depositAmount,
      confirmations,
      minedTx.transactionHash,
      txType,
    );
  }

  let asyncBlockEventListener: (
    blockNumber: number,
    stopListeningCallback: () => void,
  ) => void;

  const stopListeningCallback = () => {
    networkProvider.off('block', asyncBlockEventListener);
  };

  asyncBlockEventListener = async (blockNumber: number) => {
    await handleBlockEvent(
      blockNumber,
      minedTx,
      toastId,
      depositAmount,
      walletAddress,
      stopListeningCallback,
      txType,
      updateConfirmations,
    );
  };

  // Start listening for 'block' events
  networkProvider.on('block', asyncBlockEventListener);
};

export const hasRequiredConfirmations = (confirmations: number) => {
  if (confirmations >= CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT) {
    console.log(
      'Transaction already has CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT confirmations, not tracking further confirmations.',
    );
    return true;
  }
  return false;
};

export const savePendingTxToastIdsToLocalStorage = (
  toastId: React.ReactText,
) => {
  const pendingTxToastIds = localStorage.getItem(
    LS_PENDING_TRANSACTIONS_TOAST_IDS,
  );
  const pendingTxToastIdsArr = pendingTxToastIds
    ? JSON.parse(pendingTxToastIds)
    : [];

  pendingTxToastIdsArr.push(toastId);

  localStorage.setItem(
    LS_PENDING_TRANSACTIONS_TOAST_IDS,
    JSON.stringify(pendingTxToastIdsArr),
  );
};

export const readPendingTxToastIdsFromLocalStorage = () => {
  const pendingTxToastIds = localStorage.getItem(
    LS_PENDING_TRANSACTIONS_TOAST_IDS,
  );
  return pendingTxToastIds ? JSON.parse(pendingTxToastIds) : [];
};

export const showToast = (
  depositAmount: number,
  confirmations: number,
  txhash: string,
  txType: LocalStorageTransactionTypes,
) => {
  const toastId = toast(
    <DepositInProgress
      confirmations={confirmations}
      depositAmount={depositAmount}
      txhash={txhash}
      type={txType}
    />,
    {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: false,
      pauseOnHover: false,
      draggable: true,
      closeOnClick: false,
      progress: confirmations / CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
      theme: 'dark',
    },
  );

  savePendingTxToastIdsToLocalStorage(toastId);

  return toastId;
};

export const handleBlockEvent = async (
  blockNumber: number,
  minedTx: TransactionReceipt,
  toastId: React.ReactText,
  depositAmount: number,
  walletAddress: string,
  stopListeningCallback: () => void,
  txType: LocalStorageTransactionTypes,
  updateConfirmations?: (confirmations: number) => void,
) => {
  let confirmations = blockNumber - minedTx.blockNumber + 1;
  updateConfirmations?.(
    Math.min(confirmations, CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT),
  );

  // Update the content of the toast
  toast.update(toastId, {
    render: (
      <DepositInProgress
        confirmations={confirmations}
        depositAmount={depositAmount}
        txhash={minedTx.transactionHash}
        type={txType}
      />
    ),
    progress: confirmations / CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
  });

  // If more than CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT confirmations, stop listening to 'block' events and remove transaction from local storage
  if (confirmations >= CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT) {
    showNotification(
      {
        title: i18n.t('tokenDepositSuccessTitle', {
          depositAmount: depositAmount,
          token: brand.tokenTicker,
        }),
        description: `Your deposit of ${depositAmount} ${brand.tokenTicker} is now available.`,
        type: NotificationType.Positive,
      },
      true,
    );
    toast.dismiss(toastId);
    removeTxFromLocalStorage(minedTx.transactionHash, walletAddress);
    stopListeningCallback();
    return;
  }
};
