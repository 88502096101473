import GreetingHeader from '../../BlastAidropDashboard/components/GreetingHeader';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useUserRegisteredForBlastAirdrop } from 'queryHooks';

const GreetingAvatar = () => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { data: userRegistration } = useUserRegisteredForBlastAirdrop(
    frontendSecrets?.jwt,
  );

  return (
    <GreetingHeader
      username={userRegistration?.twitterData?.username}
      profilePicture={userRegistration?.twitterData?.profilePicture}
    />
  );
};

export default observer(GreetingAvatar);
