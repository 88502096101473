import { brandedSelect } from 'utils/brand';

import { discordUrl, twitterUrl } from 'constants/socials';

import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  padding-top: 20vh;
  text-align: center;

  span.subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors.warningForeground100};
  }

  h1.title {
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 1px;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.warningForeground100};
  }

  p.text {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors.background};
    margin: 24px 0 52px;
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const UpgradeNotice = () => {
  return (
    <Container>
      <h1 className="title">Upgrade Notice</h1>
      <p className="text">
        Our exchange is currently undergoing an upgrade, we will be back up
        shortly.
      </p>
      <span className="subtitle">
        Check our{' '}
        <a href={twitterUrl} target="_blank" rel="noreferrer">
          twitter
        </a>{' '}
        and{' '}
        <a href={discordUrl} target="_blank" rel="noreferrer">
          discord
        </a>{' '}
        for the latest updates.
      </span>
    </Container>
  );
};

export default UpgradeNotice;
