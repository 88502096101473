import { memo } from 'react';

import { useBalanceOperationsAPI, useDownloadCsv } from 'hooks';

import TransfersTable from 'components/Tables/TransfersTable';
import ClaimWithdrawableBalanceButton from 'components/Tables/TransfersTable/WithdrawableBalance/ClaimWithdrawableBalanceButton';
import { useWithdrawableBalance } from 'components/Tables/TransfersTable/WithdrawableBalance/useWithdrawableBalance';
import { TablePageContainer, TableTitle } from 'components/Tables/styles';
import Text from 'components/Text';

import Portfolio from '../../index';
import { Row } from 'theme/globalStyledComponents/row';

import { useTranslation } from 'react-i18next';

const PortfolioTransfers = () => {
  const {
    withdrawableBalance,
    setWithdrawableBalance,
    updateWithdrawableBalance,
    isFetchingWithdrawableBalance,
  } = useWithdrawableBalance();
  const { t } = useTranslation();
  const { fetchPrivateTransfers } = useBalanceOperationsAPI();
  const DownloadCSV = useDownloadCsv({
    fetchFunction: fetchPrivateTransfers,
    fileName: 'TRANSFER_HISTORY',
    avoidRows: ['id', 'opsId2', 'reason'],
    headTranslations: {
      timestamp: t('time'),
      amount: t('amountUsd'),
      opsType: t('type'),
    },
  });

  return (
    <Portfolio>
      <TablePageContainer addBackground addPadding>
        <Row className="justify-between">
          <Text variant="HEADING_H2" fontWeight="semiBold">
            Transfers
          </Text>

          <div className="flex gap-10">
            <DownloadCSV />

            <ClaimWithdrawableBalanceButton
              withdrawableBalance={withdrawableBalance}
              setWithdrawableBalance={setWithdrawableBalance}
              updateWithdrawableBalance={updateWithdrawableBalance}
            />
          </div>
        </Row>
        <TransfersTable
          isFetchingWithdrawableBalance={isFetchingWithdrawableBalance}
          includePagination={true}
          showFilterOptions
        />
      </TablePageContainer>
    </Portfolio>
  );
};

export default memo(PortfolioTransfers);
