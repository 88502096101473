import axios from 'axios';

export const BINANCE_PAY_SERVICE_BASE_URL =
  'https://binance.blastgoldcalculator.com';

export type IBinanceCheckoutData = {
  currency: string;
  totalFee: string;
  prepayId: string;
  terminalType: string;
  expireTime: number;
  qrcodeLink: string;
  qrContent: string;
  checkoutUrl: string;
  deeplink: string;
  universalUrl: string;
};

type IStartTransferResponse = {
  status: string;
  code: string;
  data: IBinanceCheckoutData;
};

export function useBinancePayApi() {
  const startBinanceTransfer = async (
    walletAddress: string,
    amount: number,
  ) => {
    try {
      const res: { data: IStartTransferResponse } = await axios.post(
        `${BINANCE_PAY_SERVICE_BASE_URL}/start_transfer`,
        {
          wallet_address: walletAddress,
          amount,
        },
      );

      if (res.data.data.checkoutUrl) {
        window.open(res.data.data.checkoutUrl, '_blank');
        return { status: true, checkoutData: res.data.data };
      }

      return { status: false };
    } catch (err) {
      console.error(
        'An error occured while fetching binance pay checkout url : ',
        err,
      );
      return { status: false };
    }
  };

  return {
    startBinanceTransfer,
  } as const;
}

export type BinancePayApi = ReturnType<typeof useBinancePayApi>;
