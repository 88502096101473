import { useActiveWeb3React } from 'hooks';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import ConfirmationModal from 'components/ConfirmationModal';

import { Modals } from 'constants/modals';
import { mixpanelService } from 'service/mixpanelService';

import { useTranslation } from 'react-i18next';

export const ConfirmAllOrdersCancelModal = () => {
  const { cancelAllOrders } = useOrdersAPI();
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();

  const handleSubmit = async () => {
    await cancelAllOrders();

    if (!account) return;
    mixpanelService.cancelOrdersAllSuccess(account);
  };

  return (
    <ConfirmationModal
      name={Modals.confirmAllOrdersCancelModal}
      title="Confirm cancel all?"
      description={
        'Are you sure that you would like to cancel all your orders for all markets?'
      }
      onButtonRightPress={handleSubmit}
      buttonLeftTitle={t('dontCancel')}
      buttonRightProps={{
        colorVariant: 'primaryRed',
      }}
      buttonRightTitle={t('confirm')}
    />
  );
};
