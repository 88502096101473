import {
  getCurrencyFromTradingPair,
  removeTrailingDigitsFromTradingPair,
} from 'utils';

import Badge from 'components/Badge';
import Text from 'components/Text';

import { TitleRow } from './style';
import { useAppContext } from 'contexts/AppContext';

import { CurrencyKind } from 'interfaces';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const {
    store: {
      appState: { defaultCurrencyKind },
      markets: { selectedMarketId },
    },
  } = useAppContext();
  const { t } = useTranslation();

  return (
    <TitleRow>
      <Text flexed variant="BODY_XS" color="shadesForeground200">
        {t('price')} <Badge bgColor="shadesBackground700">USD</Badge>
      </Text>

      <Text
        className="justify-end"
        flexed
        variant="BODY_XS"
        color="shadesForeground200"
      >
        {t('amount')}
        <Badge bgColor="shadesBackground700">
          {defaultCurrencyKind === CurrencyKind.BASE
            ? getCurrencyFromTradingPair(
                removeTrailingDigitsFromTradingPair(selectedMarketId),
              )
            : 'USD'}
        </Badge>
      </Text>

      <Text
        className="justify-end"
        flexed
        variant="BODY_XS"
        color="shadesForeground200"
      >
        {t('time')}
      </Text>
    </TitleRow>
  );
};

export default observer(Header);
