import { brand } from 'utils/brand';

export const EXCHANGE = brand.appShortName;

export const ConfigurationData = {
  supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D'],
  exchanges: [
    {
      value: EXCHANGE,
      name: 'Strip Finance',
      desc: 'Strip Finance',
    },
  ],
  symbols_types: [
    {
      name: 'crypto',
      value: 'crypto',
    },
  ],
};
