import { useEffect } from 'react';

import { useActiveWeb3React } from 'hooks';
import useKey, { Key } from 'hooks/useKey';

import Button from 'components/Button/button';
import Modal from 'components/Modal';
import TabSelector from 'components/TabSelector';
import Text from 'components/Text';

import RequestStatus from './RequestStatus';
import StakeInputs from './StakeInputs';
import UnstakeInputs from './UnstakeInputs';
import VaultStats from './VaultStats';
import { AmendStep } from './enums';
import { Container, StyledLoading } from './styles';
import useAmend from './useAmend';
import { getActionButtonState } from './utils';
import { Modals } from 'constants/modals';
import { getCurrentPps } from 'pages/Vaults/utils';
import { mixpanelService } from 'service/mixpanelService';
import { theme } from 'theme/Theme';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export enum AmendType {
  Stake,
  UnStake,
}

const getHeading = (type: AmendType, t) => {
  switch (type) {
    case AmendType.Stake:
      return t('addLiquidity');
    case AmendType.UnStake:
      return t('removeLiquidity');
    default:
      return '';
  }
};

type Props = {
  defaultAmendType: AmendType;
  poolId: number;
};
const AmendLiquidityModal = ({ defaultAmendType, poolId }: Props) => {
  const { account } = useActiveWeb3React();
  const { t } = useTranslation();
  const {
    onStake,
    onUnstake,
    currentStep,
    amendType,
    isPendingTxSign,
    isProcessingRequest,
    isApprovingUsdt,
    stakeAmount,
    userShares,
    unStakeAmount,
    vaultData,
    setAmendType,
    isLoadingVaultData,
    isErrorVaultData,
    isAmendTypeStake,
    isAmendTypeUnstake,
    stakeResult,
    setStakeAmount,
    availableBalance,
    refetchVaultData,
    isRefetchingVaultData,
    setUnStakeAmount,
    unstakeResult,
    isLoading,
  } = useAmend({ defaultAmendType, poolId });

  const { label: buttonLabel, isDisabled: isButtonDisabled } =
    getActionButtonState(
      currentStep,
      amendType,
      isPendingTxSign,
      isProcessingRequest,
      isApprovingUsdt,
      stakeAmount,
      unStakeAmount,
      userShares,
      availableBalance,
      t,
    );

  useEffect(() => {
    if (!account) return;
    mixpanelService.elixirVaultsNavigate(account, defaultAmendType);
  }, [account, defaultAmendType]);

  const pricePerShare = vaultData ? getCurrentPps(vaultData) : 0;

  useKey(Key.Enter, () => {
    if (isButtonDisabled) return;

    isAmendTypeStake ? onStake() : onUnstake();
  });

  return (
    <Modal
      name={Modals.amendLiquidityModal}
      showHeader={false}
      showCloseIcon={false}
      size="auto"
      data-gtmid={`amend-liquidity-modal`}
    >
      <Container>
        <Text variant="BODY_L" className="align-center" fontWeight="semiBold">
          {getHeading(amendType, t)}
        </Text>

        <TabSelector
          disabled={
            isPendingTxSign ||
            isProcessingRequest ||
            [
              AmendStep.STAKE_FAILED,
              AmendStep.STAKE_SUCCESS,
              AmendStep.UNSTAKE_FAILED,
              AmendStep.UNSTAKE_SUCCESS,
            ].includes(currentStep) // Dont let user switch if request has been sent.
          }
          buttonOptions={[
            {
              label: t('stake'),
              value: AmendType.Stake,
              bgColor: theme.colors.shadesBackground900,
            },
            {
              label: t('unstake'),
              value: AmendType.UnStake,
              bgColor: theme.colors.shadesBackground900,
            },
          ]}
          handleOptionSelect={setAmendType}
          initialSelectedOption={amendType}
        />

        {(isLoadingVaultData || isLoading) && !isErrorVaultData ? (
          <StyledLoading />
        ) : (
          <>
            {[
              AmendStep.ENABLE_USDT,
              AmendStep.STAKE,
              AmendStep.UNSTAKE,
            ].includes(currentStep) ? (
              <>
                <VaultStats
                  amendType={amendType}
                  maxRedeemableShares={userShares}
                  pricePerShare={pricePerShare}
                  totalIssuedShares={vaultData?.activeAmount}
                  totalVolumeUsd={vaultData?.tvlUsd}
                  isLoading={isLoadingVaultData}
                  tokenPair={vaultData?.tokenPair}
                  unstakeAmount={unStakeAmount}
                />

                {isAmendTypeStake ? (
                  <StakeInputs
                    amount={stakeAmount}
                    onAmountChange={setStakeAmount}
                    availableBalance={availableBalance}
                    pricePerShare={pricePerShare}
                    onRefetchPricePerShare={() => refetchVaultData()}
                    isRefetching={isRefetchingVaultData}
                    disabled={isProcessingRequest || isPendingTxSign}
                  />
                ) : null}

                {isAmendTypeUnstake ? (
                  <UnstakeInputs
                    amount={unStakeAmount}
                    onAmountChange={setUnStakeAmount}
                    maxRedeemableShares={userShares}
                    pricePerShare={pricePerShare}
                    disabled={isProcessingRequest || isPendingTxSign}
                  />
                ) : null}
              </>
            ) : null}

            {[AmendStep.STAKE_SUCCESS, AmendStep.STAKE_FAILED].includes(
              currentStep,
            ) ? (
              <RequestStatus
                amount={stakeAmount || undefined}
                tokenPair={vaultData?.tokenPair || '--'}
                amendType={AmendType.Stake}
                initialSpotState={stakeResult?.spotState}
                requestSent={stakeResult?.requestSent}
              />
            ) : null}

            {[AmendStep.UNSTAKE_SUCCESS, AmendStep.UNSTAKE_FAILED].includes(
              currentStep,
            ) ? (
              <RequestStatus
                amount={unStakeAmount || undefined}
                tokenPair={vaultData?.tokenPair || '--'}
                amendType={AmendType.UnStake}
                initialSpotState={unstakeResult?.spotState}
                requestSent={unstakeResult?.requestSent}
              />
            ) : null}

            <Button
              disabled={isButtonDisabled}
              colorVariant={
                // @todo: clean this up
                [AmendStep.STAKE_SUCCESS, AmendStep.UNSTAKE_SUCCESS].includes(
                  currentStep,
                )
                  ? 'primaryGreen'
                  : [AmendStep.STAKE_FAILED, AmendStep.UNSTAKE_FAILED].includes(
                      currentStep,
                    )
                  ? 'primaryRed'
                  : isAmendTypeUnstake
                  ? 'primaryRed'
                  : 'primaryGreen'
              }
              sizeVariant="S"
              onClick={isAmendTypeStake ? onStake : onUnstake}
              data-gtmid={`button-${
                isAmendTypeStake ? 'stake' : 'unstake'
              }-vault`}
            >
              {buttonLabel}
            </Button>
          </>
        )}
      </Container>
    </Modal>
  );
};

export default observer(AmendLiquidityModal);
