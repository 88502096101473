import { Icon } from 'components';

import checkCircle from 'assets/icons/check-white-circle-positive.svg';

import styled, { keyframes } from 'styled-components';

type Props = {
  currentStepIndex: number;
  stepIndex: number;
};

const StyledEmptyCircle = styled.div`
  z-index: 1;
  position: relative;
  height: 18px;
  width: 18px;
  border: 1.2px solid ${({ theme }) => theme.colors.shadesBackground500};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
`;

const blinkAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.1; }
  100% { opacity: 1; }
`;

const StyledCurrentStep = styled.div`
  height: 18px;
  width: 18px;
  border: 1.2px solid ${({ theme }) => theme.colors.positiveForeground300};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  z-index: 1;
  position: relative;

  > div {
    width: 8px;
    border-radius: 100px;
    height: 8px;
    background: ${({ theme }) => theme.colors.positiveForeground300};
    animation: ${blinkAnimation} 1s infinite;
  }
`;

const StepIcon = ({ stepIndex, currentStepIndex }: Props) => {
  const isChecked = currentStepIndex > stepIndex;
  const isCurrentStep = currentStepIndex === stepIndex;

  if (isChecked) return <Icon src={checkCircle} size="ML" />;

  if (isCurrentStep)
    return (
      <StyledCurrentStep>
        <div />
      </StyledCurrentStep>
    );

  return <StyledEmptyCircle></StyledEmptyCircle>;
};

export default StepIcon;
