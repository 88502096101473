import { memo, useEffect } from 'react';

import { appLangToParticleAuthLangCode } from 'utils/particleAuth';

import Button from 'components/Button/button';
import { SelectDropdown } from 'components/SelectDropdown.tsx';

import translateIcon from 'assets/icons/translate.svg';

import { socialconnect } from 'connectors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Languages =
  | 'English'
  | 'Korean'
  | 'Chinese'
  | 'Japanese'
  | 'Traditional Chinese'
  | 'Spanish';

const languagesOptions: Languages[] = [
  'English',
  'Korean',
  'Chinese',
  'Japanese',
  'Traditional Chinese',
  'Spanish',
];

export const languageToCode = {
  English: 'en',
  Korean: 'ko',
  Chinese: 'zh',
  Japanese: 'ja',
  Spanish: 'es',
  'Traditional Chinese': 'zh-Hant',
};

const languageToFlag = (language: Languages) => {
  switch (language) {
    case 'English':
      return '🇺🇸';
    case 'Korean':
      return '🇰🇷';
    case 'Chinese':
      return '🇨🇳';
    case 'Japanese':
      return '🇯🇵';
    case 'Traditional Chinese':
      return '🇹🇼';
    case 'Spanish':
      return '🇪🇸';
    default:
      return '🇺🇸';
  }
};

type Props = {
  selected: boolean;
};
const LanguageTextParserContainer = styled.span<Props>`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.shadesForeground200};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.shadesBackground700 : 'transparent'};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  border-radius: 8px;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.shadesBackground700};
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const languageTextParser = (value: Languages, selected: boolean) => {
  return (
    <LanguageTextParserContainer selected={selected}>
      {languageToFlag(value)}
      <span className="ml-5">{value}</span>
    </LanguageTextParserContainer>
  );
};

const LanguagesDropdown = () => {
  const { i18n } = useTranslation();

  const handleLanguagesOptionSelect = (option: Languages) => {
    const code = languageToCode[option];
    i18n.changeLanguage(code);
    socialconnect.setLanguage(appLangToParticleAuthLangCode[code]);
  };

  return (
    <div>
      <SelectDropdown
        options={languagesOptions}
        onOptionSelect={handleLanguagesOptionSelect}
        displayTextParser={val =>
          languageTextParser(val, i18n.language === languageToCode[val])
        }
        gtmIdGenerator={option => `option-language-${languageToCode[option]}`}
        InputElement={
          <Button rightIcon={translateIcon} iconSize="L" iconOnly={true} />
        }
        customStyles={{
          borderRadius: '8px',
          padding: '8px',
          liBorderRadius: '8px',
          top: '40px',
          liPadding: '0px',
          liHoveredBackgroundColor: 'transparent',
        }}
      />
    </div>
  );
};

export default memo(LanguagesDropdown);
