import { TextVariant } from 'components/Text/variant';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .contained-container {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.shadesBackground700};
  }

  .children-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 2px;

    font-size: 11px;
    line-height: normal;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

type ContainerProps = {
  showError?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
  showSwapCurrency?: boolean;
  isDisabledTheSameAsBG: boolean;
  partiallyDisabled: boolean;
  isErrorAWarning: boolean;
};
export const InputContainer = styled.div<ContainerProps>`
  background: ${({ theme }) => theme.colors.shadesBackground900};
  border-radius: 8px;
  padding: 9px; // 10 - 1 for border
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;

  border: 1px solid transparent;

  .error-icon {
    height: 11px;
    width: 11px;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};
  }

  .mb-4 {
    margin-bottom: 4px;
  }

  .gap-8 {
    gap: 8px;
  }

  .value-content {
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    /* @media (min-width: ${({ theme }) => theme.screenSizes.xxl}px) {
      height: 24px;
    } */

    input.value {
      min-width: 0;
      width: 100%;
      padding-left: 0;
      flex: 1;
      background: transparent;
      border: 0;
      outline: none;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
      appearance: textfield;
      font-family: ${({ theme }) => theme.fontFamilyNumbers};
      font-variant: tabular-nums;
      color: ${({ theme }) => theme.colors.white};

      font-size: ${TextVariant['BODY_M'].fontSize};
      line-height: ${TextVariant['BODY_M'].lineHeight};
      letter-spacing: ${TextVariant['BODY_M'].letterSpacing};

      ::placeholder {
        color: ${({ theme }) => theme.colors.shadesForeground200};
      }
    }

    .currency-container {
      cursor: ${({ showSwapCurrency }) =>
        showSwapCurrency ? 'pointer' : 'cursor'};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: ${TextVariant['BODY_M'].fontSize};
      line-height: ${TextVariant['BODY_M'].lineHeight};
      letter-spacing: ${TextVariant['BODY_M'].letterSpacing};
      color: ${({ theme }) => theme.colors.shadesForeground200};

      .currency-icon {
        width: 16px;
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .right-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-end;
    align-self: flex-end;
  }

  .badge {
    cursor: pointer;
    display: flex;
    padding: 3px 5px;
    gap: 10px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
    margin: -2px 0px;
    user-select: none;

    :hover {
      opacity: 0.8;
    }
  }

  .mid-badge {
    background-color: ${({ theme }) => theme.colors.shadesBackground700};
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      &.container {
        border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
      }
    `};

  ${({ showError, isErrorAWarning }) =>
    showError &&
    css`
      &.container {
        border: 1px solid
          ${({ theme }) =>
            theme.colors[
              isErrorAWarning ? 'warningForeground300' : 'negativeForeground200'
            ]};
      }
    `};

  ${({ isDisabled, isDisabledTheSameAsBG, partiallyDisabled }) =>
    isDisabled &&
    css`
      &.container {
        pointer-events: none;

        ${!partiallyDisabled &&
        css`
          background: ${({ theme }) =>
            isDisabledTheSameAsBG
              ? theme.colors.shadesBackground900
              : theme.colors.shadesBackground700};
          opacity: ${isDisabledTheSameAsBG ? 0.8 : 1};
          border: 1px solid ${({ theme }) => theme.colors.transparent};
        `}
      }

      ${!partiallyDisabled &&
      css`
        .label,
        .value-content input.value,
        .currency-container .currency {
          color: ${({ theme }) => theme.colors.shadesForeground400};
        }

        .currency-icon {
          opacity: 0.5;
        }

        .max-badge {
          border: 1px solid ${({ theme }) => theme.colors.shadesBackground600};
          color: ${({ theme }) => theme.colors.shadesForeground400};
        }
      `}
    `}
`;

export const ErrorMessage = styled.div<{ isErrorAWarning: boolean }>`
  color: ${({ theme, isErrorAWarning }) =>
    theme.colors[
      isErrorAWarning ? 'warningForeground300' : 'negativeForeground200'
    ]};
  font-size: 11px;
  margin-top: 5px;
  margin-bottom: 3px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
