import { Dispatch, SetStateAction, memo } from 'react';

import {
  getChainIconAndLabel,
  getAssetIconAndLabel,
  getTokenOptionsForChain,
} from './helpers';
import Selector from './selector';
import { Container } from './styles';

import { UniswapSwapTokens, Chain } from 'enums';

type Props = {
  selectedChain: Chain;
  setSelectedChain: Dispatch<SetStateAction<Chain>>;
  selectedToken: UniswapSwapTokens;
  onSetSelectedToken: (token: UniswapSwapTokens) => void;
  disableToken: boolean;
};

const ChainAndAssetSelector = ({
  selectedChain,
  setSelectedChain,
  selectedToken,
  onSetSelectedToken,
  disableToken,
}: Props) => {
  return (
    <Container>
      <Selector
        title={'Chain'}
        value={selectedChain}
        getLabelAndIcon={getChainIconAndLabel}
        onOptionSelect={setSelectedChain}
        options={[]}
        disabled
        disabledLabel="Multichain Soon"
      />
      <Selector
        title={'Token'}
        value={selectedToken}
        disabled={disableToken}
        getLabelAndIcon={getAssetIconAndLabel}
        onOptionSelect={onSetSelectedToken}
        options={disableToken ? [] : getTokenOptionsForChain(selectedChain)}
        gtmIdGenerator={option => `swap-token-option-${option.toLowerCase()}`}
      />
    </Container>
  );
};

export default memo(ChainAndAssetSelector);
