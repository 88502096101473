import { brandedSelect } from 'utils/brand';

import { Row } from 'theme/globalStyledComponents/row';

import styled from 'styled-components';

const Container = styled(Row)<Props>`
  height: 8px;
  width: 100%;
  flex: 1;
  border-radius: 100px;
  overflow: hidden;

  .slanted-border {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 20px;
    background-color: ${({ theme }) => theme.colors.shadesBackground800};
    transform: skewY(-45deg);
    z-index: 2;
  }

  .right {
    right: -10px;
  }

  .left {
    left: -10px;
  }

  > div {
    display: flex;
    position: relative;
    flex: 1;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.shadesBackground700};
  }

  > div:nth-child(-n + ${({ progress }) => progress}) {
    background-color: ${({ theme }) =>
      brandedSelect({
        rabbitx: theme.colors.positiveForeground200,
        bfx: theme.colors.main200,
      })};
  }

  > div:first-child {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  > div:last-child {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  @keyframes blink {
    0% {
      background-color: ${({ theme }) =>
        brandedSelect({
          rabbitx: theme.colors.positiveForeground200,
          bfx: theme.colors.main200,
        })};
    }

    50% {
      background-color: ${({ theme }) => theme.colors.shadesBackground700};
    }

    100% {
      background-color: ${({ theme }) =>
        brandedSelect({
          rabbitx: theme.colors.positiveForeground200,
          bfx: theme.colors.main200,
        })};
    }
  }

  .blink {
    animation: blink 1s infinite;
  }
`;

type Props = { progress: number; animateLastStep: boolean };
const StepIndicator = (props: Props) => {
  return (
    <Container {...props}>
      <div>
        <div className="slanted-border right" />
      </div>
      <div>
        <div className="slanted-border left" />
        <div className="slanted-border right" />
      </div>
      <div>
        <div className="slanted-border left" />
        <div className="slanted-border right" />
      </div>
      <div className={`${props.animateLastStep ? 'blink' : ''}`}>
        <div className={`slanted-border left `} />
      </div>
    </Container>
  );
};

export default StepIndicator;
