import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';

import { BigNumber } from 'ethers';

export const defaultGas = '2000000';
export const estimationRate = 150;

export function calcGas(estimation: BigNumber | undefined) {
  if (estimation === undefined) return defaultGas;

  return estimation.mul(estimationRate).div(100);
}

/**
 * Returns the checksummed address if the address is valid, otherwise returns false
 */
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library?.getSigner(account).connectUnchecked();
}

/**
 * Gets a signer if account is available, otherwise just returns a web3 provider
 */
function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

/**
 * Connects to a deployed contract instance using a signer if an account is provided, or else using a provider
 */
export function getContract<T>(
  contractFactory: any,
  address: string,
  library: Web3Provider,
  account?: string,
): T {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return contractFactory.connect(
    address,
    getProviderOrSigner(library, account),
  );
}
