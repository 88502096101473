import { useEffect } from 'react';

import { brandedSelect } from 'utils/brand';

import { getMarketFromPath, useSwitchMarket } from 'hooks/useSwitchMarket';
import useWindowDimensions from 'hooks/useWindowDimensions';

import Banner from 'components/Banner';

import { AccountAndContractDetailsTabs } from './components/AccountAndContractDetailsTabs/AccountAndContractDetailsTabs';
import { AccountStats } from './components/AccountStats/AccountStats';
import BuySell from './components/BuySell/BuySell';
import ContractDetails from './components/ContractDetails/ContractDetails';
import MarketBar from './components/MarketBar/MarketBar';
import OrderBook from './components/OrderBook/OrderBook';
import RecentTrades from './components/RecentTrades/RecentTrades';
import TradingChartAreaTabs from './components/TradingChartAreaTabs';
import TradingHistoryTabs, {
  EXTEND_HISTORY_TABS_THRESHOLD_HEIGHT,
  ORDERBOOK_TRADES_TAB_VIEW_HEIGHT_THRESHOLD,
} from './components/TradingHistoryTabs/TradingHistoryTabs';
import { useAppContext } from 'contexts/AppContext';
import { defaultMarket } from 'mockData/markets';
import NewsFooter from 'pages/Layout/Footer';

import { Markets, UserSettings } from 'enums';
import { observer } from 'mobx-react';
import { useIdleTimer } from 'react-idle-timer';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

const TradePageStyled = styled.div`
  margin: 0 10px;
  display: flex;
  gap: 5px;
  flex: 1;
  height: calc(100vh-54px);
  flex-direction: column;

  .left-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    gap: 5px;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    width: 310px;
    gap: 5px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      width: 349px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 5px;
    flex-basis: 0;
    flex-grow: 1;

    ::-webkit-scrollbar {
      display: none;
    }

    .responsive-row {
      display: flex;
      flex-direction: column;
      flex: 1;

      @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
        flex-direction: row;
        gap: 5px;
      }

      @media (min-height: ${EXTEND_HISTORY_TABS_THRESHOLD_HEIGHT}px) {
        flex: 1;
        flex-grow: 3;
      }
    }
  }
`;

const MarketSelectAndCharts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

const GraphAndOrderbook = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 5px;
  flex: 1;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    min-height: 515px;
    max-height: 515px;
  }

  @media (min-height: ${ORDERBOOK_TRADES_TAB_VIEW_HEIGHT_THRESHOLD + 1}px) {
    min-height: initial;
    max-height: initial;

    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      max-height: calc(100vh - 312px);
      flex-basis: calc(100vh - 312px);

      min-height: 550px;
      margin-top: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    flex-direction: row;

    @media (min-height: ${EXTEND_HISTORY_TABS_THRESHOLD_HEIGHT}px) {
      flex-basis: initial;
      min-height: 60vh;
    }
  }
`;

const OrderBookAndRecentTrades = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;
  padding: 10px 0px;
  min-height: 495px;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    min-height: initial;
  }
`;

export const REWARD_MARKET_CONFIG = brandedSelect({
  bfx: {
    // [Markets.PAC_USD]: 8,
    // [Markets.MOTHER_USD]: 4,
    // [Markets.NOT_USD]: 4,
    // [Markets.FLOKI1000_USD]: 4,
    // [Markets.TAIKO_USD]: 4,
    // [Markets.BONK1000_USD]: 4,
  },
  rabbitx: {},
});

export const REWARD_BADGE_ENABLED_MARKETS = Object.keys(REWARD_MARKET_CONFIG);

export const MARKETS_WITH_WARNING_BANNER = [
  Markets.PAC_USD,
  Markets.MAGA_USD,
  Markets.TRUMP_USD,
  Markets.MOG1000_USD,
  Markets.MOTHER_USD,
];

export const PAC_TOKEN_MIGRATION_WARNING = [
  Markets.PAC_USD,
  Markets.MAGA_USD,
  Markets.TRUMP_USD,
  Markets.MOG1000_USD,
  Markets.MOTHER_USD,
];

const Trade = () => {
  const { width, height } = useWindowDimensions();
  const theme = useTheme();
  const { pair: pathPair } = useParams();
  const {
    store: {
      markets: {
        markets,
        getDefaultMarket,
        avoidPairEffect,
        setAvoidPairEffect,
        selectedMarketId,
      },
    },
  } = useAppContext();
  const { switchMarketById, switchMarket } = useSwitchMarket();

  const isSmallerThanXL = width <= (theme as any).screenSizes.xl;
  const isSmallerThanDefault = width < (theme as any).screenSizes.default;

  useEffect(() => {
    if (!markets) return;

    // For in-app switches, we switch the pair in url and also do setSelectedById, so we dont want to watch for url changes, but reset the avoid flag to future changes
    if (avoidPairEffect) {
      setAvoidPairEffect(false);
      return;
    }

    const switchToDefaultMarket = () => {
      const lastSavedDefault = getDefaultMarket(markets);
      if (lastSavedDefault) {
        switchMarket(lastSavedDefault);
      } else {
        switchMarketById(defaultMarket.id);
      }
    };

    if (pathPair) {
      const marketFromPath = getMarketFromPath(markets);

      if (marketFromPath) {
        switchMarket(marketFromPath);
      } else {
        switchToDefaultMarket();
      }
    } else {
      switchToDefaultMarket();
    }
  }, [pathPair, markets]);

  const onIdle = () => {
    window.location.reload();
  };

  const isHeightOverThreshold = height >= EXTEND_HISTORY_TABS_THRESHOLD_HEIGHT;

  const shouldShowOrderbookAndRecentTradesInTabView =
    height <= ORDERBOOK_TRADES_TAB_VIEW_HEIGHT_THRESHOLD ||
    width < (theme as any).screenSizes.xl;

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 60, // 1 hour idle time to reload
  });

  return (
    <TradePageStyled>
      {MARKETS_WITH_WARNING_BANNER.includes(selectedMarketId as Markets) ? (
        <Banner
          closeFlagSettingKey={
            UserSettings.IS_MARKET_TRADE_WARNING_BANNER_DISMISSED
          }
        >
          {/* Temporary Warning */}
          {selectedMarketId === Markets.PAC_USD
            ? `Due to $PAC's imminent token migration, the $PAC pair may experience extreme volatility and low liquidity. DYOR and trade responsibly.`
            : `This perpetual contract uses onchain AMMs for the underlying oracle price, beware of high volatility and limited liquidity. We recommend trading using no more than 5x leverage to lower liquidation risks.`}
        </Banner>
      ) : null}
      <MarketBar />
      <div className="content">
        <div className="responsive-row">
          <div className="left-content">
            <GraphAndOrderbook>
              <MarketSelectAndCharts>
                <TradingChartAreaTabs />
              </MarketSelectAndCharts>
              <OrderBookAndRecentTrades>
                {shouldShowOrderbookAndRecentTradesInTabView ? (
                  <OrderBook showRecentTrades={true} />
                ) : (
                  <>
                    <OrderBook showRecentTrades={false} />
                    <RecentTrades />
                  </>
                )}
              </OrderBookAndRecentTrades>
            </GraphAndOrderbook>

            {isSmallerThanXL ? (
              <>
                <BuySell showConfirmation={true} className="p-15" />
                <AccountAndContractDetailsTabs />
              </>
            ) : null}

            {!isHeightOverThreshold ? (
              <TradingHistoryTabs isRowCollapsible={isSmallerThanDefault} />
            ) : null}
          </div>

          {!isSmallerThanXL ? (
            <div className="right-content">
              <BuySell showConfirmation={true} className="p-15" />
              <AccountStats isPadded />
              <ContractDetails isPadded />
            </div>
          ) : null}
        </div>
        {isHeightOverThreshold ? (
          <TradingHistoryTabs isRowCollapsible={isSmallerThanDefault} />
        ) : null}
        <NewsFooter isPadded={false} />
      </div>
    </TradePageStyled>
  );
};

export default observer(Trade);
