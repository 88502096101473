import { memo } from 'react';

import { PlatformVaultsDataWithHoldings } from 'hooks';

import VaultGrid from './Grid';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { useTranslation } from 'react-i18next';

type Props = {
  vaults: PlatformVaultsDataWithHoldings[] | undefined;
  isLoading: boolean;
  isError: boolean;
};
const VaultsList = ({ vaults, isLoading, isError }: Props) => {
  const { t } = useTranslation();
  // @TODO: Implement filtering
  const filteredVaults = vaults;
  const initialVaultRouterAddress = vaults?.[0].wallet;
  return (
    <>
      {isError ? (
        <ColoredText color={Colors.Danger}>
          {t('errorFetchingVaultsPleaseRefresh')}
        </ColoredText>
      ) : (
        <VaultGrid
          vaults={filteredVaults}
          isLoading={isLoading}
          initialVaultRouterAddress={initialVaultRouterAddress}
        />
      )}

      {/* <VaultActivityTable isLoadingVaults={isLoading} /> */}
    </>
  );
};

export default memo(VaultsList);
