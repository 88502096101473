import { useCallback, useEffect, useState } from 'react';

import { bigNumberToFloat } from 'utils';

import { useActiveWeb3React } from 'hooks';
import { useDepositsAPI } from 'hooks/useDepositsAPI';

import { useAppContext } from 'contexts/AppContext';

/**
 * This hook is used to fetch the withdrawable balance from the L1 smart contract
 * @returns object containing the withdrawable balance, a function to update the withdrawable balance,
 * and a boolean indicating if the withdrawable balance is being fetched
 */
export const useWithdrawableBalance = () => {
  const { account } = useActiveWeb3React();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const { getWithdrawableBalance, USDTDecimals } = useDepositsAPI();
  const [withdrawableBalance, setWithdrawableBalance] = useState<number | null>(
    null,
  );
  const [isFetchingWithdrawableBalance, setIsFetchingWithdrawableBalance] =
    useState<boolean>(true);

  /**
   * Fetches the withdrawable balance from the L1 smart contract for the given account
   * @param account wallet address
   * @returns withdrawable balance
   */
  const fetchWithdrawableBalance = useCallback(
    async (account: string): Promise<number> => {
      const withdrawableBalanceBN = await getWithdrawableBalance(account);

      return bigNumberToFloat(withdrawableBalanceBN, USDTDecimals);
    },
    [getWithdrawableBalance, USDTDecimals],
  );

  useEffect(() => {
    setWithdrawableBalance(null);
    setIsFetchingWithdrawableBalance(true);
  }, [account, frontendSecrets?.jwt]);

  /**
   * Fetches the withdrawable balance from the L1 smart contract and updates the state
   */
  const updateWithdrawableBalance = useCallback(async () => {
    if (!account || !frontendSecrets?.jwt) return;

    try {
      const newWithdrawableBalance = await fetchWithdrawableBalance(account);

      // If the withdrawable balance is the same as the one in the state, do nothing
      if (newWithdrawableBalance === withdrawableBalance) {
        return;
      }
      setWithdrawableBalance(newWithdrawableBalance);
    } catch (e) {
      console.error(e);
      setWithdrawableBalance(0);
    } finally {
      setIsFetchingWithdrawableBalance(false);
    }
  }, [
    account,
    frontendSecrets?.jwt,
    withdrawableBalance,
    setWithdrawableBalance,
    fetchWithdrawableBalance,
  ]);

  return {
    withdrawableBalance,
    isFetchingWithdrawableBalance,
    setWithdrawableBalance,
    updateWithdrawableBalance,
  };
};
