import { AbstractConnector } from '@web3-react/abstract-connector';

import metamaskLogo from 'assets/assetLogos/metamask.svg';
import walletconnectLogo from 'assets/assetLogos/wallet-connect-white.svg';
import okxLogo from 'assets/icons/okx.svg';
import rabbyLogo from 'assets/icons/rabby.svg';
import scAppleLogo from 'assets/icons/socials/apple-dark.svg';
import scDiscordLogo from 'assets/icons/socials/discord24.svg';
import scGoogleLogo from 'assets/icons/socials/google24.svg';
import vaultLogo from 'assets/icons/vault.svg';
import { ParticleAuthConnector } from 'connectors/particleAuthConnector';
import { WalletConnectV2Connector } from 'connectors/walletconnectV2Connector';

import { ConnectorNames } from 'enums';

export const getMethodLabelAndIcon = ({
  connector,
  isVault,
  isInjectedMetamask,
}: {
  connector: ConnectorNames | AbstractConnector | undefined;
  isVault?: boolean;
  isInjectedMetamask?: boolean;
}) => {
  if (isVault)
    return {
      label: isInjectedMetamask ? 'Vault' : 'Install Metamask',
      icon: vaultLogo,
    };

  if (
    connector === ConnectorNames.WalletConnect ||
    connector instanceof WalletConnectV2Connector
  ) {
    return { label: 'Wallet Connect', icon: walletconnectLogo };
  }
  if (
    connector === ConnectorNames.SocialConnect ||
    connector instanceof ParticleAuthConnector
  )
    return {
      label: 'Social Connect',
      icon: [scGoogleLogo, scAppleLogo, scDiscordLogo],
      description: 'Powered by Particle Wallet',
    };

  return {
    label: isInjectedMetamask ? 'Wallets' : 'Install Metamask',
    icon: [metamaskLogo, okxLogo, rabbyLogo],
  };
};
