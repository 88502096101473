import { useState } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import { showNotification } from 'utils';
import { brand } from 'utils/brand';

import { useElixirContractsAPI } from 'hooks';
import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import WithdrawalRow from './WithdrawalRow';
import { useAppContext } from 'contexts/AppContext';
import { VaultManager } from 'enums/vaultManager';
import {
  useFetchVaultsPendingWalletShares,
  useFetchVaultsRouterUsdtBalances,
} from 'queryHooks/elixirVaults';

import { NotificationType, QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

const Seperator = styled.div`
  height: 1.5px;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  display: flex;
  width: 100%;
`;

type Props = {
  vaults: VaultWithContractInfo[] | undefined;
  vaultManager: VaultManager;
  isLoadingVaults?: boolean;
};

const WithdrawalRows = ({
  vaults = [],
  vaultManager,
  isLoadingVaults = false,
}: Props) => {
  const { t } = useTranslation();
  const { claim } = useElixirContractsAPI();
  const [isClaiming, setIsClaiming] = useState(false);
  const queryClient = useQueryClient();

  const vaultsPoolIds = vaults?.map(vault => vault.productId);

  const { data: vaultsPendingWalletShares } = useFetchVaultsPendingWalletShares(
    {
      vaultsPoolIds,
      elixirVaultsData: vaults,
    },
  );

  const vaultsWithPendingShares = vaults?.filter(vault => {
    const pendingShares = vaultsPendingWalletShares?.[vault.productId];

    return pendingShares && pendingShares > 0;
  });

  const routerAddresses = vaultsWithPendingShares?.map(
    vault => vault.routerAddress,
  );

  const { data: vaultsRouterUsdtBalances } = useFetchVaultsRouterUsdtBalances({
    routerAddresses,
  });

  const onClaim = async (poolId: number, pendingAmount: number) => {
    try {
      if (!poolId) return;
      flushSync(() => setIsClaiming(true));

      const claimTx = await claim(poolId);

      showNotification({
        title: `🎉 ${t('claimingAmountToken', {
          amount: pendingAmount,
          token: brand.tokenTicker,
        })}! `,
        description: t('claimingAmountTokenFromUnstakeAmountDescription', {
          amount: pendingAmount,
          token: brand.tokenTicker,
        }),
        type: NotificationType.Positive,
      });

      await claimTx.wait();

      queryClient.invalidateQueries(QueryKeys.ElixirVaultsPendingWalletShares);

      showNotification({
        title: `🎉 ${t('claimingSuccessfulTitle')}`,
        description: t(
          'claimedSuccessFullyAmountTokenFromUnstakeAmountDescription',
          {
            amount: pendingAmount,
            token: brand.tokenTicker,
          },
        ),
        type: NotificationType.Positive,
      });
    } catch (err) {
      showNotification({
        title: t('couldntClaimUnstakedAmounTitle'),
        description: t('couldntClaimUnstakedAmounDescription'),
        type: NotificationType.Negative,
      });
    } finally {
      flushSync(() => setIsClaiming(false));
    }
  };

  const withdrawalsDontExists =
    !vaultsRouterUsdtBalances ||
    !vaultsPendingWalletShares ||
    !vaultsWithPendingShares ||
    !vaults ||
    vaults.length === 0 ||
    vaultsWithPendingShares.length === 0;

  if (withdrawalsDontExists) return null;

  return (
    <>
      {vaultsWithPendingShares.map(vault => {
        const pendingAmount = vaultsPendingWalletShares[vault.productId];

        return (
          <WithdrawalRow
            vaultManager={vaultManager}
            vault={vault}
            pendingAmount={pendingAmount}
            routerUsdtBalance={vaultsRouterUsdtBalances[vault.routerAddress]}
            onClaim={onClaim}
            isClaiming={isClaiming}
          />
        );
      })}
      <TableRow className="seperator-row">
        <TableCell colSpan={100}>
          <Seperator />
        </TableCell>
      </TableRow>
    </>
  );
};

export default WithdrawalRows;
