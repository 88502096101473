import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;
`;

type ContentProps = {
  isExpanded: boolean;
};
export const Content = styled.div<ContentProps>`
  color: ${({ theme }) => theme.colors.shadesForeground200};
  font-size: 12px;
  line-height: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  opacity: 1;
  max-height: 1000px;
  overflow: hidden;
  transition: opacity 0.2s ease-out, max-height 0.5s ease-out;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    font-size: 14px;
    line-height: 16px;
  }

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      opacity: 0;
      max-height: 0;
    `}
`;
