import AirdropUserStatsSection, { Phase } from '../AirdropUserStatsSection';
import { Container } from './styles';

import { observer } from 'mobx-react';

export type AirdropUserStats = {
  isEligible: boolean;
  tradingVolume: number;
  avgOpenInterest: number;
  pnl: number;
  avgDeposit: number;
};

type Props = {
  airdropUserStats: AirdropUserStats;
  phaseImg: string;
  phase: Phase;
  children: React.ReactNode;
};
const PhaseContainer = ({
  airdropUserStats,
  children,
  phaseImg,
  phase,
}: Props) => {
  return (
    <Container>
      <AirdropUserStatsSection
        airdropUserStats={airdropUserStats}
        phaseImg={phaseImg}
        phase={phase}
      />
      {children}
    </Container>
  );
};

export default observer(PhaseContainer);
