import { brand } from 'utils/brand';

import { Icon } from 'components';

import { ListSeperator, NavItemContainer } from './styles';
import blastBlackIcon from 'assets/assetLogos/blast-black-outline.svg';
import blastGrayIcon from 'assets/assetLogos/blast-gray-outline.svg';
import { routes } from 'pages/routes';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type StyledNavItemContainerProps = { isActive: boolean; activeColor?: string };

const StyledNavItemContainer = styled(
  NavItemContainer,
)<StyledNavItemContainerProps>`
  .blast-icon {
    padding: 5px;
    border-radius: 8px;
    background: ${({ isActive, theme, activeColor }) =>
      isActive && (activeColor || theme.colors.main200)};

    &:hover {
      background: ${({ theme, activeColor }) =>
        activeColor || theme.colors.main200};
    }
  }

  :hover {
    .blast-icon {
      background: ${({ theme, activeColor }) =>
        activeColor || theme.colors.main200};
    }
  }
`;

const AirdropSideBarActions = ({
  pathname,
  closeMobileSideBar,
}: {
  pathname: string;
  closeMobileSideBar?: () => void;
}) => {
  const navigate = useNavigate();

  // const blastAirdropRoutePath =
  //   routes.portfolio.airdrops.blastAirdrop?.getRoutePath() ?? '';
  const dashboardRoute =
    routes.portfolio.airdrops.dashboard.getRoutePath() ?? '';

  // const isBlastAirdropActive = pathname
  //   .toLowerCase()
  //   .includes(blastAirdropRoutePath.toLowerCase());

  const isDashboardActive = pathname
    .toLowerCase()
    .includes(dashboardRoute.toLowerCase());

  return (
    <>
      {/* {brand.featureFlags.airdrops.blastAirdrop ? (
        <NavItemContainer
          onClick={() => {
            navigate(blastAirdropRoutePath);
            closeMobileSideBar?.();
          }}
          isActive={isBlastAirdropActive}
        >
          <StyledBlastIcon
            size="ML"
            isActive={isBlastAirdropActive}
            src={isBlastAirdropActive ? blastBlackIcon : blastGrayIcon}
            activeColor="#FCFC03"
          />

          <div className="menu-text">Blast Airdrop</div>
        </NavItemContainer>
      ) : null} */}

      <StyledNavItemContainer
        onClick={() => {
          navigate(dashboardRoute);
          closeMobileSideBar?.();
        }}
        isActive={isDashboardActive}
      >
        <Icon
          className="blast-icon"
          size="ML"
          src={isDashboardActive ? blastBlackIcon : blastGrayIcon}
        />

        <div className="menu-text">Earn Points</div>
      </StyledNavItemContainer>
    </>
  );
};

export default AirdropSideBarActions;
