import { memo } from 'react';

import { mergeOrderbookWithDepthDataByPriceTick } from 'utils';

import { AskBidTableProps } from './OrderBook';
import { BidsAndAsksContainer } from './OrderBookStyles';
import OrderRow from './OrderRow';

import { OrderbookSide } from 'enums';
import { OrderbookOrder } from 'interfaces';

type Props = {
  allBids: OrderbookOrder[];
} & AskBidTableProps;
const BidsTable = ({
  selectedMarketOrders,
  minTick,
  pricePrecision,
  allBids,
  selectedMarketId,
  ordersAndTradesCurrencyKind,
  isLoading,
  selectedTick,
  ...rest
}: Props) => {
  const bids = mergeOrderbookWithDepthDataByPriceTick(
    selectedTick === minTick ? allBids.slice(0, 11) : allBids,
    OrderbookSide.Bid,
    selectedTick,
    pricePrecision,
    minTick,
  );

  return (
    <BidsAndAsksContainer className="bids">
      {!isLoading
        ? bids.map((item, index) => (
            <OrderRow
              key={item.price}
              order={item}
              isFirstRow={index === 0}
              orderSide={OrderbookSide.Bid}
              currency={ordersAndTradesCurrencyKind}
              marketID={selectedMarketId}
              ordersForCurrentPriceLevel={selectedMarketOrders.filter(
                order => order.price === item.price,
              )}
              {...rest}
            />
          ))
        : null}
    </BidsAndAsksContainer>
  );
};

export default BidsTable;
