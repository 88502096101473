import { useState } from 'react';

import { showNotification, verifyUserSession } from 'utils';

import {
  useActiveWeb3React,
  useVerifyChainId,
  useBalanceOperationsAPI,
} from 'hooks';
import { useDepositsAPI } from 'hooks/useDepositsAPI';

import { useAppContext } from 'contexts/AppContext';
import { mixpanelService } from 'service/mixpanelService';

import { NotificationType, QueryKeys, DepositOrWithdrawalStatus } from 'enums';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

const getClaimBtnText = (
  isClaiming: boolean,
  isPendingTxSign: boolean,
  t: any,
  balanceOperationStatus?: DepositOrWithdrawalStatus,
) => {
  if (!balanceOperationStatus) return '';

  if (
    [
      DepositOrWithdrawalStatus.Canceled,
      DepositOrWithdrawalStatus.Success,
      DepositOrWithdrawalStatus.Failed,
    ].includes(balanceOperationStatus)
  ) {
    return t('claim');
  }

  if (isPendingTxSign) {
    return t('pendingDot');
  }

  if (isClaiming) {
    return t('claimingDot');
  }

  return t('claim');
};

const useCancelAndClaimWithdrawal = ({
  opsId,
  opsStatus,
}: {
  opsId?: string;
  opsStatus?: DepositOrWithdrawalStatus;
}) => {
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const [isClaiming, setIsClaiming] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isPendingTxSign, setIsPendingTxSign] = useState(false);
  const queryClient = useQueryClient();

  const { claimWithdrawal, cancelWithdrawal, setProcessingStatus } =
    useBalanceOperationsAPI();
  const { withdrawWithdrawableBalance } = useDepositsAPI();

  /**
   * Handles the claim all button click
   */
  const handleClaimWithdrawal = async () => {
    try {
      if (
        !frontendSecrets?.profile?.wallet ||
        !opsId ||
        isClaiming ||
        isPendingTxSign
      )
        return;

      const wallet = frontendSecrets.profile.wallet;

      if (!(await validateNetworkAndSwitchIfRequired('Try to claim again.'))) {
        return;
      }

      mixpanelService.claimWithdrawClicked(wallet);

      showNotification({
        title: t('claimingWithdrawalDot'),
        type: NotificationType.Info,
      });
      flushSync(() => {
        setIsClaiming(true);
      });

      // call API endpoint for claiming withdrawal
      const res = await claimWithdrawal(opsId);

      flushSync(() => {
        setIsPendingTxSign(true);
      });

      // call contract method for claiming withdrawal with the response from the API
      const tx = await withdrawWithdrawableBalance(res, wallet);

      flushSync(() => {
        setIsPendingTxSign(false);
      });

      const minedTx = await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(wallet)) {
        return;
      }

      if (minedTx.status !== 1) {
        throw new Error('Transaction failed');
      }

      mixpanelService.claimWithdrawSuccess(wallet);

      await setProcessingStatus(opsId, minedTx.transactionHash);

      await queryClient.invalidateQueries(QueryKeys.Transfers);
      showNotification({
        title: t('withdrawalClaimBeingProcessed'),
        description: t('withdrawalclaimBeingProcessedDescription'),
        type: NotificationType.Positive,
      });
    } catch (e: any) {
      console.error(e);
      showNotification({
        title: t('withdrawalClaimFailed'),
        description: t('withdrawalClaimFailedDescription', {
          errorMessage: e?.message,
        }),
        type: NotificationType.Negative,
      });
    } finally {
      await queryClient.invalidateQueries(QueryKeys.Transfers);
      setIsPendingTxSign(false);
      setIsClaiming(false);
    }
  };

  /**
   * Handles the cancel button click
   */
  const handleCancelWithdrawal = async () => {
    try {
      if (!account || !opsId || isCancelling || isPendingTxSign) return;

      mixpanelService.cancelWithdrawClicked(account);

      showNotification({
        title: t('cancelingWithdrawalDot'),
        type: NotificationType.Info,
      });
      flushSync(() => {
        setIsCancelling(true);
      });

      // call API endpoint for canceling withdrawal
      await cancelWithdrawal(opsId);

      mixpanelService.cancelWithdrawSuccess(account);

      showNotification({
        title: t('cancelingWithdrawalSuccessful'),
        type: NotificationType.Positive,
      });
    } catch (e: any) {
      console.error(e);
      showNotification({
        title: t('cancelingWithdrawalFailed'),
        description: t('cancelingWithdrawalFailedDescription', {
          errorMessage: e?.message,
        }),
        type: NotificationType.Negative,
      });
    } finally {
      await queryClient.invalidateQueries(QueryKeys.Transfers);
      setIsCancelling(false);
    }
  };

  // Can be canceled if status is Pending or Claimable
  const shouldDisableCancelButton =
    !opsStatus ||
    ![
      DepositOrWithdrawalStatus.Pending,
      DepositOrWithdrawalStatus.Claimable,
    ].includes(opsStatus);

  // Can be claimed if status is Claimable, Claiming or Processing
  const shouldDisableClaimButton =
    !opsStatus ||
    ![
      DepositOrWithdrawalStatus.Claimable,
      DepositOrWithdrawalStatus.Claiming,
      DepositOrWithdrawalStatus.Processing,
    ].includes(opsStatus);

  return {
    shouldDisableCancelButton,
    shouldDisableClaimButton,
    handleCancelWithdrawal,
    handleClaimWithdrawal,
    isClaiming,
    isCancelling,
    isPendingTxSign,
    claimButtonText: getClaimBtnText(isClaiming, isPendingTxSign, t, opsStatus),
  };
};

export default useCancelAndClaimWithdrawal;
