import { TableBody, TableCell, TableHead } from '@material-ui/core';

import { isBrandRabbitX } from 'utils/brand';

import useModal from 'hooks/useModal';

import Loading from 'components/Loading';
import Text from 'components/Text';

import InviteFriendsModal from '../../InviteFriends/InviteFriendsModal';
import TableRow from './TableRow';
import {
  Container,
  TableBanner,
  TableContainerStyled,
  TableRowStyled,
  TableStyled,
} from './styles';
import leaderboardCompetitionBfxImage from 'assets/images/leaderboard-competition-bfx.png';
import leaderboardCompetitionRabbitxImage from 'assets/images/leaderboard-competition-rabbitx.png';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { ReferralLeaderboard } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const leaderboardCompetitionImage = isBrandRabbitX
  ? leaderboardCompetitionRabbitxImage
  : leaderboardCompetitionBfxImage;

type Props = {
  isLoading?: boolean;
  isError?: boolean;
  referralLeaderboard?: ReferralLeaderboard[];
  userLeaderboard: ReferralLeaderboard | undefined;
  userShortCode: string | undefined;
};
const LeaderboardTable = ({
  isLoading,
  isError,
  referralLeaderboard,
  userLeaderboard,
  userShortCode,
}: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const modal = useModal();
  const { t } = useTranslation();

  const onUserShare = (rankText: string) => {
    if (!userShortCode) return;

    modal.present(
      <InviteFriendsModal referralCode={userShortCode} rankText={rankText} />,
      Modals.inviteFriendsModal,
    );
  };

  return (
    <Container>
      <TableBanner>
        <div className="content">
          <Text fontWeight="semiBold">🥇 {t('ReferralRanking')}</Text>
          <h2>
            {t('top10Referred')}
            <br />
            {t('VolumeTraders')}
          </h2>
          {/* <Text variant="BODY_L" fontWeight="semiBold">
          </Text> */}
        </div>
        <div>
          <img
            src={leaderboardCompetitionImage}
            alt="leaderboard-competition"
          />
        </div>
      </TableBanner>
      <div className="outer-table-container">
        <TableContainerStyled>
          <TableStyled>
            <TableHead>
              <TableRowStyled>
                <TableCell>{t('rank')}</TableCell>
                <TableCell>{t('user')}</TableCell>
                <TableCell>{t('signups')}</TableCell>
                <TableCell>{t('volume')}</TableCell>
                {/* <TableCell></TableCell> */}
              </TableRowStyled>
            </TableHead>
            <TableBody>
              {!!frontendSecrets?.jwt && userLeaderboard && (
                <>
                  <TableRow
                    referralLeaderboard={{
                      ...userLeaderboard,
                      wallet: frontendSecrets.profile.wallet,
                    }}
                    isCurrentUser
                    onSharePress={onUserShare}
                  />
                  <TableRowStyled className="empty-row"></TableRowStyled>
                  <TableRowStyled className="empty-row"></TableRowStyled>
                  <TableRowStyled className="separator-row">
                    <TableCell colSpan={5}></TableCell>
                  </TableRowStyled>
                  <TableRowStyled className="empty-row"></TableRowStyled>
                </>
              )}

              {referralLeaderboard &&
                referralLeaderboard.map((item, i) => (
                  <TableRow key={i} referralLeaderboard={item} />
                ))}

              {isLoading && (
                <TableRowStyled>
                  <TableCell colSpan={5} align="center">
                    <Loading />
                  </TableCell>
                </TableRowStyled>
              )}

              {referralLeaderboard && referralLeaderboard.length === 0 && (
                <TableRowStyled>
                  <TableCell colSpan={5}>{t('noResults')}</TableCell>
                </TableRowStyled>
              )}

              {isError && (
                <TableRowStyled>
                  <TableCell colSpan={5}>
                    <ColoredText color={Colors.Danger}>
                      {t('errorOccurredTryAgain')}
                    </ColoredText>
                  </TableCell>
                </TableRowStyled>
              )}
            </TableBody>
          </TableStyled>
        </TableContainerStyled>
      </div>
    </Container>
  );
};

export default observer(LeaderboardTable);
