import { useVerifyChainId } from 'hooks';
import useModal from 'hooks/useModal';

import AmendPlatformVaultLiquidityModal, {
  AmendType,
} from './AmendPlatformVaultLiquidityModal';
import UnstakeWarningModal from './UnstakeWarningModal';
import { Modals } from 'constants/modals';

import { useTranslation } from 'react-i18next';

export const useAmendPlatformVault = () => {
  const { validateNetworkAndDoAction } = useVerifyChainId();
  const modal = useModal();
  const { t } = useTranslation();

  const continueAmending = (amendType: AmendType, vaultWallet: string) => {
    validateNetworkAndDoAction(() => {
      modal.present(
        <AmendPlatformVaultLiquidityModal
          defaultAmendType={amendType}
          vaultWallet={vaultWallet}
        />,
        Modals.amendPlatformVaultLiquidityModal,
      );
    }, t('youCanNowStakeUnstakeFunds'));
  };

  const amendPlatformVault = (amendType: AmendType, vaultWallet: string) => {
    const onContinue = () => continueAmending(amendType, vaultWallet);

    if (amendType === AmendType.UnStake) {
      modal.present(
        <UnstakeWarningModal onContinue={onContinue} />,
        Modals.unstakeWarningModal,
      );
    } else {
      onContinue();
    }
  };

  return { amendPlatformVault } as const;
};
