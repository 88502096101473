import { showNotification } from 'utils';
import { brand } from 'utils/brand';

import { SpotStateType } from 'enums/spotState';

import { AmendType } from '.';
import { NotificationType } from 'enums';

export const StatusTx = {
  stakeSuccess: (tokenPair: string, amount: number | undefined, t) => ({
    title: t('stakeSuccess', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    })!,
    description: t('stakeSuccessDescription', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    }),
  }),

  unstakeSucess: (tokenPair: string, amount: number | undefined, t) => ({
    title: t('unstakeSuccess', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    })!,
    description: t('unstakeSuccessDescription', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    }),
  }),

  unstakeFailed: (tokenPair: string, amount: number | undefined, t) => ({
    title: t('unstakeFailed')!,
    description: t('unstakeFailedDescription', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    }),
  }),

  stakeFailed: (tokenPair: string, amount: number | undefined, t) => ({
    title: t('stakeFailed')!,
    description: t('stakeFailedDescription', {
      pair: tokenPair,
      amount,
      token: brand.tokenTicker,
    }),
  }),

  stakeProcessing: (tokenPair: string, amount: number | undefined, t) => ({
    title: t('stakeProcessing')!,
    description: t('stakeProcessingDescription', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    }),
  }),

  unstakeProcessing: (tokenPair: string, amount: number | undefined, t) => ({
    title: t('unstakeProcessing')!,
    description: t('unstakeProcessingDescription', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    }),
  }),

  stakeRequestSentButUntracked: (
    tokenPair: string,
    amount: number | undefined,
    t,
  ) => ({
    title: t('stakeRequestSent', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    })!,
    description: t('stakeRequestSentDescription', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    }),
  }),

  unstakeRequestSentButUntracked: (
    tokenPair: string,
    amount: number | undefined,
    t,
  ) => ({
    title: t('unstakeRequestSent', {
      amount,
      pair: tokenPair,
      token: brand.tokenTicker,
    })!,
    description: t('unstakeRequestSentDescription', {
      amount,
      pair: tokenPair,
    }),
  }),

  stakeInQueue: (
    tokenPair: string,
    amount: number | undefined,
    positionInQueue: number,
    t,
  ) => ({
    title: t('stakePending')!,
    description:
      positionInQueue === 1
        ? t('stakePendingDescriptionProcessingShortly', {
            amount,
            pair: tokenPair,
            token: brand.tokenTicker,
          })
        : t('stakePendingDescriptionInQueue', {
            amount,
            pair: tokenPair,
            position: positionInQueue - 1,
            token: brand.tokenTicker,
          }),
  }),

  unstakeInQueue: (
    tokenPair: string,
    amount: number | undefined,
    positionInQueue: number,
    t,
  ) => ({
    title: t('unstakePending')!,
    description:
      positionInQueue === 1
        ? t('unstakePendingDescriptionProcessingShortly', {
            amount,
            pair: tokenPair,
            token: brand.tokenTicker,
          })
        : t('unsstakePendingDescriptionInQueue', {
            amount,
            pair: tokenPair,
            position: positionInQueue - 1,
            token: brand.tokenTicker,
          }),
  }),
};

export const getRequestStatusText = (
  amendType: AmendType,
  spotStateType: SpotStateType,
  tokenPair: string,
  amount: number | undefined,
  positionInQueue: number,
  t,
) => {
  if (amendType === AmendType.Stake) {
    if (spotStateType === SpotStateType.Executed)
      return StatusTx.stakeSuccess(tokenPair, amount, t);
    if (spotStateType === SpotStateType.Executing)
      return StatusTx.stakeProcessing(tokenPair, amount, t);
    return StatusTx.stakeInQueue(tokenPair, amount, positionInQueue, t);
  } else {
    if (spotStateType === SpotStateType.Executed)
      return StatusTx.unstakeSucess(tokenPair, amount, t);
    if (spotStateType === SpotStateType.Executing)
      return StatusTx.unstakeProcessing(tokenPair, amount, t);
    return StatusTx.unstakeInQueue(tokenPair, amount, positionInQueue, t);
  }
};

export const showRequestStatusNotification = ({
  type,
  spotStateType,
  amount,
  tokenPair,
  justSubmitted,
  positionInQueue,
  t,
}: {
  type: AmendType | undefined;
  spotStateType?: SpotStateType;
  amount: number;
  tokenPair: string;
  positionInQueue?: number;
  justSubmitted?: boolean;
  t: any;
}) => {
  if (type === undefined) return;

  // Notifications when the stake/unstake request is submitted, i.e. the transaction was broadcasted sucessfully.
  if (justSubmitted) {
    if (type === AmendType.Stake) {
      showNotification({
        title: 'Stake Request Submitted',
        description: `Your request to stake ${amount} ${brand.tokenTicker} into ${tokenPair} vault was submitted.`,
        type: NotificationType.Info,
      });
      return;
    }
    if (type === AmendType.UnStake) {
      showNotification({
        title: 'Unstake Request Submitted',
        description: `Your request to unstake ${amount} shares of ${tokenPair} was submitted.`,
        type: NotificationType.Info,
      });
      return;
    }

    return;
  }

  if (!spotStateType) return;

  const requestStatusText = getRequestStatusText(
    type,
    spotStateType,
    tokenPair,
    amount,
    positionInQueue || 0,
    t,
  );

  const notificationType =
    spotStateType === SpotStateType.Executed
      ? NotificationType.Positive
      : NotificationType.Info;

  // Show the notification using the appropriate method (e.g., showNotification)
  showNotification({ ...requestStatusText, type: notificationType });
};
