import { Icon } from 'components';
import Loading from 'components/Loading';
import Text from 'components/Text';

import CoinRow from './CoinRow';
import { GrayLine, SectionStyled } from './styles';
import { Colors } from 'pages/Trade/components/shared';

import { Market } from 'interfaces';
import { observer } from 'mobx-react';

type Props = {
  description?: React.ReactNode;
  title: string;
  items: Market[] | null;
  headerColor: Colors;
  headerIcon?: string;
  isWatchlist?: boolean;
  isLoading?: boolean;
  setFavoriteMarket: (marketId: string) => void;
};
const Section = ({
  title,
  description,
  items,
  headerColor,
  headerIcon,
  isWatchlist,
  isLoading = false,
  setFavoriteMarket,
}: Props) => {
  if (!isWatchlist && items && items.length === 0) {
    return null;
  }

  return (
    <SectionStyled headerColor={headerColor}>
      <div className="header">
        <div className="left-part gap-5">
          <Text variant="HEADING_H2" fontWeight="semiBold">
            {title}
          </Text>
          <Icon src={headerIcon} alt="header icon" />
        </div>
        <div className="right-part">
          <GrayLine />
        </div>
      </div>
      {/* {description && (!items || items?.length === 0) ? ( */}
      <Text variant="BODY_S" color="shadesForeground200">
        {description}
      </Text>
      {/* ) : null} */}
      {isLoading && (
        <div className="flex justify-center">
          <Loading />
        </div>
      )}
      {items &&
        items.map((item, index: number) => (
          <CoinRow
            market={item}
            key={index}
            setFavoriteMarket={setFavoriteMarket}
          />
        ))}
    </SectionStyled>
  );
};

export default observer(Section);
