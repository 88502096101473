import { memo, useState } from 'react';

import { useReferralsAPI } from 'hooks/useReferralsAPI';

import {
  options,
  TimeOptions,
} from '../../ReferralDashboard/Leaderboard/TimeframeSelectDropdown';
import LeaderboardTable from './LeaderboardTable';
import { Container } from './styles';

import { QueryKeys } from 'enums';
import { Referral, ReferralLeaderboard } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const getUserLeaderboard = (
  userReferral: Referral | undefined,
  selectedTimescale: TimeOptions,
): ReferralLeaderboard | undefined => {
  if (
    !userReferral ||
    !userReferral.leaderboardStatusWeekly ||
    !userReferral.leaderboardStatusMonthly ||
    !userReferral.leaderboardStatusAll
  ) {
    return undefined;
  }

  switch (selectedTimescale) {
    case '1w':
      return userReferral.leaderboardStatusWeekly;
    case '1m':
      return userReferral.leaderboardStatusMonthly;
    case 'all':
      return userReferral.leaderboardStatusAll;
  }
};

type Props = {
  twitterData: Record<string, any>;
  userReferral: Referral | undefined;
};
const Leaderboard = ({ twitterData, userReferral }: Props) => {
  const { fetchReferralsLeaderboard } = useReferralsAPI();
  const { t } = useTranslation();

  const [selectedTimescale, setSelectedTimescale] = useState<TimeOptions>(
    options[0],
  );

  const {
    isLoading: isLoadingReferralsLeaderboard,
    data: referralsLeaderboard,
    isError: isErrorReferralsLeaderboard,
  } = useQuery(
    [QueryKeys.ReferralsLeaderboard, selectedTimescale],
    async () => await fetchReferralsLeaderboard(selectedTimescale),
    {
      // 10 minutes
      refetchInterval: 60_000 * 10,
    },
  );

  const userLeaderboard = getUserLeaderboard(userReferral, selectedTimescale);

  return (
    <Container>
      <LeaderboardTable
        isLoading={isLoadingReferralsLeaderboard}
        isError={isErrorReferralsLeaderboard}
        referralLeaderboard={referralsLeaderboard}
        userLeaderboard={userLeaderboard}
        userShortCode={userReferral?.shortCode}
        twitterData={twitterData}
      />
    </Container>
  );
};

export default memo(Leaderboard);
