import { brandedSelect } from 'utils/brand';

import linkIcon from 'assets/icons/link.svg';
import coinIcon from 'assets/icons/portfolio-nav-items/trades-active.svg';
import safeIcon from 'assets/icons/safe.svg';
import walletIcon from 'assets/icons/wallet-white.svg';

import { ALL_STEPS } from '.';

export enum Step {
  CONNECT_WALLET,
  DEPOSIT_FUNDS,
  STAKE_INTO_VAULT,
  REFER_FRIENDS,
}

export const getStepTitleDescriptionIcon = (step: Step, t) => {
  if (step === Step.CONNECT_WALLET) {
    return {
      title: t('connectWallet'),
      description: t('onboardingConnectWalletDescription'),
      icon: walletIcon,
      ctaLabel: t('connect'),
    };
  }

  if (step === Step.DEPOSIT_FUNDS) {
    return {
      title: t('depositFunds'),
      description: t('onboardingDepositFundsDescription'),
      icon: coinIcon,
      ctaLabel: t('deposit'),
    };
  }

  if (step === Step.STAKE_INTO_VAULT) {
    return {
      title: t('stakeIntoVault'),
      description: t(
        brandedSelect({
          bfx: 'onboardingStakeIntoVaultDescription',
          rabbitx: 'onboardingStakeIntoVaultDescriptionRBX',
        }),
      ),
      icon: safeIcon,
      ctaLabel: t('stake'),
    };
  }

  if (step === Step.REFER_FRIENDS) {
    return {
      title: t('referYourFriend'),
      description: t('onboardingReferYourFriendDescription'),
      icon: linkIcon,
      ctaLabel: t('CopyLink'),
    };
  }

  return {
    title: '',
    description: '',
    icon: '',
    ctaLabel: '',
  };
};

export const getNextStep = (completedSteps: Step[]) => {
  const STEPS_WITHOUT_CONNECT = ALL_STEPS.filter(
    step => step !== Step.CONNECT_WALLET,
  );

  const nextStep = STEPS_WITHOUT_CONNECT.find(
    step => !completedSteps.includes(step),
  );

  return nextStep;
};
