import { brand } from 'utils/brand';

import Text from 'components/Text';

import { AmendStep } from '../enums';

import { AmendType } from '..';

interface Props {
  amount: number;
  step: AmendStep;
  type: AmendType;
}

const RequestStatus = ({ amount, step, type }: Props) => {
  if (step === AmendStep.STAKE_SUCCESS)
    return (
      <>
        <Text
          variant="BODY_M"
          fontWeight="semiBold"
          color="positiveForeground200"
          className="align-center"
          preWrap
        >
          {`Your stake request for ${amount} ${brand.tokenTicker} has been successfully submitted.`}
        </Text>
        <Text
          variant="BODY_M"
          fontWeight="semiBold"
          color="shadesForeground200"
          className="align-center"
          preWrap
        >
          It might take upto a minute for your position to be updated.
        </Text>
      </>
    );

  if (step === AmendStep.STAKE_FAILED) {
    return (
      <>
        <Text
          variant="BODY_M"
          fontWeight="semiBold"
          color="negativeForeground100"
          className="align-center"
          preWrap
        >
          {`Your stake request of ${amount} ${brand.tokenTicker} could not be submitted successfully.`}
        </Text>
        <Text
          variant="BODY_M"
          fontWeight="semiBold"
          color="shadesForeground200"
          className="align-center"
          preWrap
        >
          Please try again later. If you encounter any issues, please contact
          support for assistance.
        </Text>
      </>
    );
  }

  if (step === AmendStep.UNSTAKE_SUCCESS)
    return (
      <>
        <Text
          variant="BODY_M"
          fontWeight="semiBold"
          color="positiveForeground200"
          className="align-center"
          preWrap
        >
          {`Your unstake request for ${amount} shares has been successfully submitted.`}
        </Text>

        <Text
          variant="BODY_M"
          fontWeight="semiBold"
          color="shadesForeground200"
          className="align-center"
          preWrap
        >
          Your funds will be availabe in your wallet balance once fund manager
          has approved your request.
          <br />
          You have the option to cancel the request before it is approved.
        </Text>
      </>
    );

  if (step === AmendStep.UNSTAKE_FAILED)
    return (
      <>
        <Text
          variant="BODY_M"
          fontWeight="semiBold"
          color="negativeForeground100"
          className="align-center"
          preWrap
        >
          {`Your unstake request for ${amount} shares could not be submitted successfully.`}
        </Text>
        <Text
          variant="BODY_M"
          fontWeight="semiBold"
          color="shadesForeground200"
          className="align-center"
          preWrap
        >
          Please try again later. If you encounter any issues, please contact
          support for assistance.
        </Text>
      </>
    );

  return null;
};

export default RequestStatus;
