import { useCallback } from 'react';

import { useExchangeAPI } from './useExchangeAPI';
import {
  API_MAPPINGS,
  CLAIM_ALL_AIRDROPS_RESPONSE_MAPPING,
} from 'constants/apiMappings';
import { RequestResponse } from 'service/restService';

import { Endpoints, RequestMethod } from 'enums';
import { ProfileAirdrop } from 'interfaces';

type ClaimOps = {
  id: number;
  profileId: number;
  status: 'claiming' | 'claimed';
  amount: number;
  timestamp: number;
  signature: string;
};
export type ClaimAllResponse = {
  claimOps: ClaimOps;
  r: string;
  s: string;
  v: number;
  bnAmount: string;
};

export function useAirdropsAPI() {
  const { makePrivateRequest } = useExchangeAPI();

  const claimAirdrop = async (
    airdropTitle: string,
  ): Promise<ClaimAllResponse> => {
    try {
      const params = {
        title: airdropTitle,
      };

      const res = await makePrivateRequest({
        method: RequestMethod.POST,
        endpoint: `/${Endpoints.AIRDROPS_CLAIM}`,
        requestParams: params,
        refreshJwt: false,
        responseMapping: CLAIM_ALL_AIRDROPS_RESPONSE_MAPPING,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  };

  const fetchAllAirdropsForUser = useCallback(async (): Promise<
    RequestResponse<ProfileAirdrop[]>
  > => {
    try {
      const res = await makePrivateRequest({
        method: RequestMethod.GET,
        endpoint: `/${Endpoints.AIRDOPS}`,
        requestParams: {},
        responseMapping: API_MAPPINGS.PROFILE_AIRDROP,
        isArray: true,
        shouldCheckJwtValidity: true,
      });

      return res;
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  }, [makePrivateRequest]);

  return {
    fetchAllAirdropsForUser,
    claimAirdrop,
  } as const;
}

export type AirdropsAPI = ReturnType<typeof useAirdropsAPI>;
