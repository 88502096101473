export enum Endpoints {
  ONBOARDING = 'onboarding',
  JWT = 'jwt',
  SRV_TIMESTAMP = 'srvtimestamp',
  ORDERS = 'orders',
  FILLS = 'fills',
  BALANCE_OPERATIONS = 'balanceops',
  ORDERS_CANCEL_ALL = 'orders/cancel_all',
  ACCOUNT_VALIDATE = 'account/validate',
  ACCOUNT_LEVERAGE = 'account/leverage',
  DEPOSIT = 'balanceops/deposit',
  AIRDOPS = 'airdrops',
  AIRDROPS_CLAIM = 'airdrops/claim',
  BALANCE_OPS_WITHDRAW = 'balanceops/withdraw',
  BALANCE_OPS_CLAIM = 'balanceops/claim',
  BALANCE_OPS_CANCEL = 'balanceops/cancel',
  BALANCE_OPS_STAKE = 'balanceops/stake',
  BALANCE_OPS_UNSTAKE_BEGIN = 'balanceops/unstake/begin',
  BALANCE_OPS_UNSTAKE_CANCEL = 'balanceops/unstake/cancel',
  BALANCE_OPS_UNSTAKE_REQUESTED = 'balanceops/unstake/requested',
  BALANCE_OPS_UNSTAKE_PROCESS = 'balanceops/unstake/process',
  BALANCE_OPS_PROCESSING = 'balanceops/processing',
  VAULT_BALANCE_OPS = 'vaults/balanceops',
  SECRETS = 'secrets',
  PORTFOLIO = 'portfolio',
  STORAGE = 'storage',
  FUNDING_RATE = 'markets/fundingrate',
  REFERRAL = 'referral',
  REFERRAL_LEADERBOARD = 'referral/leaderboard',
  MARKETS_COINS = 'markets/coins',
  VAULT_NAV_HISTORY = 'vaults/navhistory',
  VAULTS = 'vaults',
  VAULTS_HOLDINGS = 'vaults/holdings',
  BLAST_POINTS = 'blast/points',
  GAME_ASSETS_BLAST = 'game_assets/blast',
  GAME_ASSETS_BLAST_LEADERBOARD = 'game_assets/blast_leaderboard',
  VOLUME = 'volume',
  PROFILE = 'profile',
  MARKET_ORDERBOOK = 'markets/orderbook',
  FILLS_ORDER = 'fills/order',
  BFX_POINTS_BALANCE = 'bfx/points/balance',
}
