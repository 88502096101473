import styled, { css } from 'styled-components';

type ContainerProps = { selected?: boolean; padding?: number };

export const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 5px;
  padding: ${({ padding }) => `${padding !== undefined ? padding : 10}px`};
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.colors.shadesBackground700};
    `};
`;
