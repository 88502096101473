import { CircularProgress } from '@material-ui/core';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loading = (props: any) => {
  return (
    <Container className="loading-icon-container" {...props}>
      <CircularProgress className="loading-icon" size={props.size ?? 40} />
    </Container>
  );
};

export default Loading;
