import styled from 'styled-components';

type BackgroundContainerProps = {
  backgroundImage?: string;
};
export const BackgroundContainer = styled.div<BackgroundContainerProps>`
  position: relative;
  background: ${({ backgroundImage }) =>
      backgroundImage && `url(${backgroundImage})`}
    no-repeat center;
  background-size: contain;
  width: 414px;
  height: 414px;
  border-radius: 20px;
  overflow: visible;
  background-color: ${({ theme }) => theme.colors.shadesBackground800};
`;

type ContainerProps = {
  backgroundImage?: string;
};
export const Container = styled.div<ContainerProps>`
  padding: 20px 30px;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;

  .headline {
    font-size: 18px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .logo {
    height: 33px;
  }
`;

export const PositionStats = styled.div`
  .market-id {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    > img {
      width: 32px;
      height: 32px;
    }

    > span {
      font-size: 28px;
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      letter-spacing: -0.56px;
    }
  }

  .trade-side {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;

    span.leverage {
      font-size: 18px;
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      line-height: 24px;
      color: ${({ theme }) => theme.colors.shadesForeground200};
    }

    .separator {
      width: 2px;
      height: 21px;
      background: ${({ theme }) => theme.colors.shadesForeground300};
    }
  }

  .position-pnl {
    margin-top: 20px;
    span.formatted-number {
      font-size: 40px;
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      letter-spacing: -0.8px;
    }
  }

  .prices-columns {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;

    .column {
      display: flex;
      flex-direction: column;
      gap: 2px;

      span:first-child {
        font-size: 14px;
        line-height: 16px;
        color: ${({ theme }) => theme.colors.shadesForeground200};
      }
      span:last-child {
        font-size: 16px;
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        line-height: 24px;
      }
    }
  }
`;

interface TradeSideLabelProps {
  isSuccess?: boolean;
}
export const TradeSideLabel = styled.span<TradeSideLabelProps>`
  padding: 6px 8px;
  border-radius: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 14px;
  line-height: 14px;
  background-color: ${({ isSuccess, theme }) =>
    isSuccess
      ? theme.colors.positiveBackground100
      : theme.colors.negativeBackground100};
  color: ${({ isSuccess, theme }) =>
    isSuccess
      ? theme.colors.positiveForeground200
      : theme.colors.negativeForeground200};
  text-transform: uppercase;
`;

export const Footer = styled.div`
  position: absolute;
  width: 352px;
  left: 0;
  bottom: -103px;
  padding: 10px 30px 12px 30px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
  background: ${({ theme }) => theme.colors.shadesBackground800};
  display: flex;
  justify-content: space-between;
  animation: slideFromTop 1s forwards;
  z-index: -3;

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .icon-circle {
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: ${({ theme }) => theme.colors.shadesBackground700};
    }

    > span {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @keyframes slideFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

type Props = {
  justifyStart?: boolean;
};
export const ReferralContainer = styled.div<Props>`
  display: flex;
  justify-content: ${({ justifyStart }) =>
    justifyStart ? 'flex-start' : 'space-between'};
  gap: 20px;
  margin-top: 20px;

  .left-col {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .loading-icon-container {
      margin-left: 5px;
    }

    .headline {
      font-size: 16px;
      line-height: 24px;
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      margin: 0;
      display: flex;
      align-items: center;
    }

    .description {
      margin: 0;
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.shadesForeground200};
    }
  }

  .qr-code {
    display: flex;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    padding: 10px;
    align-self: flex-start;
  }
`;
