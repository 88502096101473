import { Icon } from 'components';
import Badge from 'components/Badge';
import Button, { ButtonProps } from 'components/Button/button';

import {
  Box,
  BoxContent,
  BoxItem,
  BoxItems,
  BoxRow,
  HorizontalSeparator,
  VerticalSeparator,
} from '../../styles';
import BorderBadge, { BorderBadgeProps } from '../Header/BorderBadge';
import lockIcon from 'assets/icons/lock.svg';

const ActiveBadge = () => (
  <Badge
    padding="5px 8px"
    borderRadius={100}
    variant="BODY_S"
    color="positiveForeground200"
    bgColor="positiveBackground100"
  >
    Active
  </Badge>
);

const ClosedBadge = () => (
  <Badge
    padding="5px 8px"
    borderRadius={100}
    variant="BODY_S"
    color="shadesForeground200"
    bgColor="shadesBackground700"
  >
    Closed
  </Badge>
);

const ComingSoonBadge = () => (
  <Badge
    padding="5px 8px"
    borderRadius={100}
    variant="BODY_S"
    color="shadesForeground200"
    bgColor="shadesBackground700"
  >
    Coming Soon
  </Badge>
);

type LockOptionsProps = {
  stats: {
    label: string;
    value: React.ReactNode;
  }[];
  ctaProps: ButtonProps & { title: string };
  title: string;
  description: string;
  headerBanner: string;
  headerIcon: string;
  badges?: BorderBadgeProps[];
  isActive?: boolean;
  isComingSoon?: boolean;
  isClosed?: boolean;
};
const LockOption = ({
  stats,
  headerBanner,
  title,
  description,
  ctaProps: { title: ctaTitle, ...restCtaProps },
  badges,
  headerIcon,
  isActive = false,
  isComingSoon = false,
  isClosed = false,
}: LockOptionsProps) => {
  return (
    <Box bgImg={headerBanner} blurred={isComingSoon}>
      <div className="container">
        <div className="heading">
          <img src={headerIcon} alt={title} />
          <div className="description">
            <h4>{title}</h4>
            <span>{description}</span>
          </div>
        </div>

        <BoxContent>
          <BoxItems blurred={isComingSoon}>
            <BoxRow>
              <BoxItem>
                <span className="label">{stats[0]?.label}</span>
                <span className="value">{stats[0]?.value}</span>
              </BoxItem>
              <BoxItem>
                <span className="label">{stats[1]?.label}</span>
                <span className="value">{stats[1]?.value}</span>
              </BoxItem>
            </BoxRow>
            <HorizontalSeparator />
            <VerticalSeparator />
            <BoxRow>
              <BoxItem>
                <span className="label">{stats[2]?.label}</span>
                <span className="value">{stats[2]?.value}</span>
              </BoxItem>
              <BoxItem>
                <span className="label">{stats[3]?.label}</span>
                <span className="value">{stats[3]?.value}</span>
              </BoxItem>
            </BoxRow>
          </BoxItems>
          <Button
            className={isComingSoon ? 'locked-button' : ''}
            colorVariant="primaryGreen"
            sizeVariant="S"
            block
            leftIcon={isComingSoon ? lockIcon : undefined}
            {...restCtaProps}
          >
            {ctaTitle}
          </Button>
        </BoxContent>
      </div>
      <div className="badges">
        {badges?.map((badge, index) => (
          <BorderBadge key={index} {...badge} />
        ))}
        {isActive ? <ActiveBadge /> : false}
        {isClosed ? <ClosedBadge /> : false}
        {isComingSoon ? <ComingSoonBadge /> : false}
      </div>
    </Box>
  );
};

export default LockOption;
