import { Container, ContainerProps } from './styles';

type Props = {
  url: string;
  label: string;
  size?: number;
} & Omit<ContainerProps, 'size'>;

const Avatar = ({
  url,
  label,
  ...containerProps
}: Props) => {
  return (
    <Container {...containerProps}>
      <img className="avatar" src={url} alt="avatar" />
      {/* <div className="label">LEVEL {label}</div> */}
    </Container>
  );
};

export default Avatar;
