import { memo } from 'react';

import Icon from 'components/Icon';
import Text from 'components/Text';

import { Container, RelativeParent } from './styles';
import closeGrayIcon from 'assets/icons/close-x.svg';
import lightningContainedIcon from 'assets/icons/lightning-outline-contained-hexagon.svg';
import bolt1 from 'assets/images/lightning-bolt-parts/bolt-part-1.png';
import bolt2 from 'assets/images/lightning-bolt-parts/bolt-part-2.png';
import bolt3 from 'assets/images/lightning-bolt-parts/bolt-part-3.png';
import lightningBoltImage from 'assets/images/lightning-bolt.png';
import { Column, Row } from 'theme/globalStyledComponents';

const SuperChargedBoost = ({
  onClose,
  levelBoost,
  onMouseIn,
  onMouseOut,
}: {
  onClose: () => void;
  levelBoost: number;
  onMouseIn?: () => void;
  onMouseOut?: () => void;
}) => {
  return (
    <RelativeParent onMouseEnter={onMouseIn} onMouseLeave={onMouseOut}>
      <img className="bolt-1" alt="" src={bolt1} />
      <img className="bolt-2" alt="" src={bolt2} />
      <img className="bolt-3" alt="" src={bolt3} />
      <Container>
        <img src={lightningBoltImage} alt="" className="ligning-bolt" />
        <Column gap={10} className="content">
          <Row gap={8}>
            <img
              src={lightningContainedIcon}
              alt="bolt"
              className="contained-icon"
            />
            <Column gap={2} className="title">
              <span>SUPERCHARGED</span>
              <span>+{levelBoost * 100}% Boost</span>
            </Column>
          </Row>
          <Text className="description">
            You are earning a {levelBoost * 100}% bonus on any points earned
            through staking & trading.
          </Text>
        </Column>
        <div className="close-btn-container" onClick={onClose}>
          <Icon src={closeGrayIcon} />
        </div>
      </Container>
    </RelativeParent>
  );
};

export default memo(SuperChargedBoost);
