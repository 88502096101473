import { useState } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import {
  capitalizeFirstLetter,
  getCurrencyFromTradingPair,
  timestampToLocalDateWithTime,
} from 'utils';
import { hasTriggerPriceInput } from 'utils/order';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import { useSwitchMarket } from 'hooks/useSwitchMarket';

import { FormattedNumber, Icon } from 'components';
import Button from 'components/Button/button';
import CollapseIcon from 'components/CollapseIcon';

import LabelAndValueCell from '../components/LabelAndValueCell';
import MarketLogoWithTradeSide from '../components/MarketLogoWithTradeSide';
import { CollapsibleTableRow, StyledTableCell } from '../styles';
import { OrderStatusCol } from './OrderStatusCol';
import editIcon from 'assets/icons/edit-pen.svg';

import { OrderStatus, OrderType } from 'enums';
import { Order } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const orderTypeToLabel = (type: string, t: any) => {
  switch (type) {
    case OrderType.MARKET:
      return t('market');
    case OrderType.LIMIT:
      return t('limit');
    case OrderType.STOP_LOSS:
      return t('stopLoss');
    case OrderType.TAKE_PROFIT:
      return t('takeProfit');
    case OrderType.STOP_LOSS_LIMIT:
      return t('stopLoss');
    case OrderType.TAKE_PROFIT_LIMIT:
      return t('takeProfit');
    case OrderType.ST0P_LIMIT:
      return t('stopLimit');
    case OrderType.STOP_MARKET:
      return t('stopMarket');
    default:
      return capitalizeFirstLetter(type);
  }
};

/**
 * Returns true if the price column should be shown for the order.
 * It is shown for limit, market and stop limit orders, and for stop market orders that are open.
 * @param type order type
 * @param status order status
 */
export const shouldShowPrice = (type: OrderType, status: OrderStatus) => {
  return [
    OrderType.LIMIT,
    OrderType.ST0P_LIMIT,
    OrderType.STOP_LOSS,
    OrderType.TAKE_PROFIT,
    OrderType.STOP_LOSS_LIMIT,
    OrderType.TAKE_PROFIT_LIMIT,
  ].includes(type);
};

export interface OrderProps {
  index: number;
  order: Order;
  showLabels: boolean;
  onCancelOrder: (order: Order) => void;
  onAmendOrder: (order: Order, assetName: string) => void;
  collapsible?: boolean;
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
  isCancelling?: boolean;
}
const OrdersRow = ({
  index,
  order,
  onCancelOrder,
  onAmendOrder,
  showLabels,
  collapsible,
  isCollapsed = true,
  toggleCollapse,
  isCancelling,
}: OrderProps) => {
  const { switchMarketById } = useSwitchMarket();
  const { t } = useTranslation();

  const {
    remainingSize,
    initialSize,
    price,
    triggerPrice,
    marketID,
    type,
    side,
    status,
    reason,
  } = order;

  const assetName = getCurrencyFromTradingPair(marketID);
  const valueUsd = initialSize * price;
  const filled = initialSize - remainingSize;
  const decimalPlaces = useGetDecimalPlaces(marketID);

  const handleSetSelectedById = () => {
    switchMarketById(marketID);
  };

  const hasTriggerPrice = hasTriggerPriceInput(type);

  if (collapsible && toggleCollapse)
    return (
      <CollapsibleTableRow
        key={order.id}
        isCollapsed={isCollapsed}
        data-cy={`order-${index}`}
        onClick={toggleCollapse}
        className="order-row"
      >
        <TableCell>
          <MarketLogoWithTradeSide
            onClick={e => {
              e.stopPropagation();
              handleSetSelectedById();
            }}
            limitWidth
            marketID={marketID}
            tradeSide={side}
          />

          {!isCollapsed ? (
            hasTriggerPrice ? (
              <LabelAndValueCell
                showLabel={showLabels}
                limitWidth
                data-cy="order-trigger-price"
                label={t('triggerPrice')}
                isCell={false}
                value={
                  <FormattedNumber
                    value={triggerPrice}
                    decimalScale={decimalPlaces.price}
                    disableTooltip={true}
                  />
                }
                className="ml-32 mt-10"
              />
            ) : (
              <LabelAndValueCell
                showLabel={showLabels}
                limitWidth
                isCell={false}
                data-cy="order-status"
                label={t('status')}
                className="ml-32 mt-10"
                value={<OrderStatusCol status={status} t={t} reason={reason} />}
              />
            )
          ) : null}
        </TableCell>

        <TableCell>
          <LabelAndValueCell
            showLabel={showLabels}
            limitWidth
            isCell={false}
            data-cy="order-quantity"
            label={t('orderSize')}
            value={
              <FormattedNumber
                value={initialSize}
                suffix={` ${assetName}`}
                decimalScale={decimalPlaces.size}
                disableTooltip={true}
              />
            }
          />
          {!isCollapsed ? (
            hasTriggerPrice ? (
              <LabelAndValueCell
                showLabel={showLabels}
                className="mt-10"
                isCell={false}
                limitWidth
                data-cy="order-status"
                label={t('status')}
                value={<OrderStatusCol status={status} t={t} reason={reason} />}
              />
            ) : (
              <LabelAndValueCell
                showLabel={showLabels}
                className="mt-10"
                isCell={false}
                label={t('filledTotal')}
                value={
                  <>
                    <FormattedNumber
                      value={filled}
                      decimalScale={decimalPlaces.size}
                      disableTooltip={true}
                    />{' '}
                    |{' '}
                    <FormattedNumber
                      value={initialSize}
                      decimalScale={decimalPlaces.size}
                      disableTooltip={true}
                    />
                  </>
                }
              />
            )
          ) : null}
        </TableCell>

        <TableCell>
          <LabelAndValueCell
            showLabel={showLabels}
            isCell={false}
            limitWidth
            data-cy="order-notional"
            label={t('valueUsd')}
            value={<FormattedNumber value={valueUsd} prefix="$" />}
          />

          {!isCollapsed ? (
            hasTriggerPrice ? (
              <LabelAndValueCell
                showLabel={showLabels}
                label={t('filledTotal')}
                isCell={false}
                className="mt-10"
                value={
                  <>
                    <FormattedNumber
                      value={filled}
                      decimalScale={decimalPlaces.size}
                      disableTooltip={true}
                    />{' '}
                    |{' '}
                    <FormattedNumber
                      value={initialSize}
                      decimalScale={decimalPlaces.size}
                      disableTooltip={true}
                    />
                  </>
                }
              />
            ) : (
              <LabelAndValueCell
                showLabel={showLabels}
                className="mt-10"
                isCell={false}
                label={t('time')}
                value={timestampToLocalDateWithTime(order.createdAt)}
              />
            )
          ) : null}
        </TableCell>

        <StyledTableCell verticalAlign={!hasTriggerPrice ? 'top' : undefined}>
          <LabelAndValueCell
            showLabel={showLabels}
            limitWidth
            isCell={false}
            data-cy="order-type"
            label={t('type')}
            value={orderTypeToLabel(type, t)}
          />

          {!isCollapsed ? (
            hasTriggerPrice ? (
              <LabelAndValueCell
                showLabel={showLabels}
                className="mt-10"
                isCell={false}
                label={t('time')}
                value={timestampToLocalDateWithTime(order.createdAt)}
              />
            ) : null
          ) : null}
        </StyledTableCell>

        <LabelAndValueCell
          showLabel={showLabels}
          verticalAlign="top"
          data-cy="order-price"
          label={t('price')}
          value={
            shouldShowPrice(type, status) ? (
              <FormattedNumber
                value={price}
                decimalScale={decimalPlaces.price}
                disableTooltip={true}
              />
            ) : (
              '--'
            )
          }
        />

        <StyledTableCell verticalAlign="top" className="action-btns">
          {[OrderStatus.OPEN, OrderStatus.PLACED].includes(order.status) ? (
            <div className="flex justify-end text-right w-100 gap-10 items-center">
              <CollapseIcon isCollapsed={isCollapsed} />
              <Button
                data-cy={`amend-order-btn`}
                iconOnly
                onClick={e => {
                  e.stopPropagation();
                  onAmendOrder(order, assetName);
                }}
                data-gtmid="icon-orders-edit"
              >
                <Icon src={editIcon} alt="Amend order icon" />
              </Button>
              <Button
                data-testid={`cancel-order-btn-${index}`}
                data-cy={`cancel-order-btn`}
                colorVariant="secondary"
                sizeVariant="S"
                onClick={e => {
                  e.stopPropagation();
                  onCancelOrder(order);
                }}
                data-gtmid="button-orders-cancel"
                border="shadesBackground500"
                background="transparent"
                isLoading={isCancelling}
              >
                {t('cancel')}
              </Button>
            </div>
          ) : null}
        </StyledTableCell>
      </CollapsibleTableRow>
    );

  return (
    <TableRow data-cy={`order-${index}`} className="order-row">
      <TableCell
        onClick={handleSetSelectedById}
        className="gap-10 items-center"
      >
        <MarketLogoWithTradeSide marketID={marketID} tradeSide={side} />
      </TableCell>

      <LabelAndValueCell
        showLabel={showLabels}
        data-cy="order-quantity"
        label={t('orderSize')}
        value={
          <FormattedNumber
            value={initialSize}
            suffix={` ${assetName}`}
            decimalScale={decimalPlaces.size}
            disableTooltip={true}
          />
        }
      />

      <LabelAndValueCell
        showLabel={showLabels}
        data-cy="order-notional"
        label={t('valueUsd')}
        value={<FormattedNumber value={valueUsd} prefix="$" />}
      />

      <LabelAndValueCell
        showLabel={showLabels}
        data-cy="order-type"
        label={t('type')}
        value={orderTypeToLabel(type, t)}
      />

      <LabelAndValueCell
        showLabel={showLabels}
        data-cy="order-price"
        label={t('price')}
        value={
          shouldShowPrice(type, status) ? (
            <FormattedNumber
              value={price}
              decimalScale={decimalPlaces.price}
              disableTooltip={true}
            />
          ) : (
            '--'
          )
        }
      />

      {hasTriggerPriceInput(type) && (
        <LabelAndValueCell
          showLabel={showLabels}
          data-cy="order-trigger-price"
          label={t('triggerPrice')}
          value={
            <FormattedNumber
              value={triggerPrice}
              decimalScale={decimalPlaces.price}
              disableTooltip={true}
            />
          }
        />
      )}

      <LabelAndValueCell
        showLabel={showLabels}
        data-cy="order-status"
        label={t('status')}
        value={<OrderStatusCol status={status} t={t} reason={reason} />}
      />

      <LabelAndValueCell
        showLabel={showLabels}
        label={t('filledTotal')}
        value={
          <>
            <FormattedNumber
              value={filled}
              decimalScale={decimalPlaces.size}
              disableTooltip={true}
            />{' '}
            |{' '}
            <FormattedNumber
              value={initialSize}
              decimalScale={decimalPlaces.size}
              disableTooltip={true}
            />
          </>
        }
      />

      <LabelAndValueCell
        showLabel={showLabels}
        alignRight
        label={t('time')}
        value={timestampToLocalDateWithTime(order.createdAt)}
      />

      <TableCell className="action-btns">
        {[OrderStatus.OPEN, OrderStatus.PLACED].includes(order.status) ? (
          <div className="flex justify-end text-right w-100 gap-10 items-center">
            <Button
              data-cy={`amend-order-btn`}
              iconOnly
              onClick={() => onAmendOrder(order, assetName)}
              data-gtmid="icon-orders-edit"
            >
              <Icon src={editIcon} alt="Amend order icon" />
            </Button>
            <Button
              data-testid={`cancel-order-btn-${index}`}
              data-cy={`cancel-order-btn`}
              colorVariant="secondary"
              sizeVariant="S"
              onClick={() => onCancelOrder(order)}
              data-gtmid="button-orders-cancel"
              border="shadesBackground500"
              background="transparent"
              isLoading={isCancelling}
            >
              {t('cancel')}
            </Button>
          </div>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

export default observer(OrdersRow);

export const CollapsibleOrdersRow = observer((props: OrderProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <OrdersRow
      collapsible
      isCollapsed={isCollapsed}
      toggleCollapse={() => setIsCollapsed(prev => !prev)}
      {...props}
    />
  );
});
