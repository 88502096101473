import {
  getCurrencyFromTradingPair,
  removeTrailingDigitsFromTradingPair,
} from 'utils';

import { useAppContext } from 'contexts/AppContext';
import { defaultMarket } from 'mockData/markets';
import { shortCodeToUrl } from 'pages/Portfolio/Pages/ReferralDashboard/InviteFriends/InviteFriendsModal';
import { routes } from 'pages/routes';

import { Market } from 'interfaces';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const getMarketFromPath = (markets: Market[]) => {
  const pathArr = window.location.pathname.split('/');
  const marketIdFromPath = pathArr[pathArr.length - 1];

  return (
    markets.find(
      market =>
        market.id.includes(marketIdFromPath) || // Eg: /trade/BT , /trade/PEP
        marketIdFromPath.includes(getCurrencyFromTradingPair(market.id)), // Eg: /trade/BTCUSD , /trade/BTCCC
    ) || null
  );
};

export const marketIdToRoute = (marketId: string, params: URLSearchParams) => {
  let url =
    routes.trade.getRoutePath() +
    '/' +
    removeTrailingDigitsFromTradingPair(getCurrencyFromTradingPair(marketId));

  if (params) {
    url = `${url}?${params.toString()}`;
  }

  return url;
};

export const useSwitchMarket = () => {
  const [searchParams] = useSearchParams();
  const {
    store: {
      markets: {
        markets,
        getDefaultMarket,
        setSelectedById,
        switchMarket: switchMarketInStore,
        setAvoidPairEffect,
      },
    },
  } = useAppContext();
  const navigate = useNavigate();

  const switchMarketById = (marketId: string | undefined) => {
    if (!marketId) return;

    const upperCasedMarketId = marketId.toUpperCase();
    // const marketIds = markets?.map(market => market.id.toUpperCase());

    // if (!marketIds?.includes(upperCasedMarketId)) return;

    setSelectedById(upperCasedMarketId);

    setAvoidPairEffect(true); // To not run the effect of the pair change, as we are changing manually

    navigate(marketIdToRoute(upperCasedMarketId, searchParams)); // Maintain searchParams in url when switching markets
  };

  const switchMarket = (market: Market) => {
    // const marketIds = markets?.map(market => market.id.toUpperCase());

    // if (!marketIds?.includes(upperCasedMarketId)) return;

    switchMarketInStore(market);

    setAvoidPairEffect(true); // To not run the effect of the pair change, as we are changing manually

    navigate(marketIdToRoute(market.id.toUpperCase(), searchParams)); // Maintain searchParams in url when switching markets
  };

  const navigateToTrade = () => {
    if (markets) {
      const marketId = getDefaultMarket(markets)?.id || defaultMarket.id;
      switchMarketById(marketId);
    }
  };

  return {
    switchMarketById,
    navigateToTrade,
    switchMarket,
  };
};
