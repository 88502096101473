import { forwardRef } from 'react';

import { getStyles, StatusBasedStyles } from 'utils';

import { NotificationStyled } from './styles';
import closeIcon from 'assets/icons/close-x.svg';

import { NotificationType } from 'enums';
import { closeSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import { useTheme } from 'styled-components';

export interface CustomToastProps extends CustomContentProps {
  type: NotificationType;
  title: string;
  description?: string;
}

const CustomToast = forwardRef<HTMLDivElement, CustomToastProps>(
  ({ id, ...props }, ref) => {
    const theme = useTheme();

    const styles: StatusBasedStyles = getStyles(theme, props.type);

    return (
      <SnackbarContent ref={ref}>
        <NotificationStyled
          titleColor={styles.title}
          backgroundColor={styles.background}
        >
          <img
            src={styles.icon}
            className="notification-icon"
            alt="Notification icon"
          />
          <div className="content">
            <h3 data-cy="notification-title">{props.title}</h3>
            {props.description ? (
              <p data-cy="notification-description">{props.description}</p>
            ) : null}
          </div>
          <img
            src={closeIcon}
            alt="Close icon"
            className="close-icon cursor-pointer"
            onClick={() => closeSnackbar()}
          />
        </NotificationStyled>
      </SnackbarContent>
    );
  },
);

CustomToast.displayName = 'CustomToast';

export default CustomToast;
