import styled from 'styled-components';

type Props = {
  rangeLength: number;
};
export const DatePickerContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  gap: 20px;
  box-shadow: 2px 2px 40px 0px rgba(23, 23, 27, 0.8);
  border-radius: 8px;

  border: 1.5px solid ${({ theme }) => theme.colors.shadesBackground700};
  background: ${({ theme }) => theme.colors.shadesBackground800};

  @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
    gap: 10px;
  }

  .arrow-icon {
    height: 16px;
    width: 16px;
  }

  .react-daterange-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .react-daterange-picker__wrapper {
    display: none;
  }

  .react-daterange-picker__button {
    display: none;
  }

  .react-calendar__navigation {
    display: flex;
    width: auto;
    flex-wrap: nowrap;
  }

  .react-daterange-picker__calendar {
    display: flex;
    position: relative !important;
    height: auto !important;
    inset: initial !important;
  }

  .react-daterange-picker__inputGroup {
    display: flex;
    flex-grow: 1;
    border-radius: 8px;
    padding: 12px 15px;
    height: auto;
    justify-content: center;
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }

  .react-daterange-picker__inputGroup__input {
    min-width: 1.25em;

    :invalid {
      background-color: transparent;
    }
  }

  .react-daterange-picker__range-divider {
    color: ${({ theme }) => theme.colors.white};
    margin: 0px 10px;
  }

  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label,
  .react-calendar__month-view__weekdays__weekday,
  .react-calendar__tile {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    width: 32px;
    height: 32px;
    font-size: 11px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${({ theme }) => theme.colors.shadesBackground500};
  }

  .react-calendar__tile--active {
    border-radius: 0;
    background-color: ${({ theme }) => theme.colors.shadesBackground700};
    color: ${({ theme }) => theme.colors.white};
  }

  .react-calendar__tile--rangeStart {
    background-color: ${({ theme }) => theme.colors.positiveForeground300};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    border-radius: ${({ rangeLength }) =>
      rangeLength > 1 ? '100px 0 0 100px' : '100px'};
  }

  .react-calendar__tile--rangeEnd {
    background-color: ${({ theme }) => theme.colors.positiveForeground300};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    border-radius: ${({ rangeLength }) =>
      rangeLength > 1 ? '0 100px 100px 0' : '100px'};
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 11px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    abbr[title] {
      text-decoration: none;
    }
  }

  .react-calendar__navigation__label__labelText {
    font-size: 13px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation__prev2-button {
    display: none;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;

  .input-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 10px;
    border-radius: 8px;
    padding: 15px;
    background: ${({ theme }) => theme.colors.shadesBackground700};

    font-size: 11px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};

    > img {
      height: 14px;
      width: 14px;
    }
  }
`;
