import { Config } from './config';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .value-approximation {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.shadesForeground300};
    margin-top: 8px;
  }
`;

type ContainerProps = {
  showError?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
  showSwapCurrency?: boolean;
  type: 'default' | 'outline';
};
export const InputContainer = styled.div<ContainerProps>`
  background: ${({ type }) => Config[type].background};
  border-radius: 8px;
  padding: ${({ type }) => Config[type].padding};
  flex-direction: column;
  border: ${({ type }) => Config[type].border};

  &:hover {
    border: ${({ type }) => Config[type].borderHovered};
  }

  .label {
    font-size: 12px;
    line-height: 14px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ type }) => Config[type].label.color};
    gap: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .value-content {
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    /* @media (min-width: ${({ theme }) => theme.screenSizes.xxl}px) {
      height: 24px;
    } */

    input.value {
      min-width: 0;
      width: 100%;
      padding-left: 0;
      flex: 1;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: 12px;
      line-height: 18px;
      background: transparent;
      border: 0;
      outline: none;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
      font-family: ${({ theme }) => theme.fontFamilyNumbers};
      font-variant: tabular-nums;
      color: ${({ theme }) => theme.colors.white};
      ::placeholder {
        color: ${({ theme }) => theme.colors.shadesForeground400};
      }

      @media (max-width: ${({ theme }) => theme.screenSizes.smaller}px) {
        font-size: 16px;
      }

      @media (min-width: ${({ theme }) => theme.screenSizes.xxl}px) {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .currency-container {
      cursor: ${({ showSwapCurrency }) =>
        showSwapCurrency ? 'pointer' : 'cursor'};
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      line-height: 14px;
      color: ${({ theme }) => theme.colors.shadesForeground300};
      .currency-icon {
        width: 16px;
      }

      @media (min-width: ${({ theme }) => theme.screenSizes.xxl}px) {
        font-size: 14px;
        line-height: 16px;

        .currency-icon {
          width: 20px;
        }
      }
    }
  }

  ${({ isFocused, type }) =>
    isFocused &&
    css`
      &.container {
        border: ${Config[type].borderFocused};
      }
    `};

  ${({ showError, type }) =>
    showError &&
    css`
      &.container {
        border: ${Config[type].borderError};
      }
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      &.container {
        pointer-events: none;
        background: ${({ theme }) => theme.colors.shadesBackground700};
      }

      .label,
      .value-content input.value,
      .currency-container .currency {
        color: ${({ theme }) => theme.colors.shadesForeground400};
      }

      .currency-icon {
        opacity: 0.5;
      }
    `}
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.negativeForeground200};
  font-size: 12px;
  margin-top: 5px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
