import { memo } from 'react';

import {
  EmptyProgressBar,
  FillingProgressBar,
  MainProgressBarDiv,
} from './styles';

import { useTheme } from 'styled-components';

const POSITIVE_THRESHOLD = 50;
const NEUTRAL_THRESHOLD = 3.5;

const getBarColor = (percent: number, theme: any): string => {
  if (percent > POSITIVE_THRESHOLD) {
    return theme.colors.positiveForeground200;
  } else if (percent > NEUTRAL_THRESHOLD) {
    return theme.colors.warningForeground100;
  }
  return theme.colors.negativeForeground200;
};

interface Props {
  percent: number;
  withChangingBarColor?: boolean;
  customBarColor?: string;
  barClass?: string;
  className?: string;
}
const LinearProgressBar = ({
  percent,
  withChangingBarColor = false,
  customBarColor,
  barClass,
  className,
}: Props) => {
  const theme = useTheme();
  const barColor =
    customBarColor ||
    (withChangingBarColor ? getBarColor(percent, theme) : 'rgb(48, 143, 232)');

  return (
    <MainProgressBarDiv className={className}>
      <EmptyProgressBar style={{ width: '100%' }} className={barClass}>
        <FillingProgressBar
          style={{
            left: percent - 100 + '%',
            transition: '2s',
            background: barColor,
          }}
        />
      </EmptyProgressBar>
    </MainProgressBarDiv>
  );
};

export default memo(LinearProgressBar);
