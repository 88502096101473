import {
  PlatformVaultHoldingsResponse,
  PlatformVaultResponse,
  PlatformVaultsDataWithHoldings,
  useBalanceOperationsAPI,
  usePlatformVaultsAPI,
} from 'hooks';
import { usePaginationFetch } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';

import { useAppContext } from 'contexts/AppContext';
import { VaultBalanceOps } from 'interfaces/vaultBalanceOps';
import { getIsDeposit } from 'pages/Vaults/Pages/Vaults/TabPanels/PlatformVaults/VaultActivityTable/ActivityRow';

import { QueryKeys } from 'enums';
import { useQuery } from 'react-query';

export function useFetchPlatformVaults() {
  const { fetchVaults } = usePlatformVaultsAPI();
  return useQuery(
    [QueryKeys.PlatformVaults],
    async () => {
      const vaults = await fetchVaults();
      return vaults;
    },
    {
      refetchInterval: 60_000,
      // 45 seconds
      staleTime: 45_000,
    },
  );
}

export function useFetchPlatformVaultsHoldings() {
  const { fetchVaultsHoldings } = usePlatformVaultsAPI();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  return useQuery(
    [QueryKeys.PlatformVaultsHoldings, frontendSecrets?.profile.wallet],
    async () => {
      if (!frontendSecrets?.profile.wallet) return;

      const vaultsHoldings = await fetchVaultsHoldings();
      return vaultsHoldings;
    },
    {
      refetchInterval: 60_000,
      // 45 seconds
      staleTime: 45_000,
      enabled: !!frontendSecrets?.profile.wallet,
    },
  );
}

export function useFetchPlatformVaultsDataWithHoldings(
  vaultWallets: string = '',
  isEnabled = true,
  disableRefetch?: boolean,
) {
  const { fetchVaults, fetchVaultsHoldings } = usePlatformVaultsAPI();

  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  return useQuery(
    [
      QueryKeys.PlatformVaultsDataWithHoldings,
      frontendSecrets?.profile.wallet,
      vaultWallets,
    ],
    async () => {
      try {
        const promises = [fetchVaults(vaultWallets)];

        if (frontendSecrets?.profile.wallet) {
          promises.push(fetchVaultsHoldings(vaultWallets) as Promise<any>);
        }

        const [vaultsData, vaultsHoldings] = (await Promise.all(promises)) as [
          PlatformVaultResponse[],
          PlatformVaultHoldingsResponse[],
        ];

        if (!vaultsData) {
          return;
        }

        if (!vaultsHoldings) {
          return vaultsData as PlatformVaultsDataWithHoldings[];
        }

        const vaultsDataWithHoldings = vaultsData.map(vault => {
          const holdings = vaultsHoldings.find(
            holdings => holdings.vaultProfileId === vault.vaultProfileId,
          );

          if (!holdings) {
            return vault as PlatformVaultsDataWithHoldings;
          }

          return {
            ...vault,
            holdings,
          } as PlatformVaultsDataWithHoldings;
        });

        return vaultsDataWithHoldings;
      } catch (e) {
        console.error('Error fetching vaults data with holdings', e);
        throw e;
      }
    },
    {
      enabled: isEnabled,
      refetchInterval: disableRefetch ? false : 15_000,
      staleTime: 5_000,
    },
  );
}

export function useFetchPrivateVaultBalanceOps(
  vaultAddress: string | undefined,
) {
  const { fetchPrivateVaultBalanceOps } = useBalanceOperationsAPI();
  const { isOnboarding } = useExchangeAPI();
  const {
    store: {
      account: { frontendSecrets, _vaultBalanceOperations },
    },
  } = useAppContext();

  return usePaginationFetch<VaultBalanceOps>({
    fetchFn: fetchPrivateVaultBalanceOps,
    socketSource: _vaultBalanceOperations,
    initialPagination: { page: 0, limit: 500 },
    currentAuthenticatedWallet: frontendSecrets?.profile?.wallet,
    isOnboarding,
    queryKey: `${QueryKeys.VaultsBalanceOps}, ${vaultAddress}`,
    queryOptions: {
      refetchInterval: 10_000,
    },
    requestQueryParams: vaultAddress
      ? {
          vault_wallet: vaultAddress,
        }
      : undefined,
  });
}

export type FetchPlatformVaultPnlResponse =
  | {
      pnl?: number;
      percentChange?: number;
    }
  | undefined;
export const useFetchPlatformVaultPnl = (
  vaultAddress: string | undefined,
): FetchPlatformVaultPnlResponse => {
  const { isLoading: isLoadingVaultBalanceOps, data: vaultBalanceOps } =
    useFetchPrivateVaultBalanceOps(vaultAddress);

  const { data: vaultsData, isLoading: isLoadingVaultsData } =
    useFetchPlatformVaultsDataWithHoldings();

  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  if (
    isLoadingVaultBalanceOps ||
    isLoadingVaultsData ||
    !vaultBalanceOps ||
    !vaultsData ||
    !vaultsData[0] ||
    !frontendSecrets?.profile.wallet
  ) {
    return;
  }

  const stakesUsdSum = vaultBalanceOps
    .filter(
      balanceOp => getIsDeposit(balanceOp) && balanceOp.status === 'success',
    )
    .reduce((acc, balanceOp) => {
      return acc + balanceOp.stakeUSDT;
    }, 0);

  const unstakesUsdSum = vaultBalanceOps
    .filter(
      balanceOp => !getIsDeposit(balanceOp) && balanceOp.status === 'success',
    )
    .reduce((acc, balanceOp) => {
      return acc + balanceOp.unstakeUSDT;
    }, 0);

  const vaultsNavSum = vaultsData.reduce((acc, vault) => {
    if (!vault.holdings) return acc;

    return acc + vault.holdings.netWithdrawable;
  }, 0);

  const pnl = vaultsNavSum + unstakesUsdSum - stakesUsdSum;
  const percentChange =
    vaultsNavSum > 1 && pnl > 0.5 ? (pnl / vaultsNavSum) * 100 : 0;

  return {
    pnl: pnl > 0 ? pnl : 0,
    percentChange: percentChange > 0 ? percentChange : 0,
  };
};
