export enum RiskLevel {
  LOW = 'low',
  MID = 'medium',
  HIGH = 'high',
  UNKNOWN = 'unknowm',
}

export const getRiskLevelFromLeverage = (leverage: number | null) => {
  if (!leverage) return RiskLevel.UNKNOWN;
  if (leverage < 5) return RiskLevel.LOW;
  if (leverage < 15) return RiskLevel.MID;
  return RiskLevel.HIGH;
};
