import { useEffect, useState } from 'react';

import { Box, Tab, Tabs } from '@material-ui/core';

import { TabPanel, a11yProps } from './AirdropPhaseTab';
import PhaseContainer from './Phases/PhaseContainer';
import PhaseOne from './Phases/PhaseOne';
import PhaseTwo from './Phases/PhaseTwo';
import { AirdropPhases, AirdropPhasesTabsStyled, Container } from './styles';
import moneyNeverSleepsImg from 'assets/images/money-never-sleeps.png';
import showMeTheMoneyImg from 'assets/images/show-me-the-money.png';
import { useAppContext } from 'contexts/AppContext';

import axios from 'axios';
import { config } from 'config';
import { QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

const ClaimAirdrop = () => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(1);

  const {
    isLoading,
    data: airdropUserStats,
    isError,
  } = useQuery(
    [QueryKeys.AirdropUserStats],
    async () => {
      try {
        if (!frontendSecrets) return;
        const { status, data } = await axios.get(
          `${config.airdropProfileStatsLambdaUrl}/?profileId=${frontendSecrets.profile.id}`,
          {
            validateStatus: function (status: number) {
              return status >= 200 && status <= 404;
            },
          },
        );

        // no profile ID provided
        if (status === 400) {
          throw new Error('Invalid profile id');
        }

        // profile with this ID does not exist
        if (status === 404) {
          return {};
        }

        return data;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    { refetchInterval: false, enabled: !!frontendSecrets?.jwt },
  );

  useEffect(() => {
    if (frontendSecrets?.profile.id === undefined) {
      queryClient.setQueryData([QueryKeys.AirdropUserStats], {});
      queryClient.setQueryData([QueryKeys.Airdrops], undefined);
    }
  }, [queryClient, frontendSecrets?.profile.id]);

  return (
    <Container>
      <h5>{t('claimAirdrop')}</h5>
      <AirdropPhases>
        <AirdropPhasesTabsStyled>
          <Box>
            <Tabs
              value={activeTab}
              onChange={(event, newValue) => setActiveTab(newValue)}
              aria-label="Airdrop phases tabs"
            >
              <Tab label={t('phaseOne')} {...a11yProps(0)} />
              <Tab label={t('phaseTwo')} {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={activeTab} index={0}>
            <PhaseContainer
              airdropUserStats={airdropUserStats}
              phaseImg={showMeTheMoneyImg}
              phase="one"
            >
              <PhaseOne
                airdropUserStats={airdropUserStats}
                isLoadingAirdropUserStats={isLoading}
                isErrorAirdropUserStats={isError}
              />
            </PhaseContainer>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <PhaseContainer
              airdropUserStats={airdropUserStats}
              phaseImg={moneyNeverSleepsImg}
              phase="two"
            >
              <PhaseTwo />
            </PhaseContainer>
          </TabPanel>
        </AirdropPhasesTabsStyled>
      </AirdropPhases>
    </Container>
  );
};

export default observer(ClaimAirdrop);
