import { useCallback, useState } from 'react';

import useModal from 'hooks/useModal';

import Button from 'components/Button/button';
import { ConfirmAllOrdersCancelModal } from 'components/Tables/OrdersTable/ConfirmAllOrdersCancelModal';
import CheckBox from 'components/Toggle/Checkbox';

import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { Row } from 'theme/globalStyledComponents/row';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type Props = {
  shouldShowCancelAllOrders: boolean;
  cancelOrdersDisabled: boolean;
};
const ShowAllMarketsAndCancelAllOrders = ({
  shouldShowCancelAllOrders,
  cancelOrdersDisabled,
}: Props) => {
  const {
    store: {
      appState: { showAllMarkets, setShowAllMarkets },
    },
  } = useAppContext();
  const [shouldShowAllMarkets, setShouldShowAllMarkets] =
    useState<boolean>(showAllMarkets);

  const handleShowAllMarketsChange = () => {
    setShouldShowAllMarkets(prev => {
      setShowAllMarkets(!prev);
      return !prev;
    });
  };
  const modal = useModal();
  const { t } = useTranslation();

  const handleCancelAllOrders = useCallback(() => {
    modal.present(
      <ConfirmAllOrdersCancelModal />,
      Modals.confirmAllOrdersCancelModal,
    );
  }, [modal]);

  return (
    <Row flex={1} gap={20} justify="flex-end">
      <CheckBox
        data-gtmid="checkbox-show-all-markets"
        label={t('showAllMarkets')}
        onClick={handleShowAllMarketsChange}
        isChecked={shouldShowAllMarkets}
        color="white"
      />
      {shouldShowCancelAllOrders ? (
        <Button
          onClick={handleCancelAllOrders}
          colorVariant="secondary"
          sizeVariant="S"
          disabled={cancelOrdersDisabled}
        >
          {t('cancelAll')}
        </Button>
      ) : null}
    </Row>
  );
};

export default observer(ShowAllMarketsAndCancelAllOrders);
