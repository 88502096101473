import TagManager from 'react-gtm-module';

export const initializeGoogleTagManager = () => {
  const gtmId = process.env.REACT_APP_GTM_ID;
  if (!gtmId) {
    console.log('No GTM ID was provided');
    return;
  }

  const tagManagerArgs = {
    gtmId,
  };

  TagManager.initialize(tagManagerArgs);
};

/**
 * Pushes an event to the GTM dataLayer
 */
export const pushDataLayerEvent = (event: string) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) {
    return;
  }

  dataLayer.push({
    event,
  });
};
