import { useEffect, useMemo, useRef, useState } from 'react';

import { TransactionReceipt, Web3Provider } from '@ethersproject/providers';
import { parseUnits } from '@ethersproject/units';

import { Tooltip } from '@material-ui/core';
import { TransakConfig, Transak } from '@transak/transak-sdk';
import { useWeb3React } from '@web3-react/core';

import {
  bigNumberToFloat,
  roundJsDecimalToString,
  showNotification,
  verifyUserSession,
} from 'utils';
import { showMeshUpdateToast } from 'utils/activeMeshTransactions';
import {
  hasRequiredConfirmations,
  removeTxFromLocalStorage,
  saveTxToLocalStorage,
  showToast,
} from 'utils/activeTransactions';
import { brand, brandedSelect } from 'utils/brand';

import { useActiveWeb3React, useOnClickOutside, useVerifyChainId } from 'hooks';
import { IBinanceCheckoutData, useBinancePayApi } from 'hooks/useBinancePayApi';
import { useDepositsAPI } from 'hooks/useDepositsAPI';
import { useMeshAPI } from 'hooks/useMeshAPI';
import useModal from 'hooks/useModal';
import useOnBlockUpdated from 'hooks/useOnBlockUpdated';
import useUniswapSdk from 'hooks/useUniswapSdk';

import DepositInProgress from 'components/CustomToasts/DepositInProgress';
import Loading from 'components/Loading';
import Modal from 'components/Modal';

import AccountEquity from './AccountEquity';
import { ActionSection } from './ActionSection';
import { BinanceOrderCheckout } from './Steps/BinanceOrderCheckout';
import ChainAndAssetSelector from './Steps/DepositStep/ChainAndAsset';
import DepositInput from './Steps/DepositStep/DepositInput';
import DepositMethods from './Steps/DepositStep/DepositMethods';
import { MeshOrderStatus } from './Steps/MeshOrderStatus';
import { MESH_ORDER_STATUS } from './Steps/MeshOrderStatus/meshOrderStatus';
import SuccessStep from './Steps/SuccessStep';
import TransakStatus from './Steps/TansakStatus';
import WaitingStep from './Steps/WaitingStep';
import SwapDetails from './SwapDetails';
import { NATIVE_CURRENCY, uniswapSwapTokensMap } from './constants';
import { ApproveTooltip, DepositContainer } from './styles';
import {
  DepositSteps,
  getCurrentDepositStep,
  getCurrentSwapStep,
  getIsMethodTransak,
  getShouldDisableDepositBtn,
  getShouldDisableSwapBtn,
  isTargetToken,
  isWaitingStep,
  SwapSteps,
} from './utils';
import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';
import { Modals } from 'constants/modals';
import { NetworkContextName } from 'constants/networkContextName';
import { useAppContext } from 'contexts/AppContext';
import { Interface, Logger } from 'ethers/lib/utils';
import { TransakEvent } from 'interfaces/transakEvent';
import { mixpanelService } from 'service/mixpanelService';

import { config } from 'config';
import {
  DepositMethod,
  Chain,
  QueryKeys,
  NotificationType,
  LocalStorageTransactionTypes,
  UniswapSwapTokens,
  MeshIntegration,
} from 'enums';
import { BigNumber } from 'ethers';
import { ProfileBalanceOps } from 'interfaces';
import { observer } from 'mobx-react';
import Pusher from 'pusher-js';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

export type TransakPaymentMethod =
  | 'credit_debit_card'
  | 'apple_pay'
  | 'google_pay'
  | 'sepa_bank_transfer'
  | 'gbp_bank_transfer'
  | 'inr_bank_transfer';

const depositToTransakPaymentMethodMap: Partial<{
  [key in DepositMethod]: TransakPaymentMethod;
}> = {
  [DepositMethod.Card]: 'credit_debit_card',
  [DepositMethod.ApplePay]: 'apple_pay',
  [DepositMethod.GooglePay]: 'google_pay',
  [DepositMethod.BinancePay]: 'credit_debit_card',
};

export const TargetToken = brandedSelect({
  rabbitx: UniswapSwapTokens.USDT,
  bfx: UniswapSwapTokens.USDB,
});

export const DEFAULT_SLIPPAGE = 0.5;

export const ESTIMATED_GAS_FEE_OFFSET = 0.0001;

// Public channel for all transak order events
let pusher = new Pusher('1d9ffac87de599c61283', { cluster: 'ap2' });

const getIndividualDepositCalldata = (
  contributor: string,
  amount: string | null,
) => {
  // Method ID for individualDeposit
  if (!amount) return;

  // ABI for individualDeposit function on our SmartContract
  let ABI = ['function individualDeposit(address contributor, uint256 amount)'];

  // Constructing the CallData
  return new Interface(ABI).encodeFunctionData('individualDeposit', [
    contributor,
    parseUnits(amount, 6),
  ]);
};

export enum TransakOrderStatus {
  PROCESSING = 'PROCESSING',
  AWAITING_DELIVERY = 'AWAITING_DELIVERY',
}

const DepositModal = () => {
  const queryClient = useQueryClient();
  const { account } = useActiveWeb3React();
  const { t } = useTranslation();
  const { library: networkProvider } =
    useWeb3React<Web3Provider>(NetworkContextName);

  const { fetchLinkTokenAndOpenLink, meshState } = useMeshAPI();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();
  const {
    USDTDecimals,
    isUsdtApproved: isUsdtApprovedAPI,
    approve: approveAPI,
    transferUsdtToL1SmartContract,
    sendTxHashToBackend,
    getUsdtAllowance,
    areReadContractsSet,
  } = useDepositsAPI();
  const modal = useModal();
  const {
    store: { account: accountStore },
  } = useAppContext();

  const [slippage, setSlippage] = useState<number>(DEFAULT_SLIPPAGE);
  const [selectedChain, setSelectedChain] = useState<Chain>(
    brandedSelect({ rabbitx: Chain.ETH, bfx: Chain.Blast }),
  );
  const [selectedToken, setSelectedToken] =
    useState<UniswapSwapTokens>(TargetToken);
  const [isSwapping, setIsSwapping] = useState(false);
  const [isApprovingTokenForSwap, setisApprovingTokenForSwap] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [depositMethod, setDepositMethod] = useState(DepositMethod.Wallet);
  const [depositAmount, setDepositAmount] = useState<number | null>(null);
  const [isApprovingUsdt, setIsApprovingUsdt] = useState(false);
  const [isPendingTxSign, setIsPendingTxSign] = useState(false);
  const [transakOrderStatus, setTransakOrderStatus] =
    useState<TransakOrderStatus>();
  const [transakPaymentMethod, setTransakPaymentMethod] =
    useState<TransakPaymentMethod>();
  const [isAwaitingConfirmation, setIsAwaitingConfirmation] = useState(false);
  const [usdtAllowance, setUsdtAllowance] = useState<number>(0);
  const [depositResultData, setDepositResultData] = useState<
    ProfileBalanceOps | undefined
  >(undefined);
  const [confirmations, setConfirmations] = useState<number>(0);
  const [depositToastId, setDepositToastId] = useState<React.ReactText | null>(
    null,
  );
  const blockHandlerRef = useRef<(blockNumber: number) => void>();
  const meshLinkCloseFnRef = useRef<() => void>(() => {});

  const [isDepositConfirmed, setIsDepositConfirmed] = useState(false);
  const [isTransakModalOpen, setTransakModalOpen] = useState(false);
  const [isOpeningMeshModal, setIsOpeningMeshModal] = useState(false);
  const [isOpeningBinancePayCheckout, setIsOpeningBinancePayCheckout] =
    useState(false);
  const [binanceCheckoutData, setBinanceCheckoutData] =
    useState<IBinanceCheckoutData>();

  const [selectedMeshIntegration, setSelectedMeshIntegration] =
    useState<MeshIntegration>(MeshIntegration.Others);

  const { startBinanceTransfer } = useBinancePayApi();

  const modalId = useMemo(() => {
    const id = Date.now().toString();
    modal.setCurrentModalId(id);
    return id;
  }, []);

  const updateModalMetadataOnConfirmation = (confirmations: number) => {
    modalId &&
      modal.setModalMetadata(modalId, {
        description: `${confirmations}/${CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT} confirmations`,
      });
  };

  const {
    getQuote,
    getCurrencyBalance,
    createTrade,
    executeTrade,
    isTokenApprovedForSwap,
    approveTokenForSwap,
  } = useUniswapSdk();

  useOnClickOutside(
    undefined,
    meshLinkCloseFnRef.current,
    'mesh-link-popup__popup-content', // Element id for mesh popup
  );

  useOnBlockUpdated(() => {
    refetchCurrencyBalance();
    refetchQuote();
  });

  useEffect(() => {
    if (!account) return;
    mixpanelService.depositNavigate(account);
  }, [account]);

  const isMethodTransak = getIsMethodTransak(depositMethod);

  const isMethodAWalletTransfer = !(
    isMethodTransak ||
    [DepositMethod.Mesh, DepositMethod.BinancePay].includes(depositMethod)
  );

  const onSetSelectedToken = (token: UniswapSwapTokens) => {
    if (!isTargetToken(token)) {
    }
    setSelectedToken(token);
  };

  const openTransak = ({
    walletAddress,
    defaultPaymentMethod,
  }: {
    walletAddress: string;
    defaultPaymentMethod: TransakPaymentMethod;
  }) => {
    setTransakModalOpen(true);

    const roundedDepositAmount = Number(
      roundJsDecimalToString(depositAmount!, 6),
    );
    const calldata = getIndividualDepositCalldata(
      walletAddress,
      roundedDepositAmount.toString(),
    );

    if (!calldata) return;

    const settings: TransakConfig = {
      apiKey: config.transakApiKey,
      environment: config.isProd
        ? Transak.ENVIRONMENTS.PRODUCTION
        : Transak.ENVIRONMENTS.STAGING,
      defaultCryptoAmount: roundedDepositAmount || undefined,
      cryptoCurrencyList: [brand.tokenTicker],
      themeColor: '000000',
      defaultPaymentMethod,
      walletAddress,
      exchangeScreenTitle: 'Deposit Funds',
      disableWalletAddressForm: true,
      smartContractAddress: config.transakContractAddress,
      estimatedGasLimit: 70_000,
      calldata,
      sourceTokenData: [
        {
          sourceTokenCode: config.isProd ? brand.tokenTicker : 'USDR',
          sourceTokenAmount: roundedDepositAmount,
        },
      ] as any,
      network: 'ethereum',
      isTransakOne: true,
    };

    const transak = new Transak(settings);

    transak.init();

    const subscribeToWebsockets = (orderId: string) => {
      let channel = pusher.subscribe(orderId);

      channel.bind('ORDER_PROCESSING', (orderData: any) => {
        if (orderData?.status === 'PENDING_DELIVERY_FROM_TRANSAK') {
          setTransakOrderStatus(TransakOrderStatus.AWAITING_DELIVERY);
        }
      });

      // receive updates of a specific event
      channel.bind('ORDER_COMPLETED', (orderData: any) => {
        console.log('ORDER COMPLETED websocket event', orderData);
        if (orderData?.transactionHash) {
          processDepositOnIndividualContract(
            orderData.transactionHash,
            orderData.cryptoAmount,
          );
          setTransakOrderStatus(undefined);
          pusher.unsubscribe(orderId);
        }
      });

      channel.bind('ORDER_FAILED', async (orderData: TransakEvent) => {
        console.log('ORDER FAILED websocket event', orderData);
        pusher.unsubscribe(orderId);
        await resetState();
        transak.close();
        onFailed();
      });
    };

    // /*
    //  * This will trigger when the user marks payment is made
    //  * You can close/navigate away at this event
    //  */
    // Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
    //   const eventData = orderData as TransakEvent;
    //   if (eventData.status?.transactionHash) {
    //     processDepositOnIndividualContract(
    //       orderData.status.transactionHash,
    //       orderData.status.cryptoAmount,
    //     );
    //     transak.close();
    //     onFailed();
    //   }
    // });

    /*
     * This will trigger when the user marks payment is made
     * You can close/navigate away at this event
     */
    Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
      console.log('callback transak order sucessfully created', orderData);
      const eventData = orderData as TransakEvent;

      const orderId = eventData.status?.id;

      if (!orderId) {
        return;
      }

      subscribeToWebsockets(orderId);

      flushSync(() => {
        // set is waiting delivery from transak
        setTransakPaymentMethod(eventData.status?.paymentOptionId);
        setTransakOrderStatus(TransakOrderStatus.PROCESSING);
        // setIsAwaitingConfirmation(true);
        setIsPendingTxSign(false);
        setDepositAmount(eventData.status.cryptoAmount);
      });
      setTransakModalOpen(false);
      transak.close();
    });

    const onFailed = () => {
      showNotification({
        title: 'Fiat On-Ramp Deposit Failed',
        description: 'Your fiat on-ramp deposit failed. Please try again.',
        type: NotificationType.Negative,
      });
      setTransakModalOpen(false);
    };

    Transak.on(Transak.EVENTS.TRANSAK_ORDER_CANCELLED, () => {
      onFailed();
    });

    Transak.on(Transak.EVENTS.TRANSAK_ORDER_FAILED, () => {
      onFailed();
    });

    Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
      setTransakModalOpen(false);
    });
  };

  const onSetDepositMethod = (method: DepositMethod) => {
    // fetchAllowedMeshIntegrations();
    if (getIsMethodTransak(method) || method === DepositMethod.Mesh) {
      setSelectedToken(TargetToken);
    }
    // if (isTransakMethod(method)) {
    //   openTransak({
    //     walletAddress: account as string,
    //     defaultPaymentMethod: depositToTransakPaymentMethodMap[
    //       method
    //     ] as TransakPaymentMethod,
    //   });
    // }
    setDepositMethod(method);
  };

  const {
    isLoading: isTokenApprovedForSwapLoading,
    data: isTokenApprovedForSwapData,
    refetch: refetchIsTokenApprovedForSwap,
  } = useQuery(
    [QueryKeys.IsTokenApprovedForSwap, account, depositAmount, selectedToken],
    () =>
      isTokenApprovedForSwap({
        token: uniswapSwapTokensMap[selectedToken],
        walletAddress: account!,
        amount: depositAmount!,
      }),
    {
      enabled: !!depositAmount && !isTargetToken(selectedToken) && !!account,
      // 10 seconds
      staleTime: 10_000,
    },
  );

  const {
    isLoading: isCurrencyBalanceLoading,
    data: selectedTokenBalance,
    refetch: refetchCurrencyBalance,
  } = useQuery(
    [QueryKeys.CurrencyBalance, account, selectedToken],
    async () => {
      const isNative = selectedToken === UniswapSwapTokens.ETH;
      const res = await getCurrencyBalance(
        account as string,
        isNative ? NATIVE_CURRENCY : uniswapSwapTokensMap[selectedToken],
      );

      if (isNative) {
        if (res > ESTIMATED_GAS_FEE_OFFSET) {
          return res - ESTIMATED_GAS_FEE_OFFSET;
        }
        return 0;
      }

      return res;
    },
    {
      enabled: !!account,
      // 10 seconds
      staleTime: 30_000,
    },
  );

  const {
    isLoading: isQuoteLoading,
    data: quote,
    refetch: refetchQuote,
  } = useQuery(
    [QueryKeys.SwapQuote, depositAmount, selectedToken],
    () =>
      getQuote({
        tokenIn: uniswapSwapTokensMap[selectedToken],
        amountIn: depositAmount!,
      }),
    {
      enabled: !!depositAmount && !isTargetToken(selectedToken),
      // 10 seconds
      staleTime: 10_000,
    },
  );

  const onRefreshQuote = () => {
    queryClient.removeQueries(QueryKeys.SwapQuote);
    refetchQuote();
  };

  const onSwap = async () => {
    try {
      if (!depositAmount || !account) return;

      if (!(await validateNetworkAndSwitchIfRequired('Initiate Swap Again'))) {
        return;
      }

      flushSync(() => setIsPendingTxSign(true));

      showNotification({
        type: NotificationType.Info,
        title: 'Swap Started',
        description:
          'Your swap has started. Confirm the transaction in your wallet.',
      });
      const trade = await createTrade({
        tokenIn: uniswapSwapTokensMap[selectedToken],
        amountIn: depositAmount,
      });
      const tx = await executeTrade({
        trade,
        tokenIn: uniswapSwapTokensMap[selectedToken],
        amount: depositAmount as number,
        walletAddress: account as string,
        slippageTolerance: slippage,
      });

      flushSync(() => setIsSwapping(true));

      showNotification({
        type: NotificationType.Positive,
        title: 'Swap transaction sent',
        description:
          'Your swap transaction has been sent. Waiting for it to complete.',
      });

      await tx.wait();

      showNotification({
        type: NotificationType.Positive,
        title: 'Swap Successful',
        description: 'Your swap was successful.',
      });

      // The swap was successful, switch to target token
      setSelectedToken(TargetToken);
      // Set the deposit amount to the output amount of the swap
      const amount = Number(trade.outputAmount.toExact());
      refetchCurrencyBalance();
      setDepositAmount(amount);
      depositUsdt(amount);
      // Initiate deposit
    } catch (e) {
      console.error(e);
      showNotification({
        type: NotificationType.Negative,
        title: 'Swap Failed',
        description: 'Your swap failed. Please try again.',
      });
      setIsPendingTxSign(false);
    } finally {
      refetchCurrencyBalance();
      refetchQuote();
      flushSync(() => {
        setIsSwapping(false);
      });
    }
  };

  const walletBalance = accountStore?.accountStats?.balance ?? 0;
  const balanceOperations = accountStore?.accountStats?.balanceOperations ?? [];

  // Get the current deposit step
  const depositStep = getCurrentDepositStep(
    usdtAllowance,
    depositAmount,
    isApprovingUsdt,
    isPendingTxSign,
    isAwaitingConfirmation,
    transakOrderStatus,
    isDepositConfirmed,
    depositResultData,
    binanceCheckoutData,
    meshState,
    isMethodAWalletTransfer,
  );

  // Get the current swap step
  const swapStep = getCurrentSwapStep({
    isTokenApprovedForSwap: isTokenApprovedForSwapData ?? false,
    isApprovingToken: isApprovingTokenForSwap,
    isSwapping,
    isPendingTxSign,
  });

  // Check if the current step is a waiting step
  const isSwapTxPendingOrSwapping = [
    SwapSteps.SWAPPING,
    SwapSteps.PENDING_TX_SIGN,
  ].includes(swapStep);

  // Check if swap Cta is disabled
  const isSwapDisabled = getShouldDisableSwapBtn({
    isLoading: isCurrencyBalanceLoading,
    isApprovingTokenForSwap,
    isSwapping,
    isPendingTxSign,
    depositAmount,
    tokenBalance: selectedTokenBalance,
  });

  // Check if deposit Cta is disabled
  const isDepositCtaDisabled = getShouldDisableDepositBtn({
    isLoading: isCurrencyBalanceLoading,
    isApprovingTokenForSwap,
    isSwapping,
    isApprovingUsdt,
    isPendingTxSign,
    step: depositStep,
    depositAmount,
    tokenBalance: selectedTokenBalance,
    depositMethod,
    isAwaitingConfirmation,
    isOpeningMeshModal,
    isOpeningBinancePayCheckout,
    transakOrderStatus,
  });

  // Fetch account ETH and USDT/USDB approval status
  const updateState = async () => {
    try {
      const [_usdtAllowance] = await Promise.all([getUsdtAllowance()]);
      setUsdtAllowance(bigNumberToFloat(_usdtAllowance, USDTDecimals));
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = async () => {
    setUsdtAllowance(0);
    setIsPendingTxSign(false);
    setIsApprovingUsdt(false);
    setIsLoading(true);
    setDepositResultData(undefined);
    await updateState();
  };

  // Approve selected token for swap
  const approveSelectedToken = async () => {
    if (
      !(await validateNetworkAndSwitchIfRequired(
        `Approve ${selectedToken} again.`,
      ))
    ) {
      return;
    }

    try {
      if (!account || !depositAmount) return;

      showNotification({
        title: `Approving ${selectedToken}`,
        description: `Approving ${selectedToken} for swap. Confirm the transaction in your wallet.`,
        type: NotificationType.Info,
      });
      flushSync(() => {
        setIsPendingTxSign(true);
      });
      const tx = await approveTokenForSwap(
        uniswapSwapTokensMap[selectedToken],
        account,
      );
      flushSync(() => {
        setIsPendingTxSign(false);
        setisApprovingTokenForSwap(true);
      });
      showNotification({
        title: `Waiting for ${selectedToken} approval to complete`,
        description: `The approval transaction has been sent. Waiting for it to complete.`,
        type: NotificationType.Info,
      });
      await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showNotification({
        title: 'Token Approval Successful',
        type: NotificationType.Positive,
      });
      return true;
    } catch (e: any) {
      console.error('Token approval failed', e);
      showNotification({
        title: 'Token Approval Failed',
        type: NotificationType.Negative,
      });
      return false;
    } finally {
      refetchCurrencyBalance();
      refetchIsTokenApprovedForSwap();
      setisApprovingTokenForSwap(false);
      setIsPendingTxSign(false);
    }
  };

  // Enable USDT
  const enableUsdt = async (amount?: number) => {
    if (
      !(await validateNetworkAndSwitchIfRequired(
        `Approve ${brand.tokenTicker} again.`,
      ))
    ) {
      return;
    }

    try {
      if (!account || !depositAmount) return;

      showNotification({
        title: `Approving ${brand.tokenTicker}`,
        description: `Approving ${brand.tokenTicker} for swap. Confirm the transaction in your wallet.`,
        type: NotificationType.Info,
      });

      flushSync(() => {
        setIsPendingTxSign(true);
      });
      const tx = await approveAPI();
      flushSync(() => {
        setIsPendingTxSign(false);
        setIsApprovingUsdt(true);
      });
      showNotification({
        title: `Waiting for ${brand.tokenTicker} approval to complete`,
        description: `The approval transaction has been sent. Waiting for it to complete.`,
        type: NotificationType.Info,
      });
      await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showNotification({
        title: `${brand.tokenTicker} Approval Successful.`,
        description: 'You can now proceed with the deposit.',
        type: NotificationType.Positive,
      });
      return true;
    } catch (e: any) {
      showNotification({
        title: t('tokenApprovalFailedTitle', { token: brand.tokenTicker }),
        description: t('tokenApprovalFailedDescription', {
          errorMessage: e.message,
          token: brand.tokenTicker,
        }),
        type: NotificationType.Negative,
      });

      return false;
    } finally {
      await resetState();
    }
  };

  const processTransactionConfirmations = async (
    tx: TransactionReceipt,
    networkProvider: Web3Provider,
    walletAddress: string,
    depositAmount: number,
    toastId?: React.ReactText,
  ) => {
    if (!networkProvider) return;

    // Fetch the latest transaction receipt
    const minedTx = await networkProvider.getTransactionReceipt(
      tx.transactionHash,
    );

    if (!minedTx || minedTx.status !== 1) {
      console.log('Transaction was unsuccessful, not tracking confirmations.');
      return;
    }

    saveTxToLocalStorage(
      minedTx,
      LocalStorageTransactionTypes.Deposit,
      walletAddress,
      depositAmount,
    );

    // Get the current block number
    let currentBlockNumber = await networkProvider.getBlockNumber();

    // Compute the number of confirmations the transaction currently has
    // Add 1 because when the transaction is mined, it has 1 confirmation
    let confirmations = currentBlockNumber - minedTx.blockNumber + 1;
    setConfirmations(confirmations);
    updateModalMetadataOnConfirmation(confirmations);
    // Check if transaction already has required confirmations
    if (hasRequiredConfirmations(confirmations)) {
      removeTxFromLocalStorage(tx.transactionHash, walletAddress);
      return;
    }

    // if toastId is passed in the function, update the existing toast, else create a new one
    let newToastId: React.ReactText;
    if (toastId) {
      newToastId = toastId;
      toast.update(toastId, {
        render: (
          <DepositInProgress
            confirmations={confirmations}
            depositAmount={depositAmount}
            txhash={minedTx.transactionHash}
          />
        ),
        progress: confirmations / CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
      });
    } else {
      newToastId = showToast(
        depositAmount,
        confirmations,
        minedTx.transactionHash,
        LocalStorageTransactionTypes.Deposit,
      );
      setDepositToastId(newToastId);
    }

    const updateConfirmations = (value: number) => {
      setConfirmations(value);
      updateModalMetadataOnConfirmation(value);

      // Update the content of the toast
      toast.update(newToastId, {
        render: (
          <DepositInProgress
            confirmations={value}
            depositAmount={depositAmount}
            txhash={minedTx.transactionHash}
          />
        ),
        progress: value / CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
      });
    };

    blockHandlerRef.current = blockNumber =>
      handleBlockEvent(
        blockNumber,
        minedTx,
        newToastId,
        depositAmount,
        walletAddress,
        networkProvider,
        updateConfirmations,
      );

    // Start listening for 'block' events
    networkProvider.on('block', blockHandlerRef.current);
    console.log('network provider', networkProvider);
  };

  const handleBlockEvent = async (
    blockNumber: number,
    minedTx: TransactionReceipt,
    toastId: React.ReactText,
    depositAmount: number,
    walletAddress: string,
    networkProvider: Web3Provider,
    updateConfirmations: (value: number) => void,
  ) => {
    let confirmations = blockNumber - minedTx.blockNumber + 1;

    const maxConfirmationsReached =
      confirmations >= CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT;

    updateConfirmations(confirmations);
    // If more than CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT confirmations, stop listening to 'block' events and remove transaction from local storage
    if (maxConfirmationsReached) {
      networkProvider.off('block', blockHandlerRef.current);
      updateConfirmations(CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT);

      modal.setModalMetadata(modalId, {
        description: t('successfull'),
        status: 'success',
      });

      showNotification(
        {
          title: t('tokenDepositSuccessTitle', {
            depositAmount: depositAmount,
            token: brand.tokenTicker,
          }),
          type: NotificationType.Positive,
        },
        true,
      );
      toast.dismiss(toastId);

      removeTxFromLocalStorage(minedTx.transactionHash, walletAddress);

      return;
    }
  };

  const processDepositOnIndividualContract = async (
    txHash: string,
    depositAmountOnContract: number,
    toastId?: React.ReactText,
  ) => {
    try {
      if (!account || !depositAmountOnContract || !networkProvider) return;

      flushSync(() => {
        setIsAwaitingConfirmation(true);
        setIsPendingTxSign(false);
      });

      mixpanelService.depositClicked(
        account,
        depositAmountOnContract,
        depositMethod,
      );

      const amount: BigNumber = parseUnits(
        roundJsDecimalToString(depositAmountOnContract, USDTDecimals),
        USDTDecimals,
      );

      const depositResultData = await sendTxHashToBackend(amount, txHash);
      setDepositResultData(depositResultData);
      accountStore.addNewBalanceOperation(depositResultData);

      const notifTitle = t('depositRequestedTitle');
      const notifDescription = t('depositRequestedDescription');
      showNotification({
        title: notifTitle,
        description: notifDescription,
        type: NotificationType.Info,
      });

      const tx = await networkProvider.getTransaction(txHash);

      if (!tx) {
        throw new Error('Transaction not found');
      }

      const minedTx = await tx.wait(1);

      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      setConfirmations(minedTx.confirmations);
      updateModalMetadataOnConfirmation(minedTx.confirmations);
      showNotification({
        title: t('depositInProgressTitle'),
        description: t('depositInProgressDescription', {
          confirmationsRequired: CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
        }),
        type: NotificationType.Info,
      });

      if (minedTx?.status !== 1) {
        throw new Error('Transaction reverted');
      }

      mixpanelService.depositSuccess(
        account,
        depositAmountOnContract,
        depositMethod,
      );

      processTransactionConfirmations(
        minedTx,
        networkProvider,
        account,
        depositAmountOnContract,
        toastId,
      );
    } catch (e) {
      console.error(e);
    }
  };
  // Deposit USDT and update state
  const depositUsdt = async (_depositAmount?: number) => {
    if (!depositAmount || !networkProvider || !account) return;

    const amount = _depositAmount ?? depositAmount;

    flushSync(() => {
      setIsPendingTxSign(true);
    });

    if (!(await validateNetworkAndSwitchIfRequired('Restart the deposit'))) {
      return;
    }

    mixpanelService.depositClicked(account, amount, depositMethod);

    try {
      // Check if USDT is approved for the deposit amount
      const isUsdtApproved = await isUsdtApprovedAPI(amount);

      if (!isUsdtApproved) {
        const res = await enableUsdt(amount);
        if (!res) {
          return;
        }
      }

      showNotification({
        type: NotificationType.Info,
        title: 'Deposit Started',
        description:
          'Your deposit has started. Confirm the transaction in your wallet.',
      });

      const amountBn: BigNumber = parseUnits(
        roundJsDecimalToString(amount, USDTDecimals),
        USDTDecimals,
      );

      const tx = await transferUsdtToL1SmartContract(amountBn);
      flushSync(() => {
        setIsAwaitingConfirmation(true);
        modal.setModalMetadata(modalId, {
          title: 'Wallet Transfer',
          description: 'Processing...',
          status: 'processing',
        });
        setIsPendingTxSign(false);
      });

      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      const depositResultData = await sendTxHashToBackend(amountBn, tx.hash);
      setDepositResultData(depositResultData);
      accountStore.addNewBalanceOperation(depositResultData);

      const notifTitle = t('depositRequestedTitle');
      const notifDescription = t('depositRequestedDescription');
      showNotification({
        title: notifTitle,
        description: notifDescription,
        type: NotificationType.Info,
      });

      const minedTx = await tx.wait(1);
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      setConfirmations(brandedSelect({ rabbitx: 1, bfx: 0 }));
      updateModalMetadataOnConfirmation(brandedSelect({ rabbitx: 1, bfx: 0 }));

      showNotification({
        title: t('depositInProgressTitle'),
        description: t('depositInProgressDescription', {
          confirmationsRequired: CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
        }),
        type: NotificationType.Info,
      });

      if (minedTx?.status !== 1) {
        modal.setModalMetadata(modalId, {
          description: 'Transaction reverted',
          status: 'error',
        });
        throw new Error('Transaction reverted');
      }

      mixpanelService.depositSuccess(account, amount, depositMethod);

      processTransactionConfirmations(
        minedTx,
        networkProvider,
        account,
        amount,
      );
    } catch (error: any) {
      if (error.code === Logger.errors.TRANSACTION_REPLACED) {
        // The transaction was canceled/replaced with another unrelated one
        if (error.cancelled) {
          showNotification({
            title: t('transactionCancelledTitle'),
            description: t('transactionCancelledDescription'),
            type: NotificationType.Negative,
          });
        }
        // The user used "speed up" or something similar in their client, but we now have the updated info
        else {
          processTransactionConfirmations(
            error.receipt,
            networkProvider,
            account,
            depositAmount,
          );
          // we need to return here, because we don't want to show the error and reset state
          return;
        }
      }
      console.log(error);
      setDepositResultData(undefined);
      setIsAwaitingConfirmation(false);
      setIsPendingTxSign(false);
      setIsLoading(true);
      setUsdtAllowance(0);
      await updateState();

      const notifTitle = t('depositRequestFailedTitle');
      const notifDescription = t('depositRequestFailedDescription', {
        errorMessage: error.message,
      });
      showNotification({
        title: notifTitle,
        description: notifDescription,
        type: NotificationType.Negative,
      });
    }
  };

  const handleMeshDeposit = async () => {
    if (!account || !depositAmount) return;
    setIsOpeningMeshModal(true);

    meshLinkCloseFnRef.current = await fetchLinkTokenAndOpenLink(
      account,
      selectedMeshIntegration,
      depositAmount,
    );

    setIsOpeningMeshModal(false);
  };

  const handleBinancePayCheckout = async () => {
    if (!account || !depositAmount) return;
    setIsOpeningBinancePayCheckout(true);

    const { status, checkoutData } = await startBinanceTransfer(
      account,
      depositAmount,
    );

    if (status) {
      showNotification({
        title: `Initiated Binance Checkout`,
        description: `Please complete the checkout for binance.`,
        type: NotificationType.Info,
      });
      setBinanceCheckoutData(checkoutData);
    } else {
      showNotification({
        title: `Failed to initiate Binance Checkout`,
        description: `Unable to start transfer, please try again later.`,
        type: NotificationType.Info,
      });
    }

    setIsOpeningBinancePayCheckout(false);
  };

  // Proceed to next step based on current step
  const proceedToNextDepositStep = async (step: DepositSteps) => {
    if (isDepositCtaDisabled) return;
    try {
      if (step !== DepositSteps.SUCCESS && isMethodTransak) {
        openTransak({
          walletAddress: account as string,
          defaultPaymentMethod: depositToTransakPaymentMethodMap[
            depositMethod
          ] as TransakPaymentMethod,
        });

        return;
      }

      if (depositMethod === DepositMethod.BinancePay) {
        if (depositStep === DepositSteps.DEPOSIT) handleBinancePayCheckout();
        else {
          onModalClose();
        }
        return;
      }

      if (depositMethod === DepositMethod.Mesh) {
        if (depositStep === DepositSteps.DEPOSIT) handleMeshDeposit();
        else {
          onModalClose();
        }
        return;
      }

      if (step === DepositSteps.ENABLE_USDT) {
        await enableUsdt();
      }
      if (step === DepositSteps.DEPOSIT) {
        await depositUsdt();
      }
      if (step === DepositSteps.SUCCESS) {
        onModalClose();
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  // Proceed to next SWAP step based on current step
  const proceedToNextSwapStep = async (swapStep: SwapSteps) => {
    if (isSwapDisabled) return;

    try {
      if (swapStep === SwapSteps.APPROVE_TOKEN) {
        if (await approveSelectedToken()) await onSwap();
      }

      if (swapStep === SwapSteps.SWAP) {
        await onSwap();
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  // Account changed
  useEffect(() => {
    (async () => {
      try {
        // Clear the modal if the account is changed and the deposit method is wallet and the account is not set
        if (!account) {
          if (depositMethod === DepositMethod.Wallet) {
            modal.clearById(modalId);
          }
        }
        if (!account || !areReadContractsSet) return;
        await updateState();
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [account, areReadContractsSet]);

  // Intervally fetch account ETH and USDT/USDB balance and USDT/USDB approval status
  useEffect(() => {
    if (!account || !areReadContractsSet) return;

    const interval = setInterval(async () => {
      await updateState();
    }, 8000);
    return () => clearInterval(interval);
  }, [account, areReadContractsSet]);

  // Deposit result data or balance operations changed
  useEffect(() => {
    if (!depositResultData || !account || !networkProvider) return;

    if (
      balanceOperations.find(
        b => b.txhash === depositResultData.txhash && b.status === 'canceled',
      )
    ) {
      showNotification(
        {
          title: t('depositTransactionSpedUpOrCancelledTitle'),
          description: t('depositTransactionSpedUpOrCancelledDescription'),
          type: NotificationType.Info,
        },
        true,
        null,
      );
      resetState();
      modal.clear();
    }

    // If it hasn't been checked yet, check if the deposit tx is confirmed
    if (
      !isDepositConfirmed &&
      balanceOperations.find(
        b => b.txhash === depositResultData.txhash && b.status === 'success',
      )
    ) {
      setIsDepositConfirmed(true);
      setDepositAmount(Number(depositResultData.amount));

      showNotification(
        {
          title: t('tokenDepositSuccessTitle', {
            depositAmount: depositAmount,
            token: brand.tokenTicker,
          }),
          description: `Your deposit of ${depositAmount} ${brand.tokenTicker} is now available.`,
          type: NotificationType.Positive,
        },
        true,
      );
      removeTxFromLocalStorage(depositResultData?.txhash ?? '', account);
      networkProvider.off('block', blockHandlerRef.current);
      modal.setModalMetadata(modalId, {
        description: 'Sucessfull',
        status: 'success',
      });
      depositToastId && toast.dismiss(depositToastId);
    }
  }, [balanceOperations, depositResultData, networkProvider, account]);

  // In case user is at the mesh checkout step, the modal should minimise on outside click, not close
  const shouldModalMinimiseOnOutsideClick =
    [
      DepositSteps.AWAITING_CONFIRMATION,
      DepositSteps.PROCESSING_DEPOSIT,
      DepositSteps.MESH_ORDER_CHECKOUT,
    ].includes(depositStep) && !isDepositConfirmed;

  const onModalClose = () => {
    if (shouldModalMinimiseOnOutsideClick && modalId) {
      modal.minimiseById(modalId); // Only in case of Mesh deposit, we need to clear the modal by id just in case the modal is minimized, we dont need it anymore
    } else {
      modal.pop({ name: Modals.depositModal });
    }
  };

  return (
    <Modal
      size="auto"
      padding="20px !important"
      overflow="visible"
      showCloseIcon={shouldModalMinimiseOnOutsideClick}
      name={Modals.depositModal}
      title="Deposit Funds"
      gtmId="deposit-modal"
      minimiseOnOutsideClick={shouldModalMinimiseOnOutsideClick}
      disableCloseOnClickOutside={
        isTransakModalOpen || meshState.isMeshOpen || isOpeningMeshModal
      }
      onClose={onModalClose}
      id={modalId}
    >
      <DepositContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {[
              DepositSteps.ENABLE_USDT,
              DepositSteps.APPROVING_USDT,
              DepositSteps.DEPOSIT,
              DepositSteps.PENDING_TX_SIGN,
            ].includes(depositStep) ? (
              <>
                {brand.featureFlags.transak && (
                  <DepositMethods
                    selected={depositMethod}
                    setSelected={onSetDepositMethod}
                    disabled={
                      isSwapTxPendingOrSwapping ||
                      depositStep === DepositSteps.PENDING_TX_SIGN ||
                      isOpeningBinancePayCheckout ||
                      isOpeningMeshModal
                    }
                    onChangeMeshIntegration={setSelectedMeshIntegration}
                    selectedMeshIntegration={selectedMeshIntegration}
                  />
                )}

                <ChainAndAssetSelector
                  selectedChain={selectedChain}
                  setSelectedChain={setSelectedChain}
                  selectedToken={selectedToken}
                  onSetSelectedToken={onSetSelectedToken}
                  disableToken={
                    isSwapTxPendingOrSwapping ||
                    !brand.featureFlags.swap ||
                    !isMethodAWalletTransfer
                  }
                />

                <DepositInput
                  disabled={
                    isSwapTxPendingOrSwapping ||
                    isApprovingTokenForSwap ||
                    isApprovingUsdt ||
                    isPendingTxSign
                  }
                  isMethodWallet={isMethodAWalletTransfer}
                  isMethodTransak={isMethodTransak}
                  token={selectedToken}
                  maxAmount={selectedTokenBalance ?? 0}
                  depositAmount={depositAmount}
                  setDepositAmount={setDepositAmount}
                  isCurrencyBalanceLoading={isCurrencyBalanceLoading}
                  showStaticInputPresets={
                    isMethodTransak || (depositMethod === DepositMethod.Mesh)
                  }
                />
              </>
            ) : null}

            {depositStep === DepositSteps.BINANCE_PAY_CHECKOUT && (
              <BinanceOrderCheckout
                data={binanceCheckoutData}
                processDepositOnIndividualContract={
                  processDepositOnIndividualContract
                }
                confirmations={confirmations}
                isDepositConfirmed={isDepositConfirmed}
              />
            )}

            {/* {depositStep === DepositSteps.MESH_ORDER_CHECKOUT && ( */}
            {depositStep === DepositSteps.MESH_ORDER_CHECKOUT && (
              <MeshOrderStatus
                depositAmount={meshState.transferData?.amount ?? 0}
                institutionTxId={meshState.transferData?.txId ?? ''}
                brokerName={meshState.integrationData?.accessToken?.brokerName}
                processDepositOnIndividualContract={
                  processDepositOnIndividualContract
                }
                confirmations={confirmations}
                isDepositConfirmed={isDepositConfirmed}
                modalId={modalId}
                updateToastId={setDepositToastId}
              />
            )}

            {[
              DepositSteps.WAITING_FOR_DELIVERY_FROM_TRANSAK,
              DepositSteps.TRANSAK_IS_PROCESSING_ORDER,
            ].includes(depositStep) && (
              <TransakStatus
                depositAmount={depositAmount ?? 0}
                status={transakOrderStatus}
                paymentMethod={transakPaymentMethod!}
              />
            )}

            {isWaitingStep(depositStep) ? (
              <WaitingStep
                depositAmount={depositAmount}
                step={depositStep}
                confirmations={confirmations}
              />
            ) : null}

            {depositStep === DepositSteps.SUCCESS ? (
              <SuccessStep depositAmount={depositAmount} />
            ) : null}

            {[
              isWaitingStep(depositStep),
              DepositSteps.SUCCESS,
              DepositSteps.WAITING_FOR_DELIVERY_FROM_TRANSAK,
              DepositSteps.TRANSAK_IS_PROCESSING_ORDER,
            ].includes(depositStep) ? (
              <AccountEquity
                step={depositStep}
                walletBalance={walletBalance}
                depositAmount={depositAmount}
              />
            ) : null}

            <SwapDetails
              onRefreshQuote={onRefreshQuote}
              quote={quote}
              isUpdatingQuote={isQuoteLoading}
              amount={depositAmount}
              token={selectedToken}
              slippage={slippage}
              onUpdateSlippage={setSlippage}
              editSlippageDisabled={isSwapTxPendingOrSwapping}
            >
              <ActionSection
                depositMethod={depositMethod}
                proceedToNextSwapStep={proceedToNextSwapStep}
                selectedToken={selectedToken}
                proceedToNextDepositStep={proceedToNextDepositStep}
                step={depositStep}
                swapStep={swapStep}
                shouldDisableActionBtn={isDepositCtaDisabled}
                shouldDisableSwapBtn={isSwapDisabled}
                isLoadingCta={isOpeningMeshModal || isOpeningBinancePayCheckout}
              />
            </SwapDetails>
          </>
        )}
        {(isTargetToken(selectedToken)
          ? depositStep === DepositSteps.ENABLE_USDT
          : swapStep === SwapSteps.APPROVE_TOKEN) &&
          isMethodAWalletTransfer && (
            <Tooltip
              title={t('depositExchangeWhyIsThisRequiredTooltip', {
                exchange: brand.appShortName,
              })}
            >
              <ApproveTooltip>
                <span>{t('whyIsThisRequired')}</span>
              </ApproveTooltip>
            </Tooltip>
          )}
      </DepositContainer>
    </Modal>
  );
};

export default observer(DepositModal);
