import { getMarketLogoFromUrl } from 'utils/marketLogo';

import Icon from 'components/Icon';
import Text from 'components/Text';
import { TextVariant } from 'components/Text/variant';

import { Container } from '../styles';

type Props = {
  icon: string;
  label: string;
  selected: boolean;
  variant: keyof typeof TextVariant;
};

export const IconAndLabelSelectOption = ({
  icon,
  label,
  selected,
  variant,
}: Props) => {
  return (
    <Container selected={selected} padding={0}>
      <Icon size="M" src={icon} />
      <Text
        fontWeight={selected ? 'semiBold' : 'medium'}
        color={selected ? 'white' : 'shadesForeground200'}
        variant={variant || 'BODY_S'}
      >
        {label}
      </Text>
    </Container>
  );
};

export default IconAndLabelSelectOption;
