import { InputHTMLAttributes, memo, useState } from 'react';

import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';

import Button from 'components/Button';
import Modal from 'components/Modal';

import { ButtonGroup, InputContainer, Separator } from './styles';

type Props = {
  defaultValue?: string;
  placeholder?: string;
  errorMessage?: string;
  onUpdate: (text: string) => void;
  onCancel?: () => void;
  isUpdating?: boolean;
  children?: React.ReactNode;
  updateEnabled?: boolean;
  name: string;
  gtmId?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;

/**
 * EditModal Component: A modal for editing a text input.
 *
 * @component
 * @param {string} [defaultValue=''] - The default value for the input.
 * @param {string} [placeholder] - The placeholder text for the input.
 * @param {string} [errorMessage] - The error message to display.
 * @param {(text: string) => void} onUpdate - Callback function called when the text is updated.
 * @param {() => void} onCancel - Callback function called when the cancel button is clicked.
 * @param {boolean} isUpdating - A boolean flag indicating whether an update is in progress.
 * @param {React.ReactNode} [children] - Additional content to render inside the modal with a default top seperator.
 * @param {boolean} [updateEnabled=true] - A boolean flag indicating whether the update button is enabled.
 * @returns {React.ReactElement} The rendered EditModal component.
 */
const EditModal = ({
  children,
  defaultValue = '',
  errorMessage,
  onUpdate,
  isUpdating = false,
  onCancel,
  updateEnabled = true,
  name,
  gtmId,
  ...rest
}: Props): React.ReactElement => {
  const modal = useModal();
  const [text, setText] = useState(defaultValue);

  const shouldNotUpdate = !updateEnabled || !text;

  const handleUpdate = () => {
    !shouldNotUpdate && onUpdate(text);
  };

  const handleCancel = () => {
    onCancel?.();
    modal.clear();
  };

  useKey([Key.Escape, Key.Enter], e => {
    if (e?.keyCode === Key.Enter) {
      handleUpdate();
    }
    if (e?.keyCode === Key.Escape) {
      handleCancel();
    }
  });

  return (
    <Modal
      gtmId={gtmId}
      showHeader={false}
      size={'small'}
      name={name}
      showCloseIcon={false}
    >
      <InputContainer>
        <input
          autoFocus
          type="text"
          value={text}
          onChange={event => setText(event.target.value)}
          {...rest}
        />
        {errorMessage && <span className="error-message">{errorMessage}</span>}
      </InputContainer>

      {children && (
        <>
          <Separator />
          {children}
        </>
      )}

      <ButtonGroup className="mt-20">
        <Button
          data-gtmid={`${gtmId}-close`}
          variant="secondaryOutline"
          size="S"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          data-gtmid={`${gtmId}-update`}
          variant={'primaryGreen'}
          size="S"
          block={true}
          disabled={shouldNotUpdate}
          onClick={handleUpdate}
          isLoading={isUpdating}
        >
          Update
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default memo(EditModal);
