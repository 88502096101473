import { useState } from 'react';

import { Tabs, Tab, Box } from '@material-ui/core';

import { brand } from 'utils/brand';

import { Icon } from 'components';

import Layout from '../../common/Layout';
import Header from './Header';
import ElixirVaults from './TabPanels/ElixirVaults';
import PlatformVaults from './TabPanels/PlatformVaults';
import UnstakeRequestsTable from './TabPanels/PlatformVaults/UnstakeRequestsTable';
import { VaultsPageStyled } from './styles';
import elixirIcon from 'assets/icons/vaults-menu/elixir-gray.svg';
import elixirActiveIcon from 'assets/icons/vaults-menu/elixir-white.svg';
import walletIcon from 'assets/icons/wallet-gray.svg';
import walletActiveIcon from 'assets/icons/wallet-white.svg';
import zapActiveIcon from 'assets/icons/zap-fast.svg';
import zapIcon from 'assets/icons/zap-fast.svg';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// @todo: style accordingly later
const TabPanelContainerStyled = styled.div`
  /* overflow-x: scroll; */
`;

// @todo: refactor accordingly later
const TabPanel = props => {
  const { children, value, index } = props;

  return (
    <TabPanelContainerStyled role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </TabPanelContainerStyled>
  );
};

const TABS: {
  labelTx: string;
  inactiveIcon: string;
  activeIcon: string;
  component: React.ReactElement;
}[] = [
  // {
  //   label: 'Strategies',
  //   inactiveIcon: zapIcon,
  //   activeIcon: zapActiveIcon,
  // },
];

if (brand.featureFlags.elixirVaults) {
  TABS.push({
    labelTx: 'elixirFusion',
    inactiveIcon: elixirIcon,
    activeIcon: elixirActiveIcon,
    component: <ElixirVaults />,
  });
}

if (brand.featureFlags.platformVaults) {
  TABS.push({
    labelTx: 'liquidityProvider',
    inactiveIcon: walletIcon,
    activeIcon: walletActiveIcon,
    component: <PlatformVaults />,
  });
  TABS.push({
    labelTx: 'unstakeRequests',
    inactiveIcon: zapIcon,
    activeIcon: zapActiveIcon,
    component: <UnstakeRequestsTable />,
  });
}
const Vaults = () => {
  const { t } = useTranslation();
  const {
    store: {
      account: { isVaultAccount },
    },
  } = useAppContext();

  const [activeTab, setActiveTab] = useState(0);

  let filteredTabs = isVaultAccount
    ? TABS
    : TABS.filter(tab => tab.labelTx !== 'unstakeRequests');

  return (
    <Layout>
      <VaultsPageStyled>
        <Header />

        <Box>
          <Tabs
            value={activeTab}
            onChange={(_event, newValue) => setActiveTab(newValue)}
            aria-label="Vaults Tab"
          >
            {filteredTabs.map(
              ({ inactiveIcon, activeIcon, labelTx }, index) => (
                <Tab
                  label={t(labelTx)}
                  className="tab-override"
                  icon={
                    <Icon
                      size="M"
                      src={index === activeTab ? activeIcon : inactiveIcon}
                      alt="tab-icon"
                    />
                  }
                />
              ),
            )}
          </Tabs>
        </Box>

        {TABS.map(({ component }, index) => (
          <TabPanel value={activeTab} index={index}>
            {component}
          </TabPanel>
        ))}

        {/* <TabPanel value={activeTab} index={2}>
          <Strategies />
        </TabPanel> */}
      </VaultsPageStyled>
    </Layout>
  );
};

export default observer(Vaults);
