import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';

import { FormattedNumber } from 'components';

import MarketStatItem from './components/MarketStatItem';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const OtherStats = () => {
  const {
    store: {
      markets: { selectedMarket, isLoading },
    },
  } = useAppContext();
  const { t } = useTranslation();
  const decimalPlaces = useGetDecimalPlaces(selectedMarket?.id);

  if (!selectedMarket || isLoading) {
    return null;
  }

  const {
    lastFundingRateBasis,
    marketPrice24hHigh,
    marketPrice24hLow,
    marketVolume24h,
    instantFundingRate,
    indexPrice,
  } = selectedMarket;

  return (
    <>
      <MarketStatItem
        label={t('24hHigh')}
        value={
          <FormattedNumber
            prefix="$"
            value={marketPrice24hHigh}
            decimalScale={decimalPlaces.price}
            data-cy="market-price-24h-high"
          />
        }
      />
      <MarketStatItem
        label={t('24hLow')}
        value={
          <FormattedNumber
            prefix="$"
            value={marketPrice24hLow}
            decimalScale={decimalPlaces.price}
            data-cy="market-price-24h-low"
          />
        }
      />
      <MarketStatItem
        label={t('24hVolume')}
        value={
          <FormattedNumber
            prefix="$"
            value={marketVolume24h}
            data-cy="market-volume-24h"
          />
        }
      />

      <MarketStatItem
        label={t('indexPrice')}
        value={
          <FormattedNumber
            prefix="$"
            value={indexPrice}
            decimalScale={decimalPlaces.price}
            data-cy="index-price"
          />
        }
      />

      <MarketStatItem
        label={t('1hFundingRate')}
        value={
          <FormattedNumber
            value={lastFundingRateBasis * 100}
            suffix="%"
            decimalScale={4}
            data-cy="last-funding-rate"
          />
        }
      />

      <MarketStatItem
        label={t('nextHourFundingRate')}
        value={
          <FormattedNumber
            value={instantFundingRate * 100}
            suffix="%"
            decimalScale={4}
            data-cy="instant-funding-rate"
          />
        }
      />
    </>
  );
};

export default observer(OtherStats);
