import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: auto;
    grid-template-areas: 'main';
    grid-template-columns: 1fr;
  }
  @media (min-width: ${({ theme }) => theme.screenSizes.medium}px) {
    grid-template-areas: 'main watchlist';
    grid-template-columns: 1fr 310px;
  }
  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    grid-gap: 16px;
    grid-template-areas: 'main watchlist';
    grid-template-columns: 1fr 310px;
  }
`;

export const MainContent = styled.div`
  min-width: 0;
  padding: 15px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.shadesBackground800};

  margin-left: 10px;

  div.markets-table-container {
    margin: 10px 0 0 0;
  }

  > .header {
    padding: 10px;
  }
`;
