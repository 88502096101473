import { useState } from 'react';

import { Key } from 'hooks';
import useModal from 'hooks/useModal';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';

import { Container, SlippageInput, SlippageOption } from './styles';
import { Modals } from 'constants/modals';

const DEFAULT_OPTIONS = [0.25, 0.5];

const MAX_SLIPPAGE = 50;

const getWarningText = (value: number | string | undefined) => {
  if (value === undefined || value === '') return '';

  const parsedNum = Number(value);

  if (parsedNum < 0.05) {
    return 'Slippage below 0.05 may result in a failed transaction.';
  }
  if (parsedNum > 1) {
    return 'Your transaction may be frontrun and result in an\nunfavorable trade.';
  }
  return '';
};

type Props = {
  defaultSlippage: number;
  onUpdate: (slippage: number) => void;
};
const SlippageSettings = ({ defaultSlippage, onUpdate }: Props) => {
  const modal = useModal();
  const isDefaultCustom = !DEFAULT_OPTIONS.includes(defaultSlippage);
  const [slippage, setSlippage] = useState<number | 'CSTM'>(
    isDefaultCustom ? 'CSTM' : defaultSlippage,
  );
  const [error, setError] = useState('');
  const [customSlippage, setCustomSlippage] = useState<string>(
    isDefaultCustom ? defaultSlippage.toString() : '',
  );

  const handleUpdate = () => {
    if (slippage === 'CSTM') {
      const parsedVal =
        customSlippage !== '' ? Number(customSlippage) : customSlippage;

      if (!parsedVal) {
        setError('Please enter a valid slippage.');
        return;
      }
      if (parsedVal > MAX_SLIPPAGE || parsedVal < 0) {
        setError(`Slippage should be above/or 0 and below ${MAX_SLIPPAGE}.`);
        return;
      }
      onUpdate(parsedVal);
      modal.pop({ name: Modals.slippageSettingsModal });
      return;
    }

    if (!slippage) {
      setError('Please select an option.');
      return;
    }

    onUpdate(slippage);
    modal.pop({ name: Modals.slippageSettingsModal });
  };

  const defaultOptionSelect = (option: number) => setSlippage(option);

  const onCustomSlippageFocus = () => setSlippage('CSTM');

  const warning = getWarningText(customSlippage);

  return (
    <Modal
      size="small"
      showCloseIcon={false}
      name={Modals.slippageSettingsModal}
      title="Slippage Settings"
      gtmId="edit-slippage-modal"
      handleKeyStrokes={{ [Key.Enter]: handleUpdate }}
    >
      <Container>
        <span className="title mb-20">Max Slippage</span>
        <div className="row">
          {DEFAULT_OPTIONS.map(option => (
            <SlippageOption
              data-gtmid={`button-slippage-dot-${option
                .toString()
                .replace('0.', '')}`}
              onClick={() => defaultOptionSelect(option)}
              isFocused={slippage === option}
            >
              {option}
            </SlippageOption>
          ))}
          <SlippageInput
            data-gtmid="button-slippage-custom"
            autoFocus={isDefaultCustom}
            onFocus={onCustomSlippageFocus}
            onChange={e => {
              setError('');
              setCustomSlippage(e.target.value);
            }}
            value={customSlippage}
            placeholder="Enter custom slippage"
            type="number"
            onMouseDown={e => e.stopPropagation()}
            customSelected={slippage === 'CSTM'}
          />
        </div>
        {slippage === 'CSTM' ? (
          error ? (
            <Text
              color="negativeForeground200"
              variant="BODY_S"
              preWrap
              className="mt-20 align-center"
            >
              {error}
            </Text>
          ) : warning ? (
            <Text
              color="warningForeground200"
              variant="BODY_S"
              preWrap
              className="mt-20 align-center"
            >
              {warning}
            </Text>
          ) : null
        ) : null}
        <Button
          variant="primaryGreen"
          block
          size="M"
          onClick={handleUpdate}
          className="button"
          data-gtmid="button-confirm-slippage"
        >
          Confirm
        </Button>
      </Container>
    </Modal>
  );
};

export default SlippageSettings;
