import { isBrandRabbitX } from 'utils/brand';

import { useReferralsAPI } from 'hooks/useReferralsAPI';

import Loading from 'components/Loading';
import Text from 'components/Text';

import { ReferralContainer } from '../styles';
import { useAppContext } from 'contexts/AppContext';
import { Column } from 'theme/globalStyledComponents/column';

import { config } from 'config';
import { QueryKeys } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useQuery } from 'react-query';

const ReferralCode = () => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { fetchUserReferral } = useReferralsAPI();
  const { t } = useTranslation();

  const {
    isLoading: isLoadingUserReferral,
    data: userReferral,
    isError: isErrorUserReferral,
  } = useQuery(
    [QueryKeys.UserReferral],
    async () => await fetchUserReferral(),
    {
      refetchInterval: false,
      enabled: !!frontendSecrets?.jwt,
    },
  );

  if (isErrorUserReferral) return null;
  if (userReferral && !userReferral?.shortCode === undefined) return null;

  const linkUrl = userReferral
    ? `${config.apiUrl}/?ref=${userReferral.shortCode}`
    : null;

  if (isBrandRabbitX) {
    return (
      <ReferralContainer justifyStart>
        {isLoadingUserReferral ? (
          <Loading size={30} />
        ) : (
          <div className="qr-code">
            <QRCode size={50} className="qrcode" value={linkUrl!} />
          </div>
        )}

        <Column>
          <Text variant="BODY_L" fontWeight="semiBold">
            {t('ReferralCode')}:{' '}
          </Text>
          <Text variant="BODY_L" fontWeight="semiBold">
            {userReferral?.shortCode ?? <Loading size={15} />}
          </Text>
        </Column>
      </ReferralContainer>
    );
  }

  return (
    <ReferralContainer>
      <div className="left-col">
        <h3 className="headline">
          {t('ReferralCode')}:{' '}
          {userReferral?.shortCode ?? <Loading size={15} />}
        </h3>
        <p className="description">{t('gotLiquidatedReferralDescription')}</p>
      </div>

      {isLoadingUserReferral ? (
        <Loading size={30} />
      ) : (
        <div className="qr-code">
          <QRCode size={50} className="qrcode" value={linkUrl!} />
        </div>
      )}
    </ReferralContainer>
  );
};

export default observer(ReferralCode);
