import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    flex-direction: row;
  }
`;

export const PortfolioValueAndStats = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 20px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    max-width: 335px;
  }

  .portfolio-chart {
    width: 100%;
  }
`;

export const ConnectWalletContainer = styled.div`
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;

  /* max-width: 400px; */
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  .header {
    font-size: 16px;
    line-height: 24px;
  }
  .description {
    margin: 15px 0 20px 0;
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;
