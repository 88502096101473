import { useEffect, useState } from 'react';

import { bigNumberToFloat } from 'utils';
import { brand, brandedSelect, isBrandRabbitX } from 'utils/brand';

import { useDepositsAPI } from 'hooks/useDepositsAPI';

import Loading from 'components/Loading';

import DataCard from './DataCard';
import { Divider } from './DataCard/styles';
import tvlIconBlack from 'assets/icons/lock-black.svg';
import tvlIcon from 'assets/icons/lock.svg';
import tradingVolumeIconBlack from 'assets/icons/speedometer-black.svg';
import tradingVolumeIcon from 'assets/icons/speedometer.svg';
import openInterestIconBlack from 'assets/icons/zap-fast-black.svg';
import openInterestIcon from 'assets/icons/zap-fast.svg';
import { RestService } from 'service/restService';

import { config } from 'config';
import { Endpoints, QueryKeys, RequestMethod } from 'enums';
import { Market } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0 20px 0;
  gap: 20px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    align-items: flex-end;
    flex-direction: row;
    gap: 50px;
  }
`;

const restService = RestService.get();

type Props = {
  markets: Market[] | undefined;
};
const DataCards = ({ markets }: Props) => {
  const { t } = useTranslation();
  const [tvl, setTvl] = useState<number>(0);
  const { getUsdtBalance, USDTDecimals } = useDepositsAPI();
  const [isFetchingTvl, setIsFetchingTvl] = useState<boolean>(true);

  useEffect(() => {
    try {
      const fetchTvl = async () => {
        const usdtBalance = await getUsdtBalance(config.RabbitContractAddress);
        setTvl(bigNumberToFloat(usdtBalance, USDTDecimals));
        setIsFetchingTvl(false);
      };

      fetchTvl();
    } catch (error) {
      console.log(error);
      setIsFetchingTvl(false);
    }
  }, [USDTDecimals, getUsdtBalance]);

  const {
    data: totalTradingVolume24hfromEndpoint,
    isLoading: isFetchingTotalTradingVolume,
  } = useQuery(
    [QueryKeys.MarketVolume],
    async () => {
      try {
        const currentTimeInMicroS = new Date().getTime() * 1000; // current time in microseconds
        const time24HoursAgo = currentTimeInMicroS - 24 * 60 * 60 * 1000 * 1000; // 24 hours ago

        const {
          data,
        }: {
          data?: { volume: number }[];
        } = await restService.request({
          method: RequestMethod.GET,
          path: `/${brand.brandId}/${Endpoints.VOLUME}`,
          queryParams: {
            start_date: time24HoursAgo,
            end_date: currentTimeInMicroS,
          },
          isArray: true,
        });

        return data?.[0]?.volume;
      } catch (error) {
        console.log('An error occured while fetching exchnage volume', error);
      }
    },
    {
      // 30sec
      refetchInterval: 30_000,
    },
  );

  if (!markets) return <Loading />;

  const reducedTotalMarket24hVolume = markets.reduce((acc, market) => {
    return acc + market.marketVolume24h;
  }, 0);

  const totalOpenInterest = markets.reduce((acc, market) => {
    return acc + market.openInterest;
  }, 0);

  return (
    <Container>
      <DataCard
        title={t('tradingVolume')}
        icon={isBrandRabbitX ? tradingVolumeIcon : tradingVolumeIconBlack}
        value={brandedSelect({
          bfx: totalTradingVolume24hfromEndpoint,
          rabbitx: reducedTotalMarket24hVolume,
        })}
        isLoading={isBrandRabbitX ? false : isFetchingTotalTradingVolume}
        label={t('exchangedInTheLast24h')}
      />
      <Divider />
      <DataCard
        title={t('openInterest')}
        icon={isBrandRabbitX ? openInterestIcon : openInterestIconBlack}
        value={totalOpenInterest}
        label={t('openPositionsOnRabbitX').replace(
          'RabbitX',
          brand.appShortName,
        )}
      />
      <Divider />
      <DataCard
        title={t('assetsTvl')}
        icon={isBrandRabbitX ? tvlIcon : tvlIconBlack}
        value={tvl}
        label={t('lockedInRabbitxProtocol').replace(
          'RabbitX',
          brand.appShortName,
        )}
        isLoading={isFetchingTvl}
      />
    </Container>
  );
};

export default observer(DataCards);
