import { useCallback, useEffect, useState } from 'react';

import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { parseUnits } from '@ethersproject/units';

import { useWeb3React } from '@web3-react/core';

import { roundJsDecimalToString, showNotification } from 'utils';
import { brand, isBrandRabbitX } from 'utils/brand';
import { getContract } from 'utils/contracts';

import { useVerifyChainId } from 'hooks';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';

import { USDT_DECIMALS } from 'constants/contracts';
import { NetworkContextName } from 'constants/networkContextName';
import { UsdtContractsApiContext } from 'contexts/UsdtContractsApiContext';
import { USDT, USDT__factory } from 'contracts/types';

import { config } from 'config';
import { NotificationType } from 'enums';
import { BigNumber, BigNumberish, constants } from 'ethers';

export const UsdtContractsProviders = ({ children }) => {
  const { library: libraryNetwork } =
    useWeb3React<Web3Provider>(NetworkContextName);
  const { account, library } = useActiveWeb3React();
  const { isCorrectChainId } = useVerifyChainId();

  const [USDTSignContract, setUSDTSignContract] = useState<USDT | undefined>(
    undefined,
  );
  const [USDTReadContract, setUSDTReadContract] = useState<USDT | undefined>(
    undefined,
  );
  const [USDTDecimals, setUSDTDecimals] = useState<number>(0);
  const [areReadContractsSet, setAreReadContractsSet] =
    useState<boolean>(false);

  const [areSignContractsSet, setAreSignContractsSet] =
    useState<boolean>(false);

  // Set read contracts
  useEffect(() => {
    (async () => {
      try {
        if (!libraryNetwork) {
          return;
        }
        setAreReadContractsSet(false);

        // USDT contract
        const USDTReadContract = getContract<USDT>(
          USDT__factory,
          config.USDTAddress,
          libraryNetwork,
        );

        const USDTDecimals = USDT_DECIMALS;
        setUSDTDecimals(USDTDecimals);
        setUSDTReadContract(USDTReadContract);
        setAreReadContractsSet(true);
      } catch (e) {
        console.error(e);
        setAreReadContractsSet(false);
      }
    })();
  }, [libraryNetwork]);

  // Set sign contracts
  useEffect(() => {
    (async () => {
      try {
        if (!account || !library) return;
        setAreSignContractsSet(false);

        const USDTSignContract = getContract<USDT>(
          USDT__factory,
          config.USDTAddress,
          library,
          account,
        );
        setUSDTSignContract(USDTSignContract);

        setAreSignContractsSet(true);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [account, library]);

  const getUsdtAllowanceForContract = useCallback(
    async (contractAddress: string): Promise<BigNumber> => {
      try {
        if (!USDTReadContract || !account) return constants.Zero;

        const allowance: BigNumber = (await USDTReadContract.allowance(
          account,
          contractAddress,
        )) as BigNumber;
        return allowance;
      } catch (e: any) {
        console.error(e);
      }
      return constants.Zero;
    },
    [USDTReadContract, account],
  );

  const getUsdtBalanceForAddress = async (address: string) => {
    try {
      if (!USDTReadContract || !address) return constants.Zero;

      return (await USDTReadContract.balanceOf(address)) as BigNumber;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };

  const approveUsdtForContract = useCallback(
    async (contractAddress: string): Promise<TransactionResponse> => {
      try {
        if (!isCorrectChainId) {
          throw new Error(
            `Incorrect chain ID when approving ${brand.tokenTicker}`,
          );
        }
        if (!USDTSignContract) {
          throw new Error(`${brand.tokenTicker}SignContract is not defined`);
        }
        if (!USDTReadContract) {
          throw new Error(`${brand.tokenTicker}ReadContract is not defined`);
        }
        if (!account) {
          throw new Error(
            `Account is not defined when approving ${brand.tokenTicker}`,
          );
        }

        // USDT contract requires resetting the allowance to 0 before setting a new one
        if (isBrandRabbitX) {
          const currentAllowance = await USDTReadContract.allowance(
            account,
            contractAddress,
          );

          // If current allowance is not 0, reset it to 0
          if (!currentAllowance.isZero()) {
            showNotification({
              title: 'Resetting USDT allowance',
              description: 'Resetting USDT allowance to 0',
              type: NotificationType.Info,
            });
            const resetTx = (await USDTSignContract.approve(
              contractAddress,
              0,
            )) as TransactionResponse;
            await resetTx.wait();
            showNotification({
              title: 'USDT allowance reset',
              description:
                'USDT allowance reset to 0. Approving new allowance...',
              type: NotificationType.Positive,
            });
          }
        }

        const tx = (await USDTSignContract.approve(
          contractAddress,
          constants.MaxUint256 as BigNumberish,
        )) as TransactionResponse;

        return tx;
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    },
    [USDTSignContract, isCorrectChainId],
  );

  const isUsdtApprovedForContract = useCallback(
    async (contractAddress: string, amount?: number) => {
      try {
        if (!account) {
          throw new Error(
            `Account is not defined when checking ${brand.tokenTicker} allowance`,
          );
        }
        if (!USDTReadContract) {
          throw new Error(
            brand.tokenTicker +
              `${brand.tokenTicker} contract is not defined when checking ${brand.tokenTicker} allowance`,
          );
        }
        const allowance: BigNumber = (await USDTReadContract.allowance(
          account,
          contractAddress,
        )) as BigNumber;
        // Check for custom amount allowance
        if (amount) {
          return allowance.gte(
            parseUnits(
              roundJsDecimalToString(amount, USDTDecimals),
              USDTDecimals,
            ),
          );
        }
        // Otherwise check for max allowance
        return constants.MaxUint256.div(2).lt(allowance);
      } catch (e: any) {
        console.error(e);
      } finally {
      }
      return false;
    },
    [USDTDecimals, USDTReadContract, account],
  );

  const value = {
    areReadContractsSet,
    getUsdtBalanceForAddress,
    getUsdtAllowanceForContract,
    isUsdtApprovedForContract,
    approveUsdtForContract,
  };

  return (
    <UsdtContractsApiContext.Provider value={value}>
      {children}
    </UsdtContractsApiContext.Provider>
  );
};
