import styled from 'styled-components';

export type TSwitchSize = keyof typeof SIZE_CONFIG;

type StyledSwitchProps = {
  on: boolean;
  size?: TSwitchSize;
};

const SIZE_CONFIG = {
  DEFAULT: { thumbSize: '23px', width: '42px', padding: '2px', left: '19px' },
  S: { thumbSize: '17px', width: '28px', padding: '2px', left: '11px' },
};

const StyledSwitch = styled.div<StyledSwitchProps>`
  display: flex;
  flex-shrink: 0;
  width: ${({ size = 'DEFAULT' }) => SIZE_CONFIG[size].width};
  height: ${({ size = 'DEFAULT' }) => SIZE_CONFIG[size].thumbSize};
  padding: ${({ size = 'DEFAULT' }) => SIZE_CONFIG[size].padding};
  border-radius: 100px;
  transition: background-color 0.3s;
  cursor: pointer;
  background-color: ${({ theme, on }) =>
    on ? theme.colors.positiveForeground300 : theme.colors.shadesBackground700};

  .thumb {
    height: ${({ size = 'DEFAULT' }) => SIZE_CONFIG[size].thumbSize};
    width: ${({ size = 'DEFAULT' }) => SIZE_CONFIG[size].thumbSize};
    border-radius: 100px;
    transition: margin-left 0.1s ease-in-out;
    background-color: ${({ theme }) => theme.colors.white};
    margin-left: ${({ on, size = 'DEFAULT' }) =>
      on ? SIZE_CONFIG[size].left : '0px'};
  }
`;

type SwitchProps = {
  onToggle?: () => void;
} & StyledSwitchProps;

const Switch = ({ onToggle, ...rest }: SwitchProps) => {
  return (
    <StyledSwitch onClick={onToggle} {...rest}>
      <div className="thumb" />
    </StyledSwitch>
  );
};

export default Switch;
