import { Icon } from 'components';
import Badge from 'components/Badge';
import IconPair from 'components/IconPair';

import { UniswapSwapTokens } from '../../../../../../../../enums';
import ethIcon from 'assets/assetLogos/eth.svg';
import rbxIcon from 'assets/assetLogos/rbx-circle-white.svg';
import wethIcon from 'assets/assetLogos/weth.svg';
import arrowRightWhiteIcon from 'assets/icons/arrow-right-white.svg';
import { Row } from 'theme/globalStyledComponents/row';

const MarketBadge = ({
  icon,
  name,
}: {
  icon: string | [string, string];
  name: string;
}) => (
  <Badge
    gap={3}
    flexed
    padding="4px"
    bgColor="shadesBackground800"
    fontWeight="semiBold"
    variant="BODY_XS"
  >
    {typeof icon === 'string' ? (
      <Icon src={icon} size="S" />
    ) : (
      <IconPair icons={icon} size={12} />
    )}
    {name}
  </Badge>
);

export const RbxBadge = () => <MarketBadge icon={rbxIcon} name="RBX" />;
export const RbxWethBadge = () => (
  <MarketBadge icon={[rbxIcon, wethIcon]} name="RBX-WETH" />
);

const SwapStepTitle = ({
  selectedAsset,
}: {
  selectedAsset: UniswapSwapTokens;
}) => (
  <Row gap={5}>
    Swapping{' '}
    <Row gap={2}>
      {selectedAsset === UniswapSwapTokens.ETH ? (
        <MarketBadge icon={ethIcon} name="ETH" />
      ) : (
        <MarketBadge icon={wethIcon} name="WETH" />
      )}

      <Icon src={arrowRightWhiteIcon} size="S" />

      <MarketBadge icon={rbxIcon} name="RBX" />
    </Row>
  </Row>
);

export default SwapStepTitle;
