import { useCallback } from 'react';

import { brandedSelect } from 'utils/brand';

import { useExchangeAPI } from './useExchangeAPI';
import {
  API_MAPPINGS,
  WITHDRAWAL_RESPONSE_MAPPING,
} from 'constants/apiMappings';
import { VaultBalanceOps } from 'interfaces/vaultBalanceOps';
import { QueryParams, RequestResponse } from 'service/restService';

import { BalanceOpsType, Endpoints, RequestMethod } from 'enums';
import { ProfileBalanceOps } from 'interfaces';

export type WithdrawalResponse = {
  withdrawal: ProfileBalanceOps;
  r: string;
  s: string;
  v: number;
  bnAmount: string;
};

export const SETTLEMENT_BALANCE_OP_TYPES = brandedSelect({
  rabbitx: [
    BalanceOpsType.RealizedPnl,
    BalanceOpsType.Fee,
    BalanceOpsType.Funding,
  ],
  bfx: [
    BalanceOpsType.RealizedPnl,
    BalanceOpsType.Fee,
    BalanceOpsType.Funding,
    BalanceOpsType.YieldPayout,
  ],
});

export function useBalanceOperationsAPI() {
  const { makePrivateRequest } = useExchangeAPI();

  const fetchPrivateTransfers = useCallback(
    async ({ queryParams }): Promise<RequestResponse<ProfileBalanceOps[]>> => {
      try {
        const res = await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.BALANCE_OPERATIONS}`,
          requestParams: {},
          responseMapping: API_MAPPINGS.BALANCE_OPERATIONS,
          isArray: true,
          queryParams: {
            ops_type: [
              BalanceOpsType.Deposit,
              BalanceOpsType.Withdrawal,
              BalanceOpsType.DepositBonus,
              BalanceOpsType.Stake,
              BalanceOpsType.UnstakeSharesVaults,
            ],
            ...queryParams,
          },
          shouldCheckJwtValidity: false,
        });

        return res;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [makePrivateRequest],
  );

  const fetchPrivateSettlements = useCallback(
    async ({ queryParams }): Promise<RequestResponse<ProfileBalanceOps[]>> => {
      try {
        const res = await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.BALANCE_OPERATIONS}`,
          requestParams: {},
          responseMapping: API_MAPPINGS.BALANCE_OPERATIONS,
          isArray: true,
          queryParams: {
            ops_type: SETTLEMENT_BALANCE_OP_TYPES,
            ...queryParams,
          },
          shouldCheckJwtValidity: false,
        });

        return res;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [makePrivateRequest],
  );

  const fetchPrivateReferralPayouts = useCallback(
    async ({ queryParams }): Promise<RequestResponse<ProfileBalanceOps[]>> => {
      try {
        const res = await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.BALANCE_OPERATIONS}`,
          requestParams: {},
          responseMapping: API_MAPPINGS.BALANCE_OPERATIONS,
          isArray: true,
          queryParams: {
            ops_type: [BalanceOpsType.ReferralPayout],
            status: ['success'],
            ...queryParams,
          },
          shouldCheckJwtValidity: false,
        });

        return res;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [makePrivateRequest],
  );

  const claimWithdrawal = async (
    balanceOperationId: string,
  ): Promise<WithdrawalResponse> => {
    try {
      const res = await makePrivateRequest({
        method: RequestMethod.POST,
        endpoint: `/${Endpoints.BALANCE_OPS_CLAIM}`,
        requestParams: {
          id: balanceOperationId,
        },
        refreshJwt: false,
        responseMapping: WITHDRAWAL_RESPONSE_MAPPING,
        shouldSignMetamaskMessage: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  };

  const cancelWithdrawal = async (balanceOperationId: string): Promise<any> => {
    try {
      const res = await makePrivateRequest({
        method: RequestMethod.DELETE,
        endpoint: `/${Endpoints.BALANCE_OPS_CANCEL}`,
        requestParams: {
          id: balanceOperationId,
        },
        refreshJwt: false,
        shouldSignMetamaskMessage: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  };

  const setProcessingStatus = async (
    balanceOperationId: string,
    txhash: string,
  ): Promise<any> => {
    try {
      const res = await makePrivateRequest({
        method: RequestMethod.POST,
        endpoint: `/${Endpoints.BALANCE_OPS_PROCESSING}`,
        requestParams: {
          txhash,
          id: balanceOperationId,
        },
        refreshJwt: false,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  };

  const fetchPrivateVaultBalanceOps = useCallback(
    async ({
      queryParams = {},
    }: {
      queryParams: QueryParams;
    }): Promise<RequestResponse<VaultBalanceOps[]>> => {
      try {
        const res = await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.VAULT_BALANCE_OPS}`,
          requestParams: {},
          responseMapping: API_MAPPINGS.VAULTS_BALANCE_OPERATIONS,
          isArray: true,
          shouldCheckJwtValidity: false,
          queryParams: {
            ops_type: [BalanceOpsType.Stake, BalanceOpsType.UnstakeShares],
            ...queryParams,
          },
        });

        return res;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [makePrivateRequest],
  );

  const fetchPrivateRequestedUnstakes = useCallback(async (): Promise<
    ProfileBalanceOps[]
  > => {
    try {
      const { data } = await makePrivateRequest({
        method: RequestMethod.GET,
        endpoint: `/${Endpoints.BALANCE_OPS_UNSTAKE_REQUESTED}`,
        requestParams: {},
        responseMapping: API_MAPPINGS.BALANCE_OPERATIONS,
        isArray: true,
        shouldCheckJwtValidity: false,
      });

      return data;
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  }, [makePrivateRequest]);

  const processUnstake = async (
    vaultWallet: string,
    fromId: number,
  ): Promise<any> => {
    try {
      const res = await makePrivateRequest({
        method: RequestMethod.POST,
        endpoint: `/${Endpoints.BALANCE_OPS_UNSTAKE_PROCESS}`,
        requestParams: {
          vault_wallet: vaultWallet,
          from_id: fromId,
          to_id: fromId,
        },
        refreshJwt: false,
        shouldSignMetamaskMessage: true,
      });
      return res;
    } catch (e: any) {
      throw e;
    }
  };

  return {
    fetchPrivateTransfers,
    fetchPrivateSettlements,
    fetchPrivateReferralPayouts,
    claimWithdrawal,
    cancelWithdrawal,
    setProcessingStatus,
    fetchPrivateVaultBalanceOps,
    fetchPrivateRequestedUnstakes,
    processUnstake,
  } as const;
}

export type BalanceOperationsAPI = ReturnType<typeof useBalanceOperationsAPI>;
