import { isBrandRabbitX } from 'utils/brand';

import { BrandSpecificButtonProps } from './button';

export const getPropsForCurrentBrand = (
  brandSpecificProps: BrandSpecificButtonProps | undefined,
) => {
  if (!brandSpecificProps) return {};

  if (isBrandRabbitX) {
    return brandSpecificProps.rabbitx;
  }
  return brandSpecificProps.bfx;
};
