import Icon, { IconProps } from 'components/Icon';
import Text, { TextProps } from 'components/Text';

import checkBoxBorderIcon from 'assets/icons/checkbox-border.svg';
import checkBoxCheckedIcon from 'assets/icons/checkbox-checked.svg';

type Props = {
  isChecked?: boolean;
  label?: string;
  size?: IconProps['size'];
} & TextProps;

const CheckBox = ({ isChecked, label, size, onClick, ...rest }: Props) => {
  const checkedStatusIcon = isChecked
    ? checkBoxCheckedIcon
    : checkBoxBorderIcon;

  if (!label)
    return <Icon onClick={onClick} src={checkedStatusIcon} size={size} />;

  return (
    <Text
      variant="BODY_S"
      color="shadesForeground200"
      flexed
      gap={10}
      onClick={onClick}
      cursorPointer
      {...rest}
    >
      <Icon src={checkedStatusIcon} size={size} />
      {label}
    </Text>
  );
};

export default CheckBox;
