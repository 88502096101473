import { TableCell, TableRow } from '@material-ui/core';

import { brand } from 'utils/brand';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { FormattedNumber, FormattedPercentage } from 'components';
import Button from 'components/Button/button';
import Icon from 'components/Icon';
import IconPair from 'components/IconPair';

import { Column, Row } from '../../../../../../../styles';
import {
  getTotalApyForVault,
  navigateToDetailedVault,
} from '../../../../../../../utils';
import { vaultManagerDetailsMap } from '../../utils';
import { VaultItemProps } from '../types';
import infoCircleWhiteIcon from 'assets/icons/info-circle-white.svg';
import infoCircleIcon from 'assets/icons/info-circle.svg';
import { AmendType } from 'pages/Vaults/common/AmendLiquidityModal';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const VaultRow = ({
  vault,
  walletShare = 0,
  isUserAuthenticated,
  manager,
  showLiquidityModal,
  onConnectWallet,
}: VaultItemProps) => {
  const withdrawable = walletShare > 0;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const marketLogo = useGetMarketLogoFromMarketId(vault?.tokenPair);

  if (!vault) return null;

  const { icon: managerIcon, label: managerLabel } =
    vaultManagerDetailsMap[manager];

  return (
    <TableRow onClick={() => navigateToDetailedVault(navigate, 1)}>
      <TableCell>
        <Row className="gap-10">
          {/* <img
            src={isWatched ? starFilledIcon : starOutlineIcon}
            alt="wathlist-status"
          /> */}
          <Row className="gap-5">
            <IconPair icons={[marketLogo, brand.tokenLogo]} size={16} />
            {vault.tokenPair}
          </Row>
        </Row>
      </TableCell>

      <TableCell>
        <Row className="gap-5">
          <FormattedPercentage value={getTotalApyForVault(vault)} />
        </Row>
      </TableCell>

      <TableCell>
        <Column className="gap-2">
          <FormattedNumber abbreviated value={vault.tvlUsd} prefix="$" />
          {/* <FormattedNumber
            className="color-secondary"
            abbreviated
            value={20050}
            suffix=" SOL"
          /> */}
        </Column>
      </TableCell>

      <TableCell>
        <Row className="gap-5">
          <Icon src={managerIcon} size="S" />
          {managerLabel}
        </Row>
      </TableCell>

      <TableCell>
        <FormattedNumber value={vault.activeAmount} />
      </TableCell>

      <TableCell>
        <FormattedNumber
          value={
            vault.tvlUsd && vault.activeAmount
              ? vault.tvlUsd / vault.activeAmount
              : 0
          }
          suffix={` ${brand.tokenTicker}`}
        />
      </TableCell>

      <TableCell>
        {isUserAuthenticated ? (
          <Row className="gap-20 justify-end">
            <Icon src={infoCircleWhiteIcon} alt="i" />

            <Row className="gap-10">
              <Button
                sizeVariant="S"
                colorVariant="primaryGreen"
                onClick={e => {
                  e.stopPropagation();
                  showLiquidityModal(AmendType.Stake, vault.productId);
                }}
                data-gtmid="button-stake-vault-init"
              >
                {t('stake')}
              </Button>
              {withdrawable ? (
                <Button
                  onClick={e => {
                    e.stopPropagation();
                    showLiquidityModal(AmendType.UnStake, vault.productId);
                  }}
                  sizeVariant="S"
                  colorVariant="secondary"
                  data-gtmid="button-unstake-vault-init"
                >
                  {t('unstake')}
                </Button>
              ) : null}
            </Row>
          </Row>
        ) : (
          <Button
            onClick={e => {
              e.stopPropagation();
              onConnectWallet();
            }}
            sizeVariant="S"
            colorVariant="primaryGreen"
          >
            {t('connectWallet')}
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default observer(VaultRow);
