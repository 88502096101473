import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: inherit;
  cursor: pointer;

  .claimed-card {
    z-index: 3;
  }

  .shifted-card {
    margin-left: -133px;
    transition: margin-left 0.3s ease-in-out;
  }

  .fee-card {
    z-index: 1;
  }

  .prize-card {
    z-index: 2;
  }

  .adjusted-vector {
    margin-bottom: -20px;
    transition: margin-bottom 0.3s ease-in-out;
  }

  .adjusted-vector.open {
    margin-bottom: 0;
  }

  .shifted-card.open {
    margin-left: 0;
  }
`;
