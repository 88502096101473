import { TableCell, TableRow } from '@material-ui/core';

import EmptyText from 'components/EmptyText';
import Icon from 'components/Icon';
import IconPair from 'components/IconPair';

import { Row } from 'pages/Vaults/styles';

const EmptyActivityRow = () => {
  return (
    <TableRow>
      <TableCell>
        <Row className="gap-5">
          <Icon isEmpty />
          <EmptyText width={48} />
        </Row>
      </TableCell>
      <TableCell>
        <Row className="gap-10">
          <IconPair isEmpty size={16} />
          <EmptyText width={62} />
        </Row>
      </TableCell>
      <TableCell>
        <Row className="gap-5">
          <Icon isEmpty />
          <EmptyText width={48} />
        </Row>
      </TableCell>
      <TableCell>
        <Row className="gap-5">
          <Icon isEmpty />
          <EmptyText width={48} />
        </Row>
      </TableCell>
      <TableCell>
        <EmptyText width={92} />
      </TableCell>
      <TableCell>
        <EmptyText width={50} />
      </TableCell>
      <TableCell>
        <Row className="gap-5">
          <Icon isEmpty />
          <EmptyText width={62} />
        </Row>
      </TableCell>
      <TableCell>
        <EmptyText width={126} />
      </TableCell>
    </TableRow>
  );
};

export default EmptyActivityRow;
