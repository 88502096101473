import { memo, useState } from 'react';

import { TableBody } from '@material-ui/core';

import TableLoader from 'components/TableLoader';
import { StyledTable, TableContainer } from 'components/Tables/styles';

import { getSortedMarkets } from '../utils';
import MarketsRow from './MarketsRow';
import TableHead from './TableHead';

import { Market } from 'interfaces';

export type SortConfig = {
  value: string;
  numClicks: number;
};

type Props = {
  markets: Market[] | undefined;
  isLoading: boolean;
  coingeckoMarketCaps: Record<string, number> | undefined;
};
const MarketsTable = ({ markets, isLoading, coingeckoMarketCaps }: Props) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

  const handleSetSortConfig = (value: string) => {
    setSortConfig(prevState => {
      // We want to increase the numClicks if the user clicks on the same label
      const shouldIncreaseNumClicks =
        prevState === null || prevState.value === value;

      // If the user clicks on a different label, we want to set the numClicks to 1
      // If the user clicks on the same label, we want to increase the numClicks
      const newNumClicks = shouldIncreaseNumClicks
        ? (prevState?.numClicks ?? 0) + 1
        : 1;

      return {
        value,
        numClicks: newNumClicks,
      };
    });
  };

  if (isLoading || !markets || !coingeckoMarketCaps) {
    return (
      <TableContainer>
        <StyledTable>
          <TableHead
            sortConfig={sortConfig}
            onSetSortConfig={handleSetSortConfig}
          />
          <TableBody>
            <TableLoader />
          </TableBody>
        </StyledTable>
      </TableContainer>
    );
  }

  const sortedMarkets = getSortedMarkets(
    sortConfig,
    markets,
    coingeckoMarketCaps,
  );

  return (
    <TableContainer className="markets-table-container">
      <StyledTable stickyHeader>
        <TableHead
          sortConfig={sortConfig}
          onSetSortConfig={handleSetSortConfig}
        />
        <TableBody>
          {sortedMarkets.length > 0 ? (
            sortedMarkets.map(market => {
              const key = `${market.id}`;
              const marketCap = coingeckoMarketCaps && coingeckoMarketCaps[key];
              return (
                <MarketsRow market={market} key={key} marketCap={marketCap} />
              );
            })
          ) : (
            <tr>
              <td colSpan={100} className="no-items">
                No markets.
              </td>
            </tr>
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default memo(MarketsTable);
