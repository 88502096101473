import { memo } from 'react';

import { Row } from 'theme/globalStyledComponents/row';

import styled from 'styled-components';

const Container = styled(Row)`
  padding: 1px;
  background: linear-gradient(
    90.74deg,
    #fadd88 0%,
    #d966b7 28.5%,
    #9a6df6 54%,
    #7c9afb 79%,
    #3fe4ff 100%
  );
  border-radius: 8px;

  .inner {
    background: ${({ theme }) => theme.colors.shadesBackground700};
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    border-radius: 8px;
    box-sizing: border-box;
    left: 0px;
  }

  .dotted-bottom-border {
    text-decoration-style: dashed;
    text-decoration-thickness: 0px;
    text-underline-position: under;
    text-decoration-line: underline;
  }

  .shimmer {
    height: 100%;
    width: 100%;
    background: ${({ theme }) =>
      `linear-gradient(
      -45deg,
      ${theme.colors.shadesBackground700}00 40%,
      ${theme.colors.shadesBackground500} 50%,
      ${theme.colors.shadesBackground700}00 60%
    )`};

    position: absolute;
    top: 0px;
    background-size: 300%;
    background-position-x: 100%;
    z-index: 0;

    animation: shimmer 4s infinite linear;

    @keyframes shimmer {
      25% {
        background-position-x: 0%;
      }

      100% {
        background-position-x: 0%;
      }
    }
  }

  .text-right {
    text-align: right;
  }
`;

type Props = {
  onClick?: () => void;
  className?: string;
  innerClass?: string;
  contentClass?: string;
  children?: React.ReactNode;
};
const ShimmingRainbow = ({
  onClick,
  className,
  innerClass,
  contentClass,
  children,
}: Props) => {
  return (
    <Container className={className}>
      <div className={`inner ${innerClass || ''}`}>
        <div className="shimmer" />
        <div className={`content ${contentClass || ''}`} onClick={onClick}>
          {children}
        </div>
      </div>
    </Container>
  );
};

export default memo(ShimmingRainbow);
