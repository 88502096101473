import { createContext } from 'react';

import { AbstractConnector } from '@web3-react/abstract-connector';

import { AddTokenParams } from 'interfaces';

export type Status =
  | 'no-provider'
  | 'wrong-network'
  | 'disconnected'
  | 'connected';

export type Account = {
  account: string | null | undefined;
  status: Status;
  isConnecting: boolean;
  areRequestsLocked: boolean;
  connect: (connector?: AbstractConnector) => Promise<void>;
  manualDisconnect: () => void;
  addTokens: (tokens: AddTokenParams[]) => Promise<any>;
  switchNetwork: (chainId: number) => Promise<void>;
  addNetwork: () => Promise<void>;
};

export const EmptyAccount = {
  account: undefined,
  status: 'no-provider',
  isConnecting: false,
  areRequestsLocked: false,
  connect: (connector?: AbstractConnector) => {},
  manualDisconnect: () => {},
  addTokens: (tokens: AddTokenParams[]) => {},
  switchNetwork: (chainId: number) => {},
  addNetwork: () => {},
};

const AccountContext = createContext<Account | null>(null);

export { AccountContext };
