import { memo } from 'react';

import { Tooltip } from '@material-ui/core';

import { FormattedNumber } from 'components';
import InfoTooltip from 'components/InfoTooltip';
import LinearProgressBar from 'components/LinearProgressBar';
import Text from 'components/Text';

import { Container, TooltipContainer, VipProgressBar } from './styles';

// import heartGreen from 'assets/icons/heart-green.svg';
// import heartRed from 'assets/icons/heart-red.svg';
// import heartYellow from 'assets/icons/heart-yellow.svg';
// import { Colors } from 'pages/Trade/components/shared';
import { Tier } from 'interfaces';
import { useTranslation } from 'react-i18next';

// const getHeartIcon = (health: number) => {
//   if (health > 50) {
//     return heartGreen;
//   } else if (health > 3.5) {
//     return heartYellow;
//   }
//   return heartRed;
// };

// const getAccountHealthColor = (health: number) => {
//   if (health > 50) {
//     return Colors.Success;
//   } else if (health > 3.5) {
//     return Colors.Warning;
//   }
//   return Colors.Danger;
// };

export const tierToFee: {
  [key: number]:
    | {
        maker: number;
        taker: number;
      }
    | undefined;
} = {
  0: {
    maker: 0,
    taker: 0.07,
  },
  1: {
    maker: 0,
    taker: 0.065,
  },
  2: {
    maker: 0,
    taker: 0.06,
  },
  3: {
    maker: 0,
    taker: 0.04,
  },
  4: {
    maker: -0.01,
    taker: 0.037,
  },
  5: {
    maker: -0.01,
    taker: 0.035,
  },
  6: {
    maker: -0.015,
    taker: 0.025,
  },
  7: {
    maker: -0.015,
    taker: 0.025,
  },
  13: {
    maker: 0,
    taker: 0.04,
  },
};

export const getTierNumber = (tier: number) => {
  if (tier === 13) {
    return 3;
  }
  return tier;
};

const getIsSpecialTier = (tier: number) => {
  return tier > 6 && tier !== 13;
};

type VipTierProps = {
  health: number;
  volume30d: number;
  tierStatus: Tier;
  className?: string;
};
const VipTier = ({ volume30d, tierStatus, className }: VipTierProps) => {
  const { t } = useTranslation();

  const linearProgressValue = tierStatus?.next
    ? (volume30d / tierStatus.next.minVolume) * 100
    : 100;
  const percentToNextTier = 100 - linearProgressValue;

  const tierFees = tierToFee[tierStatus.current.tier];
  const isSpecialTier = getIsSpecialTier(tierStatus.current.tier);

  return (
    <Container className={className}>
      <VipProgressBar>
        <div className="label">
          <div className="left-part">
            <Text variant="BODY_S">{t('tier')}</Text>

            <Text variant="BODY_S" className="current-tier">
              {t('VIP')} {getTierNumber(tierStatus.current.tier)}
            </Text>

            {tierFees ? (
              <InfoTooltip
                color="gray"
                iconSize={16}
                title={
                  <TooltipContainer>
                    <p>
                      {t('makerFee')}: {tierFees.maker}%
                    </p>
                    <p>
                      {t('takerFee')}: {tierFees.taker}%
                    </p>
                  </TooltipContainer>
                }
                placement="top"
              />
            ) : null}
          </div>

          {!isSpecialTier ? (
            <div className="right-part">
              {tierStatus?.next ? (
                <>
                  <Text
                    variant="BODY_S"
                    className="dollars-until"
                    color="shadesForeground200"
                  >
                    <FormattedNumber
                      value={tierStatus.neededVolume}
                      prefix="$"
                      decimalScale={0}
                    />{' '}
                    {t('until')}
                  </Text>
                  <Text variant="BODY_S" className="max-vip">
                    {t('vip')} {tierStatus.next.tier}
                  </Text>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
        {tierStatus?.next && !isSpecialTier ? (
          <Tooltip
            title={`${percentToNextTier.toFixed(2)}% ${t('toNextTier')}`}
          >
            <div>
              <LinearProgressBar
                percent={linearProgressValue}
                customBarColor="linear-gradient(90deg, #423E5C -6.52%, #807252 108.7%)"
              />
            </div>
          </Tooltip>
        ) : null}
      </VipProgressBar>
    </Container>
  );
};

export default memo(VipTier);
