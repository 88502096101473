import { memo, useRef, useState } from 'react';

import { isMobileDevice } from 'utils';
import { brand, isBrandRabbitX } from 'utils/brand';

import { useOnClickOutside } from 'hooks';
import useDragToScroll from 'hooks/useDragToScroll';

import { Icon } from 'components';
import Loading from 'components/Loading';

import { MESH_INTEGRATION_TO_LABLE_ICON_MAP } from '../../../constants';
import MeshIntegrationList from './MeshIntegrationList';
import { Container, Method } from './styles';
import binanceDarkRoundedIcon from 'assets/exchanges/binance-dark-rounded.svg';
import binanceIcon from 'assets/exchanges/binance.svg';
import bitfinexIcon from 'assets/exchanges/bitfinex.svg';
import coinbaseIcon from 'assets/exchanges/coinbase.svg';
import exchangesStackIcon from 'assets/exchanges/exhange-stack.svg';
import checkDarkIcon from 'assets/icons/check-dark.svg';
import chevronDownIcon from 'assets/icons/chevron-down-gray.svg';
import chevronUpIcon from 'assets/icons/chevron-up-gray.svg';
import masterCardIcon from 'assets/icons/mastercard.svg';
import appleIcon from 'assets/icons/socials/apple24.svg';
import googleIcon from 'assets/icons/socials/google24.svg';
import walletIcon from 'assets/icons/wallet-white.svg';
import { DepositMethod, MeshIntegration } from 'enums/depositMethod';

import styled from 'styled-components';

const StyledStackIcons = styled.div`
  display: flex;
  align-items: center;

  .negative-ml {
    margin-left: -6px;
  }
`;

const ExchangeStackIcon = () => {
  return (
    <StyledStackIcons>
      <Icon src={binanceDarkRoundedIcon} />
      <Icon src={coinbaseIcon} className="negative-ml" />
      <Icon src={bitfinexIcon} className="negative-ml" />
    </StyledStackIcons>
  );
};

type Props = {
  selected: DepositMethod;
  setSelected: (method: DepositMethod) => void;
  disabled: boolean;
  onChangeMeshIntegration: (id: MeshIntegration) => void;
  selectedMeshIntegration: MeshIntegration;
};

const DEPOSIT_OPTIONS: {
  method: DepositMethod;
  icon: string;
  gtmId: string;
  label: string;
  comingSoon?: boolean;
  IconComponent?: any;
}[] = [
  {
    method: DepositMethod.Wallet,
    icon: walletIcon,
    gtmId: 'wallet',
    label: 'Wallet',
  },
];

if (isBrandRabbitX) {
  DEPOSIT_OPTIONS.push({
    method: DepositMethod.Mesh,
    icon: exchangesStackIcon,
    IconComponent: ExchangeStackIcon,
    label: 'Exchanges',
    comingSoon: false,
    gtmId: 'exchange',
  });
}

if (brand.featureFlags.transak) {
  DEPOSIT_OPTIONS.push(
    {
      method: DepositMethod.Card,
      icon: masterCardIcon,
      label: 'Card',
      comingSoon: false,
      gtmId: 'card',
    },
    {
      method: DepositMethod.ApplePay,
      icon: appleIcon,
      label: 'Pay',
      comingSoon: false,
      gtmId: 'apple-pay',
    },
    {
      method: DepositMethod.GooglePay,
      icon: googleIcon,
      label: 'Pay',
      gtmId: 'google-pay',
      comingSoon: false,
    },
    // {
    //   method: DepositMethod.BinancePay,
    //   gtmId: 'binance-pay',
    //   icon: binanceIcon,
    //   label: 'Pay',
    //   comingSoon: true,
    // },
  );
}

// Apple Pay is not supported on mobile
const FILTERED_DEPOSIT_OPTIONS = isMobileDevice()
  ? DEPOSIT_OPTIONS.filter(option => option.method !== DepositMethod.ApplePay)
  : DEPOSIT_OPTIONS;

const DepositOption = ({
  option,
  onSelect,
  isFocused,
  disabled,
  isLoading = false,
  isHovered,
  rightIcon,
}: {
  option: (typeof DEPOSIT_OPTIONS)[number];
  isFocused: boolean;
  disabled: boolean;
  onSelect: (method: DepositMethod) => void;
  isLoading?: boolean;
  isHovered: boolean;
  rightIcon?: string;
}) => {
  const isComingSoon = option.comingSoon || false;

  return (
    <div
      className="method-container"
      data-gtmid={`button-payment-${option.gtmId}`}
    >
      {isComingSoon ? (
        <span className="coming-soon-badge">Coming Soon</span>
      ) : null}
      <Method
        isHovered={isHovered}
        isFocused={isFocused}
        isDisabled={disabled}
        isComingSoon={isComingSoon}
        onClick={() => !isComingSoon && !disabled && onSelect(option.method)}
      >
        {isLoading ? (
          <Loading size="12px" />
        ) : (
          <>
            {option.IconComponent ? (
              option.IconComponent()
            ) : (
              <img src={option.icon} alt={option.method} />
            )}
            <span>{option.label}</span>
            {rightIcon ? <Icon src={rightIcon} /> : null}
            {isFocused ? (
              <div className="checked-container">
                <img src={checkDarkIcon} alt="checked" />
              </div>
            ) : null}
          </>
        )}
      </Method>
    </div>
  );
};

const DepositMethods = ({
  selected,
  setSelected,
  disabled,
  onChangeMeshIntegration,
  selectedMeshIntegration,
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { centerElement } = useDragToScroll(scrollRef);
  const [isMeshIntegrationDropdownOpen, setMeshIntegrationDropdownOpen] =
    useState(false);

  const offsetLeft = centerElement(
    FILTERED_DEPOSIT_OPTIONS.findIndex(i => i.method === selected),
    'horizontal',
  );

  const listRef = useRef(null);

  useOnClickOutside(listRef, () => setMeshIntegrationDropdownOpen(false));

  return (
    <Container scrollLeft={offsetLeft}>
      <span className="title">Deposit With</span>
      <div className="flex flex-col gap-10" ref={listRef}>
        <div ref={scrollRef} className="method-row">
          {FILTERED_DEPOSIT_OPTIONS.map(_option => {
            const option =
              _option.method === DepositMethod.Mesh
                ? selectedMeshIntegration !== MeshIntegration.Others
                  ? {
                      ..._option,
                      ...MESH_INTEGRATION_TO_LABLE_ICON_MAP[
                        selectedMeshIntegration
                      ],
                      IconComponent: undefined,
                    }
                  : _option
                : _option;

            return (
              <DepositOption
                option={option}
                isFocused={selected === option.method}
                onSelect={depositMethod => {
                  if (depositMethod === DepositMethod.Mesh) {
                    setMeshIntegrationDropdownOpen(prev => !prev);
                  } else {
                    setMeshIntegrationDropdownOpen(false);
                  }
                  setSelected(depositMethod);
                }}
                disabled={disabled}
                isHovered={
                  option.method === DepositMethod.Mesh
                    ? isMeshIntegrationDropdownOpen
                    : false
                }
                rightIcon={
                  option.method === DepositMethod.Mesh
                    ? isMeshIntegrationDropdownOpen
                      ? chevronUpIcon
                      : chevronDownIcon
                    : undefined
                }
              />
            );
          })}
        </div>

        {isMeshIntegrationDropdownOpen ? (
          <MeshIntegrationList
            selectedMeshIntegration={selectedMeshIntegration}
            onChangeMeshIntegration={onChangeMeshIntegration}
            onOutsideClick={() => setMeshIntegrationDropdownOpen(false)}
          />
        ) : null}
      </div>
    </Container>
  );
};

// Memoize the 'DepositMethods' component to only update when the 'selectedMethod or selectedMeshIntegration' prop changes,
// as 'centerElement' logic is executed within this component.
export default memo(
  DepositMethods,
  (prevProps, nextProps) =>
    prevProps.disabled === nextProps.disabled &&
    prevProps.selected === nextProps.selected &&
    prevProps.selectedMeshIntegration === nextProps.selectedMeshIntegration,
);
