import Text from 'components/Text';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type TabProps = {
  isActive: boolean;
  bgColor?: string;
  fixedWidth?: boolean;
};
export const Tab = styled(Text)<TabProps>`
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.shadesForeground200};
  cursor: pointer;
  flex: 1;
  white-space: nowrap;

  ${({ fixedWidth }) =>
    fixedWidth &&
    css`
      width: 100px;
      .label {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `}

  ${({ isActive }) =>
    !isActive &&
    css`
      &:hover {
        /* background-color: ${({ theme }) =>
          theme.colors.shadesBackground700}; */
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        color: ${({ theme }) => theme.colors.white};
      }
    `}


  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.shadesBackground600};
      color: ${theme.colors.white};
    `}

  ${({ isActive, bgColor }) =>
    isActive &&
    bgColor &&
    css<TabProps>`
      background-color: ${({ bgColor }) => bgColor};
    `}
`;
