import { useCallback, useEffect, useRef } from 'react';

import { useConfirmCancelOrder } from 'hooks/useConfirmCancelOrder';
import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import useModal from 'hooks/useModal';

import {
  ContextMenuItem,
  SeriesStyle,
} from 'components/TVChartContainer/charting_library';
import { ConfirmOrderCancelModal } from 'components/Tables/OrdersTable/ConfirmOrderCancelModal';
import ClosePositionModal from 'components/Tables/PositionsTable/ClosePositionModal';

import {
  getWidget,
  LineStyle,
  TVChartContainer,
} from '../../../../../components/TVChartContainer';
import { formatResolution } from '../../../../../components/TVChartContainer/api/helpersRabbit';
import AmendOrderModal from '../../../../../components/Tables/OrdersTable/AmendOrderModal';
import StopLossTakeProfitCancelModal from '../../../../../components/Tables/PositionsTable/StopLossTakeProfitCancelModal';
import { DEFAULT_DECIMAL_SCALE } from '../../../../../constants/general';
import { useFillsAPI, usePaginationFetch } from '../../../../../hooks';
import { useExchangeAPI } from '../../../../../hooks/useExchangeAPI';
import { useOrdersAPI } from '../../../../../hooks/useOrdersAPI';
import {
  basisToPercent,
  getCurrencyFromTradingPair,
  pushDataLayerEvent,
  roundDownToNearestTick,
  roundToNearestTick,
} from '../../../../../utils';
import { calculateLiquidationPrice } from '../../../../../utils/position';
import { deepMergeArraysById } from '../../../../../utils/state';
import { BuySellModal } from '../../BuySellModal/BuySellModal';
import { ConfigurationData } from './HistoricalPrice.const';
import {
  getAllSymbols,
  getCandleData,
  getOrderLabel,
  subscribeOnStream,
  unsubscribeFromStream,
} from './HistoricalPrice.helper';
import { getDataFeed } from './datafeed';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { ModalsContextInterface } from 'contexts/ModalContext';
import cloneDeep from 'lodash/cloneDeep';

import { OrderStatus, OrderType, QueryKeys, TradeSide } from 'enums';
import {
  AccountStats,
  Fill,
  Order,
  Position,
  SpecialOrder,
  TVConfig,
} from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

const HistoricalPriceStyled = styled.div`
  height: 100%;
`;

let orderLines = new Map();
let liqPriceLines = new Map();
let positionLines = new Map();
let historyLines = new Map();

interface HistoricalPriceProps {
  accountStats: AccountStats | undefined | null;
  config: TVConfig;
  isMobile?: boolean;
}

const HistoricalPrice = ({
  config,
  accountStats,
  isMobile,
}: HistoricalPriceProps) => {
  const lastSize = useRef<number | null>(null);
  const {
    store: {
      tvConfig,
      account: accountStore,
      markets: {
        selectedMarketId,
        markets,
        getDecimalPlacesByMarketId,
        getById,
      },
      orderbook: { setOrderInputPrice },
    },
  } = useAppContext();

  const decimalScale = useGetDecimalPlaces(selectedMarketId);
  const maintenanceMargin = getById(selectedMarketId)?.maintenanceMargin;

  const modal = useModal();
  const { t } = useTranslation();
  const modalRef = useRef<ModalsContextInterface>();
  modalRef.current = modal as ModalsContextInterface;

  const theme: any = useTheme();
  const themeRed = theme.colors.negativeForeground400;
  const themeGreen = theme.colors.positiveForeground400;
  const { amendOrder, amendSltpOrder } = useOrdersAPI();
  const timePriceRef = useRef<{ time: number; price: number }>({
    time: 0,
    price: 0,
  });
  const { cancelOrder, cancelMultipleOrders } = useConfirmCancelOrder();

  const { isOnboarding } = useExchangeAPI();
  const { fetchPrivateFills } = useFillsAPI();

  const { isLoading: fillsIsLoading, data: fills } = usePaginationFetch<Fill>({
    fetchFn: fetchPrivateFills,
    socketSource: accountStore?._fills,
    initialPagination: { page: 0, limit: 10 },
    currentAuthenticatedWallet: accountStore?.frontendSecrets?.profile?.wallet,
    isOnboarding,
    queryKey: QueryKeys.Fills,
    disableRefetchInterval: true,
  });

  const handleCancelOrderClick = (order: Order) => {
    clearModals();
    if (
      [OrderStatus.PLACED].includes(order.status) &&
      [
        OrderType.STOP_LOSS,
        OrderType.STOP_LOSS_LIMIT,
        OrderType.TAKE_PROFIT,
        OrderType.TAKE_PROFIT_LIMIT,
      ].includes(order.type)
    ) {
      modal.present(
        <StopLossTakeProfitCancelModal marketId={order.marketID} />,
        Modals.slTpCancelModal,
      );
    } else {
      const checkMultipleOrders = accountStore?._orders?.filter(
        p =>
          p.marketID === order.marketID &&
          p.status === order.status &&
          p.price === order.price &&
          p.type === order.type,
      );
      if (checkMultipleOrders && checkMultipleOrders?.length > 1) {
        cancelMultipleOrders(checkMultipleOrders, false);
      } else {
        cancelOrder(order);
      }
    }
  };

  const handleAmendOrder = (order: Order) => {
    clearModals();
    modal.present(
      <AmendOrderModal
        order={order}
        currency={getCurrencyFromTradingPair(order.marketID)}
      />,
      Modals.amendOrderModal,
    );
  };

  const handleClosePositionClick = (position: Position) => {
    clearModals();
    modal.present(
      <ClosePositionModal
        currency={getCurrencyFromTradingPair(position.marketID)}
        initialPrice={null}
        position={position}
      />,
      Modals.closePositionModal,
    );
  };

  const symbol = selectedMarketId;
  const onChartTypeChanged = (chartType: SeriesStyle) => {
    tvConfig.setChartType(chartType);
  };

  const createHistoryComponent = (fill: Fill) => {
    const mainColor = fill.side === TradeSide.LONG ? themeGreen : themeRed;
    const direction = fill.side === TradeSide.LONG ? 'buy' : 'sell';
    return (
      getWidget()
        .activeChart()
        // .createShape({ time: fill.timestamp, price: fill.price }, {
        //   shape: fill.side === TradeSide.LONG ? "arrow_up" : "arrow_down",
        //   disableSave: true,
        //   disableUndo: true,
        //   disableSelection: true,
        //   zOrder: 'top',
        //   overrides: { "size": 8}
        // })
        .createExecutionShape()
        .setArrowColor(mainColor)
        .setDirection(direction)
        .setTime(fill.timestamp)
        .setPrice(fill.price)
    );
  };

  const createOrderComponent = (order: Order) => {
    const quantityBC = order.side === TradeSide.LONG ? '#5973fe' : themeRed;

    let price;
    if (
      [OrderStatus.PLACED].includes(order.status) &&
      [OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(order.type)
    )
      price = order.triggerPrice;
    else if (
      [OrderStatus.OPEN].includes(order.status) &&
      [OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(order.type)
    )
      price = order.price;
    else if (
      [OrderStatus.PLACED].includes(order.status) &&
      [
        OrderType.STOP_LOSS,
        OrderType.STOP_LOSS_LIMIT,
        OrderType.TAKE_PROFIT,
        OrderType.TAKE_PROFIT_LIMIT,
      ].includes(order.type)
    )
      price = order.triggerPrice;
    else price = order.price;

    const decimalScale = getDecimalPlacesByMarketId(selectedMarketId);
    const roundedPrice = roundToNearestTick(price, decimalScale.minTick);

    let roundedSize;
    if ([OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(order.type))
      roundedSize = roundToNearestTick(
        order.remainingSize,
        decimalScale.minOrder,
      );
    else if (
      [OrderStatus.PLACED].includes(order.status) &&
      [
        OrderType.STOP_LOSS,
        OrderType.STOP_LOSS_LIMIT,
        OrderType.TAKE_PROFIT,
        OrderType.TAKE_PROFIT_LIMIT,
      ].includes(order.type)
    )
      roundedSize = `${basisToPercent((order as SpecialOrder).sizePercent)}%`;
    else
      roundedSize = roundToNearestTick(
        order.remainingSize,
        decimalScale.minOrder,
      );

    const orderLine = getWidget()
      // create the component
      .activeChart()
      .createOrderLine()
      // style
      .setLineStyle(LineStyle.Dashed)
      .setBodyBorderColor(quantityBC)
      .setLineColor(quantityBC)
      .setLineLength(20)
      .setQuantityBackgroundColor(quantityBC)
      .setQuantityBorderColor(quantityBC)
      .setCancelButtonBorderColor(quantityBC)
      .setCancelButtonIconColor(quantityBC)
      .setEditable(true)
      .setModifyTooltip(t('amendOrder'))
      .setBodyTextColor(theme.colors.shadesBackground900)
      .onModify(order.id, id => {
        const order = accountStore?._orders?.find(p => p.id === id);
        if (!order) return;
        handleAmendOrder(order);
      })
      .onCancel(order.id, id => {
        pushDataLayerEvent('chartTradeCancelOrder');
        // window.dataLayer.push({ event: 'chartTradeCancelOrder' });
        const order = accountStore?._orders?.find(p => p.id === id);
        if (!order) return;
        handleCancelOrderClick(order);
      })
      // set data
      .setText(getOrderLabel(order, t))
      .setQuantity(roundedSize.toString())
      .setPrice(roundedPrice)
      .onMove(async () => {
        const decimalScale = getDecimalPlacesByMarketId(order.marketID);
        const roundedNewPrice = roundToNearestTick(
          orderLine.getPrice(),
          decimalScale.minTick,
        );

        try {
          if (
            [OrderStatus.PLACED].includes(order.status) &&
            [
              OrderType.STOP_LOSS,
              OrderType.STOP_LOSS_LIMIT,
              OrderType.TAKE_PROFIT,
              OrderType.TAKE_PROFIT_LIMIT,
            ].includes(order.type)
          ) {
            let price, roundedPrice;
            if (OrderType.STOP_LOSS_LIMIT === order.type && order.price) {
              price =
                orderLine.getPrice() *
                (order.side === TradeSide.LONG ? 1.003 : 0.997);
            } else if (
              OrderType.TAKE_PROFIT_LIMIT === order.type &&
              order.price
            ) {
              price =
                orderLine.getPrice() *
                (order.side === TradeSide.LONG ? 1.003 : 0.997);
            }

            if (price)
              roundedPrice = roundDownToNearestTick(
                price,
                decimalScale.minTick,
              );

            await amendSltpOrder({
              orderID: order.id,
              marketID: order.marketID,
              type: order.type,
              sizePercent: (order as SpecialOrder).sizePercent,
              ...{ triggerPrice: roundedNewPrice },
              ...(roundedPrice ? { price: roundedPrice } : {}),
            });
          } else if (
            [OrderStatus.OPEN].includes(order.status) &&
            [OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(order.type)
          ) {
            await amendOrder({
              orderID: order.id,
              marketID: order.marketID,
              type: order.type,
              size: roundedSize,
              ...{ price: roundedNewPrice },
              ...{ triggerPrice: order.triggerPrice },
            });
          } else {
            await amendOrder({
              orderID: order.id,
              marketID: order.marketID,
              type: order.type,
              size: roundedSize,
              ...([OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(
                order.type,
              )
                ? { triggerPrice: roundedNewPrice }
                : { price: roundedNewPrice }),
              ...([OrderType.ST0P_LIMIT].includes(order.type)
                ? { price: order.price }
                : {}),
            });
          }

          setTimeout(() => {
            customizeTVChart();
          }, 200);
        } catch (e: any) {
          console.error(
            'An error occurred when trying to amend order from tradingview',
            e,
          );
          customizeTVChart();
        }
      });
    return orderLine;
  };

  const createPositionComponent = (position: Position) => {
    const mainColor = position.side === TradeSide.LONG ? themeGreen : themeRed;
    const decimalScale = getDecimalPlacesByMarketId(selectedMarketId);
    const roundedPrice = roundToNearestTick(
      position.avgEntryPrice,
      decimalScale.minTick,
    );
    const roundedSize = roundToNearestTick(
      position.size,
      decimalScale.minOrder,
    );

    const pnlValue = Number(
      position.unrealizedPnl.toFixed(DEFAULT_DECIMAL_SCALE),
    );

    let pnl;
    if (pnlValue < 0) pnl = `P&L -$${Math.abs(pnlValue)}`;
    else if (pnlValue > 0) pnl = `P&L $${pnlValue}`;
    else pnl = `P&L ${pnlValue}`;

    return (
      getWidget()
        // create the component
        .activeChart()
        .createPositionLine()
        // style
        .setLineStyle(LineStyle.Solid)
        .setBodyBorderColor(mainColor)
        .setLineColor(mainColor)
        .setLineLength(20)
        .setQuantityBackgroundColor(mainColor)
        .setQuantityBorderColor(mainColor)
        // .setExtendLeft(false)
        //@ts-ignore
        .setBodyTextColor(theme.colors.white)
        .setBodyBackgroundColor(
          theme.colors.shadesBackground700TransparentChart,
        )
        .setCloseButtonBackgroundColor(
          theme.colors.shadesBackground700TransparentChart,
        )
        .setCloseButtonBorderColor(mainColor)
        .setCloseButtonIconColor(theme.colors.white)
        // set handlers
        .onClose(position.id, id => {
          pushDataLayerEvent('chartTradeClosePosition');
          const position = accountStore?._positions?.find(p => p.id === id);
          if (!position) return;
          handleClosePositionClick(position);
        })
        // set data
        //.setText(getPositionLabel(position).toUpperCase())
        .setText(pnl)
        .setQuantity(roundedSize.toString())
        .setPrice(roundedPrice)
    );
  };

  const createLiqPriceComponent = (position: Position) => {
    const mainColor = theme.colors.warningForeground200;
    const liqPrice = calculateLiquidationPrice(
      accountStore._positions,
      accountStore._accountStats?.balance,
      position,
      maintenanceMargin,
    );
    if (liqPrice === 0) return;

    const decimalScale = getDecimalPlacesByMarketId(selectedMarketId);
    const roundedPrice = roundToNearestTick(liqPrice, decimalScale.minTick);

    return getWidget()
      .activeChart()
      .createOrderLine()
      .setLineStyle(LineStyle.Dashed)
      .setBodyBorderColor(mainColor)
      .setLineColor(mainColor)
      .setLineLength(20)
      .setQuantityBackgroundColor(mainColor)
      .setQuantityBorderColor(mainColor)
      .setBodyTextColor(theme.colors.white)
      .setBodyBackgroundColor(theme.colors.shadesBackground700TransparentChart)
      .setEditable(false)
      .setText(t('liquidationPriceShort') + '  ')
      .setQuantity('')
      .setPrice(roundedPrice);
  };

  const clearModals = () => {
    modal.clearByName(Modals.closePositionModal);
    modal.clearByName(Modals.amendOrderModal);
    modal.clearByName(Modals.slTpCancelModal);
    modal.clearByName(Modals.confirmationModal);
    modal.clearByName(Modals.confirmOrderCancelModal);
    modal.clearByName(Modals.buySellModal);
    modal.clear();
  };

  const clearCustomizations = () => {
    for (let entry of Array.from(historyLines.entries())) {
      let item = historyLines.get(entry[0]);
      if (item) {
        item.remove();
      }
    }

    for (let entry of Array.from(orderLines.entries())) {
      let item = orderLines.get(entry[0]);
      if (item) {
        item.remove();
      }
    }
    for (let entry of Array.from(positionLines.entries())) {
      let item = positionLines.get(entry[0]);
      if (item) {
        item.remove();
      }
    }
    for (let entry of Array.from(liqPriceLines.entries())) {
      let item = liqPriceLines.get(entry[0]);
      if (item) {
        item.remove();
      }
    }
    orderLines = new Map();
    liqPriceLines = new Map();
    positionLines = new Map();
    historyLines = new Map();
  };

  /**
   * group the fills according to timestamp,
   * lets say, time interval 30min on the chart so
   * 16:08 and 16:22 records will end up 1 record with timestamp 16:00
   * and will be located in related candle
   * @param histories
   */
  const groupHistories = (histories: Fill[]) => {
    let items = cloneDeep(histories);
    const threshold = formatResolution(config.resolution) * 60;
    items.forEach(item => {
      item.timestamp = Math.floor(item.timestamp / 1000 / 1000);
    });
    items.forEach(item => {
      item.timestamp = Math.floor(item.timestamp / threshold) * threshold;
    });
    items = items.reduce((uniques: Fill[], item) => {
      return uniques.find(
        el => el.side === item.side && el.timestamp === item.timestamp,
      )
        ? uniques
        : [...uniques, item];
    }, []);
    return items;
  };

  const customizeTVChart = () => {
    try {
      clearCustomizations();
      if (!tvConfig.config.showOrderLines) return;
      if (!accountStore?.frontendSecrets) return;
      if (!accountStore?._accountStats) return;
      const tvWidget = getWidget();
      //@ts-ignore
      if (!tvWidget || !tvWidget._ready) return;

      let orderStatuses = [
        OrderStatus.OPEN,
        OrderStatus.PROCESSING,
        OrderStatus.PLACED,
      ];
      const orders = accountStore?._orders?.filter(
        o =>
          (o.marketID === selectedMarketId &&
            orderStatuses.includes(o.status) &&
            [OrderType.LIMIT, OrderType.MARKET].includes(o.type)) ||
          (o.marketID === selectedMarketId &&
            orderStatuses.includes(o.status) &&
            [OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(o.type)) ||
          (o.marketID === selectedMarketId &&
            orderStatuses.includes(o.status) &&
            [
              OrderType.STOP_LOSS,
              OrderType.STOP_LOSS_LIMIT,
              OrderType.TAKE_PROFIT,
              OrderType.TAKE_PROFIT_LIMIT,
            ].includes(o.type)),
      );

      const positions = accountStore?._positions?.filter(
        p => p.marketID === selectedMarketId,
      );

      orders?.sort((a, b) => a.createdAt - b.createdAt);
      orders?.forEach(order => {
        orderLines.set(order.id, createOrderComponent(order));
      });

      positions?.forEach(position => {
        positionLines.set(position.id, createPositionComponent(position));
      });

      positions?.forEach(position => {
        liqPriceLines.set(position.id, createLiqPriceComponent(position));
      });

      let histories = deepMergeArraysById(fills, accountStore?._fills ?? []);
      histories = histories.filter(o => o.marketID === selectedMarketId);
      if (histories && histories.length > 0) {
        const items = groupHistories(histories);
        items?.map(order => {
          historyLines.set(order.id, createHistoryComponent(order));
        });
      }
    } catch (error) {
      console.error(`customizeTVChart: ${error}`);
    }
  };

  const onChartReady = () => {
    lastSize.current = null;
    const tvWidget = getWidget();
    tvWidget.onContextMenu(
      (unixTime: number, price: number): ContextMenuItem[] => {
        const decimalScale = getDecimalPlacesByMarketId(selectedMarketId);
        price = Number(price.toFixed(decimalScale.price));
        return [
          {
            position: 'top',
            text: `Trade ${symbol} @ ${price}`,
            click: () => {
              setOrderInputPrice(null);
              modalRef.current?.present(
                <BuySellModal
                  afterConfirmBuy={size => {
                    lastSize.current = size;
                  }}
                  afterConfirmSell={size => {
                    lastSize.current = size;
                  }}
                  unixTime={unixTime}
                  price={price}
                  subTitle={selectedMarketId}
                  lastSize={lastSize.current}
                />,
                Modals.buySellModal,
              );
            },
          },
        ];
      },
    );

    tvWidget.subscribe('mouse_down', event => {
      timePriceRef.current.price = Number(
        timePriceRef.current.price.toFixed(decimalScale.price),
      );
      accountStore.changeOrderEntryPrice(timePriceRef.current.price);
      pushDataLayerEvent('chartTradeSelectPrice');
    });

    tvWidget
      .activeChart()
      .crossHairMoved()
      .subscribe(null, ({ time, price }) => {
        timePriceRef.current.time = time;
        timePriceRef.current.price = price;
      });

    tvWidget.activeChart().dataReady(() => {
      customizeTVChart();
    });
  };

  const resetCacheRef = useRef<() => void | undefined>();

  const apiDataFeed = getDataFeed(
    ConfigurationData,
    getAllSymbols(markets!),
    getCandleData,
    subscribeOnStream,
    unsubscribeFromStream,
    resetCacheRef,
    getDecimalPlacesByMarketId,
    tvConfig,
  );

  useEffect(() => {
    const tvWidget = getWidget();
    //@ts-ignore
    if (!tvWidget || !tvWidget._ready) return;
    const resolution = tvWidget.activeChart().resolution();
    tvWidget.setSymbol(symbol, resolution, customizeTVChart);
    onChartReady();
  }, [symbol]);

  useEffect(() => {
    const tvWidget = getWidget();
    //@ts-ignore
    if (!tvWidget || !tvWidget._ready) return;
    tvWidget.activeChart().dataReady(() => {
      customizeTVChart();
    });
  }, [
    accountStore.isOrdersOrPositionsChanged,
    accountStore?.frontendSecrets,
    fillsIsLoading,
    //@ts-ignore
    getWidget()?._ready,
  ]);

  return (
    <HistoricalPriceStyled>
      <TVChartContainer
        datafeed={apiDataFeed}
        resetCache={resetCacheRef}
        symbol={symbol}
        preset={isMobile ? 'mobile' : undefined}
        interval={config.resolution}
        chartType={config.chartType}
        onChartTypeChanged={onChartTypeChanged}
        onChartReady={onChartReady}
      />
    </HistoricalPriceStyled>
  );
};

export default observer(HistoricalPrice);
