import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isObject from 'lodash/isObject';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import values from 'lodash/values';

/**
 * Merges two arrays of objects based on a shared 'id' property, creating a new array
 * of merged objects with unique 'id' properties. If allowNewItems is true, new objects
 * in dataSecond will also be included. The resulting array is sorted by 'timestamp' or
 * 'createdAt', if either property exists.
 * @param {Array<T>} dataFirst - The first array to be merged
 * @param {Array<T>} dataSecond - The second array to be merged
 * @param {boolean} allowNewItems - Whether to allow new objects from dataSecond to be included in the resulting array
 * @returns {Array<T>} A new array of merged objects with unique 'id' properties
 */
export const deepMergeArraysById = <
  T extends { id: string | number; timestamp?: number; createdAt?: number },
>(
  dataFirst: T[],
  dataSecond: T[],
  allowNewItems = true,
): T[] => {
  if (!dataSecond) {
    return cloneDeep(dataFirst);
  }

  const cloneDeepDataFirst = cloneDeep(dataFirst);
  const cloneDeepDataSecond = cloneDeep(dataSecond);

  let result: T[];

  // Allow new items to be included in the resulting array
  if (allowNewItems) {
    // merge the two arrays using keyBy and merge
    const merged = merge(
      keyBy(cloneDeepDataFirst, 'id'),
      keyBy(cloneDeepDataSecond, 'id'),
    );
    // convert the merged object to an array
    result = values(merged);
  }
  // Only include items that exist in both arrays
  else {
    // convert the first array to an object
    const obj1 = keyBy(cloneDeepDataFirst, 'id');
    // merge the two arrays using keyBy and merge, but only if the item exists in both arrays
    const mergedObj = mergeWith(
      {},
      obj1,
      keyBy(
        filter(cloneDeepDataSecond, item =>
          find(cloneDeepDataFirst, { id: item.id }),
        ),
        'id',
      ),
      (objValue, srcValue) => {
        if (isObject(objValue)) {
          return merge(objValue, srcValue);
        }
      },
    );
    result = values(mergedObj);
  }

  // sort the array by 'timestamp' or 'createdAt', if either property exists
  if (result?.length > 0) {
    if (result[0].hasOwnProperty('timestamp')) {
      result.sort((a, b) => b.timestamp! - a.timestamp!);
    }
    if (result[0].hasOwnProperty('createdAt')) {
      result.sort((a, b) => b.createdAt! - a.createdAt!);
    }
  }

  return cloneDeep(result);
};
