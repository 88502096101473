import { Tooltip } from '@material-ui/core';

import { makeABitLessthanOrEqual } from 'utils';
import { brand } from 'utils/brand';

import { FormattedNumber } from 'components';
import NumberInput from 'components/Inputs/NumberInput';
import Loading from 'components/Loading';
import { Presets } from 'components/Presets/Presets';

import { ESTIMATED_GAS_FEE_OFFSET } from '../../..';
import { Badge } from '../../../styles';
import { ColoredText, Container, TransakKycDescription } from './styles';
import arrowRightIcon from 'assets/icons/arrow-right-gray.svg';
import errorCircleIcon from 'assets/icons/error.svg';
import helpCircleIcon from 'assets/icons/help-circle.svg';
import { USDT_DECIMALS } from 'constants/contracts';

import { UniswapSwapTokens } from 'enums';

const getDecimalScale = (selectedToken: UniswapSwapTokens) => {
  switch (selectedToken) {
    case UniswapSwapTokens.USDT:
    case UniswapSwapTokens.USDC:
    case UniswapSwapTokens.DAI:
      return 2;
    case UniswapSwapTokens.ETH:
    case UniswapSwapTokens.WETH:
    case UniswapSwapTokens.WBTC:
      return 6;
    default:
      return 3;
  }
};

const MAX_INPUT_LENGTH = 20;

type Props = {
  depositAmount: number | null;
  setDepositAmount: (amount: number | null) => void;
  maxAmount: number;
  token: UniswapSwapTokens;
  disabled: boolean;
  isMethodTransak: boolean;
  isMethodWallet: boolean;
  isCurrencyBalanceLoading: boolean;
  showStaticInputPresets: boolean;
};
const DepositInput = ({
  depositAmount,
  setDepositAmount,
  maxAmount,
  token,
  disabled,
  isMethodTransak,
  isMethodWallet,
  isCurrencyBalanceLoading,
  showStaticInputPresets,
}: Props) => {
  const decimalScale = getDecimalScale(token);
  const onChange = (value: number | null) => {
    if (value && value.toString().length > MAX_INPUT_LENGTH) return;
    setDepositAmount(value);
  };

  const isNative = token === UniswapSwapTokens.ETH;

  // Leave some to account for automatic rounding
  const parsedMaxAmount = makeABitLessthanOrEqual(
    maxAmount,
    USDT_DECIMALS,
    USDT_DECIMALS > 6 ? 100 : 1, // in case of less than RBX, where post decimal digit count is 6, auto rounding doesnt takes place
  );

  return (
    <>
      <Container>
        <div className="input-container">
          <NumberInput
            disabled={disabled}
            label={'Amount'}
            maxValue={parsedMaxAmount}
            showSingleMaxButton={isMethodWallet}
            showSwapCurrency={false}
            showCurrencyIcon={false}
            showValueApproximation={false}
            showPresets={false}
            gtmId="deposit-modal-quantity-input"
            value={depositAmount}
            onChange={onChange}
            max={parsedMaxAmount}
            autoFocus
          />
        </div>
        {isMethodWallet && !disabled ? (
          <div className="details-container">
            <span>
              Available <Badge>{token}</Badge>
            </span>
            <span>
              {isCurrencyBalanceLoading ? (
                <Loading size={12} />
              ) : (
                <>
                  {depositAmount && depositAmount > maxAmount ? (
                    <>
                      <ColoredText isError>Not Enough {token}</ColoredText>
                      <Tooltip
                        title={`Enter a lower amount or try other payment methods.`}
                        placement="top"
                      >
                        <img alt="error" src={errorCircleIcon} />
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <FormattedNumber
                        value={maxAmount}
                        decimalScale={decimalScale}
                      />
                      <img src={arrowRightIcon} alt="->" />
                      <ColoredText
                        isError={maxAmount - (depositAmount ?? 0) < 0}
                      >
                        <FormattedNumber
                          value={maxAmount - (depositAmount ?? 0)}
                          decimalScale={decimalScale}
                        />
                      </ColoredText>
                      {isNative ? (
                        <Tooltip
                          title={`Displayed balance accounts for -${ESTIMATED_GAS_FEE_OFFSET} ETH gas fee.`}
                          placement="top"
                        >
                          <img alt="help" src={helpCircleIcon} />
                        </Tooltip>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </span>
          </div>
        ) : null}
      </Container>
      {showStaticInputPresets && (
        <Presets
          onOptionSelect={setDepositAmount}
          isPresetTypeSlider={false}
          isPresetActive={val => val === depositAmount}
          selectedPreset={null}
          presets={[100, 250, 500, 1000]}
          prefix={'$'}
          className="mt-10"
          suffix={null}
        />
      )}
      {isMethodTransak && (
        <TransakKycDescription>
          For Transak deposits smaller than 50 {brand.tokenTicker}, no KYC is
          required.
        </TransakKycDescription>
      )}
    </>
  );
};

export default DepositInput;
