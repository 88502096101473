import { brand } from './brand';

export const createTypedDataSignRequestData = (
  timestamp: number,
  chainId: number,
) => {
  const eip712domain_type_definition = {
    EIP712Domain: [
      {
        name: 'name',
        type: 'string',
      },
      {
        name: 'version',
        type: 'string',
      },
      {
        name: 'chainId',
        type: 'uint256',
      },
    ],
  };

  const domain = {
    name: brand.typedDataDomainName,
    version: '1',
    chainId,
    verifyingContract: '',
  };

  // The named list of all type definitions
  const types = {
    ...eip712domain_type_definition,
    signin: [
      { name: 'message', type: 'string' },
      { name: 'timestamp', type: 'uint256' },
    ],
  };

  // The data to sign
  const value = {
    message: brand.onboardingMessage,
    timestamp: timestamp,
  };

  const signRequest = {
    types,
    primaryType: 'signin',
    domain: domain,
    message: value,
  };

  return signRequest;
};
