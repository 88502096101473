import { memo } from 'react';

import { basisToPercent } from 'utils';
import { brand } from 'utils/brand';

import useModal from 'hooks/useModal';

import { FormattedNumber } from 'components';
import EditReferralLinkModal from 'components/EditReferralLinkModal';
import { Icon } from 'components/Icon';
import ResponsiveCopyIcon from 'components/ResponsiveCopyIcon';
import Text from 'components/Text';

import Avatar from '../Avatar';
import { shortCodeToUrl } from '../InviteFriends/InviteFriendsModal';
import { CARD_LEVELS } from '../RewardsHub/cardLevels';
import ReferredGoalProgressBar from './ReferredGoalProgressBar';
import { ChangeLabel, Container, ReferralLinkInfo } from './styles';
import copyIcon from 'assets/icons/copy.svg';
import editPenWhiteIcon from 'assets/icons/edit-pen-white.svg';
import lockWithHexagon from 'assets/icons/lock-with-hexagon.svg';
import avatarImg from 'assets/images/rewards/avatar.jpeg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { ReferralModel } from 'enums/referralModel';
import { Row } from 'theme/globalStyledComponents/row';

import { Referral, ReferralLevel, ReferralLevelStatus } from 'interfaces';
import { observer } from 'mobx-react';
import { useUserRegisteredForBlastAirdrop } from 'queryHooks';
import { useTranslation } from 'react-i18next';

const KOL_VOLUME_GOAL = 1_000_000_000;

export const MAX_REFERRAL_LINK_CHANGES = 3;

export const getReferralLevelComission = (
  level: number,
  cardLevels: ReferralLevel[],
) => {
  const cardLevel = cardLevels.find(cardLevel => cardLevel.level === level);
  return cardLevel?.comissionPercent ?? '-';
};

export const getReferralLevelAccumulatedBonuses = (
  level: number,
  cardLevels: ReferralLevel[],
): number => {
  return cardLevels.reduce((accumulatedBonus, cardLevel) => {
    if (cardLevel.level <= level) {
      return accumulatedBonus + cardLevel.comissionBonus;
    } else {
      return accumulatedBonus;
    }
  }, 0);
};

const getGoalProgressForPercentageModel = (referral: ReferralLevelStatus) => {
  if (referral.next === undefined) return 1; // for the last a·chiev·a·ble level

  const lastAchievedVolume = referral.current.volume;

  return (
    (referral.volume - lastAchievedVolume) /
    (referral.next.volume - lastAchievedVolume)
  );
};

const getParsedProfileInfo = (userReferral: Referral) => {
  if (userReferral.model === ReferralModel.KOL) {
    return {
      currentLevelLabel: 'VIP',
      referredVolumeGoal: KOL_VOLUME_GOAL,
      goalProgress: userReferral.referralLevelStatus.volume / KOL_VOLUME_GOAL,
      commission: basisToPercent(userReferral.modelFeePercent) ?? 0,
    };
  }
  if (userReferral.model === ReferralModel.Percentage) {
    return {
      currentLevelLabel: `Level ${userReferral.referralLevelStatus.current.level}`,
      referredVolumeGoal: userReferral.referralLevelStatus.next?.volume,
      goalProgress: getGoalProgressForPercentageModel(
        userReferral.referralLevelStatus,
      ),
      commission: getReferralLevelComission(
        userReferral.referralLevelStatus.current.level,
        CARD_LEVELS,
      ),
    };
  }
  return {
    currentLevelLabel: `--`,
    referredVolumeGoal: undefined,
    goalProgress: 1,
    commission: undefined,
  };
};

type Props = {
  userReferral: Referral | undefined;
};

const ProfileInfo = ({ userReferral }: Props) => {
  const modal = useModal();
  const { t } = useTranslation();

  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { data: userRegistration } = useUserRegisteredForBlastAirdrop(
    frontendSecrets?.jwt,
  );

  if (!userReferral) return null;
  const twitterProfilePicture = userRegistration?.twitterData?.profilePicture;
  const { shortCode, amendCounter, referralLevelStatus } = userReferral;

  const isAmendable = amendCounter < MAX_REFERRAL_LINK_CHANGES;
  const referralLinkChangesLeft = MAX_REFERRAL_LINK_CHANGES - amendCounter;

  const isReferralModelKOL = userReferral.model === ReferralModel.KOL;

  const { currentLevelLabel, referredVolumeGoal, goalProgress, commission } =
    getParsedProfileInfo(userReferral);

  const openNewReferralLinkModal = () => {
    modal.present(
      <EditReferralLinkModal
        referralLinkChangesLeft={referralLinkChangesLeft}
      />,
      Modals.editReferralLinkModal,
    );
  };

  const linkUrl = shortCodeToUrl(shortCode);

  return (
    <Container>
      <Text variant="HEADING_H2" fontWeight="semiBold" className="mt-10">
        Referral Dashboard
      </Text>
      <div className="header">
        <div className="left-col">
          <Avatar
            url={twitterProfilePicture || avatarImg}
            label={userReferral.referralLevelStatus.current.level.toString()}
          />
          <div className="separator" />
          <ReferralLinkInfo>
            <div className="header">
              <Text variant="BODY_S" color="shadesForeground200">
                {t('referralLink')}
              </Text>
              {isAmendable && (
                <ChangeLabel variant="BODY_XS" isSuccess={true}>
                  {referralLinkChangesLeft}{' '}
                  {referralLinkChangesLeft === 1 ? t('Change') : t('Changes')}{' '}
                  {t('Left')}
                </ChangeLabel>
              )}
            </div>
            <div className="edit-container">
              <Text variant="HEADING_H1" fontWeight="semiBold">
                {shortCode}
              </Text>
              {isAmendable ? (
                <Icon
                  src={editPenWhiteIcon}
                  alt="Edit"
                  avoidPadding
                  data-gtmid="icon-edit-referral-link"
                  onClick={openNewReferralLinkModal}
                />
              ) : null}
              <ResponsiveCopyIcon textToCopy={linkUrl} iconColor={'white'} />
            </div>
          </ReferralLinkInfo>
        </div>
        <div className="right-col">
          <div className="current-level">
            <div className="left">
              <Text variant="BODY_S" color="shadesForeground200">
                {t('CurrentLevel')}
              </Text>
              <Text variant="BODY_S" fontWeight="semiBold">
                {currentLevelLabel}
              </Text>
            </div>
            <Icon src={lockWithHexagon} alt="lock" />
          </div>

          <div className="level-details">
            {isReferralModelKOL ? null : (
              <>
                <div>
                  <Text variant="BODY_S" color="shadesForeground200">
                    {t('AccumulativeBonus')}
                  </Text>
                  <Row gap={3}>
                    <Icon
                      src={brand.tokenLogo}
                      alt={brand.tokenTicker}
                      size="SM"
                    />
                    <FormattedNumber
                      value={getReferralLevelAccumulatedBonuses(
                        userReferral.referralLevelStatus.current.level,
                        CARD_LEVELS,
                      )}
                      variant="BODY_S"
                      fontWeight="semiBold"
                    />
                  </Row>
                </div>
                <div className="separator" />
              </>
            )}
            <div>
              <Text variant="BODY_S" color="shadesForeground200">
                {t('Comission')}
              </Text>

              <FormattedNumber
                value={commission}
                decimalScale={0}
                suffix="%"
                variant="BODY_S"
                fontWeight="semiBold"
              />
            </div>
          </div>
        </div>
      </div>
      {referralLevelStatus.volume !== undefined && (
        <ReferredGoalProgressBar
          referredCurrent={referralLevelStatus?.volume}
          referredGoal={referredVolumeGoal}
          progress={goalProgress}
          nextLevel={
            isReferralModelKOL
              ? undefined
              : userReferral.referralLevelStatus.next?.level
          }
        />
      )}
    </Container>
  );
};

export default observer(ProfileInfo);
