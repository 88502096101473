import { FormattedNumber, Icon } from 'components';
import Loading from 'components/Loading';
import Text from 'components/Text';
import { TextVariant } from 'components/Text/variant';

import editPenGrayIcon from 'assets/icons/edit-pen.svg';
import reloadIcon from 'assets/icons/refresh.svg';

import { UniswapSwapTokens } from 'enums';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;

  > div {
    display: flex;
    justify-content: space-between;
    font-size: ${TextVariant['BODY_S'].fontSize};
    line-height: ${TextVariant['BODY_S'].lineHeight};
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

const Parent = ({
  avoidWrapper,
  children,
}: {
  avoidWrapper: boolean;
  children: React.ReactNode;
}) => (avoidWrapper ? <>{children}</> : <Container>{children}</Container>);

type Props = {
  onRateReload: () => void;
  isUpdatingQuote: boolean;
  onSlippageEdit: () => void;
  slippage: number;
  rate: string | undefined;
  asset: string;
  amount: number | null;
  avoidWrapper?: boolean;
};
export const RateAndSlippageDetails = ({
  asset,
  amount,
  onRateReload,
  onSlippageEdit,
  rate,
  isUpdatingQuote,
  slippage,
  avoidWrapper = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Parent avoidWrapper={avoidWrapper}>
      {amount ? (
        <div>
          {t('rate')}
          <Text flexed gap={2}>
            {amount} {asset} ≈ {` `}
            {isUpdatingQuote || !rate ? (
              <Loading size={12} />
            ) : (
              <FormattedNumber value={rate} />
            )}{' '}
            {UniswapSwapTokens.RBX}
            <Icon size="S" src={reloadIcon} onClick={onRateReload} />
          </Text>
        </div>
      ) : null}
      <div>
        {t('slippage')}
        <Text flexed gap={2}>
          {slippage}%{' '}
          <Icon size="S" src={editPenGrayIcon} onClick={onSlippageEdit} />
        </Text>
      </div>
    </Parent>
  );
};
