import { memo } from 'react';

import PositionsTable from 'components/Tables/PositionsTable';
import { TablePageContainer } from 'components/Tables/styles';
import Text from 'components/Text';

import Portfolio from '../../index';

const PortfolioPositions = () => {
  return (
    <Portfolio>
      <TablePageContainer addBackground addPadding>
        <Text
          variant="HEADING_H2"
          fontWeight="semiBold"
          flexed
          className="justify-between"
        >
          Positions
        </Text>

        <PositionsTable />
      </TablePageContainer>
    </Portfolio>
  );
};

export default memo(PortfolioPositions);
