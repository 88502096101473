import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  border-radius: 8px;
  padding: 15px;
  background: ${({ theme }) => theme.colors.shadesBackground600};

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .label {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.white};
      gap: 6px;
    }

    .progress-value {
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.shadesForeground300};
      .current-progress {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  .stats-columns {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .stats-column {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .label,
      .formatted-number {
        font-size: 12px;
        line-height: 14px;
      }
      .label {
        color: ${({ theme }) => theme.colors.shadesForeground300};
        font-weight: ${({ theme }) => theme.fontWeights.medium};
      }
      .formatted-number {
        color: ${({ theme }) => theme.colors.white};
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      }
      @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
        .label,
        .formatted-number {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
`;
