import { TradeSide } from '../enums';

import { Position } from 'interfaces';

/**
 * calcs liquidation price with other positions with following formula
 * it is temporary will be calculated by backend later on
 * for Long; liquidation price = max(0, (WB - sum(maint% x otherPosNotional) - avgEntryPrice x qty)/[(maint%-1) x qty])
 * for Short; liquidation price = (WB - sum(maint% x otherPosNotional) + avgEntryPrice x qty)/[(maint%+1) x qty])
 * @returns liqPrice
 */
export const calculateLiquidationPrice = (
  _positions: Position[] | undefined,
  balance: number | undefined,
  position: Position,
  maintenanceMargin = 0.03,
) => {
  let otherPosNotional = _positions
    ?.filter(item => item.marketID !== position.marketID)
    .map(o => o.notional)
    .reduce((a, c) => a + c, 0);

  // const remainingBalance = balance;
  if (!otherPosNotional || otherPosNotional === 0) otherPosNotional = 1;

  let liqPrice = 0;
  if (position.side === TradeSide.LONG) {
    const dividend =
      (balance ?? 0) -
      maintenanceMargin * otherPosNotional -
      position.avgEntryPrice * position.size;
    let denominator = (maintenanceMargin - 1) * position.size;
    if (denominator === 0) denominator = 1;

    liqPrice = Math.max(0, dividend / denominator);
    return liqPrice;
  } else {
    const dividend =
      (balance ?? 0) -
      maintenanceMargin * otherPosNotional +
      position.avgEntryPrice * position.size;
    let denominator = (maintenanceMargin + 1) * position.size;
    if (denominator === 0) denominator = 1;

    liqPrice = dividend / denominator;
    return liqPrice;
  }
};
