import { memo } from 'react';

import { AirdropUserStats } from '../../PhaseContainer';
import ClaimRewards from './ClaimRewards';
import EligibilityCheck from './EligibilityCheck';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 30px;
  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    margin-top: 0;
    width: 420px;
  }
`;

type Props = {
  airdropUserStats: AirdropUserStats;
};
const EligibilityAndClaimSection = ({ airdropUserStats }: Props) => {
  const { isEligible } = airdropUserStats;
  const { t } = useTranslation();

  return (
    <Container>
      <span className="font-body-l font-medium">
        {isEligible ? t('congratsEligible') : t('ohNoNotEligible')}
      </span>
      <EligibilityCheck airdropUserStats={airdropUserStats} />
      {isEligible ? <ClaimRewards /> : null}
    </Container>
  );
};

export default memo(EligibilityAndClaimSection);
