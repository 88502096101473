import { memo } from 'react';

import NewsList from '../../components/NewsList';
import Portfolio from '../../index';
import Overview from 'pages/Portfolio/components/Overview';

const PortfolioOverview = () => {
  return (
    <Portfolio>
      <Overview />
      <NewsList />
    </Portfolio>
  );
};

export default memo(PortfolioOverview);
