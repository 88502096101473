import MarketSelect from '../MarketSelect';
import MainStats from './MainStats';
import OtherStats from './OtherStats';
import { MarketBarStyled } from './style';

const MarketBar = () => {
  return (
    <MarketBarStyled data-cy="market-bar">
      <MarketSelect />
      <div className="main-stats">
        <MainStats />
      </div>
      <div className="other-stats">
        <OtherStats />
      </div>
    </MarketBarStyled>
  );
};

export default MarketBar;
