import tier1Bg from 'assets/images/tierCards/1.png';
import tier2Bg from 'assets/images/tierCards/2.png';
import tier3Bg from 'assets/images/tierCards/3.png';
import tier4Bg from 'assets/images/tierCards/4.png';
import tier5Bg from 'assets/images/tierCards/5.png';
import tier6Bg from 'assets/images/tierCards/6.png';
import tier7Bg from 'assets/images/tierCards/7.png';

import styled, { css } from 'styled-components';

const TIER_TO_BG_MAP = {
  0: tier1Bg,
  1: tier2Bg,
  2: tier3Bg,
  3: tier4Bg,
  4: tier5Bg,
  5: tier6Bg,
  6: tier7Bg,
};

type Props = {
  tier?: number;
};
export const StyledTierCard = styled.div<Props>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
  overflow: hidden;
  width: 335px;
  border-radius: 12px;
  box-sizing: border-box;
  height: 168px;
  justify-content: space-between;
  ${({ tier }) =>
    tier === undefined
      ? css`
          background-color: #d3d3d310;
        `
      : css`
          background: url(${tier === undefined ? 6 : TIER_TO_BG_MAP[tier]})
            lightgray 50% / 100% 100% no-repeat;
        `}

  > div {
    width: 100%;
  }

  .trade_button {
    color: ${({ theme }) => theme.colors.black};
  }

  .lock_container {
    display: flex;
    padding: 5px;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.white};
  }

  .progress_bar {
    background-color: rgba(255, 255, 255, 0.2);
    height: 8px;
    border-radius: 40px;
    margin: 9px 0px;
    width: 100%;
  }
`;
