import { ORDERBOOK_TRADES_TAB_VIEW_HEIGHT_THRESHOLD } from '../TradingHistoryTabs/TradingHistoryTabs';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  min-width: 258px;
  box-sizing: border-box;
  flex: 1;

  @media (min-height: ${ORDERBOOK_TRADES_TAB_VIEW_HEIGHT_THRESHOLD + 1}px) {
    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      flex: initial;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    width: 256px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    width: 329px;
  }
`;

export const OrdersTableStyled = styled.div`
  width: 100%;
`;

export const BidsAndAsksContainer = styled.div`
  max-height: 187px;
  min-height: 187px;
  /* overflow: hidden; */

  &.asks {
    display: flex;
    flex: 1 1 1;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
