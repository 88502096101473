import { isBrandBfx } from 'utils/brand';

import Loading from 'components/Loading';
import Text from 'components/Text';

import { Steps, StyledClipStepIcon, StyledStepIcon } from './styles';
import checkedIcon from 'assets/icons/check-white.svg';

export const StepIcon = ({
  checked,
  loading,
  isCurrentStep = false,
  stepNumber,
}: {
  checked: boolean;
  isCurrentStep?: boolean;
  loading?: boolean;
  stepNumber: number;
}) => {
  if (loading) return <Loading size={'20px'} />;

  if (isBrandBfx) {
    return (
      <StyledClipStepIcon
        isActive={isCurrentStep}
        variant="BODY_M"
        fontWeight="semiBold"
        color={isCurrentStep ? 'white' : 'shadesForeground200'}
      >
        {stepNumber}
      </StyledClipStepIcon>
    );
  }

  return (
    <StyledStepIcon checked={checked}>
      {checked ? (
        <img src={checkedIcon} alt="check" />
      ) : isCurrentStep ? (
        <div className="dot" />
      ) : null}
    </StyledStepIcon>
  );
};

type StepListProps = {
  steps: {
    label: string;
    description: string;
    rightComponent?: React.ReactElement;
  }[];
  currentStep?: number;
  isLoading?: boolean;
};
export const StepList = ({
  steps,
  currentStep = 0,
  isLoading,
}: StepListProps) => {
  return (
    <Steps>
      {steps.map(({ label, description, rightComponent }, index) => {
        const stepNumber = index + 1;

        return (
          <>
            <div className="step">
              <StepIcon
                checked={stepNumber < currentStep}
                loading={stepNumber === currentStep ? isLoading : false}
                isCurrentStep={currentStep === stepNumber}
                stepNumber={stepNumber}
              />
              <div className="column">
                <Text variant="BODY_S" fontWeight="semiBold">
                  {!isBrandBfx ? `${stepNumber}. ` : ''}
                  {label}
                </Text>
                <Text
                  variant="BODY_S"
                  fontWeight="semiBold"
                  color="shadesForeground200"
                >
                  {description}
                </Text>
              </div>

              {rightComponent}
              {isBrandBfx && currentStep > stepNumber ? (
                <StyledStepIcon checked>
                  <img src={checkedIcon} alt="check" />
                </StyledStepIcon>
              ) : null}
            </div>
            {isBrandBfx && index < steps.length - 1 ? (
              <div className="step-divider" />
            ) : null}
          </>
        );
      })}
    </Steps>
  );
};
