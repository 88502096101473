import { memo } from 'react';

import { Tooltip } from '@material-ui/core';

import useModal from 'hooks/useModal';

import { FormattedNumber } from 'components';
import Icon from 'components/Icon';
import Loading from 'components/Loading';

import SlippageSettings from '../../../../../../components/SlippageSettingsModal';
import { Badge } from '../styles';
import { isTargetToken } from '../utils';
import { Container } from './styles';
import arrowRightIcon from 'assets/icons/arrow-right-gray.svg';
import editIcon from 'assets/icons/edit-pen-white.svg';
import infoIcon from 'assets/icons/info-circle.svg';
import refreshIcon from 'assets/icons/refresh.svg';
import { Modals } from 'constants/modals';

import { TargetToken } from '..';
import { UniswapSwapTokens } from 'enums';

type Props = {
  children: React.ReactNode;
  token: UniswapSwapTokens;
  slippage: number;
  amount: number | null;
  onUpdateSlippage: (slippage: number) => void;
  quote: string | undefined;
  isUpdatingQuote: boolean;
  onRefreshQuote: () => void;
  editSlippageDisabled: boolean;
};
const SwapDetails = ({
  slippage,
  token,
  onUpdateSlippage,
  amount,
  children,
  quote,
  isUpdatingQuote,
  onRefreshQuote,
  editSlippageDisabled,
}: Props) => {
  const modal = useModal();

  const onEditSlippage = () => {
    if (editSlippageDisabled) return;

    modal.present(
      <SlippageSettings
        defaultSlippage={slippage}
        onUpdate={newSlippage => {
          onUpdateSlippage(newSlippage);
        }}
      />,
      Modals.slippageSettingsModal,
    );
  };

  return (
    <Container>
      {!isTargetToken(token) ? (
        <div className="content">
          <div className="row">
            <span>
              Swap
              <Tooltip
                title={`${token} will be converted to ${TargetToken}`}
                placement="top"
              >
                <img src={infoIcon} alt="info" />
              </Tooltip>
            </span>
            <span>
              <Badge>{token}</Badge>
              <img src={arrowRightIcon} alt="->" />
              <Badge>{TargetToken}</Badge>
            </span>
          </div>

          {amount ? (
            <div className="row">
              <span>Rate</span>
              <span>
                <FormattedNumber value={amount} /> {token} ≈ {` `}
                {isUpdatingQuote || !quote ? (
                  <Loading size={12} />
                ) : (
                  <FormattedNumber value={quote} />
                )}{' '}
                {TargetToken}
                <img
                  data-gtmid={`icon-reload-${token.toLowerCase()}-rate`}
                  src={refreshIcon}
                  alt="refresh"
                  onClick={onRefreshQuote}
                  className="cursor-pointer"
                />
              </span>
            </div>
          ) : null}

          <div className="row">
            <span>Slippage</span>
            <span>
              {`${slippage}%`}
              <Icon
                avoidPadding
                onClick={onEditSlippage}
                data-gtmid="icon-edit-slippage"
                size="S"
                src={editIcon}
                alt="refresh"
                className={`edit-icon ${
                  editSlippageDisabled ? 'cursor-blocked' : 'cursor-pointer'
                }`}
              />
            </span>
          </div>
        </div>
      ) : null}
      {children}
    </Container>
  );
};

export default memo(SwapDetails);
