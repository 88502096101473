import { Icon } from 'components';

import checkedIcon from 'assets/icons/check-white-circle-positive.svg';
import errorIcon from 'assets/icons/error.svg';
import { ModalStatus } from 'providers/ModalPresenter';

import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.2; }
  100% { opacity: 1; }
`;

const BlinkingDot = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.colors.positiveForeground300};
  animation: ${blink} 1s infinite;
  border-radius: 100%;
`;

const StatusIcon = ({ status }: { status?: ModalStatus }) => {
  if (status === 'processing') return <BlinkingDot />;

  if (status === 'success') return <Icon src={checkedIcon} size="S" />;

  if (status === 'error') return <Icon src={errorIcon} size="S" />;

  return null;
};

export default StatusIcon;
