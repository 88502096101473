import { TextVariant } from 'components/Text/variant';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.shadesBackground600};
  border-radius: 8px;
  margin-bottom: 10px;

  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;

    .h-14 {
      height: 14px;
      max-height: 14px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: ${TextVariant['BODY_S'].fontSize};
      line-height: ${TextVariant['BODY_S'].lineHeight};
      color: ${({ theme }) => theme.colors.shadesForeground200};
    }
  }
`;
