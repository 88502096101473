import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  padding: 36px;
  box-sizing: border-box;
  min-height: calc(100vh - 200px);
`;

export const CenterContent = styled.div`
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    width: 507px;
  }
`;

export const InputsContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ArrowDownContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease-in-out;

    :hover {
      transform: translateY(3px);
    }
  }
`;
