import { deserializeSingle } from 'utils/apiMiddleware';
import { parseJwt } from 'utils/jwt';
import { notifications, showNotification } from 'utils/notifications';

import { API_MAPPINGS } from 'constants/apiMappings';
import CentrifugeService from 'service/centrifugeService';
import { mixpanelService } from 'service/mixpanelService';
import AccountStore from 'stores/account';
import CentrifugeStore from 'stores/centrifuge';

import { PublicationContext, SubscribedContext } from 'centrifuge';
import { ProfileType, WebsocketChannels } from 'enums';
import { AccountStats, Notification } from 'interfaces';

const showAccountNotifications = (notifications: Notification[]) => {
  notifications.forEach(({ title, description, type }) => {
    showNotification({ title, description, type }, true);
  });
};

const getChannelName = (jwt: string) => {
  const jwtPayload = parseJwt(jwt);
  const profileId = jwtPayload.sub;
  const channelName = `${WebsocketChannels.Account}@${profileId}`;
  return channelName;
};

const handlePublicationEvent = (
  accountStore: AccountStore,
  publicationData: PublicationContext,
) => {
  if (!publicationData.data) {
    return;
  }
  const accountData = deserializeSingle<AccountStats>(
    publicationData.data,
    API_MAPPINGS.PROFILE_DATA,
  );
  // @dev: may be a delay between data and unsubscribe so we need to validate the wallet
  // @TODO: change wallet check to profileId check (after discussion with Kirill)
  // if (data.wallet !== account) return;
  // console.log('account publication event', data);
  // Published account notifications, skip merging account data
  if (
    accountData.profileNotifications &&
    accountData.profileNotifications?.length > 0
  ) {
    showAccountNotifications(accountData.profileNotifications);
  } else {
    accountStore.setAccount(accountData);
  }
  accountStore.setIsLoading(false);
};

const handleSubscribedEvent = (
  centrifugeStore: CentrifugeStore,
  accountStore: AccountStore,
  account: string,
  subscribeData: SubscribedContext,
) => {
  const accountDataTmp = subscribeData.data;

  // accountDataTmp.positions[0].take_profit = {
  //   market_id: 'BTC-USD',
  //   id: '1',
  //   status: OrderStatus.PLACED,
  //   trigger_price: 35000,
  //   size_percent: 0.25,
  //   order_type: OrderType.TAKE_PROFIT,
  // };

  // accountDataTmp.positions[0].stop_loss = {
  //   market_id: 'BTC-USD',
  //   id: '1',
  //   status: OrderStatus.PLACED,
  //   trigger_price: 25000,
  //   size_percent: 0.5,
  //   order_type: OrderType.STOP_LOSS,
  // };

  // accountDataTmp.positions[1].stop_loss = {
  //   market_id: 'ETH-USD',
  //   id: '1',
  //   status: OrderStatus.PLACED,
  //   trigger_price: 1500,
  //   size_percent: 0.3,
  //   order_type: OrderType.STOP_LOSS,
  // };

  const accountData = deserializeSingle<AccountStats>(
    accountDataTmp,
    API_MAPPINGS.PROFILE_DATA,
  );

  if (
    accountData.profileType !== ProfileType.VAULT &&
    accountData.wallet.toLowerCase() !== account.toLowerCase()
  ) {
    return;
  }

  if (centrifugeStore.isPrivateDisconnected) {
    notifications.connectionToDataReEstablished('profile data');
    centrifugeStore.setIsPrivateDisconnected(false);
  }

  accountStore.setAccount(accountData, true);
  accountStore.setIsLoading(false);
};

const handleUnsubscribedEvent = (accountStore: AccountStore) => {
  // Don't show when changing wallets or when logging out manually
  if (!accountStore.frontendSecrets) {
    return;
  }

  accountStore.setIsLoading(true);
  notifications.couldNotReEstablishConnectionToData('profile data');
};

const handleSubscribingEvent = (accountStore: AccountStore, data: any) => {
  if (data.code === 0) {
    return;
  }

  accountStore.setIsLoading(true);
  notifications.dataConnectionLost('Profile data');
};

// Account
export const handleAccountChannelSubscription = (
  account: string | null | undefined,
  accountStore: AccountStore,
  centrifugeStore: CentrifugeStore,
) => {
  if (!account || !accountStore?.frontendSecrets?.jwt || !centrifugeStore) {
    return;
  }

  const privateCentrifugeService = CentrifugeService.getPrivate(
    accountStore.frontendSecrets.jwt,
    account,
    accountStore,
  );

  const channelName = getChannelName(accountStore.frontendSecrets.jwt);

  if (!privateCentrifugeService.hasSubscription(channelName)) {
    accountStore.setIsLoading(true);
  }

  const sub = privateCentrifugeService.addSubscription(channelName);

  sub.on('publication', (publicationData: PublicationContext) => {
    handlePublicationEvent(accountStore, publicationData);
  });

  // Initial data
  sub.on('subscribed', (subscribeData: SubscribedContext) => {
    mixpanelService.connectWalletSuccess(account);
    handleSubscribedEvent(
      centrifugeStore,
      accountStore,
      account,
      subscribeData,
    );
  });

  sub.on('unsubscribed', () => {
    handleUnsubscribedEvent(accountStore);
  });

  sub.on('subscribing', (data: any) => {
    handleSubscribingEvent(accountStore, data);
  });
};
