import { memo, useState } from 'react';

import { Tooltip } from '@material-ui/core';

import { showNotification } from 'utils';

import { Container } from './styles';
import copyIcon from 'assets/icons/copy.svg';
import eyeClosed from 'assets/icons/eye-close.svg';
import eyeOpen from 'assets/icons/eye-open.svg';

import { NotificationType } from 'enums';

type Props = {
  secret: string;
  label?: string;
  showVisibilityToggle?: boolean;
  initialIsVisible?: boolean;
};
const CredentialRevealer = ({
  label = '',
  secret,
  showVisibilityToggle = true,
  initialIsVisible = false,
}: Props) => {
  const [isVisible, setIsVisible] = useState(initialIsVisible);

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState);
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(secret);

      showNotification({
        title: 'Copied to clipboard',
        description: `${label ?? 'Credential'} copied to clipboard!`,
        type: NotificationType.Positive,
      });
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <Container>
      {label && <div className="top-row">{label}</div>}
      <div className="bottom-row">
        <div className="input-container">
          <Tooltip title={isVisible ? secret : ''} placement="top">
            <input
              type={isVisible ? 'text' : 'password'}
              value={secret}
              readOnly
            />
          </Tooltip>
          <img
            src={copyIcon}
            alt="copy"
            className="cursor-pointer"
            onClick={copyToClipboard}
          />
        </div>
        {showVisibilityToggle && (
          <img
            src={isVisible ? eyeClosed : eyeOpen}
            onClick={toggleVisibility}
            alt="eye"
            className="cursor-pointer"
          />
        )}
      </div>
    </Container>
  );
};

export default memo(CredentialRevealer);
