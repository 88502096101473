import { marketLeverageValues } from './marketLeverageValues';
import { StyledSlider } from './styles';
import { RiskLevel } from './utils';

const BlankLeverageSlider = () => {
  return (
    <StyledSlider
      aria-label="Custom marks"
      defaultValue={0}
      getAriaValueText={v => `${v}x`}
      step={1}
      valueLabelDisplay="auto"
      marks={marketLeverageValues}
      min={1}
      max={20}
      key={0}
      disabled
    />
  );
};

export default BlankLeverageSlider;
