import { memo } from 'react';

import { AbstractConnector } from '@web3-react/abstract-connector';

import { isMobileDevice } from 'utils';

import MethodOption from '../MethodOption';

import {
  doesCurrentChainSupportParticleAuth,
  injected,
  socialconnect,
  walletconnect,
} from 'connectors';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .title {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    line-height: 16px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .row {
    display: flex;

    gap: inherit;
  }

  .method-row {
    display: flex;
    flex-direction: column;
    gap: inherit;

    @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
      flex-direction: row;
    }
  }
`;

export const isInjectedProviderMetamask = (): boolean => {
  return !!window.ethereum?.isMetaMask;
};

type Props = {
  connectWallet: (
    connector: AbstractConnector,
    isVault?: boolean,
  ) => Promise<void>;
};
const ConnectMethods = ({ connectWallet }: Props) => {
  const isInjectedMetamask = isInjectedProviderMetamask();
  const { t } = useTranslation();

  const isMobile = isMobileDevice();
  // Show Metamask option only on desktop or if Metamask is installed on mobile
  const showMetamask = !isMobile || (isMobile && isInjectedMetamask);

  return (
    <>
      <Section>
        <span className="title">{t('chooseWallet')}</span>
        {showMetamask ? (
          <div className="method-row">
            <MethodOption
              connectWallet={connectWallet}
              connector={injected}
              gtmId="button-metamask"
              dataCy="metamask-connect-btn"
              iconBorder="half"
              showMoreIcon={false}
            />
            <MethodOption
              connectWallet={connectWallet}
              connector={injected}
              isVault={true}
              dataCy="vault-connect-btn"
              gtmId="button-vault"
            />
          </div>
        ) : null}
        <MethodOption
          gtmId="button-walletconnect"
          connectWallet={connectWallet}
          connector={walletconnect}
        />
      </Section>

      {doesCurrentChainSupportParticleAuth ? (
        <Section>
          <span className="title">{t('orConnectWithSocials')}</span>
          <MethodOption
            connector={socialconnect}
            connectWallet={connectWallet}
            gtmId="button-socialconnect"
            hideLabel
          />
        </Section>
      ) : null}
    </>
  );
};

export default memo(ConnectMethods);
