import React, { useCallback } from 'react';

import Text from 'components/Text';

import { Container, ErrorMessage, InputContainer } from './styles';
import errorIcon from 'assets/icons/error.svg';

import { useDebouncedCallback } from 'use-debounce';

type Props = {
  type?: 'default' | 'outline';
  value: any;
  onChange?: (value: string) => void;
  label?: string;
  debounceTimeout?: number;
  placeholder?: string;
  showError?: boolean;
  errorMessage?: string | null;
  icon?: string;
  leftElement?: React.ReactElement;
  showIcon?: boolean;
  isRequired?: boolean;
  overrideInputClass?: string; // overrides input class whenever passed as props
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  Exclude<
    keyof React.InputHTMLAttributes<HTMLInputElement>,
    'defaultValue' | 'type' | 'value' | 'onChange'
  >
>;

const TextInput = (props: Props) => {
  const {
    type = 'default',
    leftElement,
    label,
    value,
    onChange,
    debounceTimeout,
    disabled,
    placeholder = '',
    showError = false,
    errorMessage,
    showIcon = true,
    icon,
    autoFocus,
    isRequired = false,
    overrideInputClass,
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = React.useState(false);

  const changeValue = useCallback(value => {
    onChange?.(value);
  }, []);
  const changeValueDebounced = useCallback(
    useDebouncedCallback(value => {
      changeValue(value);
    }, debounceTimeout),
    [],
  );

  const handleValueChange = useCallback(({ value }: { value: any }) => {
    if (debounceTimeout) {
      changeValueDebounced(value);
    } else {
      changeValue(value);
    }
  }, []);

  const handleFocusInput = () => {
    inputRef.current?.focus();
  };

  return (
    <Container onClick={handleFocusInput}>
      {type === 'outline' && label && (
        <Text className="flex mb-5 gap-3" variant="BODY_S">
          {isRequired ? <Text color="negativeForeground200">*</Text> : null}
          {label}
          {showError && <img src={errorIcon} alt="error icon" />}
        </Text>
      )}
      <InputContainer
        isFocused={isFocused}
        showError={showError}
        isDisabled={disabled}
        className="container"
        type={type}
      >
        {type === 'default' && label && (
          <span className="label">
            {label}
            {showError && <img src={errorIcon} alt="error icon" />}
          </span>
        )}
        <div className="value-content">
          {leftElement}
          <input
            ref={inputRef}
            className={overrideInputClass || 'value'}
            type="text"
            value={value}
            placeholder={placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={event => handleValueChange({ value: event.target.value })}
            disabled={disabled}
            autoFocus={autoFocus}
          />
          <div className="currency-container">
            {showIcon && icon && (
              <img className="currency-icon" src={icon} alt={`input icon`} />
            )}
          </div>
        </div>
      </InputContainer>
      {showError && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default TextInput;
