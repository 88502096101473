import { memo } from 'react';

import { getRequestStatusText } from '../notifications';
import shortcutIcon from 'assets/icons/shortcut-gray.svg';
import { SpotStateType } from 'enums/spotState';
import { SpotType } from 'enums/spotType';

import { AmendType } from '..';
import { config } from 'config';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  p {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  .confirmations {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }

  .Toastify__progress-bar-theme--dark {
    background: red !important;
  }
`;

const getFooter = (
  positionInQueue: number,
  spotStateType: SpotStateType,
  t,
) => {
  if (spotStateType === SpotStateType.Executed) return t('success');

  if (spotStateType === SpotStateType.Executing) return t('processing');

  return `${t('positionInQueue')}: ${positionInQueue}`;
};

const convertSpotTypeToAmendType = (spotType: SpotType) => {
  if (spotType === SpotType.Deposit) return AmendType.Stake;
  return AmendType.UnStake;
};

type Props = {
  positionInQueue: number;
  amount: number;
  txhash: string;
  type: SpotType;
  spotStateType: SpotStateType;
  tokenPair: string;
};
const PendingVaultActivityToast = ({
  spotStateType,
  positionInQueue,
  amount,
  type,
  txhash,
  tokenPair,
}: Props) => {
  const etherscanUrl = `${config.etherscanUrl}/tx/${txhash}`;

  const { t } = useTranslation();
  const amendType = convertSpotTypeToAmendType(type);

  const requestStatus = getRequestStatusText(
    amendType,
    spotStateType,
    tokenPair,
    amount,
    positionInQueue,
    t,
  );

  return (
    <Container>
      <h6>{requestStatus.title}</h6>
      <p>
        {requestStatus.description}
        <a
          href={etherscanUrl}
          target="_blank"
          rel="noreferrer"
          className="ml-5"
        >
          <img src={shortcutIcon} alt="Shortcut" />
        </a>
      </p>
      <p className="m-0 confirmations">
        {getFooter(positionInQueue, spotStateType, t)}
      </p>
    </Container>
  );
};

export default memo(PendingVaultActivityToast);
