import { TextField, InputAdornment } from '@material-ui/core';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { TextVariant } from 'components/Text/variant';

import searchIcon from 'assets/icons/search.svg';

import styled, { useTheme } from 'styled-components';

const TextFieldStyled = styled(TextField)`
  input.MuiInput-input::placeholder {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    opacity: 1;
  }
`;

// const Separator = styled.div`
//   background: ${({ theme }) => theme.colors.shadesBackground500};
//   width: 1.5px;
//   margin: 0 10px 0 5px;
//   height: 18px;
// `;

const StyledIcon = styled.img`
  height: 16px;
  width: 16px;
  margin: 0 5px 0 0;
`;

type SearchInputProps = {
  placeholder: string;
  onChange: (value: string) => void;
  onClick?: () => void;
  value: string;
  className?: string;
  block?: boolean;
};
const Search = ({
  placeholder,
  onChange,
  value,
  onClick,
  className,
  block = false,
}: SearchInputProps) => {
  const { colors, fontWeights }: any = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <TextFieldStyled
      onClick={onClick}
      data-cy="search-input"
      id="standard-basic"
      variant="standard"
      placeholder={placeholder}
      style={{ background: 'transparent', width: '100%' }}
      value={value}
      onChange={handleChange}
      autoComplete={'off'}
      className={className}
      InputProps={{
        style: {
          background: 'transparent',
          color: colors.white,
          borderRadius: '8px',
          border: `1.5px solid ${colors.shadesBackground700}`,
          fontWeight: fontWeights.medium,
          height: '34px',
          ...TextVariant['BODY_S'],
        },
        startAdornment: (
          <InputAdornment
            position="start"
            style={{
              color: colors.shadesForefround200,
              padding: '0 0 0 10px',
              marginRight: 0,
            }}
          >
            <StyledIcon src={searchIcon} alt="Search icon" />
            {/* <Separator /> */}
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
};

export default Search;
