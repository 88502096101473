import { Dispatch, SetStateAction } from 'react';

import { capitalizeFirstLetter } from 'utils';

import FilterSelector from 'components/FilterSelector';
import DateRangeFilterSelector, {
  DateFilterRangeWithType,
} from 'components/FilterSelector/DateRangeFilterSelector';
import { LabelWithRadio } from 'components/FilterSelector/OptionRenderers';

import { FilterOptionsContainer } from '../styles';
import diceIcon from 'assets/icons/dice.svg';

import { BalanceOpsType } from 'enums';
import { observer } from 'mobx-react';
import styled from 'styled-components';

export type TTransfersFilter = {
  type?: BalanceOpsType;
  dateRange?: DateFilterRangeWithType;
};

type Props = {
  appliedFilters: TTransfersFilter;
  onChangeFilter: Dispatch<SetStateAction<TTransfersFilter>>;
};
const TransfersFilterOptions = ({ appliedFilters, onChangeFilter }: Props) => {
  const handleFilterChange = (
    _value: TTransfersFilter[keyof TTransfersFilter],
    key: keyof TTransfersFilter,
  ) => {
    const value = appliedFilters[key] === _value ? undefined : _value;
    onChangeFilter({ ...appliedFilters, [key]: value });
  };

  return (
    <FilterOptionsContainer>
      <FilterSelector
        icon={diceIcon}
        label={'Type'}
        value={
          appliedFilters.type
            ? capitalizeFirstLetter(appliedFilters.type)
            : 'All'
        }
        renderOption={option => (
          <LabelWithRadio
            selected={option === appliedFilters.type}
            label={capitalizeFirstLetter(option)}
          />
        )}
        options={[BalanceOpsType.Deposit, BalanceOpsType.Withdrawal]}
        onOptionSelect={option => handleFilterChange(option, 'type')}
      />

      <div className="seperator" />

      <DateRangeFilterSelector
        selectedRange={appliedFilters?.dateRange}
        onRangeSelect={range => handleFilterChange(range, 'dateRange')}
      />
    </FilterOptionsContainer>
  );
};

export default observer(TransfersFilterOptions);
