import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  padding: 0px 10px;

  .block {
    display: block;
    width: 100%;
  }

  .active-badge {
    display: inline-block;
    padding: 6px;

    border-radius: 6px;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    &.success {
      background: ${({ theme }) => theme.colors.positiveBackground100};
      color: ${({ theme }) => theme.colors.positiveForeground200};
    }
    &.warning {
      background: ${({ theme }) => theme.colors.shadesBackground700};
      color: ${({ theme }) => theme.colors.shadesForeground100};
    }
  }
`;

type Props = { children?: React.ReactNode };

const Layout = ({ children }: Props) => {
  return (
    <Container className="gap-20">
      <div className="block">{children}</div>

      {/* <Sidebar /> */}
    </Container>
  );
};

export default Layout;
