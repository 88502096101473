import { useState } from 'react';

import { formatUnits } from '@ethersproject/units';

import { showNotification } from 'utils';
import { brand } from 'utils/brand';

import { useElixirContractsAPI } from 'hooks';
import { RbxRewards, VaultWithContractInfo } from 'hooks/useElixirVaults';
import useModal from 'hooks/useModal';

import { FormattedNumber, FormattedPercentage } from 'components';
import Button from 'components/Button/button';
import InfoTooltip from 'components/InfoTooltip';
import Loading from 'components/Loading';
import Text from 'components/Text';

import giftIcon from 'assets/icons/gift-outline-warning.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import { ColoredText, Colors } from 'pages/Trade/components/shared';
import { elixirVaultsConstants } from 'pages/Vaults/Pages/Vaults/TabPanels/ElixirVaults/constants';
import { Column } from 'pages/Vaults/styles';
import {
  useFetchClaimedRbxRewardsForUser,
  useFetchRbxRewardsForUser,
} from 'queryHooks/elixirVaults';

import dayjs from 'dayjs';
import { NotificationType, QueryKeys } from 'enums';
import { BigNumber } from 'ethers';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
const tz = 'America/New_York';

const Container = styled.div`
  .button-claim-rewards {
    padding: 12px 15px;
    font-size: 13px;
    gap: 5px;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;

const getClaimableRewards = (
  rbxRewardsForUser: RbxRewards | null | undefined,
  rbxClaimedRewardsForUser: BigNumber | undefined,
) => {
  if (!rbxRewardsForUser || !rbxClaimedRewardsForUser) return 0;

  const claimableRewards = BigNumber.from(rbxRewardsForUser.amount).sub(
    rbxClaimedRewardsForUser,
  );

  const decimal = Number(formatUnits(claimableRewards, 18));
  return decimal >= 0 ? decimal : 0;
};

/**
 * Gets the next epoch date for every 7 days
 */
export const getNextEpochDate = (
  inceptionDate: Date,
  currentDate: Date,
): string => {
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const daysInterval = 7;

  // Convert inceptionDate and currentDate to day.js objects with the 'America/New_York' (EST) timezone
  const inceptionDateInEST = dayjs.tz(inceptionDate, tz);
  const currentDateInEST = dayjs.tz(currentDate, tz);

  // Calculate the time difference between the current date and the inception date in milliseconds
  const timeDifference = currentDateInEST.diff(inceptionDateInEST);

  // Calculate the number of completed intervals
  const completedIntervals = Math.floor(
    timeDifference / (daysInterval * millisecondsInADay),
  );

  // Calculate the milliseconds for the next epoch
  const millisecondsUntilNextEpoch =
    (completedIntervals + 1) * daysInterval * millisecondsInADay;

  // Create the nextEpochDate in EST timezone
  const nextEpochDate = inceptionDateInEST.add(
    millisecondsUntilNextEpoch,
    'millisecond',
  );
  // Convert day.js object back to JavaScript Date object
  return `${nextEpochDate.format('lll')} EST`;
};

type Props = {
  vault: VaultWithContractInfo;
};
const YourRewards = ({ vault }: Props) => {
  const { t } = useTranslation();
  const { claimRbxRewards } = useElixirContractsAPI();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const modal = useModal();
  const [isClaimingRewards, setIsClaimingRewards] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: rbxRewardsForUser,
    isLoading: isLoadingRbxRewardsForUser,
    isError: isErrorRbxRewardsForUser,
  } = useFetchRbxRewardsForUser();
  const {
    data: rbxClaimedRewardsForUser,
    isLoading: isLoadingRbxClaimedRewardsForUser,
    isError: isErrorRbxClaimedRewardsForUser,
  } = useFetchClaimedRbxRewardsForUser();

  const onClaimRewards = async () => {
    if (!rbxRewardsForUser || parseFloat(rbxClaimableRewards.toString()) <= 0)
      return;

    try {
      flushSync(() => {
        setIsClaimingRewards(true);
      });
      showNotification({
        title: t('claimingRewardsTitle'),
        description: t('claimingRewardsDescription'),
        type: NotificationType.Info,
      });

      const tx = await claimRbxRewards(rbxRewardsForUser);

      await tx.wait();

      queryClient.invalidateQueries(
        QueryKeys.ElixirVaultsRbxRewardsForUserClaimed,
      );

      showNotification({
        title: t('rewardsClaimedTitle'),
        description: t('rewardsClaimedDescription'),
        type: NotificationType.Positive,
      });
    } catch (e) {
      showNotification({
        title: t('error'),
        description: t('claimRewardsErrorDescription'),
        type: NotificationType.Negative,
      });
      console.error('Error claiming rewards', e);
    } finally {
      flushSync(() => {
        setIsClaimingRewards(false);
      });
    }
  };

  const rbxClaimableRewards = getClaimableRewards(
    rbxRewardsForUser,
    rbxClaimedRewardsForUser,
  );

  const rewardItems = [
    {
      label: t('yourEstAPY'),
      value: <FormattedPercentage value={vault.apy} />,
    },
    {
      label: t('nextEpoch'),
      value: (
        <span>
          {vault
            ? getNextEpochDate(
                elixirVaultsConstants[vault.elixirPairId].inceptionDate,
                new Date(),
              )
            : '-'}
        </span>
      ),
    },
    {
      label: t('claimableRewards'),
      value: (
        <FormattedNumber
          value={rbxClaimableRewards}
          suffix={` ${brand.rewardTokenTicker}`}
        />
      ),
      tooltipText: t('claimableRewardsToolTip'),
    },
  ];

  const onConnectWallet = () =>
    modal.present(<WalletModal />, Modals.walletModal);

  const isLoading =
    isLoadingRbxRewardsForUser || isLoadingRbxClaimedRewardsForUser;
  const isError = isErrorRbxRewardsForUser || isErrorRbxClaimedRewardsForUser;

  return (
    <Container className="container">
      <Text variant="BODY_M" fontWeight="semiBold">
        {t('yourRewards')}
      </Text>

      {isLoading && <Loading />}
      {isError && (
        <ColoredText color={Colors.Danger}>
          {t('errorFetchingRewardsPleaseRefresh')}
        </ColoredText>
      )}

      {!isLoading && !isError && (
        <>
          <Column className="gap-5">
            {rewardItems.map(({ label, value, tooltipText }) => (
              <Text
                variant="BODY_S"
                className="justify-between flex items-center"
              >
                <Text color="shadesForeground200" flexed className="gap-2">
                  {label}
                  {tooltipText ? (
                    <InfoTooltip title={tooltipText} iconSize={12} />
                  ) : null}
                </Text>
                {value}
              </Text>
            ))}
          </Column>

          {frontendSecrets?.jwt ? (
            <Button
              colorVariant="tertiary"
              className="button-claim-rewards"
              onClick={onClaimRewards}
              isLoading={isClaimingRewards}
              disabled={
                parseFloat(rbxClaimableRewards.toString()) <= 0 ||
                isClaimingRewards
              }
            >
              {t('claimRewards')}
              <img src={giftIcon} alt="gift" />
            </Button>
          ) : (
            <Button
              colorVariant="primaryGreen"
              block
              sizeVariant="S"
              onClick={event => {
                event.stopPropagation();
                onConnectWallet();
              }}
            >
              {t('connectWallet')}
            </Button>
          )}
        </>
      )}
    </Container>
  );
};

export default observer(YourRewards);
