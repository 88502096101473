import { memo } from 'react';

import { brand } from 'utils/brand';

import Loading from 'components/Loading';
import Text from 'components/Text';

import StatBox from './StatBox';
import { Container, StatsBoxes } from './styles';

import { Referral } from 'interfaces';
import { useTranslation } from 'react-i18next';

type Props = {
  userReferral: Referral | undefined;
};
const GeneralStats = ({ userReferral }: Props) => {
  const { t } = useTranslation();
  if (!userReferral) return <Loading />;

  return (
    <Container>
      <Text variant="BODY_M" fontWeight="semiBold">
        {t('generalStats')}
      </Text>
      <StatsBoxes>
        <StatBox
          title={t('allTimeVolume')}
          value={userReferral.referralLevelStatus.volume}
          valuePrefix="$"
        />
        <StatBox
          title={t('allTimeEarnings')}
          value={userReferral.earningsAllTime}
          valueIcon={brand.tokenLogo}
        />
        <StatBox
          title={t('numberOfSignups')}
          value={userReferral.invitedCounter}
          valueDecimalScale={0}
        />
        <StatBox
          title={t('weeklyRanking')}
          value={userReferral.leaderboardStatusWeekly.currentRank}
          valuePrefix="#"
          valueDecimalScale={0}
        />
        <StatBox
          title={t('monthlyRanking')}
          value={userReferral.leaderboardStatusMonthly.currentRank}
          valuePrefix="#"
          valueDecimalScale={0}
        />
        <StatBox
          title={t('allTimeRanking')}
          value={userReferral.leaderboardStatusAll.currentRank}
          valuePrefix="#"
          valueDecimalScale={0}
        />
      </StatsBoxes>
    </Container>
  );
};

export default memo(GeneralStats);
