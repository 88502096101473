import styled, { css } from 'styled-components';

export type ContractDetailsStyledProps = { isPadded?: boolean };
export const ContractDetailsStyled = styled.div<ContractDetailsStyledProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;

  ${({ isPadded }) =>
    isPadded &&
    css`
      padding: 15px;
    `};

  .stats {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0 0;
    gap: 2px;
  }

  .loading-wrapper {
    display: flex;
    justify-content: center;
  }

  .title {
    margin-top: 12px;

    @media (min-width: ${({ theme }) => theme.screenSizes.xl + 1}px) {
      margin-top: 0px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    border-top: 0;
  }
`;

export const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
