import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .chart {
    height: 60px;
    margin: 0 -5px; // chart alignment
  }
`;
