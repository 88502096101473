import { MAX_RECENT_TRADES_NUM } from 'constants/general';
import { PriceChangeType } from 'enums/priceChangeType';

import { ArrowState } from 'enums';
import { Trade } from 'interfaces';

/**
 * Adds priceChangeType to each trade
 * @param previousTrade previous trade in the array
 * @param currentTrade current trade to compare to
 * @returns priceChangeType, e.g. Positive, Negative or None
 */
export const getPriceChangeType = (
  previousTrade: Trade | undefined | null,
  currentTrade: Trade,
) => {
  if (previousTrade) {
    const previousTradePrice = parseFloat(previousTrade.price);
    const currentTradePrice = parseFloat(currentTrade.price);

    if (currentTradePrice > previousTradePrice) {
      return PriceChangeType.Positive;
    } else if (currentTradePrice < previousTradePrice) {
      return PriceChangeType.Negative;
    } else {
      return previousTrade.priceChangeType;
    }
  } else {
    return PriceChangeType.None;
  }
};

/**
 * Adds arrowState to each trade
 * @param previousTrade previous trade in the array
 * @param currentTrade current trade to compare to
 * @returns arrowState, e.g. Up, Down or None
 */
export const getArrowState = (
  previousTrade: Trade | undefined | null,
  currentTrade: Trade,
): ArrowState => {
  if (!previousTrade) {
    return ArrowState.None;
  }

  const currentTradePrice = parseFloat(currentTrade.price);
  const previousTradePrice = parseFloat(previousTrade.price);
  if (previousTradePrice === currentTradePrice) {
    return ArrowState.None;
  }
  return currentTradePrice < previousTradePrice
    ? ArrowState.Down
    : ArrowState.Up;
};

export const parseInitialResponseData = (
  resTrades: Trade[],
  maxTradesNum: number,
) => {
  const slicedTrades = resTrades.slice(0, maxTradesNum);
  const reversedTrades = slicedTrades.reverse();

  // Add priceChangeType to each trade
  reversedTrades.forEach((trade: Trade, index: number) => {
    const previousTrade = reversedTrades[index - 1];
    const priceChangeType = getPriceChangeType(previousTrade, trade);
    const arrowState = getArrowState(previousTrade, trade);
    reversedTrades[index] = {
      ...trade,
      priceChangeType,
      arrowState,
    };
  });
  return reversedTrades.reverse();
};

/**
 * Adds new trades to the beginning of an array and returns 9 items
 * @param currentTrades current trades array
 * @param newTrades new trades that were emitted through the publication channel
 */
export const parsePublicationData = (
  currentTrades: Trade[] | null,
  newTrades: Trade[],
) => {
  // reverse the array so that the first trade is the oldest one for easier comparison
  const reversedArray = newTrades.slice().reverse();
  reversedArray.forEach((trade: Trade, index: number) => {
    // If it's the first trade, then we don't have a previous trade to compare it with
    // which means the previous trade is the last trade in the existing trades array
    const previousTrade =
      index === 0
        ? currentTrades && currentTrades[0]
        : reversedArray[index - 1];
    const priceChangeType = getPriceChangeType(previousTrade, trade);
    const arrowState = getArrowState(previousTrade, trade);
    reversedArray[index] = {
      ...trade,
      priceChangeType,
      arrowState,
    };
  });

  if (!currentTrades) {
    return reversedArray.reverse().slice(0, MAX_RECENT_TRADES_NUM);
  }

  const trades = [
    // reverse the array again so that the first trade is the newest one
    ...reversedArray.reverse(),
    ...currentTrades,
  ];

  return trades.slice(0, MAX_RECENT_TRADES_NUM);
};
