import { useState, useEffect, useCallback } from 'react';

import { formatUnits } from '@ethersproject/units';

import {
  makeABitLessthanOrEqual,
  parseTimeUntil,
  roundJsDecimalToString,
  showNotification,
  verifyUserSession,
} from 'utils';
import { brandedSelect, isBrandBfx, isBrandRabbitX } from 'utils/brand';

import { useActiveWeb3React, useTokenLockAPI, useVerifyChainId } from 'hooks';
import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';

import { FormattedNumber } from 'components';
import Badge from 'components/Badge';
import IconPair from 'components/IconPair';
import Loading from 'components/Loading';
import Text from 'components/Text';

import LockOptionLayout from '../Layout';
import { LP_ALLOCATION, getAllocation } from '../LockAndEarn/utils';
import { BFX_BADGE, BLASTER_SWAP_BADGE, TWICE_POINTS_BADGE } from '../badges';
import ContractDetails from '../components/ContractDetails';
import InputAndActions from '../components/InputAndActions';
import { ActionType } from '../components/InputAndActions/LockUnlockSelector/enums';
import SwapAndLockModal from './SwapAndLockModal';
import { IconPairContainer } from './styles';
import rbxIcon from 'assets/assetLogos/rbx-circle-white.svg';
import wethIcon from 'assets/assetLogos/weth.svg';
import lpAndEarnBanner from 'assets/images/lpEarnBg.webp';
import { RBX_DECIMALS } from 'constants/contracts';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import {
  useFetchAccountLpRbxBalance,
  useFetchTotalLockedLpRbx,
  useFetchYourLocksLpRbx,
} from 'queryHooks/tokenLock';
import RealisticConfetti from 'react-canvas-confetti/dist/presets/realistic';
import { mixpanelService } from 'service/mixpanelService';
import { Row } from 'theme/globalStyledComponents';

import { NotificationType } from 'enums';
import { BigNumber } from 'ethers';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

// const BFX_AIRDROP_ALLOCATION = 90_000_000;

const getYourLocksAmount = (
  yourLocks:
    | {
        amount: BigNumber;
      }
    | undefined,
) => {
  if (!yourLocks) return undefined;

  return formatUnits(yourLocks.amount, RBX_DECIMALS);
};

const getTotalLockedRbx = (totalLocked: BigNumber | undefined) => {
  if (!totalLocked) return undefined;

  return formatUnits(totalLocked, RBX_DECIMALS);
};

// const getEstimatedAllocation = (
//   yourLocked: number | undefined,
//   totalLocked: number | undefined,
// ) => {
//   if (!yourLocked || !totalLocked || totalLocked === 0) return undefined;

//   return BFX_AIRDROP_ALLOCATION * (yourLocked / totalLocked);
// };

const DEFAULT_SLIPPAGE = 0.5;

const getCtaText = ({
  actionType,
  isAwaitingConfirmation,
  pendingTxSign,
}: {
  actionType: ActionType;
  isAwaitingConfirmation: boolean;
  pendingTxSign: boolean;
}) => {
  if (isAwaitingConfirmation) {
    return 'Awaiting Confirmation...';
  }

  if (pendingTxSign) {
    return 'Pending...';
  }

  return actionType === ActionType.LOCK ? 'Lock' : 'Unlock';
};

const getShouldDisableCta = ({
  actionType,
  inputValue,
  lpRbxBalance,
  isAwaitingConfirmation,
  pendingTxSign,
  lockReleaseTime,
  yourLocksAmount,
  isUnlocking,
}: {
  actionType: ActionType;
  inputValue: number | null;
  lpRbxBalance: number | undefined;
  isAwaitingConfirmation: boolean;
  pendingTxSign: boolean;
  lockReleaseTime: number | BigNumber | undefined;
  yourLocksAmount: number | undefined;
  isUnlocking: boolean;
}) => {
  if (isAwaitingConfirmation || pendingTxSign || isUnlocking) {
    return true;
  }

  if (actionType === ActionType.LOCK) {
    return !inputValue || !lpRbxBalance || inputValue > lpRbxBalance;
  }

  if (actionType === ActionType.UNLOCK) {
    if (!lockReleaseTime) return true;
    if (!yourLocksAmount) return true;
    const releaseTimeNum = Number(lockReleaseTime.toString()) * 1000;
    return Date.now() < releaseTimeNum;
  }

  return false;
};

const LpAndEarn = () => {
  const modal = useModal();
  const { t } = useTranslation();
  const { account } = useActiveWeb3React();
  const { withdrawLockedTokensLpRbx } = useTokenLockAPI();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const [inputValue, setInputValue] = useState<number | null>(0);

  const [actionType, setActionType] = useState<ActionType>(ActionType.LOCK);

  const [pendingTxSign, setPendingTxSign] = useState(false);
  const [isAwaitingConfirmation, setIsAwaitingConfirmation] = useState(false);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const IS_CLOSED = actionType === ActionType.LOCK;

  const showSwap = false;

  const {
    data: totalLocked,
    isLoading: isLoadingTotalLocked,
    isError: isErrorTotalLocked,
    refetch: refetchTotalLocked,
  } = useFetchTotalLockedLpRbx();
  const {
    data: yourLocks,
    isLoading: isLoadingYourLocks,
    refetch: refetchYourLocks,
    isError: isErrorYourLocks,
  } = useFetchYourLocksLpRbx();

  const {
    data: lpRbxBalance,
    isLoading: isLoadingLpRbxBalance,
    refetch: refetchLpRbxBalance,
  } = useFetchAccountLpRbxBalance();

  useEffect(() => {
    if (!frontendSecrets?.profile.wallet) {
      return;
    }

    mixpanelService.stakeLockLpNavigate(frontendSecrets.profile.wallet);
  }, [frontendSecrets?.profile.wallet]);

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => {
        setShowConfetti(false);
      }, 2000);
    }
  }, [showConfetti]);

  // const onReloadRate = () => {
  //   // @todo: Implement
  // };

  // const onSlippageEdit = () => {
  //   modal.present(
  //     <SlippageSettings
  //       defaultSlippage={slippage}
  //       onUpdate={updatedSlippage => {
  //         setSlippage(updatedSlippage);
  //       }}
  //     />,
  //     Modals.slippageSettingsModal,
  //   );
  // };

  const onWithdrawLockedTokens = useCallback(async () => {
    if (!account) return;

    try {
      flushSync(() => {
        setPendingTxSign(true);
        setIsUnlocking(true);
      });

      if (!(await validateNetworkAndSwitchIfRequired('Restart the unlock'))) {
        return;
      }

      showNotification({
        title: 'Confirm Token Unlock',
        description: 'Please confirm the transaction in your wallet',
        type: NotificationType.Positive,
      });

      const tx = await withdrawLockedTokensLpRbx();

      showNotification({
        title: 'Tokens Unlock Started',
        description: 'Please wait until the transaction is confirmed',
        type: NotificationType.Positive,
      });

      flushSync(() => {
        setIsAwaitingConfirmation(true);
        setPendingTxSign(false);
      });

      await tx.wait();
      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      showNotification({
        title: 'Successfully withdrew tokens',
        description: 'Your tokens have been withdrawn successfully',
        type: NotificationType.Positive,
      });
      setActionType(ActionType.LOCK);
    } catch (e) {
      console.error(e);
      showNotification({
        title: 'Error withdrawing tokens',
        description: 'An error occurred while withdrawing your tokens',
        type: NotificationType.Negative,
      });
    } finally {
      refetchYourLocks();
      refetchTotalLocked();
      refetchLpRbxBalance();
      setPendingTxSign(false);
      setIsAwaitingConfirmation(false);
      setIsUnlocking(false);
    }
  }, [
    account,
    validateNetworkAndSwitchIfRequired,
    withdrawLockedTokensLpRbx,
    refetchYourLocks,
    refetchTotalLocked,
    refetchLpRbxBalance,
  ]);

  const onCtaClick = useCallback(() => {
    if (modal.activeModals.some(i => i.name === Modals.lockTokenModal)) return;

    if (actionType === ActionType.LOCK) {
      if (!inputValue || !account) return;

      const roundedInputValue = Number(roundJsDecimalToString(inputValue));
      if (roundedInputValue === 0) {
        showNotification({
          title: 'Invalid Input',
          description: 'Please enter a valid amount to lock',
          type: NotificationType.Negative,
        });
        return;
      }

      modal.present(
        <SwapAndLockModal
          title={showSwap ? 'Swap & Lock' : 'Lock'}
          defaultSlippage={DEFAULT_SLIPPAGE}
          inputAmount={Number(roundJsDecimalToString(inputValue))}
          showSwapStep={showSwap}
          onShowConfetti={() => setShowConfetti(true)}
        />,
        Modals.lockTokenModal,
      );
    } else {
      onWithdrawLockedTokens();
    }
  }, [
    inputValue,
    account,
    actionType,
    modal,
    showSwap,
    onWithdrawLockedTokens,
  ]);

  useKey(Key.Enter, onCtaClick);

  const openAddLiquidity = () => {
    if (isBrandRabbitX) {
      window.open(
        'https://app.uniswap.org/add/v2/ETH/0x3Ba925fdeAe6B46d0BB4d424D829982Cb2F7309e',
        // 'https://app.uniswap.org/add/0x3ba925fdeae6b46d0bb4d424d829982cb2f7309e/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        '_blank',
      );
    } else {
      window.open(
        'https://blasterswap.com/pools/liquidity?address0=0x236bb48fcF61ce996B2C8C196a9258c176100c7d&address1=0x4300000000000000000000000000000000000004',
        '_blank',
      );
    }
  };

  const parsedReleaseTime =
    parseTimeUntil(Number(yourLocks?.releaseTime.toString()) * 1_000_000) ??
    'Unlockable';

  const yourLocksAmount = getYourLocksAmount(yourLocks);
  const totalLockedAmount = totalLocked;
  // const estimatedAllocation = getEstimatedAllocation(
  //   Number(yourLocksAmount),
  //   Number(totalLockedAmount),
  // );

  const ctaText = getCtaText({
    actionType,
    isAwaitingConfirmation,
    pendingTxSign,
  });

  const shouldDisableCta = getShouldDisableCta({
    actionType,
    inputValue,
    lpRbxBalance,
    isAwaitingConfirmation,
    pendingTxSign,
    isUnlocking,
    lockReleaseTime: yourLocks?.releaseTime,
    yourLocksAmount: Number(yourLocksAmount),
  });

  const isLockActionType = actionType === ActionType.LOCK;
  const shouldDisableUnlock = !yourLocksAmount || Number(yourLocksAmount) === 0;
  const actionTypeInputValue = isLockActionType ? inputValue : yourLocksAmount;

  const parsedLpRbxBalance = makeABitLessthanOrEqual(lpRbxBalance ?? 0, 7);

  const onChangeActionType = (v: ActionType) => {
    setInputValue(
      v === ActionType.LOCK
        ? 0
        : yourLocksAmount
        ? parseFloat(yourLocksAmount)
        : null,
    );
    setActionType(v);
  };

  return (
    <LockOptionLayout
      headerProps={{
        backgroundImage: lpAndEarnBanner,
        description: `Lock your RBX-WETH LP Tokens (${brandedSelect({
          bfx: 'Blasterswap',
          rabbitx: 'Uniswap',
        })}}) to earn BFX Points`,
        title: 'LP & Earn',
        badges: isBrandBfx
          ? [BFX_BADGE, BLASTER_SWAP_BADGE, TWICE_POINTS_BADGE]
          : [BFX_BADGE],
      }}
    >
      <InputAndActions
        actionType={actionType}
        onChangeActionType={onChangeActionType}
        onCtaClick={onCtaClick}
        value={inputValue}
        onChange={setInputValue}
        ctaText={ctaText}
        disableCta={shouldDisableCta}
        hideInput={!isLockActionType}
        disabled={IS_CLOSED || !isLockActionType}
        disableUnlock={shouldDisableUnlock}
        partiallyDisabled={!isLockActionType && !shouldDisableCta}
        currencyComponent={
          <IconPairContainer flexed gap={5} variant="BODY_M" color="white">
            <IconPair icons={[rbxIcon, wethIcon]} size={16} />
            RBX-WETH LP
          </IconPairContainer>
        }
      >
        {!isLockActionType && yourLocksAmount && Number(yourLocksAmount) > 0 ? (
          <div className="h-14">
            <Text flexed>Unlockable In</Text>

            {parsedReleaseTime}
          </div>
        ) : null}

        {isLockActionType ? (
          <div>
            <Text flexed>
              {t('available')}
              <Badge
                bgColor="shadesBackground800"
                padding="3px 4px"
                borderColor="shadesBackground700"
              >
                LP
              </Badge>
            </Text>
            <Row gap={5}>
              {isLoadingLpRbxBalance ? (
                <Loading size={12} />
              ) : (
                <FormattedNumber value={lpRbxBalance} />
              )}
              <Badge
                cursorPointer
                bgColor="shadesBackground700"
                padding="4px 6px"
                borderColor="shadesBackground700"
                hoveredBgColor="shadesBackground900"
                color="white"
                variant="BODY_XS"
                className={IS_CLOSED ? 'cursor-not-allowed' : ''}
                onClick={() => {
                  if (IS_CLOSED) return;
                  if (!isLoadingLpRbxBalance) setInputValue(parsedLpRbxBalance);
                }}
              >
                MAX
              </Badge>
            </Row>
          </div>
        ) : null}

        <div onClick={openAddLiquidity} className="cursor-pointer">
          Don't have RBX/WETH LP?
          <Badge
            padding="6px"
            borderRadius={6}
            bgColor="positiveBackground100"
            color="positiveForeground200"
            hoveredBgColor="positiveBackground200"
            cursorPointer
          >
            Get LP
          </Badge>
        </div>
      </InputAndActions>

      <ContractDetails
        isLoading={isLoadingTotalLocked || isLoadingYourLocks}
        isError={isErrorTotalLocked || isErrorYourLocks}
        stats={[
          {
            title: 'Total locked (All Chains)',
            value: <FormattedNumber value={totalLockedAmount} suffix=" LP" />,
          },
          {
            title: 'Your Locked',
            value: <FormattedNumber value={yourLocksAmount} suffix=" LP" />,
          },
          {
            title: 'Your BFX Points',
            value: (
              <FormattedNumber
                value={getAllocation(
                  yourLocksAmount ? parseFloat(yourLocksAmount) : 0,
                  totalLockedAmount ?? 0,
                  LP_ALLOCATION,
                )}
                suffix=" BFX PTS"
              />
            ),
          },
          {
            title: 'Unlockable In',
            value: (
              <div>
                {yourLocksAmount && Number(yourLocksAmount) > 0
                  ? parsedReleaseTime
                  : '-'}
              </div>
            ),
          },
        ]}
      />

      {showConfetti ? (
        <RealisticConfetti
          autorun={{ duration: 2000, speed: 0.5 }}
          className="confetti-container"
          decorateOptions={options => ({ ...options, startVelocity: 30 })}
        />
      ) : null}

      {/* <YourContractStats
          stats={[
            { title: 'Allocation', redactedValue: true },
            { title: 'Vesting Period', redactedValue: true },
            { title: 'Total Claimed', redactedValue: true },
            { title: 'Vesting', redactedValue: true },
            { title: 'Available to Claim', redactedValue: true },
          ]}
        /> */}
    </LockOptionLayout>
  );
};

export default LpAndEarn;
