import { memo } from 'react';

import caretDown from 'assets/icons/chevronDown.svg';
import caretUp from 'assets/icons/chevronUp.svg';

import styled from 'styled-components';

type ContainerProps = {
  isExpanded: boolean;
};
const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
  padding: 10px 0;
  cursor: pointer;
  > span.label {
    color: ${({ theme, isExpanded }) =>
      isExpanded ? theme.colors.white : theme.colors.shadesForeground200};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 14px;
    line-height: 16px;
  }
`;

type DropdownToggleProps = {
  label: string;
  isExpanded: boolean;
  handleClick: () => void;
  enableToggle: boolean;
};
const DropdownToggle = ({
  label,
  isExpanded,
  handleClick,
  enableToggle,
}: DropdownToggleProps) => {
  return (
    <Container isExpanded={isExpanded} onClick={handleClick}>
      <span className="label">{label}</span>
      {enableToggle ? (
        <img
          src={isExpanded ? caretUp : caretDown}
          alt={isExpanded ? 'Caret Up' : 'Caret Down'}
        />
      ) : null}
    </Container>
  );
};

export default memo(DropdownToggle);
