import styled from 'styled-components';

export const StyledSteps = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      position: relative;
      z-index: 1;
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
  }

  .lh-14 {
    line-height: 14px;
  }

  .sub-step-title {
    line-height: 14px;
    margin-top: 11px;
  }

  .step-title {
    line-height: 20px;
  }
`;

type StepProgressLineProps = {
  currentStepIndex: number;
  stepIndex: number;
};
export const StepProgressLine = styled.div<StepProgressLineProps>`
  width: 2px;
  height: 13px;
  margin: -1px 0px;
  position: relative;
  z-index: 0;
  background: ${({ theme, currentStepIndex, stepIndex }) =>
    stepIndex <= currentStepIndex
      ? theme.colors.positiveForeground300
      : theme.colors.shadesBackground600};
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.shadesBackground700};
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
