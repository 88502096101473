import { brand } from 'utils/brand';

import { CardVariant } from './enums';
import GreaterThanIcon from 'assets/icons/greater-than.svg';
import PercentContainedIcon from 'assets/icons/percent-contained-dark.svg';
import ClaimedVector from 'assets/images/rewards/claimed.png';
import FeeVector from 'assets/images/rewards/fee.png';
import GoalVector from 'assets/images/rewards/goal.png';
import LockedVector from 'assets/images/rewards/locked.svg';
import PrizeVector from 'assets/images/rewards/prize.png';

export const DefaultVariantProps = new Map([
  [
    CardVariant.GOAL,
    {
      vectorSource: GoalVector,
      leftIcon: GreaterThanIcon,
    },
  ],
  [
    CardVariant.PRIZE,
    {
      vectorSource: PrizeVector,
      leftIcon: brand.tokenLogo,
      gradientColors: ['#190C3E', '#67529B'],
      borderColor: '#1D1041',
    },
  ],
  [
    CardVariant.FEE,
    {
      vectorSource: FeeVector,
      rightIcon: PercentContainedIcon,
      gradientColors: ['#003D11', '#559B8A'],
      borderColor: '#054117',
    },
  ],
  [
    CardVariant.LOCKED,
    { vectorSource: LockedVector, leftIcon: GreaterThanIcon },
  ],
  [
    CardVariant.CLAIMED,
    { vectorSource: ClaimedVector, leftIcon: GreaterThanIcon },
  ],
]);
