import { useState } from 'react';

import { pushDataLayerEvent, readUserSetting, saveUserSetting } from 'utils';

import useModal from 'hooks/useModal';

import Button from 'components/Button/button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Text from 'components/Text';
import CheckBox from 'components/Toggle/Checkbox';

import Timer from './Timer';
import xGrayIcon from 'assets/icons/close-x.svg';
import positiveLoudSpeakerIcon from 'assets/icons/hexagon-outline-positive-loudspeaker.svg';
import depositBonusBanner from 'assets/images/deposit-bonus-banner.svg';
import { Modals } from 'constants/modals';
import { routes } from 'pages/routes';
import { Column } from 'theme/globalStyledComponents/column';
import { Row } from 'theme/globalStyledComponents/row';

import { UserSettings } from 'enums';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled(Column)`
  padding: 20px;
  position: relative;
  gap: 20px;
  border-radius: 20px;
  width: 423px;
  box-sizing: border-box;
  border: 1px solid #3e3e3e;
  background: ${({ theme }) => theme.colors.shadesBackground900};
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.4) inset;

  width: calc(100vw - 40px);

  @media (min-width: 463px) {
    width: 423px;
  }

  .description {
    background: linear-gradient(272deg, #696f7b 0%, #c0cbe1 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .button {
    background: ${({ theme }) => theme.colors.goldGradient200};
    box-shadow: 0px 0px 20px rgba(111, 96, 64, 0.25);

    :hover {
      background: ${({ theme }) => theme.colors.goldGradient100};
    }
  }

  .right-banner-image {
    display: none;
    @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
      display: flex;
    }
    z-index: 1;

    position: absolute;
    right: -140px;
    top: -25px;
    /* width: 235px; */
    height: 245px;
  }

  .header {
    z-index: 2;
    position: absolute;
    top: -50px;
    justify-content: space-between;
    width: 100%;
    left: 0;

    .icon-40 {
      height: 40px;
      width: 40px;
    }

    .icon-bg-32 {
      height: 32px;
      width: 32px;
      background: ${({ theme }) => theme.colors.shadesBackground800};
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        background: ${({ theme }) => theme.colors.shadesBackground700};
      }
    }
  }
`;

export const navigateToVaultAndStakeNow = navigate => {
  navigate({
    pathname: routes.vaults.getRoutePath(),
    search: new URLSearchParams({ origin: 'stake_now' }).toString(),
  });
};

type Props = {
  isOriginAmendModal?: boolean;
};
const DepositBonusModal = ({ isOriginAmendModal }: Props) => {
  const modal = useModal();
  const navigate = useNavigate();
  const [isDontShowAgain, setIsDontShowAgain] = useState(
    !!readUserSetting(UserSettings.DONT_SHOW_BFX_DEPOSIT_POPUP_AGAIN),
  );

  const onCheckBoxClick = () => {
    const nextValue = !isDontShowAgain;
    saveUserSetting(UserSettings.DONT_SHOW_BFX_DEPOSIT_POPUP_AGAIN, nextValue);
    setIsDontShowAgain(nextValue);
  };

  const onCloseModal = () => {
    modal.pop({ name: Modals.bfxDepositBonusModal });
    pushDataLayerEvent('bfx-deposit-bonus-modal-close');
  };

  const onDepositNow = () => {
    onCloseModal();
    if (!isOriginAmendModal) {
      navigateToVaultAndStakeNow(navigate);
    }
  };

  return (
    <Modal
      showHeader={false}
      showCloseIcon={false}
      size={'auto'}
      name={Modals.bfxDepositBonusModal}
      overflow={'visible'}
      borderRadius={'20px'}
      noBorder
      noShadow
      data-gtmid={`deposit-bonus-modal`}
    >
      <Container>
        <Row className="header">
          <Row gap={10}>
            <img
              className="icon-40"
              src={positiveLoudSpeakerIcon}
              alt="loudspeaker"
            />
            <Column>
              <Text variant="BODY_M" color="shadesForeground200">
                Remaining
              </Text>
              <Timer onTimeover={onCloseModal} />
            </Column>
          </Row>
          <div className="icon-bg-32 cursor-pointer" onClick={onCloseModal}>
            <Icon src={xGrayIcon} />
          </div>
        </Row>

        <Text variant="HEADING_H1" fontWeight="semiBold">
          Earn 3% Bonus on Deposits
        </Text>

        <Text variant="BODY_L" preWrap className="description">
          {`Deposit and hold funds for 2 weeks to\nearn 3% cash bonus. It’s NUTS, we know!`}
        </Text>

        <CheckBox
          isChecked={isDontShowAgain}
          label="Do not show this again"
          gap={10}
          variant="BODY_M"
          onClick={onCheckBoxClick}
        />

        <Button
          block
          className="button"
          onClick={onDepositNow}
          sizeVariant="L"
          data-gtmid="button-deposit-now"
        >
          Deposit Now
        </Button>

        <img src={depositBonusBanner} alt="" className="right-banner-image" />
      </Container>
    </Modal>
  );
};

export default DepositBonusModal;
