import { Dispatch, SetStateAction } from 'react';

import { SETTLEMENT_BALANCE_OP_TYPES } from 'hooks';

import FilterSelector from 'components/FilterSelector';
import DateRangeFilterSelector, {
  DateFilterRangeWithType,
} from 'components/FilterSelector/DateRangeFilterSelector';
import { LabelWithRadio } from 'components/FilterSelector/OptionRenderers';

import { FilterOptionsContainer } from '../styles';
import { opsTypeToSettlement } from './SettlementsRow';
import diceIcon from 'assets/icons/dice.svg';

import { BalanceOpsType } from 'enums';
import { observer } from 'mobx-react';

export type TSettlementsFilter = {
  type?: BalanceOpsType;
  dateRange?: DateFilterRangeWithType;
};

type Props = {
  appliedFilters: TSettlementsFilter;
  onChangeFilter: Dispatch<SetStateAction<TSettlementsFilter>>;
};
const SettlementsFilterOptions = ({
  appliedFilters,
  onChangeFilter,
}: Props) => {
  const handleFilterChange = (
    _value: TSettlementsFilter[keyof TSettlementsFilter],
    key: keyof TSettlementsFilter,
  ) => {
    const value = appliedFilters[key] === _value ? undefined : _value;
    onChangeFilter({ ...appliedFilters, [key]: value });
  };

  return (
    <FilterOptionsContainer>
      <FilterSelector
        icon={diceIcon}
        label={'Type'}
        value={
          appliedFilters.type ? opsTypeToSettlement[appliedFilters.type] : 'All'
        }
        renderOption={option => (
          <LabelWithRadio
            selected={option === appliedFilters.type}
            label={opsTypeToSettlement[option]}
          />
        )}
        options={SETTLEMENT_BALANCE_OP_TYPES}
        onOptionSelect={option => handleFilterChange(option, 'type')}
      />

      <div className="seperator" />

      <DateRangeFilterSelector
        selectedRange={appliedFilters?.dateRange}
        onRangeSelect={range => handleFilterChange(range, 'dateRange')}
      />
    </FilterOptionsContainer>
  );
};

export default observer(SettlementsFilterOptions);
