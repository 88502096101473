import { useState } from 'react';

import useModal from 'hooks/useModal';
import useModalKeyListener from 'hooks/useModalKeyListener';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import { FormattedNumber } from 'components/FormattedNumber';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Modal from 'components/Modal';

import StopLossTakeProfitAmendModal from '../StopLossTakeProfitAmendModal';
import { getMarketPosition } from '../stopLossTakeProfitCell';
import { ContentRow } from './styles';
import editIcon from 'assets/icons/edit-pen.svg';
import deleteIcon from 'assets/icons/trash.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { Column } from 'theme/globalStyledComponents/column';

import { OrderType } from 'enums';
import { Order } from 'interfaces';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

export const getIsTakeProfit = (type: OrderType) =>
  [OrderType.TAKE_PROFIT, OrderType.TAKE_PROFIT_LIMIT].includes(type);

export const getIsStopLoss = (type: OrderType) =>
  [OrderType.STOP_LOSS, OrderType.STOP_LOSS_LIMIT].includes(type);

type Props = {
  marketId: string;
};
const StopLossTakeProfitCancelModal = ({ marketId }: Props) => {
  const modal = useModal();
  const {
    store: {
      account: { _positions },
    },
  } = useAppContext();
  const { cancelSltpOrder } = useOrdersAPI();
  const { t } = useTranslation();

  const [isCancellingTakeProfit, setIsCancellingTakeProfit] =
    useState<boolean>(false);
  const [isCancellingStopLoss, setIsCancellingStopLoss] =
    useState<boolean>(false);

  const handleCancel = async (order: Order) => {
    const isTakeProfit = getIsTakeProfit(order.type);
    try {
      flushSync(() => {
        if (isTakeProfit) {
          setIsCancellingTakeProfit(true);
        } else {
          setIsCancellingStopLoss(true);
        }
      });
      await cancelSltpOrder({
        orderID: order.id,
        marketID: order.marketID,
        type: order.type,
      });
    } catch (e: any) {
      console.log(
        `An error occurred when canceling ${order.type} order from positions table.`,
        e,
      );
    } finally {
      if (isTakeProfit) {
        setIsCancellingTakeProfit(false);
      } else {
        setIsCancellingStopLoss(false);
      }
    }
  };

  const handleEdit = (type: OrderType) => {
    modal.present(
      <StopLossTakeProfitAmendModal marketId={marketId} initialType={type} />,
      Modals.slTpAmendModal,
    );
  };

  useModalKeyListener({
    handleClose: modal.clear,
  });

  const position = getMarketPosition(marketId, _positions);

  if (!position) return null;

  const { takeProfit, stopLoss } = position;

  // If there is no SL/TP set, close the modal
  if (!takeProfit && !stopLoss) {
    modal.clear();
  }

  return (
    <Modal
      showHeader={true}
      title={t('CancelSLTP')}
      size={'small'}
      name={Modals.slTpCancelModal}
      showCloseIcon={false}
    >
      {!stopLoss && !takeProfit && (
        <p className="font-body-l font-semiBold">{t('noSLTP')}</p>
      )}
      <Column align="stretch" gap={10}>
        {stopLoss && (
          <ContentRow isPositive={false}>
            <span className="label">{t('SLcolon')}</span>
            <div className="content">
              <div className="cell">
                <FormattedNumber
                  value={stopLoss.triggerPrice}
                  prefix={`${t('price')}: `}
                />
              </div>
              <div className="separator" />
              <div className="cell">
                <FormattedNumber
                  value={stopLoss.sizePercent * 100}
                  prefix={`${t('size')}: `}
                  suffix="%"
                />
              </div>
              <div className="separator" />
              <div className="cell">
                <Icon
                  src={editIcon}
                  alt="Edit"
                  onClick={() => handleEdit(stopLoss.type)}
                />
                {isCancellingStopLoss ? (
                  <Loading />
                ) : (
                  <Icon
                    src={deleteIcon}
                    alt="Cancel"
                    onClick={() => handleCancel(stopLoss)}
                  />
                )}
              </div>
            </div>
          </ContentRow>
        )}

        {takeProfit && (
          <ContentRow isPositive>
            <span className="label">{t('TPcolon')}</span>
            <div className="content">
              <div className="cell">
                <FormattedNumber
                  value={takeProfit.triggerPrice}
                  prefix={`${t('price')}: `}
                />
              </div>
              <div className="separator" />
              <div className="cell">
                <FormattedNumber
                  value={takeProfit.sizePercent * 100}
                  prefix={`${t('size')}: `}
                  suffix="%"
                />
              </div>
              <div className="separator" />
              <div className="cell">
                <Icon
                  src={editIcon}
                  alt="Edit"
                  onClick={() => handleEdit(takeProfit.type)}
                />
                {isCancellingTakeProfit ? (
                  <Loading />
                ) : (
                  <Icon
                    src={deleteIcon}
                    alt="Cancel"
                    onClick={() => handleCancel(takeProfit)}
                  />
                )}
              </div>
            </div>
          </ContentRow>
        )}
      </Column>
    </Modal>
  );
};

export default observer(StopLossTakeProfitCancelModal);
