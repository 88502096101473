export type WalletCheckProvider = {
  isMetaMask?: boolean;
  isRabby?: boolean;
  wc?: boolean;
  isWalletConnect?: boolean;
  isCoinbaseWallet?: boolean;
  isTrust?: boolean;
  isBraveWallet?: boolean;
  isMathWallet?: boolean;
  isBinanceChain?: boolean;
  isFrame?: boolean;
  isOpera?: boolean;
  isParticleNetwork?: boolean;
  [key: string]: any;
};

export const detectWalletName = (
  provider: WalletCheckProvider | undefined,
): string => {
  if (!provider) {
    return 'Unknown';
  }

  if (provider.isRabby) {
    return 'Rabby';
  } else if (provider.wc || provider.isWalletConnect) {
    return 'WalletConnect';
  } else if (provider.isParticleNetwork) {
    return 'Particle Network';
  } else if (provider.isCoinbaseWallet) {
    return 'Coinbase Wallet';
  } else if (provider.isTrust) {
    return 'Trust Wallet';
  } else if (provider.isBraveWallet) {
    return 'Brave Wallet';
  } else if (provider.isMathWallet) {
    return 'Math Wallet';
  } else if (provider.isBinanceChain) {
    return 'Binance Chain Wallet';
  } else if (provider.isFrame) {
    return 'Frame';
  } else if (provider.isOpera) {
    return 'Opera Wallet';
  } else if (provider.isMetaMask) {
    return 'MetaMask';
  } else {
    return 'Unknown';
  }
};
