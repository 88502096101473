import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { isBrandRabbitX } from 'utils/brand';

import { TextVariant } from 'components/Text/variant';

import CheckboxBorderIcon from 'assets/icons/checkbox-border.svg';
import CheckboxCheckedIcon from 'assets/icons/checkbox-checked.svg';

import { Theme as ITheme, ThemeColors } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const getThemeColors = (): ThemeColors => {
  // RabbitX theme colors
  if (isBrandRabbitX) {
    return {
      mobileOutline: '#636E85',

      transparent: 'transparent',

      main300: '#91D600',
      main200: '#B1FB16',

      white: '#FFFFFF',
      black: '#000000',

      shadesBackground900: '#0A0E18',
      shadesBackground800: '#101624',
      shadesBackground700: '#1A2235',
      shadesBackground700TransparentChart: '#161D2E',
      shadesBackground600: '#202A3F',
      shadesBackground500: '#23304A',

      shadesForeground400: '#414E69',
      shadesForeground300: '#636E85',
      shadesForeground200: '#828DA4',
      shadesForeground100: '#A1ACC2',
      shadesForeground50: '#C0CBE1',

      primaryGreenForeground300: '#008064',
      primaryGreenForeground200: '#009978',
      primaryGreenForeground100: '#19EAAB',

      positiveForeground400: '#008064',
      positiveForeground300: '#009978',
      positiveForeground200: '#19EAAB',
      positiveForeground100: '#89FFB8',
      positiveForeground50: '#D3F5DF',

      primaryGreenBackground500: '#12292E',
      primaryGreenBackground400: '#122F35',
      primaryGreenBackground300: '#12353C',
      primaryGreenBackground200: '#113A42',
      primaryGreenBackground100: '#1C3D44',

      positiveBackground500: '#12292E',
      positiveBackground400: '#122F35',
      positiveBackground300: '#12353C',
      positiveBackground200: '#113A42',
      positiveBackground100: '#1C3D44',

      warningBackground500: '#322A1E',
      warningBackground400: '#39301F',
      warningBackground300: '#403520',
      warningBackground200: '#473D22',
      warningBackground100: '#464129',

      secondaryGold100: '#FFF5D1',
      secondaryGold200: '#F6E198',
      secondaryGold300: '#F3CD75',
      secondaryGold400: '#FDB022',
      secondaryGoldBackground100: '#403520',
      secondaryGoldTransparent: 'rgba(246, 225, 152, 0.5)',

      warningForeground400: '#D4973B',
      warningForeground300: '#FDB022',
      warningForeground200: '#F3CD75',
      warningForeground100: '#F6E198',
      warningForeground50: '#FFF5D1',

      negativeBackground500: '#321E2C',
      negativeBackground400: '#391F2E',
      negativeBackground300: '#402031',
      negativeBackground200: '#472233',
      negativeBackground100: '#462936',

      primaryRedBackground500: '#321E2C',
      primaryRedBackground400: '#391F2E',
      primaryRedBackground300: '#402031',
      primaryRedBackground200: '#472233',
      primaryRedBackground100: '#462936',

      negativeForeground400: '#D43B4E',
      negativeForeground300: '#ED344A',
      negativeForeground200: '#FF475D',
      negativeForeground100: '#FF6D7F',
      negativeForeground50: '#F5D3D7',

      primaryRedForeground300: '#D43B4E',
      primaryRedForeground200: '#ED344A',
      primaryRedForeground100: '#FF475D',

      goldGradient300: 'linear-gradient(90deg, #292638 0%, #38342B 100%)',
      goldGradient200: 'linear-gradient(90deg, #393552 -6.52%, #524935 100%)',
      goldGradient100: 'linear-gradient(90deg, #423E5C -6.52%, #807252 108.7%)',

      secondaryWalletAndRewardsPurpleGold300:
        'linear-gradient(90deg, #292638 -6.52%, #38342B 108.7%)',
      secondaryWalletAndRewardsPurpleGold200:
        'linear-gradient(90deg, #393552 -6.52%, #524935 100%)',
      secondaryWalletAndRewardsPurpleGold100:
        'linear-gradient(90deg, #423E5C -6.52%, #807252 108.7%)',

      positiveGradient300:
        'linear-gradient(180deg, rgba(13, 203, 146, 0.14) 17.02%, rgba(13, 203, 146, 0.00) 100.09%)',
      positiveGradient200:
        'linear-gradient(180deg, rgba(13, 203, 146, 0.14) -12.93%, rgba(13, 203, 146, 0.00) 100%)',
      positiveGradient100:
        'linear-gradient(180deg, rgba(13, 203, 146, 0.14) 62.5%, rgba(13, 203, 146, 0.00) 95.31%)',

      primaryGreenGradientsS:
        'linear-gradient(180deg, rgba(13, 203, 146, 0.14) 62.5%, rgba(13, 203, 146, 0) 95.31%)',
      primaryGreenGradientsM:
        'linear-gradient(180deg, rgba(13, 203, 146, 0.14) -12.93%, rgba(13, 203, 146, 0) 100%)',
      primaryGreenGradientsL:
        'linear-gradient(180deg, rgba(13, 203, 146, 0.14) 17.02%, rgba(13, 203, 146, 0) 100.09%)',

      negativeGradient300:
        'linear-gradient(180deg, rgba(238, 55, 104, 0.20) 6.61%, rgba(223, 32, 64, 0.00) 100%)',
      negativeGradient200:
        'linear-gradient(180deg, rgba(238, 55, 104, 0.20) -4.71%, rgba(223, 32, 64, 0.00) 100%)',
      negativeGradient100:
        'linear-gradient(180deg, rgba(238, 55, 104, 0.15) 64.55%, rgba(223, 32, 64, 0.00) 100%)',

      primaryRedGradientsS:
        'linear-gradient(180deg, rgba(238, 55, 104, 0.15) 64.55%, rgba(223, 32, 64, 0) 100%)',
      primaryRedGradientsM:
        'linear-gradient(180deg, rgba(238, 55, 104, 0.2) -4.71%, rgba(223, 32, 64, 0) 100%)',
      primaryRedGradientsL:
        'linear-gradient(180deg, rgba(238, 55, 104, 0.2) 6.61%, rgba(223, 32, 64, 0) 100%)',

      secondaryWalletAndRewardsGeneralPurple: '#8A61FF',

      gaugeGradient:
        'radial-gradient(284.04% 100% at 0% 50%, #19EAAB 0%, #F6E198 75.96%, #EE3768 100%)',

      tertiaryGradientsGauge:
        'radial-gradient(100% 284.04% at 0% 50%, #19EAAB 0%, #F6E198 75.96%, #EE3768 100%)',

      leverageRiskGradient:
        'radial-gradient(277.17% 97.58% at 2.42% 54.55%, #19EAAB 0%, #4E5EDA 51.16%, #FF475D 100%)',

      tertiaryGradientsLeverageRisk:
        'radial-gradient(97.58% 277.17% at 2.42% 54.55%, #19EAAB 0%, #4E5EDA 51.16%, #FF475D 100%)',
      tertiaryGradientsLowLeverageRisk: '#26C8B7',
      tertiaryGradientsSemiLowLeverageRisk: '#3E8BCB',
      tertiaryGradientsMediumLeverageRisk: '#4E5EDA',
      tertiaryGradientsHighLeverageRisk: '#D14782',
      tertiaryGradientsVeryHighLeverageRisk: '#FF475D',
    };
  }

  // BFX theme colors
  return {
    mobileOutline: '#636E85',

    transparent: 'transparent',

    main300: '#91D600',
    main200: '#B1FB16',

    white: '#FFFFFF',
    black: '#000000',

    shadesBackground900: '#111416',
    shadesBackground800: '#121C25',
    shadesBackground700: '#142431',
    shadesBackground700TransparentChart: '#131E26',
    shadesBackground600: '#1C3342',
    shadesBackground500: '#213C4B',

    shadesForeground400: '#355557',
    shadesForeground300: '#58787A',
    shadesForeground200: '#828DA4',
    shadesForeground100: '#98B7B9',
    shadesForeground50: '#BCD7D9',

    primaryGreenForeground100: '#19EAAB',
    primaryGreenForeground200: '#009978',
    primaryGreenForeground300: '#008064',

    positiveForeground400: '#008064',
    positiveForeground300: '#009978',
    positiveForeground200: '#19EAAB',
    positiveForeground100: '#89FFB8',
    positiveForeground50: '#D3F5DF',

    primaryGreenBackground500: '#12292E',
    primaryGreenBackground400: '#122F35',
    primaryGreenBackground300: '#12353C',
    primaryGreenBackground200: '#113A42',
    primaryGreenBackground100: '#1C3D44',

    positiveBackground500: '#12292E',
    positiveBackground400: '#122F35',
    positiveBackground300: '#12353C',
    positiveBackground200: '#113A42',
    positiveBackground100: '#1C3D44',

    warningBackground500: '#322A1E',
    warningBackground400: '#39301F',
    warningBackground300: '#403520',
    warningBackground200: '#473D22',
    warningBackground100: '#464129',

    secondaryGold100: '#FFF5D1',
    secondaryGold200: '#F6E198',
    secondaryGold300: '#F3CD75',
    secondaryGold400: '#FDB022',
    secondaryGoldBackground100: '#403520',
    secondaryGoldTransparent: 'rgba(246, 225, 152, 0.5)',

    warningForeground400: '#D4973B',
    warningForeground300: '#FDB022',
    warningForeground200: '#F3CD75',
    warningForeground100: '#F6E198',
    warningForeground50: '#FFF5D1',

    negativeBackground500: '#321E2C',
    negativeBackground400: '#391F2E',
    negativeBackground300: '#402031',
    negativeBackground200: '#472233',
    negativeBackground100: '#462936',

    primaryRedBackground500: '#321E2C',
    primaryRedBackground400: '#391F2E',
    primaryRedBackground300: '#402031',
    primaryRedBackground200: '#472233',
    primaryRedBackground100: '#462936',

    negativeForeground400: '#D43B4E',
    negativeForeground300: '#ED344A',
    negativeForeground200: '#FF475D',
    negativeForeground100: '#FF6D7F',
    negativeForeground50: '#F5D3D7',

    primaryRedForeground100: '#FF475D',
    primaryRedForeground200: '#ED344A',
    primaryRedForeground300: '#D43B4E',

    goldGradient300: 'linear-gradient(90deg, #292638 0%, #38342B 100%)',
    goldGradient200: 'linear-gradient(90deg, #393552 -6.52%, #524935 100%)',
    goldGradient100: 'linear-gradient(90deg, #423E5C -6.52%, #807252 108.7%)',

    secondaryWalletAndRewardsPurpleGold300:
      'linear-gradient(90deg, #292638 -6.52%, #38342B 108.7%)',
    secondaryWalletAndRewardsPurpleGold200:
      'linear-gradient(90deg, #393552 -6.52%, #524935 100%)',
    secondaryWalletAndRewardsPurpleGold100:
      'linear-gradient(90deg, #423E5C -6.52%, #807252 108.7%)',

    positiveGradient300:
      'linear-gradient(180deg, rgba(13, 203, 146, 0.14) 17.02%, rgba(13, 203, 146, 0.00) 100.09%)',
    positiveGradient200:
      'linear-gradient(180deg, rgba(13, 203, 146, 0.14) -12.93%, rgba(13, 203, 146, 0.00) 100%)',
    positiveGradient100:
      'linear-gradient(180deg, rgba(13, 203, 146, 0.14) 62.5%, rgba(13, 203, 146, 0.00) 95.31%)',

    primaryGreenGradientsS:
      'linear-gradient(180deg, rgba(13, 203, 146, 0.14) 62.5%, rgba(13, 203, 146, 0) 95.31%)',
    primaryGreenGradientsM:
      'linear-gradient(180deg, rgba(13, 203, 146, 0.14) -12.93%, rgba(13, 203, 146, 0) 100%)',
    primaryGreenGradientsL:
      'linear-gradient(180deg, rgba(13, 203, 146, 0.14) 17.02%, rgba(13, 203, 146, 0) 100.09%)',

    negativeGradient300:
      'linear-gradient(180deg, rgba(238, 55, 104, 0.20) 6.61%, rgba(223, 32, 64, 0.00) 100%)',
    negativeGradient200:
      'linear-gradient(180deg, rgba(238, 55, 104, 0.20) -4.71%, rgba(223, 32, 64, 0.00) 100%)',
    negativeGradient100:
      'linear-gradient(180deg, rgba(238, 55, 104, 0.15) 64.55%, rgba(223, 32, 64, 0.00) 100%)',

    primaryRedGradientsS:
      'linear-gradient(180deg, rgba(238, 55, 104, 0.15) 64.55%, rgba(223, 32, 64, 0) 100%)',
    primaryRedGradientsM:
      'linear-gradient(180deg, rgba(238, 55, 104, 0.2) -4.71%, rgba(223, 32, 64, 0) 100%)',
    primaryRedGradientsL:
      'linear-gradient(180deg, rgba(238, 55, 104, 0.2) 6.61%, rgba(223, 32, 64, 0) 100%)',

    secondaryWalletAndRewardsGeneralPurple: '#8A61FF',

    gaugeGradient:
      'radial-gradient(284.04% 100% at 0% 50%, #19EAAB 0%, #F6E198 75.96%, #EE3768 100%)',

    tertiaryGradientsGauge:
      'radial-gradient(100% 284.04% at 0% 50%, #19EAAB 0%, #F6E198 75.96%, #EE3768 100%)',

    leverageRiskGradient:
      'radial-gradient(277.17% 97.58% at 2.42% 54.55%, #19EAAB 0%, #4E5EDA 51.16%, #FF475D 100%)',

    tertiaryGradientsLeverageRisk:
      'radial-gradient(97.58% 277.17% at 2.42% 54.55%, #19EAAB 0%, #4E5EDA 51.16%, #FF475D 100%)',
    tertiaryGradientsLowLeverageRisk: '#26C8B7',
    tertiaryGradientsSemiLowLeverageRisk: '#3E8BCB',
    tertiaryGradientsMediumLeverageRisk: '#4E5EDA',
    tertiaryGradientsHighLeverageRisk: '#D14782',
    tertiaryGradientsVeryHighLeverageRisk: '#FF475D',
  };
};

const currentThemeColors: ThemeColors = getThemeColors();

export const theme: ITheme = {
  fonts: {
    kabeljauwRabbitXText: 'KabeljauwRabbitXText',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 550,
  },
  fontFamilyText: ['KabeljauwRabbitXText', 'sans-serif'].join(','),
  fontFamilyNumbers: ['KabeljauwRabbitXText', 'sans-serif'].join(','),
  colors: currentThemeColors,
  screenSizes: {
    xs: 360,
    smaller: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    medium: 1280,
    xxl: 1400,
    xxxl: 1600,
    default: 1920,
    ultrawide: 2500,
  },
};

export type TScreenSize = keyof typeof theme.screenSizes;

export const createMuiTheme = (t: any) =>
  createTheme({
    props: {
      MuiTablePagination: {
        labelRowsPerPage: `${t ? t('rowsPerPage') : 'Rows per page'}:`,
      },
      MuiCheckbox: {
        checkedIcon: <img src={CheckboxCheckedIcon} alt="checked" />,
        icon: <img src={CheckboxBorderIcon} alt="checked" />,
      },
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    typography: {
      fontFamily: theme.fontFamilyText,
    },
    overrides: {
      MuiFormControlLabel: {
        root: {
          color: theme.colors.shadesForeground300,
        },
      },
      MuiCheckbox: {
        root: {
          '& .PrivateSwitchBase-root-59': {
            padding: '0px',
          },
          '& .MuiIconButton-label': {
            color: theme.colors.shadesForeground300,
          },
          '& .MuiTouchRipple-root': {
            color: theme.colors.shadesForeground200,
          },
        },
      },
      MuiTabs: {
        indicator: {
          background: '#fff',
          height: '2px',
        },
        root: {
          minHeight: 'unset',
          minWidth: 'unset',
          height: 'unset',
          overflow: 'visible',

          '& .MuiTabs-flexContainer': {
            overflow: 'visible',
          },
          '& .MuiTab-root': {
            boxSizing: 'border-box',
            padding: '0 0 10px 0',
            marginRight: '20px',
            minHeight: 'unset',
            height: 'unset',
            minWidth: 'unset',
            overflow: 'visible',
          },
          padding: '0px',
          '& .MuiTab-wrapper': {
            textTransform: 'none',
            fontSize: '11px',
            lineHeight: 'normal',
            fontWeight: theme.fontWeights.medium,
            color: theme.colors.shadesForeground200,
            transition: 'all 0.2s ease',
            padding: 0,
            overflow: 'visible',
          },

          '& .MuiTouchRipple-root': {
            margin: '-10px -10px 0 -10px',
          },
          '& .Mui-selected .MuiTab-wrapper': {
            color: theme.colors.white,
            fontWeight: theme.fontWeights.semiBold,
            padding: 0,
            overflow: 'visible',
          },
        },
      },

      MuiTablePagination: {
        root: {
          color: theme.colors.shadesForeground200,
          ...TextVariant['BODY_M'],
          marginTop: '10px',

          '& .MuiIconButton-root': {
            padding: 0,
          },
        },
        caption: TextVariant['BODY_M'],
        actions: {
          color: theme.colors.white,

          '& .Mui-disabled': {
            color: theme.colors.shadesForeground200,
          },
        },
        select: {
          color: `${theme.colors.shadesForeground200} !important`,
          paddingLeft: 0,
          ...TextVariant['BODY_M'],
        },
        selectIcon: {
          color: theme.colors.shadesForeground200,
        },
        input: {
          ...TextVariant['BODY_M'],
          color: theme.colors.shadesForeground200,
        },
        toolbar: { height: 'unset', minHeight: 'unset' },
      },

      MuiList: {
        root: { background: theme.colors.shadesBackground500 },
        padding: { paddingTop: 0, paddingBottom: 0 },
      },
      MuiMenuItem: {
        root: {
          minHeight: 'auto',
          color: theme.colors.shadesForeground200,
          ...TextVariant['BODY_M'],

          '.Mui-Selected': {
            color: theme.colors.white,
          },
        },
      },
      MuiTable: {
        root: {
          '& .MuiTableCell-stickyHeader': {
            background: theme.colors.shadesBackground900,
          },
        },
      },
      MuiSlider: {
        root: {
          right: '-2px',
          fontWeight: theme.fontWeights.medium,
          '&.Mui-disabled': {
            opacity: 0.4,
            color: theme.colors.shadesBackground700,
          },
        },
        colorPrimary: {
          color: theme.colors.shadesBackground700,
        },
        thumbColorPrimary: {
          background: theme.colors.shadesBackground700,
        },
        thumb: {
          height: '14px',
          width: '14px',
          top: '15px',
        },
        track: {
          height: '8px',
          background: theme.colors.shadesBackground700,
          opacity: 1,
          borderRadius: '4px',
        },
        rail: {
          width: 'calc(100% + 8px)',
          right: '-6px',
          height: '8px',
          background: theme.colors.shadesBackground700,
          opacity: 1,
          borderRadius: '4px',
        },
        valueLabel: {
          color: theme.colors.shadesBackground700,
          left: 'calc(-50% - 3px)',
          '& span span': {
            color: theme.colors.shadesBackground900,
            fontWeight: theme.fontWeights.semiBold,
          },
        },
        mark: {
          width: '4px',
          height: '4px',
          backgroundColor: theme.colors.shadesForeground300,
          top: '15px',
        },
        markActive: {
          width: '4px',
          height: '4px',
          backgroundColor: theme.colors.shadesForeground300,
          top: '15px',
        },
        markLabel: {
          color: theme.colors.shadesForeground200,
          fontWeight: theme.fontWeights.medium,
          top: '28px',
          ...TextVariant['BODY_S'],
        },
        markLabelActive: {
          color: theme.colors.shadesForeground200,
          fontWeight: theme.fontWeights.medium,
          ...TextVariant['BODY_S'],
        },
      },
    },
  });

const Theme = ({ children }) => {
  const { t } = useTranslation();

  return (
    <MuiThemeProvider theme={createMuiTheme(t)}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </MuiThemeProvider>
  );
};

export default Theme;
