import { getCurrencyFromTradingPair } from 'utils';

import { FormattedNumber } from 'components';
import Text from 'components/Text';

import { ColoredText, Colors } from '../shared';
import { useAppContext } from 'contexts/AppContext';

import { TradeSide } from 'enums';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const UserMarketStatsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  height: 20px;
  margin-top: 10px;
`;

type Props = {
  handleQuantityChange: (quantity: number) => void;
};
const UserMarketStats = ({ handleQuantityChange }: Props) => {
  const {
    store: {
      markets: { selectedMarketId },
      account: { _positions, frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();

  if (!frontendSecrets || !selectedMarketId || !_positions) {
    return null;
  }

  const selectedMarketPosition = _positions.find(
    el => el.marketID === selectedMarketId,
  );

  if (!selectedMarketPosition) {
    return null;
  }

  const { size, unrealizedPnl } = selectedMarketPosition;
  const positionCurrency = getCurrencyFromTradingPair(selectedMarketId);
  const isTradeLong = selectedMarketPosition.side === TradeSide.LONG;

  return (
    <UserMarketStatsStyled>
      <Text variant="BODY_S" flexed color="shadesForeground200">
        {t('position')}:
        <Text
          color={
            isTradeLong ? 'primaryGreenForeground100' : 'negativeForeground200'
          }
          onClick={() => {
            handleQuantityChange(size);
          }}
        >
          <FormattedNumber value={size} suffix={` ${positionCurrency}`} />
        </Text>
      </Text>

      <Text variant="BODY_S" flexed color="shadesForeground200">
        {t('pnl')}:
        <Text
          color={
            unrealizedPnl >= 0
              ? 'primaryGreenForeground100'
              : 'negativeForeground200'
          }
        >
          <FormattedNumber value={unrealizedPnl} prefix="$" />
        </Text>
      </Text>
    </UserMarketStatsStyled>
  );
};

export default observer(UserMarketStats);
