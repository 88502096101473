import { Colors } from 'pages/Trade/components/shared';

import styled from 'styled-components';

type ContainerProps = {
  showWatchlistOnMediumDevices: boolean;
  showWatchlistOnlyOnUltraWideDevices: boolean;
};
export const Container = styled.div`
  width: 300px;
  grid-column: auto;
  max-height: calc(100vh - 150px);
  overflow-y: hidden;

  @media (max-width: ${({ theme }) => theme.screenSizes.default - 1}px) {
    display: ${({ showWatchlistOnMediumDevices }: ContainerProps) =>
      showWatchlistOnMediumDevices ? 'block' : 'none'};
  }

  @media (max-width: ${({ theme }) => theme.screenSizes.ultrawide - 1}px) {
    display: ${({ showWatchlistOnlyOnUltraWideDevices }: ContainerProps) =>
      !showWatchlistOnlyOnUltraWideDevices ? 'block' : 'none'};
  }

  @media (max-width: ${({ theme }) => theme.screenSizes.medium - 1}px) {
    display: none;
  }
`;

type FixedContainerProps = {
  isFixed: boolean;
};
export const FixedContainer = styled.div<FixedContainerProps>`
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'relative')};
  top: 0;
  max-width: 300px;
`;

type SectionProps = {
  headerColor?: Colors;
};

export const SectionStyled = styled.div<SectionProps>`
  padding-bottom: 20px;

  .in-span-icon {
    margin: 0px 2px;
    vertical-align: middle;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .left-part {
      display: flex;
      align-items: center;
      color: ${({ headerColor, theme }) =>
        headerColor === Colors.Inherit
          ? theme.colors.white
          : headerColor === Colors.Success
          ? theme.colors.positiveForeground200
          : theme.colors.negativeForeground200};
    }

    .right-part {
      display: flex;
      justify-content: flex-end;
      width: 30%;
    }
  }
`;

export const GrayLine = styled.div`
  width: 100px;
  height: 2px;
  background: #222b3d;
  width: 100%;
`;

export const CoinRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: 12px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }
`;

export const CoinInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img.coin-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .coin-name {
    display: flex;
    flex-direction: column;
    width: 65px;
    gap: 2px;

    .coin-ticker {
      word-break: break-all;
    }
  }
`;

export const CoinChart = styled.div`
  height: 28px;
  width: 100%;
  padding: 0 5px;
`;

export const CoinStats = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 85px;
  text-align: right;
  gap: 2px;
`;
