import { WalletEntryPosition } from '@particle-network/auth';
import {
  BlastSepolia,
  Ethereum,
  EthereumSepolia,
  Blast,
} from '@particle-network/chains';
import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';

import { isBrandRabbitX } from 'utils/brand';
import { appLangToParticleAuthLangCode } from 'utils/particleAuth';

import { config } from '../config';
import { ParticleAuthConnector } from './particleAuthConnector';
import { WalletConnectV2Connector } from './walletconnectV2Connector';
import i18n from 'i18n/config';

import { ChainId } from 'enums';

export const isMainnet = (chainId: number) => {
  if (isBrandRabbitX) {
    return [ChainId.MAINNET].includes(chainId);
  }
  return [ChainId.BLAST_MAINNET].includes(chainId);
};

const ALCHEMY_KEY = process.env.REACT_APP_ALCHEMY_KEY;
if (typeof ALCHEMY_KEY === 'undefined') {
  throw new Error(`REACT_APP_ALCHEMY must be a defined environment variable`);
}

const CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID);
if (typeof CHAIN_ID === 'undefined') {
  throw new Error(`REACT_APP_CHAIN_ID must be a defined environment variable`);
}

// @todo: for now, we only support one chain ID per environment, we should probably read an array of chain IDs from .env to support multiple
const mainnetSupportedChainIds = isBrandRabbitX
  ? [ChainId.MAINNET]
  : [ChainId.BLAST_MAINNET];
const devSupportedChainIds = isBrandRabbitX
  ? [ChainId.SEPOLIA]
  : [ChainId.BLAST_SEPOLIA];

const allSupportedChainIds = isMainnet(CHAIN_ID)
  ? mainnetSupportedChainIds
  : devSupportedChainIds;

const WALLET_CONNECT_V2_PROJECT_ID =
  process.env.REACT_APP_WALLET_CONNECT_V2_PROJECT_ID;
if (typeof WALLET_CONNECT_V2_PROJECT_ID === 'undefined') {
  throw new Error(
    `REACT_APP_WALLET_CONNECT_V2_PROJECT_ID must be a defined environment variable`,
  );
}

export const NETWORK_URLS = {
  [ChainId.MAINNET]: `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  [ChainId.SEPOLIA]: `https://eth-sepolia.g.alchemy.com/v2/${ALCHEMY_KEY}`,
  [ChainId.BLAST_SEPOLIA]: `https://rpc.ankr.com/blast_testnet_sepolia/b7d0a3faad063ffc1d357fcfeab73ff55e20f85d3b259b42719338d98c12e6ec`,
  [ChainId.BLAST_MAINNET]:
    'https://misty-empty-meme.blast-mainnet.quiknode.pro/290576d02da19e9227d799054d2d1d383de9b389',
  [ChainId.LOCAL]: 'http://127.0.0.1:8545/',
};

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: CHAIN_ID,
});

export const injected = new InjectedConnector({
  // supportedChainIds: allSupportedChainIds,
});

export const walletconnect = new WalletConnectV2Connector({
  projectId: WALLET_CONNECT_V2_PROJECT_ID,
  showQrModal: true,
  chains: allSupportedChainIds,
  rpcMap: NETWORK_URLS,
} as any);

const PN_APP_ID_WEB = process.env.REACT_APP_PN_APP_ID_WEB;
if (typeof PN_APP_ID_WEB === 'undefined') {
  throw new Error(`PN_APP_ID_WEB must be a defined environment variable`);
}

// Chain ID to name map, only to be used for particle supported chains
// Add chainID and name here if you want to support particle auth for more chains
const chainIDToNameMap = {
  [ChainId.MAINNET]: Ethereum.name,
  [ChainId.SEPOLIA]: EthereumSepolia.name,
  [ChainId.BLAST_SEPOLIA]: BlastSepolia.name,
  [ChainId.BLAST_MAINNET]: Blast.name,
};

export const doesCurrentChainSupportParticleAuth =
  chainIDToNameMap[config.chainID] !== undefined;

const chainConfig = doesCurrentChainSupportParticleAuth
  ? {
      id: config.chainID,
      name: chainIDToNameMap[config.chainID],
    }
  : {
      id: ChainId.SEPOLIA,
      name: EthereumSepolia.name,
    };

export const socialconnect = new ParticleAuthConnector({
  projectId: process.env.REACT_APP_PN_PROJECT_ID as string,
  clientKey: process.env.REACT_APP_PN_PROJECT_CLIENT_KEY as string,
  appId: process.env.REACT_APP_PN_APP_ID_WEB as string,
  chainName: chainConfig.name,
  chainId: chainConfig.id,
  wallet: {
    //optional: by default, the wallet entry is displayed in the bottom right corner of the webpage.
    displayWalletEntry: false, //show wallet entry when connect particle.
    defaultWalletEntryPosition: WalletEntryPosition.BR, //wallet entry position
    uiMode: 'dark', //optional: light or dark, if not set, the default is the same as web auth.
    supportChains: [chainConfig],
    customStyle: {}, //optional: custom wallet style
  },
});
