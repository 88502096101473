import { useRef, useState } from 'react';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { LooseValue } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';

import { dateToUnixTimestampMicroseconds, parseDateToSlashes } from 'utils';

import Button from 'components/Button/button';

import { Row, DatePickerContainer } from './styles';
import calenderIcon from 'assets/icons/calender-gray.svg';
import doubleArrowLeftIcon from 'assets/icons/double-arrow-left.svg';
import doubleArrowRightIcon from 'assets/icons/double-arrow-right.svg';

import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';

export type UnixDate = number | null;
export type UnixDateRange = [UnixDate, UnixDate];

export type StyledDateRangePickerProps = {
  selectedRange: UnixDateRange | undefined;
  onRangeSelect: (range: UnixDateRange | undefined) => void;
  containerClass?: string;
};

const convertUnixToLooseValue = (input: UnixDateRange | null | undefined) => {
  if (!isArray(input)) return;
  return [
    input[0] === null ? null : new Date(input[0] / 1000),
    input[1] === null ? null : new Date(input[1] / 1000),
  ] as LooseValue;
};

const convertLooseValueToUnix = (input: LooseValue): UnixDateRange => {
  if (!isArray(input)) return [null, null];

  if (input[0] instanceof Date && input[1] instanceof Date) {
    return [
      dateToUnixTimestampMicroseconds(input[0]),
      dateToUnixTimestampMicroseconds(input[1]),
    ] as UnixDateRange;
  }

  return [null, null];
};

const getShortWeekday = (date: Date) =>
  ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][date.getDay()];

/**
 * Displays a component for selecting a date range.
 *
 * @component
 * @param {UnixDateRange} selectedRange - The currently selected date range (in microseconds).
 * @param {(range: UnixDateRange) => void} onRangeSelect - Callback function to handle when a new date range is selected (in microseconds).
 * @param {string} [containerClass] - Overrides container class.
 * @returns {React.ReactElement} The rendered StyledDateRangePicker component.
 */
const StyledDateRangePicker = ({
  selectedRange,
  onRangeSelect,
  containerClass,
}: StyledDateRangePickerProps): React.ReactElement => {
  const [range, setRange] = useState({
    range: convertUnixToLooseValue(selectedRange),
    activeLength: selectedRange ? 2 : 0,
  });
  const { t } = useTranslation();
  const inputRef = useRef<HTMLDivElement>(null);

  const handleUpdateClick = () => {
    if (!range.range) return;
    onRangeSelect(convertLooseValueToUnix(range.range));
  };

  // A hacky way to get whether a date or a range is selected
  const handleDayClick = () =>
    setTimeout(() => {
      const container = inputRef.current;
      if (!container) return;
      setRange(prev => ({
        ...prev,
        activeLength: container.querySelectorAll(
          '.react-calendar__tile--active',
        ).length,
      }));
    });

  return (
    <DatePickerContainer
      rangeLength={range.activeLength}
      className={containerClass}
    >
      <Row>
        <div className="input-wrapper">
          <img src={calenderIcon} alt="start" />
          {parseDateToSlashes(range.range?.[0])}
        </div>
        -
        <div className="input-wrapper">
          <img src={calenderIcon} alt="end" />
          {parseDateToSlashes(range.range?.[1])}
        </div>
      </Row>
      <DateRangePicker
        isOpen
        inputRef={inputRef}
        onChange={range => setRange(prev => ({ ...prev, range }))}
        value={range.range}
        onClickDay={handleDayClick}
        nextLabel={
          <img src={doubleArrowRightIcon} alt="»" className="arrow-icon" />
        }
        prevLabel={
          <img src={doubleArrowLeftIcon} alt="«" className="arrow-icon" />
        }
        formatShortWeekday={(_local, date) => getShortWeekday(date)}
      />
      <Row>
        <Button
          block
          sizeVariant="S"
          colorVariant="secondary"
          onClick={() => onRangeSelect(undefined)}
        >
          {t('clear')}
        </Button>
        <Button
          block
          sizeVariant="S"
          colorVariant="primaryGreen"
          disabled={
            !(
              isArray(range.range) &&
              range.activeLength > 1 &&
              range.range?.[0] &&
              range.range?.[1]
            )
          }
          onClick={handleUpdateClick}
        >
          {t('update')}
        </Button>
      </Row>
    </DatePickerContainer>
  );
};

export default StyledDateRangePicker;
