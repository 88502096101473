import { FormControlLabel } from '@material-ui/core';

import styled from 'styled-components';

type AdvancedOptionsProps = {
  isOpen: boolean;
};
export const AdvancedOptionsStyled = styled.div<AdvancedOptionsProps>`
  padding: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.shadesForeground100};
  cursor: pointer;
  background: ${({ theme, isOpen }) =>
    isOpen
      ? theme.colors.shadesBackground700
      : theme.colors.shadesBackground800};
  border: 1px solid
    ${({ theme, isOpen }) =>
      isOpen ? 'transparent' : theme.colors.shadesBackground500};
  border-radius: 8px;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.shadesForeground400};
  }

  .PrivateSwitchBase-root-62 {
    padding: '0px';
  }

  .PrivateSwitchBase-input-59 {
    padding: '0px';
  }
`;

export const DropdownStyled = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    margin-top: 10px;
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  font-size: 12px;

  & .MuiButtonBase-root {
    padding-right: 5px;
  }
`;
