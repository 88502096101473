import { Theme as ITheme } from 'interfaces';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{ theme: ITheme }>`
  html {
    height: -webkit-fill-available;
  }
  
  body {
    color: ${({ theme }) => theme.colors.white};
    letter-spacing: -0.02em;
    font-family: ${({ theme }) => theme.fontFamilyText};    
    margin: 0;
    background: ${({ theme }) => theme.colors.shadesBackground900};
    font-variant: tabular-nums;
  }

  a {
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
  }

  button {
    border: 0;
    display: inline-block;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    font-variant: tabular-nums;
    font-family: ${({ theme }) => theme.fontFamilyText};    
  }

  .no-value {
    color: ${({ theme }) => theme.colors.shadesForeground400};
  }

  :root {
      /* fonts */
      --numbers-xs-medium: 'Kabeljauw RabbitX Text';
      --font-britanica-bold: Britanica-Bold;
      --font-britanica-regular: Britanica-Regular;
      --font-britanica-black: Britanica-Black;

      /* font sizes */
      --numbers-xs-medium-size: 14px;
      --numbers-xxs-medium-size: 12px;
      --font-size-smi: 13px;
      --font-size-base: 16px;
      --font-size-xl: 20px;
      --font-size-5xl: 24px;
      --font-size-lg: 18px;
      --numbers-us-medium-size: 10px;

      /* Colors */
      --color-black: #000;
      --markets-colour-short-btn: #462936;
      --colour-background: #0a0e18;
      --color-gray-100: rgba(255, 255, 255, 0.4);
      --colour-text-primary: #fff;
      --colour-component1: #213c4b;
      --markets-colour-long-btn: #1c3d44;
      --colour-component: #23304a;
      --colour-text-secondary: #828da4;
      --colour-negative: #ff475d;
      --colour-positive: #19eaab;

      /* Gaps */
      --gap-21xl: 40px;
      --gap-3xs: 10px;
      --gap-8xs: 5px;
      --gap-12xs: 1px;
      --gap-11xl: 30px;
      --gap-6xl: 25px;
      --gap-mini: 15px;

      /* Paddings */
      --padding-lg: 18px;
      --padding-xl: 20px;
      --padding-3xs: 10px;
      --padding-mini: 15px;
      --padding-7xl: 26px;
      --padding-11xs: 2px;
      --padding-9xs: 4px;
      --padding-8xs: 5px;
      --padding-10xs: 3px;
      --padding-xs: 12px;
      --padding-6xl: 25px;

      /* Border radiuses */
      --br-base: 16px;
      --br-5xs: 8px;
      --br-81xl: 100px;
      --br-9xs: 4px;
      --br-xs: 12px;
  }
`;
