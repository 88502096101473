import { brand } from 'utils/brand';

import { PlatformVaultsDataWithHoldings, useVerifyChainId } from 'hooks';
import useModal from 'hooks/useModal';

import { FormattedNumber, Icon } from 'components';
import Badge from 'components/Badge';
import Button from 'components/Button/button';
import IconPair from 'components/IconPair';
import InfoTooltip from 'components/InfoTooltip';
import Text from 'components/Text';

import { getTotalVaultApy } from '../../../VaultList/utils';
import { useAmendPlatformVault } from '../../../useAmendPlatformVault';
import blastLogo from 'assets/assetLogos/blast-green.svg';
import { SHOULD_DISABLE_DEPOSITS_WITHDRAWALS } from 'constants/general';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import {
  StatSeperator,
  StatContainer,
  Container,
} from 'pages/Vaults/Pages/VaultOverview/components/VaultName/styles';
import { AmendType } from 'pages/Vaults/common/AmendLiquidityModal';
import { Column } from 'pages/Vaults/styles';
import { useFetchPlatformVaultPnl } from 'queryHooks/platformVaults';
import { Row } from 'theme/globalStyledComponents/row';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type Props = {
  vault: PlatformVaultsDataWithHoldings;
};
const VaultName = ({ vault }: Props) => {
  const { t } = useTranslation();
  const modal = useModal();
  const {
    store: {
      account: { frontendSecrets, isVaultAccount },
    },
  } = useAppContext();
  const { amendPlatformVault } = useAmendPlatformVault();
  const isSuspended = vault.status === 'suspended';
  const shouldDisableActionButtons = isVaultAccount || isSuspended;

  const vaultPnl = useFetchPlatformVaultPnl(vault.wallet);

  const { pnl, percentChange } = vaultPnl || {};
  const isPositivePnl = pnl && pnl > 0;
  const pnlPrefix = isPositivePnl ? '+$' : '$';

  const showLiquidityModal = (amendType: AmendType) => {
    if (shouldDisableActionButtons) return;

    amendPlatformVault(amendType, vault.wallet);
  };

  const onConnectWallet = () =>
    modal.present(<WalletModal />, Modals.walletModal);

  return (
    <Container>
      <div className="top-part">
        <Text variant="BODY_L">
          {t('operatedBy')} {vault.managerName}
        </Text>

        <div className="stats-container">
          <Stat
            label={t('yourShares')}
            value={vault.holdings?.userShares}
            suffix={` ${t('shares')}`}
          />
          <StatSeperator />
          <Stat
            label={t('yourNAV')}
            value={
              frontendSecrets?.jwt ? vault.holdings?.netWithdrawable : undefined
            }
            suffix={` ${brand.tokenTicker}`}
          />
          <StatSeperator />

          <>
            <Stat
              label={t('yourPnl')}
              value={pnl}
              valuePrefix={pnlPrefix}
              percentChange={isPositivePnl ? percentChange : undefined}
            />
          </>
          <Stat
            label={t('monthlyApy')}
            value={getTotalVaultApy(vault.apy)}
            suffix="%"
            toolTipText={t('vaultApyTooltip')}
          />
        </div>
      </div>

      <div className="bottom-part">
        <Column className="gap-10 icons-and-status-container">
          <Row gap={10}>
            <Text
              variant="BODY_S"
              className={`active-badge ${!isSuspended ? 'success' : 'warning'}`}
            >
              {t(!isSuspended ? 'active' : 'inactive')}
            </Text>
            <Icon
              size="L"
              src={brand.chainLogo}
              alt={brand.tokenTicker}
              className="br-4"
            />
          </Row>

          <Text
            variant="BODY_L"
            fontWeight="semiBold"
            className="gap-5 responsive-row"
          >
            <IconPair size={24} icons={[blastLogo, brand.tokenLogo]} />
            {brand.tokenTicker}
          </Text>
        </Column>

        <div className="action-buttons">
          {frontendSecrets?.profile.wallet ? (
            <>
              <Button
                sizeVariant="S"
                colorVariant="primaryGreen"
                disabled={
                  shouldDisableActionButtons ||
                  SHOULD_DISABLE_DEPOSITS_WITHDRAWALS
                }
                onClick={() => showLiquidityModal(AmendType.Stake)}
                // rightIcon={downloadIcon}
              >
                {t('stake')}
              </Button>

              <Button
                sizeVariant="S"
                colorVariant="secondary"
                block
                disabled={
                  shouldDisableActionButtons ||
                  SHOULD_DISABLE_DEPOSITS_WITHDRAWALS
                }
                onClick={() => showLiquidityModal(AmendType.UnStake)}
                // rightIcon={uploadIcon}
              >
                {t('unstake')}
              </Button>
            </>
          ) : (
            <Button
              colorVariant="primaryGreen"
              block
              sizeVariant="S"
              onClick={event => {
                event.stopPropagation();
                onConnectWallet();
              }}
            >
              {t('connectWallet')}
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};

type StatProps = {
  label: string;
  value: number | undefined;
  toolTipText?: string;
  suffix?: string;
  valuePrefix?: string;
  percentChange?: number;
};
const Stat = ({
  label,
  value,
  toolTipText,
  suffix,
  valuePrefix,
  percentChange,
}: StatProps) => {
  return (
    <StatContainer>
      <Text
        variant="BODY_M"
        className="align-right"
        color="shadesForeground200"
        flexed
        gap={5}
      >
        {label}
        {toolTipText ? <InfoTooltip title={toolTipText} /> : null}
      </Text>
      <div className="value">
        <FormattedNumber
          variant="NUMBERS_L"
          fontWeight="semiBold"
          value={value}
          suffix={suffix}
          prefix={valuePrefix}
          className="align-right"
        />
        {percentChange !== undefined && (
          <Badge
            bgColor="positiveBackground100"
            color="positiveForeground200"
            variant="BODY_S"
            padding="4px"
          >
            <FormattedNumber value={percentChange} suffix="%" prefix="+" />
          </Badge>
        )}
      </div>
    </StatContainer>
  );
};

export default observer(VaultName);
