import { IBinanceCheckoutData } from 'hooks/useBinancePayApi';
import { TMeshState } from 'hooks/useMeshAPI';

import { TargetToken, TransakOrderStatus } from '.';
import { DepositMethod, UniswapSwapTokens } from 'enums';
import { ProfileBalanceOps } from 'interfaces';

export enum SwapSteps {
  APPROVE_TOKEN,
  APPROVING_TOKEN,
  SWAP,
  PENDING_TX_SIGN,
  SWAPPING,
  SUCCESS,
}

export enum DepositSteps {
  SWAP,
  ENABLE_USDT,
  APPROVING_USDT,
  DEPOSIT,
  PENDING_TX_SIGN,
  TRANSAK_IS_PROCESSING_ORDER,
  WAITING_FOR_DELIVERY_FROM_TRANSAK,
  WAITING_FOR_DELIVERY_FROM_MESH,
  AWAITING_CONFIRMATION,
  PROCESSING_DEPOSIT,
  SUCCESS,
  BINANCE_PAY_CHECKOUT,
  MESH_ORDER_CHECKOUT,
}

export const isTargetToken = (token: UniswapSwapTokens) =>
  token === TargetToken;

export const isWaitingStep = (step: DepositSteps) =>
  [
    DepositSteps.AWAITING_CONFIRMATION,
    DepositSteps.PROCESSING_DEPOSIT,
  ].includes(step);

export const getCurrentSwapStep = ({
  isTokenApprovedForSwap,
  isApprovingToken,
  isSwapping,
  isPendingTxSign,
}: {
  isTokenApprovedForSwap: boolean;
  isApprovingToken: boolean;
  isSwapping: boolean;
  isPendingTxSign: boolean;
}) => {
  let step: SwapSteps = SwapSteps.SWAP;
  if (isTokenApprovedForSwap) {
    step = SwapSteps.SWAP;
  } else {
    step = SwapSteps.APPROVE_TOKEN;
  }
  if (isApprovingToken) {
    step = SwapSteps.APPROVING_TOKEN;
  }
  if (isPendingTxSign) {
    step = SwapSteps.PENDING_TX_SIGN;
  }
  if (isSwapping) {
    step = SwapSteps.SWAPPING;
  }
  return step;
};

export const getCurrentDepositStep = (
  usdtAllowance: number,
  depositAmount: number | null,
  isApprovingUsdt: boolean,
  isPendingTxSign: boolean,
  isAwaitingConfirmation: boolean,
  transakOrderStatus: TransakOrderStatus | undefined,
  isDepositConfirmed: boolean,
  depositResultData: ProfileBalanceOps | undefined,
  binanceCheckoutData: IBinanceCheckoutData | undefined,
  meshState: TMeshState,
  isMethodAWalletTransfer: boolean,
): DepositSteps => {
  let step = DepositSteps.DEPOSIT;

  if (
    isMethodAWalletTransfer &&
    depositAmount &&
    usdtAllowance < depositAmount
  ) {
    step = DepositSteps.ENABLE_USDT;
  }

  if (binanceCheckoutData) {
    return (step = DepositSteps.BINANCE_PAY_CHECKOUT);
  }

  if (meshState.transferData) {
    return (step = DepositSteps.MESH_ORDER_CHECKOUT);
  }

  if (isApprovingUsdt) {
    step = DepositSteps.APPROVING_USDT;
  }

  if (isPendingTxSign) {
    step = DepositSteps.PENDING_TX_SIGN;
  }

  if (isAwaitingConfirmation) {
    step = DepositSteps.AWAITING_CONFIRMATION;
  }

  if (transakOrderStatus) {
    if (transakOrderStatus === TransakOrderStatus.PROCESSING) {
      step = DepositSteps.TRANSAK_IS_PROCESSING_ORDER;
    }
    if (transakOrderStatus === TransakOrderStatus.AWAITING_DELIVERY) {
      step = DepositSteps.WAITING_FOR_DELIVERY_FROM_TRANSAK;
    }
  }

  if (depositResultData?.txhash) {
    step = DepositSteps.PROCESSING_DEPOSIT;
  }
  if (isDepositConfirmed) {
    step = DepositSteps.SUCCESS;
  }

  return step;
};

interface GetShouldDisableDepositBtnProps {
  isLoading: boolean;
  isApprovingTokenForSwap: boolean;
  isSwapping: boolean;
  isApprovingUsdt: boolean;
  isPendingTxSign: boolean;
  step: DepositSteps;
  depositAmount: number | null;
  tokenBalance: number | undefined;
  depositMethod: DepositMethod;
  isAwaitingConfirmation: boolean;
  isOpeningMeshModal: boolean;
  isOpeningBinancePayCheckout: boolean;
  transakOrderStatus: TransakOrderStatus | undefined;
}
export const getShouldDisableDepositBtn = ({
  isLoading,
  isApprovingTokenForSwap,
  isSwapping,
  isApprovingUsdt,
  isPendingTxSign,
  step,
  depositAmount,
  tokenBalance,
  depositMethod,
  isAwaitingConfirmation,
  transakOrderStatus,
  isOpeningMeshModal,
  isOpeningBinancePayCheckout,
}: GetShouldDisableDepositBtnProps) => {
  // Don't disable the action button (Start Trading) if the deposit was successful
  if (step === DepositSteps.SUCCESS) {
    return false;
  }

  if (depositMethod === DepositMethod.Mesh || isOpeningMeshModal)
    return (depositAmount ?? 0) <= 0;

  if (depositMethod === DepositMethod.BinancePay) {
    if (isOpeningBinancePayCheckout) return true;
    return (depositAmount ?? 0) <= 0;
  }

  if (getIsMethodTransak(depositMethod)) {
    // If its a transak method, we dont care about the tokenBalance, just the deposit amount has to be above zero
    if (isWaitingStep(step)) return true;

    if (transakOrderStatus) {
      return true;
    }

    return (depositAmount ?? 0) > 0 ? false : true;
  }

  // Disable the action button if:
  return (
    // 1. The app is currently loading
    isLoading ||
    // 2. Approving token for swap
    isApprovingTokenForSwap ||
    // 3. The step is swapping
    isSwapping ||
    // 4. The step is waiting
    isWaitingStep(step) ||
    // 5. The tx is waiting to be signed
    isPendingTxSign ||
    // 6. The user is currently approving USDT
    isApprovingUsdt ||
    // 7. The user has not entered a deposit amount
    !depositAmount ||
    // 9. The user does not have enough USDT to cover the deposit amount
    !tokenBalance ||
    tokenBalance < depositAmount ||
    isAwaitingConfirmation
  );
};

interface GetShouldDisableSwapBtnProps {
  isLoading: boolean;
  isApprovingTokenForSwap: boolean;
  isSwapping: boolean;
  isPendingTxSign: boolean;
  depositAmount: number | null;
  tokenBalance: number | undefined;
}
export const getShouldDisableSwapBtn = ({
  isLoading,
  isApprovingTokenForSwap,
  isSwapping,
  isPendingTxSign,
  depositAmount,
  tokenBalance,
}: GetShouldDisableSwapBtnProps) => {
  // Disable the action button if:
  return (
    // 1. The app is currently loading
    isLoading ||
    // 2. Approving token for swap
    isApprovingTokenForSwap ||
    // 3. The step is swapping
    isSwapping ||
    // 4. The tx is waiting to be signed
    isPendingTxSign ||
    // 5. The user has not entered a deposit amount
    !depositAmount ||
    // 7. The user does not have enough tokens to swap
    !tokenBalance ||
    tokenBalance <= 0 ||
    tokenBalance < depositAmount
  );
};

export const getIsMethodTransak = (method: DepositMethod) =>
  [
    DepositMethod.ApplePay,
    DepositMethod.Card,
    DepositMethod.GooglePay,
  ].includes(method);
