import { brand, brandedSelect, isBrandRabbitX } from 'utils/brand';

import { getYourSharesNum } from 'pages/Vaults/Pages/Vaults/TabPanels/ElixirVaults';
import { getYourSharesUsd } from 'pages/Vaults/Pages/Vaults/TabPanels/PlatformVaults';
import {
  useFetchElixirVaultsData,
  useFetchVaultsWalletShares,
} from 'queryHooks/elixirVaults';
import { useFetchPlatformVaultsDataWithHoldings } from 'queryHooks/platformVaults';

export function useGetElixirVaultsShares(props?: {
  isEnabled?: boolean;
  disableRefetch?: boolean;
}) {
  const { isEnabled = isBrandRabbitX } = props || {};
  const { data: elixirVaultsData } = useFetchElixirVaultsData(isEnabled);

  const vaultsPoolIds = elixirVaultsData?.vaults.map(vault => vault.productId);

  const { data: vaultsWalletShares } = useFetchVaultsWalletShares({
    vaultsPoolIds,
    elixirVaultsData: elixirVaultsData?.vaults,
    isEnabled,
    disableRefetch: props?.disableRefetch,
  });

  return getYourSharesNum(vaultsWalletShares);
}

export function useGetPlatformVaultsShares(props?: {
  isEnabled?: boolean;
  disableRefetch?: boolean;
}) {
  const { isEnabled = brand.featureFlags.platformVaults } = props || {};

  const { data: vaultsDataWithHoldings } =
    useFetchPlatformVaultsDataWithHoldings('', isEnabled, props?.disableRefetch);

  return getYourSharesUsd(vaultsDataWithHoldings);
}

export const useGetStakedAmount = brandedSelect({
  rabbitx: useGetElixirVaultsShares,
  bfx: useGetPlatformVaultsShares,
});
