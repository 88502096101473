import Button from 'components/Button/button';

import { Container } from './styles';
import useCancelAndClaimWithdrawal from './useCancelAndClaimWithdrawal';

import { DepositOrWithdrawalStatus } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

type Props = {
  balanceOperationStatus: DepositOrWithdrawalStatus;
  balanceOperationId: string;
  cancellable?: boolean;
};
const CancelAndClaimCell = ({
  balanceOperationId,
  balanceOperationStatus,
  cancellable = true,
}: Props) => {
  const { t } = useTranslation();

  const {
    shouldDisableCancelButton,
    isCancelling,
    isClaiming,
    handleCancelWithdrawal,
    handleClaimWithdrawal,
    shouldDisableClaimButton,
    claimButtonText,
  } = useCancelAndClaimWithdrawal({
    opsId: balanceOperationId,
    opsStatus: balanceOperationStatus,
  });

  return (
    <Container>
      {cancellable ? (
        <Button
          colorVariant="secondary"
          sizeVariant="S"
          disabled={shouldDisableCancelButton || isCancelling || isClaiming}
          onClick={e => {
            e.stopPropagation();
            handleCancelWithdrawal();
          }}
          border="shadesBackground500"
          background="transparent"
        >
          {isCancelling ? t('cancelingDot') : t('cancel')}
        </Button>
      ) : null}

      <Button
        colorVariant="primaryGreen"
        sizeVariant="S"
        disabled={shouldDisableClaimButton || isClaiming || isCancelling}
        onClick={e => {
          e.stopPropagation();
          handleClaimWithdrawal();
        }}
      >
        {claimButtonText}
      </Button>
    </Container>
  );
};

export default observer(CancelAndClaimCell);
