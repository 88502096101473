import { memo, useRef, useState } from 'react';

import { timestampToLocalDate } from 'utils';

import { useOnClickOutside } from 'hooks';

import StyledDateRangePicker, {
  StyledDateRangePickerProps,
  UnixDate,
} from 'components/DateRangePicker';

import { LabelWithRadio } from '../OptionRenderers';
import { DateFilterRangeType } from './range';
import hourGlassIcon from 'assets/icons/hourglass-white.svg';

import FilterSelector from '..';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;

  .dropdown-content {
    position: absolute;
    top: 60px;
    z-index: 5;
  }
`;

export type DateFilterRangeWithType = {
  type: DateFilterRangeType;
  range: StyledDateRangePickerProps['selectedRange'];
};

const constructRangeFromStaticTypes = (
  type: Exclude<DateFilterRangeType, 'custom'>,
): [UnixDate, UnixDate] => {
  let configStartDate = {
    [DateFilterRangeType.lastSevenDays]:
      Date.now() * 1000 - 7 * 24 * 60 * 60 * 1000 * 1000,
    [DateFilterRangeType.lastThirtyDays]:
      Date.now() * 1000 - 30 * 24 * 60 * 60 * 1000 * 1000,
    [DateFilterRangeType.lastNinetyDays]:
      Date.now() * 1000 - 90 * 24 * 60 * 60 * 1000 * 1000,
  };
  return [configStartDate[type], Date.now() * 1000];
};

type Props = {
  onRangeSelect: (filterRange: DateFilterRangeWithType | undefined) => void;
  selectedRange: DateFilterRangeWithType | undefined;
};
const DateRangeFilterSelector = ({ onRangeSelect, selectedRange }: Props) => {
  const [isPickerShown, setPickerShown] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, () => setPickerShown(false));

  const handleOptionSelect = (option: DateFilterRangeType) => {
    if (option === DateFilterRangeType.custom) {
      setPickerShown(true);
      return;
    }

    // if already selected, then pass undefined to remove currently applied date filters
    if (selectedRange?.type === option) {
      onRangeSelect(undefined);
      return;
    }

    onRangeSelect({
      type: option,
      range: constructRangeFromStaticTypes(option),
    });
  };

  const getOptionLabel = (option: DateFilterRangeType) => {
    if (option === DateFilterRangeType.lastSevenDays) return 'Last 7 Days';
    if (option === DateFilterRangeType.lastThirtyDays) return 'Last 30 Days';
    if (option === DateFilterRangeType.lastNinetyDays) return 'Last 90 Days';
    if (option === DateFilterRangeType.custom) {
      if (
        selectedRange?.type === DateFilterRangeType.custom &&
        selectedRange.range &&
        selectedRange.range[0] &&
        selectedRange.range[1]
      )
        return (
          timestampToLocalDate(selectedRange?.range?.[0]) +
          ' - ' +
          timestampToLocalDate(selectedRange?.range?.[1])
        );

      return 'Custom';
    }
    return '--';
  };

  return (
    <Container ref={containerRef}>
      <FilterSelector
        renderOption={option => (
          <LabelWithRadio
            selected={selectedRange?.type === option}
            label={getOptionLabel(option)}
          />
        )}
        options={[
          DateFilterRangeType.lastSevenDays,
          DateFilterRangeType.lastThirtyDays,
          DateFilterRangeType.lastNinetyDays,
          DateFilterRangeType.custom,
        ]}
        onDropdownShownChange={shown => {
          shown && setPickerShown(false);
        }}
        onOptionSelect={handleOptionSelect}
        icon={hourGlassIcon}
        label={'Date Range'}
        value={selectedRange ? getOptionLabel(selectedRange.type) : '- -'}
      />

      {isPickerShown ? (
        <StyledDateRangePicker
          containerClass="dropdown-content"
          onRangeSelect={range => {
            if (!range) {
              onRangeSelect(undefined);
            } else {
              onRangeSelect({ range, type: DateFilterRangeType.custom });
            }
            setPickerShown(false);
          }}
          selectedRange={
            selectedRange?.type === DateFilterRangeType.custom
              ? selectedRange.range
              : undefined
          }
        />
      ) : null}
    </Container>
  );
};

export default memo(DateRangeFilterSelector);
