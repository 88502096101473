import Loading from 'components/Loading';

import TradeRow from './TradeRow';
import { NoResultsAndLoaderContainer } from './style';
import { useAppContext } from 'contexts/AppContext';
import { Column } from 'theme/globalStyledComponents/column';

import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

const Container = styled(Column)`
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  min-height: 200px;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    min-height: auto;
  }
`;

const TradesList = () => {
  const {
    store: {
      trades: { trades, isLoading },
      appState: { defaultCurrencyKind },
      markets: { selectedMarketId },
    },
  } = useAppContext();

  const noResults =
    isLoading || !trades || !selectedMarketId || trades.length === 0;

  if (noResults) {
    return (
      <NoResultsAndLoaderContainer className="mt-20">
        {trades?.length === 0 ? 'No Results' : null}
        {isLoading ? <Loading /> : null}
      </NoResultsAndLoaderContainer>
    );
  }

  return (
    <Container justify="flex-start">
      {trades.map((item, index) => (
        <TradeRow
          index={index}
          trade={item}
          key={`${item.id}`}
          currency={defaultCurrencyKind}
          marketID={selectedMarketId}
        />
      ))}
    </Container>
  );
};

export default observer(TradesList);
