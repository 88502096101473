import { useState } from 'react';

import {
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { timestampToLocalDateWithTime } from 'utils';

import { Pagination } from 'hooks';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { FormattedNumber } from 'components';
import TableLoader from 'components/TableLoader';
import { StyledTable } from 'components/Tables/styles';

import { TableContainer } from '../styles';
import FundingRatesTableHead from './FundingRatesTableHead';
import { useAppContext } from 'contexts/AppContext';
import { MarketService } from 'service/marketService';

import { QueryKeys } from 'enums';
import { FundingRate } from 'interfaces';
import { observer } from 'mobx-react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

const marketService = MarketService.get();

const FundingRatesTable = () => {
  const {
    store: {
      markets: { selectedMarketId },
    },
  } = useAppContext();

  const { width } = useWindowDimensions();
  const theme = useTheme();
  const isLargerThanDefault = width >= (theme as any).screenSizes.default;

  const [pagination, setPagination] = useState<Pagination>({
    page: 0,
    limit: isLargerThanDefault ? 10 : 6,
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPagination({ ...pagination, limit: +event.target.value, page: 0 });
  };

  const {
    isLoading: isLoadingFundingRates,
    data,
    isError,
  } = useQuery(
    [QueryKeys.FundingRates, selectedMarketId, pagination],
    async () => {
      const queryParams = {
        p_page: pagination.page,
        p_limit: pagination.limit,
        market_id: selectedMarketId,
      };
      return await marketService.fetchFundingRatesForMarket(queryParams);
    },
    {
      refetchInterval: 30_000,
      enabled: !!selectedMarketId,
    },
  );

  if (!data || isLoadingFundingRates || isError) {
    return (
      <StyledTable>
        <FundingRatesTableHead />
        {isLoadingFundingRates && <TableLoader />}
        {isError && (
          <TableRow>
            <TableCell colSpan={100} className="no-items">
              Something went wrong.
            </TableCell>
          </TableRow>
        )}
      </StyledTable>
    );
  }

  const { pagination: responsePagination, data: fundingRates } = data;
  const { page, limit: rowsPerPage } = pagination;

  return (
    <TableContainer>
      <StyledTable stickyHeader>
        <FundingRatesTableHead />
        <TableBody>
          {fundingRates.map((fundingRate: FundingRate) => (
            <TableRow key={fundingRate.timestamp}>
              <TableCell key={0}>
                {timestampToLocalDateWithTime(fundingRate.timestamp)}
              </TableCell>
              <TableCell key={1}>{'1h'}</TableCell>
              <TableCell key={2} align="right">
                <FormattedNumber
                  value={fundingRate.fundingRate * 100}
                  suffix="%"
                  decimalScale={6}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      <TablePagination
        rowsPerPageOptions={[6, 10, 25]}
        component="div"
        count={responsePagination?.total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default observer(FundingRatesTable);
