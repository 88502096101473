/**
 * Sends a google analytics event
 * @param category Event category (e.g. Button, SelectDropdown,...)
 * @param action Event action (e.g. Click, Select,...)
 * @param label Event label
 */
export const sendGaEvent = (category: string, action: string, label = '') => {
  // @TODO: no GA yet
  // ReactGA.event({
  //   category,
  //   label,
  //   action: `${action}: ${label}`,
  // });
};
