import { memo } from 'react';

import { ProgressContainer } from './styles';
import { theme } from 'theme/Theme';

type ProgressContainerProps = {
  colorsInversed?: boolean;
  value: number;
  size: number;
  groupColorAt?: [number, number];
  strokeWidth?: number;
  backgroundColor?: string;
  progressColor?: string;
  children?: React.ReactNode;
  className?: string;
};

const getColors = (
  value: number,
  inversed?: boolean,
  groupColorAt = [20, 80],
) => {
  const highValueColors = {
    backgroundColor: theme.colors.positiveBackground100,
    progressColor: theme.colors.positiveForeground200,
  };

  const lowValueColors = {
    backgroundColor: theme.colors.negativeBackground100,
    progressColor: theme.colors.negativeForeground200,
  };

  if (value > groupColorAt[1]) {
    return inversed ? lowValueColors : highValueColors;
  }

  if (value > groupColorAt[0]) {
    return {
      backgroundColor: theme.colors.warningBackground300,
      progressColor: theme.colors.warningForeground300,
    };
  }

  return inversed ? highValueColors : lowValueColors;
};

/**
 * Props for the ProgressContainer component.
 *
 * @typedef {Object} ProgressContainerProps
 * @property {boolean} [colorsInversed=false] - Flag indicating whether colors should be inversed.
 * @property {number} value - The progress value. Should be between 0 and 1.
 * @property {number} size - The size of the progress container.
 * @property {Array<number>} groupColorAt - Array used to determine color groups. Eg: [50,70] , the colors groups will be constructed for 0-50,50-70,70-100
 * @property {number} [strokeWidth] - The width of the progress stroke. Defaults to a reasonable value.
 */

const CircularProgress = ({
  size,
  strokeWidth = size * 0.1,
  colorsInversed = false,
  groupColorAt,
  value,
  progressColor,
  backgroundColor,
  children,
  className,
}: ProgressContainerProps) => {
  const radius = (size - strokeWidth) / 2;
  const strokeDaskArray = 2 * 3.14 * radius;

  const {
    backgroundColor: calculatedBackgroundColor,
    progressColor: calculatedProgressColor,
  } = getColors(value, colorsInversed, groupColorAt);

  const strokeDashOffset = strokeDaskArray * (1 - value / 100);

  return (
    <ProgressContainer size={size} className={className}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <circle
          r={radius}
          cx={size / 2}
          cy={size / 2}
          fill="transparent"
          stroke={backgroundColor || calculatedBackgroundColor}
          stroke-width={`${strokeWidth}px`}
        ></circle>
        <circle
          r={radius}
          cx={size / 2}
          cy={size / 2}
          fill="transparent"
          stroke={progressColor || calculatedProgressColor}
          stroke-linecap="round"
          stroke-width={`${strokeWidth}px`}
          stroke-dasharray={strokeDaskArray}
          stroke-dashoffset={strokeDashOffset}
        ></circle>
      </svg>
      <div className="children">{children}</div>
    </ProgressContainer>
  );
};

export default memo(CircularProgress);
