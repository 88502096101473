import { memo, useState } from 'react';

import { saveUserSetting } from 'utils';

import { Icon } from 'components';
import Text from 'components/Text';

import xBlackIcon from 'assets/icons/close-x-dark.svg';
import infoWarningIcon from 'assets/icons/info-circle-warning.svg';
import { Row } from 'theme/globalStyledComponents';

import { UserSettings } from 'enums';
import styled from 'styled-components';

const Container = styled(Row)`
  padding: 10px;
  border-radius: 8px;
  gap: 10px;
  justify-content: center;
  background: ${({ theme }) => theme.colors.warningBackground400};

  position: relative;
  margin-top: 5px;

  .close-icon {
    border-radius: 100px;
    border: 2px solid ${({ theme }) => theme.colors.shadesBackground900};
    background: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: -5px;
    right: 0;
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

type Props = {
  children: React.ReactNode;
  closeFlagSettingKey: UserSettings;
  className?: string;
};
const Banner = ({ children, closeFlagSettingKey, className }: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const onDismiss = () => {
    setIsOpen(false);
    saveUserSetting(closeFlagSettingKey, true);
  };

  if (!isOpen) return null;

  return (
    <Container className={className}>
      <Icon src={infoWarningIcon} size="L" />
      <Text flexed variant="BODY_S" color="warningForeground200">
        {children}
      </Text>
      <div className="close-icon" onClick={onDismiss}>
        <Icon src={xBlackIcon} size="S" />
      </div>
    </Container>
  );
};

export default memo(Banner);
