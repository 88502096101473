import { capitalizeFirstLetter } from 'utils';

import Icon from 'components/Icon';
import Text, { TextProps } from 'components/Text';

import PendingWithdrawal from './PendingWithdrawal';
import checkPositiveIcon from 'assets/icons/checkmark.svg';
import xGrayIcon from 'assets/icons/close-x.svg';
import hourGlassIcon from 'assets/icons/hourglass.svg';
import packageClaimableIcon from 'assets/icons/package-claimable.svg';
import pendingIcon from 'assets/icons/pending.svg';
import processingIcon from 'assets/icons/refresh.svg';

import { config } from 'config';
import { BalanceOpsType, DepositOrWithdrawalStatus } from 'enums';
import { useTranslation } from 'react-i18next';

export const StatusText = (props: TextProps) => (
  <Text
    flexed
    gap={2}
    variant="BODY_S"
    responsiveVariant={{ default: 'BODY_M' }}
    {...props}
  />
);

const TransferStatusCell = ({
  status,
  txhash,
  wallet,
  amount,
  timestamp,
  type,
}: {
  status: DepositOrWithdrawalStatus;
  txhash?: string;
  wallet?: string;
  amount?: string;
  timestamp?: number;
  type?: BalanceOpsType;
}) => {
  const { t } = useTranslation();

  switch (status) {
    case DepositOrWithdrawalStatus.Pending: {
      if (type === BalanceOpsType.Withdrawal) {
        return (
          <PendingWithdrawal
            amount={Number(amount)}
            timestamp={timestamp as number}
          />
        );
      }
      return (
        <StatusText>
          <Icon size="S" src={pendingIcon} alt="Pending" />
          {t('pending')}
        </StatusText>
      );
    }
    case DepositOrWithdrawalStatus.Claimable:
      return (
        <StatusText>
          <Icon size="S" src={packageClaimableIcon} alt="Claimable" />
          {t('claimable')}
        </StatusText>
      );
    case DepositOrWithdrawalStatus.Canceled:
      return (
        <StatusText color="shadesForeground200">
          <Icon size="S" src={xGrayIcon} alt="Canceled" />
          {t('canceled')}
        </StatusText>
      );
    case DepositOrWithdrawalStatus.Claiming:
      // const claimingEtherscanUrl = txhash
      //   ? `${config.etherscanUrl}/tx/${txhash}`
      //   : `${config.etherscanUrl}/address/${config.RabbitContractAddress}?fromaddress=${wallet}`;
      return (
        <StatusText>
          <Icon size="S" src={hourGlassIcon} alt="Claiming" />
          {t('claiming')}
          {/* <a href={claimingEhercanUrl} target="_blank" rel="noreferrer">
              <img src={shortcutIcon} alt="Shortcut" />
            </a> */}
        </StatusText>
      );
    case DepositOrWithdrawalStatus.Processing: {
      // If txhash is not available, just show the transactions list for this wallet
      const etherscanUrl = txhash
        ? `${config.etherscanUrl}/tx/${txhash}`
        : `${config.etherscanUrl}/address/${config.RabbitContractAddress}?fromaddress=${wallet}`;
      return (
        <StatusText>
          <Icon size="S" src={processingIcon} alt="Processing" />
          {t('processing')}
          {/* <a href={etherscanUrl} target="_blank" rel="noreferrer">
            <Icon size="S" src={shortcutIcon} alt="Shortcut" />
          </a> */}
        </StatusText>
      );
    }
    case DepositOrWithdrawalStatus.Success: {
      // If txhash is not available, just show the transactions list for this wallet
      const etherscanUrl = txhash
        ? `${config.etherscanUrl}/tx/${txhash}`
        : `${config.etherscanUrl}/address/${config.RabbitContractAddress}?fromaddress=${wallet}`;
      return (
        <StatusText color="primaryGreenForeground100">
          <Icon size="S" src={checkPositiveIcon} alt="Check" />
          {t('success')}

          {/* <a href={etherscanUrl} target="_blank" rel="noreferrer">
            <Icon size="S" src={shortcutIcon} alt="Shortcut" />
          </a> */}
        </StatusText>
      );
    }
    // From v1
    case DepositOrWithdrawalStatus.Transferring:
      return (
        <StatusText>
          <Icon size="S" src={processingIcon} alt="Transferring" />
          {t('transferring')}
        </StatusText>
      );
    case DepositOrWithdrawalStatus.Failed:
      return (
        <StatusText color="shadesForeground200">{t('rejected')}</StatusText>
      );
    default:
      return <StatusText>{capitalizeFirstLetter(status)}</StatusText>;
  }
};

export default TransferStatusCell;
