import { useEffect, useState } from 'react';

import { brand, brandedSelect } from 'utils/brand';

import Button from 'components/Button/button';

import AirdropSideBarActions from './AirdropSideBarActions';
import { getPortfolioMenuItems } from './navItems';
import {
  Container,
  ListStyled,
  NavItemContainer,
  NavLinkStyled,
  ListSeperator,
} from './styles';
import claimAirdropIcon from 'assets/icons/portfolio-nav-items/claim-airdrop.svg';
import { routes } from 'pages/routes';

import { ComponentsName } from 'enums';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const isActiveLink = (
  pathname: string,
  itemTo: string,
  componentName: ComponentsName,
) => {
  const lowerCasePathname = pathname.toLowerCase();
  const lowerCaseItemTo = itemTo.toLowerCase();

  if (componentName === ComponentsName.PortfolioOverview) {
    if (
      ['portfolio', '/portfolio', '/portfolio/'].some(
        rootPath => lowerCasePathname === rootPath,
      )
    ) {
      return true;
    }
  }

  return lowerCasePathname.includes(lowerCaseItemTo);
};

const Sidebar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isFixed, setIsFixed] = useState(false);

  const goToClaimAirdropPage = () => {
    navigate(routes.portfolio.airdrops.getRedirectPath());
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const menuItems = getPortfolioMenuItems(t);

  return (
    <Container>
      <ListStyled isFixed={isFixed}>
        {menuItems.map((item, i) => (
          <li key={i}>
            <NavLinkStyled
              to={item.to}
              isactivelink={isActiveLink(
                pathname,
                item.to,
                item.componentName,
              ).toString()}
            >
              <div className="icon-container">
                <img
                  src={
                    isActiveLink(pathname, item.to, item.componentName)
                      ? item.icon.active
                      : item.icon.default
                  }
                  alt={item.text}
                />
              </div>
              <span className="menu-text">{item.text}</span>
            </NavLinkStyled>
          </li>
        ))}

        <AirdropSideBarActions pathname={pathname} />
      </ListStyled>
    </Container>
  );
};

export default Sidebar;

// {brand.featureFlags.airdrops.default && (
//   <>
//     <ListSeperator />

//     <NavItemContainer>
//       <Button
//         className="container"
//         colorVariant="tertiary"
//         sizeVariant="S"
//         rightIcon={claimAirdropIcon}
//         onClick={goToClaimAirdropPage}
//       >
//         <span className="menu-label">
//           {t('menu.portfolioAirdrops')}
//         </span>
//       </Button>
//       {/* <div className="menu-text">{t('menu.portfolioAirdrops')}</div> */}
//     </NavItemContainer>
//   </>
// )}
