import { memo, useState } from 'react';

import { useAirdropsAPI } from 'hooks';

import Loading from 'components/Loading';

import DropdownToggle from '../../shared/DropdownToggle';
import { Content } from '../styles';
import ClaimBox from './ClaimBox';
import ProgressBox from './ProgressBox';
import { useAppContext } from 'contexts/AppContext';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { QueryKeys } from 'enums';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';

export const AIRDROP_PHASE_ONE_TITLE = 'phase1';

const Container = styled.div`
  transition: opacity 0.2s ease-out, max-height 0.5s ease-out;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
`;

const ClaimRewards = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { fetchAllAirdropsForUser } = useAirdropsAPI();
  const { t } = useTranslation();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const {
    isLoading,
    data: profileAirdrops,
    isError,
  } = useQuery(
    [QueryKeys.Airdrops, frontendSecrets?.profile.wallet],
    async () => {
      try {
        const res = await fetchAllAirdropsForUser();

        return res.data;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    {
      refetchInterval: 15_000,
      staleTime: 10_000,
      enabled: !!frontendSecrets?.profile.wallet,
    },
  );

  if (isError) {
    return (
      <Container>
        <ColoredText color={Colors.Danger} className="mt-10">
          {t('somethingWentWrong')}
        </ColoredText>
      </Container>
    );
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  const profileAirdrop =
    profileAirdrops &&
    profileAirdrops.find(
      airdrop => airdrop.airdropTitle === AIRDROP_PHASE_ONE_TITLE,
    );

  if (profileAirdrop === undefined) {
    return (
      <Container>
        <ColoredText color={Colors.Danger} className="mt-10">
          {t('noAirdropsFoundForUser')}
        </ColoredText>
      </Container>
    );
  }

  return (
    <Container>
      <DropdownToggle
        isExpanded={isExpanded}
        label="Claim Rewards"
        handleClick={() => setIsExpanded(prevExpanded => !prevExpanded)}
        enableToggle={true}
      />
      <Content isExpanded={isExpanded}>
        <p className="mb-20">
          {profileAirdrop.status === 'finished'
            ? t('claimedYourRewards')
            : t('onceClaimingLive')}
        </p>
        <ClaimBox profileAirdrop={profileAirdrop} />
        <ProgressBox profileAirdrop={profileAirdrop} />
      </Content>
    </Container>
  );
};

export default memo(ClaimRewards);
