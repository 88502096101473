import { memo } from 'react';

import TransfersTable from 'components/Tables/TransfersTable';
import ClaimWithdrawableBalanceButton from 'components/Tables/TransfersTable/WithdrawableBalance/ClaimWithdrawableBalanceButton';
import { useWithdrawableBalance } from 'components/Tables/TransfersTable/WithdrawableBalance/useWithdrawableBalance';
import { TablePageContainer } from 'components/Tables/styles';

import styled from 'styled-components';

const TablePageContainerStyled = styled(TablePageContainer)`
  margin: 0;
`;

const TradingPageTransfers = ({
  isRowCollapsible,
}: {
  isRowCollapsible: boolean;
}) => {
  const {
    withdrawableBalance,
    setWithdrawableBalance,
    updateWithdrawableBalance,
    isFetchingWithdrawableBalance,
  } = useWithdrawableBalance();

  return (
    <TablePageContainerStyled>
      <ClaimWithdrawableBalanceButton
        withdrawableBalance={withdrawableBalance}
        setWithdrawableBalance={setWithdrawableBalance}
        updateWithdrawableBalance={updateWithdrawableBalance}
      />

      <TransfersTable
        isFetchingWithdrawableBalance={isFetchingWithdrawableBalance}
        includePagination={false}
        showLabelOnTableCell={true}
        showTableHead={false}
        isRowCollapsible={isRowCollapsible}
      />
    </TablePageContainerStyled>
  );
};

export default memo(TradingPageTransfers);
