import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Text from 'components/Text';

import { Container } from './styles';
import checkIcon from 'assets/icons/check-dark.svg';
import chevronDown from 'assets/icons/chevron-down-gray.svg';
import chevronUp from 'assets/icons/chevron-up-gray.svg';
import { Row } from 'theme/globalStyledComponents/row';

import { useTranslation } from 'react-i18next';

type StepTitleAndActionsProps = {
  isCollapsed?: boolean;
  icon: string;
  title: string;
  description: string;
  ctaLabel: string;
  onCtaPress: () => void;
  completed: boolean;
  onSkipStep: () => void;
  showSkip: boolean;
  isCtaLoading: boolean;
  onClick: () => void;
};

const StepTitleAndActions = ({
  isCollapsed = false,
  title,
  icon,
  description,
  ctaLabel,
  onCtaPress,
  completed,
  onSkipStep,
  showSkip,
  isCtaLoading,
  onClick,
}: StepTitleAndActionsProps) => {
  const { t } = useTranslation();
  return (
    <Container
      onClick={onClick}
      isCollapsed={isCollapsed}
      completed={completed}
    >
      <Row gap={10} className="w-full">
        {completed ? (
          <img className="icon-container" src={checkIcon} alt="checked" />
        ) : (
          <Icon src={icon} size="ML" />
        )}
        <Text
          variant="BODY_M"
          flexed
          className={completed ? 'strike flex-1' : 'flex-1'}
          fontWeight="semiBold"
        >
          {title}
        </Text>
        <Icon
          src={isCollapsed ? chevronDown : chevronUp}
          className="drop-icon"
        />
      </Row>
      {!isCollapsed ? (
        <>
          <Text variant="BODY_S" className={completed ? 'strike' : ''}>
            {description}
          </Text>

          <Row gap={8}>
            <Text
              variant="BUTTON_S"
              fontWeight="semiBold"
              className="button"
              onClick={e => {
                if (completed) return;
                e.stopPropagation();
                onCtaPress();
              }}
            >
              {isCtaLoading ? <Loading size="13.5px" /> : ctaLabel}
            </Text>

            {showSkip && !completed ? (
              <Text
                variant="BODY_S"
                color="shadesForeground200"
                hoveredColor="white"
                onClick={e => {
                  e.stopPropagation();
                  if (!isCtaLoading) onSkipStep();
                }}
              >
                {t('skip')}
              </Text>
            ) : null}
          </Row>
        </>
      ) : null}
    </Container>
  );
};

export default StepTitleAndActions;
