export const MIXPANEL_CONNECT_WALLET_NAVIGATE = 'AF_CONNECT_WALLET_NAVIGATE';
export const MIXPANEL_CONNECT_WALLET_CLICKED = 'AF_CONNECT_WALLET_CLICKED';
export const MIXPANEL_CONNECT_WALLET_SUCCESS = 'AF_CONNECT_WALLET_SUCCESS';
export const MIXPANEL_DEPOSIT_NAVIGATE = 'AF_DEPOSIT_NAVIGATE';
export const MIXPANEL_DEPOSIT_CLICKED = 'AF_DEPOSIT_CLICKED';
export const MIXPANEL_DEPOSIT_SUCCESS = 'AF_DEPOSIT_SUCCESS';
export const MIXPANEL_WITHDRAW_NAVIGATE = 'AF_WITHDRAW_NAVIGATE';
export const MIXPANEL_WITHDRAW_CLICKED = 'AF_WITHDRAW_CLICKED';
export const MIXPANEL_WITHDRAW_SUCCESS = 'AF_WITHDRAW_SUCCESS';
export const MIXPANEL_OPEN_ORDER_CLICKED = 'AF_OPEN_ORDER_CLICKED';
export const MIXPANEL_OPEN_ORDER_SUCCESS = 'AF_OPEN_ORDER_SUCCESS';
export const MIXPANEL_CANCEL_WITHDRAW_CLICKED = 'AF_CANCEL_WITHDRAW_CLICKED';
export const MIXPANEL_CANCEL_WITHDRAW_SUCCESS = 'AF_CANCEL_WITHDRAW_SUCCESS';
export const MIXPANEL_CLAIM_WITHDRAW_CLICKED = 'AF_CLAIM_WITHDRAW_CLICKED';
export const MIXPANEL_CLAIM_WITHDRAW_SUCCESS = 'AF_CLAIM_WITHDRAW_SUCCESS';
export const MIXPANEL_CLOSE_POSITION_SUCCESS = 'AF_CLOSE_POSITION_SUCCESS';
export const MIXPANEL_CLOSE_POSITIONS_ALL_SUCCESS =
  'AF_CLOSE_POSITIONS_ALL_SUCCESS';
export const MIXPANEL_CANCEL_ORDER_SUCCESS = 'AF_CANCEL_ORDER_SUCCESS';
export const MIXPANEL_CANCEL_ORDERS_ALL_SUCCESS =
  'AF_CANCEL_ORDERS_ALL_SUCCESS';
export const MIXPANEL_LOG_OUT_SUCCESS = 'AF_LOG_OUT_SUCCESS';
export const MIXPANEL_ELIXIR_VAULTS_NAVIGATE = 'AF_ELIXIR_VAULTS_NAVIGATE';
export const MIXPANEL_ELIXIR_VAULTS_CLICKED = 'AF_ELIXIR_VAULTS_CLICKED';
export const MIXPANEL_ELIXIR_VAULTS_SUCCESS = 'AF_ELIXIR_VAULTS_SUCCESS';
export const MIXPANEL_STAKE_LOCK_RBX_NAVIGATE = 'AF_STAKE_LOCK_RBX_NAVIGATE';
export const MIXPANEL_STAKE_LOCK_RBX_CLICKED = 'AF_STAKE_LOCK_RBX_CLICKED';
export const MIXPANEL_STAKE_LOCK_RBX_SUCCESS = 'AF_STAKE_LOCK_RBX_SUCCESS';
export const MIXPANEL_STAKE_LOCK_LP_NAVIGATE = 'AF_STAKE_LOCK_LP_NAVIGATE';
export const MIXPANEL_STAKE_LOCK_LP_CLICKED = 'AF_STAKE_LOCK_LP_CLICKED';
export const MIXPANEL_STAKE_LOCK_LP_SUCCESS = 'AF_STAKE_LOCK_LP_SUCCESS';
