import HistoricalPrice from './views/HistoricalPrice';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import styled from 'styled-components';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 400px;
  grid-area: tradingview;
  #tv_chart_container,
  iframe {
    min-height: 400px;
  }
  iframe {
    z-index: -1;
  }
  height: 100%;
`;

const TradingChart = () => {
  const {
    store: {
      account: { accountStats },
      markets: { markets, selectedMarketId },
      tvConfig: { config },
    },
  } = useAppContext();

  if (!selectedMarketId || !markets) return null;

  return (
    <BodyContainer>
      {selectedMarketId ? (
        <HistoricalPrice accountStats={accountStats} config={config} />
      ) : null}
    </BodyContainer>
  );
};

export default observer(TradingChart);
