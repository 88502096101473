import { useSwitchMarket } from 'hooks/useSwitchMarket';

import { ResolutionString } from '../../components/TVChartContainer/charting_library';
import HistoricalPrice from '../Trade/components/TradingChart/views/HistoricalPrice';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const BodyContainer = styled.div`
  height: 100vh;
`;

export const TVMobile = observer(() => {
  const {
    store: {
      account: { accountStats },
      markets: { markets, selectedMarketId },
      tvConfig,
    },
  } = useAppContext();
  const { switchMarketById } = useSwitchMarket();

  const [searchParams] = useSearchParams();
  const marketId = searchParams.get('id');

  if (marketId) {
    switchMarketById(marketId);
  }

  const resolution = searchParams.get('resolution');
  if (resolution) {
    tvConfig.setResolution(resolution as ResolutionString);
  }

  const backgroundColor = searchParams.get('backgroundColor');
  if (backgroundColor) {
    tvConfig.setMobileBackgroundColor(`#${backgroundColor}`);
  }

  if (!selectedMarketId || !markets) return null;

  document.body.style.backgroundColor = backgroundColor
    ? `#${backgroundColor}`
    : tvConfig.config.mobileBackgroundColor;
  return (
    <BodyContainer>
      {selectedMarketId ? (
        <HistoricalPrice
          accountStats={accountStats}
          config={tvConfig.config}
          isMobile={true}
        />
      ) : null}
    </BodyContainer>
  );
});
