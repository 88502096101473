import styled, { css } from 'styled-components';

type ContainerProps = { iconBorder?: 'half' | 'full'; showMoreIcon: boolean };
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 1 0 0;
  cursor: pointer;
  padding: 15px;
  border-radius: 12px;
  align-items: center;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.shadesForeground200};
  transition: background 200ms;

  font-size: 14px;
  line-height: 16px;

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    font-size: 16px;
    line-height: 24px;
  }

  .description {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 12px;
    line-height: normal;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
  }

  .spread-icons {
    display: flex;
  }

  .spread-icons > :first-child {
    margin-left: 0;
  }

  .spread-icons > :not(:first-child) {
    margin-left: -7px;
  }

  ${({ showMoreIcon }) =>
    showMoreIcon &&
    css`
      .spread-icons > :last-child {
        background: ${({ theme }) => theme.colors.shadesBackground500};
      }
    `}

  .spreaded-icon {
    display: flex;
    padding: 4px;
    height: 12px;
    width: 12px;
    border-radius: ${({ iconBorder }) =>
      iconBorder === 'half' ? '6px' : '100px'};
    border-width: 2px;
    border-style: solid;
    background: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.shadesBackground700};
    transition: border 200ms;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      height: 16px;
      width: 16px;
    }
  }

  .row {
    display: flex;
    align-items: center;

    > img {
      display: flex;
      padding: 4px;
      height: 16px;
      width: 16px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: ${({ theme }) => theme.colors.white};

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        height: 20px;
        width: 20px;
      }
    }

    .wallet-connect-background {
      background: #2280fc;
    }
  }

  .arrow-right {
    height: 20px;
    width: 20px;
    opacity: 0;
    margin-right: 4px;
    transition: opacity 200ms, margin-right 200ms;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      height: 24px;
      width: 24px;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.shadesBackground600};

    .arrow-right {
      opacity: 1;
      margin-right: 0px;
    }

    .spreaded-icon {
      border-color: ${({ theme }) => theme.colors.shadesBackground600};
    }
  }
`;
