import { memo, useState } from 'react';

import { usePortfolioAPI } from 'hooks/usePortfolioAPI';

import { FormattedNumber, Icon } from 'components';
import Loading from 'components/Loading';
import RbxAreaChart from 'components/RbxAreaChart';
import Text from 'components/Text';

import Timescale from './Timescale';
import { Container, Header } from './styles';
import arrowDown from 'assets/icons/arrow-down-new.svg';
import arrowUp from 'assets/icons/arrow-up-new.svg';
import { useAppContext } from 'contexts/AppContext';
import { ColoredText, Colors } from 'pages/Trade/components/shared';
import { Column } from 'theme/globalStyledComponents/column';

import { QueryKeys, TimescaleType } from 'enums';
import { PortfolioHistoricalStats } from 'interfaces';
import { useQuery } from 'react-query';

export const getChangeDirection = (
  data: PortfolioHistoricalStats[] | undefined,
): 'positive' | 'negative' => {
  if (!data || data.length < 2) return 'positive';

  const startValue = data[0].value;
  const endValue = data[data.length - 1].value;

  if (startValue > endValue) return 'negative';
  return 'positive';
};

export const getChangeValue = (
  data: PortfolioHistoricalStats[] | undefined,
): number => {
  if (!data || data.length < 2) return 0;

  try {
    const startValue = data[0].value;
    const endValue = data[data.length - 1].value;

    const change = Math.abs(endValue - startValue);
    return isNaN(change) ? 0 : change;
  } catch {
    return 0;
  }
};

export const getChangePercentage = (
  data: PortfolioHistoricalStats[] | undefined,
): number => {
  if (!data || data.length < 2) return 0;

  try {
    const changeValue = getChangeValue(data);
    const startValue = data[0].value;

    const percentage = Math.abs((changeValue / startValue) * 100);
    return isNaN(percentage) ? 0 : percentage;
  } catch {
    return 0;
  }
};

type Props = {
  balance: number;
};
const PortfolioChart = ({ balance }: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const { fetchPortfolioStats } = usePortfolioAPI();

  const [selectedTimescale, setSelectedTimescale] = useState<TimescaleType>(
    TimescaleType.Month,
  );

  const {
    isLoading: isLoadingPortfolioChart,
    data: portfolioChart,
    isError,
  } = useQuery(
    [QueryKeys.Portfolio, selectedTimescale],
    async () => await fetchPortfolioStats(selectedTimescale),
    { enabled: !!frontendSecrets?.jwt, refetchInterval: 10_000 },
  );

  const isValueChangePositive =
    getChangeDirection(portfolioChart) === 'positive';
  const changeValue = getChangeValue(portfolioChart);
  const changePercentage = getChangePercentage(portfolioChart);

  return (
    <Container>
      <Header>
        <Column gap={5}>
          <FormattedNumber
            variant="NUMBERS_XXL"
            fontWeight="semiBold"
            value={balance}
            prefix="$"
            negativeToZero
          />

          {isLoadingPortfolioChart ? (
            <Loading size={20} />
          ) : (
            <>
              <Text
                variant="NUMBERS_L"
                color={
                  isValueChangePositive
                    ? 'primaryGreenForeground100'
                    : 'negativeForeground200'
                }
                flexed
              >
                <Icon
                  size="M"
                  src={isValueChangePositive ? arrowUp : arrowDown}
                  alt="Arrow price change"
                />
                <FormattedNumber
                  value={changeValue}
                  allowNegative={false}
                  prefix="$"
                />{' '}
                (
                <FormattedNumber
                  value={changePercentage}
                  isPercentage={true}
                  allowNegative={false}
                  suffix="%"
                />
                )
              </Text>
            </>
          )}
        </Column>
        <Timescale
          selectedTimescale={selectedTimescale}
          onChange={setSelectedTimescale}
        />
      </Header>
      {isError && (
        <div className="flex justify-center">
          <ColoredText color={Colors.Danger}>
            An error has occurred when trying to fetch chart data. Please try
            again later.
          </ColoredText>
        </div>
      )}
      {isLoadingPortfolioChart ? (
        <div className="flex justify-center">
          <Loading />
        </div>
      ) : (
        <div className="chart">
          <RbxAreaChart
            data={portfolioChart}
            isPositiveChange={isValueChangePositive}
            strokeWidth={3}
            minHeight={300}
            containsDates={true}
          />
        </div>
      )}
    </Container>
  );
};

export default memo(PortfolioChart);
