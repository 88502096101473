import { memo } from 'react';

import {
  TableRow,
  TableHead as TableHeadMui,
  TableCell,
} from '@material-ui/core';

import TextToolTip from 'components/InfoTooltip/TextToolTip';
import SortStatusDoubleCaret from 'components/Tables/components/SortStatusDoubleCaret';

import { Row } from '../../../../../../../styles';

import { SortBy, SortConfig } from '.';
import { useTranslation } from 'react-i18next';

type Props = {
  onSetSortBy: (sortBy: SortBy) => void;
  sortConfig: SortConfig | undefined;
};
const TableHead = ({ onSetSortBy, sortConfig }: Props) => {
  const { t } = useTranslation();
  const SortStatusIcon = ({ sortBy }: { sortBy: SortBy }) => (
    <SortStatusDoubleCaret
      isSorted={sortBy === sortConfig?.sortBy}
      numOfClicks={sortConfig?.numOfClick}
    />
  );

  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>
          <Row className="gap-5">{t('vaultName')}</Row>
        </TableCell>

        <TableCell key={1} onClick={() => onSetSortBy(SortBy.APY)}>
          <Row className="gap-5">
            <TextToolTip title={t('annualPercentageYield')}>
              {t('apy')}
            </TextToolTip>
            <SortStatusIcon sortBy={SortBy.APY} />
          </Row>
        </TableCell>

        <TableCell key={2} onClick={() => onSetSortBy(SortBy.TVL)}>
          <Row className="gap-5">
            <TextToolTip title={t('totalValueLocked')}>{t('tvl')}</TextToolTip>
            <SortStatusIcon sortBy={SortBy.TVL} />
          </Row>
        </TableCell>

        <TableCell key={4}>
          <Row className="gap-5">{t('manager')}</Row>
        </TableCell>

        <TableCell key={5}>
          <TextToolTip title={t('combinedSharesOffered')}>
            {t('shares')}
          </TextToolTip>
        </TableCell>
        <TableCell key={6} onClick={() => onSetSortBy(SortBy.PPS)}>
          <Row className="gap-5">
            {t('pricePerShare')}
            <SortStatusIcon sortBy={SortBy.PPS} />
          </Row>
        </TableCell>

        <TableCell key={7} align="right">
          {t('actions')}
        </TableCell>
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
