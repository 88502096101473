import TabSelector from 'components/TabSelector';
import { TextVariant } from 'components/Text/variant';

import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
`;

export const MarginSeparator = styled.div`
  width: 100%;
  height: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${TextVariant['BODY_M'].fontSize};
  line-height: ${TextVariant['BODY_M'].lineHeight};
`;

type BadgeProps = {
  type: 'danger' | 'success';
};
export const Badge = styled.span<BadgeProps>`
  padding: 4px;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 11px;
  line-height: 14px;
  color: ${({ theme, type }) =>
    type === 'danger'
      ? theme.colors.negativeForeground200
      : theme.colors.positiveForeground200};

  background: ${({ theme, type }) =>
    type === 'danger'
      ? theme.colors.negativeBackground100
      : theme.colors.positiveBackground100};

  border-radius: 4px;
  text-transform: uppercase;
  margin-left: 5px;
`;

export const ExpectedLossProfitRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 20px 0;
`;

export const StyledTabSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 6px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.shadesBackground700};

  .tabs-container {
    > span {
      padding: 8px 15px !important;
      border-radius: 6px !important;
    }
  }
`;
