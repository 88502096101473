import { useCallback } from 'react';

import { byteArrayToString, jsonToByteArray } from 'utils';

import { useExchangeAPI } from './useExchangeAPI';

import { Endpoints, RequestMethod } from 'enums';

export function useStorageAPI() {
  const { makePrivateRequest } = useExchangeAPI();

  const saveSettings = useCallback(
    async (settings: any): Promise<any> => {
      try {
        const params = JSON.stringify(settings);

        const byteArray = jsonToByteArray(params);

        const res = await makePrivateRequest({
          method: RequestMethod.POST,
          endpoint: `/${Endpoints.STORAGE}`,
          requestParams: {
            data: byteArray,
          },
          refreshJwt: false,
          shouldCheckJwtValidity: true,
        });
        return res;
      } catch (e: any) {
        throw e;
      }
    },
    [makePrivateRequest],
  );

  const fetchSettingsForUser = useCallback(async (): Promise<any> => {
    try {
      const res = await makePrivateRequest({
        method: RequestMethod.GET,
        endpoint: `/${Endpoints.STORAGE}`,
        requestParams: {},
        refreshJwt: false,
        shouldCheckJwtValidity: true,
      });

      if (res?.data) {
        const stringFromArray = byteArrayToString(res.data);
        const parsedString = JSON.parse(stringFromArray);
        const parsedStringToObject = JSON.parse(parsedString);
        return parsedStringToObject;
      }
      return {};
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  }, [makePrivateRequest]);

  return {
    fetchSettingsForUser,
    saveSettings,
  } as const;
}

export type StorageAPI = ReturnType<typeof useStorageAPI>;
