import styled from 'styled-components';

export const WalletItemContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0px 20px;

  .button {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    gap: 5px;
    padding: 10px 12px;

    font-size: 12px;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 14px;
      line-height: 16px;
    }

    > img {
      height: 12px;
      width: 12px;
    }
  }

  .delete-icon {
    display: flex;
    margin-right: -26px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    opacity: 0;
    transition: margin-right 0.3s, opacity 0.3s;
  }

  &:hover .delete-icon {
    margin-right: 0;
    opacity: 1;
  }
`;

type SectionProps = {
  isAuthenticated: boolean;
};
export const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2px;
  opacity: ${({ isAuthenticated }) => (isAuthenticated ? 1 : 0.3)};

  .text-primary {
    display: flex;
    align-items: center;
    gap: 3px;
    flex-grow: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 12px;
    line-height: 14px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 14px;
      line-height: 20px;
    }

    > img {
      height: 14px;
      width: 14px;
      cursor: pointer;
      box-sizing: border-box;
      padding: 1.5px;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        height: 16px;
        width: 16px;
      }
    }
  }

  .text-secondary {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 10px;
    line-height: normal;

    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: ${({ theme }) => theme.colors.shadesForeground200};

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      font-size: 12px;
    }

    > img {
      height: 12px;
      width: 12px;

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        height: 14px;
        width: 14px;
      }
    }
  }
`;
