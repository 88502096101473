import { Tooltip } from '@material-ui/core';

import { showNotification, textWithEllipsisInTheMiddle } from 'utils';

import { useExchangeAPI } from 'hooks/useExchangeAPI';
import useModal from 'hooks/useModal';

import Button from 'components/Button';
import Icon from 'components/Icon';

import EditWalletName from '../EditNameModal';
import WalletAvatarWithPicker from '../WalletAvatarWithPicker';
import { getBadgeIcon } from './helpers';
import { Section, WalletItemContainer } from './styles';
import copyIcon from 'assets/icons/copy-white.svg';
import editPenIcon from 'assets/icons/edit-pen-white.svg';
import trashIcon from 'assets/icons/trash.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { TSavedWallet } from 'enums/savedWallet';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';

import { ConnectorNames, NotificationType } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const getGTMIdSuffix = (connector: ConnectorNames, isVault) => {
  if (isVault) return 'vault';
  if (connector === ConnectorNames.Injected) return 'metamask';
  if (connector === ConnectorNames.SocialConnect) return 'social-connect';
  if (connector === ConnectorNames.WalletConnect) return 'wallet-connect';
  return '';
};

type WalletItemProps = {
  walletData: TSavedWallet;
  isThisAuthenticated: boolean;
  onDelete: (id: string, account: string) => void;
  onLogin: (wallet: TSavedWallet) => void;
  onUpdate: (
    input: Partial<Omit<TSavedWallet, 'address'>>,
    address: string,
  ) => void;
  onPickerVisibilityChange: (b: boolean) => void;
  isPickerOpen: boolean;
  twitterImage?: string;
};

const WalletItem = ({
  walletData,
  isThisAuthenticated,
  onDelete,
  onLogin,
  onPickerVisibilityChange,
  onUpdate,
  isPickerOpen,
  twitterImage,
}: WalletItemProps) => {
  const { id, emoji, image, name, address, connectorName, vaultAddress } =
    walletData;

  const modal = useModal();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();
  const { onboardUser } = useExchangeAPI();

  const pendingVerification = !frontendSecrets;

  const walletAddress = walletData.vaultAddress
    ? walletData.vaultAddress
    : address;

  const openEditModal = () => {
    modal.present(
      <EditWalletName
        onUpdate={newName => onUpdate({ name: newName }, id)}
        account={address}
        defaultValue={name}
        gtmId="edit-wallet-modal"
      />,
      Modals.editWalletNameModal,
    );
  };

  const onCopy = () => {
    navigator.clipboard
      .writeText(walletAddress)
      .then(() => {
        showNotification({
          type: NotificationType.Positive,
          title: 'Wallet Address Copied to Clipboard',
        });
      })
      .catch(error => {
        showNotification({
          type: NotificationType.Negative,
          title: 'Failed to Copy to Clipboard',
        });
      });
  };

  const onSignAndVerify = () => {
    onboardUser();
    modal.present(<WalletModal />, Modals.walletModal);
  };

  const badgeIcon = getBadgeIcon(walletData);

  return (
    <WalletItemContainer>
      <WalletAvatarWithPicker
        isAuthenticated={isThisAuthenticated}
        onPickerVisibilityChange={onPickerVisibilityChange}
        onUpdate={input => onUpdate(input, id)}
        image={image}
        emoji={emoji}
        isPickerOpenByDefault={isPickerOpen && isThisAuthenticated}
        twitterImage={twitterImage}
      />

      <Section isAuthenticated={isThisAuthenticated}>
        <span className="text-primary">
          {name}

          <Icon
            data-gtmid="icon-edit-wallet"
            size="S"
            onClick={openEditModal}
            src={editPenIcon}
            alt="edit"
            avoidPadding
          />
        </span>

        <span className="text-secondary">
          {textWithEllipsisInTheMiddle(walletAddress)}
          {walletData.vaultAddress ? (
            <Tooltip title={walletData.vaultAddress}>
              <img src={badgeIcon} alt="Vault" />
            </Tooltip>
          ) : badgeIcon ? (
            <img src={badgeIcon} alt="Badge" />
          ) : null}
        </span>
      </Section>

      {isThisAuthenticated ? (
        pendingVerification ? (
          <Button
            variant="secondaryDefault"
            className="button"
            onClick={onSignAndVerify}
            data-gtmid="button-sign"
          >
            {t('verify')}
          </Button>
        ) : (
          <Button
            variant="secondaryDefault"
            className="button"
            onClick={onCopy}
            data-gtmid="button-copy-address-2"
          >
            {t('copy')}
          </Button>
        )
      ) : (
        <Button
          variant="secondaryDefault"
          className="button"
          data-gtmid={`button-quick-login-${getGTMIdSuffix(
            connectorName,
            !!vaultAddress,
          )}`}
          onClick={() => onLogin(walletData)}
        >
          <span>{t('login')}</span>
        </Button>
      )}

      {!isThisAuthenticated ? (
        <img
          className="delete-icon"
          alt="delete"
          src={trashIcon}
          onClick={() => onDelete(id, address)}
        />
      ) : null}
    </WalletItemContainer>
  );
};

export default observer(WalletItem);
