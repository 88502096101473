import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;
  width: 100%;
  margin-top: 10px;
  background: ${({ theme }) => theme.colors.shadesBackground700};

  .input-container {
    display: flex;
    align-items: center;
    border-radius: inherit;
    align-self: stretch;
    background: ${({ theme }) => theme.colors.shadesBackground900};

    .container {
      padding: 6px 8px;
    }

    /* .column {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex-grow: 1;

      > span {
        font-size: 12px;
        line-height: normal;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        color: ${({ theme }) => theme.colors.shadesForeground200};
      }

      > input {
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        color: ${({ theme }) => theme.colors.shadesForeground200};
        background: transparent;
        font-size: 14px;
        line-height: 16px;
        outline: none;
        border: none;
      }
    } */

    > div {
      flex-grow: 1;
    }
  }

  .details-container {
    margin-bottom: 10px;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;

    > span {
      display: flex;
      align-items: center;
      gap: 3px;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.shadesForeground200};
      font-size: 12px;
      line-height: 14px;

      > img {
        height: 12px;
        width: 12px;
      }
    }
  }
`;

type ColoredTextProps = {
  isError: boolean;
};
export const ColoredText = styled.span<ColoredTextProps>`
  color: ${({ theme, isError }) =>
    isError ? theme.colors.negativeForeground200 : 'inherit'};
`;

export const TransakKycDescription = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.shadesForeground200};
  margin-top: 10px;
`;
