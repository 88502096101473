import { isBrandRabbitX } from 'utils/brand';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    display: block;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    width: 140px;
  }
`;

type ListStyledProps = {
  isFixed: boolean;
};
export const ListStyled = styled.ul<ListStyledProps>`
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 10px;
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'relative')};
  top: 0;
`;

interface NavLinkProps {
  isactivelink: string;
}
export const NavLinkStyled = styled(NavLink)<NavLinkProps>`
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-left: 3px;
  position: relative;
  margin-bottom: 10px;

  &:hover .icon-container {
    background: ${({ theme, isactivelink }) =>
      isactivelink === 'true'
        ? isBrandRabbitX
          ? theme.colors.shadesBackground600
          : theme.colors.main200
        : isBrandRabbitX
        ? theme.colors.shadesBackground700
        : theme.colors.main300};
  }

  &:hover .menu-text {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .icon-container {
    padding: 5px;
    background: ${({ isactivelink, theme }) =>
      isactivelink === 'true'
        ? isBrandRabbitX
          ? theme.colors.shadesBackground600
          : theme.colors.main200
        : 'transparent'};

    border-radius: 8px;
    display: flex;

    img {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenSizes.default - 1}px) {
    &:hover .menu-text {
      display: flex;
      color: white;
    }

    .menu-text {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      position: absolute;
      background: #283247;
      left: 45px;
      padding: 5px 10px;
      border-radius: 12px;
      white-space: nowrap;
      display: none;
      z-index: 100;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    margin-bottom: 10px;
    margin-left: 0px;

    .menu-text {
      margin-left: 10px;
      display: flex;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ isactivelink, theme }) =>
        isactivelink === 'true' ? '#FFFFFF !important' : '#636E85'};
    }
  }
`;

type NavItemContainerProps = {
  isActive?: boolean;
};
export const NavItemContainer = styled.div<NavItemContainerProps>`
  position: relative;
  display: flex;
  cursor: pointer;
  gap: 10px;
  align-items: center;

  .container {
    padding: 10px !important;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    margin-bottom: 10px;
    margin-left: 3px;
  }

  .menu-label {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    position: absolute;
    background: #283247;
    left: 43px;
    padding: 5px 10px;
    border-radius: 12px;
    white-space: nowrap;
    font-size: 13px;
    line-height: 26px;
    z-index: 3;
    display: none;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      display: flex;
      position: relative;
      padding: initial;
      background: initial;
      border-radius: initial;
      white-space: initial;
      z-index: initial;
      left: initial;
    }
  }

  .menu-text {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    position: absolute;
    background: #283247;
    left: 43px;
    padding: 5px 10px;
    border-radius: 12px;
    white-space: nowrap;
    font-size: 13px;
    line-height: 26px;
    z-index: 3;
    display: none;
  }

  :hover {
    .menu-text {
      display: flex;

      color: ${({ theme }) => theme.colors.white};

      @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
        color: ${({ theme }) => theme.colors.shadesForeground200};
      }
    }

    .menu-label {
      display: flex;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    margin-bottom: 10px;
    margin-left: 0px;

    .menu-text {
      left: 0px;
      position: relative;
      padding: 0;
      background: none;
      display: flex;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ isActive }) => (isActive ? '#FFFFFF !important' : '#636E85')};
    }
  }
`;

export const ListSeperator = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.colors.shadesBackground500};

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    display: flex;
    height: 2px;
    width: 100%;
    margin-bottom: 10px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    width: 20%;
    margin-left: 40%;
  }
`;
