import { memo, useState } from 'react';

import { brand } from 'utils/brand';

import { FormattedNumber, Icon } from 'components';
import { FormattedNumberProps } from 'components/FormattedNumber';
import IconPair from 'components/IconPair';
import InfoTooltip from 'components/InfoTooltip';
import Text from 'components/Text';

import blastLogo from 'assets/assetLogos/blast-green.svg';
import chevronDownIcon from 'assets/icons/chevronDown.svg';
import chevronUpIcon from 'assets/icons/chevronUp.svg';
import { Column, Row } from 'pages/Vaults/styles';

import { AmendType } from '..';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StatsContainer = styled(Column)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  border-radius: 8px;

  .stats-column {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 0px 10px 10px 10px;
  }
`;

type Props = {
  amendType: AmendType;
  unstakeAmount: number | null;
  pricePerShare?: number;
  totalIssuedShares?: number;
  vaultEquity?: number;
  vaultName?: string;
  performanceFee?: number;
};
const VaultStats = ({
  amendType,
  pricePerShare,
  unstakeAmount = 0,
  totalIssuedShares,
  vaultEquity,
  vaultName,
  performanceFee,
}: Props) => {
  const { t } = useTranslation();
  const [isDropDownShown, setDropDownShown] = useState(true);

  let stats: {
    label: string;
    value: FormattedNumberProps;
    tooltipText?: string;
  }[] = [];

  if (amendType === AmendType.Stake)
    stats = [
      {
        label: t('sharePrice'),
        value: { decimalScale: 4, value: pricePerShare, prefix: '$' }, // Decimal places always equal to 4 or ??
      },
      {
        label: t('tvl'),
        value: { value: vaultEquity, prefix: '$' },
        tooltipText: t('vaultsEquity'),
      },

      {
        label: t('totalIssuedShares'),
        value: { value: totalIssuedShares },
      },
    ];

  if (amendType === AmendType.UnStake)
    stats = [
      {
        label: t('sharesToBeRedeemed'),
        value: { value: unstakeAmount ?? 0 },
      },
      {
        label: t('sharePrice'),
        value: { decimalScale: 4, value: pricePerShare, prefix: '$' },
      },
      {
        label: t('estimatedValue'),
        value: {
          value: (unstakeAmount as number) * (pricePerShare ?? 0),
          prefix: '$',
        },
        tooltipText: t('estimatedValueOfSharesBeingRedeemed'),
      },
      {
        label: t('performanceFee'),
        value: { value: (performanceFee ?? 0) * 100, suffix: '%' },
      },
    ];

  return (
    <StatsContainer>
      <Row
        className="justify-between p-10 cursor-pointer"
        onClick={() => setDropDownShown(prev => !prev)}
      >
        <Row className="gap-5 ">
          <IconPair size={24} icons={[blastLogo, brand.tokenLogo]} />
          <Text variant="BODY_S">{vaultName ?? '--'}</Text>
        </Row>
        <img
          src={isDropDownShown ? chevronUpIcon : chevronDownIcon}
          alt={'status'}
        />
      </Row>

      {isDropDownShown ? (
        <Column className="stats-column">
          {stats.map(({ label, value, tooltipText }) => (
            <Text variant="BODY_S" className="flex justify-between">
              <Text
                className="flex items-center gap-2"
                color="shadesForeground200"
              >
                {label}
                {tooltipText ? (
                  <InfoTooltip iconSize={12} title={tooltipText} />
                ) : null}
              </Text>

              <FormattedNumber {...value} />
            </Text>
          ))}
        </Column>
      ) : null}
    </StatsContainer>
  );
};
export default memo(VaultStats);
