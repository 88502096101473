import { isBrandBfx } from 'utils/brand';

import { ComponentsName } from 'enums';

export const isActiveLink = (
  pathname: string,
  itemTo: string,
  componentName: ComponentsName,
  isWidthOverMedium: boolean,
) => {
  if (componentName === ComponentsName.Trade) {
    if (pathname === '' || pathname === '/') {
      return true;
    }
  }

  const rootPath = pathname.split('/')[1];
  const subPath = pathname.split('/')[2];

  // only for referrals, as it has a different structure, @todo: make this more generic
  if (subPath === 'referrals' && isWidthOverMedium && isBrandBfx)
    return pathname.toLowerCase() === `${`/${componentName}`}`.toLowerCase();

  return (
    `/${rootPath.toLowerCase()}` === itemTo.toLowerCase() ||
    pathname.toLowerCase() === `${`/${componentName}`}`.toLowerCase()
  );
};
