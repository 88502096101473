export enum ViewType {
  GRID,
  TABLE,
}

export enum VaultType {
  ALL,
  MY_VAULTS,
  WATCHLIST,
  NEW,
}
