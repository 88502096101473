import { useCallback, useEffect, useState } from 'react';

import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { parseUnits } from '@ethersproject/units';

import { useWeb3React } from '@web3-react/core';

import {
  bigNumberToFloat,
  roundJsDecimalToString,
  showNotification,
} from 'utils';
import { brand, isBrandRabbitX } from 'utils/brand';
import { getContract } from 'utils/contracts';

import { useVerifyChainId, WithdrawalResponse } from 'hooks';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { useExchangeAPI } from 'hooks/useExchangeAPI';

import { BALANCE_OPERATIONS } from 'constants/apiMappings';
import { USDT_DECIMALS } from 'constants/contracts';
import { NetworkContextName } from 'constants/networkContextName';
import { DepositsApiContext } from 'contexts/DepositsApiContext';
import {
  Rabbit,
  Rabbit__factory,
  USDT,
  USDT__factory,
  RabbitOld,
  RabbitOld__factory,
} from 'contracts/types';

import { config } from 'config';
import { Endpoints, NotificationType, RequestMethod } from 'enums';
import { ethers, constants, ContractTransaction } from 'ethers';
import { ProfileBalanceOps } from 'interfaces';
import { useTranslation } from 'react-i18next';

export const DepositsApiProvider = ({ children }) => {
  const { t } = useTranslation();
  const { makePrivateRequest } = useExchangeAPI();

  const { library: libraryNetwork } =
    useWeb3React<Web3Provider>(NetworkContextName);
  const { account, library } = useActiveWeb3React();
  const { isCorrectChainId } = useVerifyChainId();

  const [USDTSignContract, setUSDTSignContract] = useState<USDT | undefined>(
    undefined,
  );
  const [USDTReadContract, setUSDTReadContract] = useState<USDT | undefined>(
    undefined,
  );
  const [RabbitSignContract, setRabbitSignContract] = useState<
    Rabbit | undefined
  >(undefined);
  const [RabbitOldSignContract, setRabbitOldSignContract] = useState<
    RabbitOld | undefined
  >(undefined);
  const [RabbitOldReadContract, setRabbitOldReadContract] = useState<
    RabbitOld | undefined
  >(undefined);
  const [USDTDecimals, setUSDTDecimals] = useState<number>(0);
  const [areReadContractsSet, setAreReadContractsSet] =
    useState<boolean>(false);

  // Set read contracts
  useEffect(() => {
    (async () => {
      try {
        if (!libraryNetwork) {
          return;
        }
        setAreReadContractsSet(false);

        // Rabbit old contract (for keeping old withdrawal logic)
        const rabbitOldContractRead = getContract<RabbitOld>(
          RabbitOld__factory,
          config.RabbitOldContractAddress,
          libraryNetwork,
        );
        setRabbitOldReadContract(rabbitOldContractRead);

        // USDT contract
        const USDTReadContract = getContract<USDT>(
          USDT__factory,
          config.USDTAddress,
          libraryNetwork,
        );
        const USDTDecimals = USDT_DECIMALS;
        setUSDTDecimals(USDTDecimals);
        setUSDTReadContract(USDTReadContract);
        setAreReadContractsSet(true);
      } catch (e) {
        console.error(e);
        setAreReadContractsSet(false);
      }
    })();
  }, [libraryNetwork]);

  // Set sign contracts
  useEffect(() => {
    (async () => {
      try {
        if (!account || !library) return;
        const USDTSignContract = getContract<USDT>(
          USDT__factory,
          config.USDTAddress,
          library,
          account,
        );

        const rabbitContract = getContract<Rabbit>(
          Rabbit__factory,
          config.RabbitContractAddress,
          library,
          account,
        );
        const rabbitOldContract = getContract<RabbitOld>(
          RabbitOld__factory,
          config.RabbitOldContractAddress,
          library,
          account,
        );
        setUSDTSignContract(USDTSignContract);
        setRabbitSignContract(rabbitContract);
        setRabbitOldSignContract(rabbitOldContract);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [account, library]);

  const getUsdtBalance = async (address: string) => {
    try {
      if (!USDTReadContract || !address) return constants.Zero;

      return (await USDTReadContract.balanceOf(address)) as BigNumber;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };

  const getAccountUsdtBalance = async () => {
    try {
      if (!USDTReadContract || !account) return constants.Zero;

      return (await USDTReadContract.balanceOf(account)) as BigNumber;
    } catch (e: any) {
      console.error(e);
    } finally {
    }
    return constants.Zero;
  };

  const getAccountEthBalance = async () => {
    try {
      if (!account) {
        return constants.Zero;
      }

      if (!libraryNetwork) {
        throw new Error('Library is not defined when getting ETH balance');
      }

      const ethBalance = await libraryNetwork.getBalance(account);
      return ethBalance ?? constants.Zero;
    } catch (e: any) {
      console.error(e);
      return constants.Zero;
    }
  };

  const getUsdtAllowance = async (): Promise<BigNumber> => {
    try {
      if (!USDTReadContract || !account) return constants.Zero;

      const allowance: BigNumber = (await USDTReadContract.allowance(
        account,
        config.RabbitContractAddress,
      )) as BigNumber;
      return allowance;
    } catch (e: any) {
      console.error(e);
    }
    return constants.Zero;
  };

  const isUsdtApproved = async (amount?: number): Promise<boolean> => {
    try {
      if (!account) {
        throw new Error(
          `Account is not defined when checking ${brand.tokenTicker} allowance`,
        );
      }
      if (!USDTReadContract) {
        throw new Error(
          `${brand.tokenTicker} contract is not defined when checking ${brand.tokenTicker} allowance`,
        );
      }
      const allowance: BigNumber = (await USDTReadContract.allowance(
        account,
        config.RabbitContractAddress,
      )) as BigNumber;
      // Check for custom amount allowance
      if (amount) {
        return allowance.gte(
          parseUnits(
            roundJsDecimalToString(amount, USDTDecimals),
            USDTDecimals,
          ),
        );
      }
      // Otherwise check for max allowance
      return constants.MaxUint256.div(2).lt(allowance);
    } catch (e: any) {
      console.error(e);
    } finally {
    }
    return false;
  };

  const approve = async (amount?: number): Promise<TransactionResponse> => {
    try {
      if (!isCorrectChainId) {
        throw new Error(
          `Incorrect chain ID when approving ${brand.tokenTicker}`,
        );
      }
      if (!account) {
        throw new Error('Account is not defined when approving USDT');
      }
      if (!USDTSignContract) {
        throw new Error(`${brand.tokenTicker}SignContract is not defined`);
      }
      if (!USDTReadContract) {
        throw new Error(`${brand.tokenTicker}ReadContract is not defined`);
      }

      // USDT contract requires resetting the allowance to 0 before setting a new one
      if (isBrandRabbitX) {
        const currentAllowance = await USDTReadContract.allowance(
          account,
          config.RabbitContractAddress,
        );

        // If current allowance is not 0, reset it to 0
        if (!currentAllowance.isZero()) {
          showNotification({
            title: 'Resetting USDT allowance',
            description: 'Resetting USDT allowance to 0',
            type: NotificationType.Info,
          });
          const resetTx = (await USDTSignContract.approve(
            config.RabbitContractAddress,
            0,
          )) as TransactionResponse;
          await resetTx.wait();
          showNotification({
            title: 'USDT allowance reset',
            description:
              'USDT allowance reset to 0. Approving new allowance...',
            type: NotificationType.Positive,
          });
        }
      }

      const allowanceAmount = amount
        ? parseUnits(amount.toString(), USDT_DECIMALS)
        : constants.MaxUint256;
      const tx = (await USDTSignContract.approve(
        config.RabbitContractAddress,
        allowanceAmount,
      )) as TransactionResponse;

      return tx;
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };

  const transferUsdtToL1SmartContract = async (amount: BigNumber) => {
    try {
      if (!isCorrectChainId) {
        throw new Error(
          `Incorrect chain ID when depositing ${brand.tokenTicker}`,
        );
      }
      if (!RabbitSignContract) {
        throw new Error('RabbitDeposit contract is not defined');
      }

      return await RabbitSignContract.deposit(amount as ethers.BigNumber);
    } catch (e: any) {
      throw e;
    }
  };

  const sendTxHashToBackend = async (
    amount: BigNumber,
    txhash: string,
  ): Promise<ProfileBalanceOps> => {
    try {
      const params = {
        txhash: txhash,
        amount: bigNumberToFloat(amount, USDTDecimals),
      };

      const { data: res }: { data: ProfileBalanceOps } =
        await makePrivateRequest({
          method: RequestMethod.POST,
          endpoint: `/${Endpoints.DEPOSIT}`,
          requestParams: params,
          refreshJwt: true,
          responseMapping: BALANCE_OPERATIONS,
        });

      return res;
    } catch (e: any) {
      throw e;
    }
  };

  const withdraw = async (amount: number): Promise<ProfileBalanceOps> => {
    try {
      const params = {
        amount: amount,
      };

      const { data: res }: { data: ProfileBalanceOps } =
        await makePrivateRequest({
          method: RequestMethod.POST,
          endpoint: `/${Endpoints.BALANCE_OPS_WITHDRAW}`,
          requestParams: params,
          refreshJwt: false,
          responseMapping: BALANCE_OPERATIONS,
          shouldSignMetamaskMessage: true,
        });

      const notifTitle = t('withdrawalRequested');
      const notifDescription = t('withdrawalRequestedDescription');

      showNotification({
        title: notifTitle,
        description: notifDescription,
        type: NotificationType.Positive,
      });

      return res;
    } catch (e: any) {
      console.error(e.message);
      const notifTitle = t('withdrawalRequestFailed');
      const notifDescription = t('withdrawalRequestFailedDescription', {
        errorMessage: e.message,
      });

      showNotification({
        title: notifTitle,
        description: notifDescription,
        type: NotificationType.Negative,
      });

      throw e;
    }
  };

  /**
   * Reads the withdrawable balance from the L1 smart contract
   * @param account wallet address
   * @returns withdrawable balance
   */
  const getWithdrawableBalance = useCallback(
    async (account: string): Promise<BigNumber> => {
      try {
        if (!RabbitOldReadContract) {
          throw new Error('RabbitOldReadContract contract is not defined');
        }

        const withdrawable = await RabbitOldReadContract.withdrawableBalance(
          account,
        );
        return withdrawable as BigNumber;
      } catch (e: any) {
        throw e;
      }
    },
    [RabbitOldReadContract],
  );

  /**
   * OLD: Withdraws the withdrawable balance from the L1 smart contract
   */
  const withdrawWithdrawableBalanceOld =
    useCallback(async (): Promise<ContractTransaction> => {
      try {
        if (!isCorrectChainId) {
          throw new Error(
            `Incorrect chain ID when withdrawing ${brand.tokenTicker}`,
          );
        }
        if (!RabbitOldSignContract || !account) {
          throw new Error('RabbitOld contract or account is not defined');
        }

        return await RabbitOldSignContract.withdraw();
      } catch (e: any) {
        throw e;
      }
    }, [RabbitOldSignContract, account, isCorrectChainId]);

  /**
   * Withdraws the withdrawable balance from the L1 smart contract
   */
  const withdrawWithdrawableBalance = useCallback(
    async (
      withdrawalResponse: WithdrawalResponse,
      wallet: string,
    ): Promise<ContractTransaction> => {
      try {
        if (!isCorrectChainId) {
          throw new Error(
            `Incorrect chain ID when withdrawing ${brand.tokenTicker}`,
          );
        }
        if (!RabbitSignContract || !account) {
          throw new Error('Rabbit contract or account is not defined');
        }

        const {
          r,
          s,
          v,
          withdrawal: { id },
          bnAmount,
        } = withdrawalResponse;

        // Strip out the 'w_' prefix from the id
        const strippedId = (id as unknown as string).replace('w_', '');

        return await RabbitSignContract.withdraw(
          strippedId,
          wallet,
          ethers.BigNumber.from(bnAmount),
          v,
          ethers.utils.arrayify(r),
          ethers.utils.arrayify(s),
        );
      } catch (e: any) {
        throw e;
      }
    },
    [RabbitSignContract, account, isCorrectChainId],
  );

  const value = {
    areReadContractsSet,
    USDTDecimals,
    getAccountUsdtBalance,
    getUsdtBalance,
    getAccountEthBalance,
    isUsdtApproved,
    getUsdtAllowance,
    approve,
    transferUsdtToL1SmartContract,
    sendTxHashToBackend,
    withdraw,
    getWithdrawableBalance,
    withdrawWithdrawableBalanceOld,
    withdrawWithdrawableBalance,
  };

  return (
    <DepositsApiContext.Provider value={value}>
      {children}
    </DepositsApiContext.Provider>
  );
};
