import {
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  useState,
} from 'react';

import { CircularProgress } from '@material-ui/core';

import Icon from 'components/Icon';
import { Size as IconSize } from 'components/Icon/size';
import { TextVariant } from 'components/Text/variant';

import {
  TButtonSizeVariant,
  TButtonColorVariant,
  ButtonSizeVariants,
  ButtonColorVariants,
} from './config';
import { getPropsForCurrentBrand } from './utils';
import { theme } from 'theme/Theme';

import { Color } from 'interfaces';
import styled, { css } from 'styled-components';
import { BrandName } from 'types';

type ButtonStyledProps = {
  colorVariant?: TButtonColorVariant;
  sizeVariant?: TButtonSizeVariant;
  animateContent?: boolean;
  block?: boolean;
  disabled?: boolean;
  iconOnly?: boolean;
  overRounded?: boolean;
  avoidPadding?: boolean;
  background?: string | { default: Color; hovered: Color };
  border?: string | { default: Color; hovered: Color };
  padding?: string;
  customDisabledOpacity?: number;
};
const ButtonStyled = styled.button<ButtonStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-style: normal;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  transition: gap 0.3s ease-in-out;
  cursor: pointer;

  ${({ sizeVariant = 'M' }) =>
    css`
      padding: ${ButtonSizeVariants[sizeVariant].padding};
    `}

  ${({ colorVariant }) =>
    colorVariant &&
    css`
      background: ${ButtonColorVariants[colorVariant].default.background};
      color: ${({ theme }) =>
        ButtonColorVariants[colorVariant].default?.text || theme.colors.white};
    `}

    ${({ colorVariant }) =>
    colorVariant &&
    ButtonColorVariants[colorVariant].default.border &&
    css`
      border: solid 1px ${ButtonColorVariants[colorVariant].default.border};
    `}

    ${({ colorVariant, disabled }) =>
    colorVariant &&
    !disabled &&
    css`
      :hover {
        background: ${ButtonColorVariants[colorVariant].hovered.background};
        color: ${({ theme }) =>
          ButtonColorVariants[colorVariant].hovered?.text ||
          theme.colors.white};
      }
    `}

    ${({ colorVariant, disabled }) =>
    colorVariant &&
    !disabled &&
    ButtonColorVariants[colorVariant].default.border &&
    css`
      :hover {
        border: solid 1px ${ButtonColorVariants[colorVariant].hovered.border};
      }
    `}


    ${({ animateContent = false, disabled }) =>
    animateContent &&
    !disabled &&
    css`
      :hover {
        gap: 10px;
      }
    `};

  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}

  ${({ disabled, customDisabledOpacity = 0.3 }) =>
    disabled &&
    css`
      opacity: ${customDisabledOpacity};
      cursor: not-allowed;
    `}

    ${({ iconOnly = false }) =>
    iconOnly &&
    css`
      padding: 5px;
      background: transparent;
    `}

    ${({ avoidPadding = false }) =>
    avoidPadding &&
    css`
      padding: 0px !important;
    `}

    ${({ overRounded = false }) =>
    overRounded &&
    css`
      border-radius: 100px;
    `}

    ${({ background }) =>
    background &&
    css`
      background: ${theme.colors[
        typeof background === 'string' ? background : background?.default
      ]};

      :hover {
        background: ${theme.colors[
          typeof background === 'string' ? background : background?.hovered
        ]};
      }
    `}

    ${({ border }) =>
    border &&
    css`
      border: solid 1px
        ${theme.colors[typeof border === 'string' ? border : border?.default]};

      :hover {
        border: solid 1px
          ${theme.colors[typeof border === 'string' ? border : border?.hovered]};
      }
    `}

    ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
`;

const LoadingStyled = styled(CircularProgress)<{
  colorVariant?: TButtonColorVariant;
}>`
  display: flex;
  margin: 0 auto;
  position: absolute;

  &.MuiCircularProgress-colorPrimary {
    color: ${({ colorVariant, theme }) =>
      colorVariant
        ? ButtonColorVariants[colorVariant].default.text || theme.colors.white
        : theme.colors.white};
  }
`;

const ContentContainer = styled.div<{ isLoading: boolean }>`
  display: flex;
  gap: 5px;
  align-items: center;

  /* To Make the content invisible without effecting the layout */
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `}
`;

type Record<BrandName extends string, ButtonProps> = {
  [K in BrandName]?: ButtonProps;
};

export type BrandSpecificButtonProps = Record<BrandName, ButtonProps>;

export type ButtonProps = {
  rightIcon?: string;
  leftIcon?: string;
  children?: React.ReactNode;
  iconSize?: keyof typeof IconSize;
  isLoading?: boolean;
  textVariant?: keyof typeof TextVariant;
} & Partial<ButtonStyledProps> &
  Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    'onClick' | 'onMouseEnter' | 'onMouseLeave' | 'className' | 'autoFocus'
  > & {
    brandSpecificProps?: BrandSpecificButtonProps; // add more to to in more brandSpecificProps
  };

const Button = forwardRef(
  (
    {
      children,
      leftIcon,
      rightIcon,
      sizeVariant, // default button size
      iconSize = sizeVariant ? ButtonSizeVariants[sizeVariant].iconSize : 'M',
      isLoading = false,
      disabled = false,
      brandSpecificProps,
      textVariant, // if passed, will overriede text variant from button size
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const _textVariant =
      textVariant ||
      (sizeVariant ? ButtonSizeVariants[sizeVariant].text : undefined);

    // if brandSpecificProps are defined, they override the default/non-brand specific props
    const propsForCurrentBrand = getPropsForCurrentBrand(brandSpecificProps);

    return (
      <ButtonStyled
        ref={ref}
        style={_textVariant && TextVariant[_textVariant]}
        sizeVariant={sizeVariant}
        disabled={disabled || isLoading}
        {...rest}
        {...propsForCurrentBrand}
      >
        <ContentContainer isLoading={isLoading}>
          {leftIcon && (
            <Icon disabled={disabled} src={leftIcon} size={iconSize} />
          )}
          {children}
          {rightIcon && (
            <Icon disabled={disabled} src={rightIcon} size={iconSize} />
          )}
        </ContentContainer>

        {isLoading ? (
          <LoadingStyled
            colorVariant={rest.colorVariant}
            style={{
              width: '14px',
              height: '14px',
            }}
          />
        ) : null}
      </ButtonStyled>
    );
  },
);

type SpecialButtonProps = {
  leftIcon?: { default: string; hovered: string };
  rightIcon?: { default: string; hovered: string };
} & Omit<ButtonProps, 'leftIcon' | 'rightIcon'>;
export const SpecialButton = ({
  leftIcon,
  rightIcon,
  ...rest
}: SpecialButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Button
      leftIcon={isHovered ? leftIcon?.hovered : leftIcon?.default}
      rightIcon={isHovered ? rightIcon?.hovered : rightIcon?.default}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      {...rest}
    />
  );
};

export default Button;
