import { RAINBOW_GRADIENT } from './components/Header/BorderBadge';
import bfxGreenIcon from 'assets/assetLogos/blast-green.svg';
import bfxBlackIcon from 'assets/assetLogos/blast.svg';
import rabbitWhiteIcon from 'assets/assetLogos/rbx-circle-white.svg';
import blasterSwapIcon from 'assets/icons/blasterswap.svg';
import { theme } from 'theme/Theme';

export const BFX_BADGE_HOVERABLE = {
  border: theme.colors.main200,
  leftIcon: bfxGreenIcon,
  content: null,
  hoveredContent: 'BFX PTS',
};

export const BFX_BADGE = {
  border: theme.colors.main200,
  leftIcon: bfxGreenIcon,
  content: 'BFX PTS',
};

export const BLASTER_SWAP_BADGE_HOVERABLE = {
  border: '#2E2E2E',
  leftIcon: blasterSwapIcon,
  content: null,
  hoveredContent: 'BlasterSwap',
};

export const BLASTER_SWAP_BADGE = {
  border: '#2E2E2E',
  leftIcon: blasterSwapIcon,
  content: 'BlasterSwap',
};

export const POINTS_BADGE_HOVERABLE = {
  border: '#2E2E2E',
  leftIcon: bfxBlackIcon,
  content: null,
  hoveredContent: 'Gold/PTS',
};

export const POINTS_BADGE = {
  border: '#2E2E2E',
  leftIcon: bfxBlackIcon,
  content: 'Gold/PTS',
};

export const TWICE_POINTS_BADGE_HOVERABLE = {
  border: RAINBOW_GRADIENT,
  leftIcon: bfxBlackIcon,
  content: '2X',
  hoveredContent: '2X Gold/PTS',
};

export const TWICE_POINTS_BADGE = {
  border: RAINBOW_GRADIENT,
  leftIcon: bfxBlackIcon,
  content: '2X Gold/PTS',
};

export const RABBIT_BADGE = {
  border: theme.colors.white,
  leftIcon: rabbitWhiteIcon,
  content: null,
  hoveredContent: 'RBX',
};
