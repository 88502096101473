import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;

  .active {
    background: ${({ theme }) => theme.colors.shadesBackground700};

    .component-icon {
      background: ${({ theme }) => theme.colors.shadesBackground500};
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.shadesBackground700};

    .component-icon {
      background: ${({ theme }) => theme.colors.shadesBackground500};
    }
  }

  .component-icon {
    width: 16px;
    height: 16px;
    padding: 8px;
    border-radius: 200px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }

  .dropdown-container {
    position: relative;
  }
`;
