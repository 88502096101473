import { API_MAPPINGS } from '../constants';
import { RestService } from 'service/restService';

import { Endpoints, QueryKeys, RequestMethod } from 'enums';
import { OrderbookWSResponse } from 'interfaces';
import { useQuery } from 'react-query';

type SnapShotResponse = OrderbookWSResponse & {
  bids: [number, number][];
  asks: [number, number][];
};

const getMidPriceFromSnapShot = (snapShot: SnapShotResponse) => {
  if (
    snapShot.asks?.[0]?.[0] &&
    snapShot.bids?.[snapShot.bids.length - 1]?.[0]
  ) {
    return (
      (snapShot.asks[0][0] + snapShot.bids[snapShot.bids.length - 1][0]) / 2
    );
  }
};

const restService = RestService.get();

export function useOrderbookSnapshot(marketId: string) {
  const { data: orderbookSnapshot } = useQuery(
    [QueryKeys.BlastPoints, marketId],
    async () => {
      try {
        const {
          data,
        }: {
          data: SnapShotResponse;
        } = await restService.request({
          method: RequestMethod.GET,
          path: `/${Endpoints.MARKET_ORDERBOOK}`,
          queryParams: {
            market_id: marketId,
          },
          isArray: true,
          responseMapping: API_MAPPINGS.MARKET_ORDERBOOK,
        });

        return data?.[0];
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    { refetchInterval: 1_500 },
  );

  return {
    orderbookSnapshot,
    midPrice: orderbookSnapshot
      ? getMidPriceFromSnapShot(orderbookSnapshot)
      : undefined,
  } as const;
}
