import { FormattedNumber } from 'components';

import { AirdropUserStats as AirdropUserStatsType } from '../PhaseContainer';
import { Container, AirdropUserStats } from './styles';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export type Phase = 'one' | 'two';

const AirdropUserStatsSection = ({
  airdropUserStats,
  phaseImg,
  phase,
}: {
  airdropUserStats: AirdropUserStatsType | undefined;
  phaseImg: string;
  phase: Phase;
}) => {
  const { t } = useTranslation();

  const { tradingVolume, pnl, avgOpenInterest } = airdropUserStats || {};

  return (
    <Container>
      <img src={phaseImg} alt="Airdrop phase" />
      {phase === 'one' && (
        <AirdropUserStats>
          <>
            <div className="stat-col">
              <span className="label">{t('tradingVolume')}</span>
              {tradingVolume !== undefined ? (
                <FormattedNumber value={tradingVolume} prefix="$" />
              ) : (
                <span className="placeholder-value">- -</span>
              )}
            </div>
            <div className="divider" />
            <div className="stat-col">
              <span className="label">{t('avgOpenInterest')}</span>
              {avgOpenInterest !== undefined ? (
                <FormattedNumber value={avgOpenInterest} prefix="$" />
              ) : (
                <span className="placeholder-value">- -</span>
              )}
            </div>
            <div className="divider" />
            <div className="stat-col">
              <span className="label">{t('profit&Loss')}</span>
              {pnl !== undefined ? (
                <ColoredText color={pnl >= 0 ? Colors.Success : Colors.Danger}>
                  <FormattedNumber value={pnl} prefix="$" />
                </ColoredText>
              ) : (
                <span className="placeholder-value">- -</span>
              )}
            </div>
          </>
        </AirdropUserStats>
      )}
    </Container>
  );
};

export default observer(AirdropUserStatsSection);
