import { memo } from 'react';

import EmptyTableBody from 'components/EmptyStateOverlay/emptyTableBody';

import EmptyActivityRow from './EmptyActivityRow';
import folderQuestionIcon from 'assets/icons/folder-question-white.svg';

import { useTranslation } from 'react-i18next';

const EmptyActivityTableState = ({ isLoading }: { isLoading: boolean }) => {
  const { t } = useTranslation();
  return (
    <EmptyTableBody
      RowComponent={<EmptyActivityRow />}
      isLoading={isLoading}
      icon={folderQuestionIcon}
      title={t('noRequestedUnstakesFound')}
      description={t('noRequestedUnstakesFoundDescription')}
    />
  );
};

export default memo(EmptyActivityTableState);
