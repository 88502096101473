import { useContext } from 'react';

import {
  EmptyDepositsApiContext,
  DepositsApiContext,
} from 'contexts/DepositsApiContext';

export const useDepositsAPI = (throwError: boolean = true) => {
  const context = useContext(DepositsApiContext);
  if (context) {
    return context;
  }
  if (throwError) {
    throw Error('Context Provider is not provided!');
  }
  return EmptyDepositsApiContext;
};
