import { Web3Provider } from '@ethersproject/providers';

import { getMeshTransfer } from 'hooks/useMeshAPI';

import MeshUpdateToast from 'components/CustomToasts/MeshUpdateToast';

import {
  processTransactionConfirmations,
  savePendingTxToastIdsToLocalStorage,
} from './activeTransactions';
import { capitalizeFirstLetter } from './textFormatters';
import { LS_PENDING_MESH_TRANSACTIONS } from 'constants/localStorageKeys';
import { Modals } from 'constants/modals';
import { ModalsContextInterface } from 'contexts/ModalContext';
import {
  getStepDescriptionText,
  MESH_ORDER_STATUS,
} from 'pages/Trade/components/AccountStats/NewDepositModal/Steps/MeshOrderStatus/meshOrderStatus';
import MeshOrderStatusModal from 'pages/Trade/components/AccountStats/NewDepositModal/Steps/MeshOrderStatus/meshOrderStatusModal';

import { LocalStorageTransactionTypes } from 'enums';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';

export type TSavedMeshTransfer = {
  amount: number;
  institutionTxId: string;
  brokerName: string;
};

const getTransfersFromLocalStorage = (): Record<string, any> => {
  const transactions = localStorage.getItem(LS_PENDING_MESH_TRANSACTIONS);
  return transactions ? JSON.parse(transactions) : {};
};

export const saveTransferToLocalStorage = (
  institutionTxId: string,
  walletAddress: string,
  amount: number,
  brokerName: string,
) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const transactionsObj = getTransfersFromLocalStorage();

  const addressTransactions = transactionsObj[lowercasedWalletAddress] || {};

  const transfersObjUpdated = {
    ...transactionsObj,
    [lowercasedWalletAddress]: {
      ...addressTransactions,
      [institutionTxId]: {
        institutionTxId,
        amount,
        brokerName,
      },
    },
  };

  localStorage.setItem(
    LS_PENDING_MESH_TRANSACTIONS,
    JSON.stringify(transfersObjUpdated),
  );
};

export const removeTransferFromLocalStorage = (
  institutionTxId: string,
  walletAddress: string,
) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const transferObj = getTransfersFromLocalStorage();

  if (
    transferObj[lowercasedWalletAddress] &&
    transferObj[lowercasedWalletAddress][institutionTxId]
  ) {
    delete transferObj[lowercasedWalletAddress][institutionTxId]; // Remove the transaction

    // If there are no more transactions for the given wallet address, remove the address key as well
    if (Object.keys(transferObj[lowercasedWalletAddress]).length === 0) {
      delete transferObj[lowercasedWalletAddress];
    }

    localStorage.setItem(
      LS_PENDING_MESH_TRANSACTIONS,
      JSON.stringify(transferObj),
    );
  }
};

export const readTxsFromLocalStorage = (walletAddress: string) => {
  const lowercasedWalletAddress = walletAddress.toLowerCase();
  const transactionsObj = getTransfersFromLocalStorage();

  const addressTransactions = transactionsObj[lowercasedWalletAddress] ?? {};

  return (
    Object.keys(addressTransactions).map(key => addressTransactions[key]) ?? []
  );
};

export const checkAndProcessPendingMeshTransfers = async (
  networkProvider: Web3Provider,
  walletAddress: string,
  modal: ModalsContextInterface,
) => {
  const transfers = readTxsFromLocalStorage(walletAddress);

  for (const transfer of transfers) {
    try {
      const {
        data: { data },
      } = await getMeshTransfer(transfer.institutionTxId);
      if (!data) return;
      const txHash = data?.tx_hash;
      const amount = transfer.amount;
      const brokerName =
        capitalizeFirstLetter(transfer.brokerName) || 'Exchange';

      if (txHash) {
        removeTransferFromLocalStorage(transfer.institutionTxId, walletAddress);
        trackTxHash(txHash, networkProvider, walletAddress, amount);
      } else {
        const id = Math.random().toString(36);

        modal.present(
          <MeshOrderStatusModal
            institutionTxId={data.institution_transaction_id}
            depositAmount={amount}
            trackTransaction={(
              txHash: string,
              updateConfirmations: (confirmations: number) => void,
              toastId: React.ReactText,
            ) => {
              trackTxHash(
                txHash,
                networkProvider,
                walletAddress,
                amount,
                toastId,
                updateConfirmations,
              );
            }}
            isDepositConfirmed={false}
            id={id}
            transferInfo={data}
            brokerName={brokerName}
          />,
          Modals.depositModal,
          {
            minimised: true,
            id: id,
            metadata: {
              title: `${brokerName} transfer`,
              description: getStepDescriptionText(
                MESH_ORDER_STATUS.PENDING_FROM_MESH,
              ),
            },
          },
        );
      }
    } catch (err) {
      console.log(
        'An error occurred while checkAndProcessPendingMeshTransfers',
        err,
      );
    }
  }
};

export const showMeshUpdateToast = (
  amount: number,
  status: MESH_ORDER_STATUS,
  brokerName: string,
  toastId?: React.ReactText,
  modalId?: string,
) => {
  if (toastId) {
    toast.update(toastId, {
      render: (
        <MeshUpdateToast
          amount={amount}
          status={status}
          brokerName={brokerName}
          modalId={modalId}
        />
      ),
    });
    return toastId;
  }

  const newId = toast(
    <MeshUpdateToast
      amount={amount}
      status={status}
      brokerName={brokerName}
      modalId={modalId}
    />,
    {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: false,
      pauseOnHover: false,
      draggable: true,
      closeOnClick: false,
      theme: 'dark',
    },
  );

  return newId;
};

const trackTxHash = async (
  txHash: string,
  networkProvider: Web3Provider,
  walletAddress: string,
  amount: number,
  toastId?: React.ReactText,
  updateConfirmations?: (confirmations: number) => void,
) => {
  try {
    const tx = await networkProvider.getTransaction(txHash);

    if (!tx) {
      throw new Error('Transaction not found');
    }

    const minedTx = await tx.wait(1);

    if (minedTx?.status !== 1) {
      throw new Error('Transaction reverted');
    }

    processTransactionConfirmations(
      minedTx,
      networkProvider,
      walletAddress,
      amount,
      LocalStorageTransactionTypes.Deposit,
      toastId,
      updateConfirmations,
    );
  } catch (e) {
    console.error(e);
  }
};
