import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    flex-direction: row;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.medium}px) {
    gap: 8px;
  }
  @media (min-width: ${({ theme }) => theme.screenSizes.xxl}px) {
    gap: 16px;
  }
`;
