import { memo, useState } from 'react';

import { useApiKeysAPI } from 'hooks';

import Loading from 'components/Loading';
import Modal from 'components/Modal';

import ApiKeyDetails from './ApiKeyDetails';
import ApiKeysList, { generateApiString } from './ApiKeysList';
import CreateNewKey from './CreateNewKey';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { ApiKey } from 'interfaces/apiKey';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { QueryKeys } from 'enums';
import { useQuery } from 'react-query';
import styled from 'styled-components';

const ApiKeysContainer = styled.div`
  display: flex;
  width: 725px;
  min-height: 625px;
  max-height: 800px;
`;

const ContentContainer = styled.div`
  display: flex;
  padding: 20px 30px;
  width: 100%;
`;

const ApiKeysModal = () => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const [selectedKey, setSelectedKey] = useState<ApiKey | null>(null);
  const [newKeyTag, setNewKeyTag] = useState<string | null>(
    generateApiString(),
  );
  const { fetchApiKeys } = useApiKeysAPI();

  const {
    isLoading,
    data: apiKeys,
    isError,
    isFetching,
  } = useQuery(
    QueryKeys.ApiKeys,
    async () => {
      try {
        const keys = await fetchApiKeys();
        setSelectedKey(keys[keys.length > 0 ? keys.length - 1 : 0]);
        return keys;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    {
      enabled: !!frontendSecrets?.jwt,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  if (!frontendSecrets) return null;

  if (isLoading || isFetching) {
    return (
      <Modal
        showHeader={false}
        showCloseIcon={false}
        size={'auto'}
        name={Modals.apiKeysModal}
      >
        <ApiKeysContainer>
          <ApiKeysList
            isLoading={true}
            setNewKeyTag={setNewKeyTag}
            newKeyTag={newKeyTag}
            apiKeys={[]}
            selectedKey={selectedKey}
            onHandleSelectKey={setSelectedKey}
          />
          <ContentContainer className="flex justify-center mt-30">
            <Loading />
          </ContentContainer>
        </ApiKeysContainer>
      </Modal>
    );
  }

  if (!apiKeys || isError) {
    return (
      <Modal
        showHeader={false}
        showCloseIcon={false}
        size={'auto'}
        name={Modals.apiKeysModal}
      >
        <ApiKeysContainer>
          <ApiKeysList
            setNewKeyTag={setNewKeyTag}
            newKeyTag={newKeyTag}
            apiKeys={[]}
            selectedKey={selectedKey}
            onHandleSelectKey={setSelectedKey}
          />
          <ContentContainer>
            <ColoredText color={Colors.Danger}>
              Something went wrong, please reopen the modal.
            </ColoredText>
          </ContentContainer>
        </ApiKeysContainer>
      </Modal>
    );
  }

  return (
    <Modal
      showHeader={false}
      showCloseIcon={false}
      size={'auto'}
      name={Modals.apiKeysModal}
    >
      <ApiKeysContainer>
        <ApiKeysList
          setNewKeyTag={setNewKeyTag}
          newKeyTag={newKeyTag}
          apiKeys={apiKeys}
          selectedKey={selectedKey}
          onHandleSelectKey={setSelectedKey}
        />
        <ContentContainer>
          {newKeyTag !== null && (
            <CreateNewKey setNewKeyTag={setNewKeyTag} newKeyTag={newKeyTag} />
          )}
          {newKeyTag === null && selectedKey && (
            <ApiKeyDetails apiKey={selectedKey} />
          )}
        </ContentContainer>
      </ApiKeysContainer>
    </Modal>
  );
};

export default memo(ApiKeysModal);
