import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;
  padding: 15px;
`;

export const StatsBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .stat {
    padding: 17px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    gap: 5px;
    background: ${({ theme }) => theme.colors.shadesBackground700};

    .value-icon {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }

    span:last-child {
      font-variant-numeric: lining-nums tabular-nums;
      font-feature-settings: 'ss05' on;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
    .stat {
      padding: 20px 12px;
    }
  }
`;
