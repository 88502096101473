import { useState } from 'react';

import { TableBody, TableCell, TableRow } from '@material-ui/core';

import { useIsTradePage } from 'hooks';

import TableLoader from 'components/TableLoader';

import { StyledTable, TableContainer } from '../styles';
import { PositionsRow } from './PositionsRow';
import TableHead from './TableHead';
import { filterPositions, sortPositions } from './utils';
import { useAppContext } from 'contexts/AppContext';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export enum SortBy {
  PNL = 'PNL',
  NOTIONAL = 'NOTIONAL',
}

export type PositionSortConfig = {
  sortBy: SortBy;
  numOfClicks: number;
};

type Props = {
  showTableHead?: boolean;
  showLabelOnTableCell?: boolean;
  isRowCollapsible?: boolean;
  includePagination?: boolean;
};
const PositionsTable = ({
  showTableHead = true,
  showLabelOnTableCell = false,
  isRowCollapsible = false,
  includePagination,
}: Props) => {
  const {
    store: {
      account: { _positions, isLoading, frontendSecrets },
      appState: { showAllMarkets },
      markets: { selectedMarketId },
    },
  } = useAppContext();
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState<PositionSortConfig | null>(null);

  const isTradePage = useIsTradePage();

  if (isLoading || !frontendSecrets) {
    return (
      <TableContainer useScroll={!includePagination}>
        <StyledTable>
          {showTableHead ? <TableHead sortConfig={sortConfig} /> : null}
          <TableBody>
            {!frontendSecrets ? (
              <TableRow>
                <TableCell colSpan={100} className="no-items">
                  {t('connectYourWalletToStartTrading')}
                </TableCell>
              </TableRow>
            ) : (
              <TableLoader />
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
    );
  }

  const filteredPositions = filterPositions({
    _positions,
    showAllMarkets,
    selectedMarketId,
    isTradePage,
  });

  const sortedAndFilteredPositions = sortPositions(
    filteredPositions,
    sortConfig,
  );

  const handleSortBy = (sortBy: SortBy) => {
    setSortConfig(prev => ({
      sortBy,
      numOfClicks: (prev?.numOfClicks ?? 0) + 1,
    }));
  };

  return (
    <TableContainer>
      <StyledTable stickyHeader>
        {showTableHead ? (
          <TableHead onSetSortBy={handleSortBy} sortConfig={sortConfig} />
        ) : null}
        <TableBody>
          {sortedAndFilteredPositions.length > 0 ? (
            sortedAndFilteredPositions.map((item, index) => {
              const key = `${item.id}`;
              return (
                <PositionsRow
                  isRowCollapsible={isRowCollapsible}
                  showLabels={showLabelOnTableCell}
                  index={index}
                  position={item}
                  key={key}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={100} className="no-items">
                You have no positions.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default observer(PositionsTable);
