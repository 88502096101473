import { createContext } from 'react';

import { TransactionResponse } from '@ethersproject/providers';

import { BigNumber, constants } from 'ethers';

export type UsdtContractsApi = {
  areReadContractsSet: boolean;
  getUsdtBalanceForAddress: (address: string) => Promise<BigNumber>;
  getUsdtAllowanceForContract: (contractAddress: string) => Promise<BigNumber>;
  approveUsdtForContract: (
    contractAddress: string,
  ) => Promise<TransactionResponse>;
  isUsdtApprovedForContract: (
    contractAddress: string,
    amount?: number,
  ) => Promise<boolean>;
};

export const EmptyUsdtContractsApiContext = {
  areReadContractsSet: false,
  getUsdtBalanceForAddress: () => {
    return constants.Zero;
  },
  getUsdtAllowanceForContract: () => {
    return constants.Zero;
  },
  approveUsdtForContract: () => {
    return {} as TransactionResponse;
  },
  isUsdtApprovedForContract: () => false,
};
const UsdtContractsApiContext = createContext<UsdtContractsApi | null>(null);

export { UsdtContractsApiContext };
