import React from 'react';

import { Web3Provider } from '@ethersproject/providers';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';

import { initializeGoogleTagManager } from 'utils';

import Web3ReactManager from 'components/Web3ReactManager';

import App from './App';
import './i18n/config';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { GlobalStyles } from './theme/GlobalStyles';
import Theme from './theme/Theme';
import { NetworkContextName } from 'constants/index';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import { mixpanelService } from 'service/mixpanelService';

import { config } from 'config';
import { Providers } from 'providers';
import { ToastContainer } from 'react-toastify';

initializeGoogleTagManager();

if (!!config.sentryDSN) {
  Sentry.init({
    dsn: config.sentryDSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: config.sentryRelease,
  });
}

mixpanelService.init();

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

function getActiveLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

// Network provider is used for reading data from the blockchain
// function getNetworkLibrary(provider: any): StaticJsonRpcProvider {
//   // StaticJsonRpcProvider doesn't call "eth_chainId" on every request, so it's faster
//   const library = new StaticJsonRpcProvider(provider);
//   library.pollingInterval = 12000;
//   return library;
// }

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getActiveLibrary}>
      <Web3ProviderNetwork getLibrary={getActiveLibrary}>
        <Web3ReactManager>
          <Theme>
            <Providers>
              <App />
              <ToastContainer />
              <GlobalStyles />
            </Providers>
          </Theme>
        </Web3ReactManager>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
