import { brand } from 'utils/brand';

import useModal from 'hooks/useModal';
import useWindowDimensions from 'hooks/useWindowDimensions';

import Button from 'components/Button/button';

import {
  QRCodeContent,
  SyncWithDesktop,
} from '../../../../components/SyncWithDesktop/SyncWithDesktop';
import InfoDropdown from '../InfoDropdown';
import LanguagesDropdown from '../LanguagesDropdown';
import SettingsDropdown from '../SettingsDropdown';
import AirdropActions from './AirdropActions';
// import BlastPoints from './AirdropActions/BlastPoints';
import DepositButtonWithBadge from './DepositButtonWithDropdown';
import { ActionButtonsStyled, VerticalSeparator } from './styles';
import syncWithDesktopIcon from 'assets/icons/syncWithDesktop.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { routes } from 'pages/routes';

import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

type Props = { closeMobileSideBar?: () => void };
const ActionButtons = ({ closeMobileSideBar }: Props) => {
  const { width: windowWidth } = useWindowDimensions();
  const { screenSizes }: any = useTheme();
  const modal = useModal();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const navigate = useNavigate();

  const handleSyncWithDesktopClick = () => {
    if (frontendSecrets) {
      const content: QRCodeContent = {
        wallet: frontendSecrets.profile.wallet,
        randomSecret: frontendSecrets.randomSecret,
        refreshToken: frontendSecrets.refreshToken,
      };

      modal.present(
        <SyncWithDesktop content={content} />,
        Modals.syncWithDesktopModal,
      );
    }
  };

  const SyncActionButton = () => {
    if (!brand.featureFlags.syncWithDesktop) {
      return null;
    }

    return (
      <Button
        iconOnly
        data-gtmid="button-sync-with-desktop"
        onClick={handleSyncWithDesktopClick}
        disabled={!frontendSecrets}
        rightIcon={syncWithDesktopIcon}
        iconSize="L"
      />
    );
  };

  const isMobile = windowWidth <= screenSizes.xl;

  const isOverMedium = windowWidth > screenSizes.medium;

  return (
    <ActionButtonsStyled>
      <AirdropActions closeMobileSidebar={closeMobileSideBar} />
      <div className="row">
        {isMobile ? <SyncActionButton /> : null}

        {isOverMedium ? <InfoDropdown /> : null}

        <LanguagesDropdown />

        {frontendSecrets?.jwt && <SettingsDropdown />}
      </div>

      {/* Rewards */}
      {isOverMedium ? (
        <Button
          onClick={() => {
            closeMobileSideBar?.();
            navigate(routes.portfolio.airdrops.dashboard.getRedirectPath());
          }}
          colorVariant="tertiary"
          sizeVariant="S"
          // block
          data-gtmid="button-rewards"
        >
          Earn Points
        </Button>
      ) : null}

      {/* Deposit */}
      <DepositButtonWithBadge />

      {!isMobile ? (
        <>
          <VerticalSeparator />
          <SyncActionButton />
        </>
      ) : null}
    </ActionButtonsStyled>
  );
};

export default observer(ActionButtons);
