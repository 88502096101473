import { capitalizeFirstLetter, showNotification } from 'utils';

import { ButtonSwitch } from 'components';

import { ActionType } from './enums';

import { NotificationType } from 'enums';

type Props = {
  selected: ActionType;
  onChange: (s: ActionType) => void;
  disableUnlock?: boolean;
};

const LockUnlockSelector = ({ onChange, selected, disableUnlock }: Props) => {
  const initialOptions = [
    { text: ActionType.LOCK, selected: selected === ActionType.LOCK },
    {
      text: ActionType.UNLOCK,
      selected: selected === ActionType.UNLOCK,
      isDisabled: disableUnlock,
    },
  ];
  return (
    <ButtonSwitch
      className="button-switch-container"
      initialOptions={initialOptions}
      onOptionSelect={s => {
        if (s === ActionType.UNLOCK && disableUnlock) {
          showNotification({
            title:
              'This tab is disabled due to no amount currently being locked',
            type: NotificationType.Info,
          });
          return;
        }
        onChange(s);
      }}
      displayTextTransform={text => capitalizeFirstLetter(text)}
      key={disableUnlock ? 'disable-unlock' : 'lock'}
    />
  );
};

export default LockUnlockSelector;
