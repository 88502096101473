import Button from 'components/Button/button';
import Icon from 'components/Icon';
import Text from 'components/Text';

import styled from 'styled-components';

const StyledEmptyOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  top: 0;
  background: ${({ theme }) => `
      linear-gradient(
      183deg,
      rgba(10, 14, 24, 0.3) 2.33%,
      ${theme.colors.shadesBackground900} 86.16%
    );`};

  > span:last-child {
    max-width: 360px;
  }
`;

export type EmptyOverlayProps = {
  icon: string;
  title: string;
  description: string;
  actionButtonLabel?: string;
  onActionButtonClick?: () => void;
};
const EmptyStateOverlay = ({
  icon,
  title,
  description,
  actionButtonLabel,
  onActionButtonClick,
}: EmptyOverlayProps) => {
  return (
    <StyledEmptyOverlay>
      <Icon src={icon} size="XL" />
      <Text
        variant="BODY_M"
        fontWeight="semiBold"
        color="white"
        className="align-center"
      >
        {title}
      </Text>
      <Text
        variant="BODY_S"
        color="shadesForeground200"
        className="align-center"
        preWrap
      >
        {description}
      </Text>
      {actionButtonLabel ? (
        <Button
          colorVariant="primaryGreen"
          onClick={onActionButtonClick}
          sizeVariant="S"
          overRounded
        >
          {actionButtonLabel}
        </Button>
      ) : null}
    </StyledEmptyOverlay>
  );
};

export default EmptyStateOverlay;
