import { API_MAPPINGS } from 'constants/apiMappings';
import { RestService } from 'service/restService';

import { Endpoints, RequestMethod, TimescaleType } from 'enums';

export type VaultTvlHistoryResponse = {
  time: number;
  value: number;
};

const restService = RestService.get();

export function useVaultsApi() {
  const fetchVaultNavHistory = async (
    account: string,
    timescale: TimescaleType = TimescaleType.Week,
  ): Promise<VaultTvlHistoryResponse[]> => {
    try {
      const { data }: { data: VaultTvlHistoryResponse[] } =
        await restService.request<VaultTvlHistoryResponse[]>({
          method: RequestMethod.GET,
          path: `/${Endpoints.VAULT_NAV_HISTORY}?vault_wallet=${account}&range=${timescale}`,
          body: {},
          headers: {},
          isArray: true,
          responseMapping: API_MAPPINGS.VAULT_TVL_CHART_MAPPING,
        });

      if (!data) {
        throw new Error('No data returned from Vault History API call');
      }

      return data;
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  };

  return {
    fetchVaultNavHistory,
  } as const;
}

export type VaultsApi = ReturnType<typeof useVaultsApi>;
