import { useState } from 'react';

import { Box, Tab, Tabs } from '@material-ui/core';

import { AccountStats } from '../AccountStats/AccountStats';
import ContractDetails from '../ContractDetails/ContractDetails';
import { TabPanel, a11yProps } from './Tab';

import styled from 'styled-components';

const AccountAndContractDetailsTabsStyled = styled.div`
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;
  padding: 15px;

  @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
    border-top: 0;
  }
`;

export const AccountAndContractDetailsTabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabPanels = [<AccountStats />, <ContractDetails />];

  return (
    <AccountAndContractDetailsTabsStyled>
      <Box>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="Details Tab"
        >
          <Tab label="Account Stats" {...a11yProps(0)} />
          <Tab label="Contract Details" {...a11yProps(1)} />
        </Tabs>
      </Box>

      {tabPanels.map((item, index) => (
        <TabPanel value={activeTab} index={index} key={index}>
          {item}
        </TabPanel>
      ))}
    </AccountAndContractDetailsTabsStyled>
  );
};
