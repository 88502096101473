import { useCallback } from 'react';

import {
  capitalizeFirstLetter,
  getCurrencyFromTradingPair,
  showNotification,
} from 'utils';
import {
  AmendOrderParams,
  AmendSltpOrderParams,
  CancelOrderParams,
  CancelSltpOrderParams,
  CreateOrderParams,
  CreateSltpOrderParams,
} from 'utils/api';

import { getIsStopLoss } from 'components/Tables/PositionsTable/StopLossTakeProfitCancelModal';

import { useExchangeAPI } from './useExchangeAPI';
import { API_MAPPINGS } from 'constants/apiMappings';
import { useAppContext } from 'contexts/AppContext';
import { RequestResponse } from 'service/restService';

import {
  Endpoints,
  NotificationType,
  OrderStatus,
  OrderType,
  RequestMethod,
} from 'enums';
import { Order, SpecialOrder } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export function useOrdersAPI() {
  const { makePrivateRequest } = useExchangeAPI();
  const {
    store: { account: accountStore },
  } = useAppContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const createOrder = useCallback(
    async (params: CreateOrderParams): Promise<Order | boolean> => {
      try {
        const { data: order }: { data: Order } = await makePrivateRequest({
          method: RequestMethod.POST,
          endpoint: `/${Endpoints.ORDERS}`,
          requestParams: params,
          responseMapping: API_MAPPINGS.ORDER,
          paramsMapping: API_MAPPINGS.CREATE_ORDER_PARAMS,
        });

        queryClient.invalidateQueries();

        const { side, size, marketID, price, type, triggerPrice } = params;

        if (order.status === OrderStatus.REJECTED) {
          const notifTitle = t('orderCreationRejectedTitle');
          const notifDescription = t('orderCreationRejectedDescription', {
            side: capitalizeFirstLetter(side),
            marketID: marketID,
            reason: order.reason,
          });

          showNotification({
            title: notifTitle,
            description: notifDescription,
            type: NotificationType.Negative,
          });
        }
        // Don't show Placing Order Noification
        // else {
        //   const isProcessing = order.status === OrderStatus.PROCESSING;
        //   const notifTitleKey = isProcessing
        //     ? 'placingOrderTitle'
        //     : 'orderPlacedTitle';
        //   const notifTitle = t(notifTitleKey);

        //   const notifDescriptionKey = isProcessing
        //     ? 'placingOrderDescription'
        //     : `orderPlacedDescription`;

        //   let notifDescription: string;
        //   if ([OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(type)) {
        //     notifDescription = t(`${notifDescriptionKey}StopOrders`, {
        //       side: side,
        //       marketID: marketID,
        //       size: size,
        //       currency: getCurrencyFromTradingPair(marketID),
        //       triggerPrice: `$${triggerPrice}`,
        //     });
        //   } else {
        //     notifDescription = t(notifDescriptionKey, {
        //       side: side,
        //       marketID: marketID,
        //       size: size,
        //       currency: getCurrencyFromTradingPair(marketID),
        //       price: type === OrderType.LIMIT ? `$${price}` : 'MARKET',
        //     });
        //   }

        //   showNotification({
        //     title: notifTitle,
        //     description: notifDescription,
        //     type: isProcessing
        //       ? NotificationType.Info
        //       : NotificationType.Positive,
        //   });
        // }
        accountStore.createOrder(order as Order);
        return order;
      } catch (e: any) {
        console.error(e.message);
        showNotification({
          title: t('orderCreationFailedTitle'),
          description: t('orderCreationFailedDescription', {
            errorMessage: e.message,
          }),
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [accountStore, makePrivateRequest, queryClient, t],
  );

  const createSltpOrder = useCallback(
    async (params: CreateSltpOrderParams): Promise<SpecialOrder | boolean> => {
      try {
        const { data: order }: { data: SpecialOrder } =
          await makePrivateRequest({
            method: RequestMethod.POST,
            endpoint: `/${Endpoints.ORDERS}`,
            requestParams: params,
            responseMapping: API_MAPPINGS.ORDER,
            paramsMapping: API_MAPPINGS.CREATE_SLTP_ORDER_PARAMS_MAPPING,
          });

        queryClient.invalidateQueries();

        const { type } = params;

        const typeText = getIsStopLoss(type) ? 'Stop Loss' : 'Take Profit';

        if (order.status === OrderStatus.REJECTED) {
          const notifTitle = `${typeText} order creation rejected`;
          const notifDescription = `Failed to create ${typeText} order`;

          showNotification({
            title: notifTitle,
            description: notifDescription,
            type: NotificationType.Negative,
          });
        }
        // Don't show Placing Order Noification
        // else {
        //   const isProcessing = order.status === OrderStatus.PROCESSING;
        //   const notifTitle = isProcessing
        //     ? `Placing ${typeText} Order`
        //     : `${typeText} order placed successfully`;

        //   showNotification({
        //     title: notifTitle,
        //     type: isProcessing
        //       ? NotificationType.Info
        //       : NotificationType.Positive,
        //   });
        // }
        return order;
      } catch (e: any) {
        console.error(e.message);
        showNotification({
          title: t('orderCreationFailedTitle'),
          description: t('orderCreationFailedDescription', {
            errorMessage: e.message,
          }),
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [makePrivateRequest, queryClient, t],
  );

  const amendOrder = useCallback(
    async (params: AmendOrderParams): Promise<Order | boolean> => {
      try {
        const { data: order }: { data: Order } = await makePrivateRequest({
          method: RequestMethod.PUT,
          endpoint: `/${Endpoints.ORDERS}`,
          requestParams: params,
          responseMapping: API_MAPPINGS.ORDER,
          paramsMapping: API_MAPPINGS.AMEND_ORDER_PARAMS,
        });

        queryClient.invalidateQueries();

        const { marketID, status } = order;
        const { price, size } = params;

        if (status === OrderStatus.REJECTED) {
          const notifTitle = t('amendingOrderRejectedTitle');
          const notifDescription = t('amendingOrderRejectedDescription', {
            marketID: marketID,
            reason: order.reason,
          });

          showNotification({
            title: notifTitle,
            description: notifDescription,
            type: NotificationType.Negative,
          });
        }
        // Don't show Placing Order Noification
        // else {
        //   const notifTitle = t('amendingOrderTitle');
        //   const notifDescription = t('amendingOrderDescription', {
        //     marketID: marketID,
        //     size: size,
        //     currency: getCurrencyFromTradingPair(marketID),
        //     price: price,
        //   });

        //   showNotification({
        //     title: notifTitle,
        //     description: notifDescription,
        //     type: NotificationType.Info,
        //   });
        // }
        return order;
      } catch (e: any) {
        console.error(e.message);
        showNotification({
          title: t('amendingOrderFailedTitle'),
          description: t('amendingOrderFailedDescription', {
            errorMessage: e.message,
          }),
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [makePrivateRequest, queryClient, t],
  );

  const amendSltpOrder = useCallback(
    async (params: AmendSltpOrderParams): Promise<SpecialOrder | boolean> => {
      try {
        const { data: order }: { data: SpecialOrder } =
          await makePrivateRequest({
            method: RequestMethod.PUT,
            endpoint: `/${Endpoints.ORDERS}`,
            requestParams: params,
            responseMapping: API_MAPPINGS.ORDER,
            paramsMapping: API_MAPPINGS.AMEND_SLTP_ORDER_PARAMS_MAPPING,
          });

        queryClient.invalidateQueries();

        const { status } = order;

        const { type } = params;

        const typeText =
          type === OrderType.STOP_LOSS ? 'Stop Loss' : 'Take Profit';

        if (status === OrderStatus.REJECTED) {
          const notifTitle = `Amending ${typeText} order`;
          const notifDescription = `Amending ${typeText} order rejected. Reason: ${order.reason}`;

          showNotification({
            title: notifTitle,
            description: notifDescription,
            type: NotificationType.Negative,
          });
        }
        // Don't show Amending Order Noification
        //  else {
        //   const notifTitle = `Amending ${typeText} Order`;

        //   showNotification({
        //     title: notifTitle,
        //     type: NotificationType.Info,
        //   });
        // }
        return order;
      } catch (e: any) {
        console.error(e.message);
        showNotification({
          title: t('amendingOrderFailedTitle'),
          description: t('amendingOrderFailedDescription', {
            errorMessage: e.message,
          }),
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [makePrivateRequest, queryClient, t],
  );

  const cancelOrder = useCallback(
    async (params: CancelOrderParams): Promise<Order | boolean> => {
      try {
        const { data: order }: { data: Order } = await makePrivateRequest({
          method: RequestMethod.DELETE,
          endpoint: `/${Endpoints.ORDERS}`,
          requestParams: params,
          responseMapping: API_MAPPINGS.ORDER,
          paramsMapping: API_MAPPINGS.CANCEL_ORDER_PARAMS,
        });

        queryClient.invalidateQueries();

        // Don't show Cancelling Order Noification
        // const { marketID } = order;
        // const notifTitle = t('cancellingOrderTitle', { marketID });
        // const notifDescription = t('cancellingOrderDescription', {
        //   marketID,
        // });

        // showNotification({
        //   title: notifTitle,
        //   description: notifDescription,
        //   type: NotificationType.Info,
        // });

        return order;
      } catch (e: any) {
        console.error(e.message);
        showNotification({
          title: t('orderCancellationFailedTitle'),
          description: t('orderCancellationFailedDescription', {
            errorMessage: e.message,
          }),
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [makePrivateRequest, queryClient, t],
  );

  const cancelSltpOrder = useCallback(
    async (params: CancelSltpOrderParams): Promise<SpecialOrder | boolean> => {
      try {
        const { data: order }: { data: SpecialOrder } =
          await makePrivateRequest({
            method: RequestMethod.DELETE,
            endpoint: `/${Endpoints.ORDERS}`,
            requestParams: params,
            responseMapping: API_MAPPINGS.ORDER,
            paramsMapping: API_MAPPINGS.CANCEL_ORDER_PARAMS,
          });

        queryClient.invalidateQueries();

        // Don't show Amending Order Noification
        // const { marketID } = order;
        // const { type } = params;

        // const typeText = getIsStopLoss(type) ? 'Stop Loss' : 'Take Profit';

        // const notifTitle = `Cancelling ${typeText} Order`;
        // const notifDescription = `Cancelling ${typeText} Order for ${marketID} market`;

        // showNotification({
        //   title: notifTitle,
        //   description: notifDescription,
        //   type: NotificationType.Info,
        // });

        return order;
      } catch (e: any) {
        console.error(e.message);
        showNotification({
          title: t('orderCancellationFailedTitle'),
          description: t('orderCancellationFailedDescription', {
            errorMessage: e.message,
          }),
          type: NotificationType.Negative,
        });
        throw e;
      }
    },
    [makePrivateRequest, queryClient, t],
  );

  const cancelAllOrders = useCallback(async (): Promise<boolean> => {
    try {
      await makePrivateRequest({
        method: RequestMethod.DELETE,
        endpoint: `/${Endpoints.ORDERS_CANCEL_ALL}`,
        requestParams: {},
      });

      queryClient.invalidateQueries();

      // const notifTitle = t('cancellingAllOrdersTitle');
      // const notifDescription = t('cancellingAllOrdersDescription');

      // showNotification({
      //   title: notifTitle,
      //   description: notifDescription,
      //   type: NotificationType.Info,
      // });

      return true;
    } catch (e: any) {
      showNotification({
        title: t('allOrdersCancellationFailedTitle'),
        description: t('allOrdersCancellationFailedDescription', {
          errorMessage: e.message,
        }),
        type: NotificationType.Negative,
      });
      throw e;
    }
  }, [makePrivateRequest, queryClient, t]);

  const fetchPrivateOrders = useCallback(
    async ({ queryParams }): Promise<RequestResponse<Order[]>> => {
      try {
        const res = await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.ORDERS}`,
          requestParams: {},
          responseMapping: API_MAPPINGS.ORDER,
          isArray: true,
          queryParams,
          shouldCheckJwtValidity: false,
        });

        return res;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [makePrivateRequest],
  );

  return {
    createOrder,
    amendOrder,
    cancelOrder,
    cancelAllOrders,
    fetchPrivateOrders,
    createSltpOrder,
    amendSltpOrder,
    cancelSltpOrder,
  } as const;
}

export type OrdersAPI = ReturnType<typeof useOrdersAPI>;
