//  Define the tiers in a list of tuples [Volume, Boost, Level]
export const TIER_TUPLES: [number, number, string][] = [
  [0, 0.0, 'Level 1'],
  [100000, 0.04, 'Level 2'],
  [400000, 0.08, 'Level 3'],
  [1000000, 0.12, 'Level 4'],
  [8750000, 0.24, 'Level 5'],
  [64000000, 0.32, 'Level 6'],
  [460000000, 0.4, 'Level 7'],
];

export const MAX_TIER_LEVEL = 7;
