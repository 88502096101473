import Modal from 'components/Modal';

import useModal from '../../hooks/useModal';
import { Modals } from 'constants/modals';

interface Props {
  url: string;
}

export const SocialWalletModal = ({ url }: Props) => {
  const modal = useModal();

  window.addEventListener('message', event => {
    if (event.data === 'PARTICLE_WALLET_CLOSE_IFRAME') {
      modal.clear();
    }
  });

  return (
    <Modal
      showCloseIcon={false}
      showHeader={false}
      title="Mobile App Login"
      size={'custom'}
      name={Modals.socialWalletModal}
      disableCloseOnClickOutside={true}
    >
      <iframe style={{ border: 0 }} src={url} height={700} width={450} />
    </Modal>
  );
};
