import { useFillsAPI } from 'hooks';
import { useDownloadCsv } from 'hooks/useDownloadCsv';

import FillsTable from 'components/Tables/FillsTable';
import { TablePageContainer } from 'components/Tables/styles';
import Text from 'components/Text';

import Portfolio from '../../index';

import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const PortfolioTrades = () => {
  const { t } = useTranslation();
  const { fetchPrivateFills } = useFillsAPI();

  const DownloadCSV = useDownloadCsv({
    fetchFunction: fetchPrivateFills,
    fileName: 'TRADE_HISTORY',
    avoidRows: ['id', 'orderID', 'tradeID'],
    headTranslations: {
      fee: t('fee'),
      size: t('size'),
      liquidation: t('liquidation'),
      marketID: t('market'),
      price: t('price'),
      side: t('side'),
      timestamp: t('time'),
    },
  });

  return (
    <Portfolio>
      <TablePageContainer addBackground addPadding>
        <Text
          variant="HEADING_H2"
          fontWeight="semiBold"
          flexed
          className="justify-between"
        >
          Trade History
          <DownloadCSV />
        </Text>

        <FillsTable includePagination={true} showFilterOptions />
      </TablePageContainer>
    </Portfolio>
  );
};

export default observer(PortfolioTrades);
