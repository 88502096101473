import { memo } from 'react';

import { FormattedNumber } from 'components';
import NumberInput from 'components/Inputs/NumberInput';

import { getCurrencyInputValue, truncatePrice } from '../../../../../../utils';
import { InfoRowStyled } from '../styles';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { CURRENCY } from '..';
import { useTranslation } from 'react-i18next';

interface Props {
  withdrawValue: number | null;
  withdrawableBalance: number;
  setWithdrawValue: (val: number | null) => void;
}
const RequestStep = ({
  withdrawValue,
  withdrawableBalance,
  setWithdrawValue,
}: Props) => {
  const { t } = useTranslation();
  const shouldShowNotEnoughWithdrawableBalance =
    withdrawValue && withdrawValue > withdrawableBalance;

  return (
    <div className="mb-30">
      <NumberInput
        value={getCurrencyInputValue(withdrawValue)}
        label={t('size')}
        currency={CURRENCY}
        maxValue={withdrawableBalance}
        onChange={val => setWithdrawValue(truncatePrice(val, 2))}
        showSwapCurrency={false}
        showCurrencyIcon={true}
        showValueApproximation={false}
        gtmId="withdraw-modal"
        autoFocus
      />

      <InfoRowStyled className="mt-20">
        <span>{t('withdrawableBalance')}</span>
        <span>
          <FormattedNumber value={withdrawableBalance} units={0} prefix="$" />
        </span>
      </InfoRowStyled>

      {shouldShowNotEnoughWithdrawableBalance ? (
        <InfoRowStyled className="mt-20" isCentered>
          <ColoredText color={Colors.Danger}>
            {t('notEnoughWithdrawableBalance')}
          </ColoredText>
        </InfoRowStyled>
      ) : null}
    </div>
  );
};

export default memo(RequestStep);
