import {
  getCurrencyFromTradingPair,
  removeTrailingDigitsFromTradingPair,
} from 'utils';

import Badge from 'components/Badge';
import Text from 'components/Text';

import { useAppContext } from 'contexts/AppContext';

import { CurrencyKind } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  gap: 10px;

  > span {
    flex: 1;
    padding: 0px 2px;
  }
`;

const TitleRow = () => {
  const {
    store: {
      markets: { selectedMarketId },
      appState,
    },
  } = useAppContext();
  const { t } = useTranslation();

  const { defaultCurrencyKind } = appState;
  const token = getCurrencyFromTradingPair(
    removeTrailingDigitsFromTradingPair(selectedMarketId),
  );

  return (
    <Container>
      <Text flexed variant="BODY_XS" color="shadesForeground200">
        {t('price')} <Badge bgColor={'shadesBackground700'}>USD</Badge>
      </Text>
      <Text
        className="justify-end"
        flexed
        variant="BODY_XS"
        color="shadesForeground200"
      >
        {t('amount')}
        <Badge bgColor={'shadesBackground700'}>
          {defaultCurrencyKind === CurrencyKind.BASE ? token : 'USD'}
        </Badge>
      </Text>
      <Text
        className="justify-end"
        flexed
        variant="BODY_XS"
        color="shadesForeground200"
      >
        {t('total')}
        <Badge bgColor={'shadesBackground700'}>
          {defaultCurrencyKind === CurrencyKind.BASE ? token : 'USD'}
        </Badge>
      </Text>
    </Container>
  );
};

export default observer(TitleRow);
