import styled from 'styled-components';

export const Steps = styled.div`
  display: flex;
  align-self: stretch;
  padding: 10px 15px;
  flex-direction: column;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.shadesBackground700};

  .step {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 10px 0px;

    .column {
      display: flex;
      flex-direction: column;
      gap: 4px;

      @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
        gap: 0px;
      }

      > p {
        margin: 0;
      }

      .heading {
        font-weight: ${({ theme }) => theme.fontWeights.semiBold};
        font-size: 14px;
        line-height: 16px;

        @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
          font-size: 12px;
          line-height: normal;
        }
      }

      .description {
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        color: ${({ theme }) => theme.colors.shadesForeground200};
        font-size: 12px;

        @media (max-width: ${({ theme }) => theme.screenSizes.medium}px) {
          font-size: 10px;
        }
      }
    }
  }
`;
