import { memo } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import { timestampToLocalDateWithTime } from 'utils';
import { brand } from 'utils/brand';

import { PlatformVaultsDataWithHoldings } from 'hooks';

import { FormattedNumber } from 'components';
import Button from 'components/Button/button';
import Icon from 'components/Icon';
import TransferStatusCell from 'components/Tables/TransfersTable/TransferStatus';

import downloadIcon from 'assets/icons/upload.svg';
import { Row } from 'pages/Vaults/styles';

import { isUnstakeStatusCancellable } from '.';
import { VisibilityProps } from '..';
import { DepositOrWithdrawalStatus } from 'enums';

type Props = {
  vaultData: PlatformVaultsDataWithHoldings | undefined;
  onCancel: (opsId: string, shares: number) => Promise<void>;
  status: DepositOrWithdrawalStatus;
  shares: number;
  opsId: string;
  timestamp: number;
} & VisibilityProps;
const UnstakeRequestRow = ({
  vaultData,
  status,
  shares,
  opsId,
  timestamp,
  onCancel,
  hideManagerColumn,
}: Props) => {
  const isCancellable = isUnstakeStatusCancellable(status);

  if (!vaultData) return null;

  return (
    <TableRow>
      <TableCell>
        <Row className="gap-5">
          <Icon src={downloadIcon} alt="stake" />
          Unstake
        </Row>
      </TableCell>

      <TableCell>
        <Row className="gap-10">
          <Row className="gap-5 items-center">
            <Icon src={brand.tokenLogo} size={'S'} />
            {vaultData.vaultName}
          </Row>
        </Row>
      </TableCell>

      {!hideManagerColumn ? (
        <TableCell>
          <Row className="gap-5">{vaultData.managerName}</Row>
        </TableCell>
      ) : undefined}

      <TableCell>
        <Row className="gap-5">
          <Icon src={brand.tokenLogo} alt={brand.tokenTicker} />
          {brand.tokenTicker}
        </Row>
      </TableCell>

      <TableCell>
        <FormattedNumber
          value={shares * vaultData.sharePrice}
          prefix="$"
          decimalScale={2}
        />
      </TableCell>

      <TableCell>
        <FormattedNumber value={shares} suffix=" Shares" decimalScale={2} />
      </TableCell>

      <TableCell>
        <Row className="gap-5">{<TransferStatusCell status={status} />}</Row>
      </TableCell>

      <TableCell>{timestampToLocalDateWithTime(timestamp)}</TableCell>

      <TableCell align="right">
        <Row className="justify-end">
          {isCancellable ? (
            <Button
              sizeVariant="S"
              colorVariant="secondary"
              border="shadesBackground500"
              background="transparent"
              onClick={() => onCancel(opsId, shares)}
            >
              Cancel
            </Button>
          ) : null}
        </Row>
      </TableCell>
    </TableRow>
  );
};

export default memo(UnstakeRequestRow);
