import { useState } from 'react';

import { Box, Tab, Tabs } from '@material-ui/core';

import TradingChart from '../TradingChart';
import FundingRates from './FundingRates';
import { TabPanel, a11yProps } from './TradingChartTab';
import { TradingChartTabsStyled } from './styles';

import { useTranslation } from 'react-i18next';

const TradingHistoryTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  return (
    <TradingChartTabsStyled>
      <Box>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="Trading Chart Area Tab"
        >
          <Tab
            data-testid="tab-price"
            data-gtmid="tab-price"
            data-cy="tab-price"
            label={t('price')}
            {...a11yProps(0)}
          />
          <Tab
            data-testid="tab-funding-rate"
            data-gtmid="tab-funding-rate"
            data-cy="tab-funding-rate"
            label={t('fundingRate')}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        <TradingChart />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <FundingRates />
      </TabPanel>
    </TradingChartTabsStyled>
  );
};

export default TradingHistoryTabs;
