import { getCurrencyFromTradingPair } from './helpers';
import btcLogo from 'assets/assetLogos/btc.svg';
import defaultLogo from 'assets/assetLogos/usd.svg';
import usdtLogo from 'assets/assetLogos/usdt.svg';
import { useAppContext } from 'contexts/AppContext';

export const getMarketLogoFromUrl = (iconUrl: string) => {
  if (iconUrl) {
    return iconUrl;
  }
  return defaultLogo;
};

export const useGetMarketLogoFromMarketId = (marketId: string | undefined) => {
  const {
    store: {
      markets: { _logosUrls },
    },
  } = useAppContext();

  if (!marketId) {
    return defaultLogo;
  }

  const currency = getCurrencyFromTradingPair(marketId).toLowerCase();

  if (currency === 'btc') {
    return btcLogo;
  }
  if (currency === 'usdt') {
    return usdtLogo;
  }

  let url;
  if (_logosUrls) {
    url = _logosUrls[currency];
  }
  const logo = url ? url : defaultLogo;

  return logo;
};
