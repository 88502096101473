import { Box } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';

import { brand } from 'utils/brand';

import LinearProgressBar from 'components/LinearProgressBar';

import { DepositSteps } from '../../utils';
import { Row, WaitingSection } from '../styles';
import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';

import { useTranslation } from 'react-i18next';

const getProgressPercentage = (step: DepositSteps, confirmations: number) => {
  if (step !== DepositSteps.PROCESSING_DEPOSIT) return 0;

  return (confirmations / CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT) * 100;
};

interface Props {
  depositAmount: number | null;
  step: DepositSteps;
  confirmations: number;
}

const WaitingStep = ({ depositAmount, step, confirmations }: Props) => {
  const { t } = useTranslation();
  const progressPercentage = getProgressPercentage(step, confirmations);

  return (
    <WaitingSection flex={1}>
      <Row>
        <AccessTime />
        <label>
          {t('depositTokenConfirmation', {
            depositAmount: depositAmount,
            total: CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
            token: brand.tokenTicker,
          })}
        </label>
      </Row>

      <Box className="linear-progress-container">
        <LinearProgressBar
          percent={progressPercentage}
          customBarColor="#d43b4e"
        />
      </Box>

      <Row className="mt-10">
        <span>
          {t('currentDepositConfirmations', {
            confirmations: confirmations,
            total: CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT,
          })}
        </span>
      </Row>
    </WaitingSection>
  );
};

export default WaitingStep;
