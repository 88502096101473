import { memo } from 'react';

import { brand } from 'utils/brand';

import { FormattedNumber } from 'components';

import { TransakOrderStatus, TransakPaymentMethod } from '../..';

import styled from 'styled-components';

const Container = styled.div`
  > p {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

const getEstimatedTimeText = (transakPaymentMethod: TransakPaymentMethod) => {
  if (
    ['sepa_bank_transfer', 'gbp_bank_transfer', 'inr_bank_transfer'].includes(
      transakPaymentMethod,
    )
  ) {
    return '3-5 business days';
  }
  return 'less than 10 minutes';
};

type Props = {
  depositAmount: number;
  status: TransakOrderStatus | undefined;
  paymentMethod: TransakPaymentMethod;
};
const TransakStatus = ({ depositAmount, status, paymentMethod }: Props) => {
  let statusText = '';

  if (status) {
    if (status === TransakOrderStatus.PROCESSING) {
      statusText = 'created and is currently being processed';
    }
    if (status === TransakOrderStatus.AWAITING_DELIVERY) {
      statusText = 'processed, waiting for delivery from transak';
    }
  }

  return (
    <Container>
      <p>
        Your{' '}
        <FormattedNumber
          value={depositAmount}
          suffix={` ${brand.tokenTicker}`}
          decimalScale={6}
        />{' '}
        deposit has been {statusText}. Keep this pop-up open to track deposit
        confirmations. An email will be sent for completed Transak transactions,
        and the estimated time is {getEstimatedTimeText(paymentMethod)}.
      </p>
    </Container>
  );
};

export default memo(TransakStatus);
