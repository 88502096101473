import { useCallback, useEffect, useState } from 'react';

import { showNotification } from 'utils';

import {
  LIMIT_EXCESSIVE_ARRAY_FOR_SCROLL,
  QUERY_REFETCH_INTERVAL,
} from '../constants/general';
import { deepMergeArraysById } from '../utils/state';
import { QueryParams } from 'service/restService';

import { NotificationType } from 'enums';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

const resetPagination = {
  page: 0,
  limit: 10,
};

interface usePaginationFetchProps {
  fetchFn: ({
    queryParams,
  }: {
    queryParams: QueryParams;
  }) => Promise<{ data: any[]; pagination: any }>;
  socketSource?: any;
  initialPagination?: Pagination;
  currentAuthenticatedWallet?: string;
  requestQueryParams?: QueryParams;
  isOnboarding?: boolean;
  allowNewItemsFromSocket?: boolean;
  queryKey: string;
  disableRefetchInterval?: boolean;
  queryOptions?: {
    refetchInterval?: number;
    staleTime?: number;
  };
  includePagination?: boolean;
}

export interface Pagination {
  page: number;
  limit: number;
}
interface usePaginationFetchReturn<T> {
  isLoading: boolean;
  data: T[];
  isError: boolean;
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  totalCount: number | null;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * This hook is used to fetch data from the API using private requests when
 * the pagination changes or when the authenticated wallet changes
 * @param fetchFn - function that fetches data from the API
 * @param initialPagination - initial pagination state
 * @param currentAuthenticatedWallet - current authenticated wallet
 * @param requestQueryParams - query params that are passed to the fetch function
 * @template T - type of the data that is fetched
 * @returns - object containing the data, pagination state, and functions to change the pagination state
 */
export const usePaginationFetch = <
  T extends {
    id: string | number;
    timestamp?: number;
    createdAt?: number;
  },
>({
  fetchFn,
  socketSource,
  initialPagination = {
    page: 0,
    limit: 10,
  },
  currentAuthenticatedWallet,
  requestQueryParams,
  isOnboarding,
  allowNewItemsFromSocket = true,
  queryKey,
  disableRefetchInterval = false,
  queryOptions = {
    refetchInterval: QUERY_REFETCH_INTERVAL,
  },
  includePagination,
}: usePaginationFetchProps): usePaginationFetchReturn<T> => {
  const queryClient = useQueryClient();
  const { page = 0, limit = 10 } = initialPagination ?? {};

  const getQueryData = () =>
    queryClient.getQueryData([
      queryKey,
      {
        page: initialPagination.page,
        limit: initialPagination.limit,
        params: requestQueryParams,
      },
    ]) as { data: T[]; pagination: { page: number; total: number } };

  const [data, setData] = useState<T[]>((getQueryData()?.data || []) as T[]);
  const { t } = useTranslation();

  const [pagination, setPagination] = useState<Pagination>({
    ...initialPagination,
    page,
    limit,
  });
  const [totalCount, setTotalCount] = useState<number | null>(
    getQueryData()?.pagination?.total || null,
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPagination({ ...pagination, limit: +event.target.value, page: 0 });
  };

  const {
    isLoading,
    data: queryData,
    error,
    isPreviousData,
  } = useQuery(
    [
      queryKey,
      {
        page: pagination.page,
        limit: pagination.limit,
        params: requestQueryParams,
      },
    ],
    async () =>
      await fetchFn({
        queryParams: {
          p_page: pagination.page,
          p_limit: pagination.limit,
          ...requestQueryParams,
        },
      }),
    {
      enabled: !isOnboarding && !!currentAuthenticatedWallet,
      refetchInterval: disableRefetchInterval
        ? false
        : queryOptions.refetchInterval,
      staleTime: queryOptions.staleTime,
      onError: () => {
        console.log(error);
        setPagination(resetPagination);
        setTotalCount(0);
        setData([]);
        showNotification(
          {
            title: t('fetchDataErrorTitle', { queryKey }),
            description: t('fetchDataErrorDescription', { queryKey }),
            type: NotificationType.Negative,
          },
          true,
        );
      },
    },
  );

  // Prefetch next page
  useEffect(() => {
    if (!includePagination) return;
    if (isOnboarding || !currentAuthenticatedWallet) return;

    const nextPage = pagination.page + 1;
    if (
      !isPreviousData &&
      totalCount !== null &&
      // Don't prefetch if we have no more pages
      nextPage * pagination.limit < totalCount
    ) {
      queryClient.prefetchQuery({
        queryKey: [
          queryKey,
          {
            page: nextPage,
            limit: pagination.limit,
            params: requestQueryParams,
          },
        ],
        queryFn: async () =>
          await fetchFn({
            queryParams: {
              p_page: nextPage,
              p_limit: pagination.limit,
              ...requestQueryParams,
            },
          }),
      });
    }
  }, [
    queryData,
    isPreviousData,
    queryClient,
    fetchFn,
    pagination.page,
    pagination.limit,
    queryKey,
    requestQueryParams,
    totalCount,
    isOnboarding,
    currentAuthenticatedWallet,
    includePagination,
  ]);

  // API query data changed
  useEffect(() => {
    if (!queryData) {
      return;
    }

    const {
      data: newQueryData,
      pagination: { page, total },
    } = queryData;

    setPagination(prevPagination => ({
      ...prevPagination,
      page,
    }));

    setTotalCount(total ?? null);

    setData(
      deepMergeArraysById(newQueryData, socketSource, allowNewItemsFromSocket),
    );
  }, [queryData, allowNewItemsFromSocket, socketSource]);

  const mergeArraysById = useCallback(
    (data: T[], socketSource: T[], allowNewItemsFromSocket: boolean) => {
      let result = deepMergeArraysById(
        data,
        socketSource,
        allowNewItemsFromSocket,
      );
      if (result?.length > LIMIT_EXCESSIVE_ARRAY_FOR_SCROLL) {
        result = result.slice(0, LIMIT_EXCESSIVE_ARRAY_FOR_SCROLL);
      }
      return result;
    },
    [],
  );

  // Websocket data changed
  useEffect(() => {
    if (socketSource) {
      setData(prevData =>
        mergeArraysById(prevData, socketSource, allowNewItemsFromSocket),
      );
    }
  }, [socketSource, allowNewItemsFromSocket, mergeArraysById]);

  return {
    isLoading: isLoading && totalCount !== null,
    data,
    isError: !!error,
    pagination,
    setPagination,
    totalCount,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
