import { Markets } from 'enums';

export interface DecimalScale {
  price: number;
  priceScale: number;
  size: number;
  minTick: number;
  minOrder: number;
}

export const MAP_MARKET_ID_TO_COINGECKO_ID = {
  [Markets.BTC_USD]: 'bitcoin',
  [Markets.ETH_USD]: 'ethereum',
  [Markets.SOL_USD]: 'solana',
  [Markets.ARB_USD]: 'arbitrum',
  [Markets.MATIC_USD]: 'matic-network',
  [Markets.DOGE_USD]: 'dogecoin',
  [Markets.LDO_USD]: 'lido-dao',
  [Markets.SUI_USD]: 'sui',
  [Markets.PEPE1000_USD]: 'pepe',
  [Markets.BCH_USD]: 'bitcoin-cash',
  [Markets.XRP_USD]: 'ripple',
  [Markets.WLD_USD]: 'worldcoin-wld',
  [Markets.TON_USD]: 'the-open-network',
  [Markets.STX_USD]: 'blockstack',
  [Markets.TRB_USD]: 'tellor',
  [Markets.APT_USD]: 'aptos',
  [Markets.INJ_USD]: 'injective-protocol',
  [Markets.AAVE_USD]: 'aave',
  [Markets.LINK_USD]: 'link',
  [Markets.BNB_USD]: 'binancecoin',
  [Markets.RNDR_USD]: 'render-token',
  [Markets.MKR_USD]: 'maker',
  [Markets.RLB_USD]: 'rollbit-coin',
  [Markets.ORDI_USD]: 'ordinals',
  [Markets.STG_USD]: 'stargate-finance',
  [Markets.SATS1000000_USD]: 'sats-ordinals',
  [Markets.TIA_USD]: 'celestia',
  [Markets.BLUR_USD]: 'blur',
  [Markets.JTO_USD]: 'jito-governance-token',
  [Markets.MEME_USD]: 'memecoin-2',
  [Markets.SEI_USD]: 'sei-network',
  [Markets.FIL_USD]: 'filecoin',
  [Markets.OP_USD]: 'optimism',
  [Markets.AVAX_USD]: 'avalanche-2',
  [Markets.YES_USD]: 'yes-money',
  [Markets.WIF_USD]: 'dogwifcoin',
  [Markets.STRK_USD]: 'starknet',
  [Markets.SHIB1000_USD]: 'shiba-inu',
  [Markets.BOME_USD]: 'book-of-meme',
  [Markets.SLERF_USD]: 'slerf',
  [Markets.W_USD]: 'wormhole',
  [Markets.ENA_USD]: 'ethena',
  [Markets.PAC_USD]: 'pacmoon',
  [Markets.MAGA_USD]: 'maga-hat',
  [Markets.TRUMP_USD]: 'maga',
  [Markets.MOG1000_USD]: 'mog-coin',
  [Markets.NOT_USD]: 'notcoin',
  [Markets.MOTHER_USD]: 'mother-iggy',
  [Markets.BONK1000_USD]: 'bonk',
  [Markets.TAIKO_USD]: 'taiko',
  [Markets.FLOKI1000_USD]: 'floki',
  [Markets.BLAST_USD]: 'blast',
  [Markets.PENDLE_USD]: 'pendle',
  [Markets.JUP_USD]: 'jupiter-exchange-solana',
  [Markets.FTM_USD]: 'fantom',
};

export const MAP_COINGECKO_ID_TO_MARKET_ID = Object.fromEntries(
  Object.entries(MAP_MARKET_ID_TO_COINGECKO_ID).map(([key, value]) => [
    value,
    key,
  ]),
);

function getCommaSeparatedMarketIds(obj: { [key: number]: string }): string {
  return Object.values(obj).join(',');
}

export const coingeckoMarketIds = getCommaSeparatedMarketIds(
  MAP_MARKET_ID_TO_COINGECKO_ID,
);
