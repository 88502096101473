import { memo, useCallback, useEffect, useState } from 'react';

import { showNotification, verifyUserSession } from 'utils';
import { brand } from 'utils/brand';

import { useAccount, useVerifyChainId } from 'hooks';
import { useDepositsAPI } from 'hooks/useDepositsAPI';

import claimArrowsIcon from 'assets/icons/claim-arrows.svg';
import { useAppContext } from 'contexts/AppContext';

import { NotificationType } from 'enums';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 14px;
`;

const ClaimButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 6px;
  background: #009978;
  border-radius: 8px;
  > span {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
  }

  img {
    transition: transform 0.3s;
  }

  &:hover {
    img {
      transform: translateX(5px);
    }
  }
`;

const getButtonLabel = (
  withdrawableBalance: number | null,
  isClaiming: boolean,
  isPendingTxSign: boolean,
) => {
  if (isPendingTxSign) {
    return 'Pending...';
  }
  if (isClaiming) {
    return 'Claiming...';
  }
  return `Claim All (${withdrawableBalance} ${brand.tokenTicker})`;
};

interface Props {
  withdrawableBalance: number | null;
  setWithdrawableBalance: (withdrawableBalance: number | null) => void;
  updateWithdrawableBalance: () => Promise<void>;
}

const ClaimWithdrawableBalanceButton = ({
  withdrawableBalance,
  setWithdrawableBalance,
  updateWithdrawableBalance,
}: Props) => {
  const { t } = useTranslation();
  const { account } = useAccount();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { withdrawWithdrawableBalanceOld } = useDepositsAPI();
  const { validateNetworkAndSwitchIfRequired } = useVerifyChainId();

  const [isPendingTxSign, setIsPendingTxSign] = useState<boolean>(false);
  const [isClaiming, setIsClaiming] = useState<boolean>(false);

  const resetAll = useCallback(() => {
    setIsClaiming(false);
    setIsPendingTxSign(false);
  }, []);

  useEffect(() => {
    resetAll();

    if (!frontendSecrets?.jwt) {
      return;
    }

    updateWithdrawableBalance();

    // Intervally fetch the withdrawable balance and update the state
    const interval = setInterval(async () => {
      updateWithdrawableBalance();
    }, 5000);

    return () => clearInterval(interval);
  }, [resetAll, frontendSecrets?.jwt, updateWithdrawableBalance]);

  /**
   * Handles the claim all button click
   */
  const handleClaimAll = async () => {
    // if (!account || !withdrawableBalance) return;
    if (!account) return;
    if (!(await validateNetworkAndSwitchIfRequired('Try to claim all again'))) {
      return;
    }

    try {
      // Flush sync to update the button state before the transaction is sent
      flushSync(() => {
        setIsPendingTxSign(true);
      });

      // Call the withdraw function on the L1 smart contract
      const tx = await withdrawWithdrawableBalanceOld();

      flushSync(() => {
        setIsPendingTxSign(false);
        setIsClaiming(true);
      });

      // Wait for the transaction to be mined
      const minedTx = await tx.wait();

      // Check if the logged in user is the same as the user who initiated the transaction
      if (!verifyUserSession(account)) {
        return;
      }

      if (minedTx?.status !== 1) {
        throw new Error('Withdrawal failed');
      }

      // fetch and update the withdrawable balance
      await updateWithdrawableBalance();

      showNotification({
        title: t('withdrawalSuccessful'),
        description: t('withdrawalSuccessfulDescription'),
        type: NotificationType.Positive,
      });
    } catch (e: any) {
      console.error(e);
      showNotification({
        title: t('withdrawalFailed'),
        description: t('withdrawalFailedDescription'),
        type: NotificationType.Negative,
      });
    } finally {
      resetAll();
    }
  };

  const shouldDisableClaimAll =
    isPendingTxSign ||
    isClaiming ||
    !withdrawableBalance ||
    withdrawableBalance === 0;

  if (!account || withdrawableBalance === 0) {
    return null;
  }

  if (withdrawableBalance === null) {
    return null;
  }

  return (
    <Container>
      {account ? (
        <ClaimButton
          onClick={handleClaimAll}
          disabled={shouldDisableClaimAll}
          data-gtmid="button-claim-all"
        >
          {getButtonLabel(withdrawableBalance, isClaiming, isPendingTxSign)}
          {withdrawableBalance > 0 ? (
            <img src={claimArrowsIcon} alt="Arrows" />
          ) : null}
        </ClaimButton>
      ) : null}
    </Container>
  );
};
export default memo(ClaimWithdrawableBalanceButton);
