import { memo, useEffect, useRef, useState } from 'react';

import { timeAgo } from 'utils';
import { brand, isBrandRabbitX } from 'utils/brand';

import { Icon } from 'components';
import Loading from 'components/Loading';
import Text from 'components/Text';

import {
  Container,
  Dot,
  HeaderStyled,
  MenuBadge,
  NewsItem,
  NewsItemList,
  NewsItemSource,
  TheTieLogo,
} from './styles';
import lightningIcon from 'assets/icons/lightning.svg';
import placeholderImgBfx from 'assets/images/bfx-placeholder.png';
import placeholderImgRabbitx from 'assets/images/rabbitx-placeholder.png';
import theTieLogo from 'assets/logos/the-tie-logo.svg';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import axios from 'axios';
import { config } from 'config';
import { QueryKeys } from 'enums';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

const placeholderImg = isBrandRabbitX
  ? placeholderImgRabbitx
  : placeholderImgBfx;

export type NewsFeedItem = {
  link_hash: string;
  link: string;
  headline: string;
  metadata: {
    image: string;
    description: string;
  };
  sources: {
    name: string;
  }[];
  date: string;
  is_trending_news: boolean;
};

export const feedNameToId = {
  discover: 12706,
  trending: 12708,
};

export const fetchNews = async (newsFeedId: number, limit = 100) => {
  const { status, data } = await axios.get(
    `${config.newsFeed.url}?news_feed_id=${newsFeedId}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${config.newsFeed.apiKey}`,
      },
    },
  );

  if (status !== 200) {
    throw new Error('An error occurred while fetching news');
  }

  return data;
};

const NewsList = () => {
  const [selectedMenu, setSelectedMenu] = useState<'discover' | 'trending'>(
    'discover',
  );
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        const headerPosition = headerRef.current.getBoundingClientRect().top;
        if (headerPosition <= 0) {
          setIsMenuFixed(true);
        } else {
          setIsMenuFixed(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Prefetch the "trending" news feed when the component mounts
  useEffect(() => {
    queryClient.prefetchQuery([QueryKeys.NewsFeed, 'trending'], () =>
      fetchNews(feedNameToId['trending']),
    );
  }, [queryClient]);

  const { data, isLoading, isError } = useQuery(
    [QueryKeys.NewsFeed, selectedMenu],
    () => fetchNews(feedNameToId[selectedMenu]),
    {
      // 30 seconds
      refetchInterval: 30 * 1000,
    },
  );

  const renderHeader = () => {
    return (
      <HeaderStyled ref={headerRef}>
        <Text variant="BODY_L" fontWeight="semiBold">
          {t('recommendedForYou')}
        </Text>
        <div className={`menu ${isMenuFixed ? 'fixed' : ''}`}>
          <div>
            <MenuBadge
              onClick={() => setSelectedMenu('discover')}
              isSelected={selectedMenu === 'discover'}
            >
              👀 {t('discover')}
            </MenuBadge>
            <MenuBadge
              onClick={() => setSelectedMenu('trending')}
              isSelected={selectedMenu === 'trending'}
              className="ml-15"
            >
              🔥 {t('trending')}
            </MenuBadge>
          </div>

          {brand.featureFlags.poweredByTheTieNews && (
            <TheTieLogo>
              <Icon size="M" src={lightningIcon} alt="lightning" />
              <Text
                variant="BODY_M"
                fontWeight="semiBold"
                color="shadesForeground200"
              >
                {t('poweredBy')}
              </Text>
              <img src={theTieLogo} alt="The Tie logo" />
            </TheTieLogo>
          )}
        </div>
      </HeaderStyled>
    );
  };

  if (isLoading) {
    return (
      <Container>
        {renderHeader()}
        <div className="text-center">
          <Loading />
        </div>
      </Container>
    );
  }

  if (isError) {
    return (
      <Container>
        {renderHeader()}
        <ColoredText color={Colors.Danger}>
          An error has occurred while fetching news. Try refreshing the page.
        </ColoredText>
      </Container>
    );
  }

  return (
    <Container>
      {renderHeader()}
      <NewsItemList>
        {data.data.map((newsItem: NewsFeedItem) => {
          const source = newsItem.sources[0] ?? {};
          return (
            <NewsItem key={newsItem.link_hash}>
              <a
                href={newsItem.link}
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                <img
                  src={
                    newsItem?.metadata?.image
                      ? newsItem.metadata.image
                      : placeholderImg
                  }
                  alt="news source logo"
                  className="news-item__image"
                />
              </a>
              <a
                href={newsItem.link}
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                <div className="news-item__content">
                  <Text
                    variant="BODY_M"
                    fontWeight="semiBold"
                    className="content__title"
                  >
                    {newsItem.headline}
                  </Text>
                  {newsItem?.metadata?.description && (
                    <Text
                      variant="BODY_S"
                      color="shadesForeground200"
                      className="content__description"
                    >
                      {newsItem.metadata.description}
                    </Text>
                  )}
                  <NewsItemSource className="content__source">
                    {/* <img src={newsItem.sourceLogo} alt="news item" /> */}
                    <Text variant="BODY_S" color="white" fontWeight="semiBold">
                      {source.name ?? ''}
                    </Text>
                    <Dot />
                    {newsItem.date && (
                      <Text variant="BODY_S" color="shadesForeground200">
                        {timeAgo(new Date(newsItem.date))}
                      </Text>
                    )}
                    {/* <span>{formatDateToLocal(new Date(newsItem.date))}</span> */}
                  </NewsItemSource>
                </div>
              </a>
            </NewsItem>
          );
        })}
      </NewsItemList>
    </Container>
  );
};

export default memo(NewsList);
