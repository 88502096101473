import { notifications, showNotificationIfAllChannelsSubscribed } from 'utils';
import { deserializeSingle } from 'utils/apiMiddleware';

import { API_MAPPINGS } from 'constants/apiMappings';
import CentrifugeService from 'service/centrifugeService';
import MarketsStore from 'stores/markets';
import OrderbooksStore from 'stores/orderbook';
import TradesStore from 'stores/tradesStore';

import {
  PublicationContext,
  State,
  SubscribedContext,
  UnsubscribedContext,
} from 'centrifuge';
import { WebsocketChannels } from 'enums';
import { Market } from 'interfaces';

const handlePublicationEvent = (
  marketsStore: MarketsStore,
  data: PublicationContext,
) => {
  const deserializedMarket = deserializeSingle<Market>(
    data,
    API_MAPPINGS.MARKET,
  );
  marketsStore.setSelected(deserializedMarket);
  marketsStore.setIsLoading(false);
};

const handleSubscribedEvent = (
  marketsStore: MarketsStore,
  centrifugeService: CentrifugeService,
  previousSelectedMarket: React.MutableRefObject<string | null>,
  data: SubscribedContext,
) => {
  if (data.wasRecovering) {
    showNotificationIfAllChannelsSubscribed(centrifugeService);
  }

  const deserializedMarket = deserializeSingle<Market>(
    data.data,
    API_MAPPINGS.MARKET,
  );

  marketsStore.setSelected(deserializedMarket);
  previousSelectedMarket.current = marketsStore.selectedMarket?.id!;
  marketsStore.setIsLoading(false);
};

let marketUpdateInterval: any;

interface Props {
  tradesStore: TradesStore;
  marketsStore: MarketsStore;
  orderbookStore: OrderbooksStore;
  previousSelectedMarket: React.MutableRefObject<string | null>;
}
export const handleMarketsChannelSubscription = ({
  tradesStore,
  marketsStore,
  orderbookStore,
  previousSelectedMarket,
}: Props) => {
  if (!tradesStore || !marketsStore.selectedMarket?.id || !orderbookStore) {
    return;
  }

  const centrifugeService = CentrifugeService.getPublic();

  if (previousSelectedMarket.current) {
    centrifugeService.removeSubscription(
      `${WebsocketChannels.Markets}:${previousSelectedMarket.current}`,
    );
    clearInterval(marketUpdateInterval);
  }

  tradesStore.subscribe(marketsStore.selectedMarket.id, centrifugeService);
  orderbookStore.subscribe(marketsStore.selectedMarket.id, centrifugeService);

  const sub = centrifugeService.addSubscription(
    `${WebsocketChannels.Markets}:${marketsStore.selectedMarket?.id}`,
  );

  sub.on('unsubscribed', (data: UnsubscribedContext) => {
    marketsStore.setIsLoading(true);

    // If we manually called "unsubscribe", don't show notification
    if (data.code === 0) return;

    const state = centrifugeService.centrifuge.state;
    if (state === State.Connected) {
      notifications.couldNotReEstablishConnectionToData('live market data');
    }
  });

  sub.on('subscribing', (data: any) => {
    // code === 0 on the initial subscribing event
    if (data.code === 0) {
      return;
    }

    marketsStore.setIsLoading(true);
    const centrifugeClientState = centrifugeService.centrifuge.state;

    // Don't show if the client is disconnected, as this means that all channels were unsubscribed
    // and in this case we show a general error that all public data connection was lost
    if (centrifugeClientState === State.Connected) {
      notifications.dataConnectionLost('live market');
    }
  });

  //Initial data
  sub.on('subscribed', (data: SubscribedContext) => {
    handleSubscribedEvent(
      marketsStore,
      centrifugeService,
      previousSelectedMarket,
      data,
    );
  });

  sub.on('publication', ({ data }: PublicationContext) => {
    handlePublicationEvent(marketsStore, data);
  });
};
