import { memo } from 'react';

import { Tooltip } from '@material-ui/core';

import { FormattedNumber } from 'components';
import LinearProgressBar from 'components/LinearProgressBar';
import Text from 'components/Text';

import { CARD_LEVELS } from '../RewardsHub/cardLevels';

import {
  getReferralLevelAccumulatedBonuses,
  getReferralLevelComission,
} from '.';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;

  .progress-bar {
    display: flex;
    gap: 10px;
    align-items: center;

    .progress-bar-container {
      position: relative;
      flex: 1;

      .bar {
        height: 6px;
      }

      .progress-bar-labels {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
      }
    }
  }
`;

const StyledNextLevelTooltip = styled(Text)`
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.shadesBackground700};
`;

const TooltipContainer = styled.div``;

type Props = {
  referredCurrent: number;
  referredGoal: number | undefined;
  nextLevel?: number;
  progress: number;
};
const ReferredGoalProgressBar = ({
  referredCurrent,
  referredGoal,
  nextLevel,
  progress,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="progress-bar">
        <div className="progress-bar-container">
          <LinearProgressBar
            percent={progress * 100}
            customBarColor="#fff"
            barClass="bar"
          />
          <div className="progress-bar-labels">
            <Text variant="BODY_S">
              {t('Referred')}:{' '}
              <FormattedNumber value={referredCurrent} prefix="$" />
            </Text>
            <Text variant="BODY_S">
              {t('Goal')}: <FormattedNumber value={referredGoal} prefix="$" />
            </Text>
          </div>
        </div>
        {nextLevel === undefined ? null : (
          <Tooltip
            title={
              <TooltipContainer>
                <div>
                  <span>{t('CashReward')}</span>
                  <br />
                  <span>
                    $
                    {getReferralLevelAccumulatedBonuses(
                      nextLevel ?? 1,
                      CARD_LEVELS,
                    )}
                  </span>
                </div>
                <br />
                <div>
                  <span>{t('ReferralCommission')}</span>
                  <br />
                  <span>
                    {getReferralLevelComission(nextLevel ?? 1, CARD_LEVELS)}%
                  </span>
                </div>
              </TooltipContainer>
            }
            disableHoverListener={nextLevel === undefined}
          >
            <StyledNextLevelTooltip variant="BODY_M" fontWeight="semiBold">
              {t('Level')} {nextLevel ?? '--'}
            </StyledNextLevelTooltip>
          </Tooltip>
        )}
      </div>
    </Container>
  );
};

export default memo(ReferredGoalProgressBar);
