import { TextVariant } from 'components/Text/variant';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;
  padding: 15px;
  margin-top: 5px;

  .link {
    display: block;
    text-decoration: none;
  }
`;

export const HeaderStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .menu {
    display: flex;
    justify-content: space-between;
  }

  .menu.fixed {
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.shadesBackground800};
    padding: 30px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 -14px;
    padding: 15px;

    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: calc(100% - 58px);

    @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
      width: calc(100% - 116px + 58px);
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.xl}px) {
      width: calc(100% - 146px + 58px);
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.medium}px) {
      width: calc(100% - 190px - 276px + 58px);
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      // don't ask...
      width: calc(calc(100% - 360px - 193px + 58px));
    }
  }
`;

type Props = {
  isSelected?: boolean;
};
export const MenuBadge = styled.div<Props>`
  display: inline-block;
  padding: 10px;
  gap: 10px;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.shadesBackground700 : 'transparent'};
  border-radius: 8px;
  cursor: pointer;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.white : theme.colors.shadesForeground200};
  font-size: ${TextVariant['BODY_M'].fontSize};
  line-height: ${TextVariant['BODY_M'].lineHeight};
  font-weight: ${({ theme, isSelected }) =>
    isSelected ? theme.fontWeights.semiBold : theme.fontWeights.medium};

  :hover {
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }
`;

export const NewsItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  }
`;

export const NewsItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  cursor: pointer;

  .news-item__image {
    width: 100%;
    aspect-ratio: 2;
    object-fit: cover;
    transition: transform 0.3s ease;
    border-radius: 20px;
  }

  .news-item__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding-top: 20px;

    h3.content__title,
    span.content__description {
      overflow: hidden;
      display: -webkit-box; /* Necessary for line-clamp to work */
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
    }
  }

  :hover {
    h3.content__title {
      color: #fff;
    }
    .news-item__image {
      transform: scale(1.03);
    }
  }
`;
export const NewsItemSource = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;

  > img {
    width: 12px;
    height: 12px;
  }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  background-color: #828da4;
  border-radius: 50%;
  display: inline-block;
`;

export const TheTieLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  /* The tie logo */
  > img:last-child {
    height: 11.5px;
  }
`;
