import styled from 'styled-components';

export const GeneralStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .item {
    /* padding: 10px; */
    gap: 2px;
    align-items: flex-start;
  }

  .icon-12 {
    height: 12px;
    width: 12px;
  }
`;
