import { MouseEventHandler } from 'react';

import chevronDownIcon from 'assets/icons/chevron-down-gray.svg';
import chevronUpIcon from 'assets/icons/chevron-up-gray.svg';

import styled, { css } from 'styled-components';

type ContainerProps = { isCollapsed: boolean };
const Container = styled.div<ContainerProps>`
  display: flex;
  cursor: pointer;
  width: 16px;
  flex-direction: column;
  height: 35.5px;
  justify-content: center;
  transition: all ease-in-out 0.1s;
  gap: 2px;

  .top-icon {
    margin-bottom: -4.5px;
  }

  .bottom-icon {
    margin-top: -4.5px;
  }

  :hover {
    gap: ${({ isCollapsed }) => (isCollapsed ? '4px' : '0px')};
  }
`;

type Props = {
  isCollapsed: boolean;
  onPress?: MouseEventHandler<HTMLDivElement> | undefined;
};
const CollapseIcon = ({ isCollapsed, onPress }: Props) => {
  const topIcon = isCollapsed ? chevronUpIcon : chevronDownIcon;
  const bottomIcon = isCollapsed ? chevronDownIcon : chevronUpIcon;
  return (
    <Container
      isCollapsed={isCollapsed}
      onClick={onPress}
      className="collapse-icon"
    >
      <img className="top-icon" alt="chevron-up" src={topIcon} />
      <img className="bottom-icon" alt="chevron-down" src={bottomIcon} />
    </Container>
  );
};

export default CollapseIcon;
