import { colors } from 'components/Button/options';

import styled from 'styled-components';

interface InfoRowStyledProps {
  isLeading?: boolean;
  isHighlighted?: boolean;
  isCentered?: boolean;
}

export const InfoRowStyled = styled.div<InfoRowStyledProps>`
  display: flex;
  width: 100%;
  max-width: 400px;
  justify-content: ${({ isCentered }) =>
    isCentered ? 'center' : 'space-between'};
  margin: ${({ isLeading }) => (isLeading ? '8px' : '6px')} 0;

  > span {
    font-size: ${({ isLeading }) => (isLeading ? '16' : '14')}px;
    line-height: ${({ isLeading }) => (isLeading ? '24' : '24')}px;
    color: ${({ isHighlighted, theme }) =>
      isHighlighted ? theme.colors.white : theme.colors.shadesForeground300};
    font-weight: ${({ isLeading, theme }) =>
      isLeading ? theme.fontWeights.semiBold : theme.fontWeights.medium};
    text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
  }

  > h6 {
    color: ${({ isHighlighted, theme }) =>
      isHighlighted ? theme.colors.white : theme.colors.shadesForeground300};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
`;

export const SeparatorStyled = styled.div`
  background: ${({ theme }) => theme.colors.shadesBackground800};
  margin: 32px 0;
  height: 1px;
`;

export const ActionSectionContainer = styled.div``;
