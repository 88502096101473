import {
  getDisableConfirmationValFromLocalStorage,
  getDisableCancelOrderConfirmationValFromLocalStorage,
  isMobileDevice,
  readUserSetting,
  saveUserSetting,
} from 'utils';

import AppStore from './app';
import {
  LS_DEFAULT_CURRENCY_KIND,
  LS_ORDER_TYPE_TIME_IN_FORCE,
  LS_SHOULD_DISABLE_CANCEL_ORDER_CONFIRMATION_MODAL,
  LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN,
  LS_SHOW_ALL_MARKETS,
} from 'constants/localStorageKeys';
import { TimeInForce } from 'pages/Trade/components/BuySell/AdvancedOptions/TimeInForceCheckboxes';

import { UserSettings } from 'enums';
import { CurrencyKind } from 'interfaces';
import { action, makeAutoObservable, observable } from 'mobx';

export default class AppStateStore {
  isNotificationsSidebarOpen = false;

  disableOrderConfirmationModal = false;
  disableCancelOrderConfirmationModal = false;
  showAllMarkets = true;
  hasWentThroughGetStarted = true;
  defaultCurrencyKind = (readUserSetting(LS_DEFAULT_CURRENCY_KIND) ??
    CurrencyKind.BASE) as CurrencyKind;

  constructor(private store: AppStore) {
    makeAutoObservable(this, {
      isNotificationsSidebarOpen: observable,
      defaultCurrencyKind: observable,
      disableOrderConfirmationModal: observable,
      disableCancelOrderConfirmationModal: observable,
      showAllMarkets: observable,
      toggleCurrencyKind: action.bound,
      setIsNotificationsSidebarOpen: action.bound,
      setDefaultCurrencyKind: action.bound,
      setIsDefaultCurrencyKindUSD: action.bound,
      setDisableOrderConfirmationModal: action.bound,
      setDisableCancelOrderConfirmationModal: action.bound,
      setShowAllMarkets: action.bound,
      hasWentThroughGetStarted: observable,
      setWentThroughtGetStarted: action.bound,
    });

    this.disableOrderConfirmationModal =
      getDisableConfirmationValFromLocalStorage();

    this.disableCancelOrderConfirmationModal =
      getDisableCancelOrderConfirmationValFromLocalStorage();

    this.hasWentThroughGetStarted =
      isMobileDevice() ||
      readUserSetting(UserSettings.HAS_WENT_THROUGH_GET_STARTED);

    const showAllMarketsLsVal = readUserSetting(LS_SHOW_ALL_MARKETS);
    this.showAllMarkets =
      showAllMarketsLsVal === 'true' || showAllMarketsLsVal === undefined;
  }

  setIsNotificationsSidebarOpen(isShown: boolean) {
    this.isNotificationsSidebarOpen = isShown;
  }

  setDisableOrderConfirmationModal(disable: boolean) {
    this.disableOrderConfirmationModal = disable;
    localStorage.setItem(
      LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN,
      disable.toString(),
    );
  }

  setDisableCancelOrderConfirmationModal(boolean: boolean) {
    this.disableCancelOrderConfirmationModal = boolean;
    localStorage.setItem(
      LS_SHOULD_DISABLE_CANCEL_ORDER_CONFIRMATION_MODAL,
      boolean.toString(),
    );
  }

  setShowAllMarkets(showAllMarkets: boolean) {
    this.showAllMarkets = showAllMarkets;
    saveUserSetting(LS_SHOW_ALL_MARKETS, showAllMarkets.toString());
  }

  setOrderTypeTimeInForce(orderType: string, timeInForce: TimeInForce) {
    saveUserSetting(LS_ORDER_TYPE_TIME_IN_FORCE, {
      ...readUserSetting(LS_ORDER_TYPE_TIME_IN_FORCE),
      [orderType]: timeInForce,
    });
  }

  setWentThroughtGetStarted(value: boolean) {
    saveUserSetting(UserSettings.HAS_WENT_THROUGH_GET_STARTED, value);
    this.hasWentThroughGetStarted = value;
  }

  get isCurrencyKindUSD() {
    return this.defaultCurrencyKind === CurrencyKind.QUOTE;
  }

  setIsDefaultCurrencyKindUSD(isKindUSD: boolean) {
    const newVal = isKindUSD ? CurrencyKind.QUOTE : CurrencyKind.BASE;
    saveUserSetting(LS_DEFAULT_CURRENCY_KIND, newVal);
    this.defaultCurrencyKind = newVal;
  }

  setDefaultCurrencyKind(currencyKind: CurrencyKind) {
    saveUserSetting(LS_DEFAULT_CURRENCY_KIND, currencyKind);
    this.defaultCurrencyKind = currencyKind;
  }

  toggleCurrencyKind() {
    const newVal = this.isCurrencyKindUSD
      ? CurrencyKind.BASE
      : CurrencyKind.QUOTE;
    saveUserSetting(LS_DEFAULT_CURRENCY_KIND, newVal);
    this.defaultCurrencyKind = newVal;
  }
}
