import { getStyles, StatusBasedStyles } from 'utils';

import { NotificationStyled } from './styles';
import closeIcon from 'assets/icons/close-x.svg';

import { Notification as INotification } from 'interfaces';
import { useTheme } from 'styled-components';

interface Props {
  notification: INotification;
}
export const Notification = ({ notification }: Props) => {
  const theme = useTheme();
  const styles: StatusBasedStyles = getStyles(theme, notification.type);

  return (
    <NotificationStyled
      titleColor={styles.title}
      backgroundColor={styles.background}
    >
      <img
        src={styles.icon}
        className="notification-icon"
        alt="Notification icon"
      />
      <div className="content">
        <h3>{notification.title}</h3>
        {notification.description ? <p>{notification.description}</p> : null}
      </div>
      <img
        src={closeIcon}
        alt="Close icon"
        className="close-icon cursor-pointer"
      />
    </NotificationStyled>
  );
};
