import { TableCell, TableRow } from '@material-ui/core';

import { timestampToLocalDateWithTime } from 'utils';
import { brand } from 'utils/brand';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';
import { TSavedVaultActivity } from 'utils/vaultActivity';

import { FormattedNumber } from 'components';
import Icon from 'components/Icon';
import IconPair from 'components/IconPair';

import { Row } from '../../styles';
import checkedIcon from 'assets/icons/check-circle-green.svg';
import downloadIcon from 'assets/icons/download-white.svg';
import clockIcon from 'assets/icons/pending.svg';
import uploadIcon from 'assets/icons/upload.svg';
import { SpotStateType } from 'enums/spotState';
import { SpotType } from 'enums/spotType';
import { VaultManager } from 'enums/vaultManager';
import { vaultManagerDetailsMap } from 'pages/Vaults/Pages/Vaults/TabPanels/common/VaultList/utils';

import { VisibilityProps } from '.';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ActionRow = styled(Row)`
  min-height: 34px;
`;

const ActivityRow = ({
  activity,
  hideActions,
  hideManagerColumn,
  currentPPS,
}: VisibilityProps & {
  activity: TSavedVaultActivity;
  currentPPS: number | undefined;
}) => {
  const { t } = useTranslation();
  const marketLogo = useGetMarketLogoFromMarketId(activity.tokenPair);

  const { icon: managerIcon, labelTx: managerLabelTx } =
    vaultManagerDetailsMap[VaultManager.ELIXIR]; // make dynamic when we have more managers

  const isDeposit = activity.spotType === SpotType.Deposit;
  const activityIcon = isDeposit ? uploadIcon : downloadIcon;

  const activityLabel = isDeposit ? 'Stake' : 'Unstake';

  // when depositing the amount is in USDT/USDB and when withdrawing its in shares

  // we show shares only for deposits
  const shares = !isDeposit ? activity.amount : undefined;

  // we show shares value only for withdrawals
  const sharesValue = isDeposit ? activity.amount : undefined;

  return (
    <TableRow>
      <TableCell>
        <Row className="gap-5">
          <img src={activityIcon} alt="stake" />
          {activityLabel}
        </Row>
      </TableCell>
      <TableCell>
        <Row className="gap-10">
          {/* <img
            src={isWatched ? starFilledIcon : starOutlineIcon}
            alt="wathlist-status"
          /> */}
          <Row className="gap-5">
            <IconPair icons={[marketLogo, brand.tokenLogo]} size={16} />
            {activity.tokenPair}
          </Row>
        </Row>
      </TableCell>
      {/* {!hideManagerColumn ? ( */}
      <TableCell>
        <Row className="gap-5">
          <img src={managerIcon} alt="elixir" />
          {t(managerLabelTx)}
        </Row>
      </TableCell>
      {/* ) : null} */}
      <TableCell>
        <Row className="gap-5">
          <img src={brand.tokenLogo} alt={brand.tokenTicker} />
          {brand.tokenTicker}
        </Row>
      </TableCell>
      <TableCell>
        <FormattedNumber value={shares} suffix=" Redeemed" />
      </TableCell>
      <TableCell>
        <FormattedNumber value={sharesValue} prefix="$" decimalScale={2} />
      </TableCell>
      <TableCell>
        <Row className="gap-5">
          {activity.spotStateType === SpotStateType.Executed ? (
            <>
              <Icon src={checkedIcon} />
              {t('success')}
            </>
          ) : (
            <>
              <Icon src={clockIcon} />
              {t('pending')}
            </>
          )}
        </Row>
      </TableCell>
      <TableCell>{timestampToLocalDateWithTime(activity.timestamp)}</TableCell>

      <TableCell>
        <ActionRow className="gap-20 justify-end">
          -
          {/* <img src={infoCircleWhiteIcon} alt="i" />

          <Row className="gap-10">
            <Button className="custom-button" size="S" variant="primaryGreen">
              Stake
            </Button>
            {withdrawable ? (
              <Button
                className="custom-button"
                size="S"
                variant="secondaryDefault"
              >
                Withdraw
              </Button>
            ) : null}
          </Row> */}
        </ActionRow>
      </TableCell>
    </TableRow>
  );
};

export default observer(ActivityRow);
