import { useState, cloneElement, useRef, useEffect } from 'react';

import { ModalStyled, Presenter } from './styles';
import ModalContext, { ModalsContextInterface } from 'contexts/ModalContext';
import { HeaderAndMobileSideBarLayout } from 'pages/Layout';

import { createPortal } from 'react-dom';

export type ModalStatus = 'success' | 'error' | 'processing';
export interface ModalMetadata {
  title?: string;
  description?: string;
  status?: ModalStatus;
}
export interface Modal {
  element: JSX.Element;
  focused?: boolean;
  stashed?: boolean;
  blurred?: boolean;
  minimised?: boolean;
  name: string;
  id?: string; // Id is used to identify the modal in the stack as there can be multiple modals with the same name
  metadata?: ModalMetadata;
}

interface Props {
  children?: any;
}

const filterMinimisedModals = (modals: Modal[]) =>
  modals.filter(m => !m.minimised);

const bipercateModals = (modals: Modal[]) => {
  let minimisedModals: Modal[] = [];
  let activeModals: Modal[] = [];

  for (let x of modals) {
    if (x.minimised) {
      minimisedModals.push(x);
    } else {
      activeModals.push(x);
    }
  }

  return { minimisedModals, activeModals };
};

// The ModalPresenter is our middleware component that will capture
// modal presentation calls and will append them to the list. The
// presenter can be nested in different levels and the parent that
// is closest to the child will capture the presentation.
export const ModalPresenter = ({ children }: Props) => {
  const [modals, setModals] = useState<Modal[]>([]);
  const [isPresentingMode, setPresentingMode] = useState<boolean>(false);
  const focusedModalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const modalRef = focusedModalRef.current;
    const avoidScrolling = event => {
      event.preventDefault(); // Prevents default scrolling behavior
    };

    modalRef?.addEventListener('wheel', avoidScrolling, {
      passive: false,
    });

    return () => modalRef?.removeEventListener('wheel', avoidScrolling);
  }, [focusedModalRef.current]);

  const context: ModalsContextInterface = {
    setModalMetadata(id, metadata) {
      setModals(prev =>
        prev.map(m =>
          m.id === id ? { ...m, metadata: { ...m.metadata, ...metadata } } : m,
        ),
      );
    },

    setCurrentModalId(id) {
      setModals(prev => prev.map(m => (m.focused ? { ...m, id } : m)));
    },

    maximiseById(id) {
      setModals(prev =>
        prev.map(m =>
          id === m.id
            ? { ...m, minimised: false, focused: true, stashed: false }
            : m,
        ),
      );
    },

    minimiseById(id) {
      setModals(prev =>
        prev.map(m =>
          m.id === id
            ? { ...m, minimised: true, focused: false, stashed: true }
            : m,
        ),
      );
    },

    present(element, name, props = {}) {
      setModals(prev => {
        // If the modal is initially minimised, add it to the stack directly
        if (props.minimised) {
          return [{ element, focused: false, name: name, ...props }, ...prev];
        }
        // Unfocus other modals
        const currentModals = prev.map(m => {
          return { ...m, focused: false, stashed: true };
        });

        let newModals: Modal[];

        // Add new modal, but wait before focusing to enable css
        // transitions to activate. Only animate if we are adding to
        // the stack.
        if (currentModals.length > 0) {
          newModals = [
            ...currentModals,
            {
              element,
              focused: true,
              name: name,
              ...props,
            },
          ];
        } else {
          newModals = [{ element, focused: true, name: name, ...props }];
        }

        return newModals;
      });
    },
    pop({ count = 1, name }) {
      const { minimisedModals, activeModals } = bipercateModals(modals);

      // allow popping only the last modal
      if (name !== activeModals[activeModals.length - 1].name) return;

      if (activeModals.length === 0) {
        return;
      }

      // Default number of modals to pop is 1
      if (!count || isNaN(count)) {
        count = 1;
      }

      // Remove last active modal and focus previous one
      const newActiveModals = activeModals.slice(0, -count);

      if (newActiveModals.length > 0) {
        newActiveModals[newActiveModals.length - 1].focused = true;
        newActiveModals[newActiveModals.length - 1].stashed = false;
      }
      setModals([...minimisedModals, ...newActiveModals]);
    },

    clearById(id: string) {
      setModals(prev => {
        if (prev.length === 0) {
          return [];
        }

        return prev.filter(item => item.id !== id);
      });
    },

    clear() {
      if (modals.length === 0) {
        return;
      }
      setModals([]);
    },

    clearByName(name) {
      setModals(prev => {
        if (prev.length === 0) {
          return [];
        }
        const { minimisedModals, activeModals } = bipercateModals(modals);

        const newActiveModals = activeModals.filter(item => item.name !== name);

        // Focus the last active modal
        if (newActiveModals.length > 0) {
          newActiveModals[newActiveModals.length - 1].focused = true;
          newActiveModals[newActiveModals.length - 1].stashed = false;
        }

        return [...minimisedModals, ...newActiveModals];
      });
    },

    get activeModals() {
      return modals;
    },

    setPresentingEffect(flag: boolean) {
      setPresentingMode(flag);
    },
  };

  function isPresenting() {
    // dont count minimised modals, as they are not visible
    return filterMinimisedModals(modals).length > 0 || isPresentingMode;
  }

  // if any modal is blurred, the presenter will be blurred on other modals as well
  const isPresenterBlurred = !!modals.find(i => i.blurred);
  const showHeaderAndMobileSidebar = isPresenterBlurred;

  const renderModals = () =>
    modals.map((modal, i) => (
      <ModalStyled
        ref={modal.focused ? focusedModalRef : null}
        isFocused={modal.focused}
        isStashed={modal.stashed || modal.minimised}
        // onClick={() => context.pop({ name: modal.name })}
      >
        {cloneElement(modal.element)}
      </ModalStyled>
    ));

  return (
    <ModalContext.Provider value={context}>
      {children}
      {createPortal(
        <Presenter isPresenting={isPresenting()} isBlurred={isPresenterBlurred}>
          {showHeaderAndMobileSidebar ? (
            <HeaderAndMobileSideBarLayout>
              {renderModals()}
            </HeaderAndMobileSideBarLayout>
          ) : (
            renderModals()
          )}
        </Presenter>,
        document.body,
      )}
    </ModalContext.Provider>
  );
};
