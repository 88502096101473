import { TextVariant } from 'components/Text/variant';

import styled, { css } from 'styled-components';

export const BuySellStyled = styled.div`
  display: flex;
  align-content: space-between;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;
`;

export const HeadingStyled = styled.div`
  /* display: flex; */
  /* gap: 8px;
  justify-content: flex-end; */
`;

export const TopPart = styled.div`
  flex: 1;

  .tab-override {
    .MuiTab-wrapper {
      flex-direction: row;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
      font-size: ${TextVariant['BODY_M'].fontSize};

      *:first-child {
        margin-bottom: 0;
      }
    }
  }

  .MuiTab-root {
    margin: 0;
    padding: 0;
  }

  .MuiTabs-flexContainer {
    border-bottom: 3px solid ${({ theme }) => theme.colors.shadesBackground800};
    gap: 20px;
  }

  .MuiTabs-indicator {
    height: 3px;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.shadesBackground500};
  }

  .sliding-option {
    display: flex;
    box-sizing: border-box;
    min-width: 33.3%;
  }
`;

interface BuySellProps {
  isModal: boolean;
}

export const BuySellButtonsContainer = styled.div<BuySellProps>`
  display: flex;
  gap: 8px;

  ${({ isModal }) =>
    isModal &&
    css`
      margin-bottom: 0;
    `}
`;

export const DropdownContainer = styled.div`
  display: flex;
  width: 65px;
  > div {
    width: 100%;
  }
`;

export const Seperator = styled.div`
  height: 2px;
  align-self: stretch;
  background: ${({ theme }) => theme.colors.shadesBackground700};
`;
