import { TableCell, TableRow } from '@material-ui/core';

import EmptyText from 'components/EmptyText';
import Icon from 'components/Icon';
import IconPair from 'components/IconPair';

import { Row } from '../../../styles';

import { VisibilityProps } from '..';

const EmptyActivityRow = ({
  hideActions,
  hideManagerColumn,
}: VisibilityProps) => {
  return (
    <TableRow>
      <TableCell>
        <Row className="gap-5">
          <Icon isEmpty />
          <EmptyText width={48} />
        </Row>
      </TableCell>
      <TableCell>
        <Row className="gap-10">
          <IconPair isEmpty size={16} />
          <EmptyText width={62} />
        </Row>
      </TableCell>
      {!hideManagerColumn ? (
        <TableCell>
          <Row className="gap-5">
            <Icon isEmpty />
            <EmptyText width={48} />
          </Row>
        </TableCell>
      ) : null}
      <TableCell>
        <Row className="gap-5">
          <Icon isEmpty />
          <EmptyText width={48} />
        </Row>
      </TableCell>
      <TableCell>
        <EmptyText width={92} />
      </TableCell>
      <TableCell>
        <EmptyText width={50} />
      </TableCell>
      <TableCell>
        <Row className="gap-5">
          <Icon isEmpty />
          <EmptyText width={62} />
        </Row>
      </TableCell>
      <TableCell>
        <EmptyText width={126} />
      </TableCell>
      {/* {!hideActions ? ( */}
      <TableCell>
        <EmptyText width={0} height={34} />
      </TableCell>
      {/* ) : null} */}
    </TableRow>
  );
};

export default EmptyActivityRow;
