import { API_MAPPINGS } from '../constants';
import { useActiveWeb3React } from './useActiveWeb3React';
import { useAppContext } from 'contexts/AppContext';
import { RestService } from 'service/restService';

import { Endpoints, QueryKeys, RequestMethod } from 'enums';
import { useQuery } from 'react-query';

const restService = RestService.get();

type TBlastPoints = {
  gold: {
    finalizedPoints: number;
    nextBatchFinalizingAt: string;
    pendingPoints: number;
  };
  blast: {
    finalizedPoints: number;
    nextBatchFinalizingAt: string;
    pendingPoints: number;
  };
};

type TBlastPointsResponse = {
  gold: {
    finalizedPoints: number;
    nextBatchFinalizingAt: string;
    pendingPoints: number;
  };
  blast: {
    finalizedPoints: number;
    nextBatchFinalizingAt: string;
    pendingPoints: number;
  };
};

export function useBlastPointsAPI() {
  const { account } = useActiveWeb3React();
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();

  const EMPTY_BLAST_POINTS: TBlastPoints = {
    gold: {
      finalizedPoints: 0,
      nextBatchFinalizingAt: '2000:01:01T00:00:00Z',
      pendingPoints: 0,
    },
    blast: {
      finalizedPoints: 0,
      nextBatchFinalizingAt: '2000:01:01T00:00:00Z',
      pendingPoints: 0,
    },
  };

  const {
    data: blastPoints = EMPTY_BLAST_POINTS, // show empty points if undefined
  }: {
    isLoading: boolean;
    data: TBlastPoints | undefined;
    isError?: any;
  } = useQuery(
    [QueryKeys.BlastPoints, frontendSecrets],
    async () => {
      try {
        if (!account) return;

        const { data }: { data: TBlastPointsResponse[] } =
          await restService.request({
            method: RequestMethod.GET,
            path: `/${Endpoints.BLAST_POINTS}`,
            queryParams: {
              wallet_address: account.toLowerCase(),
            },
            isArray: true,
            responseMapping: API_MAPPINGS.BLAST_POINTS,
          });

        const points = data?.[0];

        return points;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    { enabled: !!account },
  );

  return { blastPoints: blastPoints } as const;
}

export type ReferralsAPI = ReturnType<typeof useBlastPointsAPI>;
