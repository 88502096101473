import { Size as IconSize } from 'components/Icon/size';
import { TextVariant } from 'components/Text/variant';

import { theme } from 'theme/Theme';

export type TButtonSizeVariant = 'S' | 'M' | 'L';
export type TButtonColorVariant =
  | 'primaryGreen'
  | 'primaryRed'
  | 'secondary'
  | 'longBuy'
  | 'longSell'
  | 'tertiary'
  | 'tableSecondary'
  | 'bfxMain';

export const ButtonSizeVariants: Record<
  TButtonSizeVariant,
  {
    padding: string;
    text: keyof typeof TextVariant;
    iconSize: keyof typeof IconSize;
  }
> = {
  L: {
    padding: '15px 20px',
    text: 'BUTTON_L',
    iconSize: 'L',
  },

  M: {
    padding: '12px 15px',
    text: 'BODY_M',
    iconSize: 'M',
  },

  S: {
    padding: '10px 12px',
    text: 'BUTTON_S',
    iconSize: 'S',
  },
};

export const ButtonColorVariants: Record<
  TButtonColorVariant,
  {
    default: { background: string; border?: string; text?: string };
    hovered: { background: string; border?: string; text?: string };
  }
> = {
  bfxMain: {
    default: { background: theme.colors.main200, text: theme.colors.black },
    hovered: { background: theme.colors.main300, text: theme.colors.black },
  },

  primaryGreen: {
    default: { background: theme.colors.positiveForeground300 },
    hovered: { background: theme.colors.positiveForeground400 },
  },

  primaryRed: {
    default: { background: theme.colors.negativeForeground300 },
    hovered: { background: theme.colors.negativeForeground400 },
  },

  secondary: {
    default: {
      background: theme.colors.shadesBackground800,
      border: theme.colors.shadesBackground700,
    },
    hovered: { background: theme.colors.shadesBackground700 },
  },

  // Secondary's sub variant to be used  on tables
  tableSecondary: {
    default: {
      background: theme.colors.shadesBackground800,
      border: theme.colors.shadesBackground700,
    },
    hovered: { background: theme.colors.shadesBackground700 },
  },

  tertiary: {
    default: {
      background: theme.colors.goldGradient300,
    },
    hovered: {
      background: theme.colors.goldGradient200,
    },
  },

  longBuy: {
    default: {
      background: theme.colors.positiveBackground100,
      text: theme.colors.positiveForeground200,
    },
    hovered: {
      background: theme.colors.positiveForeground300,
    },
  },

  longSell: {
    default: {
      background: theme.colors.negativeBackground100,
      text: theme.colors.negativeForeground200,
    },
    hovered: {
      background: theme.colors.negativeForeground300,
    },
  },
};
