import { Table, TableContainer, TableRow } from '@material-ui/core';

import { TextVariant } from 'components/Text/variant';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  .outer-table-container {
    padding: 15px;
    display: flex;
    background-color: #0a0e18;
    border-radius: 16px;
    margin-top: -20px;
    z-index: 3;
    position: relative;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      padding: 20px;
      /* padding: 30px 50px 50px; */
    }
  }
`;

export const TableBanner = styled.div`
  background-color: #292848;
  position: relative;
  overflow: hidden;

  > div > img {
    border-radius: 16px;
    position: absolute;
    right: 0;
    top: 0;
    max-height: 250px;

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      max-height: 300px;
    }
  }

  padding: 35px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .content {
    position: relative;
    z-index: 2;
    > h6 {
      font-size: 18px;
      line-height: 24px;
      margin: 0 0 15px;
    }

    > h2 {
      font-size: 30px;
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      letter-spacing: -0.6px;
      margin: 0 0 15px;
    }

    > p {
      font-size: 14px;
      line-height: 16px;
    }

    @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
      padding: 45px 50px;

      > h6 {
        font-size: 20px;
        letter-spacing: -0.4px;
        margin-bottom: 20px;
      }

      > h2 {
        font-size: 36px;
        letter-spacing: -0.72px;
        margin-bottom: 20px;
      }

      > p {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }
  }
`;

export const TableContainerStyled = styled(TableContainer)``;

export const TableStyled = styled(Table)`
  border-collapse: collapse;

  /* tbody tr:after {
    content: '-';
    display: block;
    line-height: 8px;
    color: transparent;
  } */

  tbody tr.empty-row {
    height: 10px;
    background: transparent;
    td {
      padding: 0;
    }
  }

  tbody tr.separator-row {
    height: 2px;
    background: ${({ theme }) => theme.colors.shadesBackground500};
    td {
      padding: 0;
    }
  }

  tr td,
  tr th {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    border: 0;
    /* border-bottom: 10px solid ${({ theme }) =>
      theme.colors.shadesBackground800}; */
  }

  tr th {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    font-size: ${TextVariant['BODY_S'].fontSize};
    line-height: ${TextVariant['BODY_S'].lineHeight};
    padding: 0 15px 10px 15px;
  }
  tr td {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${TextVariant['BODY_S'].fontSize};
    line-height: ${TextVariant['BODY_S'].lineHeight};
    padding: 11.5px 15px;
  }

  tbody tr td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  tbody tr td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  /* tr:last-child td {
    padding-bottom: 0;
  } */
`;

type TableRowStyledProps = {
  backgroundGradient?: string;
};
export const TableRowStyled = styled(TableRow)<TableRowStyledProps>`
  background: ${({ backgroundGradient }) =>
    backgroundGradient ?? 'transparent'};

  .icon {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }
`;

export const RankCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  > img {
    width: 18px;
  }
`;

type RankChangeCellProps = {
  isNegative?: boolean;
  isPositive?: boolean;
  isNeutral?: boolean;
};
export const RankChangeLabel = styled.span<RankChangeCellProps>`
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 2px 4px;
  font-size: 12px;
  line-height: 14px; /* 116.667% */
  border-radius: 4px;

  > img {
    width: 12px;
    height: 12px;
  }

  ${({ isPositive }) =>
    isPositive &&
    css`
      background: ${({ theme }) => theme.colors.primaryGreenBackground100};
      color: ${({ theme }) => theme.colors.primaryGreenForeground100};
    `};

  ${({ isNegative }) =>
    isNegative &&
    css`
      background: ${({ theme }) => theme.colors.primaryRedBackground100};
      color: ${({ theme }) => theme.colors.primaryRedForeground100};
    `};

  ${({ isNeutral }) =>
    isNeutral &&
    css`
      background: ${({ theme }) => theme.colors.shadesBackground500};
      color: ${({ theme }) => theme.colors.shadesForeground200};
    `};
`;

export const TwitterProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .avatar {
    height: 24px;
    width: 24px;
    border-radius: 100%;
  }

  .label {
  }
`;
