import { useCallback, useEffect, useRef, useState } from 'react';

import {
  USD_DECIMAL_SCALE,
  getCurrencyFromTradingPair,
  getMaxOrderUsdValue,
  roundDownToNearestTick,
  roundToNearestTick,
} from 'utils';
import { CreateOrderParams } from 'utils/api';
import {
  checkOrderForErrors,
  getLongAndShortBuyingPower,
  getMaxOrderUsdValueMinusFees,
} from 'utils/order';

import {
  DEFAULT_DECIMAL_PLACES,
  useGetDecimalPlaces,
} from 'hooks/useDecimalPlaces';
import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import Button from 'components/Button/button';
import NumberInput from 'components/Inputs/NumberInput';

import ConfirmBuySellModal from '../../BuySell/ConfirmBuySellModal';
import TradeSideAndLeverageSelector from '../../BuySell/TradeSideAndLeverageSelector';
import { Container } from './styles';
import {
  LS_ORDERBOOK_ORDER_ENTRY_QUANTITY,
  LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN,
} from 'constants/localStorageKeys';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import { mixpanelService } from 'service/mixpanelService';

import { OrderType, TradeSide } from 'enums';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';

interface InitialOption {
  text: string;
  selected: boolean;
}

// Write a function that saves quantity for the selected market to local storage
/**
 * Save the quantity for the selected market and currency kind to local storage
 * @param selectedMarketCurrency e.g. 'BTC', 'ETH', etc.
 * @param selectedCurrencyKind e.g. 'USD' or the selected market currency
 * @param quantity the quantity to save
 */
const saveQuantityToLocalStorage = (
  selectedMarketCurrency: string,
  selectedCurrencyKind: string,
  quantity: number | null,
) => {
  const lsItem = localStorage.getItem(LS_ORDERBOOK_ORDER_ENTRY_QUANTITY);
  const quantityObj = lsItem ? JSON.parse(lsItem) : {};
  quantityObj[selectedMarketCurrency] = {
    ...(quantityObj[selectedMarketCurrency] ?? {}),
    [selectedCurrencyKind]: quantity,
  };

  localStorage.setItem(
    LS_ORDERBOOK_ORDER_ENTRY_QUANTITY,
    JSON.stringify(quantityObj),
  );
};

/**
 * Get the quantity for the selected market and currency kind from local storage
 * @param selectedMarketCurrency e.g. 'BTC', 'ETH', etc.
 * @param selectedCurrencyKind e.g. 'USD' or the selected market currency
 * @returns the quantity or null if it doesn't exist
 */
const getQuantityFromLocalStorage = (
  selectedMarketCurrency: string,
  selectedCurrencyKind: string,
) => {
  const lsItem = localStorage.getItem(LS_ORDERBOOK_ORDER_ENTRY_QUANTITY);
  const quantityObj = lsItem ? JSON.parse(lsItem) : {};
  return quantityObj[selectedMarketCurrency]?.[selectedCurrencyKind] ?? null;
};

// /**
//  * Save selected currency kind to local storage
//  */
// const saveSelectedCurrencyKindToLocalStorage = (
//   selectedMarketCurrency: string,
//   selectedCurrencyKind: string,
// ) => {
//   const prevLsItem = localStorage.getItem(
//     LS_ORDERBOOK_ORDER_ENTRY_CURRENCY_KIND,
//   );
//   // get the previous value from local storage and update it with the new one
//   const prevLsItemJson = prevLsItem ? JSON.parse(prevLsItem) : {};
//   const newItem = {
//     ...prevLsItemJson,
//     [selectedMarketCurrency]: selectedCurrencyKind,
//   };
//   localStorage.setItem(
//     LS_ORDERBOOK_ORDER_ENTRY_CURRENCY_KIND,
//     JSON.stringify(newItem),
//   );
// };

// /**
//  * Build the initial options array for the currency kind button switch and
//  * set "selected" to true for the one stored in local storage
//  * @param initialOptions e.g. [{ text: 'USD', selected: false }, { text: 'BTC', selected: false }]
//  * @param selectedMarketCurrency e.g. 'BTC', 'ETH', etc.
//  * @returns e.g. [{ text: 'USD', selected: true }, { text: 'BTC', selected: false }]
//  */
// const getInitialOptions = (
//   initialOptions: InitialOption[],
//   selectedMarketCurrency: string,
// ) => {
//   const currencyKind = getSelectedCurrencyKindFromLocalStorage(
//     selectedMarketCurrency,
//   );

//   // set the selected option to the one stored in local storage
//   return initialOptions.map(option => ({
//     ...option,
//     selected: option.text === currencyKind,
//   }));
// };

const OrderEntryPopUp = () => {
  const {
    store: {
      orderbook: { setIsOrderEntryPopUpShown, orderbookOrderEntryPrice },
      markets: { selectedMarketId, selectedMarket },
      account: accountStore,
      appState: { isCurrencyKindUSD, setIsDefaultCurrencyKindUSD },
    },
  } = useAppContext();
  const modal = useModal();
  const { createOrder } = useOrdersAPI();
  const { t } = useTranslation();

  const decimalPlaces = useGetDecimalPlaces(selectedMarketId);

  const selectedMarketCurrency = getCurrencyFromTradingPair(selectedMarketId);

  const quantityCurrency = isCurrencyKindUSD ? 'USD' : selectedMarketCurrency;

  // Initial quantity read from local storage
  const initialQuantity = getQuantityFromLocalStorage(
    selectedMarketCurrency,
    quantityCurrency,
  );
  const [quantity, setQuantity] = useState<number | null>(initialQuantity);
  const [isProcessingCreateOrder, setIsProcessingCreateOrder] = useState<
    'buy' | 'sell' | null
  >(null);

  useKey(Key.Escape, () => setIsOrderEntryPopUpShown(false));

  // Save quantity to local storage when it changes
  useEffect(() => {
    if (quantity !== null && quantity <= 0) {
      return;
    }

    saveQuantityToLocalStorage(
      selectedMarketCurrency,
      quantityCurrency,
      quantity,
    );
  }, [quantity]);

  const updateQuantityOnCurrencyKindChange = useCallback(
    (newCurrencyKind: string) => {
      if (!quantity || quantity <= 0 || !orderbookOrderEntryPrice) {
        return;
      }

      // If the new curreny kind is USD, then we need to multiply the quantity by the price
      // to get the quantity in the USD, otherwise we need to multiply to get the quantity in the
      // selected market currency
      const newQuantity: number =
        newCurrencyKind === 'USD'
          ? roundDownToNearestTick(
              quantity * orderbookOrderEntryPrice,
              DEFAULT_DECIMAL_PLACES.minOrder,
            )
          : roundDownToNearestTick(
              quantity / orderbookOrderEntryPrice,
              decimalPlaces.minOrder,
            );

      setQuantity(newQuantity);
    },
    [quantity, orderbookOrderEntryPrice],
  );

  const swapCurrencyKind = () => {
    const nextVal = isCurrencyKindUSD ? selectedMarketCurrency : 'USD';
    handleCurrencyKindChange(nextVal);
  };

  // store the selected currency kind in local storage when it changes
  const handleCurrencyKindChange = useCallback(
    (newCurrencyKind: string) => {
      setIsDefaultCurrencyKindUSD(newCurrencyKind === 'USD');

      // If the user changes currency kind, then it should show the equivalent amount in the new currency
      updateQuantityOnCurrencyKindChange(newCurrencyKind);
    },
    [selectedMarketCurrency, updateQuantityOnCurrencyKindChange],
  );

  const onConfirmCreateOrder = async (params: CreateOrderParams) => {
    try {
      flushSync(() => {
        setIsProcessingCreateOrder(
          params.side === TradeSide.LONG ? 'buy' : 'sell',
        );
      });

      if (!accountStore.frontendSecrets) {
        return;
      }

      mixpanelService.openOrderClicked(
        accountStore.frontendSecrets?.profile.wallet,
        params,
        'orderbook',
      );

      await createOrder(params);

      mixpanelService.openOrderSuccess(
        accountStore.frontendSecrets?.profile.wallet,
        params,
        'orderbook',
      );
      setIsOrderEntryPopUpShown(false);
    } catch (e: any) {
      console.log('An error occurred while trying to create an order.', e);
    } finally {
      setIsProcessingCreateOrder(null);
    }
  };

  const openConfirmModal = useCallback(
    (isBuy: boolean) => {
      if (!quantity || !orderbookOrderEntryPrice) {
        return;
      }

      const roundedPrice = roundToNearestTick(
        orderbookOrderEntryPrice,
        decimalPlaces.minTick,
      );
      // If the curreny kind is USD, then we need to divide the quantity by the price
      // to get the quantity in the selected market currency
      const computedQuantity = isCurrencyKindUSD
        ? quantity / orderbookOrderEntryPrice
        : quantity;

      const roundedQuantity = roundToNearestTick(
        computedQuantity,
        decimalPlaces.minOrder,
      );

      const params = {
        price: roundedPrice,
        marketID: selectedMarketId,
        quantity: roundedQuantity,
        size: roundedQuantity,
        side: isBuy ? TradeSide.LONG : TradeSide.SHORT,
        type: OrderType.LIMIT,
      };

      // If the user has disabled the confirmation screen, then just create the order
      if (
        localStorage.getItem(LS_SHOULD_DISABLE_ORDER_CONFIRMATION_SCREEN) ===
        'true'
      ) {
        onConfirmCreateOrder(params);
        return;
      }

      modal.present(
        <ConfirmBuySellModal
          data={params}
          onConfirm={() => {
            onConfirmCreateOrder(params);
          }}
          isBuy={isBuy}
        />,
        Modals.confirmBuySellModal,
      );
    },
    [quantity, isCurrencyKindUSD],
  );

  const shouldDisableSubmitBtn =
    !!isProcessingCreateOrder || !quantity || quantity <= 0;

  const maxOrderUsdValueRounded = getMaxOrderUsdValue(
    accountStore.accountStats,
    selectedMarket,
  );

  // account for some fee and change in withdrawable balance (subtract 3%)
  const maxOrderUsdValueMinusFees = maxOrderUsdValueRounded
    ? roundDownToNearestTick(
        getMaxOrderUsdValueMinusFees(maxOrderUsdValueRounded) as number,
        0.03,
      )
    : null;

  const { longingPower, shortingPower, longMaxQuantity, shortMaxQuantity } =
    getLongAndShortBuyingPower(
      accountStore._positions,
      selectedMarketId,
      maxOrderUsdValueMinusFees ?? 0,
      orderbookOrderEntryPrice,
      selectedMarket?.fairPrice ?? 0,
      decimalPlaces,
      OrderType.LIMIT,
    );

  const computedValue = isCurrencyKindUSD
    ? quantity
    : (quantity ?? 0) * (orderbookOrderEntryPrice ?? 0);

  const computedQuantity = isCurrencyKindUSD
    ? orderbookOrderEntryPrice
      ? (quantity ?? 0) / orderbookOrderEntryPrice
      : null
    : quantity;

  const { quantityError } = checkOrderForErrors({
    orderConfig: {
      value: computedValue,
      quantity: computedQuantity,
      type: OrderType.LIMIT,
      price: orderbookOrderEntryPrice,
    },
    decimalPlaces,
    assetName: selectedMarketCurrency,
    maxOrderUsdValueMinusFees,
    currencyKind: quantityCurrency,
    marketId: selectedMarketId,
    positions: accountStore._positions,
    isCurrencyKindUSD,
  });

  return (
    <Container id="order-entry-pop-up" data-cy="order-entry-pop-up">
      <NumberInput
        value={quantity}
        onChange={setQuantity}
        // placeholder={placeholderText}
        placeholder={'0.00'}
        label={t('size')}
        autoFocus
        data-cy="order-entry-pop-up-quantity-input"
        showPresets
        onSwapCurrency={swapCurrencyKind}
        currency={quantityCurrency}
        maxValue={
          isCurrencyKindUSD
            ? Math.max(longingPower, shortingPower)
            : Math.max(longMaxQuantity, shortMaxQuantity)
        }
        approximationCurrency={
          isCurrencyKindUSD ? selectedMarketCurrency : 'USD'
        }
        className="mb-10"
        approximatedValue={(isCurrencyKindUSD
          ? computedQuantity
          : computedValue
        )?.toString()}
        inputValueDecimalScale={
          isCurrencyKindUSD ? USD_DECIMAL_SCALE : decimalPlaces
        }
        approximatedValueDecimalScale={
          isCurrencyKindUSD ? decimalPlaces : USD_DECIMAL_SCALE
        }
        // In case of Limit Order, long and short errors are the same
        errorMessage={quantityError.long}
        showError={!!quantityError.long}
      />

      {accountStore?.frontendSecrets ? (
        <TradeSideAndLeverageSelector
          isLongLoading={isProcessingCreateOrder === 'buy'}
          isShortLoading={isProcessingCreateOrder === 'sell'}
          longPrice={orderbookOrderEntryPrice}
          shortPrice={orderbookOrderEntryPrice}
          type="clickable"
          onClickTradeSide={side =>
            !shouldDisableSubmitBtn && openConfirmModal(side === TradeSide.LONG)
          }
          decimalScale={decimalPlaces}
          disabled={!!quantityError.long}
        />
      ) : (
        <Button
          colorVariant="primaryGreen"
          sizeVariant="S"
          block
          onClick={() => {
            modal.present(<WalletModal />, Modals.walletModal);
          }}
        >
          {t('connectWallet')}
        </Button>
      )}
    </Container>
  );
};

export default observer(OrderEntryPopUp);
