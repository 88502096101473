import { MouseEventHandler } from 'react';

import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { useGetMarketTitleFromMarketId } from 'hooks';

import { Icon } from 'components';
import Badge from 'components/Badge';
import Text from 'components/Text';

import { useAppContext } from 'contexts/AppContext';
import { Column } from 'theme/globalStyledComponents/column';
import { Row } from 'theme/globalStyledComponents/row';

import { TradeSide } from 'enums';
import { observer } from 'mobx-react';
import styled from 'styled-components';

type StyledColumnProps = {
  limitWidth?: boolean;
};
const StyledColumn = styled(Column)<StyledColumnProps>`
  min-width: ${({ limitWidth }) => (limitWidth ? '150px' : 'initial')};
`;
type Props = {
  marketID: string | undefined;
  tradeSide?: TradeSide;
  showTitleAndLeverage?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  limitWidth?: boolean;
} & StyledColumnProps;
const MarketLogoWithTradeSide = ({
  tradeSide,
  marketID,
  onClick,
  limitWidth,
}: Props) => {
  const {
    store: { account: accountStore },
  } = useAppContext();

  const marketLogo = useGetMarketLogoFromMarketId(marketID);
  const marketTitle = useGetMarketTitleFromMarketId(marketID);

  if (!marketID) return null;

  return (
    <Row onClick={onClick}>
      <Icon className="asset-logo" src={marketLogo} alt="Market logo" />

      <StyledColumn gap={2} limitWidth={limitWidth}>
        <Text
          flexed
          gap={2}
          variant="BODY_S"
          responsiveVariant={{ default: 'BODY_M' }}
          fontWeight="semiBold"
        >
          {marketID}
          {tradeSide ? (
            <Badge
              bgColor={
                tradeSide === TradeSide.LONG
                  ? 'primaryGreenBackground100'
                  : 'primaryRedBackground100'
              }
              color={
                tradeSide === TradeSide.LONG
                  ? 'primaryGreenForeground100'
                  : 'negativeForeground200'
              }
              variant="BODY_XS"
              data-cy="order-trade-side"
              padding="3px 4px"
              responsiveVariant={{ default: 'BODY_S' }}
            >
              {tradeSide?.toLocaleUpperCase()}
            </Badge>
          ) : null}
        </Text>
        <Text
          variant="BODY_S"
          color="shadesForeground200"
          responsiveVariant={{ default: 'BODY_M' }}
        >
          {`${marketTitle} (Perp ${accountStore.getMarketLeverage(marketID)}x)`}
        </Text>
      </StyledColumn>
    </Row>
  );
};

export default observer(MarketLogoWithTradeSide);
