import Button from 'components/Button';

import { routes } from '../routes';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  padding-top: 20vh;
  text-align: center;

  span.subtitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors.warningForeground100};
  }

  h1.title {
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    letter-spacing: 1px;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.warningForeground100};
  }

  p.text {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${({ theme }) => theme.colors.background};
    margin: 24px 0 52px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 12px;

  button {
    width: 100%;
  }

  .arrow-back-icon {
    margin-right: 14px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    flex-direction: row;
    button {
      width: initial;
    }
  }
`;

const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    // if you can't navigate back (e.g. link opened in new tab), there doesn't seem to be any way of
    // verifying the history with react-router in v6, so we use window.history.state
    // https://stackoverflow.com/a/71647428/17694616
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      goHome();
    }
  };

  const goHome = () => {
    navigate(routes.trade.getRoutePath());
  };

  return (
    <Container>
      <span className="subtitle">404 error</span>
      <h1 className="title">We can't find that page</h1>
      <p className="text">
        Sorry, the page you are looking for doesn't exist or has been moved.
      </p>
      <ButtonGroup>
        <Button variant="secondaryOutline" onClick={goBack}>
          Go back
        </Button>
        <Button variant="secondaryDefault" onClick={goHome}>
          Take me home
        </Button>
      </ButtonGroup>
    </Container>
  );
};

export default NotFound;
