import { useEffect, useState } from 'react';

import {
  USD_DECIMAL_SCALE,
  getCurrencyFromTradingPair,
  roundToNearestTick,
  showNotification,
} from 'utils';
import { CreateOrderParams } from 'utils/api';
import {
  CreateLadderParamsProps,
  createLadderParams,
  hasPriceInput,
} from 'utils/order';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import useModal from 'hooks/useModal';

import { FormattedNumber, Icon } from 'components';
import Button from 'components/Button/button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { TextVariant } from 'components/Text/variant';
import CheckBox from 'components/Toggle/Checkbox';

import { calculateLiquidationPrice } from '../../../../utils/position';
import { getAvgEntryPrice } from './BuySell';
import { Seperator } from './style';
import doubleArrowLeftGray from 'assets/icons/double-arrow-left-gray.svg';
import doubleArrowLeftWhite from 'assets/icons/double-arrow-left.svg';
import doubleArrowRightGray from 'assets/icons/double-arrow-right-gray.svg';
import doubleArrowRightWhite from 'assets/icons/double-arrow-right.svg';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { Column } from 'theme/globalStyledComponents';

import { NotificationType, OrderType } from 'enums';
import { Position } from 'interfaces';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import ReactOdometer from 'react-odometerjs';
import styled from 'styled-components';

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;

  > span {
    font-size: ${TextVariant['BODY_M'].fontSize};
    line-height: ${TextVariant['BODY_M'].lineHeight};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }
  > span:first-of-type {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
  > span:last-of-type {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const PriceProtectionDescription = styled(Text)`
  color: ${({ theme }) => theme.colors.shadesForeground200};
  max-width: 358px;
`;

interface Props {
  data: CreateOrderParams | CreateOrderParams[];
  isBuy: boolean;
  onConfirm: (params: CreateOrderParams | CreateOrderParams[]) => void;
  onClose?: () => void;
  ladderParams?: CreateLadderParamsProps;
}

const ConfirmBuySellModal = ({
  data,
  isBuy,
  onConfirm,
  onClose,
  ladderParams,
}: Props) => {
  const {
    store: {
      account: accountStore,
      markets: marketsStore,
      appState: {
        disableOrderConfirmationModal,
        setDisableOrderConfirmationModal,
      },
      orderbook: { getMarketOrderEstimation },
    },
  } = useAppContext();
  const [updatedOrders, setUpdatedOrders] = useState<
    CreateOrderParams[] | undefined
  >();
  const modal = useModal();
  const { t } = useTranslation();
  const [currentOrderIndex, setCurrentOrderIndex] = useState(0);

  const ordersArray = updatedOrders || (isArray(data) ? data : [data]);

  const { marketID, size, side, triggerPrice, price, type } =
    ordersArray[currentOrderIndex];

  const handleNext = () => {
    if (currentOrderIndex < ordersArray.length - 1) {
      setCurrentOrderIndex(currentOrderIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentOrderIndex > 0) {
      setCurrentOrderIndex(currentOrderIndex - 1);
    }
  };

  const currency = getCurrencyFromTradingPair(marketID);
  const decimalPlaces = useGetDecimalPlaces(marketID);
  const market = marketsStore.getById(marketID);
  const maintenanceMargin = market?.maintenanceMargin;

  const [shouldNotShowAgain, setShouldNotShowAgain] = useState<boolean>(
    disableOrderConfirmationModal,
  );

  const marketOrderEstimation = getMarketOrderEstimation(size, side, type);

  const prePosition = {
    marketID: marketID,
    size: size,
    side: side,
    avgEntryPrice: getAvgEntryPrice(
      type,
      triggerPrice,
      market?.fairPrice,
      price,
      marketOrderEstimation?.avgPrice,
    ),
  } as Position;

  const liqPrice = calculateLiquidationPrice(
    accountStore._positions,
    accountStore._accountStats?.balance,
    prePosition,
    maintenanceMargin,
  );

  const handleSubmit = event => {
    event.preventDefault();

    if (updatedOrders) {
      onConfirm(data);
    } else {
      onConfirm(data);
    }
    modal.pop({ name: Modals.confirmBuySellModal });
  };

  const handleClose = () => {
    modal.clear();
    onClose && onClose();
  };

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit(event);
      }
      if (event.key === 'Escape') {
        handleClose();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const handleDoNotShowAgainChange = () => {
    const nextVal = !shouldNotShowAgain;
    setShouldNotShowAgain(nextVal);
    setDisableOrderConfirmationModal(nextVal);
  };

  const showPrevNext = isArray(data) && data.length > 1;

  const isLastIndex = currentOrderIndex === ordersArray.length - 1;
  const isFirstIndex = currentOrderIndex === 0;

  // On Ladder, we need to check if the order is errored
  const erroredOrders = ordersArray.filter(order => order.price <= 0);

  const erroredOrderNumbers = ordersArray
    .map((order, index) => (order.price <= 0 ? index + 1 : null))
    .filter(Boolean)
    .join(', ');

  const totalOrderSize = ordersArray.reduce(
    (acc, order) => (acc = acc + (order.price > 0 ? order.size : 0)),
    0,
  );

  const totalOrderValue = ordersArray.reduce(
    (acc, order) =>
      (acc = acc + (order.price > 0 ? order.size * order.price : 0)),
    0,
  );

  const redoLadderOrders = () => {
    if (!ladderParams) {
      showNotification({
        type: NotificationType.Negative,
        title: 'Something went wrong.',
      });
      return;
    }

    setCurrentOrderIndex(0);
    setUpdatedOrders(
      createLadderParams({ ...ladderParams, avoidZeroPrice: true }),
    );
  };

  const isCurrencyKindUSD = !!ladderParams?.isCurrencyKindUSD;

  const isAMarketOrder = type === OrderType.MARKET;

  return (
    <Modal
      showHeader={true}
      title={`${t('confirm')} ${isAMarketOrder ? 'Market' : ''} ${
        isBuy ? t('buy') : t('sell')
      }?`}
      size={'small'}
      onClose={handleClose}
      showCloseIcon={false}
      name={Modals.confirmBuySellModal}
    >
      <Column gap={20} align="stretch">
        <Column gap={5} align="stretch">
          <InfoRow>
            <span>{t('market')}</span>
            <span>{marketID}</span>
          </InfoRow>

          {triggerPrice ? (
            <InfoRow>
              <span>{t('triggerPrice')}</span>
              <span>
                <FormattedNumber
                  value={triggerPrice}
                  prefix="$"
                  decimalScale={decimalPlaces.price}
                />
              </span>
            </InfoRow>
          ) : null}

          <InfoRow>
            <span>
              {isAMarketOrder
                ? t('expectedPrice')
                : type === OrderType.ST0P_LIMIT
                ? t('limitPrice')
                : t('price')}
            </span>
            <span>
              <FormattedNumber
                value={isAMarketOrder ? marketOrderEstimation?.avgPrice : price}
                prefix="$"
                color={price <= 0 ? 'negativeForeground200' : 'white'}
                decimalScale={decimalPlaces.price}
              />
            </span>
          </InfoRow>

          <InfoRow>
            <span>{t('size')}</span>
            <span>
              <FormattedNumber
                value={size}
                suffix={` ${currency}`}
                decimalScale={decimalPlaces.size}
              />
            </span>
          </InfoRow>

          {liqPrice !== 0 ? (
            <InfoRow>
              <span>{t('estLiquidationPrice')}</span>
              <span>
                <FormattedNumber
                  value={liqPrice}
                  prefix="$"
                  decimalScale={decimalPlaces.price}
                />
              </span>
            </InfoRow>
          ) : null}
        </Column>

        {type === OrderType.MARKET ? (
          <PriceProtectionDescription variant="BODY_S">
            {t('priceProtectionPartialMarketOrders')}
          </PriceProtectionDescription>
        ) : null}

        {showPrevNext ? (
          <>
            <Seperator />

            <Column gap={5} align="stretch">
              <InfoRow>
                <span>
                  {t('order')}{' '}
                  <Text color="white">#{currentOrderIndex + 1}</Text> of{' '}
                  {ordersArray.length}
                </span>

                <Text flexed gap={10}>
                  <Icon
                    src={
                      isFirstIndex ? doubleArrowLeftGray : doubleArrowLeftWhite
                    }
                    onClick={handlePrev}
                    size="SM"
                    disabled={isFirstIndex}
                  />
                  <Icon
                    src={
                      isLastIndex ? doubleArrowRightGray : doubleArrowRightWhite
                    }
                    onClick={handleNext}
                    size="SM"
                    disabled={isLastIndex}
                  />
                </Text>
              </InfoRow>

              <InfoRow>
                <span>{t(isCurrencyKindUSD ? 'totalValue' : 'totalSize')}</span>
                <span>
                  <FormattedNumber
                    value={isCurrencyKindUSD ? totalOrderValue : totalOrderSize}
                    decimalScale={
                      isCurrencyKindUSD
                        ? USD_DECIMAL_SCALE.size
                        : decimalPlaces.size
                    }
                    suffix={` ${isCurrencyKindUSD ? 'USD' : currency}`}
                  />
                </span>
              </InfoRow>
            </Column>
          </>
        ) : null}

        {erroredOrders?.length > 0 ? (
          <Text
            variant="BODY_S"
            color="negativeForeground200"
            className="align-center"
          >
            Order{erroredOrders.length > 1 ? 's' : ''} {erroredOrderNumbers}{' '}
            will not be placed due to the price being invalid. Order{' '}
            {isCurrencyKindUSD ? 'value' : 'size'} has been{' '}
            {isCurrencyKindUSD ? 'updated' : 'reduced'} to{' '}
            <FormattedNumber
              value={isCurrencyKindUSD ? totalOrderValue : totalOrderSize}
              disableTooltip
              decimalScale={
                isCurrencyKindUSD ? USD_DECIMAL_SCALE.size : decimalPlaces.size
              }
              suffix={isCurrencyKindUSD ? ' USD' : ` ${currency}`}
            />
            .{' '}
            <Text
              underline
              color="shadesForeground200"
              onClick={redoLadderOrders}
              cursorPointer
              noWrap
            >
              {isCurrencyKindUSD
                ? 'Remove Invalid Orders'
                : 'Distribute Remaining Size'}
            </Text>
          </Text>
        ) : null}

        <CheckBox
          isChecked={shouldNotShowAgain}
          onClick={handleDoNotShowAgainChange}
          label={t('doNotShowAgain')}
        />

        <ButtonGroup>
          <Button
            colorVariant="secondary"
            sizeVariant="S"
            onClick={handleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid="confirm-buy-sell-btn"
            colorVariant={isBuy ? 'primaryGreen' : 'primaryRed'}
            sizeVariant="S"
            block={true}
            onClick={handleSubmit}
            data-gtmid={`button-confirm-${isBuy ? 'buy' : 'sell'}`}
          >
            {t('confirm')}
          </Button>
        </ButtonGroup>
      </Column>
    </Modal>
  );
};

export default observer(ConfirmBuySellModal);
