/**
 * Preloads images into cache so that they are shown immediately, e.g. in MarketSelectDropdown component
 * @param preloadSrcList Array of images to preload into cache
 */
export const preloadImages = (preloadSrcList: string[]) => {
  preloadSrcList.forEach(preloadedImg => {
    const img = new Image();
    img.src = preloadedImg;
    window[preloadedImg] = img;
  });
};
