import { useEffect, useState } from 'react';

import { Icon } from 'components';
import Loading from 'components/Loading';
import Text from 'components/Text';

import Section from './Section';
import { Container, FixedContainer } from './styles';
import arrowDownIcon from 'assets/icons/arrow-down-new.svg';
import arrowUpIcon from 'assets/icons/arrow-up-new.svg';
import starBrightIcon from 'assets/icons/star-bright.svg';
import starGrayIcon from 'assets/icons/star-gray-description.svg';
import { useAppContext } from 'contexts/AppContext';
import { Colors } from 'pages/Trade/components/shared';
import { MarketService } from 'service/marketService';

import { QueryKeys } from 'enums';
import { Market, UserSettings } from 'interfaces';
import { observer } from 'mobx-react';
import { useFetchUserSettings, useSaveSettings } from 'queryHooks';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

// Temporarily set to 0 to show all markets
const GAINERS_LIST_THRESHOLD = 0;

const marketService = MarketService.get();

const marketsQueryParams = {
  line_charts: 'true',
};

/**
 * Get the favorite markets from the list of markets
 */
const getFilteredFavoriteMarkets = (
  markets: Market[] | undefined,
  favoriteMarkets: Record<string, boolean> | undefined,
): Market[] | null => {
  if (!favoriteMarkets || !markets) {
    return null;
  }
  return markets?.filter(market => favoriteMarkets[market.id] ?? false);
};

/**
 * Get the top 5 gainers or losers from the list of markets
 */
const getTopGainersOrLosers = (
  markets: Market[] | undefined,
  isTopGainers: boolean,
): Market[] | null => {
  if (!markets) {
    return null;
  }

  const filteredMarkets = markets.filter(market => {
    const percentageChange = market.lastTradePrice24hChangeBasis * 100;
    if (isTopGainers) {
      return percentageChange >= GAINERS_LIST_THRESHOLD;
    } else {
      return percentageChange * 100 < -1 * GAINERS_LIST_THRESHOLD;
    }
  });

  const sortedMarkets = [...filteredMarkets].sort((a, b) => {
    if (isTopGainers) {
      return b.lastTradePrice24hChangeBasis - a.lastTradePrice24hChangeBasis;
    }
    return a.lastTradePrice24hChangeBasis - b.lastTradePrice24hChangeBasis;
  });

  return sortedMarkets.slice(0, 4);
};

type Props = {
  showWatchlistOnMediumDevices: boolean;
  showWatchlistOnlyOnUltraWideDevices?: boolean;
};
const Watchlist = ({
  showWatchlistOnMediumDevices,
  showWatchlistOnlyOnUltraWideDevices = false,
}: Props) => {
  const {
    store: {
      account: { frontendSecrets },
    },
  } = useAppContext();
  const { t } = useTranslation();

  const [isFixed, setIsFixed] = useState(false);

  const { isLoading, data: markets } = useQuery(
    [QueryKeys.Markets, marketsQueryParams],
    async () => {
      const markets: Market[] | null = await marketService.fetchMarkets(
        marketsQueryParams,
      );
      if (!markets) {
        throw new Error(
          'An error occurred while trying to fetch markets in MarketSearchDropdown component',
        );
      }
      return markets;
    },
    {
      refetchInterval: 6000,
    },
  );

  const { data: settingsData, isLoading: isLoadingSettings } =
    useFetchUserSettings(frontendSecrets?.jwt);

  const { handleFavoriteMarket } = useSaveSettings();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 55) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const topGainers = getTopGainersOrLosers(markets, true);
  const topLosers = getTopGainersOrLosers(markets, false);

  const filteredFavoriteMarkets = getFilteredFavoriteMarkets(
    markets,
    settingsData?.favoriteMarkets,
  );

  return (
    <Container
      showWatchlistOnMediumDevices={showWatchlistOnMediumDevices}
      showWatchlistOnlyOnUltraWideDevices={showWatchlistOnlyOnUltraWideDevices}
    >
      <FixedContainer isFixed={isFixed}>
        <Section
          title={t('watchlist')}
          items={filteredFavoriteMarkets}
          description={
            <Text variant="BODY_S" color="shadesForeground200">
              {t('clickOnThe')}{' '}
              <Icon
                size="S"
                className="in-span-icon"
                src={starGrayIcon}
                alt="star gray"
              />
              {t('clickOnThePartTwo')}
            </Text>
          }
          headerColor={Colors.Inherit}
          headerIcon={starBrightIcon}
          isWatchlist={true}
          isLoading={isLoadingSettings}
          setFavoriteMarket={handleFavoriteMarket}
        />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Section
              title={t('topGainers')}
              items={topGainers}
              headerColor={Colors.Success}
              headerIcon={arrowUpIcon}
              setFavoriteMarket={handleFavoriteMarket}
            />
            <Section
              title={t('topLosers')}
              items={topLosers}
              headerColor={Colors.Danger}
              headerIcon={arrowDownIcon}
              setFavoriteMarket={handleFavoriteMarket}
            />
          </>
        )}
      </FixedContainer>
    </Container>
  );
};

export default observer(Watchlist);
