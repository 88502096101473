import { refreshCentrifugeJwt } from 'utils';

import AccountStore from 'stores/account';

import {
  Centrifuge,
  HistoryOptions,
  Subscription,
  SubscriptionState,
} from 'centrifuge';
import { config } from 'config';

const DEFAULT_CENTRIFUGE_OPTIONS = {
  minReconnectDelay: 1000,
  maxReconnectDelay: 5000,
  maxServerPingDelay: 5000,
};

enum ConnectionType {
  Private = 'private',
  Public = 'public',
}

const PUBLIC_JWT_TOKEN = process.env.REACT_APP_PUBLIC_JWT_TOKEN;
if (typeof PUBLIC_JWT_TOKEN === 'undefined') {
  throw new Error(
    `REACT_APP_PUBLIC_JWT_TOKEN must be a defined environment variable`,
  );
}

export class CentrifugeService {
  private static publicInstance: CentrifugeService | null;
  private static privateInstance: CentrifugeService | null;

  public centrifuge: Centrifuge;
  public subscriptions: { [key: string]: Subscription } = {};
  public connectionType: ConnectionType;
  public accountStore: AccountStore | null | undefined = null;
  public walletAddress: string | null | undefined = null;

  constructor(
    WEBSOCKET_URL: string,
    JWT_TOKEN?: string,
    walletAddress?: string,
    accountStore?: AccountStore,
  ) {
    // For private channels
    if (JWT_TOKEN) {
      if (!walletAddress || !accountStore) {
        throw new Error(
          'walletAddress and accountStore are required when JWT_TOKEN is present',
        );
      }
      this.accountStore = accountStore;
      this.walletAddress = walletAddress;
      this.centrifuge = new Centrifuge(WEBSOCKET_URL, {
        token: JWT_TOKEN,
        ...DEFAULT_CENTRIFUGE_OPTIONS,
        getToken: () => {
          return refreshCentrifugeJwt(walletAddress, accountStore);
        },
      });
    } else {
      this.centrifuge = new Centrifuge(
        WEBSOCKET_URL,
        // @TODO: This should be a public connection, but the backend still requires this public jwt token
        {
          token: PUBLIC_JWT_TOKEN,
          ...DEFAULT_CENTRIFUGE_OPTIONS,
        },
      );
    }

    this.connectionType = JWT_TOKEN
      ? ConnectionType.Private
      : ConnectionType.Public;

    this.centrifuge.connect();
    this.centrifuge.on('error', data => {
      console.log(
        `Centrifuge went into 'error' mode!. Connection type: ${this.connectionType}`,
        data,
      );
    });
    this.centrifuge.on('connected', data => {
      console.log(
        `Centrifuge successfully connected!. Connection type: ${this.connectionType}`,
        data,
      );
    });
    this.centrifuge.on('connecting', data => {
      console.log(
        `Centrifuge went into 'connecting' mode!. Connection type: ${this.connectionType}`,
        data,
      );
    });
    this.centrifuge.on('disconnected', data => {
      console.warn(
        `Centrifuge disconnected!. Connection type: ${this.connectionType}`,
        data,
      );
    });
  }

  static disconnectPublic = () => {
    const publicCentrifuge = CentrifugeService.publicInstance;
    publicCentrifuge &&
      Object.keys(publicCentrifuge.subscriptions).forEach(el =>
        publicCentrifuge.removeSubscription(el),
      );
    publicCentrifuge && publicCentrifuge.centrifuge.disconnect();
    publicCentrifuge && publicCentrifuge.centrifuge.removeAllListeners();
    CentrifugeService.publicInstance = null;
  };

  static disconnectPrivate = () => {
    const privateCentrifuge = CentrifugeService.privateInstance;
    privateCentrifuge &&
      Object.keys(privateCentrifuge.subscriptions).forEach(el =>
        privateCentrifuge.removeSubscription(el),
      );
    privateCentrifuge && privateCentrifuge.centrifuge.disconnect();
    privateCentrifuge && privateCentrifuge.centrifuge.removeAllListeners();
    CentrifugeService.privateInstance = null;
  };

  static getPublic = (): CentrifugeService => {
    if (!CentrifugeService.publicInstance) {
      CentrifugeService.publicInstance = new CentrifugeService(
        config.websocketUrl,
      );
    }
    return CentrifugeService.publicInstance;
  };

  static getPrivate = (
    jwtToken: string,
    walletAddress?: string,
    accountStore?: AccountStore,
  ): CentrifugeService => {
    if (!CentrifugeService.privateInstance) {
      CentrifugeService.privateInstance = new CentrifugeService(
        config.websocketUrl,
        jwtToken,
        walletAddress,
        accountStore,
      );
    }

    return CentrifugeService.privateInstance;
  };

  addSubscription = (
    channel: string,
    callbacks?: {
      subscribed?: (data: any) => void;
      subscribing?: (data: any) => void;
      unsubscribed?: (data: any) => void;
      error?: (data: any) => void;
    },
  ): Subscription => {
    if (this.hasSubscription(channel)) {
      return this.subscriptions[channel];
    }

    this.subscriptions[channel] = this.centrifuge.newSubscription(channel);
    this.subscriptions[channel].on('error', (e: any) => {
      callbacks?.error && callbacks?.error(e);
      console.error(
        `Centrifuge Subscription for channel ${channel} failed with error: ${e?.message}`,
      );
    });
    this.subscriptions[channel].on('unsubscribed', data => {
      callbacks?.unsubscribed && callbacks?.unsubscribed(data);
      console.log(
        `Centrifuge Subscription for channel ${channel} went into "unsubscribed" state`,
      );
    });
    this.subscriptions[channel].on('subscribed', data => {
      callbacks?.subscribed && callbacks?.subscribed(data);
      console.log(
        `Centrifuge Subscription for channel ${channel} went into "subscribed" state`,
      );
    });
    this.subscriptions[channel].on('subscribing', data => {
      callbacks?.subscribing && callbacks?.subscribing(data);
      console.log(
        `Centrifuge Subscription for channel ${channel} went into "subscribing" state`,
        data,
      );
    });
    this.subscriptions[channel].subscribe();

    return this.subscriptions[channel];
  };

  removeSubscription = (channel: string) => {
    if (!this.hasSubscription(channel)) return;

    this.subscriptions[channel].unsubscribe();
    this.centrifuge.removeSubscription(this.subscriptions[channel]);
  };

  getHistory = async (channel: string, limit: number) => {
    if (!this.hasSubscription(channel)) return;

    const opts: HistoryOptions = {
      limit,
      since: undefined,
      reverse: true,
    };
    return await this.subscriptions[channel].history(opts);
  };

  hasSubscription = (channel: string): boolean => {
    return (
      this.subscriptions[channel]?.state === SubscriptionState.Subscribed ||
      this.subscriptions[channel]?.state === SubscriptionState.Subscribing
    );
  };

  getSubscription = (channel: string): Subscription => {
    return this.subscriptions[channel];
  };

  refreshJwt = () => {
    if (!this.walletAddress || !this.accountStore) {
      return;
    }
    refreshCentrifugeJwt(this.walletAddress, this.accountStore);
  };
}

export default CentrifugeService;
