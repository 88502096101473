import { formatDateToLocal, textWithEllipsisInTheMiddle } from 'utils';

import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import { FormattedNumber, FormattedPercentage } from 'components';
import InfoTooltip from 'components/InfoTooltip';
import Text from 'components/Text';

import { GeneralStatsContainer } from './styles';
import newTabIcon from 'assets/icons/new-tab-white.svg';
import { elixirVaultsConstants } from 'pages/Vaults/Pages/Vaults/TabPanels/ElixirVaults/constants';
import { Column } from 'pages/Vaults/styles';
import {
  getCurrentPps,
  getTotalApyForVault,
  hasHardCap,
} from 'pages/Vaults/utils';

import { config } from 'config';
import { useTranslation } from 'react-i18next';

type Props = { vault: VaultWithContractInfo; address: string };

const GeneralStats = ({ address, vault }: Props) => {
  const { t } = useTranslation();
  const vaultConstants = elixirVaultsConstants[vault.elixirPairId];

  const generalStats = [
    {
      label: t('inceptionDate'),
      value: formatDateToLocal(vaultConstants.inceptionDate),
    },
    {
      label: t('contractAddress'),
      value: textWithEllipsisInTheMiddle(
        config.elixirVaults.rabbitManagerContractAddress,
      ),
      openInNewTab: () =>
        window.open(
          `${config.etherscanUrl}/address/${config.elixirVaults.rabbitManagerContractAddress}`,
          '_blank',
        ),
    },
    {
      label: t('hardCap'),
      value: hasHardCap(vault.hardcap) ? (
        <FormattedNumber prefix="$" value={vault.hardcap} />
      ) : (
        t('noCap')
      ),
    },
    {
      label: t('productID'),
      value: vault.productId,
    },
    {
      label: t('inceptionPPS'),
      value: (
        <FormattedNumber
          value={
            elixirVaultsConstants[vault.elixirPairId].inceptionPricePerShare
          }
          prefix="$"
        />
      ),
      toolTipText: t('inceptionPPSTooltip'),
    },
    {
      label: t('currentPPS24h'),
      value: <FormattedNumber value={getCurrentPps(vault)} prefix="$" />,
      toolTipText: t('netValuePerShare'),
    },
    {
      label: t('estTotalAPY'),
      value: <FormattedPercentage value={getTotalApyForVault(vault)} />,
    },
    {
      label: t('managementFee'),
      value: <FormattedPercentage value={0.05} />,
    },
    {
      label: t('estRewardsAPY'),
      value: <FormattedPercentage value={vault.apy} />,
    },
    {
      label: t('performanceFee'),
      value: <FormattedPercentage value={0} />,
    },
  ];

  return (
    <GeneralStatsContainer>
      {/* <Text variant="BODY_M" fontWeight="semiBold">
        {t('generalStats')}
      </Text> */}

      <div className="grid">
        {generalStats.map(({ label, value, toolTipText, openInNewTab }) => (
          <Column className="item">
            <Text
              variant="BODY_S"
              className="flex items-center gap-2"
              color="shadesForeground200"
            >
              {label}
              {toolTipText ? (
                <InfoTooltip iconSize={12} title={toolTipText} />
              ) : null}
            </Text>

            <Text variant="BODY_S" className="flex items-center gap-2">
              {value}
              {openInNewTab ? (
                <img
                  src={newTabIcon}
                  onClick={openInNewTab}
                  alt="->"
                  className="icon-12 cursor-pointer"
                />
              ) : null}
            </Text>
          </Column>
        ))}
      </div>
    </GeneralStatsContainer>
  );
};

export default GeneralStats;
