import { useCallback } from 'react';

import { useExchangeAPI } from './useExchangeAPI';
import { API_MAPPINGS } from 'constants/apiMappings';
import { RequestResponse } from 'service/restService';

import { Endpoints, RequestMethod } from 'enums';
import { Fill } from 'interfaces';

export function useFillsAPI() {
  const { makePrivateRequest } = useExchangeAPI();

  const fetchPrivateFills = useCallback(
    async ({ queryParams }): Promise<RequestResponse<Fill[]>> => {
      try {
        const res = await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.FILLS}`,
          requestParams: {},
          responseMapping: API_MAPPINGS.FILL,
          isArray: true,
          queryParams,
          shouldCheckJwtValidity: false,
        });

        return res;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [makePrivateRequest],
  );

  const fetchFillsForOrder = useCallback(
    async (orderId: string): Promise<any> => {
      try {
        const { data }: { data: any } = await makePrivateRequest({
          method: RequestMethod.GET,
          endpoint: `/${Endpoints.FILLS_ORDER}`,
          requestParams: {},
          queryParams: {
            order_id: orderId,
          },
          responseMapping: API_MAPPINGS.FILL,
          isArray: true,
          shouldCheckJwtValidity: false,
        });

        return data;
      } catch (e: any) {
        console.error(e.message);
        throw e;
      }
    },
    [makePrivateRequest],
  );

  return {
    fetchPrivateFills,
    fetchFillsForOrder,
  } as const;
}

export type FillsAPI = ReturnType<typeof useFillsAPI>;
