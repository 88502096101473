import React, { memo, useState } from 'react';

import Icon from 'components/Icon';
import { SelectDropdown } from 'components/SelectDropdown.tsx';
import Text from 'components/Text';
import { TextVariant } from 'components/Text/variant';

import { Container, Tab } from './styles';
import chevronDownGray from 'assets/icons/chevron-down-gray.svg';
import chevronDownWhite from 'assets/icons/chevron-down-white.svg';
import chevronUpGray from 'assets/icons/chevron-up-gray.svg';
import chevronUpWhite from 'assets/icons/chevron-up-white.svg';

import { useTranslation } from 'react-i18next';

const getDisplayIcon = (
  dropdownDisplay: string | null,
  isDropdownShown: boolean,
) => {
  if (dropdownDisplay)
    return isDropdownShown ? chevronUpWhite : chevronDownWhite;
  else {
    return isDropdownShown ? chevronUpGray : chevronDownGray;
  }
};

type Option<T = string> = {
  label: string;
  value: T;
  bgColor?: string;
};

type Props<T = string> = {
  buttonOptions: Option<T>[];
  dropdownOptions?: Option<T>[];
  handleOptionSelect: (option: T) => void;
  initialSelectedOption?: T;
  initialOptionsDropdownAlignment?: 'left' | 'right';
  bgColor?: string;
  disabled?: boolean;
  variant?: keyof typeof TextVariant;
};

const TabSelector = <T,>({
  buttonOptions,
  dropdownOptions,
  handleOptionSelect,
  initialSelectedOption,
  initialOptionsDropdownAlignment = 'right',
  bgColor,
  disabled = false,
  variant,
}: Props<T>) => {
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<T | null>(
    initialSelectedOption ?? null,
  );
  const [dropdownDisplay, setDropdownDisplay] = useState<string | null>(null);
  const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);

  const onOptionSelect = (option: Option<T>) => {
    setSelectedOption(option.value);
    handleOptionSelect(option.value);
  };

  const handleButtonClick = (option: Option<T>) => {
    onOptionSelect(option);
    setDropdownDisplay(null);
  };

  const handleDropdownChange = (option: Option<T>) => {
    onOptionSelect(option);
    setDropdownDisplay(option.label);
  };

  return (
    <Container className="tabs-container">
      {buttonOptions.map((option, index) => (
        <Tab
          key={index.toString()} // Ensure a unique key
          onClick={() => !disabled && handleButtonClick(option)}
          isActive={selectedOption === option.value}
          variant={variant || 'BODY_M'}
          bgColor={bgColor || option?.bgColor}
        >
          {option.label}
        </Tab>
      ))}

      {dropdownOptions && (
        <SelectDropdown<Option<T>>
          options={dropdownOptions}
          onOptionSelect={handleDropdownChange}
          customStyles={{
            top: '40px',
            right: initialOptionsDropdownAlignment === 'right' ? 'auto' : '0',
          }}
          disabled={disabled}
          displayTextParser={val => val.label}
          onDropdownShownChange={setIsDropdownShown}
          InputElement={
            <Tab
              isActive={dropdownOptions.some(
                option => option.value === selectedOption,
              )}
              fixedWidth
            >
              <Text variant="BODY_M" className="label">
                {dropdownDisplay ?? t('more')}
              </Text>
              <Icon
                src={getDisplayIcon(dropdownDisplay, isDropdownShown)}
                alt={isDropdownShown ? 'Caret up icon' : 'Caret down icon'}
                size="M"
              />
            </Tab>
          }
          isOptionSelected={option => option.value === selectedOption}
        />
      )}
    </Container>
  );
};

export default TabSelector;
