import { Icon } from 'components';

import checkCircle from 'assets/icons/check-white-circle-positive.svg';

import styled from 'styled-components';

type Props = {
  currentStepIndex: number;
  stepIndex: number;
};

const EmptyCircle = styled.div`
  position: relative;
  z-index: 1;
  height: 14px;
  width: 14px;
  border-radius: 100px;
  background: ${({ theme }) => theme.colors.shadesBackground600};
`;

export const SubStepIcon = ({ currentStepIndex, stepIndex }: Props) => {
  const isChecked = currentStepIndex > stepIndex;

  if (isChecked) return <Icon src={checkCircle} size="SM" />;

  return <EmptyCircle />;
};

export default SubStepIcon;
