import { Icon } from 'components';
import Text from 'components/Text';

import { Steps } from './styles';
import levelUpIcon from 'assets/icons/level-up.svg';
import linkIcon from 'assets/icons/link.svg';
import walletIcon from 'assets/icons/wallet-white.svg';
import { Column } from 'theme/globalStyledComponents/column';

const STEPS = [
  {
    icon: linkIcon,
    title: 'Sign up with your link',
    description: 'And connect their wallet for the first time',
  },
  {
    icon: walletIcon,
    title: 'Add money to their account',
    description: 'By depositing a supported stablecoin',
  },
  {
    icon: levelUpIcon,
    title: 'Level up & earn rewards',
    description: 'Via your friend trading more',
  },
];

const StepsForInvitingFriends = () => {
  return (
    <Steps>
      {STEPS.map((step, index) => (
        <div key={index} className="step">
          <Icon size="ML" src={step.icon} alt="link" />
          <Column gap={4}>
            <Text variant="BODY_M" fontWeight="semiBold">
              {step.title}
            </Text>
            <Text variant="BODY_XS" color="shadesForeground200">
              {step.description}
            </Text>
          </Column>
        </div>
      ))}
    </Steps>
  );
};

export default StepsForInvitingFriends;
