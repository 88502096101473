import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import { getCurrentPps } from 'pages/Vaults/utils';

import { SortBy, SortConfig } from '.';

export const sortVaults = (
  _vaults: VaultWithContractInfo[] | undefined,
  sortConfig: SortConfig | undefined,
) => {
  if (!sortConfig) return _vaults || [];

  let vaults = _vaults ? [..._vaults] : [];

  if (sortConfig.sortBy === SortBy.APY) {
    vaults = sortVaultsByProperty(vaults, 'apy', sortConfig.numOfClick);
  }
  if (sortConfig.sortBy === SortBy.TVL) {
    vaults = sortVaultsByProperty(vaults, 'tvlUsd', sortConfig.numOfClick);
  }
  if (sortConfig.sortBy === SortBy.PPS) {
    vaults = sortVaultsByPps(vaults, sortConfig.numOfClick);
  }
  return vaults || [];
};

const sortVaultsByProperty = (
  vaults: VaultWithContractInfo[],
  property: 'apy' | 'tvlUsd',
  numOfClick: number,
) => {
  return vaults.sort((a, b) => {
    let aValue = a[property];
    let bValue = b[property];
    if (numOfClick % 3 === 1) {
      // Ascending
      return aValue - bValue;
    } else if (numOfClick % 3 === 2) {
      // Descending
      return bValue - aValue;
    }
    // No sorting
    return 0;
  });
};

const sortVaultsByPps = (
  vaults: VaultWithContractInfo[],
  numOfClick: number,
) => {
  return vaults.sort((a, b) => {
    let aValue = getCurrentPps(a);
    let bValue = getCurrentPps(b);
    if (numOfClick % 3 === 1) {
      // Ascending
      return aValue - bValue;
    } else if (numOfClick % 3 === 2) {
      // Descending
      return bValue - aValue;
    }
    // No sorting
    return 0;
  });
};
