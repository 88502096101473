import { memo } from 'react';

import { FormattedNumber } from 'components';
import Text from 'components/Text';

import { Container } from './styles';
import { Column } from 'theme/globalStyledComponents/column';
import { Row } from 'theme/globalStyledComponents/row';

import { useTranslation } from 'react-i18next';

const calculateMarginRequirement = (
  totalPositionMargin: number,
  totalOrderMargin: number,
) => {
  return totalPositionMargin + totalOrderMargin;
};

type PortfolioStatsProps = {
  unrealizedPnl: number;
  accountLeverage: number;
  totalPositionMargin: number;
  totalOrderMargin: number;
  balance: number;
};
const PortfolioStats = ({
  unrealizedPnl,
  accountLeverage,
  totalPositionMargin,
  totalOrderMargin,
  balance,
}: PortfolioStatsProps) => {
  const { t } = useTranslation();

  const isPnl24hPositive = unrealizedPnl >= 0;
  const marginRequirement = calculateMarginRequirement(
    totalPositionMargin,
    totalOrderMargin,
  );

  return (
    <Container>
      <Text variant="BODY_L" color="shadesForeground200">
        {t('portfolioStats')}
      </Text>

      <Row className="row">
        <Column className="flex-1" gap={2}>
          <Text variant="BODY_S" color="shadesForeground200">
            {t('unrealisedPnl')}
          </Text>

          <FormattedNumber
            color={
              isPnl24hPositive
                ? 'primaryGreenForeground100'
                : 'negativeForeground200'
            }
            variant="BODY_S"
            value={unrealizedPnl}
            fontWeight="semiBold"
            prefix="$"
          />
        </Column>

        <Column className="flex-1" gap={2}>
          <Text variant="BODY_S" color="shadesForeground200">
            {t('accountLeverage')}
          </Text>
          <FormattedNumber
            variant="BODY_S"
            value={accountLeverage}
            fontWeight="semiBold"
            suffix="x"
          />
        </Column>
      </Row>

      <Row>
        <Column className="flex-1" gap={2}>
          <Text variant="BODY_S" color="shadesForeground200">
            {t('marginReq')}
          </Text>
          <FormattedNumber
            variant="BODY_S"
            fontWeight="semiBold"
            value={marginRequirement}
            prefix="$"
          />
        </Column>

        <Column className="flex-1" gap={2}>
          <Text variant="BODY_S" color="shadesForeground200">
            {t('availableBalance')}
          </Text>
          <FormattedNumber
            variant="BODY_S"
            fontWeight="semiBold"
            value={balance}
            prefix="$"
            negativeToZero
          />
        </Column>
      </Row>
    </Container>
  );
};

export default memo(PortfolioStats);
