import { getCurrencyFromTradingPair } from 'utils';

import { useConfirmCancelOrder } from 'hooks/useConfirmCancelOrder';
import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';

import ConfirmationModal from 'components/ConfirmationModal';
import DisabledCancelOrderConfirmationCheckbox from 'components/Tables/OrdersTable/DisabledCancelOrderConfirmationCheckbox';

import { Order } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const ConfirmCancelMutipleOrdersModal = ({ orders }: { orders: Order[] }) => {
  const { t } = useTranslation();
  const areMultipleOrders = orders.length > 1;
  const { cancelMultipleOrders } = useConfirmCancelOrder();

  const { marketID, price } = orders[0];
  const currency = getCurrencyFromTradingPair(marketID);
  const { size: sizePrecision } = useGetDecimalPlaces(marketID);

  const totalRemainingSize = orders.reduce((accumulator, currentOrder) => {
    return accumulator + currentOrder.remainingSize;
  }, 0);

  return (
    <ConfirmationModal
      title={
        areMultipleOrders
          ? `Cancel ${orders.length} orders?`
          : t('confirmCancel?')
      }
      description={`Are you sure you want to cancel your ${
        areMultipleOrders
          ? `${
              orders.length
            } orders with a total size of ${totalRemainingSize.toFixed(
              sizePrecision,
            )} ${currency}`
          : `order with a size of ${totalRemainingSize} ${currency}`
      }  for market ${marketID} @ ${price}?`}
      onButtonRightPress={() => cancelMultipleOrders(orders, true)}
      buttonRightProps={{ colorVariant: 'primaryRed' }}
      buttonRightTitle={t('confirm')}
      buttonLeftTitle={t('dontCancel')}
    >
      <DisabledCancelOrderConfirmationCheckbox />
    </ConfirmationModal>
  );
};

export default observer(ConfirmCancelMutipleOrdersModal);
