import { useCallback, useMemo, useState } from 'react';

import {
  capitalizeFirstLetter,
  getCurrencyInputValue,
  readUserSetting,
  roundToNearestTick,
  saveUserSetting,
  showNotification,
} from 'utils';

import { useActiveWeb3React } from 'hooks';
import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import useKey, { Key } from 'hooks/useKey';
import useModal from 'hooks/useModal';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import { ButtonSwitch, ButtonSwitchOption, FormattedNumber } from 'components';
import Badge from 'components/Badge';
import Button from 'components/Button/button';
import NumberInput from 'components/Inputs/NumberInput';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import Text from 'components/Text';

import MarketSlippage from './MarketSlippage';
import { MARKET_PRICE_SPREAD_THRESHOLD_PERCENTAGE } from 'constants/general';
import { LS_DEFAULT_MARKET_CLOSE_SLIPPAGE } from 'constants/localStorageKeys';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { useOrderbookSnapshot } from 'queryHooks/orderbookSnapshot';
import { MarketService } from 'service/marketService';
import { mixpanelService } from 'service/mixpanelService';
import { Row } from 'theme/globalStyledComponents';

import { NotificationType, OrderType, QueryKeys, TradeSide } from 'enums';
import { Market, Position } from 'interfaces';
import { isNull } from 'lodash';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
`;

type MarginSeperatorProps = {
  height?: number;
};
const MarginSeparator = styled.div<MarginSeperatorProps>`
  width: 100%;
  height: ${({ height = 20 }) => `${height}px`};
`;

interface Props {
  currency: string;
  position: Position;
  initialPrice: number | null;
}

const initialOrderTypeOptions: ButtonSwitchOption<OrderType>[] = [
  { text: OrderType.MARKET, selected: true },
  { text: OrderType.LIMIT, selected: false },
];

const getShouldDisableConfirmBtn = (
  isClosingPosition: boolean,
  orderType: OrderType,
  price: number | null,
  inputSize: number | null,
  positionSize: number,
  slippage: number | null,
): boolean => {
  if (isClosingPosition) {
    return true;
  }

  // If order type is limit, price must be provided and greater than 0
  if (orderType === OrderType.LIMIT) {
    if (price === null || price <= 0) {
      return true;
    }
  } else {
    if (slippage && slippage > MARKET_PRICE_SPREAD_THRESHOLD_PERCENTAGE)
      return true;
  }

  if (inputSize === null || inputSize <= 0) {
    return true;
  }

  // is inputSize is greater than Position Size
  if (inputSize > positionSize) return true;

  return false;
};

const checkForErrors = (
  position: Position,
  inputSize: number | null,
  price: number | null,
  orderType: OrderType | null,
  slippage: number | null,
) => {
  let sizeError: string | null = null;
  let slippageError: string | null = null;

  if (slippage) {
    if (slippage > MARKET_PRICE_SPREAD_THRESHOLD_PERCENTAGE) {
      slippageError = `Slippage Must be below ${MARKET_PRICE_SPREAD_THRESHOLD_PERCENTAGE}%`;
    }
  }

  if (!inputSize) return { slippageError };
  if (inputSize > position.size)
    sizeError = 'Input size must be less than the position size';

  if (orderType === OrderType.LIMIT && !price)
    return {
      priceError: 'Price is required for Limit Order',
      sizeError,
    };

  return {
    sizeError,
    slippageError,
  };
};

const getPnL = (
  position: Position,
  price: number | null,
  inputSize: number | null,
  isMarketClose: boolean,
) => {
  if (!price || !inputSize) return 0;

  if (position.side === TradeSide.LONG) {
    if (isMarketClose)
      return position.unrealizedPnl * (inputSize / position.size);
    return (price - position.avgEntryPrice) * inputSize;
  } else {
    if (isMarketClose)
      return position.unrealizedPnl * (inputSize / position.size);
    return (position.avgEntryPrice - price) * inputSize;
  }
};

export const getDefaultSlippage = () => {
  const slippageFromLS = parseFloat(
    readUserSetting(LS_DEFAULT_MARKET_CLOSE_SLIPPAGE),
  );
  if (!slippageFromLS || isNaN(slippageFromLS)) return null;
  return slippageFromLS;
};

export const saveSlippageToLS = (val: number | null) => {
  if (!val) {
    saveUserSetting(LS_DEFAULT_MARKET_CLOSE_SLIPPAGE, '');
    return;
  }

  // Save max slippage if user inputs anything above max
  if (val > MARKET_PRICE_SPREAD_THRESHOLD_PERCENTAGE) {
    saveUserSetting(
      LS_DEFAULT_MARKET_CLOSE_SLIPPAGE,
      MARKET_PRICE_SPREAD_THRESHOLD_PERCENTAGE.toString(),
    );
    return;
  }

  saveUserSetting(LS_DEFAULT_MARKET_CLOSE_SLIPPAGE, val.toString());
};

const marketService = MarketService.get();

const ClosePositionModal = ({ position, currency, initialPrice }: Props) => {
  const [slippage, setSlippage] = useState<number | null>(getDefaultSlippage());
  const { isLoading: isLoadingMarket, data: market } = useQuery(
    [QueryKeys.Markets, position.marketID],
    async () => {
      const market: Market | null = await marketService.fetchMarketWithId(
        position.marketID,
      );
      if (!market) {
        throw new Error(
          'An error occurred while trying to fetch market in ClosePositionModal component',
        );
      }
      setPrice(market.lastTradePrice);
      return market;
    },
    {
      refetchInterval: false,
      enabled: !!position.marketID,
    },
  );

  const { midPrice } = useOrderbookSnapshot(position.marketID);

  const modal = useModal();
  const { t } = useTranslation();
  const { createOrder } = useOrdersAPI();
  const [isClosingPosition, setIsClosingPosition] = useState(false);
  const { account } = useActiveWeb3React();

  const { size: initialSize, marketID } = position;

  const decimalScale = useGetDecimalPlaces(marketID);
  const roundedPrice =
    initialPrice && roundToNearestTick(initialPrice, decimalScale.minTick);
  const roundedSize = roundToNearestTick(initialSize, decimalScale.minOrder);

  const [price, setPrice] = useState<number | null>(
    roundedPrice || (market ? market.marketPrice : null),
  );
  const [size, setSize] = useState<number | null>(roundedSize);
  const [orderType, setOrderType] = useState<OrderType>(OrderType.MARKET);

  const makeClosePositionRequest = async (
    position: Position,
    orderType: OrderType,
    price: number | null,
    size: number,
  ) => {
    try {
      if (!account) {
        return;
      }

      const roundedPrice = roundToNearestTick(price ?? 0, decimalScale.minTick);
      const roundedSize = roundToNearestTick(size, decimalScale.minOrder);

      await createOrder({
        price: roundedPrice,
        size: roundedSize,
        // Order side should be the opposite of position's side
        side:
          position.side === TradeSide.LONG ? TradeSide.SHORT : TradeSide.LONG,
        marketID: position.marketID,
        type: orderType,
      });

      mixpanelService.closePositionSuccess(account);
    } catch (e) {
      console.error('An error occurred while closing a position.');
      throw e;
    }
  };

  const shouldDisableConfirmBtn = getShouldDisableConfirmBtn(
    isClosingPosition,
    orderType,
    price,
    size,
    position.size,
    slippage,
  );

  const handleClosePositionClick = useCallback(async () => {
    if (shouldDisableConfirmBtn || isClosingPosition) return;

    // If orderType is MARKET, price is not required
    if (orderType === OrderType.MARKET && !size) {
      return;
    }
    // If orderType is LIMIT, price is required
    if ((orderType === OrderType.LIMIT && !price) || !size) {
      return;
    }

    try {
      flushSync(() => {
        setIsClosingPosition(true);
      });
      let marketOrLimitPrice = price;
      let _orderType = orderType;

      // Temporary: fetch market's market price when orderType === 'MARKET'
      if (orderType === OrderType.MARKET) {
        const marketService = MarketService.get();
        const market = await marketService.fetchMarketWithId(position.marketID);
        if (!market) {
          throw new Error(
            'An error occurred when trying to fetch market price when closing a position.',
          );
        }
        marketOrLimitPrice = market.marketPrice;

        // If slippage is provided, we place a limit order with slippage applied from the market price
        if (slippage) {
          if (position.side === TradeSide.LONG) {
            marketOrLimitPrice = marketOrLimitPrice * (1 - slippage / 100);
          } else {
            marketOrLimitPrice = marketOrLimitPrice * (1 + slippage / 100);
          }
          _orderType = OrderType.LIMIT;
        }
      }

      await makeClosePositionRequest(
        position,
        _orderType,
        marketOrLimitPrice,
        size,
      );
      modal.clear();
    } catch (e: any) {
      console.error(e);
      showNotification({
        title: t('closingPositionFailed'),
        description: t('closingPositionFailedDescription', {
          errorMessage: e.message,
        }),
        type: NotificationType.Negative,
      });
    } finally {
      setIsClosingPosition(false);
    }
  }, [
    shouldDisableConfirmBtn,
    isClosingPosition,
    makeClosePositionRequest,
    orderType,
    slippage,
  ]);

  const saveSlippageToLSDebounced = useDebouncedCallback(value => {
    saveSlippageToLS(value);
  }, 1000);

  if (isLoadingMarket) {
    return (
      <Modal
        showHeader={true}
        title={`Close Position : ${marketID}`}
        size={'small'}
        name={Modals.closePositionModal}
        showCloseIcon={false}
      >
        <div className="flex justify-center items-center h-40">
          <Loading />
        </div>
      </Modal>
    );
  }

  const errors = checkForErrors(position, size, price, orderType, slippage);

  if (!market?.fairPrice) return null;

  const PnL = getPnL(position, price, size, orderType === OrderType.MARKET);
  const isGain = PnL > 0;
  const isLoss = PnL < 0;
  const isNeutral = PnL === 0;

  const onSlippageChange = (val: number | null) => {
    saveSlippageToLSDebounced(val);
    setSlippage(val);
  };

  return (
    <Modal
      showHeader={true}
      title={`Close Position : ${marketID}`}
      size={'small'}
      name={Modals.closePositionModal}
      showCloseIcon={false}
      handleKeyStrokes={{
        [Key.Enter]: handleClosePositionClick,
      }}
    >
      <ButtonSwitch
        key={orderType}
        data-cy="close-position-modal-order-type"
        displayTextTransform={(text: string | number) =>
          capitalizeFirstLetter(text as string)
        }
        initialOptions={initialOrderTypeOptions.map(i => ({
          ...i,
          selected: i.text === orderType,
        }))}
        onOptionSelect={value => setOrderType(value as OrderType)}
        dataGtmIds={[
          'tab-close-position-popup-limit',
          'tab-close-position-popup-market',
        ]}
        textVariant="BODY_M"
      />

      <MarginSeparator />

      {orderType === OrderType.LIMIT ? (
        <>
          <NumberInput
            data-cy="close-position-modal-price-input"
            value={getCurrencyInputValue(price)}
            showPresets={false}
            label="Price"
            currency="USD"
            onChange={value => {
              setPrice(value);
            }}
            showSwapCurrency={false}
            showCurrencyIcon={true}
            showValueApproximation={false}
            className="gap-10"
            errorMessage={errors?.priceError}
            showError={!!errors?.priceError}
            midValue={midPrice}
          />
          <MarginSeparator height={10} />
        </>
      ) : null}

      <NumberInput
        data-cy="close-position-modal-size-input"
        value={getCurrencyInputValue(size)}
        label="Size"
        showPresets={true}
        maxValue={roundedSize}
        currency={currency}
        onChange={value => {
          setSize(value);
        }}
        showSwapCurrency={false}
        showCurrencyIcon={true}
        showValueApproximation={false}
        errorMessage={errors?.sizeError}
        showError={!!errors?.sizeError}
      />

      <Row justify="space-between" className="mt-10">
        <Text variant="BODY_S" color="shadesForeground200">
          {t(isLoss ? 'expectedLoss' : 'expectedGain')}
        </Text>
        <Row gap={5}>
          <FormattedNumber
            color={
              isNeutral
                ? 'shadesForeground200'
                : isGain
                ? 'positiveForeground200'
                : 'negativeForeground200'
            }
            value={Math.abs(PnL)}
            variant="BODY_S"
            prefix="$"
          />
          <Badge
            padding="4px 6px"
            borderColor="shadesBackground700"
            borderRadius={3}
            variant="BODY_XS"
            color="shadesForeground200"
          >
            USD
          </Badge>
        </Row>
      </Row>

      {orderType === OrderType.MARKET ? (
        <MarketSlippage
          slippage={slippage}
          onSlippageChange={onSlippageChange}
          error={errors?.slippageError}
        />
      ) : null}
      <ButtonGroup className="mt-20">
        <Button colorVariant="secondary" sizeVariant="S" onClick={modal.clear}>
          {t('cancel')}
        </Button>
        <Button
          data-testid="history-position-close-modal"
          data-cy="close-position-modal-confirm-btn"
          colorVariant={'primaryRed'}
          sizeVariant="S"
          block={true}
          disabled={shouldDisableConfirmBtn}
          onClick={handleClosePositionClick}
          data-gtmid={`button-close-position-popup-${orderType.toLowerCase()}-close`}
          isLoading={isClosingPosition}
        >
          {capitalizeFirstLetter(orderType)} Close
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default observer(ClosePositionModal);
