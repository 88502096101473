import { memo } from 'react';

import Button from 'components/Button';

import { SwapSteps } from '.';
import { useTranslation } from 'react-i18next';

const getButtonLabel = (isLoading: boolean, step: SwapSteps, t: any) => {
  if (isLoading) {
    return t('pendingDot');
  }
  switch (step) {
    case SwapSteps.APPROVE_STRP:
      return t('approveStrp');
    case SwapSteps.APPROVING_STRP:
      return t('approvingStrpDot');
    case SwapSteps.PENDING_TX_SIGN:
      return t('pendingDot');
    case SwapSteps.PROCESSING_SWAP:
      return t('processingSwapDot');
    default:
      return t('confirm');
  }
};

interface Props {
  proceedToNextStep: (step: SwapSteps) => void;
  step: SwapSteps;
  shouldDisableActionBtn: boolean;
  isLoading: boolean;
}

const ConfirmSection = ({
  proceedToNextStep,
  step,
  shouldDisableActionBtn,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const buttonLabel = getButtonLabel(isLoading, step, t);

  return (
    <Button
      className="mt-15"
      variant="primaryGreen"
      size="extraLarge"
      block
      disabled={shouldDisableActionBtn}
      onClick={() => {
        proceedToNextStep(step);
      }}
    >
      {buttonLabel}
    </Button>
  );
};

export default memo(ConfirmSection);
