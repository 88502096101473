import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  label {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 14px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.shadesForeground100};
  }
`;

interface BodyContainerProps {
  isFocused?: boolean;
}
export const BodyContainer = styled.div<BodyContainerProps>`
  display: flex;
  padding: 11px;
  border: 1px solid ${({ theme }) => theme.colors.mobileOutline};
  border-radius: 6px;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamilyNumbers};

  :focus-within {
    border: 1px solid ${({ theme }) => theme.colors.warningForeground100};
  }
  img:hover {
    cursor: pointer;
  }

  .input-container {
    flex: 1;
  }

  input {
    background: transparent;
    border: 0;
    outline: none;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 16px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;

    ::placeholder {
      color: ${({ theme }) => theme.colors.shadesForeground100};
    }
  }

  .currency {
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 16px;
    line-height: 17px;
    color: ${({ theme }) => theme.colors.shadesForeground300};
    margin-left: 13px;
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.warningForeground100};
    `}
`;
