import { useCallback, useState } from 'react';

import { useIsTradePage } from 'hooks';
import { useConfirmCancelOrder } from 'hooks/useConfirmCancelOrder';
import useModal from 'hooks/useModal';

import TabSelector from 'components/TabSelector';
import { TableContainer } from 'components/Tables/styles';

import AmendOrderModal from './AmendOrderModal';
import LimitAndMarketOrders from './LimitAndMarketOrders';
import OrderFilterOptions, { TOrderFilter } from './OrderFilterOptions';
import { filterOrders } from './utils';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { QueryParams } from 'service/restService';

import { OrderStatus, OrderType } from 'enums';
import { Order } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export enum SelectedOrdersType {
  LIMIT_AND_MARKET = 'LIMIT_AND_MARKET',
  STOP_ORDERS = 'STOP_ORDERS',
}

const createTabsButtonOptions = ({
  showCount = false,
  limitAndMarketOrdersCount,
  stopOrdersCount,
  t,
}: {
  showCount: boolean;
  limitAndMarketOrdersCount: number;
  stopOrdersCount: number;
  t: any;
}) => {
  const limitAndMarketLabel = t('limitAndMarkeOrdersWithCount', {
    count: showCount ? ` (${limitAndMarketOrdersCount})` : '',
  });

  const stopOrdersLabel = t('stopOrdersWithCount', {
    count: showCount ? ` (${stopOrdersCount})` : '',
  });

  const tabsButtonOptions: { label: string; value: SelectedOrdersType }[] = [
    {
      label: limitAndMarketLabel,
      value: SelectedOrdersType.LIMIT_AND_MARKET,
    },
    {
      label: stopOrdersLabel,
      value: SelectedOrdersType.STOP_ORDERS,
    },
  ];

  return tabsButtonOptions;
};

export enum SortBy {
  NOTIONAL = 'NOTIONAL',
  SIDE = 'SIDE',
  STATUS = 'STATUS',
  CREATED_AT = 'CREATED_AT',
  PNL = 'PNL',
}

interface Props {
  includePagination?: boolean;
  requestQueryParams?: QueryParams;
  shouldHideClosed?: boolean;
  allowNewItemsFromSocket?: boolean;
  showTableHead?: boolean;
  showLabelOnTableCell?: boolean;
  showFilterOptions?: boolean;
  isRowCollapsible?: boolean;
}

const constructRequestQueryParamsFilters = (filter: TOrderFilter) => {
  const params = {};

  if (filter.marketId) {
    params['market_id'] = filter.marketId;
  }

  if (filter.orderStatus) {
    params['status'] = filter.orderStatus;
  }

  if (
    filter.dateRange &&
    filter.dateRange.range &&
    filter.dateRange.range[0] &&
    filter.dateRange.range[1]
  ) {
    params['start_time'] = filter.dateRange.range[0];
    params['end_time'] = filter.dateRange.range[1];
  }

  return params;
};

const OrdersTable = ({
  includePagination,
  requestQueryParams = {},
  shouldHideClosed = false,
  allowNewItemsFromSocket = true,
  showTableHead = true,
  showLabelOnTableCell = false,
  showFilterOptions = false,
  isRowCollapsible = false,
}: Props) => {
  const modal = useModal();
  const {
    store: {
      account: { _stopOrders, _limitAndMarketOrders },
      markets: { selectedMarketId },
      appState: { showAllMarkets },
    },
  } = useAppContext();
  const isTradePage = useIsTradePage();
  const { cancelOrder, currentlyCancellingOrderIds } = useConfirmCancelOrder();
  const { t } = useTranslation();

  const [appliedFilters, setAppliedFilters] = useState<TOrderFilter>({
    selectedOrdersType: SelectedOrdersType.LIMIT_AND_MARKET,
  });

  const handleAmendOrder = useCallback(
    (order: Order, assetName: string) => {
      modal.present(
        <AmendOrderModal order={order} currency={assetName} />,
        Modals.amendOrderModal,
      );
    },
    [modal],
  );

  const limitAndMarketOrdersWithClosed = filterOrders({
    _orders: _limitAndMarketOrders,
    showAllMarkets,
    selectedMarketId,
    isTradePage,
    shouldHideClosed: false,
  });

  const stopOrdersWithClosed = filterOrders({
    _orders: _stopOrders,
    showAllMarkets,
    selectedMarketId,
    isTradePage,
    shouldHideClosed: false,
  });

  const limitAndMarketOrders = shouldHideClosed
    ? limitAndMarketOrdersWithClosed.filter(el =>
        [OrderStatus.OPEN, OrderStatus.PROCESSING, OrderStatus.PLACED].includes(
          el.status,
        ),
      )
    : limitAndMarketOrdersWithClosed;

  const stopOrders = shouldHideClosed
    ? stopOrdersWithClosed.filter(el =>
        [OrderStatus.OPEN, OrderStatus.PROCESSING, OrderStatus.PLACED].includes(
          el.status,
        ),
      )
    : stopOrdersWithClosed;

  const filterParams = constructRequestQueryParamsFilters(appliedFilters);

  return (
    <>
      {showFilterOptions ? (
        <OrderFilterOptions
          appliedFilters={appliedFilters}
          onChangeFilter={setAppliedFilters}
        />
      ) : null}
      <TableContainer useScroll={!includePagination}>
        {/* Filter includes this, so we intentionally hide it when filter options are visible */}
        {!showFilterOptions ? (
          <div className={`flex mb-10 ${isTradePage ? 'ml-10' : ''}`}>
            <TabSelector
              handleOptionSelect={val =>
                setAppliedFilters({
                  selectedOrdersType: val as SelectedOrdersType,
                })
              }
              buttonOptions={createTabsButtonOptions({
                limitAndMarketOrdersCount: limitAndMarketOrders?.length ?? 0,
                stopOrdersCount: stopOrders?.length ?? 0,
                showCount: isTradePage,
                t,
              })}
              initialSelectedOption={appliedFilters.selectedOrdersType}
              initialOptionsDropdownAlignment={'right'}
            />
          </div>
        ) : null}

        {appliedFilters.selectedOrdersType ===
          SelectedOrdersType.LIMIT_AND_MARKET && (
          <LimitAndMarketOrders
            showTableHead={showTableHead}
            showLabelOnTableCell={showLabelOnTableCell}
            socketSource={limitAndMarketOrdersWithClosed}
            handleAmendOrder={handleAmendOrder}
            handleCancelOrder={cancelOrder}
            allowNewItemsFromSocket={allowNewItemsFromSocket}
            includePagination={includePagination}
            requestQueryParams={{
              order_type: [
                OrderType.LIMIT,
                OrderType.MARKET,
                OrderType.STOP_LOSS,
                OrderType.TAKE_PROFIT,
                OrderType.STOP_LOSS_LIMIT,
                OrderType.TAKE_PROFIT_LIMIT,
              ],
              ...filterParams,
              ...requestQueryParams,
            }}
            selectedOrdersType={SelectedOrdersType.LIMIT_AND_MARKET}
            shouldHideClosed={shouldHideClosed}
            isTradePage={isTradePage}
            isRowCollapsible={isRowCollapsible}
            currentlyCancellingOrderIds={currentlyCancellingOrderIds}
          />
        )}
        {appliedFilters.selectedOrdersType ===
          SelectedOrdersType.STOP_ORDERS && (
          <LimitAndMarketOrders
            showTableHead={showTableHead}
            showLabelOnTableCell={showLabelOnTableCell}
            socketSource={stopOrdersWithClosed}
            handleAmendOrder={handleAmendOrder}
            handleCancelOrder={cancelOrder}
            allowNewItemsFromSocket={allowNewItemsFromSocket}
            includePagination={includePagination}
            requestQueryParams={{
              order_type: [OrderType.ST0P_LIMIT, OrderType.STOP_MARKET],
              ...filterParams,
              ...requestQueryParams,
            }}
            selectedOrdersType={SelectedOrdersType.STOP_ORDERS}
            shouldHideClosed={shouldHideClosed}
            isTradePage={isTradePage}
            isRowCollapsible={isRowCollapsible}
            currentlyCancellingOrderIds={currentlyCancellingOrderIds}
          />
        )}
      </TableContainer>
    </>
  );
};

export default observer(OrdersTable);
