import { memo } from 'react';

import Search from 'components/Search/Search';
import SwitchWithIcons from 'components/Toggle/SwitchWithIcons';

import { VaultType, ViewType } from '../../../../../../enums';
import { FiltersStyled, StyledMenuItem } from './styles';
import gridDarkIcon from 'assets/icons/grid-dark.svg';
import gridIcon from 'assets/icons/grid.svg';
import rowDarkIcon from 'assets/icons/row-dark.svg';
import rowIcon from 'assets/icons/row.svg';

import { useTranslation } from 'react-i18next';

const MENU_ITEMS = (t: any): any[] => [
  { label: t('allVaults'), value: VaultType.ALL },
  { label: t('myVaults'), value: VaultType.MY_VAULTS },
  // { label: 'My Watchlist', value: VaultType.WATCHLIST },
  // { label: 'New Releases', value: VaultType.NEW },
];

type Props = {
  searchTerm: string;
  onChangeSearchTerm: (s: string) => void;
  viewType: ViewType;
  onChangeViewType: (v: ViewType) => void;
  type: VaultType;
  onChangeType: (v: VaultType) => void;
};
const Filters = ({
  searchTerm,
  onChangeSearchTerm,
  type,
  onChangeType,
  viewType,
  onChangeViewType,
}: Props) => {
  const { t } = useTranslation();

  return (
    <FiltersStyled>
      <div>
        {MENU_ITEMS(t).map(item => (
          <StyledMenuItem
            variant="BODY_S"
            isActive={item.value === type}
            onClick={() => onChangeType(item.value)}
          >
            {item.label}
          </StyledMenuItem>
        ))}
      </div>
      <div>
        <SwitchWithIcons
          options={[
            {
              defaultIcon: gridIcon,
              activeIcon: gridDarkIcon,
              value: ViewType.GRID,
            },
            {
              defaultIcon: rowIcon,
              activeIcon: rowDarkIcon,
              value: ViewType.TABLE,
            },
          ]}
          value={viewType}
          onToggle={onChangeViewType}
        />
        <Search
          value={searchTerm}
          onChange={onChangeSearchTerm}
          placeholder={t('searchMarkets')}
          className="search-container"
        />
      </div>
    </FiltersStyled>
  );
};

export default memo(Filters);
