import { useVerifyChainId } from 'hooks';
import useModal from 'hooks/useModal';

import { VaultListProps } from '../types';
import { EmptyGridState } from './EmptyState';
import EmptyVaultCard from './EmptyState/EmptyVaultCard';
import VaultCard from './VaultCard';
import { Modals } from 'constants/modals';
import AmendLiquidityModal, {
  AmendType,
} from 'pages/Vaults/common/AmendLiquidityModal';
import { getTotalApyForVault } from 'pages/Vaults/utils';

import { t } from 'i18next';
import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  }
`;

const VaultGrid = ({
  vaults = [],
  vaultsWalletShares,
  isUserAuthenticated,
  isLoading,
  initialVaultId,
  maxApy,
  ...rest
}: VaultListProps) => {
  const modal = useModal();
  const { validateNetworkAndDoAction } = useVerifyChainId();

  const onStartStakingUnstaking = () => {
    initialVaultId &&
      validateNetworkAndDoAction(() => {
        modal.present(
          <AmendLiquidityModal
            defaultAmendType={AmendType.Stake}
            poolId={initialVaultId}
          />,
          Modals.amendLiquidityModal,
        );
      }, t('youCanNowStakeUnstakeFunds'));
  };

  return (
    <Grid>
      {vaults.length === 0 ? (
        <EmptyGridState
          isLoading={isLoading}
          isUserAuthenticated={isUserAuthenticated}
          onStartStakingUnstaking={onStartStakingUnstaking}
          apy={maxApy}
        />
      ) : (
        <>
          {!isUserAuthenticated ? (
            <EmptyVaultCard
              displayActions
              isUserAuthenticated={false}
              isLoading={isLoading}
              title={t('noWalletConnected')!}
              description={t('connectNowAndStartStakingUnstaking')!}
              apy={maxApy}
            />
          ) : null}
          {vaults.map(vault => (
            <VaultCard
              vault={vault}
              walletShare={
                vaultsWalletShares &&
                vaultsWalletShares[vault.productId].sharesAmount
              }
              isUserAuthenticated={isUserAuthenticated}
              {...rest}
            />
          ))}
        </>
      )}
    </Grid>
  );
};

export default VaultGrid;
