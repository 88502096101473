import { useCallback, useState } from 'react';

import {
  LinkPayload,
  createLink,
  TransferFinishedPayload,
  LinkEventType,
  LinkEventTypeKeys,
  IntegrationAccessToken,
} from '@meshconnect/web-link-sdk';

import { readUserSetting, saveUserSetting, showNotification } from 'utils';

import { LS_MESH_ACCESS_TOKENS } from 'constants/localStorageKeys';

import axios from 'axios';
import { config } from 'config';
import { MeshIntegration, NotificationType } from 'enums';

const MESH_TO_INTEGRATION_ID = {
  [MeshIntegration.Bitfinex]: '8fbd1e9f-f7a5-4151-a36c-531da4865bda',
  [MeshIntegration.Others]: null,
  [MeshIntegration.Binance]: '9226e5c2-ebc3-4fdd-94f6-ed52cdce1420',
  [MeshIntegration.Coinbase]: '47624467-e52e-4938-a41a-7926b6c27acf',
};

export type TMeshState = {
  integrationData: LinkPayload | null;
  transferData:
    | (TransferFinishedPayload & { timestamp: number; uniqueId: string })
    | null;
  error: string | undefined;
  events: LinkEventType[];
  isMeshOpen: boolean;
};

export const MESH_RBX_BASE_URL = 'https://mesh.blastgoldcalculator.com';
// export const MESH_RBX_BASE_URL = 'https://mesh.bunnyexchange.tech';

const getAccessTokenFromLinkPayload = (
  payload: LinkPayload,
): IntegrationAccessToken | undefined => {
  const accessToken = payload.accessToken?.accountTokens?.[0]?.accessToken;
  const accountId = payload.accessToken?.accountTokens?.[0]?.account.accountId;
  const accountName =
    payload.accessToken?.accountTokens?.[0]?.account.accountName;
  const brokerType = payload.accessToken?.brokerType;
  const brokerName = payload.accessToken?.brokerName;

  if (accessToken && accountId && accountName && brokerName && brokerType)
    return {
      accessToken,
      accountId,
      accountName,
      brokerType,
      brokerName,
    };
};

const getAccessTokensFromLs = (): IntegrationAccessToken[] => {
  try {
    const res: IntegrationAccessToken[] = JSON.parse(
      readUserSetting(LS_MESH_ACCESS_TOKENS),
    );

    return res || [];
  } catch (err) {
    return [];
  }
};

const addAccessTokenToLs = (payload: LinkPayload) => {
  const integrationAccessToken = getAccessTokenFromLinkPayload(payload);

  if (!integrationAccessToken) return;

  try {
    const res = getAccessTokensFromLs();

    // Remove if brokername already present
    const filteredAccessTokens = res.filter(
      i => i.brokerName !== integrationAccessToken.brokerName,
    );

    const newAccessTokensStr = JSON.stringify([
      ...filteredAccessTokens,
      integrationAccessToken,
    ]);

    saveUserSetting(LS_MESH_ACCESS_TOKENS, newAccessTokensStr);

    return res || [];
  } catch (err) {
    console.error('ERR SAVING');
    return [];
  }
};

export type TMeshTransfer = {
  institution_transaction_id: string;
  mesh_transfer_id: string;
  status: string;
  amount: number;
  currency: string;
  timestamp: number;
  tx_hash?: string;
};

export const getMeshTransfer = (institutionTxId: string) => {
  return axios.get<{ data: TMeshTransfer }>(
    `${MESH_RBX_BASE_URL}/get_transfer`,
    {
      params: {
        institution_transaction_id: institutionTxId,
      },
    },
  );
};

export function useMeshAPI() {
  const [meshState, setMeshState] = useState<TMeshState>({
    error: undefined,
    transferData: null,
    integrationData: null,
    events: [],
    isMeshOpen: false,
  });

  const fetchLinkTokenAndOpenLink = useCallback(
    async (
      account: string,
      integration: MeshIntegration,
      amount: number,
      onTransferFinished?: (transferData: TransferFinishedPayload) => void,
    ): Promise<any> => {
      const requestBody = {
        userId: account.toString(),
        integrationId: MESH_TO_INTEGRATION_ID[integration], // get integrationId from passed integration
        amount: amount,
        env: 'mainnet',
      };

      try {
        const res: { data: any } = await axios.post(
          `${MESH_RBX_BASE_URL}/start_transfer`,
          requestBody,
        );

        if (res.data?.linkToken) {
          setMeshState({ ...meshState, isMeshOpen: true });

          const accessTokens = getAccessTokensFromLs();
          let currentIntegrationAccessTokens:
            | undefined
            | IntegrationAccessToken[] = accessTokens.filter(i =>
            i.brokerName.toLowerCase().includes(integration),
          );

          // Check whether access token stored for integration is valid, if not dont pass it in the mesh's UI
          if (currentIntegrationAccessTokens.length > 0) {
            const integrationAccess = currentIntegrationAccessTokens[0];
            try {
              await axios.post(`${MESH_RBX_BASE_URL}/check_access_token`, {
                accessToken: integrationAccess.accessToken,
                type: integrationAccess.brokerType,
              });
            } catch (err) {
              currentIntegrationAccessTokens = undefined; // Reset access token to be passed as it is not valid

              console.log(err);
            }
          }

          const meshLink = createLink({
            clientId: config.mesh.clientId,
            onIntegrationConnected: payload => {
              addAccessTokenToLs(payload);
              setMeshState(prev => ({ ...prev, integrationData: payload }));
            },
            onExit: error => {
              setMeshState(prev => ({ ...prev, error, isMeshOpen: false }));
            },
            onTransferFinished: transferData => {
              onTransferFinished?.(transferData);
              setMeshState(prev => ({
                ...prev,
                transferData: {
                  ...transferData,
                  timestamp: Date.now() * 1000,
                  uniqueId: `${transferData.txId}-${Date.now() * 1000}`, // for testnet, txId is same for multiple transfers, so we need a unique id to assign to modals for each transfer
                },
              }));
            },
            onEvent: ev => {
              console.log('Event', ev);
              setMeshState(prev => {
                if (
                  (
                    [
                      'integrationConnectionError',
                      'transferPreviewError',
                      'close',
                      'done',
                    ] as LinkEventTypeKeys[]
                  ).includes(ev.type)
                ) {
                  let errorMessage = (ev as any).payload?.errorMessage;
                  if (errorMessage) {
                    setMeshState(prev => ({ ...prev, error: errorMessage }));
                  }
                }
                prev.events.push(ev);
                return prev;
              });
            },
            accessTokens: currentIntegrationAccessTokens,
            transferDestinationTokens: [],
          });

          // Open Mesh IFrame
          meshLink.openLink(res.data.linkToken).then(undefined, () => {
            setMeshState(prev => ({ ...prev, isMeshOpen: false }));
          });

          return meshLink.closeLink;
        }
      } catch (e: any) {
        console.error('Something went wrong fetching link token', e.message);
        showNotification({
          title: 'Unable to initialize mesh!',
          description: e?.displayMessage,
          type: NotificationType.Negative,
        });
      }
    },
    [],
  );

  return {
    fetchLinkTokenAndOpenLink,
    meshState,
  };
}

export type TMeshApi = ReturnType<typeof useMeshAPI>;
