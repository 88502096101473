import { useEffect, useState } from 'react';

import { Tabs } from '@material-ui/core';

import { useBalanceOperationsAPI, usePaginationFetch } from 'hooks';

import { BalanceOpsClaimableStatuses } from 'components/CustomToasts/ClaimableWithdrawal';
import FillsTable from 'components/Tables/FillsTable';
import OrderHistoryTable from 'components/Tables/OrderHistoryTable';
import OrdersTable from 'components/Tables/OrdersTable';
import { filterOrders } from 'components/Tables/OrdersTable/utils';
import PositionsTable from 'components/Tables/PositionsTable';
import { filterPositions } from 'components/Tables/PositionsTable/utils';
import { useWithdrawableBalance } from 'components/Tables/TransfersTable/WithdrawableBalance/useWithdrawableBalance';

import ShowAllMarketsAndCancelAllOrders from './ShowAllMarketsAndCancelAllOrders';
import TabWithCounter from './TabWithCounter';
import { TabPanel, a11yProps } from './TradingHistoryTab';
import TransfersTable from './TransfersTable';
import { useAppContext } from 'contexts/AppContext';
import { Row } from 'theme/globalStyledComponents/row';

import { BalanceOpsType, OrderStatus, QueryKeys } from 'enums';
import { ProfileBalanceOps } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const EXTEND_HISTORY_TABS_THRESHOLD_HEIGHT = 1500;

export const ORDERBOOK_TRADES_TAB_VIEW_HEIGHT_THRESHOLD = 900;

const TradingHistoryTabsStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border-radius: 8px;
  flex-grow: 1;
  height: auto;

  /* @media (min-height: ${ORDERBOOK_TRADES_TAB_VIEW_HEIGHT_THRESHOLD + 1}px) {
    flex-grow: 1;
  } */

  @media (min-height: ${EXTEND_HISTORY_TABS_THRESHOLD_HEIGHT}px) {
    flex-grow: 1;
  }

  > div:first-child {
    height: 35.5px;
  }

  .MuiTabs-flexContainer {
    height: 31px;
    /* padding: 0 20px; */
  }

  .MuiTabs-scroller.MuiTabs-fixed {
    overflow-x: auto !important;
  }
`;

const TradingHistoryTabs = ({
  isRowCollapsible,
}: {
  isRowCollapsible: boolean;
}) => {
  const {
    store: {
      account: {
        isLoading,
        _orders,
        _positions,
        frontendSecrets,
        _balanceOperations,
      },
      markets: { selectedMarketId },
      appState: { showAllMarkets },
    },
  } = useAppContext();
  const { t } = useTranslation();
  const { withdrawableBalance, updateWithdrawableBalance } =
    useWithdrawableBalance();
  const { fetchPrivateTransfers } = useBalanceOperationsAPI();

  const [activeTab, setActiveTab] = useState(0);

  const positions = filterPositions({
    _positions,
    showAllMarkets,
    selectedMarketId,
    isTradePage: true,
  });

  const orders = filterOrders({
    _orders,
    showAllMarkets,
    selectedMarketId,
    isTradePage: true,
    shouldHideClosed: true,
  });

  useEffect(() => {
    updateWithdrawableBalance();
  }, [frontendSecrets?.jwt, updateWithdrawableBalance]);

  // using PaginationFetch hook to fetch claimable transfers, so that we can use socket source to listen to new claimable transfers or status updates
  const { data: claimableTransfers } = usePaginationFetch<ProfileBalanceOps>({
    fetchFn: fetchPrivateTransfers,
    socketSource: _balanceOperations,
    currentAuthenticatedWallet: frontendSecrets?.profile?.wallet,
    requestQueryParams: {
      ops_type: BalanceOpsType.Withdrawal,
      status: BalanceOpsClaimableStatuses,
    },
    queryKey: QueryKeys.Transfers,
    disableRefetchInterval: true,
  });

  // Socket Source Might Contain new balance operations with irrelavant status, so we filter again
  let filteredClaimableTransfers = claimableTransfers.filter(t =>
    BalanceOpsClaimableStatuses.includes(t.status),
  );

  const shouldShowIsWithdrawableBalance =
    !isLoading &&
    !!frontendSecrets &&
    (!!withdrawableBalance ||
      (filteredClaimableTransfers && filteredClaimableTransfers.length > 0));

  const shouldShowItemsCount = !isLoading && !!frontendSecrets;

  return (
    <TradingHistoryTabsStyled>
      <Row className="p-10">
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          aria-label="Positions Tab"
        >
          <TabWithCounter
            data-testid="history-positions-tab"
            data-gtmid="tab-positions"
            data-cy="tab-positions"
            label={t('positions')}
            isActive={activeTab === 0}
            showCounter={shouldShowItemsCount}
            count={positions.length}
            {...a11yProps(0)}
          />
          <TabWithCounter
            isActive={activeTab === 1}
            data-gtmid="tab-orders"
            data-testid="history-orders-tab"
            label={t('orders')}
            showCounter={shouldShowItemsCount}
            count={orders.length}
            {...a11yProps(1)}
          />

          <TabWithCounter
            isActive={activeTab === 2}
            data-testid="history-order-tab"
            label={t('orderHistory')}
            {...a11yProps(2)}
          />
          <TabWithCounter
            isActive={activeTab === 3}
            data-testid="history-fills-tab"
            label={t('tradeHistory')}
            {...a11yProps(3)}
          />
          <TabWithCounter
            isActive={activeTab === 4}
            data-gtmid="tab-deposits"
            data-testid="history-deposit-withdraw"
            label={t('transfers')}
            showCounter={shouldShowIsWithdrawableBalance}
            count={1} // only one at a time
            // label={`Deposits/Withdrawals ${
            //   depositsAndWithdrawals ? `(${depositsAndWithdrawals.length})` : ''
            // }`}
            {...a11yProps(4)}
          />
          {/* <TabWithCounter
            isActive={activeTab === 4}
            data-gtmid="tab-account"
            data-testid="history-account-tab"
            label={t('account')}
            {...a11yProps(4)}
          /> */}
        </Tabs>
        {frontendSecrets ? (
          <ShowAllMarketsAndCancelAllOrders
            shouldShowCancelAllOrders={activeTab === 1}
            cancelOrdersDisabled={orders.length === 0}
          />
        ) : null}
      </Row>

      <TabPanel value={activeTab} index={0}>
        <PositionsTable
          showLabelOnTableCell={true}
          showTableHead={false}
          isRowCollapsible={isRowCollapsible}
          includePagination={false}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <OrdersTable
          includePagination={false}
          requestQueryParams={{
            status: [
              OrderStatus.OPEN,
              OrderStatus.PROCESSING,
              OrderStatus.PLACED,
            ],
          }}
          shouldHideClosed
          showLabelOnTableCell={true}
          showTableHead={false}
          isRowCollapsible={isRowCollapsible}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <OrderHistoryTable includePagination={false} />
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        <FillsTable
          showLabelOnTableCell={true}
          showTableHead={false}
          isRowCollapsible={true} // always true in case of fills due to it being differnet from normal tables
          includePagination={false}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={4}>
        <TransfersTable isRowCollapsible={isRowCollapsible} />
      </TabPanel>
      {/* Was asked to remove the Accounts Tab! */}
      {/* <TabPanel value={activeTab} index={4}>
        <Account />
      </TabPanel> */}
    </TradingHistoryTabsStyled>
  );
};

export default observer(TradingHistoryTabs);
