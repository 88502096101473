import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    max-width: 60%;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
    > img {
      min-width: 420px;
    }
  }
`;

export const AirdropUserStats = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 15px;

  .divider {
    width: 30px;
    height: 2px;
    background: ${({ theme }) => theme.colors.shadesBackground500};
    margin: auto;

    @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
      height: 30px;
      width: 2px;
    }
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
    flex-direction: row;
  }

  background: ${({ theme }) => theme.colors.shadesBackground600};
  border-radius: 8px;
  padding: 8px;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.screenSizes.md}px) {
    padding: 15px;
  }

  .stat-col {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    justify-content: space-between;

    .label,
    .formatted-number {
      font-size: 12px;
      line-height: 14px;
    }
    .label {
      color: ${({ theme }) => theme.colors.shadesForeground300};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
    }
    .formatted-number {
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    }
    .placeholder-value {
      color: ${({ theme }) => theme.colors.shadesForeground300};
    }

    /* 
    @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
      .label,
      .formatted-number {
        font-size: 14px;
        line-height: 16px;
      }
    } */

    @media (min-width: ${({ theme }) => theme.screenSizes.sm}px) {
      text-align: left;
    }
    @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
      .label,
      .formatted-number {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;
