import { memo, useState } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import {
  USD_DECIMAL_SCALE,
  roundToNearestTick,
  timestampToLocalDateWithTime,
} from 'utils';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import { useSwitchMarket } from 'hooks/useSwitchMarket';

import { FormattedNumber } from 'components';
import CollapseIcon from 'components/CollapseIcon';

import { OrderStatusCol } from '../OrdersTable/OrderStatusCol';
import LabelAndValueCell from '../components/LabelAndValueCell';
import MarketLogoWithTradeSide from '../components/MarketLogoWithTradeSide';
import { CollapsibleTableRow } from '../styles';
import LoadingChildFills from './LoadingChildFills';

import { OrderType } from 'enums';
import { Fill, Order } from 'interfaces';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const IconTableCell = styled(TableCell)`
  width: 16px;
  vertical-align: top;
`;

const MAX_CHILD_FILLS_COUNT = 20;

export const FillRow = observer(({ fill }: { fill: Fill }) => {
  const { switchMarketById } = useSwitchMarket();
  const { t } = useTranslation();

  const { marketID, size, price, side, liquidation, fee, timestamp } = fill;

  const decimalPlaces = useGetDecimalPlaces(marketID);

  const valueUsd = roundToNearestTick(size * price, USD_DECIMAL_SCALE.minOrder);

  return (
    <CollapsibleTableRow className={'bg-900'} isCollapsed={false}>
      <TableCell className="cursor-pointer">
        <MarketLogoWithTradeSide
          limitWidth
          marketID={marketID}
          onClick={e => {
            e.stopPropagation();
            switchMarketById(marketID);
          }}
          tradeSide={side}
        />

        <LabelAndValueCell
          showLabel={true}
          className="mt-10 ml-32"
          limitWidth
          isCell={false}
          label={t('liquidation')}
          value={
            liquidation === undefined ? '-' : liquidation ? 'True' : 'False'
          }
        />
      </TableCell>

      <TableCell>
        <LabelAndValueCell
          showLabel={true}
          limitWidth
          isCell={false}
          label={t('filled')}
          value={
            <FormattedNumber
              value={size}
              decimalScale={decimalPlaces.size}
              disableTooltip={true}
            />
          }
        />

        <LabelAndValueCell
          showLabel={true}
          className="mt-10"
          limitWidth
          isCell={false}
          label={t('fee')}
          value={<FormattedNumber value={fee} decimalScale={6} prefix="$" />}
        />
      </TableCell>

      <LabelAndValueCell
        showLabel={true}
        verticalAlign="top"
        limitWidth
        label={t('valueUsd')}
        value={<FormattedNumber value={valueUsd} prefix="$" />}
      />

      <LabelAndValueCell
        showLabel={true}
        verticalAlign="top"
        limitWidth
        label={t('price')}
        value={
          <FormattedNumber
            value={price}
            decimalScale={decimalPlaces.price}
            disableTooltip={true}
          />
        }
      />

      <LabelAndValueCell
        showLabel={true}
        limitWidth
        verticalAlign="top"
        label={t('tradeId')}
        value={fill.tradeID}
      />

      <LabelAndValueCell
        showLabel={true}
        limitWidth
        verticalAlign="top"
        label={t('time')}
        value={timestampToLocalDateWithTime(timestamp)}
      />

      <IconTableCell>{null}</IconTableCell>
    </CollapsibleTableRow>
  );
});

type OrderRowProps = {
  order: Order;
  isCollapsed: boolean;
  toggleCollapse: () => void;
  liquidation?: boolean;
  avgFilledPrice?: number;
  collapsible: boolean;
  totalFee?: number;
};
export const OrderRow = observer(
  ({
    order,
    isCollapsed,
    toggleCollapse,
    liquidation,
    avgFilledPrice,
    collapsible,
    totalFee,
  }: OrderRowProps) => {
    const { switchMarketById } = useSwitchMarket();
    const { t } = useTranslation();

    const {
      marketID,
      price,
      totalFilledSize: size,
      side,
      createdAt: timestamp,
      type,
    } = order;

    const decimalPlaces = useGetDecimalPlaces(marketID);

    const valueUsd = roundToNearestTick(
      size * price,
      USD_DECIMAL_SCALE.minOrder,
    );

    const orderPriceComponent =
      type === OrderType.MARKET ? (
        t('market').toUpperCase()
      ) : (
        <FormattedNumber
          value={price}
          decimalScale={decimalPlaces.price}
          disableTooltip={true}
        />
      );

    return (
      <CollapsibleTableRow
        className={!isCollapsed ? 'bg-600' : ''}
        onClick={toggleCollapse}
        isCollapsed={isCollapsed}
      >
        <TableCell className="cursor-pointer">
          <MarketLogoWithTradeSide
            limitWidth
            marketID={marketID}
            onClick={e => {
              e.stopPropagation();
              switchMarketById(marketID);
            }}
            tradeSide={side}
          />

          {!isCollapsed ? (
            <LabelAndValueCell
              showLabel={true}
              className="mt-10 ml-32"
              limitWidth
              isCell={false}
              label={t('liquidation')}
              value={
                liquidation === undefined ? '-' : liquidation ? 'True' : 'False'
              }
            />
          ) : null}
        </TableCell>

        <TableCell>
          <LabelAndValueCell
            showLabel={true}
            limitWidth
            isCell={false}
            label={t('totalFilled')}
            value={
              <FormattedNumber
                value={size}
                decimalScale={decimalPlaces.size}
                disableTooltip={true}
              />
            }
          />

          {!isCollapsed ? (
            <LabelAndValueCell
              showLabel={true}
              className="mt-10"
              limitWidth
              isCell={false}
              label={t('totalFee')}
              value={
                <FormattedNumber value={totalFee} decimalScale={6} prefix="$" />
              }
            />
          ) : null}
        </TableCell>

        <TableCell>
          <LabelAndValueCell
            showLabel={true}
            isCell={false}
            verticalAlign="top"
            limitWidth
            label={t('totalValueUsd')}
            value={<FormattedNumber value={valueUsd} prefix="$" />}
          />

          {!isCollapsed ? (
            <LabelAndValueCell
              showLabel={true}
              className="mt-10"
              limitWidth
              isCell={false}
              label={t('avgFilledPrice')}
              value={
                <FormattedNumber
                  value={avgFilledPrice}
                  decimalScale={decimalPlaces.price}
                  prefix="$"
                />
              }
            />
          ) : null}
        </TableCell>

        <LabelAndValueCell
          showLabel={true}
          verticalAlign="top"
          limitWidth
          label={t('price')}
          value={orderPriceComponent}
        />

        <LabelAndValueCell
          showLabel={true}
          limitWidth
          verticalAlign="top"
          label={t('status')}
          value={
            <OrderStatusCol status={order.status} reason={order.reason} t={t} />
          }
        />

        <LabelAndValueCell
          showLabel={true}
          limitWidth
          verticalAlign="top"
          label={t('time')}
          value={timestampToLocalDateWithTime(timestamp)}
        />

        <IconTableCell>
          {collapsible ? <CollapseIcon isCollapsed={isCollapsed} /> : null}
        </IconTableCell>
      </CollapsibleTableRow>
    );
  },
);

export const GroupedOrderFillsRow = ({
  onRowClick,
  showFills,
  orderFills,
  order,
  areOrderFillsLoading,
}: {
  order: Order;
  showFills: boolean;
  onRowClick: (id: string) => void;
  orderFills: Fill[] | undefined;
  areOrderFillsLoading: boolean;
}) => {
  const hasSomeFills = order.totalFilledSize > 0;

  if (!hasSomeFills) return null;

  const toggleCollapse = () => {
    onRowClick(order.id);
  };

  const totalFee = showFills
    ? orderFills?.reduce((acc, fill) => acc + fill.fee, 0)
    : undefined;

  const averageFilledPrice = showFills
    ? (orderFills?.reduce((acc, fill) => acc + fill.size * fill.price, 0) ??
        0) / order.totalFilledSize
    : undefined;

  const isLiquidationOrder = showFills
    ? orderFills?.some(fill => fill.liquidation)
    : undefined;

  return (
    <>
      <OrderRow
        collapsible={true}
        isCollapsed={!showFills}
        toggleCollapse={toggleCollapse}
        totalFee={totalFee}
        liquidation={isLiquidationOrder}
        order={order}
        avgFilledPrice={averageFilledPrice}
      />

      {showFills ? (
        areOrderFillsLoading ? (
          <LoadingChildFills />
        ) : (
          orderFills &&
          orderFills.length > 1 &&
          orderFills?.map((fill, index) =>
            index > MAX_CHILD_FILLS_COUNT ? null : <FillRow fill={fill} />,
          )
        )
      ) : null}
    </>
  );
};
