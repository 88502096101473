import styled, { css } from 'styled-components';

type InputBoxProps = {
  animateBackground?: boolean;
};
export const InputBox = styled.div<InputBoxProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 24px;
  background: ${({ theme }) =>
    theme.colors.shadesBackground700TransparentChart};
  border-radius: 12px;
  transition: ease-out 300ms;

  ${({ animateBackground }) =>
    animateBackground &&
    css`
      :hover {
        background: ${({ theme }) => theme.colors.shadesBackground700};
      }
    `}
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .label {
    color: ${({ theme }) => theme.colors.shadesForeground200};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: 20px;
    line-height: 26px;
  }
`;

export const MaxButton = styled.button`
  border-radius: 4px;
  padding: 4px 6px;
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.shadesForeground300};
  background: transparent;

  :hover {
    background: ${({ theme }) => theme.colors.shadesBackground600};
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 10px 0;
  justify-content: space-between;

  .currency {
    display: flex;
    flex-direction: row;
    align-items: center;
    .icon-container {
      width: 32px;
      height: 32px;
      margin-right: 10px;
      border: 1px solid #222937;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 22px;
      }
    }

    .label {
      display: inline-block;
    }
  }
`;

export const InputStyled = styled.input`
  background: transparent;
  border: 0;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.kabeljauwRabbitXText};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -1px;
  text-align: right;
  font-variant: tabular-nums;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  width: inherit;

  ::placeholder {
    color: ${({ theme }) => theme.colors.shadesForeground100};
  }
`;

export const StatsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.shadesForeground200};

  .holdings {
    display: flex;
    flex-direction: row;
    align-items: center;
    > img {
      width: 16px;
    }
  }
`;
