import { ReferralLevel } from 'interfaces';

export const CARD_LEVELS: ReferralLevel[] = [
  { level: 1, volume: 0, comissionBonus: 0, comissionPercent: 20 },
  { level: 2, volume: 200_000, comissionBonus: 5, comissionPercent: 20 },
  { level: 3, volume: 400_000, comissionBonus: 10, comissionPercent: 22 },
  { level: 4, volume: 1_000_000, comissionBonus: 30, comissionPercent: 24 },
  { level: 5, volume: 3_500_000, comissionBonus: 100, comissionPercent: 26 },
  { level: 6, volume: 8_750_000, comissionBonus: 250, comissionPercent: 28 },
  { level: 7, volume: 23_000_000, comissionBonus: 700, comissionPercent: 30 },
  { level: 8, volume: 65_000_000, comissionBonus: 1_000, comissionPercent: 32 },
  {
    level: 9,
    volume: 200_000_000,
    comissionBonus: 5_000,
    comissionPercent: 34,
  },
  {
    level: 10,
    volume: 500_000_000,
    comissionBonus: 10_000,
    comissionPercent: 36,
  },
  {
    level: 11,
    volume: 1_000_000_000,
    comissionBonus: 25_000,
    comissionPercent: 38,
  },
  {
    level: 12,
    volume: 10_000_000_000,
    comissionBonus: 100_000,
    comissionPercent: 40,
  },
];
