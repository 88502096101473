import { useCallback, useState } from 'react';

import { showNotification, timestampToLocalDate } from 'utils';

import { ConfirmOrderCancelModal } from 'components/Tables/OrdersTable/ConfirmOrderCancelModal';

import { useActiveWeb3React } from './useActiveWeb3React';
import useModal from './useModal';
import { useOrdersAPI } from './useOrdersAPI';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import ConfirmCancelMutipleOrdersModal from 'pages/Trade/components/OrderBook/ConfirmCancelMutipleOrdersModal';
import { mixpanelService } from 'service/mixpanelService';

import { NotificationType } from 'enums';
import { Order } from 'interfaces';

export function useConfirmCancelOrder() {
  const [currentlyCancellingOrderIds, setCurrentlyCancellingOrderIds] =
    useState<string[]>([]);
  const { cancelOrder } = useOrdersAPI();
  const {
    store: {
      appState: { disableCancelOrderConfirmationModal },
    },
  } = useAppContext();
  const modal = useModal();
  const { account } = useActiveWeb3React();

  const handleOrderCancellation = useCallback(
    async (order: Order) => {
      if (disableCancelOrderConfirmationModal) {
        try {
          setCurrentlyCancellingOrderIds(prev => [...prev, order.id]);

          await cancelOrder({ orderID: order.id, marketID: order.marketID });

          if (!account) return;
          mixpanelService.cancelOrderSuccess(account);
        } catch (e) {
          console.error(
            'An error occured while cancelling order when confirmation was disabled : ',
            e,
          );
        } finally {
          setCurrentlyCancellingOrderIds(prev =>
            prev.filter(id => id !== order.id),
          );
        }
        return;
      }

      // show confirmation modal
      modal.present(
        <ConfirmOrderCancelModal order={order} />,
        Modals.confirmOrderCancelModal,
      );
    },
    [modal, cancelOrder, disableCancelOrderConfirmationModal, account],
  );

  const handleMultipleOrderCancellation = useCallback(
    async (orders: Order[], avoidConfirmation: boolean) => {
      if (disableCancelOrderConfirmationModal || avoidConfirmation) {
        try {
          setCurrentlyCancellingOrderIds(orders.map(order => order.id));

          const cancelPromises = orders.map(async order => {
            try {
              await cancelOrder({
                orderID: order.id,
                marketID: order.marketID,
              });
              return { order, success: true };
            } catch (e) {
              return { order, success: false };
            }
          });

          const results = await Promise.all(cancelPromises);

          // If its a single order, we dont want to show any additional notifications.
          if (orders.length === 1) return;

          const allOrdersCancelled = !results
            .map(i => i.success)
            .includes(false);

          if (allOrdersCancelled) {
            showNotification({
              title: `All ${orders.length} orders were cancelled successfully`,
              type: NotificationType.Positive,
            });
          } else {
            // Check results for success/failure for each order
            results.forEach((result, index) => {
              const {
                success,
                order: { id, price, remainingSize, marketID, timestamp },
              } = result;

              if (success) return; // Only show notifications for order that have failed, as the success notification has already been shown

              showNotification({
                title: `Failed To Cancel Order #${id}`,
                description: `Order with size ${remainingSize} on market ${marketID} @ ${price} placed on date ${timestampToLocalDate(
                  timestamp,
                )} could not be cancelled.`,
                type: NotificationType.Info,
              });
            });
          }
        } catch (e) {
          console.error(
            'An error occured while cancelling multiple orders when confirmation was disabled : ',
            e,
          );
        } finally {
          setCurrentlyCancellingOrderIds([]);
        }
        return;
      }

      // show confirmation modal
      modal.present(
        <ConfirmCancelMutipleOrdersModal orders={orders} />,
        Modals.confirmationModal,
      );
    },
    [modal, cancelOrder, disableCancelOrderConfirmationModal],
  );

  return {
    cancelMultipleOrders: handleMultipleOrderCancellation,
    cancelOrder: handleOrderCancellation,
    currentlyCancellingOrderIds,
  } as const;
}
