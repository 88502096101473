import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TooltipContainer = styled.div`
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-family: ${({ theme }) => theme.fontFamilyText};
`;

export const VipProgressBar = styled.div`
  .label {
    display: flex;
    gap: 3px;
    margin-bottom: 12px;
    justify-content: space-between;

    .left-part,
    .right-part {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .current-tier {
      padding: 4px 6px;
      background: linear-gradient(90deg, #393552 -6.52%, #524935 100%);
      border-radius: 4px;
      font-weight: ${({ theme }) => theme.fontWeights.semiBold};
      color: ${({ theme }) => theme.colors.secondaryGold200};
    }

    .max-vip {
      padding: 4px 6px;
      gap: 10px;
      border: 1px solid rgba(246, 225, 152, 0.5);
      border-radius: 4px;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: rgba(246, 225, 152, 0.5);
    }
  }
`;
