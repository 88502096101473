import { memo } from 'react';

import { brand } from 'utils/brand';

import { InfoRowStyled } from '../styles';

import { useTranslation } from 'react-i18next';

interface Props {
  withdrawValue: number | null;
}
const ConfirmStep = ({ withdrawValue }: Props) => {
  const { t } = useTranslation();
  if (!withdrawValue) return null;
  return (
    <InfoRowStyled isLeading={true} isHighlighted={true} className="mb-20">
      <span>
        {t('withdrawTokenConfirmation', {
          withdrawValue: withdrawValue,
          token: brand.tokenTicker,
        })}
      </span>
    </InfoRowStyled>
  );
};

export default memo(ConfirmStep);
