import { useState } from 'react';

import { timestampToLocalDate, getCurrencyFromTradingPair } from 'utils';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import { FormattedNumber } from 'components';
import ConfirmationModal from 'components/ConfirmationModal';
import Text from 'components/Text';
import CheckBox from 'components/Toggle/Checkbox';

import DisabledCancelOrderConfirmationCheckbox from './DisabledCancelOrderConfirmationCheckbox';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';

import { Order } from 'interfaces';
import { useTranslation } from 'react-i18next';

interface Props {
  order: Order;
}

export const ConfirmOrderCancelModal = ({
  order: { id, marketID: market, remainingSize: size, createdAt },
}: Props) => {
  const { cancelOrder } = useOrdersAPI();
  const { t } = useTranslation();

  const currency = getCurrencyFromTradingPair(market);

  const { size: sizePrecision } = useGetDecimalPlaces(market);

  const handleSubmit = async () => {
    await cancelOrder({
      orderID: id,
      marketID: market,
    });
  };

  return (
    <ConfirmationModal
      name={Modals.confirmOrderCancelModal}
      title={t('confirmCancel?')}
      description={
        <Text>
          {t('cancelOrderConfirmationFirstPart', {
            market,
          })}{' '}
          <FormattedNumber
            value={size}
            decimalScale={sizePrecision}
            suffix={` ${currency}`}
          />{' '}
          {t('cancelOrderConfirmationPartTwo', {
            createdAt: timestampToLocalDate(createdAt),
          })}
        </Text>
      }
      onButtonRightPress={handleSubmit}
      treatAsASync
      buttonRightProps={{
        colorVariant: 'primaryRed',
        gtmId: 'button-cancel-order-popup-confirm',
      }}
      buttonRightTitle={t('confirm')}
      buttonLeftTitle={t('dontCancel')}
    >
      <DisabledCancelOrderConfirmationCheckbox />
    </ConfirmationModal>
  );
};
