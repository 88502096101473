import { useEffect, useState } from 'react';

import { TableCell, TableRow } from '@material-ui/core';

import {
  getTimeUntil,
  parseSecondsInDDHHMMSS,
  parseTimeUntil,
  timestampToLocalDateWithTime,
} from 'utils';
import { brand } from 'utils/brand';

import { FormattedNumber } from 'components';
import Button from 'components/Button/button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import TransferStatusCell from 'components/Tables/TransfersTable/TransferStatus';

import { isUnstakeStatusCancellable } from './UnstakeRequestRows';
import downloadIcon from 'assets/icons/download-white.svg';
import uploadIcon from 'assets/icons/upload.svg';
import { VaultBalanceOpsType } from 'enums/vaultBalanceOpsType';
import { VaultBalanceOps } from 'interfaces/vaultBalanceOps';
import { Row } from 'theme/globalStyledComponents/row';

import { VisibilityProps } from '.';
import { DepositOrWithdrawalStatus } from 'enums';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const getIsDeposit = (balanceOps: VaultBalanceOps) => {
  if (balanceOps.opsType) {
    return balanceOps.opsType === VaultBalanceOpsType.Stake;
  }
  return balanceOps.type === VaultBalanceOpsType.Stake;
};

const ActionRow = styled(Row)`
  min-height: 34px;
`;

const unix24h = 24 * 60 * 60 * 1000 * 1000;

const TimerContainer = styled.div`
  display: flex;
  width: 30px;
`;

const force2Digits = (val: number) => (val < 10 ? `0${val}` : val);

const Timer24h = ({ timestamp }: { timestamp: number }) => {
  let timeUntilRequestApproved = timestamp + unix24h;

  const [timer, setTimer] = useState(getTimeUntil(timeUntilRequestApproved));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(getTimeUntil(timeUntilRequestApproved));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (timeUntilRequestApproved - new Date().getTime() * 1000 <= 0) {
    return <Loading size="10px" />;
  }

  const { seconds, minutes, hours } = timer;
  return (
    <TimerContainer>{`${hours}:${force2Digits(minutes)}:${force2Digits(
      seconds,
    )}`}</TimerContainer>
  );
};

const ActivityRow = ({
  balanceOps,
  hideActions,
  hideManagerColumn,
  onCancel,
}: VisibilityProps & {
  balanceOps: VaultBalanceOps;
  onCancel: (opsId: string, amount: number) => void;
}) => {
  const { t } = useTranslation();
  const isDeposit = getIsDeposit(balanceOps);

  const isCancellable = isUnstakeStatusCancellable(balanceOps.status);

  const activityIcon = isDeposit ? uploadIcon : downloadIcon;

  const activityLabel = t(isDeposit ? 'stake' : 'unstake');

  const shares = isDeposit ? balanceOps.stakeShares : balanceOps.unstakeShares;
  const sharesValue = isDeposit ? balanceOps.stakeUSDT : balanceOps.unstakeUSDT;

  return (
    <TableRow>
      <TableCell>
        <Row className="gap-5">
          <Icon src={activityIcon} alt="stake" />
          {activityLabel}
        </Row>
      </TableCell>
      <TableCell>
        <Row className="gap-10">
          {/* <img
            src={isWatched ? starFilledIcon : starOutlineIcon}
            alt="wathlist-status"
          /> */}
          <Row className="gap-5">
            <Icon src={brand.tokenLogo} size={'M'} />
            {balanceOps.vaultName ?? '-'}
          </Row>
        </Row>
      </TableCell>

      {!hideManagerColumn ? (
        <TableCell>
          <Row className="gap-5">{balanceOps.managerName}</Row>
        </TableCell>
      ) : null}

      <TableCell>
        <Row className="gap-5">
          <Icon size="M" src={brand.tokenLogo} alt={brand.tokenTicker} />
          {brand.tokenTicker}
        </Row>
      </TableCell>
      <TableCell>
        <FormattedNumber
          value={shares}
          suffix={` ${isDeposit ? 'Created' : 'Redeemed'}`}
        />
      </TableCell>
      <TableCell>
        <FormattedNumber value={sharesValue} prefix="$" decimalScale={2} />
      </TableCell>
      <TableCell>
        <Row className="gap-5">
          <TransferStatusCell status={balanceOps.status} />
          {balanceOps.status === DepositOrWithdrawalStatus.Requested ? (
            <Timer24h timestamp={balanceOps.timestamp} />
          ) : null}
        </Row>
      </TableCell>
      <TableCell align={hideActions ? 'right' : 'left'}>
        {timestampToLocalDateWithTime(balanceOps.timestamp)}
      </TableCell>

      {!hideActions ? (
        <TableCell align="right">
          <ActionRow className="justify-end">
            {isCancellable ? (
              <Button
                sizeVariant="S"
                colorVariant="secondary"
                border="shadesBackground500"
                background="transparent"
                onClick={() => onCancel(balanceOps.id, shares)}
              >
                {t('cancel')}
              </Button>
            ) : (
              '-'
            )}
          </ActionRow>
        </TableCell>
      ) : null}
    </TableRow>
  );
};

export default ActivityRow;
