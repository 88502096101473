import { useState, useEffect } from 'react';

import { useWeb3React } from '@web3-react/core';

import { readUserSetting, sendGaEvent } from 'utils';

import { injected, network, socialconnect } from '../connectors';
import { LS_IS_WALLET_CONNECTED_VIA_SOCIAL } from '../constants/localStorageKeys';
import { NetworkContextName } from 'constants/index';

import { GaEventActions, GaEventCategories } from 'enums';

export function useEagerConnect() {
  const { activate, active } = useWeb3React();
  const { activate: activateNetwork } = useWeb3React(NetworkContextName);

  const [tried, setTried] = useState(false);

  useEffect(() => {
    const connect = async () => {
      try {
        sendGaEvent(
          GaEventCategories.Button,
          GaEventActions.Click,
          'Connect wallet',
        );
        await activateNetwork(network, undefined, true);
        if (readUserSetting(LS_IS_WALLET_CONNECTED_VIA_SOCIAL)) {
          if (socialconnect?.particleNetwork?.auth?.isLogin()) {
            await activate(socialconnect, undefined, true);
          }
        } else {
          const isAuthorized = await injected.isAuthorized();
          if (isAuthorized) {
            await activate(injected, undefined, true);
          }
        }

        setTried(true);
      } catch (error: any) {
        setTried(true);
        console.error(error);
      }
    };

    connect();
  }, []); // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}
