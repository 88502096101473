import { useState, useEffect, useCallback } from 'react';

import {
  USD_DECIMAL_SCALE,
  getCurrencyFromTradingPair,
  getCurrencyInputValue,
  getMaxOrderUsdValue,
  parseProdDisabled,
  roundDownToNearestTick,
  roundToNearestTick,
  showNotification,
} from 'utils';
import {
  checkOrderForErrors,
  getLongAndShortBuyingPower,
  getMaxOrderUsdValueMinusFees,
  hasPriceInput,
  hasTriggerPriceInput,
} from 'utils/order';

import { Key } from 'hooks';
import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';
import useModal from 'hooks/useModal';
import { useOrdersAPI } from 'hooks/useOrdersAPI';

import Button from 'components/Button/button';
import NumberInput from 'components/Inputs/NumberInput';
import Modal from 'components/Modal';

import {
  NotificationType,
  OrderStatus,
  OrderType,
  TradeSide,
} from '../../../enums';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { useOrderbookSnapshot } from 'queryHooks/orderbookSnapshot';

import { Order } from 'interfaces';
import { observer } from 'mobx-react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
`;

type MarginSeperatorProps = {
  height?: number;
};
const MarginSeparator = styled.div<MarginSeperatorProps>`
  width: 100%;
  height: ${({ height = 20 }) => `${height}px`};
`;

const shouldShowTriggerPriceInput = (
  orderType: OrderType,
  status: OrderStatus,
) => {
  return (
    [OrderType.ST0P_LIMIT, OrderType.STOP_MARKET].includes(orderType) &&
    status === OrderStatus.PLACED
  );
};

/**
 * Returns true if the price input should be shown for the order.
 * It is shown for limit, market and stop limit orders, and for stop market orders that are open.
 * @param type order type
 * @param status order status
 */
export const shouldShowPricePriceInput = (
  type: OrderType,
  status: OrderStatus,
) => {
  return (
    [
      OrderType.LIMIT,
      OrderType.ST0P_LIMIT,
      OrderType.MARKET,
      OrderType.STOP_LOSS,
      OrderType.TAKE_PROFIT,
      OrderType.STOP_LOSS_LIMIT,
      OrderType.TAKE_PROFIT_LIMIT,
    ].includes(type) ||
    (type === OrderType.STOP_MARKET && status === OrderStatus.OPEN)
  );
};

// const isLimitPrice = (orderType: OrderType) =>
//   [
//     OrderType.ST0P_LIMIT,
//     OrderType.STOP_LOSS_LIMIT,
//     OrderType.TAKE_PROFIT_LIMIT,
//   ].includes(orderType);

const getShouldDisableConfirmButton = ({
  newPrice,
  newSize,
  newTriggerPrice,
  orderType,
  isAmendingOrder,
}: {
  newPrice: number;
  newSize: number;
  newTriggerPrice: number;
  orderType: OrderType;
  isAmendingOrder: boolean;
}) => {
  if (isAmendingOrder) return true;

  if (hasPriceInput(orderType)) {
    if (!newPrice || newPrice <= 0) {
      return true;
    }
  }

  if (hasTriggerPriceInput(orderType)) {
    if (!newTriggerPrice || newTriggerPrice <= 0) {
      return true;
    }
  }

  if (!newSize || newSize <= 0) {
    return true;
  }
  return false;
};

interface Props {
  order: Order;
  currency: string;
}

const AmendOrderModal = ({ order, currency }: Props) => {
  const modal = useModal();
  const { t } = useTranslation();
  const {
    store: {
      account: { _orders, accountStats, _positions },
      markets: marketsStore,
      appState: { isCurrencyKindUSD, toggleCurrencyKind },
    },
  } = useAppContext();
  const { amendOrder } = useOrdersAPI();

  const {
    price,
    marketID,
    initialSize,
    remainingSize,
    triggerPrice,
    status,
    type,
  } = order;
  const { midPrice } = useOrderbookSnapshot(marketID);

  const [newPrice, setNewPrice] = useState<number | null>(price);
  const [newTriggerPrice, setNewTriggerPrice] = useState<number>(triggerPrice);

  const quantityCurrency = isCurrencyKindUSD ? 'USD' : currency;

  const [isAmendingOrder, setIsAmendingOrder] = useState<boolean>(false);

  const [newOrderState, setNewOrderState] = useState<{
    size: number | null;
    value: number | null;
  }>({
    size: initialSize,
    value: initialSize * price,
  });

  const orderMarket = marketsStore.getById(marketID);

  const maxOrderUsdValue = getMaxOrderUsdValue(accountStats, orderMarket);

  const usdAmountAlreadyInOrder = order.price * order.remainingSize;

  // account for some fee and change in withdrawable balance (subtract 3%)
  const maxOrderUsdValueMinusFees = maxOrderUsdValue
    ? roundDownToNearestTick(
        getMaxOrderUsdValueMinusFees(maxOrderUsdValue) as number,
        0.03,
      ) + usdAmountAlreadyInOrder // add amount already in order
    : null;

  const decimalScale = useGetDecimalPlaces(marketID);

  const {
    longingPower: longinPowerMinusFees,
    shortingPower: shortingPowerMinusFees,
    longMaxQuantity,
    shortMaxQuantity,
  } = getLongAndShortBuyingPower(
    _positions,
    order.marketID,
    maxOrderUsdValueMinusFees ?? 0,
    newPrice,
    orderMarket?.fairPrice ?? 0,
    decimalScale,
    order.type,
  );

  const makeAmendOrderRequest = useCallback(
    async ({
      newPrice,
      newSize,
      newTriggerPrice,
    }: {
      newPrice: number;
      newSize: number;
      newTriggerPrice: number;
    }) => {
      try {
        flushSync(() => {
          setIsAmendingOrder(true);
        });

        const latest = _orders?.find(p => p.id === order.id);
        let filled: number;
        if (latest) {
          filled = initialSize - latest.remainingSize;
        } else {
          filled = initialSize - remainingSize;
        }
        if (newSize - filled <= 0) {
          showNotification({
            title: t('orderAmendFailed'),
            description: t('orderAmendFailedDescription'),
            type: NotificationType.Info,
          });
          return;
        }

        newSize = newSize - filled;

        const roundedSize = roundToNearestTick(newSize, decimalScale.minOrder);
        const roundedPrice =
          newPrice && roundToNearestTick(newPrice, decimalScale.minTick);

        const roundedTriggerPrice =
          newTriggerPrice &&
          roundToNearestTick(newTriggerPrice, decimalScale.minTick);

        await amendOrder({
          orderID: order.id,
          marketID: order.marketID,
          type: order.type,
          size: roundedSize,
          ...(roundedTriggerPrice && { triggerPrice: roundedTriggerPrice }),
          ...(roundedPrice && { price: roundedPrice }),
        });
        modal.clear();
      } catch (e) {
        console.error(
          'An error occurred while amending an order from the orders table.',
          e,
        );
      } finally {
        setIsAmendingOrder(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, marketID, amendOrder, modal],
  );

  useEffect(() => {
    const listener = event => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit(event);
      }
      if (event.key === 'Escape') {
        modal.clear();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPrice, newOrderState.size]);

  const shouldDisableConfirmButton = getShouldDisableConfirmButton({
    newPrice: newPrice ?? 0,
    newSize: newOrderState.size ?? 0,
    newTriggerPrice,
    orderType: order.type,
    isAmendingOrder,
  });

  const handlePriceChange = (val: number | null) => {
    setNewPrice(val);

    if (!val) return;

    if (quantityCurrency === 'USD') {
      setNewOrderState(prevState => ({
        value: prevState.value,
        size: (prevState.value ?? 0) / val,
      }));
    } else {
      setNewOrderState(prevState => ({
        value: prevState.size ? prevState.size * val : null,
        size: prevState.size,
      }));
    }
  };

  const { quantityError, priceError, triggerPriceError } = checkOrderForErrors({
    orderConfig: {
      triggerPrice: newTriggerPrice,
      price: newPrice,
      quantity: newOrderState.size,
      value: newOrderState.value,
      type: order.type,
      marketPrice: marketsStore.getById(order.marketID)?.fairPrice,
    },
    maxOrderUsdValueMinusFees,
    decimalPlaces: decimalScale,
    assetName: getCurrencyFromTradingPair(order.marketID),
    currencyKind: quantityCurrency,
    positions: _positions,
    marketId: marketID,
    isCurrencyKindUSD,
  });

  const quantityErrorMessage =
    order.side === TradeSide.LONG ? quantityError.long : quantityError.short;

  const hasError =
    !!quantityErrorMessage || !!priceError || !!triggerPriceError;

  const handleSubmit = async (event?: any) => {
    event?.preventDefault();

    if (shouldDisableConfirmButton || hasError) return;

    if (!newOrderState.size || !newPrice) return;

    await makeAmendOrderRequest({
      newPrice,
      newSize: newOrderState.size,
      newTriggerPrice,
    });
  };

  return (
    <Modal
      showHeader={true}
      title={t('amendOrder')}
      size={'small'}
      name={Modals.amendOrderModal}
      showCloseIcon={false}
      handleKeyStrokes={{
        [Key.Enter]: handleSubmit,
      }}
    >
      {shouldShowTriggerPriceInput(type, status) && (
        <>
          <NumberInput
            data-cy="amend-order-trigger-price"
            value={getCurrencyInputValue(newTriggerPrice)}
            onChange={value => {
              setNewTriggerPrice(value as number);
            }}
            label={t('triggerPrice')}
            placeholder={'0.00'}
            currency="USD"
            showPresets={false}
            showSwapCurrency={false}
            showCurrencyIcon={true}
            showValueApproximation={false}
            errorMessage={triggerPriceError}
            showError={!!triggerPriceError}
          />
          <MarginSeparator height={10} />
        </>
      )}

      {shouldShowPricePriceInput(type, status) && (
        <>
          <NumberInput
            data-cy="amend-order-price"
            value={getCurrencyInputValue(newPrice)}
            onChange={handlePriceChange}
            label={
              order.type === OrderType.LIMIT ? t('price') : t('limitPrice')
            }
            placeholder={'0.00'}
            currency="USD"
            showPresets={false}
            showSwapCurrency={false}
            showCurrencyIcon={true}
            showValueApproximation={false}
            errorMessage={priceError}
            showError={!!priceError}
            midValue={midPrice}
          />
          <MarginSeparator height={10} />
        </>
      )}

      {isCurrencyKindUSD ? (
        <NumberInput
          data-cy="amend-order-size"
          key="USD"
          value={newOrderState.value}
          onChange={val => {
            if (val === null || val === 0) {
              setNewOrderState({
                size: null,
                value: val,
              });
              return;
            }
            setNewOrderState({
              size: newPrice ? Number((val! / newPrice).toFixed(5)) : null,
              value: val,
            });
          }}
          label={t('size')}
          currency={'USD'}
          showPresets={true}
          showSingleMaxButton={false}
          onSwapCurrency={toggleCurrencyKind}
          approximationCurrency={currency}
          // needs to be converted to string - https://github.com/s-yadav/react-number-format/issues/212
          approximatedValue={newOrderState.size?.toString()}
          approximatedValueDecimalScale={decimalScale}
          maxValue={
            order.side === TradeSide.LONG
              ? longinPowerMinusFees
              : shortingPowerMinusFees
          }
          autoFocus
          inputValueDecimalScale={USD_DECIMAL_SCALE}
        />
      ) : (
        <NumberInput
          data-cy="amend-order-size"
          value={newOrderState.size}
          onChange={val => {
            if (val === null || val === 0) {
              setNewOrderState({
                size: val,
                value: null,
              });
              return;
            }

            setNewOrderState({
              size: val,
              value: Number(val * (newPrice ?? 0)),
            });
          }}
          label={t('size')}
          maxValue={
            order.side === TradeSide.LONG ? longMaxQuantity : shortMaxQuantity
          }
          currency={currency}
          showSingleMaxButton={false}
          onSwapCurrency={toggleCurrencyKind}
          approximationCurrency="USD"
          approximatedValue={newOrderState.value?.toString()}
          approximatedValueDecimalScale={USD_DECIMAL_SCALE}
          inputValueDecimalScale={decimalScale}
          showPresets={true}
          autoFocus
        />
      )}
      <ButtonGroup className="mt-20">
        <Button colorVariant="secondary" sizeVariant="S" onClick={modal.clear}>
          {t('cancel')}
        </Button>
        <Button
          colorVariant={'primaryGreen'}
          sizeVariant="S"
          block={true}
          onClick={handleSubmit}
          disabled={parseProdDisabled(shouldDisableConfirmButton || hasError)}
          data-cy="amend-order-submit-btn"
          data-gtmid="button-amend-order-confirm"
          isLoading={isAmendingOrder}
        >
          {quantityErrorMessage || t('confirm')}
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

export default observer(AmendOrderModal);
