import { memo } from 'react';

import { textWithEllipsisInTheMiddle } from 'utils';
import { isBrandBfx } from 'utils/brand';

import { useActiveWeb3React } from 'hooks';

import { FormattedNumber } from 'components';
import CircularProgress from 'components/CircularProgress';
import Text from 'components/Text';

import { MAX_TIER_LEVEL, TIER_TUPLES } from '../../tierConfig';
import avatarImg from 'assets/images/rewards/avatar.jpeg';
import { theme } from 'theme/Theme';
import { Column, Row } from 'theme/globalStyledComponents';

import { observer } from 'mobx-react';
import styled from 'styled-components';

const Container = styled(Row)`
  .circular-progress:hover {
    cursor: ${isBrandBfx ? 'help' : 'default'};

    .pfp-tooltip {
      display: flex;
    }
  }

  .avatar-container {
    display: flex;
    .avatar {
      width: 59px;
      height: 59px;
      border-radius: 100px;
      position: relative;
    }

    .pfp-tooltip {
      position: absolute;
      background: ${({ theme }) => theme.colors.shadesBackground600};
      padding: 5px;
      border-radius: 4px;
      display: none;
      bottom: -20px;
    }
  }

  .badge {
    height: 16px;
    width: 16px;
    position: absolute;
    border-radius: 100px;
    right: -0.5px;
    top: -0.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid ${({ theme }) => theme.colors.shadesBackground900};
    background: ${({ theme }) => theme.colors.main200};
  }
`;

const ProgressWrapper = ({
  children,
  progress = 0,
}: {
  children: React.ReactNode;
  progress?: number;
}) => {
  if (isBrandBfx)
    return (
      <CircularProgress
        size={69}
        strokeWidth={3.5}
        value={progress}
        backgroundColor={theme.colors.shadesBackground700}
        progressColor={theme.colors.main200}
        className="circular-progress"
      >
        {children}
      </CircularProgress>
    );

  return <>{children}</>;
};

const getProgress = (
  level: number | undefined,
  tradingVolume: number | undefined,
) => {
  if (tradingVolume === undefined) {
    return { progress: undefined, remainingVolumeToLevelUp: undefined };
  }

  if (level === undefined)
    return {
      progress: 0,
      remainingVolumeToLevelUp: undefined,
    };

  if (!level || level === 7)
    return { progress: 100, remainingVolumeToLevelUp: undefined };

  if (tradingVolume === 0)
    return { progress: 0, remainingVolumeToLevelUp: TIER_TUPLES[1][0] };

  const tupleIndex = level - 1;

  const nextTupleIndex = tupleIndex + 1;

  return (
    // Volume Required for Next Level
    {
      progress:
        ((tradingVolume - TIER_TUPLES[tupleIndex][0]) /
          (TIER_TUPLES[nextTupleIndex][0] -
            (TIER_TUPLES[tupleIndex]?.[0] ?? 0))) *
        100,
      remainingVolumeToLevelUp: TIER_TUPLES[nextTupleIndex][0] - tradingVolume,
    }
  );
};

type GreetingHeaderProps = {
  profilePicture?: string;
  username?: string;
  level?: number;
  tradingVolume?: number;
};
const GreetingHeader = ({
  profilePicture,
  username,
  level,
  tradingVolume,
}: GreetingHeaderProps) => {
  const { progress, remainingVolumeToLevelUp } = getProgress(
    level,
    tradingVolume,
  );
  const { account } = useActiveWeb3React();

  const parsedUserName = username
    ? username
    : account
    ? textWithEllipsisInTheMiddle(account)
    : 'user';

  return (
    <Container gap={20}>
      <ProgressWrapper progress={progress}>
        <div className="avatar-container">
          <img
            className="avatar"
            alt="avatar"
            src={profilePicture || avatarImg}
          />

          {tradingVolume !== undefined && remainingVolumeToLevelUp ? (
            <FormattedNumber
              disableTooltip
              prefix="Trade $"
              value={remainingVolumeToLevelUp}
              suffix=" more to level up!"
              variant="BODY_XS"
              className="pfp-tooltip"
              noWrap
            />
          ) : null}
        </div>

        {level !== undefined ? (
          <div className="badge">
            <Text
              color="shadesBackground900"
              fontWeight="semiBold"
              variant="BODY_S"
            >
              {level ?? '-'}
            </Text>
          </div>
        ) : null}
      </ProgressWrapper>
      <Column gap={5}>
        <Text variant="HEADING_H1" fontWeight="semiBold">
          Hello 👋 @{parsedUserName},
        </Text>
        <Text variant="BODY_M" color="shadesForeground200">
          Welcome to your airdrop dashboard
        </Text>
      </Column>
    </Container>
  );
};

export default observer(GreetingHeader);
