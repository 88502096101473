import { getCurrencyFromTradingPair } from 'utils';

import { DecimalScale } from 'constants/marketMappings';
import { useAppContext } from 'contexts/AppContext';

export const DEFAULT_DECIMAL_PLACES: DecimalScale = {
  price: 2,
  priceScale: 2,
  size: 2,
  minOrder: 0.0001,
  minTick: 0.0001,
};

export const useGetMarketTitleFromMarketId = (marketId: string | undefined) => {
  const {
    store: {
      markets: { _marketsTitles },
    },
  } = useAppContext();

  if (!marketId) {
    return 'N/A';
  }

  const currency = getCurrencyFromTradingPair(marketId).toLowerCase();

  if (currency === 'btc') {
    return 'Bitcoin';
  }

  let marketTitle: string | undefined;
  if (_marketsTitles) {
    marketTitle = _marketsTitles[currency];
  }

  return marketTitle ?? '-';
};
