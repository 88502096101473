export enum QueryKeys {
  Orders = 'orders',
  Fills = 'fills',
  Transfers = 'transfers',
  Settlements = 'settlements',
  Markets = 'markets',
  Airdrops = 'airdrops',
  AirdropUserStats = 'airdropUserStats',
  RbxBalance = 'rbxBalance',
  NewsFeed = 'newsFeed',
  ApiKeys = 'apiKeys',
  Portfolio = 'portfolio',
  Settings = 'settings',
  FundingRates = 'fundingRates',
  CoingeckoMarketData = 'coingeckoMarketData',
  ReferralsLeaderboard = 'referralsLeaderboard',
  UserReferral = 'userReferral',
  ReferralFees = 'referralFees',
  ElixirVaultsTvlApy = 'elixirVaultsTvlApy',
  ElixirVaultsWalletShares = 'elixirVaultsWalletShares',
  ElixirVaultsPendingWalletShares = 'elixirVaultsPendingWalletShares',
  ElixirVaultsRouterUsdtBalances = 'elixirVaultsRouterUsdtBalanaces',
  ElixirVaultsRbxRewardsForUser = 'elixirVaultsRbxRewards',
  ElixirVaultsRbxRewardsForUserClaimed = 'elixirVaultsRbxRewardsClaimed',
  CurrencyBalance = 'currencyBalance',
  SwapQuote = 'swapQuote',
  IsTokenApprovedForSwap = 'isTokenApprovedForSwap',
  VaultsNavHistory = 'vaultsNavHistory',
  PlatformVault = 'platformVault',
  PlatformVaults = 'platformVaults',
  PlatformVaultsHoldings = 'platformVaultsHoldings',
  PlatformVaultsDataWithHoldings = 'platformVaultsDataWithHoldings',
  VaultsBalanceOps = 'vaultsBalanceOps',
  VaultUnstakeRequests = 'vaultUnstakeRequests',
  BalanceOpsRequestedUnstakes = 'balanceOpsRequestedUnstakes',
  BlastPoints = 'blastPoints',
  ClaimableTransfers = 'claimableTransfers',
  AccountRbxBalance = 'accountRbxBalance',
  AccountLpRbxBalance = 'accountLpRbxBalance',
  TotalTokensLocked = 'totalTokensLocked',
  YourTokenLocks = 'yourTokenLocks',
  LockCutoffTime = 'lockCutoffTime',
  UserGameAssets = 'userGameAssets',
  GameAssetsLeaderboard = 'gameAssetsLeaderboard',
  UserRegistrationForBlastAirdrop = 'userRegistrationForBlastAirdrop',
  MarketVolume = 'marketVolume',
  UserProfile = 'userProfile',
  OrderbookSnapshot = 'orderbookSnapshot',
  MeshIntegrations = 'meshIntegrations',
  OrderFills = 'orderFills',
  BFXPointsBalance = 'bfxPointsBalance',
}

/**
 * Array of all public query keys
 */
export const privateQueryKeysArray = Object.keys(QueryKeys)
  .filter(
    key =>
      ![
        QueryKeys.NewsFeed,
        QueryKeys.Markets,
        QueryKeys.ReferralsLeaderboard,
        QueryKeys.ElixirVaultsTvlApy,
        QueryKeys.ElixirVaultsRouterUsdtBalances,
        QueryKeys.ElixirVaultsRbxRewardsForUser,
        QueryKeys.ElixirVaultsRbxRewardsForUserClaimed,
        QueryKeys.VaultsNavHistory,
        QueryKeys.PlatformVaultsDataWithHoldings,
        QueryKeys.OrderbookSnapshot,
      ].includes(QueryKeys[key]),
  )
  .map(key => QueryKeys[key]);
