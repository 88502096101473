import AccountStore from './account';
import AppStateStore from './appState';
import CentrifugeStore from './centrifuge';
import MarketsStore from './markets';
import OrderbookStore from './orderbook';
import SavedWalletStore from './savedWalletsStore';
import TradesStore from './tradesStore';
import TVConfigStore from './tvConfig';

export default class AppStore {
  appState = new AppStateStore(this);
  markets = new MarketsStore(this);
  account = new AccountStore(this);
  tvConfig = new TVConfigStore(this);
  orderbook = new OrderbookStore(this);
  trades = new TradesStore(this);
  centrifuge = new CentrifugeStore(this);
  savedWallets = new SavedWalletStore(this);
}
