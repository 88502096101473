import { memo } from 'react';

import EmptyTableBody from 'components/EmptyStateOverlay/emptyTableBody';

import EmptyActivityRow from './EmptyActivityRow';
import folderQuestionIcon from 'assets/icons/folder-question-white.svg';

import { VisibilityProps } from '..';
import { useTranslation } from 'react-i18next';

const EmptyActivityTableState = ({
  hideActions,
  hideManagerColumn,
  isLoading,
}: VisibilityProps & { isLoading: boolean }) => {
  const { t } = useTranslation();
  return (
    <EmptyTableBody
      RowComponent={
        <EmptyActivityRow
          hideActions={hideActions}
          hideManagerColumn={hideManagerColumn}
        />
      }
      isLoading={isLoading}
      icon={folderQuestionIcon}
      title={t('noActivityFound')}
      description={t('noActivityFoundDescription')}
    />
  );
};

export default memo(EmptyActivityTableState);
