import useModal from 'hooks/useModal';

import { FormattedNumber } from 'components';
import Button from 'components/Button/button';
import EmptyText from 'components/EmptyText';
import Icon from 'components/Icon';
import IconPair from 'components/IconPair';
import Text from 'components/Text';

import { StyledEmptyVaultCard } from './styles';
import folderQuestionIcon from 'assets/icons/folder-question-white.svg';
import { Modals } from 'constants/modals';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import { getTotalVaultApy } from 'pages/Vaults/Pages/Vaults/TabPanels/PlatformVaults/VaultList/utils';
import {
  Row,
  Column,
} from 'pages/Vaults/Pages/Vaults/TabPanels/common/VaultList/List/Grid/styles';

import { useTranslation } from 'react-i18next';

export type EmptyVaultCardProps = {
  displayActions?: boolean;
  isUserAuthenticated: boolean;
  isLoading: boolean;
  title?: string;
  description?: string;
  onCtaPress?: () => void;
  apy?: number;
};
const EmptyVaultCard = ({
  displayActions = false,
  isUserAuthenticated,
  isLoading,
  title,
  description,
  onCtaPress,
  apy,
}: EmptyVaultCardProps) => {
  const modal = useModal();
  const { t } = useTranslation();

  const actionButtonLabelTx = isUserAuthenticated
    ? 'startEarning'
    : 'connectWallet';

  const onConnectWallet = () => {
    modal.present(<WalletModal />, Modals.walletModal);
  };

  return (
    <StyledEmptyVaultCard disableHoverAnimation>
      <div className="top-part">
        <Row className="justify-between">
          <Column className="gap-2">
            {displayActions ? (
              <Text variant="BODY_M" fontWeight="semiBold" color="white">
                {title || t('noVaultsFound')}
              </Text>
            ) : (
              <EmptyText width={82} />
            )}

            {displayActions ? (
              <Text variant="BODY_S" color="shadesForeground200">
                {description ||
                  t(
                    isUserAuthenticated
                      ? 'startStakingAndEarnRewards'
                      : 'connectWalletToViewYourVaults',
                  )}
              </Text>
            ) : (
              <EmptyText width={120} />
            )}
          </Column>

          {displayActions ? (
            <Icon src={folderQuestionIcon} size="XL" />
          ) : (
            <IconPair isEmpty size={32} />
          )}
        </Row>

        {displayActions ? (
          <Row className="gap-5 items-center">
            <FormattedNumber
              prefix="~"
              value={apy ? apy : 135}
              variant="NUMBERS_XL"
              suffix="%"
              color="white"
            />

            <Text
              className="success-badge"
              variant="BODY_XS"
              color="positiveForeground200"
            >
              {t('potentialAPY')}
            </Text>
          </Row>
        ) : (
          <EmptyText width={0} />
        )}

        <Column className="gap-2">
          <Row className="justify-between">
            <EmptyText width={112} />
            <EmptyText width={36} />
          </Row>

          <Row className="justify-between">
            <EmptyText width={30} />
            <EmptyText width={105} />
          </Row>
        </Column>

        {displayActions ? (
          <Button
            sizeVariant="S"
            colorVariant="primaryGreen"
            block
            onClick={!isUserAuthenticated ? onConnectWallet : onCtaPress}
          >
            {t(actionButtonLabelTx)}
          </Button>
        ) : (
          <div className="empty-button" />
        )}
      </div>
      {isUserAuthenticated ? (
        <Row className="bottom-part justify-between">
          <EmptyText width={61} />
          <EmptyText width={86} />
        </Row>
      ) : null}
      {isLoading ? <div className="shimmer" /> : null}
    </StyledEmptyVaultCard>
  );
};

export default EmptyVaultCard;
