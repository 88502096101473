import { useEffect } from 'react';

import { StyledTierCard } from './styles';
import vip6CardBg from 'assets/videos/card_vip_6.mp4';

import styled from 'styled-components';

const StyledSpecialCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  overflow: hidden;
  border-radius: 14px;
  background: linear-gradient(
    135deg,
    rgba(140, 107, 46, 1) 0%,
    rgba(232, 200, 139, 1) 45%
  );

  @keyframes rotateBorder {
    0% {
      left: -120px;
    }
    50% {
      left: 450px;
    }
    100% {
      left: -120px;
    }
  }

  .animated-border {
    z-index: 1;
    position: absolute;
    height: 300%;
    width: 30px;
    background: #ffbf44;
    transform: rotate(-45deg);
    animation: rotateBorder 3s ease-in-out infinite;
  }

  .bg_video {
    top: 2px;
    position: absolute;
    z-index: 2;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border-radius: 12px;
    object-fit: cover;
  }

  .children {
    position: relative;
    z-index: 3;
  }
`;

const SpecialTierCard = ({ children }) => {
  return (
    <StyledSpecialCard>
      <video autoPlay loop muted className="bg_video">
        <source src={vip6CardBg} type="video/mp4" />
      </video>

      <div className="animated-border" />

      <StyledTierCard className="children">{children}</StyledTierCard>
    </StyledSpecialCard>
  );
};

export default SpecialTierCard;
