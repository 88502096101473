import { memo, useState } from 'react';

import { brand } from 'utils/brand';

import { VaultWithContractInfo } from 'hooks/useElixirVaults';

import { FormattedNumber } from 'components';
import Loading from 'components/Loading';
import TabSelector from 'components/TabSelector';
import Text from 'components/Text';

import TVLAndPerformanceChart from './TVLAndPerformanceChart';
import { Column, Row } from 'pages/Vaults/styles';
import { useFetchVaultTvlHistory } from 'queryHooks/vaults';
import { theme } from 'theme/Theme';

import { TimescaleType } from 'enums';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled(Column)`
  flex: 1;
  gap: 20px;

  .time-interval-container {
    padding: 6px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }

  .selector-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;

    @media (min-width: ${({ theme }) => theme.screenSizes.smaller}px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .recharts-cartesian-axis-tick {
    margin: 0px 10px;
  }
`;

export enum ChartType {
  TVL,
  Performance,
}

type Props = {
  showPerformanceChart?: boolean;
  vault: VaultWithContractInfo | undefined;
};
const Chart = ({ showPerformanceChart = false, vault }: Props) => {
  const { t } = useTranslation();
  const [chartType, setChartType] = useState(ChartType.TVL);
  const [timeInterval, setTimeInterval] = useState<TimescaleType>(
    TimescaleType.Week,
  );

  const buttonOptions = [{ label: t('tvl'), value: ChartType.TVL }];

  if (showPerformanceChart) {
    buttonOptions.push({ label: 'Performance', value: ChartType.Performance });
  }

  const {
    data: vaultTvlHistoryData,
    isLoading: isLoadingVaultTvlHistory,
    isError: isErrorVaultTvlHistory,
  } = useFetchVaultTvlHistory({
    vaultWalletAddress: vault?.routerAddress,
    timescale: timeInterval,
  });

  if (!vault) {
    return null;
  }

  return (
    <Container className="container">
      <div className="selector-container">
        <TabSelector
          buttonOptions={buttonOptions}
          handleOptionSelect={setChartType}
          initialSelectedOption={chartType}
          bgColor={theme.colors.shadesBackground700}
          variant="BODY_S"
        />
        <div className="time-interval-container">
          <TabSelector
            buttonOptions={[
              { label: '24h', value: TimescaleType.Day },
              { label: '7d', value: TimescaleType.Week },
              { label: '30d', value: TimescaleType.Month },
            ]}
            bgColor={theme.colors.shadesBackground900}
            handleOptionSelect={setTimeInterval}
            initialSelectedOption={timeInterval}
            variant="BODY_S"
          />
        </div>
      </div>
      <FormattedNumber
        variant="NUMBERS_L"
        fontWeight="semiBold"
        value={vault.tvlUsd}
        suffix={' ' + brand.tokenTicker}
      />
      {isLoadingVaultTvlHistory ? (
        <Loading />
      ) : isErrorVaultTvlHistory ? (
        <Text variant="HEADING_H2" color="negativeForeground200">
          {t('errorFetchingTvlHistory')}
        </Text>
      ) : (
        <TVLAndPerformanceChart type={chartType} data={vaultTvlHistoryData} />
      )}
    </Container>
  );
};

export default memo(Chart);
