import { createContext } from 'react';

import { FrontendSecrets } from 'utils/signatures';

import { MakePrivateRequestParams } from 'providers/exchangeApiProvider';

export type ExchangeAPI = {
  frontendSecrets: FrontendSecrets | null;
  onboardUser: (vaultAddress?: string) => Promise<boolean>;
  refreshJwt: () => Promise<void>;
  makePrivateRequest: (params: MakePrivateRequestParams) => Promise<any>;
  isOnboarding: boolean;
  isReadingSignatureFromLocalDB: boolean;
};

export const EmptyExchangeApiContext = {
  frontendSecrets: {},
  onboardUser: () => {},
  refreshJwt: () => {},
  makePrivateRequest: () => {},
  isOnboarding: false,
  isReadingSignatureFromLocalDB: false,
};

const ExchangeApiContext = createContext<ExchangeAPI | null>(null);

export { ExchangeApiContext };
