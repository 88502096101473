import styled, { css } from 'styled-components';

export type ColumnProps = {
  gap?: number;
  align?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
  justify?: 'space-between' | 'flex-start' | 'flex-end';
  alignSelf?: 'stretch';
};
export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align = 'flex-start' }) => align};
  justify-content: ${({ justify = 'center' }) => justify};

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `};

  ${({ alignSelf }) =>
    alignSelf &&
    css`
      alignself: ${alignSelf};
    `};
`;

export default Column;
