import { useCallback, useEffect, useState } from 'react';

import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse, Web3Provider } from '@ethersproject/providers';
import { parseUnits } from '@ethersproject/units';

import { useWeb3React } from '@web3-react/core';

import { bigNumberToFloat, roundJsDecimalToString } from 'utils';
import { brand } from 'utils/brand';
import { getContract } from 'utils/contracts';

import { ClaimAllResponse, useVerifyChainId } from 'hooks';
import { useActiveWeb3React } from 'hooks/useActiveWeb3React';
import { useExchangeAPI } from 'hooks/useExchangeAPI';
import { useUsdtContractsApi } from 'hooks/useUsdtContractsApi';

import { BALANCE_OPERATIONS } from 'constants/apiMappings';
import { RBX_DECIMALS, USDT_DECIMALS } from 'constants/contracts';
import { NetworkContextName } from 'constants/networkContextName';
import { RabbitContractsApiContext } from 'contexts/RabbitContractsApiContext';
import {
  STRP__factory,
  STRP,
  RBT__factory,
  RBT,
  Airdrop,
  Airdrop__factory,
  Vault,
  Vault__factory,
} from 'contracts/types';

import { config } from 'config';
import { Endpoints, RequestMethod } from 'enums';
import { ethers, constants, ContractTransaction, BigNumberish } from 'ethers';
import { ProfileBalanceOps } from 'interfaces';

export const RabbitContractsProvider = ({ children }) => {
  const { library: libraryNetwork } =
    useWeb3React<Web3Provider>(NetworkContextName);
  const { account, library } = useActiveWeb3React();
  const { isCorrectChainId } = useVerifyChainId();
  const { makePrivateRequest } = useExchangeAPI();
  const {
    isUsdtApprovedForContract,
    approveUsdtForContract,
    getUsdtAllowanceForContract,
  } = useUsdtContractsApi();

  const [STRPSignContract, setSTRPSignContract] = useState<STRP | undefined>(
    undefined,
  );
  const [STRPReadContract, setSTRPReadContract] = useState<STRP | undefined>(
    undefined,
  );
  const [STRPDecimals, setSTRPDecimals] = useState<number>(0);
  const [RBTSignContract, setRBTSignContract] = useState<RBT | undefined>(
    undefined,
  );
  const [RBTReadContract, setRBTReadContract] = useState<STRP | undefined>(
    undefined,
  );
  const [RBTDecimals, setRBTDecimals] = useState<number>(0);
  const [areReadContractsSet, setAreReadContractsSet] =
    useState<boolean>(false);

  const [AirdropSignContract, setAirdropSignContract] = useState<
    Airdrop | undefined
  >(undefined);

  const [PlatformVaultSignContract, setPlatformVaultSignContract] = useState<
    Vault | undefined
  >(undefined);

  // Set read contracts
  useEffect(() => {
    (async () => {
      try {
        if (!libraryNetwork) {
          return;
        }
        setAreReadContractsSet(false);
        // STRP contract
        const STRPReadContract = getContract<STRP>(
          STRP__factory,
          config.STRPAddress,
          libraryNetwork,
        );
        const STRPDecimals = RBX_DECIMALS;
        setSTRPDecimals(STRPDecimals);
        setSTRPReadContract(STRPReadContract);

        // RBT contract
        const RBTReadContract = getContract<RBT>(
          RBT__factory,
          config.RBTAddress,
          libraryNetwork,
        );

        const RBTDecimals = RBX_DECIMALS;
        setRBTDecimals(RBTDecimals);
        setRBTReadContract(RBTReadContract);
        setAreReadContractsSet(true);
      } catch (e) {
        console.error(e);
        setAreReadContractsSet(false);
      }
    })();
  }, [libraryNetwork]);

  // Set sign contracts
  useEffect(() => {
    (async () => {
      try {
        if (!account || !library) return;
        const STRPSignContract = getContract<STRP>(
          STRP__factory,
          config.STRPAddress,
          library,
          account,
        );
        setSTRPSignContract(STRPSignContract);

        const RBTSignContract = getContract<RBT>(
          RBT__factory,
          config.RBTAddress,
          library,
          account,
        );
        setRBTSignContract(RBTSignContract);

        const AirdropSignContract = getContract<Airdrop>(
          Airdrop__factory,
          config.AirdropAddress,
          library,
          account,
        );
        setAirdropSignContract(AirdropSignContract);

        const PlatformVaultSignContract = getContract<Vault>(
          Vault__factory,
          config.platformVaultContractAddress,
          library,
          account,
        );
        setPlatformVaultSignContract(PlatformVaultSignContract);
      } catch (error: any) {
        console.error(error);
      }
    })();
  }, [account, library]);

  const getAccountSTRPBalance = async () => {
    try {
      if (!STRPReadContract || !account) return constants.Zero;

      return (await STRPReadContract.balanceOf(account)) as BigNumber;
    } catch (e: any) {
      console.error(e);
    } finally {
    }
    return constants.Zero;
  };

  const getAccountRBTBalance = async () => {
    try {
      if (!RBTReadContract || !account) return constants.Zero;

      return (await RBTReadContract.balanceOf(account)) as BigNumber;
    } catch (e: any) {
      console.error(e);
    } finally {
    }
    return constants.Zero;
  };

  const getStrpAllowanceForRbt = async (): Promise<BigNumber> => {
    try {
      if (!STRPReadContract || !account) return constants.Zero;

      const allowance: BigNumber = (await STRPReadContract.allowance(
        account,
        config.RBTAddress,
      )) as BigNumber;
      return allowance;
    } catch (e: any) {
      console.error(e);
    }
    return constants.Zero;
  };

  const isRbtApprovedForStrp = useCallback(
    async (amount?: BigNumber): Promise<boolean> => {
      try {
        if (!account) {
          throw new Error(
            'Account is not defined when checking RBT allowance on STRP contract',
          );
        }
        if (!STRPReadContract) {
          throw new Error(
            'STRP contract is not defined when checking RBT allowance on STRP contract',
          );
        }
        const allowance: BigNumber = (await STRPReadContract.allowance(
          account,
          config.RBTAddress,
        )) as BigNumber;
        // Check for custom amount allowance
        if (amount) {
          return allowance.gte(amount);
        }
        // Otherwise check for max allowance
        return constants.MaxUint256.div(2).lt(allowance);
      } catch (e: any) {
        console.error(e);
      } finally {
      }
      return false;
    },
    [STRPReadContract, account],
  );

  const approveRbtForStrpContract =
    useCallback(async (): Promise<TransactionResponse> => {
      try {
        if (!isCorrectChainId) {
          throw new Error('Incorrect chain ID when approving RBT for STRP');
        }
        if (!STRPSignContract || !account) {
          throw new Error('STRPSignContract is not defined');
        }
        const tx = (await STRPSignContract.approve(
          config.RBTAddress,
          constants.MaxUint256 as BigNumberish,
        )) as TransactionResponse;

        return tx;
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    }, [STRPSignContract, account, isCorrectChainId]);

  const swapStrpToRbt = useCallback(
    async (amount: BigNumber): Promise<ContractTransaction> => {
      try {
        if (!isCorrectChainId) {
          throw new Error('Incorrect chain ID when swapping STRP to RBT');
        }
        if (!RBTSignContract || !account) {
          throw new Error('RBTSign contract is not defined');
        }
        return await RBTSignContract.swapSTRP(amount as ethers.BigNumber);
      } catch (e: any) {
        throw e;
      }
    },
    [RBTSignContract, account, isCorrectChainId],
  );

  const approveRbxForContract = useCallback(
    async (contractAddress: string): Promise<TransactionResponse> => {
      try {
        if (!isCorrectChainId) {
          throw new Error(`Incorrect chain ID when approving RBX`);
        }
        if (!RBTSignContract) {
          throw new Error(`RBTSignContract is not defined`);
        }

        const tx = (await RBTSignContract.approve(
          contractAddress,
          constants.MaxUint256 as BigNumberish,
        )) as TransactionResponse;

        return tx;
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    },
    [RBTSignContract, isCorrectChainId],
  );

  const isRbxApprovedForContract = useCallback(
    async (contractAddress: string, amount?: number) => {
      try {
        if (!account) {
          throw new Error(`Account is not defined when checking RBX allowance`);
        }
        if (!RBTReadContract) {
          throw new Error(
            `RBTReadContract contract is not defined when checking rBX allowance`,
          );
        }
        const allowance: BigNumber = (await RBTReadContract.allowance(
          account,
          contractAddress,
        )) as BigNumber;
        // Check for custom amount allowance
        if (amount) {
          return allowance.gte(
            parseUnits(
              roundJsDecimalToString(amount, RBX_DECIMALS),
              RBX_DECIMALS,
            ),
          );
        }
        // Otherwise check for max allowance
        return constants.MaxUint256.div(2).lt(allowance);
      } catch (e: any) {
        console.error(e);
        throw e;
      }
    },
    [RBTReadContract, account],
  );

  const claimAirdropFromContract = useCallback(
    async (
      claimAllResponse: ClaimAllResponse,
      wallet: string,
    ): Promise<ContractTransaction> => {
      try {
        if (!isCorrectChainId) {
          throw new Error('Incorrect chain ID when claiming airdrop');
        }
        if (!AirdropSignContract || !account) {
          throw new Error('AirdropSign contract is not defined');
        }

        const {
          r,
          s,
          v,
          claimOps: { id },
          bnAmount,
        } = claimAllResponse;

        return await AirdropSignContract.claim(
          id,
          wallet,
          ethers.BigNumber.from(bnAmount),
          v,
          ethers.utils.arrayify(r),
          ethers.utils.arrayify(s),
        );
      } catch (e: any) {
        throw e;
      }
    },
    [AirdropSignContract, account, isCorrectChainId],
  );

  const stakeToVault = useCallback(
    async (amount: BigNumber) => {
      try {
        if (!isCorrectChainId) {
          throw new Error(
            `Incorrect chain ID when staking ${brand.tokenTicker} to vault`,
          );
        }
        if (!PlatformVaultSignContract) {
          throw new Error('PlatformVaultSigncontract contract is not defined');
        }

        return await PlatformVaultSignContract.stake(
          amount as ethers.BigNumber,
        );
      } catch (e: any) {
        throw e;
      }
    },
    [PlatformVaultSignContract, isCorrectChainId],
  );

  const getUsdtAllowanceForVault = useCallback(async () => {
    try {
      if (!isCorrectChainId) {
        throw new Error(
          `Incorrect chain ID when getting ${brand.tokenTicker} allowance for vault`,
        );
      }

      return await getUsdtAllowanceForContract(
        config.platformVaultContractAddress,
      );
    } catch (e: any) {
      throw e;
    }
  }, [getUsdtAllowanceForContract, isCorrectChainId]);

  const approveUsdtForVault = useCallback(async () => {
    try {
      if (!isCorrectChainId) {
        throw new Error(
          `Incorrect chain ID when staking ${brand.tokenTicker} to vault`,
        );
      }

      return await approveUsdtForContract(config.platformVaultContractAddress);
    } catch (e: any) {
      throw e;
    }
  }, [approveUsdtForContract, isCorrectChainId]);

  const isUsdtApprovedForVault = useCallback(
    async (amount?: number) => {
      try {
        if (!isCorrectChainId) {
          throw new Error(
            `Incorrect chain ID when staking ${brand.tokenTicker} to vault`,
          );
        }

        return await isUsdtApprovedForContract(
          config.platformVaultContractAddress,
          amount,
        );
      } catch (e: any) {
        throw e;
      }
    },
    [isCorrectChainId, isUsdtApprovedForContract],
  );

  const sendVaultStakeTxHashToBackend = async (
    vaultAddress: string,
    amount: BigNumber,
    txhash: string,
  ): Promise<ProfileBalanceOps> => {
    try {
      const params = {
        vault_wallet: vaultAddress,
        txhash: txhash,
        amount: bigNumberToFloat(amount, USDT_DECIMALS),
      };

      const { data: res }: { data: ProfileBalanceOps } =
        await makePrivateRequest({
          method: RequestMethod.POST,
          endpoint: `/${Endpoints.BALANCE_OPS_STAKE}`,
          requestParams: params,
          refreshJwt: true,
          responseMapping: BALANCE_OPERATIONS,
        });

      return res;
    } catch (e: any) {
      throw e;
    }
  };

  const sendUnstakeFromVaultRequest = async (
    vaultAddress: string,
    shares: number,
  ): Promise<ProfileBalanceOps> => {
    try {
      const params = {
        vault_wallet: vaultAddress,
        shares,
      };

      const { data: res }: { data: ProfileBalanceOps } =
        await makePrivateRequest({
          method: RequestMethod.POST,
          endpoint: `/${Endpoints.BALANCE_OPS_UNSTAKE_BEGIN}`,
          requestParams: params,
          refreshJwt: true,
          responseMapping: BALANCE_OPERATIONS,
        });

      return res;
    } catch (e: any) {
      throw e;
    }
  };

  const cancelUnstakeFromVaultRequest = async (
    balanceOpsId: string,
  ): Promise<boolean> => {
    try {
      const params = {
        id: balanceOpsId,
      };

      await makePrivateRequest({
        method: RequestMethod.POST,
        endpoint: `/${Endpoints.BALANCE_OPS_UNSTAKE_CANCEL}`,
        requestParams: params,
        refreshJwt: true,
        responseMapping: BALANCE_OPERATIONS,
      });

      return true;
    } catch (e: any) {
      throw e;
    }
  };

  const value = {
    areReadContractsSet,
    STRPDecimals,
    RBTDecimals,
    getAccountSTRPBalance,
    getStrpAllowanceForRbt,
    getAccountRBTBalance,
    isRbtApprovedForStrp,
    approveRbtForStrpContract,
    swapStrpToRbt,
    claimAirdropFromContract,
    stakeToVault,
    approveUsdtForVault,
    getUsdtAllowanceForVault,
    isUsdtApprovedForVault,
    sendVaultStakeTxHashToBackend,
    sendUnstakeFromVaultRequest,
    cancelUnstakeFromVaultRequest,
    isRbxApprovedForContract,
    approveRbxForContract,
  };

  return (
    <RabbitContractsApiContext.Provider value={value}>
      {children}
    </RabbitContractsApiContext.Provider>
  );
};
