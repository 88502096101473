import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AuthType, ParticleNetwork } from '@particle-network/auth';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError } from '@web3-react/core';
import {
  InjectedConnector,
  NoEthereumProviderError,
} from '@web3-react/injected-connector';
import { UserRejectedRequestError } from '@web3-react/walletconnect-connector';

import { showNotification } from 'utils';
import { constructParticleSavedConfig } from 'utils/particleAuth';

import { useActiveWeb3React } from 'hooks/useActiveWeb3React';

import { injected } from '../connectors';
import { AccountContext, Status } from '../contexts/AccountContext';
import { db } from 'IndexedDB/db';
import { ParticleAuthConnector } from 'connectors/particleAuthConnector';
import { WalletConnectV2Connector } from 'connectors/walletconnectV2Connector';
import { useAppContext } from 'contexts/AppContext';
import CentrifugeService from 'service/centrifugeService';
import { mixpanelService } from 'service/mixpanelService';

import { config } from 'config';
import { ConnectorNames, NotificationType } from 'enums';
import { AddTokenParams } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { numberToHex } from 'web3-utils';

const ethereum = window.ethereum;

export const AccountProvider = ({ children }) => {
  const { account, deactivate, active, activate, error, connector } =
    useActiveWeb3React();
  const [status, setStatus] = useState<Status>('disconnected');
  const [isConnecting, setConnecting] = useState(false);
  const firstRender = useRef(true);
  const {
    store: {
      account: accountStore,
      savedWallets: {
        cleanup: cleanupSavedWalletCache,
        handleAccountActivation,
      },
    },
  } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (error instanceof NoEthereumProviderError) {
      setStatus('no-provider');
      showNotification(
        {
          title: t('noEtheremBrowserExtensionDetected'),
          type: NotificationType.Negative,
        },
        true,
      );
    } else if (error instanceof UnsupportedChainIdError) {
      setStatus('wrong-network');
      showNotification(
        {
          title: t('unsupportedNetwork'),
          type: NotificationType.Negative,
        },
        true,
      );
    } else if (!account) {
      setStatus('disconnected');
      if (!firstRender.current) {
        showNotification(
          {
            title: t('walletDisconnected'),
            type: NotificationType.Info,
          },
          true,
        );
        accountStore.unsetFrontendSecrets();
        CentrifugeService.disconnectPrivate();
      }
    } else {
      setStatus('connected');
      showNotification(
        {
          title: t('walletConnected'),
          type: NotificationType.Positive,
        },
        true,
      );
    }
    // On initial data, account is undefined, which causes the weird
    // "wallet disconnected" notification when the page loads
    if (firstRender.current && account !== undefined) {
      firstRender.current = false;
    }
  }, [account, error]);

  /**
   * Based on the passed connector instance,
   * This saves the config in local storage, so that a particular wallet can be quickly logged in at a later stage.
   * Also, marks the passed wallet as current in savedWalletStore.
   */
  const saveWalletConfigToLocalStorage = async (
    connector: AbstractConnector,
  ) => {
    try {
      // If we can get account from connecotr, then its activated
      const address = await connector.getAccount();

      if (!address) return;

      if (connector instanceof ParticleAuthConnector) {
        let particleNetwork = connector.particleNetwork as ParticleNetwork;
        const userInfo = particleNetwork.auth.getUserInfo();
        if (!userInfo) return;
        handleAccountActivation(
          constructParticleSavedConfig(userInfo, address),
        );
      }

      if (connector instanceof InjectedConnector) {
        handleAccountActivation({
          address,
          connectorName: ConnectorNames.Injected,
        });
      }

      if (connector instanceof WalletConnectV2Connector) {
        // connector.provider?.session.
        handleAccountActivation({
          address,
          connectorName: ConnectorNames.WalletConnect,
        });
      }
    } catch (err) {
      console.error('Unable to save wallet.', err);
    }
  };

  /**
   * Adds a network saved in the config file and throws an error if the provider is not connected to the current chain
   */
  const addNetwork = useCallback(async () => {
    setConnecting(true);
    console.log('wallet_addEthereumChain');
    try {
      if (ethereum?.isConnected()) {
        return ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              rpcUrls: [config.network.url],
              chainId: numberToHex(config.chainID),
              chainName: config.network.networkName,
              nativeCurrency: {
                name: config.network.symbol,
                symbol: config.network.symbol,
                decimals: 18,
              },
              blockExplorerUrls: [config.network.explorerUrl],
            },
          ],
        });
      } else {
        throw new Error('No Ethereum provider');
      }
    } catch (error: any) {
      throw error;
    } finally {
      setConnecting(false);
    }
  }, []);

  /**
   * Adds specified tokens to metamask
   */
  const addTokens = useCallback(async (tokens: AddTokenParams[]) => {
    try {
      if (ethereum?.isConnected()) {
        return Promise.all(
          tokens.map((item: AddTokenParams) => {
            return ethereum.request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address: item.address,
                  symbol: item.symbol,
                  decimals: item.decimals,
                  image: item.image ?? null,
                },
              },
            });
          }),
        );
      } else {
        throw new Error('No Ethereum provider');
      }
    } catch (error: any) {
      throw error;
    }
  }, []);

  /**
   * Adds a network and activates the selected connector
   * @param connector selected connector (Metamask or WalletConnect)
   */
  const addNetworkAndConnect = async (connector: AbstractConnector) => {
    try {
      await addNetwork();
      await activate(connector, undefined, true);
    } catch (error: any) {
      try {
        await switchNetwork(config.chainID);
      } catch (error: any) {
        console.error(error);
      }
      showNotification(
        {
          title: t('switchNetwork', {
            networkName: config.network.networkName,
          }),
          type: NotificationType.Negative,
        },
        true,
      );
    }
  };

  /**
   * First tries to activate the selected connector. If not successful, it checks whether an incorrect network
   * is selected. If not, it adds it and tries activating the selector again with the correct network. If the user
   * rejects activating the selector, we just show the "Wallet connection rejected" error, otherwise we show a
   * generic error.
   */
  const connect = useCallback(
    async (connector: AbstractConnector = injected) => {
      setConnecting(true);
      try {
        // Activate the connector
        await activate(connector, undefined, true);
      } catch (error: any) {
        console.log('err', error);
        // If wrong network, add the correct network and try activating the connector again
        if (error instanceof UnsupportedChainIdError) {
          await addNetworkAndConnect(connector);
        }
        // If the user rejected activating the connector
        else if (error instanceof UserRejectedRequestError) {
          showNotification(
            {
              title: t('walletConnectionRejected'),
              type: NotificationType.Info,
            },
            true,
          );
        }
        // Generic error
        else {
          error.code === -32002
            ? showNotification(
                {
                  title: t('requestPendingCheckWallet'),
                  type: NotificationType.Info,
                },
                true,
              )
            : showNotification(
                { title: error.message, type: NotificationType.Negative },
                true,
              );
        }
      } finally {
        setConnecting(false);
      }
    },
    [addNetwork, account, status],
  );

  useEffect(() => {
    if (connector) {
      saveWalletConfigToLocalStorage(connector);
    }
  }, [account]);

  const switchNetwork = useCallback(async (chainID: number) => {
    try {
      setConnecting(true);
      if (ethereum?.isConnected()) {
        return ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: numberToHex(chainID),
            },
          ],
        });
      } else {
        throw new Error('No Ethereum provider');
      }
    } catch (error: any) {
      console.log('switchNetwork error', error);

      throw error;
    } finally {
      setConnecting(false);
    }
  }, []);

  const manualDisconnect = useCallback(async () => {
    deactivate();
    cleanupSavedWalletCache();

    if (!account) {
      return;
    }

    mixpanelService.logoutSuccess(account);
    // Remove the secrets for this account from IndexedDB
    await db.deleteItem(account);
  }, [account]);

  const value = useMemo(
    () => ({
      account,
      status,
      isConnecting,
      areRequestsLocked: !active,
      connect,
      manualDisconnect,
      addTokens,
      switchNetwork,
      addNetwork,
    }),
    [
      account,
      connect,
      manualDisconnect,
      active,
      isConnecting,
      status,
      addTokens,
      switchNetwork,
      addNetwork,
    ],
  );

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};
