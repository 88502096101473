export const marketLeverageValues = [
  {
    value: 1,
    label: '1x',
  },
  {
    value: 5,
    label: '5x',
  },
  {
    value: 10,
    label: '10x',
  },
  {
    value: 15,
    label: '15x',
  },
  {
    value: 20,
    label: '20x',
  },
];
