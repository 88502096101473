import { CampaignAnalytics } from 'hooks';

import { readUserSetting } from './userSettings';
import { LS_CLOCK_OFFSET } from 'constants/localStorageKeys';

import { OrderType, TradeSide } from 'enums';

export interface OnBoardingParams {
  wallet: string;
  signature: string;
  is_client: boolean;
  profile_type?: string;
  meta: {
    referrer_short_code?: string;
    campaign: CampaignAnalytics[];
  };
}

export interface RefreshJwtParams {
  isClient: boolean;
  refreshToken: string;
}

export interface RefreshJwtResponse {
  jwt: string;
  randomSecret: string;
  refreshToken: string;
}

export interface CreateOrderParams {
  marketID: string;
  type: OrderType;
  side: TradeSide;
  price: number;
  size: number;
  triggerPrice?: number;
  timeInForce?: string;
}

export interface CreateSltpOrderParams {
  marketID: string;
  type: OrderType;
  triggerPrice: number;
  sizePercent: number;
  price?: number;
}

export interface CreateNewKeyParams {
  tag: string;
  expiration: number;
  allowedIpList?: string[];
}

export interface DeleteApiKeyParams {
  key: string;
}

export interface RefreshApiKeyParams {
  key: string;
  refreshToken: string;
}

export interface AmendOrderParams {
  orderID: string;
  marketID: string;
  type: OrderType;
  price?: number;
  size: number;
  triggerPrice?: number;
}

export interface AmendSltpOrderParams {
  orderID: string;
  marketID: string;
  type: OrderType;
  triggerPrice: number;
  sizePercent: number;
  price?: number;
}

export interface CancelOrderParams {
  orderID: string;
  marketID: string;
}

export interface CancelSltpOrderParams extends CancelOrderParams {
  type: OrderType;
}

export interface ChangeMarketLeverageParams {
  marketID: string;
  leverage: number;
}

export interface CreateOrderBackendParams {
  market_id: string;
  type: OrderType;
  side: TradeSide;
  price: number;
  size: number;
}

export interface CreateSltpOrderBackendParams {
  market_id: string;
  type: OrderType;
  trigger_price: number;
  size_percent: number;
}

export interface AmendOrderBackendParams {
  order_id: string;
  market_id: string;
  type: OrderType;
  price: number;
  size: number;
}

export interface AmendSltpOrderBackendParams {
  order_id: string;
  market_id: string;
  type: OrderType;
  trigger_price: number;
  size_percent: number;
}

export interface CancelOrderBackendParams {
  order_id: string;
  market_id: string;
}

export interface ChangeMarketLeverageBackendParams {
  market_id: string;
  leverage: number;
}

export const getCurrentTimestamp = () => {
  return Math.floor(new Date().getTime() / 1000);
};

export const getTimestampWithOffset = (
  currentTimestamp: number,
  offset: number,
) => {
  return currentTimestamp + offset;
};

export const getTimestampWithOffsetFromLocalStorage = () => {
  const offset = readUserSetting(LS_CLOCK_OFFSET) ?? 0;
  const timestampWithOffset = getTimestampWithOffset(
    getCurrentTimestamp(),
    offset,
  );
  return timestampWithOffset;
};
