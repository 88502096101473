import { Icon } from 'components';
import { List } from 'components/SelectDropdown.tsx';
import Text from 'components/Text';
import { TextVariant } from 'components/Text/variant';

import { MESH_INTEGRATION_TO_LABLE_ICON_MAP } from '../../../constants';

import { MeshIntegration } from 'enums';

const MeshIntegrationList = ({
  onChangeMeshIntegration,
  selectedMeshIntegration,
  onOutsideClick,
}: {
  onChangeMeshIntegration: (id: MeshIntegration) => void;
  selectedMeshIntegration: MeshIntegration;
  onOutsideClick: () => void;
}) => {
  return (
    <List className="mesh-dropdown">
      {[
        MeshIntegration.Binance,
        MeshIntegration.Coinbase,
        MeshIntegration.Bitfinex,
        MeshIntegration.Others,
      ].map(el => {
        const { label, icon } = MESH_INTEGRATION_TO_LABLE_ICON_MAP[el];
        return (
          <li
            onClick={() => {
              onChangeMeshIntegration(el);
              onOutsideClick();
            }}
            style={TextVariant['BODY_S']}
            className={selectedMeshIntegration === el ? 'selected' : ''}
          >
            <Text gap={5} flexed>
              {icon ? <Icon borderRadius={2} src={icon} /> : null}
              {label}
            </Text>
          </li>
        );
      })}
    </List>
  );
};

export default MeshIntegrationList;
