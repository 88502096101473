import { Icon } from 'components';

import caretDownIcon from 'assets/icons/caret-down.svg';
import caretUpIcon from 'assets/icons/caret-up.svg';
import { Column } from 'theme/globalStyledComponents/column';

const isUpActive = (numOfClicks: number) => numOfClicks % 3 === 1;
const isDownActive = (numOfClicks: number) => numOfClicks % 3 === 2;

type Props = {
  isSorted: boolean;
  numOfClicks: number | undefined;
};

const SortStatusDoubleCaret = ({ isSorted, numOfClicks = 0 }: Props) => {
  const caretUpOpacity = isSorted ? (isUpActive(numOfClicks) ? 0.5 : 1) : 1;

  const caretDownOpacity = isSorted ? (isDownActive(numOfClicks) ? 0.5 : 1) : 1;

  return (
    <Column className="gap-2">
      <Icon src={caretUpIcon} size="XXS" opacity={caretUpOpacity} />
      <Icon src={caretDownIcon} size="XXS" opacity={caretDownOpacity} />
    </Column>
  );
};

export default SortStatusDoubleCaret;
