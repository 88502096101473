import React, { useCallback } from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import { FormattedNumber } from 'components/FormattedNumber';

import {
  HeaderRow,
  InputBox,
  InputRow,
  InputStyled,
  MaxButton,
  StatsRow,
} from './styles';
import walletIcon from 'assets/icons/wallet-gray.svg';

import BigDecimalJS from 'js-big-decimal';
import { useDebouncedCallback } from 'use-debounce';

type Props = {
  value: any;
  decimals?: number;
  maxValue?: BigDecimalJS | undefined | null;
  currency: string;
  currencyIcon: string;
  currencyBalance: number;
  onChange?: (value: BigDecimalJS) => void;
  onClear?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: string | null;
  label: string;
  showHeader?: boolean;
  showMax?: boolean;
  dataTestID?: string;
  readOnly?: boolean;
  leftPart?: JSX.Element;
  debounceTimeout?: number;
  labelPrefix?: string;
  labelSuffix?: string;
  labelMaxValue?: string | undefined;
  maxButtonTooltip?: string;
  allowNegative?: boolean;
  placeholder?: string;
  maxButtonGtmId?: string;
} & Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  Exclude<
    keyof React.InputHTMLAttributes<HTMLInputElement>,
    'defaultValue' | 'type' | 'value' | 'onChange'
  >
>;

function MigrateStrpInput(props: Props) {
  const {
    label,
    value,
    maxValue,
    showMax = true,
    dataTestID,
    currency,
    currencyIcon,
    currencyBalance = 0,
    onChange,
    debounceTimeout,
    disabled,
    placeholder = '0',
    maxButtonTooltip,
    maxButtonGtmId,
    ...restProps
  } = props;

  const changeValue = useCallback(value => {
    const parsedVal = value !== '' ? value : null;
    onChange?.(parsedVal);
  }, []);
  const changeValueDebounced = useCallback(
    useDebouncedCallback(value => {
      changeValue(value);
    }, debounceTimeout),
    [],
  );

  const handleValueChange = useCallback(({ value }: { value: any }) => {
    if (debounceTimeout) {
      changeValueDebounced(value);
    } else {
      changeValue(value);
    }
  }, []);

  const getMaxButton = () => {
    const restProps = {};
    if (maxButtonGtmId) {
      restProps['data-gtmid'] = maxButtonGtmId;
    }

    const b = (
      <MaxButton
        onClick={() => {
          handleValueChange({ value: maxValue ?? 0 });
        }}
        disabled={disabled}
        {...restProps}
      >
        MAX
      </MaxButton>
    );
    return maxButtonTooltip ? (
      <Tooltip placement="top" title={maxButtonTooltip}>
        <div>{b}</div>
      </Tooltip>
    ) : (
      b
    );
  };

  return (
    <InputBox animateBackground={showMax}>
      <HeaderRow>
        <span className="label">{label}</span>
        {showMax ? getMaxButton() : null}
      </HeaderRow>
      <InputRow>
        <div className="currency">
          <div className="icon-container">
            <img src={currencyIcon} alt={currency} />
          </div>
          <h5 className="label font-medium">{currency}</h5>
        </div>
        <InputStyled
          {...restProps}
          type="string"
          data-testid={dataTestID}
          value={value}
          placeholder={placeholder}
          onChange={event => handleValueChange({ value: event.target.value })}
          disabled={disabled}
        />
      </InputRow>
      <StatsRow>
        <div className="holdings">
          <img src={walletIcon} alt="Wallet" />
          <FormattedNumber value={currencyBalance} className="ml-5" />
        </div>
        {/* <div className="value">
          <FormattedNumber value={8675.55} prefix="$" />
        </div> */}
      </StatsRow>
    </InputBox>
  );
}

export default MigrateStrpInput;
