import { memo, useState } from 'react';

import { brand } from 'utils/brand';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { FormattedNumber } from 'components';
import { FormattedNumberProps } from 'components/FormattedNumber';
import IconPair from 'components/IconPair';
import InfoTooltip from 'components/InfoTooltip';
import Text from 'components/Text';

import chevronDownIcon from 'assets/icons/chevronDown.svg';
import chevronUpIcon from 'assets/icons/chevronUp.svg';
import { Column, Row } from 'pages/Vaults/styles';
import { theme } from 'theme/Theme';

import { AmendType } from '..';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StatsContainer = styled(Column)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.shadesBackground700};
  border-radius: 8px;

  .stats-column {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 0px 10px 10px 10px;
  }
`;

type Props = {
  amendType: AmendType;
  maxRedeemableShares: number;
  unstakeAmount: number | null;
  pricePerShare?: number;
  totalIssuedShares?: number;
  totalVolumeUsd?: number;
  isLoading: boolean;
  tokenPair?: string;
};
const VaultStats = ({
  amendType,
  pricePerShare,
  unstakeAmount = 0,
  maxRedeemableShares,
  totalIssuedShares,
  totalVolumeUsd,
  tokenPair,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const [isDropDownShown, setDropDownShown] = useState(true);

  let stats: {
    label: string;
    value: FormattedNumberProps;
    tooltipText?: string;
  }[] = [];

  if (amendType === AmendType.Stake)
    stats = [
      {
        label: t('sharePrice'),
        value: { decimalScale: 4, value: pricePerShare, prefix: '$' }, // Decimal places always equal to 4 or ??
      },
      {
        label: t('tvl'),
        value: { value: totalVolumeUsd, prefix: '$' },
      },

      {
        label: t('totalIssuedShares'),
        value: { value: totalIssuedShares },
      },
    ];

  if (amendType === AmendType.UnStake)
    stats = [
      {
        label: t('sharesToBeRedeemed'),
        value: { value: unstakeAmount ?? 0 },
      },
      {
        label: t('sharePrice'),
        value: { decimalScale: 4, value: pricePerShare, prefix: '$' },
      },
      {
        label: t('estimatedValue'),
        value: {
          value: (unstakeAmount as number) * (pricePerShare ?? 0),
          prefix: '$',
        },
        tooltipText: t('estimatedValueOfSharesBeingRedeemed'),
      },
      {
        label: t('performanceFee'),
        value: { value: 0, prefix: '$' }, // HARDCODED_PERFORMANCE_FEE
      },
    ];

  const assetLogo = useGetMarketLogoFromMarketId(
    tokenPair?.split('-')[0] || '',
  );

  return (
    <StatsContainer>
      <Row
        className="justify-between p-10 cursor-pointer"
        onClick={() => setDropDownShown(prev => !prev)}
      >
        <Row className="gap-5 ">
          <IconPair
            icons={[assetLogo, brand.tokenLogo]}
            size={24}
            bgColor={theme.colors.shadesBackground700}
          />
          <Text variant="BODY_S">{tokenPair ?? '--'}</Text>
        </Row>
        <img
          src={isDropDownShown ? chevronUpIcon : chevronDownIcon}
          alt={'status'}
        />
      </Row>

      {isDropDownShown ? (
        <Column className="stats-column">
          {stats.map(({ label, value, tooltipText }) => (
            <Text variant="BODY_S" className="flex justify-between">
              <Text
                className="flex items-center gap-2"
                color="shadesForeground200"
              >
                {label}
                {tooltipText ? (
                  <InfoTooltip iconSize={12} title={tooltipText} />
                ) : null}
              </Text>

              <FormattedNumber {...value} />
            </Text>
          ))}
        </Column>
      ) : null}
    </StatsContainer>
  );
};
export default observer(VaultStats);
