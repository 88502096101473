import {
  AuthType,
  Config,
  LoginOptions,
  ParticleNetwork,
} from '@particle-network/auth';
import { ParticleProvider } from '@particle-network/provider';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { ConnectorUpdate } from '@web3-react/types';

export class ParticleAuthConnector extends AbstractConnector {
  particleNetwork: ParticleNetwork;
  loginOptions: LoginOptions = {};
  private particleProvider: ParticleProvider | undefined;

  constructor(private config: Config) {
    super({
      supportedChainIds: [config.chainId ?? 1],
    });

    this.particleNetwork = new ParticleNetwork(this.config);
  }

  activate = async (): Promise<ConnectorUpdate<string | number>> => {
    if (!this.particleProvider) {
      this.particleProvider = new ParticleProvider(this.particleNetwork.auth);
    }

    const loginOptions = this.loginOptions;

    // Reset login options for the next activation, whether login succeeds or fails
    this.loginOptions = {};

    if (!this.particleNetwork.auth.isLogin()) {
      await this.particleNetwork.auth.login({
        preferredAuthType: 'email',
        ...loginOptions,
      });
    }

    const account = await this.getAccount();
    const chainId = await this.getChainId();

    return {
      provider: this.particleProvider,
      account,
      chainId,
    };
  };

  async getProvider(): Promise<any> {
    return this.particleProvider;
  }
  async getChainId(): Promise<string | number> {
    return this.particleNetwork.auth.chainId();
  }
  async getAccount(): Promise<string | null> {
    if (this.particleProvider) {
      return this.particleProvider
        .request({ method: 'eth_accounts' })
        .then((accounts): string => accounts[0]);
    } else {
      return null;
    }
  }
  async deactivate() {
    await this.particleNetwork.auth.logout();
    this.emitDeactivate();
  }

  setLoginOptions(options: LoginOptions) {
    this.loginOptions = options;
  }

  setLanguage(lang: string) {
    this.particleNetwork.setLanguage(lang);
  }
}
