import styled from 'styled-components';

export const Container = styled.div`
  .row {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 20px;
    @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 30px;
      gap: 20px;

      > div {
        flex: 1;
      }
    }
  }
`;
