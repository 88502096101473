import { memo } from 'react';

import { TableCell, Tooltip } from '@material-ui/core';

import { textWithEllipsisInTheMiddle } from 'utils';

import {
  RankCellContainer,
  RankChangeLabel,
  TableRowStyled,
  TwitterProfile,
} from './styles';
import settingsIcon from 'assets/bfxInvite/settings.svg';
import chevronDownRedIcon from 'assets/icons/chevron-down-red.svg';
import chevronUpGreenIcon from 'assets/icons/chevron-up-green.svg';
import medalOneIcon from 'assets/icons/medal-1.svg';
import medalTwoIcon from 'assets/icons/medal-2.svg';
import medalThreeIcon from 'assets/icons/medal-3.svg';
import neutralLineIcon from 'assets/icons/neutral-line.svg';

import { ReferralLeaderboard } from 'interfaces';

const rowGradientBackgrounds = [
  'linear-gradient(90deg, rgba(255, 215, 63, 0.10) 0%, rgba(16, 22, 36, 0.00) 100%)',
  'linear-gradient(90deg, rgba(194, 208, 211, 0.10) 0%, rgba(16, 22, 36, 0.00) 100%)',
  'linear-gradient(90deg, rgba(221, 64, 27, 0.10) 0%, rgba(0, 0, 0, 0.00) 100%, rgba(36, 16, 16, 0.00) 100%, rgba(16, 22, 36, 0.00) 100%)',
];

const getMedalIcon = (currentRank: number) => {
  switch (currentRank) {
    case 1:
      return medalOneIcon;
    case 2:
      return medalTwoIcon;
    case 3:
      return medalThreeIcon;
  }
};

const getChangeIcon = (change: number) => {
  if (change > 0) {
    return chevronUpGreenIcon;
  }
  if (change < 0) {
    return chevronDownRedIcon;
  }
  return neutralLineIcon;
};

export const getCurrentRankText = (currentRank: number) => {
  if (currentRank === 0) {
    return 'Unranked';
  }
  return `#${currentRank}`;
};

type Props = {
  referralLeaderboard: ReferralLeaderboard;
  isCurrentUser?: boolean;
  onSharePress?: (rankText: string) => void;
  twitterData: Record<string, any>;
};
const TableRow = ({
  referralLeaderboard: { volume, currentRank, change, wallet, invitedCounter },
  isCurrentUser,
  onSharePress,
  twitterData,
}: Props) => {
  return (
    <>
      <TableRowStyled className="empty-row"></TableRowStyled>
      <TableRowStyled
        backgroundGradient={rowGradientBackgrounds[currentRank - 1]}
      >
        <TableCell>
          <RankCellContainer>
            {currentRank >= 1 && currentRank <= 3 && (
              <img
                src={getMedalIcon(currentRank)}
                alt={`medal ${currentRank}`}
              />
            )}
            {getCurrentRankText(currentRank)}
            {currentRank !== 0 && (
              <RankChangeLabel
                isPositive={change > 0}
                isNegative={change < 0}
                isNeutral={change === 0}
              >
                <img src={getChangeIcon(change)} alt="rank change" />
                {change}
              </RankChangeLabel>
            )}
          </RankCellContainer>
        </TableCell>
        <TableCell>
          <Tooltip title={wallet} placement="top">
            <span>{textWithEllipsisInTheMiddle(wallet)}</span>
          </Tooltip>
        </TableCell>
        <TableCell>{invitedCounter}</TableCell>
        <TableCell>
          <TwitterProfile>
            {twitterData['data'] ? (
              <>
                <img
                  className="avatar"
                  src={twitterData['data']['profile_image_url']}
                  alt="avatar"
                />
                <div className="label">@{twitterData['data']['username']}</div>
                {isCurrentUser && (
                  <img
                    src={settingsIcon}
                    className="icon"
                    alt="share"
                    onClick={() =>
                      onSharePress?.(getCurrentRankText(currentRank))
                    }
                  />
                )}
              </>
            ) : (
              '- -'
            )}
          </TwitterProfile>
        </TableCell>
      </TableRowStyled>
    </>
  );
};

export default memo(TableRow);
