import { RestService } from 'service/restService';

import { Endpoints, RequestMethod } from 'enums';

const restService = RestService.get();

/**
 * Get the server timestamp, the offset between the client and server timestamps gets saved
 * into local storage on every GET request
 * @returns true if the server timestamp is received, or false otherwise
 */
export const getSrvTimestamp = async () => {
  try {
    await restService.request({
      method: RequestMethod.GET,
      path: `/${Endpoints.SRV_TIMESTAMP}`,
      body: {},
      headers: {},
      returnRaw: true,
    });

    return true;
  } catch (e: any) {
    return false;
  }
};
