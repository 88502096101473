import { memo } from 'react';

import { Tooltip } from '@material-ui/core';

import { Container } from './styles';
import conversionRate from 'assets/icons/conversion-rate.svg';
import infoIcon from 'assets/icons/information.svg';

import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="flex items-center">
        <h5 className="font-medium">{t('migrate')}</h5>
        <Tooltip title={t('migrateTooltip')} placement="top">
          <img
            src={infoIcon}
            alt="Information"
            className="ml-10 cursor-pointer"
          />
        </Tooltip>
      </div>
      <div className="flex mt-15 font-body-m font-semiBold gap-5">
        <img src={conversionRate} alt="Conversion rate" />
        <span className="ml-5">1 STRP</span>
        <span className="gray">=</span> 10 RBX
      </div>
    </Container>
  );
};

export default memo(Header);
