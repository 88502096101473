import { useCallback, useState } from 'react';

import useModal from 'hooks/useModal';

import Modal from 'components/Modal';

import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';
import { Modals } from 'constants/modals';

import { MeshOrderStatus, Props } from '.';

const MeshOrderStatusModal = ({
  id,
  trackTransaction,
  ...props
}: Omit<Props, 'processDepositOnIndividualContract' | 'confirmations'> & {
  id: string;
  trackTransaction: (
    txHash: string,
    updateConfirmations: (confirmations: number) => void,
    toastId: React.ReactText,
  ) => void;
}) => {
  const modal = useModal();
  const [confirmations, setConfirmations] = useState<number>(0);

  const shouldModalMinimiseOnOutsideClick =
    confirmations < CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT;

  const onModalClose = () => {
    if (shouldModalMinimiseOnOutsideClick && id) {
      modal.minimiseById(id); // Only in case of Mesh deposit, we need to clear the modal by id just in case the modal is minimized, we dont need it anymore
    } else {
      modal.pop({ name: Modals.depositModal });
    }
  };

  const onUpdateConfirmations = (value: number) => {
    setConfirmations(value);
    if (value >= CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT) {
      modal.setModalMetadata(id, {
        description: 'Sucessfull',
        status: 'success',
      });
    } else {
      modal.setModalMetadata(id, {
        description: `${value}/${CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT} confirmations`,
      });
    }
  };

  const processDepositOnIndividualContract = useCallback(
    (txHash: string, deposiAmount: number, toastId?: React.ReactText) =>
      toastId && trackTransaction(txHash, onUpdateConfirmations, toastId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Modal
      size="auto"
      padding="20px !important"
      overflow="visible"
      name={Modals.depositModal}
      title="Deposit Funds"
      gtmId="deposit-modal"
      minimiseOnOutsideClick={shouldModalMinimiseOnOutsideClick}
      onClose={onModalClose}
      id={id}
    >
      <MeshOrderStatus
        {...props}
        confirmations={confirmations}
        processDepositOnIndividualContract={processDepositOnIndividualContract}
        modalId={id}
        isDepositConfirmed={
          confirmations >= CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT
        }
      />
    </Modal>
  );
};

export default MeshOrderStatusModal;
