export const START_TIME_BFX_DEPOSIT_BONUS = 'March 08, 2024 12:00:00 UTC'; // Start time for the BFX deposit bonus
const BFX_DEPOSIT_BONUS_DEADLINE = 'March 22, 2024 12:00:00 UTC'; // Deadline for the BFX deposit bonus

// used once to check if the deposit bonus is active
export const isBfxDepositBonusActive =
  new Date().getTime() - Date.parse(START_TIME_BFX_DEPOSIT_BONUS) > 0 && // post start time
  Date.parse(BFX_DEPOSIT_BONUS_DEADLINE) - new Date().getTime() > 0; // pre deadline

export const getTimeTillBfxDepositDeadline = () => {
  const total = Date.parse(BFX_DEPOSIT_BONUS_DEADLINE) - new Date().getTime();
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};
