import { createContext } from 'react';

import { Modal, ModalMetadata } from 'providers/ModalPresenter';

export interface ModalsContextInterface {
  present: (
    element: JSX.Element,
    name: string,
    props?: {
      minimised?: boolean;
      id?: string;
      blurred?: boolean;
      metadata?: ModalMetadata;
    },
  ) => void;
  pop: ({ count, name }: { count?: number; name?: string }) => void;
  clear: () => void;
  maximiseById: (id: string) => void;
  minimiseById: (id: string) => void;
  clearByName: (name: string) => void;
  activeModals: Modal[];
  setPresentingEffect: (flag: boolean) => void;
  setModalMetadata: (id: string, metadata: ModalMetadata) => void;
  clearById: (id: string) => void;
  setCurrentModalId: (id: string) => void;
}

const ModalContext = createContext<ModalsContextInterface>(null as any);

export default ModalContext;
