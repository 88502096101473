import { getMarketLogoFromUrl } from 'utils/marketLogo';

import Icon from 'components/Icon';
import Text from 'components/Text';

import { Container } from '../styles';

import { Market } from 'interfaces';

type Props = {
  market: Market;
  selected: boolean;
};

export const MarketAsFilterOption = ({ market, selected }: Props) => {
  const { id, marketTitle } = market;
  return (
    <Container selected={selected} padding={10}>
      <Icon alt={id} size="M" src={getMarketLogoFromUrl(market.iconUrl)} />
      <Text
        fontWeight={selected ? 'semiBold' : 'medium'}
        color={selected ? 'white' : 'shadesForeground200'}
        variant="BODY_S"
      >
        {marketTitle}
      </Text>
    </Container>
  );
};

export default MarketAsFilterOption;
