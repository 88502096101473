import Text from 'components/Text';

import StepIcon from './Icons/StepIcon';
import SubStepIcon from './Icons/SubStepIcon';
import { StyledSteps, Container, StepProgressLine } from './styles';
import { Column } from 'theme/globalStyledComponents/column';

type Step = {
  title: string | React.ReactElement;
  description?: string | React.ReactElement;
};

type Props = {
  steps: (Step & { subSteps?: Step[] })[];
  currentStepIndex: number;
  currentSubStepIndex?: number;
  forceShowDescription?: boolean;
};

const Steps = ({
  steps,
  currentStepIndex,
  currentSubStepIndex = 0,
  forceShowDescription = false,
}: Props) => {
  return (
    <Container>
      {steps.map(({ title, description, subSteps = [] }, stepIndex) => {
        const isCurrentStep = currentStepIndex === stepIndex;

        return (
          <StyledSteps>
            <div>
              <StepIcon
                stepIndex={stepIndex}
                currentStepIndex={currentStepIndex}
              />

              {isCurrentStep ? (
                <>
                  {subSteps.map((_subStep, subStepIndex) => (
                    <>
                      <StepProgressLine
                        stepIndex={subStepIndex}
                        currentStepIndex={currentSubStepIndex}
                      />
                      <SubStepIcon
                        stepIndex={subStepIndex}
                        currentStepIndex={currentSubStepIndex}
                      />
                    </>
                  ))}
                </>
              ) : null}
            </div>

            <div className="steps">
              {!forceShowDescription && isCurrentStep ? (
                <Text
                  variant="BUTTON_S"
                  fontWeight="semiBold"
                  className="step-title"
                >
                  {title}
                </Text>
              ) : (
                <Column className="step-title-col">
                  <Text variant="BUTTON_S" fontWeight="semiBold">
                    {title}
                  </Text>

                  <Text
                    variant="BODY_XS"
                    className="lh-14"
                    color="shadesForeground200"
                  >
                    {description}
                  </Text>
                </Column>
              )}

              {isCurrentStep
                ? subSteps.map((subStep, index) => (
                    <Text
                      variant="BODY_XS"
                      className="sub-step-title"
                      color="shadesForeground200"
                    >
                      {index + 1}. {subStep.title}
                    </Text>
                  ))
                : null}
            </div>
          </StyledSteps>
        );
      })}
    </Container>
  );
};

export default Steps;
