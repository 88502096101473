import { memo } from 'react';

import {
  TableRow,
  TableHead as TableHeadMui,
  TableCell,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const TableHead = () => {
  const { t } = useTranslation();

  return (
    <TableHeadMui>
      <TableRow>
        <TableCell key={0}>{t('type')}</TableCell>
        <TableCell key={1}>{t('currency')}</TableCell>
        <TableCell key={2}>{t('amount')}</TableCell>
        <TableCell key={3}>{t('value')}</TableCell>
        <TableCell key={4}>{t('status')}</TableCell>
        <TableCell key={5}>{t('time')}</TableCell>
        <TableCell key={6}></TableCell>
      </TableRow>
    </TableHeadMui>
  );
};

export default memo(TableHead);
