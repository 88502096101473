import { ChangeEvent, memo, useState } from 'react';

import { Tooltip } from '@material-ui/core';

import { getCurrencyInputValue } from 'utils';

import { useApiKeysAPI } from 'hooks';

import Button from 'components/Button';
import EmailInput from 'components/Inputs/EmailInput';
import NumberInput from 'components/Inputs/NumberInput';

import IpRestrictions from '../IpRestrictions';
import { ButtonsContainer, Container, ExpirationAndAlert } from './styles';
import calendarIcon from 'assets/icons/calendar.svg';
import helpCircle from 'assets/icons/help-circle.svg';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { flushSync } from 'react-dom';

const SECONDS_IN_DAY = 24 * 60 * 60;

type Error = {
  tag?: boolean;
  expiration?: boolean;
};

type Props = {
  setNewKeyTag: (value: string | null) => void;
  newKeyTag: string | null;
};
const CreateNewKey = ({ setNewKeyTag, newKeyTag }: Props) => {
  const { createNewApiKey } = useApiKeysAPI();

  const [expirationDays, setExpirationDays] = useState<number | null>(90);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const [allowedIpList, setAllowedIpList] = useState<string[]>([]);

  const handleAllowedIpListAdd = (ip: string) => {
    setAllowedIpList(prev => [ip, ...prev.filter(ip => ip !== '')]);
  };

  const handleAlowedIpListChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    setAllowedIpList(prev => {
      const updatedList = [...prev];
      updatedList[index] = event.target.value;
      return updatedList;
    });
  };

  const handleDeleteAllowedIp = (ip: string) => {
    setAllowedIpList(prev => prev.filter(item => item !== ip));
  };

  const handleSubmit = async () => {
    setError(null);

    if (!newKeyTag || !expirationDays) {
      const error: Error = {};
      if (!newKeyTag) {
        error.tag = true;
      }
      if (!expirationDays) {
        error.expiration = true;
      }
      setError(error);
      return;
    }

    try {
      flushSync(() => {
        setIsSubmitting(true);
      });

      await createNewApiKey({
        tag: newKeyTag,
        expiration: expirationDays * SECONDS_IN_DAY,
        ...(allowedIpList.length > 0 && { allowedIpList }),
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }

    setNewKeyTag(null);
  };

  return (
    <Container>
      <h5 className="heading">Create new key</h5>
      <ExpirationAndAlert>
        <div className="col">
          <label>Expiration</label>
          <NumberInput
            key="USD"
            value={getCurrencyInputValue(expirationDays)}
            showValueApproximation={false}
            showSwapCurrency={false}
            onChange={value => setExpirationDays(value)}
            customCurrencyIcon={calendarIcon}
            currency={'days'}
            showPresets={false}
            errorMessage={error?.expiration ? 'Expiration is required' : null}
          />
        </div>
        {/* <div className="col">
          <label className="flex items-center">
            Configure Alert
            <Tooltip title="Configure Alert Tooltip" placement="top">
              <img src={helpCircle} alt="Help" className="ml-5" />
            </Tooltip>
          </label>
          <EmailInput
            //   isSubmitting={isSubmitting}
            isSubmitting={false}
            // handleSubmit={handleNotifyMe}
            handleSubmit={() => {}}
            // for resetting the input field
            // key={emailInputKey}
            submitButtonText={'Notify'}
          />
        </div> */}
      </ExpirationAndAlert>
      <div className="divider" />
      <IpRestrictions
        allowedIps={allowedIpList}
        handleDeleteAllowedIp={handleDeleteAllowedIp}
        handleAllowedIpListAdd={handleAllowedIpListAdd}
        handleAllowedIpListChange={handleAlowedIpListChange}
      />
      {error?.tag && (
        <ColoredText color={Colors.Danger} className="font-body-m">
          Missing API key name. Please fill in an API key name in the left hand
          section.
        </ColoredText>
      )}
      <ButtonsContainer className="mt-20">
        <Button
          variant="secondaryOutline"
          size="S"
          responsiveSizing={{ default: 'large' }}
          onClick={() => setNewKeyTag(null)}
        >
          Cancel
        </Button>
        <Button
          variant="primaryGreen"
          size="S"
          responsiveSizing={{ default: 'large' }}
          onClick={handleSubmit}
          disabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default memo(CreateNewKey);
