import { useBalanceOperationsAPI, usePaginationFetch } from 'hooks';
import { useExchangeAPI } from 'hooks/useExchangeAPI';

import ClaimMessage from './ClaimMessage';
import { useAppContext } from 'contexts/AppContext';
import { Row } from 'theme/globalStyledComponents/row';

import { BalanceOpsType, DepositOrWithdrawalStatus, QueryKeys } from 'enums';
import { ProfileBalanceOps } from 'interfaces';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Container = styled(Row)`
  margin: 5px 10px;
  padding: 10px;
  border-radius: 8px;
  justify-content: center;
  gap: 40px;
  background: ${({ theme }) => theme.colors.positiveBackground500};
`;

export const BalanceOpsClaimableStatuses = [
  DepositOrWithdrawalStatus.Claimable,
  DepositOrWithdrawalStatus.Claiming,
];

const ClaimableWithdrawal = () => {
  const {
    store: {
      account: { frontendSecrets, _balanceOperations },
    },
  } = useAppContext();
  const { fetchPrivateTransfers } = useBalanceOperationsAPI();
  const { isOnboarding } = useExchangeAPI();

  // using PaginationFetch hook to fetch claimable transfers, so that we can use socket source to listen to new claimable transfers or status updates
  const { isLoading, data: claimableTransfers } =
    usePaginationFetch<ProfileBalanceOps>({
      fetchFn: fetchPrivateTransfers,
      socketSource: _balanceOperations,
      currentAuthenticatedWallet: frontendSecrets?.profile?.wallet,
      requestQueryParams: {
        ops_type: BalanceOpsType.Withdrawal,
        status: BalanceOpsClaimableStatuses,
      },
      isOnboarding,
      queryKey: QueryKeys.Transfers,
    });

  // Socket Source Might Contain new balance operations with irrelavant status, so we filter again
  let filteredClaimableTransfers = claimableTransfers.filter(t =>
    BalanceOpsClaimableStatuses.includes(t.status),
  );

  if (!filteredClaimableTransfers || !frontendSecrets || isLoading) return null;

  const balanceOp = filteredClaimableTransfers?.[0];

  if (!balanceOp) return null;

  return (
    <Container>
      <ClaimMessage
        key={balanceOp.id}
        opsStatus={balanceOp.status}
        opsId={balanceOp.id}
      />
    </Container>
  );
};

export default observer(ClaimableWithdrawal);
