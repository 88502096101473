import { Slider } from '@material-ui/core';

import { RiskLevel } from './utils';

import { Color } from 'interfaces';
import styled from 'styled-components';

export const riskToColorMap: Record<RiskLevel, Color> = {
  [RiskLevel.UNKNOWN]: 'shadesForeground200',
  [RiskLevel.LOW]: 'tertiaryGradientsLowLeverageRisk',
  [RiskLevel.MID]: 'tertiaryGradientsMediumLeverageRisk',
  [RiskLevel.HIGH]: 'tertiaryGradientsHighLeverageRisk',
};

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;

  gap: 10px;
  max-height: 23px;

  .labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .value {
    padding: 5px 10px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.shadesBackground700};
  }
`;

export const MarketLeverageSliderStyled = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;

  .MuiSlider-root {
    margin-right: 8px;
  }
`;

export const StyledSlider = styled(Slider)`
  width: calc(100% - 8px);
  margin-top: -5px;

  .MuiSlider-markLabel {
    display: none;
  }

  .MuiSlider-mark {
    display: none;
  }

  & .MuiSlider-rail {
    opacity: 0.5;
  }

  & .MuiSlider-thumb {
    border: 1px solid ${({ theme }) => theme.colors.shadesForeground300};
    background: 1px solid ${({ theme }) => theme.colors.shadesForeground400};
  }

  & .muislider-mark {
    &.MuiSlider-markActive {
      opacity: 1;
    }
  }

  .MuiSlider-valueLabel {
    display: none;
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px
      ${({ theme }) => theme.colors.shadesForeground300}34;
  }

  .MuiSlider-track {
    margin-top: 2px;
    height: 3px;
    background: ${({ theme }) => theme.colors.positiveForeground200};
  }

  .MuiSlider-rail {
    margin-top: 2.5px;
    height: 3px;
  }
`;
