import { Icon } from 'components';
import { SelectDropdown } from 'components/SelectDropdown.tsx';
import Text from 'components/Text';

import { DropdownSelect } from './styles';
import caretDownIcon from 'assets/icons/chevronDown.svg';

import i18next from 'i18next';

export type TimeOptions = '1w' | '1m' | 'all';

export const options: TimeOptions[] = ['1w', '1m', 'all'];

const timeOptionToText = (timeOption: TimeOptions) => {
  switch (timeOption) {
    case '1w':
      return i18next.t('thisWeek');
    case '1m':
      return i18next.t('thisMonth');
    case 'all':
      return i18next.t('allTime');
    default:
      return i18next.t('thisMonth');
  }
};

type Props = {
  selectedTimeframe: TimeOptions;
  setSelectedTimeframe: (time: TimeOptions) => void;
};
const TimeframeSelectDropdown = ({
  selectedTimeframe,
  setSelectedTimeframe,
}: Props) => {
  return (
    <SelectDropdown
      gtmIdGenerator={timeframe => `option-leaderboard-timeframe-${timeframe}`}
      options={options}
      onOptionSelect={setSelectedTimeframe}
      displayTextParser={timeOptionToText}
      customStyles={{ top: '50px' }}
      InputElement={
        <DropdownSelect>
          <Text variant="BODY_L" color="shadesForeground200">
            {timeOptionToText(selectedTimeframe)}
          </Text>
          <Icon src={caretDownIcon} alt="caret down" />
        </DropdownSelect>
      }
      isOptionSelected={option => option === selectedTimeframe}
    />
  );
};

export default TimeframeSelectDropdown;
