import { useRef } from 'react';

import { showNotification } from 'utils';
import { brand, isBrandRabbitX } from 'utils/brand';
import { useGetMarketLogoFromMarketId } from 'utils/marketLogo';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';

import { FormattedNumber } from 'components/FormattedNumber';
import Modal from 'components/Modal';

import { getMarketPosition } from '../stopLossTakeProfitCell';
import ReferralCode from './ReferralCode';
import {
  BackgroundContainer,
  Container,
  Footer,
  Header,
  PositionStats,
  TradeSideLabel,
} from './styles';
import clipboardIcon from 'assets/icons/copy-white.svg';
import downloadIcon from 'assets/icons/download-white.svg';
import telegramIcon from 'assets/icons/socials/telegram-white.svg';
import twitterIcon from 'assets/icons/socials/twitter-white.svg';
import sharePnlBackgroundBfxImage from 'assets/images/share-pnl-background-bfx.png';
import sharePnlBackgroundRabbitxImage from 'assets/images/share-pnl-background-rabbitx.png';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { ColoredText, Colors } from 'pages/Trade/components/shared';

import { NotificationType, TradeSide } from 'enums';
import { toPng, toBlob } from 'html-to-image';
import { observer } from 'mobx-react';

const sharePnlBackgroundImage = isBrandRabbitX
  ? sharePnlBackgroundRabbitxImage
  : sharePnlBackgroundBfxImage;

/**
 * Calculates the percentage pnl of a position
 * @param pnl - unrealized pnl
 * @param notional - position notional
 * @param marketLeverage  - market leverage
 * @returns
 */
const calculatePnlPercentage = (
  pnl: number,
  notional: number,
  marketLeverage: number | 1,
) => {
  return (pnl / notional) * marketLeverage * 100;
};

const openTwitterWithText = (percentage: number, marketId: string) => {
  const baseTwitterURL = 'https://twitter.com/intent/tweet?text=';
  const tweetText = `💰 Check out my latest trade on ${
    brand.appShortName
  }. I made ${percentage.toFixed(
    2,
  )}% on ${marketId}. The crypto market never sleeps, and neither do I! Get the best trader UI and lowest fees at ${
    brand.appUrl
  }`;
  const encodedText = encodeURIComponent(tweetText);
  const fullURL = baseTwitterURL + encodedText;

  window.open(fullURL, '_blank');
};

const openTelegramWithText = (percentage: number, marketId: string) => {
  const baseTelegramURL = 'https://t.me/share/url?url=';
  const tweetText = `💰 Check out my latest trade on ${
    brand.appShortName
  }. I made ${percentage.toFixed(
    2,
  )}% on ${marketId}. The crypto market never sleeps, and neither do I! Get the best trader UI and lowest fees at ${
    brand.appUrl
  }`;
  const encodedText = encodeURIComponent(tweetText);
  const fullURL = baseTelegramURL + encodedText;

  window.open(fullURL, '_blank');
};

/**
 * Filter out html elements that should not be included in the image
 */
const htmlImageElementsFilter = (node: HTMLElement) => {
  const exclusionClasses = ['close-icon'];
  return !exclusionClasses.some(classname =>
    node.classList?.contains(classname),
  );
};

type Props = {
  marketId: string;
};
const SharePnlModal = ({ marketId }: Props) => {
  const {
    store: { account: accountStore },
  } = useAppContext();
  const ref = useRef<HTMLDivElement>(null);

  const position = getMarketPosition(marketId, accountStore._positions);
  const marketLogo = useGetMarketLogoFromMarketId(marketId);

  const decimalPlaces = useGetDecimalPlaces(marketId);

  if (!position) return null;

  const createPng = async (): Promise<any> => {
    try {
      if (!ref.current) return;
      const htmlElement = ref.current;
      htmlElement.style.borderRadius = '0px';
      const dataUrl = await toPng(htmlElement, {
        filter: htmlImageElementsFilter,
      });
      htmlElement.style.borderRadius = '20px';
      return dataUrl;
    } catch (err) {
      console.error('Failed to capture component:', err);
      throw err;
    }
  };

  const downloadImage = async () => {
    try {
      const image = await createPng();
      const link = document.createElement('a');
      link.download = `${marketId}-pnl.png`;
      link.href = image;
      link.click();
      showNotification({
        type: NotificationType.Positive,
        title: 'P&L Image Downloaded',
      });
    } catch (e) {
      console.error(e);
      showNotification({
        type: NotificationType.Negative,
        title: 'Failed to Download Image',
      });
    }
  };

  const copyToClipboard = async (): Promise<any> => {
    try {
      if (!ref.current) return;

      const htmlElement = ref.current;
      htmlElement.style.borderRadius = '0px';
      const blob = await toBlob(htmlElement, {
        cacheBust: true,
        filter: htmlImageElementsFilter,
      });
      htmlElement.style.borderRadius = '20px';

      if (!blob) return;

      const data = [new ClipboardItem({ 'image/png': blob })];

      navigator.clipboard.write(data);
      showNotification({
        type: NotificationType.Positive,
        title: 'PnL Image Copied to Clipboard',
      });
    } catch (e) {
      console.error(e);
      showNotification({
        type: NotificationType.Negative,
        title: 'Failed to Copy to Clipboard',
      });
    }
  };

  const marketLeverage = accountStore.getMarketLeverage(marketId);
  const pnlPercentage = calculatePnlPercentage(
    position.unrealizedPnl,
    position.notional,
    Number(marketLeverage),
  );

  return (
    <Modal
      showHeader={false}
      showCloseIcon={true}
      size={'custom'}
      name={Modals.sharePnlModal}
      overflow={'visible'}
      padding={'0px'}
    >
      <BackgroundContainer backgroundImage={sharePnlBackgroundImage} ref={ref}>
        <Container>
          <Header>
            <img
              src={brand.logo}
              alt={`${brand.appShortName}-logo`}
              className="logo"
            />
            <span className="headline">{brand.tagline}</span>
          </Header>

          <PositionStats>
            <div className="market-id">
              <img src={marketLogo} alt="market logo" />
              <span>{marketId}</span>
            </div>
            <div className="trade-side">
              <TradeSideLabel isSuccess={position.side === TradeSide.LONG}>
                {position.side}
              </TradeSideLabel>
              <div className="separator"></div>
              <span className="leverage"> {marketLeverage}x</span>
            </div>
            <div className="position-pnl">
              <ColoredText
                color={
                  position.unrealizedPnl < 0 ? Colors.Danger : Colors.Success
                }
              >
                <FormattedNumber
                  prefix={position.unrealizedPnl < 0 ? '-' : '+'}
                  value={pnlPercentage}
                  suffix="%"
                  allowNegative={false}
                />
              </ColoredText>
            </div>
            <div className="prices-columns">
              <div className="column">
                <span>Entry Price</span>
                <FormattedNumber
                  value={position.avgEntryPrice}
                  decimalScale={decimalPlaces.price}
                />
              </div>
              <div className="column">
                <span>Current Price</span>
                <FormattedNumber
                  value={position.fairPrice}
                  decimalScale={decimalPlaces.price}
                />
              </div>
            </div>
          </PositionStats>
          <ReferralCode />
        </Container>
        <Footer>
          <div
            className="column"
            onClick={() => {
              openTwitterWithText(pnlPercentage, marketId);
            }}
          >
            <div className="icon-circle">
              <img src={twitterIcon} alt="twitter" />
            </div>
            <span>Twitter</span>
          </div>
          <div
            className="column"
            onClick={() => {
              openTelegramWithText(pnlPercentage, marketId);
            }}
          >
            <div className="icon-circle">
              <img src={telegramIcon} alt="telegram" />
            </div>
            <span>Telegram</span>
          </div>
          <div className="column" onClick={copyToClipboard}>
            <div className="icon-circle">
              <img src={clipboardIcon} alt="clipboard" />
            </div>
            <span>Clipboard</span>
          </div>
          <div className="column" onClick={downloadImage}>
            <div className="icon-circle">
              <img src={downloadIcon} alt="download" />
            </div>
            <span>Download</span>
          </div>
        </Footer>
      </BackgroundContainer>
    </Modal>
  );
};

export default observer(SharePnlModal);
