import { useEffect, useState } from 'react';

import { DefaultEventsMap } from '@socket.io/component-emitter';

import {
  IBinanceCheckoutData,
  BINANCE_PAY_SERVICE_BASE_URL,
} from 'hooks/useBinancePayApi';

import Steps from 'components/ProgressiveSteps';
import Text from 'components/Text';

import { BINANCE_ORDER_STATUS } from './binanceOrderStatus';
import { CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT } from 'constants/general';

import { Socket, io } from 'socket.io-client';

type Props = {
  data: IBinanceCheckoutData | undefined;
  processDepositOnIndividualContract: (hash: string, amount: number) => void;
  confirmations: number;
  isDepositConfirmed: boolean;
};

const getCurrentStepIndex = (
  orderStatus: BINANCE_ORDER_STATUS,
  isDepositConfirmed: boolean,
) =>
  orderStatus === BINANCE_ORDER_STATUS.WAITING_FOR_PAYMENT
    ? 1
    : [
        BINANCE_ORDER_STATUS.RECEIVED_ON_BINANCE,
        BINANCE_ORDER_STATUS.CONTRACT_CALLED,
      ].includes(orderStatus)
    ? 2
    : isDepositConfirmed
    ? 3
    : 0;

export const BinanceOrderCheckout = ({
  data,
  processDepositOnIndividualContract,
  confirmations,
  isDepositConfirmed,
}: Props) => {
  const [orderState, setOrderStatus] = useState<{
    status: BINANCE_ORDER_STATUS;
    txHash?: string;
  }>({ status: BINANCE_ORDER_STATUS.WAITING_FOR_PAYMENT });

  const bizId = data?.prepayId;

  const handleTransferUpdate = (
    data: {
      code: BINANCE_ORDER_STATUS;
      tx_hash?: string;
      amount?: number;
    },
    socket: Socket<DefaultEventsMap, DefaultEventsMap>,
  ) => {
    if (data.code) {
      if (
        [
          BINANCE_ORDER_STATUS.CANCELLED,
          BINANCE_ORDER_STATUS.RECEIVED_ON_BINANCE,
          BINANCE_ORDER_STATUS.CONTRACT_CALLED,
          BINANCE_ORDER_STATUS.CONTRACT_CALL_FAILED,
        ].includes(data.code)
      ) {
        setOrderStatus({
          status: data.code,
          txHash: data.tx_hash,
        });

        // Disconned on any other than received on binance as we dont need to track anymore
        if (data.code !== BINANCE_ORDER_STATUS.RECEIVED_ON_BINANCE) {
          socket.disconnect();
        }

        // if all conditions are met, call the process funciton
        if (
          data.code === BINANCE_ORDER_STATUS.CONTRACT_CALLED &&
          data.tx_hash &&
          data.amount
        ) {
          processDepositOnIndividualContract(data.tx_hash, data.amount);
        }
      }
    }
  };

  useEffect(() => {
    if (bizId) {
      const socket = io(BINANCE_PAY_SERVICE_BASE_URL, {
        transports: ['websocket'],
      });

      // client-side
      socket.on('connect', () => {
        socket.emit('join', bizId);
      });

      socket.on('transfer_update', payload =>
        handleTransferUpdate(payload, socket),
      );
    }
  }, [bizId]);

  if (!data)
    return (
      <Text color="primaryRedForeground200">
        Something went wrong. Please try again.
      </Text>
    );

  const checkoutUrl = data.checkoutUrl;
  const prepayId = data.prepayId;

  if (orderState.status === BINANCE_ORDER_STATUS.CONTRACT_CALL_FAILED) {
    return (
      <Text
        color="shadesForeground200"
        variant="BODY_S"
        className="align-center"
      >
        Unable to process your transaction!
        <br />
        <br />
        We were unable to your Binance Pay order with id :{' '}
        <Text color="white">{prepayId}</Text>. Please contact support.
      </Text>
    );
  }

  if (orderState.status === BINANCE_ORDER_STATUS.CANCELLED) {
    return (
      <Text
        color="shadesForeground200"
        variant="BODY_S"
        className="align-center"
      >
        Order Timed Out!
        <br />
        <br />
        Binance Pay order created with id :{' '}
        <Text color="white">{prepayId}</Text> was timed out due to inactivity.{' '}
      </Text>
    );
  }

  const currentStepIndex = getCurrentStepIndex(
    orderState.status,
    isDepositConfirmed,
  );

  return (
    <Steps
      steps={[
        {
          title: <>Initiated Binance Pay transfer</>,
          description: `Order id : ${bizId}`,
        },
        {
          title: <>Awaiting payment on Binance</>,
          description: (
            <Text underline>
              <a href={checkoutUrl} target="_blank" rel="noreferrer">
                Click here to checkout.
              </a>
            </Text>
          ),
        },
        {
          title: <>Waiting for on-chain confirmations</>,
          description: `(${
            isDepositConfirmed
              ? CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT
              : confirmations
          }/${CONFIRMATIONS_REQUIRED_TO_CONFIRM_DEPOSIT} deposit confirmations)`,
        },
      ]}
      currentStepIndex={currentStepIndex}
      forceShowDescription
    />
  );
};
