import { useEffect, useRef, useState } from 'react';

import { saveUserSetting, showNotification } from 'utils';
import { brandedSelect } from 'utils/brand';

import useModal from 'hooks/useModal';
import { useReferralsAPI } from 'hooks/useReferralsAPI';

import { navigateToVaultAndStakeNow } from 'components/DepositBonusModal';
import Text from 'components/Text';

import StepTitleAndActions from './StepDisplayLayout';
import StepIndicator from './StepIndicator';
import { Step, getNextStep, getStepTitleDescriptionIcon } from './utils';
import { SHOULD_DISABLE_DEPOSITS_WITHDRAWALS } from 'constants/general';
import { Modals } from 'constants/modals';
import { useAppContext } from 'contexts/AppContext';
import { WalletModal } from 'pages/Layout/shared/WalletModal/WalletModal';
import { shortCodeToUrl } from 'pages/Portfolio/Pages/ReferralDashboard/InviteFriends/InviteFriendsModal';
import NewDepositModal from 'pages/Trade/components/AccountStats/NewDepositModal';
import { routes } from 'pages/routes';
import RealisticConfetti from 'react-canvas-confetti/dist/presets/realistic';
import { Column } from 'theme/globalStyledComponents/column';
import { Row } from 'theme/globalStyledComponents/row';

import { NotificationType, QueryKeys, UserSettings } from 'enums';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const ALL_STEPS = [
  Step.CONNECT_WALLET,
  Step.DEPOSIT_FUNDS,
  Step.STAKE_INTO_VAULT,
  Step.REFER_FRIENDS,
];

const Container = styled(Column)`
  gap: 15px;
  padding: 20px 15px;
  width: 325px;
  position: fixed;
  bottom: 40px;
  left: 15px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.shadesBackground800};
  /* box-shadow: 2px 2px 40px 0px rgba(23, 23, 27, 0.8); */
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground700};

  z-index: 10;

  .steps {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
    width: 100%;
    box-sizing: border-box;
    gap: 5px;
    background: ${({ theme }) => theme.colors.shadesBackground900};
    border-radius: 8px;

    .seperator {
      display: flex;

      background: ${({ theme }) => theme.colors.shadesBackground700};
      height: 1px;
      align-self: stretch;
      margin: 0px 10px;
    }

    .confetti {
      position: absolute;

      width: 200%;
      height: 80vh;
      /* bottom:-100px; */
      overflow: visible;
    }
  }

  .block-container {
    display: flex;
    align-items: center;
    align-self: stretch;
    cursor: pointer;

    :hover {
      .block {
        background: ${({ theme }) => theme.colors.shadesForeground400};
      }
    }
  }

  .block {
    width: 18px;
    height: 2px;
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

const OnboardingSteps = () => {
  const [currentStep, setCurrentStep] = useState<Step | undefined>(
    Step.DEPOSIT_FUNDS,
  );
  const navigate = useNavigate();
  const {
    store: {
      account: { frontendSecrets },
      appState: { setWentThroughtGetStarted },
    },
  } = useAppContext();
  const modal = useModal();
  const { t } = useTranslation();

  const [isAboutToHide, setIsAboutToHide] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<Step[]>([]);

  const { fetchUserReferral } = useReferralsAPI();
  const stakeInitiated = useRef(false);

  const {
    isLoading: isLoadingUserReferral,
    data: userReferral,
    isError: isErrorUserReferral,
  } = useQuery(
    [QueryKeys.UserReferral],
    async () => await fetchUserReferral(),
    {
      // 60 seconds
      refetchInterval: 60_000,
      enabled: !!frontendSecrets?.jwt,
    },
  );

  useEffect(() => {
    if (isAboutToHide) return;

    // -1 to take into account the connect step which is mandatory
    if (completedSteps.length < ALL_STEPS.length - 1) return;

    setIsAboutToHide(true);
    setTimeout(() => {
      hideAndNeverShowAgain();
    }, 4000);
  }, [completedSteps, isAboutToHide]);

  const onCtaPress = () => {
    if (currentStepWithWalletIntoAccount === Step.CONNECT_WALLET) {
      navigate(routes.trade.getRedirectPath());
      modal.present(<WalletModal />, Modals.walletModal);

      return;
    }

    if (currentStepWithWalletIntoAccount === Step.DEPOSIT_FUNDS) {
      if (SHOULD_DISABLE_DEPOSITS_WITHDRAWALS) return;
      modal.present(<NewDepositModal />, Modals.depositModal);
      markStepAsCompleted(Step.DEPOSIT_FUNDS);
    }

    if (currentStepWithWalletIntoAccount === Step.STAKE_INTO_VAULT) {
      stakeInitiated.current = true;
      navigateToVaultAndStakeNow(navigate);
      markStepAsCompleted(Step.STAKE_INTO_VAULT);
    }

    if (currentStepWithWalletIntoAccount === Step.REFER_FRIENDS) {
      navigate(routes.portfolio.referrals.getRedirectPath());
      if (!userReferral) return;
      navigator.clipboard
        .writeText(shortCodeToUrl(userReferral.shortCode))
        .then(() => {
          showNotification({
            type: NotificationType.Positive,
            title: t('referralLinkCopiedToClipboard'),
          });
        })
        .catch(error => {
          console.error('ERROR COPYING CODE', error);
          showNotification({
            type: NotificationType.Negative,
            title: 'Failed to Copy to Clipboard',
          });
        });
      markStepAsCompleted(Step.REFER_FRIENDS);
    }
  };

  const markStepAsCompleted = (step: Step) => {
    const newSteps = [...completedSteps, step];
    if (!completedSteps.includes(step)) {
      setCompletedSteps(newSteps);
    }

    setCurrentStep(getNextStep(newSteps));
  };

  const hideAndNeverShowAgain = () => setWentThroughtGetStarted(true);

  const isStakeModalOpen = modal.activeModals.find(
    ({ name }) =>
      name ===
      brandedSelect({
        rabbitx: Modals.amendLiquidityModal,
        bfx: Modals.amendPlatformVaultLiquidityModal,
      }),
  );

  const isStakeLoading = !isStakeModalOpen && stakeInitiated.current;

  if (isStakeModalOpen && stakeInitiated.current)
    stakeInitiated.current = false;

  const currentStepWithWalletIntoAccount = stakeInitiated.current
    ? Step.STAKE_INTO_VAULT
    : frontendSecrets
    ? currentStep
    : Step.CONNECT_WALLET;

  const progress = frontendSecrets
    ? completedSteps.length + 1
    : completedSteps.length;

  return (
    <Container>
      <Row justify="space-between" className="w-full">
        <Text variant="BODY_L" fontWeight="semiBold">
          {t('getStarted')}
        </Text>

        <div className="block-container" onClick={hideAndNeverShowAgain}>
          <div className="block" />
        </div>
      </Row>

      <StepIndicator progress={progress} animateLastStep={isAboutToHide} />

      <div className="steps">
        {ALL_STEPS.map((step, index) => {
          const { title, description, icon, ctaLabel } =
            getStepTitleDescriptionIcon(step, t);

          const isCtaLoading =
            step === Step.REFER_FRIENDS
              ? isLoadingUserReferral || isErrorUserReferral
              : step === Step.STAKE_INTO_VAULT
              ? isStakeLoading
              : false;

          const isStepCompleted =
            step === Step.CONNECT_WALLET
              ? !!frontendSecrets
              : completedSteps.includes(step);

          return (
            <>
              <StepTitleAndActions
                onClick={() => setCurrentStep(step)}
                isCollapsed={currentStepWithWalletIntoAccount !== step}
                icon={icon}
                title={title}
                description={description}
                ctaLabel={ctaLabel}
                onCtaPress={onCtaPress}
                completed={isStepCompleted}
                onSkipStep={() => {
                  markStepAsCompleted(step);
                }}
                showSkip={step !== Step.CONNECT_WALLET}
                isCtaLoading={isCtaLoading}
                key={step}
              />

              {index + 1 < ALL_STEPS.length ? (
                <div className="seperator" />
              ) : null}
            </>
          );
        })}

        {isAboutToHide ? (
          <RealisticConfetti
            autorun={{ duration: 1000, speed: 0.5 }}
            className="confetti"
            decorateOptions={options => ({ ...options, startVelocity: 30 })}
          />
        ) : null}
      </div>

      <Text
        variant="BODY_S"
        color="shadesForeground200"
        hoveredColor="white"
        onClick={hideAndNeverShowAgain}
        cursorPointer
      >
        {t('dontShowMeThisAgain')}
      </Text>
    </Container>
  );
};

export default observer(OnboardingSteps);
