import { useState } from 'react';

import { getCurrencyFromTradingPair } from 'utils';

import useModal from 'hooks/useModal';

import { Icon } from 'components';
import BaseLoading from 'components/Loading';
import AmendOrderModal from 'components/Tables/OrdersTable/AmendOrderModal';
import { orderTypeToLabel } from 'components/Tables/OrdersTable/OrdersRow';
import Text from 'components/Text';

import editPenGrayIcon from 'assets/icons/edit-pen.svg';
import errorCircle from 'assets/icons/error-circle.svg';
import { Modals } from 'constants/modals';

import { TradeSide } from 'enums';
import { Order } from 'interfaces';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const RelativeParent = styled.div<{ side: TradeSide }>`
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    ${({ side }) =>
      side === TradeSide.SHORT
        ? css`
            top: 105%;
          `
        : css`
            bottom: 105%;
          `}

    left: -10px;
    justify-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 8px;
    position: absolute;

    background: ${({ theme }) => theme.colors.shadesBackground800};
    border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
    border-radius: 6px;

    z-index: 8;
  }
`;

const Loading = styled(BaseLoading)`
  height: 12px;
  width: 12px;

  .loading-icon {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }
`;

/* 
 This is used to check even if one of order is currently cancelling, then we need not let user cancel again, until it is done
*/
const checkIsCancellingSome = (
  orders: Order[],
  currentlyLoadingIds: string[],
) => {
  let found = false;

  for (const order of orders) {
    if (currentlyLoadingIds.includes(order.id)) {
      found = true;
      break;
    }
  }

  return found;
};
const MultiOrderActionIcon = ({
  type,
  orders,
  onOrderClick,
  onIconClick,
  currentlyLoadingIds = [],
}: {
  type: 'CANCEL' | 'EDIT';
  onOrderClick: (o: Order) => void;
  onIconClick?: () => void;
  orders: Order[];
  currentlyLoadingIds?: string[];
}) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const isSingleOrder = orders.length === 1;

  const isCancellingAny = isSingleOrder
    ? currentlyLoadingIds.includes(orders[0].id)
    : checkIsCancellingSome(orders, currentlyLoadingIds);

  const typeIcon = type === 'CANCEL' ? errorCircle : editPenGrayIcon;
  return (
    <RelativeParent
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      side={orders[0].side}
    >
      {isSingleOrder && isCancellingAny ? (
        <Loading size="12px" />
      ) : (
        <Icon
          src={typeIcon}
          size="S"
          onClick={e => {
            e.stopPropagation();
            if (isCancellingAny) return;
            onIconClick?.();
          }}
        />
      )}

      {isSingleOrder ? null : isHovered ? (
        <>
          <div className="content">
            {orders.map(o => {
              const isCancellingCurrentOrder = currentlyLoadingIds.includes(
                o.id,
              );
              return (
                <Text
                  variant="BODY_XS"
                  flexed
                  gap={5}
                  color="shadesForeground200"
                  noWrap
                  onClick={e => {
                    e.stopPropagation();
                    if (isCancellingCurrentOrder) return;
                    onOrderClick(o);
                  }}
                  hoveredColor="white"
                >
                  {isCancellingCurrentOrder ? (
                    <Loading size="12px" />
                  ) : (
                    <Icon size="S" src={typeIcon} />
                  )}
                  {o.totalFilledSize} | {o.initialSize}
                  {'  '}({orderTypeToLabel(o.type, t)})
                </Text>
              );
            })}
          </div>
        </>
      ) : null}
    </RelativeParent>
  );
};

export const EditOrdersIcon = ({ orders }: { orders: Order[] }) => {
  const modal = useModal();

  const onEditOrder = (order: Order) => {
    modal.present(
      <AmendOrderModal
        order={order}
        currency={getCurrencyFromTradingPair(order.marketID)}
      />,
      Modals.amendOrderModal,
    );
  };

  return (
    <MultiOrderActionIcon
      orders={orders}
      onOrderClick={onEditOrder}
      type="EDIT"
      onIconClick={() => {
        if (orders.length === 1) onEditOrder(orders[0]);
      }} // if is single order, then on main icon click, open modal on icon click as per very first order in array
    />
  );
};

export const CancelOrdersIcon = ({
  orders,
  handleCancelOrder,
  cancelAllOrders,
  currentlyCancellingOrderIds,
}: {
  orders: Order[];
  handleCancelOrder: (o: Order) => void;
  cancelAllOrders: () => void;
  currentlyCancellingOrderIds: string[];
}) => {
  return (
    <MultiOrderActionIcon
      orders={orders}
      onOrderClick={handleCancelOrder}
      onIconClick={cancelAllOrders}
      type="CANCEL"
      currentlyLoadingIds={currentlyCancellingOrderIds}
    />
  );
};
