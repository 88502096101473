import { Icon } from 'components';
import ShimmingRainbow from 'components/ShimmingRainbow';
import Text from 'components/Text';

import blastBlackIcon from 'assets/assetLogos/blast.svg';

import styled from 'styled-components';

const Container = styled.div`
  .text {
    margin-right: 4px;
    width: 28px;
  }
`;

const RewardsBadge = ({ multiplier }: { multiplier: number }) => {
  return (
    <Container>
      <ShimmingRainbow>
        <Text flexed gap={1} className="text" variant="BODY_XS" color="white">
          <Icon src={blastBlackIcon} size="SM" rounded />
          {multiplier}X
        </Text>
      </ShimmingRainbow>
    </Container>
  );
};

export default RewardsBadge;
