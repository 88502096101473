import { brand, isBrandBfx, isBrandRabbitX } from 'utils/brand';

import leaderboardIconActiveBlack from 'assets/icons/portfolio-nav-items/leaderboards-active-black.svg';
import leaderboardIconActive from 'assets/icons/portfolio-nav-items/leaderboards-active.svg';
import leaderboardIconDefault from 'assets/icons/portfolio-nav-items/leaderboards-default.svg';
import migrateIconActiveBlack from 'assets/icons/portfolio-nav-items/migrate-active-black.svg';
import migrateIconActive from 'assets/icons/portfolio-nav-items/migrate-active.svg';
import migrateIconDefault from 'assets/icons/portfolio-nav-items/migrate-default.svg';
import ordersIconActiveBlack from 'assets/icons/portfolio-nav-items/orders-active-black.svg';
import ordersIconActive from 'assets/icons/portfolio-nav-items/orders-active.svg';
import ordersIconDefault from 'assets/icons/portfolio-nav-items/orders-default.svg';
import overviewIconActiveBlack from 'assets/icons/portfolio-nav-items/overview-active-black.svg';
import overviewIconActive from 'assets/icons/portfolio-nav-items/overview-active.svg';
import overviewIconDefault from 'assets/icons/portfolio-nav-items/overview-default.svg';
import positionsIconActiveBlack from 'assets/icons/portfolio-nav-items/positions-active-black.svg';
import positionsIconActive from 'assets/icons/portfolio-nav-items/positions-active.svg';
import positionsIconDefault from 'assets/icons/portfolio-nav-items/positions-default.svg';
import referralsIconActiveBlack from 'assets/icons/portfolio-nav-items/referrals-active-black.svg';
import referralsIconActive from 'assets/icons/portfolio-nav-items/referrals-active.svg';
import referralsIconDefault from 'assets/icons/portfolio-nav-items/referrals-default.svg';
import settlementsIconActiveBlack from 'assets/icons/portfolio-nav-items/settlements-active-black.svg';
import settlementsIconActive from 'assets/icons/portfolio-nav-items/settlements-active.svg';
import settlementsIconDefault from 'assets/icons/portfolio-nav-items/settlements-default.svg';
import tradeHistoryIconActiveBlack from 'assets/icons/portfolio-nav-items/trades-active-black.svg';
import tradeHistoryIconActive from 'assets/icons/portfolio-nav-items/trades-active.svg';
import tradeHistoryIconDefault from 'assets/icons/portfolio-nav-items/trades-default.svg';
import transfersIconActiveBlack from 'assets/icons/portfolio-nav-items/transfers-active-black.svg';
import transfersIconActive from 'assets/icons/portfolio-nav-items/transfers-active.svg';
import transfersIconDefault from 'assets/icons/portfolio-nav-items/transfers-default.svg';
import { routes } from 'pages/routes';

import { ComponentsName } from 'enums';

export type SidebarNavItem = {
  to: string;
  text: string;
  icon: {
    active: string;
    default: string;
  };
  componentName: ComponentsName;
};

export const getPortfolioMenuItems = (t: any): SidebarNavItem[] => {
  const menuItems = [
    {
      to: routes.portfolio.overview.getRoutePath(),
      text: t('menu.portfolioOverview'),
      icon: {
        active: isBrandRabbitX ? overviewIconActive : overviewIconActiveBlack,
        default: overviewIconDefault,
      },
      componentName: ComponentsName.PortfolioOverview,
    },
    {
      to: routes.portfolio.trades.getRoutePath(),
      text: t('menu.portfolioTradeHistory'),
      icon: {
        active: isBrandRabbitX
          ? tradeHistoryIconActive
          : tradeHistoryIconActiveBlack,
        default: tradeHistoryIconDefault,
      },
      componentName: ComponentsName.PortfolioTrades,
    },
    {
      to: routes.portfolio.positions.getRoutePath(),
      text: t('menu.portfolioPositions'),
      icon: {
        active: isBrandRabbitX ? positionsIconActive : positionsIconActiveBlack,
        default: positionsIconDefault,
      },
      componentName: ComponentsName.PortfolioPositions,
    },
    {
      to: routes.portfolio.orders.getRoutePath(),
      text: t('menu.portfolioOrders'),
      icon: {
        active: isBrandRabbitX ? ordersIconActive : ordersIconActiveBlack,
        default: ordersIconDefault,
      },
      componentName: ComponentsName.PortfolioOrders,
    },
    {
      to: routes.portfolio.transfers.getRoutePath(),
      text: t('menu.portfolioTransfers'),
      icon: {
        active: isBrandRabbitX ? transfersIconActive : transfersIconActiveBlack,
        default: transfersIconDefault,
      },
      componentName: ComponentsName.PortfolioTransfers,
    },
    {
      to: routes.portfolio.settlements.getRoutePath(),
      text: t('menu.portfolioSettlement'),
      icon: {
        active: isBrandRabbitX
          ? settlementsIconActive
          : settlementsIconActiveBlack,
        default: settlementsIconDefault,
      },
      componentName: ComponentsName.PortfolioSettlements,
    },
    {
      to: routes.portfolio.referrals.getRoutePath(),
      text: t('referrals'),
      icon: {
        active: isBrandRabbitX ? referralsIconActive : referralsIconActiveBlack,
        default: referralsIconDefault,
      },
      componentName: ComponentsName.PortfolioReferrals,
    },
    {
      to: routes.portfolio.referralFees.getRoutePath(),
      text: t('referralFees'),
      icon: {
        active: isBrandRabbitX ? referralsIconActive : referralsIconActiveBlack,
        default: referralsIconDefault,
      },
      componentName: ComponentsName.PortfolioReferralFees,
    },
  ];

  // // Leaderboard
  // if (isBrandBfx) {
  //   menuItems.splice(1, 0, {
  //     to: routes.portfolio.leaderboard.getRoutePath(),
  //     text: 'Leaderboard',
  //     icon: {
  //       active: isBrandRabbitX
  //         ? leaderboardIconActive
  //         : leaderboardIconActiveBlack,
  //       default: leaderboardIconDefault,
  //     },
  //     componentName: ComponentsName.PortfolioReferrals,
  //   });
  // }

  if (brand.featureFlags.migrateStrp) {
    menuItems.push({
      to: routes.portfolio.migrate.getRoutePath(),
      text: t('menu.portfolioMigrateStrp'),
      icon: {
        active: isBrandRabbitX ? migrateIconActive : migrateIconActiveBlack,
        default: migrateIconDefault,
      },
      componentName: ComponentsName.PortfolioMigrate,
    });
  }

  return menuItems;
};
