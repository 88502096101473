import { ForwardedRef, HTMLAttributes, forwardRef } from 'react';

import { TextVariant } from './variant';
import { TScreenSize } from 'theme/Theme';

import { Theme } from 'interfaces';
import styled, { css } from 'styled-components';

type Colors = Theme['colors'];
type FontWeights = Theme['fontWeights'];

type TResponsiveVariants = {
  [key in TScreenSize]?: keyof typeof TextVariant;
};

type StyledSpanProps = {
  color?: keyof Colors;
  hoveredColor?: keyof Colors;
  fontWeight?: keyof FontWeights;
  preWrap: boolean;
  noWrap: boolean;
  flexed: boolean;
  gap?: number;
  cursorPointer?: boolean;
  variant?: keyof typeof TextVariant;
  responsiveVariant?: TResponsiveVariants;
  avoidTransition?: boolean;
  numOfLines?: number;
  underline?: boolean;
};
const StyledSpan = styled.span<StyledSpanProps>`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  white-space: ${({ preWrap, noWrap }) =>
    noWrap ? 'nowrap' : preWrap ? 'pre-wrap' : 'initial'};
  transition: all 0.2s ease;

  ${({ flexed, gap = 2 }) =>
    flexed &&
    css`
      display: flex;
      align-items: center;
      gap: ${gap}px;
    `}

  ${({ avoidTransition }) =>
    avoidTransition &&
    css`
      transition: none;
    `}

  ${({ fontWeight }) =>
    fontWeight &&
    css`
      font-weight: ${({ theme }) => theme.fontWeights[fontWeight]};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${({ theme }) => theme.colors[color]};
    `}

    ${({ hoveredColor }) =>
    hoveredColor &&
    css`
      :hover {
        color: ${({ theme }) => theme.colors[hoveredColor]};
      }
    `}

  ${({ cursorPointer }) =>
    cursorPointer &&
    css`
      cursor: pointer;

      :hover {
        opacity: 0.7;
      }
    `}

    ${({ variant }) =>
    variant &&
    css`
      font-size: ${TextVariant[variant].fontSize};
      line-height: ${TextVariant[variant].lineHeight};
      letter-spacing: ${TextVariant[variant]?.letterSpacing ?? 'initial'};
    `}

    ${({ responsiveVariant, theme }) =>
    responsiveVariant &&
    css`
      ${Object.keys(responsiveVariant).map(
        size =>
          `@media (min-width: ${theme.screenSizes[size]}px) {
      font-size: ${TextVariant[responsiveVariant[size]].fontSize};
      line-height: ${TextVariant[responsiveVariant[size]].lineHeight};
      letter-spacing: ${
        TextVariant[responsiveVariant[size]]?.letterSpacing ?? 'initial'
      };

        };`,
      )}
    `}

    ${({ numOfLines }) =>
    numOfLines &&
    css`
      display: -webkit-box; /* Necessary for multi-line truncation */
      -webkit-box-orient: vertical; /* Vertical box layout */
      -webkit-line-clamp: ${numOfLines}; /* Limit to three lines */
      overflow: hidden; /* Hide overflowed content */
      text-overflow: ellipsis; /* Add ellipsis at the end of truncated text */
    `}
    ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}
`;

export type TextProps = Partial<StyledSpanProps> &
  HTMLAttributes<HTMLSpanElement>;

const Text = forwardRef(
  (
    {
      children,
      className,
      color,
      fontWeight,
      preWrap = false,
      noWrap = false,
      flexed = false,
      ...rest
    }: TextProps,
    ref: ForwardedRef<HTMLSpanElement>,
  ) => {
    return (
      <StyledSpan
        // style={variant && TextVariant[variant]}
        color={color}
        fontWeight={fontWeight}
        preWrap={preWrap}
        noWrap={noWrap}
        flexed={flexed}
        ref={ref}
        className={className}
        {...rest}
      >
        {children}
      </StyledSpan>
    );
  },
);

export default Text;
