import Header, { HeaderProps } from './components/Header';
import { Row } from 'theme/globalStyledComponents/row';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 100px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: center;
    width: 100%;

    @media (min-width: 800px) {
      width: 753px;
    }
  }

  .children {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: stretch;

    @media (min-width: ${({ theme }) => theme.screenSizes.lg}px) {
      flex-direction: row;
      align-items: flex-start;
    }
  }
`;

const LockOptionLayout = ({
  children,
  headerProps,
}: {
  children: React.ReactNode;
  headerProps: HeaderProps;
}) => {
  return (
    <Container>
      <div>
        <Header {...headerProps} />
        <Row className="children">{children}</Row>
      </div>
    </Container>
  );
};

export default LockOptionLayout;
