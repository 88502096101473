import styled from 'styled-components';

export const MarketSelectStyled = styled.div`
  display: flex;

  cursor: pointer;
  position: relative;

  .market-icon {
    width: 24px;
    height: 24px;
  }
`;

interface MarketContainerProps {
  isDropdownShown: boolean;
}
export const MarketContainer = styled.div<MarketContainerProps>`
  display: flex;
  align-items: center;
  background: ${({ isDropdownShown, theme }) =>
    isDropdownShown ? theme.colors.shadesBackground700 : 'transparent'};
  border-radius: 8px;
  padding: 8px;
  gap: 5px;

  :hover {
    background: ${({ isDropdownShown, theme }) =>
      theme.colors.shadesBackground700};
  }

  /* width: 137px; */
`;

export const MarketInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  height: 28px;
`;

export const MarketInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1px;

  .pair {
    display: flex;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
  }

  .type {
    color: ${({ theme }) => theme.colors.shadesForeground300};
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 56px;
  border-radius: 16px;
  z-index: 14;
  background: ${({ theme }) => theme.colors.shadesBackground800};
  border: 1px solid ${({ theme }) => theme.colors.shadesBackground500};
  width: 320px;
  padding: 8px;

  @media (min-width: 360px) {
    padding: 10px;
    width: 340px;
  }

  @media (min-width: 380px) {
    padding: 10px;
    width: 360px;
  }

  @media (min-width: 400px) {
    padding: 12px;
    width: 386px;
  }

  @media (min-width: 800px) {
    width: 386px;
    padding: 16px;
  }

  @media (min-width: 1280px) {
    width: 386px;
    padding: 16px;
  }

  @media (min-width: ${({ theme }) => theme.screenSizes.default}px) {
    width: 386px;
    padding: 24px;
  }
`;

export const TopPart = styled.div``;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  span {
    margin: 0;
  }

  img {
    cursor: pointer;
  }
`;

export const NoResultsAndLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.shadesForeground300};
`;
