import { memo } from 'react';

import { FormattedNumber } from 'components';

import { Row, SuccessSection } from '../styles';
import { DEFAULT_DECIMAL_SCALE } from 'constants/general';

import { useTranslation } from 'react-i18next';

interface Props {
  depositAmount: number | null;
}
const SuccessStep = ({ depositAmount }: Props) => {
  const { t } = useTranslation();
  if (!depositAmount) return null;
  return (
    <SuccessSection flex={1}>
      <Row>
        <FormattedNumber
          value={depositAmount}
          decimalScale={DEFAULT_DECIMAL_SCALE}
          disableTooltip={true}
          prefix="$"
        />
        <span>{t('hasBeenDepositedToYourAccount')}</span>
      </Row>
    </SuccessSection>
  );
};

export default memo(SuccessStep);
