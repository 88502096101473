import { Slider } from '@material-ui/core';

import Text from 'components/Text';

import styled, { css } from 'styled-components';
import { isPrefixUnaryExpression } from 'typescript';

const ButtonGroupStyled = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledText = styled(Text)<{ isActive?: boolean }>`
  background: ${({ theme, isActive }) =>
    isActive
      ? theme.colors.shadesBackground500
      : theme.colors.shadesBackground700};
  border-radius: 6px;
  text-align: center;
  flex: 1;
  cursor: pointer;
  justify-content: center;
  padding: 8px 0px;
  user-select: none;

  ${({ isActive }) =>
    !isActive &&
    css`
      &:hover {
        color: ${({ theme }) => theme.colors.white};
      }
    `}
`;

const SliderContainer = styled.div`
  position: relative;
  height: 39px;

  .labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

const SliderStyled = styled(Slider)`
  width: calc(100% - 8px);

  & .MuiSlider-rail {
    opacity: 0.5;
  }

  & .MuiSlider-thumb {
    border: 1px solid ${({ theme }) => theme.colors.shadesForeground300};
    background: 1px solid ${({ theme }) => theme.colors.shadesForeground400};
  }

  & .muislider-mark {
    &.MuiSlider-markActive {
      opacity: 1;
    }
  }

  .MuiSlider-markLabel {
    display: none;
  }

  .MuiSlider-valueLabel {
    color: ${({ theme }) => theme.colors.shadesForeground200};
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px
      ${({ theme }) => theme.colors.shadesForeground300}34;
  }

  .MuiSlider-track {
    background: ${({ theme }) => theme.colors.shadesForeground300}34;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 39px;
  min-height: 39px;
  width: 100%;
`;

const defaultPresets = [25, 50, 75, 100];

interface Props {
  presets?: number[];
  onOptionSelect: (value: number) => void;
  dataGtmId?: string;
  isPresetActive?: (value: number) => boolean;
  disabled?: boolean;
  className?: string;
  isPresetTypeSlider: boolean;
  selectedPreset: number | null;
  suffix?: string | null;
  prefix?: string | null;
}
export const Presets = ({
  isPresetActive,
  presets,
  onOptionSelect,
  dataGtmId,
  disabled = false,
  className,
  isPresetTypeSlider,
  selectedPreset,
  prefix = null,
  suffix = '%',
}: Props) => {
  const _presets = presets ? presets : defaultPresets;

  return (
    <Container>
      {isPresetTypeSlider ? (
        <SliderContainer>
          <SliderStyled
            // aria-label="Custom marks"÷
            defaultValue={selectedPreset ?? 0}
            getAriaValueText={v => `${v}%`}
            step={1}
            valueLabelDisplay="auto"
            marks={_presets.map(i => ({ label: i.toString(), value: i }))}
            min={0}
            key={selectedPreset}
            max={100}
            onChangeCommitted={(e, v) => onOptionSelect(v as number)}
            // onChange={(e, v) => onOptionSelect(v as number)}
          />

          <Text variant="BODY_S" color="shadesForeground200" className="labels">
            <span>0%</span>
            <span>100%</span>
          </Text>
        </SliderContainer>
      ) : (
        <ButtonGroupStyled
          aria-label="outlined primary button group"
          className={className}
        >
          {_presets.map(el => (
            <StyledText
              data-testid={`preset-percent-${el}`}
              onClick={() => !disabled && onOptionSelect(el)}
              key={el}
              data-gtmid={`${dataGtmId}-${el}`}
              variant="BODY_S"
              fontWeight="semiBold"
              color={el === selectedPreset ? 'white' : 'shadesForeground200'}
              isActive={el === selectedPreset}
              flexed
            >
              {prefix}
              {el}
              {suffix}
            </StyledText>
          ))}
        </ButtonGroupStyled>
      )}
    </Container>
  );
};
