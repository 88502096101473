import { saveUserSetting } from 'utils';
import { getTimestampWithOffsetFromLocalStorage } from 'utils/api';
import { deserializeArray, deserializeSingle } from 'utils/apiMiddleware';
import { isBrandRabbitX } from 'utils/brand';
import {
  FrontendSecrets,
  NewPayload,
  PayloadHash,
  RBTSignature,
} from 'utils/signatures';

import { SIGNATURE_LIFETIME } from 'constants/api';
import { LS_CLOCK_OFFSET } from 'constants/localStorageKeys';

import axios from 'axios';
import { config as appConfig } from 'config';
import { RequestMethod } from 'enums';
import { BackendObj, ApiMapping } from 'interfaces';

export interface Pagination {
  total: number;
  page: number;
  perPage: number;
  limit: number;
  order: string;
}

interface RbtResponse<T> {
  error: string;
  result: T[] | T;
  success: boolean;
  pagination: Pagination;
}

export type RequestResponse<ResponseType> = {
  pagination: Pagination;
  data: ResponseType;
};

export type QueryParams = Record<string, number | string | string[]>;

interface RequestParams {
  method: RequestMethod;
  path?: string;
  fullUrl?: string;
  body?: any;
  queryParams?: QueryParams;
  headers?: { [key: string]: string };
  isArray?: boolean;
  returnRaw?: boolean;
  responseMapping?: ApiMapping;
  paramsMapping?: ApiMapping;
}

const getClockOffset = (
  serverTime: number,
  beforeRequestTime: number,
  afterRequestTime: number,
) => {
  const roundTripTime = afterRequestTime - beforeRequestTime;
  const halfRoundTripTime = roundTripTime / 2;
  const adjustedServerTime = serverTime - halfRoundTripTime;
  const offset = adjustedServerTime - beforeRequestTime;
  return offset;
};

/**
 * The base http service
 */
export class RestService {
  private static instance: RestService;
  private apiUrl: string;

  constructor() {
    // @TODO: there should be an option to pass in apiUrl through a constructor, as we might use multiple urls in the future
    this.apiUrl = appConfig.apiUrl;
  }

  static get = (): RestService => {
    if (!RestService.instance) {
      RestService.instance = new RestService();
    }
    return RestService.instance;
  };

  /**
   * Create fully qualified URL from supplied path
   * @param path
   */
  makeUrl(path?: string): string {
    // Append path to url
    let url = this.apiUrl;
    if (path) {
      url += path;
    }

    return url;
  }

  /**
   * Make HTTP query params string from primitive object
   * @param queryParams - object containing query params to be appended to the url
   * @returns - string containing the query params
   */
  static makeQueryParams(queryParams: QueryParams | undefined) {
    if (!queryParams) {
      return '';
    }

    const httpParams = new URLSearchParams();

    // Loop through each key-value pair in the query params object
    for (const [key, value] of Object.entries(queryParams)) {
      // If the value is an array, append each array element as a separate parameter
      if (Array.isArray(value)) {
        for (const val of value) {
          httpParams.append(key, val.toString());
        }
      }
      // Otherwise, append the value as a single parameter
      else {
        httpParams.append(key, value.toString());
      }
    }

    return `?${httpParams.toString()}`;
  }

  getResponse<ResponseType>(
    res: RbtResponse<ResponseType>,
    isArray: boolean,
    mapping: ApiMapping | undefined,
  ): RequestResponse<ResponseType> {
    let data: ResponseType;
    // If no mapping is provided, return the raw response
    if (!mapping) {
      data = isArray ? res.result : res.result[0];
    }
    // Otherwise, deserialize the response using the provided mapping
    else {
      data = isArray
        ? (deserializeArray(
            res.result as BackendObj[],
            mapping,
          ) as ResponseType)
        : deserializeSingle(res.result[0], mapping);
    }

    return {
      data,
      pagination: res.pagination,
    };
  }

  getParams(params: any, mapping: ApiMapping | undefined) {
    if (!mapping) {
      return params;
    }

    return deserializeSingle(params, mapping);
  }

  /**
   * Perform an HTTP request
   * @param params.method GET, POST, PUT, or DELETE
   * @param params.path Path to append to URL
   * @param params.fullUrl Fully qualified URL (overrides path)
   * @param params.body Body of the request
   * @param params.queryParams Query parameters
   * @param params.headers Query headers
   * @param params.responseMapping Response mapping for deserialization
   */
  async request<ResponseType>({
    method,
    path,
    fullUrl,
    body,
    queryParams,
    headers,
    isArray = false,
    returnRaw = false,
    paramsMapping,
    responseMapping,
  }: RequestParams): Promise<RequestResponse<ResponseType>> {
    let url: string;
    if (typeof fullUrl !== 'undefined') {
      url = fullUrl;
    } else {
      url = this.makeUrl(path);
    }

    const urlWithParams = `${url}${RestService.makeQueryParams(queryParams)}`;

    const requestHeaders = {
      'Access-Control-Allow-Credentials': true,
      'Max-Content-Length': 'Infinity',
      'Content-Type': 'application/json',
      ...(!isBrandRabbitX ? { EID: 'bfx' } : {}),
      ...headers,
    };

    switch (method) {
      case RequestMethod.GET:
        try {
          const beforeRequestTime = Math.floor(new Date().getTime() / 1000);
          const res = await axios.get(urlWithParams, {
            method: RequestMethod.GET,
            headers: requestHeaders,
            withCredentials: true,
          });
          const afterRequestTime = Math.floor(new Date().getTime() / 1000);
          // Save the clock offset if the server timestamp is provided
          if (res.headers['srv-timestamp']) {
            const clockOffset = getClockOffset(
              Number(res.headers['srv-timestamp']),
              beforeRequestTime,
              afterRequestTime,
            );
            saveUserSetting(LS_CLOCK_OFFSET, Math.floor(clockOffset));
          }
          const rbtRes: RbtResponse<ResponseType> = res.data;
          if (rbtRes.error) {
            throw new Error(
              `Error during GET request for url: ${urlWithParams}, Error: ${rbtRes.error}`,
            );
          }
          //@ts-ignore
          if (returnRaw) return rbtRes;

          return this.getResponse(rbtRes, isArray, responseMapping);
        } catch (e: any) {
          throw new Error(
            `Error during GET request for url: ${urlWithParams}`,
            e,
          );
        }
      case RequestMethod.POST:
        try {
          const res = await axios.post(
            urlWithParams,
            this.getParams(body, paramsMapping),
            {
              headers: requestHeaders,
              withCredentials: true,
              validateStatus: function (status: number) {
                return status >= 200 && status <= 400;
              },
            },
          );
          const rbtRes: RbtResponse<ResponseType> = res.data;
          if (rbtRes.error) {
            throw new Error(rbtRes.error);
          }
          return this.getResponse(rbtRes, isArray, responseMapping);
        } catch (e: any) {
          throw new Error(e);
        }
      case RequestMethod.PUT:
        try {
          const res = await axios.put(
            urlWithParams,
            this.getParams(body, paramsMapping),
            {
              headers: requestHeaders,
              withCredentials: true,
              validateStatus: function (status: number) {
                return status >= 200 && status <= 400;
              },
            },
          );
          const rbtRes: RbtResponse<ResponseType> = res.data;
          if (rbtRes.error) {
            throw new Error(rbtRes.error);
          }
          return this.getResponse(rbtRes, isArray, responseMapping);
        } catch (e: any) {
          throw new Error(e);
        }
      case RequestMethod.PATCH:
        try {
          const res = await axios.patch(
            urlWithParams,
            this.getParams(body, paramsMapping),
            {
              headers: requestHeaders,
              withCredentials: true,
              validateStatus: function (status: number) {
                return status >= 200 && status <= 400;
              },
            },
          );
          const rbtRes: RbtResponse<ResponseType> = res.data;
          if (rbtRes.error) {
            throw new Error(rbtRes.error);
          }
          return this.getResponse(rbtRes, isArray, responseMapping);
        } catch (e: any) {
          throw new Error(e);
        }
      case RequestMethod.DELETE:
        try {
          const res = await axios.delete(urlWithParams, {
            headers: requestHeaders,
            data: this.getParams(body, paramsMapping),
            withCredentials: true,
            validateStatus: function (status: number) {
              return status >= 200 && status <= 400;
            },
          });
          const rbtRes: RbtResponse<ResponseType> = res.data;
          if (rbtRes.error) {
            throw new Error(rbtRes.error);
          }
          return this.getResponse(rbtRes, isArray, responseMapping);
        } catch (e: any) {
          throw new Error(e);
        }
      default:
        throw new Error(`Invalid request type: ${method}`);
    }
  }

  /**
   * Perform an HTTP private request
   * @param params.method GET, POST, PUT, or DELETE
   * @param params.path Path to append to URL
   * @param params.requestParams Request parameters
   * @param params.responseMapping Response mapping for deserialization
   * @param params.paramsMapping Request params mapping for serialization
   */
  async privateRequest<ResponseType>({
    method,
    endpoint,
    requestParams,
    paramsMapping,
    responseMapping,
    accountSecrets,
    isArray = false,
    queryParams,
    requestHeaders = {},
  }: {
    method: RequestMethod;
    endpoint: string;
    requestParams: any;
    paramsMapping?: ApiMapping | undefined;
    responseMapping?: ApiMapping | undefined;
    accountSecrets: FrontendSecrets;
    isArray?: boolean;
    queryParams?: QueryParams;
    requestHeaders?: Record<string, string>;
  }): Promise<RequestResponse<ResponseType>> {
    const timestamp =
      getTimestampWithOffsetFromLocalStorage() + SIGNATURE_LIFETIME;

    // Serialize request params if needed
    const serializedParams = paramsMapping
      ? deserializeSingle(requestParams, paramsMapping)
      : requestParams;

    // Create payload and hash it
    const payload = NewPayload(timestamp, method, endpoint, serializedParams);
    const hashedPayload = PayloadHash(timestamp, payload);
    // Create RBT signature for the RBT-SIGNATURE header
    const rbtSignature = RBTSignature(
      hashedPayload,
      accountSecrets.randomSecret,
    );

    const headers = {
      'RBT-SIGNATURE': rbtSignature,
      'RBT-TS': timestamp.toString(),
      ...requestHeaders,
    };

    return await this.request<any>({
      method,
      path: endpoint,
      body: requestParams,
      headers,
      responseMapping,
      paramsMapping,
      isArray,
      queryParams,
    });
  }
}
