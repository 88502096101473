import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;

  background: linear-gradient(
    101.93deg,
    #202c4b 0%,
    #1e1d35 51.04%,
    #24152c 100%
  );

  .gray {
    color: ${({ theme }) => theme.colors.shadesForeground300};
  }
`;
