import { timestampToLocaleTimeString } from 'utils';

import { useGetDecimalPlaces } from 'hooks/useDecimalPlaces';

import { FormattedNumber, Icon } from 'components';
import Text from 'components/Text';
import { TextVariant } from 'components/Text/variant';

import arrowDown from 'assets/icons/arrow-down.svg';
import arrowUp from 'assets/icons/arrow-up.svg';
import { DEFAULT_DECIMAL_SCALE } from 'constants/general';
import { PriceChangeType } from 'enums/priceChangeType';

import { ArrowState } from 'enums';
import { CurrencyKind, Trade } from 'interfaces';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const BOLD_AND_HIGHLIGHT_THRESHOLD = 50_000;
const BOLD_AND_BRIGHTER_HIGHLIGHT_THRESHOLD = 200_000;

interface TradeRowProps {
  isBold?: boolean;
  backgroundColor: string;
  priceChangeType: PriceChangeType;
}
const TradeRowStyled = styled.div<TradeRowProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-weight: ${({ isBold, theme }) =>
    isBold ? theme.fontWeights.semiBold : theme.fontWeights.medium};
  background: ${({ backgroundColor }) => backgroundColor};

  transition: all 0.1s;

  > span {
    display: flex;
    align-items: center;
    width: 33.33%;
    height: 17px;
    position: relative;
    padding: 0px 2px;

    :nth-of-type(2),
    :nth-of-type(3) {
      justify-content: flex-end;
    }

    :first-child {
      color: ${({ priceChangeType, theme }) =>
        priceChangeType === PriceChangeType.Negative
          ? theme.colors.negativeForeground200
          : theme.colors.positiveForeground200};
    }

    .arrow-icon {
      width: 6px;
      right: 5px;
      margin-left: 5px;
    }
  }
`;

export enum HighlightLevels {
  None = 'None',
  NormalSuccessHighlight = 'NormalSuccessHighlight',
  BrighterSuccessHighlight = 'BrighterSuccessHighlight',
  NormalDangerHighlight = 'NormalDangerHighlight',
  BrighterDangerHighlight = 'BrighterDangerHighlight',
}

export const tradeRowBackgroundColors = {
  [HighlightLevels.None]: 'transparent',
  [HighlightLevels.NormalSuccessHighlight]: 'rgba(13, 203, 146, 0.35)',
  [HighlightLevels.BrighterSuccessHighlight]: 'rgba(13, 203, 146, 0.5)',
  [HighlightLevels.NormalDangerHighlight]: 'rgba(245, 88, 88, 0.35)',
  [HighlightLevels.BrighterDangerHighlight]: 'rgba(245, 88, 88, 0.5)',
};

export const getTradeRowBackgroundColor = (
  priceChangeType: PriceChangeType,
  tradeVolume: number,
): string => {
  // No background highlight if trade volume < $200,000
  if (tradeVolume < BOLD_AND_HIGHLIGHT_THRESHOLD)
    return tradeRowBackgroundColors[HighlightLevels.None];

  // Price = up or the same
  if (
    [PriceChangeType.Positive, PriceChangeType.None].includes(priceChangeType)
  ) {
    if (tradeVolume > BOLD_AND_BRIGHTER_HIGHLIGHT_THRESHOLD) {
      return tradeRowBackgroundColors[HighlightLevels.BrighterSuccessHighlight];
    } else {
      return tradeRowBackgroundColors[HighlightLevels.NormalSuccessHighlight];
    }
  }
  // Price = down
  else {
    if (tradeVolume > BOLD_AND_BRIGHTER_HIGHLIGHT_THRESHOLD) {
      return tradeRowBackgroundColors[HighlightLevels.BrighterDangerHighlight];
    } else {
      return tradeRowBackgroundColors[HighlightLevels.NormalDangerHighlight];
    }
  }
};

interface Props {
  trade: Trade;
  index: number;
  currency: CurrencyKind;
  marketID: string;
}
const TradeRow = ({
  trade: { price, size, timestamp, priceChangeType, arrowState },
  currency,
  marketID,
}: Props) => {
  const totalAmount = parseFloat(size) * parseFloat(price);
  const isBold = totalAmount > BOLD_AND_HIGHLIGHT_THRESHOLD;
  const backgroundColor = getTradeRowBackgroundColor(
    priceChangeType!,
    totalAmount,
  );

  const currencySize =
    currency === CurrencyKind.BASE
      ? size
      : parseFloat(size) * parseFloat(price);

  const decimalScales = useGetDecimalPlaces(marketID);
  const priceDecimalScale = decimalScales.price;
  const sizeDecimalScale =
    currency === CurrencyKind.QUOTE
      ? DEFAULT_DECIMAL_SCALE
      : decimalScales.size;

  return (
    <TradeRowStyled
      priceChangeType={priceChangeType!}
      isBold={isBold}
      backgroundColor={backgroundColor}
      style={TextVariant['BODY_S']}
    >
      <Text flexed>
        <FormattedNumber
          value={price}
          decimalScale={priceDecimalScale}
          disableTooltip={true}
        />
        {arrowState !== ArrowState.None ? (
          <Icon
            src={arrowState === ArrowState.Down ? arrowDown : arrowUp}
            size="S"
            className="ml-2"
            alt="Price change arrow"
          />
        ) : null}
      </Text>
      <Text color={'shadesForeground200'}>
        <FormattedNumber
          value={currencySize}
          decimalScale={sizeDecimalScale}
          disableTooltip={true}
        />
      </Text>
      <Text color={'shadesForeground200'}>
        {timestampToLocaleTimeString(timestamp)}
      </Text>
    </TradeRowStyled>
  );
};

export default observer(TradeRow);
